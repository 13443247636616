/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';
import './template.css';
import getStrNum from '../../functions/strNumseparator';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const TemplateSection = ({
  arrayOne, customFeilds,
}) => {
  const {
    userData,
  } = useContext(AuthDataContext);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';

  const htmlJson = {
    tr1: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
          >
            {element.events.map((el) => (
              <>
                {!!el?.text && (
                  <span
                    className="test"
                    style={{ ...el.style }}
                    role="presentation"
                  >
                    {el.text}
                  </span>
                )}
              </>
            ))}
          </div>
        </Draggable>
      </div>
    ),
    tr2: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
          >
            {element.events.map((el) => (
              <>
                {el?.text && (
                  <span
                    style={{
                      ...el.style,
                    }}
                    role="presentation"
                  >
                    {el.text}
                  </span>
                )}
              </>
            ))}
          </div>
        </Draggable>
      </div>
    ),
    tr3: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />

        {element.events.map((el) => (
          <>
            {el?.text && (
            <Draggable
              position={{ x: Number(getStrNum(el?.style.left)?.num), y: Number(getStrNum(el?.style.top)?.num) }}
            >
              <div
                className="box"
                style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
              >
                <span
                  style={{
                    ...el.style,
                  }}
                  role="presentation"
                >
                  {el.text}
                </span>
              </div>
            </Draggable>
            )}
          </>
        ))}
      </div>
    ),
    tr4: (element, iii) => (
      <div>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              className="test"
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              className="test"
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr5: (element, iii) => (
      <div>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              className="test"
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              className="test"
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr6: (element, iii) => (
      <div>
        {element.events[0].text ? (
          <>
            <Draggable
              position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
            >
              <div
                className="box"
                style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
              >
                <span
                  style={{
                    ...element.events[0].style,
                  }}
                  role="presentation"
                >
                  {element.events[0].text}
                </span>
              </div>
            </Draggable>
          </>
        ) : (
          <>
            <div />
          </>
        )}

        {element.events[1].text && (
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
        )}
        {element.events[2].text && (
        <Draggable
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[2].style,
              }}
              role="presentation"
            >
              {element.events[2].text}
            </span>
          </div>
        </Draggable>
        )}
      </div>
    ),
    tr7: (element, iii) => (
      <div>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element?.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <div style={{ ...element.events[0].tdStyle2, position: 'absolute' }} />
      </div>
    ),
    tr8: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr9: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr10: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[2].style,
              }}
              role="presentation"
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr11: (element, iii) => (
      <>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle3, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr12: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[2].style,
              }}
              role="presentation"
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr13: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[2].style,
              }}
              role="presentation"
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[3]?.style.left)?.num), y: Number(getStrNum(element?.events[3]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[3].tdStyle4, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[3].style,
              }}
              role="presentation"
            >
              {element.events[3]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr14: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr15: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[2].style,
              }}
              role="presentation"
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          position={{ x: Number(getStrNum(element?.events[3]?.style.left)?.num), y: Number(getStrNum(element?.events[3]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[3].tdStyle4, position: 'absolute' }}
          >
            <span
              style={{
                ...element.events[3].style,
              }}
              role="presentation"
            >
              {element.events[3]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    img: (element, iii) => (
      <>
        <>
          <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
          <Draggable
            position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
          >
            <div
              className="box"
              // style={element.events[0].tdStyle2}
              style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            >
              {element.events.map((el, i) => (
                <>
                  {el?.text !== '' && (
                  <img
                    src={el?.text}
                    alt="logo"
                    style={{
                      maxHeight: `${el.style.imageHeight}`,
                      height: 'auto',
                      width: `${el.style.imageWidth}`,
                      objectFit: 'contain',
                      left: `${el.style.left}`,
                      top: `${el.style.top}`,
                      transform: `rotate(${el.style.imageRotate}deg)`,
                      zIndex: el.style.zIndex,
                      position: el.style.position,
                    }}
                  />
                  )}
                </>
              ))}
            </div>
          </Draggable>
        </>
      </>
    ),
  };
  const customJson = {
    tr: (element, iii) => (
      <>
        <Draggable
          position={{ x: Number(getStrNum(element?.style.left)?.num), y: Number(getStrNum(element?.style.top)?.num) }}
        >
          <div
            className="box"
            // style={element.tdStyle2}
            style={{ ...element.tdStyle2, position: 'absolute' }}
          >
            <span
              style={{
                ...element.style,
              }}
              role="presentation"
            >
              {element?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    img: (element, iii) => (
      <>
        <>
          <div />
        </>
      </>
    ),
  };
  return (
    <div className="quotes-template-page default-view-templates ">
      <page size="A4">
        <div
          id="myTable"
          className="edit-template quotes-edit-temp add-edit-quotes-template"
          style={{
            overflow: 'hidden',
            wordBreak: 'break-word',
            width: `${countryType === 'us' ? '8.5in' : '595px'}`,
            margin: '0px auto',
            borderTop: '1px solid lightgray',
            borderRight: '1px solid lightgray',
            borderLeft: 'none',
            display: 'block',
            backgroundColor: 'white',
            position: 'relative',
            height: `${countryType === 'us' ? '11in' : '842px'}`,
            border: '1px solid rgb(219 219 219)',
            padding: '20px',
          }}
        >
          {customFeilds
            && customFeilds.map((cust) => <>{customJson[cust.type](cust)}</>)}
          {Array.from(new Set(arrayOne.map((s) => s.type)))
            .map((lab) => ({
              name: lab,
              events: arrayOne
                .filter((s) => s.type === lab)
                .map((edition) => edition),
            }))
            .map((el, i) => (
              <>{htmlJson[el.name](el, i)}</>
            ))}
        </div>
      </page>
    </div>
  );
};
TemplateSection.propTypes = {
  arrayOne: PropTypes.arrayOf.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
};
export default TemplateSection;
