import React from 'react';
import { PropTypes } from 'prop-types';
import {
  GoogleMap, Marker, useJsApiLoader, Circle, DistanceMatrixService,
} from '@react-google-maps/api';
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';

const containerStyle = {
  width: '100%',
  height: '500px',
};
const ServiceAreaMap = (props) => {
  const {
    coords, clientCoords, setMatrix, siteAddresses, setClientCoords,
  } = props;
  const options = {
    strokeColor: '#06B59F',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: 'rgba(6, 181, 159, 0.2 )',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 0,
    zIndex: 1,
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA4nIdMdEOV6yq3hvMi605xcQRL7hBiVaM',
  });
  const onMapLoad = async () => {
    if (siteAddresses) {
      const results = await geocodeByAddress(siteAddresses);
      const { lat, lng } = await getLatLng(results[0]);
      // console.log('lat', 'lat', lat, lng);
      setClientCoords({
        lat,
        lng,
      });
    }
  };
  return isLoaded ? (
    <GoogleMap
      id="circle-example"
      mapContainerStyle={containerStyle}
      zoom={7}
      center={coords}
      options={options}
      onLoad={onMapLoad}
    >
      {coords && <Marker position={{ lat: coords.lat, lng: coords.lng }} />}
      {coords && clientCoords && (
      <DistanceMatrixService
        options={{
          destinations: [{ lat: clientCoords.lat, lng: clientCoords.lng }],
          origins: [{ lng: coords.lng, lat: coords.lat }],
          travelMode: 'DRIVING',
        }}
        callback={(res) => {
          if (res.rows[0].elements[0].status === 'ZERO_RESULTS') {
            setMatrix({
              totalTime: '',
              totalDistance: 'ZERO_RESULTS FOUND',
            });
          } else {
            setMatrix({
              totalTime: res.rows[0].elements[0].duration.text,
              totalDistance: res.rows[0].elements[0].distance.text,
            });
          }
        }}
      />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};
ServiceAreaMap.propTypes = {
  coords: PropTypes.func.isRequired,
  clientCoords: PropTypes.func.isRequired,
  setMatrix: PropTypes.func.isRequired,
  siteAddresses: PropTypes.string.isRequired,
  setClientCoords: PropTypes.func.isRequired,
};

export default React.memo(ServiceAreaMap);
