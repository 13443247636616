/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Nav,
  NavDropdown,
  Button,
  Navbar,
  Form,
  Container,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
// import logo from '../../assets/home/logo-pool.png';
import { Helmet } from 'react-helmet';
import logowhite from '../../assets/home/pool-logo-white.png';
// import mobilelogo from '../../assets/home/mobile-logo.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { HeaderContext } from '../../contexts/HeaderContext';
import cancel from '../../assets/home/cross-icon.png';
// import openheader from '../../assets/home/hamburger-icon.png';
import openheader from '../../assets/home/hamburger-icon.png';
import openheaderblue from '../../assets/home/hamburger-icon-blue.png';
import mobilelogo from '../../assets/home/calendar-pro-logo.png';
import mobilelogoblue from '../../assets/home/blue-logo.png';
import './header.css';
import { getCalendarPath } from '../../functions/index';
import './webfile.css';

const Header = ({ setChatBot }) => {
  // console.log('pathhhhh', getCalendarPath());
  let history = useHistory();
  let calcPath = getCalendarPath();
  const IndustryType = localStorage.getItem('IndustryType');
  const { userData, setUserData } = useContext(AuthDataContext);
  const { showHeader, setShowHeader } = useContext(HeaderContext);
  const [isSticky, setIsSticky] = useState(false);
  const [desktopHeader, setDesktopHeader] = useState();
  const [subsciption, setSubsciption] = useState(false);
  const [disableHeader, setDisableHeader] = useState(false);
  const [headerState, setHeaderState] = useState([]);
  const [desktopHeaderState, setDesktopHeaderState] = useState([]);
  const ref = React.createRef();

  useEffect(() => {
    const copyPermissions = JSON.parse(JSON.stringify(userData?.permissions));
    const filteredData = copyPermissions.filter((el) => !el.showMenu);
    const newArray = [];
    filteredData.forEach((el, i, selfArr) => {
      if (
        ![
          'Water Test History',
          'Inventory',
          'Users',
          'Permissions & Access Level',
          'Subscription Management',
          'Logout',
        ].includes(el.action)
        && ((el.class !== 'main-menu hidden-menu'
        && el.class.includes('main-menu')) || el.action === 'Calculator')
        && i === selfArr.findIndex((t) => t._id === el._id)
      ) {
        newArray.push({
          ...el,
          child: copyPermissions.filter(
            (elem, index, self) => elem.action !== '4-Pad & 6-Pad Test Strip Reader'
              && elem.category !== 'Calendar' && elem.category !== 'Calculator'
              && elem.category === el.category
               && elem.action !== el.category
              && index === self.findIndex((t) => t._id === elem._id),
          ), // for removing duplicay
        });
      }
    });

    console.log({ newArray });
    setHeaderState(filteredData);
    setDesktopHeaderState(newArray);
  }, [userData?.role]);

  const watchScroll = () => {
    if (window.scrollY > 60) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
    // localStorage.removeItem('s#@4f');
    // localStorage.removeItem('f#@5e');
    // localStorage.removeItem('loginStaff');
  };

  const getNavName = (action) => {
    let name = '';

    switch (action) {
      // case 'permissions & access level':
      //   name = action;
      //   break;
      case 'Subscription Management':
        name = action;
        break;
      case 'Calculator':
        name = action;
        break;
      case 'Staff':
        name = action;
        break;
      case 'Dashboard':
        name = action;
        break;
      case 'pool set-up':
        name = 'Pool Set-up';
        break;
      case 'services & products':
        name = 'Services & Products';
        break;
      case 'Manage Clients':
        name = action;
        break;
      case 'Add Appointment':
        name = action;
        break;
      case 'Calendar':
        name = action;
        break;
      case 'Clients':
        name = action;
        break;
      case 'Invoices & Payments':
        name = action;
        break;
      case 'Quotes':
        name = action;
        break;
      // case 'Payments':
      //   name = action;
      //   break;
      case 'Sales Resources':
        name = action;
        break;
      case 'Inventory':
        name = action;
        break;
      // case 'Purchase Orders':
      //   name = action;
      //   break;
      case 'Admin':
        name = action;
        break;
      case 'Company Information & Locations':
        name = action;
        break;
      case 'Data Uploads & Downloads':
        name = action;
        break;
      case 'Settings':
        name = action;
        break;
      case 'Industry Type':
        name = action;
        break;
      case 'Calendar settings & Unit of Measurement':
        name = 'Calendar Settings & Unit of Measurement';
        break;
      case 'My Account':
        name = action;
        break;
      case 'My Profile':
        name = action;
        break;
      case 'Calendar-Pro Billing':
        name = action;
        break;
      case 'Help Centre':
        name = action;
        break;
      case 'Getting Started':
        name = action;
        break;
      case 'Video Tutorials':
        name = action;
        break;
      case 'Contact':
        name = action;
        break;
      case 'Accounting software & merchant facility':
        name = 'Accounting Software & Merchant Facility';
        break;
      case 'Permissions & Access Level':
        name = action;
        break;
      case 'Quote & invoice templates':
        name = 'Quote & Invoice Templates';
        break;
      case 'Auto invoice sending & payment reminders':
        name = 'Auto Invoice Sending & Payment Reminders';
        break;
      case 'Water Test History':
        name = action;
        break;
      case 'Chatbot':
        name = action;
        break;
      default:
        break;
    }
    return name;
  };

  useEffect(() => {
    if (window !== undefined && window.screen.width >= 768) {
      window.addEventListener('scroll', watchScroll);
    }
    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  const handleHeader = () => {
    setShowHeader(true);
  };
  const handleHideHeader = () => {
    setShowHeader(false);
  };
  const handleDesktopHeader = () => {
    setDesktopHeader(!desktopHeader);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const { pathname } = window.location;
    if (pathname === '/subscriptions') {
      setSubsciption(true);
    } else {
      setSubsciption(false);
    }

    if (pathname === '/invoice-details') {
      setDisableHeader(true);
    } else {
      setDisableHeader(false);
    }
  }, []);

  const handleLogin = () => {
    history.push('/login');
  };

  // const handleHome = () => {
  //   window.open('https://calendar-pro.com/');
  // };

  const handleChatBotModal = () => {
    setChatBot(true);
    setShowHeader(false);
  };

  return (
    <>
      <Helmet>
        <body
          lang="en"
          className={`${showHeader ? 'fixed-body-section' : ''} ${
            desktopHeader ? 'fixed-body-section' : ''
          } ${subsciption ? 'yellow-header' : ''} ${disableHeader ? 'disable-header' : ''}`}
        />
      </Helmet>
      <div
        className={`header mbl-header ${isSticky ? ' sticky' : ''} `}
        ref={ref}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="mobile-view">
                <a href="https://calendar-pro.com" className="navbar-brand">
                  {/* <img src={mobilelogo} alt="logo" className="logo-dark" /> */}
                  {/* <img src={logowhite} alt="logo-white" className="logo-light" /> */}
                  {subsciption ? (
                    <img
                      src={mobilelogoblue}
                      alt="logo"
                      className="logo-dark"
                    />
                  ) : (
                    <img src={mobilelogo} alt="logo" className="logo-dark" />
                  )}
                </a>

                <div
                  className={`cross-icon ${showHeader ? 'show-header' : ''}`}
                >
                  {subsciption ? (
                    <img
                      src={openheaderblue}
                      alt="open"
                      onClick={handleHeader}
                      role="presentation"
                      className="openheader g"
                    />
                  ) : (
                    <img
                      src={openheader}
                      alt="open"
                      onClick={handleHeader}
                      role="presentation"
                      className="openheader g"
                    />
                  )}
                </div>
              </div>
              <div className="header-top d-none d-xl-block">
                {userData.auth ? (
                  <ul className="menu-profile-list">
                    <li className=" menu-profile-list d-flex align-items-center">
                      <span>Welcome : </span>
                      <h6>{userData.email}</h6>
                    </li>
                    {/* <li className="menu-profile-item">
                    <Link to="/profile" className="nav-link"> My Profile </Link>
                  </li> */}
                    <li className="menu-profile-item">
                      <Button
                        type="submit"
                        className="btn btn-danger"
                        onClick={handleLogout}
                      >
                        {' '}
                        Logout
                        {' '}
                      </Button>
                    </li>
                  </ul>
                ) : null}
              </div>
              {showHeader && (
                <div className="custom-header mob-header">
                  <div className="custom-mob-section">
                    <div className="cross-icon">
                      <img
                        src={cancel}
                        alt="close"
                        onClick={handleHideHeader}
                        role="presentation"
                      />
                    </div>
                    <a href="https://calendar-pro.com" className="navbar-brand">
                      <img src={mobilelogo} alt="logo" className="logo-dark" />
                      <img
                        src={logowhite}
                        alt="logo-white"
                        className="logo-light"
                      />
                    </a>
                    <div className="header-link">
                      {userData.auth && userData.permissions.length ? (
                        <Nav className="ml-auto">
                          {/* <Nav.Item>
                      <Link to="/dashboard" className="nav-link">Dashboard</Link>
                    </Nav.Item> */}
                          {headerState.map((permission) => (
                            <>
                              {permission.route ? (

                                <>
                                  {permission?.route === '/chatBot' ? (
                                    <Nav.Item
                                      key={permission.route}
                                      className={`${permission.class}`}
                                    >
                                      <span
                                        onClick={handleChatBotModal}
                                        className="nav-link"
                                      >
                                        {getNavName(permission.action)}
                                      </span>
                                    </Nav.Item>
                                  ) : (
                                    <>
                                      {userData?.industryId?.name
                                    !== 'Pool Servicing'
                                  && (permission.action === 'Calculator'
                                    || permission.action
                                      === 'pool set-up') ? null : (

                                        <Nav.Item
                                          key={permission.route}
                                          className={`${permission.class}`}
                                          onClick={() => setUserData({
                                            ...userData,
                                            showHeader: false,
                                          })}
                                        >
                                          <Link
                                            to={
                                          permission.route === '/calendar'
                                            ? calcPath
                                            : permission.route
                                        }
                                            className="nav-link"
                                          >
                                            {getNavName(permission.action)}
                                          </Link>
                                        </Nav.Item>

                                        )}

                                    </>
                                  )}

                                </>
                              ) : (
                                <Nav.Item
                                  key={permission.route}
                                  className={`${permission.class}`}
                                >
                                  <Link
                                    to="/"
                                    className="nav-link disable-link"
                                  >
                                    {getNavName(permission.action)}
                                  </Link>
                                </Nav.Item>
                              )}
                            </>
                          ))}
                          {/* <Nav.Item onClick={() => setUserData({ ...userData, showHeader: false })}>
                        <Link to="/contact" className="nav-link">Contact</Link>
                      </Nav.Item>
                      <Nav.Item className="d-block d-xl-none" onClick={() => setUserData({ ...userData, showHeader: false })}>
                        <Link to="/profile" className="nav-link">My Profile</Link>
                      </Nav.Item> */}
                          <Nav.Item
                            className="d-block d-xl-none mt-2 logout-btn"
                            onClick={() => setUserData({ ...userData, showHeader: false })}
                          >
                            <Button
                              type="submit"
                              className="btn btn-danger"
                              onClick={handleLogout}
                            >
                              {' '}
                              Logout
                              {' '}
                            </Button>
                          </Nav.Item>
                        </Nav>
                      ) : !userData.auth ? (
                        <Nav className="ml-auto mt-2">
                          <Nav.Item className="submenu-item nav-item">
                            <Link
                              to="/"
                              className="nav-link"
                            >
                              Home
                            </Link>
                          </Nav.Item>
                          <Nav.Item className="submenu-item nav-item">
                            <Link
                              to="/contact"
                              className="nav-link"
                            >
                              Contact
                            </Link>
                          </Nav.Item>
                          <Nav.Item
                            className="d-block d-xl-none mt-2 logout-btn"
                            onClick={() => setUserData({ ...userData, showHeader: false })}
                          >
                            <Button
                              type="submit"
                              className="btn btn-danger"
                              onClick={handleLogout}
                            >
                              {' '}
                              Login
                              {' '}
                            </Button>
                          </Nav.Item>
                        </Nav>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`header web-header ${isSticky ? ' sticky' : ''} `}
        ref={ref}
      >
        <Container className="header-relative">
          <Navbar collapseOnSelect expand="lg">

            <div className="web-header-layout">
              <Navbar.Brand href="https://calendar-pro.com">
                <img src={mobilelogo} alt="logo" className="logo-dark" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                {localStorage.getItem('s#@4f') && (
                <Nav className="ml-auto">
                  {desktopHeaderState.map((elem) => (

                    !(elem?.child?.length > 0)
                      ? (
                        <Link className="nav-link" to={elem.route}>
                          {elem.action}
                        </Link>
                      )
                      : (
                        <NavDropdown
                          title={elem.action}
                          id="collasible-nav-dropdown"
                        >
                          {elem.child.map((elemen) => (
                            elemen?.route === '/chatBot' ? (
                              <Nav.Item
                                key={elemen.route}
                                // className={`${elemen.class}`}
                              >
                                <span
                                  onClick={handleChatBotModal}
                                  className="dropdown-item"
                                >
                                  {getNavName(elemen.action)}
                                </span>
                              </Nav.Item>
                            ) : <Link className="dropdown-item" to={elemen.route}>{getNavName(elemen.action)}</Link>
                          ))}
                        </NavDropdown>
                      )

                  ))}

                </Nav>
                )}
              </Navbar.Collapse>
            </div>

          </Navbar>
          {localStorage.getItem('s#@4f') ? (
            <div className="auth-login">
              <p>
                Welcome:
                {' '}
                {userData.email}
              </p>
              <Button onClick={handleLogout}>Logout</Button>
            </div>
          ) : (
            <div className="auth-login">
              <Button onClick={handleLogin}>Login</Button>
            </div>
          )}

        </Container>
      </div>
    </>
  );
};
Header.propTypes = {
  setChatBot: PropTypes.func.isRequired,
};
export default Header;
