import React from 'react';
import PropTypes from 'prop-types';
import cancelimg from '../../assets/home/cross-icon.png';
import calendaricon from '../../assets/home/week-logo.png';
import daysicon from '../../assets/home/days-logo.png';
import dayicon from '../../assets/home/day-logo.png';
import CalendarOption from './slider';
import whitearroe from '../../assets/home/whitearrow.png';

const TeamMember = ({
  handleCloseClient,
  setCalendarColor,
  showTeam,
  wrapperRef,
  staffList,
  activeStaff,
  SelectedStaff,
  handleCustomeView,
  filterType,
  color,
  activeStaffId,
  filterTime,
  secondcolor,
  handleLocationPopUp,
  handleTimeSlot,
}) => (
  <>
    {showTeam && <div className="popup-section" />}
    <div className="calendar-team-member" ref={wrapperRef}>
      <div className="calendar-option">
        <div className="calendar-close">
          <img
            src={cancelimg}
            alt="close"
            onClick={handleCloseClient}
            role="presentation"
          />
        </div>
        <div className="calendar-options">
          <h6>Calendar Options</h6>
          <CalendarOption
            setCalendarColor={setCalendarColor}
            handleCustomeView={handleCustomeView}
            filterType={filterType}
            color={color}
            filterTime={filterTime}
            secondcolor={secondcolor}
            handleTimeSlot={handleTimeSlot}
          />
          {/* <div className="total-option">
          <div className="calendar-time">
            <div className="day-option">
              <img src={dayicon} alt="icon" />
            </div>
            <p>Day</p>
          </div>
          <div className="calendar-time">
            <div className="day-option">
              <img src={daysicon} alt="icon" />
            </div>
            <p>3 Days</p>
          </div>
          <div className="calendar-time active">
            <div className="day-option">
              <img src={calendaricon} alt="icon" />
            </div>
            <p>Week</p>
          </div>
        </div>
       */}
        </div>
      </div>
      <div className="member-location" onClick={handleLocationPopUp}>
        <div>
          <h6>Location</h6>
          <p>
            {activeStaff.streetAddress || 'Sydney '}
          </p>
        </div>
        <div className="location-arrow">
          <img src={whitearroe} alt="whitearroe" />
        </div>
      </div>
      <div className="team-members">
        <h6>Team Members</h6>
        <div className="member-name">
          {staffList
            && staffList.map((el, i) => {
              let { firstname, lastname, _id } = el || {};
              let fisrt = firstname ? firstname.substring(0, 1) : '';
              let last = lastname ? lastname.substring(0, 1) : '';
              return (
                <>
                  <div className={`team-area ${activeStaffId === _id ? 'active' : ''}`} onClick={() => SelectedStaff(_id, firstname, lastname)}>
                    <div
                      className="first-last"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {`${fisrt}${last}`}
                    </div>
                    {firstname}
                    {' '}
                    {lastname}
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  </>
);

TeamMember.propTypes = {
  handleCloseClient: PropTypes.func.isRequired,
  setCalendarColor: PropTypes.func.isRequired,
  showTeam: PropTypes.func.isRequired,
  wrapperRef: PropTypes.func.isRequired,
  staffList: PropTypes.func.isRequired,
  activeStaff: PropTypes.func.isRequired,
  SelectedStaff: PropTypes.func.isRequired,
  handleCustomeView: PropTypes.func.isRequired,
  filterType: PropTypes.func.isRequired,
  color: PropTypes.func.isRequired,
  activeStaffId: PropTypes.func.isRequired,
  filterTime: PropTypes.func.isRequired,
  secondcolor: PropTypes.func.isRequired,
  handleLocationPopUp: PropTypes.func.isRequired,
  handleTimeSlot: PropTypes.func.isRequired,
};
export default TeamMember;
