import React, { useState, useContext, useEffect } from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import showmore from '../../assets/home/showmoreblue.png';
import EditMobilePopup from './editMobilePopup';
import EditEmailPopup from './editEmailPopup';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { removeCodeFromNumber } from '../../functions';

const SendingReminders = () => {
  const { userData } = useContext(AuthDataContext);
  const {
    invoicePhone, istwilio, invoiceEmail, isEmail, isNumberVerify, isEmailVerify,
  } = userData || {};
  // console.log('userData==>', userData);

  const [view, setView] = useState('Mobile and Email Address for Sending Invoices & Reminders');
  const [verifyMobile, setVerifyMobile] = useState(false);

  const [editMobile, setEditMobile] = useState(false);
  const [editEmail, setEditEMail] = useState(false);
  const [invoiceNumberState, setInvoiceNumberState] = useState('');
  const [countryCode, setCountryCode] = useState('+61');

  useEffect(() => {
    if (invoicePhone) {
      const { updatedNo, countryCode: code } = removeCodeFromNumber(invoicePhone);
      if (code) {
        setCountryCode(code);
      }
      setInvoiceNumberState(updatedNo);
    }
  }, [invoicePhone]);
  //   const renderView = () => {
  //     let componentToRender = null;
  //     switch (view) {
  //       case 'Appointment Summary':
  //         componentToRender = (
  //           <Appointmentsummary />
  //         );
  //         break;
  //       case 'Appointment Detail':
  //         componentToRender = (
  //           <AppointmentDetail />
  //         );
  //         break;

  //       default:
  //         break;
  //     }
  //     return componentToRender;
  //   };

  const updateView = (key) => {
    setView(key);
  };

  const handleEditMobile = (type) => {
    if (type === 'phone') {
      setEditMobile(!editMobile);
      setVerifyMobile(false);
    }
    if (type === 'email') {
      setEditEMail(!editEmail);
      setVerifyMobile(false);
    }
  };

  // useEffect(() => {
  //   if (invoicePhone) {

  //   }
  // }, [invoicePhone]);
  return (
    <div className="reports-appointment pool-set-up search-invoice sending-reminders">
      <div className="target-heading client-profile">
        <>
          <div className="">
            <h3>
              {view}
            </h3>
          </div>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => handleEditMobile('phone')}>
                      <span>Edit or Change Authorised Mobile Number</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={() => handleEditMobile('email')}>
                      <span>Edit or Change Send From Email Address</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </>
      </div>
      <div className="sending-reminders-page">
        <div className="appointment-summary">
          <Form.Group controlId="formBasicInput-3" className="number">
            <Form.Label>
              Authorised Mobile No.
            </Form.Label>
            <div className="number-field">
              {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
              <div className="number-box">
                <Dropdown id="mobile">
                  <Dropdown.Toggle variant="success" id="dropdown-basic1">
                    <div className="flag-icon">
                      <img src={(invoicePhone && invoicePhone?.includes('+1')) ? us : flag} alt="flag" />
                    </div>
                    {invoicePhone?.includes('+61') ? (
                      <span>
                        Australia
                        {' '}
                        {countryCode}
                      </span>
                    ) : invoicePhone?.includes('+1') ? (
                      <span>
                        United States
                        {' '}
                        {countryCode}
                      </span>
                    ) : ''}
                    <span>
                      <i className="fas fa-caret-down" />
                    </span>
                    {/* <div className="country-code">{selectedCountry}</div> */}
                  </Dropdown.Toggle>
                </Dropdown>
              </div>
              <NumberFormat
                className="form-control mobile-num sending-reminders-disabled"
                format={`${invoicePhone && invoicePhone.includes('+61') ? '##' : '#'} ### ### ###`}
                name="mobileNo"
                autocomplete="off"
                value={isNumberVerify ? invoiceNumberState : ''}
                disabled
              />
            </div>
          </Form.Group>
          <Form.Group controlId="formBasicCompanyType">
            <Form.Label>Authorised Email Address</Form.Label>
            <Form.Control type="email" placeholder="" name="" value={isEmailVerify ? invoiceEmail : ''} disabled />
          </Form.Group>
        </div>
        <div className="appointment-summary">
          <h6>SMS Messages & Email Settings for Authorised Mobile</h6>
          <div className="pending-water-test">
            <h3>
              Show Sent SMS Messages & Invoices on Mobile
            </h3>
            <div className="open-date client-notes">
              <div className="basic">
                <Form.Check
                  type="checkbox"
                  checked={istwilio}
                />
              </div>
            </div>
          </div>
          <div className="pending-water-test">
            <h3>
              Show Sent Payment Reminders in IMAP Email
            </h3>
            <div className="open-date client-notes">
              <div className="basic">
                <Form.Check
                  type="checkbox"
                  checked={isEmail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditMobilePopup show={editMobile} handleClose={handleEditMobile} verifyMobile={verifyMobile} setVerifyMobile={setVerifyMobile} invoicePhone={isNumberVerify ? invoicePhone : ''} istwilio={istwilio} />
      <EditEmailPopup show={editEmail} handleClose={handleEditMobile} verifyMobile={verifyMobile} setVerifyMobile={setVerifyMobile} invoiceEmail={isEmailVerify ? invoiceEmail : ''} isEmail={isEmail} />
    </div>

  );
};
export default SendingReminders;
