import React, { useState, useEffect, useContext } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import showmore from '../../assets/home/showmoreblue.png';
import target from '../../assets/home/icon/target.png';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import SendingReminders from './sendingReminders';
import './index.css';
import EmailSMSSending from './emailSMSSending';
import SMSInvoiceTemplate from './smsInvoiceTemplate';
import EmailInoivceTemplate from './emailInvoiceTemplate';
import PaymentReminder from './paymentReminders';
import InvoiceSendApproval from './invoiceSendApproval';
import { userInstance } from '../../config/axios';
import './web.css';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import Loader from '../loader/loader';

const AutomatedPayment = () => {
  const {
    userData: { calendarSettings },
  } = useContext(AuthDataContext);
  const [view, setView] = useState('reportListing');
  const [name, setName] = useState('Reports');
  const [loderStatus, setLoderStatus] = useState(false);
  const [allSettingData, setallSettingData] = useState({});
  const [loading, setLoading] = useState(false);

  const getSettingData = async () => {
    try {
      const response = await userInstance().get('/automaticInvoice/getReminderSetting');
      setLoading(false);
      const { settingData, code } = response.data;
      const copySettings = { ...settingData };
      if (copySettings.timescheduled.days === 'Everyday') {
        const newDayList = [...copySettings.timescheduled.daysList];
        const { week } = calendarSettings;
        const spliceDays = newDayList.splice(week, newDayList.length);
        const newData = [...spliceDays, ...newDayList];
        copySettings.timescheduled.daysList = newData;
      }
      if (code === 200) {
        setallSettingData(copySettings);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    getSettingData();
  }, []);

  const renderView = () => {
    let componenettorender = null;
    switch (view) {
      case 'reportListing':
        componenettorender = (
          <SendingReminders updateView={updateView} setLoderStatus={setLoderStatus} />
        );
        break;
      case 'Email & SMS Sending Days & Times':
        componenettorender = (
          <EmailSMSSending getSettingData={getSettingData} allSettingData={allSettingData} />
        );
        break;
      case 'SMS Invoice Template':
        componenettorender = (
          <SMSInvoiceTemplate getSettingData={getSettingData} allSettingData={allSettingData} />
        );
        break;
      case 'Email Invoice Template':
        componenettorender = (
          <EmailInoivceTemplate getSettingData={getSettingData} allSettingData={allSettingData} />
        );
        break;
      case 'Payment Reminder':
        componenettorender = (
          <PaymentReminder allSettingData={allSettingData} setallSettingData={setallSettingData} getSettingData={getSettingData} />
        );
        break;
      case 'Invoice Send Approval Settings':
        componenettorender = (
          <InvoiceSendApproval allSettingData={allSettingData} />
        );
        break;
      default:
        break;
    }
    return componenettorender;
  };

  const updateView = (key) => {
    setView(key);
    setName(key);
    // setLoderStatus(true);
  };
  return (
    <AdminWrapper>
      {loading ? <Loader /> : (
        <div className={`staff-container automated-payment-reminders ${view === 'Payment Reminder' ? 'payment-reminder-sending' : 'reports-page'} `}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>Automated Invoice Sending & Payment Reminders</h3>
                  <div className="content-dropdown">
                    <div className="client-option service-edit-icon">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={showmore} alt="show" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('reportListing')}>
                              <span>
                                Mobile & Email Address for
                                Sending Invoices & Reminders
                              </span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('Email & SMS Sending Days & Times')}>
                              <span>Email & SMS Sending Days & Times </span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('SMS Invoice Template')}>
                              <span>SMS Invoice Message Template </span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('Email Invoice Template')}>
                              <span>Email Invoice Template</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('Payment Reminder')}>
                              <span>Payment Reminder Sending Conditions </span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('Invoice Send Approval Settings')}>
                              <span>Invoice Send Approval Settings </span>
                            </div>
                          </Dropdown.Item>
                          {/* <Dropdown.Item>
                          <div className="action" onClick={() => updateView('Purchases')}>
                            <span>Edit or Change Authorised Mobile Number </span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('Transactions')}>
                            <span>Edit or Change Send From Email Address </span>
                          </div>
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="main-titles-desktop">
                  <div className="dropdown-option-section">
                    <div className={`option-list ${view === 'reportListing' ? 'active-menu' : ''}`} onClick={() => updateView('reportListing')}>
                      <img src={target} alt="edit" />
                      Mobile & Email Address for
                      Sending Invoices & Reminders
                    </div>
                    <div className={`option-list ${view === 'Email & SMS Sending Days & Times' ? 'active-menu' : ''}`} onClick={() => updateView('Email & SMS Sending Days & Times')}>
                      <img src={target} alt="edit" />
                      Email & SMS Sending Days & Times
                    </div>
                    <div className={`option-list ${view === 'SMS Invoice Template' ? 'active-menu' : ''}`} onClick={() => updateView('SMS Invoice Template')}>
                      <img src={target} alt="edit" />
                      SMS Invoice Message Template
                    </div>
                    <div className={`option-list ${view === 'Email Invoice Template' ? 'active-menu' : ''}`} onClick={() => updateView('Email Invoice Template')}>
                      <img src={target} alt="edit" />
                      Email Invoice Template
                    </div>
                    <div className={`option-list ${view === 'Payment Reminder' ? 'active-menu' : ''}`} onClick={() => updateView('Payment Reminder')}>
                      <img src={target} alt="edit" />
                      Payment Reminder Sending Conditions
                    </div>
                    <div className={`option-list ${view === 'Invoice Send Approval Settings' ? 'active-menu' : ''}`} onClick={() => updateView('Invoice Send Approval Settings')}>
                      <img src={target} alt="edit" />
                      Invoice Send Approval Settings
                    </div>
                  </div>
                </div>
                {renderView()}
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};
export default AutomatedPayment;
