import React, { useState, useEffect, useRef } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import StartEndDate from './startEndDate';
import crosss from '../../assets/home/black-icon.png';
import search from '../../assets/home/search.png';
import { userInstance } from '../../config/axios';
import {
  filterAppointmentObj,
} from '../../functions/index';

const Appointmentsummary = () => {
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });
  const [datePopup, setDatePopup] = useState();
  const [allLocation, setlocationList] = useState([]);
  const [allteamList, setallteamList] = useState([]);
  const [filterStatus, setfilterStatus] = useState('');
  const [totoalSummary, settotoalSummary] = useState({ totalLabour: 0, totalSale: 0 });
  const [appsummaryDate, setappsummaryDate] = useState();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const initialfilterObj = {
    location: 'alllocation',
    searchlocation: '',
    teammember: 'allteam',
    searchteammember: '',
    locationId: '',
    teammemberId: '',
    locationStatus: false,
    teamStatus: false,
    datetype: 'today',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
  };
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  const handleDateRange = (start, end) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.rangeStartDate = start;
    copyfilterObj.rangeEndDate = end;
    setFilterObj(copyfilterObj);
    setfilterStatus(start);
    setDatePopup(false);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    setfilterStatus(value);
    let copyfilterObj = { ...filterObj, [name]: value };
    setFilterObj(copyfilterObj);
  };
  const removeSearchText = (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj[value] = '';
    if (value === 'searchlocation') {
      copyfilterObj.locationId = '';
    } else {
      copyfilterObj.teammemberId = '';
    }
    setFilterObj(copyfilterObj);
  };
  const filterAppointment = async () => {
    const payload = filterAppointmentObj(filterObj);
    setappsummaryDate(payload.summaryDate);
    setLoading(true);
    const response = await userInstance().post(
      '/appointment/filterAppointmentSummary',
      {
        filterObj, payload,
      },
    );
    setLoading(false);
    const {
      code, summaryResult, alltotalCost, alltotalLabour,
    } = response.data;
    let copySummary = { ...totoalSummary };
    copySummary.totalLabour = alltotalLabour;
    copySummary.totalSale = alltotalCost;
    settotoalSummary(copySummary);
    if (code === 200) {
      setDataList(summaryResult);
    }
  };
  const searchLocation = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchlocation = value;
    copyfilterObj.locationStatus = true;
    setFilterObj(copyfilterObj);
    const response = await userInstance().get(
      `/appointment/filterLocation?location=${value}`,
    );
    const { locationList, code } = response.data;
    if (code === 200) {
      setlocationList(locationList);
    }
  };
  const selectValue = (name, value, id) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj[name] = value;
    setfilterStatus(value);
    if (name === 'searchlocation') {
      copyfilterObj.locationId = id;
      copyfilterObj.locationStatus = false;
    } else {
      copyfilterObj.teammemberId = id;
      copyfilterObj.teamStatus = false;
    }
    setFilterObj(copyfilterObj);
  };
  const searchTeammember = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchteammember = value;
    copyfilterObj.teamStatus = true;
    setFilterObj(copyfilterObj);
    const response = await userInstance().get(
      `/appointment/filterTeammember?team=${value}&location=${filterObj.locationId}&locationtype=${filterObj.location}`,
    );
    const { teamList, code } = response.data;
    if (code === 200) {
      setallteamList(teamList);
    }
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setlocationList(null);
          let copyfilterObj = { ...filterObj };
          copyfilterObj.teamStatus = false;
          setFilterObj(copyfilterObj);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const calculateMutiCost = (allitem) => {
    const totalCost = allitem.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.totalData ? next.totalData.totalLabour : 0),
      0,
    );
    const totalSellCost = allitem.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.Invoice ? next.Invoice.invoiceTotal : 0),
      0,
    );
    return { totalCost: parseFloat(totalCost).toFixed(2), totalSellCost: parseFloat(totalSellCost).toFixed(2) };
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    filterAppointment(filterStatus);
  }, [filterStatus]);
  return (
    <div className="appointment-summary">
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Location</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="location">
            <option value="alllocation">All Locations</option>
            <option value="specificlocations">Specific Locations</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.location === 'specificlocations' ? (
        <div className="search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchlocation" onChange={(e) => searchLocation(e.target.value)} value={filterObj.searchlocation} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchlocation')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.locationStatus && (
          <>
            {allLocation && allLocation.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {allLocation.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el.locationName}
                    key={el._id}
                    onClick={() => selectValue('searchlocation', el.locationName, el._id)}
                  >
                    {el.locationName}
                  </li>
                ))}
              </ul>
            ) : allLocation && !allLocation.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Team Member</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="teammember">
            <option value="allteam">All Team Members</option>
            <option value="specificteam">Specific Team Members</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.teammember === 'specificteam' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchteammember" onChange={(e) => searchTeammember(e.target.value)} value={filterObj.searchteammember} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchteammember')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.teamStatus && (
          <>
            {allteamList && allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {allteamList.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el._id}
                    key={el._id}
                    onClick={() => selectValue('searchteammember', `${el.firstname} ${el.lastname}`, el._id)}
                  >
                    {`${el.firstname} ${el.lastname}`}
                  </li>
                ))}
              </ul>
            ) : allteamList && !allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            value={filterObj.datetype}
            onChange={(e) => handleOnchange(e.target)}
            name="datetype"
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
            <option value="lastweek">Last Week</option>
            <option value="lastyear">Last Year</option>
            <option value="nextweek">Next Week</option>
            <option value="nextyear">Next Year</option>
            <option value="weekdate">Week to Date</option>
            <option value="monthdate">Month to Date</option>
            <option value="yeardate">Year to Date</option>
            <option value="daterange">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>

      {filterObj.datetype === 'daterange' ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>
              {`${moment(filterObj.rangeStartDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">
              {' '}
              {`${moment(filterObj.rangeEndDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
        </div>
      ) : (
        ''
      )}

      <div className="appointment-summary-section">
        <p>Appointment Summary</p>
        {/* <p>Mon, 16 Aug 2021 - Mon, 18 Aug 2021</p> */}
        <p>{appsummaryDate}</p>
        <hr />
        <div className="summary-table">
          <div className="summary-table-header">
            <h6>Team Member</h6>
            <h6>No. of Appts</h6>
            <h6>Labour & Margin</h6>
            <h6>Total Sales</h6>
          </div>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {dataList.length > 0
                ? dataList.map((el, index) => {
                  const {
                    firstname, lastname, appcount, totalCost, totalLabour,
                  } = el || {};
                  return (
                    <div className="summary-table-body">
                      <p>
                        {firstname}
                        {' '}
                        {lastname}
                      </p>
                      <p>{appcount}</p>
                      <p>{`$${(totalLabour || 0).toFixed(2)}`}</p>
                      <p>{`$${(totalCost || 0).toFixed(2)}`}</p>
                    </div>
                  );
                })
                : (
                  <div>
                    <p>
                      No Records Found
                    </p>
                  </div>
                )}
              <hr />
              <div className="summary-table-body total-section">
                <p>Period Total:</p>
                <p />
                <p>{`$${totoalSummary.totalLabour.toFixed(2)}`}</p>
                <p>{`$${totoalSummary.totalSale.toFixed(2)}`}</p>
              </div>
            </>
          )}

        </div>
      </div>

      {/* <div className="appointment-summary-section">
        <p>Appointment Summary</p>
        <p>Mon, 16 Aug 2021 - Mon, 18 Aug 2021</p>
        <hr />
        <div className="summary-table">
          <div className="summary-table-header">
            <h6>Team Member</h6>
            <h6>No. of Appts</h6>
            <h6>Labour & Margin</h6>
            <h6>Total Sales</h6>
          </div>
          <div className="summary-table-body">
            <p>Team Member</p>
            <p>13</p>
            <p>$12,233.00</p>
            <p>$12,233.00</p>
          </div>
          <div className="summary-table-body">
            <p>Team Member</p>
            <p>13</p>
            <p>$12,233.00</p>
            <p>$12,233.00</p>
          </div>
          <div className="summary-table-body">
            <p>Team Member</p>
            <p>13</p>
            <p>$12,233.00</p>
            <p>$12,233.00</p>
          </div>
          <div className="summary-table-body">
            <p>Team Member</p>
            <p>13</p>
            <p>$12,233.00</p>
            <p>$12,233.00</p>
          </div>
          <hr />
          <div className="summary-table-body total-section">
            <p>Period Total:</p>
            <p />
            <p>$12,233.00</p>
            <p>$12,233.00</p>
          </div>
        </div>
      </div> */}
      <StartEndDate datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} handleDateOnchange={handleDateOnchange} dateRange={dateRange} handleDateRange={handleDateRange} />
    </div>
  );
};
export default Appointmentsummary;
