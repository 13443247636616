import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import StartEndDate from './startEndDate';
import crosss from '../../assets/home/black-icon.png';
import search from '../../assets/home/search.png';
import { userInstance } from '../../config/axios';

const TransactionSummary = ({ locationData }) => {
  const [dateRange, setDateRange] = useState();
  const [clients, setClients] = useState();
  const [datePopup, setDatePopup] = useState();
  const [teamMemberData, setTeamMemberData] = useState([]);
  const handleDateRange = (e) => {
    setDateRange(e.target.value);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleClients = (e) => {
    setClients(e.target.value);
  };

  const initialfilterObj = {
    // location: '',
    searchlocation: '',
    // teammember: 'allteam',
    // searchteammember: '',
    locationId: 'alllocation',
    teammemberId: 'allteam',
    locationStatus: false,
    teamStatus: false,
    datetype: 'today',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    // customer: 'allCustomer',
    // searchcustomer: '',
    // customerStatus: false,
    // customerId: '',
    payment: 'all',
  };

  const [filterObj, setFilterObj] = useState(initialfilterObj);
  const [allLocation, setlocationList] = useState([]);
  const [filterStatus, setfilterStatus] = useState('');
  // const [locationData, setLocationData] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     const response = await userInstance().get(
  //       '/appointment/getLocationList',
  //     );
  //     const { locationList, code } = response.data;
  //     locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
  //     if (code === 200) {
  //       setLocationData([...locationList]);
  //     }
  //   })();
  // }, []);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setlocationList(null);
          let copyfilterObj = { ...filterObj };
          copyfilterObj.teamStatus = false;
          setFilterObj(copyfilterObj);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const searchLocation = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.locationStatus = true;
    copyfilterObj.searchlocation = value;
    // console.log(copyfilterObj);
    setFilterObj(copyfilterObj);

    const response = await userInstance().get(
      `/appointment/filterLocation?searchKeyword=${value}`,
    );
    const { locationList, code } = response.data;

    if (code === 200) {
      setlocationList(locationList);
    }
  };
  const selectValue = (name, value, id) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj[name] = value;
    setfilterStatus(value);
    if (name === 'searchcustomer') {
      copyfilterObj.customerId = id;
      copyfilterObj.customerStatus = false;
    } else if (name === 'searchlocation') {
      copyfilterObj.locationId = id;
      copyfilterObj.locationStatus = false;
    } else {
      copyfilterObj.teammemberId = id;
      copyfilterObj.teamStatus = false;
    }
    setFilterObj(copyfilterObj);
  };
  const handleRemove = () => {
    setfilterStatus('');
    setFilterObj({ ...filterObj, locationId: '', searchlocation: '' });
  };

  //
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    setfilterStatus(value);
    if (name === 'teammemberId' && value !== '') {
      const getLocationId = teamMemberData.filter((el) => el._id === value);
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = value;
      copyfilterObj.locationId = getLocationId[0].location;
      setFilterObj(copyfilterObj);
    } else if (name === 'locationId' && value === '') {
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = '';
      copyfilterObj.locationId = value;
      setFilterObj(copyfilterObj);
    } else {
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await userInstance().get(
        '/appointment/getTeamMemberList',
      );
      const { teamList, code } = response.data;
      teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
      if (code === 200) {
        setTeamMemberData(teamList);
        // setfilterStatus(teamList);
      }
    })();
  }, []);

  return (

    <div className="appointment-summary">
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Transaction Analysis</Form.Label>
        <div className="select-box">
          <Form.Control as="select">
            <option value="Architect's Company">All Services & Products</option>
            <option value="Architect's Company">All Services</option>
            <option value="Architect's Company">All Products</option>
            <option value="Architect's Company">Specific Service</option>
            <option value="Architect's Company">Specific  Products</option>
            <option value="Architect's Company">Invoices</option>
            <option value="Architect's Company">Invoices changed after being saved Payments</option>
            <option value="Architect's Company">Payments changed after being saved Purchases</option>
            <option value="Architect's Company">Purchases changed after being saved Inventory</option>
            <option value="Architect's Company">inventory changed after being saved</option>
          </Form.Control>
        </div>
      </Form.Group>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Clients</Form.Label>
        <div className="select-box">
          <Form.Control as="select">
            <option value="All Clients">All Clients</option>
            <option value="Specific clients">Specific clients</option>
          </Form.Control>
        </div>
      </Form.Group>

      {!(locationData.length > 10) ? (
        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Location</Form.Label>
          <div className="select-box">
            <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="locationId" value={filterObj.locationId}>
              <option value="">All Locations</option>
              {locationData.map((el) => <option value={el._id}>{el?.locationName}</option>)}
            </Form.Control>
          </div>
        </Form.Group>
      ) : null}
      {locationData.length > 10 ? (
        <div className="search-box search-text search-summary">
          <h6>Location</h6>
          <Form.Group className="search-box">
            <Form.Control
              type="text"
              placeholder="Search by name, mobile, phone or address"
              name="searchlocation"
              onChange={(e) => searchLocation(e.target.value)}
              value={filterObj.searchlocation}
              autoComplete="off"
            />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={handleRemove}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.locationStatus && (
            <>
              {allLocation && allLocation.length ? (
                <ul className="auto-complete" ref={wrapperRef}>
                  {allLocation.map((el) => (
                    <li
                      className="auto-complete-list"
                      value={el.locationName}
                      key={el._id}
                      onClick={() => selectValue('searchlocation', el.locationName, el._id)}
                    >
                      {el.locationName}
                    </li>
                  ))}
                </ul>
              ) : allLocation && !allLocation.length ? (
                <ul className="auto-complete" ref={wrapperRef}>
                  <li>No Results Found</li>
                </ul>
              ) : null}
            </>
          )}
        </div>
      ) : null}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Team Member</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="teammemberId" value={filterObj.teammemberId}>
            <option value="">All Team Members</option>
            {teamMemberData.map((el) => (
              <>
                {filterObj.locationId === '' ? <option value={el._id}>{`${el?.firstname} ${el?.lastname}`}</option> : filterObj?.locationId === el?.location && <option value={el._id}>{`${el?.firstname} ${el.lastname}`}</option>}
              </>
            ))}
          </Form.Control>
        </div>
      </Form.Group>
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control as="select" value={dateRange} onChange={(e) => handleDateRange(e)}>
            <option value="Today">Today</option>
            <option value="Yesterday">Yesterday</option>
            <option value="Tomorrow">Tomorrow</option>
            <option value="This Week">This Week</option>
            <option value="This Month">This Month</option>
            <option value="This Year">This Year</option>
            <option value="Last Week">Last Week</option>
            <option value="Last Year">Last Year</option>
            <option value="Next Week">Next Week</option>
            <option value="Next Year">Next Year</option>
            <option value="Week to Date">Week to Date</option>
            <option value="Month to Date">Month to Date</option>
            <option value="Year to Date">Year to Date</option>
            <option value="Date Range">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>
      {dateRange === 'Date Range' ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>Mon, 16 Aug 2021</div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">Mon, 16 Aug 2021</div>
          </Form.Group>
        </div>
      ) : ''}

      <div className="appointment-summary-section">
        <p>Transaction Summary</p>
        <p>Mon, 16 Aug 2021 - Mon, 18 Aug 2021</p>
        <hr />
        <div className="details-table">
          <div className="details-body">
            <h6>Service or Product Sales</h6>
            <p>Viron P320 Variable Speed Pump</p>
          </div>
          <div className="details-body">
            <h6>Clients</h6>
            <p>All Clients</p>
          </div>
          <div className="details-body">
            <h6>Branch Location</h6>
            <p>12, Evansdale Road, Howthorn VIC 3122</p>
          </div>
          <div className="details-body">
            <h6>Team Member</h6>
            <p>Mano Gaurav</p>
          </div>
          <div className="details-body">
            <h6>Sales</h6>
            <p>215</p>
          </div>
          <div className="details-body">
            <h6>Average Labour Sell Price</h6>
            <p>$545.00</p>
          </div>
          <div className="details-body">
            <h6>Average Product Buy cost</h6>
            <p>$545.00</p>
          </div>
          <hr />
          <div className="details-body period-total">
            <h6>Period Sales Total</h6>
            <h6>$125.00</h6>
          </div>
          <div className="details-body period-total-other">
            <h6>Period Margin Total</h6>
            <h6>$125.00</h6>
          </div>
        </div>
      </div>
      <StartEndDate datePopup={datePopup} setDatePopup={setDatePopup} />
    </div>
  );
};

TransactionSummary.propTypes = {
  locationData: PropTypes.arrayOf.isRequired,
};
export default TransactionSummary;
