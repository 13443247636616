import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import {
  getCalendarPath,
} from '../../functions/index';

const ConfirmPopupApp = ({
  setOpenConfirmBox,
  handleEditAppointment,
  singleMsg,
  notifyCustomer, setNotifyCustomer, type, groupAppointments,
}) => {
  const history = useHistory();
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal'
          ) {
            setOpenConfirmBox(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [addappt, setAddAppt] = useState('current');
  const handleChange = (e) => {
    setAddAppt(e.target.value);
  };
  const handleChange1 = (value) => {
    setAddAppt(value);
  };
  const handleCancel = () => {
    setOpenConfirmBox(false);
    history.push(getCalendarPath());
  };
  // console.log('singleMsg =================>', { singleMsg, groupAppointments });
  return (
    <div className="duration-picker time-picker-modal">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          {type === 'edit'
            ? (
              <div className="appointment-view">
                <div className="pending-water-test" onClick={() => handleChange1('current')}>
                  <h3>Update This Appointment Only</h3>
                  <div className="open-date client-notes">
                    <div className="basic">
                      <Form.Check
                        type="checkbox"
                        value="current"
                        onChange={handleChange}
                        checked={addappt === 'current'}
                      />
                    </div>
                  </div>
                </div>
                <div className="pending-water-test" onClick={() => handleChange1('upcomming')}>
                  <h3>Update All Upcoming Appointments</h3>
                  <div className="open-date client-notes">
                    <div className="basic">
                      <Form.Check
                        type="checkbox"
                        value="upcomming"
                        onChange={handleChange}
                        checked={addappt === 'upcomming'}
                      />
                    </div>
                  </div>
                </div>
                <div className="event-popup-btn footer-btn view-btn">
                  <Button
                    className="appointment-btn btn-edit"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="appointment-btn btn-save"
                    onClick={() => handleEditAppointment(addappt)}
                  >
                    OK
                  </Button>
                </div>
              </div>
            )
            : (
              <div className="appointment-view">
                {singleMsg || (groupAppointments.length > 1 && (groupAppointments[0].appointment_calc_type === 'Black-Spot Algae' || groupAppointments[0].appointment_calc_type === 'Green Pool' || groupAppointments[0].appointment_calc_type === 'Drain Pool' || groupAppointments[0].appointment_calc_type === 'Water Testing' || groupAppointments[0].appointment_calc_type === 'Sand Filter Media Replacement')) ? (
                  <>
                    <div className="pending-water-test" onClick={() => handleChange1('current')}>
                      <h3>Reschedule this Appointment Only</h3>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            value="current"
                            onChange={handleChange}
                            checked={addappt === 'current'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="pending-water-test" onClick={() => handleChange1('upcomming')}>
                      <h3>Reschedule All Appointments in this Set</h3>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            value="upcomming"
                            onChange={handleChange}
                            checked={addappt === 'upcomming'}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="pending-water-test" onClick={() => handleChange1('current')}>
                      <h3>Reschedule  Appointment </h3>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            value="current"
                            onChange={handleChange}
                            checked={addappt === 'current'}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="pending-water-test" onClick={() => setNotifyCustomer(!notifyCustomer)}>
                  <h3>Notify Customer of Reschedule </h3>
                  <div className="open-date client-notes">
                    <div className="basic">
                      <Form.Check type="checkbox" checked={notifyCustomer} onChange={(e) => setNotifyCustomer(e.target.checked)} />
                    </div>
                  </div>
                </div>
                <div className="event-popup-btn footer-btn view-btn">
                  <Button
                    className="appointment-btn btn-edit"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    className="appointment-btn btn-save"
                    onClick={() => handleEditAppointment(addappt)}
                  >
                    OK
                  </Button>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
ConfirmPopupApp.propTypes = {
  setOpenConfirmBox: PropTypes.func.isRequired,
  handleEditAppointment: PropTypes.func.isRequired,
  singleMsg: PropTypes.string.isRequired,
  setNotifyCustomer: PropTypes.func.isRequired,
  notifyCustomer: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  groupAppointments: PropTypes.arrayOf.isRequired,
};
export default ConfirmPopupApp;
