import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Table } from 'react-bootstrap';

const ViewCsv = ({
  csvDataToRender, closeViewModal, resultJS, setResultJS, setShowViewCsvPopupTable, viewType,
}) => {
  const [checkData, setCheckData] = useState({});
  const handleCheckBox = (e, index) => {
    const old = [...csvDataToRender];
    const changeKeys = old[index];
    let newArr = [];
    changeKeys.rowsAddedId.forEach((el) => {
      let obj = el;
      delete obj.sort;
      let entries = Object.entries(obj);
      let fromIndex;
      let toIndex;
      let capsEntries = entries.map((entry, i) => {
        let keyValues = entry;
        if (keyValues[0] === 'poolHeight') {
          keyValues = ['PoolLength', entry[1]];
        } else if (keyValues[0] === 'customerType') {
          keyValues = ['ContactType', entry[1]];
          toIndex = i + 1;
        } else if (keyValues[0] === 'companyName') {
          keyValues = ['Company Name', entry[1]];
        } else if (keyValues[0] === 'country') {
          keyValues = ['Country', entry[1]];
        } else if (keyValues[0] === 'firstname') {
          keyValues = ['ContactsFirst Name', entry[1]];
        } else if (keyValues[0] === 'lastname') {
          keyValues = ['ContactsLast Name', entry[1]];
        } else if (keyValues[0] === 'mobileNo') {
          keyValues = ['ContactsMobile Number', entry[1]];
        } else if (keyValues[0] === 'landlineNo') {
          keyValues = ['ContactsLandlineNo', entry[1]];
        } else if (keyValues[0] === 'state') {
          keyValues = ['State', entry[1]];
        } else if (keyValues[0] === 'city') {
          keyValues = ['City', entry[1]];
        } else if (keyValues[0] === 'suburb') {
          keyValues = ['City/Town/Suburb', entry[1]];
        } else if (keyValues[0] === 'poolWidth') {
          keyValues = ['Pool Width', entry[1]];
        } else if (keyValues[0] === 'streetAddress') {
          keyValues = ['Street Address', entry[1]];
        } else if (keyValues[0] === 'poolFinish') {
          keyValues = ['PoolFinish', entry[1]];
        } else if (keyValues[0] === 'chlorinationType') {
          keyValues = ['ChlorinationType', entry[1]];
        } else if (keyValues[0] === 'chlorination') {
          keyValues = ['Chlorination', entry[1]];
        } else if (keyValues[0] === 'invoiceSendType') {
          keyValues = ['AutomaticInvoiceSending', entry[1]];
        } else if (keyValues[0] === 'gender') {
          keyValues = ['Gender', entry[1]];
        } else if (keyValues[0] === 'note') {
          keyValues = ['ClientNotes', entry[1]];
        } else if (keyValues[0] === 'postcode') {
          keyValues = ['Postal / Zip Code', entry[1]];
        } else if (keyValues[0] === 'companyName') {
          fromIndex = i;
        }
        return [
          keyValues[0][0].toUpperCase() + keyValues[0].slice(1),
          keyValues[1],
        ];
      });
      const element = capsEntries.splice(fromIndex, 1)[0];
      capsEntries.splice(toIndex, 0, element);
      let capsPopulations = Object.fromEntries(capsEntries);
      newArr.push(capsPopulations);
    });
    changeKeys.rowsAddedId = newArr;
    setCheckData(old[index]);
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal download-csv-popup'
          ) {
            closeViewModal();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const viewCsv = async () => {
    const selectedCsv = checkData.rowsAddedId;
    let headings = [];
    let dataArray = [];
    if (viewType === 'customer') {
      // eslint-disable-next-line prefer-destructuring
      dataArray = selectedCsv;
      headings = Object.keys(dataArray.length > 0 && dataArray[0]).filter(
        (c) => c !== 'Appointments' && c !== 'AssociatedContacts' && c !== 'SiteAddresses' && c !== 'ChlorinatorModel' && c !== 'SelectedModelObj' && c !== '_id' && c !== 'Userid' && c !== '__v' && c !== 'ShowBookings' && c !== 'CreatedAt' && c !== 'UpdatedAt' && c !== 'ContactId' && c !== 'CsvName',
      );
    } else if (viewType === 'service') {
      dataArray = selectedCsv.map((el) => {
        el.SubCategory = el?.SubCategory.filter((ele) => ele?.csvName === checkData?.name);
        return el.SubCategory;
      }).flat(Infinity);
      headings = Object.keys(dataArray.length > 0 && dataArray[0]).filter(
        (c) => c !== 'csvName' && c !== 's_uId' && c !== 'soldPer',
      );
    } else if (viewType === 'product') {
      dataArray = selectedCsv;
      headings = Object.keys(dataArray.length > 0 && dataArray[0]).filter(
        (c) => c !== 'csvName' && c !== 's_uId' && c !== 'addjustmentRecord' && c !== 'inventoryId' && c !== 'industryId' && c !== 'staffStockData' && c !== 'updatedAt' && c !== 'userId' && c !== 'updatedAt' && c !== 'createdAt' && c !== '_id' && c !== '__v'
        && c !== 'staffStockData' && c !== 'currentStockItems',
      );
    } else if (viewType === 'inventory') {
      dataArray = selectedCsv;
      headings = Object.keys(dataArray.length > 0 && dataArray[0]).filter(
        (c) => c !== 'csvName' && c !== 's_uId' && c !== 'addjustmentRecord' && c !== 'inventoryId' && c !== 'industryId' && c !== 'staffStockData' && c !== 'updatedAt' && c !== 'userId' && c !== 'updatedAt' && c !== 'createdAt' && c !== '_id' && c !== '__v',
      );
    } else if (viewType === 'staff') {
      dataArray = selectedCsv;
      headings = Object.keys(dataArray.length > 0 && dataArray[0]).filter(
        (c) => c !== 'firstLogin' && c !== 'shift' && c !== 'hide' && c !== 'isOwner' && c !== 'isDelete' && c !== 'appointments' && c !== 'blocktimes' && c !== 'istwilio' && c !== 'isNumberVerify' && c !== 'isEmail' && c !== 'isEmailVerify' && c !== 'chemicalsToDisplay' && c !== 'userid' && c !== 'password' && c !== 'role' && c !== 'hexToken' && c !== 'industryId' && c !== 'createdAt' && c !== 'updatedAt' && c !== '__v' && c !== '_id' && c !== 'csvName',
      );
    }
    // console.log('dataArray--', dataArray);

    if (headings && headings.length > 0) {
      const result = (
        <>
          <Table responsive>
            <tr>
              <>
                {headings && headings.map((el) => (
                  <>
                    <th>{el.toUpperCase()}</th>
                  </>
                ))}
              </>
            </tr>

            {dataArray && dataArray.map((el) => (
              <tr>
                {headings && headings.map((elem) => (
                  <td>{el[elem] ?? 'EMPTY'}</td>
                ))}
              </tr>
            ))}

          </Table>
        </>
      );
      setResultJS(result);
      closeViewModal();
      setShowViewCsvPopupTable(true);
    }
    // window.open(selectedCsv.csvLink, '_blank');
  };

  return (
    <div className="duration-picker time-picker-modal download-csv-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <h6>Select a CSV to View</h6>
          <div className="appointment-view">
            {csvDataToRender && csvDataToRender.length > 0 ? (
              <>
                {csvDataToRender.map((el, i) => (
                  <>
                    <div className="pending-water-test">
                      <h3>{el?.name}</h3>
                      <div className="open-date client-notes">
                        <div className="basic">
                          <Form.Check
                            type="checkbox"
                            value={el?._id}
                            onChange={(e) => handleCheckBox(e, i)}
                            checked={el._id === checkData._id || false}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                <div>No CSV to View</div>
              </>
            )}

            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => closeViewModal()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save"
                onClick={viewCsv}
              >
                Select
              </Button>
              {resultJS}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ViewCsv.propTypes = {
  csvDataToRender: PropTypes.arrayOf.isRequired,
  closeViewModal: PropTypes.func.isRequired,
  setResultJS: PropTypes.func.isRequired,
  setShowViewCsvPopupTable: PropTypes.func.isRequired,
  resultJS: PropTypes.objectOf.isRequired,
  viewType: PropTypes.string.isRequired,
};
export default ViewCsv;
