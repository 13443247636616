import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

const ColorPopup = ({ handleColorPopup, setColor, color }) => {
  const [saveColor, setSaveColor] = useState(color);

  const handleChange = (e) => {
    setSaveColor(e.hex);
  };
  const handleSave = () => {
    setColor(saveColor);
    handleColorPopup();
  };
  return (
    <div
      className="duration-picker time-picker-modal update-source-popup color-picker-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup">
          <div className="appointment-view">
            <ChromePicker
              onChange={(e) => handleChange(e)}
              placement="right"
              color={saveColor}
            />
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={handleColorPopup}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={handleSave}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ColorPopup;
ColorPopup.defaultProps = {
  color: 'red',
};

ColorPopup.propTypes = {
  handleColorPopup: PropTypes.func.isRequired,
  setColor: PropTypes.func.isRequired,
  color: PropTypes.string,
};
