import React, { useState, useEffect } from 'react';
import {
  Button, Dropdown, Form,
} from 'react-bootstrap';
// import FileViewer from 'react-file-viewer';
import Preview from 'preview-office-docs';
import { userInstance } from '../../config/axios';
import showmore from '../../assets/home/showmoreblue.png';
import toastMessage from '../../functions/toastMessage';
import SaveTemplatePopup from '../quoteTemplates/savetemplatePopup';
import UploadDocx from './uploadDocx';
import PreviewDocxTemp from './previewDocxTemp';

const DocxInvoiceTemplate = () => {
  const [savePopup, setSavePopup] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [tempList, setTempList] = useState([]);
  const [view, setView] = useState('defaultdocxTemplate');
  const [isloading, setIsloading] = useState(false);
  const [activeTemp, setActiveTemp] = useState('https://calendar-pro.s3.amazonaws.com/Invoices/invoice.docx');
  const [uploadPopup, setUploadPopup] = useState(false);
  const [selectedImgData, setSelectedImgData] = useState({});
  const [ispreview, setIspreview] = useState(false);

  const onError = (e) => {
    console.log(e, 'error in file-viewer');
  };

  const handleDownload = () => {
    const url = activeTemp;
    const link = document.createElement('a');
    link.href = url;
    // link.download = 'filename767676.docx';
    link.click();
  };

  const handleDataPopup = (name) => {
    setTemplateName(name);
  };
  const saveTemplate = async () => {
    try {
      setIsloading(true);
      const response = await userInstance().post('/invoice/downloadInvoicedocx', { templateName });
      const { code } = response?.data || {};
      if (code === 200) {
        setIsloading(false);
        toastMessage('success', 'Created successfully');
        setSavePopup(false);
        getAllTemplate();
      }
    } catch (e) {
      setIsloading(false);
      console.log('docx error=>', e);
    }
  };

  const getAllTemplate = async () => {
    try {
      const response = await userInstance().get('/template/getAllTemplate?type=docx');
      const { code, msg, temp } = response.data;
      if (msg === 'Success') {
        if (temp && temp?.length > 0) {
          setActiveTemp(temp[0]?.docxurl);
        }
        setTempList(temp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllTemplate();
  }, []);

  const updateView = (type) => {
    if (type === 'adddocxTemplate') {
      setSavePopup(true);
    }
    if (type === 'listdocxTemplate' || type === 'defaultdocxTemplate') {
      getAllTemplate();
    }
    setView(type);
  };
  const handleOnchange = (e) => {
    setActiveTemp(e.target.value);
  };
  const handleUpload = () => {
    console.log('upload test =');
    setUploadPopup(true);
  };
  const handleImagePopup = () => {
    setUploadPopup(false);
    selectedImgData({});
  };
  const uploadDocx = async () => {
    try {
      setIsloading(true);
      console.log('test =>');
      const formdata = new FormData();
      formdata.append('file', selectedImgData);
      const result = await userInstance().post('/invoice/uploadDocx', formdata);
      const { code } = result?.data;
      console.log('result =>', result);
      // const { imageUrl } = result.data;
      if (code === 200) {
        toastMessage('success', 'Upload Successfully');
        setUploadPopup(false);
        setIsloading(false);
        getAllTemplate();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePreview = () => {
    setIspreview(!ispreview);
    // ispreview, setIspreview
  };

  return (
    <>
      <div className="target-heading client-profile">

        <div className="invoice-header">
          <h3>
            {view === 'defaultdocxTemplate' ? 'Default Invoice Templates (.docx)' : ''}
            {view === 'adddocxTemplate' ? 'Add New Invoice Template (.docx)' : ''}
            {view === 'listdocxTemplate' ? 'Saved  Invoice Template (.docx)' : ''}
          </h3>
        </div>
        <div className="content-dropdown">
          <div className="client-option service-edit-icon">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={showmore} alt="show" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <div className="action" onClick={() => updateView('defaultdocxTemplate')}>
                    <span>Default Invoice Template (.docx)</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="action" onClick={() => updateView('adddocxTemplate')}>
                    <span>Add Invoice Template (.docx)</span>
                  </div>
                </Dropdown.Item>

                <Dropdown.Item>
                  <div className="action" onClick={() => updateView('listdocxTemplate')}>
                    <span>Saved Invoice Templates (.docx)</span>
                  </div>
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

      </div>
      {view === 'listdocxTemplate' && (
      <div className="target-heading client-profile">

        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>
            Template Name
          </Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              name="customerType"
              value={activeTemp}
              onChange={(e) => handleOnchange(e)}
            >
              {tempList && tempList.map((temp) => (
                <option value={temp.docxurl}>
                  {temp.templateName}
                  {temp.default ? '-default' : ''}
                </option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

      </div>
      )}

      <div className="edit-template-page" key={activeTemp}>

        <Preview
          url={activeTemp}
          height="500px"
          width="100%"
        />
        <div className="template-btn view-btn">
          <div className="client-option service-edit-icon">

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                View Other Settings
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <div className="action" onClick={() => handlePreview()}>
                    Preview Template
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDownload()}>
                  <div className="action">
                    Download Template
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="action" onClick={() => handleUpload()}>
                    Upload Template
                  </div>
                </Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>

          </div>
          <Button className="save-btn">Save/Update</Button>
        </div>
        {savePopup && (
        <SaveTemplatePopup
          savePopup={savePopup}
          setSavePopup={setSavePopup}
          handleDataPopup={handleDataPopup}
          templateName={templateName}
          saveTemplate={saveTemplate}
          view={view}
          isloading={isloading}
        />
        )}

        {uploadPopup && (
        <UploadDocx
          showPopup={uploadPopup}
          handleDataPopup={handleDataPopup}
          isloading={isloading}
          handleImagePopup={handleImagePopup}
          setSelectedImgData={setSelectedImgData}
          uploadDocx={uploadDocx}
        />
        )}
        {ispreview && (
          <PreviewDocxTemp handlePreviewPopup={handlePreview} previewPopup={ispreview} url={activeTemp} onError={onError} />
        )}

      </div>
    </>
  );
};
DocxInvoiceTemplate.propTypes = {};
export default DocxInvoiceTemplate;
