/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import Draggable, { DraggableCore } from 'react-draggable';
import PropTypes from 'prop-types';
import './template.css';
import getStrNum from '../../functions/strNumseparator';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { TemplateFeatures } from '../../contexts/TemplateFeatureContext';

let startPos = {
  x: 0,
  y: 0,
};
let stopPos = {
  x: 0,
  y: 0,
};
const TestingTemplate = ({
  templateData,
  handleText,
  handleImagePopupOpen,
  customFeilds,
  handleTextNew,
  handleImageNew,
  handleMoveText,
  handleTextNewMove,
  view,
}) => {
  const { userData } = useContext(AuthDataContext);
  const {
    setIsNewChange,
  } = useContext(TemplateFeatures);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';
  // console.log(countryType, 'countryTypecountryType');
  const [arrayOne, setArrayOne] = useState(templateData);
  const [activeDrags, setActiveDrag] = useState(0);

  useEffect(() => {
    setArrayOne(templateData);
  }, [templateData]);
  const onStart = (_, data) => {
    setActiveDrag(activeDrags + 1);
    startPos = { ...data };
  };

  const eventLogger = (
    e,
    data,
    style,
    text,
    index,
    i,
    placeholder,
    tdStyle,
  ) => {
    let distx = data.x - startPos.x;
    let disty = data.y - startPos.y;
    let dist = Math.hypot(distx, disty);
    if (dist < 5) {
      return handleText(
        style,
        text,
        index,
        i,
        placeholder,
        tdStyle,
        e,
      );
    }
    setIsNewChange(true);
    return handleMoveText(style, text, index, i, placeholder, tdStyle, data.y, data.x);
  };
  const eventLoggerImg = (
    e,
    data,
    style,
    text,
    index,
    i,
    placeholder,
    tdStyle,
  ) => {
    let distx = data.x - startPos.x;
    let disty = data.y - startPos.y;
    let dist = Math.hypot(distx, disty);
    if (dist < 5) {
      return handleImagePopupOpen(
        index,
      );
    }
    setIsNewChange(true);
    return handleMoveText(style, text, index, i, placeholder, tdStyle, data.y, data.x);
  };
  const eventLogger1 = (
    e,
    data,
    style,
    text,
    index,
  ) => {
    let distx = data.x - startPos.x;
    let disty = data.y - startPos.y;
    let dist = Math.hypot(distx, disty);
    if (dist < 5) {
      return handleTextNew(
        style,
        text,
        index,
        e,
      );
    }
    setIsNewChange(true);
    return handleTextNewMove(style, text, index, data.y, data.x);
  };
  const htmlJson = {
    tr1: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events.map((el) => (
              <>
                {!!el?.text && (
                  <span
                    className="test"
                    style={{ ...el.style }}
                    role="presentation"
                  >
                    {el.text}
                  </span>
                )}
              </>
            ))}
          </div>
        </Draggable>
      </div>
    ),
    tr2: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events.map((el) => (
              <>
                {el?.text && (
                  <span
                    style={{
                      ...el.style,
                    }}
                    role="presentation"
                  >
                    {el.text}
                  </span>
                )}
              </>
            ))}
          </div>
        </Draggable>
      </div>
    ),
    tr3: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />

        {element.events.map((el) => (
          <>
            {el?.text && (
            <Draggable
              onStart={onStart}
              onStop={(e, data) => eventLogger(
                e,
                data,
                el?.style,
                el?.text,
                el?.index,
                0,
                el?.placeholder,
                'tdStyle2',
              )}
              position={{ x: Number(getStrNum(el?.style.left)?.num), y: Number(getStrNum(el?.style.top)?.num) }}
            >
              <div
                className="box"
                style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
                onClick={(e) => handleText(
                  el?.style,
                  el?.text,
                  el?.index,
                  0,
                  el?.placeholder,
                  'tdStyle2',
                  e,
                )}
              >
                <span
                  style={{
                    ...el.style,
                  }}
                  role="presentation"
                >
                  {el.text}
                </span>
              </div>
            </Draggable>
            )}
          </>
        ))}
      </div>
    ),
    tr4: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              className="test"
              style={{
                ...element.events[0].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              className="test"
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr5: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              className="test"
              style={{
                ...element.events[0].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              className="test"
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr6: (element, iii) => (
      <div>
        {element.events[0].text ? (
          <>
            <Draggable
              onStart={onStart}
              onStop={(e, data) => eventLogger(
                e,
                data,
                element?.events[0]?.style,
                element?.events[0]?.text,
                element?.events[0]?.index,
                0,
                element?.events[0]?.placeholder,
                'tdStyle1',
              )}
              position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
            >
              <div
                className="box"
                style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
                onClick={(e) => handleText(
                  element?.events[0]?.style,
                  element?.events[0]?.text,
                  element?.events[0]?.index,
                  0,
                  element?.events[0]?.placeholder,
                  'tdStyle1',
                  e,
                )}
              >
                <span
                  style={{
                    ...element.events[0].style,
                  }}
                  role="presentation"
                >
                  {element.events[0].text}
                </span>
              </div>
            </Draggable>
          </>
        ) : (
          <>
            <div />
          </>
        )}

        {element.events[1].text && (
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
        )}
        {element.events[2].text && (
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[2]?.style,
            element?.events[2]?.text,
            element?.events[2]?.index,
            0,
            element?.events[2]?.placeholder,
            'tdStyle3',
          )}
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[2]?.style,
              element?.events[2]?.text,
              element?.events[2]?.index,
              0,
              element?.events[2]?.placeholder,
              'tdStyle3',
              e,
            )}
          >
            <span
              style={{
                ...element.events[2].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[2].text}
            </span>
          </div>
        </Draggable>
        )}
      </div>
    ),
    tr7: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element?.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <div style={{ ...element.events[0].tdStyle2, position: 'absolute' }} />
      </div>
    ),
    tr8: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr9: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr10: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[2]?.style,
            element?.events[2]?.text,
            element?.events[2]?.index,
            0,
            element?.events[2]?.placeholder,
            'tdStyle3',
          )}
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[2]?.style,
              element?.events[2]?.text,
              element?.events[2]?.index,
              0,
              element?.events[2]?.placeholder,
              'tdStyle3',
              e,
            )}
          >
            <span
              style={{
                ...element.events[2].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
              onClick={(e) => handleText(
                element.events[2]?.style,
                element.events[2]?.text,
                element.events[2]?.index,
                0,
                element.events[2]?.placeholder,
                'tdStyle3',
                e,
              )}
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr11: (element, iii) => (
      <>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle3',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle3, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle3',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr12: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[2]?.style,
            element?.events[2]?.text,
            element?.events[2]?.index,
            0,
            element?.events[2]?.placeholder,
            'tdStyle3',
          )}
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[2]?.style,
              element?.events[2]?.text,
              element?.events[2]?.index,
              0,
              element?.events[2]?.placeholder,
              'tdStyle3',
              e,
            )}
          >
            <span
              style={{
                ...element.events[2].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr13: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[2]?.style,
            element?.events[2]?.text,
            element?.events[2]?.index,
            0,
            element?.events[2]?.placeholder,
            'tdStyle3',
          )}
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[2]?.style,
              element?.events[2]?.text,
              element?.events[2]?.index,
              0,
              element?.events[2]?.placeholder,
              'tdStyle3',
              e,
            )}
          >
            <span
              style={{
                ...element.events[2].style,
              }}
              role="presentation"
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[3]?.style,
            element?.events[3]?.text,
            element?.events[3]?.index,
            0,
            element?.events[3]?.placeholder,
            'tdStyle4',
          )}
          position={{ x: Number(getStrNum(element?.events[3]?.style.left)?.num), y: Number(getStrNum(element?.events[3]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[3].tdStyle4, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[3]?.style,
              element?.events[3]?.text,
              element?.events[3]?.index,
              0,
              element?.events[3]?.placeholder,
              'tdStyle4',
              e,
            )}
          >
            <span
              style={{
                ...element.events[3].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[3]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr14: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    tr15: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
              role="presentation"
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
              role="presentation"
            >
              {element.events[1]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[2]?.style,
            element?.events[2]?.text,
            element?.events[2]?.index,
            0,
            element?.events[2]?.placeholder,
            'tdStyle3',
          )}
          position={{ x: Number(getStrNum(element?.events[2]?.style.left)?.num), y: Number(getStrNum(element?.events[2]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[2].tdStyle3, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[2]?.style,
              element?.events[2]?.text,
              element?.events[2]?.index,
              0,
              element?.events[2]?.placeholder,
              'tdStyle3',
              e,
            )}
          >
            <span
              style={{
                ...element.events[2].style,
              }}
              role="presentation"
            >
              {element.events[2]?.text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[3]?.style,
            element?.events[3]?.text,
            element?.events[3]?.index,
            0,
            element?.events[3]?.placeholder,
            'tdStyle4',
          )}
          position={{ x: Number(getStrNum(element?.events[3]?.style.left)?.num), y: Number(getStrNum(element?.events[3]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[3].tdStyle4, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[3]?.style,
              element?.events[3]?.text,
              element?.events[3]?.index,
              0,
              element?.events[3]?.placeholder,
              'tdStyle4',
              e,
            )}
          >
            <span
              style={{
                ...element.events[3].style,
                border: view === 'addTemplate' ? '1px solid #333' : '',
              }}
              role="presentation"
            >
              {element.events[3]?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    img: (element, iii) => (
      <>
        <>
          <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
          <Draggable
            onStart={onStart}
            onStop={(e, data) => eventLoggerImg(
              e,
              data,
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
            )}
            position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
          >
            <div
              className="box"
              // style={element.events[0].tdStyle2}
              style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
              onClick={() => handleImagePopupOpen(element.events[0].index)}
            >
              {element.events.map((el, i) => (
                <>
                  {el?.text !== '' && (
                  <img
                    src={el?.text}
                    alt="logo"
                    style={{
                      maxHeight: `${el.style.imageHeight}`,
                      height: 'auto',
                      width: `${el.style.imageWidth}`,
                      objectFit: 'contain',
                      left: `${el.style.left}`,
                      top: `${el.style.top}`,
                      transform: `rotate(${el.style.imageRotate}deg)`,
                      zIndex: el.style.zIndex,
                      // position: el.style.position,
                    }}
                  />
                  // <img
                  //   src={el.preview ? el?.text : el.url}
                  //   alt="logo"
                  //   style={{
                  //     height: `${el.style.imageHeight}`,
                  //     width: `${el.style.imageWidth}`,
                  //     objectFit: 'contain',
                  //     position: 'absolute',
                  //     marginRight: `${el.style.imageMarginRight}`,
                  //     left: `${el.style.imageMarginLeft}`,
                  //     top: `${el.style.imageMarginTop}`,
                  //     marginBottom: `${el.style.imageMarginBottom}`,
                  //     transform: `rotate(${el.style.imageRotate})deg`,
                  //     zIndex: el.style.zIndex,
                  //   }}
                  //   onClick={() => handleImageNew(el?.style, el?.text, el?.index)}
                  // />
                  )}
                </>
              ))}
            </div>
          </Draggable>
        </>
      </>
    ),
  };
  const customJson = {
    tr: (element, iii) => (
      <>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger1(
            e,
            data,
            element?.style,
            element?.text,
            element?.index,
          )}
          position={{ x: Number(getStrNum(element?.style.left)?.num), y: Number(getStrNum(element?.style.top)?.num) }}
        >
          <div
            className="box"
            // style={element.tdStyle2}
            style={{ ...element.tdStyle2, position: 'absolute' }}
            onClick={(e) => handleTextNew(element.style, element.text, element.index, e)}
          >
            <span
              style={{
                ...element.style,
              }}
              role="presentation"
            >
              {element?.text}
            </span>
          </div>
        </Draggable>
      </>
    ),
    img: (element, iii) => (
      <>
        <>
          <div />
        </>
      </>
    ),
  };
  return (
    <div className="quotes-template-page">
      <page size="A4">
        <div
          id="myTable"
          className="edit-template quotes-edit-temp add-edit-quotes-template"
          style={{
            overflow: 'hidden',
            wordBreak: 'break-word',
            width: `${countryType === 'us' ? '7.9in' : '595px'}`,
            margin: '0px auto',
            borderTop: '1px solid lightgray',
            borderRight: '1px solid lightgray',
            borderLeft: 'none',
            display: 'block',
            backgroundColor: 'white',
            position: 'relative',
            height: `${countryType === 'us' ? '11in' : '842px'}`,
            border: '1px solid rgb(219 219 219)',
            padding: '20px',
          }}
        >
          {customFeilds
            && customFeilds.map((cust) => <>{customJson[cust.type](cust)}</>)}
          {Array.from(new Set(arrayOne.map((s) => s.type)))
            .map((lab) => ({
              name: lab,
              events: arrayOne
                .filter((s) => s.type === lab)
                .map((edition) => edition),
            }))
            .map((el, i) => (
              <>{htmlJson[el.name](el, i)}</>
            ))}
        </div>
      </page>
    </div>
  );
};
TestingTemplate.propTypes = {
  templateData: PropTypes.arrayOf.isRequired,
  handleText: PropTypes.func.isRequired,
  handleImagePopupOpen: PropTypes.func.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
  handleTextNew: PropTypes.func.isRequired,
  handleImageNew: PropTypes.func.isRequired,
  handleMoveText: PropTypes.func.isRequired,
  handleTextNewMove: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};
export default TestingTemplate;
