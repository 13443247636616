/* eslint-disable no-multi-assign */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Button, Modal, Nav, Tabs, Tab,
} from 'react-bootstrap';
import getStrNum from '../../functions/strNumseparator';
import { validateDataRangeText, valueConverterToOther, valueConverterToPx } from '../../functions';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const EditNewTextPopup = ({
  addTextpopup, handleTextPopup, handleTextNew,
  customFeilds, setCustomFeilds, text, customName, setText, customNameTwo, tdIndex, widthValue, setShowEdit,
}) => {
  const { userData } = useContext(AuthDataContext);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';
  let sizeCount = 0;
  const Font = [
    "'Exo 2', sans-serif",
    "'Montserrat', sans-serif",
    "'Poppins', sans-serif",
    "'Raleway', sans-serif",
    "'Roboto', sans-serif",
  ];

  const [customObject, setCustomObject] = useState(text);
  const [errors, setErrors] = useState('');
  const [updatedValues, setUpdatedValues] = useState();
  const [dummy, setDummy] = useState(false);

  useEffect(() => {
    const oldObject = JSON.parse(JSON.stringify(customFeilds));
    console.log('oldObject ==>', {
      oldObject, customNameTwo,
    });
    let customcheck = { ...oldObject[customNameTwo] };
    setCustomObject(customcheck);
    updateMeasurement(customcheck);
  }, []);

  const updateMeasurement = (fielddata) => {
    const updateData = JSON.parse(JSON.stringify(fielddata));
    console.log('updateData testimg ===============>', updateData);
    if (updateData?.style) {
      if (updateData?.style?.top || updateData?.style?.left || updateData[tdIndex].height || updateData[tdIndex].width) {
        let topVal = valueConverterToOther(updateData.style.top, countryType);
        updateData.style.top = topVal;
        setUpdatedValues(updateData);
        let leftVal = valueConverterToOther(updateData.style.left, countryType);
        updateData.style.left = leftVal;
        setUpdatedValues(updateData);

        let heightVal = valueConverterToOther(updateData[tdIndex].height, countryType);
        updateData[tdIndex].height = heightVal;
        setUpdatedValues(updateData);

        let widthVal = valueConverterToOther(updateData[tdIndex].width, countryType);
        updateData[tdIndex].width = widthVal;
        console.log('updateData =======>', updateData);
        setUpdatedValues(updateData);
      }
    }
  };

  useEffect(() => {
    if (customObject.style) {
      let dd = document.getElementById('newTexttt').offsetWidth;
      let str = document.getElementById('newTexttt').innerText;
      const spacesCount = str.split(' ').length - 1;
      let dd1 = dd + 10 + spacesCount;
      let oldStyleObjectt = { ...customObject };
      let newWidth = `${dd1}px`;
      oldStyleObjectt.tdStyle2.width = `${newWidth}`;
      const convertedVal = valueConverterToOther(newWidth, countryType);
      let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
      copyupdatedValues.tdStyle2.width = convertedVal;
      setUpdatedValues(copyupdatedValues);
      setCustomObject(oldStyleObjectt);
      setDummy(false);
    }
  }, [dummy]);
  const setTemplateData = (data, wave) => {
    setDummy(true);
    const { value } = data;
    let parsingValue;
    let oldState = [...customFeilds];
    if (wave === 'text') {
      oldState[customNameTwo].text = value;
      setCustomObject({ ...customObject, text: value });
      setCustomFeilds(oldState);
      return;
    }
    if (wave === 'fontSize') {
      parsingValue = parseInt(value, 10);
    } else if (wave === 'lineHeight') {
      parsingValue = `${value}px`;
    } else {
      parsingValue = value;
    }
    let { style } = customObject;
    let oldStyleObject = { ...style };
    oldStyleObject[wave] = parsingValue;
    oldState[customNameTwo].style = oldStyleObject;
    setCustomObject({ ...customObject, style: oldStyleObject });
    setCustomFeilds(oldState);
  };

  const handleSave = () => {
    // console.log('customFeilds ==>', { customFeilds });

    // const oldState = JSON.parse(JSON.stringify(customFeilds));

    // let { style } = oldState[customNameTwo];
    // let oldStyleObject = JSON.parse(JSON.stringify(style));
    // oldState[customNameTwo].style = oldStyleObject;
    // setCustomFeilds(oldState);
    setShowEdit(false);
  };

  const deleteFeild = () => {
    const oldObject = [...customFeilds];
    oldObject.splice(customNameTwo, 1);
    oldObject.forEach((el, m) => {
      el.index = m;
    });
    setCustomFeilds(oldObject);
    setShowEdit(false);
  };

  const setTemplateLocationSize2 = (data, type = 'px') => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    const { isValid, error } = validateDataRangeText(value, name);
    let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
    setErrors(error);
    if (isValid) {
      const keyVal = `${value}px`;
      let oldState = [...customFeilds];
      let { style } = oldState[customNameTwo];
      let oldtdStyleObject = { ...style };
      oldState[customNameTwo].style = oldtdStyleObject;
      if ((name === 'left' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject[name] = keyVal;
      } else if ((name === 'top' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject[name] = keyVal;
      } else {
        oldtdStyleObject[name] = 'initial';
      }
      if (oldtdStyleObject.left === 'initial' && oldtdStyleObject.top === 'initial') {
        oldtdStyleObject.position = 'initial';
      }
      setCustomObject({ ...customObject, style: oldtdStyleObject });
      setCustomFeilds(oldState);
      copyupdatedValues.style[name] = OriginalVal;
      setUpdatedValues(copyupdatedValues);
    }
  };
  const setTemplateLocationSize = (data, type) => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    const keyVal = `${value}px`;
    let oldState = [...customFeilds];
    let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
    const { tdStyle1, tdStyle2 } = oldState[customNameTwo];
    let oldtdStyle1Object = { ...tdStyle1 };
    let oldtdStyle2Object = { ...tdStyle2 };
    if (tdIndex === 'tdStyle1') {
      oldtdStyle1Object[name] = keyVal;
      oldState[customNameTwo].tdStyle1 = oldtdStyle1Object;
      setCustomObject({ ...customObject, tdStyle1: oldtdStyle1Object });
      copyupdatedValues.tdStyle1[name] = OriginalVal;
    } else {
      oldtdStyle2Object[name] = keyVal;
      oldState[customNameTwo].tdStyle2 = oldtdStyle2Object;
      setCustomObject({ ...customObject, tdStyle2: oldtdStyle2Object });
      setCustomFeilds(oldState);
      copyupdatedValues.tdStyle2[name] = OriginalVal;
    }
    setUpdatedValues(copyupdatedValues);
  };
  const addjustElementWidth = (data, type) => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    let oldState = [...customFeilds];
    let { tdStyle2 } = customObject;
    let oldStyleObject = { ...tdStyle2 };
    oldStyleObject.width = `${value}px`;
    oldState[customNameTwo].tdStyle2 = oldStyleObject;
    setCustomObject({ ...customObject, tdStyle2: oldStyleObject });
    setCustomFeilds(oldState);
    let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
    copyupdatedValues.tdStyle2.width = OriginalVal;
    setUpdatedValues(copyupdatedValues);
  };

  return (
    <Modal
      size="md"
      show={addTextpopup}
      onHide={() => setShowEdit(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup image-upload-popup change-text-popup"
    >
      <Modal.Body>
        <div className="appointment-container preview-popup">
          <div className="change-image-section">
            <div className="preview-text">
              <h6>Text Wording</h6>
              <span style={{
                fontFamily: `${customObject?.style?.fontFamily}`,
                fontSize: `${customObject?.style?.fontSize}px`,
                color: `${customObject?.style?.color}`,
                fontStyle: `${customObject?.style?.fontStyle}`,
                textDecoration: `${customObject?.style?.textDecoration}`,
                fontWeight: `${customObject?.style?.fontWeight}`,
                lineHeight: `${customObject?.style?.lineHeight}`,
                border: '1px solid rgb(220, 217, 217)',
                padding: '10px',
                marginBottom: '15px',
                display: 'flex',
                borderRadius: '5px',
              }}
              >
                <span id="newTexttt">{customObject.text}</span>
              </span>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                {/* <Form.Label>Text Wording</Form.Label> */}
                <Form.Control as="textarea" rows={3} value={customObject.text} onChange={({ target: { value, name } }) => setTemplateData({ value, name }, 'text')} />
              </Form.Group>
            </div>
            <h6>Type Characteristics</h6>
            <div className="field-location-section">
              <div className="field-location">

                <Form.Group controlId="formGridEmail">
                  <Form.Label>Typeface</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.font} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'font')}>
                      {Font.map((f) => (
                        <option value={f}>{f}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Point Size</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontSize} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontSize')}>
                      {Array.from(Array(20).keys()).map(() => {
                        sizeCount += 1;
                        return (
                          <option value={sizeCount}>{sizeCount}</option>
                        );
                      })}
                    </select>
                  </div>
                </Form.Group>

              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Style</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontStyle} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontStyle')}>
                      <option value="normal">Normal</option>
                      <option value="Italic">Italic</option>
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Line Spacing</Form.Label>
                  <Form.Control type="number" value={getStrNum(customObject?.style?.lineHeight)?.num} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'lineHeight')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Alignment</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textAlign} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textAlign')}>
                      <option value="left">Flush Left</option>
                      <option value="center">Centered</option>
                      <option value="right">Flush Right</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Colour</Form.Label>
                  <Form.Control type="color" value={customObject?.style?.color} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'color')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Effects</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textDecoration} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textDecoration')}>
                      <option value="underline">UnderLine</option>
                      <option value="line-through">Strikeout</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Weight</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontWeight} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontWeight')}>
                      <option value="bold">Bold</option>
                      <option value="bolder">Bolder</option>
                      <option value="lighter">Lighter</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="text-popup-spacing">
              <h6>Text Field Location & Size</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.style?.top)?.num} name="top" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(updatedValues?.style?.top)?.str)} placeholder="0" />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.top && <p className="error-msg">{errors.top}</p>}
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Distance from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.style?.left)?.num} name="left" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(updatedValues?.style?.left)?.str)} placeholder="0" />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.left && <p className="error-msg">{errors.left}</p>}
                  </Form.Group>
                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Height</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.[tdIndex]?.height)?.num} name="height" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(updatedValues?.[tdIndex]?.height)?.str)} />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Width</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.[tdIndex]?.width)?.num} name="width" onChange={(e) => addjustElementWidth(e.target, getStrNum(updatedValues?.[tdIndex]?.width)?.str)} />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="template-btn">
            <Button className="delete-btn" onClick={deleteFeild}>Delete Field</Button>
            <Button className="save-btn" onClick={handleSave}>Save/ Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
};
EditNewTextPopup.propTypes = {
  addTextpopup: PropTypes.bool.isRequired,
  handleTextPopup: PropTypes.bool.isRequired,
  handleTextNew: PropTypes.func.isRequired,
  customFeilds: PropTypes.objectOf.isRequired,
  setCustomFeilds: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  customName: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  customNameTwo: PropTypes.func.isRequired,
  tdIndex: PropTypes.string.isRequired,
  widthValue: PropTypes.string.isRequired,
  setShowEdit: PropTypes.func.isRequired,
};
export default EditNewTextPopup;
