import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const ClientDetailsUpdatePopUp = ({
  clientPopUpStatus, setClientPopUpStatus, handleUpdateClientDetails, clientSelectStatus, setClientSelectStatus,
}) => (
  <div
    className="duration-picker time-picker-modal update-source-popup"
  >
    <div>
      <div className="time-keeper-popup duration-popup event-popup">
        <div className="appointment-view">
          <div className="pending-water-test" onClick={() => setClientSelectStatus(!clientPopUpStatus)}>
            <h3>
              Update Changed Pool Parameters in Client Record
            </h3>
            <div className="open-date client-notes">
              <div className="basic">
                <Form.Check
                  type="checkbox"
                  name="waterSource"
                  checked={
                    clientPopUpStatus ? true : ''
                        }
                />
              </div>
            </div>
          </div>
          <div className="event-popup-btn footer-btn view-btn">
            <Button className="appointment-btn btn-edit" type="button" onClick={() => { setClientPopUpStatus(!clientPopUpStatus); }}>
              Cancel
            </Button>
            <Button type="button" className="appointment-btn btn-save" onClick={handleUpdateClientDetails}>
              Select
            </Button>
          </div>

        </div>

      </div>
    </div>
    {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
  </div>

);
ClientDetailsUpdatePopUp.propTypes = {
  clientPopUpStatus: PropTypes.bool.isRequired,
  setClientPopUpStatus: PropTypes.func.isRequired,
  handleUpdateClientDetails: PropTypes.func.isRequired,
  clientSelectStatus: PropTypes.bool.isRequired,
  setClientSelectStatus: PropTypes.bool.isRequired,

};
export default ClientDetailsUpdatePopUp;
