import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const AfterEditChemicalPopUp = ({
  setEditChemicalPopUp, editChemicalPopUp, handleAfterEditChemical, selectedEditOption, handleSaveAfterEditChemicals, editPricePermission,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          // console.log('comingUnderThisSection');
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setEditChemicalPopUp(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal update-source-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <div className="pending-water-test" onClick={() => handleAfterEditChemical('calculatedCost')}>
              <h3>
                Chemical Cost as Calculated
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="calculatedCost"
                    checked={
                      selectedEditOption === 'calculatedCost' ? true : ''
                        }
                    // onChange={(e) => handleAfterEditChemical(e.target.name)}

                  />
                </div>
              </div>
            </div>
            {editPricePermission ? (
              <div className="pending-water-test" onClick={() => handleAfterEditChemical('changedSellPrice')}>
                <h3>Edit Changed Chemicals Sell Price</h3>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="changedSellPrice"
                      checked={
                      selectedEditOption === 'changedSellPrice' ? true : ''
                        }
                      onChange={(e) => handleAfterEditChemical(e.target.name)}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => { setEditChemicalPopUp(!editChemicalPopUp); }}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleSaveAfterEditChemicals()}>
                Select
              </Button>
            </div>

          </div>

        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>

  );
};
AfterEditChemicalPopUp.propTypes = {
  editChemicalPopUp: PropTypes.func.isRequired,
  setEditChemicalPopUp: PropTypes.func.isRequired,
  handleAfterEditChemical: PropTypes.func.isRequired,
  selectedEditOption: PropTypes.func.isRequired,
  handleSaveAfterEditChemicals: PropTypes.func.isRequired,
  editPricePermission: PropTypes.bool.isRequired,
};
export default AfterEditChemicalPopUp;
