/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable no-fallthrough */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import {
  Table, Button, Form, Dropdown, Accordion, Card,
} from 'react-bootstrap';

import { confirmAlert } from 'react-confirm-alert';
import Adminwrapper from '../admin/adminwrapper/wrapper';
// import checkimg from '../../assets/home/check.png';
import { showErrMsg, validateDataWithKey } from '../../functions/index';
import { userInstance } from '../../config/axios';
import {
  targetLevelState,
  chemicalCostState,
  drainageCostState,
  balanceWaterState,
  chlorinatorModelState,
  calciumAdjustmentState,
  targetLevelDefaultState,
  chemicalCostDefaultState,
  drainageCostDefaultState,
  chemicalBalanceWaterDefaultState,
  drainAcidDefaultState,
  drainAcidState,
  greenPoolCalculationDefaultState,
  greenPoolState,
  sandFilterMediaState,
  sandFilterMediaDefaultState,
  defaultSecondVisitState,
  secondVisitCostState,
  blackSpotAlgaeState,
  blackSpotAlgaeDefaultState,
  defaultChemicalDisplayState,
  chemicalDisplayState,
  taxApplicableState,
  defaultTaxApplicableState,
} from './pool_state';
import greyplus from '../../assets/home/plus.png';
import showmore from '../../assets/home/showmoreblue.png';
import Chlorinator from './chlorinator-popup';
import DrainageCost from './dranage-cost';
import MiniralPool from './miniralpool';
import MiniralPopup from './miniral-popup';
import ChemistryTarget from './chemistry-target';
import ChemicalCost from './chemicalCost';
import ChlorinatorPage from './chlorinator';
import './poolsetup.css';
import BalanceWater from './balance-water';
import ca from '../../assets/home/icon/ca.png';
import d from '../../assets/home/icon/d.png';
import chlorinatorimg from '../../assets/home/icon/chlorinator.png';
import percentage from '../../assets/home/icon/percentage.png';
import target from '../../assets/home/icon/target.png';
import GreenPoolCalculation from './greenPool-calculation';
import AcidWashCalculation from './acidwashCalculation';
import FilterReplacement from './filterReplacement';
import SecondVisitCost from './secondVisitCost';
import DurationPicker from '../appointment/durationPicker';
import BlackSpotAlgae from './blackSpotAlgae';

import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorBorderTime, errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import ChemicalsDisplay from './chemicalsDisplay';
import './webview.css';
import TaxApplicable from './taxApplicable';
import Loader from '../loader/loader';
import toastMessage from '../../functions/toastMessage';
import CheckSetUpPermissions from './checkSetUpPermissions';

function PoolSetUp() {
  const {
    userData, setUserData,
  } = useContext(AuthDataContext);

  const removeInCaseOfImperial = ['Magna Minerals (10 kg)', 'Aquatherepe Minerals (10 kg)', 'Aquatherepe Transform (10 kg)', 'Fine Glass Media (15 kg)', 'Coarse Glass Media (15 kg)'];

  // console.log('userData', userData);
  const [idealData, setIdealData] = useState({
    chlorine: '',
    phConcreteTilted: '',
    phfibre: '',
    phliner: '',
    alkalinity: '',
    stabiliser: '',
    calciumHardness: '',
    salt: '',
    chlorineLvl: '',
    phLvl: '',
    alkalinityLvl: '',
    stabiliserLvl: '',
    calciumHardnessLvl: '',
    saltLvl: '',
    magnaPoolAdjust: '',
    aquaAdjust: '',
    baseGranChlorine: '',
    baseSodiumThi: '',
    basePhSodaAsh: '',
    basePhAcid: '',
    baseAlkBuffer: '',
    baseCalciumRaiser: '',
    baseStabiliser: '',
    basePhosphates: '',
    baseSalt: '',
    liquidChlorine: '',
    baseConvert: '',
    baseMagna: '',
    baseAqua: '',
    baseSalt1: '',
    sellLiquid: '',
    sellGranular: '',
    sellSodium: '',
    sellPhAcid: '',
    sellSodaAsh: '',
    sellBuffer: '',
    sellCalciumHardness: '',
    sellStabiliser: '',
    sellPhosphates: '',
    sellConvert: '',
    sellMagna: '',
    sellAqua: '',
  });
  const [renderSort, setRenderSort] = useState(false);
  const [chlorinator, setChlorinator] = useState();
  const [miniralPopup, setMiniralPopup] = useState();
  const [view, setView] = useState('');
  const [state, setState] = useState(chemicalDisplayState);
  const [isUpdate, setUpdate] = useState(null);
  const [chlorinatorData, setChlData] = useState(chlorinatorModelState);
  const [calciumAdjustment, setCalciumAdjustment] = useState(calciumAdjustmentState);
  const [selectedIndexModel, setIndex] = useState(null);
  const [calIndex, setCalIndex] = useState(null);
  const [orpProbe, setOrpProbe] = useState();
  const [orpMineralProbe, setOrpMineralProbe] = useState();
  const [chlorineValue, setChlorineValue] = useState('Granular Chlorine');
  const [greenPool, setGreenPool] = useState();
  const [acidWash, setAcidwash] = useState();

  const [defaultViewState, setDefaultViewState] = useState([]);
  const [change, setChange] = useState('newUpdate');
  const [newView, setNewView] = useState('');

  const [datass, setdata] = useState([]);
  const [count, setCount] = useState(1);
  const [managementPrevious, setManagementPrevious] = useState(false);
  const [newArrayStateGreen, setNewArrayStateGreen] = useState([]);
  const [newArrayStateBlack, setNewArrayStateBlack] = useState([]);

  const [durationPicker, setDurationPicker] = useState(false);
  const [durationData, setDuration] = useState('');
  const [granSelected, setGranSelected] = useState();
  // const [blackSelected, setBlackSelected] = useState();
  const [drainState, setDrainState] = useState();
  const [poolChlorineSize, setPoolChlorineSize] = useState();
  const [poolBlackSize, setPoolBlackSize] = useState();
  const [drainPoolSize, setDrainPoolSize] = useState();
  const [filterData, setFilterData] = useState([]);
  const [chemCost, setChemCost] = useState(null);
  const [activeLink, setActiveLink] = useState('');

  const isUsImperial = userData?.calendarSettings?.unit !== 'metric';
  const [errorValue, setErrorValue] = useState('');
  const [taxStatus, setTaxStatus] = useState(false);
  const [taxStatusLabour, setTaxStatuslabour] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOrp = (value) => {
    // console.log('value', value, 'chlorinatorData', chlorinatorData.orp);
    const obj = { customCost: value };
    // console.log('obj', obj);
    let val = true;
    if (value === 'Yes') {
      setOrpProbe(obj);
      setChlData({ ...chlorinatorData, orp: value });
    } else if (value === 'No') {
      setOrpProbe(false);
      val = false;
      setChlData({ ...chlorinatorData, orp: value });
    }
  };

  const handleMineralOrp = (value) => {
    if (value === 'Yes') {
      setCalciumAdjustment({ ...calciumAdjustment, orp: value, stabiliserLevel: state && calIndex ? state[calIndex].stabiliserLevel : '' });
    } else if (value === 'No') {
      setCalciumAdjustment({ ...calciumAdjustment, orp: value, stabiliserLevel: '' });
    }
  };

  const handleChlorinator = () => {
    if (chlorinator) {
      setIndex(null);
      setChlData(chlorinatorModelState);
    }
    setChlorinator(!chlorinator);
  };

  async function chck() {
    if (change === 'newUpdate') {
      const response = await userInstance().get(`/poolmineral/getPoolMineral/${view}`);
      const { pooldata } = response.data;
      if (pooldata[view]) {
        if (view === 'drainageSetup') {
          setdata({ ...pooldata[view] });
        } else if (view === 'taxApplicable') {
          setdata({ ...pooldata[view] });
        } else if (view === 'secondVisitCost') {
          setdata({ ...pooldata[view] });
        } else if (view === 'chemicalsDisplay') {
          setdata({ ...pooldata[view] });
        } else {
          setdata([...pooldata[view]]);
        }
      }
    }
  }

  // section For SecondVisit Cost

  const handleDurationPicker = () => {
    setDuration(state.duration);
    setDurationPicker(!durationPicker);
  };
  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };
  const handleChangeCustom = (value) => {
    const obj = { ...state, customCost: value };
    if (value === 'default') {
      obj.cost = '100.00';
    } else if (value === 'yes' || value === 'sellPrice') {
      obj.cost = '';
      obj.buyCostProduct = obj.prevBuyCostProduct;
    }
    setState(obj);
  };
  const withValueLimitSellPrice = (inputObj) => {
    const { value } = inputObj;
    if (value.match(/\./g)) {
      const [, decimal] = value.split('.');
      if (decimal?.length > 2) {
        return;
      }
    }
    const toCheck = 9999999999.999;
    // console.log('toCheck', toCheck);
    if (value <= toCheck) return inputObj;
    toastMessage('error', 'Enter an amount 9,999,999,999.999 or less.');

    return '';
  };
  const handleChangeSecondVisitCost = (e) => {
    const { value, name } = e.target;
    const obj = { ...state, [name]: value };
    if (name === 'pricingType') {
      if (state.customCost !== 'default') {
        obj.cost = '';
      }
    }
    setState(obj);
  };

  const handleMiniralPopup = () => {
    if (miniralPopup) {
      setCalIndex(null);
      setCalciumAdjustment(calciumAdjustmentState);
    }
    setMiniralPopup(!miniralPopup);
  };

  const getIdeals = async () => {
    try {
      if (view) {
        // console.log('view------', view);
        const response = await userInstance().get(`/poolmineral/getPoolMineral/${view}`);
        const { pooldata } = response.data;
        // console.log('pooldata[view]', pooldata[view]);
        if (pooldata[view]) {
          if (view === 'drainageSetup') {
            setState({ ...pooldata[view] });
          } else if (view === 'taxApplicable') {
            setState({ ...pooldata[view] });
          } else if (view === 'secondVisitCost') {
            setState({ ...pooldata[view] });
          } else if (view === 'chemicalsDisplay') {
            setState({ ...pooldata[view] });
          } else {
            setState([...pooldata[view]]);
          }
        }
      }
      if (newView) {
        const response = await userInstance().get(`/poolmineral/getPoolMineral/${newView}`);
        const { pooldata } = response.data;
        if (newView === 'revertdrainageSetup') {
          setDefaultViewState({ ...pooldata[newView] });
        } else if (newView === 'reverttaxApplicable') {
          setDefaultViewState({ ...pooldata[newView] });
        } else if (newView === 'revertsecondVisitCost') {
          setDefaultViewState({ ...pooldata[newView] });
        } else if (newView === 'revertchemicalsDisplay') {
          setDefaultViewState({ ...pooldata[newView] });
        } else {
          setDefaultViewState([...pooldata[newView]]);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      //   console.log(error);
    }
  };

  const handleChange = (e, index) => {
    const copyState = state;
    const { value } = e.target;
    const oldData = copyState.filter((el) => el);
    const selectedChemical = copyState[index];
    if (value && !value.match(/^[0-9]*[.]?[0-9]{0,2}$/)) {
      return;
    }
    if (e.target.name === 'okPositive' || e.target.name === 'drainPoint') {
      // console.log('rrrr =>', e.target.value, e.target.style.width);
      let curWidth = Number(e.target.style.width.replace('px', ''));

      if (e.target.value.toString().length > selectedChemical[e.target.name].toString().length) {
        curWidth += 10;
      } else {
        curWidth -= 10;
      }
      e.target.style.width = `${curWidth}px`;
    }
    selectedChemical[e.target.name] = e.target.value;
    setState([...copyState]);
  };

  const handleFilterChange = (e, index) => {
    const copyState = state;
    const selectedFilter = copyState[index];
    if (e.target.name === 'sandQuantity') {
      if (e.target.value.match(/^[0-9]*[.]?[0-9]{0,2}$/)) {
        selectedFilter[e.target.name] = e.target.value;
        selectedFilter.bagsSand = parseFloat(selectedFilter.sandQuantity ? selectedFilter.sandQuantity : 0) / 20;
      }
    } else {
      selectedFilter[e.target.name].quantity = e.target.value;
      selectedFilter.bagsGlass = parseFloat(selectedFilter.glassCoarseQuantity.quantity ? selectedFilter.glassCoarseQuantity.quantity : 0) + parseFloat(selectedFilter.glassFineQuantity.quantity ? selectedFilter.glassFineQuantity.quantity : 0);
    }
    setState([...copyState]);
  };

  const handleChangeDrainageCost = (e) => {
    const { value, name } = e.target;
    const obj = { [name]: value };
    if (value.match(/\./g)) {
      const [, decimal] = value.split('.');
      if (decimal?.length > 2) {
        return;
      }
    }
    let {
      poolsize, litresPerHour, drainagePercentage, hourlyRate, setupCost,
    } = state;
    if (name === 'poolsize') {
      poolsize = value;
    } else if (name === 'litresPerHour') {
      litresPerHour = value;
    } else if (name === 'drainagePercentage') {
      drainagePercentage = value;
    } else if (name === 'hourlyRate') {
      hourlyRate = value;
    } else if (name === 'setupCost') {
      setupCost = value;
    }

    const hoursToDrain = (parseFloat(poolsize) / parseFloat(litresPerHour)) * (parseFloat(drainagePercentage) / 100);
    const cost = (parseInt(Math.ceil((parseFloat(hourlyRate) * parseFloat(hoursToDrain)) / 5), 10) * 5) + parseFloat(setupCost);
    obj.hoursToDrain = hoursToDrain.toFixed(2);
    if (cost) obj.cost = cost.toFixed(2);
    setState((prev) => ({ ...prev, ...obj }));
  };

  const moveArr = (oldIndex, newIndex) => {
    const arrCopy = [...state];
    let index = null;
    if (newIndex >= arrCopy.length) {
      let k = newIndex - arrCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        arrCopy.push(undefined);
      }
    }
    arrCopy.splice(newIndex, 0, arrCopy.splice(oldIndex, 1)[0]);
    setState([...arrCopy]);
  };

  const handleSorting = () => {
    setUpdate(true);
    setRenderSort(false);
  };

  const [checkBlankFields, setCheckBlankFields] = useState([]);

  const chemicalTargetBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (!el?.target?.trim() || !el?.drainPoint?.trim() || !el?.okPositive?.trim() || !el?.okNegative?.trim()) {
        arr = [...arr, el.name];
      }
    });
    return arr;
  };

  const chemicalCostsBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (!el?.buycost || !el?.sellprice) {
        arr = [...arr, el.name];
      }
    });
    return arr;
  };

  const acidDrainBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (el?.calciName !== 'acidPoolSize') {
        if (['chlorineWashQuantity', 'acidWashQuantity'].includes(el?.calciName)) {
          if (!el.dosageAndTime?.trim()) arr = [...arr, el.calciName];
        } else if (!el?.timeReq?.trim()) arr = [...arr, el.calciName];
      }
    });
    return arr;
  };

  const sandFilterBlank = () => {
    let arr = [];
    state.forEach((el) => {
      console.log({ el });
      if (el?.unit?.includes('hour')) {
        if (!el?.sandQuantity?.trim()) arr = [...arr, el.mediaFilterSize];
      } else if (!el?.sandQuantity?.trim() || !el?.glassFineQuantity?.quantity?.trim() || !el?.glassCoarseQuantity?.quantity?.trim()
      ) arr = [...arr, el.mediaFilterSize];
    });
    return arr;
  };

  const blackSpotAndGreenPoolBlank = () => {
    let arr = [];
    state.forEach((el) => {
      if (!el.dosageAndTime?.trim()) {
        let name = !el.greenName ? el.chemicalName : el.greenName;
        arr = [...arr, name];
      }
    });
    return arr;
  };

  const drainageBlank = () => {
    let arr = [];
    Object.keys(state).forEach((el) => {
      if (el !== 'cost' && !state[el]?.trim()) {
        arr = [...arr, el];
      }
    });
    return arr;
  };
  const secondVisitCostBlank = () => {
    if (!state?.cost) return ['cost'];
  };

  const checkInputFieldsBlanks = () => {
    let valid = true;
    let arr = [];
    switch (view) {
      case 'chemicalTargetLevels':
        arr = chemicalTargetBlank();
        break;
      case 'chemicalCosts':
        arr = chemicalCostsBlank();
        break;
      case 'acidWashCalculation':
        arr = acidDrainBlank();
        break;
      case 'sandFilterMedia':
        arr = sandFilterBlank();
        break;
      case 'blackSpotAlgae':
      case 'greenpoolcalculation':
        arr = blackSpotAndGreenPoolBlank();
        break;
      case 'drainageSetup':
        arr = drainageBlank();
        break;
      case 'secondVisitCost':
        arr = secondVisitCostBlank();
        break;
      default:
        break;
    }

    if (arr?.length > 0) {
      toastMessage('error', 'Fields cannot be blank');
      setCheckBlankFields(arr);
      setErrorValue(Math.floor(Math.random() * 100));
      valid = false;
    }
    return valid;
  };

  const handleRequest = async () => {
    setCount(count + 1);
    try {
      let isValid = true;
      isValid = checkInputFieldsBlanks();
      if (!isValid) {
        return;
      }

      if (view === 'taxApplicable') {
        if (state?.labourTax === '') {
          isValid = false;
          setTaxStatuslabour(false);
          setErrorValue('labourTax');
          toastMessage('error', 'Fields can not be empty.');
        }
        if (state?.chemicalsTax === '') {
          isValid = false;
          setTaxStatus(false);
          setErrorValue('chemicalsTax');
          toastMessage('error', 'Fields can not be empty.');
        }

        if (!isValid) {
          return setTimeout(() => {
            setErrorValue('');
          }, errorBorderTime);
        }

        state.labourTax = parseFloat(state?.labourTax).toFixed(2);
        state.chemicalsTax = parseFloat(state?.chemicalsTax).toFixed(2);
      }
      if (newView) {
        const response = await userInstance().put('/poolmineral/updatePoolMineral', { key: newView, data: datass });
        const { msg } = response.data;
        setDefaultViewState(state);
        toastMessage('success', msg);
      }
      const response = await userInstance().put('/poolmineral/updatePoolMineral', { key: view, data: state });
      const { msg } = response.data;
      toastMessage('success', msg);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }

    setUpdate(false);
    getIdeals();
    if (!managementPrevious) {
      chck();
    }
    setChange('newUpdate');
    setManagementPrevious(false);
  };

  const handleAdjust = (e) => {
    e.preventDefault();
    // console.log(state);
  };

  const updateView = (key) => {
    setLoading(true);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setActiveLink(key);
    setView(key);
    if (key === 'calciumAdjustments' || key === 'chlorinatorModels') {
      setNewView('');
    } else {
      setNewView(`revert${key}`);
    }
    // setState(null);
    setUpdate(null);
  };

  const handleChlModelData = (e) => {
    let value = e.target.value === '' ? 0.00 : parseFloat(e.target.value);
    if (e.target.name === 'recmndStabiliserLvl') {
      if (value <= 150 && value >= 0) {
        setChlData({ ...chlorinatorData, [e.target.name]: parseFloat(value).toFixed() });
      }
    } else if (e.target.name === 'saltLevel') {
      if (value <= 10000 && value >= 0) {
        setChlData({ ...chlorinatorData, [e.target.name]: parseFloat(value).toFixed() });
      }
    } else {
      setChlData({ ...chlorinatorData, [e.target.name]: e.target.value });
    }
  };

  const limitNumber = (inputObj) => {
    let { value } = inputObj;
    if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
      return;
    }
    if (value === '00' || value === '0.00') {
      return 0;
    }
    if (value.charAt(0) === '.') {
      value = `0${value}`;
    }
    const limitAmount = 100.00;
    const splitVal = value.toString().split('.');
    const isValid = splitVal.length > 1 ? splitVal[1].length <= 2 : true;
    if (value <= limitAmount && isValid) {
      return inputObj;
    }
  };
  const handleNumberChange = (e) => {
    let { value } = e.target;
    let val;
    if (value.charAt(0) === '.') {
      val = `0${value}`;
      value = val;
    }

    const copy = { ...calciumAdjustment };

    if (e.target.name === 'magnesium') {
      let calciumValue = '';
      if (e.target.value !== '') {
        calciumValue = 100 - e.target.value;
      } else {
        calciumValue = '';
      }
      copy.calcium = calciumValue;
    }

    copy[e.target.name] = value;
    setCalciumAdjustment({ ...copy });
    // }
  };
  const handleChlorinatorNumberChange = (e) => {
    let { value } = e.target;
    let val;
    if (value.charAt(0) === '.') {
      val = `0${value}`;
      value = val;
    }

    setChlData({ ...chlorinatorData, [e.target.name]: value });
  };
  const handleCalciumModelData = (e) => {
    const value = e.target.value === '' ? 0.00 : parseFloat(e.target.value);
    if (e.target.name === 'stabiliserLevel') {
      if (value <= 150 && value >= 0) {
        setCalciumAdjustment({ ...calciumAdjustment, [e.target.name]: parseFloat(value).toFixed() });
      }
    } else if (e.target.name === 'saltLevel') {
      if (value <= 10000 && value >= 0) {
        setCalciumAdjustment({ ...calciumAdjustment, [e.target.name]: parseFloat(value).toFixed() });
      }
    } else {
      setCalciumAdjustment({ ...calciumAdjustment, [e.target.name]: e.target.value });
    }
  };

  const handleEditCalcium = (data, index) => {
    setCalciumAdjustment(data);
    setCalIndex(index);
    setMiniralPopup(!miniralPopup);
  };

  const handleSetEditChl = (data, index) => {
    setChlData(data);
    setIndex(index);
    setChlorinator(!chlorinator);
  };

  const handleDeleteModel = (index) => {
    const currentState = state;
    currentState.splice(index, 1);
    setState([...currentState]);
    setUpdate(true);
  };

  const handleCalciumDeleteModel = (index) => {
    const currentState = state;
    currentState.splice(index, 1);
    setState([...currentState]);
    setUpdate(true);
  };

  const submitRemove = (index) => {
    confirmAlert({
      title: 'Delete Chlorinator Model?',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteModel(index),
        },
        {
          label: 'No',
          onClick: () => ' No ',
        },
      ],
    });
  };
  const removeCalcium = (index) => {
    confirmAlert({
      title: 'Delete Calcium Model?',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleCalciumDeleteModel(index),
        },
        {
          label: 'No',
          onClick: () => ' No ',
        },
      ],
    });
  };

  async function getData() {
    try {
      const newresponse = await userInstance().get('/poolmineral/getAllPoolMinerals');
      const respiData = newresponse?.data?.mineralList;
      // console.log({ respiData });
      const { chemicalsTax, labourTax } = respiData?.taxApplicable || {};
      if (chemicalsTax && labourTax) {
        setTaxStatus(true);
      }
      // console.log({ chemicalsTax, labourTax });
      const costObj = {};
      if (respiData) {
        respiData.chemicalCosts.forEach((el) => {
          if (el.name === 'granularChlorine') {
            costObj.granBuy = el.buycost;
            costObj.granSell = el.sellprice;
          } else if (el.name === 'liquidChlorine') {
            costObj.liqBuy = el.buycost;
            costObj.liqSell = el.sellprice;
          } else if (el.name === 'blackSpotAlgae') {
            costObj.blackBuy = el.buycost;
            costObj.blackSell = el.sellprice;
          }
        });
        setChemCost(costObj);
        // console.log('respiData.chemicalCosts', respiData.chemicalCosts, 'costObj', costObj);
        setGranSelected(respiData.chemicalCosts);
        setDrainState(respiData.drainageSetup);
        setPoolBlackSize(respiData.blackSpotAlgae[16].dosageAndTime);
        setPoolChlorineSize(respiData.greenpoolcalculation[12].dosageAndTime);
        setDrainPoolSize(respiData.acidWashCalculation[6].dosageAndTime);
        setNewArrayStateGreen(respiData.greenpoolcalculation);
        setNewArrayStateBlack(respiData.blackSpotAlgae);
        setFilterData(respiData.sandFilterMedia);
      }
    } catch (error) {
      setLoading(false);
      console.log('error in get data ==>', error);
    }
  }

  useEffect(() => {
    // setLoading(true);
    getData();
  }, [state]);

  const [permission, setPermission] = useState(true);

  // useEffect(() => {
  //   setLoading(true);
  //   // if (userData.accessLevel) {
  //   //   const filteredData = userData.permissions.find((el) => el.action === 'Chemicals Used to Balance Water');
  //   //   console.log('filteredData', filteredData, 'userData.accessLevel', userData.accessLevel);
  //   //   if (filteredData && filteredData.level.includes(userData.accessLevel)) {
  //   //     setPermission(true);
  //   //   } else {
  //   //     setPermission(false);
  //   //   }
  //   // }
  // }, []);

  // async function getGreenData() {
  //   const newresponse = await userInstance().get('/poolmineral/getAllPoolMinerals');

  // }
  // useEffect(() => {
  //   getGreenData();
  // }, []);
  // const handleRequestBalance = () => {
  //   setUpdate(true);
  //   getIdeals();
  // };
  // const [indexValue, setIndexValue] = useState();

  const handleChangeBalance = (e, index) => {
    const old = [...state];
    if (e.target.value === 'Dry Acid' || e.target.value === 'Hydrochloric Acid') {
      old.forEach((el) => {
        if (el.decreaseName === 'Hydrochloric Acid' || el.decreaseName === 'Dry Acid') {
          el.selected = e.target.value;
        }
      });
    }
    old[index].selected = e.target.value;

    setState(old);
    // s = e.target.value;
  };

  // const initialCheckState = {
  //   freeChlorine: true,
  //   totalChlorine: true,
  //   pH: true,
  //   totalAlkalinity: true,
  //   calciumHardness: false,
  //   stabiliser: true,
  //   copper: false,
  //   phosphates: false,
  //   salt: true,
  // };

  // const [checkState, setCheckState] = useState(initialCheckState);

  const handleChecked = (value) => {
    // console.log('values', value);
    setState({ ...state, [value]: !state[value] });
  };

  const handleChangeTaxValues = (e) => {
    const { name, value } = e.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split('.');
      if (decimal?.length > 2) {
        return;
      }
    }
    setState({ ...state, [name]: value });
  };

  const renderView = () => {
    // console.log('view', view);
    let componentToRender = null;
    switch (view) {
      case 'taxApplicable':
        componentToRender = (
          <TaxApplicable
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            handleChangeTaxValues={handleChangeTaxValues}
            state={state}
            error={errorValue}
            taxStatus={taxStatus}
            taxStatusLabour={taxStatusLabour}
          />
        );
        break;

      case 'chemicalsDisplay':
        componentToRender = (
          <ChemicalsDisplay
            handleChecked={handleChecked}
            state={state}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
          />
        );
        break;

      case 'chemicalTargetLevels':
        componentToRender = (
          <ChemistryTarget
            handleChange={handleChange}
            handleAdjust={handleAdjust}
            state={state}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            checkBlankFields={checkBlankFields}
            errorValue={errorValue}
          />
        );
        break;
      case 'chemicalCosts':
        componentToRender = (
          <ChemicalCost
            idealData={idealData}
            handleChange={handleChange}
            handleAdjust={handleAdjust}
            state={state}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            defaultViewState={defaultViewState}
            change={change}
            isUsImperial={isUsImperial}
            removeInCaseOfImperial={removeInCaseOfImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case 'chlorinatorModels':
        componentToRender = (
          <ChlorinatorPage
            handleChlorinator={handleChlorinator}
            renderSort={renderSort}
            setRenderSort={setRenderSort}
            handleChlModelData={handleChlModelData}
            state={state}
            handleSetEditChl={handleSetEditChl}
            submitRemove={submitRemove}
            moveArr={moveArr}
            handleSorting={handleSorting}
          />
        );
        break;
      case 'drainageSetup':
        componentToRender = (
          <div className="dranage-cost-setup">
            <DrainageCost
              handleChangeDrainageCost={handleChangeDrainageCost}
              handleRequest={handleRequest}
              state={state}
              handleRevertViewUpdate={handleRevertViewUpdate}
              handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
              change={change}
              isUsImperial={isUsImperial}
              checkBlankFields={checkBlankFields}
            />
          </div>
        );
        break;
      case 'calciumAdjustments':
        componentToRender = (
          <MiniralPool
            renderSort={renderSort}
            setRenderSort={setRenderSort}
            handleMiniralPopup={handleMiniralPopup}
            state={state}
            handleEditCalcium={handleEditCalcium}
            removeCalcium={removeCalcium}
            handleCalciumModelData={handleCalciumModelData}
            moveArr={moveArr}
            handleSorting={handleSorting}
            handleMineralOrp={handleMineralOrp}
          />
        );
        break;
      case 'chemicalBalanceWater':
        componentToRender = (
          <BalanceWater
            state={state}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleRequest={handleRequest}
            handleChangeBalance={handleChangeBalance}
            chlorineValue={chlorineValue}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            // selected={selected}
          />
        );
        break;
      case 'greenpoolcalculation':
        componentToRender = (
          <GreenPoolCalculation
            greenPool={greenPool}
            state={state}
            view={view}
            handleChange={handleChange}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            count={count}
            newArrayStateGreen={newArrayStateGreen}
            poolChlorineSize={poolChlorineSize}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            chemCost={chemCost}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case 'blackSpotAlgae':
        componentToRender = (
          <BlackSpotAlgae
            greenPool={greenPool}
            state={state}
            view={view}
            handleChange={handleChange}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            count={count}
            newArrayStateGreen={newArrayStateBlack}
            poolChlorineSize={poolBlackSize}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            chemCost={chemCost}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case 'acidWashCalculation':
        componentToRender = (
          <AcidWashCalculation
            drainState={drainState}
            acidWash={acidWash}
            state={state}
            handleChange={handleChange}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            drainPoolSize={drainPoolSize}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            change={change}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}

          />
        );
        break;
      case 'sandFilterMedia':
        componentToRender = (
          <FilterReplacement
            state={state}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            handleFilterChange={handleFilterChange}
            handleRequest={handleRequest}
            change={change}
            filterData={filterData}
            granSelected={granSelected}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}
          />
        );
        break;
      case 'secondVisitCost':
        componentToRender = (
          <SecondVisitCost
            handleChangeSecondVisitCost={handleChangeSecondVisitCost}
            state={state}
            handleRequest={handleRequest}
            handleRevertViewUpdate={handleRevertViewUpdate}
            handleNewRevertDefaultUpdate={handleNewRevertDefaultUpdate}
            withValueLimitSellPrice={withValueLimitSellPrice}
            handleChangeCustom={handleChangeCustom}
            convertToReadableDuration={convertToReadableDuration}
            handleDurationPicker={handleDurationPicker}
            isUsImperial={isUsImperial}
            checkBlankFields={checkBlankFields}

          />
        );
        break;
      default:
        break;
    }
    return componentToRender;
  };

  const updateState = () => {
    let stateName = null;
    switch (view) {
      case 'taxApplicable':
        stateName = taxApplicableState;
        break;
      case 'chemicalTargetLevels':
        stateName = targetLevelState;
        break;
      case 'chemicalCosts':
        stateName = chemicalCostState;
        break;
      case 'chlorinatorModels':
        stateName = [];
        break;
      case 'drainageSetup':
        stateName = drainageCostState;
        break;
      case 'calciumAdjustments':
        stateName = [];
        break;
      case 'chemicalBalanceWater':
        stateName = balanceWaterState;
        break;
      case 'greenpoolcalculation':
        stateName = greenPoolState;
        break;
      case 'blackSpotAlgae':
        stateName = blackSpotAlgaeState;
      case 'acidWashCalculation':
        stateName = drainAcidState;
        break;
      case 'sandFilterMedia':
        stateName = sandFilterMediaState;
        break;
      case 'secondVisitCost':
        stateName = secondVisitCostState;
        break;
      case 'chemicalsDisplay':
        stateName = chemicalDisplayState;
        break;

      default:
        break;
    }
    setState(stateName);
  };

  const checkChlorinatorValues = () => {
    let isValid = true;
    const {
      name, saltLevel, okNegative, okPositive, drainPoint, orp, recmndStabiliserLvl,
    } = chlorinatorData;
    if (!name?.trim()) {
      isValid = false;
      toastMessage('error', 'Name is required.');
      setErrorValue('name');
    } else if (!saltLevel) {
      isValid = false;
      toastMessage('error', 'saltLevel is required.');
      setErrorValue('saltLevel');
    } else if (!okNegative) {
      isValid = false;
      toastMessage('error', 'okNegative is required.');
      setErrorValue('okNegative');
    } else if (!okPositive) {
      isValid = false;
      toastMessage('error', 'okPositive is required.');
      setErrorValue('okPositive');
    } else if (!drainPoint) {
      isValid = false;
      toastMessage('error', 'drainPoint is required.');
      setErrorValue('drainPoint');
    } else if (orp === 'Yes' && !recmndStabiliserLvl) {
      toastMessage('error', 'Fill Manufacturer Recommended Stabiliser Level.');
      isValid = false;
      setErrorValue('recmndStabiliserLvl');
    }

    setTimeout(() => {
      setErrorValue('');
    }, 1000);
    return isValid;
  };

  const handleAddModel = (e) => {
    const isValid = checkChlorinatorValues();
    e.preventDefault();
    if (isValid) {
      const currentState = state;
      currentState.push(chlorinatorData);
      setState([...currentState]);
      setUpdate(true);
      setCalciumAdjustment(chlorinatorModelState);
      handleChlorinator();
    }
  };

  const handleEditModel = (e) => {
    const isValid = checkChlorinatorValues();
    e.preventDefault();
    if (isValid) {
      const currentState = state;
      currentState[selectedIndexModel] = chlorinatorData;
      setState([...currentState]);
      setUpdate(true);
      setChlData(chlorinatorModelState);
      handleChlorinator();
    }
  };

  const checkCalciumValue = () => {
    const {
      okPositive, okNegative, saltLevel, drainPoint, orp, stabiliserLevel,
    } = calciumAdjustment;
    const validationObj = {
      saltLevel,
      okNegative,
      okPositive,
      drainPoint,
    };
    let { isValid, emptyField } = validateDataWithKey(validationObj);
    let message = '';
    if (!isValid) message = `${emptyField} is required`;
    if (isValid && orp === 'Yes' && !stabiliserLevel?.trim()) {
      message = 'Fill Manufacturer Recommended Stabiliser Level';
      emptyField = 'stabiliserLevel';
      isValid = false;
    }
    if (message) {
      toastMessage('error', message);
      setErrorValue(emptyField);
      setTimeout(() => {
        setErrorValue('');
      }, 1000);
    }
    return isValid;
  };

  const handleAddCalciumModel = (e) => {
    e.preventDefault();
    const { brandName, magnesium, calcium } = calciumAdjustment;
    const isValid = checkCalciumValue();
    if (isValid) {
      if (parseFloat(magnesium) + parseFloat(calcium) === 100) {
        const currentState = state;
        currentState.push(calciumAdjustment);
        setState([...currentState]);
        setUpdate(true);
        setCalciumAdjustment(calciumAdjustmentState);
        setMiniralPopup(!miniralPopup);
      } else {
        toastMessage('error', 'Magnesium percentage & Calcium percentage must equal 100 percent.');
      }
    }
  };

  const handleEditCalciumModel = (e) => {
    e.preventDefault();
    const { brandName, magnesium, calcium } = calciumAdjustment;
    const isValid = checkCalciumValue();
    if (isValid) {
      if (parseFloat(calciumAdjustment.magnesium) + parseFloat(calciumAdjustment.calcium) === 100) {
        const currentState = state;
        currentState[calIndex] = calciumAdjustment;
        setState([...currentState]);
        setUpdate(true);
        setCalciumAdjustment(calciumAdjustmentState);
        setMiniralPopup(!miniralPopup);
      } else {
        toastMessage('error', 'Magnesium percentage & Calcium percentage must equal 100 percent.');
      }
    }
  };
  const handleNewRevertDefaultUpdate = (e, data) => {
    // setChange('');
    let defaultValues = null;
    switch (view) {
      case 'chemicalTargetLevels':
        defaultValues = defaultViewState;
        break;
      case 'chemicalCosts':
        defaultValues = defaultViewState;
        break;
      case 'drainageSetup':
        defaultValues = defaultViewState;
        break;
      case 'chemicalBalanceWater':
        defaultValues = defaultViewState;
        break;
      case 'greenpoolcalculation':
        defaultValues = defaultViewState;
        break;
      case 'blackSpotAlgae':
        defaultValues = defaultViewState;
        break;
      case 'acidWashCalculation':
        defaultValues = defaultViewState;
        break;
      case 'sandFilterMedia':
        defaultValues = defaultViewState;
        break;
      case 'secondVisitCost':
        defaultValues = defaultViewState;
        break;
      case 'chemicalsDisplay':
        defaultValues = defaultViewState;
        break;

      default:
        break;
    }

    if (view === 'drainageSetup') {
      setState(defaultValues);
    } else if (view === 'secondVisitCost') {
      setState(defaultValues);
    } else if (view === 'taxApplicable') {
      setState(defaultValues);
    } else if (view === 'chemicalsDisplay') {
      setState(defaultValues);
    } else {
      setState([...defaultValues]);
    }
    if (view === 'greenpoolcalculation') {
      getData();
    }
    setUpdate(true);
    setManagementPrevious(true);
  };

  const handleRevertViewUpdate = (e, data) => {
    setChange('');
    setdata(state);
    let defaultValues = null;
    switch (view) {
      case 'taxApplicable':
        defaultValues = defaultTaxApplicableState;
        break;
      case 'chemicalTargetLevels':
        defaultValues = targetLevelDefaultState;
        break;
      case 'chemicalCosts':
        defaultValues = chemicalCostDefaultState;
        break;
      case 'drainageSetup':
        defaultValues = drainageCostDefaultState;
        break;
      case 'chemicalBalanceWater':
        defaultValues = chemicalBalanceWaterDefaultState;
        break;
      case 'greenpoolcalculation':
        defaultValues = greenPoolCalculationDefaultState;
        break;
      case 'blackSpotAlgae':
        defaultValues = blackSpotAlgaeDefaultState;
        break;
      case 'acidWashCalculation':
        defaultValues = drainAcidDefaultState;
        break;
      case 'sandFilterMedia':
        defaultValues = sandFilterMediaDefaultState;
        break;
      case 'secondVisitCost':
        defaultValues = defaultSecondVisitState;
        break;
      case 'chemicalsDisplay':
        defaultValues = defaultChemicalDisplayState;
        break;

      default:
        break;
    }
    // console.log('Revert Data =>', defaultValues);
    if (view === 'drainageSetup') {
      setState(defaultValues);
    } else if (view === 'secondVisitCost') {
      setState(defaultValues);
    } else if (view === 'taxApplicable') {
      setState(defaultValues);
    } else if (view === 'chemicalsDisplay') {
      setState(defaultValues);
    } else {
      setState([...defaultValues]);
    }
    if (view === 'greenpoolcalculation') {
      getData();
    }
    setUpdate(true);
    setManagementPrevious(true);
  };

  useEffect(() => {
    if (isUpdate === null) {
      updateState();
      getIdeals();
    }
    if (isUpdate === true) {
      handleRequest();
    }
  }, [view, isUpdate]);

  useEffect(() => {
    if (view) {
      chck();
    }
  }, [view]);

  // useEffect(() => {
  //   chck();
  //   console.log('morehappy');
  // }, [count]);
  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  const PoolArrayData = [
    {
      viewName: 'chemicalTargetLevels',
      name: 'Chemistry Target Levels',
      imageSource: target,
    },
    {
      viewName: 'chemicalsDisplay',
      name: 'Calculator Chemicals to Display',
      imageSource: target,
    },
    {
      viewName: 'chemicalCosts',
      name: 'Chemical Costs & Margins',
      imageSource: percentage,
    },
    {
      viewName: 'chlorinatorModels',
      name: 'Chlorinator Models',
      imageSource: chlorinatorimg,
    },
    {
      viewName: 'drainageSetup',
      name: 'Drainage Cost',
      imageSource: d,
    },
    {
      viewName: 'calciumAdjustments',
      name: 'Mineral Pool Calcium Adjustment',
      imageSource: ca,
    },
    {
      viewName: 'chemicalBalanceWater',
      name: 'Chemicals Used to Balance Water',
      imageSource: greyplus,
    },
    {
      viewName: 'greenpoolcalculation',
      name: 'Green Pool Calculations',
      imageSource: target,
    },
    {
      viewName: 'blackSpotAlgae',
      name: 'Black-Spot Algae Calculations',
      imageSource: target,
    },
    {
      viewName: 'acidWashCalculation',
      name: 'Drain, Pressure Wash, Chlorine & Acid Wash Calculations',
      imageSource: target,
    },
    {
      viewName: 'sandFilterMedia',
      name: 'Sand Filter Media Replacement',
      imageSource: target,
    },
    {
      viewName: 'secondVisitCost',
      name: 'Second Visit Costs',
      imageSource: d,
    },

  ];

  useEffect(() => {
    try {
      if (userData?.permissions?.length > 0) {
        // console.log('comingUnderthis');
        let allViewNames = [];
        userData?.permissions?.forEach((el) => {
          if (el.category === 'pool set-up') {
            allViewNames.push(el.action);
          }
        });
        // console.log('allViewNames', allViewNames);
        const result = PoolArrayData.findIndex((el) => allViewNames?.includes(el.name));
        const nameOfView = PoolArrayData[result]?.viewName;
        const nameOfRevertView = (nameOfView === 'chlorinatorModels' || nameOfView === 'calciumAdjustments')
          ? '' : `revert${nameOfView}`;
        setView(nameOfView);
        setActiveLink(nameOfView);
        setNewView(nameOfRevertView);
        setLoading(true);
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  }, [userData?.permissions?.length]);

  return (
    <Adminwrapper>
      {loading ? <Loader /> : (
        <div className=" pool-set-up">
          <div className="container">
            <div className="row webview-pool-section">
              <div className="col-md-12">
                <div className="top-heading">
                  <h1>Pool Setup</h1>
                </div>
                <div className="dropdown-option-section">
                  {PoolArrayData.map((el) => (
                    <CheckSetUpPermissions
                      name={el?.name}
                      viewName={el?.viewName}
                      updateView={updateView}
                      imageSource={target}
                      classType="option-list"
                      activeLink={activeLink}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="pool-bg">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-titles-mob">
                    <h3>Pool Set-up</h3>
                    <div className="content-dropdown">
                      <div className="client-option service-edit-icon">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={showmore} alt="show" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {/* <div className="action" onClick={() => updateView('chemicalTargetLevels')}>
                          <span>
                            <img src={target} alt="edit" />
                          </span>
                          <span> Chemical Used to Balance Water</span>
                        </div> */}

                            {PoolArrayData.map((el) => (
                              <Dropdown.Item>
                                <CheckSetUpPermissions
                                  name={el?.name}
                                  viewName={el?.viewName}
                                  updateView={updateView}
                                  imageSource={target}
                                  classType="action"
                                />
                              </Dropdown.Item>
                            ))}

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {/* <ChemistryTarget idealData={idealData} handleRevertDefaultAdjust={handleRevertDefaultAdjust} handleChange={handleChange} handleAdjust={handleAdjust} />
              <ChemicalCost idealData={idealData} handleUpdateIdeals={handleUpdateIdeals} handleChange={handleChange} />
              <ChlorinatorPage handleChlorinator={handleChlorinator} renderSort={renderSort} setRenderSort={setRenderSort} />
              <div className="dranage-cost-setup">
                <DrainageCost />
              </div>
              <MiniralPool renderSort={renderSort} setRenderSort={setRenderSort} handleMiniralPopup={handleMiniralPopup} /> */}
                  {renderView()}
                  {/* <BalanceWater /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Chlorinator
        chlorinator={chlorinator}
        setChlorinator={setChlorinator}
        handleChlorinator={handleChlorinator}
        handleChlModelData={handleChlModelData}
        chlorinatorData={chlorinatorData}
        handleAddModel={handleAddModel}
        selectedIndexModel={selectedIndexModel}
        handleEditModel={handleEditModel}
        orpProbe={orpProbe}
        setOrpProbe={setOrpProbe}
        handleOrp={handleOrp}
        handleChlorinatorNumberChange={handleChlorinatorNumberChange}
        limitNumber={limitNumber}
        error={errorValue}
      />
      <MiniralPopup
        limitNumber={limitNumber}
        handleNumberChange={handleNumberChange}
        miniralPopup={miniralPopup}
        setMiniralPopup={setMiniralPopup}
        handleMiniralPopup={handleMiniralPopup}
        calciumAdjustment={calciumAdjustment}
        handleEditCalciumModel={handleEditCalciumModel}
        handleAddCalciumModel={handleAddCalciumModel}
        handleCalciumModelData={handleCalciumModelData}
        calIndex={calIndex}
        handleMineralOrp={handleMineralOrp}
        error={errorValue}
      />
      {durationPicker && (
      <DurationPicker
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        durationData={durationData}
        handleDurationPicker={handleDurationPicker}
        setDuration={setDuration}
        setBookingData={setState}
        convertToReadableDuration={convertToReadableDuration}
        bookingData={state}
        subcategory={null}
        setSubCategory={null}
      />
      )}
    </Adminwrapper>
  );
}

export default PoolSetUp;
