/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button, Modal, Dropdown, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import ChangeTextPopup from './changeTextPopup';
import SaveTemplatePopup from './savetemplatePopup';
import showmore from '../../assets/home/showmoreblue.png';
import TestingTemplate from './testinghtml';
import PreviewTemplate from './previewInvoice';
import AddNewTextPopuppage from './addnewTextPopup';
import EditNewTextPopup from './editnewTextPopup';
import AddNewImage from './addNewImage';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import TemplateSection from './templateSection';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';
import SaveDefaultPopup from '../quoteTemplates/saveDefaultPopup';
import { TemplateFeatures } from '../../contexts/TemplateFeatureContext';
import SaveTemplateConfirmPopup from './save-template-confirm-popup';

const InvoiceTemplate = () => {
  const {
    locationsInfo, userData,
  } = useContext(AuthDataContext);
  const {
    // states related to this component- start
    savePopup,
    setSavePopup,
    defaulttype,
    setDefaulttype,
    view,
    setView,
    templateName,
    setTemplateName,
    handlesavePopup,
    // states related to this component- end
    dummyView,
    setDummyView,
    isNewChange,
    setIsNewChange,
    saveTemplateConfirmPopup,
    setSaveTemplateConfirmPopup,
    setSaveConfirmPopupValue,
    isParentClickDropdown,
    setIsParentClickDropdown,
    setSaveTemplateSelectedDropdown,
    saveTemplateSelectedDropdown,
    dummyMainPageView,
    setMainView,
  } = useContext(TemplateFeatures);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';

  const { companyContactDetail, companyInformation } = locationsInfo || {};
  const {
    address, companyName, postcode, state, suburb,
  } = companyInformation || {};
  // console.log('companyInformation ==>', companyInformation);
  const {
    mobile, email,
  } = companyContactDetail || {};
  let companyaddress = `${address || ''} ${suburb || ''} ${state || ''} ${postcode || ''}`;
  let companyLocations = companyaddress !== '  ' ? companyaddress : '';
  // console.log('companyLocations =>', companyLocations);
  const dataAU = [
    {
      text: '',
      url: '',
      type: 'img',
      textFeild: false,
      index: 0,
      placeholder: 'Company Logo',
      style: {
        lineHeight: '9px',
        imageHeight: '50px',
        imageWidth: '100px',
        imageRotate: 0,
        imageUrl: '',
        zIndex: 9,
        top: '0px',
        left: '5px',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        textAlign: 'left',
        height: 'fit-content',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: companyLocations || '5/607 St Kilda Road Melboume VIC 3004',
      type: 'tr1',
      textFeild: true,
      index: 1,
      placeholder: 'Company Location',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '0px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: mobile || '0409 774 687',
      type: 'tr2',
      textFeild: false,
      index: 2,
      placeholder: 'Mobile No',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '10px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '0px',
      },
    },
    {
      text: email || 'www.cleanpools.com.au',
      type: 'tr3',
      textFeild: false,
      index: 3,
      placeholder: 'Email',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '25.60px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '0px',
        top: '1',
      },
    },
    {
      text: '[InvoiceType]',
      type: 'tr4',
      textFeild: false,
      staticFeild: 'Tax Invoice',
      index: 4,
      placeholder: 'InvoiceType',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '56.13px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '15px',
      },
    },
    {
      text: companyName || 'TypeCraft Pvt Ltd trading as CleanPools Pool maintanace experts ABN 80 007 007 007',
      type: 'tr4',
      textFeild: false,
      index: 5,
      placeholder: 'Company Name',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '56.13px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '15px',
      },
    },
    {
      text: 'Invoice #',
      type: 'tr5',
      textFeild: true,
      index: 6,
      placeholder: 'Invoice',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '74.13px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '15px',
      },
    },
    {
      text: '[Invoice]',
      type: 'tr5',
      textFeild: false,
      staticFeild: '5456456',
      index: 7,
      placeholder: 'Invoice NO',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '74.13px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Bill To',
      type: 'tr6',
      textFeild: true,
      index: 8,
      placeholder: 'Bill To',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '100.13px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Date',
      type: 'tr6',
      textFeild: true,
      index: 9,
      placeholder: 'Date',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '100.13px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Date]',
      type: 'tr6',
      textFeild: false,
      staticFeild: new Date().toLocaleDateString(),
      index: 10,
      placeholder: 'Date',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '100.13px',
        left: '415px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Billing Address]',
      type: 'tr7',
      textFeild: false,
      staticFeild: 'PAUL - 21 VERMON STREET VIC 3146',
      index: 11,
      placeholder: 'Billing Address',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '130.67px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '50px',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '50px',
      },
    },
    {
      text: 'Description',
      type: 'tr8',
      textFeild: true,
      index: 12,
      placeholder: 'Description',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '205.47px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Goods/Services Cost',
      type: 'tr8',
      textFeild: true,
      index: 13,
      placeholder: 'Goods/Services Cost',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '205.47px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Description]',
      type: 'tr9',
      textFeild: false,
      staticFeild: [{ decsription: '22.8.21 - Leak Detection', cost: ' $400.00' },
        { decsription: '  22.8.21 - Supply & install', cost: '$3000.00' },
        { decsription: ' 22.8.21 - Supply & install ', cost: '$350.00' }],
      index: 14,
      placeholder: 'Description',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '217.33px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Ex Amount]',
      type: 'tr9',
      textFeild: false,
      index: 15,
      placeholder: 'Ex Amount',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '217.33px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'E & O.E - Payment Terms',
      type: 'tr10',
      textFeild: true,
      index: 16,
      placeholder: 'E & O.E - Payment Terms',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '685px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'SubTotal',
      type: 'tr10',
      index: 17,
      textFeild: true,
      placeholder: 'SubTotal',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '685px',
        left: '340px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Subtotal]',
      type: 'tr10',
      staticFeild: '$3,750.00',
      textFeild: false,
      index: 18,
      placeholder: 'Subtotal',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '685px',
        left: '480px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'GST',
      type: 'tr11',
      textFeild: true,
      index: 19,
      placeholder: 'GST',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '702px',
        left: '340px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Tax]',
      type: 'tr11',
      textFeild: false,
      staticFeild: '$375.00',
      index: 20,
      placeholder: 'Tax',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '702px',
        left: '480px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'EFT Details',
      textFeild: true,
      type: 'tr12',
      index: 21,
      placeholder: 'EFT Details',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '738px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Invoice Total',
      textFeild: true,
      type: 'tr12',
      index: 22,
      placeholder: 'Invoice Total',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '738px',
        left: '340px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Total]',
      textFeild: false,
      staticFeild: '$4,125.00',
      type: 'tr12',
      index: 23,
      placeholder: 'Total',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '738px',
        left: '480px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Account Name',
      type: 'tr13',
      textFeild: true,
      index: 24,
      placeholder: 'Account Name',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '722px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Clean Pools',
      textFeild: true,
      type: 'tr13',
      index: 25,
      placeholder: 'Clean Pools',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '722px',
        left: '82px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Amount Paid',
      textFeild: true,
      type: 'tr13',
      index: 26,
      placeholder: 'Amount Paid',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '722px',
        left: '340px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Applied]',
      type: 'tr13',
      staticFeild: '$0.00',
      textFeild: false,
      index: 27,
      placeholder: 'Applied',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '722px',
        left: '480px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle4: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'BSB',
      type: 'tr14',
      textFeild: true,
      index: 28,
      placeholder: 'BSB',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '759px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '063-138',
      type: 'tr14',
      textFeild: true,
      index: 29,
      placeholder: '063-138',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '759px',
        left: '32px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Account No',
      type: 'tr15',
      textFeild: true,
      index: 30,
      placeholder: 'Account No',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '777px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },

    },
    {
      text: '2165450',
      textFeild: true,
      type: 'tr15',
      index: 31,
      placeholder: 'Account No',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '777px',
        left: '59px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Balance Due',
      type: 'tr15',
      textFeild: true,
      index: 32,
      placeholder: 'Balance Due',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '777px',
        left: '340px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Balance]',
      type: 'tr15',
      textFeild: false,
      staticFeild: '$4,125.00',
      index: 33,
      placeholder: 'Balance',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '777px',
        left: '480px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle4: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
  ];

  const dataUS = [
    {
      text: '',
      url: '',
      type: 'img',
      textFeild: false,
      index: 0,
      placeholder: 'Company Logo',
      style: {
        lineHeight: '9px',
        imageHeight: '50px',
        imageWidth: '100px',
        imageRotate: 0,
        imageUrl: '',
        zIndex: 9,
        top: '0px',
        left: '5px',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        textAlign: 'left',
        height: 'fit-content',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: companyLocations || '5/607 St Kilda Road Melboume VIC 3004',
      type: 'tr1',
      textFeild: true,
      index: 1,
      placeholder: 'Company Location',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '0px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',

      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: mobile || '0409 774 687',
      type: 'tr2',
      textFeild: false,
      index: 2,
      placeholder: 'Mobile No',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '10px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '0px',
      },
    },
    {
      text: email || 'www.cleanpools.com.au',
      type: 'tr3',
      textFeild: false,
      index: 3,
      placeholder: 'Email',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '25.60px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '0px',
        top: '1',
      },
    },
    {
      text: '[InvoiceType]',
      type: 'tr4',
      textFeild: false,
      staticFeild: 'Tax Invoice',
      index: 4,
      placeholder: 'InvoiceType',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '56.13px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '15px',
      },
    },
    {
      text: companyName || 'TypeCraft Pvt Ltd trading as CleanPools Pool maintanace experts ABN 80 007 007 007',
      type: 'tr4',
      textFeild: false,
      index: 5,
      placeholder: 'Company Name',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '56.13px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '15px',
      },
    },
    {
      text: 'Invoice #',
      type: 'tr5',
      textFeild: true,
      index: 6,
      placeholder: 'Invoice',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '74.13px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginBottom: '0px',
        marginTop: '15px',
      },
    },
    {
      text: '[Invoice]',
      type: 'tr5',
      textFeild: false,
      staticFeild: '5456456',
      index: 7,
      placeholder: 'Invoice NO',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '74.13px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Bill To',
      type: 'tr6',
      textFeild: true,
      index: 8,
      placeholder: 'Bill To',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '100.13px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Date',
      type: 'tr6',
      textFeild: true,
      index: 9,
      placeholder: 'Date',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '100.13px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Date]',
      type: 'tr6',
      textFeild: false,
      staticFeild: new Date().toLocaleDateString(),
      index: 10,
      placeholder: 'Date',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '100.13px',
        left: '546.2px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '15px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Billing Address]',
      type: 'tr7',
      textFeild: false,
      staticFeild: 'PAUL - 21 VERMON STREET VIC 3146',
      index: 11,
      placeholder: 'Billing Address',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '130.67px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '50px',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '50px',
      },
    },
    {
      text: 'Description',
      type: 'tr8',
      textFeild: true,
      index: 12,
      placeholder: 'Description',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '205.47px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Goods/Services Cost',
      type: 'tr8',
      textFeild: true,
      index: 13,
      placeholder: 'Goods/Services Cost',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '205.47px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Description]',
      type: 'tr9',
      textFeild: false,
      staticFeild: [{ decsription: '22.8.21 - Leak Detection', cost: ' $400.00' },
        { decsription: '  22.8.21 - Supply & install', cost: '$3000.00' },
        { decsription: ' 22.8.21 - Supply & install ', cost: '$350.00' }],
      index: 14,
      placeholder: 'Description',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '217.33px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Ex Amount]',
      type: 'tr9',
      textFeild: false,
      index: 15,
      placeholder: 'Ex Amount',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '217.33px',
        left: '516px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'E & O.E - Payment Terms',
      type: 'tr10',
      textFeild: true,
      index: 16,
      placeholder: 'E & O.E - Payment Terms',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '840px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'SubTotal',
      type: 'tr10',
      index: 17,
      textFeild: true,
      placeholder: 'SubTotal',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '840px',
        left: '498px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Subtotal]',
      type: 'tr10',
      staticFeild: '$3,750.00',
      textFeild: false,
      index: 18,
      placeholder: 'Subtotal',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '840px',
        left: '605px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'GST',
      type: 'tr11',
      textFeild: true,
      index: 19,
      placeholder: 'GST',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '858px',
        left: '498px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Tax]',
      type: 'tr11',
      textFeild: false,
      staticFeild: '$375.00',
      index: 20,
      placeholder: 'Tax',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '858px',
        left: '605px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'EFT Details',
      textFeild: true,
      type: 'tr12',
      index: 21,
      placeholder: 'EFT Details',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '890px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Invoice Total',
      textFeild: true,
      type: 'tr12',
      index: 22,
      placeholder: 'Invoice Total',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '890px',
        left: '498px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Total]',
      textFeild: false,
      staticFeild: '$4,125.00',
      type: 'tr12',
      index: 23,
      placeholder: 'Total',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '890px',
        left: '605px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Account Name',
      type: 'tr13',
      textFeild: true,
      index: 24,
      placeholder: 'Account Name',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '874px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Clean Pools',
      textFeild: true,
      type: 'tr13',
      index: 25,
      placeholder: 'Clean Pools',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '874px',
        left: '82px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Amount Paid',
      textFeild: true,
      type: 'tr13',
      index: 26,
      placeholder: 'Amount Paid',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '874px',
        left: '498px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Applied]',
      type: 'tr13',
      staticFeild: '$0.00',
      textFeild: false,
      index: 27,
      placeholder: 'Applied',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '874px',
        left: '605px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle4: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'BSB',
      type: 'tr14',
      textFeild: true,
      index: 28,
      placeholder: 'BSB',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '905px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '063-138',
      type: 'tr14',
      textFeild: true,
      index: 29,
      placeholder: '063-138',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '905px',
        left: '32px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Account No',
      type: 'tr15',
      textFeild: true,
      index: 30,
      placeholder: 'Account No',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '922px',
        left: '5px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },

    },
    {
      text: '2165450',
      textFeild: true,
      type: 'tr15',
      index: 31,
      placeholder: 'Account No',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '922px',
        left: '59px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: 'Balance Due',
      type: 'tr15',
      textFeild: true,
      index: 32,
      placeholder: 'Balance Due',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '922px',
        left: '498px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle3: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: '[Balance]',
      type: 'tr15',
      textFeild: false,
      staticFeild: '$4,125.00',
      index: 33,
      placeholder: 'Balance',
      style: {
        lineHeight: '12px',
        fontFamily: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '922px',
        left: '605px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle4: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
  ];

  const [templateData, setTemplateData] = useState(countryType === 'us' ? dataUS : dataAU);
  const [templateDataCopy, setTemplateDataCopy] = useState(countryType === 'us' ? dataUS : dataAU);
  const [templateDataCopy2, setTemplateDataCopy2] = useState(countryType === 'us' ? dataUS : dataAU);
  const [showEdit, setShowEdit] = useState(false);
  const [customFeilds, setCustomFeilds] = useState([]);
  const [arrayOne, setArrayOne] = useState(countryType === 'us' ? dataUS : dataAU);
  const [loading, setLoading] = useState(false);
  const [defaultStatus, setDefaultStatus] = useState();
  const [tableNo, setTableNo] = useState(0);
  const [defaultTemplateId, setDefaultTemplateId] = useState(null);
  const [templateArray, setTemplateArray] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [textPopup, setTextPopup] = useState(false);
  const [customName, setCustomName] = useState('');
  const [isDefaultSelect, setIsDefaultSelect] = useState(false);
  const [zoomin, setZoomIn] = useState(false);
  const [previewPopup, setPreviewPopup] = useState();
  const [editTemplate, setEditTemplate] = useState();
  const [addTextpopup, setAddTextPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const [texts, setText] = useState('');
  const [addImagepopup, setAddImagePopup] = useState(false);
  const [editImagepopup, setEditImagePopup] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [placeholderText, setPlaceholderText] = useState('');
  const [tdIndex, settdIndex] = useState('');
  const [isEdit, setisEdit] = useState(false);
  const [widthValue, setWidthValue] = useState();
  const [isloading, setIsloading] = useState(true);
  const [defaultPopup, setDefaultPopup] = useState(false);

  const handleShow = () => {
    setShowEdit(true);
  };
  const handleClose = () => {
    setShowEdit(false);
  };

  const AddImagePopup = () => {
    setAddImagePopup(false);
  };

  const OpenAddImagePopup = () => {
    setAddImagePopup(true);
  };

  const EditImagePopup = () => {
    setEditImagePopup(false);
  };

  const OpenEditImagePopup = () => {
    setEditImagePopup(true);
  };

  const handleImageNew = (dataa, info, k) => {
    setText(dataa);
    setCustomName(info);
    setIndex(k);
    OpenEditImagePopup();
  };

  const AddNewTextPopup = () => {
    setAddTextPopup(false);
  };

  const OpenAddNewTextPopup = () => {
    setAddTextPopup(true);
  };
  const handleTextPopup = () => {
    setTextPopup(false);
  };

  const handleImagePopup = () => {
    setShowPopup(false);
    setAddImagePopup(false);
  };

  const handleImagePopupOpen = (k) => {
    setAddImagePopup(true);
    setIndex(k);
    // setShowPopup(true);
  };

  const setData = () => {
    setTemplateData(templateDataCopy);
    handleTextPopup();
    handleImagePopup();
    AddNewTextPopup();
  };
  const handleText = (dataa, info, k, f, plcText, tdindex, e) => {
    let copyDataObj = { ...dataa };
    const child = e.target.getBoundingClientRect();
    if (!isEdit) {
      return;
    }
    let oldtempObject = [...templateDataCopy];
    let newWidth = child.width.toFixed(2);
    oldtempObject[k][tdindex].width = `${newWidth}px`;
    oldtempObject[k].style = copyDataObj;
    setTemplateDataCopy(oldtempObject);
    setPlaceholderText(plcText);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
    setTableNo(f);
    settdIndex(tdindex);
    setTextPopup(true);
  };
  const handleMoveText = (dataa, info, k, f, plcText, tdindex, top, left) => {
    let topVal = top.toFixed(2);
    let leftVal = left.toFixed(2);
    let copyDataObj = { ...dataa };
    copyDataObj.top = `${topVal}px`;
    copyDataObj.left = `${leftVal}px`;
    if (!isEdit) {
      return;
    }
    let oldtempObject = [...templateDataCopy];
    oldtempObject[k].style = copyDataObj;
    setTemplateDataCopy(oldtempObject);
    setPlaceholderText(plcText);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
    setTableNo(f);
    settdIndex(tdindex);
  };

  const handleTextNew = (dataa, info, k, e) => {
    let copyDataObj = { ...dataa };
    const child = e.target.getBoundingClientRect();
    let oldtempObject = [...customFeilds];
    oldtempObject[k].style = copyDataObj;
    oldtempObject[k].style.position = 'initial';
    setCustomFeilds(oldtempObject);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
    handleShow();
  };

  const handleTextNewMove = (dataa, info, k, top, left) => {
    let topVal = top.toFixed(2);
    let leftVal = left.toFixed(2);
    let copyDataObj = { ...dataa };
    copyDataObj.top = `${topVal}px`;
    copyDataObj.left = `${leftVal}px`;
    let oldtempObject = [...customFeilds];
    oldtempObject[k].style = copyDataObj;
    oldtempObject[k].style.position = 'initial';
    setCustomFeilds(oldtempObject);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
  };
  const saveTemplate = async () => {
    try {
      if (templateName) {
        if (view === 'addTemplate') {
          const formdata = new FormData();
          formdata.append('templateData', JSON.stringify(templateData));
          // formdata.append('templateDataOne', JSON.stringify(templateDataOne));
          formdata.append('templateDataOne', JSON.stringify([]));
          formdata.append('customFeilds', JSON.stringify(customFeilds));
          formdata.append('templateName', templateName);
          formdata.append('file', templateData[0].url);
          formdata.append('defaultStatus', defaultStatus);
          formdata.append('type', 'Invoice');
          formdata.append('defaulttype', defaulttype);
          const response = await notTimeOutUserInstance().post('/template/addTemplate', formdata);
          const { status } = response || {};
          const { msg } = response.data;
          if (msg === 'success') {
            setTemplateData(countryType === 'us' ? dataUS : dataAU);
            setTemplateDataCopy(countryType === 'us' ? dataUS : dataAU);
            // setTemplateDataOne(data2);
            // setTemplateDataCopyOne(data2);
            setCustomFeilds([]);
            toastMessage('success', msg);
            setSavePopup();
          }
          if (status === 201) {
            console.log('status------------');
            toastMessage('error', msg);
          }
        } else {
          const formdata = new FormData();
          formdata.append('templateData', JSON.stringify(templateData));
          // formdata.append('templateDataOne', JSON.stringify(templateDataOne));
          formdata.append('customFeilds', JSON.stringify(customFeilds));
          formdata.append('templateName', templateName);
          formdata.append('file', templateData[0].url);
          formdata.append('id', defaultTemplateId);
          formdata.append('type', 'Invoice');
          formdata.append('defaulttype', defaulttype);
          const response = await notTimeOutUserInstance().post('/template/editTemplate', formdata);
          // console.log(response);
          if (defaulttype === 'default') {
            getAllTemplate();
          }

          const { msg, tempId } = response.data;
          setDefaultTemplateId(tempId._id);
          setTemplateName(tempId.templateName);
          setTemplateData(tempId.currentTemplateData);
          setTemplateDataCopy(tempId.currentTemplateData);
          // setTemplateDataOne(tempId.currentTemplateDataOne);
          // setTemplateDataCopyOne(tempId.currentTemplateDataOne);
          setCustomFeilds(tempId.customFeilds);
          toastMessage('success', msg);
          setSavePopup();
        }
        // Default the templateconfirmSavePopup
        // start
        setSaveConfirmPopupValue(false);
        setSaveConfirmPopupValue('save-before-leave');
        // end
      } else {
        toastMessage('error', 'Please enter template name.');
      }
    } catch (error) {
      console.log(error);
    }
    if (isParentClickDropdown) {
      setMainView(dummyMainPageView);
      setIsParentClickDropdown(false);
    } else if (saveTemplateSelectedDropdown) {
      await setTemp(saveTemplateSelectedDropdown);
    } else {
      setView(dummyView);
    }
    setIsNewChange(false);
  };
  const handleZoomin = () => {
    setZoomIn(!zoomin);
  };
  const handlePreviewPopup = async () => {
    try {
      setPreviewPopup(!previewPopup);
      setLoading(true);
      const payload = {
        templateData,
        customFeilds,
        countryType,
      };
      console.log('preview ======>', countryType);
      const response = await userInstance().post('/template/previewTemplatePdf', payload);

      const { code, msg, tempId } = response.data;
      setPreviewUrl(tempId);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUrl = async () => {
    const payload = {
      previewUrl,
    };
    const response = await userInstance().post('/template/removeTemplateFroms3', payload);
    if (response) {
      setPreviewUrl(null);
    }
    setPreviewPopup(false);
  };

  const handleDefaultPopup = (type) => {
    setDefaultPopup(!defaultPopup);
    setDefaulttype(type);
    if (view === 'addTemplate') {
      setTemplateName('');
    }
  };

  const handleDataPopup = (dataa) => {
    setTemplateName(dataa);
  };
  const renderedView = () => {
    let componentToRender = null;
    switch (view) {
      case 'InvoiceTemplate':
        componentToRender = (
          <TemplateSection
            arrayOne={arrayOne}
            customFeilds={customFeilds}
          />
        );
        break;
      case 'editTemplate':
        componentToRender = (
          <TestingTemplate
            templateData={templateData}
            // templateDataOne={templateDataOne}
            handleText={handleText}
            handleImagePopupOpen={handleImagePopupOpen}
            customFeilds={customFeilds}
            setCustomFeilds={setCustomFeilds}
            handleTextNew={handleTextNew}
            handleImageNew={handleImageNew}
            defaultTemplateId={defaultTemplateId}
            handleMoveText={handleMoveText}
            handleTextNewMove={handleTextNewMove}
            view={view}
           // handlebottomText={handlebottomText}

          />
        );
        break;
      case 'addTemplate':
        componentToRender = (
          <TestingTemplate
            templateData={templateData}
            // templateDataOne={templateDataOne}
            handleText={handleText}
            handleImagePopupOpen={handleImagePopupOpen}
            customFeilds={customFeilds}
            setCustomFeilds={setCustomFeilds}
            handleTextNew={handleTextNew}
            handleImageNew={handleImageNew}
            setTemplateDataCopy={setTemplateDataCopy}
            handleMoveText={handleMoveText}
            handleTextNewMove={handleTextNewMove}
            view={view}
           // handlebottomText={handlebottomText}
          />
        );
        break;
      default:
        break;
    }
    return componentToRender;
  };
  const updateView = (key, type) => {
    if (isNewChange && view !== key) {
      setDummyView(key);
      setSaveTemplateConfirmPopup(!saveTemplateConfirmPopup);
      setIsParentClickDropdown(false);
      return;
    }
    setView(key);
    if (key === 'addTemplate') {
      setTemplateName('');
      setTemplateData(countryType === 'us' ? dataUS : dataAU);
      setTemplateDataCopy(countryType === 'us' ? dataUS : dataAU);
      setCustomFeilds([]);
      // setTemplateDataOne(data2);
      // setTemplateDataCopyOne(data2);
    } else {
      getAllTemplate(key);
    }
    if (type === 'isedit' || key === 'addTemplate') {
      setisEdit(true);
    } else {
      setisEdit(false);
    }
  };

  const getAllTemplate = async (info) => {
    try {
      const response = await userInstance().get('/template/getAllTemplate?type=Invoice');
      const { code, msg, temp } = response.data;
      if (temp.length > 0) {
        setDefaultStatus(false);
        const defaultTemp = temp.filter((el, i) => el.default);
        setTemplateArray(temp);
        if (defaultTemp.length > 0) {
          setIsDefaultSelect(true);
          setDefaultTemplateId(defaultTemp[0]._id);
          setTemp(defaultTemp[0]._id);
          let updated = defaultTemp[0].currentTemplateData;
          // updated[1].text = companyLocations || updated[1].text;
          updated[3].text = email || updated[3].text;
          updated[5].text = companyName || updated[5].text;
          setArrayOne(updated);
          setCustomFeilds(defaultTemp[0].customFeilds);
        } else {
          setIsDefaultSelect(false);
          setDefaultTemplateId(temp[0]._id);
          setTemp(temp[0]._id);
          let updated = temp[0].currentTemplateData;
          // updated[1].text = companyLocations || updated[1].text;
          updated[3].text = email || updated[3].text;
          updated[5].text = companyName || updated[5].text;
          setArrayOne(updated);
          setCustomFeilds(temp[0].customFeilds);
        }
      } else {
        setDefaultStatus(true);
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplate();
  }, []);

  const setTemp = async (info) => {
    try {
      // setIsloading(true);
      const response = await userInstance().get(`/template/getTemplateById?id=${info}`);
      const { code, msg, tempId } = response.data;

      setIsDefaultSelect(tempId?.default);
      setDefaultTemplateId(tempId._id);
      setTemplateName(tempId.templateName);
      let updated = tempId.currentTemplateData;
      // updated[1].text = companyLocations || updated[1].text;
      updated[3].text = email || updated[3].text;
      updated[5].text = companyName || updated[5].text;
      setTemplateData(updated);
      setTemplateDataCopy(updated);
      setCustomFeilds(tempId.customFeilds);
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      console.log(error);
    }
    setSaveTemplateSelectedDropdown('');
  };

  const Revert = async () => {
    try {
      const payload = {
        id: defaultTemplateId,
      };
      const response = await userInstance().post('/template/revertTemplate', payload);
      const { code, msg, tempId } = response.data;
      setDefaultTemplateId(tempId._id);
      setTemplateName(tempId.templateName);
      setTemplateData(tempId.currentTemplateData);
      setTemplateDataCopy(tempId.currentTemplateData);
      // setTemplateDataOne(tempId.currentTemplateDataOne);
      // setTemplateDataCopyOne(tempId.currentTemplateDataOne);
      setCustomFeilds(tempId.customFeilds);
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };

  const handleOnedit = () => {
    setisEdit(true);
  };
  return (

    <>
      {isloading ? <Loader /> : (
        <>
          <div className="target-heading client-profile">
            {view === 'InvoiceTemplate' || view === 'addTemplate' || view === 'editTemplate' ? (
              <>
                <div className="invoice-header">
                  <h3>
                    {view === 'InvoiceTemplate' ? 'Default Invoice Template' : ''}
                    {view === 'addTemplate' ? 'Add New Invoice Template' : ''}
                    {view === 'editTemplate' ? 'Saved  Invoice Template' : ''}
                  </h3>
                </div>
                <div className="content-dropdown">
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('InvoiceTemplate')}>
                            <span>Default Invoice Template</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('addTemplate')}>
                            <span>Add New Invoice Template</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action">
                            <span onClick={() => updateView('editTemplate')}>Saved Invoice Templates</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </>
            ) : ''}
          </div>
          {view === 'editTemplate' && (
          <div className="target-heading client-profile">

            <Form.Group controlId="formBasicCompanyType">
              <Form.Label>
                Template Name
              </Form.Label>
              <div className="select-box">
                <Form.Control
                  as="select"
                  name="customerType"
                  value={defaultTemplateId}
                  onChange={(e) => {
                    if (isNewChange) {
                      setSaveTemplateConfirmPopup(true);
                      setSaveTemplateSelectedDropdown(e.target.value);
                    } else {
                      setTemp(e.target.value);
                    }
                  }}
                >
                  {templateArray && templateArray.map((temp) => (
                    <option value={temp._id}>
                      {temp.templateName}
                      {temp.default ? '-default' : ''}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>

          </div>
          )}
          <div className="edit-template-page">
            {renderedView()}
            <div className="template-btn view-btn">

              {view === 'InvoiceTemplate' ? (
                <div className="client-option service-edit-icon">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                      View Other Settings
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                      <Dropdown.Item>
                        <div className="action" onClick={handlesavePopup}>
                          Save Template As
                        </div>
                      </Dropdown.Item>

                      <Dropdown.Item>
                        <div className="action" onClick={() => updateView('editTemplate', 'isedit')}>
                          Edit Template
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="action">
                          Restore to Original Default
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="action">
                          Revert to Previous Saved Default
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : ''}
              {view === 'editTemplate' || view === 'addTemplate' ? (
                <div className="client-option service-edit-icon">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                      View Other Settings
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        {!isDefaultSelect && (
                          <div className="action" onClick={() => handleDefaultPopup('default')}>
                            Save as Default
                          </div>
                        ) }
                      </Dropdown.Item>
                      {!isEdit && view !== 'addTemplate'
                    && (
                    <Dropdown.Item>
                      <div className="action" onClick={() => handleOnedit()}>
                        Edit Template
                      </div>
                    </Dropdown.Item>
                    )}
                      <Dropdown.Item>
                        <div className="action" onClick={handlePreviewPopup}>
                          Preview Template
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="action" onClick={OpenAddNewTextPopup}>
                          Add Text Field
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div className="action" onClick={OpenAddImagePopup}>
                          Add Picture Field
                        </div>
                      </Dropdown.Item>
                      {(view === 'editTemplate') && (
                        <Dropdown.Item onClick={Revert}>
                          <div className="action">
                            Revert to Previous Saved
                          </div>
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : ''}
              {view === 'InvoiceTemplate' && (
              <Button className="save-btn" onClick={handlesavePopup}>Saved as default</Button>
              )}
              {(view === 'editTemplate' || view === 'addTemplate') && (
              <Button className="save-btn" onClick={handlesavePopup}>Save/Update</Button>
              )}
            </div>
            {textPopup && (
            <ChangeTextPopup
              textPopup={textPopup}
              setTemplateDat={setTemplateData}
              templateDataCopy={tableNo === 0 ? templateDataCopy : []}
              handleTextPopup={handleTextPopup}
              setTemplateDataCopy={tableNo === 0 ? setTemplateDataCopy : []}
              text={texts}
              setText={setText}
              customName={customName}
              customNameTwo={index}
              setData={setData}
              placeholderText={placeholderText}
              tdIndex={tdIndex}
              widthValue={widthValue}
              setTemplateData={setTemplateData}
              templateDataCopy2={templateDataCopy2}
              setIsNewChange={setIsNewChange}
            />
            )}
            {addImagepopup && (
            <AddNewImage
              showPopup={addImagepopup}
              handleImagePopup={AddImagePopup}
              setTemplateData={setTemplateData}
              templateDataCopy={templateDataCopy}
              setTemplateDataCopy={setTemplateDataCopy}
              text={texts}
              setText={setText}
              customName={customName}
              customNameTwo={index}
              setData={setData}
              handleShow={handleShow}
              customFeilds={customFeilds}
              setCustomFeilds={setCustomFeilds}
              index={index}
              placeholderText={placeholderText}
              templateData={templateData}
              setIsNewChange={setIsNewChange}
            />
            )}
            <Modal
              size="md"
              show={previewPopup}
              onHide={handlePreviewPopup}
              aria-labelledby="contained-modal-title-vcenter"
              centered
              className="search-modal new-search-popup1 preview-popup preview-pdf"
            >
              <Modal.Body>
                <div className="appointment-container preview-invoice preview-popup">
                  <div className="back-option" onClick={handlePreviewPopup}>
                    <span />
                    <i className="fa fa-times" aria-hidden="true" />
                  </div>
                  <PreviewTemplate
                    templateData={templateData}
                // templateDataOne={templateDataOne}
                    customFeilds={customFeilds}
                    previewUrl={previewUrl}
                    loading={loading}
                  />
                  <div className="close-btn">
                    <Button className="btn btn-primary" onClick={() => deleteUrl()}>Close</Button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {savePopup && (
            <SaveTemplatePopup
              savePopup={savePopup}
              setSavePopup={setSavePopup}
              handleDataPopup={handleDataPopup}
              templateName={templateName}
              saveTemplate={saveTemplate}
              view={view}
            />
            )}
            {defaultPopup && (
            <SaveDefaultPopup
              savePopup={defaultPopup}
              setSavePopup={setDefaultPopup}
              handleDataPopup={handleDataPopup}
              templateName={templateName}
              saveTemplate={saveTemplate}
              view={view}
            />
            )}
            {addTextpopup && (
            <AddNewTextPopuppage
              addTextpopup={addTextpopup}
              setAddTextPopup={setAddTextPopup}
              handleTextPopup={AddNewTextPopup}
              setTemplateDat={setTemplateData}
              templateDataCopy={templateDataCopy}
              setTemplateDataCopy={setTemplateDataCopy}
              text={texts}
              setText={setText}
              customName={customName}
              customNameTwo={index}
              setData={setData}
              handleShow={handleShow}
              customFeilds={customFeilds}
              setCustomFeilds={setCustomFeilds}
              tdIndex="tdStyle2"
              widthValue={widthValue}
              setIsNewChange={setIsNewChange}
            />
            )}
            {showEdit && (
            <EditNewTextPopup
              addTextpopup={showEdit}
              handleTextPopup={handleShow}
              handleTextNew={handleTextNew}
              customFeilds={customFeilds}
              setCustomFeilds={setCustomFeilds}
              setShowEdit={setShowEdit}
              text={texts}
              setText={setText}
              customName={customName}
              customNameTwo={index}
              tdIndex="tdStyle2"
              widthValue={widthValue}
            />
            )}
            {saveTemplateConfirmPopup && !isParentClickDropdown && (
            <SaveTemplateConfirmPopup handlesavePopup={handlesavePopup} setView={setView} isMain={false} setSaveTemp={setTemp} />
            )}
          </div>
        </>
      )}

    </>
  );
};

export default InvoiceTemplate;
TemplateSection.propTypes = {
  arrayOne: PropTypes.objectOf.isRequired,
  customFeilds: PropTypes.objectOf.isRequired,
};
