/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Modal, Form, Button, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import TimePicker from './timePicker';

const EditDaysTime = ({
  show, handleclose, timePicker, setTimePicker, handleTimeRepeat, handleTimePicker, setPayload, payload, handleSaveUpdate,
}) => {
  const { startTime, endTime, days } = payload || {};
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleclose}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="dropdown-modal edit-working-popup edit-daystime-popup staff-modal"
      >
        <div className="staff-close">
          <i className="fa fa-times" onClick={handleclose} aria-hidden="true" />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Working Hours
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="shift-container">
            <div className="row">
              <div className="col-md-6">
                <div className="table-dropdown datetime-table time">
                  <span>Sending Start Time</span>
                  <Dropdown name="startingDate">
                    <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => handleTimePicker('start')}>
                      {startTime || '9.00 AM' }

                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
              <div className="col-md-6">
                <div className="table-dropdown datetime-table time">
                  <span>Sending End Time</span>
                  <Dropdown name="startingDate">
                    <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => handleTimePicker('end')}>
                      {endTime || '5.00 PM'}
                    </Dropdown.Toggle>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>
                    Repeats
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="customerType"
                      onChange={(e) => handleTimeRepeat(e)}
                    >
                      <option value="Everyday_0-6" selected={days === 'Everyday'}> Everyday </option>
                      <option value="Mon-Fri_1-5" selected={days === 'Mon-Fri'}> Mon-Fri </option>
                      <option value="Mon-Sat_1-6" selected={days === 'Mon-Sat'}>  Mon-Sat</option>
                      <option value="Tue-Thu_2-4" selected={days === 'Tue-Thu'}>Tue-Thu</option>
                      <option value="Tue-Fri_2-5" selected={days === 'Tue-Fri'}>Tue-Fri</option>
                      <option value="Tue-Sat_2-6" selected={days === 'Tue-Sat'}>Tue-Sat</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="view-btn">
              <Button type="submit" className="btn btn-delete">Cancel</Button>
              <Button type="submit" className="btn btn-save" onClick={() => handleSaveUpdate()}>Save/Update</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {timePicker && (
      <TimePicker
        timepicker={timePicker}
        handleTimePicker={() => setTimePicker(!timePicker)}
        payload={payload}
        setPayload={setPayload}
      />
      )}
    </>
  );
};

EditDaysTime.propTypes = {
  show: PropTypes.bool.isRequired,
  handleclose: PropTypes.func.isRequired,
  setTimePicker: PropTypes.func.isRequired,
  timePicker: PropTypes.bool.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  handleSaveUpdate: PropTypes.func.isRequired,
};

export default EditDaysTime;
