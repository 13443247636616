import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { invoiceMaximum } from './index';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const InvoiceSendApproval = ({ allSettingData }) => {
  const [invoiceMax, setinvoiceMax] = useState('');
  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target || {};
    setinvoiceMax(value);
  };
  const submitInvoiceMax = async () => {
    if (parseFloat(invoiceMax) > 0) {
      const res = await invoiceMaximum(invoiceMax);
      if (res.code === 200) {
        toastMessage('success', 'Invoice amount set successfully');
      }
    } else {
      toastMessage('error', 'Invoice amount should be greater than 0.');
    }
  };

  useEffect(() => {
    setinvoiceMax(allSettingData?.invoiceMaximum);
  }, [allSettingData?.invoiceMaximum]);

  return (
    <div className="email-sms-sending search-invoice pool-set-up invoice-send-approval">
      <div className="target-heading client-profile">
        <div className="">
          <h3>
            Invoice Send Approval Settings
          </h3>
        </div>
      </div>
      <div className="target-heading client-profile">
        <div className="">
          <p>
            Maximum Amount for Automatically Sending Invoices Without Send Approval
          </p>
        </div>
      </div>
      <div className="invoice-max-section">
        <Form.Group controlId="formBasicInput-1">
          <Form.Label>
            Invoice Maximum
          </Form.Label>
          <Form.Control
            type="number"
            name="invoiceMax"
            value={invoiceMax || ''}
            placeholder="$100.00"
            min="0"
            onChange={(e) => handleChange(e)}
          />
        </Form.Group>
        <div className="event-popup-btn footer-btn view-btn">
          <Button className="appointment-btn btn-edit" type="button">
            Cancel
          </Button>
          <Button type="button" className="appointment-btn btn-save" onClick={() => submitInvoiceMax()}>
            Save/Update
          </Button>
        </div>
      </div>
    </div>
  );
};
InvoiceSendApproval.propTypes = {
  allSettingData: PropTypes.arrayOf.isRequired,
};
export default InvoiceSendApproval;
