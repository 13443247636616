/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table, Button, Accordion, Card, Form, Dropdown,
} from 'react-bootstrap';
import ReactExport from 'react-export-excel';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { queryString } from '../../functions/index';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import uncheckimg from '../../assets/home/uncheck.png';
import search from '../../assets/home/search.png';
import showmore from '../../assets/home/showmoreblue.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import '../prduct&services/product&services.css';
import crosss from '../../assets/home/black-icon.png';
import time from '../../assets/home/clock.png';
import searchgray from '../../assets/home/searchgray.png';
// import upload from '../../assets/home/upload.png';
import download from '../../assets/home/download.png';
import arrow from '../../assets/home/arrowdown.png';
import DeletePopUp from '../DeletePopUp/index';
// import { AuthDataContext } from '../../contexts/AuthDataContext';

function SubscriptionTable(props) {
  // let clientIdentity = localStorage.getItem('clientId');
  // const { clientAllData } = useContext(AuthDataContext);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const {
    setCrossStatus,
    clientList,
    handleEditClient,
    removeClient,
    showHide,
    searchClient,
    handleFormModal,
    handleContactModal,
    handleEditContact,
    handleDeleteContact,
    handleSiteAddressModal,
    handleEditAddress,
    handleDeleteAddress,
    handleVisibilityAddr,
    showAll,
    hideClicked,
    hideAll,
    setClientTxt,
    clientTxt,
    setHideSearch,
    isHideSearch,
    hideClickedAssociated,
    clientDetail,
    setClientDetail,
    checkAddress,
    selectedIndex,
    claddressIndex,
    selectedClient,
    backToAppointment,
    newstate,
    setnewState,
    setClientIdentity,
    handleResendLogin,
  } = props;

  let {
    clientid, name, startDateTime, employee_id, manageClient, typee, back,
  } = queryString();
  const history = useHistory();

  const [accordinData, setAccordinData] = useState({
    client: [],
    associatedContacts: [],
    siteAddresses: [],
    contacts: [],
    addresses: [],
    addrContacts: [],
  });
  const [clientNewData, setClientNewData] = useState([]);
  const [check1, setCheck1] = useState(false);
  const [indexCheck, setindexCheck] = useState(0);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delData, setDelData] = useState();
  const [popUpText, setPopUpText] = useState();

  // const PopUpText = 'Delete Client?';

  const paraText = 'Are you sure to do this?';

  function toggleClient(clientIdent) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    const changeState = clientNewData?.slice();
    const index = changeState.indexOf(clientIdent);
    if (index >= 0) {
      if (name) {
        // console.log('name', typee, name, manageClient);
        if (typee === 'edit' && back) {
          history.push(`addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&&cust=add`);
        } else if (name && typeof (manageClient) === 'undefined') {
          // console.log('manageClient', manageClient);
          backToAppointment();
        } else if (typee === 'add') {
          history.push(`addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&typo=add`);
        }
      } else {
        changeState.splice(index, 1);
        setClientNewData(changeState);
        setClientIdentity(false);
        setnewState(!newstate);
      }
    } else {
      changeState.push(clientIdent);
      setClientNewData(changeState);
      setClientIdentity(true);
      setnewState(!newstate);
    }

    // if (name) {
    // console.log('clientList', clientList);
    // }
  }

  // const findingClientFromCalculator = () => {
  //   console.log('clientList', clientList, clientIdentity);
  //   const findClient = clientList.find((el) => el?._id === clientIdentity);
  //   console.log('findClient', findClient);
  //   if (findClient) {
  //     setClientNewData([findClient.firstname]);
  //     setnewState(!newstate);
  //     localStorage.removeItem('clientId');
  //   }
  // };
  // useEffect(() => {
  //   if (clientIdentity && clientList) {
  //     findingClientFromCalculator();
  //   }
  // }, [clientList]);

  // useEffect(() => {
  //   if (!manageClient && name && clientAllData?.clientAllDetails) {
  //     // handleEditClient(clientAllData.clientAllDetails);
  //     toggleClient(name);
  //   }
  //   if (manageClient && name) {
  //     toggleClient(name);
  //   }
  //   if (typee === 'add') {
  //     handleFormModal();
  //   }
  // }, []);

  function nth(n) {
    return n + ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || `${n}th`;
  }
  const [show, setShow] = useState(true);

  const handleSiteAccordion = (id, type) => {
    // console.log('grytdwteiudu', id, type);
    const selectedAccordin = accordinData[type];
    // console.log('grytdwteiudu', selectedAccordin);
    if (selectedAccordin && selectedAccordin.includes(id)) {
      const data = selectedAccordin.filter((el) => el !== id);

      setAccordinData({ ...accordinData, [type]: [...data] });
    } else {
      setAccordinData({ ...accordinData, [type]: [...selectedAccordin, id] });
    }
    if (type === 'client') { setShow(!show); }
  };

  const camelCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1);

  const handleQuotes = (id, firstName) => {
    let routeType = 'client';
    history.push(`/booking?client=${id}&routeType=${routeType}&name=${firstName}`);
  };

  const filterColumns = (data) => {
    if (clientList && clientList.length) {
      // Get column names
      const columns = Object.keys(data[0]);
      // console.log('columns', columns);

      // Remove by key (firstname)
      const filterColsByKey = columns.filter(
        (c) => c !== 'appointments' && c !== 'associatedContacts' && c !== 'siteAddresses',
      );

      // OR use the below line instead of the above if you want to filter by index
      // columns.shift()

      return filterColsByKey; // OR return columns
    }
    return [];
  };

  const handlePopUpChange = (val) => {
    if (val === 'Yes') {
      switch (popUpText) {
        case 'Delete Client?':
          removeClient(delData);
          break;

        case 'Delete Contact?': {
          const {
            _id, i, contactIndex, type, addrIndex,
          } = delData;
          handleDeleteContact(_id, i, contactIndex, type, addrIndex);
          break;
        }

        case 'Delete Site Address?': {
          const { _id, i, addrIndex } = delData;
          handleDeleteAddress(_id, i, addrIndex);
          break;
        }

        default:
          break;
      }
    }

    setDeletePopUp(false);
  };

  const submitRemove = (id) => {
    setPopUpText('Delete Client?');
    setDeletePopUp(true);
    setDelData(id);
  };

  const submitRemoveContact = (_id, i, contactIndex, type, addrIndex) => {
    setPopUpText('Delete Contact?');
    setDeletePopUp(true);
    setDelData({
      _id, i, contactIndex, type, addrIndex,
    });
  };

  const submitRemoveAddress = (_id, i, addrIndex) => {
    setPopUpText('Delete Site Address?');
    setDeletePopUp(true);
    setDelData({ _id, i, addrIndex });
  };

  const handleWaterTest = (clientId, clientName) => {
    history.push(`/watertesthistory?data=${clientId}&name=${clientName}&routeType=client`);
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const handleCheck = (idx) => {
    setindexCheck(idx);
    setCheck1(!check1);
  };
  // console.log('ClientList', clientList);

  let contactCount = 1;

  const associatedDisplay = (valueContacts, contIndex) => `fas ${valueContacts?.includes(contIndex.toString()) ? 'far fa-chevron-circle-down' : 'far fa-chevron-circle-up'}`;

  const getContactNameType = (custType, contcount) => (custType === 'Householder' ? `${nth(contcount)} Contact` : `Associated Billing Contact ${contcount}`);

  return (
    <>
      {newstate && (
      <>
        <div className="main-titles-mob">
          <h3>Subscriber List</h3>
          <div className="content-dropdown">
            {/* <Dropdown name="startingDate">
            <Dropdown.Toggle variant="link" id="dropdown-basic">
              <i className="fas fa-ellipsis-h" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                Option 1
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
          </div>
          <div className="client-option service-edit-icon">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={showmore} alt="show" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="action" onClick={handleFormModal}>
                  <span>
                    <img src={greyplus} alt="edit" />
                  </span>
                  <span> New Subscriber</span>
                </div>
                <div className="action" onClick={() => setHideSearch(true)}>
                  <span><img src={searchgray} alt="edit" /></span>
                  <span>Show Hidden data in Search</span>
                </div>
                <div className="action">
                  <span><img src={download} alt="edit" /></span>
                  <ExcelFile filename="client-list" element={<Button className="btn">Download in XL</Button>}>
                    <ExcelSheet data={clientList} name="Test">
                      {filterColumns(clientList).map((col) => (
                        <ExcelColumn label={camelCase(col)} value={col} />
                      ))}
                    </ExcelSheet>
                  </ExcelFile>
                </div>
                {/* <div className="action">
                <span>
                  <img src={upload} alt="edit" />
                </span>
                <span> Upload Client List </span>
              </div> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="client-search-box">
          <Form inline>
            <Form.Group controlId="formBasicSearch" className=" search-box search-text">
              <Form.Control
                placeholder="Search by name, mobile, phone or address"
                onChange={(e) => searchClient(e.target.value)}
                onKeyDown={onKeyDown}
                value={clientTxt}
              />
              <img src={search} alt="search" />
              {clientTxt ? (
                <div className="remove-text" onClick={() => searchClient('')}>
                  <img src={crosss} className="cross-img" alt="cross-img" />
                </div>
              ) : null}
            </Form.Group>
            {isHideSearch ? (
              <span className="hidden-data">
                Hidden Data Shown in Search
                <img
                  src={crosss}
                  className="cross-img"
                  alt="cross-img"
                  onClick={() => {
                    setHideSearch(false);
                    searchClient('');
                  }}
                />
              </span>
            ) : null}
          </Form>
          {/* {clientList && clientList.length ? (
          <ExcelFile
            filename="client-list"
            element={(
              <div className="download-btn">
                <Button className="btn btn-dwnld">Download in XL</Button>
              </div>
            )}
          >
            <ExcelSheet data={clientList} name="Test">
              {filterColumns(clientList).map((col) => (
                <ExcelColumn label={camelCase(col)} value={col} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        ) : null} */}
        </div>
      </>
      )}
      <div className="staff-table client-table">
        {/* <div className="mob-table">
          {clientList ? (
            <>
              {' '}
              {clientList.length === 0 ? (
                <div className="placehoder-text">No Clients Found</div>
              ) : (
                <>
                  {clientList.map((client, i) => {
                    let jsxData = (
                      <Accordion className="mob-accordion">
                        <Card>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={i.toString()}
                              onClick={() => handleUpAccordion(i)}
                            >

                              <i
                                className={`fas ${
                                  selectedAccordion.includes(i)
                                    ? 'far fa-chevron-circle-down'
                                    : 'far fa-chevron-circle-up'
                                }`}
                              />
                              <span>{`${client?.firstname} ${client?.lastname}`}</span>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={i.toString()}>
                            <Card.Body>
                              <ul className="staff-list">
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Company Type</h3>
                                    <h5>{`${client?.customerType}`}</h5>
                                  </div>
                                </li>
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Company Name</h3>
                                    <h5>{`${client?.companyName}`}</h5>
                                  </div>
                                </li>
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Billing Street Address</h3>
                                    <h5>{`${client?.streetAddress}`}</h5>
                                  </div>
                                </li>
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Mobile</h3>
                                    <h5>
                                      <a className="mobile" href={`tel:${client?.mobileNo}`}>
                                        {`${client?.mobileNo}`}
                                      </a>
                                    </h5>
                                  </div>
                                </li>
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Email</h3>
                                    <h5>
                                      <a className="email" href={`mailto:${client?.email}`}>
                                        {client?.email}
                                      </a>
                                    </h5>
                                  </div>
                                </li>
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Actions</h3>
                                    <div className="action-btn">
                                      <Button className="edit-btn" onClick={() => handleEditClient(client)}>
                                        <i className="fas fa-edit" />
                                      </Button>
                                      <Button className="delete-btn" onClick={() => submitRemove(client?._id)}>
                                        <i className="far fa-trash-alt" />
                                      </Button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    );
                    if (showHide === false && client?.hide === true) {
                      jsxData = null;
                    }
                    return jsxData;
                  })}
                </>
              )}
              {' '}
            </>
          ) : null}
        </div> */}
      </div>

      {/* <div className="client-main-contact">
        <div className="client-name-contact" role="presentation">
          <div className="">
            <h3 className="client-company-name">
              Company Name
            </h3>
            <h4 className="client-name">Client Name</h4>
            <p className="client-address">address</p>
          </div>
          <div className="open-date client-notes">
            <div className="basic">
              <Form.Check type="checkbox" />
            </div>
            <img src={arrow} alt="arrow" />
          </div>
        </div>
        <div className="client-name-contact" role="presentation">
          <div className="">
            <h3 className="client-company-name">
              Site Address
            </h3>
            <h4 className="client-name">Client Name</h4>
            <p className="client-address">address</p>
          </div>
          <div className="open-date client-notes">
            <div className="basic">
              <Form.Check type="checkbox" />
            </div>

          </div>
        </div>
        <div className="client-name-contact" role="presentation">
          <div className="">
            <h3 className="client-company-name">
              Site Address 2
            </h3>
            <h4 className="client-name">Client Name</h4>
            <p className="client-address">address</p>
          </div>
          <div className="open-date client-notes">
            <div className="basic">
              <Form.Check type="checkbox" />
            </div>

          </div>
        </div>
      </div>
      hreijfuaoi

       */}
      {clientList ? (
        <>
          {clientList.length === 0 ? (
            <div className="placehoder-text">No Subscribers Found</div>
          ) : (
            <>

              {clientList.map((client, i) => {
                let jsxData = (
                  <>
                    {newstate && (

                      <div className="client-main-contact" key={client?._id}>
                        <div
                          className="client-name-contact"
                          onClick={() => handleCheck(i)}
                        >
                          <div
                            className=""
                          >
                            <h3 className="client-company-name">{client?.companyName}</h3>
                            <h4 className="client-name">
                              {' '}
                              {`${client?.firstname} ${client?.lastname}`}
                            </h4>
                            <p className="client-address">{`${client?.streetAddress}`}</p>
                          </div>
                          <div className="open-date client-notes">
                            <div className="basic">
                              {/* <Form.Check type="checkbox" checked={indexCheck === i && check1 === true} onClick={() => handleCheck(i)} /> */}
                            </div>
                            <div className="arrow-img">
                              <img
                                src={arrow}
                                alt="arrow"
                                role="presentation"
                                onClick={() => toggleClient(client?._id)}
                              />
                            </div>

                          </div>
                        </div>

                      </div>
                    )}
                    {/* {console.log('clinnnnnnn', clientNewData, client?._id)} */}
                    {newstate === false && clientNewData?.includes(client?._id) && (
                      <div className="cover-client-page">

                        <div className="client-back-btn">
                          {/* <button type="button" className="btn" onClick={() => toggleClient(client?.firstname)}>
                            <img src={crosss} alt="cancel" />
                          </button> */}
                          <div className="staff-close">

                            <i className="fa fa-times" onClick={() => toggleClient(client?._id)} aria-hidden="true" />

                          </div>
                        </div>
                        <Accordion className="mob-accordion new-client-section" key={client?._id}>
                          <Card>
                            <Card.Header>
                              <div className="client-heading-option first-heading">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={client?._id}
                                  // onClick={() => handleSiteAccordion(client?._id, 'client')}
                                >
                                  {/* <h5>
                                    <i
                                      className={`fas ${
                                        accordinData.client?.includes(client?._id)
                                          ? 'far fa-chevron-circle-down'
                                          : 'far fa-chevron-circle-up'
                                      }`}
                                    />
                                    {`${client?.companyName ? client?.companyName : ''}`}
                                  </h5> */}
                                </Accordion.Toggle>

                                {/* Resend Login Details
                                + Associated Billing Contact
                                Show Hidden Associated Billing Contacts
                                SearchHidden Associated Billing Contacts
                                Staff for this Subscriber
                                Invoices & Payments
                                Direct Debit/Card Details
                                Edit
                                Delete */}
                                <div className="client-option service-edit-icon">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                      <img src={showmore} alt="show" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>

                                      <div className="action" onClick={() => handleResendLogin(client?.staffId)}>
                                        <span>
                                          {' '}
                                          <i className="fa fa-info-circle" aria-hidden="true" />
                                        </span>
                                        <span> Resend Login Details</span>
                                      </div>
                                      <div className="action" onClick={() => handleContactModal(i, client?._id)}>
                                        <span>
                                          <img src={greyplus} alt="edit" />
                                        </span>
                                        {client?.customerType !== 'Householder' ? (
                                          <span> Associated Billing Contact</span>
                                        ) : (
                                          <span> Additional Contact</span>
                                        )}
                                      </div>
                                      {client?.customerType !== 'Householder' && (
                                        <div className="action" onClick={() => handleSiteAddressModal(i, client?._id)}>
                                          <span>
                                            <span>
                                              <img src={greyplus} alt="edit" />
                                            </span>
                                          </span>
                                          <span>Site Address</span>
                                        </div>
                                      )}
                                      {client?.customerType !== 'Householder' ? (
                                        <>
                                          <div className="action" onClick={() => showAll(i, 'associatedContacts')}>
                                            <span>
                                              <i className="fa fa-eye" aria-hidden="true" />
                                            </span>
                                            <span>
                                              {`${
                                                hideClickedAssociated === true && hideClickedAssociated
                                                  ? 'Hide'
                                                  : hideClickedAssociated === false
                                                    ? 'Show Hidden'
                                                    : 'Show Hidden'
                                              } Associated Billing Contacts`}
                                            </span>
                                          </div>
                                          <div className="action">
                                            <span>
                                              <img src={searchgray} alt="edit" />
                                            </span>
                                            <span>Search Hidden Associated Billing Contacts</span>
                                          </div>
                                        </>
                                      ) : null}

                                      <div
                                        className="action"
                                        onClick={() => showAll(
                                          i,
                                          client?.customerType !== 'Householder' ? 'siteAddresses' : 'siteContacts',
                                        )}
                                      >
                                        <span>
                                          <i className="fa fa-eye" aria-hidden="true" />
                                        </span>

                                        <span>
                                          {`${
                                            hideClicked === true && hideClicked
                                              ? 'Hide'
                                              : hideClicked === false
                                                ? 'Show Hidden'
                                                : 'Show Hidden'
                                          } ${client?.customerType !== 'Householder' ? 'Site Addresses' : 'Contacts'}`}
                                        </span>
                                      </div>
                                      {client?.customerType !== 'Householder' ? (
                                        <>
                                          <div className="action">
                                            <span>
                                              <img src={searchgray} alt="edit" />
                                            </span>
                                            <span>Search Hidden Site Addresses</span>
                                          </div>
                                          {/* <div className="action" onClick={() => handleEditClient(client)}>
                                            <span>
                                              <img src={greyedit} alt="edit" />
                                            </span>
                                            <span> Edit </span>
                                          </div> */}
                                        </>
                                      ) : null}
                                      <div className="action">
                                        <span>
                                          <i className="fa fa-eye" aria-hidden="true" />
                                        </span>
                                        <span> Staff for this Subscriber </span>
                                      </div>

                                      <div className="action">
                                        <span>
                                          <i className="fa fa-eye" aria-hidden="true" />
                                        </span>
                                        <span> Invoices & Payments </span>
                                      </div>

                                      <div className="action">
                                        {/* <span>
                                          <i className="fa fa-eye" aria-hidden="true" />
                                        </span> */}
                                        <span>  Direct Debit/Card Details </span>
                                      </div>

                                      <div className="action" onClick={() => handleEditClient(client)}>
                                        {/* <span>
                                          <i className="fa fa-edit" aria-hidden="true" />
                                        </span> */}
                                        <span>  Edit </span>
                                      </div>

                                      <div className="action" onClick={() => submitRemove(client?._id)}>
                                        {/* <span>
                                          <img src={greydelete} alt="edit" />
                                        </span> */}
                                        <span> Delete </span>
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </Card.Header>
                            {/* {console.log('this is client===> ', client)} */}
                            {show ? (
                              <Accordion eventKey={client?._id}>
                                <Card.Body>
                                  <ul className="staff-list top-client-details">
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Main Contact</h3>
                                        <h5>{`${client?.firstname} ${client?.lastname}`}</h5>
                                      </div>
                                    </li>
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Mobile</h3>
                                        <h5>
                                          <a className="mobile" href={`tel:${client?.mobileNo}`}>
                                            {`${client?.mobileNo}`}
                                          </a>
                                        </h5>
                                      </div>
                                    </li>
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Email</h3>
                                        <h5>
                                          <a className="email" href={`mailto:${client?.email}`}>
                                            {client?.email}
                                          </a>
                                        </h5>
                                      </div>
                                    </li>

                                    {/* {client?.customerType !== 'Householder' ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Company Name</h3>
                                        <h5>{`${client?.companyName}`}</h5>
                                      </div>
                                    </li>
                                  ) : (
                                    ''
                                  )} */}
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Billing Street Address</h3>
                                        <h5>{`${client?.streetAddress}`}</h5>
                                      </div>
                                    </li>
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Billing Suburb</h3>
                                        <h5>{client?.suburb !== undefined ? client?.suburb : ''}</h5>
                                      </div>
                                    </li>
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Billing Contact Type</h3>
                                        <h5>{`${client?.customerType}`}</h5>
                                      </div>
                                    </li>
                                    {/* <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>Actions</h3>
                                <div className="action-btn">
                                  <Button className="edit-btn" onClick={() => handleEditClient(client)}>
                                    <i className="fas fa-edit" />
                                  </Button>
                                  <Button className="delete-btn" onClick={() => submitRemove(client?._id)}>
                                    <i className="far fa-trash-alt" />
                                  </Button>
                                </div>
                              </div>
                            </li> */}
                                  </ul>

                                  {client?.associatedContacts?.length > 0 && client.associatedContacts.map((el, contactIndex) => {
                                    let contactsId = '';
                                    if (el?.hide === false || el?.tempShow === true) {
                                      contactCount += 1;
                                      contactsId = el?.name + el?.mobileNo + el?.email + contactIndex;
                                      return (
                                        <Accordion className="mob-accordion inner-item" key={contactIndex.toString()}>
                                          <Card>
                                            <Card.Header>
                                              <div className="client-heading-option main-inner-heading first-heading">
                                                <Accordion.Toggle
                                                  as={Button}
                                                  variant="link"
                                                  eventKey={contactIndex.toString()}
                                                  onClick={() => handleSiteAccordion(contactIndex.toString(), 'associatedContacts')}
                                                >
                                                  <h5>
                                                    <i
                                                      className={associatedDisplay(accordinData?.associatedContacts, contactIndex)}
                                                    />
                                                    {getContactNameType(client?.customerType, contactCount)}
                                                  </h5>

                                                </Accordion.Toggle>

                                              </div>
                                            </Card.Header>

                                            <div key={contactsId}>

                                              <Accordion.Collapse eventKey={contactIndex.toString()}>
                                                <Card.Body>

                                                  <div className="">

                                                    <ul className="staff-list ass-contact">
                                                      <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>
                                                            {' '}
                                                            Contact Name
                                                          </h3>
                                                          <h5>
                                                            {el?.name}
                                                            {' '}
                                                            {el?.lastName || ''}
                                                          </h5>
                                                        </div>
                                                      </li>
                                                      <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>Mobile No</h3>
                                                          <h5>{el?.mobileNo}</h5>
                                                        </div>
                                                      </li>
                                                      <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>Relationship to Main Contact</h3>
                                                          <h5>Secretary</h5>
                                                        </div>
                                                      </li>
                                                      {/* <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>Landline No</h3>
                                                          <h5>{el?.landlineNo}</h5>
                                                        </div>
                                                      </li>
                                                      <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>Email</h3>
                                                          <h5>{el?.email}</h5>
                                                        </div>
                                                      </li> */}
                                                      <li className="staff-list-item">
                                                        <div className="staff-box">
                                                          <h3>Actions</h3>
                                                          <div className="action-btn">
                                                            <Button className="edit-btn">
                                                              <img
                                                                src={whiteedit}
                                                                alt="edit"
                                                                onClick={() => handleEditContact(el, client?._id, i, contactIndex)}
                                                              />
                                                            </Button>
                                                            <Button className="eye-btn">
                                                              <i
                                                                className={el?.hide ? 'fas fa-eye-slash' : 'fa fa-eye'}
                                                                onClick={() => handleVisibilityAddr(
                                                                  i,
                                                                  client?._id,
                                                                  contactIndex,
                                                                  'associatedContacts',
                                                                )}
                                                              />
                                                            </Button>
                                                            <Button className="delete-btn">
                                                              <img
                                                                src={whitedelete}
                                                                alt="edit"
                                                                onClick={() => submitRemoveContact(
                                                                  client?._id,
                                                                  i,
                                                                  contactIndex,
                                                                  'contact',
                                                                )}
                                                              />
                                                            </Button>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </ul>

                                                  </div>

                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </div>

                                          </Card>
                                        </Accordion>
                                      );
                                    }
                                    return '';
                                  })}
                                  {client?.siteAddresses?.length
                                && client?.siteAddresses?.filter((addr) => addr?.hide === false || addr?.tempShow === true)
                                  .length ? (
                                    <>
                                      {client?.siteAddresses?.map((el, addrIndex) => {
                                        let addressesId = '';
                                        if (!el?.hide || el?.tempShow === true) {
                                          addressesId = el?.householderName + el?.address + el?.mobileNo;
                                          return (
                                            <Accordion className="mob-accordion inner-item" key={addressesId}>
                                              <Card>
                                                <Card.Header>
                                                  <div className="client-heading-option first-heading site-with-address">
                                                    <Accordion.Toggle
                                                      as={Button}
                                                      variant="link"
                                                      eventKey={addressesId}
                                                      onClick={() => handleSiteAccordion(addressesId, 'addresses')}
                                                    >
                                                      <h5>
                                                        <i
                                                          className={accordinData.addresses.includes(addressesId) ? 'fas far fa-chevron-circle-down'
                                                            : 'fas far fa-chevron-circle-up'}
                                                        />

                                                        {addrIndex === 0 ? (
                                                          <div>
                                                            Site Address
                                                          </div>
                                                        ) : (
                                                          <div>
                                                            Site Address
                                                            {' '}
                                                            {(addrIndex + 1)}
                                                          </div>
                                                        )}

                                                      </h5>
                                                      <h6>{el?.address}</h6>
                                                    </Accordion.Toggle>

                                                    {/* <div
                                                    className="client-option service-edit-icon"
                                                    onClick={() => handleContactModal(i, client?._id, addrIndex)}
                                                  >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                  </div> */}
                                                    {/* <div
                                                    className={`client-option service-edit-icon ${accordinData.addresses.includes(addressesId) ? 'show-associated' : 'hide-img'}`}
                                                    onClick={() => handleContactModal(i, client?._id, addrIndex)}
                                                  >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                    <div className="client-option service-edit-icon">
                                                      <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                          <img src={showmore} alt="show" />
                                                        </Dropdown.Toggle>
                                                      </Dropdown>
                                                    </div>
                                                  </div> */}
                                                  </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={addressesId}>
                                                  <Card.Body>
                                                    <div className="occupier-contact" key={addressesId}>
                                                      <Card>
                                                        <Card.Body>
                                                          <ul className="staff-list occupier-list">
                                                            <li className="staff-list-item">
                                                              <div className="staff-box">
                                                                <h3>Occupier</h3>
                                                                <h5>{el?.householderName}</h5>
                                                              </div>
                                                            </li>
                                                            <li className="staff-list-item">
                                                              <div className="staff-box">
                                                                <h3>Mobile No.</h3>
                                                                <h5>{el?.mobileNo}</h5>
                                                              </div>
                                                            </li>
                                                            <li className="staff-list-item">
                                                              <div className="staff-box">
                                                                <h3>Actions</h3>
                                                                <div className="action-btn">
                                                                  <Button className="edit-btn">
                                                                    <img
                                                                      src={whiteedit}
                                                                      alt="edit"
                                                                      onClick={() => handleEditAddress(el, client?._id, i, addrIndex)}
                                                                    />
                                                                  </Button>
                                                                  <Button className="eye-btn">
                                                                    <i
                                                                      className={
                                                                        el?.hide
                                                                          ? 'fas fa-eye-slash'
                                                                          : 'fa fa-eye'
                                                                      }
                                                                      aria-hidden="true"
                                                                      onClick={() => handleVisibilityAddr(
                                                                        i,
                                                                        client?._id,
                                                                        addrIndex,
                                                                        'siteAddresses',
                                                                      )}
                                                                    />
                                                                  </Button>
                                                                  <Button className="delete-btn">
                                                                    <img
                                                                      src={whitedelete}
                                                                      alt="edit"
                                                                      onClick={() => submitRemoveAddress(client?._id, i, addrIndex)}
                                                                    />
                                                                  </Button>
                                                                </div>
                                                              </div>
                                                            </li>
                                                          </ul>

                                                        </Card.Body>
                                                      </Card>
                                                    </div>
                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </Card>
                                            </Accordion>
                                          );
                                        }

                                        return '';
                                      })}
                                    </>
                                    ) : null}
                                </Card.Body>
                              </Accordion>
                            ) : null}
                          </Card>
                        </Accordion>
                      </div>
                    )}
                  </>
                );
                if (showHide === false && client?.hide === true) {
                  jsxData = null;
                }
                return jsxData;
              })}
              {deletePopUp && (
              <DeletePopUp
                  // additionalContactDetails={additionalContactDetails}
                PopUpText={popUpText}
                handlePopUpChange={handlePopUpChange}
                setDeletePopUp={setDeletePopUp}
                paraText={paraText}
              />
              )}
            </>
          )}
        </>
      ) : null}
    </>
  );
}

SubscriptionTable.propTypes = {
  clientList: PropTypes.func.isRequired,
  handleEditClient: PropTypes.func.isRequired,
  removeClient: PropTypes.func.isRequired,
  showHide: PropTypes.func.isRequired,
  searchClient: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  handleContactModal: PropTypes.func.isRequired,
  handleEditContact: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired,
  handleSiteAddressModal: PropTypes.func.isRequired,
  handleEditAddress: PropTypes.func.isRequired,
  handleDeleteAddress: PropTypes.func.isRequired,
  handleVisibilityAddr: PropTypes.func.isRequired,
  showAll: PropTypes.func.isRequired,
  hideClicked: PropTypes.func.isRequired,
  hideAll: PropTypes.func.isRequired,
  setClientTxt: PropTypes.func.isRequired,
  clientTxt: PropTypes.func.isRequired,
  setHideSearch: PropTypes.func.isRequired,
  isHideSearch: PropTypes.func.isRequired,
  hideClickedAssociated: PropTypes.func.isRequired,
  setClientDetail: PropTypes.func.isRequired,
  clientDetail: PropTypes.func.isRequired,
  selectedClient: PropTypes.func.isRequired,
  selectedIndex: PropTypes.func.isRequired,
  claddressIndex: PropTypes.func.isRequired,
  backToAppointment: PropTypes.func.isRequired,
  setCrossStatus: PropTypes.bool.isRequired,
  setnewState: PropTypes.func.isRequired,
  newstate: PropTypes.bool.isRequired,
  setClientIdentity: PropTypes.func.isRequired,
  handleResendLogin: PropTypes.func.isRequired,
};

export default SubscriptionTable;
