import React from 'react';
import { Form, Button } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';

const Profile = () => (
  <AdminWrapper>
    <div className="contact-page pool-set-up calendar-settings">
      <div className="container">
        <div className="row">

          <div className="col-md-12">
            <div className="main-titles-mob">
              <h3>Profile</h3>
            </div>
          </div>
        </div>

        <div className="contact-container client-main-contact">

          <div className="contact-form staff-form">
            <Form>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label className="form-label">
                      Week Begins
                    </Form.Label>
                    <div className="select-box">
                      <select
                        id="formBasic"
                        className="form-control"
                        name="soldPer"
                      >
                        <option value={1}>Monday</option>
                        <option value={2}>Tuesday</option>
                        <option value={3}>Wednesday</option>
                        <option value={4}>Thursday</option>
                        <option value={5}>Friday</option>
                        <option value={6}>Saturday</option>
                        <option value={7}>Sunday</option>
                      </select>
                    </div>
                  </Form.Group>

                </div>
                <div className="col-md-6">
                  <Form.Group>
                    <Form.Label className="form-label">
                      Time Display
                    </Form.Label>
                    <div className="select-box">
                      <select
                        id="formBasic"
                        className="form-control"
                        name="soldPer"
                      >
                        <option value="h:mm a">12-hour format (ie 2:00pm)</option>
                        <option value="HH:mm A">24-hour format (ie 14:00)</option>
                      </select>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Label className="form-label">
                      Time Zone
                    </Form.Label>
                    <div className="select-box">
                      <select
                        id="formBasic"
                        className="form-control"
                        name="soldPer"
                      >
                        <option value="No Value">Select Time Zone</option>

                      </select>
                    </div>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="view-btn">
                    <Button type="button" className="btn btn-edit btn btn-primary">Cancel</Button>
                    <Button type="submit" className="btn btn-save btn btn-primary">Save/Update</Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>

        </div>
      </div>
    </div>
  </AdminWrapper>
);
export default Profile;
