import React, { useEffect, useMemo, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Modal, Button } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import useResponsiveFontSize from './useResponsiveFontSize';
import { userInstance } from '../../config/axios';
import './payment.css';
import toastMessage from '../../functions/toastMessage';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: '#32325d',
          fontSmoothing: 'antialiased',
          fontSize: '18px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    }),

  );

  return options;
};

const CheckOutForm = ({ openPaymentForm, payloadData, setOpenPaymentForm }) => {
  delete payloadData?._id;
  const stripe = useStripe();
  const elements = useElements();
  const [volume, setVolume] = useState(0);
  const [Data, addData] = useState({});
  const [error, setError] = useState(null);
  const [paymentcard, setPaymentcard] = useState(null);
  const [price, setPrice] = useState(0);
  const [interval, setInterval] = useState('');
  const [del, setDel] = useState(false);
  // const options = useOptions();
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: '#32325d',
          fontSmoothing: 'antialiased',
          fontSize: '18px',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a',
        },
      },
    }),

  );
  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   if (!stripe || !elements) {
  //     // Stripe.js has not loaded yet. Make sure to disable
  //     // form submission until Stripe.js has loaded.
  //     return;
  //   }

  //   const payload = await stripe.createPaymentMethod({
  //     type: 'card',
  //     card: elements.getElement(CardElement),
  //   });

  //   console.log('[PaymentMethod]', payload);
  // };

  const handlePayment = (e) => {
    if (e.target.checked) {
      setPaymentcard(true);
    } else {
      setPaymentcard(false);
    }
  };
  const handlePay = (e) => {
    setPrice(e.target.value);
    setInterval(e.target.name);
  };

  const getUser = async () => {
    const response = await userInstance().get('/transactions');
    const { Data: datas, code } = response.data;
    if (code === 200 && Data != null) {
      addData(Data);
      setVolume(Data.Employees);
      let value = localStorage.getItem('delete');
      let datainlocal = JSON.parse(value) === true;
      if (datainlocal) {
        setDel(datainlocal);
      } else {
        setDel(false);
      }
    } else {
      addData('');
    }
  };
  useEffect(() => {
    // getUser();s
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    // console.log('rsult', result, card, price);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      if (price === 0) {
        const payload = {
          stripeToken: result.token.id,
          amount: payloadData?.annnumCost,
          payloadData,
          // interval,
          // noOfEmployess: volume,
        };
        const response = await userInstance().post('/transaction/makePayment', payload);
        const { code, msg } = response.data;
        if (code === 200) {
          toastMessage('success', msg);

          window.location.href = '/payment';
        } else {
          toastMessage('error', msg);
        }
      } else {
        let err = 'Amount Is Empty';
        setError(err);
      }
    }
  };

  // const changeSubscription = async()=>{
  //   const payload={
  //     subscriptionId:Data.subscriptionId,
  //     productId:Data.productId,
  //     interval:interval,
  //     Amount:price,
  //     noOfEmployess:volume,
  //     deleteUser:del
  //   }
  //   const response = await userInstance.post('/updateSubscription',payload)
  //   const { code, msg } = response.data
  //   if (code === 200) {
  //     localStorage.removeItem("delete");
  //     getUser()
  //   toast.success(msg, { containerId: 'B' })
  //   }
  //   else {
  //   toast.error(msg, { containerId: 'B' })
  //   }
  // }

  return (
    <Modal
      size="lg"
      show={openPaymentForm}
    // onHide={!showForm}
      aria-labelledby="contained-modal-title-vcenter "
      top
      className="staff-modal payment-popup"
    >
      <div className="staff-close">
        <i className="fa fa-times" aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <form onSubmit={handleSubmit}>
            <div>
              <h6>
                Please complete the payment first ,
                then you can continue with services
              </h6>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Selected Plan -</span>
              <span>{payloadData?.subscriptionType}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '2px 0 10px 0' }}>
              <span>Price - $</span>
              <span>{payloadData?.annnumCost}</span>
            </div>
            {/* <label> */}
            <h6>Pay With</h6>
            <CardElement
              options={options}
              onReady={() => {
                console.log('CardElement [ready]');
              }}
              onChange={(event) => {
                console.log('CardElement [change]', event);
              }}
              onBlur={() => {
                console.log('CardElement [blur]');
              }}
              onFocus={() => {
                console.log('CardElement [focus]');
              }}
            />
            {/* </label> */}
            <div className="view-btn footer-btn service-submit ">

              {/* <Button
                type="submit"
                className="add-btn"
                disabled={!stripe}
                onClick={setop}
              >
                Back
              </Button> */}
              <Button
                type="submit"
                className="add-btn"
                disabled={!stripe}
                // onClick={handleSubmit}
              >
                Pay $2400
              </Button>

            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

CheckOutForm.propTypes = {
  openPaymentForm: PropTypes.bool.isRequired,
  payloadData: PropTypes.objectOf.isRequired,
  setOpenPaymentForm: PropTypes.func.isRequired,
};

export default CheckOutForm;
