import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import showmore from '../../assets/home/showmoreblue.png';
import PurchasesSummary from './purchasesSummary';

const Purchases = () => {
  const [view, setView] = useState('Purchases Summary');
  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case 'Purchases Summary':
        componentToRender = (
          <PurchasesSummary />
        );
        break;

      default:
        break;
    }
    return componentToRender;
  };

  const updateView = (key) => {
    setView(key);
  };
  return (
    <div className="reports-appointment pool-set-up search-invoice">
      <div className="target-heading client-profile">
        <>
          <div className="">
            <h3>
              {view}
            </h3>
          </div>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Purchases Summary')}>
                      <span>Purchases Summary</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action">
                      <span>Purchases Detail</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action">
                      <span>Download Displayed Data in Excel</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </>
      </div>
      {renderView()}
    </div>

  );
};
export default Purchases;
