/* eslint-disable object-shorthand */
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import moment from 'moment';
import { userInstance } from '../../config/axios';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import { showErrMsg, getCalendarPath } from '../../functions/index';
import timeZone from './timezone';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import './calendarSettings.css';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';
// import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';

const Calendarsettings = () => {
  const errorMsgTime = 2500;
  const successMsgTime = 2500;
  const { userData, setUserData } = useContext(AuthDataContext);
  const [week, setWeek] = useState(1);
  const [timeDisplay, settimeDisplay] = useState('h:mm a');
  const [calendarzone, setcalendarZone] = useState();
  const [calendarzone2, setcalendarZone2] = useState();
  const [calendarDateTime, setcalendarDateTime] = useState();
  const [unitMeasurement, setunitMeasurement] = useState();
  const [loading, setLoading] = useState(false);
  // const [hourformat, setHourFormat] = useState('h:mm a');

  const handleCancel = () => {
    const { calendarSettings } = userData;
    setWeek(calendarSettings.week);
    settimeDisplay(calendarSettings.timeDisplay);
    setcalendarZone(calendarSettings.timeZone);
    setcalendarZone2(calendarSettings.timeZoneText);
    setunitMeasurement(calendarSettings.unit);
    const dt = new Date();
    if (calendarSettings.timeZone) {
      let myTime = dt.toLocaleString('en-US', {
        timeZone: `${calendarSettings.timeZone}`,
      });
      setcalendarDateTime(myTime);
    } else {
      setcalendarDateTime(dt);
    }
  };

  const handleWeek = (e) => {
    setWeek(e.target.value);
  };
  const handlechangeZone = (e) => {
    if (e.target.value !== 'No Value') {
      const requiredObject = timeZone.filter((el) => el.text === e.target.value);
      const dt = new Date();
      let myTime = dt.toLocaleString('en-US', {
        timeZone: `${requiredObject[0].utc}`,
      });
      setcalendarZone2(requiredObject[0].text);
      setcalendarZone(requiredObject[0].utc);
      setcalendarDateTime(myTime);
    }
  };
  const handletimeDisplay = (e) => {
    settimeDisplay(e.target.value);
  };
  const handleMeasurement = (e) => {
    setunitMeasurement(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      week,
      timeDisplay,
      timeZone: calendarzone,
      timeZoneText: calendarzone2,
      unit: unitMeasurement,
    };
    if (calendarzone2 !== 'No Value') {
      try {
        const response = await userInstance().post('/user/calendarSettings', payload);
        if (response.data.code === 200) {
          toastMessage('success', 'Successfully Updated Calendar Settings');

          setUserData({
            ...userData,
            calendarSettings: {
              week, timeDisplay, timeZone: calendarzone, timeZoneText: calendarzone2, unit: unitMeasurement,
            },
          });
        } else {
          toastMessage('error', 'Request could not be processed.');
        }
        // console.log('this is the reponse', response);
      } catch (err) {
        showErrMsg(err);
        // console.log('errorMsgTime', errorMsgTime);
      }
    } else {
      toastMessage('error', 'Please Select a Time Zone.');
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      const { calendarSettings } = userData;
      setWeek(calendarSettings.week);
      settimeDisplay(calendarSettings.timeDisplay);
      setcalendarZone(calendarSettings.timeZone);
      setcalendarZone2(calendarSettings.timeZoneText);
      setunitMeasurement(calendarSettings.unit);
      const dt = new Date();
      if (calendarSettings.timeZone) {
        let myTime = dt.toLocaleString('en-US', {
          timeZone: `${calendarSettings.timeZone}`,
        });
        setcalendarDateTime(myTime);
      } else {
        setcalendarDateTime(dt);
      }
    } catch (error) {
      setLoading(false);
    }

    // console.log('this is the calendar settings', calendarSettings);
    // console.log('this is the userData', userData);
  }, [userData]);

  return (
    <AdminWrapper>
      {loading ? <Loader /> : (
        <div className="contact-page pool-set-up calendar-settings">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="back-option">
                  <span />
                  <Link to={getCalendarPath()}>
                    <i
                      className="fa fa-times"
                      aria-hidden="true"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>Calendar Settings & Unit of Measurement</h3>
                </div>
              </div>
            </div>

            <div className="contact-container client-main-contact">

              <div className="contact-form staff-form">
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label className="form-label">
                          Week Begins
                        </Form.Label>
                        <div className="select-box">
                          <select
                            id="formBasic"
                            className="form-control"
                            name="soldPer"
                            value={week}
                            onChange={handleWeek}
                          >
                            <option value={1}>Monday</option>
                            <option value={2}>Tuesday</option>
                            <option value={3}>Wednesday</option>
                            <option value={4}>Thursday</option>
                            <option value={5}>Friday</option>
                            <option value={6}>Saturday</option>
                            <option value={7}>Sunday</option>
                          </select>
                        </div>
                      </Form.Group>

                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label className="form-label">
                          Time Display
                        </Form.Label>
                        <div className="select-box">
                          <select
                            id="formBasic"
                            className="form-control"
                            name="soldPer"
                            value={timeDisplay}
                            onChange={handletimeDisplay}
                          >
                            <option value="h:mm a">12-hour format (ie 2:00 PM)</option>
                            <option value="HH:mm A">24-hour format (ie 14:00 PM)</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label className="form-label">
                          Time Zone
                        </Form.Label>
                        <div className="select-box">
                          <select
                            id="formBasic"
                            className="form-control"
                            name="soldPer"
                            value={calendarzone2}
                            onChange={handlechangeZone}
                          >
                            <option value="No Value">Select Time Zone</option>
                            {timeZone.map((el, i) => (
                              <option value={el.text}>
                                {el.text}
                              </option>
                            ))}

                          </select>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group controlId="formBasicInput-1">
                        <Form.Label>
                          Current Date & Time
                          {' '}
                          <span>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="companyName"
                          placeholder="Wednesday, 4 August 2021"
                          value={moment(calendarDateTime).format(`ddd, DD MMM yyyy ${timeDisplay === 'HH:mm A' ? 'HH:mm' : timeDisplay}`)}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <Form.Group>
                        <Form.Label className="form-label">
                          Unit of Measurement
                        </Form.Label>
                        <div className="select-box">
                          <select
                            id="formBasic"
                            className="form-control"
                            name="soldPer"
                            value={unitMeasurement}
                            onChange={handleMeasurement}
                          >
                            <option value="metric">Metric</option>
                            <option value="US Imperial">US Imperial</option>
                          </select>
                        </div>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="view-btn">
                        <Button type="button" className="btn btn-edit btn btn-primary" onClick={handleCancel}>Cancel</Button>
                        <Button type="submit" className="btn btn-save btn btn-primary">Save/Update</Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>

            </div>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};

export default Calendarsettings;
