/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Nav, Tabs, Tab, Spinner,
} from 'react-bootstrap';
import logo from '../../assets/home/temp-logo.png';
import getStrNum from '../../functions/strNumseparator';
import { userInstance } from '../../config/axios';

const ChangeImagePopup = ({
  showPopup, handleImagePopup, setData, templateDataCopy, setTemplateDataCopy, setTemplateDat, index, placeholderText, tdIndex, templateDataCopy2,
}) => {
  const [selectScaling, setSelectScaling] = useState('Original');
  const [isloader, setisloader] = useState(false);
  const [previewUrl, setpreviewUrl] = useState('');
  const setTemplateData = (data, type) => {
    const { name, value } = data.target;
    let oldState = [...templateDataCopy];
    let { style } = oldState[index];
    let oldStyleObject = { ...style };
    const types = name === 'imageRotate' ? '' : type;
    oldStyleObject[name] = `${value + types}`;
    oldState[index].style = oldStyleObject;
    setTemplateDataCopy(oldState);
  };
  const setTemplateLogo = async (data) => {
    try {
      setisloader(true);
      let oldState = [...templateDataCopy];
      if (data.target.files) {
        setpreviewUrl(window.URL.createObjectURL(data.target.files[0]));
        oldState[index].preview = true;
        if (placeholderText !== 'Signature') {
          const formdata = new FormData();
          formdata.append('file', data.target.files[0]);
          const result = await userInstance().post('/template/uploadSingleImage', formdata);
          const { imageUrl } = result.data;
          oldState[index].text = imageUrl;
          oldState[index].url = imageUrl;
        }
        setTemplateDataCopy(oldState);
        setisloader(false);
      }
    } catch (e) {
      setisloader(false);
    }
  };

  const removePicture = () => {
    let oldState = [...templateDataCopy];
    oldState[index].text = logo;
    oldState[index].url = '';
    setTemplateDataCopy(oldState);
  };

  const setSelectScalingValue = (info) => {
    setSelectScaling(info);
    if (info === 'Original') {
      let oldState = [...templateDataCopy];
      let { style } = oldState[index];
      let oldStyleObject = { ...style };
      oldStyleObject.imageHeight = '100%';
      oldStyleObject.imageWidth = '100%';
      oldState[index].style = oldStyleObject;
      setTemplateDataCopy(oldState);
    }
  };
  // const deleteFeild = () => {
  //   const oldObject = [...templateDataCopy];
  //   oldObject.splice(index, 1);
  //   setTemplateDataCopy(oldObject);
  //   setTemplateDat(oldObject);
  //   handleImagePopup();
  // };
  const deleteFeild = () => {
    let oldObject = [...templateDataCopy];
    oldObject[index].text = '';
    oldObject[index].url = '';
    setTemplateDataCopy(oldObject);
    setTemplateDat(oldObject);
    handleImagePopup();
  };

  const setOpacity = (info) => {
    let oldState = [...templateDataCopy];
    let { style } = oldState[index];
    let oldStyleObject = { ...style };
    oldStyleObject.zIndex = parseInt(info, 10);
    oldState[index].style = oldStyleObject;
    setTemplateDataCopy(oldState);
  };
  const setTemplateLocationSize2 = (data, type = '%') => {
    const { name, value } = data || {};
    console.log('value===>', { value, name, ans: (name === 'left' && (value !== '' && value !== '0' && value !== '%')) });
    const keyVal = `${value}%`;
    let oldState = [...templateDataCopy];
    let { style } = oldState[index];
    let oldtdStyleObject = { ...style };
    if ((name === 'left' && (value !== '' && value !== '0' && value !== '%'))) {
      oldtdStyleObject.position = 'absolute';
      oldtdStyleObject[name] = keyVal;
    } else if ((name === 'top' && (value !== '' && value !== '0' && value !== '%'))) {
      oldtdStyleObject.position = 'absolute';
      oldtdStyleObject[name] = keyVal;
    } else {
      oldtdStyleObject[name] = 'initial';
    }
    if (oldtdStyleObject.left === 'initial' && oldtdStyleObject.top === 'initial') {
      oldtdStyleObject.position = 'initial';
    }
    oldState[index].style = oldtdStyleObject;
    // setCustomObject({ ...customObject, style: oldtdStyleObject });
    setTemplateDataCopy(oldState);
  };
  const setTemplateLocationSize = (data, type) => {
    const { name, value } = data || {};
    const keyVal = value + type;
    let oldState = [...templateDataCopy];
    const { tdStyle1, tdStyle2 } = oldState[index];
    let oldtdStyle1Object = { ...tdStyle1 };
    let oldtdStyle2Object = { ...tdStyle2 };
    if (tdIndex === 'tdStyle1') {
      oldtdStyle1Object[name] = keyVal;
      oldState[index].tdStyle1 = oldtdStyle1Object;
      // setCustomObject({ ...customObject, tdStyle1: oldtdStyle1Object });
      setTemplateDataCopy(oldState);
    } else {
      oldtdStyle2Object[name] = keyVal;
      oldState[index].tdStyle2 = oldtdStyle2Object;
      // setCustomObject({ ...customObject, tdStyle2: oldtdStyle2Object });
      setTemplateDataCopy(oldState);
    }
  };

  const addjustElementWidth = (data, type) => {
    const { name, value } = data || {};
    let oldState = JSON.parse(JSON.stringify(templateDataCopy));
    let { tdStyle1, type: trType, tdStyle2 } = oldState[index];
    // const trLength = templateDataCopy.filter((el) => el.type === trType)?.length;
    const restWidth = 100 - parseFloat(value);
    let oldtdStyle1Object = { ...tdStyle1 };
    let oldtdStyle2Object = { ...tdStyle2 };
    oldtdStyle2Object.width = `${value}%`;
    oldtdStyle1Object.width = `${restWidth}%`;
    oldState[index].tdStyle1 = oldtdStyle1Object;
    oldState[index].tdStyle2 = oldtdStyle2Object;
    setTemplateDataCopy(oldState);
  };

  return (
    <Modal
      size="md"
      show={showPopup}
      onHide={handleImagePopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup new-image-popup"
    >
      <Modal.Body>
        <div className="appointment-container preview-popup">
          <div className="change-image-section">
            <div className="field-location-section">
              <div className="preview-image">
                <h6>
                  Preview
                  {' '}
                  {placeholderText || 'Picture'}
                </h6>
                <span
                  style={{
                    display: 'inline-block',
                    height: '80px',
                    width: '100%',
                    border: '1px solid #dcd9d9 ',
                    padding: '10px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <img
                    id="an-img"
                    style={{
                      height: 'auto',
                      maxHeight: `${templateDataCopy[index]?.style.imageHeight}`,
                      width: `${templateDataCopy[index]?.style.imageWidth}`,
                      objectFit: 'contain',
                      marginRight: `${templateDataCopy[index]?.style.imageMarginRight}`,
                      marginLeft: `${templateDataCopy[index]?.style.imageMarginLeft}`,
                      marginTop: `${templateDataCopy[index]?.style.imageMarginTop}`,
                      marginBottom: `${templateDataCopy[index]?.style.imageMarginBottom}`,
                      transform: `rotate(${templateDataCopy[index]?.style.imageRotate}deg)`,
                      top: `${templateDataCopy[index]?.style.top}`,
                      left: `${templateDataCopy[index]?.style.left}`,
                      position: `${templateDataCopy[index]?.style.position}`,
                    }}
                    src={templateDataCopy[index]?.url ? templateDataCopy[index]?.url : logo}
                    alt={placeholderText || 'Logo'}
                  />
                </span>
              </div>
              <div className="template-btn temp-btn">
                <Button className="delete-btn" onClick={() => removePicture()}>Remove</Button>
                <Button type="submit" className="save-template upload-logo">
                  <input type="file" id="upload" onChange={(e) => setTemplateLogo(e)} />
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label htmlFor="upload">
                      {' '}
                      {isloader
                        ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : 'Select File'}

                    </Form.Label>
                  </Form.Group>
                </Button>
              </div>
              {/* <div className="field-location">
                <h6>Image Location</h6>
                <div className="image-size">
                  <Form.Group controlId="formBasicEmail">

                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" name="imageMarginTop" value={getStrNum(templateDataCopy[index]?.style?.imageMarginTop)?.num} onChange={(e) => setTemplateData(e, getStrNum(templateDataCopy[index]?.style?.imageMarginTop)?.str)} placeholder="0" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from left</Form.Label>
                    <Form.Control type="number" name="imageMarginLeft" value={getStrNum(templateDataCopy[index]?.style?.imageMarginLeft)?.num} onChange={(e) => setTemplateData(e, getStrNum(templateDataCopy[index]?.style?.imageMarginLeft)?.str)} placeholder="0" />
                  </Form.Group>
                </div>
              </div> */}
              <div className="text-popup-spacing">
                <h6>Image Location & Size</h6>
                <div className="field-location-section">
                  <div className="field-location">
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>Distance from Top</Form.Label>
                      <Form.Control type="number" value={getStrNum(templateDataCopy[index]?.style?.top)?.num} name="top" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(templateDataCopy[index]?.style?.top)?.str)} placeholder="0" />
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>Distance from Left</Form.Label>
                      <Form.Control type="number" value={getStrNum(templateDataCopy[index]?.style?.left)?.num} name="left" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(templateDataCopy[index]?.style?.left)?.str)} placeholder="0" />
                    </Form.Group>
                  </div>
                  <div className="field-location">
                    {/* <Form.Group controlId="formGridEmail">
                      <Form.Label>Field Height</Form.Label>

                      <Form.Control type="number" value={getStrNum(templateDataCopy[index]?.tdStyle2?.lineHeight)?.num} name="lineHeight" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(templateDataCopy[index]?.tdStyle2?.lineHeight)?.str)} />
                    </Form.Group>
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>Field Width</Form.Label>
                      <Form.Control type="number" value={getStrNum(templateDataCopy[index]?.tdStyle2?.width)?.num} name="width" onChange={(e) => addjustElementWidth(e.target, getStrNum(templateDataCopy[index]?.tdStyle2?.width)?.str)} />
                    </Form.Group> */}
                  </div>
                </div>
              </div>

              <div className="field-location">
                <h6>Image Opacity</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="imagetype"
                        value={templateDataCopy[index]?.style?.zIndex}
                        onChange={(e) => setOpacity(e.target.value)}
                      >
                        <option value="9">Set Image behind type</option>
                        <option value="99">Set Image in front of type</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
                <h6>Image Size</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="customerType"
                        value={selectScaling}
                        onChange={(e) => setSelectScalingValue(e.target.value)}
                      >
                        <option value="Original">Fixed Percentage of Original</option>
                        <option value="Custom">Custom Height & Width</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>

                <div className="image-size">
                  {selectScaling === 'Original' ? (
                    <>
                      {/* <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling</Form.Label>
                        <Form.Control type="number" name="imageHeight" value={getStrNum(templateDataCopy[index]?.style?.imageHeight)?.num} onChange={(e) => setTemplateData(e, getStrNum(templateDataCopy[index]?.style?.imageHeight)?.str)} />
                      </Form.Group> */}
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={templateDataCopy[index]?.style?.imageRotate} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Height</Form.Label>
                        <Form.Control type="number" name="imageHeight" value={getStrNum(templateDataCopy[index]?.style?.imageHeight)?.num} onChange={(e) => setTemplateData(e, getStrNum(templateDataCopy[index]?.style?.imageHeight)?.str)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Width</Form.Label>
                        <Form.Control type="number" name="imageWidth" value={getStrNum(templateDataCopy[index]?.style?.imageWidth)?.num} onChange={(e) => setTemplateData(e, getStrNum(templateDataCopy[index]?.style?.imageHeight)?.str)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={templateDataCopy[index]?.style?.imageRotate} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                    </>
                  )}

                </div>
              </div>
              <div className="template-btn">
                <Button onClick={deleteFeild} className="delete-btn">Delete Field</Button>
                <Button className="save-btn" onClick={setData}>Save/ Update</Button>
              </div>
            </div>
          </div>

        </div>
      </Modal.Body>
    </Modal>

  );
};
ChangeImagePopup.propTypes = {
  showPopup: PropTypes.func.isRequired,
  handleImagePopup: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  templateDataCopy: PropTypes.objectOf.isRequired,
  setTemplateDataCopy: PropTypes.func.isRequired,
  setTemplateDat: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  tdIndex: PropTypes.string.isRequired,
  templateDataCopy2: PropTypes.arrayOf.isRequired,
};
export default ChangeImagePopup;
