/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../admin.css';
import Helmet from 'react-helmet';
import Headroom from 'react-headroom';
import Header from '../../header/header';
import HeaderDataProvider from '../../../contexts/HeaderContext';
import Footer from '../../footer/footer';
import Notification from '../../notification/notification';
import { GetNotification } from '../../../socket';
import { userInstance } from '../../../config/axios';
import Loader from '../../loader/loader';
// import AdminMenu from '../adminmenu/menu';
import ChatBot from '../../chatBot/index';

const AdminWrapper = ({
  children, TodayEvents, isNavClick, OpennotificationView,
}) => {
  let lastScrollTop = 0;
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const [isStickyheader, setIsStickyheader] = useState('');
  const [todayNotification, setTodayNotification] = useState([]);
  const [oldNotification, setOldNotification] = useState([]);
  const [count, setCount] = useState();
  const [notify, setNotify] = useState();
  const [checkNotification, setCheckNotification] = useState(false);
  const [chatBot, setChatBot] = useState(false);

  const ref = React.createRef();
  const watchScroll = () => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop && !isNavClick?.current) {
      setIsStickyheader('header-up');
    } else {
      setIsStickyheader('header-down');
    }
    lastScrollTop = st <= 0 ? 0 : st;
  };
  useEffect(() => {
    if (window !== undefined && window.screen.width <= 768) {
      window.addEventListener('scroll', watchScroll);
    }
    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);

  const handleNotify = async () => {
    setNotify(true);
    setCheckNotification(true);
    const response = await userInstance().get(
      '/appointment/markasreadNotification',
    );
    const { code } = response.data;
    if (code === 200) {
      setCount(0);
    }
  };
  const notificationData = async () => {
    try {
      if (localStorage.getItem('f#@5e')) {
        const response = await userInstance().get('/appointment/getNotification');
        const {
          code, todayList, oldList, unseenCount,
        } = response.data;
        // console.log('resultresult=>', todayList, oldList);
        if (code === 200) {
          setTodayNotification(todayList);
          setOldNotification(oldList);
          setCount(unseenCount);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    GetNotification((data) => {
      notificationData();
    });
  }, []);
  useEffect(() => {
    notificationData();
  }, []);
  return (
    <>
      {/* <Helmet>
        <html className={`admin-view ${notify ? 'notification-show' : ''}`} lang="en" />
      </Helmet> */}
      <div
        className={`admin-wrapper ${isStickyheader} ${
          notify ? 'notification-show' : ''
        }`}
      >
        {/* <Loader /> */}
        <Headroom>
          <div className="header-view">
            <HeaderDataProvider>
              <Header setChatBot={setChatBot} />
            </HeaderDataProvider>
          </div>
        </Headroom>
        <div className="admin-container">
          <div className="admin-content-view">
            <div className="admin-content">{children}</div>
          </div>
        </div>
        <div className="footer-wrapper">
          <Headroom>
            <Footer
              TodayEvents={TodayEvents}
              handleNotify={handleNotify}
              count={count}
              setNotify={setNotify}
              checkNotification={checkNotification}
              setCheckNotification={setCheckNotification}
            />
          </Headroom>
        </div>
        <>
          {notify && <div className="popup-section" />}
          <Notification
            todayList={todayNotification}
            oldList={oldNotification}
            show={notify}
            setNotify={setNotify}
            OpennotificationView={OpennotificationView}
            setCheckNotification={setCheckNotification}
          />
        </>
      </div>
      {chatBot && (
        <ChatBot chatBot={chatBot} setChatBot={setChatBot} />
      )}
    </>
  );
};

AdminWrapper.defaultProps = {
  isNavClick: false,
};

AdminWrapper.propTypes = {
  isNavClick: PropTypes.bool,
  children: PropTypes.node.isRequired,
  TodayEvents: PropTypes.func.isRequired,
  OpennotificationView: PropTypes.func.isRequired,
};
export default AdminWrapper;
