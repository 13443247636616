import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import StartEndDate from './startEndDate';
import crosss from '../../assets/home/black-icon.png';
import search from '../../assets/home/search.png';
import { userInstance } from '../../config/axios';
import {
  filterAppointmentObj, removeComma,
} from '../../functions/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const AppointmentDetail = () => {
  const { userData: { industryId } } = useContext(AuthDataContext);
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });
  const [datePopup, setDatePopup] = useState();
  const [allLocation, setlocationList] = useState([]);
  const [allteamList, setallteamList] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [filterStatus, setfilterStatus] = useState('');
  const [totoalSummary, settotoalSummary] = useState({ totalLabour: 0, totalSale: 0 });
  const [appsummaryDate, setappsummaryDate] = useState();
  const [dataList, setDataList] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [loading, setLoading] = useState(false);
  const initialfilterObj = {
    location: 'alllocation',
    searchlocation: '',
    teammember: 'allteam',
    searchteammember: '',
    locationId: '',
    teammemberId: '',
    locationStatus: false,
    teamStatus: false,
    customerStatus: false,
    serviceStatus: false,
    datetype: 'today',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    customer: 'allcustomer',
    searchcustomer: '',
    customerId: '',
    service: 'allservice',
    searchService: '',
    serviceId: '',
  };
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  const handleDateRange = (start, end) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.rangeStartDate = start;
    copyfilterObj.rangeEndDate = end;
    setFilterObj(copyfilterObj);
    setfilterStatus(start);
    setDatePopup(false);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    setfilterStatus(value);
    let copyfilterObj = { ...filterObj };
    copyfilterObj[name] = value;
    // copyfilterObj[value2] = '';
    setFilterObj(copyfilterObj);
  };
  const removeSearchText = (value, value2) => {
    // console.log('searcRemove', value, value2);
    let copyfilterObj = { ...filterObj };
    copyfilterObj[value] = '';
    copyfilterObj[value2] = '';
    setFilterObj(copyfilterObj);
  };
  const filterAppointment = async () => {
    // console.log('okkkk', { filterObj });
    const payload = filterAppointmentObj(filterObj);
    setappsummaryDate(payload.summaryDate);
    console.log(payload);
    setLoading(true);
    const response = await userInstance().post(
      '/appointment/filterAppointmentDetail',
      {
        filterObj, payload,
      },
    );
    setLoading(false);
    const {
      filterList, code, totalCosts, invoiceTotal,
    } = response.data;
    // console.log('filterList ==>', filterList);
    let copySummary = { ...totoalSummary };
    copySummary.totalLabour = totalCosts;
    copySummary.totalSale = invoiceTotal;
    settotoalSummary(copySummary);
    if (code === 200) {
      setDataList(filterList);
    }
    // console.log('filterAppointmentSummary', totalCosts, invoiceTotal);
  };
  const searchLocation = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchlocation = value;
    copyfilterObj.locationStatus = true;
    setFilterObj(copyfilterObj);
    const response = await userInstance().get(
      `/appointment/filterLocation?location=${value}`,
    );
    const { locationList, code } = response.data;
    if (code === 200) {
      setlocationList(locationList);
    }
  };
  const selectValue = (name, value, id) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj[name] = value;
    setfilterStatus(value);
    if (name === 'searchlocation') {
      copyfilterObj.locationId = id;
      copyfilterObj.locationStatus = false;
    } else if (name === 'searchcustomer') {
      copyfilterObj.customerId = id;
      copyfilterObj.customerStatus = false;
    } else if (name === 'searchService') {
      copyfilterObj.serviceStatus = false;
    } else {
      copyfilterObj.teammemberId = id;
      copyfilterObj.teamStatus = false;
    }
    setFilterObj(copyfilterObj);
  };
  const searchTeammember = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchteammember = value;
    copyfilterObj.teamStatus = true;
    setFilterObj(copyfilterObj);
    const response = await userInstance().get(
      `/appointment/filterTeammember?team=${value}`,
    );
    const { teamList, code } = response.data;
    if (code === 200) {
      setallteamList(teamList);
    }
  };
  const searchCustomer = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchcustomer = value;
    copyfilterObj.customerStatus = true;
    setFilterObj(copyfilterObj);
    if (value) {
      const response = await userInstance().get(`/appointment/filterCustomer?value=${value}`);
      const { customers } = response.data;
      setcustomerList(customers);
    } else {
      setcustomerList([]);
    }
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setlocationList(null);
          let copyfilterObj = { ...filterObj };
          copyfilterObj.teamStatus = false;
          setFilterObj(copyfilterObj);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const searchServiceProduct = async (searchTxt) => {
    try {
      let copyfilterObj = { ...filterObj };
      copyfilterObj.searchService = searchTxt;
      copyfilterObj.serviceStatus = true;
      setFilterObj(copyfilterObj);
      if (searchTxt) {
        const response = await userInstance().get(`/service/searchCategory?value=${searchTxt.replace('$', '').trim()}&industryId=${industryId._id}`);
        const { services } = response.data;
        if (services.length) {
          const subCat = [];
          services.forEach((service) => {
            const reg = new RegExp(`${searchTxt.replace('$', '').trim()}`, 'i');
            const matchedSubcategories = service.subCategory.filter(
              (el) => reg.test(el.cost) || reg.test(el.name) || reg.test(el.retailCost) || reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            // console.log('services', subCat);
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const calculatePeriodTotal = (dataListing) => {
    const totalLabourMargin = dataListing.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.lab ? removeComma(next.lab) : 0),
      0,
    );
    const totalSaleCost = dataListing.reduce(
      (prev, next) => parseFloat(prev || 0)
        + parseFloat(next.cost ? next.cost : 0),
      0,
    );

    return { totalLabourMargin: parseFloat(removeComma(totalLabourMargin)).toFixed(2), totalSaleCost: parseFloat(removeComma(totalSaleCost)).toFixed(2) };
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    filterAppointment(filterStatus);
  }, [filterStatus]);
  return (

    <div className="appointment-summary">
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Location</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="location">
            <option value="alllocation">All Locations</option>
            <option value="specificlocations">Specific Locations</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.location === 'specificlocations' ? (
        <div className="search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchlocation" onChange={(e) => searchLocation(e.target.value)} value={filterObj.searchlocation} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchlocation', 'locationId')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.locationStatus && (
          <>
            {allLocation && allLocation.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {allLocation.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el.locationName}
                    key={el._id}
                    onClick={() => selectValue('searchlocation', el.locationName, el._id)}
                  >
                    {el.locationName}
                  </li>
                ))}
              </ul>
            ) : allLocation && !allLocation.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Team Member</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="teammember">
            <option value="allteam">All Team Members</option>
            <option value="specificteam">Specific Team Members</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.teammember === 'specificteam' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchteammember" onChange={(e) => searchTeammember(e.target.value)} value={filterObj.searchteammember} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchteammember', 'teammemberId')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.teamStatus && (
          <>
            {allteamList && allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {allteamList.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el._id}
                    key={el._id}
                    onClick={() => selectValue('searchteammember', `${el.firstname} ${el.lastname}`, el._id)}
                  >
                    {`${el.firstname} ${el.lastname}`}
                  </li>
                ))}
              </ul>
            ) : allteamList && !allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Customer</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="customer">
            <option value="allcustomer">All Customers</option>
            <option value="specificcustomer">Specific Customer</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.customer === 'specificcustomer' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchcustomer" onChange={(e) => searchCustomer(e.target.value)} value={filterObj.searchcustomer} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchcustomer', 'customerId')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.customerStatus && (
          <>
            {customerList && customerList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {customerList.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el._id}
                    key={el._id}
                    onClick={() => selectValue('searchcustomer', `${el.firstname} ${el.lastname}`, el._id)}
                  >
                    {`${el.firstname} ${el.lastname}`}
                  </li>
                ))}
              </ul>
            ) : customerList && !customerList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Service or Product</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="service">
            <option value="allservice">All Services & Products</option>
            <option value="specificservice">Specific Service or Product</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.service === 'specificservice' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchService" onChange={(e) => searchServiceProduct(e.target.value)} value={filterObj.searchService} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchService', 'serviceId')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.serviceStatus && (
          <>
            {subcategories && subcategories.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {subcategories.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el.name}
                    key={el.name}
                    onClick={() => selectValue('searchService', `${el.name}`, el.name)}
                  >
                    {`${el.name}`}
                  </li>
                ))}
              </ul>
            ) : subcategories && !subcategories.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            value={filterObj.datetype}
            onChange={(e) => handleOnchange(e.target)}
            name="datetype"
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
            <option value="lastweek">Last Week</option>
            <option value="lastyear">Last Year</option>
            <option value="nextweek">Next Week</option>
            <option value="nextyear">Next Year</option>
            <option value="weekdate">Week to Date</option>
            <option value="monthdate">Month to Date</option>
            <option value="yeardate">Year to Date</option>
            <option value="daterange">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.datetype === 'daterange' ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>
              {`${moment(filterObj.rangeStartDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">
              {' '}
              {`${moment(filterObj.rangeEndDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
        </div>
      ) : (
        ''
      )}
      <div className="appointment-summary-section">
        <p>Appointment Detail</p>
        <p>{appsummaryDate}</p>
        {/* <p>Mon, 16 Aug 2021 - Mon, 18 Aug 2021</p> */}
        <hr />
        <div className="summary-table">
          <div className="summary-table-header">
            <h6>Service or Product</h6>
            <h6>No. of Appts</h6>
            <h6>Labour & Margin</h6>
            <h6>Total Sales</h6>
          </div>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {' '}
              {dataList.length > 0
                ? dataList?.map((el, index) => (
                  <div className="summary-table-body">
                    <p>{el.name}</p>
                    <p>{el.count}</p>
                    <p>
                      $
                      { el.lab.toFixed(2)}
                    </p>
                    <p>
                      $
                      {el.cost.toFixed(2)}
                    </p>
                  </div>
                ))
                : (
                  <div>
                    <p>
                      No Records Found
                    </p>
                  </div>
                )}
              <hr />
              <div className="summary-table-body total-section">
                <p>Period Total:</p>
                <p />
                {dataList.length ? (
                  <>
                    {' '}
                    <p>
                      $
                      {calculatePeriodTotal(dataList).totalLabourMargin}
                    </p>
                    <p>
                      $
                      {calculatePeriodTotal(dataList).totalSaleCost}
                    </p>

                  </>
                )
                  : (
                    <>
                      {' '}
                      <p>
                        $0
                      </p>
                      <p>
                        $0
                      </p>
                    </>
                  )}
              </div>
            </>
          )}

        </div>
      </div>
      <StartEndDate datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} handleDateOnchange={handleDateOnchange} dateRange={dateRange} handleDateRange={handleDateRange} />
    </div>
  );
};
export default AppointmentDetail;
