/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!localStorage.getItem('s#@4f') ? (
      <Component {...props} {...rest} />
    ) : (
      <Redirect to="/calculator" />
    ))}
  />
);

LoginRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default LoginRoute;
