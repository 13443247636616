/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const AddCategory = ({
  show, handleClose, addUpdateReminderGroup, groupFormType, setGroupFormName, groupFormName, groupIndex,
}) => (
  <>
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="service-modal"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={handleClose}
          aria-hidden="true"
        />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {groupFormType}
          {' '}
          Reminder Group
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="modal-form reminderGroupForm">
              <Form>
                <Form.Group
                  as={Row}
                  controlId="formInput1"
                  className="italic-text"
                >
                  <Form.Label column sm="3">
                    Name of Reminder Group
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      type="text"
                      placeholder="Enter Name of Reminder Group"
                      name="groupName"
                      onChange={(e) => setGroupFormName(e.target.value)}
                      value={groupFormName}
                    />
                  </Col>
                </Form.Group>
              </Form>
            </div>
            <div className="modal-form">
              <div className="view-btn">
                <Button
                  type="submit"
                  className="btn btn-save"
                  onClick={() => addUpdateReminderGroup(groupFormType, groupIndex)}
                >
                  Save/Update
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
);

AddCategory.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addUpdateReminderGroup: PropTypes.func.isRequired,
  groupFormType: PropTypes.string.isRequired,
  groupFormName: PropTypes.string.isRequired,
  setGroupFormName: PropTypes.func.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default AddCategory;
