import { userInstance, notTimeOutUserInstance } from '../../config/axios';

export const uplaodCSVApiData = async (type, formData) => {
  try {
    const response = await notTimeOutUserInstance().post(`/upload/csv-file/${type}`, formData);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const getCsvData = async (type) => {
  try {
    const response = await userInstance().get(`/upload/${type}`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateCsvData = async (csvId, updatedName, oldName) => {
  try {
    const payLoad = {
      csvId, updatedName, oldName,
    };
    const response = await userInstance().put('/upload/update', payLoad);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const deleteCsv = async (csvId, csvLink) => {
  try {
    const payload = {
      csvId, csvLink,
    };
    const response = await userInstance().put('/upload/delete-csv', payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const checkUploadCsv = async (type, uploadedArray) => {
  try {
    const response = await userInstance().post(`/upload/${type}/check-duplicate`, { uploadedArray });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const checkUploadCsvCustomer = async (uploadedArray) => {
  try {
    const response = await userInstance().post('/upload/customercsv/check-error-summary', { uploadedArray });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
