import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import AuthDataProvider from './contexts/AuthDataContext';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: 'https://a346643aa82c46f198c9cba55b9244ab@o487112.ingest.sentry.io/5545541',
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <AuthDataProvider>
      <App />
    </AuthDataProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register();
