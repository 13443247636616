/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import {
  Button, Modal, Dropdown, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Draggable, { DraggableCore } from 'react-draggable';
import { initial } from 'lodash';
import logo from '../../assets/home/temp-logo.png';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import ChangeTextPopup from './changeTextPopup';
import SaveTemplatePopup from './savetemplatePopup';
import ChangeImagePopup from './changeImage';
import showmore from '../../assets/home/showmoreblue.png';
import AddEditTemplate from './addedithtml';
import PreviewTemplate from './previewInvoice';
import AddNewTextPopuppage from './addnewTextPopup';
import EditNewTextPopup from './editnewTextPopup';
import AddNewImage from './addNewImage';
import EditNewImage from './editNewImage';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import getStrNum from '../../functions/strNumseparator';
import SaveDefaultPopup from './saveDefaultPopup';
import { TemplateFeatures } from '../../contexts/TemplateFeatureContext';
import SaveTemplateConfirmPopup from '../editInvoicceTemplate/save-template-confirm-popup';

const QuoteTemplate = ({ viewtype }) => {
  const {
    locationsInfo, userData,
  } = useContext(AuthDataContext);
  const {
    // states related to this component- start
    savePopup,
    setSavePopup,
    defaulttype,
    setDefaulttype,
    quoteView: view, setQuoteView: setView,
    templateName,
    setTemplateName,
    handlesavePopup,
    // states related to this component- end
    dummyView,
    setDummyView,
    isNewChange,
    setIsNewChange,
    saveTemplateConfirmPopup,
    setSaveTemplateConfirmPopup,
    setSaveConfirmPopupValue,
    isParentClickDropdown,
    setIsParentClickDropdown,
    setSaveTemplateSelectedDropdown,
    saveTemplateSelectedDropdown,
    dummyMainPageView,
    setMainView,
  } = useContext(TemplateFeatures);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';
  const { companyContactDetail, companyInformation } = locationsInfo || {};
  const {
    address, companyName, postcode, state, suburb,
  } = companyInformation || {};
  const {
    mobile, email,
  } = companyContactDetail || {};
  let companyaddress = `${address || ''} ${suburb || ''} ${state || ''} ${postcode || ''}`;
  let companyLocations = companyaddress !== '  ' ? companyaddress : '';

  const dataAU = [
    {
      // text: 'http://calendar-pro.s3.amazonaws.com/TemplateImages/4d26b90eff338e1c7a65f15e01804560.png',
      // url: 'http://calendar-pro.s3.amazonaws.com/TemplateImages/4d26b90eff338e1c7a65f15e01804560.png',
      text: '',
      url: '',
      type: 'img',
      textFeild: false,
      index: 0,
      placeholder: 'Company Logo',
      style: {
        lineHeight: '9px',
        imageHeight: '50px',
        imageWidth: '100px',
        imageRotate: 0,
        imageUrl: '',
        zIndex: 9,
        top: '0px',
        left: '5px',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        textAlign: 'left',
        height: 'fit-content',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: companyLocations || '5/607 St Kilda Road Melboume VIC 3004',
      type: 'tr1',
      placeholder: 'Company Location',
      textFeild: true,
      index: 1,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '5px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0px',
      },
    },
    {
      text: mobile || '0409 774 687',
      type: 'tr2',
      placeholder: 'Mobile No',
      textFeild: false,
      index: 2,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '18px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
      },
    },
    {
      text: 'Bill To:',
      type: 'tr3',
      placeholder: 'Bill To',
      textFeild: true,
      staticFeild: 'Tax Invoice',
      index: 3,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '32px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
      },
    },
    {
      text: email || 'www.cleanpools.com.au',
      type: 'tr3',
      placeholder: 'Email',
      textFeild: false,
      index: 4,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '32px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
        top: '1',
      },
    },
    {
      text: '[Billing Address]',
      type: 'tr4',
      placeholder: 'Billing Address',
      textFeild: true,
      staticFeild: 'Tax Invoice',
      index: 5,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '43px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
      },
    },
    {
      text: companyName || 'TypeCraft Pvt Ltd trading as CleanPools Pool maintanace experts ABN 80 007 007 007',
      type: 'tr4',
      placeholder: 'Company Name',
      textFeild: false,
      index: 6,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '43px',
        left: '385px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0px',
      },
    },

    {
      text: '[Date]',
      type: 'tr7',
      placeholder: 'Date',
      textFeild: true,
      index: 7,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '78px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0%',
      },
    },
    {
      text: 'Dear <fname>,',
      type: 'tr8',
      placeholder: 'Client Name',
      textFeild: true,
      staticFeild: 'PAUL - 21 VERMON STREET VIC 3146',
      index: 8,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '102px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0px',
      },
    },
    {
      text: 'Thank you for the time spent discussing your pool requirements. Please find our quote detailed below with our recommendations and costs',
      type: 'tr9',
      placeholder: 'Quote Opening Text',
      textFeild: true,
      index: 9,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '117px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        wordBreak: 'break-all',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Quote #',
      type: 'tr10',
      placeholder: 'Quote #',
      textFeild: true,
      index: 10,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '160px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '15%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '[Quote]',
      type: 'tr10',
      placeholder: 'Quote',
      textFeild: true,
      index: 11,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '165px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: '85%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Description:',
      type: 'tr11',
      placeholder: 'Description',
      textFeild: true,
      index: 12,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '174px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '40%',
      },
      tdStyle2: {
        width: '60%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Item No:',
      type: 'tr12',
      placeholder: 'Item No',
      textFeild: true,
      index: 13,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '211px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Item Name',
      type: 'tr12',
      placeholder: 'Item Name',
      textFeild: true,
      index: 14,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '211px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Sub Total:',
      type: 'tr13',
      placeholder: 'Sub Total',
      textFeild: true,
      index: 15,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '608.13px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '$00.00',
      type: 'tr13',
      placeholder: 'Item Cost',
      textFeild: true,
      index: 16,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '608.13px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'GST Amount:',
      type: 'tr14',
      placeholder: 'GST Amount',
      textFeild: true,
      index: 17,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '623.13px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '$00.00',
      type: 'tr14',
      placeholder: 'GST Amount',
      textFeild: true,
      index: 18,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '623.13px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Total Cost:',
      type: 'tr15',
      placeholder: 'Total Cost',
      textFeild: true,
      index: 19,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '638.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '$00.00',
      type: 'tr15',
      placeholder: 'Total Cost',
      textFeild: true,
      index: 20,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '638.27px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'I hope that the above pricing meets with your approval and look forward to being of service to you in the future. If we have misinterpreted your requirements or if you require any additional detail or ammendments, please do not hestitate to contact me on 0409 774 687',
      type: 'tr16',
      placeholder: 'Quote Closing Text',
      textFeild: true,
      index: 21,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '674.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '[Complimentary Close]',
      type: 'tr17',
      placeholder: 'Complimentary Close',
      textFeild: true,
      index: 22,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '722.13px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[Signature]',
      url: '',
      type: 'tr18',
      placeholder: 'Signature',
      textFeild: false,
      index: 23,
      style: {
        lineHeight: '12px',
        imageHeight: 100,
        imageWidth: 100,
        imageActualHeight: 100,
        imageActualWidth: 100,
        imageMarginLeft: 0,
        imageMarginRight: 0,
        imageMarginTop: 0,
        imageMarginBottom: 0,
        imageRotate: 0,
        imageUrl: '',
        zIndex: 9,
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '737.47px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        textAlign: 'left',
        width: 'fit-content',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
        height: '14px',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[First & Last name]',
      type: 'tr19',
      placeholder: 'First & Last name',
      textFeild: true,
      index: 24,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '751.46px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
    {
      text: '[Title]',
      type: 'tr20',
      placeholder: 'Title',
      textFeild: false,
      index: 25,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '766.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },

      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[Mobile]',
      type: 'tr21',
      placeholder: 'Mobile No',
      textFeild: false,
      index: 26,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '780.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[Email]',
      type: 'tr22',
      placeholder: 'Email',
      textFeild: false,
      index: 27,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '793.47px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
  ];

  const dataUS = [
    {
      // text: 'http://calendar-pro.s3.amazonaws.com/TemplateImages/4d26b90eff338e1c7a65f15e01804560.png',
      // url: 'http://calendar-pro.s3.amazonaws.com/TemplateImages/4d26b90eff338e1c7a65f15e01804560.png',
      text: '',
      url: '',
      type: 'img',
      textFeild: false,
      index: 0,
      placeholder: 'Company Logo',
      style: {
        lineHeight: '9px',
        imageHeight: '50px',
        imageWidth: '100px',
        imageRotate: 0,
        imageUrl: '',
        zIndex: 9,
        top: '0px',
        left: '5px',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        textAlign: 'left',
        height: 'fit-content',
        paddingLeft: '0px',
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    {
      text: companyLocations || '5/607 St Kilda Road Melboume VIC 3004',
      type: 'tr1',
      placeholder: 'Company Location',
      textFeild: true,
      index: 1,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '5px',
        left: '542px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0px',
      },
    },
    {
      text: mobile || '0409 774 687',
      type: 'tr2',
      placeholder: 'Mobile No',
      textFeild: false,
      index: 2,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '18px',
        left: '542px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
      },
    },
    {
      text: 'Bill To:',
      type: 'tr3',
      placeholder: 'Bill To',
      textFeild: true,
      staticFeild: 'Tax Invoice',
      index: 3,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '32px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
      },
    },
    {
      text: email || 'www.cleanpools.com.au',
      type: 'tr3',
      placeholder: 'Email',
      textFeild: false,
      index: 4,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '32px',
        left: '542px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
        top: '1',
      },
    },
    {
      text: '[Billing Address]',
      type: 'tr4',
      placeholder: 'Billing Address',
      textFeild: true,
      staticFeild: 'Tax Invoice',
      index: 5,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '43px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
      },
    },
    {
      text: companyName || 'TypeCraft Pvt Ltd trading as CleanPools Pool maintanace experts ABN 80 007 007 007',
      type: 'tr4',
      placeholder: 'Company Name',
      textFeild: false,
      index: 6,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '43px',
        left: '542px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0px',
      },
    },

    {
      text: '[Date]',
      type: 'tr7',
      placeholder: 'Date',
      textFeild: true,
      index: 7,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        height: '12px',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '78px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0%',
      },
    },
    {
      text: 'Dear <fname>,',
      type: 'tr8',
      placeholder: 'Client Name',
      textFeild: true,
      staticFeild: 'PAUL - 21 VERMON STREET VIC 3146',
      index: 8,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '102px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingLeft: '0%',
        paddingTop: '0%',
        paddingBottom: '0px',
      },
    },
    {
      text: 'Thank you for the time spent discussing your pool requirements. Please find our quote detailed below with our recommendations and costs',
      type: 'tr9',
      placeholder: 'Quote Opening Text',
      textFeild: true,
      index: 9,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '117px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        wordBreak: 'break-all',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Quote #',
      type: 'tr10',
      placeholder: 'Quote #',
      textFeild: true,
      index: 10,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '160px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '15%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '[Quote]',
      type: 'tr10',
      placeholder: 'Quote',
      textFeild: true,
      index: 11,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '165px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: '85%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Description:',
      type: 'tr11',
      placeholder: 'Description',
      textFeild: true,
      index: 12,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '174px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '40%',
      },
      tdStyle2: {
        width: '60%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Item No:',
      type: 'tr12',
      placeholder: 'Item No',
      textFeild: true,
      index: 13,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '211px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Item Name',
      type: 'tr12',
      placeholder: 'Item Name',
      textFeild: true,
      index: 14,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '211px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Sub Total:',
      type: 'tr13',
      placeholder: 'Sub Total',
      textFeild: true,
      index: 15,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '608.13px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '$00.00',
      type: 'tr13',
      placeholder: 'Item Cost',
      textFeild: true,
      index: 16,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '608.13px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'GST Amount:',
      type: 'tr14',
      placeholder: 'GST Amount',
      textFeild: true,
      index: 17,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '623.13px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '$00.00',
      type: 'tr14',
      placeholder: 'GST Amount',
      textFeild: true,
      index: 18,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '623.13px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'Total Cost:',
      type: 'tr15',
      placeholder: 'Total Cost',
      textFeild: true,
      index: 19,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '638.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: '45%',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '$00.00',
      type: 'tr15',
      placeholder: 'Total Cost',
      textFeild: true,
      index: 20,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '638.27px',
        left: '300px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: 'I hope that the above pricing meets with your approval and look forward to being of service to you in the future. If we have misinterpreted your requirements or if you require any additional detail or ammendments, please do not hestitate to contact me on 0409 774 687',
      type: 'tr16',
      placeholder: 'Quote Closing Text',
      textFeild: true,
      index: 21,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '674.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
      },
      tdStyle2: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
    },
    {
      text: '[Complimentary Close]',
      type: 'tr17',
      placeholder: 'Complimentary Close',
      textFeild: true,
      index: 22,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '722.13px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0%',
        paddingLeft: '0%',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[Signature]',
      url: '',
      type: 'tr18',
      placeholder: 'Signature',
      textFeild: false,
      index: 23,
      style: {
        lineHeight: '12px',
        imageHeight: 100,
        imageWidth: 100,
        imageActualHeight: 100,
        imageActualWidth: 100,
        imageMarginLeft: 0,
        imageMarginRight: 0,
        imageMarginTop: 0,
        imageMarginBottom: 0,
        imageRotate: 0,
        imageUrl: '',
        zIndex: 9,
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '737.47px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        textAlign: 'left',
        width: 'fit-content',
        paddingLeft: '0%',
        paddingBottom: '0px',
        paddingTop: '0%',
        height: '14px',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[First & Last name]',
      type: 'tr19',
      placeholder: 'First & Last name',
      textFeild: true,
      index: 24,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '751.46px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
    {
      text: '[Title]',
      type: 'tr20',
      placeholder: 'Title',
      textFeild: false,
      index: 25,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '766.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },

      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[Mobile]',
      type: 'tr21',
      placeholder: 'Mobile No',
      textFeild: false,
      index: 26,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '780.27px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
      tdStyle2: {
        width: 'fit-content',
      },
    },
    {
      text: '[Email]',
      type: 'tr22',
      placeholder: 'Email',
      textFeild: false,
      index: 27,
      style: {
        lineHeight: '12px',
        font: "'Exo 2', sans-serif",
        fontStyle: 'Normal',
        color: '#333333',
        fontSize: '9px',
        textAlign: 'left',
        textDecoration: 'unset',
        fontWeight: 'unset',
        top: '793.47px',
        left: '8px',
        display: 'block',
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
      },
      tdStyle2: {
        width: 'fit-content',
      },
      tdStyle1: {
        width: 'fit-content',
        textAlign: 'left',
        height: '14px',
        paddingTop: '0px',
        paddingLeft: '0%',
      },
    },
  ];

  const [templateData, setTemplateData] = useState(countryType === 'us' ? dataUS : dataAU);
  const [templateDataCopy, setTemplateDataCopy] = useState(countryType === 'us' ? dataUS : dataAU);
  const [arrayOne, setArrayOne] = useState(countryType === 'us' ? dataUS : dataAU);
  const [showEdit, setShowEdit] = useState(false);
  const [customFeilds, setCustomFeilds] = useState([]);
  const [templateDataCopy2, setTemplateDataCopy2] = useState(countryType === 'us' ? dataUS : dataAU);
  const handleShow = () => {
    setShowEdit(true);
  };
  const handleClose = () => {
    setShowEdit(false);
  };
  const [tableNo, setTableNo] = useState(0);
  const [defaultTemplateId, setDefaultTemplateId] = useState(null);
  const [templateArray, setTemplateArray] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [textPopup, setTextPopup] = useState(false);
  const [customName, setCustomName] = useState('');
  const [customNameTwo, setCustomNameTwo] = useState('');
  const [zoomin, setZoomIn] = useState(false);
  const [previewPopup, setPreviewPopup] = useState();
  const [editquoteTemplate, setEditTemplate] = useState();
  const [addTextpopup, setAddTextPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const [texts, setText] = useState('');
  const [addImagepopup, setAddImagePopup] = useState(false);
  const [editImagepopup, setEditImagePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [defaultStatus, setDefaultStatus] = useState();
  const [placeholderText, setPlaceholderText] = useState('');
  const [tdIndex, settdIndex] = useState('');
  const [isEdit, setisEdit] = useState(false);
  const [widthValue, setWidthValue] = useState();
  const [isDefaultSelect, setIsDefaultSelect] = useState(false);
  const [defaultPopup, setDefaultPopup] = useState(false);

  const AddImagePopup = () => {
    setAddImagePopup(false);
  };

  const OpenAddImagePopup = () => {
    setAddImagePopup(true);
  };

  const EditImagePopup = () => {
    setEditImagePopup(false);
  };

  const OpenEditImagePopup = () => {
    setEditImagePopup(true);
  };

  const handleImageNew = (dataa, info, k) => {
    setText(dataa);
    setCustomName(info);
    setIndex(k);
    OpenEditImagePopup();
  };

  const AddNewTextPopup = () => {
    setAddTextPopup(false);
  };

  const OpenAddNewTextPopup = () => {
    setAddTextPopup(true);
  };
  const handleTextPopup = () => {
    setTextPopup(false);
  };

  const handleImagePopup = () => {
    setShowPopup(false);
  };

  const handleImagePopupOpen = (k, plcText) => {
    setPlaceholderText(plcText);
    setIndex(k);
    setShowPopup(true);
  };

  const setData = () => {
    setTemplateData(templateDataCopy);
    handleTextPopup();
    handleImagePopup();
    AddNewTextPopup();
    setIsNewChange(true);
  };
  const handleText = (dataa, info, k, f, plcText, tdindex, e) => {
    let copyDataObj = { ...dataa };
    const child = e.target.getBoundingClientRect();
    if (!isEdit) {
      return;
    }
    let oldtempObject = [...templateDataCopy];
    let newWidth = child.width.toFixed(2);
    oldtempObject[k][tdindex].width = `${newWidth}px`;
    oldtempObject[k].style = copyDataObj;
    setTemplateDataCopy(oldtempObject);
    setPlaceholderText(plcText);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
    setTableNo(f);
    settdIndex(tdindex);
    setTextPopup(true);
  };
  const handleMoveText = (dataa, info, k, f, plcText, tdindex, top, left) => {
    let topVal = top.toFixed(2);
    let leftVal = left.toFixed(2);
    let copyDataObj = { ...dataa };
    copyDataObj.top = `${topVal}px`;
    copyDataObj.left = `${leftVal}px`;
    if (!isEdit) {
      return;
    }
    let oldtempObject = [...templateDataCopy];
    oldtempObject[k].style = copyDataObj;
    setTemplateDataCopy(oldtempObject);
    setPlaceholderText(plcText);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
    setTableNo(f);
    settdIndex(tdindex);
  };

  const handleTextNew = (dataa, info, k, e) => {
    let copyDataObj = { ...dataa };
    const child = e.target.getBoundingClientRect();
    let oldtempObject = [...customFeilds];
    oldtempObject[k].style = copyDataObj;
    oldtempObject[k].style.position = 'initial';
    setCustomFeilds(oldtempObject);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
    handleShow();
  };

  const handleTextNewMove = (dataa, info, k, top, left) => {
    let topVal = top.toFixed(2);
    let leftVal = left.toFixed(2);
    let copyDataObj = { ...dataa };
    copyDataObj.top = `${topVal}px`;
    copyDataObj.left = `${leftVal}px`;
    let oldtempObject = [...customFeilds];
    oldtempObject[k].style = copyDataObj;
    oldtempObject[k].style.position = 'initial';
    setCustomFeilds(oldtempObject);
    setText(copyDataObj);
    setCustomName(info);
    setIndex(k);
  };

  const saveTemplate = async () => {
    try {
      if (templateName) {
        if (view !== 'addquoteTemplate' && defaultTemplateId) {
          const formdata = new FormData();
          formdata.append('templateData', JSON.stringify(templateData));
          formdata.append('templateDataOne', JSON.stringify([]));
          formdata.append('customFeilds', JSON.stringify(customFeilds));
          formdata.append('templateName', templateName);
          formdata.append('file', templateData[0].url);
          formdata.append('id', defaultTemplateId);
          formdata.append('type', 'quote');
          formdata.append('defaulttype', defaulttype);
          formdata.append('sigfile', templateData[23].url);
          const response = await notTimeOutUserInstance().post('/template/editTemplate', formdata);
          const { code, msg, tempId } = response.data;
          setDefaultTemplateId(tempId._id);
          setTemplateName(tempId.templateName);
          setTemplateData(tempId.currentTemplateData);
          setTemplateDataCopy(tempId.currentTemplateData);
          setCustomFeilds(tempId.customFeilds);
          setDefaulttype('');
          toastMessage('success', msg);
          setSavePopup();

          getAllTemplate();
        } else {
          let copytemplateData = [...templateData];
          copytemplateData[25].textFeild = true;
          copytemplateData[26].textFeild = true;
          copytemplateData[27].textFeild = true;
          const defaultval = templateArray.length === 0 ? 'default' : defaulttype;
          const formdata = new FormData();
          formdata.append('templateData', JSON.stringify(copytemplateData));
          formdata.append('templateDataOne', JSON.stringify([]));
          formdata.append('customFeilds', JSON.stringify(customFeilds));
          formdata.append('templateName', templateName);
          formdata.append('file', []);
          formdata.append('type', 'quote');
          formdata.append('defaultStatus', defaultStatus);
          formdata.append('file2', customFeilds[0] ? customFeilds[0].url : '');
          formdata.append('sigfile', templateData[23].url);
          formdata.append('defaulttype', defaultval);
          const response = await notTimeOutUserInstance().post('/template/addTemplate', formdata);
          const { code, msg } = response.data;
          // if (defaulttype === 'default') {
          //   getAllTemplate();
          // }

          setTemplateData(countryType === 'us' ? dataUS : dataAU);
          setTemplateDataCopy(countryType === 'us' ? dataUS : dataAU);
          setCustomFeilds([]);
          toastMessage('success', msg);
          setSavePopup();
          setView('QuoteTemplate');
          setDefaulttype('');
        }
      } else {
        toastMessage('error', 'Please enter template name.');
      }
    } catch (error) {
      console.log(error);
    }
    if (isParentClickDropdown) {
      setMainView(dummyMainPageView);
      setIsParentClickDropdown(false);
    } else if (saveTemplateSelectedDropdown) {
      await setTemp(saveTemplateSelectedDropdown);
    } else {
      setView(dummyView);
    }
    setIsNewChange(false);
  };

  const handleZoomin = () => {
    setZoomIn(!zoomin);
  };
  const handlePreviewPopup = async () => {
    try {
      setPreviewPopup(!previewPopup);
      let copyTemplateDataArra = templateData;
      copyTemplateDataArra[8].text = 'Dear,';
      setLoading(true);
      const payload = {
        templateData: copyTemplateDataArra,
        customFeilds,
        type: 'quote',
        templateDataOne: {
          services: [],
        },
        countryType,
      };
      // console.log('payload===>', payload);
      const response = await userInstance().post('/template/previewTemplatePdf', payload);
      const { code, msg, tempId } = response.data;
      setPreviewUrl(tempId);
      setLoading(false);
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };

  const handleDefaultPopup = (type) => {
    setDefaultPopup(!defaultPopup);
    setDefaulttype(type);
    if (view === 'addTemplate') {
      setTemplateName('');
    }
  };

  const handleDataPopup = (dataa) => {
    setTemplateName(dataa);
  };
  const renderedView = () => {
    let componentToRender = null;
    switch (view) {
      case 'QuoteTemplate':
        componentToRender = (
          <TemplateSection arrayOne={arrayOne} customFeilds={customFeilds} countryType={countryType} />
        );
        break;
      case 'editquoteTemplate':
        componentToRender = (
          <AddEditTemplate
            templateData={templateData}
            // templateDataOne={templateDataOne}
            handleText={handleText}
            handleImagePopupOpen={handleImagePopupOpen}
            customFeilds={customFeilds}
            setCustomFeilds={setCustomFeilds}
            handleTextNew={handleTextNew}
            handleImageNew={handleImageNew}
            defaultTemplateId={defaultTemplateId}
            handleMoveText={handleMoveText}
          />
        );
        break;
      case 'addquoteTemplate':
        componentToRender = (
          <AddEditTemplate
            templateData={templateData}
            // templateDataOne={templateDataOne}
            handleText={handleText}
            handleImagePopupOpen={handleImagePopupOpen}
            customFeilds={customFeilds}
            setCustomFeilds={setCustomFeilds}
            handleTextNew={handleTextNew}
            handleImageNew={handleImageNew}
            handleTextNewMove={handleTextNewMove}
            handleMoveText={handleMoveText}
            setTemplateDataCopy={setTemplateDataCopy}
            view={view}
          />
        );
        break;
      default:
        break;
    }
    return componentToRender;
  };
  const updateView = (key, type) => {
    if (isNewChange && view !== key) {
      setDummyView(key);
      setSaveTemplateConfirmPopup(!saveTemplateConfirmPopup);
      setIsParentClickDropdown(false);
      return;
    }
    setView(key);
    if (key === 'addquoteTemplate') {
      setTemplateData(countryType === 'us' ? dataUS : dataAU);
      setTemplateDataCopy(countryType === 'us' ? dataUS : dataAU);
      setCustomFeilds([]);
      setTemplateName('');
    } else {
      getAllTemplate(key);
    }
    if (type === 'isedit' || key === 'addquoteTemplate') {
      setisEdit(true);
    } else {
      setisEdit(false);
    }
  };

  const getAllTemplate = async (info) => {
    try {
      const viewSection = info || 'QuoteTemplate';
      const response = await userInstance().get('/template/getAllTemplate?type=quote');
      const { code, msg, temp } = response.data;
      if (temp.length > 0) {
        setDefaultStatus(false);
        setTemplateArray(temp);
        const defaultTemp = temp.filter((el, i) => el.default);
        if (defaultTemp.length > 0) {
          setIsDefaultSelect(true);
          // console.log('defaultTemp====>', defaultTemp);
          // setTemp(defaultTemp[0]._id);
          let updated = defaultTemp[0].currentTemplateData;
          // updated[1].text = companyLocations || updated[1].text;
          updated[2].text = mobile || updated[2].text;
          updated[4].text = email || updated[3].text;
          updated[6].text = companyName || '';
          setDefaultTemplateId(defaultTemp[0]._id);
          setArrayOne(updated);
          setCustomFeilds(defaultTemp[0].customFeilds);
          setTemplateName(defaultTemp[0].templateName);
          setTemplateData(updated);
          setTemplateDataCopy(updated);
        } else {
          setIsDefaultSelect(false);
          // setTemp(temp[0]._id);
          let updated = temp[0].currentTemplateData;
          // updated[1].text = companyLocations || updated[1].text;
          updated[2].text = mobile || updated[2].text;
          updated[4].text = email || updated[3].text;
          updated[6].text = companyName || '';
          // setArrayOne(updated);
          setCustomFeilds(temp[0].customFeilds);
          setDefaultTemplateId(temp[0]._id);
          setTemplateName(temp[0].templateName);
          setTemplateData(updated);
          setTemplateDataCopy(updated);
        }
      } else {
        setDefaulttype('default');
        setDefaultStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplate();
  }, []);

  const setTemp = async (info) => {
    try {
      const response = await userInstance().get(`/template/getTemplateById?id=${info}`);
      const { code, msg, tempId } = response.data;
      setIsDefaultSelect(tempId?.default);
      setDefaultTemplateId(tempId._id);
      setTemplateName(tempId.templateName);
      let updated = tempId.currentTemplateData;
      // updated[1].text = companyLocations || updated[1].text;
      updated[2].text = mobile || updated[2].text;
      updated[4].text = email || updated[3].text;
      updated[6].text = companyName || '';
      setTemplateData(updated);
      setTemplateDataCopy(updated);
      setCustomFeilds(tempId.customFeilds);
    } catch (error) {
      console.log(error);
    }
    setSaveTemplateSelectedDropdown('');
  };

  const Revert = async () => {
    try {
      const payload = {
        id: defaultTemplateId,
      };
      const response = await userInstance().post('/template/revertTemplate', payload);
      const { code, msg, tempId } = response.data;
      setDefaultTemplateId(tempId._id);
      setTemplateName(tempId.templateName);
      setTemplateData(tempId.currentTemplateData);
      setTemplateDataCopy(tempId.currentTemplateData);
      // setTemplateDataOne(tempId.currentTemplateDataOne);
      // setTemplateDataCopyOne(tempId.currentTemplateDataOne);
      setCustomFeilds(tempId.customFeilds);
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };
  // Delete the url work
  const deleteUrl = async () => {
    const payload = {
      previewUrl,
    };
    const response = await userInstance().post('/template/removeTemplateFroms3', payload);
    if (response) {
      setPreviewUrl(null);
    }
    setPreviewPopup(false);
  };
  const handleOnedit = () => {
    setisEdit(true);
  };
  return (
    <>
      <div className="target-heading client-profile">

        {view === 'QuoteTemplate' || view === 'addquoteTemplate' || view === 'editquoteTemplate' ? (
          <>
            <div className="invoice-header">
              <h3>
                {view === 'QuoteTemplate' ? 'Default Quote Template' : ''}
                {view === 'addquoteTemplate' ? 'Add New Quote Template' : ''}
                {view === 'editquoteTemplate' ? 'Saved  Quote Template' : ''}
              </h3>
            </div>
            <div className="content-dropdown">
              <div className="client-option service-edit-icon">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={showmore} alt="show" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div className="action" onClick={() => updateView('QuoteTemplate')}>
                        <span>Default Quote Template</span>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div className="action" onClick={() => updateView('addquoteTemplate')}>
                        <span>Add New Quote Template</span>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <div className="action">
                        <span onClick={() => updateView('editquoteTemplate')}>Saved Quote Templates</span>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </>
        ) : ''}
      </div>

      {view === 'editquoteTemplate' && (
      <div className="target-heading client-profile">
        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>
            Template Name
          </Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              name="customerType"
              value={defaultTemplateId}
              onChange={(e) => {
                if (isNewChange) {
                  setSaveTemplateConfirmPopup(true);
                  setSaveTemplateSelectedDropdown(e.target.value);
                } else {
                  setTemp(e.target.value);
                }
              }}
            >
              {templateArray && templateArray.map((temp) => (
                <option value={temp._id}>
                  {temp.templateName}
                  {temp.default ? '-default' : ''}
                </option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

      </div>
      )}

      <div className="edit-template-page">
        {renderedView()}

        <div className="template-btn view-btn">
          <div className="client-option service-edit-icon">
            {view === 'QuoteTemplate' ? (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                  View Other Settings
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => handlesavePopup('default')}>
                      Save Template As
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('editquoteTemplate', 'isedit')}>
                      Edit Template
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action">
                      Restore to Original Default
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action">
                      Revert to Previous Saved Default
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : ''}
            {view === 'editquoteTemplate' || view === 'addquoteTemplate' ? (
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                  View Other Settings
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    {!isDefaultSelect && (
                    <div className="action" onClick={() => handleDefaultPopup('default')}>
                      Save as Default
                    </div>
                    )}

                  </Dropdown.Item>
                  {!isEdit && view !== 'addquoteTemplate'
                    && (
                    <Dropdown.Item>
                      <div className="action" onClick={() => handleOnedit()}>
                        Edit Template
                      </div>
                    </Dropdown.Item>
                    )}
                  <Dropdown.Item>
                    <div className="action" onClick={handlePreviewPopup}>
                      Preview Template
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={OpenAddNewTextPopup}>
                      Add Text Field
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={OpenAddImagePopup}>
                      Add Picture Field
                    </div>
                  </Dropdown.Item>
                  {(view === 'editquoteTemplate') && (
                  <Dropdown.Item onClick={Revert}>
                    <div className="action">
                      Revert to Previous Saved
                    </div>
                  </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            ) : ''}
          </div>
          {view === 'QuoteTemplate' && (
          <Button className="save-btn" onClick={handlesavePopup}>Saved as default</Button>
          )}
          {(view === 'editquoteTemplate' || view === 'addquoteTemplate') && (
          <Button className="save-btn" onClick={handlesavePopup}>Save/Update</Button>
          )}
        </div>
        {/* {textPopup && (
        <ChangeTextPopup
          textPopup={textPopup}
          setTemplateDat={setTemplateData}
          templateDataCopy={tableNo === 0 ? templateDataCopy : ''}
          handleTextPopup={handleTextPopup}
          setTemplateDataCopy={tableNo === 0 ? setTemplateDataCopy : ''}
          text={texts}
          setText={setText}
          customName={customName}
          customNameTwo={index}
          setData={setData}
          placeholderText={placeholderText}
          tdIndex={tdIndex}
          templateDataCopy2={templateDataCopy2}
        />
        )} */}

        {textPopup && (
        <ChangeTextPopup
          textPopup={textPopup}
          setTemplateDat={setTemplateData}
          templateDataCopy={tableNo === 0 ? templateDataCopy : []}
          handleTextPopup={handleTextPopup}
          setTemplateDataCopy={tableNo === 0 ? setTemplateDataCopy : []}
          text={texts}
          setText={setText}
          customName={customName}
          customNameTwo={index}
          setData={setData}
          placeholderText={placeholderText}
          tdIndex={tdIndex}
          widthValue={widthValue}
          setTemplateData={setTemplateData}
          templateDataCopy2={templateDataCopy2}
          setIsNewChange={setIsNewChange}
        />
        )}

        {showPopup && (
        <ChangeImagePopup
          showPopup={showPopup}
          handleImagePopup={handleImagePopup}
          setData={setData}
          setTemplateDat={setTemplateData}
          templateDataCopy={templateDataCopy}
          setTemplateDataCopy={setTemplateDataCopy}
          index={index}
          placeholderText={placeholderText}
          tdIndex="tdStyle2"
          templateDataCopy2={templateDataCopy2}
        />
        )}
        {addImagepopup && (
        <AddNewImage
          showPopup={addImagepopup}
          handleImagePopup={AddImagePopup}
          setTemplateData={setTemplateData}
          templateDataCopy={templateDataCopy}
          setTemplateDataCopy={setTemplateDataCopy}
          text={texts}
          setText={setText}
          customName={customName}
          customNameTwo={index}
          setData={setData}
          handleShow={handleShow}
          customFeilds={customFeilds}
          setCustomFeilds={setCustomFeilds}
          index={index}
          placeholderText={placeholderText}
          templateData={templateData}
          setIsNewChange={setIsNewChange}
        />
        )}

        {editImagepopup && (
        <EditNewImage
          showPopup={editImagepopup}
          handleImagePopup={EditImagePopup}
          setTemplateDat={setTemplateData}
          templateDataCopy={templateDataCopy}
          setTemplateDataCopy={setTemplateDataCopy}
          text={texts}
          setText={setText}
          customName={customName}
          customNameTwo={index}
          setData={setData}
          handleShow={handleShow}
          customFeilds={customFeilds}
          setCustomFeilds={setCustomFeilds}
        />
        )}

        <Modal
          size="md"
          show={previewPopup}
          onHide={handlePreviewPopup}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="search-modal new-search-popup1 preview-popup preview-pdf"
        >
          <Modal.Body>
            <div className="appointment-container preview-invoice preview-popup">
              <PreviewTemplate
                templateData={templateData}
                customFeilds={customFeilds}
                previewUrl={previewUrl}
                loading={loading}
              />
              <div className="close-btn">
                <Button className="btn btn-primary" onClick={() => deleteUrl()}>Close</Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {savePopup && (
        <SaveTemplatePopup
          savePopup={savePopup}
          setSavePopup={setSavePopup}
          handleDataPopup={handleDataPopup}
          templateName={templateName}
          saveTemplate={saveTemplate}
          type="Quote"
        />
        )}
        {defaultPopup && (
          <SaveDefaultPopup
            savePopup={defaultPopup}
            setSavePopup={setDefaultPopup}
            handleDataPopup={handleDataPopup}
            templateName={templateName}
            saveTemplate={saveTemplate}
            type="Quote"
          />
        )}
        {addTextpopup && (
        <AddNewTextPopuppage
          addTextpopup={addTextpopup}
          setAddTextPopup={setAddTextPopup}
          handleTextPopup={AddNewTextPopup}
          setTemplateDat={setTemplateData}
          templateDataCopy={templateDataCopy}
          setTemplateDataCopy={setTemplateDataCopy}
          text={texts}
          setText={setText}
          customName={customName}
          customNameTwo={index}
          setData={setData}
          handleShow={handleShow}
          customFeilds={customFeilds}
          setCustomFeilds={setCustomFeilds}
          tdIndex="tdStyle2"
          setIsNewChange={setIsNewChange}
        />
        )}
        {showEdit && (
        <EditNewTextPopup
          addTextpopup={showEdit}
          handleTextPopup={handleShow}
          handleTextNew={handleTextNew}
          customFeilds={customFeilds}
          setCustomFeilds={setCustomFeilds}
          handleClose={handleClose}
          text={texts}
          setText={setText}
          customName={customName}
          customNameTwo={index}
          tdIndex="tdStyle2"
        />
        )}
      </div>
      {saveTemplateConfirmPopup && !isParentClickDropdown && (
      <SaveTemplateConfirmPopup isMain={false} setSaveTemp={setTemp} />
      )}
    </>
  );
};
QuoteTemplate.propTypes = {
  viewtype: PropTypes.string.isRequired,
};
export default QuoteTemplate;

const htmlJson = {
  tr1: (element, iii) => (
    <div>
      <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          {element.events.map((el) => (
            <>
              {!!el?.text && (
              <>
                <span
                  className="test"
                  style={{
                    ...el.style,
                  }}
                >
                  {el.text}
                </span>
              </>
              )}
            </>
          ))}
        </div>
      </Draggable>
    </div>
  ),
  tr2: (element, iii) => (
    <div>
      <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          {element.events.map((el) => (
            <>
              {el?.text && (
              <div style={el.tdStyle2}>
                <span
                  style={{
                    ...el.style,
                  }}
                >
                  {el.text}
                </span>
              </div>
              )}
            </>
          ))}
        </div>
      </Draggable>
    </div>
  ),
  tr3: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          {element.events[0].text ? (
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>

          ) : (
            <>
              <div />
            </>
          )}
        </div>
      </Draggable>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[1].tdStyle2, position: 'absolute' }}

        >
          {element.events[1].text && (
          <span
            style={{
              ...element?.events[1]?.style,
            }}
          >
            {element.events[1].text}
          </span>
          )}
        </div>
      </Draggable>
    </div>
  ),
  tr4: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          {element.events[0].text ? (
            <>
              <div style={element.events[0].tdStyle1}>
                <span
                  style={{
                    ...element?.events[0]?.style,
                  }}
                >
                  {element.events[0].text}
                </span>
              </div>
            </>
          ) : (
            <>
              <div />
            </>
          )}
        </div>
      </Draggable>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[1].tdStyle2, position: 'absolute' }}

        >
          {element.events[1].text && (
          <div style={element.events[1].tdStyle2}>
            <span
              style={{
                ...element.events[1].style,
              }}
            >
              {element?.events[1]?.text}
            </span>
          </div>
          )}
        </div>
      </Draggable>
    </div>
  ),
  tr5: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          {element.events[0].text && (
          <>
            <div style={element.events[0].tdStyle1}>
              {element.events.map((el, i) => (
                <>
                  <span
                    style={{
                      ...el.style,
                    }}
                    role="presentation"

                  >
                    {el.text}
                  </span>
                </>
              ))}
            </div>
            <div style={element.events[0].tdStyle2} />
          </>
          )}
        </div>
      </Draggable>
    </div>
  ),
  tr6: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          {element.events[0].text && (
          <>
            <div style={element.events[0].tdStyle1}>
              <span
                style={element.events[0].style}
              >
                {element.events[0].text}
              </span>
            </div>
          </>
          )}
          <div style={element.events[1].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  tr7: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <div style={element.events[0].tdStyle2}>
            <span
              style={{
                ...element?.events[0]?.style,
              }}
            >
              {element.events[0].text}
            </span>
          </div>
          <div style={element.events[0].tdStyle1} />
        </div>
      </Draggable>
    </div>
  ),
  tr8: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <div style={element.events[0].tdStyle1} />
          {element.events.map((el, i) => (
            <>
              {/* <td style={element.events[0].tdStyle1} /> */}
              <div style={el.tdStyle2}>
                <span
                  style={{
                    ...el.style,
                  }}
                >
                  {el.text}
                </span>
              </div>
            </>
          ))}
        </div>
      </Draggable>
    </div>
  ),
  tr9: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <div style={element.events[0].tdStyle2}>
            <span
              style={{
                ...element?.events[0]?.style,
              }}
            >
              {element.events[0].text}
            </span>
          </div>
          <div style={element.events[0].tdStyle1} />
        </div>
      </Draggable>
    </div>
  ),
  tr10: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle1, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
        </div>
      </Draggable>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[1].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[1].style,
            }}
          >
            {element.events[1].text}
          </span>
        </div>
      </Draggable>
    </div>
  ),
  tr11: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <div style={element.events[0].tdStyle1}>
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
          </div>
          <div style={element.events[0].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  tr12: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle1, position: 'absolute' }}

        >

          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
        </div>
      </Draggable>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[1].tdStyle2, position: 'absolute' }}

        >

          <span
            style={{
              ...element.events[1].style,
            }}
          >
            {element.events[1].text}
          </span>
        </div>
      </Draggable>
    </div>
  ),
  tr13: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle1, position: 'absolute' }}

        >
          <span
            style={element.events[0].style}
          >
            {element.events[0].text}
          </span>
        </div>
      </Draggable>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[1].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[1].style,
            }}
          >
            {element.events[1].text}
          </span>
        </div>
      </Draggable>
    </div>
  ),
  tr14: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle1, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
        </div>
      </Draggable>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[1].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[1].style,
            }}
          >
            {element.events[1].text}
          </span>
        </div>
      </Draggable>
    </div>
  ),
  tr15: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle1, position: 'absolute' }}

        >
          <span
            style={element.events[0].style}
          >
            {element.events[0].text}
          </span>
        </div>
      </Draggable>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[1].tdStyle1, position: 'absolute' }}

        >
          <span
            style={element.events[1].style}
          >
            {element.events[1].text}
          </span>
        </div>
      </Draggable>
    </div>
  ),
  tr16: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element?.events[0]?.style,
            }}
          >
            {element.events[0].text}
          </span>
        </div>
      </Draggable>
      <div style={element.events[0].tdStyle1} />
    </div>
  ),
  tr17: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
          <div style={element.events[0].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  tr18: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
          <div style={element.events[0].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  tr19: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
          <div style={element.events[0].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  tr20: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
          <div style={element.events[0].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  tr21: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle1, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
          <div style={element.events[0].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  tr22: (element, iii) => (
    <div>
      <Draggable
        position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
      >
        <div
          className="box"
          style={{ ...element.events[0].tdStyle2, position: 'absolute' }}

        >
          <span
            style={{
              ...element.events[0].style,
            }}
          >
            {element.events[0].text}
          </span>
          <div style={element.events[0].tdStyle2} />
        </div>
      </Draggable>
    </div>
  ),
  img: (element, iii) => (
    <>
      <>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
          // style={element.events[0].tdStyle2}
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
          >
            {element.events.map((el, i) => (
              <>
                {el?.text !== '' && (
                <img
                  src={el?.text}
                  alt="logo"
                  style={{
                    maxHeight: `${el.style.imageHeight}`,
                    height: 'auto',
                    width: `${el.style.imageWidth}`,
                    objectFit: 'contain',
                    left: `${el.style.left}`,
                    top: `${el.style.top}`,
                    transform: `rotate(${el.style.imageRotate}deg)`,
                    zIndex: el.style.zIndex,
                  // position: el.style.position,
                  }}
                />
                // <img
                //   src={el.preview ? el?.text : el.url}
                //   alt="logo"
                //   style={{
                //     height: `${el.style.imageHeight}`,
                //     width: `${el.style.imageWidth}`,
                //     objectFit: 'contain',
                //     position: 'absolute',
                //     marginRight: `${el.style.imageMarginRight}`,
                //     left: `${el.style.imageMarginLeft}`,
                //     top: `${el.style.imageMarginTop}`,
                //     marginBottom: `${el.style.imageMarginBottom}`,
                //     transform: `rotate(${el.style.imageRotate})deg`,
                //     zIndex: el.style.zIndex,
                //   }}
                //   onClick={() => handleImageNew(el?.style, el?.text, el?.index)}
                // />
                )}
              </>
            ))}
          </div>
        </Draggable>
      </>
    </>
  ),
};
const customJson = {
  tr: (element, iii) => (
    <div>
      <div style={element?.tdStyle2}>
        <span
          style={{
            ...element.style,
          }}
          role="presentation"
        >
          {element.text}
        </span>
      </div>
    </div>
  ),
  img: (element, iii) => (
    <div>
      <div>
        <img
          src={element.text}
          alt="logo"
          style={{
            height: `${element.style.imageHeight}`,
            width: `${element.style.imageWidth}`,
            objectFit: 'contain',
            position: 'absolute',
            marginRight: `${element.style.imageMarginRight}`,
            left: `${element.style.imageMarginLeft}`,
            top: `${element.style.imageMarginTop}`,
            marginBottom: `${element.style.imageMarginBottom}`,
            transform: `rotate(${element.style.imageRotate})deg`,
            zIndex: element.style.zIndex,
          }}
        />
      </div>
    </div>
  ),
};
export const TemplateSection = ({ arrayOne, customFeilds, countryType }) => (
  <div className="quotes-template-page default-quotes-template">
    <page size="A4">
      {/* <TransformWrapper>
          <TransformComponent> */}
      <div
        id="myTable"
        className="edit-template quotes-edit-temp add-edit-quotes-template"
        style={{
          overflow: 'hidden',
          wordBreak: 'break-word',
          width: `${countryType === 'us' ? '7.9in' : '595px'}`,
          margin: '0px auto',
          borderTop: '1px solid lightgray',
          borderRight: '1px solid lightgray',
          borderLeft: 'none',
          display: 'block',
          backgroundColor: 'white',
          position: 'relative',
          height: `${countryType === 'us' ? '11in' : '842px'}`,
          border: '1px solid rgb(219 219 219)',
          padding: '0px',
        }}
      >
        {arrayOne && Array.from(new Set(arrayOne.map((s) => s.type))).map(
          (lab) => ({
            name: lab,
            events: arrayOne
              .filter((s) => s.type === lab)
              .map((edition) => edition),
          }),
        ).map((el, i) => (
          <>
            {htmlJson[el.name](el, i)}
          </>
        ))}
        {customFeilds && customFeilds.map((cust) => (
          <>
            {customJson[cust.type](cust)}
          </>
        ))}
        {' '}
      </div>
    </page>
  </div>
);

TemplateSection.propTypes = {
  arrayOne: PropTypes.objectOf.isRequired,
  customFeilds: PropTypes.objectOf.isRequired,
  countryType: PropTypes.string.isRequired,
};
