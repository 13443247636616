import React from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { propTypes } from 'react-bootstrap/esm/Image';

const EditSMSInvoiceTemplate = ({
  show, handleclose, handleonChange, payload, handleSaveUpdate,
}) => (

  <Modal
    size="lg"
    show={show}
    onHide={handleclose}
    aria-labelledby="contained-modal-title-vcenter"
    top
    className="dropdown-modal edit-working-popup edit-daystime-popup"
  >
    <div className="staff-close">
      <i className="fa fa-times" onClick={handleclose} aria-hidden="true" />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Edit SMS Invoice Template
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="staff-form">
        <div className="shift-container">
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicCompanyType">
                <Form.Label>
                  Salutation
                </Form.Label>
                <div className="select-box">
                  {/* <i className="fas fa-sort-down" /> */}
                  <Form.Control
                    as="select"
                    name="salutation"
                    onChange={(e) => handleonChange(e)}
                  >
                    <option value="Hi" selected={payload?.salutation === 'Hi'}>Hi</option>
                    <option value="Hello" selected={payload?.salutation === 'Hello'}>Hello</option>
                    <option value="Dear" selected={payload?.salutation === 'Dear'}>Dear</option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicCompanyType">
                <Form.Label>
                  Addressee
                </Form.Label>
                <div className="select-box">
                  {/* <i className="fas fa-sort-down" /> */}
                  <Form.Control
                    as="select"
                    name="address"
                    onChange={(e) => handleonChange(e)}
                  >
                    <option value="fname" selected={payload?.address === 'fname'}> &lt;fname&gt;, </option>
                    <option value="fname-lname" selected={payload?.address === 'fname-lname'}> &lt;fname lname&gt;, </option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1">
                <Form.Label>
                  Text Opening
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="textOpening"
                  value={payload?.textOpening}
                  onChange={(e) => handleonChange(e)}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1">
                <Form.Label>
                  Text Closing
                </Form.Label>
                <Form.Control
                  type="text"
                  name="textClosing"
                  value={payload?.textClosing}
                  onChange={(e) => handleonChange(e)}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicCompanyType">
                <Form.Label>
                  Complimentary Close
                </Form.Label>
                <div className="select-box">
                  {/* <i className="fas fa-sort-down" /> */}
                  <Form.Control
                    as="select"
                    name="complimentary"
                    onChange={(e) => handleonChange(e)}
                  >
                    <option value="Yours Sincerly," selected={payload?.complimentary === 'Yours Sincerly,'}>Yours Sincerly,</option>
                    <option value="Yours truely," selected={payload?.complimentary === 'Yours truely,'}>Yours truely,</option>
                    <option value="Best Wishes," selected={payload?.complimentary === 'Best Wishes,'}>Best Wishes,</option>
                    <option value="Warm Wishes," selected={payload?.complimentary === 'Warm Wishes,'}>Warm Wishes,</option>
                    <option value="Kind Wishes," selected={payload?.complimentary === 'Kind Wishes,'}>Kind Wishes,</option>
                    <option value="Best Regards," selected={payload?.complimentary === 'Best Regards,'}>Best Regards,</option>
                    <option value="Warm Regards," selected={payload?.complimentary === 'Warm Regards,'}>Warm Regards,</option>
                    <option value="Kind Regards," selected={payload?.complimentary === 'Kind Regards,'}>Kind Regards,</option>
                    <option value="With Thanks," selected={payload?.complimentary === 'With Thanks,'}>With Thanks,</option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1">
                <Form.Label>
                  Sender&apos;s name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="senderName"
                  onChange={(e) => handleonChange(e)}
                  value={payload?.senderName}
                />
              </Form.Group>
            </div>
          </div>

          <div className="view-btn">
            <Button type="submit" className="btn btn-delete" onClick={handleclose}>Cancel</Button>
            <Button type="submit" className="btn btn-save" onClick={handleSaveUpdate}>Save/Update</Button>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
EditSMSInvoiceTemplate.propTypes = {
  show: PropTypes.bool.isRequired,
  handleclose: PropTypes.func.isRequired,
  payload: PropTypes.objectOf.isRequired,
  handleonChange: PropTypes.func.isRequired,
  handleSaveUpdate: PropTypes.func.isRequired,
};
export default EditSMSInvoiceTemplate;
