import React, { useState } from 'react';
import {
  Button, Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { revertKeyClient } from '../../functions/index';
import arrow from '../../assets/home/whitearrow.png';

const ClientErrortable = ({ uploadErrors, handleClose, confirmSelectUploadErrorFromUpload }) => {
  const [listingPage, setlistingPage] = useState(true);
  const [summary, setSummary] = useState([]);

  const handleDetailsView = (summaryData) => {
    setlistingPage(false);
    setSummary(summaryData);
  };

  const traverseCondition = () => {
    // this condition is placed suppose a user is on Detail Error Summary and clicks on the close then
    // the user should be brought back to the listing page instead of closing the popup all together
    if (!listingPage) {
      setlistingPage((prev) => !prev);
    } else {
      handleClose();
    }
  };

  const revertBackKey = (key) => revertKeyClient(key, 'customer');

  return (
    <>
      <div className=" staff-container pool-set-up search-invoice csv-upload-erros upload-cus-errors">
        <div className="container">
          <div className="back-option calc-option" onClick={traverseCondition}>
            <span />

            <i
              className="fa fa-times"
              aria-hidden="true"
            />

          </div>
          <div className="row">
            <div className="col-md-12">
              {listingPage && (
              <>
                <div className="error-desc">
                  <h3>Error Data</h3>
                  <p>Update any missing field data in the original .csv that is required and re-upload</p>
                </div>
                <div id="table-scroll" className="staff-table table-scroll">
                  <div className="cus-header">
                    <h6>
                      Field Name
                    </h6>
                    <h6> Upload OK</h6>
                    <h6>Missing Fields</h6>
                    <h6 className="hidden"><span>hidden</span></h6>
                  </div>
                  <div className="cus-body">
                    {
                    Object.entries(uploadErrors.errorSummary).map((ele) => (
                      <div className="us-list">
                        <p>
                          {revertBackKey(ele[0])}
                        </p>
                        <p>
                          {ele[1].ok}
                          {' '}
                        </p>
                        {ele[1].missing === 0 ? (
                          <p>-</p>
                        ) : (
                          <p className="error-no">{ele[1].missing}</p>
                        )}
                        <p>
                          <img src={arrow} alt="arrow" onClick={() => handleDetailsView(ele[1].summary)} />
                        </p>
                      </div>
                    ))
                   }
                  </div>
                  <div className="view-btn">
                    <Button type="button" className="btn btn-edit" onClick={traverseCondition}>Cancel</Button>
                    <Button type="Submit" className="btn btn-save" onClick={() => confirmSelectUploadErrorFromUpload('continue')}>Upload </Button>
                  </div>
                </div>

              </>
              )}
              {!listingPage && (
                <>
                  <div className="main-titles-mob">
                    <h3>Upload Errors</h3>
                  </div>
                  {/* <div id="table-scroll" className="staff-table table-scroll" /> */}
                  {
                    summary.length > 0 && summary.map((ele) => (
                      <>
                        <div className="mob-table">
                          <Card>
                            <Card.Body>
                              <ul className="staff-list">
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Row No.</h3>
                                    <h5>{ele?.row}</h5>
                                  </div>
                                </li>
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Error</h3>
                                    <h5>{ele?.error}</h5>
                                  </div>
                                </li>
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Description</h3>
                                    <h5>{ele?.reason}</h5>
                                  </div>
                                </li>
                                {ele?.email ? (
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Email</h3>
                                      <h5>{ele?.email}</h5>
                                    </div>
                                  </li>
                                ) : null}
                              </ul>
                            </Card.Body>
                          </Card>
                        </div>
                      </>
                    ))
                  }

                  {
                    summary.length === 0 && <div className="no-summary-details">No Uploads Error Summary</div>
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
ClientErrortable.propTypes = {
  uploadErrors: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirmSelectUploadErrorFromUpload: PropTypes.func.isRequired,
};

export default ClientErrortable;
