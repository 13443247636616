/* eslint-disable max-len */
import React from 'react';
import {
  Modal, Form, Col, Row, Button, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { limitWidthHeight } from '../calculator/calculatorUtils';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';

const AddSiteAddress = ({
  showSiteAddress,
  handleSiteAddressModal,
  addressData,
  handleAddressData,
  handleAddAddress,
  selectedCountry,
  handleSiteAddressCountry,
  poolFinishArray,
  chlorinationArray,
  handleSitePoolFinish,
  handleSiteChlorination,
  handleSiteChlorinationType,
  error,
}) => {
  addressData.chlorinationType = addressData?.chlorinationType === 'Mineral Pool' ? 'Mineral Pool with Chlorinator' : addressData?.chlorinationType;
  return (

    <Modal
      size="lg"
      show={showSiteAddress}
      onHide={handleSiteAddressModal}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={handleSiteAddressModal}
          aria-hidden="true"
        />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Site Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-1" className="italic-text">
                  <Form.Label>
                    Householder Name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="householderName"
                    onChange={handleAddressData}
                    value={addressData?.householderName}
                    placeholder="Enter Householder Name"
                    className={`${
                      error === 'householdername'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-1" className="italic-text">
                  <Form.Label>
                    Site Address
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    onChange={handleAddressData}
                    value={addressData?.address}
                    placeholder="Enter Address"
                    className={`${
                      error === 'address'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="number italic-text" controlId="formBasicInput-1">
                  <Form.Label>
                    Mobile No
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  {/* <Form.Control type="number" name="mobileNo" onChange={handleAddressData} value={addressData.mobileNo} /> */}
                  <div className="number-field">
                    <div className="number-box">
                      <Dropdown id="mobile">
                        <Dropdown.Toggle variant="success" id="dropdown-basic1">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span>
                            <i className="fas fa-caret-down" />
                          </span>
                          {/* <div className="country-code">{selectedCountry}</div> */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleSiteAddressCountry('+61')} id="12">
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleSiteAddressCountry('+1')} id="122">
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <NumberFormat
                      // className="form-control mobile-num"
                      className={`form-control mobile-num ${
                        error === 'mobileno'
                          ? 'error-text'
                          : ''
                      }`}
                      format="### ### ###"
                      onChange={handleAddressData}
                      name="mobileNo"
                      value={addressData?.mobileNo}
                      placeholder="Enter Mobile Number"
                      autocomplete="off"
                    />
                  </div>

                </Form.Group>
              </div>
              {/* <div className="row"> */}

              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-10">
                  <Form.Label>
                    Pool Size
                  </Form.Label>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <NumberFormat
                      className="form-control mobile-num"
                      onChange={handleAddressData}
                      name="poolWidth"
                      value={addressData.poolWidth}
                      autocomplete="off"
                      isAllowed={limitWidthHeight}
                    />
                    <span style={{
                      padding: '0 10px',
                      fontSize: '16px',
                    }}
                    >
                      X

                    </span>
                    <NumberFormat
                      className="form-control mobile-num"
                      onChange={handleAddressData}
                      name="poolHeight"
                      value={addressData.poolHeight}
                      autocomplete="off"
                      isAllowed={limitWidthHeight}
                    />
                  </div>
                </Form.Group>
              </div>
              {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-10">
                  <Form.Label>
                    Pool Size
                  </Form.Label>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <NumberFormat
                      className="form-control mobile-num"
                      // onChange={handleChange}
                      name="poolWidth"
                      // value={clientDetails.poolWidth}
                      autocomplete="off"
                      isAllowed={limitWidthHeight}
                    />
                    <span style={{
                      padding: '0 10px',
                      fontSize: '16px',
                    }}
                    >
                      X

                    </span>
                    <NumberFormat
                      className="form-control mobile-num"
                      // onChange={handleChange}
                      name="poolHeight"
                      // value={clientDetails.poolHeight}
                      autocomplete="off"
                      isAllowed={limitWidthHeight}
                    />
                  </div>
                </Form.Group>
              </div>
            </div> */}
              {/* <div className="row"> */}
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Pool Finish</Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      value={addressData?.poolFinish}
                      name="poolFinish"
                      onChange={handleSitePoolFinish}
                    >
                      {poolFinishArray?.map((el) => <option key={el}>{el}</option>) }

                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              {/* </div> */}
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Chlorination Type</Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      value={addressData?.chlorinationType}
                      name="chlorinationType"
                      onChange={handleSiteChlorinationType}
                    >
                      {chlorinationArray?.map((el) => <option key={el}>{el}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              {/* </div> */}
              {/* <div className="row"> */}
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Chlorination</Form.Label>
                  <div className="select-box">
                    {/* <i className="fas fa-sort-down" /> */}
                    <Form.Control
                      as="select"
                      value={addressData?.selectedModel}
                      name="selectedModel"
                      onChange={handleSiteChlorination}
                    >
                      {addressData?.chlorinatorModel?.map((el) => <option value={el.name || el.brandName || el} key={el.name || el.brandName || el}>{el.name || el.brandName || el}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    className="btn btn-edit"
                    onClick={handleSiteAddressModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn btn-save"
                    onClick={handleAddAddress}
                  >
                    Save/Update
                  </Button>
                </div>
              </div>
            </div>

          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

AddSiteAddress.propTypes = {
  showSiteAddress: PropTypes.bool.isRequired,
  handleSiteAddressModal: PropTypes.func.isRequired,
  addressData: PropTypes.func.isRequired,
  handleAddressData: PropTypes.func.isRequired,
  handleAddAddress: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  handleSiteAddressCountry: PropTypes.func.isRequired,
  poolFinishArray: PropTypes.arrayOf.isRequired,
  chlorinationArray: PropTypes.arrayOf.isRequired,
  handleSitePoolFinish: PropTypes.func.isRequired,
  handleSiteChlorination: PropTypes.func.isRequired,
  handleSiteChlorinationType: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default AddSiteAddress;
