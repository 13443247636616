/* eslint-disable react/destructuring-assignment */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css';
// import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';

const TextEditor = ({ data, setData }) => {
  const modules = {
    toolbar: false,
  };

  const formats = [];

  // console.log('data', data);

  const handleProcedureContentChange = (content) => {
    setData(content);
    // console.log('content---->', content);
  };

  return (
    <div>
      <div style={{ justifyContent: 'center', width: '100%' }}>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          placeholder=""
          value={data}
          onChange={handleProcedureContentChange}
          style={{ height: 'auto', border: 'none' }}
        />
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  data: PropTypes.string.isRequired,
  setData: PropTypes.func.isRequired,
};

export default TextEditor;
