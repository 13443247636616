/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Table, Button, Accordion, Card, Form, Dropdown,
} from 'react-bootstrap';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { confirmAlert } from 'react-confirm-alert';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import search from '../../assets/home/search.png';
import { removeCodeFromNumber, showErrMsg, validateDataWithKey } from '../../functions/index';
import { emailRegex } from '../../config/keys';
import { userInstance } from '../../config/axios';
import showmore from '../../assets/home/showmoreblue.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import sort from '../../assets/home/sort.png';
import '../prduct&services/product&services.css';
import AddCompanyLocation from './addCompanyLocation';
import AddBilingContact from './contactPopup';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import { errorBorderTime, errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';
import DeletePopUp from '../DeletePopUp';

const CompanyLocation = ({ setView }) => {
  const initialLocationState = {
    locationName: '',
    firstname: '',
    lastname: '',
    mobile: '',
    landline: '',
    email: '',
    streetAddress: '',
    suburb: '',
    postcode: '',
    state: '',
    gender: 'male',
    note: '',
    location: '',
    anotherContacts: [],
    hide: false,
  };
  const initialContactData = {
    name: '', lastName: '', mobile: '', email: '', landline: '', hide: false, tempShow: false,
  };
  const [locationData, setLocationData] = useState(initialLocationState);
  const [contactData, setContactData] = useState(initialContactData);
  const [showForm, setShowForm] = useState();
  const [showContact, setShowContact] = useState();
  const [selectedId, setSelectedId] = useState('');
  const [index, setIndex] = useState(0);
  const [arrIndex, setArrIndex] = useState(0);
  const [type, setType] = useState('add');
  const [selectedCountry, setCountry] = useState('+61');
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [clAddress, setClAddress] = useState([]);
  const [conAddress, setConAddress] = useState([]);
  const [list, setList] = useState([]);
  const [clientTxt, setClientTxt] = useState('');
  const [renderSort, setRenderSort] = useState(false);
  const limit = 3;
  const [errorValue, setErrorValue] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [paraText, setParaText] = useState('');
  const [popUpText, setPopUpText] = useState('');

  // const paraText = 'Deleting the client will remove the client, but all records saved will be kept.';
  const handleAddress = (id) => {
    const changeState = clAddress.slice();
    const indexxx = changeState.indexOf(id);
    if (indexxx >= 0) {
      changeState.splice(indexxx, 1);
      setClAddress(changeState);
    } else {
      changeState.push(id);
      setClAddress(changeState);
    }
  };
  const handlecontacts = (id) => {
    const changeStateAddress = conAddress.slice();
    const indexxx = changeStateAddress.indexOf(id);
    if (indexxx >= 0) {
      changeStateAddress.splice(indexxx, 1);
      setConAddress(changeStateAddress);
    } else {
      changeStateAddress.push(id);
      setConAddress(changeStateAddress);
    }
  };
  const handleFormModal = (info) => {
    setType(info);
    if (info === 'add') {
      setLocationData(initialLocationState);
    }
    setShowForm(!showForm);
  };
  const handleEditFormModal = (data) => {
    let copyCountryCode = selectedCountry;
    if (data?.mobile) {
      const { updatedNo, countryCode } = removeCodeFromNumber(data.mobile);
      copyCountryCode = countryCode;
      data.mobile = updatedNo;
    }

    if (data?.landline) {
      const { updatedNo } = removeCodeFromNumber(data.landline);
      data.landline = updatedNo;
    }

    setCountry(copyCountryCode);
    setLocationData(data);
    setSelectedId(data._id);
    setType('edit');
    handleFormModal('edit');
  };

  const handleContact = (key, info1, info) => {
    setArrIndex(key);
    setType(info);
    setContactData(info1);
    showContactDetails();
  };

  const handleContactEdit = (key, key2, info1, info) => {
    setArrIndex(key);
    setIndex(key2);
    setType(info);
    setContactData(info1);
    showContactDetails();
  };

  const showContactDetails = () => {
    setShowContact(!showContact);
  };

  const handleLocationInformation = (e) => {
    const { name, value, id } = e.target;
    const detail = name === 'gender' ? id : value;
    setLocationData({ ...locationData, [name]: detail });
  };

  const saveContactDetails = async (e) => {
    e.preventDefault();
    const {
      name, lastName, email, mobile, landline,
    } = contactData;
    console.log('asdfasdhfjkasg', mobile);
    try {
      const { isValid, emptyField } = validateDataWithKey(
        {
          'first name': name,
          'last name': lastName,
          email,
          mobile,
        },
      );
      console.log('contact data', contactData);

      if (isValid) {
        if (emailRegex.test(String(contactData.email).toLowerCase())) {
          let isMobileValid = true;
          if (selectedCountry === '+61') {
            if (contactData.mobile.charAt(0) === '0') {
              contactData.mobile = contactData.mobile.replace('0', '');
            }
            if (contactData.landline.charAt(0) === '0') {
              contactData.landline = contactData.landline.replace('0', '');
            }
            if (contactData.mobile.charAt(0) !== '4') {
              isMobileValid = false;

              setErrorValue('email');
              toastMessage('error', 'Please start mobile number with 4');
            }
            if (contactData.mobile.trim().length !== 11) {
              setErrorValue('mobileno');
              isMobileValid = false;
              toastMessage('error', 'Enter 9 Digit Mobile Number');
            }
          }
          if (selectedCountry === '+1') {
            if (contactData.mobile.charAt(0) === '0') {
              contactData.mobile = contactData.mobile.replace('0', '');
            }
            if (contactData.landline.charAt(0) === '0') {
              contactData.landline = contactData.landline.replace('0', '');
            }

            if (contactData.mobile.trim().length !== 12) {
              setErrorValue('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              toastMessage('error', 'Enter 10 Digit Mobile Number');
            }
          }
          const arrayData = list[arrIndex];
          if (isMobileValid) {
            if (type === 'add') {
              contactData.mobile = `${selectedCountry} ${contactData.mobile}`;
              if (landline !== '') {
                contactData.landline = `${selectedCountry} ${contactData.landline}`;
              }
              arrayData.anotherContacts.push(contactData);
            } else {
              const editCont = arrayData.anotherContacts;
              editCont[index] = contactData;
              arrayData.anotherContacts = editCont;
            }
            const payload = { ...arrayData };

            const response = await userInstance().post('/location/editLocation', payload);
            setContactData(initialContactData);
            getAllLocation();
            const { msg } = response.data;
            toastMessage('success', msg);

            showContactDetails();
          }
        } else {
          setErrorValue('email');
          toastMessage('error', 'Invalid email.');
        }
      } else {
        setErrorValue(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleContactDetails = (e) => {
    const { name, value } = e.target;
    setContactData({ ...contactData, [name]: value });
  };

  function nth(n) {
    return n + ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || `${n}th`;
  }

  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = locationData.mobile.includes('+61') ? '+61' : '+1';
    console.log(textToReplace);
    const obj = {};
    if (locationData.mobile) {
      obj.mobile = `${locationData.mobile.replace(textToReplace, '')}`;
    }
    if (locationData.landline) {
      obj.landline = `${locationData.landline.replace(textToReplace, '')}`;
    }
    setLocationData({ ...locationData, ...obj });
  };

  const getAllLocation = async () => {
    try {
      setLoading(true);
      const response = await userInstance().get(`/location/getLocationList?limit=${limit}&skip=${skip}`);
      setLoading(false);
      // console.log('sdsdsd', response);
      const { location, count } = response.data;
      setList(location);
      setPageCounts(count);
    } catch (error) {
      console.log(error);
      setLoading(false);
      //   console.log(error);
    }
  };
  const pageCount = Math.ceil(pageCounts / limit);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };

  useEffect(() => {
    getAllLocation();
  }, [skip, limit]);

  const saveLocation = async (e) => {
    e.preventDefault();
    const {
      firstname, lastname, email, mobile,
    } = locationData;
    try {
      const { isValid, emptyField } = validateDataWithKey(
        {
          'first name': firstname,
          'last name': lastname,
          email,
          mobile,
        },
      );
      if (isValid) {
        let isMobileValid = true;
        let isLandlineValid = true;
        let errorMsg = '';
        // let landlineMsg = '';
        if (emailRegex.test(String(locationData.email).toLowerCase())) {
          const payload = { ...locationData };
          if (selectedCountry === '+61') {
            if (payload.mobile.charAt(0) === '0') {
              payload.mobile = payload.mobile.replace('0', '');
            }
            if (payload.landline.charAt(0) === '0') {
              payload.landline = payload.landline.replace('0', '');
            }
            if (payload.mobile.charAt(0) !== '4') {
              // console.log('payload.mobileNo.charAt(4)', payload.mobileNo.charAt(4));
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setErrorValue('mobileno');
            }
            if (payload.mobile.trim().length !== 11) {
              setErrorValue('mobileno');
              isMobileValid = false;
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }
          if (selectedCountry === '+1') {
            if (payload.mobile.charAt(0) === '0') {
              payload.mobile = payload.mobile.replace('0', '');
            }

            if (payload.mobile.charAt(0) !== '4') {
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setErrorValue('mobileno');
            }
            if (payload.mobile.trim().length !== 12) {
              setErrorValue('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }
          // if (payload.mobile !== '') {
          //   if (payload.landline !== '') {
          //     if (locationData.landline.trim().length !== 11) {
          //       isLandlineValid = false;
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('landline');
          //       }
          //     }
          //   } else {
          //     isMobileValid = true;
          //   }
          // }
          // if (payload.landline !== '') {
          //   if (payload.mobile !== '') {
          //     if (payload.mobile.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('mobile');
          //       }
          //     }
          //   } else {
          //     isLandlineValid = true;
          //   }
          // }
          // if (payload.mobile === '') {
          //   if (payload.landline !== '') {
          //     if (locationData.landline.trim().length !== 15) {
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('landline');
          //       }
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   isMobileValid = false;
          // }
          // if (payload.landline === '') {
          //   if (payload.mobile !== '') {
          //     if (payload.mobile.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('mobile');
          //       }
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   isLandlineValid = false;
          // }

          if (payload?.mobile && isMobileValid) {
            payload.mobile = `${selectedCountry} ${payload.mobile}`;
          }

          if (payload?.landline && isLandlineValid) {
            payload.landline = `${selectedCountry} ${payload.landline}`;
          }

          if (isMobileValid) {
            console.log(payload);
            let response;
            if (type === 'add') {
              response = await userInstance().post('/location/addLocation', payload);
              setLocationData(initialLocationState);
            } else {
              response = await userInstance().post('/location/editLocation', payload);
            }
            getAllLocation();
            const { msg } = response.data;
            toastMessage('success', msg);

            handleFormModal();
          } else {
            toastMessage('error', errorMsg);
          }
        } else {
          setErrorValue('email');
          toastMessage('success', 'Invalid email.');
        }
      } else {
        setErrorValue(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const deleteLocationConact = ({ id, id1, typ }) => {
    if (typ === 'contact') {
      setPopUpText('Delete Contact');
      setParaText('Do you want to continue?');
    }
    if (typ === 'location') {
      setPopUpText('Delete Location');
      setParaText('Do you want to continue?');
    }
    setDeletePopUp(true);
    setDeleteData({ id, id1, typ });

    // console.log(id, id1, typ);
    // if (typ === 'location') {
    //   console.log('delete location');
    // }
    // // if (j === 'contact') {
    // //   console.log('contact');
    // // }
    // // updateLocation(delLocation);
    // // updateContact(value1, value2),
  };
  const deleteLocation = (value) => {
    // setDelLocation(value);
    // setDeletePopUp(true);

    // confirmAlert({
    //   title: 'Delete Location',
    //   message: 'Do you want to continue?',
    //   buttons: [
    //     {
    //       label: 'Yes',
    //       onClick: () => updateLocation(value),
    //     },
    //     {
    //       label: 'No',
    //       onClick: () => ' No ',
    //     },
    //   ],
    // });
  };
  const handlePopUpChange = (val) => {
    if (val === 'Yes') {
      const { id, id1, typ } = deleteData;
      if (typ === 'location') {
        updateLocation(id);
      }
      if (typ === 'contact') {
        updateContact(id, id1);
      }

      console.log(id, id1, typ);
      // updateLocation(delLocation);
    }

    setDeletePopUp(false);
  };

  const deleteConatct = (value1, value2) => {
    confirmAlert({
      title: 'Delete Contact',
      message: 'Do you want to continue?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => updateContact(value1, value2),
        },
        {
          label: 'No',
          onClick: () => ' No ',
        },
      ],
    });
  };

  const updateLocation = async (info) => {
    try {
      const payload = {
        _id: info,
      };
      const response = await userInstance().post('/location/deleteLocation', payload);
      const { msg } = response.data;
      toastMessage('success', msg);
      getAllLocation();
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const updateContact = async (data1, data2) => {
    try {
      const old = [...list];
      const locationIndex = old[data1];
      locationIndex.anotherContacts.splice(data2, 1);
      const payload = { ...locationIndex };
      const response = await userInstance().post('/location/editLocation', payload);
      const { msg } = response.data;
      toastMessage('success', msg);
      getAllLocation();
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleVisibilityContact = async (data1, data2) => {
    console.log({ data1, data2 });
    try {
      const old = [...list];
      const locationIndex = old[data1];
      if (!locationIndex.anotherContacts[data2].hide) {
        locationIndex.anotherContacts[data2].hide = true;
      } else {
        locationIndex.anotherContacts[data2].hide = false;
      }
      const payload = { ...locationIndex };
      const response = await userInstance().post('/location/editLocation', payload);
      const { msg } = response.data;
      toastMessage('success', msg);
      getAllLocation();
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleHide = (r) => {
    const old = [...list];
    if (old[r].tempShow) {
      old[r].tempShow = false;
    } else {
      old[r].tempShow = true;
    }
    console.log(old);
    setList(old);
  };

  const searchLocation = async (value) => {
    try {
      setClientTxt(value);
      const response = await userInstance().get(`/location/searchLocation?value=${value}`);
      let listing = response.data.location;
      setList(listing);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const moveArr = (oldIndex, newIndex, order, sign) => {
    const filteredArr = list;
    if (sign === 'minus') {
      filteredArr[oldIndex - 1].order = filteredArr[oldIndex].order;
    } else {
      filteredArr[oldIndex + 1].order = filteredArr[oldIndex].order;
    }
    filteredArr[oldIndex].order = order;
    const currentServices = [...filteredArr];
    if (newIndex >= currentServices.length) {
      let k = newIndex - currentServices.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        currentServices.push(undefined);
      }
    }
    currentServices.splice(newIndex, 0, currentServices.splice(oldIndex, 1)[0]);
    setList(currentServices);
  };
  const handleBackPage = () => {
    setView('companyInfo');
  };

  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  return (

    loading ? <Loader /> : (
      <div className="client-container company-location">

        <div className="row">
          <div className="col-md-12">
            <div className="back-option">
              <span />
              <i className="fa fa-times" aria-hidden="true" onClick={handleBackPage} />

            </div>
            <div className="main-titles-mob">
              <h3>Company Locations</h3>
              <div className="client-option service-edit-icon sort-icon">
                <img src={sort} alt="sorting" onClick={() => setRenderSort(!renderSort)} />
                <i className="fa fa-plus" aria-hidden="true" onClick={() => handleFormModal('add')} />
              </div>
            </div>

            <div className="client-search-box">
              <Form inline>
                <Form.Group controlId="formBasicSearch" className=" search-box">
                  <Form.Control
                    placeholder="Search"
                    onChange={(e) => searchLocation(e.target.value)}
                  // eslint-disable-next-line react/jsx-no-bind
                    onKeyDown={onKeyDown}
                    value={clientTxt}
                  />
                  <img src={search} alt="search" />
                </Form.Group>
              </Form>
            </div>

          </div>

          {list && list.length > 0 ? (
            <>
              {list && list.map((el, k) => (
                <div className="col-md-12">
                  <Accordion className="mob-accordion new-client-section" eventKey={k.toString()}>
                    <Card>
                      <Card.Header>
                        <div className="client-heading-option first-heading add-location-field">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={k.toString()}
                            onClick={() => handleAddress(el._id)}
                          >
                            <h5>
                              <i
                                className={`fas ${
                                  clAddress.includes(el._id)
                                    ? 'far fa-chevron-circle-down'
                                    : 'far fa-chevron-circle-up'
                                }`}
                              />
                            </h5>
                            <div className="name-location">
                              <h5>
                                {el.locationName}
                              </h5>
                              {clAddress.includes(el._id) ? null : (
                                <p>{el.streetAddress}</p>
                              )}
                            </div>
                          </Accordion.Toggle>
                          <div className="client-option service-edit-icon">
                            {renderSort ? (
                              <div className={`sorting up-down ${renderSort && 'active-sort'}`}>
                                {k !== 0 ? (
                                  <img
                                    src={upsort}
                                    alt="sorting"
                                    onClick={() => moveArr(k, k - 1, el.order - 1, 'minus')}
                                  />
                                ) : null}
                                {k !== list.filter((data) => data).length - 1 ? (
                                  <img
                                    src={downsort}
                                    alt="sorting"
                                    onClick={() => moveArr(k, k + 1, el.order + 1, 'plus')}
                                  />
                                ) : null}
                              </div>
                            ) : null}
                            <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <img src={showmore} alt="show" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <div
                                  className="action"
                                >
                                  <span onClick={() => handleContact(k, initialContactData, 'add')}>
                                    Additional Contact
                                  </span>
                                </div>
                                <div className="action">
                                  {el.tempShow ? (
                                    <span onClick={() => handleHide(k)}>
                                      Hide Hidden Additional Contacts
                                    </span>
                                  ) : (
                                    <span onClick={() => handleHide(k)}>
                                      Show Hidden Additional Contacts
                                    </span>
                                  )}
                                </div>
                                <div
                                  className="action"
                                >
                                  <span> Search Hidden Additional Contacts </span>
                                </div>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey={k.toString()}>
                        <Card.Body>
                          <ul className="staff-list top-client-details">
                            <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>Street Address</h3>
                                <h5>{el.streetAddress}</h5>
                              </div>
                            </li>
                            <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>Main Contact</h3>
                                <h5>
                                  <a className="mobile" href="/hh">
                                    {el.firstname}
                                    {' '}
                                    {el.lastname}
                                  </a>
                                </h5>
                              </div>
                            </li>
                            <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>Mobile</h3>
                                <h5>
                                  {el.mobile}
                                </h5>
                              </div>
                            </li>
                            <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>Landline</h3>
                                <h5>{el.landline}</h5>
                              </div>
                            </li>
                            <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>Email</h3>
                                <h5>{el.email}</h5>
                              </div>
                            </li>
                            <li className="staff-list-item">
                              <div className="staff-box">
                                <h3>Actions</h3>
                                <div className="action-btn">
                                  <Button className="edit-btn">
                                    <img
                                      src={whiteedit}
                                      alt="edit"
                                      onClick={() => handleEditFormModal(el)}
                                    />
                                  </Button>
                                  <Button className="eye-btn">
                                    <i
                                      className=" fas fa-eye-slash"
                                    />
                                  </Button>
                                  <Button className="delete-btn" onClick={() => deleteLocation(el._id)}>
                                    <img
                                      src={whitedelete}
                                      alt="edit"
                                    />
                                  </Button>
                                </div>
                              </div>
                            </li>
                          </ul>
                          {el.anotherContacts && el.anotherContacts.filter((ele) => !ele.hide || el.tempShow).map((item, j) => (
                            <Accordion className="mob-accordion inner-item" key={j.toString()}>
                              <Card>
                                <Card.Header>
                                  <div className="client-heading-option first-heading site-with-address">
                                    <Accordion.Toggle
                                      as={Button}
                                      variant="link"
                                      eventKey={j.toString()}
                                      onClick={() => handlecontacts(item.name)}
                                    >
                                      <h5>
                                        <i
                                          className={`fas ${
                                            conAddress.includes(item.name)
                                              ? 'far fa-chevron-circle-down'
                                              : 'far fa-chevron-circle-up'
                                          }`}
                                        />
                                        {`${nth(j + 2)} Contact`}
                                      </h5>
                                      <h6>
                                        {item.name}
                                        {' '}
                                        {item.lastName}
                                      </h6>
                                    </Accordion.Toggle>
                                  </div>
                                </Card.Header>
                                <div key="1">
                                  <Accordion.Collapse eventKey={j.toString()}>
                                    <Card.Body>
                                      <div className="">
                                        <ul className="staff-list ass-contact">
                                          <li className="staff-list-item">
                                            <div className="staff-box">
                                              <h3>
                                                Mobile
                                              </h3>
                                              <h5>
                                                {item.mobile}
                                              </h5>
                                            </div>
                                          </li>
                                          <li className="staff-list-item">
                                            <div className="staff-box">
                                              <h3>Landline</h3>
                                              <h5>{item.landline}</h5>
                                            </div>
                                          </li>
                                          <li className="staff-list-item">
                                            <div className="staff-box">
                                              <h3>Actions</h3>
                                              <div className="action-btn">
                                                <Button className="edit-btn" onClick={() => handleContactEdit(k, j, item, 'edit')}>
                                                  <img
                                                    src={whiteedit}
                                                    alt="edit"
                                                  />
                                                </Button>
                                                <Button className="eye-btn">
                                                  <i
                                                    className={`${item.hide ? 'fas fa-eye-slash' : 'fa fa-eye'}`}
                                                    aria-hidden="true"
                                                    onClick={() => handleVisibilityContact(k, j)}
                                                  />
                                                </Button>
                                                <Button className="delete-btn" onClick={() => deleteConatct(k, j)}>
                                                  <img
                                                    src={whitedelete}
                                                    alt="edit"
                                                  />
                                                </Button>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </div>
                              </Card>
                            </Accordion>
                          ))}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              ))}
            </>
          ) : (
            <div className="col-md-12">
              <div className="placehoder-text">No Locations Found</div>
            </div>
          )}

          {list.length > 0 && (
          <div className="col-md-12">
            <div className="user-pagination">
              <ReactPaginate
                previousLabel="← Previous"
                nextLabel="Next →"
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName="pagination-chk"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                breakLabel="..."
                marginPagesDisplayed={-2}
                pageRangeDisplayed={0}
              />
              <div className="no-of-page">
                Page
                {' '}
                {selectedPage}
                {' '}
                of
                {' '}
                {' '}
                {pageCount}
              </div>
            </div>
          </div>
          )}

          <AddCompanyLocation
            handleFormModal={handleFormModal}
            showForm={showForm}
            selectedCountry={selectedCountry}
            locationData={locationData}
            handleLocationInformation={handleLocationInformation}
            handleCountry={handleCountry}
            saveLocation={saveLocation}
            type={type}
            error={errorValue}
          />
          <AddBilingContact
            handleContactModal={showContactDetails}
            showContactModal={showContact}
            contactData={contactData}
            saveContactDetails={saveContactDetails}
            handleContactDetails={handleContactDetails}
            error={errorValue}
            handleCountry={handleCountry}
            selectedCountry={selectedCountry}
          />
        </div>
        <AddCompanyLocation
          handleFormModal={handleFormModal}
          showForm={showForm}
          selectedCountry={selectedCountry}
          locationData={locationData}
          handleLocationInformation={handleLocationInformation}
          handleCountry={handleCountry}
          saveLocation={saveLocation}
          type={type}
          error={errorValue}
        />
        <AddBilingContact
          handleContactModal={showContactDetails}
          showContactModal={showContact}
          contactData={contactData}
          saveContactDetails={saveContactDetails}
          handleContactDetails={handleContactDetails}
          error={errorValue}
          handleCountry={handleCountry}
          selectedCountry={selectedCountry}
        />
        {deletePopUp && (
        <DeletePopUp
                  // additionalContactDetails={additionalContactDetails}
          PopUpText={popUpText}
          handlePopUpChange={handlePopUpChange}
          setDeletePopUp={setDeletePopUp}
          paraText={paraText}
        />
        )}
      </div>

    ));
};
CompanyLocation.propTypes = {
  setView: PropTypes.string.isRequired,
};
export default CompanyLocation;
