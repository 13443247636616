/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
// import chatbot from '../../assets/home/bot.png';
import chatbot from '../../assets/home/robot.jpg';
import './index.css';
import Loader from '../loader/loader';

import { ocrPath } from '../../config/keys';

let msg = '';
const ChatBot = ({ setChatBot }) => {
  const timepickerRef = useRef(null);
  const scrollRef = useRef();
  const [message, setMessage] = useState('');
  const [allMessages, setAllMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  // const getAllMessages = () => {
  //   const response = {};
  //   if (response) {
  //     setAllMessages(response?.data);
  //   }
  // };

  const sendMessage = async (e) => {
    // msg = document?.getElementById('textadded')?.innerText;
    // setMessage(msg);
    e.preventDefault();
    try {
      if (!message) return;
      // setAllMessages([...allMessages, { user: message }]);
      // setAllMessages((prev) => {
      //   prev.push({ user: message });
      //   console.log('prev', prev);
      //   return [...prev];
      // });

      setAllMessages((prevState) => ([
        ...prevState,
        { user: message },
      ]));
      // setLoading(true);
      scrollToBottom();
      var text = document.querySelectorAll('.autosize');
      text.forEach((el) => {
        el.setAttribute('rows', 1);
        el.style.height = 'auto';
        el.style.height = '35px';
      });
      setMessage('');
      const formData = new FormData();
      formData.append('input', message);
      setLoading(true);

      const response = await axios.post(`${ocrPath}/chat`, formData);
      setLoading(false);
      if (response?.data) {
        setAllMessages((prevState) => ([
          ...prevState,
          { bot: response?.data },
        ]));

        // setAllMessages((prev) => {
        //   console.log({ prev });
        //   prev.push({ bot: response?.data });
        //   return [...prev];
        // });
        scrollToBottom();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          // if (event.srcElement.className === 'duration-picker time-picker-modal') {
          setChatBot(false);
          // }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function resize(textarea) {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }

  // console.log(allMessages, 'gggggggggggggggg');
  const handleClear = () => {
    var text = document.querySelectorAll('.autosize');

    text.forEach((el) => {
      el.setAttribute('rows', 1);
      el.style.height = 'auto';
      el.style.height = '35px';
    });
    setMessage('');
    // document.getElementById('textadded').innerText = '';
  };

  const handleUP = (e) => {
    setMessage(e.target.innerText);
  };
  const handleDown = (e) => {
    setMessage(e.target.innerText);
  };
  const handleTest = (e) => {
    e.target.parentNode.dataset.replicatedValue = e.target.value;
    setMessage(e.target.value);
  };

  const handleReset = () => {
    setAllMessages([]);
  };

  const handleAuto = () => {
    var text = document.querySelectorAll('.autosize');

    text.forEach((el) => {
      el.setAttribute('rows', 1);
      resize(el);
    });

    text.forEach((el) => {
      el.addEventListener('input', () => {
        resize(el);
      });
    });
  };

  const chatText = (string, isBot) => {
    console.log({ string, isBot });
    const text = "Apologies, but our database doesn't have the answer to your question. The response below is from another AI bot, please verify to confirm its accuracy.";
    if (string?.includes(text)) {
      return (
        <>
          <h6 className="differ-para">
            <p>
              <span>{isBot}</span>
              {' '}
              <span className="error-chattext">
                {text}
              </span>
            </p>
          </h6>
          <h6>
            <p>
              {string.replace(text)}
            </p>
          </h6>
        </>
      );
    }
    return (

      <h6>
        {/* {console.log(el)} */}
        <p>
          <span>{isBot}</span>
          {' '}
          {string}
        </p>
      </h6>

    );
  };
  return (
    <div
      className="duration-picker time-picker-modal chat-bot-page"
    >
      <div>
        <div className="time-keeper-popup duration-popup" ref={wrapperRef}>
          <div className="inner-content">
            <div className="back-popup" onClick={() => setChatBot(false)}>
              <i className="las la-times" />

            </div>
            <div className="chatbot-content">
              <div className="chat-img">
                <img src={chatbot} alt="chatbot" />
                <h2>
                  Chat
                  <span>Bot</span>
                </h2>
              </div>
            </div>
            <div className="chat-bot-messages">
              {loading && (
              <Loader />
              )}
              <div className="cpbot-para main-para">
                <div>
                  <h6>
                    <p>
                      <span>CP Bot:</span>
                      Hello!&nbsp;&nbsp;Please ask me any questions you may have regarding our software, or if there&apos;s any pool issues you need help with.
                    </p>
                  </h6>
                </div>
              </div>
              {allMessages.map((el) => (
                <div className="cpbot-para">
                  {chatText((el.bot || el.user), (el.bot ? 'CP Bot:' : 'User:'))}
                  {/* <h6>
                    <p>
                      <span>{el.bot ? 'CP Bot:' : 'User:'}</span>
                      {' '}
                      {el.bot || el.user}
                    </p>
                  </h6> */}
                </div>
              ))}
              <span ref={scrollRef} />
            </div>
            <div className="chatbot-section">
              <Form>
                <div className="input-section">
                  <Form.Group className="form-group" controlId="formBasicPassword">
                    {/* <Form.Control className="textarea resize-ta" as="textarea" value={message} placeholder="Type your question here" onChange={(e) => setMessage(e.target.value)} /> */}
                    {/* <span className="form-control" id="textadded" role="textbox" data-placeholder="Type something..." onKeyUp={handleUP} onClick={handleDown} onChange={(e) => setMessage(e.target.value)} contentEditable="true" />
                    {message?.length <= 0 && (
                    <p className="type-msg">Type your question here</p>
                    )} */}
                    {/* <div className="grow-wrap">
                      <textarea name="text" id="text" className={`form-control ${message.length <= 0 ? 'clear-height' : ''}`} placeholder="Type your question here" cols="22" onChange={(e) => setMessage(e.target.value)} value={message} onInput={(e) => handleTest(e)} />
                    </div> */}

                    <textarea onInput={handleAuto} placeholder="Type your question here" className={`autosize form-control ${message.length <= 0 ? 'clear-height' : ''}`} onChange={(e) => setMessage(e.target.value)} value={message} />
                    {message?.length > 0 && (
                    <i className="las la-times" onClick={handleClear} />
                    )}
                  </Form.Group>
                  <div className="row">
                    <div className="view-btn staff-form-btn">
                      {allMessages.length > 0 ? (
                        <Button type="button" className="btn btn-edit" onClick={handleReset}> Reset </Button>
                      ) : (
                        <Button type="button" className="btn btn-edit" onClick={() => setChatBot(false)}> Cancel </Button>
                      )}
                      <Button type="button" className="btn btn-save" disabled={loading} onClick={sendMessage}> Send </Button>
                    </div>
                  </div>

                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ChatBot.propTypes = {
  setChatBot: PropTypes.func.isRequired,
};
export default ChatBot;
