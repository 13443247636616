import React, { useState, useEffect } from 'react';
import {
  Tabs, Tab, Form, Button, Dropdown,
} from 'react-bootstrap';
import Loader from '../../loader/loader';
import { userInstance, notTimeOutUserInstance } from '../../../config/axios';
import showmore from '../../../assets/home/showmoreblue.png';
import xerologo from '../../../assets/home/xero-logo.svg';
import { errorMsgTime, successMsgTime } from '../../../functions/intervalTime';
import '../xero.css';
import toastMessage from '../../../functions/toastMessage';

const AccountingXeroSoftware = () => {
  const [connected, setConnected] = useState(false);
  const [contactTime, setContactTime] = useState('1');
  const [isLoading, setIsLoading] = useState(false);

  const connect = async () => {
    const response = await userInstance().get('/xero/xeroConnect');
    const { xeroArray } = response.data;
    const redirect_url = xeroArray[0];
    // console.log('redirectUrl', redirect_url);
    window.location.assign(redirect_url);
  };

  const handleChange = (e) => {
    setContactTime(e.target.value);
  };

  const getContacts = async () => {
    try {
      setIsLoading(true);
      const response = await userInstance().post('/xero/getAllContactsFromXero', { contactTime });
      const message = response.data.msg;
      toastMessage('success', message);
      setIsLoading(false);
    } catch (error) {
      let errorMsg = '';
      setIsLoading(false);
      if (error.response) {
        errorMsg = error.response;
        let message = error.response.data.msg;
        let { status } = error.response;
        toastMessage('error', message);
        console.error(errorMsg);
      } if (error.request) {
        errorMsg = error.request;
        console.error(errorMsg);
      }
      errorMsg = error.message;
      console.error(errorMsg);
    }
  };

  const isConnected = async () => {
    const response = await userInstance().get('/xero/isConnected');
    // console.log('response', response);
    const { xeroResponse } = response.data;
    setConnected(xeroResponse);
  };
  useEffect(() => {
    isConnected();
  }, []);

  // console.log('this is the contact Time', contactTime);

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && (
      <div>
        <Form className="import-contacts-form">
          <Form.Group>
            <Form.Label>
              Import Contacts
            </Form.Label>
            <div className="select-section">
              <div className="select-box">
                <Form.Control
                  as="select"
                  value={contactTime}
                  name="contactTimming"
                  onChange={handleChange}
                >
                  <option value="1">1 Month</option>
                  <option value="2">2 Months</option>
                  <option value="3">3 Months</option>
                  <option value="9">9 Months</option>
                  <option value="12">1 Year</option>
                  <option value="24">2 Years</option>
                  <option value="60">5 Years</option>
                </Form.Control>
              </div>
              <div className="view-btn">
                <Button className="btn-save import-btn" onClick={getContacts}>Import Contacts</Button>
              </div>
            </div>
          </Form.Group>
        </Form>

        {' '}
        <div className="row">
          <div className="col-md-12">
            <div className="appointment-view xero-box status-box-xero">
              <img src={xerologo} alt="xero" />
              <div className="view-btn">
                <Button onClick={connected === false ? connect : undefined} className="btn-save">{connected ? 'Connected' : 'Connect to Xero'}</Button>
              </div>
            </div>

          </div>
        </div>

      </div>
      )}
    </>

  );
};
export default AccountingXeroSoftware;
