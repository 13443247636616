import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import showmore from '../../assets/home/showmoreblue.png';
import EditEmailTemplate from './editEmailTemplate';
import { userInstance } from '../../config/axios';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const EmailInoivceTemplate = ({ getSettingData, allSettingData }) => {
  // console.log('allSettingData=>', allSettingData);
  const { emailtemplate } = allSettingData || {};
  const [editInvoice, setEditInvoice] = useState(false);
  const initialState = {
    salutation: 'Hi',
    address: 'fname',
    textOpening: 'Your Pool visit was completed for today. Please click the invoice link to view detail.',
    textClosing: 'Thank you very much for your work',
    complimentary: '',
    senderName: '',
    type: 'emailtemplate',
    msgsubject: '',
    sendermobile: '',
    sendercompanyname: '',
    senderaddress: '',
    senderstreetaddress: '',
    senderlandingnumber: '',

  };
  const [payload, setPayload] = useState(initialState);
  const handleonChange = (e) => {
    const { value, name } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const handleSaveUpdate = async () => {
    const response = await userInstance().post('/automaticInvoice/addUpdateDayTimeTemplate', payload);
    const {
      code,
    } = response.data;
    if (code === 200) {
      toastMessage('success', 'Email Invoice Template updated successfully');

      setEditInvoice(false);
      getSettingData();
    }
  };

  const handleEditInvoice = () => {
    setEditInvoice(!editInvoice);
  };

  useEffect(() => {
    if (emailtemplate) {
      const {
        salutation,
        address,
        textOpening,
        textClosing,
        complimentary,
        senderName,
        msgsubject,
        sendermobile,
        sendercompanyname,
        senderaddress,
        senderstreetaddress,
        senderlandingnumber,
      } = emailtemplate || {};
      let copyPayload = { ...payload };
      copyPayload.salutation = salutation;
      copyPayload.address = address;
      copyPayload.textOpening = textOpening;
      copyPayload.textClosing = textClosing;
      copyPayload.complimentary = complimentary;
      copyPayload.senderName = senderName;
      copyPayload.msgsubject = msgsubject;
      copyPayload.sendermobile = sendermobile;
      copyPayload.sendercompanyname = sendercompanyname;
      copyPayload.senderaddress = senderaddress;
      copyPayload.senderstreetaddress = senderstreetaddress;
      copyPayload.senderlandingnumber = senderlandingnumber;
      setPayload(copyPayload);
    }
  }, [emailtemplate]);
  return (
    <div className="email-sms-sending search-invoice pool-set-up sms-invocie-template email-invoice-template">
      <div className="target-heading client-profile">
        <div className="">
          <h3>
            Email Invoice Template
          </h3>
        </div>
      </div>
      <div className="sending-days-section">
        <div className="top-heading">
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={handleEditInvoice}>
                      <span>Edit</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="days-description">
          <div className="message-subject">
            <h6>
              {' '}
              {emailtemplate?.msgsubject || 'Message Subject'}
              {' '}
            </h6>
          </div>
          <p>
            {emailtemplate?.address === 'fname-lname' ? '[fName lName]' : '[fName]'}
            ,
          </p>
          <p>
            {emailtemplate?.textOpening || 'Your pool visit was completed for today.Please click the invoice link to view detail.'}
            {' '}
          </p>

          <div className="todays-invoice">
            <h6>TODAY&apos;S INVOICE</h6>
            <p>
              <span>$</span>
              {' '}
              195.00 |
              {' '}
              <span className="inovocie-link">INV #159</span>
              {' '}
              Thu, 17 Feb 2022
              {' '}
            </p>
          </div>
          <div className="todays-invoice past-due">
            <h6>PAST DUE</h6>
            <p>
              <span>$</span>
              {' '}
              195.00 |
              {' '}
              <span className="inovocie-link">INV #159</span>
              {' '}
              Thu, 17 Feb 2022
              {' '}
            </p>
            <p>
              <span>$</span>
              {' '}
              195.00 |
              {' '}
              <span className="inovocie-link">INV #159</span>
              {' '}
              Thu, 17 Feb 2022
              {' '}
            </p>
            <p>
              <span>$</span>
              {' '}
              195.00 |
              {' '}
              <span className="inovocie-link">INV #159</span>
              {' '}
              Thu, 17 Feb 2022
              {' '}
            </p>
            <p>
              <span>$</span>
              {' '}
              195.00 |
              {' '}
              <span className="inovocie-link">INV #159</span>
              {' '}
              Thu, 17 Feb 2022
              {' '}
            </p>
            <h6>$2,320 TOTAL DUE</h6>
          </div>
          <div className="bottom-inovoice-text">
            <p>{emailtemplate?.textClosing || 'Thank you very much for your work'}</p>
            <p>{emailtemplate?.complimentary || 'Kind Regards,'}</p>
            <p>
              {emailtemplate?.senderName || ''}
              {' '}
            </p>
            <p>
              Mobile:
              {' '}
              {emailtemplate?.sendermobile || ''}
            </p>
          </div>
          <div className="bottom-inovoice-text sample-company-name">
            <h6>
              {' '}
              {emailtemplate?.sendercompanyname || 'Sample Company Name'}
              {' '}

            </h6>
            <p>

              {emailtemplate?.senderaddress || 'www.samplecompany.com'}
              {' '}

            </p>
            <p>
              {' '}
              {emailtemplate?.senderstreetaddress || '1-3 Avenue Street, Suburb STATE 3121'}
              {' '}

            </p>
            <p>
              Landline:
              {emailtemplate?.senderlandingnumber || '+4014 774 410'}
              {' '}

            </p>
          </div>
        </div>

      </div>
      <EditEmailTemplate show={editInvoice} handleclose={handleEditInvoice} payload={payload} handleonChange={handleonChange} handleSaveUpdate={handleSaveUpdate} />
    </div>
  );
};
EmailInoivceTemplate.propTypes = {
  getSettingData: PropTypes.func.isRequired,
  allSettingData: PropTypes.objectOf.isRequired,
};
export default EmailInoivceTemplate;
