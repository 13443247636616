import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Accordion, Card, Dropdown,
} from 'react-bootstrap';
import PropTypes, { object } from 'prop-types';
import sort from '../../assets/home/sort.png';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import showmore from '../../assets/home/showmore.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import search from '../../assets/home/search-white.png';
import PreviewSMS from './previewSMS';
import AddPaymentReminder from './addPaymentReminder';
import AddReminderGroup from './addReminderGroup';
import {
  addUdpateReminderGroupApi,
  deleteReminderGroup,
  addSubGroupApi,
  updateSubGroupApi,
  deleteSubGroupApi,
  updateOrder,
} from './index';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import DeletePopUp from '../DeletePopUp/index';
import { getAllCustomersFun, numberFromString } from '../../functions/index';
import SmsTemplatePreview from './PreviewTemplates/SmsTemplatePreview';
import sortClientList from '../../functions/listSort';
import toastMessage from '../../functions/toastMessage';

const PaymentReminder = ({
  allSettingData,
  getSettingData,
  setallSettingData,
}) => {
  const initialSubGroup = {
    reminderName: '',
    conditionSetNumber: 0,
    clientList: [],
    sendTrigger: {
      days: 1,
      weeks: 0,
    },
    clientsType: 'All Clients (except with custom send conditions)',
    repeatFrequency: {
      interval_multiplier: '4',
      interval_type: 'weekly',
      frequencyMsg: 'Repeats every 4 weeks',
    },
    repeatEndTime: 'After 2 times',
    repeatNumber: 0,
    sendSubject: '',
    textOpening: '',
    textClosing: '',
    mainGroupId: '',
  };
  const initialDatePopup = {
    interval_type: 'weekly',
    interval_multiplier: '4',
  };
  const timepickerRef = useRef(null);
  const [previewSMS, setPreviewSMS] = useState(false);
  const [openReminderGroupForm, setOpenReminderGroupForm] = useState(false);
  const [addPaymentReminder, setAddPaymentReminder] = useState(false);
  const [groupFormType, setGroupFormType] = useState('');
  const [groupFormName, setGroupFormName] = useState('');
  const [groupId, setGroupId] = useState('');
  const [groupIndex, setGroupIndex] = useState(null);
  const [subGroupFormData, setSubGroupFormData] = useState(initialSubGroup);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delData, setDelData] = useState({});
  const [delStatus, setDelStatus] = useState('');
  const [popUpText, setPopUpText] = useState('');
  const [searchedClients, setClients] = useState([]);
  const [allCustomerList, setAllCustomerList] = useState();
  const [repeatFrequency, setRepeatFrequency] = useState('');
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const [actionType, setActionType] = useState('');
  const [clientSelectBySearch, setClientSelectBySearch] = useState([]);
  const [allClinetsExceptId, setAllClinetsExceptId] = useState([]);
  const [addClient, setAddClient] = useState(false);
  const [specificClientsIdArray, setSpecificClientsIdArray] = useState([]);
  const [selectedAccordion, setSelectedAccordion] = useState([]);
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  const [renderSortCondition, setRenderSortCondition] = useState(false);
  const [triggerDaysCheckAddEdit, setTriggerDaysCheckAddEdit] = useState({
    afterCheck: 0,
    beforeAfterCheck: [],
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeArrcount, setActiveArrcount] = useState(0);

  const paraText = 'Are you sure to do this?';

  // Get Customer list Start->>>>>>>>>>>>>>
  const getAllCustomers = async (data) => {
    try {
      const response = await getAllCustomersFun();
      let { customers } = response;
      customers = sortClientList(customers);
      // customers.sort((a, b) => a.firstname.localeCompare(b.firstname));
      if (data) {
        const filteredCustomer = customers.filter((el) => data.every((elem) => elem !== el._id));
        setClients(filteredCustomer);
        setAllCustomerList(filteredCustomer);
      } else {
        setClients(customers);
        setAllCustomerList(customers);
      }
      let oldIds = [];
      data.forEach((item, index) => {
        oldIds.push(item._id);
      });
      oldIds.join(',');
      setSpecificClientsIdArray(oldIds);
    } catch (error) {
      console.log(error);
    }
  };

  // Get Customer list End->>>>>>>>>>>>>>

  // verifying days while add/edit set Start------->>>>>
  const verifyDays = (durationTotalDaysSelect) => {
    if (actionType === 'add') {
      if (durationTotalDaysSelect > triggerDaysCheckAddEdit.afterCheck) {
        return true;
      }
      toastMessage('error', `Send Trigger should be greater than ${triggerDaysCheckAddEdit.afterCheck} Days.`);

      return false;
    }
    // firstDatevalue execute in all condtion
    const firstDateValue = triggerDaysCheckAddEdit?.beforeAfterCheck[0]?.totalDaysLastTrigger;
    // Second Date Value will execute only when edit the middle index reminder constion
    const secondDateValue = triggerDaysCheckAddEdit?.beforeAfterCheck[1]?.totalDaysLastTrigger;
    const beforeIndexCheck = triggerDaysCheckAddEdit.findNextPrevIndex.beforeIndex;
    const afterIndexcheck = triggerDaysCheckAddEdit.findNextPrevIndex.afterIndex;
    if (beforeIndexCheck >= 0 && afterIndexcheck >= 0) {
      if (
        durationTotalDaysSelect > firstDateValue
        && durationTotalDaysSelect < secondDateValue
      ) {
        return true;
      }
      toastMessage('error', `Send Trigger  should be in between ${firstDateValue} and ${secondDateValue} Days.`);

      return false;
    }
    if (beforeIndexCheck >= 0) {
      if (durationTotalDaysSelect > firstDateValue) {
        return true;
      }
      toastMessage('error', `Send Trigger  should be greater than ${firstDateValue} Days.`);

      return false;
    }
    if (afterIndexcheck >= 0) {
      if (durationTotalDaysSelect < firstDateValue) {
        return true;
      }
      toastMessage('error', `Send Trigger  should be less than  ${firstDateValue} Days.`);
    }
    return false;
  };

  // verifying days while add/edit set Start------->>>>>

  // Open & Close the main Group Form
  const openReminderGroupFormFun = (e, formType, _id, prevGroupName, index) => {
    e.preventDefault();
    setGroupFormType(formType);
    if (formType === 'Edit') {
      setGroupId(_id);
      setGroupFormName(prevGroupName);
      setGroupIndex(index);
    } else {
      setGroupFormName('');
    }
    setOpenReminderGroupForm(!openReminderGroupForm);
  };
  // check totals days selected in a set
  const triggerDaysCheck = (data, index) => {
    let lastConditionSendTrigger = data[index]?.sendTrigger;
    const checkEmpty = Object.keys(lastConditionSendTrigger).length === 0;
    if (checkEmpty) {
      lastConditionSendTrigger = {
        days: 1,
        weeks: 0,
      };
    }
    const lastConditionRepeatFrequency = data[index].repeatFrequency;
    const lastConditionRepeatEndTime = data[index].repeatEndTime;
    // Manupluate above variablles
    const monthsToDays = lastConditionRepeatEndTime.indexOf('month') !== -1;
    const repeatFreqMain = lastConditionRepeatFrequency.interval_type === 'weekly'
      ? parseFloat(lastConditionRepeatFrequency.interval_multiplier) * 7
      : lastConditionRepeatFrequency.interval_type === 'monthly'
        ? parseFloat(lastConditionRepeatFrequency.interval_multiplier) * 30
        : parseFloat(lastConditionRepeatFrequency.interval_multiplier);
    const repeatTime = lastConditionRepeatEndTime === 'Ongoing'
      ? 50
      : (numberFromString(lastConditionRepeatEndTime)
            - (monthsToDays ? 0 : 1))
          * (monthsToDays ? 30 : 1);
    const triggerDaysTotal = lastConditionSendTrigger.weeks > 0
      ? lastConditionSendTrigger.days + lastConditionSendTrigger.weeks * 7
      : lastConditionSendTrigger.days;
    const totalDaysLastTrigger = triggerDaysTotal
      + (monthsToDays ? 1 : parseFloat(repeatFreqMain))
        * (repeatTime === 0 ? 50 : parseFloat(repeatTime));
    // setLastTriggerCheck(lastConditionSendTrigger);
    return {
      days: (totalDaysLastTrigger % 7) + 1,
      weeks: Math.floor(totalDaysLastTrigger / 7),
      totalDaysLastTrigger,
    };
  };

  const checkIndex = (index, data) => {
    if (index === 0 && index === data.length - 1) {
      return { afterIndex: 0 };
    }
    if (index === 0) {
      return { afterIndex: index + 1 };
    }
    if (index === data.length - 1) {
      return { beforeIndex: index - 1 };
    }
    return { beforeIndex: index - 1, afterIndex: index + 1 };
  };

  // Open & Close the SubGroupForm
  const handleAddEditPaymentRemider = (
    type,
    index,
    subCondtionLength,
    mainGroupId,
    conditionSetArr,
    data,
  ) => {
    setAddPaymentReminder(true);
    const clientIds = allCustomerList.map((elem) => elem._id);
    setAllClinetsExceptId(clientIds);
    let afterDateObj = {
      days: 1,
      weeks: 0,
    };
    const arrayLength = subCondtionLength - 1;
    if (type === 'add') {
      const setNameNumber = `Payment Reminders - (Sending Set No. ${
        subCondtionLength + 1
      })`;
      setActionType('add');
      if (arrayLength >= 0) {
        const { totalDaysLastTrigger, days, weeks } = triggerDaysCheck(
          conditionSetArr,
          subCondtionLength - 1,
        );
        setTriggerDaysCheckAddEdit({ afterCheck: totalDaysLastTrigger });
        // created this object for check the conflict of days
        afterDateObj.days = days;
        afterDateObj.weeks = weeks;
      }
      setSubGroupFormData({
        ...subGroupFormData,
        reminderName: setNameNumber,
        conditionSetNumber: subCondtionLength + 1,
        mainGroupId,
        clientList: clientIds,
        sendTrigger: afterDateObj,
      });
      setAddClient(false);
      setClientSelectBySearch([]);
    } else {
      const findNextPrevIndex = checkIndex(index, conditionSetArr);
      const datavalue = Object.keys(findNextPrevIndex).map((key, i) => triggerDaysCheck(conditionSetArr, findNextPrevIndex[key]));
      console.log('data-------', data);
      setTriggerDaysCheckAddEdit({
        beforeAfterCheck: datavalue,
        findNextPrevIndex,
      });
      if (data.clientsType === 'Specific Clients') {
        const filteredCustomer = allSettingData?.specificClientsArray?.filter(
          (el) => data.clientList.find((elem) => elem === el._id),
        );
        setClientSelectBySearch(filteredCustomer);
        setAddClient(true);
      }
      setActiveIndex(index);
      setActionType('edit');
      setActiveArrcount(conditionSetArr.length);
      setSubGroupFormData(data);
      setDatePopupData(data.repeatFrequency);
    }
  };
  const closeAddForm = () => {
    setSubGroupFormData(initialSubGroup);
    setAddPaymentReminder(false);
    if (actionType === 'edit') {
      let oldIds = [];
      allSettingData.specificClientsArray?.forEach((item, index) => {
        oldIds.push(item._id);
      });
      oldIds.join(',');
      setSpecificClientsIdArray(oldIds);
    }
    setActionType('');
  };
  // Main group functions Start->>>>>>>>>>>>>>
  // handle subGroupForm
  const handleSubGroupFormChange = (e, index, mainFroupIndex) => {
    e.preventDefault();
    const { value, name } = e.target || {};
    let copySubGroupFormData = { ...subGroupFormData };
    copySubGroupFormData[name] = value;
    setSubGroupFormData(copySubGroupFormData);
  };
  // add groupForm API Fun.
  const addUpdateReminderGroup = async (formType, index) => {
    if (groupFormName) {
      const res = await addUdpateReminderGroupApi(
        groupFormName,
        formType,
        groupId,
      );
      const { msg, code } = res;
      if (code === 200) {
        if (formType === 'Edit') {
          let updateGroup = allSettingData?.paymentReminderGroup[index];
          updateGroup.name = groupFormName;
        } else {
          let payload = {
            conditionSets: [],
            name: groupFormName,
          };
          let groupArray = allSettingData?.paymentReminderGroup;
          groupArray.push(payload);
        }
        getSettingData();
        toastMessage('success', msg);

        setOpenReminderGroupForm(false);
      } else {
        toastMessage('success', `Unable to ${formType} group.`);
      }
    } else {
      toastMessage('error', 'Please enter group Name.');
    }
  };
  // Main group functions End->>>>>>>>>>>>>>

  // Delete function Start--->>>>>>>>>>
  const handlePopUpChange = (val) => {
    const {
      id: group_Id,
      groupIndexValue,
      mainGroupId,
      mainGroupIndex,
      subCondId,
      clientsType,
      clientsArray,
      subGroupIndex,
    } = delData;
    if (val === 'Yes') {
      switch (delStatus) {
        case 'Delete Group':
          handleDeleteGroup(group_Id, groupIndexValue);
          break;
        default:
          handleDeleteSubGroupCondition(
            mainGroupId,
            mainGroupIndex,
            subCondId,
            clientsType,
            clientsArray,
            subGroupIndex,
          );
          break;
      }
    }
    setDeletePopUp(false);
  };
  const submitRemoveGroup = (id, groupIndexValue) => {
    setDelStatus('Delete Group');
    setPopUpText('Delete Group?');
    setDeletePopUp(true);
    setDelData({
      id,
      groupIndexValue,
    });
  };
  const submitRemoveSubGroup = (
    mainGroupId,
    mainGroupIndex,
    subCondId,
    clientsType,
    clientsArray,
    subGroupIndex,
  ) => {
    setDelStatus('Delete Condition');
    setPopUpText('Delete Condition?');
    setDeletePopUp(true);
    setDelData({
      mainGroupId,
      mainGroupIndex,
      subCondId,
      clientsType,
      clientsArray,
      subGroupIndex,
    });
  };
  const handleDeleteGroup = async (group_Id, groupIndexValue) => {
    const checkGroupData = allSettingData?.paymentReminderGroup[groupIndexValue];
    if (checkGroupData.conditionSets.length === 0) {
      const deleteRes = await deleteReminderGroup(group_Id);
      if (deleteRes.code === 200) {
        toastMessage('success', deleteRes.msg);

        getSettingData();
      } else {
        toastMessage('error', 'unable to delete group.');
      }
    } else {
      toastMessage('error', "Delete group's condition first.");
    }
  };
  const handleDeleteSubGroupCondition = async (
    mainGroupId,
    mainGroupIndex,
    subCondId,
    clientsType,
    clientsArray,
    subGroupIndex,
  ) => {
    // This map is for Changing the reminder number and name from database afetr delete the selected group
    const resultFrom = allSettingData.paymentReminderGroup[
      mainGroupIndex
    ].conditionSets.slice(subGroupIndex + 1);
    const {
      sendTrigger,
      repeatFrequency: repeatFreq,
      repeatEndTime,
    } = allSettingData.paymentReminderGroup[mainGroupIndex].conditionSets[
      subGroupIndex
    ];
    const arrData = [
      {
        sendTrigger,
        repeatFrequency: repeatFreq,
        repeatEndTime,
      },
    ];
    const totalDaysReminder = allSettingData?.paymentReminderGroupTotalDays;
    const { totalDaysLastTrigger: totalDaysOfTrigger } = triggerDaysCheck(
      arrData,
      0,
    );
    let paymentReminderGroupTotalDays = totalDaysReminder - totalDaysOfTrigger;
    if (paymentReminderGroupTotalDays < 0) {
      paymentReminderGroupTotalDays = 0;
    }
    const finalResultToSend = resultFrom.map(
      ({ _id, conditionSetNumber, reminderName }) => ({
        _id,
        conditionSetNumber: conditionSetNumber - 1,
        reminderName: `Payment Reminders - (Sending Set No. ${
          conditionSetNumber - 1
        })`,
      }),
    );
    const payload = {
      mainGroupId,
      mainGroupIndex,
      subCondId,
      clientsType,
      clientsArray,
      finalResultToSend,
      paymentReminderGroupTotalDays,
    };
    const deleteSubGroup = await deleteSubGroupApi(payload);
    const { code } = deleteSubGroup;
    if (code === 200) {
      toastMessage('success', 'Condition deleted successfully');

      getSettingData();
    } else {
      toastMessage('error', 'Condition cannot deleted.');
    }
  };

  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };

  const handleChangeDate = (type, value) => {
    setDatePopupData({ ...datePopupData, [type]: value });
  };

  const updateDateData = () => {
    let { interval_type, interval_multiplier } = datePopupData;
    const originalIntervalType = interval_type;
    let frequencyMsg = '';
    if (parseInt(interval_multiplier, 10) > 1) {
      switch (interval_type) {
        case 'monthly':
          interval_type = 'months';
          break;
        case 'weekly':
          interval_type = 'weeks';
          break;
        case 'daily':
          interval_type = 'days';
          break;

        default:
          break;
      }
    }
    frequencyMsg = parseInt(interval_multiplier, 10) > 1
      ? `Repeats every ${interval_multiplier} ${interval_type}`
      : interval_type;
    let copySubGroupFormData = subGroupFormData;
    copySubGroupFormData.repeatFrequency.interval_multiplier = interval_multiplier;
    copySubGroupFormData.repeatFrequency.interval_type = originalIntervalType;
    copySubGroupFormData.repeatFrequency.frequencyMsg = frequencyMsg;
    setSubGroupFormData(copySubGroupFormData);
  };
  const handleSaveDate = () => {
    updateDateData();
    handleFrequency();
  };

  const saveUpdateSubGroup = async () => {
    const totalDaysReminder = allSettingData?.paymentReminderGroupTotalDays || 0;
    const {
      sendSubject,
      textClosing,
      textOpening,
      clientList,
      sendTrigger,
      repeatFrequency: repeatFreq,
      repeatEndTime,
      conditionSetNumber,
      totalDays: preViousTotalDays,
    } = subGroupFormData;
    let isCheck = false;
    // console.log('test data =========>', { subGroupFormData });

    // get the days conflict or not
    // Start-------->>

    // manuplate array for triggerDays function.
    const arrData = [
      {
        sendTrigger,
        repeatFrequency: repeatFreq,
        repeatEndTime,
      },
    ];
    const totalDaysStartFrom = sendTrigger.days + sendTrigger.weeks * 7;
    const { totalDaysLastTrigger: totalDaysOfTrigger } = triggerDaysCheck(
      arrData,
      0,
    );
    let firstIndexData = false;
    if (actionType === 'add') {
      if (totalDaysStartFrom > totalDaysReminder) {
        isCheck = true;
        firstIndexData = true;
      } else {
        isCheck = false;
        toastMessage('error', `Send Trigger should be greater than ${totalDaysReminder} Days.`);
      }
    } else {
      isCheck = verifyDays(totalDaysStartFrom);
    }
    let paymentReminderGroupTotalDays = 0;
    if (actionType === 'add') {
      paymentReminderGroupTotalDays = totalDaysReminder + totalDaysOfTrigger;
    } else {
      let newDaysToGroups = 0;
      if (totalDaysOfTrigger > preViousTotalDays) {
        newDaysToGroups = preViousTotalDays + (totalDaysStartFrom + totalDaysOfTrigger);
      } else if (totalDaysOfTrigger < preViousTotalDays) {
        newDaysToGroups = preViousTotalDays - (totalDaysStartFrom + totalDaysOfTrigger);
      }
      paymentReminderGroupTotalDays = totalDaysReminder + newDaysToGroups;
    }
    const numberValue = parseFloat(repeatEndTime.replace(/\D/g, '') || 50);
    subGroupFormData.repeatNumber = numberValue;
    subGroupFormData.totalDays = totalDaysOfTrigger;
    // End----->>>>>
    if (isCheck) {
      if (clientList.length > 0) {
        if (sendSubject) {
          if (textOpening) {
            if (textClosing) {
              if (actionType === 'add') {
                const res = await addSubGroupApi(
                  subGroupFormData,
                  paymentReminderGroupTotalDays,
                );
                const { code } = res;
                if (code === 200) {
                  toastMessage('success', 'Condition group added successfully');

                  getSettingData();
                  setSubGroupFormData(initialSubGroup);
                  setAddPaymentReminder(false);
                } else {
                  toastMessage('error', 'Unable to add Condition.');
                }
              } else {
                const res = await updateSubGroupApi(
                  subGroupFormData,
                  specificClientsIdArray,
                  paymentReminderGroupTotalDays,
                );
                const { code } = res;
                if (code === 200) {
                  toastMessage('success', 'Condition group added successfully');

                  getSettingData();
                  setSubGroupFormData(initialSubGroup);
                  setAddPaymentReminder(false);
                } else {
                  toastMessage('error', 'Unable to add Condition.');
                }
              }
              isCheck = false;
            } else {
              toastMessage('error', 'Please, enter the text closing.');
            }
          } else {
            toastMessage('error', 'Please, enter the text opening.');
          }
        } else {
          toastMessage('error', 'Please, enter the send subject.');
        }
      } else {
        toastMessage('error', 'Please, add client.');
      }
    }
  };
  const getDaysData = (ele) => {
    let toShowData = '';
    if (ele.sendTrigger?.weeks > 0 && ele.sendTrigger?.days > 0) {
      toShowData = `${ele?.sendTrigger?.days} Days ${ele?.sendTrigger?.weeks} Weeks `;
    } else if (ele?.sendTrigger?.weeks > 0) {
      toShowData = `${ele?.sendTrigger?.weeks} Weeks`;
    } else if (ele?.sendTrigger?.days > 0) {
      toShowData = `${ele?.sendTrigger?.days} Days`;
    }
    return toShowData;
  };
  // Handling accordion open & close start->>>>>>>>>
  const handleUpAccordion = (e) => {
    if (selectedAccordion && selectedAccordion.includes(e)) {
      const data = selectedAccordion.filter((el) => el !== e);
      return setSelectedAccordion(data);
    }
    return setSelectedAccordion([...selectedAccordion, e]);
  };
  const handleNestedAccordion = (e) => {
    if (selectedNestedAccordion && selectedNestedAccordion.includes(e)) {
      const data = selectedNestedAccordion.filter((el) => el !== e);
      return setSelectedNestedAccordion(data);
    }
    return setSelectedNestedAccordion([...selectedNestedAccordion, e]);
  };
  // Handling accordion open & close End->>>>>>>>>

  // Sorting functions Start->>>>>>>>>>>
  const moveArr = (oldIndex, newIndex, entryType, order, sign) => {
    const filteredArr = allSettingData?.paymentReminderGroup;
    const currentServices = [...filteredArr];
    if (newIndex >= currentServices.length) {
      let k = newIndex - currentServices.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        currentServices.push(undefined);
      }
    }
    currentServices.splice(newIndex, 0, currentServices.splice(oldIndex, 1)[0]);
    setallSettingData({
      ...allSettingData,
      paymentReminderGroup: currentServices,
    });
  };

  const moveArrSubcategory = (categoryId, oldIndex, newIndex) => {
    const arrCopy = [...allSettingData?.paymentReminderGroup];
    let index = null;
    const filteredArr = arrCopy.filter((el, i) => {
      if (el._id === categoryId) {
        index = i;
        return true;
      }
      return false;
    });
    if (filteredArr.length) {
      const subcategories = filteredArr[0].conditionSets;
      if (newIndex >= subcategories.length) {
        let k = newIndex - subcategories.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
          subcategories.push(undefined);
        }
      }
      subcategories.splice(newIndex, 0, subcategories.splice(oldIndex, 1)[0]);
      arrCopy[index].conditionSets = subcategories;
      setallSettingData({ ...allSettingData, paymentReminderGroup: arrCopy });
    }
  };

  const updateSorting = async (e) => {
    e.preventDefault();
    try {
      const response = await updateOrder(allSettingData?.paymentReminderGroup);
      const { msg, code } = response;
      if (code === 200) {
        toastMessage('success', msg);
      } else {
        toastMessage('error', msg);
      }
    } catch (error) {
      console.log(error);
    }
    setRenderSortCondition(false);
  };
  // Sorting functions End->>>>>>>>>>>
  useEffect(() => {
    getAllCustomers(allSettingData?.specificClientsArray);
  }, [allSettingData?.specificClientsArray]);

  // Preview functions start------>>>>>
  const [showTemplate, setShowTemplate] = useState({
    sms: false,
    email: false,
  });
  const handlePreviewSelect = (value) => () => {
    const copyShowTemplate = showTemplate;
    if (value === 'sms') {
      copyShowTemplate.sms = true;
      copyShowTemplate.email = false;
    } else {
      copyShowTemplate.email = true;
      copyShowTemplate.sms = false;
    }
    setShowTemplate(copyShowTemplate);
    setPreviewSMS(false);
  };
  const [templatePreviewData, setTemplatePreviewData] = useState(null);
  const handlePreviewSMS = (data) => () => {
    setPreviewSMS(!previewSMS);
    setTemplatePreviewData(data);
  };
  const handleClosePreviewTemplate = () => {
    setShowTemplate({ sms: false, email: false });
  };
  // Preview functions end---------->>>
  return (
    <div className="payment-reminder-page">
      <div className="product-service-container">
        <div className="table-data">
          <div className="service-mbl">
            <Accordion defaultActiveKey="0">
              <Card>
                <div className="service-container">
                  <div className="service-title title-service">
                    <h4>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Payment Reminders
                      </Accordion.Toggle>
                    </h4>
                    <div
                      className={`services-header ${
                        renderSortCondition && 'active-sort'
                      }`}
                    >
                      {allSettingData
                        && allSettingData?.paymentReminderGroup.length > 0 && (
                          <>
                            <div
                              className="sorting"
                              onClick={() => setRenderSortCondition(!renderSortCondition)}
                            >
                              <img src={sort} alt="sorting" />
                            </div>
                          </>
                      )}
                      <span>
                        <i
                          onClick={(e) => openReminderGroupFormFun(e, 'Add')}
                          className="fa fa-plus"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <Accordion.Collapse eventKey="0">
                  <div className="mob-table">
                    {allSettingData
                      && allSettingData?.paymentReminderGroup.length > 0
                      && allSettingData?.paymentReminderGroup.map((el, index) => (
                        <>
                          <Accordion className="mob-accordion outer-accordion">
                            <Card.Header>
                              <div className="heading-accordion">
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={
                                    !el?.conditionSets?.length
                                      ? null
                                      : index.toString()
                                  }
                                  onClick={() => handleUpAccordion(el._id)}
                                >
                                  <h5>
                                    <i
                                      className={
                                        selectedAccordion?.includes(el?._id)
                                          ? 'fas far fa-chevron-circle-down'
                                          : 'fas far fa-chevron-circle-up'
                                      }
                                    />
                                    {el?.name}
                                  </h5>
                                </Accordion.Toggle>
                                <div
                                  className={
                                    renderSortCondition
                                      ? 'service-edit-icon add-sort'
                                      : 'service-edit-icon'
                                  }
                                >
                                  {renderSortCondition ? (
                                    <div
                                      className={
                                        renderSortCondition
                                          ? 'sorting up-down active-sort'
                                          : 'sorting up-down '
                                      }
                                    >
                                      {index !== 0 ? (
                                        <img
                                          src={upsort}
                                          alt="sorting"
                                          onClick={() => moveArr(
                                            index,
                                            index - 1,
                                            'product',
                                            el.order - 1,
                                            'minus',
                                          )}
                                        />
                                      ) : null}
                                      {index
                                      !== allSettingData?.paymentReminderGroup
                                        .length
                                        - 1 ? (
                                          <img
                                            src={downsort}
                                            alt="sorting"
                                            onClick={() => moveArr(
                                              index,
                                              index + 1,
                                              'product',
                                              el.order + 1,
                                              'plus',
                                            )}
                                          />
                                        ) : null}
                                    </div>
                                  ) : null}
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      variant="success"
                                      id="dropdown-basic"
                                    >
                                      <img src={showmore} alt="show" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <div
                                        className="action"
                                        onClick={() => handleAddEditPaymentRemider(
                                          'add',
                                          index,
                                          el.conditionSets.length,
                                          el._id,
                                          el.conditionSets,
                                        )}
                                      >
                                        <img src={greyplus} alt="edit" />
                                        Add
                                      </div>
                                      <div
                                        className="action"
                                        onClick={(e) => openReminderGroupFormFun(
                                          e,
                                          'Edit',
                                          el._id,
                                          el.name,
                                          index,
                                        )}
                                      >
                                        <img src={greyedit} alt="edit" />
                                        Edit
                                      </div>
                                      <div
                                        className="action"
                                        onClick={() => submitRemoveGroup(el._id, index)}
                                      >
                                        <img src={greydelete} alt="delete" />
                                        Delete
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </Card.Header>

                            <Accordion.Collapse eventKey={index.toString()}>
                              <Card.Body>
                                <div className="inner-accordion">
                                  {el.conditionSets
                                    && el.conditionSets.length > 0
                                    && el.conditionSets.map((ele, i) => (
                                      <>
                                        <Accordion defaultActiveKey="0">
                                          <Accordion>
                                            <Card.Header className="sub-category">
                                              <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                eventKey="0"
                                                onClick={() => handleNestedAccordion(i)}
                                              >
                                                <div className="product-service-list">
                                                  <i
                                                    className={
                                                      selectedNestedAccordion.includes(
                                                        i,
                                                      )
                                                        ? 'fas far fa-chevron-circle-down'
                                                        : 'fas far fa-chevron-circle-up'
                                                    }
                                                  />
                                                  <span>
                                                    {ele?.reminderName.substring(
                                                      0,
                                                      19,
                                                    )}
                                                    <span className="span-measure inner-measure">
                                                      {ele?.reminderName.substring(
                                                        19,
                                                        ele.reminderName.length,
                                                      )}
                                                    </span>
                                                  </span>
                                                </div>
                                              </Accordion.Toggle>
                                              <span className="time-category">
                                                {renderSortCondition ? (
                                                  <div className="sorting up-down">
                                                    {i !== 0 ? (
                                                      <img
                                                        src={upsort}
                                                        alt="sorting"
                                                        onClick={() => moveArrSubcategory(
                                                          el._id,
                                                          i,
                                                          i - 1,
                                                        )}
                                                      />
                                                    ) : null}
                                                    {i
                                                    !== el.conditionSets.length
                                                      - 1 ? (
                                                        <img
                                                          src={downsort}
                                                          alt="sorting"
                                                          onClick={() => moveArrSubcategory(
                                                            el._id,
                                                            i,
                                                            i + 1,
                                                          )}
                                                        />
                                                      ) : null}
                                                  </div>
                                                ) : null}
                                              </span>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                              <ul className="grid-list">
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Client send List</h3>
                                                    <div className="grid-content">
                                                      {ele.clientsType
                                                      === 'Specific Clients'
                                                        ? 'Specific Clients'
                                                        : 'All Clients'}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Send Trigger</h3>
                                                    <div className="grid-content">
                                                      {ele && getDaysData(ele)}
                                                      {' '}
                                                      from invoice Date
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Repeat Frequency</h3>
                                                    <div className="grid-content">
                                                      {ele?.repeatFrequency
                                                        .frequencyMsg || ''}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Repeat End Time</h3>
                                                    <div className="grid-content">
                                                      {ele?.repeatEndTime || ''}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Send Subject</h3>
                                                    <div className="grid-content">
                                                      {ele?.sendSubject || ''}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Text Opening</h3>
                                                    <div className="grid-content">
                                                      {ele?.textOpening || ''}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Text Closing</h3>
                                                    <div className="grid-content">
                                                      {ele?.textClosing || ''}
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Actions</h3>
                                                    <div className="action-btn">
                                                      <Button
                                                        className="edit-btn"
                                                        onClick={() => handleAddEditPaymentRemider(
                                                          'edit',
                                                          i,
                                                          0,
                                                          el._id,
                                                          el.conditionSets,
                                                          ele,
                                                        )}
                                                      >
                                                        <img
                                                          src={whiteedit}
                                                          alt="edit"
                                                        />
                                                      </Button>
                                                      <Button
                                                        className="edit-btn search-icon"
                                                        onClick={handlePreviewSMS(
                                                          ele,
                                                        )}
                                                      >
                                                        <img
                                                          src={search}
                                                          alt="edit"
                                                        />
                                                      </Button>
                                                      <Button
                                                        className="delete-btn"
                                                        onClick={() => submitRemoveSubGroup(
                                                          ele._id,
                                                          index,
                                                          ele._id,
                                                          ele.clientsType,
                                                          ele.clientList,
                                                          i,
                                                        )}
                                                      >
                                                        <img
                                                          src={whitedelete}
                                                          alt="edit"
                                                        />
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </Accordion.Collapse>
                                          </Accordion>
                                        </Accordion>
                                      </>
                                    ))}
                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Accordion>
                        </>
                      ))}
                  </div>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
          {renderSortCondition ? (
            <div className="view-btn">
              <Button
                type="submit"
                className="btn btn-save"
                onClick={updateSorting}
              >
                Save/Update
              </Button>
            </div>
          ) : null}
        </div>
      </div>

      <AddPaymentReminder
        show={addPaymentReminder}
        subGroupFormData={subGroupFormData}
        setSubGroupFormData={setSubGroupFormData}
        handleclose={closeAddForm}
        handleSubGroupFormChange={handleSubGroupFormChange}
        searchedClients={searchedClients}
        setClients={setClients}
        allCustomerList={allCustomerList}
        handleFrequency={handleFrequency}
        setRepeatFrequency={setRepeatFrequency}
        repeatFrequency={repeatFrequency}
        datePopupData={datePopupData}
        handleChangeDate={handleChangeDate}
        timepickerRef={timepickerRef}
        handleSaveDate={handleSaveDate}
        saveUpdateSubGroup={saveUpdateSubGroup}
        clientSelectBySearch={clientSelectBySearch}
        setClientSelectBySearch={setClientSelectBySearch}
        allClinetsExceptId={allClinetsExceptId}
        addClient={addClient}
        setAddClient={setAddClient}
        specificClientsIdArray={specificClientsIdArray}
        setSpecificClientsIdArray={setSpecificClientsIdArray}
        actionType={actionType}
        triggerDaysCheckAddEdit={triggerDaysCheckAddEdit}
      />
      <AddReminderGroup
        show={openReminderGroupForm}
        handleClose={openReminderGroupFormFun}
        addUpdateReminderGroup={addUpdateReminderGroup}
        groupFormType={groupFormType}
        groupFormName={groupFormName}
        setGroupFormName={setGroupFormName}
        groupIndex={groupIndex}
      />
      {previewSMS && (
        <PreviewSMS
          setPreviewSMS={setPreviewSMS}
          handlePreviewSelect={handlePreviewSelect}
        />
      )}
      {deletePopUp && (
        <DeletePopUp
          handlePopUpChange={handlePopUpChange}
          paraText={paraText}
          PopUpText={popUpText}
        />
      )}
      <SmsTemplatePreview
        show={showTemplate.sms || showTemplate.email}
        handleClose={handleClosePreviewTemplate}
        smstemplate={templatePreviewData}
        templateName={showTemplate.sms ? 'Sms' : 'Email'}
      />
    </div>
  );
};
PaymentReminder.propTypes = {
  allSettingData: PropTypes.arrayOf.isRequired,
  getSettingData: PropTypes.func.isRequired,
  setallSettingData: PropTypes.func.isRequired,
};
export default PaymentReminder;
