import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Adminwrapper from '../admin/adminwrapper/wrapper';
import showmore from '../../assets/home/showmoreblue.png';
import target from '../../assets/home/icon/target.png';
import InvoiceDetail from './invoicedetail';
import { userInstance } from '../../config/axios';
import './invoice.css';
import arrow from '../../assets/home/arrowdown.png';

const PaymentList = ({
  handleInvoice, allInvoices, handlePageClick, pageCount, selectedPage,
}) => (
  <div className="pool-setup">
    <div className="target-heading">
      <h6>Receive Payments</h6>
    </div>

    <div className="invoices-table pool-chemistry-box receive-payments">
      {allInvoices && allInvoices.length > 0 ? (
        <>
          { allInvoices && allInvoices.map((el) => (
            <div className="table-section mobile-comp" onClick={() => handleInvoice(el.invoice._id)}>
              <div className="invoices-table-left">
                <div className="invoices-head">
                  <h6>{moment(el.createdAt).format('DD MMM')}</h6>
                  <h6>Invoice No.</h6>
                  <h6>Invoice Total</h6>
                  <h6>Amount Received</h6>
                  <h6>Payment Date</h6>
                </div>
                <div className="invoice-body">
                  <p>{moment(el?.createdAt).format('YYYY')}</p>
                  <p>{el?.invoice?.invoiceNo}</p>
                  <p>
                    $
                    {el?.invoice?.invoiceTotal}
                  </p>
                  <p>
                    $
                    {el?.amount}
                  </p>
                  <p>
                    {moment(el?.createdAt).format('DD MMM YYYY')}
                  </p>
                </div>
              </div>
              <div className="invoices-table-right">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          ))}

          <div className="table-section desktop-comp">
            <div className="invoices-table-left">
              <div className="invoices-head">
                <h6>Date</h6>
                <h6>Invoice No.</h6>
                <h6>Invoice Total</h6>
                <h6>Amount Received</h6>
                <h6>Payment Date</h6>
              </div>
              { allInvoices && allInvoices.map((el) => (
                <div className="invoice-body" onClick={() => handleInvoice(el.invoice._id)}>
                  <p>{moment(el.createdAt).format('DD MMM YYYY')}</p>
                  <p>{el?.invoice?.invoiceNo}</p>
                  <p>
                    $
                    {el?.invoice?.invoiceTotal}
                  </p>
                  <p>
                    $
                    {el?.amount}
                  </p>
                  <p>
                    {moment(el?.createdAt).format('DD MMM YYYY')}
                  </p>
                  <div className="invoices-table-right">
                    <img src={arrow} alt="arrow" />
                  </div>
                </div>
              ))}
            </div>
          </div>

        </>
      ) : (
        <p className="no-invoice">No Payments Found</p>
      )}

      {allInvoices.length > 0 ? (
        <div className="user-pagination">
          <ReactPaginate
            previousLabel="← Previous"
            nextLabel="Next →"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination-chk"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeClassName="pagination__link--active"
            breakLabel="..."
            marginPagesDisplayed={-2}
            pageRangeDisplayed={0}
          />
          <div className="no-of-page">
            Page
            {' '}
            {selectedPage}
            {' '}
            of
            {' '}
            {' '}
            {pageCount}
          </div>
        </div>
      ) : (
        null
      )}

    </div>

  </div>

);
PaymentList.propTypes = {
  handleInvoice: PropTypes.func.isRequired,
  allInvoices: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  pageCount: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
};
export default PaymentList;
