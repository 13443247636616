import React, { useEffect, useRef, useState } from 'react';
import { Form, Button, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const SMSOption = ({ setSMSOption, handleSubmit, isloader }) => {
  const [sendby, setSendby] = useState('sms');
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            // setEventPopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleClose = () => {
    setSMSOption(false);
  };
  const handleCheck = (type) => {
    setSendby(type);
  };

  return (
    <div
      className="duration-picker time-picker-modal"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <div className="pending-water-test">
              <h3>Send by SMS</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="sms" name="sendby" onClick={() => handleCheck('sms')} checked={sendby === 'sms' || false} />
                </div>
              </div>
            </div>
            <div className="pending-water-test">
              <h3>Send by Email</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="email" name="sendby" onClick={() => handleCheck('email')} checked={sendby === 'email' || false} />
                </div>
              </div>
            </div>
            <div className="pending-water-test">
              <h3>Send by SMS & Email</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="sms&email" name="sendby" onClick={() => handleCheck('sms&email')} checked={sendby === 'sms&email' || false} />
                </div>
              </div>
            </div>
            <div className="pending-water-test">
              <h3>Save as Pending</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="saveaspending" name="sendby" onClick={() => handleCheck('saveaspending')} checked={sendby === 'saveaspending' || false} />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleSubmit(sendby)}>
                {isloader
                  ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : `${sendby === 'saveaspending' ? 'Save/Update' : 'Send'}`}
              </Button>
            </div>

          </div>

        </div>
      </div>
    </div>

  );
};
SMSOption.propTypes = {
  setSMSOption: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isloader: PropTypes.bool.isRequired,
};
export default SMSOption;
