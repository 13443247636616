/* eslint-disable no-multi-assign */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Button, Modal, Nav, Tabs, Tab,
} from 'react-bootstrap';
import getStrNum from '../../functions/strNumseparator';

const EditNewTextPopup = ({
  addTextpopup, handleTextPopup, handleTextNew,
  customFeilds, setCustomFeilds, handleClose, text, customName, setText, customNameTwo, tdIndex,
}) => {
  let sizeCount = 0;
  const Font = ['Times',
    'Arial', 'Helvetica Neue',
    'Helvetica', 'sans-serif', 'BlinkMacSystemFont',
    'Consolas', 'Menlo', 'Monaco', 'Lucida Console',
    'Liberation Mono', 'DejaVu Sans Mono',
    'Bitstream Vera Sans Mono', 'Courier New',
    'monospace', '"Exo 2", sans-serif'];

  const [customObject, setCustomObject] = useState(text);
  useEffect(() => {
    let oldObject = [...customFeilds];
    let customcheck = { ...oldObject[customNameTwo] };
    setCustomObject(customcheck);
  }, []);
  const setTemplateData = (data, wave) => {
    const { value } = data;
    let parsingValue;
    let oldState = [...customFeilds];
    if (wave === 'text') {
      oldState[customNameTwo].text = value;
      setCustomObject({ ...customObject, text: value });
      setCustomFeilds(oldState);
      return;
    }
    if (wave === 'fontSize') {
      parsingValue = parseInt(value, 10);
    } else if (wave === 'lineHeight') {
      parsingValue = `${value}px`;
    } else {
      parsingValue = value;
    }
    let { style } = customObject;
    let oldStyleObject = { ...style };
    oldStyleObject[wave] = parsingValue;
    oldState[customNameTwo].style = oldStyleObject;
    setCustomObject({ ...customObject, style: oldStyleObject });
    setCustomFeilds(oldState);
  };

  const setNewText = () => {
    handleClose();
  };

  const deleteFeild = () => {
    const oldObject = [...customFeilds];
    oldObject.splice(customNameTwo, 1);
    oldObject.forEach((el, m) => {
      el.index = m;
    });
    setCustomFeilds(oldObject);
    handleClose();
  };

  const setTemplateLocationSize2 = (data, type = 'px') => {
    const { name, value } = data || {};
    console.log('value===>', { value, name, ans: (name === 'left' && (value !== '' && value !== '0' && value !== 'px')) });
    const keyVal = `${value}px`;
    let oldState = [...customFeilds];
    let { style } = oldState[customNameTwo];
    let oldtdStyleObject = { ...style };
    oldState[customNameTwo].style = oldtdStyleObject;
    if ((name === 'left' && (value !== '' && value !== '0' && value !== 'px'))) {
      oldtdStyleObject.position = 'absolute';
      oldtdStyleObject[name] = keyVal;
    } else if ((name === 'top' && (value !== '' && value !== '0' && value !== 'px'))) {
      oldtdStyleObject.position = 'absolute';
      oldtdStyleObject[name] = keyVal;
    } else {
      oldtdStyleObject[name] = 'initial';
    }
    if (oldtdStyleObject.left === 'initial' && oldtdStyleObject.top === 'initial') {
      oldtdStyleObject.position = 'initial';
    }
    setCustomObject({ ...customObject, style: oldtdStyleObject });
    setCustomFeilds(oldState);
  };
  const setTemplateLocationSize = (data, type) => {
    const { name, value } = data || {};

    const keyVal = value + type;
    let oldState = [...customFeilds];
    const { tdStyle1, tdStyle2 } = oldState[customNameTwo];
    let oldtdStyle1Object = { ...tdStyle1 };
    let oldtdStyle2Object = { ...tdStyle2 };
    if (tdIndex === 'tdStyle1') {
      oldtdStyle1Object[name] = keyVal;
      oldState[customNameTwo].tdStyle1 = oldtdStyle1Object;
      setCustomObject({ ...customObject, tdStyle1: oldtdStyle1Object });
    } else {
      oldtdStyle2Object[name] = keyVal;
      oldState[customNameTwo].tdStyle2 = oldtdStyle2Object;
      setCustomObject({ ...customObject, tdStyle2: oldtdStyle2Object });
      setCustomFeilds(oldState);
    }
  };
  const addjustElementWidth = (data, type) => {
    const { name, value } = data || {};
    let oldState = [...customFeilds];
    let { tdStyle2 } = customObject;
    let oldStyleObject = { ...tdStyle2 };
    oldStyleObject.width = `${value}%`;
    oldState[customNameTwo].tdStyle2 = oldStyleObject;

    setCustomObject({ ...customObject, tdStyle2: oldStyleObject });
    setCustomFeilds(oldState);
  };

  return (
    <Modal
      size="md"
      show={addTextpopup}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup image-upload-popup change-text-popup"
    >
      <Modal.Body>
        <div className="appointment-container preview-invoice preview-popup">
          <div className="change-image-section">
            <div className="preview-text">
              <h6>Text Wording</h6>
              <span style={{
                fontFamily: `${customObject.fontFamily}`,
                fontSize: `${customObject.fontSize}px`,
                color: `${customObject.color}`,
                fontStyle: `${customObject.fontStyle}`,
                textDecoration: `${customObject.textDecoration}`,
                fontWeight: `${customObject.fontWeight}`,
                lineHeight: `${customObject.lineHeight}`,
                border: '1px solid rgb(220, 217, 217)',
                padding: '10px',
                marginBottom: '15px',
                display: 'flex',
                borderRadius: '5px',
              }}
              >
                {customObject.text}
              </span>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                {/* <Form.Label>Text Wording</Form.Label> */}
                <Form.Control as="textarea" rows={3} value={customObject.text} onChange={({ target: { value, name } }) => setTemplateData({ value, name }, 'text')} />
              </Form.Group>
            </div>
            <h6>Type Characteristics</h6>
            <div className="field-location-section">
              <div className="field-location">

                <Form.Group controlId="formGridEmail">
                  <Form.Label>Typeface</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.font} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'font')}>
                      {Font.map((f) => (
                        <option value={f}>{f}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Point Size</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontSize} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontSize')}>
                      {Array.from(Array(20).keys()).map(() => {
                        sizeCount += 1;
                        return (
                          <option value={sizeCount}>{sizeCount}</option>
                        );
                      })}
                    </select>
                  </div>
                </Form.Group>

              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Style</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontStyle} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontStyle')}>
                      <option value="normal">Normal</option>
                      <option value="Italic">Italic</option>
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Line Spacing</Form.Label>
                  <Form.Control type="number" value={getStrNum(customObject?.style?.lineHeight)?.num} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'lineHeight')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Alignment</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textAlign} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textAlign')}>
                      <option value="left">Flush Left</option>
                      <option value="center">Centered</option>
                      <option value="right">Flush Right</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Colour</Form.Label>
                  <Form.Control type="color" value={customObject?.style?.color} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'color')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Effects</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textDecoration} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textDecoration')}>
                      <option value="underline">UnderLine</option>
                      <option value="line-through">Strikeout</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Weight</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontWeight} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontWeight')}>
                      <option value="bold">Bold</option>
                      <option value="bolder">Bolder</option>
                      <option value="lighter">Lighter</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="text-popup-spacing">
              <h6>Text Field Location & Size</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.style?.top)?.num} name="top" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(customObject?.style?.top)?.str)} placeholder="0" />
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Distance from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.style?.left)?.num} name="left" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(customObject?.style?.left)?.str)} placeholder="0" />
                  </Form.Group>
                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Height</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.lineHeight)?.num} name="lineHeight" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.lineHeight)?.str)} />
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Width</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.width)?.num} name="width" onChange={(e) => addjustElementWidth(e.target, getStrNum(customObject?.[tdIndex]?.width)?.str)} />
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* <div className="text-popup-spacing">
              <h6>Spacing from Top & Left</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Spacing from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.paddingTop)?.num} name="paddingTop" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.paddingTop)?.str)} placeholder="0" />
                  </Form.Group>

                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Spacing from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.paddingLeft)?.num} name="paddingLeft" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.paddingLeft)?.str)} placeholder="0" />
                  </Form.Group>
                </div>
              </div>
            </div> */}
          </div>
          <div className="template-btn">
            <Button className="delete-btn" onClick={deleteFeild}>Delete Field</Button>
            <Button className="save-btn" onClick={setNewText}>Save/ Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
};
EditNewTextPopup.propTypes = {
  addTextpopup: PropTypes.func.isRequired,
  handleTextPopup: PropTypes.bool.isRequired,
  handleTextNew: PropTypes.func.isRequired,
  customFeilds: PropTypes.objectOf.isRequired,
  handleClose: PropTypes.func.isRequired,
  setCustomFeilds: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  customName: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  customNameTwo: PropTypes.func.isRequired,
  tdIndex: PropTypes.string.isRequired,
};
export default EditNewTextPopup;
