import React, { useEffect, useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const QuotesTextOption = ({ setQuoteOption, handleSubmit, types }) => {
  const [sendby, setSendby] = useState('quoteOnly');
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            // setEventPopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleClose = () => {
    setQuoteOption(false);
  };
  const handleCheck = (type) => {
    setSendby(type);
  };

  return (
    <div
      className="duration-picker time-picker-modal"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>

          <div className="appointment-view">
            <h5>
              Edit Quote
              {' '}
              {types}
            </h5>
            <div className="pending-water-test">
              <h3>Change This Quote Only</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="quoteOnly" name="sendby" onClick={() => handleCheck('quoteOnly')} checked={sendby === 'quoteOnly' || false} />
                </div>
              </div>
            </div>
            <div className="pending-water-test">
              <h3>Update Quote Template</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check type="checkbox" value="quoteTemp" name="sendby" onClick={() => handleCheck('quoteTemp')} checked={sendby === 'quoteTemp' || false} />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleSubmit(sendby, types)}>
                Save/Update
              </Button>
            </div>

          </div>

        </div>
      </div>
    </div>

  );
};
QuotesTextOption.propTypes = {
  setQuoteOption: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  types: PropTypes.string.isRequired,
};
export default QuotesTextOption;
