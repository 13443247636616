/* eslint-disable no-return-assign */
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import {
  Modal, Dropdown, Form, Button,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import './addappointment.css';
import SearchModal from './searchmodal';
import ServiceModal from './servicemodal';
import search from '../../assets/admin/search-icon.png';
import arrow from '../../assets/home/arrowdown.png';
import arrowwhite from '../../assets/home/whitearrow.png';
import TimePicker from './timepicker';
import greydelete from '../../assets/home/grey-delete.png';
import showmore from '../../assets/home/showmore.png';
import DateModal from './dateModal';
import { userInstance } from '../../config/axios';
import {
  showErrMsg,
  addZero,
  queryString,
  matchDataObject, getFirstServiceTime, removeComma,
} from '../../functions/index';
import sort from '../../assets/home/sort.png';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import clientimg from '../../assets/home/client.png';
import HourPicker from './hourPicker';
import edit from '../../assets/home/grey-edit.png';
import ConfirmPopupApp from './confirmPopupApp';
import { errorBorderTime, errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import sortClientList from '../../functions/listSort';
import toastMessage from '../../functions/toastMessage';

const Editappointment = ({
  handleEdit,
  editAppointment,
  handleCloseEdit,
  idd,
  getAppointments,
  setRandom,
  durationPicker,
  setDurationPicker,
  durationData, setDuration,
  handleDurationPicker,
  setBookingData,
  bookingData,
  initialBookingData,
  currentDates,
  eventDate,
  pid,
  handleAppointmentDetails,
  SelectedStaff,
  setEventDate,
}) => {
  const { userData: { industryId } } = useContext(AuthDataContext);
  const history = useHistory();
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  // let { startDateTime, employee_id, appId } = queryString();
  const timeFromDateFormat = 'HH:mm';
  const [setDefaultTime, SetTime] = useState('9:30');
  const otherBookingInitial = {
    category: '',
    subcategory: '',
    duration: '5',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    soldPer: '',
    unit: '',
    symbol: '',
    staffName: '',
    staffId: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    xerocode: '',
    supplier: '',
  };
  const initialDatePopup = { interval_type: 'weekly', interval_multiplier: '4' };
  const initialDateData = {
    date: moment().format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: '',
    occurenceMsg: '',
    specificDate: moment().format(dateFormat),
    requiredday: null,
    appointmenttype: 'oneteam_oneday',
    service: [],
  };
  const multiNotesState = [{ notes: null }];
  const [multiNotes, setMultiNotes] = useState(multiNotesState);
  const [showSearch, setShowSearch] = useState(false);
  const [showService, setShowService] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [searchedClients, setClients] = useState(null);
  const [searchedClients2, setClients2] = useState(null);
  const [addedClients, setAddedClients] = useState([]);
  // const [bookingData, setBookingData] = useState(initialBookingData);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [addedServices, setServices] = useState([]);
  const [editData, setEditData] = useState({ edit: false, index: '' });
  const [notes, setNotes] = useState('');
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState('');
  const [summary, setSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
  });
  const [mutiSummary, setMutiSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
  });
  const [mutiStaff, setMutiStaff] = useState([]);
  const [dateData, setDateData] = useState(initialDateData);
  // const [renderSort, setRenderSort] = useState(false);
  const [misc, setMisc] = useState(false);
  const [extras, setExtras] = useState({ value: '', msg: '' });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [subcategoryTxt, setSearchTxt] = useState('');
  const [clientTxt, setClientTxt] = useState('');
  // const [durationPicker, setDurationPicker] = useState(false);
  const [timeData, setTime] = useState('9:30');
  // const [durationData, setDuration] = useState('');
  const [repeatAppt, setRepeatAppt] = useState(false);
  const [repeatFrequency, setRepeatFrequency] = useState('');
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const timepickerRef = useRef(null);
  const [requiredDays, setRequiredDays] = useState(2);
  const [team, setTeam] = useState(2);
  const [newLength, setnewlength] = useState();
  const [staffIndex, setStaffIndex] = useState();
  // const [viewAppointmentPopup, setViewappointment] = useState();
  const [appointmentType, setAppointmentType] = useState('oneteam_oneday');
  const [multiServices, setMultiServices] = useState([]);
  const [multiAppointment, setmultiAppointment] = useState(false);
  const [activeAdd, setActiveAdd] = useState(0);
  const [newAppointment, setNewAppointment] = useState([initialDateData]);
  const [appointmentIndex, setappointmentIndex] = useState(0);
  const [existingClientId, setExistingClientId] = useState('');
  const [checkRepeated, setCheckRepeated] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [oldObjectData, setoldObjectData] = useState([]);
  const [activeRepeatIndex, setActiveRepeatIndex] = useState();
  const [calcType, setCalcType] = useState();
  const [errorValue, setErrorValue] = useState('');
  const [isHideSearch, setHideSearch] = useState(false);

  // const [testOldOBj, settestOldOBj] = useState([]);
  const handleSaveDate = () => {
    updateDateData();
    handleFrequency();
  };

  const setStaff = (staffName, staffId, info) => {
    if (info === 'oneteam_oneday') {
      const old = [...newAppointment];
      const servicesListCopy = old[0].service;
      servicesListCopy.forEach((el) => {
        el.staffId = staffId;
        el.staffName = staffName;
      });
      old[0].service = servicesListCopy;

      setNewAppointment([...old]);
    } else if (info === 'oneteam_multiday') {
      const old = [...newAppointment];
      old.forEach((item) => {
        item.service.forEach((el) => {
          el.staffId = staffId;
          el.staffName = staffName;
        });
      });
      setNewAppointment([...old]);
    } else if (info === 'multiteam_oneday' || info === 'multiteam_multiday') {
      if (editData.edit) {
        const old = [...newAppointment];
        const indexService = old[appointmentIndex].service;
        indexService.forEach((el) => {
          el.staffId = staffId;
          el.staffName = staffName;
        });
        old[appointmentIndex].service = indexService;

        setNewAppointment([...old]);
        const OldState = [...mutiStaff];
        const findIndex = OldState.findIndex((el) => el.index === appointmentIndex);
        if (findIndex > -1) {
          OldState[findIndex].staffId = staffId;
          OldState[findIndex].staffName = staffName;
          setMutiStaff(OldState);
        }
      }
    }
  };

  const updateDateData = (isEndType) => {
    let { interval_type, interval_multiplier } = datePopupData;
    const { occurenceMsg } = dateData;

    const originalIntervalType = interval_type;
    let frequencyMsg = '';
    if (parseInt(interval_multiplier, 10) > 1) {
      switch (interval_type) {
        case 'monthly':
          interval_type = 'months';
          break;
        case 'weekly':
          interval_type = 'weeks';
          break;
        case 'daily':
          interval_type = 'days';
          break;

        default:
          break;
      }
    }
    frequencyMsg = parseInt(interval_multiplier, 10) > 1
      ? `Repeats every ${interval_multiplier} ${interval_type}`
      : interval_type;
    const updateObj = {
      ...dateData,
      interval_multiplier,
      interval_type: originalIntervalType,
      frequencyMsg,
    };

    if (occurenceMsg === 'On a Specific Date') {
      const interval = timeInterval(interval_multiplier, originalIntervalType);
      updateObj.specificDate = interval;
    }

    if (isEndType) {
      updateObj.endType = 'ongoing';
      updateObj.occurenceMsg = 'Ongoing';
    }
    setDateData(updateObj);
  };

  // eslint-disable-next-line consistent-return
  const timeInterval = (interval, type) => {
    if (type === 'weekly') {
      // let intervalDay = dateData.specificDate interval * 7;
      let intervalDay = moment(dateData.date)
        .add(interval * 7, 'days')
        .format(dateFormat);
      return intervalDay;
    }
    if (type === 'daily') {
      let intervalDay = moment(dateData.date)
        .add(interval, 'days')
        .format(dateFormat);
      return intervalDay;
    }
    if (type === 'monthly') {
      let intervalDay = moment(dateData.date)
        .add(interval * 30, 'days')
        .format(dateFormat);
      // let intervalDay = interval * 30;
      return intervalDay;
    }
  };

  const handleChangeDate = (type, value) => {
    setDatePopupData({ ...datePopupData, [type]: value });
  };

  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        console.log('dd', timepickerRef);
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };

  const handleRepeat = () => {
    setRepeatAppt(!repeatAppt);
    const old = [...newAppointment];
    if (old[appointmentIndex].repeatAppt) {
      old[appointmentIndex].repeatAppt = false;
      old[appointmentIndex].occurence = null;
      old[appointmentIndex].frequencyMsg = null;
      old[appointmentIndex].interval_type = null;
      old[appointmentIndex].msg = "One-off appointment | Doesn't repeat";
      old[appointmentIndex].endType = null;
      old[appointmentIndex].occurenceMsg = null;
      old[appointmentIndex].interval_multiplier = null;
      // const initialCopy = { ...old[appointmentIndex] };
      // delete initialCopy.date;
      // setDateData({ ...initialCopy, date: dateData.date });
      // setDatePopupData({ ...initialDatePopup });
      // old[appointmentIndex] = { ...initialCopy, date: dateData.date };
      // console.log('comingtheUndr', old[appointmentIndex]);
    } else {
      old[appointmentIndex].repeatAppt = true;
      updateDateData(true);
    }
    setNewAppointment(old);
    // if (repeatAppt) {
    //   const initialCopy = { ...initialDateData };
    //   delete initialCopy.date;
    //   setDateData({ ...initialCopy, date: dateData.date });
    //   setDatePopupData({ ...initialDatePopup });
    // } else {
    //   updateDateData(true);
    // }
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };
  const handleHourPicker = () => {
    console.log('hourpixkier htting');
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };

  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];

    let newNextStartTime;
    let currentNewStartTime;

    if (sign === 'plus') {
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(
          currentEntryStart.setMinutes(
            currentEntryStart.getMinutes() + parseFloat(duration),
          ),
        ),
      ).format(dateFormat);
      newNextStartTime = moment(updatedNextEntryStart).format(
        timeFromDateFormat,
      );

      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === 'minus') {
      newNextStartTime = startTime;

      const updatedCurrentEntryStart = moment(
        new Date(
          new Date(start).setMinutes(
            new Date(start).getMinutes()
            + parseFloat(servicesCopy[oldIndex].duration),
          ),
        ),
      ).format(dateFormat);
      currentNewStartTime = moment(updatedCurrentEntryStart).format(
        timeFromDateFormat,
      );
    }
    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    calculateDurations(servicesCopy);
  };
  const handleChangeDateData = (e) => {
    // console.log('ee', e.target.value);
    const { name } = e.target;
    const { value } = e.target;
    const obj = { ...dateData };

    if (value === 'On a Specific Date') {
      const interval = timeInterval(obj.interval_multiplier, obj.interval_type);
      obj.specificDate = interval;
    }

    if (value === 'Repeats every 4 weeks') {
      handleFrequency();
    }
    if (
      name === 'interval_multiplier'
      && (value === 'daily'
        || value === 'weekly'
        || value.includes('weeks')
        || value.includes('days')
        || value.includes('One-off'))
    ) {
      const val = value.includes('weeks') || value.includes('days')
        ? parseInt(value.replace(/[^0-9\\.]/g, ''), 10)
        : 1;
      obj.interval_multiplier = val;
      obj.interval_type = value.includes('weeks') || value === 'weekly' ? 'weekly' : 'daily';
      obj.frequencyMsg = value;
      if (!obj.occurence) {
        obj.occurence = null;
      }
      obj.endType = !obj.endType ? 'ongoing' : 'by_occurence';
      if (value === "One-off appointment | Doesn't repeat") {
        obj.occurence = null;
        obj.frequencyMsg = null;
        obj.interval_type = null;
        obj.msg = value;
        obj.interval_multiplier = null;
        obj.endType = null;
        obj.frequencyMsg = '';
        obj.occurenceMsg = '';
      } else {
        obj.occurenceMsg = !obj.occurenceMsg ? 'Ongoing' : obj.occurenceMsg;
      }
    } else if (name === 'occurence') {
      if (value === 'Ongoing') {
        obj.occurence = null;
        obj.endType = value.toLowerCase();
        obj.occurenceMsg = value;
      } else {
        obj.occurenceMsg = value;
        obj[name] = parseInt(value.replace(/[^0-9\\.]/g, ''), 10);
        obj.endType = 'by_occurence';
      }
    } else {
      obj[name] = value;
    }
    setDateData(obj);
  };

  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === 'default') {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === 'product') {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === 'yes' || value === 'sellPrice') {
      obj.cost = '';
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === 'buyCost') {
      obj.buyCostProduct = '';
      obj.cost = obj.previousCost;
    } else if (value === 'buySell') {
      obj.cost = '';
      obj.buyCostProduct = '';
    }
    setBookingData(obj);
  };

  const handleShowSearch = async () => {
    setShowSearch(!showSearch);
    if (showSearch) {
      setClients(null);
    } else {
      const response = await userInstance().get('/customer/getCustomersAll2');
      if (response.status === 200) {
        const { customers } = response.data;
        let updatedCustomer = customers;
        if (addedClients[0]?._id) {
          updatedCustomer = customers.map((el) => {
            if (el._id === addedClients[0]._id) {
              if (el.siteAddresses[activeAdd]) {
                el.siteAddresses[activeAdd].activeSiteAdd = true;
              }
            }
            return el;
          });
        }
        const newList = sortClientList(updatedCustomer);
        const newList2 = sortClientList(customers);
        setClients(newList);
        setClients2(newList2);
      }
    }
  };

  const getAllClients = async () => {
    const response = await userInstance().get('/customer/getCustomersAll2');
    if (response.status === 200) {
      const { customers } = response.data;
      let updatedCustomer = customers;
      if (addedClients[0]?._id) {
        updatedCustomer = customers.map((el) => {
          if (el._id === addedClients[0]._id) {
            if (el.siteAddresses[activeAdd]) {
              el.siteAddresses[activeAdd].activeSiteAdd = true;
            }
          }
          return el;
        });
      }
      const newList = sortClientList(updatedCustomer);
      const newList2 = sortClientList(customers);
      setClients(newList);
      setClients2(newList2);
    }
  };

  const searchClient = (searchTxt_) => {
    setClientTxt(searchTxt_);
    // console.log('this is the search Text', searchTxt_);
    // try {
    //   if (searchTxt_) {
    //     const response = await userInstance().get(
    //       `/customer/searchCustomer?value=${searchTxt_}`,
    //     );
    //     const { customers } = response.data;
    //     setClients(customers);
    //   } else {
    //     setClients(searchedClients2);
    //   }
    // } catch (error) {
    //   console.log(error);
    //   //   console.log(error);
    // }
  };
  useEffect(() => {
    let searchTimeOut = setTimeout(async () => {
      if (!clientTxt) {
        setClients(searchedClients2);
        return;
      }
      try {
        const response = await userInstance().get(`/customer/searchCustomer?value=${clientTxt}`);
        let list = response.data.customers;
        if (clientTxt) {
          if (isHideSearch) {
            console.log('check3');
            list.forEach((client) => {
              if (client.siteAddressess) {
                client.siteAddressess.forEach((addr) => {
                  addr.hide = false;
                });
              }
            });
          } else {
            console.log('wantConditions');
            list = list.filter((el) => (el.siteAddresses ? el.siteAddresses.filter((adr) => adr.hide).length === 0 : el));
          }
        }
        const newList = sortClientList(list);
        setClients(newList);
      } catch (error) {
        console.log(error);
      }
    }, 500);
    return (() => clearInterval(searchTimeOut));
  }, [clientTxt]);

  const getAppointmentById = async () => {
    try {
      const response = await userInstance().get(
        `/appointment/getAppointmentByIdEdit?id=${idd}&pid=${pid}`,
      );
      const { appointmentdetail, msg } = response.data;
      console.log('appointmentdetail===>>', appointmentdetail);
      localStorage.setItem('currentapp', JSON.stringify(appointmentdetail[0]));
      if (msg === 'Success') {
        let arr = [];
        console.log('comingSSSSS', currentDates);
        setAppointmentType(appointmentdetail[0].appointmenttype);
        setoldObjectData(appointmentdetail[0]);
        setAddedClients([appointmentdetail[0].client]);
        setActiveAdd(appointmentdetail[0].clientSiteAddressIndex);
        setRequiredDays(parseInt(appointmentdetail[0].requiredday, 10));
        setTeam(parseInt(appointmentdetail[0].teammember, 10));
        setCalcType(appointmentdetail[0]?.appointment_calc_type);
        appointmentdetail.forEach((el) => {
          const { appointmenttype } = el.dateData[0];
          if (appointmentdetail[0].appointmenttype === 'oneteam_oneday') {
            // console.log('comingInthisCondition');
            arr = [...arr, ...el.dateData];
            arr[0].date = new Date(moment(currentDates).format('YYYY-MM-DD')).toISOString();
          } else {
            arr = [...arr, ...el.dateData];
          }
        });
        setNewAppointment(arr);
        calculateMutiCost(arr, null);
        SetTime(appointmentdetail[0].dateData[0].service[0].startTime);
        if (appointmentdetail[0].appointmenttype === 'multiteam_oneday' || appointmentdetail[0].appointmenttype === 'multiteam_multiday') {
          arr.forEach((ele, i) => {
            ele.service.forEach((item) => {
              const findArray = mutiStaff.find((el) => el.index === i);
              if (!findArray) {
                mutiStaff.push({
                  staffId: item.staffId,
                  index: i,
                  staffName: item.staffName,
                  date: ele.date,
                });
                setMutiStaff(mutiStaff);
              }
            });
          });
        }
        //   setAppointmentType(appointmentdetail.appointmenttype);
        //   setNewAppointment(appointmentdetail.dateData);
        //   setDateData(appointmentdetail.dateData[0]);
        //   setAddedClients(appointmentdetail.client);
        //   setServices(appointmentdetail.dateData[0].service);
        //   setSummary(appointmentdetail.totalData);
        //   setExistingClientId(appointmentdetail._id);

        const { interval_type } = appointmentdetail[0].dateData[0] || {};
        if (interval_type && appointmentdetail[0].appointmenttype === 'oneteam_oneday') {
          setCheckRepeated(true);
          const activeIndex = parseInt(localStorage.getItem('activeIndex'), 10);
          setActiveRepeatIndex(activeIndex);
        } else {
          setCheckRepeated(false);
        }
      }
    } catch (error) {
      //   console.log(error);
    }
  };

  const handleShowService = (index) => {
    setappointmentIndex(index);
    setStaffIndex(0);
    const old = [...newAppointment];
    const Index = index || 0;
    if (appointmentType === 'multiteam_oneday' || appointmentType === 'multiteam_multiday') {
      const currentServices = old[Index].service.length;
      setnewlength(currentServices);
    } else if (appointmentType === 'oneteam_multiday') {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setShowService(!showService);
    setEditData({ edit: false, index: '' });
    const lastService = addedServices[addedServices.length - 1];
    if (lastService) {
      const startTime = moment(lastService.end).format(timeFromDateFormat);
      setBookingData({ ...bookingData, startTime, ...otherBookingInitial });
    } else {
      setBookingData({ ...bookingData, ...otherBookingInitial });
    }
    setSearchTxt('');
    setSubcategories(null);
    setMisc(false);
    if (appointmentType === 'multiteam_oneday') {
      checkStaff(old, Index, index);
    } else if (appointmentType === 'multiteam_multiday') {
      const appDate = old[Index].date;
      checkMultiStaff(old, Index, index, appDate);
    } else if (appointmentType === 'oneteam_multiday' || appointmentType === 'oneteam_oneday') {
      const currentServices = old[0].service[0];
      if (currentServices) {
        const obj = {
          ...bookingData,
          staffId: currentServices.staffId,
          staffName: currentServices.staffName,
          startTime: setDefaultTime,
        };
        setBookingData(obj);
      } else {
        const obj = {
          ...bookingData,
          staffId: bookingData.staffId,
          staffName: bookingData.staffName,
          startTime: setDefaultTime,
        };
        setBookingData(obj);
      }
    }
  };

  const checkMultiStaff = async (old, Index, index, appDate) => {
    let leftStaffs = [];
    if (mutiStaff.length > 0) {
      const filterDates = mutiStaff.filter((el) => new Date(el.date).toLocaleDateString() === new Date(appDate).toLocaleDateString());
      const findIndex = filterDates.filter((el) => el.index < index);
      if (findIndex.length > 0) {
        const currentServices = old[Index].service.length;
        if (currentServices === 0) {
          findIndex.forEach((el) => {
            leftStaffs.push(el.staffId);
          });
          const filterProList = filterArray(staffList, leftStaffs);
          const obj = {
            ...bookingData,
            staffId: filterProList[0]._id,
            staffName: `${filterProList[0].firstname} ${filterProList[0].lastname}`,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
          setStaffList(filterProList);
        } else {
          const findIndexStaff = mutiStaff.filter((el) => el.index === index);
          if (findIndexStaff.length > 0) {
            const obj = {
              ...bookingData,
              staffId: findIndexStaff[0].staffId,
              staffName: findIndexStaff[0].staffName,
              startTime: setDefaultTime,
            };
            setBookingData(obj);
          }
        }
      } else {
        await getStaffList();
        const findIndexStaff = mutiStaff.filter((el) => el.index === index);
        if (findIndexStaff.length > 0) {
          const setDefaultStaff = await staffList.filter((el) => el._id === findIndexStaff[0].staffId);
          if (setDefaultStaff.length > 0) {
            const obj = {
              ...bookingData,
              staffId: setDefaultStaff[0]._id,
              staffName: `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
              startTime: setDefaultTime,
            };
            setBookingData(obj);
          }
        } else {
          await getStaffList();
          const obj = {
            ...bookingData,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
        }
      }
    }
  };

  const checkStaff = async (old, Index, index) => {
    let leftStaffs = [];
    if (mutiStaff.length > 0) {
      const findIndex = mutiStaff.filter((el) => el.index < index);
      // console.log('findIndex', findIndex);
      if (findIndex.length > 0) {
        const currentServices = old[Index].service.length;
        // console.log('currentServices', currentServices);
        if (currentServices === 0) {
          findIndex.forEach((el) => {
            leftStaffs.push(el.staffId);
          });
          const filterProList = filterArray(staffList, leftStaffs);
          const obj = {
            ...bookingData,
            staffId: filterProList[0]._id,
            staffName: `${filterProList[0].firstname} ${filterProList[0].lastname}`,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
          setStaffList(filterProList);
        } else {
          // console.log('mutiStaff', mutiStaff, index);
          const findIndexStaff = mutiStaff.filter((el) => el.index === index);
          // console.log('findIndexStaff', findIndexStaff);
          if (findIndexStaff.length > 0) {
            const obj = {
              ...bookingData,
              staffId: findIndexStaff[0].staffId,
              staffName: findIndexStaff[0].staffName,
              startTime: setDefaultTime,
            };
            setBookingData(obj);
          }
        }
      } else {
        await getStaffList();
        const findIndexStaff = mutiStaff.filter((el) => el.index === index);
        const setDefaultStaff = await staffList.filter((el) => el._id === findIndexStaff[0].staffId);
        if (setDefaultStaff.length > 0) {
          const obj = {
            ...bookingData,
            staffId: setDefaultStaff[0]._id,
            staffName: `${setDefaultStaff[0].firstname} ${setDefaultStaff[0].lastname}`,
            startTime: setDefaultTime,
          };
          setBookingData(obj);
        }
      }
    }
  };

  const filterArray = (arr1, arr2) => {
    let filteredArr = [];
    arr1.forEach((el) => {
      const userExists = arr2.some((user) => user === el._id);
      if (!userExists) {
        filteredArr.push(el);
      }
    });
    return filteredArr;
  };

  const handleDateModal = (handeltype, index) => {
    const {
      date, occurence, interval_multiplier, interval_type, endType, occurenceMsg, specificDate, requiredday, createdOn, endsAt, appointmenttype, teammember,
    } = dateData;
    let oldStateData = [...newAppointment];

    const obj = oldStateData[index];

    if (obj.msg === "One-off appointment | Doesn't repeat") {
      obj.repeatAppt = false;
    }
    // console.log('obj', obj.msg);
    setappointmentIndex(index);
    let startDate = new Date(date);
    // startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(endsAt || startDate);
    // endDate.setHours(0, 0, 0, 0);
    if (handeltype === 'add' && appointmentType === 'oneteam_oneday') {
      setOneTeamOneDay(dateData, startDate, oldStateData, endDate, index);
    } else if (handeltype === 'add' && (appointmentType === 'oneteam_multiday')) {
      setOneTeamMultiday(oldStateData, obj, startDate, dateData, index, endDate);
    } else if (handeltype === 'add' && appointmentType === 'multiteam_oneday') {
      setMultiTeamOneday(index, teammember, oldStateData, obj, startDate, createdOn, dateData);
    } else if (handeltype === 'add' && appointmentType === 'multiteam_multiday') {
      setMultiTeamMultiday(index, teammember, requiredday, oldStateData, obj, startDate, createdOn, dateData);
    } else if (handeltype === 'close' && (appointmentType === 'oneteam_multiday')) {
      let leftServices = [];
      oldStateData.splice(index, 1);
      oldStateData.forEach((el) => {
        el.requiredday = oldStateData.length === 1 ? 2 : oldStateData.length;
        el.msg = appointmentType === 'multiteam_multiday' ? 'Additional Team Members Multi Visit' : `One Team Member Multi Visit | ${oldStateData.length === 1 ? 2 : oldStateData.length} days | Doesn't repeat`;
        leftServices = [...leftServices, ...el.service];
      });
      if (oldStateData.length === 1) {
        setRequiredDays(2);
      } else {
        setRequiredDays(oldStateData.length);
      }
      setNewAppointment(oldStateData);
      setappointmentIndex(0);
      calculateMutiCost(oldStateData, null);
    } else if (handeltype === 'close' && appointmentType === 'multiteam_oneday') {
      let leftServices = [];
      oldStateData.splice(index, 1);
      oldStateData.forEach((el) => {
        el.teammember = oldStateData.length === 1 ? 2 : oldStateData.length;
        el.msg = 'Additional Team Member | Day Visit';
        leftServices = [...leftServices, ...el.service];
      });
      if (oldStateData.length === 1) {
        setTeam(2);
      } else {
        setTeam(oldStateData.length);
      }
      setNewAppointment(oldStateData);
      setappointmentIndex(0);
      calculateMutiCost(oldStateData, null);
      const oldMulti = [...mutiStaff];
      const findIndex = oldMulti.findIndex((el) => el.index === index);
      oldMulti.splice(findIndex, 1);
      setMutiStaff(oldMulti);
    } else if (handeltype === 'close' && appointmentType === 'multiteam_multiday') {
      // console.log('length', oldStateData.length, team);
      if (oldStateData.length > team) {
        const indexDate = oldStateData[index].date;
        for (var i = oldStateData.length - 1; i >= 0; i--) {
          if (new Date(indexDate).toLocaleString() === new Date(oldStateData[i].date).toLocaleString()) {
            oldStateData.splice(i, 1);
          }
        }
      } else {
        oldStateData.length = 1;
      }
      // setRequiredDays(requiredDays - 1);
      setNewAppointment(oldStateData);
      setappointmentIndex(0);
      calculateMutiCost(oldStateData, null);
      const oldMulti = [...mutiStaff];
      const findIndex = oldMulti.findIndex((el) => el.index === index);
      oldMulti.splice(findIndex, 1);
      setMutiStaff(oldMulti);
    } else {
      const dateDataCopy = oldStateData[index];
      oldStateData[index] = dateDataCopy;
      setDateData(dateDataCopy);
      setDatePopupData({
        ...datePopupData,
        interval_multiplier: dateDataCopy.interval_multiplier,
        interval_type: dateDataCopy.interval_type,
      });
      setNewAppointment(oldStateData);
    }
    setShowDate(!showDate);
  };

  const setOneTeamOneDay = (dateDataa, startDate, oldStateData, endDate, index) => {
    const {
      date, occurence, interval_multiplier, interval_type, endType, occurenceMsg, specificDate, requiredday, createdOn, endsAt, appointmenttype, teammember,
    } = dateDataa;
    oldStateData[0].date = moment(startDate).format(dateFormat);
    oldStateData[0].endsAt = moment(endDate).format(dateFormat);

    // changes  by Randhir may be some issues
    // if (oldStateData[0]?.service[0]) {
    //   let dateForServ = moment(startDate).toDate();
    //   const firstService = oldStateData[0].service[0].startTime;

    //   let newTime = firstService.split(':');

    //   dateForServ.setHours(newTime[0]);
    //   dateForServ.setMinutes(newTime[1]);
    //   dateForServ.setSeconds(0);

    //   oldStateData[0].service[0].start = moment(dateForServ).format(dateFormat);
    // }

    setNewAppointment(oldStateData);
    let msgIntervalType = 'days';
    if (interval_multiplier) {
      let newDate = new Date(occurenceMsg === 'On a Specific Date' ? specificDate : date);
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        interval *= 7;
        msgIntervalType = 'weeks';
      } else if (interval_type === 'monthly') {
        // newDate.setDate(newDate.getDate() + daysToAdd);
        newDate = new Date(moment(newDate, 'DD-MM-YYYY').add((occurence - 1) * parseInt(interval, 10), 'months').format('L'));

        msgIntervalType = 'months';
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (occurenceMsg !== 'On a Specific Date' && interval_type !== 'monthly') {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date' ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}` : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
      // setDateData({ ...dateDataa, msg, endsAt: newDate });
    }
  };

  const setOneTeamMultiday = (oldStateData, obj, startDate, dateDataa, index, endDate) => {
    const {
      date, occurence, interval_multiplier, interval_type, endType, occurenceMsg, specificDate, requiredday, createdOn, endsAt, appointmenttype, teammember,
    } = dateDataa;
    if (index === 0) {
      if (new Date(initialDateData.date).toLocaleDateString() !== new Date(startDate).toLocaleDateString()) {
        oldStateData[0].date = moment(startDate).format(dateFormat);
        oldStateData[0].endsAt = moment(endDate).format(dateFormat);
        setNewAppointment(oldStateData);
      }
    }
    let addService = [];
    let nostep = parseInt(requiredDays, 10) || 2;
    let nextDate = startDate;
    let { length } = newAppointment;
    // console.log({ length, nostep });
    if (length === nostep) {
      oldStateData[index].date = moment(startDate).format(dateFormat);
      oldStateData[index].endsAt = moment(endDate).format(dateFormat);
      setNewAppointment(oldStateData);
    } else if (length > nostep) {
      oldStateData.length = nostep;
      setNewAppointment(oldStateData);
      calculateMutiCost(oldStateData, null);
      setappointmentIndex(0);
    } else {
      for (let step = length; step < nostep; step++) {
        nextDate.setDate(nextDate.getDate() + 1);
        let dateDataCopy = {
          ...dateDataa,
          date: moment(nextDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(nextDate).format(dateFormat),
          requiredday: requiredday || 2,
          teammember: null,
          teamnotes: null,
          repeatAppt: false,
          msg: `One Team Member Multi Visit | ${requiredDays || 2} days | Doesn't repeat`,
          service: addService,
        };
        oldStateData[step] = dateDataCopy;
      }
      for (let step = 0; step < nostep; step++) {
        oldStateData[step].msg = `One Team Member Multi Visit | ${requiredDays || 2} days | Doesn't repeat`;
      }
      setNewAppointment(oldStateData);
      calculateMutiCost(oldStateData, index);
    }

    let msgIntervalType = 'days';
    if (interval_multiplier > 1) {
      msgIntervalType = 'days';
    } else {
      msgIntervalType = 'day';
    }
    if (interval_multiplier) {
      let newDate = new Date(occurenceMsg === 'On a Specific Date' ? specificDate : date);
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        if (interval > 1) {
          msgIntervalType = 'weeks';
        } else {
          msgIntervalType = 'week';
        }
        interval *= 7;
      } else if (interval_type === 'monthly') {
        if (interval > 1) {
          msgIntervalType = 'months';
        } else {
          msgIntervalType = 'month';
        }
        newDate = new Date(moment(newDate, 'DD-MM-YYYY').add((occurence - 1) * parseInt(interval, 10), 'months').format('L'));
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (occurenceMsg !== 'On a Specific Date' && interval_type !== 'monthly') {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date' ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}` : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
    }
  };

  const setMultiTeamOneday = (index, teammember, oldStateData, obj, startDate, createdOn, dateDataa) => {
    const {
      date, occurence, interval_multiplier, interval_type, endType, occurenceMsg, specificDate, requiredday, endsAt, appointmenttype,
    } = dateDataa;
    let addService = [];
    let nostep = parseInt(team, 10) || 2;
    let { length } = newAppointment;
    // console.log('index........', index, length, nostep);
    if (length === nostep) {
      for (let step = 0; step < nostep; step++) {
        oldStateData[step].date = moment(startDate).format(dateFormat);
        oldStateData[step].endsAt = moment(startDate).format(dateFormat);
      }
    } else if (length > nostep) {
      oldStateData.length = nostep;
      setNewAppointment(oldStateData);
      calculateMutiCost(oldStateData, null);
      setappointmentIndex(0);
    } else {
      for (let step = length; step < nostep; step++) {
        let dateDataCopy = {
          ...obj,
          date: moment(startDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(startDate).format(dateFormat),
          teamnotes: null,
          teammember: teammember || 2,
          requiredday: null,
          repeatAppt: false,
          msg: 'Additional Team Member | Day Visit',
          service: addService,
        };
        oldStateData[step] = dateDataCopy;
      }
      for (let step = 0; step < nostep; step++) {
        oldStateData[step].date = moment(startDate).format(dateFormat);
        oldStateData[step].endsAt = moment(startDate).format(dateFormat);
      }
    }
    setNewAppointment(oldStateData);
    calculateMutiCost(oldStateData, appointmentIndex);
    let msgIntervalType = 'days';
    if (interval_multiplier) {
      let newDate = new Date(occurenceMsg === 'On a Specific Date' ? specificDate : date);
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        if (interval > 1) {
          msgIntervalType = 'weeks';
        } else {
          msgIntervalType = 'week';
        }
        interval *= 7;
      } else if (interval_type === 'monthly') {
        if (interval > 1) {
          msgIntervalType = 'months';
        } else {
          msgIntervalType = 'month';
        }
        newDate = new Date(moment(newDate, 'DD-MM-YYYY').add((occurence - 1) * parseInt(interval, 10), 'months').format('L'));
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (occurenceMsg !== 'On a Specific Date' && interval_type !== 'monthly') {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date' ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}` : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
    }
  };

  const setMultiTeamMultiday = (index, teammember, requiredday, oldStateData, obj, startDate, createdOn, dateDataa) => {
    const {
      date, occurence, interval_multiplier, interval_type, endType, occurenceMsg, specificDate, endsAt, appointmenttype,
    } = dateDataa;
    let addService = [];
    let nextDate = startDate;
    let flag = 1;
    const t = parseInt(team, 10);
    const rd = parseInt(requiredDays, 10);
    const len = t * rd;
    const { length } = oldStateData;
    console.log({
      t, rd, length, len,
    });
    if (len === length) {
      const indexA = [];
      const indexDate = oldStateData[index].date;
      for (var j = 0; j < oldStateData.length; j++) {
        if (new Date(indexDate).toLocaleString() === new Date(oldStateData[j].date).toLocaleString()) indexA.push(j);
      }
      for (let i = 0; i < indexA.length; i++) {
        oldStateData[indexA[i]].date = moment(startDate).format(dateFormat);
        oldStateData[indexA[i]].endsAt = moment(startDate).format(dateFormat);
      }
    } else if (length === 1) {
      for (let i = length; i < len; i++) {
        if (i !== 0) {
          if (i % t === 0) {
            nextDate.setDate(nextDate.getDate() + 1);
            flag++;
          }
        }
        let dateDataCopy = {
          ...obj,
          date: moment(nextDate).format(dateFormat),
          createdOn: moment(createdOn).format(dateFormat),
          endsAt: moment(nextDate).format(dateFormat),
          teamnotes: null,
          teammember: teammember || 2,
          requiredday: requiredday || 2,
          repeatAppt: false,
          msg: 'Additional Team Member | MultiVisit',
          service: addService,
        };
        oldStateData[i] = dateDataCopy;
      }
    } else if (length < len) {
      if (rd + length === len) {
        console.log('increase members');
        const mergeTeamMember = mergeTeamMembers(nextDate, oldStateData, createdOn, obj, rd);
        oldStateData = mergeTeamMember;
      } else {
        console.log('increase required day');
        nextDate = new Date(oldStateData[oldStateData.length - 1].date);
        for (let i = length; i < len; i++) {
          if (i !== 0) {
            if (i % t === 0) {
              nextDate.setDate(nextDate.getDate() + 1);
              flag++;
            }
          }
          let dateDataCopy = {
            ...obj,
            date: moment(nextDate).format(dateFormat),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: moment(nextDate).format(dateFormat),
            teamnotes: null,
            teammember: teammember || 2,
            requiredday: requiredday || 2,
            repeatAppt: false,
            msg: 'Additional Team Member | MultiVisit',
            service: addService,
          };
          oldStateData[i] = dateDataCopy;
        }
      }
    }
    setNewAppointment(oldStateData);
    calculateMutiCost(oldStateData, appointmentIndex);
    let msgIntervalType = 'days';
    if (interval_multiplier) {
      let newDate = new Date(occurenceMsg === 'On a Specific Date' ? specificDate : date);
      newDate.setHours(12, 0, 0, 0);
      let interval = interval_multiplier;
      if (interval_type === 'weekly') {
        interval *= 7;
        msgIntervalType = 'weeks';
      } else if (interval_type === 'monthly') {
        // newDate.setDate(newDate.getDate() + daysToAdd);
        newDate = new Date(moment(newDate, 'DD-MM-YYYY').add((occurence - 1) * parseInt(interval, 10), 'months').format('L'));

        msgIntervalType = 'months';
      }
      if (endType === 'ongoing') {
        newDate.setFullYear(newDate.getFullYear() + 1);
        newDate.setDate(newDate.getDate() - 1);
      } else if (occurenceMsg !== 'On a Specific Date' && interval_type !== 'monthly') {
        const daysToAdd = (occurence - 1) * interval;
        // console.log('daysToAdd', daysToAdd, occurence, interval);
        newDate.setDate(newDate.getDate() + daysToAdd);
        // console.log('date....', newDate, 'old date', date);
      }
      // console.log('newDat.....e', newDate);
      const msg = endType === 'ongoing'
        ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ongoing`
        : occurenceMsg !== 'On a Specific Date' ? `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends after ${occurence} times on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}` : `Repeats every ${interval_multiplier} ${msgIntervalType} | Ends on ${moment(newDate).format('dddd')}, ${moment(newDate).format('D MMMM YYYY')}`;
      let dateDataCopy = {
        ...dateDataa,
        endsAt: newDate,
        msg,
        occurenceMsg,
        occurence,
      };
      oldStateData[index] = dateDataCopy;
      setNewAppointment(oldStateData);
    }
  };

  const mergeTeamMembers = (nextDate, oldStateData, createdOn, obj, rd) => {
    const arr = [];
    let mergeArray = [];
    for (let f = 0; f < rd; f++) {
      nextDate.setDate(nextDate.getDate() + f);
      let dateDataCopy = {
        ...obj,
        date: moment(nextDate).format(dateFormat),
        createdOn: moment(createdOn).format(dateFormat),
        endsAt: moment(nextDate).format(dateFormat),
        teamnotes: null,
        teammember: team || 2,
        requiredday: rd || 2,
        repeatAppt: false,
        msg: 'Additional Team Member | MultiVisit',
        service: [],
      };
      arr.push(dateDataCopy);
    }
    const groupDate = (key) => (result, current) => {
      const item = { ...current };
      if (typeof result[current[key]] === 'undefined') {
        result[current[key]] = [item];
      } else {
        result[current[key]].push(item);
      }
      return result;
    };
    const timespan = oldStateData.reduce(groupDate('date'), {});
    Object.entries(timespan).forEach((item) => {
      const findDate = arr.find((el) => new Date(el.date).toLocaleString() === new Date(item[0]).toLocaleString());
      item[1].push(findDate);
      mergeArray = [...mergeArray, item[1]];
    });
    var merged = [].concat([], ...mergeArray);
    return merged;
  };

  const handleAddClient = (client) => {
    if (client.siteAddresses[activeAdd] || client.siteAddresses.length === 0) {
      const data = client.siteAddresses.filter((elem) => !elem.hide)[activeAdd];
      let { activeSiteAdd } = data || {};
      if (activeSiteAdd || client.siteAddresses.length === 0) {
        setAddedClients([client]);
        handleShowSearch();
        setBookingData({ ...bookingData, clientId: client._id });
        setClientTxt('');
      } else {
        toastMessage('error', 'Please select site address.');
      }
    } else {
      toastMessage('error', 'Please select site address.');
    }
  };

  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === 'staffName') {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj.staffId = staffId;
      setStaff(value, staffId, appointmentType);
    }
    if (name === 'cost' && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === 'startTime') {
      obj.timeChange = true;
    } else if (name === 'duration') {
      obj.durationChange = true;
    } else if (name === 'quantity') {
      obj.quantityChange = true;
    } else if (name === 'pricingType') {
      obj.duration = bookingData.previousDuration;
      obj.quantity = '5';
      if (bookingData.customCost !== 'default') {
        obj.cost = '';
      }
    } else if (name === 'soldPer' && value !== 'Add Custom Measure') {
      obj.unit = '';
      obj.symbol = '';
    }
    setBookingData(obj);
  };

  const addSubcategoryToService = (value) => {
    let kquantity = '1';
    const selectedCategory = categories.filter(
      (el) => el.subCategory.filter((sub) => sub.name === value).length,
    );
    const subcategoryData = selectedCategory[0].subCategory.filter(
      (el) => el.name === value,
    );
    const { type, category } = selectedCategory[0];
    const {
      duration, cost, soldPer, unit, symbol, serviceDescription, tax, xerocode,
      s_uId,
      categoryId,
      supplier,
    } = subcategoryData[0] || {};

    let customCostData = 'default';
    let newCost = addZero(
      subcategoryData[0][type === 'product' ? 'retailCost' : 'cost'],
    );
    let newBuyCost = type === 'product' ? cost : '';

    if (selectedCategory[0].category.toLowerCase().includes('misc')) {
      setMisc(true);
      customCostData = type === 'product' ? 'buySell' : 'yes';
      newCost = '';
      newBuyCost = '';
    }
    let soldPerValue = soldPer || '';
    if (type === 'product') {
      if (soldPer === 'Add Custom Measure') {
        soldPerValue = `${unit}(${symbol})`;
      }
    }
    if (value.toLowerCase().includes('misc')) {
      if (type === 'product') {
        kquantity = '';
        bookingData.quantity = kquantity;
      }
      // setBookingData({ ...bookingData, quantity: '' });
    }
    setBookingData({
      ...bookingData,
      category,
      subcategory: value,
      cost: newCost,
      duration,
      type,
      quantity: kquantity,
      buyCostProduct: newBuyCost,
      customCost: customCostData,
      timeChange: false,
      customMisc: '',
      previousDuration: '',
      previousQuantity: '',
      pricingType: 'fixedCost',
      previousCost: newCost,
      prevBuyCostProduct: newBuyCost,
      soldPer: soldPerValue,
      description: serviceDescription || value,
      tax,
      xerocode,
      s_uId,
      categoryId,
      supplier,
    });
    setSearchTxt(value);
    setSubcategories(null);
  };

  const getCategories = async () => {
    try {
      console.log('hello');
      const response = await userInstance().get('/service/getServices');
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      if (staffData) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const getTimes = () => g();

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: '12:25 AM',
      closeTime: '23:30 PM',
    };

    // Format the time
    const startTime = moment(x.openTime, 'h:mm A');

    // Format the end time and the next day to it
    const endTime = moment(x.closeTime, 'h:mm A');

    // Times
    const allTimes = [];

    // Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      // Push times
      allTimes.push(startTime.format('h:mm A'));
      // Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    return allTimes;
  };

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg = hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };
  // const convertToReadableHour = (data, type) => {
  //   let msg = '';
  //   if (type === 'service') {
  //     const hours = Math.floor(data / 60);
  //     msg = hours ? `${hours}` : `${0}`;
  //     return msg;
  //   }

  //   msg = data;
  //   return msg;
  // };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get('/staff/getStaff');
      const { staff } = response.data;
      setStaffList(staff);
      return {
        staffName: `${staff[0].firstname} ${staff[0].lastname}`,
        staffId: staff[0]._id,
      };
    } catch (error) {
      console.log(error);
      //   console.log(error);
      return null;
    }
  };

  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };

  const convertHours = (mins) => {
    const hour = Math.floor(mins / 60);
    var mins = mins % 60;
    const converted = `${pad(hour, 2)}:${pad(mins, 2)}`;
    return converted;
  };

  const calculate_time_slot = (start_time, end_time, interval = '30') => {
    var i;
    let formatted_time;
    const time_slots = new Array();
    for (var i = start_time; i <= end_time; i += interval) {
      formatted_time = convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  };

  const getHourWithQuantity = (duration, multiplier) => {
    // console.log('multiplier', multiplier);
    let hourPart = duration.includes('hr')
      ? parseFloat(duration.split('hr')[0].trim())
      : 0;
    let minutePart = duration.includes('hr') && duration.includes('min')
      ? parseFloat(duration.split('hr')[1].split('min')[0])
      : duration.includes('min')
        ? parseFloat(duration.split('min')[0])
        : 0;

    let hourWithQuantity = hourPart * multiplier;
    // console.log('hourWithQuantity', hourWithQuantity);
    return { hourWithQuantity, hourPart, minutePart };
  };

  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration = bookingDataCopy.pricingType === 'hourly'
      ? bookingDataCopy.quantity
      : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat('1')),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };

  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    // console.log('updatedDuration', updatedDuration);
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split('.')[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration = parseFloat(hourWithQuantity.toString().split('.')[0]) * 60
      + updatedMinute
      + minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return finalDuration.toString();
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    // console.log('booking data copy', bookingDataCopy);
    // console.log('lenght', bookingData
    // .length);
    const {
      cost, buyCostProduct, quantity, type, renderCost,
    } = bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;
    // console.log('type', type);

    if (type === 'product') {
      totalBuy += parseFloat(removeComma(buyCostProduct));
      totalSellPrice += parseFloat(removeComma(renderCost));
      // console.log('totalSellPrice', totalSellPrice, 'total buy', totalBuy);
      const totalLabourProduct = (
        totalSellPrice
        - totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === 'service') {
      totalLabour = bookingDataCopy.pricingType === 'hourly'
        ? calculateServiceLabour(bookingDataCopy)
        : cost;
    }
    return { totalLabour, laborMargin };
  };

  const renderCostCalculation = (data) => {
    let quot = data / 5;
    // console.log('quot', quot);
    let str = quot.toString();
    let splitData = str.split('.');
    // console.log('splitData', splitData);
    let value;
    if (splitData.length > 1 && splitData[1] !== '' && splitData[1] !== '0') {
      value = parseFloat(splitData[0]) + 1;
    } else {
      value = quot;
    }
    let result = value * 5;

    // console.log('ddddddddddd', result);
    return result;
  };

  const validateService = () => {
    let isValid = true;
    let msg = '';
    let errValue = '';
    const {
      category, subcategory, staffName, type, customMisc, quantity, pricingType,
    } = bookingData;
    let { cost, buyCostProduct } = bookingData;

    cost = cost.replace(/,/g, '').replace('$', '');
    buyCostProduct = buyCostProduct.replace(/,/g, '').replace('$', '');
    // console.log('cost', cost, 'buy cost product', buyCostProduct);

    if (!category) {
      isValid = false;
      msg = 'Please select service or product';
      errValue = 'category';
    } else if (!subcategory) {
      isValid = false;
      msg = 'Please select subcategory';
      errValue = 'subcategory';
    } else if (!staffName) {
      isValid = false;
      msg = 'Please select staff';
      errValue = 'staffName';
    } else if (
      type === 'product'
      && parseFloat(cost) < parseFloat(buyCostProduct)
    ) {
      isValid = false;
      msg = 'Sell price must be greater than buy cost';
      errValue = 'sell price';
    } else if (!cost && pricingType !== 'addNote') {
      isValid = false;
      msg = 'Enter Sell Price (ex tax)';
      errValue = 'sell price';
    } else if (!buyCostProduct && type === 'product') {
      isValid = false;
      msg = 'Enter Buy Price (ex tax)';
      errValue = 'buyCostProduct';
    } else if (
      bookingData.category.toLowerCase().includes('misc')
      && !customMisc
    ) {
      isValid = false;
      msg = 'Enter New Name';
      errValue = 'customMisc';
    } else if (!quantity) {
      isValid = false;
      msg = 'Enter quantity';
      errValue = 'quantity';
    }
    return { isValid, msg, errValue };
  };
  function groupBy(collection, property) {
    let i = 0;
    let val;
    let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const handleAddService = async () => {
    const {
      subcategory, type, duration, previousDuration, previousQuantity, pricingType, customMisc, description,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;
    let oldStateCopy = [...newAppointment];
    quantity = quantity.replace(/,/g, '');
    cost = parseFloat((cost || '0').replace(/,/g, '').replace('$', '')).toFixed(2);
    console.log('buyCostProduct =>', buyCostProduct);
    buyCostProduct = buyCostProduct ? parseFloat((buyCostProduct || 0)?.replace(/,/g, '').replace('$', '')).toFixed(2) : 0;
    // console.log('parseFloat(cost)', parseFloat(cost), 'parseFloat(quantity)', parseFloat(quantity));
    renderCost = type === 'service' && pricingType === 'hourly'
      ? ((parseFloat(cost) * parseFloat(quantity)) / 60)
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    // const output = renderCostCalculation(15.00);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = ((renderCost / quantity).toFixed(2)).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      let exData = oldStateCopy[appointmentIndex];
      let exServices = oldStateCopy[appointmentIndex].service;
      let startTime = setDefaultTime; // changes by sanjana '9:30';
      let start = moment(exData.date).toDate();
      let time = bookingData.startTime.split(':');
      const bookingDataCopy = { ...bookingData };

      const lastService = exServices[exServices.length - 1];
      // console.log('lastService', lastService);
      if (lastService) {
        start = moment(lastService.end).toDate();
        time = moment(lastService.end).format(timeFromDateFormat).split(':');
      }
      // console.log('time', time);
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      // console.log('start', start);

      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { totalLabour, laborMargin } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;
      let durationInMin = duration;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
      }

      if (pricingType === 'addNote') {
        durationInMin = '0';
      }

      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      bookingDataCopy.duration = durationInMin;
      bookingDataCopy.start = moment(start).format(dateFormat);
      bookingDataCopy.end = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(bookingDataCopy.duration))),
      ).format(dateFormat);

      startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);
      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00' ? addZero(renderCost) : addZero(renderCost),
      );

      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        bookingDataCopy.previousQuantity = quantity;
      }

      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }

      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? removeComma(next.renderCost) : 0), 0);
        const totalduration = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0), 0);
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      let oldServices = [...newAppointment[appointmentIndex].service];
      oldServices.push(bookingDataCopy);
      oldStateCopy[appointmentIndex].service = oldServices;
      setNewAppointment(oldStateCopy);
      calculateMutiCost(oldStateCopy, appointmentIndex);
      handleShowService(appointmentIndex);
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      if (appointmentType === 'multiteam_oneday' || appointmentType === 'multiteam_multiday') {
        const findArray = mutiStaff.find((el) => el.index === appointmentIndex);
        if (!findArray) {
          mutiStaff.push({
            staffId: bookingDataCopy.staffId,
            index: appointmentIndex,
            staffName: bookingDataCopy.staffName,
            date: oldStateCopy[appointmentIndex].date,
          });
          setMutiStaff(mutiStaff);
        }
      }
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;

    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(buyCostProduct.replace(/,/g, '').replace('$', '')).toFixed(2);

    renderCost = type === 'service' && pricingType === 'hourly'
      ? ((parseFloat(cost) * parseFloat(quantity)) / 60)
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = ((renderCost / quantity).toFixed(2)).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };
      console.log('startr', bookingData);
      const startDate = moment(start).toDate();
      const time = startTime.split(':');

      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00' ? addZero(renderCost) : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;

      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }

      startDate.setHours(time[0]);
      startDate.setMinutes(time[1]);
      startDate.setSeconds(0);

      bookingDataCopy.start = moment(startDate).format(dateFormat);

      const { totalLabour, laborMargin } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = 0;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (previousQuantity !== quantity || previousDuration !== duration) {
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }
      bookingDataCopy.end = moment(
        new Date(startDate.setMinutes(startDate.getMinutes() + parseFloat(bookingDataCopy.duration))),
      ).format(dateFormat);

      console.log('addedServices', addedServices, bookingDataCopy);
      let oldServices = [...newAppointment[appointmentIndex].service];
      const currentServices = [...oldServices];
      currentServices[editData.index] = bookingDataCopy;
      console.log('currentServices', currentServices, oldServices);
      finalServices = currentServices;
      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? removeComma(next.renderCost) : 0), 0);
        const totalduration = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0), 0);
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      calculateNewDurations(bookingDataCopy);
      bookingDataCopy.timeChange = false;
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      setShowService(!showService);
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };
  const calculateDurations = (services) => {
    services.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(':');
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
      ).format(dateFormat);
    });
    setServices([...services]);
  };

  const calculateSummary = (finalServices) => {
    let totalDuration = 0;
    let totalCost = 0;
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(removeComma(el.renderCost));
      laborMargin += parseFloat(removeComma(el.laborMargin)) + parseFloat(removeComma(el.totalLabour));
    });
    const totalLabour = laborMargin;
    setSummary({
      ...summary,
      totalCost: addZero(parseFloat(removeComma(totalCost)).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(removeComma(totalLabour)).toFixed(2)),
      multiTotal: totalCost,
    });
  };

  const handleEditService = ({
    service, index, subIndex, appIndex,
  }) => {
    if (appointmentType === 'multiteam_oneday') {
      let leftStaffs = [];
      const findIndex = mutiStaff.filter((el) => el.index !== appIndex);
      if (findIndex.length > 0) {
        findIndex.forEach((el) => {
          leftStaffs.push(el.staffId);
        });
        const filterProList = filterArray(staffList, leftStaffs);
        setStaffList(filterProList);
      } else {
        getStaffList();
      }
    }
    setStaffIndex(index);
    setappointmentIndex(appIndex);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    const updateObj = { ...bookingData, ...service };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index });
    if (parseFloat(service.duration) > 960) {
      setExtras({
        ...extras,
        value: service.duration,
        data: convertToReadableDuration(service.duration),
      });
    }
    const subcategory = service.subcategory.replace(
      service.subcategory.split('$')[1],
      service.renderCost.split('.')[1] === '00' ? addZero(service.renderCost) : addZero(service.cost),
    );
    setSearchTxt(subcategory);
  };

  const handleDeleteService = () => {
    const oldState = [...newAppointment];
    if (appointmentType === 'oneteam_multiday' || appointmentType === 'oneteam_oneday') {
      const currentServices = oldState[appointmentIndex].service;
      currentServices.splice(editData.index, 1);
      oldState[appointmentIndex].service = currentServices;
      calculateMutiCost(oldState, appointmentIndex);
      setNewAppointment(oldState);
    } else if (appointmentType === 'multiteam_oneday' || appointmentType === 'multiteam_multiday') {
      const currentServices = oldState[appointmentIndex].service;
      currentServices.splice(editData.index, 1);
      oldState[appointmentIndex].service = currentServices;
      calculateMutiCost(oldState, appointmentIndex);
      setNewAppointment(oldState);
      const oldMulti = [...mutiStaff];
      if (currentServices.length === 0) {
        const findIndex = oldMulti.findIndex((el) => el.index === appointmentIndex);
        oldMulti.splice(findIndex, 1);
        setMutiStaff(oldMulti);
      }
    }
    setShowService(!showService);
  };

  const handleSearchCategory = async (searchTxt) => {
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt.replace('$', '').trim()}&industryId=${industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((service) => {
            const reg = new RegExp(`${searchTxt.replace('$', '').trim()}`, 'i');
            const matchedSubcategories = service.subCategory.filter(
              (el) => reg.test(el.cost)
                || reg.test(el.name)
                || reg.test(el.retailCost)
                || reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const CrossDate = () => {
    setShowDate(!showDate);
  };

  const SetRDays = (info) => {
    setRequiredDays(info);
  };

  const SetRteams = (info) => {
    setTeam(info);
  };

  const duplicateElements = (data) => {
    const arr = [];
    let count = 1;
    let j = 0;
    for (let i = 0; i < data.length; i++) {
      const findArr = arr.find((el) => el.date === data[i].date);
      if (!findArr) {
        arr.push({ date: data[i].date, count });
        j++;
      } else {
        arr[j - 1].count = arr[j - 1].count + 1;
      }
    }
    const same = (array) => {
      var first = array[0].count;
      return array.every((element) => element.count === first);
    };
    const check = same(arr);
    return check;
  };

  const duplicateElementsTwo = (data) => {
    const arr = [];
    let count = 1;
    let j = 0;
    for (let i = 0; i < data.length; i++) {
      const findArr = arr.find((el) => el.date === data[i].date);
      if (!findArr) {
        arr.push({ date: data[i].date, count });
        j++;
      } else {
        arr[j - 1].count = arr[j - 1].count + 1;
      }
    }
    const same = (array) => {
      var first = new Date(array[0].date).toLocaleDateString();
      return array.every((element) => new Date(element.date).toLocaleDateString() === first);
    };
    const check = same(arr);
    return check;
  };
  const checkMultiDateBox = (arrr) => {
    const duplicates = arrr.reduce((acc, el, i, arr) => {
      if (arr.indexOf(el.date) !== i && acc.indexOf(el.date) < 0) acc.push(el.date); return acc;
    }, []);
    const data = duplicates;
    return data;
  };

  const handleEditAppointment = async (confirmType) => {
    try {
      let validateServices;
      let isBoxValid = true;
      let valMessage = '';
      let isValid = true;
      let val = '';
      const old = [...newAppointment];
      let firstServiceTime = '';
      if (old[0].service.length > 0) {
        firstServiceTime = getFirstServiceTime(old[0].date, old[0].service[0].startTime);
      }
      let evryLength = old.every((el) => el.service.filter((elem) => elem.pricingType !== 'addNote')?.length > 0);

      validateServices = evryLength;
      if (old.length === 1) {
        isBoxValid = false;
        if (appointmentType === 'oneteam_multiday') {
          valMessage = 'Atleast 2 days are required';
        } else if (appointmentType === 'multiteam_oneday') {
          valMessage = 'Atleast 2 members are required';
        } else if (appointmentType === 'multiteam_multiday') {
          valMessage = 'Atleast 2 members and 2 days are required';
        } else {
          isBoxValid = true;
        }
      } else if (old.length > 1 && appointmentType === 'multiteam_multiday') {
        if (checkMultiDateBox(old).length === 1) {
          valMessage = 'Atleast 2 days are required';
          isBoxValid = false;
        } else if (!duplicateElements(old)) {
          valMessage = 'Same Members are required';
          isBoxValid = false;
        } else {
          isBoxValid = true;
        }
      } else if (old.length > 1 && appointmentType === 'oneteam_multiday') {
        if (duplicateElementsTwo(old)) {
          valMessage = 'Same Dates not allowed in oneteam multivisit';
          isBoxValid = false;
        } else {
          isBoxValid = true;
        }
      } else {
        isBoxValid = true;
        valMessage = '';
      }
      if (!addedClients.length) {
        isValid = false;
        val = 'clients';
      } else if (!validateServices) {
        isValid = false;
        val = 'services';
      }
      if (isValid) {
        if (isBoxValid) {
          let {
            firstname,
            lastname,
            streetAddress,
            suburb,
            state,
            _id,
            siteAddresses,
            mobileNo,
            note,
          } = addedClients[0] ? addedClients[0] : {};
          let cl = [];
          if (firstname === undefined) {
            cl = addedClients;
          } else {
            cl = [
              {
                name: `${firstname} ${lastname}`,
                address: `${streetAddress}${suburb || state ? ' ,' : ''
                } ${suburb} ${state}`,
                _id,
                siteAddresses: siteAddresses ? siteAddresses[activeAdd] : '',
                mobileNo,
                note,
              },
            ];
          }
          newAppointment.forEach((el) => {
            const startDat = moment(el.date).format('YYYY-MM-DD');
            el.date = new Date(startDat).toISOString();
          });
          const { repeats } = JSON.parse(localStorage.getItem('currentapp')) || {};
          // console.log('check payload in edit', {
          //   Id: idd,
          //   staffId: localStorage.getItem('staff_id'),
          //   currentDate: new Date(moment(currentDates).format('YYYY-MM-DD')).toISOString(),
          //   type: confirmType,
          //   activeRepeatIndex,
          //   services: addedServices,
          //   requiredday: requiredDays,
          //   teammember: team,
          //   client: _id,
          //   clientSiteAddressIndex: activeAdd,
          //   notes,
          //   dateData: newAppointment,
          //   appointmenttype: appointmentType,
          //   pid,
          //   repeats,
          //   repeatsSize: repeats.length,
          //   oldObjectData: JSON.parse(localStorage.getItem('currentapp')),
          //   // matchFrequency,
          // //   services: addedServices,
          // //   client: cl,
          // //   notes,
          // // dateData: newAppointment,
          // //   appointmenttype: appointmentType,
          // //   requiredday: requiredDays,
          // //   teammember: team,
          // //   confirmType,
          // //   activeRepeatIndex,
          // });

          const { matchtype, matchFrequency } = matchDataObject(cl, newAppointment, appointmentType);
          console.log('matchtype', matchtype, calcType);
          // console.log('services', addedServices, old[0].service[0].start);
          let editServiceTime = old[0]?.service[0]?.start;
          if (matchtype) {
            // console.log('coming', addedServices);
            // editServiceTime = addedServices[0].start;
            const response = await userInstance().post('/appointment/editNewAppointment', {
              Id: idd,
              staffId: localStorage.getItem('staff_id'),
              currentDate: new Date(moment(currentDates).format('YYYY-MM-DD')).toISOString(),
              type: confirmType,
              activeRepeatIndex,
              services: addedServices,
              requiredday: requiredDays,
              teammember: team,
              client: _id,
              clientSiteAddressIndex: activeAdd,
              notes,
              dateData: newAppointment,
              appointmenttype: appointmentType,
              pid,
              repeats,
              repeatsSize: repeats.length,
              oldObjectData: JSON.parse(localStorage.getItem('currentapp')),
              matchFrequency,
              appointment_calc_type: calcType,
              industryId: industryId?._id,
            });
          }
          toastMessage('success', 'Appointment updated successfully');

          handleAppointmentDetails(false, 'edit');
          handleCloseEdit(false);
          setClients([]);
          setServices([]);
          setBookingData(initialBookingData);
          setNotes('');
          setAddedClients([]);
          setSummary({ totalCost: 0, totalDuration: 0 });
          setDateData(initialDateData);
          setMultiNotes(multiNotesState);
          setmultiAppointment(false);
          setMultiServices([]);
          setActiveAdd('');
          localStorage.removeItem('currentapp');
          SelectedStaff(localStorage.getItem('staff_id'), 'editttt');
          setEventDate(firstServiceTime);
        } else {
          toastMessage('error', `${valMessage}.`);
        }
      } else {
        toastMessage('error', `Please add ${val}.`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const calculateNewDurations = (data) => {
    let multiArray = [];
    const oldState = [...newAppointment];
    oldState[appointmentIndex].service[editData.index] = data;
    multiArray = oldState[appointmentIndex].service;
    const remainingServices = multiArray.filter((_el, i) => i > editData.index);
    const oldServices = multiArray.filter((_el, i) => i <= editData.index);

    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.end);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(new Date(end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)))).format(
        dateFormat,
      );
    }
    oldState[appointmentIndex].service = [...oldServices, ...remainingServices];
    calculateMutiCost(oldState, appointmentIndex);
    setNewAppointment(oldState);
  };

  const recalculateServices = (data) => {
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    const previousServices = addedServices.filter(
      (_el, i) => i < editData.index,
    );
    // console.log('remainingServices', remainingServices);
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    const arr = previousServices;
    const newArray = arr.concat(remainingServices);
    setServices([...newArray]);
  };
  const handleAppointmentType = (type) => {
    setmultiAppointment(false);
    setAppointmentType(type);
    const old = [...newAppointment];
    old[0].repeatAppt = false;
    old[0].occurence = null;
    old[0].frequencyMsg = null;
    old[0].interval_type = null;
    old[0].msg = "One-off appointment | Doesn't repeat";
    old[0].endType = null;
    old[0].occurenceMsg = null;
    old[0].interval_multiplier = null;
    setNewAppointment([old[0]]);
    calculateMutiCost([old[0]], 0);
    setappointmentIndex(0);
  };
  const handleChangeNotes = (name, value, index) => {
    let val = [...multiNotes];
    val[index][name] = value;
    setMultiNotes(val);
  };

  const checkAddress = (index, id) => {
    setActiveAdd(index);
    let oldState = [...searchedClients];
    oldState.forEach((el) => {
      if (el._id === id) {
        el.siteAddresses?.forEach((el2, i) => {
          if (index === i) {
            console.log('index', index, i);
            el2.activeSiteAdd = !el2.activeSiteAdd;
          } else {
            el2.activeSiteAdd = false;
          }
        });
      } else {
        el.siteAddresses.forEach((el2, i) => {
          el2.activeSiteAdd = false;
        });
      }
    });
    setClients(oldState);
  };

  useEffect(() => {
    getCategories();
    getAppointmentById();
  }, []);

  const calculateMutiCost = (item, index) => {
    const totalCost = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next.renderCost ? removeComma(next.renderCost) : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    const totalLabourAmount = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next.totalLabour ? removeComma(next.totalLabour) : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);

    const totalMargin = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next.laborMargin ? removeComma(next.laborMargin) : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    const totalLabour = totalLabourAmount + totalMargin;

    const totalDuration = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next.duration ? removeComma(next.duration) : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    if (index !== null) {
      const subSummary = item[index].service;
      let obj = {
        totalCost: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
        totalDuration: TotalPartduration(subSummary),
        totalLabour: addZero(parseFloat(TotalPartLabour(subSummary)).toFixed(2)),
        multiTotal: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
        totalMargin: parseFloat(totalMargin).toFixed(2),
        totalLabourOnly: parseFloat(totalLabourAmount).toFixed(2),
      };
      item[index].summary = obj;
      setNewAppointment(item);
    }
    setMutiSummary({
      ...mutiSummary,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      multiTotal: totalCost,
      totalLabourMargin: addZero(parseFloat(totalMargin).toFixed(2)),
      totalLabourAmount: addZero(parseFloat(totalLabourAmount).toFixed(2)),

    });
  };

  const handleEditConfirmPopupe = () => {
    const repeatsFilterArray = oldObjectData.repeats.filter((el) => el.status !== 'Cancelled');
    const repeatsLength = repeatsFilterArray.length - 1;
    // console.log('activeRepeatIndex', activeRepeatIndex, repeatsLength, oldObjectData);
    if (activeRepeatIndex !== repeatsLength) {
      setOpenConfirmBox(true);
    } else {
      handleEditAppointment('current');
    }
  };

  const handleRenderSort = (index5) => {
    const old = [...newAppointment];
    if (old[index5].renderSort) {
      old[index5].renderSort = false;
    } else {
      old[index5].renderSort = true;
    }
    setNewAppointment(old);
  };

  // for calculate index

  const calculateIndex = (servicesCopy, oldIndex, sign, typeValue) => {
    let indexValue = -1;
    servicesCopy.forEach((elem, index) => {
      if ((typeValue ? elem.pricingType === 'addNote' : elem.pricingType !== 'addNote')) {
        if (indexValue === -1 && sign === 'plus' && index > oldIndex) {
          indexValue = index;
        } else if (sign === 'minus' && index < oldIndex) {
          indexValue = index;
        }
      }
    });
    return indexValue;
  };

  const moveArrr = (oldIndex, nnn, sign, appIndex, typeValue, upperIndex) => {
    const old = [...newAppointment];
    let servicesCopy = old[appIndex].service;
    let newIndex = calculateIndex(servicesCopy, oldIndex, sign, typeValue);
    const { duration, start, startTime } = servicesCopy[newIndex];

    let newNextStartTime;
    let currentNewStartTime;

    if (sign === 'plus') {
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(currentEntryStart.setMinutes(currentEntryStart.getMinutes() + parseFloat(duration))),
      ).format(dateFormat);
      newNextStartTime = moment(updatedNextEntryStart).format(timeFromDateFormat);

      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === 'minus') {
      newNextStartTime = startTime;

      const updatedCurrentEntryStart = moment(
        new Date(new Date(start).setMinutes(new Date(start).getMinutes() + parseFloat(servicesCopy[oldIndex].duration))),
      ).format(dateFormat);

      currentNewStartTime = moment(updatedCurrentEntryStart).format(timeFromDateFormat);
    }

    const updatedOldIndex = {
      ...servicesCopy[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...servicesCopy[newIndex],
      startTime: currentNewStartTime,
    };
    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }

    let temp = servicesCopy[oldIndex];
    servicesCopy[oldIndex] = servicesCopy[newIndex];
    servicesCopy[newIndex] = temp;
    // servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    calculateMutiDurations(servicesCopy, appIndex, upperIndex);
    // setServices([...servicesCopy]);
  };

  const calculateMutiDurations = (services, appIndex) => {
    const old = [...newAppointment];
    services.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(':');
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration)))).format(dateFormat);
    });
    old[appIndex].service = services;
    setNewAppointment(old);
    // setServices([...services]);
  };

  const handleChangeNotesMulti = (name, value, index) => {
    let val = [...newAppointment];
    val[index][name] = value;
    setMultiNotes(val);
  };

  const TotalPartduration = (el) => {
    let totalDuration = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0), 0);
    return totalDuration;
  };
  const TotalPartLabour = (el) => {
    let totalLabourOnly = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.totalLabour ? removeComma(next.totalLabour) : 0), 0);
    let totalMargin = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.laborMargin ? removeComma(next.laborMargin) : 0), 0);
    let totalLabour = totalLabourOnly + totalMargin;
    return totalLabour;
  };

  const TotalPartVist = (el) => {
    let totalcost = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? removeComma(next.renderCost) : 0), 0);
    return totalcost;
  };
  const handleEditDetails = (data, data2) => {
    // console.log('data, data2', data, data2);
    const splitName = data2.split(' ');
    // window.location.href = `/client?startDateTime=${startDateTime}&employee_id=${employee_id}&clientid=${data}&name=${data2}`;
    // if (splitName.length > 0) {
    //   window.location.href = `/client?clientid=${data}&name=${splitName[0]}&typee=edit`;
    // } else {
    history.push(`/client?clientid=${data}&name=${data2}&typee=edit`);
    // }
  };
  // let initializeStar = '';
  // const showStar = () => {
  //   initializeStar += '*';
  //   return initializeStar;
  // };

  const getCounts = (items) => {
    let notesCount = 0;
    let serviceProductsCount = 0;
    items.forEach((item) => {
      if (item.pricingType === 'addNote') {
        notesCount++;
      } else {
        serviceProductsCount++;
      }
    });
    return { notesCount, serviceProductsCount };
  };

  return (
    <>
      <Modal
        size="md"
        show={editAppointment}
        onHide={handleCloseEdit}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="staff-modal appointment-details edit-appt"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            aria-hidden="true"
            onClick={handleCloseEdit}
          />
        </div>
        <Modal.Header>
          <Modal.Title>Edit Appointment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="staff-form">
            <div className="appointment-container">
              <div className="container-fluid">
                <div className="row">
                  <div className="add-appointment">
                    <div className="row">
                      <div className="col-md-4">
                        {!addedClients.length || !addedClients ? (
                          <div className="search-container">
                            <div className="search-title">
                              <h3> Client </h3>
                              <span
                                className="plus-icon"
                                onClick={handleShowSearch}
                                role="presentation"
                              >
                                <i className="fas fa-plus" />
                              </span>
                            </div>
                            <div className="no-customer">
                              <img src={search} alt={search} />
                              <p>
                                Use the search to add a customers, or keep empty
                                to save as walk-in.
                              </p>
                            </div>
                            <div className="footer-btn view-btn">
                              <Button className="appointment-btn btn-save">
                                {' '}
                                Save/Update
                                {' '}
                              </Button>
                            </div>
                          </div>
                        ) : null}
                        {addedClients && addedClients.map((client) => (
                          <div className="search-container">
                            <div className="search-title" key={client._id}>
                              <div className="client-profile">
                                {/* {editAppointment && client.firstname ? (
                                  <div className="">
                                    <h3>{`${client.firstname}`}</h3>
                                    <p>{`${client.address}`}</p>
                                    <p>{selectedSiteAddress(client.siteAddresses)}</p>
                                    <p>{client.mobileNo}</p>
                                  </div>
                                ) : ( */}
                                <div className="">
                                  <h3>{`${client?.firstname || ''} ${client?.lastname || ''}`}</h3>
                                  <p>
                                    {`${client?.streetAddress || ''}${client?.suburb || client?.state ? '' : ''
                                    } ${client?.suburb || ''} ${client?.state || ''}`}
                                  </p>
                                  {/* <p>{selectedSiteAddress(client.siteAddresses)}</p> */}
                                  <p>{client?.mobileNo || ''}</p>
                                </div>
                                {/* )} */}

                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    <img src={showmore} alt="show" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {/* <Dropdown.Item>
                                      <div className="action client">
                                        <a href={`tel:${client.mobileNo}`}>
                                          <i
                                            className="fa fa-phone"
                                            aria-hidden="true"
                                          />
                                          Call
                                          {client.mobileNo}
                                        </a>
                                      </div>
                                    </Dropdown.Item> */}
                                    <Dropdown.Item>
                                      <div
                                        className="action client"
                                        onClick={() => {
                                          handleEditDetails(client._id, client.firstname);
                                        }}
                                      >
                                        <img src={edit} alt="edit" />
                                        Edit Client Details
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <div
                                        className="action client"
                                        onClick={() => {
                                          handleShowSearch();
                                        }}
                                      >
                                        <img src={clientimg} alt="edit" />
                                        Change Client
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                      <div
                                        className="action"
                                        onClick={() => setAddedClients([])}
                                      >
                                        <img src={greydelete} alt="edit" />
                                        Delete
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>

                                {/* <Button onClick={() => setAddedClients([])} className="client-delete">
                                <img src={deleteimg} alt="delete-img" />
                              </Button> */}
                              </div>
                            </div>
                            <div className="client-profile">
                              <div className="client-name-contact site-addresses">
                                {/* {editAppointment && client.name ? (
                                  <div className="client-left-details">
                                    <h5 className="client-company-name">
                                      Site Address
                                    </h5>
                                    <p>
                                      {client.siteAddresses
                                        ? client.siteAddresses.householderName
                                        : ''}
                                    </p>
                                    <p>
                                      {client.siteAddresses
                                        ? client.siteAddresses.address
                                        : ''}
                                      {' '}
                                    </p>
                                    <p>
                                      <a
                                        className="mobile"
                                        href={`tel:${client.siteAddresses
                                          ? client.siteAddresses.mobileNo
                                          : '' || ''
                                        }`}
                                      >
                                        {`${client.siteAddresses
                                          ? client.siteAddresses.mobileNo
                                          : '' || ''
                                        }`}
                                      </a>
                                    </p>
                                  </div>
                                ) : ( */}
                                <>
                                  {client?.siteAddresses?.length > 0 ? (
                                    <div className="client-left-details">
                                      {client?.siteAddresses[activeAdd] && (
                                      <h5 className="client-company-name">
                                        Site Address
                                      </h5>
                                      )}
                                      <p>
                                        {client?.siteAddresses[activeAdd]?.householderName
                                          ? client.siteAddresses[activeAdd]?.householderName
                                          : ''}
                                      </p>
                                      <p>
                                        {client?.siteAddresses[activeAdd]?.address
                                          ? client?.siteAddresses[activeAdd]?.address
                                          : ''}
                                        {' '}
                                      </p>
                                      <p>
                                        <a
                                          className="mobile"
                                          href={`tel:${client.siteAddresses[activeAdd]?.mobileNo || ''
                                          }`}
                                        >
                                          {`${client.siteAddresses[activeAdd]?.mobileNo || ''
                                          }`}
                                        </a>
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="client-left-details" />
                                  )}
                                </>
                                {/* )} */}
                              </div>
                            </div>
                            <div className="appointyment-type">
                              <h5>Appointment Type</h5>
                              <div className="appointment-type-one" onClick={() => handleAppointmentType('oneteam_oneday')}>
                                <div className="team-member">
                                  <h6>One Team Member - Day Visit</h6>
                                  <p>
                                    (appointments that can be completed on a day)
                                  </p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'oneteam_oneday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType('oneteam_oneday')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="appointment-type-one" onClick={() => handleAppointmentType('oneteam_multiday')}>
                                <div className="team-member">
                                  <h6>One Team Member - Multi Visit</h6>
                                  <p>(requires more than one day to complete)</p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'oneteam_multiday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType('oneteam_multiday')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="appointment-type-one" onClick={() => handleAppointmentType('multiteam_oneday')}>
                                <div className="team-member">
                                  <h6>Additional Team Members - Day Visit</h6>
                                  <p>
                                    (requires multiple team members on a day
                                    visit)
                                  </p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'multiteam_oneday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType('multiteam_oneday')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="appointment-type-one"
                                onClick={() => handleAppointmentType(
                                  'multiteam_multiday',
                                )}
                              >
                                <div className="team-member">
                                  <h6>Additional Team Members - Multi Visit</h6>
                                  <p>
                                    (multiple team members & more than one day
                                    needed)
                                  </p>
                                </div>
                                <div className="open-date client-notes">
                                  <div className="basic">
                                    <Form.Check
                                      type="checkbox"
                                      checked={
                                        appointmentType
                                          && appointmentType === 'multiteam_multiday'
                                          ? true
                                          : ''
                                      }
                                      onClick={() => handleAppointmentType(
                                        'multiteam_multiday',
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p className="group-time">
                              <span className="require-icon">*</span>
                              Used to group time, materials & cost on to a single
                              invoice
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="col-md-8">
                        {/* {console.log(newAppointment, mutiStaff)} */}
                        <div className="appointment-view">
                          {/* {console.log('newAppo', newAppointment)} */}
                          {newAppointment && newAppointment.map((el4, index4) => {
                            let initializeStar = '';
                            let notesActualIndex = -1;
                            let servicesProductsIndex = -1;

                            const { notesCount, serviceProductsCount } = getCounts(el4.service);
                            return (
                              <div className="appointmentbox">
                                <div className="appointment-date" role="presentation" onClick={() => handleDateModal('', index4)}>
                                  {' '}
                                  <div className="">
                                    <h3>
                                      {`${moment(el4.date).format('dddd')}, ${moment(el4.date).format(
                                        'D MMMM YYYY',
                                      )}`}
                                    </h3>
                                    <span>{el4.msg}</span>
                                  </div>
                                  <div className="open-date">
                                    <img src={arrowwhite} alt="arrow" />
                                  </div>
                                </div>
                                <div className="search-title service-head">
                                  <h3>Services or Products</h3>
                                  <div className="sorting-services">
                                    {el4.service.length > 1 ? (
                                      <div className="sorting">
                                        <img src={sort} alt="sorting" onClick={() => handleRenderSort(index4)} />
                                      </div>
                                    ) : null}
                                    <span className="plus-icon" onClick={() => handleShowService(index4)} role="presentation">
                                      <i className="fas fa-plus" />
                                    </span>
                                  </div>
                                </div>
                                {el4.service.map((el, index) => {
                                  if (el.pricingType !== 'addNote') {
                                    servicesProductsIndex += 1;
                                  }
                                  return el.pricingType !== 'addNote' ? (
                                    <>
                                      {/* {el2.map((el, i) => ( */}
                                      <div className={`search-title ${el4.renderSort && 'add-sortings'}`}>
                                        <div
                                          className="client-profile"
                                          onClick={() => handleEditService({ service: el, index, appIndex: index4 })}
                                        >
                                          <h3>
                                            {`${el.category.toLowerCase().includes('misc')
                                              ? `${el.customMisc} $${addZero(el.renderCost)}`
                                              : el.subcategory
                                            } ${parseFloat(el.quantity) > 1
                                              ? `($${`${addZero(el.cost)}${el.soldPer === 'Kilogram (kg)' ? ' per kg' : ''}`} x ${`${el.type === 'service' && el.pricingType === 'hourly' ? convertToReadableDuration(el.quantity) : `${el.soldPer === 'Kilogram (kg)' ? `${el.quantity}kg` : el.quantity}`}${el.type === 'service' ? ' ' : ''
                                              })`}`
                                              : ''
                                            }`}
                                          </h3>
                                          <p>
                                            <span>{moment(el.startTime, [timeFromDateFormat]).format('h:mm a')}</span>
                                            <span> | </span>
                                            <span>{` ${convertToReadableDuration(el.duration)}`}</span>
                                            <span> | </span>
                                            <span>{` with ${el.staffName}`}</span>
                                            <span> | </span>
                                          </p>
                                          <p>
                                            <span>
                                              {`${el.type === 'product'
                                                ? `Margin $${addZero(el.laborMargin)}`
                                                : el.type === 'service'
                                                  ? `Labour $${`${addZero(el.totalLabour)}`}`
                                                  : null
                                              }`}
                                            </span>
                                          </p>
                                        </div>
                                        <div className="sorting-sub-services appointment-sort">
                                          <div className="service-edit-icon">
                                            {el4.renderSort ? (
                                              <div className={`sorting up-down ${el4.renderSort && 'active-sort'}`}>
                                                {servicesProductsIndex !== 0 ? (
                                                  <img src={upsort} alt="sorting" onClick={() => moveArrr(index, index - 1, 'minus', index4)} />
                                                ) : null}
                                                {servicesProductsIndex !== serviceProductsCount - 1 ? (
                                                  <img src={downsort} alt="sorting" onClick={() => moveArrr(index, index + 1, 'plus', index4)} />
                                                ) : null}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div
                                            className="content-dropdown"
                                            onClick={() => handleEditService({ service: el, index, appIndex: index4 })}
                                          >
                                            <Dropdown name="startingDate">
                                              <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                <img src={arrow} alt="arrow" />
                                              </Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item>Option 1</Dropdown.Item>
                                                <Dropdown.Item>Option 2</Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                      {/* ))} */}
                                    </>
                                  ) : null;
                                })}

                                {el4.service.findIndex((elem) => elem.pricingType === 'addNote') >= 0 ? (
                                  <div className="finalize-cost-page view-appointment-details pt-4">
                                    <div className="added-chemicals-info">

                                      <div className="search-title service-head ">
                                        <h3 className="">Notes</h3>
                                      </div>

                                      {el4.service.map((el, index) => {
                                        if (el.pricingType === 'addNote') {
                                          initializeStar += '*';
                                          notesActualIndex += 1;
                                        }
                                        return el.pricingType === 'addNote' ? (
                                          <div
                                            className={`search-title ${el4.renderSort && 'add-sortings'} pt-1 pb-1`}
                                          >
                                            <div
                                              className="client-profile"
                                              onClick={() => handleEditService({
                                                service: el,
                                                index,
                                                appIndex: index4,
                                              })}
                                            >
                                              <p>
                                                <span>
                                                  {initializeStar}
                                                  {' '}
                                                  {el?.customMisc}
                                                </span>
                                              </p>
                                            </div>
                                            <div className="sorting-sub-services appointment-sort">
                                              <div className="service-edit-icon">
                                                {el4.renderSort ? (
                                                  <div className={`sorting up-down ${el4.renderSort && 'active-sort'}`}>
                                                    {notesActualIndex !== 0 ? (
                                                      <img src={upsort} alt="sorting" onClick={() => moveArrr(index, index - 1, 'minus', index4, 'addNote')} />
                                                    ) : null}
                                                    {notesActualIndex !== notesCount - 1 ? (
                                                      <img src={downsort} alt="sorting" onClick={() => moveArrr(index, index + 1, 'plus', index4, 'addNote')} />
                                                    ) : null}
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div
                                                className="content-dropdown"
                                                onClick={() => handleEditService({ service: el, index, appIndex: index4 })}
                                              >
                                                <Dropdown name="startingDate">
                                                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                                                    <img src={arrow} alt="arrow" />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu>
                                                    <Dropdown.Item>Option 1</Dropdown.Item>
                                                    <Dropdown.Item>Option 2</Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null;
                                      })}
                                    </div>
                                  </div>
                                ) : ''}
                                {appointmentType === 'oneteam_oneday' && (
                                <>
                                  <div className="appointment-form">
                                    <Form>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Team Member Notes</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          placeholder="Only Visible to Team Members"
                                          // onChange={(e) => setNotes(e.target.value)}
                                          value={el4.teamnotes}
                                          onChange={(e) => handleChangeNotesMulti('teamnotes', e.target.value, index4)}
                                          name="notes"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                  <div className="appointment-total">
                                    <h6>{`Visit Total: $${mutiSummary.totalCost}`}</h6>
                                    <h6 className="duration-time">
                                      Duration:
                                      <span>{convertToReadableDuration(mutiSummary.totalDuration)}</span>
                                    </h6>
                                    <h6 className="duration-time">
                                      {((parseFloat(mutiSummary.totalLabourAmount) > 0 && parseFloat(mutiSummary.totalLabourMargin) > 0) || parseFloat(mutiSummary.totalLabour === 0)) ? 'Labour & Margin:' : (parseFloat(mutiSummary.totalLabourMargin) > 0) ? 'Margin:' : 'Labour'}
                                      <span>{`$${mutiSummary.totalLabour}`}</span>
                                      {/* Labour & Margin:
                                      <span>{`$${mutiSummary.totalLabour ? mutiSummary.totalLabour : 0}`}</span> */}
                                    </h6>
                                  </div>
                                </>
                                )}
                                {(appointmentType === 'oneteam_multiday' || appointmentType === 'multiteam_oneday' || appointmentType === 'multiteam_multiday')
                                && (
                                  <>
                                    <div className="appointment-form">
                                      <Form>
                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                          <Form.Label>Team Member Notes</Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            placeholder="Only Visible to Team Members"
                                            // onChange={(e) => setNotes(e.target.value)}
                                            value={el4.teamnotes}
                                            onChange={(e) => handleChangeNotesMulti('teamnotes', e.target.value, index4)}
                                            name="notes"
                                          />
                                        </Form.Group>
                                      </Form>
                                    </div>
                                    <div className="appointment-total">
                                      <h6>{`Visit Part-${index4 + 1} (of ${newAppointment.length}) Total: $${addZero(parseFloat(TotalPartVist(el4.service)).toFixed(2))}`}</h6>
                                      <h6 className="duration-time">
                                        Duration:
                                        <span>{convertToReadableDuration(TotalPartduration(el4.service))}</span>
                                      </h6>
                                      <h6>
                                        labour & Margin: $
                                        {parseFloat(
                                          TotalPartLabour(el4.service),
                                        ).toFixed(2)}
                                      </h6>
                                    </div>

                                  </>
                                )}
                              </div>
                            );
                          })}
                          {(appointmentType === 'oneteam_multiday' || appointmentType === 'multiteam_oneday' || appointmentType === 'multiteam_multiday')
                            && (
                              <div className="appointment-total">
                                <h6>{`Multi-Visit Total:$${mutiSummary.totalCost}`}</h6>
                                <h6 className="duration-time">
                                  Combined Duration:
                                  <span>{convertToReadableDuration(mutiSummary.totalDuration)}</span>
                                </h6>
                              </div>
                            )}
                          {/* {appointmentType === 'multiteam_oneday'
                              && (
                                <>
                                  <div className="appointment-form">
                                    <Form>
                                      <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Team Member Notes</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          placeholder="Only Visible to Team Members"
                                          // onChange={(e) => setNotes(e.target.value)}
                                          // value={notes}
                                          onChange={(e) => handleChangeNotes('notes', e.target.value, 0)}
                                          name="notes"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </div>
                                  <div className="appointment-total">
                                    <h6>{`Multi-Visit Total:$${mutiSummary.totalCost}`}</h6>
                                    <h6 className="duration-time">
                                      Combined Duration:
                                      <span>{convertToReadableDuration(mutiSummary.totalDuration)}</span>
                                    </h6>
                                  </div>
                                </>
                              )} */}
                          {(dateData.requiredday && summary.multiTotal && multiAppointment) ? (
                            <div className="appointment-total">
                              {/* <h6>{`Multi-Visit Total: $${addZero((parseFloat(summary.totalCost.replace(',', '')) * parseFloat(dateData.requiredday)).toFixed(2))}`}</h6>
                            <h6 className="duration-time">
                              Combined Duration:
                              <span>{convertToReadableDuration(summary.totalDuration * parseInt(dateData.requiredday))}</span>
                            </h6> */}
                            </div>
                          ) : ''}
                          <div className="footer-btn view-btn">
                            <Button
                              className="appointment-btn btn-save"
                              onClick={() => (checkRepeated ? handleEditConfirmPopupe() : handleEditAppointment(''))}
                            >
                              Save/Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <SearchModal
                      showSearch={showSearch}
                      setShowSearch={setShowSearch}
                      handleShowSearch={handleShowSearch}
                      searchClient={searchClient}
                      searchedClients={searchedClients}
                      handleAddClient={handleAddClient}
                      clientTxt={clientTxt}
                      setClientTxt={setClientTxt}
                      checkAddress={checkAddress}
                      getAllClients={getAllClients}
                      setHideSearch={setHideSearch}
                      isHideSearch={isHideSearch}
                    />
                    <DateModal
                      showDate={showDate}
                      setShowDate={setShowDate}
                      handleDateModal={handleDateModal}
                      handleBookingData={handleBookingData}
                      bookingData={bookingData}
                      setBookingData={setBookingData}
                      dateData={dateData}
                      handleChangeDateData={handleChangeDateData}
                      setDateData={setDateData}
                      repeatAppt={repeatAppt}
                      setRepeatAppt={setRepeatAppt}
                      handleFrequency={handleFrequency}
                      repeatFrequency={repeatFrequency}
                      setRepeatFrequency={setRepeatFrequency}
                      handleChangeDate={handleChangeDate}
                      datePopupData={datePopupData}
                      timepickerRef={timepickerRef}
                      handleSaveDate={handleSaveDate}
                      handleRepeat={handleRepeat}
                      appointmentType={appointmentType}
                      appointmentIndex={appointmentIndex}
                      CrossDate={CrossDate}
                      newAppointment={newAppointment}
                      requiredDays={requiredDays}
                      setRequiredDays={SetRDays}
                      team={team}
                      setTeam={SetRteams}
                    />

                    <ServiceModal
                      showService={showService}
                      setShowService={setShowService}
                      handleShowService={handleShowService}
                      newLength={newLength}
                      categories={categories}
                      subcategories={subcategories}
                      handleBookingData={handleBookingData}
                      getTimes={getTimes}
                      bookingData={bookingData}
                      renderOptions={renderOptions}
                      staffList={staffList}
                      handleAddService={handleAddService}
                      editData={editData}
                      handleDeleteService={handleDeleteService}
                      handleSearchCategory={handleSearchCategory}
                      setSubcategories={setSubcategories}
                      addSubcategoryToService={addSubcategoryToService}
                      editService={editService}
                      setBookingData={setBookingData}
                      staffIndex={staffIndex}
                      misc={misc}
                      addedServices={addedServices}
                      servicesLength={addedServices.length}
                      extras={extras}
                      handleChangeCustom={handleChangeCustom}
                      timepickerShow={timepickerShow}
                      handleTimePicker={handleTimePicker}
                      subcategoryTxt={subcategoryTxt}
                      setSearchTxt={setSearchTxt}
                      durationPicker={durationPicker}
                      setDurationPicker={setDurationPicker}
                      convertToReadableDuration={convertToReadableDuration}
                      handleDurationPicker={handleDurationPicker}
                      setnewlength={setnewlength}
                      setHourPicker={setHourPicker}
                      hourPicker={hourPicker}
                      handleHourPicker={handleHourPicker}
                      appointmentType={appointmentType}
                      setDuration={setDuration}
                      durationData={durationData}
                      error={errorValue}
                    />
                    {timepickerShow ? (
                      <TimePicker
                        timepickerShow={timepickerShow}
                        handleTimePicker={handleTimePicker}
                        bookingData={bookingData}
                        setBookingData={setBookingData}
                        timeData={timeData}
                        setTime={setTime}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {openConfirmBox
        && (
          <ConfirmPopupApp
            openConfirmBox={openConfirmBox}
            setOpenConfirmBox={setOpenConfirmBox}
            handleEditAppointment={handleEditAppointment}
            type="edit"
          />
        )}
      {hourPicker ? (
        <HourPicker
          setHourPicker={setHourPicker}
          handleHourPicker={handleHourPicker}
          hourPicker={hourPicker}
          hourData={hourData}
          handleDurationPicker={handleDurationPicker}
          setHourData={setHourData}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}
    </>
  );
};
Editappointment.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  editAppointment: PropTypes.func.isRequired,
  idd: PropTypes.string.isRequired,
  handleCloseEdit: PropTypes.func.isRequired,
  getAppointments: PropTypes.func.isRequired,
  setRandom: PropTypes.func.isRequired,
  durationPicker: PropTypes.func.isRequired,
  setDurationPicker: PropTypes.func.isRequired,
  setDuration: PropTypes.func.isRequired,
  durationData: PropTypes.func.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  setBookingData: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  initialBookingData: PropTypes.func.isRequired,
  currentDates: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  pid: PropTypes.string.isRequired,
  handleAppointmentDetails: PropTypes.func.isRequired,
  SelectedStaff: PropTypes.func.isRequired,
  setEventDate: PropTypes.func.isRequired,
};
export default Editappointment;
