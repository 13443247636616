/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import { Button, Pagination } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

import AdminWrapper from '../admin/adminwrapper/wrapper';
import ClienTable from './clienttable';
import Cli from './clientform';
// import StaffModal from './staffModal';
import { userInstance } from '../../config/axios';
import {
  showErrMsg, validateDataWithKey, queryString, getCalendarPath, removeCodeFromNumber,
} from '../../functions/index';

import AddSiteAddress from './siteAddress';
import { client, emailRegex } from '../../config/keys';
import AddBilingContact from './billingContact';
import './client.css';
import DeletePopUp from '../DeletePopUp/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import {
  errorBorderTime, errorMsgTime, nameRegex, postCodeRegex, successMsgTime,
} from '../../functions/intervalTime';
import sortClientList from '../../functions/listSort';
import toastMessage from '../../functions/toastMessage';
import { countries, states } from '../../country-state/country-state';
import UploadErrorTable from '../uploadCSV/upload-error-table';
import Loader from '../loader/loader';
import { validateData } from '../calculator/calculatorUtils';

function Client() {
  const { setClientAllData, clientAllData, userData } = useContext(AuthDataContext);
  const history = useHistory();
  const initialClientData = {
    customerType: 'Householder',
    companyName: '',
    firstname: '',
    lastname: '',
    mobileNo: '',
    landlineNo: '',
    email: '',
    streetAddress: '',
    suburb: '',
    postcode: '',
    state: '',
    country: 'Australia',
    invoiceSendType: 'Send Invoices by SMS',
    gender: 'male',
    note: '',
    hide: false,
    associatedContacts: [],
    siteAddresses: [],
    showBookings: true,
    poolWidth: '',
    poolHeight: '',
    chlorinationType: 'Salt Pool',
    selectedModel: '',
    // updatedSelectedModel: '',
    selectedModelObj: null,
    poolFinish: 'Concrete Pool - Outdoor',
    chlorinatorModel: [],
    poolDepth: 'Average Depth - Rectangular Pool',
    diameter: '',
    deepEnd: '',
    shallowEnd: '',
    poolCapacity: '',
    poolMsg: '',

  };
  let {
    clientid, name, startDateTime, employee_id, typee, manageClient, back, typeRoute,
  } = queryString();

  // console.log('test Client okay ==>', queryString());

  const initialContactData = {
    name: '', lastName: '', mobileNo: '', email: '', landlineNo: '', hide: false, tempShow: false,
  };
  const initialAddressData = {
    householderName: '',
    address: '',
    mobileNo: '',
    landlineNo: '',
    email: '',
    nestedContacts: [],
    hide: false,
    tempShow: false,
    poolWidth: '',
    poolHeight: '',
    chlorinationType: 'Salt Pool',
    selectedModel: '',
    selectedModelObj: null,
    poolFinish: 'Concrete Pool - Outdoor',
    chlorinatorModel: [],
  };

  const isUsImperial = userData?.calendarSettings?.unit !== 'metric';
  const limit = 10;
  const [showTable, setShowTable] = useState(false);
  const [clientData, setClientData] = useState(initialClientData);
  const [clientList, setClientList] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedClient, setClientId] = useState('');
  const [showHide, setShowHide] = useState(false);
  const [selectedCountry, setCountry] = useState('+61');
  const [searchData, setSearchData] = useState('');
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [showContactModal, setContactModal] = useState(false);
  const [showSiteAddress, setSiteAddress] = useState(false);
  const [contactData, setContactData] = useState(initialContactData);
  const [selectedIndex, setIndex] = useState('');
  const [runEdit, setRun] = useState(null);
  const [isUpdate, setUpdate] = useState(false);
  const [contactIndex, setContactIndex] = useState('');
  const [menuType, setMenuType] = useState('');
  const [addressData, setAddressData] = useState(initialAddressData);
  const [addressIndex, setAddrIndex] = useState('');
  const [hideClicked, setClicked] = useState(null);
  const [msg, setMsg] = useState('');
  const [clientTxt, setClientTxt] = useState('');
  const [isHideSearch, setHideSearch] = useState(false);
  const [hideClickedAssociated, setHideClkAssociated] = useState(null);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delValue, setDelvalue] = useState();
  const [crossStatus, setCrossStatus] = useState(false);
  const [clientIdentity, setClientIdentity] = useState('');
  const [error, setError] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [stateArray, setStateArray] = useState([]);
  const [mobileFormat, setMobileFormat] = useState('### ### ###');
  const [showUploadError, setShowUploadError] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [showUploadErrorPopup, setShowUploadErrorPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const poolFinishArray = [
    'Concrete Pool - Outdoor', 'Fibreglass Pool - Outdoor', 'Vinyl Liner Pool - Outdoor', 'Concrete Pool - Indoor', 'Fibreglass Pool - Indoor', 'Vinyl Liner Pool - Indoor',
  ];
  const chlorinationArray = [
    'Salt Pool', 'Mineral Pool with Chlorinator', 'Convert Salt Pool to Mineral Pool',
    'Manually Chlorinated',
  ];
  const poolTypeArray = [
    'Non-Mineral Pool', 'Convert Pool to Mineral Pool', 'Aquatherepe Transform Maintenance',
  ];
  const convertPoolChlorineArray = [
    'Aquatherepe Transform',
    'Aquatherepe Transform - With ORP Probe',
  ];
  const poolDepthArray = ['Average Depth - Rectangular Pool', 'Average Depth - Circular pool', 'Specific Depths - Rectangular Pool', 'Specific Depths - Circular Pool'];

  const PopUpText = `Any Additional Contacts and
  their details will be deleted
  with this change.`;

  const paraText = 'Do you want to continue?';

  const initialValidationData = {
    poolWidth: false,
    poolHeight: false,
    shalowEnd: false,
    deepEnd: false,
    diameter: false,
  };

  const [validationData, setValData] = useState(initialValidationData);

  const [saltChlorinator, setSaltChorinator] = useState([]);
  const [mineralChlorinator, setMineralChlorinator] = useState([]);
  // const [chlorinatorModel, setChlorinatorModel] = useState([]);

  const manageClientPoolData = (clientState, setClientState, clType,
    modelName, changeStatus, doStatus) => {
    const {
      selectedModel, updatedSelectedModel, selectedModelObj, chlorinatorModel,
    } = clientState;
    const copyData = { ...clientState };
    copyData.chlorinatorModel = chlorinatorModel;
    copyData.selectedModel = selectedModel;
    copyData.selectedModelObj = doStatus ? selectedModelObj : null;
    copyData.chlorinationType = clType;
    if (!chlorinatorModel?.length) {
      copyData.chlorinatorModel = modelName;
      copyData.selectedModel = modelName[0].name;
      copyData.selectedModelObj = doStatus ? modelName[0] : null;
    }

    // console.log('copyData', copyData);

    setClientState(copyData);
  };

  const handleCountryStates = (compareValue) => {
    const fIndex = countries.findIndex((el) => el.name === compareValue);
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter((el) => el.countryCode === countries[fIndex].isoCode);
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      console.log({ list });
      setCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };

  const forSettingChlorinationModel = (saltModel, mineralModel, typeCh, clientState, setClientState, changeStatus) => {
    // const typeCh = clientState.chlorinationType;
    // console.log('typeCh===>>>', saltModel, mineralModel, typeCh, clientState, setClientState);
    if (typeCh === 'Salt Pool') {
      manageClientPoolData(clientState,
        setClientState,
        typeCh,
        saltModel,
        changeStatus, 'A');
    } else if (typeCh === 'Mineral Pool with Chlorinator' || typeCh === 'Mineral Pool') {
      manageClientPoolData(clientState,
        setClientState,
        typeCh,
        mineralModel,
        changeStatus, 'B');
    } else if (typeCh === 'Convert Salt Pool to Mineral Pool') {
      manageClientPoolData(clientState,
        setClientState,
        typeCh,
        convertPoolChlorineArray,
        changeStatus, '');
    } else if (typeCh === 'Manually Chlorinated') {
      manageClientPoolData(clientState,
        setClientState,
        typeCh,
        poolTypeArray,
        changeStatus, '');
    }
  };

  useEffect(async () => {
    // console.log('hhhhhhhhhhhhhhhhhhhh');
    const newresponse = await userInstance().get('/poolmineral/getAllPoolMinerals');
    const mineralList = newresponse?.data?.mineralList;
    if (mineralList) {
      const saltChorinatorModel = mineralList.chlorinatorModels;
      const mineralChlorinatorModel = mineralList.calciumAdjustments;
      setSaltChorinator(saltChorinatorModel);
      setMineralChlorinator(mineralChlorinatorModel);

      forSettingChlorinationModel(saltChorinatorModel,
        mineralChlorinatorModel,
        addressData.chlorinationType,
        addressData,
        setAddressData);

      forSettingChlorinationModel(saltChorinatorModel,
        mineralChlorinatorModel,
        clientData.chlorinationType,
        clientData,
        setClientData);
    }
  }, [showForm]);

  const handlePoolFinish = (e) => {
    const { value } = e.target;
    setClientData({ ...clientData, poolFinish: value });
  };

  const handleChlorinationType = (e) => {
    const { value } = e.target;
    forSettingChlorinationModel(saltChlorinator, mineralChlorinator, value, clientData, setClientData, 'onChange');
  };

  const handleChlorination = (e) => {
    const { value } = e.target;
    const el = clientData.chlorinatorModel.find((ele) => (ele.name || ele.brandName || ele) === value);

    setClientData({
      ...clientData, selectedModel: value, selectedModelObj: el,
    });
  };

  // For Site Addresssess
  const handleSitePoolFinish = (e) => {
    const { value } = e.target;
    setAddressData({ ...addressData, poolFinish: value });
  };

  const handleSiteChlorinationType = (e) => {
    const { value } = e.target;
    forSettingChlorinationModel(saltChlorinator, mineralChlorinator, value, addressData, setAddressData, 'onChange');
  };

  const handleSiteChlorination = (e) => {
    const { value } = e.target;
    const el = addressData.chlorinatorModel.find((ele) => (ele.name || ele.brandName || ele) === value);

    setAddressData({
      ...addressData, selectedModel: value, selectedModelObj: el,
    });
  };

  const handlePopUpChange = (val) => {
    if (val === 'Yes') {
      setClientData({ ...clientData, customerType: delValue });
    }
    setDeletePopUp(false);
    setClientData({ ...clientData });
  };
  const editCustomerType = (value) => {
    setDeletePopUp(true);
    setDelvalue(value);
  };

  const handleContactModal = (index, _id, addrIndex) => {
    setContactData(initialContactData);
    setContactModal(!showContactModal);
    if (index !== undefined) setIndex(index);
    if (_id) setClientId(_id);
    if (addrIndex !== undefined) {
      setMenuType('site');
      setAddrIndex(addrIndex);
    } else {
      setMenuType('associated');
    }
  };
  const handleSiteAddressModal = (index, _id) => {
    setAddressData({
      ...initialAddressData, selectedModel: addressData.selectedModel, chlorinatorModel: addressData.chlorinatorModel, selectedModelObj: addressData.selectedModelObj,
    });
    setSiteAddress(!showSiteAddress);
    setMenuType('site');
    if (index !== undefined) setIndex(index);
    if (_id) setClientId(_id);
  };

  const handleContactData = (e) => {
    if (e.target.name === 'name' || e.target.name === 'lastName') {
      if (e.target.value && !(nameRegex.test(e.target.value))) {
        return;
      }
    }
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };
  const handleAddressData = (e) => {
    if (e.target.name === 'householderName') {
      if (e.target.value && !(nameRegex.test(e.target.value))) {
        return;
      }
    }
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  const handleAddBillingContact = (e) => {
    e.preventDefault();
    let message = '';
    const {
      name, lastName, mobileNo, email,
    } = contactData;
    const validationObj = {
      'first name': name?.trim(),
      'last name': lastName?.trim(),
      'mobile no': mobileNo,
    };

    // console.log('contactData', contactData);
    let { isValid, emptyField } = validateDataWithKey(validationObj);

    if (isValid) {
      let isMobileValid = true;
      let isLandlineValid = true;
      let mobileMsg = '';
      let landlineMsg = '';
      let suburbMsg = '';

      if (selectedCountry === '+61') {
        if (contactData.mobileNo.charAt(0) === '0') {
          contactData.mobileNo = contactData.mobileNo.replace('0', '');
        }
        if (contactData.landlineNo.charAt(0) === '0') {
          contactData.landlineNo = contactData.landlineNo.replace('0', '');
        }

        if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
          isLandlineValid = false;
          landlineMsg = 'Please enter area code, followed by phone no.';
          setError('landlineno');
        }
        if (contactData.mobileNo.charAt(0) !== '4') {
          // console.log('payload.mobileNo.charAt(4)', payload.mobileNo.charAt(4));
          isMobileValid = false;
          mobileMsg = 'Please start mobile number with 4';
          setError('mobileno');
        }
        if (contactData.mobileNo.trim().length !== 11) {
          setError('mobileno');
          isMobileValid = false;
          // mobileMsg = 'Invalid Mobile Number';
          toastMessage('error', 'Enter 9 Digit Mobile Number');
        }
      }
      if (selectedCountry === '+1') {
        if (contactData.mobileNo.charAt(0) === '0') {
          contactData.mobileNo = contactData.mobileNo.replace('0', '');
        }
        // if (contactData.landlineNo.charAt(0) === '0') {
        //   contactData.landlineNo = contactData.landlineNo.replace('0', '');
        // }

        // if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
        //   isLandlineValid = false;
        //   landlineMsg = 'Please enter area code, followed by phone no.';
        //   setError('landlineno');
        // }
        if (contactData.mobileNo.trim().length !== 12) {
          setError('mobileno');
          isMobileValid = false;
          toastMessage('error', 'Enter 10 Digit Mobile Number');
        }
      }
      if (!isMobileValid) {
        setError('mobileno');
        toastMessage('error', mobileMsg);
        return;
      }

      if (!isLandlineValid) {
        setError('landlineno');
        toastMessage('error', landlineMsg);
        return;
      }

      if (contactData?.mobileNo) {
        contactData.mobileNo = `${selectedCountry} ${contactData.mobileNo}`;
      }
      if (contactData?.landlineNo) {
        contactData.landlineNo = `${selectedCountry} ${contactData.landlineNo}`;
      }

      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      // console.log('client', client);
      const propertyName = menuType === 'site' ? 'siteAddresses' : 'associatedContacts';
      const contacts = client[propertyName];
      if (menuType === 'associated') {
        if (isUpdate) {
          let selectedContact = contacts.length ? contacts[contactIndex] : null;
          if (selectedContact) {
            selectedContact = contactData;
            contacts[contactIndex] = selectedContact;
          }
          message = 'Contact updated';
        } else {
          message = 'Contact added';
          contacts.push(contactData);
        }
      } else if (menuType === 'site') {
        const existingContacts = contacts[addressIndex].nestedContacts;
        if (isUpdate) {
          existingContacts[contactIndex] = contactData;
          message = 'Contact updated';
        } else {
          message = 'Contact added';
          existingContacts.push(contactData);
        }
        let selectedAddress = contacts[addressIndex];
        selectedAddress.nestedContacts = existingContacts;
        contacts[addressIndex] = selectedAddress;
      }
      client[propertyName] = contacts;
      setClientList([...clientListCopy]);
      setRun(true);
      setContactModal(!showContactModal);
      setUpdate(false);
    } else {
      toastMessage('error', 'Invalid email.');

      setError('email');
    }

    setTimeout(() => {
      setError('');
    }, errorBorderTime);
    setMsg(message);
  };

  const handleEditContact = (data, _id, index, contactI, addrIndex) => {
    let copySelectedCountry = selectedCountry;
    if (data?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(data.mobileNo);
      data.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }
    if (data?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(data.landlineNo);
      data.landlineNo = updatedNo;
    }

    setContactData(data);
    setClientId(_id);
    setIndex(index);
    setUpdate(true);
    setCountry(copySelectedCountry);
    if (contactI !== undefined) setContactIndex(contactI);
    if (addrIndex !== undefined) {
      setMenuType('site');
      setAddrIndex(addrIndex);
    } else {
      setMenuType('associated');
    }
    setContactModal(!showContactModal);
  };

  const [claddressIndex, setClAddressIndex] = useState('');

  const checkAddress = (index, _id, addrId) => {
    setClientId(_id);
    setIndex(index);
    setClAddressIndex(addrId);
    const clientListCopy = clientList;
    // console.log('clientList==>', clientList);
    const selectedClient = clientListCopy[index];
    // console.log('clientList==>', selectedClient);
    if (selectedClient.siteAddresses) {
      selectedClient.siteAddresses.filter((elem) => !elem.hide).forEach((el, i) => {
        if (i === addrId && selectedClient?._id === _id) {
          const val = el.default !== true;
          el.default = val;
          if (!val) {
            toastMessage('success', 'Site address removed successfully');

            // setMsg('Site address removed successfully');
          } else {
            toastMessage('success', 'Site address selected successfully');

            // setMsg('Site address selected successfully');
          }
        } else {
          el.default = false;
        }
      });
    }
    setClientList([...clientListCopy]);
    // setRun(true);
  };

  const handleDeleteContact = (_id, index, contactI, type, addrIndex) => {
    setClientId(_id);
    setIndex(index);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const propertyName = type === 'site' ? 'siteAddresses' : 'associatedContacts';
    const contacts = client[propertyName] ? client[propertyName] : [];
    if (type === 'contact') {
      contacts.splice(contactI, 1);
    } else if (type === 'site') {
      const existingContacts = contacts[addrIndex].nestedContacts;
      existingContacts.splice(contactI, 1);
    }
    client[propertyName] = contacts;
    setClientList([...clientListCopy]);
    setMsg('Contact deleted');
    setRun(true);
  };

  const handleAddAddress = (e) => {
    e.preventDefault();

    let message = '';
    const {
      householderName, address, mobileNo, poolHeight, poolWidth,
    } = addressData;
    const validationObj = {
      'householder name': householderName?.trim(),
      address,
      'mobile no': mobileNo,
    };

    const { isValid, emptyField } = validateDataWithKey(validationObj);
    if (isValid) {
      let isMobileValid = true;
      let mobileMsg = '';

      if (selectedCountry === '+61') {
        if (addressData.mobileNo.charAt(0) === '0') {
          addressData.mobileNo = addressData.mobileNo.replace('0', '');
        }

        if (addressData.mobileNo.charAt(0) !== '4') {
          // console.log('payload.mobileNo.charAt(4)', payload.mobileNo.charAt(4));
          isMobileValid = false;
          mobileMsg = 'Please start mobile number with 4';
          setError('mobileno');
        }
      }

      if (!isMobileValid) {
        setError('mobileno');
        toastMessage('error', mobileMsg);

        return;
      }

      if (addressData?.mobileNo) {
        addressData.mobileNo = `${selectedCountry} ${addressData.mobileNo}`;
      }

      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      const addresses = client.siteAddresses ? client.siteAddresses : [];
      if (isUpdate) {
        let selectedContact = addresses.length ? addresses[addressIndex] : null;
        if (selectedContact) {
          selectedContact = addressData;
          addresses[addressIndex] = selectedContact;
        }
        message = 'Address updated';
      } else {
        message = 'Address added';
        addresses.push(addressData);
      }

      client.siteAddresses = addresses;
      setClientList([...clientListCopy]);
      setRun(true);
      if (!clientid) {
        setSiteAddress(!showSiteAddress);
      }
      setUpdate(false);
    } else {
      setError(emptyField?.split(' ').join('').toLowerCase());
      toastMessage('error', `Please fill ${emptyField}.`);
    }
    setMsg(message);
    setTimeout(() => {
      setError('');
    }, errorBorderTime);
  };

  const handleEditAddress = (data, _id, index, addrIndex) => {
    if (data.selectedModel === '') {
      data.selectedModel = addressData.selectedModel;
      data.selectedModelObj = addressData.selectedModelObj;
    }

    let copySelectedCountry = selectedCountry;
    if (data?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(data.mobileNo);
      data.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }
    if (data?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(data.landlineNo);
      data.landlineNo = updatedNo;
    }
    setCountry(copySelectedCountry);

    setAddressData({ ...addressData, ...data });
    setClientId(_id);
    setIndex(index);
    setUpdate(true);
    setAddrIndex(addrIndex);
    setSiteAddress(!showSiteAddress);
    setMenuType('site');
  };

  const handleDeleteAddress = (_id, index, addrIndex) => {
    setClientId(_id);
    setIndex(index);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const addresses = client.siteAddresses ? client.siteAddresses : [];
    addresses.splice(addrIndex, 1);
    client.siteAddressess = addresses;
    setClientList([...clientListCopy]);
    setMsg('Address deleted');
    setRun(true);
  };

  const handleVisibilityAddr = (index, _id, addrIndex, type) => {
    setIndex(index);
    setClientId(_id);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const addresses = client[type];
    const currentAddress = addresses[addrIndex];
    currentAddress.hide = !currentAddress.hide;
    currentAddress.tempShow = type === 'associatedContacts' ? hideClickedAssociated : hideClicked;
    client[type] = addresses;
    setClientList([...clientListCopy]);
    setMsg('Visibility updated');
    setRun(true);
  };

  const showAll = (index, type) => {
    const value = !(type === 'siteContacts' || type === 'siteAddresses' ? hideClicked : hideClickedAssociated);
    if (type === 'associatedContacts') {
      setHideClkAssociated(value);
    } else {
      setClicked(value);
    }
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    client[type === 'siteAddresses' ? type : 'associatedContacts'].forEach((el) => {
      el.tempShow = value;
    });
    setClientList([...clientListCopy]);
  };

  const hideAll = (index, _id, type) => {
    setIndex(index);
    setClientId(_id);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    client[type].forEach((el) => {
      el.hide = true;
      el.tempShow = hideClicked;
    });
    setClientList([...clientListCopy]);
    setMsg('Visibility updated');
    setRun(true);
  };

  const HandleOpenCloseTable = (staff) => {
    setShowTable(!showTable);
    if (staff) {
      setClientId(staff._id);
      const newShift = convertDate(staff.shift);
      setClientData({ ...clientData, ...staff, shift: newShift });
    }
    if (showTable === true) {
      // setEditWorking(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };
  function sortSiteAddresses(listArray) {
    listArray?.forEach((el) => {
      const sortSiteAddArray = el?.siteAddresses?.sort((a, b) => a?.householderName.localeCompare(b?.householderName));
      el.siteAddresses = sortSiteAddArray;
    });
    return listArray;
  }

  const getClientList = async () => {
    try {
      if (!clientid) clientid = '';
      if (!typee) clientid = '';

      const response = await userInstance().get(`/customer/getCustomers?limit=${limit}&skip=${skip}&clientId=${clientid}`);
      const list = response.data.customers;
      const newList = sortSiteAddresses(list);
      setPageCounts(response.data.count);
      setClientList(newList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const nextpage = async () => {
    setSkip(skip + 1);
  };
  const previousPage = async () => {
    setSkip(skip - 1);
  };

  const searchCustomerApi = async () => {
    try {
      if (!clientTxt) {
        getClientList();
        return;
      }
      const response = await userInstance().get(`/customer/searchCustomer?value=${clientTxt}`);
      let list = response.data.customers;
      if (clientTxt) {
        if (isHideSearch) {
          list.forEach((client) => {
            if (client.siteAddressess) {
              client.siteAddressess.forEach((addr) => {
                addr.hide = false;
              });
            }
          });
        } else {
          list = list.filter((el) => (el.siteAddresses ? el.siteAddresses.filter((adr) => adr.hide).length === 0 : el));
        }
      }
      const newList = sortClientList(list);
      setPageCounts(list?.length);
      setClientList(newList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let searchTimeOut = setTimeout(async () => {
      searchCustomerApi();
    }, 500);

    return (() => clearInterval(searchTimeOut));
  }, [clientTxt, limit, skip, clientid]);

  // let searchTimeout;
  const searchClient = (value) => {
    setClientTxt(value);
  };

  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = clientData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (clientData.mobileNo) {
      obj.mobileNo = `${clientData.mobileNo.replace(textToReplace, '')}`;
    }
    if (clientData.landlineNo) {
      obj.landlineNo = `${clientData.landlineNo.replace(textToReplace, '')}`;
    }
    setClientData({ ...clientData, ...obj });
  };
  const handleSiteAddressCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = addressData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (addressData.mobileNo) {
      obj.mobileNo = `${addressData.mobileNo.replace(textToReplace, '')}`;
    }
    setSiteAddress({ ...addressData, ...obj });
  };

  const convertDate = (shift) => {
    const newShift = [];
    shift.forEach((el) => {
      if (el) {
        newShift.push({ ...el, date: new Date(new Date(el.date).toDateString()) });
      }
    });
    return newShift;
  };

  const handleChange = (e, passName) => {
    let { name, value } = e.target;

    if (name === 'firstname' || name === 'lastname') {
      if (value && !(nameRegex.test(value))) {
        return;
      }
    }

    if (name === 'postcode') {
      if (value && !(postCodeRegex.test(value))) {
        return;
      }
    }
    const copy = { ...clientData };

    let {
      poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd,
    } = clientData;

    if (name === 'country') {
      if (value === 'Australia') {
        setMobileFormat('### ### ###');
      }
      if (value === 'United States') {
        setMobileFormat('### ### ####');
      }
      handleCountryStates(value);
      copy.state = '';
    }
    // console.log('value', value, 'name', name);
    if (name === 'gender') {
      value = e.target.id;
    }
    if (name === 'hide') {
      value = JSON.parse(e.target.value);
    }
    if (name === 'customerType' && selectedClient) {
      const client = clientList.filter((el) => el._id === selectedClient);
      if (client[0].customerType === 'Householder' && value !== 'Householder' || client[0].customerType !== 'Householder' && value === 'Householder') {
        editCustomerType(value);
      } else {
        copy[name] = value;
      }
    } else {
      copy[name] = value;
    }

    if (['poolWidth', 'poolHeight', 'shallowEnd', 'deepEnd', 'diameter'].includes(name)) {
      if (value && !value.match(/^(\d{0,7})(\.[0-9]{0,2})?$/)) {
        return;
      }
      if (name === 'poolWidth') {
        poolWidth = value;
      } else if (name === 'poolHeight') {
        poolHeight = value;
      } else if (name === 'shallowEnd') {
        shallowEnd = value;
      } else if (name === 'deepEnd') {
        deepEnd = value;
      } else if (name === 'diameter') {
        diameter = value;
      }

      const { poolMsg, poolCapacity } = calculationPoolLitres(poolDepth,
        Number(poolWidth),
        Number(poolHeight),
        Number(diameter),
        Number(deepEnd),
        Number(shallowEnd));
      copy.poolMsg = poolMsg;
      copy.poolCapacity = poolCapacity;
    }

    setClientData(copy);

    // if (name === 'billingAddressType' && name !== 'Select') {
    //   const addressType = value;
    //   if (addressType === 'same') {
    //     value = clientData.streetAddress;
    //   } else if (addressType === 'different') {
    //     value = '';
    //   }
    //   setClientData({ ...clientData, billingAddress: value, [name]: addressType });
    // }
  };

  const createXeroCustomer = async (payload) => {
    try {
      let xeroResponse = await userInstance().post('/xero/createContact', payload);
      if (xeroResponse.data.msg === 'Success') {
        let contactId = xeroResponse.data.contactId;
        return { result: true, contactId };
      }
      return { result: null, contactId: '' };
    } catch (error) {
      let errorMsg = '';

      if (error.response) {
        errorMsg = error.response;
        let message = error.response.data.msg;
        let status = error.response.status;
        toastMessage('error', message);

        return { result: false, contactId: '' };
      } if (error.request) {
        errorMsg = error.request;
        // console.error(errorMsg);
        return { result: false, contactId: '' };
      }
      errorMsg = error.message;
      // console.error(errorMsg);
      return { result: false, contactId: '' };
    }
  };
  const createQuickBooksCustomer = async (payload) => {
    try {
      let quickResponse = await userInstance().post('/quickBooks/createQuickBooksContact', payload);
      if (quickResponse.data.quickMsg === 'Success') {
        let quickBookId = quickResponse.data.quickData.quickBookId;
        let quickBookSyncToken = quickResponse.data.quickData.quickBookSyncToken;
        return { result: true, quickBookId, quickBookSyncToken };
      }
      return { result: null, quickBookId: '', quickBookSyncToken: '' };
    } catch (error) {
      let errorMsg = '';
      if (error.response) {
        errorMsg = error.response;
        let message = error.response.data.msg;
        let status = error.response.status;
        toastMessage('error', message);
        return { result: false, quickBookId: '', quickBookSyncToken: '' };
      } if (error.request) {
        errorMsg = error.request;
        return { result: false, quickBookId: '', quickBookSyncToken: '' };
      }
      errorMsg = error.message;
      // console.error(errorMsg);
      return { result: false, quickBookId: '', quickBookSyncToken: '' };
    }
  };

  const updateXeroCustomer = async (payload, selectedClient) => {
    try {
      let xeroResponse = await userInstance().put('/xero/updateContact', { ...payload, selectedClient });
      if (xeroResponse.data.msg === 'Success') {
        return { result: true };
      }
      return { result: null };
    } catch (error) {
      let errorMsg = '';
      if (error.response) {
        errorMsg = error.response;
        let message = error.response.data.msg;
        let status = error.response.status;
        toastMessage('error', message);
        console.error(errorMsg);
        return { result: false };
      } if (error.request) {
        errorMsg = error.request;
        console.error(errorMsg);
        return { result: false };
      }
      errorMsg = error.message;
      console.error(errorMsg);
      return { result: false };
    }
  };

  const checkPoolSizeInputsOk = () => {
    let isValid = true;
    let validationObj = {};
    let emptyField = [];

    const {
      poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd, poolCapacity,
    } = clientAllData;

    if (poolDepth === 'Average Depth - Rectangular Pool') {
      validationObj.poolWidth = poolWidth;
      validationObj.poolHeight = poolHeight;
    } else if (poolDepth === 'Average Depth - Circular pool') {
      validationObj.diameter = diameter;
    } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
      validationObj.poolWidth = poolWidth;
      validationObj.poolHeight = poolHeight;
      validationObj.shallowEnd = shallowEnd;
      validationObj.deepEnd = deepEnd;
    } else if (poolDepth === 'Specific Depths - Circular Pool') {
      validationObj.shallowEnd = shallowEnd;
      validationObj.deepEnd = deepEnd;
      validationObj.diameter = diameter;
    }

    const returnedData = validateData(validationObj);
    isValid = returnedData.isValid;
    emptyField = returnedData.emptyField;
    console.log({ isValid, emptyField });
    setValData({ ...initialValidationData, ...emptyField });
    return isValid;
  };

  const handleAddCustomer = async (e) => {
    e.preventDefault();
    const {
      customerType,
      companyName,
      firstname,
      lastname,
      mobileNo,
      landlineNo,
      email,
      streetAddress,
      suburb,
      state,
      country,
      postcode,
      invoiceSendType,
    } = clientData;
    let name = [firstname, lastname].join(' ');
    let payload = { ...clientData, name };
    try {
      const validationObj = {};
      if (customerType !== 'Householder') {
        validationObj[`${customerType} name`] = companyName;
      }
      validationObj.country = country;
      validationObj['customer type'] = customerType;
      validationObj['first name'] = firstname?.trim();
      validationObj['last name'] = lastname?.trim();
      validationObj['mobile no'] = mobileNo;
      validationObj.email = email;
      validationObj['street address'] = streetAddress;
      validationObj.suburb = suburb;
      validationObj['postal/zip code'] = postcode;
      validationObj.state = state;
      validationObj['street address'] = streetAddress;

      // const validationObj = {
      //   'customer type': customerType,
      //   'first name': firstname,
      //   'last name': lastname,
      //   'mobile no': mobileNo,
      //   email,
      //   'street address': streetAddress,
      //   suburb,
      //   state,
      //   country,

      //   // 'landline no': landlineNo,
      //   // email,
      // };
      // validationObj.landmark = landmark;
      // validationObj['street address'] = streetAddress;
      validationObj['invoice sending type'] = invoiceSendType;
      let { isValid, emptyField } = validateDataWithKey(validationObj);
      let emailValid = true;
      if (email) {
        if (emailRegex.test(String(clientData.email).toLowerCase())) {
          emailValid = true;
        } else {
          emailValid = false;
        }
      }

      if (isValid) {
        if (!checkPoolSizeInputsOk()) return;
        // if (email && emailRegex.test(String(clientData.email).toLowerCase())) {
        let isMobileValid = true;
        let isLandlineValid = true;
        let mobileMsg = '';
        let landlineMsg = '';
        let suburbMsg = '';
        if (selectedCountry === '+61') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }
          console.log('landlineNo', clientData.landlineNo.length);
          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            console.log('payload.mobileNo.charAt(4)', payload.mobileNo.charAt(4));
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 11) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 9 Digit Mobile Number';
          }
        }
        if (selectedCountry === '+1') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 12) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 10 Digit Mobile Number';
          }
        }
        if (isLandlineValid) {
          if (payload?.landlineNo) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }
          if (emailValid) {
            if (isMobileValid) {
              if (payload?.mobileNo) {
                payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
              }
              const allStatsRequiredData = await Promise.allSettled([
                createXeroCustomer(payload),
              ]);
              const [
                xeroResponse,
              ] = allStatsRequiredData.map((el) => el.value);
              let { result: xeroResult, contactId } = xeroResponse;

              if (xeroResult === true) {
                payload = { ...payload, contactId };
              }

              if (xeroResult !== false) {
              // console.log('I should be here only once');
                let response = await userInstance().post('/customer/addCustomer', payload);
                const { status, msg } = response.data;
                if (status === 'success') {
                  const { msg, customerId } = response.data;
                  payload._id = customerId;

                  let copyclientAllDatas = { ...clientAllData };
                  copyclientAllDatas.clientAllDetails = payload;
                  copyclientAllDatas.appointmentData.client = payload;

                  setClientAllData(copyclientAllDatas);
                  localStorage.setItem('apptData', JSON.stringify(copyclientAllDatas));
                  if (crossStatus && typee === 'add') {
                    if (back === 'newinvoice') {
                      history.push('/invoice?clienttype=edit');
                    } else if (back === 'quotes') {
                      history.push('/quotes?clienttype=edit');
                    } else {
                      history.push(`addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&cust=add`);
                    }
                  }
                  setClientData({ ...clientData, ...initialClientData });
                  setError('');
                  // setShowForm(false);
                  getClientList();
                }

                toastMessage(status, msg);
              }
            } else if (!isMobileValid) {
              setError('mobileno');
              toastMessage('error', mobileMsg);
            }
          } else {
            setError('email');
            toastMessage('error', 'Email is Invalid!');
          }
        } else {
          toastMessage('error', landlineMsg);
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setError('');
      }, errorBorderTime);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormModal = () => {
    handleCountryStates(clientData?.country);
    setCrossStatus(true);
    setClientData({
      ...initialClientData, selectedModel: clientData.selectedModel, chlorinatorModel: clientData.chlorinatorModel, selectedModelObj: clientData.selectedModelObj,
    });
    setClientId('');
    setShowForm(!showForm);
    if (typee === 'add' && crossStatus) {
      if (back === 'newinvoice') {
        history.push('/invoice?clienttype=edit');
      } else if (back === 'quotes') {
        history.push('/quotes?clienttype=close');
      } else {
        history.push(`addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&typo=add`);
      }
    }
  };

  const handleEditClient = (staff) => {
    handleCountryStates(staff?.country);
    setClientId(staff._id);
    setShowForm(true);
    if (!staff.selectedModel) staff.selectedModel = clientData.selectedModel;
    if (!staff.selectedModelObj) staff.selectedModelObj = clientData.selectedModelObj;
    if (!staff.chlorinatorModel) staff.chlorinatorModel = clientData.chlorinatorModel;
    if (!staff.chlorinationType) staff.chlorinationType = clientData.chlorinationType;

    let copySelectedCountry = selectedCountry;
    if (staff?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(staff.mobileNo);
      staff.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }
    if (staff?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(staff.landlineNo);
      staff.landlineNo = updatedNo;
    }
    // const upStaff = {...staff}
    setCountry(copySelectedCountry);

    setClientData({ ...clientData, ...staff });
    // console.log(selectedClient);
  };

  const editClient = async (e) => {
    e.preventDefault();
    const {
      customerType,
      companyName,
      firstname,
      lastname,
      mobileNo,
      landlineNo,
      email,
      billingAddressType,
      billingAddress,
      streetAddress,
      state,
      suburb,
      country,
      postcode,
      invoiceSendType,
    } = clientData;
    let name = [firstname, lastname].join(' ');
    let payload = { ...clientData, name };
    try {
      const validationObj = {};
      if (customerType !== 'Householder') {
        validationObj[`${customerType} name`] = companyName;
      }
      validationObj.country = country;
      validationObj['customer type'] = customerType;
      validationObj['first name'] = firstname?.trim();
      validationObj['last name'] = lastname?.trim();
      validationObj['mobile no'] = mobileNo;
      validationObj.email = email;
      validationObj['street address'] = streetAddress;
      validationObj.suburb = suburb;
      // validationObj['postal/zip code'] = postcode;
      validationObj.state = state;

      // const validationObj = {
      //   'customer type': customerType,
      //   'first name': firstname,
      //   'last name': lastname,
      //   'mobile no': mobileNo,
      //   email,
      //   'street address': streetAddress,
      //   suburb,
      //   state,
      //   country,
      //   // 'landline no': landlineNo,
      // };
      // console.log('Step 1');
      // console.log('bilingAddress Type', billingAddressType);

      if (billingAddressType === 'same') {
        validationObj['street address'] = streetAddress;
        payload.billingAddress = streetAddress;
      } else if (billingAddressType === 'different') {
        validationObj['billing address'] = billingAddress;
      }

      // if (customerType !== 'Householder') {
      //   validationObj['company name'] = companyName;
      // }
      validationObj['invoice sending type'] = invoiceSendType;
      let { isValid, emptyField } = validateDataWithKey(validationObj);
      let emailValid = true;
      if (email) {
        if (emailRegex.test(String(clientData.email).toLowerCase())) {
          emailValid = true;
        } else {
          emailValid = false;
        }
      }
      if (isValid) {
        // if (emailRegex.test(String(clientData.email).toLowerCase())) {
        let isMobileValid = true;
        let isLandlineValid = true;
        let mobileMsg = '';
        let landlineMsg = '';
        if (selectedCountry === '+61') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            // console.log('payload.mobileNo.charAt(4)', payload.mobileNo.charAt(4));
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 11) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 9 Digit Mobile Number';
          }
        }
        if (selectedCountry === '+1') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 12) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 10 Digit Mobile Number';
          }
        }
        if (isLandlineValid) {
          if (payload?.landlineNo) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }
          if (emailValid) {
            if (isMobileValid) {
              if (payload?.mobileNo) {
                payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
              }
              // const allStatsRequiredData = await Promise.allSettled([
              //   updateXeroCustomer(payload, selectedClient),
              // ]);
              // const [
              //   xeroResponse,
              // ] = allStatsRequiredData.map((el) => el.value);
              // let { result: xeroResult } = xeroResponse;

              // console.log({ allStatsRequiredData, xeroResponse });

              // if (xeroResult !== false) {
              const response = await userInstance().put('/customer/updateCustomer', { ...payload, selectedClient });
              const { status, msg } = response.data;
              if (status === 'success') {
                setShowForm(false);
                setError('');
                toastMessage(status, msg);
                searchCustomerApi();
                // getClientList();
                if (clientAllData?.activeStaff || back === 'appt' || back === 'quotes' || back === 'newinvoice') {
                  let copyclientAllDatas = { ...clientAllData };
                  copyclientAllDatas.clientAllDetails = payload;
                  copyclientAllDatas.appointmentData.client = payload;

                  // setClientAllData(copyclientAllDatas);
                  localStorage.setItem('apptData', JSON.stringify(copyclientAllDatas));
                }
              }
              if (status === 'error') {
                toastMessage(status, msg);
                setError('');
                if (clientAllData?.activeStaff || back === 'appt' || back === 'quotes' || back === 'newinvoice') {
                  let copyclientAllDatas = { ...clientAllData };
                  copyclientAllDatas.clientAllDetails = payload;
                  copyclientAllDatas.appointmentData.client = payload;

                  // setClientAllData(copyclientAllDatas);
                  localStorage.setItem('apptData', JSON.stringify(copyclientAllDatas));
                }
              }
              // }
            } else if (!isMobileValid) {
              setError('mobileno');
              toastMessage('error', mobileMsg);
            }
          } else {
            setError('email');
            toastMessage('error', 'Email is not Valid.');
          }
        } else {
          toastMessage('error', landlineMsg);
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setError('');
      }, errorBorderTime);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const editSubfields = async () => {
    try {
      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      client.siteAddresses.forEach((el) => {
        delete el.tempShow;
      });
      client.associatedContacts.forEach((el) => {
        delete el.tempShow;
      });
      const { associatedContacts, siteAddresses } = client;
      await userInstance().put('/customer/updateSubfields', {
        associatedContacts,
        selectedClient,
        siteAddresses,
      });
      toastMessage('success', msg);
      client.siteAddresses.forEach((el) => {
        el.tempShow = hideClicked;
      });
      client.associatedContacts.forEach((el) => {
        el.tempShow = hideClickedAssociated;
      });
      setClientList([...clientListCopy]);
      setRun(false);
      if (clientid) {
        let copyclientAllDatas = { ...clientAllData };

        copyclientAllDatas.clientAllDetails.siteAddresses = client?.siteAddresses;
        copyclientAllDatas.appointmentData.client.siteAddresses = client?.siteAddresses;
        setClientAllData(copyclientAllDatas);
        localStorage.setItem('apptData', JSON.stringify(copyclientAllDatas));
      }
      // getClientList();
    } catch (error) {
      setRun(false);
      console.log(error);
    }
  };

  const removeClient = async (id, toggleClient) => {
    try {
      const response = await userInstance().put(`/customer/deleteCustomer?id=${id}`);
      getClientList();
      toggleClient(id);
      toastMessage('success', 'Client deleted successfully');
    } catch (error) {
      console.log(error);
    }
  };

  const setBooking = () => {
    setClientData({ ...clientData, showBookings: !clientData.showBookings });
  };
  const backToAppointment = () => {
    // console.log('backToAppointment=>');
    const upType = typee || typeRoute;
    if (upType && crossStatus && back) {
      history.push(`/addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&clientid=${clientid}`);
    } else if (upType) {
      let oldUrl = getCalendarPath();
      const newUrl = `${oldUrl}&clientid=${clientid}`;
      if (upType === 'edit') localStorage.setItem('idClient', clientid);
      history.push(newUrl);
    }
  };

  useEffect(() => {
    if (runEdit === null) {
      setLoading(true);
      getClientList();
    }
    if (runEdit === true) {
      editSubfields();
    }
  }, [skip, runEdit]);

  useEffect(() => {
    if (error) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [error]);

  const pageCount = Math.ceil(pageCounts / limit);

  const closeErrorListTable = () => {
    setShowUploadError(false);
    setShowUploadErrorPopup(true);
  };

  const calculationPoolLitres = (poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd) => {
    let poolCapacity = 0;
    // console.log('cominginthissusiou', {
    //   poolDepth, poolWidth, poolHeight, diameter, deepEnd, shallowEnd,
    // });
    let poolMsg = '';
    if (poolDepth === 'Average Depth - Rectangular Pool') {
      const calValue = !isUsImperial ? 1250 : 5.5 * 7.48;
      poolCapacity = poolWidth * poolHeight * calValue;
    } else if (poolDepth === 'Average Depth - Circular pool') {
      const calValue = !isUsImperial ? 1250 * 0.78 : 5.5 * 7.48;
      poolCapacity = diameter * diameter * calValue;
    } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
      const calValue = !isUsImperial ? 1000 : 5.5 * 7.48;
      const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
      poolCapacity = poolWidth * poolHeight * shallowCalculation * calValue;
    } else if (poolDepth === 'Specific Depths - Circular Pool') {
      const calValue = !isUsImperial ? 780 : 5.5 * 7.48;
      const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
      poolCapacity = diameter * diameter * shallowCalculation * calValue;
    }

    poolCapacity = !isUsImperial
      ? Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000
      : Math.round(parseInt(poolCapacity, 10) / 100) * 100;

    const exceedLimitValue = !isUsImperial ? 2500000 : 660000;
    if (parseInt(poolCapacity, 10) >= exceedLimitValue) {
      poolMsg = 'Exceeded Limit';
    }
    if (!(Number(poolCapacity) >= 0)) poolCapacity = 0;

    return { poolCapacity, poolMsg };
  };

  const handlePoolDepth = (e) => {
    const { value } = e.target;
    const {
      poolWidth, poolHeight, diameter, deepEnd, shallowEnd,
    } = clientData;

    const { poolMsg, poolCapacity } = calculationPoolLitres(value,
      Number(poolWidth),
      Number(poolHeight),
      Number(diameter),
      Number(deepEnd),
      Number(shallowEnd));

    setClientData({
      ...clientData, poolDepth: value, poolMsg, poolCapacity,
    });
  };

  return (

    <AdminWrapper>
      {loading ? <Loader /> : (
        <>
          {!showUploadError ? (
            <>
              <div className=" client-container">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <ClienTable
                        clientList={clientList}
                        setClientList={setClientList}
                        HandleOpenCloseTable={HandleOpenCloseTable}
                        handleFormModal={handleFormModal}
                        handleEditClient={handleEditClient}
                        removeClient={removeClient}
                        setShowHide={setShowHide}
                        showHide={showHide}
                        searchClient={searchClient}
                        setSearchData={setSearchData}
                        searchData={searchData}
                        handleContactModal={handleContactModal}
                        handleEditContact={handleEditContact}
                        handleDeleteContact={handleDeleteContact}
                        handleSiteAddressModal={handleSiteAddressModal}
                        handleEditAddress={handleEditAddress}
                        handleDeleteAddress={handleDeleteAddress}
                        handleVisibilityAddr={handleVisibilityAddr}
                        showAll={showAll}
                        hideClicked={hideClicked}
                        hideAll={hideAll}
                        clientTxt={clientTxt}
                        setClientTxt={setClientTxt}
                        setHideSearch={setHideSearch}
                        isHideSearch={isHideSearch}
                        hideClickedAssociated={hideClickedAssociated}
                        checkAddress={checkAddress}
                        selectedClient={selectedClient}
                        claddressIndex={claddressIndex}
                        selectedIndex={selectedIndex}
                        name={name}
                        backToAppointment={backToAppointment}
                        setCrossStatus={setCrossStatus}
                        crossStatus={crossStatus}
                        setClientIdentity={setClientIdentity}
                        skipValue={skip}
                        setskipValue={setSkip}
                        setShowUploadError={setShowUploadError}
                        setUploadErrors={setUploadErrors}
                        showUploadErrorPopup={showUploadErrorPopup}
                        setShowUploadErrorPopup={setShowUploadErrorPopup}
                        getClientList={getClientList}
                      />
                      <Cli
                        HandleOpenCloseTable={HandleOpenCloseTable}
                        handleChange={handleChange}
                        clientDetails={clientData}
                        handleAddCustomer={handleAddCustomer}
                        showForm={showForm}
                        handleFormModal={handleFormModal}
                        selectedClient={selectedClient}
                        editClient={editClient}
                        handleCountry={handleCountry}
                        selectedCountry={selectedCountry}
                        setBooking={setBooking}
                        poolFinishArray={poolFinishArray}
                        poolTypeArray={poolTypeArray}
                        convertPoolChlorineArray={convertPoolChlorineArray}
                        chlorinationArray={chlorinationArray}
                  // chlorinatorModel={chlorinatorModel}
                        handleChlorinationType={handleChlorinationType}
                        handleChlorination={handleChlorination}
                        handlePoolFinish={handlePoolFinish}
                        error={error}
                        stateArray={stateArray}
                        countries={countries}
                        mobileFormat={mobileFormat}
                        poolDepthArray={poolDepthArray}
                        handlePoolDepth={handlePoolDepth}
                        isUsImperial={isUsImperial}
                        validationData={validationData}
                      />
                      {/* <div className="view-btn">
                  <Button type="button" className="btn add-btn" onClick={handleFormModal}>
                    Add Client
                  </Button>
                </div> */}

                      {deletePopUp && (
                      <DeletePopUp
                  // additionalContactDetails={additionalContactDetails}
                        handlePopUpChange={handlePopUpChange}
                        setDeletePopUp={setDeletePopUp}
                  // deletePopUp={deletePopUp}
                        PopUpText={PopUpText}
                        paraText={paraText}
                      />
                      )}

                      {clientList && !clientIdentity ? (
                        <div className="user-pagination">
                          <ReactPaginate
                            previousLabel="← Previous"
                            nextLabel="Next →"
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName="pagination-chk"
                            previousLinkClassName="pagination__link"
                            nextLinkClassName="pagination__link"
                            disabledClassName="pagination__link--disabled"
                            activeClassName="pagination__link--active"
                            forcePage={selectedPage - 1}
                            renderOnZeroPageCount={null}
                          />
                          {clientList && clientList.length > 0 ? (
                            <div className="no-of-page">
                              Page
                              {' '}
                              {selectedPage}
                              {' '}
                              of
                              {' '}
                              {' '}
                              {pageCount}
                            </div>
                          ) : null }

                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <AddSiteAddress
                  handleSiteAddressModal={handleSiteAddressModal}
                  showSiteAddress={showSiteAddress}
                  addressData={addressData}
                  handleAddressData={handleAddressData}
                  handleAddAddress={handleAddAddress}
                  selectedCountry={selectedCountry}
                  handleSiteAddressCountry={handleSiteAddressCountry}
                  poolFinishArray={poolFinishArray}
                  poolTypeArray={poolTypeArray}
                  convertPoolChlorineArray={convertPoolChlorineArray}
                  chlorinationArray={chlorinationArray}
                  handleSiteChlorinationType={handleSiteChlorinationType}
                  handleSiteChlorination={handleSiteChlorination}
                  handleSitePoolFinish={handleSitePoolFinish}
                  error={error}
                />
                <AddBilingContact
                  showContactModal={showContactModal}
                  handleContactModal={handleContactModal}
                  handleContactData={handleContactData}
                  handleAddBillingContact={handleAddBillingContact}
                  contactData={contactData}
                  error={error}
                  selectedCountry={selectedCountry}
                  handleCountry={handleCountry}
                  mobileFormat={mobileFormat}
                />
              </div>
            </>
          ) : <UploadErrorTable uploadErrors={uploadErrors} handleClose={closeErrorListTable} />}
        </>
      )}
    </AdminWrapper>

  );
}

export default Client;
