/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import React, { useState, useContext } from 'react';
import {
  Form, Table, Button, Accordion, Card, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import uncheckimg from '../../assets/home/uncheck.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import showmore from '../../assets/home/showmoreblue.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import time from '../../assets/home/clock.png';
import search from '../../assets/home/searchgray.png';
import crosss from '../../assets/home/black-icon.png';
import DeletePopUp from '../DeletePopUp/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';

function StaffTable(props) {
  const { userData } = useContext(AuthDataContext);
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const {
    staffList, HandleOpenCloseTable, handleFormModal, handleEditStaff, removeStaff, showHide, setShowHide, handleManageVisible, searchTxt, searchStaff, resendPassword,
  } = props;
  // console.log('staffList=>', staffList);
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delData, setDelData] = useState();
  const PopUpText = 'Delete Staff?';

  const paraText = 'Are you sure to do this?';

  const handlePopUpChange = (val) => {
    // console.log('val', val);
    if (val === 'Yes') {
      removeStaff(delData);
    }
    setDeletePopUp(false);
  };

  // const submitRemove = (id) => {
  //   confirmAlert({
  //     title: 'Delete Staff?',
  //     message: 'Are you sure to do this?',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => removeStaff(id),
  //       },
  //       {
  //         label: 'No',
  //         onClick: () => ' No ',
  //       },
  //     ],
  //   });
  // };
  const submitRemove = (id) => {
    setDeletePopUp(true);
    setDelData(id);
  };

  const handleNestedAccordion = (e) => {
    if (selectedNestedAccordion && selectedNestedAccordion.includes(e)) {
      const data = selectedNestedAccordion.filter((el) => el !== e);
      return setSelectedNestedAccordion(data);
    }
    return setSelectedNestedAccordion([...selectedNestedAccordion, e]);
  };
  const camelCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1);

  const renderStaff = (staff, i) => (
    <Accordion className="mob-accordion">
      <Card>
        <Card.Header>
          <div className="staff-main-heading">
            <Accordion.Toggle as={Button} variant="link" eventKey={i.toString()} onClick={() => handleNestedAccordion(i)}>
              {/* <i className="fas fa-chevron-circle-down" /> */}
              <i className={`fas ${selectedNestedAccordion.includes(i) ? 'far fa-chevron-circle-down' : 'far fa-chevron-circle-up'}`} />
              <span>
                {`${staff.firstname} ${staff.lastname}`}
              </span>
            </Accordion.Toggle>
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => resendPassword(staff._id)}>
                      <span><i className="fa fa-info-circle" aria-hidden="true" /></span>
                      <span> Resend Login Details</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

        </Card.Header>
        <Accordion.Collapse eventKey={i.toString()}>
          <Card.Body>
            <ul className="staff-list">
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Mobile</h3>
                  <h5>
                    <a className="mobile" href={`tel:${staff.mobileNo}`}>
                      {`${staff.mobileNo}`}
                    </a>
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Email</h3>
                  <h5>
                    <a className="email" href={`mailto:${staff.email}`}>
                      {staff.email}
                    </a>
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Access Levels</h3>
                  <h5>
                    {staff.accessLevel}
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Actions</h3>
                  <div className="action-btn">
                    <Button className="edit-btn" onClick={() => handleEditStaff(staff)}>
                      <img src={whiteedit} alt="edit-img" />
                    </Button>
                    {staff._id !== localStorage.getItem('loginStaff') && (
                    <>
                      {!staff.hide ? (
                        <Button className="eye-btn" onClick={() => handleManageVisible({ _id: staff._id, hide: true })}>
                          <i className="fas fa-eye" />
                        </Button>
                      ) : staff.hide && showHide ? (
                        <Button className="eyehide-btn" onClick={() => handleManageVisible({ _id: staff._id, hide: false })}>
                          <i className="fas fa-eye-slash" />
                        </Button>
                      ) : (
                        <Button className="eyehide-btn" onClick={() => handleManageVisible({ _id: staff._id, hide: false })}>
                          <i className="fas fa-eye-slash" />
                        </Button>
                      )}
                    </>
                    )}
                    {
                      !staff.isOwner && staff._id !== localStorage.getItem('loginStaff') ? (
                        <Button className="delete-btn" onClick={() => submitRemove(staff._id)}>
                          <img src={whitedelete} alt="edit-img" />
                        </Button>
                      ) : null
                    }

                  </div>
                </div>
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const filterColumns = (data) => {
    if (staffList.length) {
      // Get column names
      const columns = Object.keys(data[0]);
      // console.log('columns', columns);
      // console.log('columns', columns);

      // Remove by key (firstname)
      const filterColsByKey = columns.filter(
        (c) => c !== 'appointments' && c !== 'shift' && c !== 'userid',
      );

      // OR use the below line instead of the above if you want to filter by index
      // columns.shift()

      return filterColsByKey; // OR return columns
    }
    return [];
  };

  const [dropDownOption, setDropDownOption] = useState('Current Staff');

  const handleDropdown = (name, value) => {
    setDropDownOption(name);
    setShowHide(value);
  };
  return (
    <>
      <div className="main-titles-mob">
        <h3>Staff</h3>
        <div className="client-option service-edit-icon">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <img src={showmore} alt="show" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="action" onClick={() => handleDropdown('Current Staff', null)}>
                <span> Current Staff</span>
              </div>
              <div className="action" onClick={handleFormModal}>
                <span> Add Staff Member</span>
              </div>
              <div className="action" onClick={() => handleDropdown('Show Hidden Staff Only', false)}>
                <span>
                  Show Hidden Staff Only
                </span>
              </div>
              {/* <div className="action" onClick={() => setShowHide(true)}>
                <span> Show All Employees </span>
              </div> */}
              <div className="action" onClick={HandleOpenCloseTable}>
                <span> Working Hours </span>
              </div>
              <div className="action">
                <ExcelFile
                  filename="staff-list"
                  element={(
                    <Button className="btn btn-dwnld">Download in Excel</Button>)}
                >
                  <ExcelSheet data={staffList} name="Test">
                    {(staffList) && filterColumns(staffList).map((col) => (
                      <ExcelColumn label={camelCase(col)} value={col} />
                    ))}
                  </ExcelSheet>
                </ExcelFile>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="target-heading"><h6>{dropDownOption}</h6></div>

      {/* <div className="client-search-box">
        <Form inline>
          <Form.Group controlId="formBasicSearch" className=" search-box">
            <Form.Control
              placeholder="Search"
              onChange={(e) => searchStaff(e.target.value)}
              onKeyDown={onKeyDown}
              value={searchTxt}
            />
            <img src={search} alt="search" />
            <div
              className="remove-text"
              onClick={() => searchStaff('')}
            >
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
        </Form>
        {staffList && staffList.length ? (
          <ExcelFile
            filename="staff-list"
            element={(
              <div className="download-btn">
                <Button className="btn btn-dwnld">Download in XL</Button>
              </div>
            )}
          >
            <ExcelSheet data={staffList} name="Test">
              {filterColumns(staffList).map((col) => (
                <ExcelColumn label={camelCase(col)} value={col} />
              ))}
            </ExcelSheet>
          </ExcelFile>
        ) : null}
      </div> */}

      {/* <div className="staff-upper-form">
        <Form>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicHiddenEmployees">
                <Form.Label>Show Hidden Employees</Form.Label>
                <div className="select-box">
                  <Form.Control as="select" onChange={(e) => setShowHide(JSON.parse(e.target.value))}>
                    <option value={false}>No</option>
                    <option value> Yes </option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
          </div>
        </Form>
      </div>
      <div className="view-btn working-hour">
        <Button type="button" className="btn add-btn blue-btn" onClick={HandleOpenCloseTable}>Working Hours</Button>
      </div> */}

      <div id="table-scroll" className="staff-table table-scroll">
        {/* <Table bordered hover striped id="main-table" className="main-table head-table">
          <thead>
            <tr>
              <th>Employee</th>
              <th>Mobile</th>
              <th>Email</th>
              <th>Access Levels</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {staffList ? (
              <>
                {staffList.length === 0 ? (
                  <tr>
                    <td colSpan="6">
                      <div className="placehoder-text">
                        No Staff Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {staffList.map((staff) => {
                      let jsxData = (
                        <tr>
                          <td>
                            {`${staff.firstname} ${staff.lastname}`}
                          </td>
                          <td>
                            <a className="mobile" href={`tel:${staff.mobileNo}`}>
                              {`${staff.mobileNo}`}
                            </a>
                          </td>
                          <td>
                            <a className="email" href={`mailto:${staff.email}`}>
                              {staff.email}
                            </a>
                          </td>
                          <td>
                            {staff.accessLevel
                    && staff.accessLevel[0].toUpperCase()
                + staff.accessLevel.slice(1)}
                          </td>
                          <td>
                            <div className="action-btn">
                              <Button className="edit-btn" onClick={() => handleEditStaff(staff)}>
                                <span><i className="fas fa-pencil-alt" /></span>
                              </Button>
                              {!staff.hide ? (
                                <Button className="eye-btn" onClick={() => handleManageVisible({ _id: staff._id, hide: true })}>
                                  <span><i className="fas fa-eye" /></span>
                                </Button>
                              ) : staff.hide && showHide ? (
                                <Button className="eyehide-btn" onClick={() => handleManageVisible({ _id: staff._id, hide: false })}>
                                  <span><i className="fas fa-eye-slash" /></span>
                                </Button>
                              ) : null}
                              <Button className="delete-btn" onClick={() => submitRemove(staff._id)}>
                                <img src={uncheckimg} alt="uncheck" />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                      if (showHide === false && staff.hide === true) {
                        jsxData = null;
                      }
                      return jsxData;
                    })}
                  </>
                ) }
              </>
            ) : null}
          </tbody>
        </Table> */}
        <div className="mob-table">
          {staffList ? (
            <>
              {staffList.length === 0 ? (
                <div className="placehoder-text">
                  No Staff Found
                </div>
              ) : (
                <>
                  {staffList.map((staff, i) => {
                    let jsxData = null;
                    if (showHide === true || (showHide === false && staff.hide === true) || (showHide === null && staff.hide === false)) {
                      jsxData = renderStaff(staff, i);
                    }
                    return jsxData;
                  })}
                </>
              ) }
            </>
          ) : null}
        </div>
        {deletePopUp ? <DeletePopUp handlePopUpChange={handlePopUpChange} paraText={paraText} PopUpText={PopUpText} /> : null}
      </div>
    </>
  );
}

StaffTable.propTypes = {
  staffList: PropTypes.func.isRequired,
  HandleOpenCloseTable: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  handleEditStaff: PropTypes.func.isRequired,
  removeStaff: PropTypes.func.isRequired,
  showHide: PropTypes.func.isRequired,
  setShowHide: PropTypes.func.isRequired,
  handleManageVisible: PropTypes.func.isRequired,
  searchTxt: PropTypes.func.isRequired,
  searchStaff: PropTypes.func.isRequired,
  resendPassword: PropTypes.func.isRequired,
};

export default StaffTable;
