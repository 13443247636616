/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
// import CountryDropdown from 'country-dropdown-with-flags-for-react';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import checkimg from '../../assets/home/check1.png';
import { limitWidthHeight } from '../calculator/calculatorUtils';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import { calculateCapacity } from '../../functions/newEvent';

function ClientForm(props) {
  const {
    handleChange,
    clientDetails,
    handleAddCustomer,
    showForm,
    handleFormModal,
    editClient,
    selectedClient,
    selectedCountry,
    handleCountry,
    setBooking,
    poolFinishArray,
    chlorinationArray,
    handleChlorinationType,
    handleChlorination,
    handlePoolFinish,
    error,
    stateArray,
    countries,
    mobileFormat,
    poolDepthArray,
    handlePoolDepth,
    isUsImperial,
    validationData,
  } = props;
  // console.log('error', error, 'dhddh', `${clientDetails.customerType?.toLowerCase()?.split(' ').join('')}name`);
  clientDetails.chlorinationType = clientDetails?.chlorinationType === 'Mineral Pool' ? 'Mineral Pool with Chlorinator' : clientDetails?.chlorinationType;
  const validateMobileNo = (inputObj) => {
    const { value } = inputObj;
    if (value) {
      // console.log('value', value);
      if (value.trim().charAt(0) === '6' && value.trim().charAt(1) === '1' || (value.trim().charAt(0) === '0') || (value.trim().charAt(0) === '+')) {
        toastMessage('error', `Please do not enter ${value.trim().charAt(0) === '0' || value.trim().charAt(0) === '+' ? value.charAt(0).trim() : '61'}  at start of mobile no.`);
        return '';
      }
      return inputObj;
    }
    return inputObj;
  };
  return (
    <Modal
      size="lg"
      show={showForm}
      onHide={!showForm}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal addClient-modal"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={() => handleFormModal()} aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Client Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form client-detail">
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicCompanyType" className="italic-text">
                  <Form.Label>
                    {`${clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}`}
                    Contact
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.customerType}
                      name="customerType"
                      onChange={handleChange}

                    >
                      <option value="Architect's Company">Architect</option>
                      <option value="Builder's Company">Builder</option>
                      <option value="Company">Company</option>
                      <option value="Consultant's Company">Consultant</option>
                      <option value="Householder">Householder</option>
                      <option value="Insurance Company">Insurance Company</option>
                      <option value="Landlord">Landlord</option>
                      <option value="Real Estate Agent">Real Estate Agent</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </div>
                </Form.Group>

              </div>
              {clientDetails.customerType !== 'Householder' ? (

                <div className="col-lg-6">
                  <Form.Group controlId="formBasicInput-1" className="italic-text">
                    <Form.Label>
                      {clientDetails.customerType === 'Other'
                        ? 'Specific Name for Customer Type'
                        : `${clientDetails.customerType} Name`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      onChange={handleChange}
                      value={clientDetails.companyName}
                      placeholder={clientDetails.customerType === 'Other'
                        ? 'Specific Name for Customer Type'
                        : `${clientDetails.customerType} Name`}
                      className={`${
                        error === `${clientDetails?.customerType?.toLowerCase()?.split(' ').join('')}name`
                          ? 'error-text'
                          : ''
                      }`}
                    />
                  </Form.Group>
                </div>

              ) : null}
              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-9" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    Country
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="country"
                      onChange={handleChange}
                      value={clientDetails.country}
                      className={`${
                        error === 'country'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      {countries?.length > 0 && countries.map((el) => <option value={el.name}>{el.name}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>

              </div>
              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-1" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's First Name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="firstname"
                    value={clientDetails.firstname}
                    placeholder="Enter First Name"
                    className={`${
                      error === 'firstname'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-2" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Last Name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="lastname"
                    value={clientDetails.lastname}
                    placeholder="Enter Last Name"
                    className={`${
                      error === 'lastname'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-3" className="number italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Mobile No.
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="number-field">
                    {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                    <div className="number-box">
                      <Dropdown id="mobile">
                        <Dropdown.Toggle variant="success" id="dropdown-basic1">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span />
                          {/* <div className="country-code">{selectedCountry}</div> */}
                        </Dropdown.Toggle>

                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === 'mobileno'
                          ? 'error-text'
                          : ''
                      }`}
                      format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                      onChange={handleChange}
                      name="mobileNo"
                      value={clientDetails.mobileNo}
                      placeholder="Enter Mobile Number"
                      autocomplete="off"
                      isAllowed={validateMobileNo}
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-34" className="number">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Landline No.
                  </Form.Label>
                  <div className="number-field">
                    {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                    <div className="number-box">
                      <Dropdown id="landline">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span />
                          {/* <div className="country-code">{}</div> */}
                        </Dropdown.Toggle>

                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === 'landlineno'
                          ? 'error-text'
                          : ''
                      }`}
                      format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                      onChange={handleChange}
                      name="landlineNo"
                      value={clientDetails.landlineNo}
                      autocomplete="off"
                    />
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-5" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Email Address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={clientDetails.email}
                    placeholder="Enter Email Address"
                    className={`${
                      error === 'email'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-6" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    Street Address
                    {/* <span className="required-field">*</span>  */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="streetAddress"
                    value={clientDetails.streetAddress}
                    placeholder="Enter Street Address"
                    className={`${
                      error === 'streetaddress'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-7" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    City/Town/Suburb
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="suburb"
                    value={clientDetails.suburb}
                    placeholder="Enter City/Town/Suburb"
                    className={`${
                      error === 'suburb'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-8">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    Postal / Zip Code
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="postcode"
                    value={clientDetails.postcode}
                    className={`${
                      error === 'postal/zip code'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-9" className="italic-text">

                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    State
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="state"
                      value={clientDetails.state}
                      placeholder="Enter State Name"
                      className={`${
                        error === 'state'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      {stateArray?.length > 0 && stateArray.map((el) => (
                        <option value={el.name}>
                          {el.isoCode}
                          {' '}
                          -
                          {' '}
                          {el.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>

              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Pool Depth</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.poolDepth}
                      name="poolFinish"
                      onChange={handlePoolDepth}
                    >
                      {poolDepthArray?.map((el) => <option key={el}>{el}</option>) }

                    </Form.Control>
                  </div>
                </Form.Group>
              </div>

              {(clientDetails?.poolDepth === 'Average Depth - Rectangular Pool' || clientDetails?.poolDepth === 'Specific Depths - Rectangular Pool') && (
              <div className="col-lg-6">
                <Form.Group controlId="formBasicInput-10">
                  <Form.Label>
                    {`Pool Size (${!isUsImperial ? 'metres' : 'feet'})`}
                  </Form.Label>
                  <div style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  >
                    <NumberFormat
                      className="form-control mobile-num"
                      onChange={handleChange}
                      name="poolHeight"
                      value={clientDetails.poolHeight}
                      autocomplete="off"
                      isAllowed={limitWidthHeight}
                    />
                    <span style={{
                      padding: '0 10px',
                      fontSize: '16px',
                    }}
                    >
                      X
                    </span>
                    <NumberFormat
                      className="form-control mobile-num"
                      onChange={handleChange}
                      name="poolWidth"
                      value={clientDetails.poolWidth}
                      autocomplete="off"
                      isAllowed={limitWidthHeight}
                    />
                  </div>
                </Form.Group>
              </div>
              )}
              {(clientDetails?.poolDepth === 'Average Depth - Circular pool' || clientDetails?.poolDepth === 'Specific Depths - Circular Pool') && (
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1" className="italic-text">
                    <Form.Label>
                      {`Pool Diameter (${!isUsImperial ? 'metres' : 'feet'})`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="diameter"
                      value={clientDetails?.diameter}
                      onChange={handleChange}
                      className={validationData?.diameter && !clientDetails?.diameter ? 'error-text' : ''}
                    />
                  </Form.Group>
                </div>
              )}
              {(clientDetails?.poolDepth === 'Specific Depths - Rectangular Pool' || clientDetails?.poolDepth === 'Specific Depths - Circular Pool') && (
              <>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicInput-1" className="italic-text">
                    <Form.Label>
                      Shallow End Depth
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="shallowEnd"
                      value={clientDetails?.shallowEnd}
                      onChange={handleChange}
                      className={validationData?.shallowEnd && !clientDetails?.shallowEnd ? 'error-text' : ''}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId="formBasicInput-1" className="italic-text">
                    <Form.Label>
                      Deep End Depth
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="deepEnd"
                      value={clientDetails?.deepEnd}
                      onChange={handleChange}
                      className={validationData?.deepEnd && !clientDetails?.deepEnd ? 'error-text' : ''}
                    />
                  </Form.Group>
                </div>
              </>
              )}

              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-1" className="italic-text">
                  <Form.Label>
                    Pool Size
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="poolCapacity"
                    value={`${
                      !clientDetails.poolMsg
                        ? `${calculateCapacity(clientDetails?.poolDepth, Number(clientDetails?.poolWidth), Number(clientDetails?.poolHeight), Number(clientDetails?.diameter), Number(clientDetails?.deepEnd), Number(clientDetails?.shallowEnd), isUsImperial)
                        } ${!isUsImperial ? 'litres' : 'gallons'}`
                        : clientDetails.poolMsg
                    }`}
                    disabled

                  />
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Pool Finish</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.poolFinish}
                      name="poolFinish"
                      onChange={handlePoolFinish}
                    >
                      {poolFinishArray?.map((el) => <option key={el}>{el}</option>) }

                    </Form.Control>
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Chlorination Type</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails?.chlorinationType}
                      name="chlorinationType"
                      onChange={handleChlorinationType}
                    >
                      {chlorinationArray?.map((el) => <option key={el}>{el}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>

              <div className="col-lg-6">
                <Form.Group controlId="formBasicLocation">
                  {/* {console.log('clientDetails', clientDetails)} */}
                  <Form.Label>Chlorination</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.selectedModel}
                      name="selectedModel"
                      onChange={handleChlorination}
                    >
                      {clientDetails.chlorinatorModel.map((el) => <option value={el.name || el.brandName || el} key={el.name || el.brandName || el}>{el.name || el.brandName || el}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Automaic Invoice Sending</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.invoiceSendType}
                      name="invoiceSendType"
                      onChange={handleChange}
                    >
                      {/* <option value="Select">Select</option> */}
                      <option value="Send Invoices by SMS">Send Invoices by SMS</option>
                      <option value="Send Invoices by Email"> Send Invoices by Email</option>
                      <option value="Send Invoices by SMS & Email"> Send Invoices by SMS & Email</option>
                      <option value="Don't Automatically send">Don't Automatically send</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>

              <div className="col-md-12">
                <Form.Group className="form-radio" onChange={handleChange} name="gender">
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    id="male"
                    checked={clientDetails.gender === 'male'}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    id="female"
                    checked={clientDetails.gender === 'female'}
                  />
                </Form.Group>
              </div>

              <div className="col-md-12">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <div className="client-notes">
                    <Form.Label>Client Notes</Form.Label>
                    <div className="basic">
                      Show on all bookings
                      <Form.Check type="checkbox" onChange={setBooking} checked={clientDetails.showBookings} />
                    </div>
                  </div>
                  <Form.Control as="textarea" rows={3} onChange={handleChange} name="note" value={clientDetails.note} />
                </Form.Group>
              </div>

              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="button"
                    className="btn btn-edit"
                    onClick={() => {
                      handleFormModal();
                    }}
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={selectedClient ? editClient : handleAddCustomer}
                  >
                    {' '}
                    Save/Update
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

ClientForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  clientDetails: PropTypes.func.isRequired,
  handleAddCustomer: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  selectedClient: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  selectedCountry: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  setBooking: PropTypes.func.isRequired,
  poolFinishArray: PropTypes.arrayOf.isRequired,
  chlorinationArray: PropTypes.arrayOf.isRequired,
  handleChlorinationType: PropTypes.func.isRequired,
  handlePoolFinish: PropTypes.func.isRequired,
  handleChlorination: PropTypes.func.isRequired,
  chlorinatorModel: PropTypes.arrayOf.isRequired,
  error: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf.isRequired,
  stateArray: PropTypes.arrayOf.isRequired,
  mobileFormat: PropTypes.string.isRequired,
  poolDepthArray: PropTypes.arrayOf.isRequired,
  handlePoolDepth: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  validationData: PropTypes.objectOf.isRequired,
};

export default ClientForm;
