/* eslint-disable no-multi-assign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Nav, Tabs, Tab,
} from 'react-bootstrap';
import getStrNum from '../../functions/strNumseparator';

const AddNewTextPopup = ({
  addTextpopup, handleTextPopup, setData,
  templateDataCopy, setTemplateDataCopy, setTemplateDat, text, customName, setText, customNameTwo, handleShow,
  customFeilds, setCustomFeilds, tdIndex, setIsNewChange,
}) => {
  console.log('add new text', {
    customNameTwo,
  });
  let sizeCount = 0;
  const Font = [
    "'Exo 2', sans-serif",
    "'Montserrat', sans-serif",
    "'Poppins', sans-serif",
    "'Raleway', sans-serif",
    "'Roboto', sans-serif",
  ];
  let initialCSS = {
    text: '',
    type: 'tr',
    index: 0,
    style: {
      lineHeight: '12px',
      font: "'Exo 2', sans-serif",
      fontStyle: 'Normal',
      color: '#333333',
      fontSize: 9,
      textAlign: 'left',
      textDecoration: 'unset',
      fontWeight: 'unset',
      top: '0px',
      left: '20px',
      position: 'absolute',
    },
    tdStyle1: {
      width: '0%',
    },
    tdStyle2: {
      width: '100%',
      textAlign: 'left',
      height: '14px',
      paddingLeft: '0%',
      paddingTop: '0%',
      paddingBottom: '0px',
    },
  };
  const [customObject, setCustomObject] = useState(initialCSS);

  const setTemplateData = (data, wave) => {
    const { value } = data;
    let parsingValue;
    if (wave === 'text') {
      setCustomObject({ ...customObject, text: value, index: customFeilds.length });
      return;
    }
    if (wave === 'fontSize') {
      parsingValue = parseInt(value, 10);
    } else if (wave === 'lineHeight') {
      parsingValue = `${value}px`;
    } else {
      parsingValue = value;
    }
    let { style } = customObject;
    let oldStyleObject = { ...style };
    oldStyleObject[wave] = parsingValue;
    setCustomObject({ ...customObject, style: oldStyleObject });
  };

  const setNewText = () => {
    // console.log('hsdhghhgr ==>', { customObject, customFeilds });
    const addtext = customObject?.text || '';
    // console.log('addtext===>', addtext);
    if (addtext) {
      // console.log('templateDataCopy==========>', { customObject });
      customFeilds.push(customObject);
      setCustomFeilds(customFeilds);
    }
    handleTextPopup();
    setIsNewChange(true);
  };

  const setTemplateLocationSize2 = (data, type = 'px') => {
    const { name, value } = data || {};
    console.log('value===>', { value, name, ans: (name === 'left' && (value !== '' && value !== '0' && value !== 'px')) });
    const keyVal = `${value}px`;
    let { style } = customObject;
    let oldtdStyleObject = { ...style };
    if ((name === 'left' && (value !== '' && value !== '0' && value !== 'px'))) {
      oldtdStyleObject.position = 'absolute';
      oldtdStyleObject[name] = keyVal;
    } else if ((name === 'top' && (value !== '' && value !== '0' && value !== 'px'))) {
      oldtdStyleObject.position = 'absolute';
      oldtdStyleObject[name] = keyVal;
    } else {
      oldtdStyleObject[name] = 'initial';
    }
    if (oldtdStyleObject.left === 'initial' && oldtdStyleObject.top === 'initial') {
      oldtdStyleObject.position = 'initial';
    }
    setCustomObject({ ...customObject, style: oldtdStyleObject });
  };
  const setTemplateLocationSize = (data, type) => {
    const { name, value } = data || {};
    const keyVal = value + type;
    const { tdStyle1, tdStyle2 } = customObject || {};
    let oldtdStyle1Object = { ...tdStyle1 };
    let oldtdStyle2Object = { ...tdStyle2 };
    if (tdIndex === 'tdStyle1') {
      oldtdStyle1Object[name] = keyVal;
      setCustomObject({ ...customObject, tdStyle1: oldtdStyle1Object });
    } else {
      oldtdStyle2Object[name] = keyVal;
      setCustomObject({ ...customObject, tdStyle2: oldtdStyle2Object });
    }
  };
  const addjustElementWidth = (data, type) => {
    const { name, value } = data || {};
    let { tdStyle2 } = customObject;
    let oldStyleObject = { ...tdStyle2 };
    oldStyleObject.width = `${value}%`;
    setCustomObject({ ...customObject, tdStyle2: oldStyleObject });
  };
  return (
    <Modal
      size="md"
      show={addTextpopup}
      onHide={handleTextPopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup change-text-popup add-field-popup"
    >
      <Modal.Body>
        <div className="appointment-container  preview-popup">
          <div className="change-image-section">
            <div className="preview-text">
              <h6>Text Wording</h6>
              <span style={{
                fontFamily: `${customObject.style.fontFamily}`,
                fontSize: `${customObject.style.fontSize}px`,
                color: `${customObject.style.color}`,
                fontStyle: `${customObject.style.fontStyle}`,
                textDecoration: `${customObject.style.textDecoration}`,
                fontWeight: `${customObject.style.fontWeight}`,
                lineHeight: `${customObject.style.lineHeight}`,
                border: '1px solid rgb(220, 217, 217)',
                padding: '10px',
                marginBottom: '15px',
                display: 'flex',
                borderRadius: '5px',
              }}
              >
                {customObject.text}
              </span>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows={3} value={customObject.text} onChange={({ target: { value, name } }) => setTemplateData({ value, name }, 'text')} />
              </Form.Group>
            </div>
            <h6>Type Characteristics</h6>
            <div className="field-location-section">
              <div className="field-location">

                <Form.Group controlId="formGridEmail">
                  <Form.Label>Typeface</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.font} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'font')}>
                      {Font.map((f) => (
                        <option value={f}>{f}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Point Size</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontSize} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontSize')}>
                      {Array.from(Array(20).keys()).map(() => {
                        sizeCount += 1;
                        return (
                          <option value={sizeCount}>{sizeCount}</option>
                        );
                      })}
                    </select>
                  </div>
                </Form.Group>

              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Style</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontStyle} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontStyle')}>
                      <option value="normal">Normal</option>
                      <option value="Italic">Italic</option>
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Line Spacing</Form.Label>
                  <Form.Control type="number" value={getStrNum(customObject?.style?.lineHeight)?.num} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'lineHeight')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Alignment</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textAlign} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textAlign')}>
                      <option value="left">Flush Left</option>
                      <option value="center">Centered</option>
                      <option value="right">Flush Right</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Colour</Form.Label>
                  <Form.Control type="color" value={customObject?.style?.color} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'color')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Effects</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textDecoration} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textDecoration')}>
                      <option value="underline">UnderLine</option>
                      <option value="line-through">Strikeout</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Weight</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontWeight} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontWeight')}>
                      <option value="bold">Bold</option>
                      <option value="bolder">Bolder</option>
                      <option value="lighter">Lighter</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="text-popup-spacing">
              <h6>Text Field Location & Size</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.style?.top)?.num} name="top" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(customObject?.style?.top)?.str)} placeholder="0" />
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Distance from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.style?.left)?.num} name="left" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(customObject?.style?.left)?.str)} placeholder="0" />
                  </Form.Group>
                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Height</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.height)?.num} name="height" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.height)?.str)} />
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Width</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.width)?.num} name="width" onChange={(e) => addjustElementWidth(e.target, getStrNum(customObject?.[tdIndex]?.width)?.str)} />
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* <div className="text-popup-spacing">
              <h6>Spacing from Top & Left</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Spacing from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.paddingTop)?.num} name="paddingTop" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.paddingTop)?.str)} placeholder="0" />
                  </Form.Group>

                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Spacing from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.paddingLeft)?.num} name="paddingLeft" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.paddingLeft)?.str)} placeholder="0" />
                  </Form.Group>
                </div>
              </div>
            </div> */}
          </div>
          <div className="template-btn">
            <Button type="submit" className="delete-btn">Delete Field</Button>
            <Button className="save-btn" onClick={setNewText}>Save/ Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
AddNewTextPopup.propTypes = {
  addTextpopup: PropTypes.func.isRequired,
  handleTextPopup: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  templateDataCopy: PropTypes.objectOf.isRequired,
  setTemplateDataCopy: PropTypes.func.isRequired,
  setTemplateDat: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  customName: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  customNameTwo: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
  setCustomFeilds: PropTypes.func.isRequired,
  setIsNewChange: PropTypes.func.isRequired,
  tdIndex: PropTypes.string.isRequired,
};
export default AddNewTextPopup;
