import React, { useState, useEffect, useRef } from 'react';
// import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const DownloadCSV = ({
  csvDataToRender, setCsvDataToRender, closeDownloadModal, handleDownloadCSVFile, view,
}) => {
  const [checkData, setCheckData] = useState({});
  const handleCheckBox = (e, index) => {
    const old = [...csvDataToRender];
    const changeKeys = old[index];
    let newArr = [];
    let dataArr = changeKeys.rowsAddedId;
    if (view === 'service') {
      dataArr = [];
      changeKeys.rowsAddedId.forEach((el) => {
        el.subCategory.forEach((ele) => {
          dataArr.push(ele);
        });
      });
    }

    dataArr.forEach((el) => {
      let obj = el;
      delete obj.sort;
      let entries = Object.entries(obj);
      let fromIndex;
      let toIndex;
      let capsEntries = entries.map((entry, i) => {
        let keyValues = entry;
        if (keyValues[0] === 'poolHeight') {
          keyValues = ['PoolLength', entry[1]];
        } else if (keyValues[0] === 'customerType') {
          keyValues = ['ContactType', entry[1]];
          toIndex = i + 1;
        } else if (keyValues[0] === 'companyName') {
          keyValues = ['Company Name', entry[1]];
        } else if (keyValues[0] === 'country') {
          keyValues = ['Country', entry[1]];
        } else if (keyValues[0] === 'firstname') {
          keyValues = ['ContactsFirst Name', entry[1]];
        } else if (keyValues[0] === 'lastname') {
          keyValues = ['ContactsLast Name', entry[1]];
        } else if (keyValues[0] === 'mobileNo') {
          keyValues = ['ContactsMobile Number', entry[1]];
        } else if (keyValues[0] === 'landlineNo') {
          keyValues = ['ContactsLandlineNo', entry[1]];
        } else if (keyValues[0] === 'state') {
          keyValues = ['State', entry[1]];
        } else if (keyValues[0] === 'city') {
          keyValues = ['City', entry[1]];
        } else if (keyValues[0] === 'suburb') {
          keyValues = ['City/Town/Suburb', entry[1]];
        } else if (keyValues[0] === 'poolWidth') {
          keyValues = ['Pool Width', entry[1]];
        } else if (keyValues[0] === 'streetAddress') {
          keyValues = ['Street Address', entry[1]];
        } else if (keyValues[0] === 'poolFinish') {
          keyValues = ['PoolFinish', entry[1]];
        } else if (keyValues[0] === 'chlorinationType') {
          keyValues = ['ChlorinationType', entry[1]];
        } else if (keyValues[0] === 'chlorination') {
          keyValues = ['Chlorination', entry[1]];
        } else if (keyValues[0] === 'invoiceSendType') {
          keyValues = ['AutomaticInvoiceSending', entry[1]];
        } else if (keyValues[0] === 'gender') {
          keyValues = ['Gender', entry[1]];
        } else if (keyValues[0] === 'note') {
          keyValues = ['ClientNotes', entry[1]];
        } else if (keyValues[0] === 'postcode') {
          keyValues = ['Postal / Zip Code', entry[1]];
        } else if (keyValues[0] === 'companyName') {
          fromIndex = i;
        } else if (keyValues[0] === 'postcode') {
          keyValues = ['ZipCode', entry[1]];
        }
        return [
          keyValues[0][0].toUpperCase() + keyValues[0].slice(1),
          keyValues[1],
        ];
      });
      const element = capsEntries.splice(fromIndex, 1)[0];
      capsEntries.splice(toIndex, 0, element);
      let capsPopulations = Object.fromEntries(capsEntries);
      newArr.push(capsPopulations);
    });

    changeKeys.rowsAddedId = newArr;
    setCheckData(old[index]);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal download-csv-popup'
          ) {
            closeDownloadModal();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal download-csv-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <h6>Select CSV to download</h6>
          <div className="appointment-view">
            {csvDataToRender && csvDataToRender.length > 0
              ? (
                <>
                  {csvDataToRender.map((el, i) => (
                    <>
                      <div className="pending-water-test">
                        <h3>{el?.name}</h3>
                        <div className="open-date client-notes">
                          <div className="basic">
                            <Form.Check
                              type="checkbox"
                              value={el?._id}
                              onChange={(e) => handleCheckBox(e, i)}
                              checked={el._id === checkData._id || false}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  <div>No CSV to Downloads</div>
                </>
              )}

            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => closeDownloadModal()}>
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save action csvUploads"
                onClick={() => {
                  // toastMessage('success', 'CSV downloaded');
                  handleDownloadCSVFile('single', checkData?.name, checkData?.rowsAddedId);
                }}
              >
                Select
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DownloadCSV.propTypes = {
  csvDataToRender: PropTypes.arrayOf.isRequired,
  setCsvDataToRender: PropTypes.func.isRequired,
  closeDownloadModal: PropTypes.func.isRequired,
  handleDownloadCSVFile: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};
export default DownloadCSV;
