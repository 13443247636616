import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Button, Modal,
} from 'react-bootstrap';
import getStrNum from '../../functions/strNumseparator';
import ColorPopup from './colorPopup';
import { validateDataRangeText, valueConverterToOther, valueConverterToPx } from '../../functions';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const ChangeTextPopup = ({
  textPopup, handleTextPopup, setData,
  templateDataCopy, setTemplateDataCopy, setTemplateDat, text, customName, setText, customNameTwo, placeholderText, tdIndex, templateDataCopy2, widthValue, setTemplateData, setIsNewChange,
}) => {
  const { userData } = useContext(AuthDataContext);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';
  let sizeCount = 0;
  const Font = [
    "'Exo 2', sans-serif",
    "'Montserrat', sans-serif",
    "'Poppins', sans-serif",
    "'Raleway', sans-serif",
    "'Roboto', sans-serif",
  ];
  const [customObject, setCustomObject] = useState(text);
  const [color, setColor] = useState('#333333');
  const [openColorPopup, setOpenColorPopup] = useState(false);
  const [copyNewTemplateRecord, setcopyNewTemplateRecord] = useState([]);
  const [errors, setErrors] = useState('');
  const [updatedValues, setUpdatedValues] = useState([]);
  const [dummy, setDummy] = useState(false);

  useEffect(() => {
    const oldObject = JSON.parse(JSON.stringify(templateDataCopy));
    const customcheck = JSON.parse(JSON.stringify(oldObject[customNameTwo]));
    setColor(customcheck?.style?.color);
    setCustomObject(customcheck);
    setcopyNewTemplateRecord(oldObject);
    updateMeasurement(customcheck);
  }, []);

  useEffect(() => {
    let dd = document?.getElementById('newTexttt')?.offsetWidth;
    let ddheight = document?.getElementById('newTexttt')?.offsetHeight;
    if (dd) {
      let str = document.getElementById('newTexttt').innerText;
      const spacesCount = str.split(' ').length - 1;
      let dd1 = dd + 10 + spacesCount;
      let oldStyleObjectt = { ...customObject };
      let newWidth = `${dd1}px`;
      oldStyleObjectt[tdIndex].width = `${newWidth}`;

      const convertedVal = valueConverterToOther(newWidth, countryType);
      let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
      copyupdatedValues[tdIndex].width = convertedVal;
      setUpdatedValues(copyupdatedValues);
      setCustomObject(oldStyleObjectt);

      let oldState = JSON.parse(JSON.stringify(copyNewTemplateRecord));
      oldState[customNameTwo][tdIndex].width = `${dd1}px`;
      // let oldtdStyle2Object = oldState[customNameTwo][tdIndex]?.width;
      // oldState[customNameTwo].tdStyle2 = oldtdStyle2Object;
      setcopyNewTemplateRecord(oldState);
      setDummy(false);
    }
  }, [dummy]);
  const handleTemplateData = (data, wave) => {
    setDummy(true);
    const { value } = data;
    let oldState = [...copyNewTemplateRecord];
    if (wave === 'text') {
      oldState[customNameTwo].text = value;
      setCustomObject({ ...customObject, text: value });
      setcopyNewTemplateRecord(oldState);
      return;
    }
    let { style } = oldState[customNameTwo];
    let oldStyleObject = { ...style };
    let parsingValue;

    if (wave === 'fontSize') {
      // parsingValue = parseInt(value, 10);
      parsingValue = `${value}px`;
    } else if (wave === 'lineHeight') {
      parsingValue = `${value}px`;
    } else {
      parsingValue = value;
    }

    oldStyleObject[wave] = parsingValue;
    oldState[customNameTwo].style = oldStyleObject;
    setCustomObject({ ...customObject, style: oldStyleObject });
    setcopyNewTemplateRecord(oldState);
  };

  const deleteFeild = () => {
    const oldObject = JSON.parse(JSON.stringify(copyNewTemplateRecord));
    oldObject[customNameTwo].text = '';
    setcopyNewTemplateRecord(oldObject);
    setTemplateDat(oldObject);
    setTemplateDataCopy(oldObject);
    handleTextPopup();
  };

  const setTemplateLocationSize = (data, type) => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    const keyVal = `${value}px`;
    let copyObjforupdate2 = JSON.parse(JSON.stringify(updatedValues));
    let oldState = [...copyNewTemplateRecord];

    const { tdStyle1, tdStyle2, tdStyle3 } = oldState[customNameTwo];
    let oldtdStyle1Object = { ...tdStyle1 };
    let oldtdStyle2Object = { ...tdStyle2 };
    let oldtdStyle3Object = { ...tdStyle3 };

    if (tdIndex === 'tdStyle1') {
      oldtdStyle1Object[name] = keyVal;
      oldState[customNameTwo].tdStyle1 = oldtdStyle1Object;
      setCustomObject({ ...customObject, tdStyle1: oldtdStyle1Object });
      setcopyNewTemplateRecord(oldState);
      copyObjforupdate2.tdStyle1[name] = OriginalVal;
    } else if (tdIndex === 'tdStyle2') {
      oldtdStyle2Object[name] = keyVal;
      oldState[customNameTwo].tdStyle2 = oldtdStyle2Object;
      setCustomObject({ ...customObject, tdStyle2: oldtdStyle2Object });
      copyObjforupdate2.tdStyle2[name] = OriginalVal;
      setcopyNewTemplateRecord(oldState);
    } else {
      oldtdStyle3Object[name] = keyVal;
      oldState[customNameTwo].tdStyle3 = oldtdStyle3Object;
      setCustomObject({ ...customObject, tdStyle3: oldtdStyle3Object });
      copyObjforupdate2.tdStyle3[name] = OriginalVal;
      setcopyNewTemplateRecord(oldState);
    }
    setUpdatedValues(copyObjforupdate2);
  };

  const setTemplateLocationSize2 = (data, type = 'px') => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);

    const { isValid, error } = validateDataRangeText(value, name, countryType);
    setErrors(error);
    if (isValid) {
      const keyVal = `${value}px`;
      let oldState = [...copyNewTemplateRecord];
      let { style } = oldState[customNameTwo];
      let oldtdStyleObject = { ...style };
      let copyObjforupdate = JSON.parse(JSON.stringify(updatedValues));
      oldState[customNameTwo].style = oldtdStyleObject;
      if ((name === 'left' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject[name] = keyVal;
        copyObjforupdate.style[name] = OriginalVal;
      } else if ((name === 'top' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject[name] = keyVal;
        copyObjforupdate.style[name] = OriginalVal;
      } else {
        oldtdStyleObject[name] = 'initial';
        copyObjforupdate.style[name] = 'initial';
      }
      if (oldtdStyleObject.left === 'initial' && oldtdStyleObject.top === 'initial') {
        oldtdStyleObject.position = 'initial';
        copyObjforupdate.style.position = 'initial';
      }
      // let copyofcustomObject = JSON.parse(JSON.stringify(customObject));
      // copyofcustomObject.style = oldtdStyleObject;
      setUpdatedValues(copyObjforupdate);
      // setCustomObject(copyofcustomObject);
      setCustomObject({ ...customObject, style: oldtdStyleObject });
      setcopyNewTemplateRecord(oldState);
    }
  };

  const addjustElementWidth = (data, type) => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    let copyObjforupdate3 = JSON.parse(JSON.stringify(updatedValues));

    let oldState = JSON.parse(JSON.stringify(templateDataCopy));
    let {
      tdStyle1, type: trType, tdStyle2, tdStyle3,
    } = oldState[customNameTwo];

    const trLength = templateDataCopy.filter((el) => el.type === trType)?.length;
    // const restWidth = 100 - parseFloat(value);
    let oldtdStyle1Object = { ...tdStyle1 };
    let oldtdStyle2Object = { ...tdStyle2 };
    let oldtdStyle3Object = { ...tdStyle3 };
    if (trLength > 1) {
      let copyCustomeObj = { ...customObject };
      const prenexIndex = tdIndex === 'tdStyle1' ? customNameTwo + 1 : tdIndex === 'tdStyle3' ? customNameTwo : customNameTwo - 1;
      let { tdStyle1: newtdStyle1, tdStyle2: newtdStyle2, tdStyle3: newtdStyle3 } = templateDataCopy2[prenexIndex];
      let oldtdStyle1Object2 = newtdStyle1 ? JSON.parse(JSON.stringify(newtdStyle1)) : {};
      // { ...newtdStyle1 };
      let oldtdStyle2Object2 = newtdStyle2 ? JSON.parse(JSON.stringify(newtdStyle2)) : {};
      // { ...newtdStyle2 };

      let oldtdStyle2Object3 = newtdStyle3 ? JSON.parse(JSON.stringify(newtdStyle3)) : {};
      // { ...newtdStyle2 };

      if (tdIndex === 'tdStyle1') {
        oldtdStyle1Object.width = `${value}px`;
        // oldtdStyle2Object2.width = `${restWidth}%`;
        oldState[customNameTwo].tdStyle1 = oldtdStyle1Object;
        oldState[prenexIndex].tdStyle2 = oldtdStyle2Object2;
        copyCustomeObj.tdStyle1 = oldtdStyle1Object;
        copyObjforupdate3.tdStyle1.width = OriginalVal;
      } else if (tdIndex === 'tdStyle3') {
        oldtdStyle2Object3.width = `${value}px`;
        // oldtdStyle1Object2.width = `${restWidth}%`;
        copyCustomeObj.tdStyle3 = oldtdStyle2Object3;
        oldState[customNameTwo].tdStyle3 = oldtdStyle2Object3;
        oldState[prenexIndex].tdStyle1 = oldtdStyle1Object2;
        copyObjforupdate3.tdStyle3.width = OriginalVal;
      } else {
        oldtdStyle2Object.width = `${value}px`;
        copyCustomeObj.tdStyle2 = oldtdStyle2Object;
        oldState[customNameTwo].tdStyle2 = oldtdStyle2Object;
        oldState[prenexIndex].tdStyle1 = oldtdStyle1Object2;
        copyObjforupdate3.tdStyle2.width = OriginalVal;
      }
      setCustomObject(copyCustomeObj);
      setcopyNewTemplateRecord(oldState);
    }
    if (trLength === 1) {
      if (tdIndex === 'tdStyle1') {
        oldtdStyle1Object.width = `${value}px`;
        copyObjforupdate3.tdStyle1.width = OriginalVal;
      } else {
        oldtdStyle2Object.width = `${value}px`;
        copyObjforupdate3.tdStyle2.width = OriginalVal;
      }
      oldState[customNameTwo].tdStyle1 = oldtdStyle1Object;
      oldState[customNameTwo].tdStyle2 = oldtdStyle2Object;
      let copyCustomeObj = { ...customObject };
      copyCustomeObj.tdStyle1 = oldtdStyle1Object;
      copyCustomeObj.tdStyle2 = oldtdStyle2Object;
      setCustomObject(copyCustomeObj);
      setcopyNewTemplateRecord(oldState);
    }
    setUpdatedValues(copyObjforupdate3);
  };

  const handleColorPopup = () => {
    setOpenColorPopup(!openColorPopup);
  };
  const handleSave = () => {
    let oldState = [...copyNewTemplateRecord];
    let { style } = oldState[customNameTwo];
    let oldStyleObject = { ...style };
    oldStyleObject.color = color;
    oldState[customNameTwo].style = oldStyleObject;
    setTemplateDataCopy(oldState);
    setTemplateData(oldState);
    handleTextPopup();
    setIsNewChange(true);
    // setData();
  };

  const updateMeasurement = (updateData) => {
    if (updateData?.style) {
      if (updateData?.style?.top || updateData?.style?.left || updateData[tdIndex]?.height || updateData[tdIndex]?.width) {
        let topVal = valueConverterToOther(updateData.style.top, countryType);
        updateData.style.top = topVal;
        setUpdatedValues(updateData);
        let leftVal = valueConverterToOther(updateData.style.left, countryType);
        updateData.style.left = leftVal;
        setUpdatedValues(updateData);

        let heightVal = valueConverterToOther(updateData[tdIndex]?.height, countryType);
        updateData[tdIndex].height = heightVal;
        setUpdatedValues(updateData);

        let widthVal = valueConverterToOther(updateData[tdIndex].width, countryType);
        updateData[tdIndex].width = widthVal;
        setUpdatedValues(updateData);
      }
    }
  };
  return (
    <Modal
      size="md"
      show={textPopup}
      onHide={handleTextPopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup change-text-popup"
    >
      <Modal.Body>
        <div className="appointment-container preview-popup">
          <div className="change-image-section">
            {customObject.textFeild ? (
              <div className="preview-text">
                <h6>{placeholderText || 'Database Field'}</h6>
                <span style={{
                  fontFamily: `${customObject?.style?.fontFamily}`,
                  fontSize: `${customObject?.style?.fontSize}`,
                  color: `${customObject?.style?.color}`,
                  fontStyle: `${customObject?.style?.fontStyle}`,
                  textDecoration: `${customObject?.style?.textDecoration}`,
                  fontWeight: `${customObject?.style?.fontWeight}`,
                  lineHeight: `${customObject?.style?.lineHeight}`,
                  border: '1px solid rgb(220, 217, 217)',
                  padding: '10px',
                  marginBottom: '15px',
                  display: 'flex',
                  borderRadius: '5px',
                }}
                >
                  <span id="newTexttt">{customObject.text}</span>
                </span>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={3} value={customObject.text} onChange={({ target: { value, name } }) => handleTemplateData({ value, name }, 'text')} />
                </Form.Group>
              </div>
            ) : (
              <div className="preview-text">
                <h6>{placeholderText || 'Text Wording'}</h6>
                <span style={{
                  fontFamily: `${customObject?.style?.fontFamily}`,
                  fontSize: `${customObject?.style?.fontSize}`,
                  color: `${customObject?.style?.color}`,
                  fontStyle: `${customObject?.style?.fontStyle}`,
                  textDecoration: `${customObject?.style?.textDecoration}`,
                  fontWeight: `${customObject?.style?.fontWeight}`,
                  lineHeight: `${customObject?.style?.lineHeight}`,
                  border: '1px solid rgb(220, 217, 217)',
                  padding: '10px',
                  marginBottom: '15px',
                  display: 'flex',
                  borderRadius: '5px',
                }}
                >
                  <span id="newTexttt">{customObject.text}</span>
                </span>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={3} value={customObject.text} />
                </Form.Group>
              </div>
            )}
            <h6>Type Characteristics</h6>
            <div className="field-location-section">
              <div className="field-location">

                <Form.Group controlId="formGridEmail">
                  <Form.Label>Typeface</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontFamily} onChange={({ target: { name, value } }) => handleTemplateData({ name, value }, 'fontFamily')}>
                      {Font.map((f) => (
                        <option value={f}>{f}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Point Size</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={getStrNum(customObject?.style?.fontSize)?.num} onChange={({ target: { name, value } }) => handleTemplateData({ name, value }, 'fontSize')}>
                      {Array.from(Array(20).keys()).map(() => {
                        sizeCount += 1;
                        return (
                          <option value={sizeCount}>{sizeCount}</option>
                        );
                      })}
                    </select>
                  </div>
                </Form.Group>

              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Style</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontStyle} onChange={({ target: { name, value } }) => handleTemplateData({ name, value }, 'fontStyle')}>
                      <option value="normal">Normal</option>
                      <option value="Italic">Italic</option>
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Line Spacing</Form.Label>
                  <Form.Control type="number" value={getStrNum(customObject?.style?.lineHeight)?.num} onChange={({ target: { name, value } }) => handleTemplateData({ name, value }, 'lineHeight')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Alignment</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textAlign} onChange={({ target: { name, value } }) => handleTemplateData({ name, value }, 'textAlign')}>
                      <option value="left">Flush Left</option>
                      <option value="center">Centered</option>
                      <option value="right">Flush Right</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location color-selection">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Colour</Form.Label>
                  <div className="outer-field">
                    <div
                      onClick={handleColorPopup}
                      style={{
                        width: '100%',
                        height: 50,
                        marginTop: 20,
                        backgroundColor: color,
                      }}
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Effects</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textDecoration} onChange={({ target: { name, value } }) => handleTemplateData({ name, value }, 'textDecoration')}>
                      <option value="underline">UnderLine</option>
                      <option value="line-through">Strikeout</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Weight</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontWeight} onChange={({ target: { name, value } }) => handleTemplateData({ name, value }, 'fontWeight')}>
                      <option value="bold">Bold</option>
                      <option value="bolder">Bolder</option>
                      <option value="lighter">Lighter</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="text-popup-spacing">
              <h6>Text Field Location & Size</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.style?.top)?.num} name="top" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(updatedValues?.style?.top)?.str)} placeholder="0" />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.top && <p className="error-msg">{errors.top}</p>}
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Distance from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.style?.left)?.num} name="left" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(updatedValues?.style?.left)?.str)} placeholder="0" />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.left && <p className="error-msg">{errors.left}</p>}
                  </Form.Group>
                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Height</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.[tdIndex]?.height)?.num} name="height" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(updatedValues?.[tdIndex]?.height)?.str)} />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.height && <p className="error-msg">{errors.height}</p>}
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Width</Form.Label>

                    <Form.Control type="number" value={getStrNum(updatedValues?.[tdIndex]?.width)?.num} name="width" onChange={(e) => addjustElementWidth(e.target, getStrNum(updatedValues?.[tdIndex]?.width)?.str)} />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.width && <p className="error-msg">{errors.width}</p>}
                  </Form.Group>
                </div>
              </div>
            </div>

            {/* <div className="text-popup-spacing">
              <h6>Spacing from Top</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Spacing from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.paddingTop)?.num} name="paddingTop" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.paddingTop)?.str)} placeholder="0" />
                  </Form.Group>

                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Spacing from Bottom</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.[tdIndex]?.paddingBottom)?.num} name="paddingBottom" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(customObject?.[tdIndex]?.paddingBottom)?.str)} placeholder="0" />
                  </Form.Group>
                </div>
              </div>
            </div> */}

          </div>
          {/* ${customObject.textFeild ? 'new-btn' : 'new-btn-section'} */}
          <div className="template-btn view-btn  new-btn ">
            {customObject.textFeild ? (
              <Button className="delete-btn" onClick={deleteFeild}>Delete Field</Button>
            ) : (
              <Button className="delete-btn" onClick={handleTextPopup}>Cancel</Button>
            )}
            {/* <Button className="delete-btn" onClick={deleteFeild}>Delete Field</Button> */}
            <Button className="save-btn" onClick={handleSave}>Save/ Update</Button>
          </div>
        </div>
        {openColorPopup && (
          <ColorPopup setColor={setColor} handleColorPopup={handleColorPopup} color={color} />
        )}
      </Modal.Body>
    </Modal>

  );
};
ChangeTextPopup.propTypes = {
  textPopup: PropTypes.func.isRequired,
  handleTextPopup: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  templateDataCopy: PropTypes.objectOf.isRequired,
  setTemplateDataCopy: PropTypes.func.isRequired,
  setTemplateDat: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  customName: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  customNameTwo: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  tdIndex: PropTypes.string.isRequired,
  templateDataCopy2: PropTypes.arrayOf.isRequired,
  widthValue: PropTypes.string.isRequired,
  setTemplateData: PropTypes.func.isRequired,
  setIsNewChange: PropTypes.func.isRequired,
};
export default ChangeTextPopup;
