import React from 'react';
import { Form, Button } from 'react-bootstrap';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const ServiceView = (props) => {
  const {
    handleBookingData,
    bookingData,
    editData,
    servicesLength,
    handleChangeCustom,
    handleTimePicker,
    convertToReadableDuration,
    handleDurationPicker,
    handleHourPicker,
    subcategoryTxt,
    endTimeNew,
    timeZone,
    timeDisplay,
  } = props;
  // console.log('timepikkk', editData, servicesLength);

  const quantityLabel = bookingData.pricingType === 'fixedCost';
  const MAX_VAL = 24;

  const withValueLimitHours = (inputObj) => {
    console.log('inputObj', inputObj);
    const { value } = inputObj;
    if (value <= MAX_VAL) return inputObj;
    toastMessage('error', 'Enter an amount 24.0 or less.');
    return '';
  };

  const withValueLimitSellPrice = (inputObj) => {
    const { value } = inputObj;
    const toCheck = 9999999999.999;
    // console.log('toCheck', toCheck);
    if (value <= toCheck) return inputObj;
    toastMessage('error', 'Enter an amount 9,999,999,999.999 or less.');
    return '';
  };
  return (
    <div className="row">
      <div className="col-md-12 col-12">
        <Form.Group controlId="formBasicHiddenEmployees">
          <Form.Label>Fixed Price, Hourly Rate or Add Note</Form.Label>
          <div className="select-box">
            {/* <i className="fas fa-sort-down" /> */}
            <Form.Control
              as="select"
              onChange={handleBookingData}
              name="pricingType"
              value={bookingData.pricingType}
              disabled={!bookingData.subcategory}
            >
              <option value="fixedCost">Fixed Cost</option>
              <option value="hourly">Hourly Rate</option>
              <option value="addNote">Add Note</option>
            </Form.Control>
          </div>
        </Form.Group>
      </div>

      {bookingData.pricingType === 'addNote' ? '' : (
        <>
          <div className="col-md-6 col-6 half">
            <Form.Group controlId="formBasicHiddenEmployees">
              <Form.Label>Appointment Time</Form.Label>
              <Button
                onClick={handleTimePicker}
              >
                {' '}
                {/* {moment(bookingData.startTime, ['HH:mm']).tz(timeZone).format(timeDisplay)} */}
                {moment(bookingData.startTime, ['HH:mm']).format('h:mm a')}
              </Button>
            </Form.Group>
          </div>
          {quantityLabel ? (
            <div className="col-md-6 col-6 halfright">
              <Form.Group controlId="formBasicHiddenEmployees">
                <Form.Label>Duration</Form.Label>
                <Button
                  onClick={handleDurationPicker}
                  disabled={!bookingData.subcategory}
                >
                  {convertToReadableDuration(bookingData.duration)}
                </Button>
              </Form.Group>
            </div>
          ) : (
            <div className="col-md-6 col-6 halfright">
              <Form.Group controlId="formBasicHiddenEmployees">
                <Form.Label>No. of Hours</Form.Label>
                {/* <NumberFormat
              thousandSeparator
              type="text"
              placeholder="No. of Hours"
              name="quantity"
              value={bookingData.quantity}
              onChange={handleBookingData}
              isAllowed={withValueLimitHours}
              disabled={!bookingData.subcategory}
            /> */}
                <Button
                  onClick={handleHourPicker}
                  disabled={!bookingData.subcategory}
                >
                  {convertToReadableDuration(bookingData.duration) }
                </Button>
              </Form.Group>
            </div>
          )}
          <div className="col-md-6 col-6 half">
            <Form.Group controlId="formBasicHiddenEmployees">
              <Form.Label>
                {quantityLabel ? 'Fixed Price Amount' : 'Hourly Rate Amount'}
              </Form.Label>
              <div className="select-box">
                {/* <i className="fas fa-sort-down" /> */}
                <Form.Control
                  as="select"
                  onChange={(e) => handleChangeCustom(e.target.value)}
                  name="customCost"
                  value={bookingData.customCost}
                  disabled={!!bookingData.category.toLowerCase().includes('misc') || !bookingData.subcategory}
                >
                  <option label="Default" name="customCost" value="default">
                    Default
                  </option>
                  <option
                    label={
                  quantityLabel ? 'Custom Sell Price' : 'Custom Hourly Rate'
                }
                    name="customCost"
                    value="yes"
                  >
                    {quantityLabel ? 'Custom Sell Price' : 'Custom Hourly Rate'}
                  </option>
                </Form.Control>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-6 col-6 halfright">
            <Form.Group controlId="formBasicHiddenEmployees">
              <Form.Label>
                {quantityLabel
                  ? 'Sell Price (ex tax)'
                  : 'Sell Price (hourly ex tax)'}
              </Form.Label>
              <NumberFormat
                thousandSeparator
                type="text"
                placeholder={
              quantityLabel
                ? 'Sell Price (ex tax)'
                : 'Sell Price (hourly ex tax)'
            }
                name="cost"
                value={bookingData.cost}
                disabled={bookingData.customCost === 'default' || !bookingData.subcategory}
                onChange={handleBookingData}
                isAllowed={withValueLimitSellPrice}
                className={bookingData.customCost === 'default' ? '' : 'addBorder'}
                prefix="$"
              />
            </Form.Group>
          </div>
        </>
      )}

    </div>
  );
};

ServiceView.propTypes = {
  handleBookingData: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  customCost: PropTypes.func.isRequired,
  servicesLength: PropTypes.func.isRequired,
  handleChangeCustom: PropTypes.func.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  subcategoryTxt: PropTypes.func.isRequired,
  handleHourPicker: PropTypes.func.isRequired,
  endTimeNew: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  timeDisplay: PropTypes.string.isRequired,
};

export default ServiceView;
