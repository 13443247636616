/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable key-spacing */
/* eslint-disable no-unused-expressions */
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { set } from 'lodash';
import { displayName } from 'react-quill';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import '../appointment/addappointment.css';
import ServiceModal from '../appointment/servicemodal';
import draining from '../../assets/home/refresh-icon.png';
import search from '../../assets/admin/search-icon.png';
import arrowwhite from '../../assets/home/whitearrow.png';
import TimePicker from '../appointment/timepicker';
// import deleteimg from '../../assets/home/white-delete.png';
import greydelete from '../../assets/home/grey-delete.png';
import showmore from '../../assets/home/showmore.png';
// import DateModal from './dateModal';
// import profile from '../../assets/admin/user.jpg';
import { userInstance } from '../../config/axios';
import history from '../../config/history';
import {
  showErrMsg, addZero, getCalendarPath, calculateEndingTimeArr, queryString,
  rmvQunt,
} from '../../functions/index';
import sort from '../../assets/home/sort.png';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import clientimg from '../../assets/home/client.png';
import DurationPicker from '../appointment/durationPicker';
import HourPicker from '../appointment/hourPicker';
import edit from '../../assets/home/grey-edit.png';
// import ViewAppointment from './view-appointment';
// import AddAppointment from '../appointment/addappointment';
import GreenPoolCalculationPrinting from './greenPoolCalculation';
import arrow from '../../assets/home/arrowdown.png';
import AddjobConfimationPopup from './addtojobConfimation';

import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorBorderTime, errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import { displayDollar, getUpdateCalVariable } from './calculatorUtils';
import toastMessage from '../../functions/toastMessage';

const GreenpoolWorking = ({
  handleBack,
  greenApiData,
  levelData,
  calVariable,
  poolInputs,
  greenSplitName,
  labourCostCalculation,
  handleCalculate,
  chemicalBalanceStatus,
  serviceAnalysis,
  updatedWaterSourceStatus,
  getresDrainData,
  waterHistory,
  balanceZodiac,
  zodiacStatus,
  chemicalaArray,
  sethistoryData,
  diameterInput,
  customDepths,
  valueType,
}) => {
  // console.log({ valueType });
  const {
    watTesId, routeStatus, Id: clId, appId: aptid, editChemicalList, backTo, watId, startDateTime, invoice, clident,
    visitMessage, back, quoteId, invFrom, withClientReturn, skipValue, namee,
  } = queryString();
  const { state } = useLocation();

  const Id = clId || ((state && state?.clientIdentity) ? state?.clientIdentity : '') || clident;
  const appId = aptid || ((state && state?.id) ? state?.id : '');
  const stateEdit = appId && (state && state?.editChemicals) ? state?.editChemicals : '';
  const editChem = appId && editChemicalList ? editChemicalList : '';
  const editChemicals = editChem || stateEdit || editChemicalList || '';

  const {
    clientAllData, setClientAllData, userData:{ industryId }, setQuoteDataFromAppointment,
  } = useContext(AuthDataContext);
  const waterId = watId || watTesId || state?.waterId;
  const clData = (waterId) ? localStorage.getItem('apptData') : '';
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  const timeFromDateFormat = 'HH:mm';
  // window.scroll({ top: 0, behavior: 'smooth' });
  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: 'Ongoing',
    category: '',
    subcategory: '',
    startTime: '9:30',
    secondStartTime: '10:30',
    thirdStartTime: '11:00',
    duration: '5',
    staffId: '',
    staffName: '',
    clientId: '',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    timeChange: false,
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    previousCost: '',
    prevBuyCostProduct: '',
    renderCost: '',
    soldPer: '',
    unit: '',
    symbol: '',
    status: '',
    edittype:true,
    tax:'',
    s_uId:'',
    categoryId:'',
    supplier:'',
  };
  const otherBookingInitial = {
    category: '',
    subcategory: '',
    duration: '5',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    soldPer: '',
    unit: '',
    symbol: '',
    tax:'',
    s_uId:'',
    categoryId:'',
    supplier:'',
  };
  const initialDatePopup = {
    interval_type: 'weekly',
    interval_multiplier: '4',
  };
  const initialDateData = {
    date: moment().format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: '',
    occurenceMsg: '',
    specificDate: moment().format(dateFormat),
    requiredday: null,
    appointmenttype: 'oneteam_oneday',
  };

  const [showService, setShowService] = useState(false);
  const [bookingData, setBookingData] = useState({ ...initialBookingData });
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [addedServices, setServices] = useState([]);
  const [editData, setEditData] = useState({
    edit: false,
    index: '',
    childIndex: '',
  });
  const [notes, setNotes] = useState('');
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState('');
  const [summary, setSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
  });
  const [dateData, setDateData] = useState(initialDateData);
  const [renderSort, setRenderSort] = useState(false);
  const [misc, setMisc] = useState(false);
  const [extras, setExtras] = useState({ value: '', msg: '' });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [subcategoryTxt, setSearchTxt] = useState('');
  const [clientTxt, setClientTxt] = useState('');
  const [durationPicker, setDurationPicker] = useState(false);
  const [timeData, setTime] = useState('9:30');
  const [durationData, setDuration] = useState('');
  const [repeatAppt, setRepeatAppt] = useState(false);
  const [repeatFrequency, setRepeatFrequency] = useState('');
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const timepickerRef = useRef(null);

  const [newLength, setnewlength] = useState();
  const [staffIndex, setStaffIndex] = useState();
  const [viewAppointmentPopup, setViewappointment] = useState();
  const [appointmentType, setAppointmentType] = useState('oneteam_oneday');
  const [multiServices, setMultiServices] = useState([]);
  const [multiAppointment, setmultiAppointment] = useState(false);
  const [greenPoolPrint, setgreenPoolPrint] = useState(false);

  const [firstIndex, setFirstIndex] = useState('');
  const [secondIndex, setSecondIndex] = useState('');
  const [getService, setGetService] = useState();
  const [greenPoolStatus, setGreenPoolStatus] = useState(true);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);

  const [greenLevelData, setGreenLevelData] = useState([]);

  const [thirdVisitStatus, setThirdVisitStatus] = useState(true);

  const [itemIndex, setItemIndex] = useState('');
  const [crossButton, setCrossButton] = useState(true);
  const [chemicalArray, setChemicalArray] = useState([]);
  const testChemicals = {};
  const [errorValue, setErrorValue] = useState('');
  const [currentLenth, setCurrentLenth] = useState(0);
  const historyBack = useHistory();
  const newHistory = useHistory();

  // console.log('greenLevelData', greenLevelData);

  useEffect(() => {
    if (levelData?.length > 0) setGreenLevelData(JSON.parse(JSON.stringify(levelData)));
  }, [levelData]);

  function grabingLevelData() {
    serviceAnalysis?.includes('Water Test');
    if ((serviceAnalysis?.includes('Water Test') && calVariable.isDrainage) || (serviceAnalysis?.includes('Water Test') && balanceZodiac === 'Zodiac Calcium Down' && zodiacStatus)) {
      setCrossButton(true);
    } else if ((serviceAnalysis?.includes('Water Test') && !calVariable.isDrainage)) {
      setCrossButton(false);
    } else if (greenSplitName === 'Part' && !calVariable.balancing) {
      setCrossButton(true);
    }
    if (calVariable.balancing) {
      // console.log('comiiiiiii', calVariable.balancing);
      setCrossButton(false);
    }
  }

  const handleSorting = (index) => {
    setGreenLevelData([
      ...greenLevelData,
      (greenLevelData[index].isActive = !greenLevelData[index].isActive),
    ]);
  };

  function SortedLevelData() {
    let copyService;
    let copyGreenLevelData = [...greenLevelData];
    copyGreenLevelData.map((element, index) => {
      copyService = [...element.service];
      let filterdata = copyService.filter((el) => el.subcategory !== '');
      element.service = filterdata;
    });

    setGreenLevelData(copyGreenLevelData);
  }

  useEffect(() => {
    grabingLevelData();
  }, [levelData]);

  const setStaff = (staffName, staffId) => {
    const servicesListCopy = addedServices;
    servicesListCopy.forEach((el) => {
      el.staffId = staffId;
      el.staffName = staffName;
    });
    setServices([...servicesListCopy]);
  };

  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };

  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };

  const moveNewArr = (oldIndex, newIndex, sign, index) => {
    let oldstartTime;
    let newStartTime;
    let copyLevelData = [...greenLevelData];
    const serviceNewCopy = [...copyLevelData[index].service];
    if (sign === 'plus' && oldIndex === serviceNewCopy.length - 1) {
      copyLevelData[index].service = serviceNewCopy;
      setGreenLevelData(copyLevelData);
    } else {
      oldstartTime = serviceNewCopy[oldIndex].startTime;
      newStartTime = serviceNewCopy[newIndex].startTime;

      serviceNewCopy[newIndex].startTime = oldstartTime;
      serviceNewCopy[oldIndex].startTime = newStartTime;
      serviceNewCopy.splice(newIndex, 0, serviceNewCopy.splice(oldIndex, 1)[0]);
      copyLevelData[index].service = serviceNewCopy;
      setGreenLevelData(copyLevelData);
      calculateEndingTimeArr(setGreenLevelData, index, greenLevelData);
    }
  };

  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];

    let newNextStartTime;
    let currentNewStartTime;

    if (sign === 'plus') {
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(
          currentEntryStart.setMinutes(
            currentEntryStart.getMinutes() + parseFloat(duration),
          ),
        ),
      ).format(dateFormat);

      newNextStartTime = moment(updatedNextEntryStart).format(
        timeFromDateFormat,
      );

      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === 'minus') {
      newNextStartTime = startTime;

      const updatedCurrentEntryStart = moment(
        new Date(
          new Date(start).setMinutes(
            new Date(start).getMinutes()
            + parseFloat(servicesCopy[oldIndex].duration),
          ),
        ),
      ).format(dateFormat);

      currentNewStartTime = moment(updatedCurrentEntryStart).format(
        timeFromDateFormat,
      );
    }

    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };

    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);

    calculateDurations(servicesCopy);
  };

  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === 'default') {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === 'product') {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === 'yes' || value === 'sellPrice') {
      obj.cost = '';
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === 'buyCost') {
      obj.buyCostProduct = '';
      obj.cost = obj.previousCost;
    } else if (value === 'buySell') {
      obj.cost = '';
      obj.buyCostProduct = '';
    }
    setBookingData(obj);
  };

  const handleDeleteChemicals = (data, index, greenName) => {
    let copyLevelData = [...greenLevelData];
    let copyService = [...copyLevelData[index].service];

    const filterdata = copyService.filter((el) => {
      if (
        greenSplitName === 'Drain'
        || serviceAnalysis === 'Sand Filter Media Replacement'
        || serviceAnalysis === 'Water Test'
      ) {
        if (el.status === '') {
          return el;
        }
      } else if (el.type === 'service' || el.status === '') {
        return el;
      }
    });

    if (filterdata?.length === 0) {
      setThirdVisitStatus(!thirdVisitStatus);
    }
    copyLevelData[index].service = filterdata;
    setGreenLevelData(copyLevelData);
    setCrossButton(!crossButton);
  };

  const handleDeleteSingleChemicals = (data, index, i) => {
    const copyLevelData = [...greenLevelData];
    const copyService = [...copyLevelData[index].service];
    const startTimePrev = copyService[i].startTime;
    const nextIndex = i + 1;
    if (copyService[nextIndex]) {
      copyService[nextIndex].startTime = startTimePrev;
    }

    let filteredData = copyService.filter((el, idx) => idx !== i);

    copyLevelData[index].service = filteredData;
    setGreenLevelData(copyLevelData);
    calculateEndingTimeArr(setGreenLevelData, index, greenLevelData);
  };

  const handleShowService = (index) => {
    bookingData.startTime = greenLevelData[index].service[
      greenLevelData[index].service.length - 1
    ].endTime;
    bookingData.quantity = '5';
    setFirstIndex(index);
    setItemIndex(index);
    setStaffIndex(0);
    setnewlength(addedServices.length);
    // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setShowService(!showService);
    setEditData({ edit: false, index: '', childIndex: '' });
    setSearchTxt('');
    setSubcategories(null);
    setMisc(false);
  };

  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === 'staffName') {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj[staffId] = staffId;
      if (
        appointmentType === 'oneteam_oneday'
        || appointmentType === 'oneteam_multiday'
      ) {
        setStaff(value, staffId);
      }
    }
    if (name === 'cost' && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === 'startTime') {
      obj.timeChange = true;
    } else if (name === 'duration') {
      obj.durationChange = true;
    } else if (name === 'quantity') {
      obj.quantityChange = true;
    } else if (name === 'pricingType') {
      // obj.duration = bookingData.previousDuration;
      if (bookingData.type === 'service') {
        obj.quantity = obj.duration;
      }
      if (bookingData.customCost !== 'default') {
        obj.cost = '';
      }
    } else if (name === 'soldPer' && value !== 'Add Custom Measure') {
      obj.unit = '';
      obj.symbol = '';
    }
    setBookingData(obj);
  };

  const addSubcategoryToService = (value) => {
    let kquantity = '1';
    const selectedCategory = categories.filter(
      (el) => el.subCategory.filter((sub) => sub.name === value).length,
    );
    const subcategoryData = selectedCategory[0].subCategory.filter(
      (el) => el.name === value,
    );
    const { type, category } = selectedCategory[0];
    const {
      duration, cost, soldPer, unit, symbol, tax, s_uId,
      categoryId, supplier,
    } = subcategoryData[0];

    let customCostData = 'default';
    let newCost = addZero(
      subcategoryData[0][type === 'product' ? 'retailCost' : 'cost'],
    );
    let newBuyCost = type === 'product' ? cost : '';

    if (selectedCategory[0].category.toLowerCase().includes('misc')) {
      setMisc(true);
      customCostData = type === 'product' ? 'buySell' : 'yes';
      newCost = '';
      newBuyCost = '';
    }
    let soldPerValue = soldPer || '';
    if (type === 'product') {
      if (soldPer === 'Add Custom Measure') {
        soldPerValue = `${unit}(${symbol})`;
      }
    }
    if (value.toLowerCase().includes('misc')) {
      if (type === 'product') {
        kquantity = '';
        bookingData.quantity = kquantity;
      }
    }
    setBookingData({
      ...bookingData,
      category,
      subcategory: value,
      cost: newCost,
      duration,
      type,
      quantity: kquantity,
      buyCostProduct: newBuyCost,
      customCost: customCostData,
      timeChange: false,
      customMisc: '',
      previousDuration: '',
      previousQuantity: '',
      pricingType: 'fixedCost',
      previousCost: newCost,
      prevBuyCostProduct: newBuyCost,
      soldPer: soldPerValue,
      tax,
      s_uId,
      categoryId,
      supplier,
    });
    setSearchTxt(value);
    setSubcategories(null);
  };

  const getCategories = async () => {
    try {
      const response = await userInstance().get('/service/getServices');
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      if (staffData !== null && staffData !== undefined) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {
      console.log(error);
    }
  };

  const getTimes = () => g();

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: '12:25 AM',
      closeTime: '23:30 PM',
    };

    // Format the time
    const startTime = moment(x.openTime, 'h:mm A');

    // Format the end time and the next day to it
    const endTime = moment(x.closeTime, 'h:mm A');

    // Times
    const allTimes = [];

    // Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      // Push times
      allTimes.push(startTime.format('h:mm A'));
      // Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    return allTimes;
  };

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg = hours && minutes
        ? `${hours}hr ${minutes} min`
        : hours && !minutes
          ? `${hours}hr`
          : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get('/staff/getStaff');
      const { staff } = response.data;
      const activeStaffId = await localStorage.getItem('staff_id');
      setStaffList(staff);
      if (staff?.length) {
        const staffDetails = staff.filter((el) => el._id === activeStaffId);
        return {
          staffName: staffDetails.length
            ? `${staffDetails[0].firstname} ${staffDetails[0].lastname}`
            : `${staff[0].firstname} ${staff[0].lastname}`,
          staffId: staffDetails.length ? staffDetails[0]._id : staff[0]._id,
        };
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };

  const convertHours = (mins) => {
    const hour = Math.floor(mins / 60);
    var mins = mins % 60;
    const converted = `${pad(hour, 2)}:${pad(mins, 2)}`;
    return converted;
  };

  const getHourWithQuantity = (duration, multiplier) => {
    let hourPart = duration.includes('hr')
      ? parseFloat(duration.split('hr')[0].trim())
      : 0;
    let minutePart = duration.includes('hr') && duration.includes('min')
      ? parseFloat(duration.split('hr')[1].split('min')[0])
      : duration.includes('min')
        ? parseFloat(duration.split('min')[0])
        : 0;

    let hourWithQuantity = hourPart * multiplier;
    return { hourWithQuantity, hourPart, minutePart };
  };

  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration = bookingDataCopy.pricingType === 'hourly'
      ? bookingDataCopy.quantity
      : bookingDataCopy.duration;

    let hour = convertToReadableDuration(
      addQuantityDuration(convertedDuration, parseFloat('1')),
    );
    let { hourWithQuantity, minutePart } = getHourWithQuantity(
      hour,
      bookingDataCopy.cost,
    );

    console.log('ppp==>>>', { hourWithQuantity, minutePart });

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? bookingDataCopy.status === 'quantity'
        ? bookingDataCopy.renderCost
        : Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };

  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(
      updatedDuration,
      quantity,
    );

    let multipleMinute = parseFloat(
      `0.${hourWithQuantity.toString().split('.')[1]}`,
    );

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration = parseFloat(hourWithQuantity.toString().split('.')[0]) * 60
      + updatedMinute
      + minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60)
      ? Math.ceil(finalDuration / 5) * 5
      : finalDuration;

    return finalDuration.toString();
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    const {
      cost, buyCostProduct, quantity, type, renderCost,
    } = bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;

    if (type === 'product') {
      totalBuy += parseFloat(buyCostProduct);
      totalSellPrice += parseFloat(renderCost);
      const totalLabourProduct = (
        totalSellPrice
        - totalBuy * parseFloat(quantity)
      ).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === 'service') {
      totalLabour = bookingDataCopy.pricingType === 'hourly'
        ? calculateServiceLabour(bookingDataCopy)
        : cost;
    }
    return { totalLabour, laborMargin };
  };

  const validateService = () => {
    let isValid = true;
    let msg = '';
    let errValue = '';
    const {
      category,
      subcategory,
      staffName,
      type,
      customMisc,
      quantity,
    } = bookingData;
    let { cost, buyCostProduct } = bookingData;

    cost = cost.replace(/,/g, '').replace('$', '');
    buyCostProduct = buyCostProduct.replace(/,/g, '').replace('$', '');
    // console.log({ bookingData });
    if (!category) {
      isValid = false;
      msg = 'Please select service or product';
      errValue = 'category';
    } else if (!subcategory) {
      isValid = false;
      msg = 'Please select service or products';
      errValue = 'subcategory';
    } else if (!staffName) {
      isValid = false;
      msg = 'Please select staff';
      errValue = 'staffName';
    } else if (
      type === 'product'
      && parseFloat(cost) < parseFloat(buyCostProduct)
    ) {
      isValid = false;
      msg = 'Sell price must be greater than buy cost';
      errValue = 'sell price';
    } else if (!cost) {
      isValid = false;
      msg = 'Enter Sell Price (ex tax)';
      errValue = 'sell price';
    } else if (!buyCostProduct && type === 'product') {
      isValid = false;
      msg = 'Enter Buy Price (ex tax)';
      errValue = 'buyCostProduct';
    } else if (
      bookingData.category.toLowerCase().includes('misc')
      && !customMisc
    ) {
      isValid = false;
      msg = 'Enter New Name';
      errValue = 'customMisc';
    } else if (!quantity) {
      isValid = false;
      msg = 'Enter quantity';
      errValue = 'quantity';
    }
    return { isValid, msg, errValue };
  };
  function groupBy(collection, property) {
    let i = 0;
    let val;
    let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }

  const handleAddService = async () => {
    const {
      subcategory,
      type,
      duration,
      previousDuration,
      previousQuantity,
      pricingType,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;

    quantity = quantity.toString().replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);

    renderCost = type === 'service' && pricingType === 'hourly'
      ? (parseFloat(cost) * parseFloat(quantity)) / 60 : type === 'service' && pricingType === 'fixedCost' ? (parseFloat(cost)).toFixed(2)
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);

    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      let startTime = '9:30';
      let start = moment(dateData.date).toDate();
      let time = bookingData.startTime.split(':');
      const bookingDataCopy = { ...bookingData };
      const lastService = addedServices[addedServices.length - 1];

      if (lastService) {
        start = moment(lastService.end).toDate();
        time = moment(lastService.end)
          .format(timeFromDateFormat)
          .split(':');
      }
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { totalLabour, laborMargin } = calculateMarginLabour(
        bookingDataCopy,
      );
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = duration;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
      }
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      bookingDataCopy.duration = durationInMin;
      bookingDataCopy.start = moment(start).format(dateFormat);
      bookingDataCopy.end = moment(
        new Date(
          start.setMinutes(
            start.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);

      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );

      bookingDataCopy.status = '';
      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        bookingDataCopy.previousQuantity = quantity;
      }
      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }

      const oldLevelData = [...greenLevelData];
      oldLevelData[itemIndex].service.push(bookingDataCopy);
      setGreenLevelData([...oldLevelData]);
      calculateEndingTimeArr(setGreenLevelData, firstIndex, greenLevelData);
      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      let FillterServices = finalServices.sort((a, b) => (a.staffName > b.staffName ? 1 : -1));

      setShowService(!showService);
      setServices([...FillterServices]);
      calculateSummary([...FillterServices]);
      setMultiServices(obj);
      setBookingData({ ...initialBookingData, staffId:bookingData.staffId, staffName:bookingData.staffName });
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
      category,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;

    quantity = quantity.toString().replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(
      buyCostProduct.replace(/,/g, '').replace('$', ''),
    ).toFixed(2);

    renderCost = type === 'service' && pricingType === 'hourly'
      ? ((parseFloat(cost) * parseFloat(quantity)) / 60).toFixed(2) : type === 'service' && pricingType === 'fixedCost' ? (parseFloat(cost)).toFixed(2)
        : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);

    if (type === 'product') {
      console.log('dsdsds', renderCost);
      // renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = (renderCost / quantity).toFixed(2).toString();
    }
    renderCost = (Math.ceil(renderCost / 5) * 5).toString();

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };

      const startDate = moment(start).toDate();
      const time = startTime.split(':');

      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00'
          ? addZero(renderCost)
          : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;

      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }

      startDate.setHours(time[0]);
      startDate.setMinutes(time[1]);
      startDate.setSeconds(0);

      bookingDataCopy.start = moment(startDate).format(dateFormat);

      const { totalLabour, laborMargin } = calculateMarginLabour(
        bookingDataCopy,
      );
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      if (category
        .toLowerCase()
        .includes('misc') || (subcategory.includes('$') && parseFloat(subcategory.split('$')[1]) === parseFloat(renderCost))) {
        bookingDataCopy.status = '';
      }

      let durationInMin = 0;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 && pricingType === 'hourly'
          ? quantity
          : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (
        previousQuantity !== quantity
        || previousDuration !== duration
      ) {
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }

      bookingDataCopy.end = moment(
        new Date(
          startDate.setMinutes(
            startDate.getMinutes() + parseFloat(bookingDataCopy.duration),
          ),
        ),
      ).format(dateFormat);

      const copyLevelData = [...greenLevelData];
      const copyService = [...copyLevelData[firstIndex].service];
      copyService[secondIndex] = { ...bookingDataCopy };
      finalServices = copyService;
      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.renderCost ? next.renderCost : 0),
          0,
        );
        const totalduration = el.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.duration ? next.duration : 0),
          0,
        );
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      calculateNewDurations(bookingDataCopy);
      bookingDataCopy.timeChange = false;

      setShowService(!showService);
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      copyLevelData[firstIndex].service = copyService;
      setGreenLevelData(copyLevelData);
      calculateEndingTimeArr(setGreenLevelData, firstIndex, greenLevelData);
      labourCostCalculation();
      setBookingData({ ...initialBookingData, staffId:bookingData.staffId, staffName:bookingData.staffName });
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };

  const calculateDurations = (services) => {
    services.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(':');
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
      ).format(dateFormat);
    });
    setServices([...services]);
  };

  const calculateSummary = (finalServices) => {
    let totalDuration = 0;
    let totalCost = 0;
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(el.renderCost);
      laborMargin += parseFloat(el.laborMargin) + parseFloat(el.totalLabour);
    });
    const totalLabour = laborMargin;
    setSummary({
      ...summary,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      multiTotal: totalCost,
    });
  };
  const handleEditService = ({ service, second, first }) => {
    // console.log({ service, second, first });
    bookingData.quantity = '5';
    setFirstIndex(first);
    setSecondIndex(second);
    setGetService(service);

    if (first === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    const updateObj = { ...bookingData, ...service };
    // console.log({ updateObj });
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index: first, childIndex: second });
    if (parseFloat(service.duration) > 960) {
      setExtras({
        ...extras,
        value: service.duration,
        data: convertToReadableDuration(service.duration),
      });
    }
    const subcategory = service.subcategory.replace(
      service.subcategory.split('$')[1],
      service.renderCost.split('.')[1] === '00'
        ? addZero(service.renderCost)
        : addZero(service.cost),
    );
    setSearchTxt(subcategory);
  };

  const handleDeleteService = () => {
    const copyLevelData = [...greenLevelData];
    const copyService = [...copyLevelData[editData.index].service];
    const startTimePrev = copyService[editData.childIndex].startTime;
    const nextIndex = editData.childIndex + 1;
    if (copyService[nextIndex]) {
      copyService[nextIndex].startTime = startTimePrev;
    }

    const filteredData = copyService.filter(
      (el, index) => index !== editData.childIndex,
    );

    copyLevelData[editData.index].service = filteredData;

    if (filteredData.length === 0) {
      setThirdVisitStatus(!thirdVisitStatus);
      setCrossButton(!crossButton);
    }
    setGreenLevelData(copyLevelData);
    calculateEndingTimeArr(setGreenLevelData, editData.index, greenLevelData);
    setShowService(!showService);
  };

  const handleSearchCategory = async (searchTxt) => {
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(
          `/service/searchCategory?value=${searchTxt.replace('$', '').trim()}&industryId=${industryId._id}`,
        );
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((service) => {
            const reg = new RegExp(`${searchTxt.replace('$', '').trim()}`, 'i');
            const matchedSubcategories = service.subCategory.filter(
              (el) => reg.test(el.cost)
                || reg.test(el.name)
                || reg.test(el.retailCost)
                || reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateNewDurations = (data) => {
    const remainingServices = addedServices.filter(
      (_el, i) => i > editData.index,
    );
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.end);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(
        new Date(
          end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)),
        ),
      ).format(dateFormat);
    }
    setServices([addedServices[0], ...remainingServices]);
  };

  useEffect(() => {
    getCategories();
  }, [dateData]);

  const TotalPartVist = (el) => {
    let totalcost = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0),
      0,
    );

    testChemicals.totalCost = totalcost.toFixed(2);

    return totalcost.toFixed(2);
  };
  const TotalPartDuration = (el) => {
    let totalDuration = el.reduce(
      (pre, next) => parseInt(pre || 0, 10) + parseInt(next.duration ? next.duration : 0, 10),
      0,
    );
    testChemicals.totalDuration = totalDuration;

    return totalDuration;
  };
  const totalLabourAndMargin = (el) => {
    let totalLabour = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.totalLabour ? (next.totalLabour === 'N/A' ? 0 : (next.totalLabour)) : 0),
      0,
    );
    let totalMargin = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.laborMargin ? (next.laborMargin === 'N/A' ? 0 : next.laborMargin) : 0),
      0,
    );
    let totalLabourMargin = totalLabour + totalMargin;
    testChemicals.labourMargin = totalLabourMargin.toFixed(2);
    return totalLabourMargin.toFixed(2);
  };

  const TotalMultiVisitTotal = (data) => {
    if (!(data?.length > 0)) return '0.00';
    const hackData = data
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    testChemicals.totalCost = hackData.toFixed(2);
    return hackData.toFixed(2);
  };
  const TotalMultiDurationTotal = (data) => {
    if (!(data?.length > 0)) return '0.00';
    const hackData = data
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
          + parseFloat(next.duration ? next.duration : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    testChemicals.totalDuration = hackData;
    return hackData;
  };
  const TotalMutiLabourMargin = (data) => {
    if (!(data?.length > 0)) return '0.00';
    const hackData = data
      .map((el2) => {
        let totalLabour = el2.service.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.totalLabour ? (next.totalLabour === 'N/A' ? 0 : next.totalLabour) : 0),
          0,
        );
        let totalMargin = el2.service.reduce(
          (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.laborMargin ? (next.laborMargin === 'N/A' ? 0 : next.laborMargin) : 0),
          0,
        );
        let totalLabourMargin = totalLabour + totalMargin;
        return totalLabourMargin;
      })
      .reduce((pre, next) => pre + next);
    testChemicals.labourMargin = hackData.toFixed(2);
    return hackData.toFixed(2);
  };

  const element = () => {
    if (serviceAnalysis) {
      return <h1>SayHello</h1>;
    }
  };
  const [newTestChemicals, setNewTestChemicals] = useState(null);
  const handleGreenPrint = () => {
    greenLevelData.map((el) => {
      if (el.service.length) {
        setgreenPoolPrint(!greenPoolPrint);
      }
      return 0;
    });
    setNewTestChemicals(testChemicals);

    if (serviceAnalysis !== 'Water Test') {
      sethistoryData();
    }
  };

  const transferAppointmentData = () => {
    // console.log('transferData', greenLevelData);
  };

  const addToJob = () => {
    setOpenConfirmBox(true);
  };

  const backToFinaliseVisit = () => {
    historyBack.push({
      pathname: '/finlizevisitcost',
      state: {
        id: state.id,
        addedServices: state.addedServices,
        summary: state.summary,
        dateBookingData: state.dateBookingData,
        clientIdentity: state.clientIdentity,
        checklistArray: state.checklistArray,
        image: state.image,
        repeats: state.repeats,
        eventDate: state.eventDate,
        customerDues: state.customerDues,
        chemicalalist: state.chemicalsArray,
        pid: state.pid,
      },
    });
  };
  const transferDataToquote = async (type, quoteType) => {
    // alert('asdfas');
    try {
      let copyService;
      let copyGreenLevelData = editChemicals ? [...chemicalaArray] : [...greenLevelData];
      console.log('editChemicals', copyGreenLevelData);
      const copyDat = waterId ? JSON.parse(clData) : clientAllData;
      const copyClientData = { ...copyDat };
      copyGreenLevelData.map((elements, index) => {
        copyService = [...elements.service];
        let filterdata = copyService.filter((el) => el.subcategory !== '');
        elements.service = filterdata;
        elements.summary = {
          totalCost:TotalPartVist(filterdata),
          totalDuration:TotalPartDuration(filterdata),
          totalLabourAndMargin:totalLabourAndMargin(filterdata),
          totalLabour:'',
          totalMargin:'',
          multiTotal:'',
        };
        elements.serviceAnalysis = serviceAnalysis;
      });
      const result = copyGreenLevelData.filter((el2) => el2.service.length > 0);
      const payloadData = editChemicals ? { ...testChemicals, testResult: result }
        : { ...newTestChemicals, testResult: result };

      let createdArrayForVisits = [];
      if (visitMessage) {
        if (!startDateTime) {
          result.map((el, index) => {
            if (index !== 0) {
              const obj = {};
              obj.labourMargin = el.summary.totalLabourAndMargin;
              obj.totalDuration = el.summary.totalDuration;
              obj.totalCost = el.summary.totalCost;
              obj.testResult = [el];

              const newMain = { _id:el._id, chemicalsArray:obj };
              createdArrayForVisits.push(newMain);
            } else {
              const newMain = { _id:el._id, chemicalsArray:payloadData };
              createdArrayForVisits.push(newMain);
            }
          });
        }
      }
      localStorage.setItem('chemicalalist', JSON.stringify([payloadData]));
      localStorage.setItem('chemicaltype', type);
      let waterTestId = waterId || '';
      const newWaterTestStatus = !!((waterId || editChemicals));
      if (type === 'yes') {
        const watHistory = waterId ? copyClientData?.waterTestHistory : waterHistory?.history;
        if (watHistory) {
          // console.log({ greenLevelData, payloadData });
          const goingToUpdate = watHistory?.calVariable;
          const waterTestIndexValue = serviceAnalysis === 'Water Test' && !watHistory?.calVariable?.isDrainage ? 'First Visit' : 'Second Visit';
          const updatedIndexValue = (serviceAnalysis === 'Green Pool' || serviceAnalysis === 'Black-Spot Algae') ? 'Third Visit' : waterTestIndexValue;
          const chemTaxValue = watHistory?.taxApplicable?.chemicalsTax;
          const upTaxValue = chemTaxValue || 1;
          const fIndex = greenLevelData?.findIndex((el) => el.value === updatedIndexValue);
          let serviceArray = [];
          if (fIndex >= 0) {
            if (greenLevelData[fIndex]?.service?.length > 0) {
              serviceArray = [...greenLevelData[fIndex]?.service];
            }
            if (serviceArray?.length > 0) {
              const updatedCalVariableObj = getUpdateCalVariable(goingToUpdate, serviceArray, upTaxValue);
              if (updatedCalVariableObj) {
                watHistory.calVariable = updatedCalVariableObj;
              }

              if (waterId) {
                copyClientData.waterTestHistory = watHistory;
              } else {
                waterHistory.history = watHistory;
              }
            }
          }
        }

        if (!newWaterTestStatus) {
          const newPayLoad = {
            history:waterHistory?.history,
            chemicals:payloadData,
          };

          if (appId && Id) {
            newPayLoad.appointmentid = appId;
            newPayLoad.clientId = Id;
          }
          // if (newPayLoad) {
          //   console.log(newPayLoad);
          //   return;
          // }

          const response = await userInstance().post(
            '/watertest/addWaterHistory',
            newPayLoad,
          );
          let { msg, saveWaterData } = response.data;
          waterTestId = saveWaterData._id;
          toastMessage('success', msg);
        }
        const updatedData = {
          appId,
          chemicalsArray:payloadData,
          waterTestStatus:true,
        };
        if (createdArrayForVisits.length > 0) {
          updatedData.diffVisitsArray = createdArrayForVisits;
        }

        if (appId) {
          let copyupdatedData = JSON.parse(JSON.stringify(updatedData));
          const copychemicalsArray = copyupdatedData?.chemicalsArray?.testResult[0]?.summary;
          copyupdatedData.chemicalsArray.labourMargin = copychemicalsArray?.totalLabourAndMargin;
          copyupdatedData.chemicalsArray.totalCost = copychemicalsArray?.totalCost;
          copyupdatedData.chemicalsArray.totalDuration = copychemicalsArray?.totalDuration;

          const res = await userInstance().put('/appointment/saveToWaterTest', copyupdatedData);
        }

        if (newWaterTestStatus) {
          const payload = {
            waterHistoryId:waterTestId,
            chemicals:payloadData,
            history:copyClientData?.waterTestHistory,
          };

          const resTest = await userInstance().put('/watertest/updateWaterHistoryChemicals', payload);
        }

        if (copyClientData?.appointmentData) {
          if (visitMessage && !startDateTime) {
            const findObj = createdArrayForVisits.find((elem) => elem._id === appId);
            copyClientData.addedServices = [];
            copyClientData.appointmentData.chemicalsArray = [findObj?.chemicalsArray];
          } else {
            copyClientData.appointmentData.chemicalsArray = [payloadData];
          }
          copyClientData.appointmentData.waterTestStatus = true;
          if (visitMessage) {
            copyClientData?.groupAppointments?.map((el, inddd) => {
              if (inddd === 0) {
                el.chemicalsArray = [payloadData];
              } else {
                el.chemicalsArray = [(createdArrayForVisits.filter((ele) => ele._id === el._id))[0]?.chemicalsArray];
              }
            });
          }
          if (state) {
            state.chemicalsArray = [payloadData];
          }
        }
        localStorage.setItem('apptData', JSON.stringify(copyClientData));
        setClientAllData(copyClientData);
      }
      if (routeStatus) {
        localStorage.setItem('clientId', Id);
      }

      if (!appId) {
        const activeStaffId = localStorage.getItem('staff_id');
        let time = '9:30'.split(':');
        let start = moment(new Date()).toDate();
        start.setHours(time[0]);
        start.setMinutes(time[1]);
        start.setSeconds(0);
        const curDate = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');

        let finaltime = startDateTime || curDate;
        // setQuoteDataFromAppointment([...copyGreenLevelData]);
        newHistory.push(`/quotes?showservice=true&startDateTime=${finaltime}&employee_id=${activeStaffId}&waterTest=done&watTesId=${waterTestId}&type=${quoteType}`);
      } else {
        // console.log('stateForgygy', state?.editChemicals);
        let oldUrl = getCalendarPath();
        const newUrl = `${oldUrl}&chemicaltype=open&waterId=${waterTestId}`;
        if (invoice) {
          const clientQuery = withClientReturn ? `&clientid=${clident}` : '';
          newHistory.push(`/${invFrom}?viewtype=detailPage&from=viewApp&cal=cal${clientQuery}`);
        } else {
          (state && (state?.editChemicals || state?.comeFrom === 'finalise')) ? backToFinaliseVisit() : newHistory.push(newUrl);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // const activeStaffId = localStorage.getItem('staff_id');

    // newHistory.push(`/quotes?showservice=true&startDateTime=${moment().format()}&employee_id=${activeStaffId}`);

    // console.log('transferData', greenLevelData);
  };
  const handleWaterTestJob = async (type, say) => {
    try {
      let copyService;
      let copyGreenLevelData = editChemicals ? [...chemicalaArray] : [...greenLevelData];
      console.log('editChemicals', copyGreenLevelData);
      const copyDat = waterId ? JSON.parse(clData) : clientAllData;
      const copyClientData = { ...copyDat };
      copyGreenLevelData.map((elements, index) => {
        copyService = [...elements.service];
        let filterdata = copyService.filter((el) => el.subcategory !== '');
        elements.service = filterdata;
        elements.summary = {
          totalCost:TotalPartVist(filterdata),
          totalDuration:TotalPartDuration(filterdata),
          totalLabourAndMargin:totalLabourAndMargin(filterdata),
          totalLabour:'',
          totalMargin:'',
          multiTotal:'',
        };
        elements.serviceAnalysis = serviceAnalysis;
      });
      const result = copyGreenLevelData.filter((el2) => el2.service.length > 0);
      const payloadData = editChemicals ? { ...testChemicals, testResult: result }
        : { ...newTestChemicals, testResult: result };

      let createdArrayForVisits = [];
      if (visitMessage) {
        if (!startDateTime) {
          result.map((el, index) => {
            if (index !== 0) {
              const obj = {};
              obj.labourMargin = el.summary.totalLabourAndMargin;
              obj.totalDuration = el.summary.totalDuration;
              obj.totalCost = el.summary.totalCost;
              obj.testResult = [el];

              const newMain = { _id:el._id, chemicalsArray:obj };
              createdArrayForVisits.push(newMain);
            } else {
              const newMain = { _id:el._id, chemicalsArray:payloadData };
              createdArrayForVisits.push(newMain);
            }
          });
        }
      }
      localStorage.setItem('chemicalalist', JSON.stringify([payloadData]));
      localStorage.setItem('chemicaltype', type);
      let waterTestId = waterId || '';
      const newWaterTestStatus = !!((waterId || editChemicals));
      if (type === 'yes') {
        const watHistory = waterId ? copyClientData?.waterTestHistory : waterHistory?.history;
        if (watHistory) {
          // console.log({ greenLevelData, payloadData });
          const goingToUpdate = watHistory?.calVariable;
          const waterTestIndexValue = serviceAnalysis === 'Water Test' && !watHistory?.calVariable?.isDrainage ? 'First Visit' : 'Second Visit';
          const updatedIndexValue = (serviceAnalysis === 'Green Pool' || serviceAnalysis === 'Black-Spot Algae') ? 'Third Visit' : waterTestIndexValue;
          const chemTaxValue = watHistory?.taxApplicable?.chemicalsTax;
          const upTaxValue = chemTaxValue || 1;
          const fIndex = greenLevelData?.findIndex((el) => el.value === updatedIndexValue);
          let serviceArray = [];
          if (fIndex >= 0) {
            if (greenLevelData[fIndex]?.service?.length > 0) {
              serviceArray = [...greenLevelData[fIndex]?.service];
            }
            if (serviceArray?.length > 0) {
              const updatedCalVariableObj = getUpdateCalVariable(goingToUpdate, serviceArray, upTaxValue);
              if (updatedCalVariableObj) {
                watHistory.calVariable = updatedCalVariableObj;
              }

              if (waterId) {
                copyClientData.waterTestHistory = watHistory;
              } else {
                waterHistory.history = watHistory;
              }
            }
          }
        }

        if (!newWaterTestStatus) {
          const newPayLoad = {
            history:waterHistory?.history,
            chemicals:payloadData,
          };

          if (appId && Id) {
            newPayLoad.appointmentid = appId;
            newPayLoad.clientId = Id;
          }
          // if (newPayLoad) {
          //   console.log(newPayLoad);
          //   return;
          // }
          const response = await userInstance().post(
            '/watertest/addWaterHistory',
            newPayLoad,
          );
          let { msg, saveWaterData } = response.data;
          waterTestId = saveWaterData._id;
          toastMessage('success', msg);
        }
        const updatedData = {
          appId,
          chemicalsArray:payloadData,
          waterTestStatus:true,
        };
        if (createdArrayForVisits.length > 0) {
          updatedData.diffVisitsArray = createdArrayForVisits;
        }

        if (appId) {
          let copyupdatedData = JSON.parse(JSON.stringify(updatedData));
          const copychemicalsArray = copyupdatedData?.chemicalsArray?.testResult[0]?.summary;
          copyupdatedData.chemicalsArray.labourMargin = copychemicalsArray?.totalLabourAndMargin;
          copyupdatedData.chemicalsArray.totalCost = copychemicalsArray?.totalCost;
          copyupdatedData.chemicalsArray.totalDuration = copychemicalsArray?.totalDuration;
          const res = await userInstance().put('/appointment/saveToWaterTest', copyupdatedData);
        }

        if (newWaterTestStatus) {
          const payload = {
            waterHistoryId:waterTestId,
            chemicals:payloadData,
            history:copyClientData?.waterTestHistory,
          };

          const resTest = await userInstance().put('/watertest/updateWaterHistoryChemicals', payload);
        }

        if (copyClientData?.appointmentData) {
          if (visitMessage && !startDateTime) {
            const findObj = createdArrayForVisits.find((elem) => elem._id === appId);
            copyClientData.addedServices = [];
            copyClientData.appointmentData.chemicalsArray = [findObj?.chemicalsArray];
          } else {
            copyClientData.appointmentData.chemicalsArray = [payloadData];
          }
          copyClientData.appointmentData.waterTestStatus = true;
          if (visitMessage) {
            copyClientData?.groupAppointments?.map((el, inddd) => {
              if (inddd === 0) {
                el.chemicalsArray = [payloadData];
              } else {
                el.chemicalsArray = [(createdArrayForVisits.filter((ele) => ele._id === el._id))[0]?.chemicalsArray];
              }
            });
          }
          if (state) {
            state.chemicalsArray = [payloadData];
          }
        }
        localStorage.setItem('apptData', JSON.stringify(copyClientData));
        setClientAllData(copyClientData);
      }
      if (routeStatus) {
        localStorage.setItem('clientId', Id);
      }

      if (!appId) {
        const activeStaffId = localStorage.getItem('staff_id');
        let time = '9:30'.split(':');
        let start = moment(new Date()).toDate();
        start.setHours(time[0]);
        start.setMinutes(time[1]);
        start.setSeconds(0);
        const curDate = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');

        let finaltime = startDateTime || curDate;
        if (back) {
          if (quoteId) {
            historyBack.push(`/quotes?showservice=true&startDateTime=${finaltime}&employee_id=${activeStaffId}&waterTest=done&watTesId=${waterTestId}&quoteId=${quoteId}&from=calculator`);
          } else {
            historyBack.push(`/quotes?showservice=true&startDateTime=${finaltime}&employee_id=${activeStaffId}&waterTest=done&watTesId=${waterTestId}`);
          }
        } else if (quoteId) {
          historyBack.push(`addappointment?startDateTime=${finaltime}&employee_id=${activeStaffId}&waterTest=done&waterId=${waterTestId}&quoteId=${quoteId}&from=calculator`);
        } else {
          historyBack.push(`addappointment?startDateTime=${finaltime}&employee_id=${activeStaffId}&waterTest=done&waterId=${waterTestId}`);
        }
        // historyBack.push(`addappointment?startDateTime=${finaltime}&employee_id=${activeStaffId}&waterTest=done&waterId=${waterTestId}`);
      } else {
        // console.log('stateForgygy', state?.editChemicals);
        let oldUrl = getCalendarPath();
        const newUrl = `${oldUrl}&chemicaltype=open&waterId=${waterTestId}`;
        if (invoice) {
          const clientQuery = withClientReturn ? `&clientid=${clident}` : '';
          historyBack.push(`/${invFrom}?viewtype=detailPage&from=viewApp&cal=cal${clientQuery}`);
        } else {
          (state && (state?.editChemicals || state?.comeFrom === 'finalise')) ? backToFinaliseVisit() : historyBack.push(newUrl);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    if (state?.comeFrom !== 'finalise') {
      if ((appId && editChemicals) || (waterId && !visitMessage)) {
        console.log('coimgUnderThisCondition');
        handleWaterTestJob('yes');
      }
    }
  }, []);

  const handleBackToPrevious = () => {
    if (!greenPoolPrint) {
      handleBack();
    } else {
      handleGreenPrint();
    }
  };

  return (
    <>
      <div className="back-option calc-option" onClick={handleBackToPrevious}>
        <span />

        <i
          className="fa fa-times"
          aria-hidden="true"
        />

      </div>
      <div
        className={`new-summary-page appointment-container finalize-cost-page green-pool-working  ${greenPoolPrint ? 'green-pool-treatment' : ''
        }`}
      >

        <div className="main-titles">
          <h3 className={!greenPoolPrint ? '' : 'green-treatment-heading'}>
            {!greenPoolPrint
              ? serviceAnalysis?.includes('Water Test')
                ? 'Water Test Workings' : serviceAnalysis === 'Black-Spot Algae' ? 'Black-Spot Algae Workings'
                  : serviceAnalysis === 'Sand Filter Media Replacement'
                    ? 'Sand Filter Media Replacement Workings'
                    : greenSplitName !== 'Drain'
                      ? greenSplitName === 'Part' || (serviceAnalysis?.includes('Water Test') && calVariable.isDrainage)
                        ? 'Part-drain Pool Workings'
                        : 'Green Pool Workings'
                      : 'Drain Pool Workings'
              : serviceAnalysis?.includes('Water Test')
                ? 'Water Test Treatment'
                : serviceAnalysis === 'Sand Filter Media Replacement'
                  ? 'Sand Filter Media Replacement' : serviceAnalysis === 'Black-Spot Algae' ? 'Black-Spot Algae Treatment'
                    : greenSplitName !== 'Drain'
                      ? greenSplitName === 'Part' || (serviceAnalysis?.includes('Water Test') && calVariable.isDrainage)
                        ? 'Part-drain Pool Costs'
                        : 'Green Pool Treatment'
                      : 'Drain Pool Costs'}
          </h3>
        </div>

        <div className="add-appointment">
          <div className="row">
            <div className="col-md-12">
              <div className="appointment-view">
                <div className="first-visit">
                  <h3 className="pool-size-inputs">
                    Pool Size:
                    <p>
                      {' '}
                      {diameterInput
                        ? poolInputs.diameter
                        : `${poolInputs.poolHeight} x ${poolInputs.poolWidth}`}
                      <span> | </span>
                      {customDepths ? `${poolInputs.shallowEnd} - ${poolInputs.deepEnd}` : 1.25}
                      <span> | </span>
                      {`${
                        !calVariable.poolMsg
                          ? `${poolInputs.poolCapacity
                            .toString()
                            .replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ',',
                            )} ${valueType ? 'litres' : 'gallons'}`
                          : calVariable.poolMsg
                      }`}

                    </p>

                  </h3>
                  {greenLevelData?.length > 0 && greenLevelData.map((mainEl, index) => {
                    let filteredData = mainEl?.service?.length > 0 ? mainEl.service.filter((el) => el.subcategory !== '') : [];
                    return (
                      <>
                        {mainEl?.service?.length !== 0 && (
                        <>
                          {
                            (greenSplitName !== 'Drain'
                                && mainEl?.value === 'Third Visit'
                                && !thirdVisitStatus)

                              || (serviceAnalysis
                                === 'Sand Filter Media Replacement'
                                && mainEl?.value === 'Second Visit'
                                && !thirdVisitStatus) ? null : (
                                  <>

                                    <div className="search-title service-head">
                                      <h3>
                                        {mainEl.value === 'Second Visit'
                                      && (greenSplitName === 'Drain' || greenSplitName === 'Part' || serviceAnalysis === 'Water Test')
                                          ? crossButton
                                            ? (
                                              <>
                                                Second Visit
                                                {' '}
                                                {mainEl.sel === 'selected' ? null : <span className="italic-text"> (after pool is refilled)</span>}
                                              </>
                                            )
                                            : 'Visit Summary'
                                          : (!thirdVisitStatus && serviceAnalysis !== 'Green Pool') || (serviceAnalysis?.includes('Water Test') && !crossButton && !zodiacStatus) || calVariable.balancing ? 'Visit Summary' : mainEl.value}
                                        {' '}
                                        {mainEl.value === 'Third Visit'
                                      && greenPoolPrint ? (
                                        <span>
                                          (includes the cost of any additional
                                          visits)
                                        </span>
                                          ) : null}
                                      </h3>
                                      {!greenPoolPrint && (
                                      <div className="sorting-services">
                                        {(mainEl.value === 'Second Visit'
                                        && greenSplitName === 'Drain'
                                        && crossButton)
                                        || (mainEl.value === 'Third Visit'
                                          && greenSplitName !== 'Drain'
                                          && crossButton)
                                        || (serviceAnalysis
                                          === 'Sand Filter Media Replacement'
                                          && mainEl.value === 'Second Visit')
                                        || (mainEl.value === 'Second Visit'
                                          && greenSplitName === 'Part' && crossButton)
                                        || (mainEl.value === 'Second Visit'
                                          && serviceAnalysis?.includes('Water Test') && crossButton) ? (
                                            <span
                                              className="cancel-icon"
                                              role="presentation"
                                              onClick={() => handleDeleteChemicals(
                                                mainEl.service,
                                                index,
                                                greenSplitName,
                                              )}
                                            >
                                              <i className="fas fa-times" />
                                            </span>
                                          ) : null}
                                        {!crossButton
                                        && mainEl.value === 'Second Visit' && (greenSplitName === 'Drain' || greenSplitName === 'Part' || serviceAnalysis?.includes('Water Test')) ? null : filteredData.length > 1 ? (
                                          <div className="sorting">
                                            <img
                                              src={sort}
                                              alt="sorting"
                                              onClick={() => {
                                                // setRenderSort(!renderSort);
                                                handleSorting(index);
                                                SortedLevelData();
                                              }}
                                            />
                                          </div>
                                          ) : null}

                                        {!crossButton
                                        && mainEl.value === 'Second Visit' && (greenSplitName === 'Drain' || greenSplitName === 'Part' || serviceAnalysis?.includes('Water Test')) ? null : (
                                          <span
                                            className="plus-icon"
                                            role="presentation"
                                            onClick={() => handleShowService(index)}
                                          >
                                            <i className="fas fa-plus" />
                                          </span>
                                          )}
                                      </div>
                                      )}
                                    </div>
                                    {mainEl.value === 'Second Visit'
                                  && (greenSplitName === 'Drain' || greenSplitName === 'Part' || serviceAnalysis?.includes('Water Test'))
                                  && crossButton ? (
                                    <>
                                      {mainEl.sel === 'selected' ? null : (
                                        <h3 className="chemical-require">
                                          Chemicals Required to Balance Water:
                                        </h3>
                                      )}
                                      {updatedWaterSourceStatus && (
                                      <p className="readings-text">
                                        <span>*</span>
                                        {' '}
                                        <span>
                                          Readings have been adjusted to compensate for Water
                                          Source chemistry.
                                          {' '}
                                          <span className="link-tag"> Click here</span>
                                          {' '}
                                          to view.
                                        </span>

                                      </p>
                                      )}
                                    </>
                                      ) : null}
                                    {mainEl?.service?.length > 0
                                  && mainEl.service.map((el, i) => (
                                    <>
                                      {' '}
                                      {i === 1
                                        && mainEl.value === 'Third Visit'
                                        && crossButton
                                        && mainEl.sel !== 'selected'
                                        && calVariable.isDrainage !== null
                                        && calVariable.isDrainage === true
                                        && (greenSplitName !== 'Drain' || greenSplitName !== 'Part') ? (
                                          <>
                                            {!chemicalBalanceStatus ? (
                                              <div className="cancel-chemical">
                                                <h3 className="chemical-require">
                                                  Chemicals Required to Balance
                                                  Water:
                                                </h3>
                                              </div>
                                            ) : null}
                                          </>
                                        ) : null}
                                      {el.subcategory !== '' ? (
                                        <>
                                          <div
                                            className={`search-title ${mainEl.isActive
                                              && 'add-sortings'}`}
                                          >
                                            <div
                                              className="client-profile"
                                              onClick={() => {
                                                el.status === 'quantity' && el.type === 'product' ? null : handleEditService({
                                                  service: el,
                                                  second: i,
                                                  first: index,
                                                });
                                              }}
                                            >

                                              <div className="client-pro-icon">
                                                <h3>

                                                  {`${el?.category
                                                    .toLowerCase()
                                                    .includes('misc')
                                                    ? `${el?.customMisc
                                                    } ${displayDollar(el?.renderCost)}${addZero(
                                                      el?.renderCost,
                                                    )}`
                                                    : !greenPoolPrint ? el?.subcategory : rmvQunt(el?.subcategory, el?.chemicalDisplayName)
                                                  } ${parseFloat(el?.quantity)
                                                      > 1
                                                      && el?.status !== 'quantity'
                                                      && el?.pricingType
                                                      !== 'fixedCost'
                                                    ? `(${displayDollar(el?.cost)}${`${addZero(
                                                      el?.cost,
                                                    )}${el?.soldPer
                                                        === 'Kilogram (kg)'
                                                      ? ' per kg'
                                                      : ''
                                                    }`} x ${`${el?.type
                                                        === 'service'
                                                        && el?.pricingType
                                                        === 'hourly'
                                                      ? convertToReadableDuration(
                                                        el?.quantity,
                                                      )
                                                      : `${el?.soldPer
                                                          === 'Kilogram (kg)'
                                                        ? `${el?.quantity}kg`
                                                        : el?.quantity
                                                      }`
                                                    }${el?.type === 'service'
                                                      ? ' '
                                                      : ''
                                                    })`}`
                                                    : el?.status
                                                        === 'quantity'
                                                        && el?.greenType === 'green'
                                                      ? `${displayDollar((el?.type
                                                        === 'service'
                                                        && el?.pricingType
                                                        === 'fixedCost'
                                                        ? el?.cost
                                                        : el?.renderCost))}${el?.type
                                                          === 'service'
                                                          && el?.pricingType
                                                          === 'fixedCost'
                                                        ? addZero(el?.cost)
                                                        : addZero(
                                                          el?.renderCost,
                                                        )
                                                      }`
                                                      : ''
                                                  } ${parseFloat(el?.actualSellPrice) > parseFloat(el?.renderCost) ? `(reduced from $${el?.actualSellPrice})` : ''}`}
                                                </h3>
                                              </div>
                                              {!greenPoolPrint ? (
                                                <p>
                                                  <span>
                                                    {moment(el?.startTime, [
                                                      timeFromDateFormat,
                                                    ]).format('h:mm a')}
                                                  </span>

                                                  <span> | </span>
                                                  <span>
                                                    {` ${convertToReadableDuration(
                                                      el?.duration,
                                                    )}`}
                                                  </span>
                                                  <span> | </span>
                                                  <span>{` with ${el?.staffName} `}</span>
                                                  <span>
                                                    {!greenPoolPrint ? '|' : ''}
                                                  </span>
                                                </p>
                                              ) : null}
                                              <p>
                                                {!greenPoolPrint ? (
                                                  <span>
                                                    {`${el?.type === 'product'
                                                      ? `Margin ${displayDollar(el.laborMargin)}${addZero(
                                                        el.laborMargin, 'margin',
                                                      )}`
                                                      : el?.type === 'service'
                                                        ? `Labour ${displayDollar(el.totalLabour)}${`${addZero(
                                                          el.totalLabour, 'labour',
                                                        )}`}`
                                                        : null
                                                    }`}
                                                  </span>
                                                ) : (
                                                  ''
                                                )}
                                              </p>
                                            </div>
                                            <div className="cancel-chemical">
                                              <div className="sorting-sub-services appointment-sort">

                                                <div className="service-edit-icon">
                                                  {mainEl?.isActive ? (
                                                    <div
                                                      className={`sorting up-down ${mainEl.isActive
                                                        && 'active-sort'}`}
                                                    >
                                                      {i !== 0 ? (
                                                        <img
                                                          src={upsort}
                                                          alt="sorting"
                                                          onClick={() => moveNewArr(
                                                            i,
                                                            i - 1,
                                                            'minus',
                                                            index,
                                                          )}
                                                        />
                                                      ) : null}
                                                      {i !== el.length - 1 ? (
                                                        <img
                                                          src={downsort}
                                                          alt="sorting"
                                                          onClick={() => moveNewArr(
                                                            i,
                                                            i + 1,
                                                            'plus',
                                                            index,
                                                          )}
                                                        />
                                                      ) : null}
                                                    </div>
                                                  ) : null}
                                                </div>
                                                {!greenPoolPrint ? (
                                                  <>

                                                    {(el?.status === 'quantity'
                                                      && el?.type
                                                      === 'product') ? null : (
                                                        <div
                                                          className="content-dropdown"
                                                          onClick={() => handleEditService({
                                                            service: el,
                                                            second: i,
                                                            first: index,
                                                          })}
                                                        >
                                                          <Dropdown name="startingDate">
                                                            <Dropdown.Toggle
                                                              variant="link"
                                                              id="dropdown-basic"
                                                            >
                                                              <img
                                                                src={arrow}
                                                                alt="arrow"
                                                              />
                                                            </Dropdown.Toggle>
                                                          </Dropdown>
                                                        </div>
                                                      )}
                                                  </>
                                                ) : null}
                                              </div>

                                              {(mainEl?.value
                                                === 'Third Visit'
                                                && crossButton
                                                && !greenPoolPrint
                                                && el?.status === 'quantity'
                                                && greenSplitName !== 'Drain')
                                                || (serviceAnalysis
                                                  === 'Sand Filter Media Replacement'
                                                  && mainEl?.value
                                                  === 'Second Visit'
                                                  && crossButton
                                                  && !greenPoolPrint
                                                  && el?.status === 'quantity')
                                                || (!greenPoolPrint
                                                  && greenSplitName === 'Part'
                                                  && crossButton)
                                                || (serviceAnalysis?.includes('Water Test')
                                                  && crossButton
                                                  && !greenPoolPrint
                                                  && calVariable.isDrainage)
                                                  || (serviceAnalysis?.includes('Water Test')
                                                  && crossButton
                                                  && !greenPoolPrint
                                                  && zodiacStatus
                                                  && mainEl?.value === 'Second Visit'
                                                  && balanceZodiac === 'Zodiac Calcium Down')
                                                  || (serviceAnalysis?.includes('Drain') && mainEl?.value === 'Second Visit'
                                                  && crossButton
                                                  && !greenPoolPrint)

                                                ? (
                                                  <>
                                                    {chemicalBalanceStatus
                                                      || el?.type
                                                        === 'service' ? null : (
                                                          <span
                                                            className="plus-icon"
                                                            role="presentation"
                                                            onClick={() => handleDeleteSingleChemicals(
                                                              mainEl.service,
                                                              index,
                                                              i,
                                                            )}
                                                          >
                                                            <i className="fas fa-times" />
                                                          </span>
                                                      )}
                                                  </>
                                                ) : null}
                                            </div>
                                          </div>
                                          {/* {mainEl.value === 'First Visit'
                                          && calVariable.isDrainage !== null
                                          && calVariable.isDrainage === true
                                          && !serviceAnalysis?.includes('Water Test')
                                          && greenSplitName !== 'Part'
                                          && el.subcategory.includes(
                                            '% of water',
                                          ) ? (
                                            <h6 className="retest-heading">
                                              <span className="start-heading">
                                                *
                                              </span>
                                              Retest other chemicals after pool
                                              is refilled
                                            </h6>
                                            ) : null} */}
                                        </>
                                      ) : null}
                                    </>
                                  ))}

                                    {(!crossButton
                                  && greenSplitName === 'Drain'
                                  && mainEl?.value === 'Second Visit')
                                  || (!crossButton
                                    && serviceAnalysis
                                    === 'Sand Filter Media Replacement'
                                    && mainEl?.value === 'Second Visit')
                                  || (!crossButton
                                    && mainEl?.value === 'Second Visit'
                                    && greenSplitName === 'Part')
                                  || (!crossButton
                                    && mainEl?.value === 'Second Visit'
                                    && serviceAnalysis?.includes('Water Test')) ? null : (
                                      <div className="appointment-total">
                                        {!greenPoolPrint && (
                                        <p>
                                          {(greenSplitName === 'Drain'
                                          && index < 2)
                                          || serviceAnalysis
                                          === 'Sand Filter Media Replacement'
                                          || serviceAnalysis?.includes(
                                            'Water Test',
                                          )
                                          || (greenSplitName === 'Part' && index < 2)
                                          || (serviceAnalysis?.includes('Water Test') && !calVariable.isDrainage)
                                            ? !crossButton
                                              ? `Visit Total: $${TotalPartVist(
                                                mainEl.service,
                                              )}`
                                              : `Visit Part-${index
                                            + 1} (of 2) Total: $${TotalPartVist(
                                                mainEl.service,
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ',',
                                              )}`
                                            : (greenSplitName !== 'Part'
                                            || greenSplitName !== 'Drain')
                                            && !crossButton
                                            && mainEl.value === 'Second Visit'
                                              ? `Visit Part-${index
                                            + 1} (of 2) Total: $${TotalPartVist(
                                                mainEl.service,
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ',',
                                              )}`
                                              : `Visit Part-${index
                                            + 1} (of 3) Total: $${TotalPartVist(
                                                mainEl.service,
                                              ).replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ',',
                                              )}`}
                                        </p>
                                        )}
                                        {!greenPoolPrint && (
                                        <p>
                                          Duration:
                                          <span className="duration-color">
                                            {convertToReadableDuration(
                                              TotalPartDuration(mainEl.service),
                                            )}
                                          </span>
                                        </p>
                                        )}
                                        {!greenPoolPrint
                                      && mainEl?.service?.length !== 0 ? (
                                        <>
                                          {mainEl.service.filter(
                                            (el) => el.type === 'product',
                                          ).length ? (
                                            <p>
                                              Labour & Margin:
                                              <span className="duration-color">
                                                {`$${totalLabourAndMargin(
                                                  mainEl.service,
                                                ).replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ',',
                                                )}`}
                                              </span>
                                            </p>
                                            ) : (
                                              <p>
                                                Labour Total:
                                                <span className="duration-color">
                                                  {`$${totalLabourAndMargin(
                                                    mainEl.service,
                                                  ).replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ',',
                                                  )}`}
                                                </span>
                                              </p>
                                            )}
                                        </>
                                          ) : null}
                                      </div>
                                      )}
                                  </>
                              )
}
                        </>
                        )}
                      </>
                    );
                  })}

                  <>

                    <div className="multi-visit-cost">
                      {!greenPoolPrint ? (
                        <h5>
                          {(!crossButton && (calVariable?.balancing || greenSplitName === 'Drain' || greenSplitName === 'Part' || serviceAnalysis?.includes('Water Test')))
                              || (serviceAnalysis
                                === 'Sand Filter Media Replacement'
                                && !crossButton)
                              || (serviceAnalysis?.includes('Water Test') && !calVariable?.isDrainage
                                && !crossButton)
                            ? null
                            : `
                            ${`Multi-Visit Total: $${TotalMultiVisitTotal(
                              greenLevelData,
                            ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                            `}
                        </h5>
                      ) : (
                        <h5>
                          {`Total Cost: $${TotalMultiVisitTotal(
                            greenLevelData,
                          ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} (+gst)`}
                        </h5>
                      )}
                      {!greenPoolPrint ? (
                        <>
                          <h5>
                            {(!crossButton && (calVariable?.balancing || greenSplitName === 'Drain' || greenSplitName === 'Part' || serviceAnalysis?.includes('Water Test')))
                                || (serviceAnalysis
                                  === 'Sand Filter Media Replacement'
                                  && !crossButton)
                                || (serviceAnalysis?.includes('Water Test')
                                  && !crossButton && !calVariable?.isDrainage)
                              ? null
                              : `
                              ${`Combined Duration: ${convertToReadableDuration(
                                TotalMultiDurationTotal(greenLevelData),
                              )}`}
                              `}
                          </h5>
                          <h5>
                            {(!crossButton && (calVariable?.balancing || greenSplitName === 'Drain' || greenSplitName === 'Part' || serviceAnalysis?.includes('Water Test')))
                                || (serviceAnalysis
                                  === 'Sand Filter Media Replacement'
                                  && !crossButton)
                                || (serviceAnalysis?.includes('Water Test')
                                  && !crossButton)
                              ? null
                              : `
                              ${`Combined Labour & Margin: $${TotalMutiLabourMargin(
                                greenLevelData,
                              ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
                              `}
                          </h5>
                        </>
                      ) : null}
                    </div>

                  </>

                  {!greenPoolPrint ? (
                    <div
                      className={`footer-btn view-btn drain-btn ${greenSplitName !== 'Part' && calVariable?.isDrainage !== null
                        && calVariable.isDrainage === true && !serviceAnalysis?.includes('Water Test')
                        ? 'footer-dainage-btn'
                        : ''
                      }`}
                    >
                      <Button
                        className="btn-delete"
                        onClick={() => {
                          serviceAnalysis?.includes('Water Test')
                            ? handleBack('WaterTest')
                            : handleBack();
                          // handleStatusCrossVisit();
                        }}
                      >
                        {greenSplitName !== 'Part' && calVariable?.isDrainage !== null
                          && calVariable.isDrainage === true && !serviceAnalysis?.includes('Water Test') ? (
                            <span>
                              <i className="fas fa-chevron-left" />
                              {' '}
                            </span>
                          ) : (
                            ''
                          )}
                        Back
                      </Button>
                      {greenSplitName !== 'Part' && calVariable?.isDrainage !== null
                      && calVariable.isDrainage === true && !serviceAnalysis?.includes('Water Test') ? (
                        <Button
                          type="button"
                          className="btn btn-primary withoutdrainage"
                          onClick={(e) => {
                            handleCalculate({
                              e,
                              withDrainage: true,
                              resDrainData:getresDrainData,
                            });
                          }}
                        >
                          {' '}
                          <span>
                            <img src={draining} alt="drain" />
                          </span>
                          No Draining
                        </Button>
                        ) : null}
                      <Button
                        className="appointment-btn btn-save"
                        onClick={handleGreenPrint}
                      >
                        Next
                        {' '}
                        {greenSplitName === 'Part' || serviceAnalysis?.includes('Water Test') ? <span>(2 of 3) </span> : ''}
                      </Button>
                    </div>
                  ) : (
                    <div className="footer-btn view-btn footer-treatment footer-dainage-btn green-drin-btn">
                      <Button className="btn-delete" onClick={handleGreenPrint}>
                        <span>
                          <i className="fas fa-chevron-left" />
                        </span>
                        {' '}
                        Back
                      </Button>
                      {/* <Button className="btn-primary withoutdrainage btn" onClick={transferAppointmentData}>
                        <Link
                          to={{
                            pathname: '/addappointment',
                            state: { greenLevelData, greenSplitName },
                          }}
                        >
                          View Other Options
                        </Link>
                      </Button> */}
                      <div className="client-option service-edit-icon" style={{ display:appId ? 'none' : 'visible' }}>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="btn btn-edi"
                          >
                            Other Options
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div
                                className="action"
                                onClick={() => transferDataToquote('yes', 'save')}
                              >
                                Save to Quotes
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => transferDataToquote('yes', 'pending')}>Add to Pending Quote</div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <Button className={`appointment-btn btn-save ${appId ? 'update-appt-btn' : ''}`} onClick={appId && !visitMessage && state?.comeFrom !== 'finalise' ? () => addToJob() : () => handleWaterTestJob('yes')}>
                        {appId ? 'Update Appointment' : 'Add to Job'}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {greenPoolPrint || serviceAnalysis?.includes('Water Test') || greenSplitName === 'Part' || ((watId) && !visitMessage) ? null : (
        <GreenPoolCalculationPrinting
          levelData={levelData}
          greenLevelData={greenLevelData}
          calVariable={calVariable}
          greenApiData={greenApiData}
          poolInputs={poolInputs}
          greenSplitName={greenSplitName}
          crossButton={crossButton}
          serviceAnalysis={serviceAnalysis}
          thirdVisitStatus={thirdVisitStatus}
        />
      )}
      <ServiceModal
        showService={showService}
        setShowService={setShowService}
        handleShowService={handleShowService}
        newLength={newLength}
        categories={categories}
        subcategories={subcategories}
        handleBookingData={handleBookingData}
        getTimes={getTimes}
        bookingData={bookingData}
        renderOptions={renderOptions}
        staffList={staffList}
        handleAddService={handleAddService}
        editData={editData}
        handleDeleteService={handleDeleteService}
        handleSearchCategory={handleSearchCategory}
        setSubcategories={setSubcategories}
        addSubcategoryToService={addSubcategoryToService}
        editService={editService}
        setBookingData={setBookingData}
        staffIndex={staffIndex}
        misc={misc}
        addedServices={addedServices}
        servicesLength={addedServices.length}
        extras={extras}
        handleChangeCustom={handleChangeCustom}
        timepickerShow={timepickerShow}
        handleTimePicker={handleTimePicker}
        subcategoryTxt={subcategoryTxt}
        setSearchTxt={setSearchTxt}
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        handleDurationPicker={handleDurationPicker}
        setnewlength={setnewlength}
        setHourPicker={setHourPicker}
        hourPicker={hourPicker}
        handleHourPicker={handleHourPicker}
        appointmentType={appointmentType}
        levelData={levelData}
        error={errorValue}
      />
      {timepickerShow ? (
        <TimePicker
          timepickerShow={timepickerShow}
          handleTimePicker={handleTimePicker}
          bookingData={bookingData}
          setBookingData={setBookingData}
          timeData={timeData}
          setTime={setTime}
        />
      ) : null}
      {durationPicker ? (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}
      {hourPicker ? (
        <HourPicker
          setHourPicker={setHourPicker}
          handleHourPicker={handleHourPicker}
          hourPicker={hourPicker}
          hourData={hourData}
          handleDurationPicker={handleDurationPicker}
          setHourData={setHourData}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}
      {openConfirmBox
        && (
          <AddjobConfimationPopup
            openConfirmBox={openConfirmBox}
            setOpenConfirmBox={setOpenConfirmBox}
            handleWaterTestJob={handleWaterTestJob}
            chemicalArray={chemicalArray}
            waterHistory={waterHistory}
          />
        )}
    </>
  );
};
GreenpoolWorking.propTypes = {
  handleBack: PropTypes.func.isRequired,
  greenApiData: PropTypes.arrayOf.isRequired,
  levelData: PropTypes.arrayOf.isRequired,
  chemicalaArray: PropTypes.arrayOf.isRequired,
  calVariable: PropTypes.objectOf.isRequired,
  poolInputs: PropTypes.objectOf.isRequired,
  greenSplitName: PropTypes.string.isRequired,
  labourCostCalculation: PropTypes.func.isRequired,
  handleCalculate: PropTypes.func.isRequired,
  chemicalBalanceStatus: PropTypes.bool.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
  getresDrainData:PropTypes.objectOf.isRequired,
  updatedWaterSourceStatus: PropTypes.bool.isRequired,
  waterHistory: PropTypes.objectOf.isRequired,
  balanceZodiac:PropTypes.string.isRequired,
  zodiacStatus:PropTypes.bool.isRequired,
  sethistoryData:PropTypes.func.isRequired,
  customDepths: PropTypes.bool.isRequired,
  diameterInput: PropTypes.bool.isRequired,
  valueType:PropTypes.bool.isRequired,
};
export default GreenpoolWorking;
