export const headersClient = [
  {
    ContactType: '',
    ' Company Name': '',
    'ContactsFirst Name': '',
    'ContactsLast Name': '',
    'ContactsMobile Number': '',
    ContactsLandlineNo: '',
    ContactsEmailAddress: '',
    Country: '',
    State: '',
    ' City/Town/Suburb': '',
    'Street Address': '',
    'Postal / Zip Code': '',
    PoolLengt: '',
    PoolWidth: '',
    PoolFinish: '',
    ChlorinationType: '',
    Chlorination: '',
    AutomaticInvoiceSending: '',
    Gender: '',
    ClientNotes: '',
  },
];

export const headerService = [
  {
    AppointmentChecklist: '',
    'Buy Cost': '',
    Category: '',
    Description: '',
    Duration: '',
    Name: '',
    Tax: '',
  },
];

export const headerProduct = [
  {
    AppointmentChecklist: '',
    'Buy Cost': '',
    Category: '',
    Description: '',
    Duration: '',
    Name: '',
    Tax: '',
    Barcode: '',
    'Product Code': '',
    'Retail Cost': '',
    Supplier: '',
    'Supply Unit': '',
    Tracked: '',
    'Sold Unit': '',
  },
];
export const headerInventory = [
  { AppointmentChecklist: '' },
  { 'Buy Cost': '' },
  { Category: '' },
  { Description: '' },
  { Duration: '' },
  { Name: '' },
  { Tax: '' },
  { Barcode: '' },
  { 'Product Code': '' },
  { 'Retail Cost': '' },
  { Supplier: '' },
  { 'Supply Unit': '' },
  { Tracked: '' },
  { 'Sold Unit': '' },
  { Location: '' },
  { stock: '' },
  { 'Team Member': '' },
];
export const staffHeader = [
  {
    Firstname: '',
    Email: '',
    AccessLevel: '',
    Lastname: '',
    Location: '',
    MobileNo: '',
    Note: '',
    State: '',
    StreetAddress: '',
    Suburb: '',
    ZipCode: '',
  }];
