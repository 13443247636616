import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Helmet from 'react-helmet';
import moment from 'moment';
import Hammer from 'hammerjs';
import 'react-calendar/dist/Calendar.css';
import CalendarModal from './calendarmodal';
import { createEventId } from './event-utils';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import { HeaderContext } from '../../contexts/HeaderContext';
import filter from '../../assets/admin/filter-icon.png';
import left1 from '../../assets/home/left1.png';
import left2 from '../../assets/home/left2.png';
import left3 from '../../assets/home/left3.png';
import right1 from '../../assets/home/right1.png';
import right2 from '../../assets/home/right2.png';
import right3 from '../../assets/home/right3.png';
import { userInstance } from '../../config/axios';
import { showErrMsg } from '../../functions/index';
import TeamMember from './teamMembers';
import aroow from '../../assets/home/showmoreblue.png';
import Viewappointment from '../appointment/view-appointment';
import './calendar.css';
import Event from './event';
// import DateModal from '../appointment/dateModal';
let lastScrollTop = 0;
var h = window.innerHeight;
var val = 50;
const Cal = () => {
  const [isSticky, setIsSticky] = useState('');
  const ref = React.createRef();
  const watchScroll = () => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      setIsSticky('shift-up');
      // console.log(st, 'st');
      // downscroll code
    } else {
      setIsSticky('shift-down');
      // upscroll code
    }
    lastScrollTop = st <= 0 ? 0 : st;
  };
  useEffect(() => {
    if (window !== undefined && window.screen.width <= 768) {
      window.addEventListener('scroll', watchScroll);
    }
    return () => {
      window.removeEventListener('scroll', watchScroll);
    };
  }, []);
  const renderWeeks = (data) => {
    const { date } = data;
    // console.log({
    //   today: moment(new Date(date).toLocaleDateString()).isSame(
    //     new Date().toLocaleDateString(),
    //   ),
    //   date: new Date(date).toLocaleDateString(),
    //   currentDate: new Date().toLocaleDateString(),
    // });
    const jsx = (
      <div
        className={`grid-box ${
          moment(new Date(date).toLocaleDateString()).isSame(
            new Date().toLocaleDateString(),
          )
            ? 'selected'
            : ''
        }`}
      >
        <span>{date.toString().substring(0, 3)}</span>
        <h5>{moment(date).format('D')}</h5>
      </div>
    );
    return jsx;
  };
  return (
    <>
      <Helmet>
        <html
          className="calendar-main-page"
          lang="en"
        />
      </Helmet>
      <AdminWrapper>
        <div
          className={`calendar-container new-cal ${isSticky}`}
          id="dds"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="demo-app calendar-inner">
                  <div className="demo-app-main main-calendar">
                    <div className="calender-date-section">
                      <div className="calendar-date">
                        <div className="calendar-date-client">
                          <div className="calendar-filter-prev">
                            <div className="calendar-filter-icons">
                              <span>
                                <img
                                  src={left3}
                                  alt="arrow"
                                />
                              </span>
                              <span>
                                <img
                                  src={left2}
                                  alt="arrow"
                                />
                              </span>
                              <span>
                                <img
                                  src={left1}
                                  alt="arrow"
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            className="date"
                            role="presentation"
                          >
                            August 2021
                            <br />
                            {' '}
                            <span>Week 35</span>
                          </div>

                          <div className="calendar-filter-next">
                            <div className="calendar-filter-icons">
                              <span>
                                <img
                                  src={right1}
                                  alt="arrow"
                                />
                              </span>
                              <span>
                                <img
                                  src={right2}
                                  alt="arrow"
                                />
                              </span>
                              <span>
                                <img
                                  src={right3}
                                  alt="arrow"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="team-member">
                          <p>Team Member:</p>
                        </div>
                      </div>
                      <div className="calender-option">
                        {/* <i className="fa fa-ellipsis-h" aria-hidden="true" onClick={handleShowTeam} />
                        <span className="navbar-toggler-icon" onClick={handleHeader} role="presentation" /> */}
                        <img src={aroow} alt="arrow" />
                      </div>
                    </div>

                    {/* <div className="calendar-filters">
                      <span className="elipse-icon"><i className="fas fa-ellipsis-h" /></span>
                      <span className="filter-icon"><img src={filter} alt="filter-icon" /></span>
                    </div> */}
                    <div className="calendar-view-page">
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        headerToolbar={{
                          left: 'prev,next today',
                          center: 'title',
                          right: 'timeGridWeek,timeGridDay',
                        }}
                        initialView="timeGridWeek"
                        editable
                        selectable
                        selectMirror
                        dayMaxEvents
                        allDaySlot={false}
                        slotDuration="00:15:00"
                        slotLabelInterval="01:00"
                        slotMaxTime="24:00:00"
                        slotLabelFormat={{
                          hour: 'numeric',
                          minute: '2-digit',
                          meridiem: 'short',
                        }}
                        dropable
                        dragScroll
                        eventBackgroundColor="#fff"
                        longPressDelay={1000}
                        dayCellClassNames="testclass"
                        firstDay={1}
                        height="auto"
                        dayHeaderContent={(data) => renderWeeks(data)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </AdminWrapper>

    </>

  );
};

export default Cal;
