import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Button, Modal, Nav, Tabs, Tab,
} from 'react-bootstrap';
import getStrNum from '../../functions/strNumseparator';

const ChangeTextPopup = ({
  textPopup, handleTextPopup, saveUpdateText,
  text, placeholderText, type, openingText, closingText,
}) => {
  const textVal = type === 'Opening' ? openingText : closingText;
  let sizeCount = 0;
  const Font = ['Times',
    'Arial', 'Helvetica Neue',
    'Helvetica', 'sans-serif', 'BlinkMacSystemFont',
    'Consolas', 'Menlo', 'Monaco', 'Lucida Console',
    'Liberation Mono', 'DejaVu Sans Mono',
    'Bitstream Vera Sans Mono', 'Courier New',
    'monospace', '"Exo 2", sans-serif'];
  const [customObject, setCustomObject] = useState(text);
  const [value, setValue] = useState(textVal);
  const handleEditText = (txt) => {
    setValue(txt);
  };

  return (
    <Modal
      size="md"
      show={textPopup}
      onHide={handleTextPopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup change-text-popup"
    >
      <Modal.Body>
        <div className="appointment-container preview-popup">
          <div className="change-image-section">

            <div className="preview-text">
              <h6>{placeholderText || 'Text Wording'}</h6>
              <span style={{
                fontFamily: `${customObject.style.fontFamily}`,
                fontSize: `${customObject.style.fontSize}px`,
                color: `${customObject.style.color}`,
                fontStyle: `${customObject.style.fontStyle}`,
                textDecoration: `${customObject.style.textDecoration}`,
                fontWeight: `${customObject.style.fontWeight}`,
                lineHeight: `${customObject.style.lineHeight}`,
                border: '1px solid rgb(220, 217, 217)',
                padding: '10px',
                marginBottom: '15px',
                display: 'flex',
                borderRadius: '5px',
              }}
              >
                {value}
              </span>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows={3} value={value} onChange={(e) => handleEditText(e.target.value)} />
              </Form.Group>
            </div>

            <h6>Type Characteristics</h6>
            <div className="field-location-section">
              <div className="field-location">

                <Form.Group controlId="formGridEmail">
                  <Form.Label>Typeface</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.font}>
                      {Font.map((f) => (
                        <option value={f}>{f}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Point Size</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontSize}>
                      {Array.from(Array(20).keys()).map(() => {
                        sizeCount += 1;
                        return (
                          <option value={sizeCount}>{sizeCount}</option>
                        );
                      })}
                    </select>
                  </div>
                </Form.Group>

              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Style</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontStyle}>
                      <option value="normal">Normal</option>
                      <option value="Italic">Italic</option>
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Line Spacing</Form.Label>
                  <Form.Control type="number" value={getStrNum(customObject?.style?.lineHeight)?.num} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Alignment</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textAlign}>
                      <option value="left">Flush Left</option>
                      <option value="center">Centered</option>
                      <option value="right">Flush Right</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Colour</Form.Label>
                  <Form.Control type="color" value={customObject?.style?.color} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Effects</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textDecoration}>
                      <option value="underline">UnderLine</option>
                      <option value="line-through">Strikeout</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Weight</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontWeight}>
                      <option value="bold">Bold</option>
                      <option value="bolder">Bolder</option>
                      <option value="lighter">Lighter</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="text-popup-spacing">
              <h6>Text Field Location & Size</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.tdStyle?.paddingTop)?.num} name="paddingTop" />
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Distance from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.tdStyle?.paddingLeft)?.num} name="paddingLeft" />
                  </Form.Group>
                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Height</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.tdStyle?.lineHeight)?.num} name="lineHeight" />
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Width</Form.Label>
                    <Form.Control type="number" value={getStrNum(customObject?.tdStyle?.width)?.num} name="width" />
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className={`template-btn view-btn ${customObject.textFeild ? 'new-btn' : 'new-btn-section'}`}>
            <Button className="save-btn" onClick={() => saveUpdateText(value, type)}>Save/ Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
};
ChangeTextPopup.propTypes = {
  textPopup: PropTypes.func.isRequired,
  handleTextPopup: PropTypes.bool.isRequired,
  saveUpdateText: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  openingText: PropTypes.string.isRequired,
  closingText: PropTypes.string.isRequired,
  placeholderText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default ChangeTextPopup;
