import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import StartEndDate from './startEndDate';
import crosss from '../../assets/home/black-icon.png';
import search from '../../assets/home/search.png';
import { userInstance } from '../../config/axios';
import {
  filterAppointmentObj,
} from '../../functions/index';

const InventoryProductSummary = () => {
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });
  const [datePopup, setDatePopup] = useState();
  const [allLocation, setlocationList] = useState([]);
  const [allteamList, setallteamList] = useState([]);
  const [filterStatus, setfilterStatus] = useState('');
  const [totoalSummary, settotoalSummary] = useState({ totalLabour: 0, totalSale: 0 });
  const [appsummaryDate, setappsummaryDate] = useState();
  const [productData, setProductData] = useState([]);
  const [productList, setProductList] = useState([]);

  const initialfilterObj = {
    product: 'allProducts',
    productStatus: false,
    productsId: '',
    searchProducts: '',
    location: 'alllocation',
    searchlocation: '',
    teammember: 'allteam',
    searchteammember: '',
    locationId: '',
    teammemberId: '',
    locationStatus: false,
    teamStatus: false,
    datetype: 'today',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
  };
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  // console.log('filterObj---', filterObj);
  const handleDateRange = (start, end) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.rangeStartDate = start;
    copyfilterObj.rangeEndDate = end;
    setFilterObj(copyfilterObj);
    setfilterStatus(start);
    setDatePopup(false);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    setfilterStatus(value);
    let copyfilterObj = { ...filterObj, [name]: value };
    setFilterObj(copyfilterObj);
  };
  const removeSearchText = (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj[value] = '';
    if (value === 'searchlocation') {
      copyfilterObj.locationId = '';
    } else {
      copyfilterObj.teammemberId = '';
    }
    setFilterObj(copyfilterObj);
  };

  const searchProducts = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchProducts = value;
    copyfilterObj.productStatus = true;
    setFilterObj(copyfilterObj);
    if (value) {
      const response = await userInstance().get(`/service/getServices?value=${value}`);
      const { services } = response.data;
      const productsName = services.filter((el) => el.type === 'product');
      setProductList(productsName);
    } else {
      setProductList([]);
    }
  };

  const filterProduct = async () => {
    const filterAppObj = filterAppointmentObj(filterObj);
    setappsummaryDate(filterAppObj.summaryDate);
    const payload = {
      filterObj,
      filterAppObj,
    };
    const response = await userInstance().post(
      '/service/productSummary', payload,
    );
    const {
      productDetails, code,
    } = response.data;

    if (code === 200) {
      setProductData(productDetails);
    }
  };
  const searchLocation = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchlocation = value;
    copyfilterObj.locationStatus = true;
    setFilterObj(copyfilterObj);
    const response = await userInstance().get(
      `/appointment/filterLocation?location=${value}`,
    );
    const { locationList, code } = response.data;
    if (code === 200) {
      setlocationList(locationList);
    }
  };
  const selectValue = (name, value, id) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj[name] = value;
    setfilterStatus(value);
    if (name === 'searchlocation') {
      copyfilterObj.locationId = id;
      copyfilterObj.locationStatus = false;
    } else if (name === 'searchteammember') {
      copyfilterObj.teammemberId = id;
      copyfilterObj.teamStatus = false;
    } else {
      copyfilterObj.productsId = id;
      copyfilterObj.productStatus = false;
    }
    setFilterObj(copyfilterObj);
  };
  const searchTeammember = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.searchteammember = value;
    copyfilterObj.teamStatus = true;
    setFilterObj(copyfilterObj);
    const response = await userInstance().get(
      `/appointment/filterTeammember?team=${value}&location=${filterObj.locationId}&locationtype=${filterObj.location}`,
    );
    const { teamList, code } = response.data;
    if (code === 200) {
      setallteamList(teamList);
    }
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setlocationList(null);
          let copyfilterObj = { ...filterObj };
          copyfilterObj.teamStatus = false;
          setFilterObj(copyfilterObj);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  useEffect(() => {
    filterProduct(filterStatus);
  }, [filterStatus]);

  return (

    <div className="appointment-summary">
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Products</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="product">
            <option value="allProducts">All Products</option>
            <option value="specificProduct">Specific Product</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.product === 'specificProduct' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchProducts" onChange={(e) => searchProducts(e.target.value)} value={filterObj.searchProducts} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchProducts', 'productId')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.productStatus && (
          <>
            {productList && productList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {productList.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el._id}
                    key={el._id}
                    onClick={() => selectValue('searchProducts', `${el.category}`, el._id)}
                  >
                    {`${el.category}`}
                  </li>
                ))}
              </ul>
            ) : productList && !productList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Location</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="location">
            <option value="alllocation">All Locations</option>
            <option value="specificlocations">Specific Locations</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.location === 'specificlocations' ? (
        <div className="search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchlocation" onChange={(e) => searchLocation(e.target.value)} value={filterObj.searchlocation} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchlocation')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.locationStatus && (
          <>
            {allLocation && allLocation.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {allLocation.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el.locationName}
                    key={el._id}
                    onClick={() => selectValue('searchlocation', el.locationName, el._id)}
                  >
                    {el.locationName}
                  </li>
                ))}
              </ul>
            ) : allLocation && !allLocation.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Team Member</Form.Label>
        <div className="select-box">
          <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="teammember">
            <option value="allteam">All Team Members</option>
            <option value="specificteam">Specific Team Members</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.teammember === 'specificteam' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchteammember" onChange={(e) => searchTeammember(e.target.value)} value={filterObj.searchteammember} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchteammember')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.teamStatus && (
          <>
            {allteamList && allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {allteamList.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el._id}
                    key={el._id}
                    onClick={() => selectValue('searchteammember', `${el.firstname} ${el.lastname}`, el._id)}
                  >
                    {`${el.firstname} ${el.lastname}`}
                  </li>
                ))}
              </ul>
            ) : allteamList && !allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            value={filterObj.datetype}
            onChange={(e) => handleOnchange(e.target)}
            name="datetype"
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
            <option value="lastweek">Last Week</option>
            <option value="lastyear">Last Year</option>
            <option value="nextweek">Next Week</option>
            <option value="nextyear">Next Year</option>
            <option value="weekdate">Week to Date</option>
            <option value="monthdate">Month to Date</option>
            <option value="yeardate">Year to Date</option>
            <option value="daterange">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>

      {filterObj.datetype === 'daterange' ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>
              {`${moment(filterObj.rangeStartDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">
              {' '}
              {`${moment(filterObj.rangeEndDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
        </div>
      ) : (
        ''
      )}

      <div className="appointment-summary-section">
        <p>Product Transaction Summary</p>
        <p>{appsummaryDate}</p>
        <hr />
        <div className="details-table">
          <div className="details-body">
            <h6>Product Name</h6>
            <p>Viron P320 Variable Speed Pump</p>
          </div>
          <div className="details-body">
            <h6>Team Member</h6>
            <p>Mano Gaurav</p>
          </div>
          <div className="details-body">
            <h6>Branch Location</h6>
            <p>12, Evansdale Road, Howthorn VIC 3122</p>
          </div>
          <div className="details-body">
            <h6>No. of Items Sold</h6>
            <p>215</p>
          </div>
          <div className="details-body">
            <h6>Average Buy Cost</h6>
            <p>$545.00</p>
          </div>
          <div className="details-body">
            <h6>Average Sell Price</h6>
            <p>$545.00</p>
          </div>
          <div className="details-body">
            <h6>Average Margin</h6>
            <p>$545.00</p>
          </div>
          <hr />
          <div className="details-body period-total">
            <h6>Period Sales Total</h6>
            <h6>$125.00</h6>
          </div>
          <div className="details-body period-total-other">
            <h6>Period Margin Total</h6>
            <h6>$125.00</h6>
          </div>
        </div>
      </div>
      <StartEndDate datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} handleDateOnchange={handleDateOnchange} dateRange={dateRange} handleDateRange={handleDateRange} />
    </div>
  );
};
export default InventoryProductSummary;
