/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

const DeleteModal = (props) => {
  const {
    handleDelete, deleteStaff, deleteCurrentShift, deleteUpcomingShift,
  } = props;

  return (
    <>
      <Modal
        size="lg"
        show={deleteStaff}
        onHide={handleDelete}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="repeat-modal"
      >
        <div className="staff-close">
          <i className="fa fa-times" onClick={handleDelete} aria-hidden="true" />
        </div>
        <Modal.Header />
        <Modal.Title id="contained-modal-title-vcenter">
          Repeating Shift
        </Modal.Title>
        <Modal.Body>
          <div className="repeat-shift-container">
            <p>
              You have edited a shift that repeats weekly. Updating upcoming shifts will
              overwrite schedule up to
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="view-btn">
            <Button type="submit" className="btn btn-edit" onClick={deleteUpcomingShift}>Delete Upcoming Shift</Button>
            <Button type="submit" className="btn btn-delete" onClick={deleteCurrentShift}>Delete This Shift Only</Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
DeleteModal.propTypes = {
  deleteStaff: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  deleteCurrentShift: PropTypes.func.isRequired,
  deleteUpcomingShift: PropTypes.func.isRequired,
};
export default DeleteModal;
