import React, {
  useState, useContext, useEffect,
} from 'react';
import { Form, Dropdown } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { useHistory } from 'react-router-dom';
import showmore from '../../assets/home/showmore.png';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import QuoteDetail from './qoutedetail';
import QuoteListing from './QuoteListing';
import SearchModal from './searchmodal';
import { queryString } from '../../functions/index';
import SearchQuote from './searchQuote-listing';
import { userInstance } from '../../config/axios';
// import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import target from '../../assets/home/icon/target.png';
import edit from '../../assets/home/grey-edit.png';
import clientimg from '../../assets/home/client.png';
import greydelete from '../../assets/home/grey-delete.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import sortClientList from '../../functions/listSort';
import PreviewQuoteSecond from './previewQuotesSecond';
import toastMessage from '../../functions/toastMessage';

const Quotes = ({ setBookingTime }) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const history = useHistory();
  const {
    client: clientIdentity, clienttype, routeType, name, skipValue, quoteId, from, type,
  } = queryString();

  const [quoteType, setQuoteType] = useState('New Quote');
  const [client, setclient] = useState();
  const [clientTxt, setClientTxt] = useState('');
  const [searchedClients, setClients] = useState([]);
  const [searchedClients2, setClients2] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [addClient, setAddClient] = useState([]);
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(0);
  const [showQuote, setShowQuote] = useState(null);
  const [saveQuoteType, setsaveQuoteType] = useState('');
  const [view, setView] = useState('invoiceList');
  const { clientAllData, setClientAllData, quoteDataFromAppointment } = useContext(AuthDataContext);
  const [isHideSearch, setHideSearch] = useState(false);
  const [activeAdd, setActiveAdd] = useState(0);
  const [previewURL, setpreviewURL] = useState();
  const [preview, setPreview] = useState();
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (clienttype === 'edit' || clientIdentity) {
      if (clientAllData?.clientAllDetails) {
        setAddClient([clientAllData.clientAllDetails]);
      }
    }
  }, [clientAllData.clientAllDetails]);

  const handleClient = async () => {
    setclient(!client);
    if (client) {
      setClients(null);
      setClientTxt('');
    } else {
      const response = await userInstance().get('/customer/getCustomersAll2');
      if (response.status === 200) {
        const { customers } = response.data;
        const newList = sortClientList(customers);
        setClients(newList);
        setClients2(newList);
      }
    }
  };
  const searchClient = (searchTxt_) => {
    setClientTxt(searchTxt_);
  };
  useEffect(() => {
    let searchTimeOut = setTimeout(async () => {
      if (!clientTxt) {
        setClients(searchedClients2);
        return;
      }
      try {
        const response = await userInstance().get(`/customer/searchCustomer?value=${clientTxt}`);
        let list = response.data.customers;
        if (clientTxt) {
          if (isHideSearch) {
            list.forEach((client2) => {
              if (client2.siteAddressess) {
                client2.siteAddressess.forEach((addr) => {
                  addr.hide = false;
                });
              }
            });
          } else {
            list = list.filter((el) => (el.siteAddresses ? el.siteAddresses.filter((adr) => adr.hide).length === 0 : el));
          }
        }
        const newList = sortClientList(list);
        setClients(newList);
      } catch (error) {
        console.log(error);
      }
    }, 500);
    return (() => clearInterval(searchTimeOut));
  }, [clientTxt]);

  const handleAddClient = (info) => {
    const copy = { ...info };
    setSelectedClient(info._id);
    if (info.siteAddresses[activeAdd] || info.siteAddresses.length === 0) {
      let { activeSiteAdd } = info.siteAddresses[activeAdd]
        ? info.siteAddresses[activeAdd]
        : {};
      if (activeSiteAdd || info.siteAddresses.length === 0) {
        setAddClient([info]);
        clientAllData.clientAllDetails = info;
        handleClient();
        setClientTxt('');
        localStorage.setItem('apptData', JSON.stringify(clientAllData));
        // console.log('client-', client);
      } else {
        toastMessage('error', 'Please select site address.');
      }
    } else {
      toastMessage('error', 'Please select site address.');
    }
  };

  const handleQuotesStatus = (e) => {
    setQuoteType(e.target.value);
    setSkip(0);
    setPageCounts(0);
  };
  const deleteClient = () => {
    setSelectedClient('');
    setAddClient([]);
    setClientTxt('');
  };
  const handleBackPage = () => {
    if (setBookingTime('Past Bookings')) {
      setAddClient([clientAllData.clientAllDetails]);
      let backPathUrl = `/booking?client=${clientIdentity}&routeType=${routeType}&name=${name}&skipValue=${skipValue}`;
      history.push(backPathUrl);
    }
  };
  const handleEditDetails = (data, data2) => {
    const url = `/client?clientid=${data}&name=${data2}&typee=edit&back=quotes`;
    history.push(url);
  };

  useEffect(() => {
    if (clienttype === 'edit') {
      const clinfo = clientAllData.clientAllDetails;
      setSelectedClient(clinfo._id);
      setAddClient([clinfo]);
    }
    if (clienttype === 'close') {
      handleClient();
    }
  }, [clienttype]);
  const checkAddress = (index, id) => {
    setActiveAdd(index);
    let oldState = [...searchedClients];
    oldState.forEach((el) => {
      if (el._id === id) {
        el.siteAddresses?.forEach((el2, i) => {
          if (index === i) {
            el2.activeSiteAdd = !el2.activeSiteAdd;
          } else {
            el2.activeSiteAdd = false;
          }
        });
      } else {
        el.siteAddresses.forEach((el2, i) => {
          el2.activeSiteAdd = false;
        });
      }
    });
    setClients(oldState);
  };

  const updateView = (key, val) => {
    if (val === 'addnew') {
      setsaveQuoteType('addnew');
    }
    setQuoteType(key);
    history.push('/quotes');
    // setActiveLink(key);
  };
  const handlePreview = (data) => {
    // console.log('handle ==============>', data, quoteType);
    const { previewUrl } = data || {};
    setpreviewURL(previewUrl);
    setShowQuote(data);
    setPreview(!preview);
  };
  const handlePreview2 = (data) => {
    setPreview(!preview);
  };

  const handleEditQuote = () => {
    const { waterTestId, chemicalsArray, _id } = showQuote || {};
    if (waterTestId) {
      history.push(`/quotes?waterTest=done&watTesId=${waterTestId}&quoteId=${_id}`);
      localStorage.setItem('chemicalalist', JSON.stringify(chemicalsArray));
    } else {
      history.push('/quotes');
    }
    setPreview(!preview);
    setQuoteType('New Quote');
    setsaveQuoteType('editquote');
  };

  const handleAddQuotes = () => {
    const {
      _id, dateData, staff, quoteDate, waterTestId, chemicalsArray,
    } = showQuote || {};

    const copyClientData = showQuote.client;
    const copyAddClient = { ...clientAllData };
    copyAddClient.clientAllDetails = copyClientData;
    setClientAllData(copyAddClient);
    localStorage.setItem('apptData', JSON.stringify(copyAddClient));
    let url = `/addappointment?startDateTime=${quoteDate}&employee_id=${staff}&quoteId=${_id}`;
    if (waterTestId) {
      localStorage.setItem('chemicalalist', JSON.stringify(chemicalsArray));
      url = `/addappointment?startDateTime=${quoteDate}&employee_id=${staff}&quoteId=${_id}&waterTest=done&waterId=${waterTestId}`;
    } else {
      localStorage.removeItem('chemicalalist');
    }
    history.push(url);
    // window.location.href = `/addappointment?startDateTime=${quoteDate}&employee_id=${staff}&quoteId=${_id}`;
  };
  const handleDuplicate = () => {
    setPreview(false);
    setQuoteType('New Quote');
    setsaveQuoteType('duplicate');
  };

  useEffect(() => {
    if (quoteId && from === 'calculator') {
      getQuoteById();
    }
  }, [quoteId, from]);

  const getQuoteById = async () => {
    const response = await userInstance().get(
      `/quotes/getQuotesById?quoteId=${quoteId}`,
    );
    const { quotesList, code } = response.data;
    const { previewUrl } = quotesList || {};
    const copydateData = JSON.parse(JSON.stringify(quotesList));
    setpreviewURL(previewUrl);
    const resulttChemical = JSON.parse(localStorage.getItem('chemicalalist'));
    const chemicalalist = resulttChemical || {};
    chemicalalist[0].testResult.forEach((el, i) => {
      const chemicalaTypeList = el.service.filter(
        (el2) => el2.type === 'product' && el2.status === 'quantity',
      );
      copydateData.chemicals = chemicalaTypeList || [];
      copydateData.chemicalsArray = chemicalalist || [];
    });
    setShowQuote(copydateData);
    // setPreview(!preview);
    setQuoteType('New Quote');
    setsaveQuoteType('editquote');
  };
  const handleRemoveText = () => {

  };
  return (
    <AdminWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {clientIdentity && (
            <div className="back-option">
              <span />
              <i className="fa fa-times" aria-hidden="true" onClick={handleBackPage} />

            </div>
            )}

            <div className="appointment-container finalize-cost-page new-invoice quotes-page">
              <div className="main-titles-mob">
                <h3>Quotes</h3>
                <div className="content-dropdown">
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('New Quote', 'addnew')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span> New Quote</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('Saved Quotes')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Saved Quotes</span>
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('Pending Quotes')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Pending Quotes</span>
                          </div>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('pending')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Search Pending Quotes</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('saved')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Search Saved Quotes</span>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => updateView('all')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Search All Quotes</span>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="main-titles-desktop">
                <div className="dropdown-option-section">
                  <div className={`option-list ${quoteType === 'New Quote' ? 'active-menu' : ''}`} onClick={() => updateView('New Quote')}>
                    <img src={target} alt="edit" />
                    New Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'Saved Quotes' ? 'active-menu' : ''}`} onClick={() => updateView('Saved Quotes')}>
                    <img src={target} alt="edit" />
                    Saved Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'Pending Quotes' ? 'active-menu' : ''}`} onClick={() => updateView('Pending Quotes')}>
                    <img src={target} alt="edit" />
                    Pending Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'pending' ? 'active-menu' : ''}`} onClick={() => updateView('pending')}>
                    <img src={target} alt="edit" />
                    Search Pending Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'saved' ? 'active-menu' : ''}`} onClick={() => updateView('saved')}>
                    <img src={target} alt="edit" />
                    Search Saved Quotes
                  </div>
                  <div className={`option-list ${quoteType === 'all' ? 'active-menu' : ''}`} onClick={() => updateView('all')}>
                    <img src={target} alt="edit" />
                    Search All Quotes
                  </div>
                </div>
              </div>
              <div className="target-heading">
                <h6>
                  {quoteType === 'pending' ? 'Search Pending Quotes' : quoteType === 'saved' ? 'Search Saved Quotes' : quoteType === 'all' ? 'Search All Quotes' : saveQuoteType === 'editquote' ? 'Edit Quote' : quoteType}
                </h6>
              </div>
              <div className="add-appointment">
                <div className="row newinvoice-row">

                  <div className="col-lg-12 col-md-8">
                    <div className={`appointment-view appointment-service-modal ${quoteType === 'New Quote' ? 'newQuote-page' : ''} ${quoteType === 'Pending Quotes' ? 'pendingQuote-page' : ''}${quoteType === 'Saved Quotes' ? 'savedQuote-page' : ''} ${quoteType === 'pending' ? 'searchPendingQuote-page' : ''}${quoteType === 'saved' ? 'savedSavedQuote-page' : ''} ${quoteType === 'all' ? 'searchAllQuote-page' : ''}`}>
                      <div className="search-container">
                        {addClient.length === 0 ? (
                          <div className="search-title">
                            <h3> Client </h3>
                            <span className="plus-icon" role="presentation">
                              <i className="fas fa-plus" onClick={handleClient} />
                            </span>
                          </div>
                        ) : (
                          <div className="quotes-client-edit">
                            {addClient && addClient.map((cl) => (
                              <>
                                <div className="search-title" key={cl?._id}>
                                  <div className="client-profile">

                                    <div className="">
                                      <h3>{`${cl?.firstname} ${cl?.lastname || ''}`}</h3>
                                      <p>
                                        {`${cl?.streetAddress}${cl?.suburb ? cl?.suburb : '' || cl?.state ? '' : ''} ${cl?.suburb || ''
                                        } ${cl?.state || ''}`}
                                      </p>
                                      <p>{cl?.mobileNo}</p>

                                    </div>
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                      >
                                        <img src={showmore} alt="show" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>

                                        <Dropdown.Item>
                                          <div
                                            className="action client"
                                            onClick={() => {
                                              handleEditDetails(
                                                cl?._id,
                                                cl?.firstname,
                                              );
                                            }}
                                          >
                                            <img src={edit} alt="edit" />
                                            Edit Client Details
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action client"
                                            onClick={handleClient}
                                          >
                                            <img src={clientimg} alt="edit" />
                                            Change Client
                                          </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          <div
                                            className="action"
                                            onClick={() => deleteClient([])}
                                          >
                                            <img src={greydelete} alt="edit" />
                                            Delete
                                          </div>
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </div>
                                <div className="client-profile">
                                  <div className="client-name-contact site-addresses">
                                    {activeAdd !== '' && activeAdd >= 0 && cl?.siteAddresses?.length > 0 && (
                                    <div className="client-left-details">
                                      <h5 className="client-company-name">
                                        Site Address
                                      </h5>
                                      <p>
                                        {cl?.siteAddresses[activeAdd]?.householderName
                                          ? cl.siteAddresses[activeAdd]?.householderName
                                          : ''}
                                      </p>
                                      <p>
                                        {cl.siteAddresses[activeAdd]?.address
                                          ? cl.siteAddresses[activeAdd]?.address
                                          : ''}
                                        {' '}
                                      </p>
                                      <p>
                                        <a
                                          className="mobile"
                                          href={`tel:${
                                            cl.siteAddresses[activeAdd]?.mobileNo || ''
                                          }`}
                                        >
                                          {cl?.siteAddresses[
                                            activeAdd
                                          ]?.mobileNo.includes('+61')
                                            ? cl?.siteAddresses[activeAdd]
                                              ?.mobileNo
                                            : `+61 ${
                                              cl?.siteAddresses[
                                                activeAdd
                                              ]?.mobileNo?.replace(
                                                phoneRegex,
                                                '$1 $2 $3',
                                              ) || ''
                                            }`}
                                        </a>
                                      </p>
                                    </div>
                                    )}
                                  </div>
                                </div>

                              </>
                            ))}
                          </div>
                        )}
                      </div>
                      {quoteType === 'New Quote' ? (
                        <QuoteDetail
                          addClient={addClient}
                          setQuoteType={setQuoteType}
                          showQuote={showQuote}
                          setShowQuote={setShowQuote}
                          saveQuoteType={saveQuoteType}
                          setAddClient={setAddClient}
                          setSelectedClient={setSelectedClient}
                          activeAdd={activeAdd}
                          setActiveAdd={setActiveAdd}
                          setsaveQuoteType={setsaveQuoteType}
                          handlePreviewEdit={handlePreview}
                          quoteDataFromAppointment={quoteDataFromAppointment}
                          from={from}
                          savetype={type}

                        />
                      ) : (
                        null
                      )}

                      {(quoteType === 'Pending Quotes') || (quoteType === 'Saved Quotes') ? (
                        <QuoteListing
                          quoteType={quoteType}
                          selectedClient={selectedClient}
                          skip={skip}
                          setSkip={setSkip}
                          pageCounts={pageCounts}
                          setPageCounts={setPageCounts}
                          setQuoteType={setQuoteType}
                          showQuote={showQuote}
                          setShowQuote={setShowQuote}
                          setsaveQuoteType={setsaveQuoteType}
                          addClient={addClient}
                          handlePreview={handlePreview}
                          previewURL={previewURL}
                          setpreviewURL={setpreviewURL}
                        />
                      ) : (
                        null
                      )}
                      {(quoteType === 'pending' || quoteType === 'saved' || quoteType === 'all') ? (
                        <SearchQuote selectedClient={selectedClient} quoteType={quoteType} />
                      ) : (
                        null
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchModal
        showSearch={client}
        handleShowSearch={handleClient}
        searchClient={searchClient}
        searchedClients={searchedClients}
        clientTxt={clientTxt}
        handleAddClient={handleAddClient}
        setHideSearch={setHideSearch}
        isHideSearch={isHideSearch}
        checkAddress={checkAddress}
        activeAdd={activeAdd}
        setClientTxt={setClientTxt}
      />
      {showQuote ? (
        <PreviewQuoteSecond
          quoteType={quoteType}
          showquote={preview}
          handleQuote={handlePreview2}
          previewURL={previewURL}
          setSuccess={setSuccess}
          addClient={[showQuote.client]}
          addedServices={showQuote.services}
          quoteDate={showQuote.quoteDate}
          summary={showQuote.totalData}
          quotesTotal={showQuote.quotesTotal}
          gst={showQuote.gst}
          quoteNo={showQuote.quoteNo}
          handleEditQuote={handleEditQuote}
          handleAddQuotes={handleAddQuotes}
          handleDuplicate={handleDuplicate}
        />
      ) : null}
    </AdminWrapper>

  );
};
Quotes.propTypes = {
  setBookingTime: PropTypes.func.isRequired,
};
export default Quotes;
