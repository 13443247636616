import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const GreenPool = (props) => {
  const {
    handleGreenChange, greenTreatment, handleGreenPoolTreatments, greenSelect,
  } = props;
  return (
    <>
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>Green Pool Treatment</Form.Label>
          </Col>
          <Col>
            <div className="select-box">
              <Form.Control
                as="select"
                custom
                value={greenTreatment}
                onChange={handleGreenPoolTreatments}
              >
                <option value="Liquid Chlorine">
                  Liquid Chlorine
                </option>
                <option value="Liquid Chlorine + Clarifier">
                  Liquid Chlorine + Clarifier
                </option>
                <option value="Liquid Chlorine + Flock">
                  {' '}
                  Liquid Chlorine + Flock
                </option>
                <option value="Granular Chlorine">
                  Granular Chlorine
                </option>
                <option value="Granular Chlorine + Clarifier">
                  Granular Chlorine + Clarifier
                </option>
                <option value="Granular Chlorine + Flock">
                  Granular Chlorine + Flock
                </option>
              </Form.Control>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>

      <Form.Group
        controlId="exampleForm.SelectCustom"
        className="green-pool-color"
      >
        <Form.Row>
          <Col column="xs" md={7} xs={5}>
            <Form.Label>
              Select Green Pool Colour
            </Form.Label>
          </Col>
          <Col>
            <div className="pool-color choose-color">
              <div className="select-color" onClick={() => handleGreenChange('green1')}>
                <h6 className="green-one">Green 1</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="green1"
                      checked={
                          greenSelect === 'green1' ? true : ''
                        }
                      // onChange={(e) => e && handleGreenChange(e.target.name)}
                    />
                  </div>
                </div>
              </div>
              <div className="select-color" onClick={() => handleGreenChange('green2')}>
                <h6 className="green-two">Green 2</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="green2"
                      checked={
                          greenSelect === 'green2' ? true : ''
                        }
                      // onChange={(e) => e && handleGreenChange(e.target.name)}
                    />
                  </div>
                </div>
              </div>
              <div className="select-color" onClick={() => handleGreenChange('green3')}>
                <h6 className="green-three">Green 3</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="green3"
                      checked={
                          greenSelect === 'green3' ? true : ''
                        }
                      // onChange={(e) => e && handleGreenChange(e.target.name)}
                    />
                  </div>
                </div>
              </div>
              <div className="select-color" onClick={() => handleGreenChange('green4')}>
                <h6 className="green-four">Green 4</h6>
                <div className="open-date client-notes">
                  <div className="basic">
                    <Form.Check
                      type="checkbox"
                      name="green4"
                      checked={
                          greenSelect === 'green4' ? true : ''
                        }
                      // onChange={(e) => e && handleGreenChange(e.target.name)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Form.Row>
      </Form.Group>

    </>
  );
};
GreenPool.propTypes = {
  handleGreenChange: PropTypes.func.isRequired,
  greenTreatment: PropTypes.string.isRequired,
  handleGreenPoolTreatments: PropTypes.func.isRequired,
  greenSelect: PropTypes.string.isRequired,

};

export default GreenPool;
