import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Spinner,
} from 'react-bootstrap';
// import logo from '../../assets/home/temp-logo.png';

const UploadCSV = ({
  show,
  handleUpload,
  handleUplaodChange,
  handleRemoveData,
  csvFilesName,
  removeFilesSelect,
  uploadCsvApi,
  isLoading,
  showRemoveButton,
  checkCsvHasErrorAndUpload,
}) => (
  <Modal
    size="md"
    show={show}
    onHide={handleUpload}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="search-modal new-search-popup1 image-upload-popup new-image-popup"
  >
    {/* <div className="staff-close">
        <i className="fa fa-times" onClick={handleUpload} aria-hidden="true" />
      </div> */}
    <Modal.Body>
      <div className="appointment-container preview-invoice preview-popup preview-csv-popup">
        <div className="change-image-section">
          <div className="field-location-section">
            <div className="preview-image">
              <h6>Preview CSV</h6>
              {csvFilesName.length > 0 ? (
                <>
                  {csvFilesName.map((el, i) => (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <span
                          style={{
                            display: 'inline-block',
                            height: '50px',
                            width: '100%',
                            border: '1px solid #dcd9d9 ',
                            padding: '10px',
                            cursor: 'pointer',
                            overflow: 'hidden',
                            position: 'relative',
                          }}
                        >
                          {el.name}
                        </span>
                        {/* <Form.Check
                          type="checkbox"
                          value={el.name}
                          onChange={(e) => removeFilesSelect(e, i)}
                          checked={el.checked || false}
                        /> */}
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <span
                  style={{
                    display: 'inline-block',
                    height: '80px',
                    width: '100%',
                    border: '1px solid #dcd9d9 ',
                    padding: '10px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                  }}
                >
                  No files
                </span>
              )}
            </div>
            <div className="template-btn temp-btn">
              {/* {showRemoveButton ? ( */}
              <Button className="delete-btn" style={{ visibility: showRemoveButton ? 'visible' : 'hidden' }} onClick={handleRemoveData}>
                Remove
              </Button>
              {/* ) : null} */}
              {(showRemoveButton && csvFilesName?.length > 0) ? (
                <Button className="save-btn" onClick={() => checkCsvHasErrorAndUpload()}>
                  {isLoading ? (
                    <Spinner animation="border" />
                  ) : (
                    'Upload'
                  )}
                </Button>
              ) : (
                <Button type="submit" className="save-template upload-logo">
                  <input
                    type="file"
                    id="upload"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={handleUplaodChange}
                  />

                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label htmlFor="upload">Select File</Form.Label>
                  </Form.Group>

                </Button>
              )}
            </div>
            {/* {csvFilesName.length > 0 && (
              <>
                <div className="template-btn">
                  <Button
                    className="delete-btn"
                    style={{ visibility: 'hidden' }}
                  >
                    Delete Field
                  </Button>
                  <Button className="save-btn" onClick={() => uploadCsvApi()}>
                    {isLoading ? (
                      <Spinner animation="border" />
                    ) : (
                      'Save/ Update'
                    )}
                  </Button>
                </div>
              </>
            )} */}
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
UploadCSV.propTypes = {
  show: PropTypes.bool.isRequired,
  handleUpload: PropTypes.func.isRequired,
  handleUplaodChange: PropTypes.func.isRequired,
  handleRemoveData: PropTypes.func.isRequired,
  csvFilesName: PropTypes.arrayOf.isRequired,
  uploadCsvApi: PropTypes.func.isRequired,
  removeFilesSelect: PropTypes.func.isRequired,
  checkCsvHasErrorAndUpload: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showRemoveButton: PropTypes.bool.isRequired,
};
export default UploadCSV;
