import openSocket from 'socket.io-client';
import { server } from '../config/keys';

// console.log('url', server);

const socket = openSocket(server, {
  transports: ['websocket'],
  rejectUnauthorized: false,
  query: {
    usertoken: localStorage.getItem('s#@4f'),
    stafftoken: localStorage.getItem('f#@5e'),
  },
});

socket.on('connect', () => {
  const userid = localStorage.getItem('s#@4f');
  const isLogin = localStorage.getItem('f#@5e');
  const isLoginId = localStorage.getItem('loginStaff');
  if (isLogin && userid) {
    socket.emit('join', isLoginId);
  }
});
socket.on('reconnect', () => {
  const userid = localStorage.getItem('s#@4f');
  const isLogin = localStorage.getItem('f#@5e');
  const isLoginId = localStorage.getItem('loginStaff');
  if (isLogin && userid) {
    socket.emit('join', isLoginId);
  }
});
const GetNotification = (cb) => {
  socket.on('GetNotification', (data) => {
    cb(data);
  });
};

export { socket, GetNotification };
