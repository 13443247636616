import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import Layout from '../layout/index';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './industrytype.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import { userInstance } from '../../config/axios';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';

const IndustryType = () => {
  // const industryData = [
  //   'Baby Sitting',
  //   'Beauty Salon',
  //   'Bin Cleaning',
  //   'Book Keeping',
  //   'Carpentry',
  //   'Carpet Cleaning',
  //   'Commercial Cleaning',
  //   'Dog Walking',
  //   'Dog Washing',
  //   'Electrician',
  //   'Fencing Contractor',
  //   'Flooring',
  //   'Gardening',
  //   'Hair Salon',
  //   'Handyman',
  //   'House Cleaning',
  //   'Lawn Mowing',
  //   'Locksmith',
  //   'Massaging',
  //   'Mechanic',
  //   'Painting',
  //   'Personal Trainer',
  //   'Photography',
  //   'Plumbing',
  //   'Pool Servicing',
  //   'Roofing',
  //   'Window Cleaning',
  //   'Pest Control',
  // ];

  const { userData, setUserData } = useContext(AuthDataContext);

  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const LastSelectedIndustry = localStorage.getItem('IndustryType');
  const [loading, setLoading] = useState(false);

  const handleChange = (data) => {
    console.log('hello', data);
    setSelectedIndustry(data);
    const findNam = industryData?.find((el) => el._id === data);
    setSelectedName(findNam?.name);
  };

  const [industryData, setIndustryData] = useState([]);

  const handleClick = async () => {
    localStorage.setItem('IndustryType', selectedIndustry);
    let industryMsg = `Industry Type has been changed to ${selectedName} `;
    toastMessage('success', industryMsg);
    const findIndustry = industryData.find((el) => el._id === selectedIndustry);
    const res = await userInstance().put('/industry/updateUsersIndustry', { industryId: selectedIndustry });
    if (res?.status === 200) {
      setUserData({
        ...userData,
        industryId: findIndustry,
      });
    }
  };

  useEffect(() => {
    if (userData?.industryId?._id) {
      setSelectedIndustry(userData?.industryId?._id);
    }
  }, [userData]);
  useEffect(async () => {
    if (LastSelectedIndustry) {
      setSelectedIndustry(LastSelectedIndustry);
    }
    try {
      setLoading(true);
      const response = await userInstance().get('/industry/getAllIndustries');
      setLoading(false);
      setIndustryData(response?.data?.findAllIndustries);
    } catch (error) {
      setLoading(false);
    }
  }, []);
  return (

    <AdminWrapper>
      {loading ? <Loader /> : (
        <div className="contact-page pool-set-up industry-type">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>Industry Type</h3>
                </div>
              </div>
            </div>

            <div className="contact-container client-main-contact">
              {industryData?.map((el) => (
                <div className="pending-water-test" onClick={() => handleChange(el._id)}>
                  <h3>{el.name}</h3>
                  <div className="open-date client-notes">
                    <div className="basic">
                      <Form.Check type="checkbox" checked={el._id === selectedIndustry} />
                    </div>
                  </div>
                </div>
              ))}

            </div>
            <div className="footer-btn view-btn">
              <Button className="appointment-btn btn-save" onClick={handleClick}>
                Save/Update
              </Button>
            </div>
          </div>
        </div>
      )}
    </AdminWrapper>

  );
};
export default IndustryType;
