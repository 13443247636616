import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal,
} from 'react-bootstrap';
// import logo from '../../assets/home/temp-logo.png';
const logo = '';

const ImageDetailsEdit = ({
  showPopup, handleImagePopup, setTemplateDat, customFeilds, setCustomFeilds, customObject,
  setCustomObject,
  selectScaling,
  setSelectScaling,
  setTemplateLogo,
  setUploadImg,
}) => {
  const setTemplateData = (data) => {
    const { name, value } = data.target;
    let { style } = customObject;
    let oldStyleObject = { ...style };
    oldStyleObject[name] = parseInt(value, 10);
    setCustomObject({ ...customObject, style: oldStyleObject });
  };
  const setSelectScalingValue = (info) => {
    setSelectScaling(info);
    if (info === 'Original') {
      let { style } = customObject;
      let oldStyleObject = { ...style };
      oldStyleObject.imageHeight = 100;
      oldStyleObject.imageWidth = 100;
      setCustomObject({ ...customObject, style: oldStyleObject });
    }
  };
  const removePicture = () => {
    setUploadImg('');
    setCustomObject({ ...customObject, text: '' });
  };
  const deleteFeild = () => {
    const oldObject = [...customFeilds];
    if (oldObject.length > 0) {
      oldObject.splice(0, 1);
      setTemplateDat(oldObject);
      handleImagePopup();
    }
  };

  const setNewText = () => {
    customFeilds.push(customObject);
    setCustomFeilds(customFeilds);
    handleImagePopup();
  };

  const setOpacity = (info) => {
    let { style } = customObject;
    let oldStyleObject = { ...style };
    oldStyleObject.zIndex = parseInt(info, 10);
    // console.log({ oldStyleObject });
    setCustomObject({ ...customObject, style: oldStyleObject });
  };
  return (
    <Modal
      size="md"
      show={showPopup}
      onHide={handleImagePopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup new-image-popup"
    >
      <Modal.Body>
        <div className="appointment-container preview-invoice preview-popup">
          <div className="change-image-section">
            <div className="field-location-section">
              <div className="preview-image">
                <h6>Preview Picture</h6>
                <span
                  style={{
                    display: 'inline-block',
                    height: '80px',
                    width: '100%',
                    border: '1px solid #dcd9d9 ',
                    padding: '5px 10px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    fontSize: '12px',
                    position: 'relative',
                  }}
                >
                  {customObject.text
                    ? (
                      <img
                        id="an-img"
                        style={{
                          height: 'auto',
                          maxHeight: `${customObject.style.imageHeight}%`,
                          width: `${customObject.style.imageWidth}%`,
                          objectFit: 'cover',
                          marginRight: `${customObject.style.imageMarginRight}%`,
                          marginLeft: `${customObject.style.imageMarginLeft}px`,
                          marginTop: `${customObject.style.imageMarginTop}px`,
                          marginBottom: `${customObject.style.imageMarginBottom}%`,
                          transform: `rotate(${customObject.style.imageRotate}deg)`,
                        }}
                        src={customObject.text ? customObject.text : logo}
                        alt="logo"
                      />
                    )
                    : 'Add signature image PNG (.png) or JPEG (.jpeg)' }
                </span>
              </div>
              <div className="template-btn temp-btn">
                <Button className="delete-btn" onClick={() => removePicture()}>Remove</Button>
                <Button type="submit" className="save-template upload-logo">
                  <input
                    type="file"
                    id="uploadimage2"
                    onChange={(e) => setTemplateLogo(e)}
                    accept=".jpg,.png,.jpeg"
                  />
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label htmlFor="uploadimage2">Upload</Form.Label>
                  </Form.Group>
                </Button>
              </div>
              <div className="field-location">
                <h6>Image Location</h6>
                <div className="image-size">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" name="imageMarginTop" value={customObject.style.imageMarginTop} onChange={(e) => setTemplateData(e)} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from left</Form.Label>
                    <Form.Control type="number" name="imageMarginLeft" value={customObject.style.imageMarginLeft} onChange={(e) => setTemplateData(e)} />
                  </Form.Group>
                </div>
              </div>

              <div className="field-location">
                <h6>Image Opacity</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="imagetype"
                        value={customObject.style.zIndex}
                        onChange={(e) => setOpacity(e.target.value)}
                      >
                        <option value="9">Set Image behind type</option>
                        <option value="99">Set Image in front of type</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
                <h6>Image Size</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="customerType"
                        value={selectScaling}
                        onChange={(e) => setSelectScalingValue(e.target.value)}
                      >
                        <option value="Original">Fixed Percentage of Original</option>
                        <option value="Custom">Custom Height & Width</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>

                <div className="image-size">
                  {selectScaling === 'Original' ? (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling</Form.Label>
                        <Form.Control type="number" name="imageHeight" value={customObject.style.imageHeight} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={customObject.style.imageRotate} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Height</Form.Label>
                        <Form.Control type="number" name="imageHeight" value={customObject.style.imageHeight} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Width</Form.Label>
                        <Form.Control type="number" name="imageWidth" value={customObject.style.imageWidth} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={customObject.style.imageRotate} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                    </>
                  )}

                </div>
              </div>
              <div className="template-btn">
                <Button onClick={handleImagePopup} className="delete-btn">Cancel</Button>
                <Button className="save-btn" onClick={setNewText}>Save/ Update</Button>
              </div>
            </div>
          </div>

        </div>
      </Modal.Body>
    </Modal>
  );
};
ImageDetailsEdit.propTypes = {
  showPopup: PropTypes.func.isRequired,
  handleImagePopup: PropTypes.bool.isRequired,
  setTemplateDat: PropTypes.func.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
  setCustomFeilds: PropTypes.func.isRequired,
  customObject: PropTypes.func.isRequired,
  setCustomObject: PropTypes.func.isRequired,
  selectScaling: PropTypes.bool.isRequired,
  setSelectScaling: PropTypes.func.isRequired,
  setTemplateLogo: PropTypes.func.isRequired,
  setUploadImg: PropTypes.func.isRequired,
};
export default ImageDetailsEdit;
