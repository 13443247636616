import React, { useState, useEffect } from 'react';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { errorMsgTime, successMsgTime } from '../../../functions/intervalTime';
import { userInstance, notTimeOutUserInstance } from '../../../config/axios';
import toastMessage from '../../../functions/toastMessage';

const Default = () => {
  const initial_state = {
    services_products: 'All Services and Products',
    xero_account: '200',
  };
  const [check, setCheck] = useState();
  const [data, setData] = useState(initial_state);
  const [chart, setChart] = useState([{ code: '200', name: 'Sales' }]);

  const handleDropDown = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    setData({ ...data, [name]: value });
    if (value === 'All Services and Products') {
      getXeroSettings('all');
    } else if (value === 'All Services') {
      getXeroSettings('services');
    } else {
      getXeroSettings('products');
    }
  };

  const getXeroSettings = async (selected_) => {
    const response = await userInstance().post('/xero/getXeroSetting', { value: selected_ });
    // console.log('this is the response', response);
    let { data: { data: apiresponse } } = response || {};
    if (apiresponse) {
      if (selected_ === 'all') {
        let { all } = apiresponse;
        setCheck(all);
      } else if (selected_ === 'services') {
        let { services } = apiresponse;
        setCheck(services);
      } else {
        let { products } = apiresponse;
        setCheck(products);
      }
    }
  };
  const getChartofAccount = async () => {
    const response = await userInstance().post('/xero/getChartAccounts');
    // console.log('this is the response', response);
    const { msg } = response.data;
    if (msg === 'Success') {
      const { data: { accounts } } = response.data || {};
      if (accounts) {
        let accountsArray = [];
        accounts.forEach(async (ele) => {
          if (ele.type === 'REVENUE') {
            let obj = { code: ele.code, name: ele.name };
            accountsArray.push(obj);
          }
        });
        setChart(accountsArray);
      }
    }
  };

  const saveDefault = async () => {
    let payload = { data };
    const response = await userInstance().post('/xero/DefaultCharts', payload);
    const { data: { code } } = response || {};
    if (code === 200) {
      toastMessage('success', 'Successfully Updated Chart of Accounts');
    }
    if (data.services_products === 'All Services and Products') {
      getXeroSettings('all');
    } else if (data.services_products === 'All Services') {
      getXeroSettings('services');
    } else {
      getXeroSettings('products');
    }
  };

  useEffect(() => {
    getXeroSettings('all');
    getChartofAccount();
  }, []);

  return (
    <>

      <div className="row">
        <div className="col-md-12">
          <Form.Group controlId="formBasicLocation">
            <Form.Label>Selected Services and Products</Form.Label>
            <div className="select-box">
              <Form.Control
                as="select"
                value={data.services_products}
                name="services_products"
                onChange={handleDropDown}
              >
                <option value="All Services and Products">All Services and Products</option>
                <option value="All Services">All Services</option>
                <option value="All Products">All Products</option>
              </Form.Control>
            </div>
          </Form.Group>
          <Form.Group controlId="formBasicLocation">
            <Form.Label>Xero Account</Form.Label>
            <div className="select-box">
              <Form.Control
                as="select"
                value={data.xero_account}
                name="xero_account"
                onChange={handleDropDown}
                className="xero-select"
              >

                {check && chart && chart.length > 1 && (
                  <option disabled>
                    Current
                    {' '}
                    {chart.find((ele) => ele.code === check).code}
                    -
                    {chart.find((ele) => ele.code === check).name}
                  </option>
                )}

                {chart.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code}
                    -
                    {option.name}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Form.Group>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn right-btns">
                <Button
                  type="button"
                  className="btn btn-save"
                  onClick={saveDefault}
                >
                  {' '}
                  Save/Update
                  {' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Default;
