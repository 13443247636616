import React, { useEffect } from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const SmsTemplatePreview = ({
  show, handleClose, smstemplate, templateName,
}) => (

  <Modal
    size="lg"
    show={show}
    onHide={handleClose}
    aria-labelledby="contained-modal-title-vcenter"
    top
    className="service-modal"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleClose}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        {templateName}
        {' '}
        Template Preview
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="email-sms-sending search-invoice pool-set-up sms-invocie-template">
        <div className="sending-days-section">
          <div className="days-description">
            <div className="message-subject">
              <h6>
                {' '}
                {smstemplate?.sendSubject || 'Message Subject'}
                {' '}
              </h6>
            </div>
            <p>
              Hi
              {' '}
              {smstemplate?.address === 'fname-lname' ? '[fName lName]' : '[fName]'}
              ,
            </p>

            <p>
              {smstemplate?.textOpening || 'Your pool visit was completed for today.Please click the invoice link to view detail.'}
              {' '}
            </p>

            <div className="todays-invoice">
              <h6>TODAY&apos;S INVOICE</h6>
              <p>
                <span>$</span>
                {' '}
                195.00 |
                {' '}
                <span className="inovocie-link">INV #159</span>
                {' '}
                Thu, 17 Feb 2022
                {' '}
              </p>
            </div>
            <div className="todays-invoice past-due">
              <h6>PAST DUE</h6>
              <p>
                <span>$</span>
                {' '}
                195.00 |
                {' '}
                <span className="inovocie-link">INV #159</span>
                {' '}
                Thu, 17 Feb 2022
                {' '}
              </p>
              <p>
                <span>$</span>
                {' '}
                195.00 |
                {' '}
                <span className="inovocie-link">INV #159</span>
                {' '}
                Thu, 17 Feb 2022
                {' '}
              </p>
              <p>
                <span>$</span>
                {' '}
                195.00 |
                {' '}
                <span className="inovocie-link">INV #159</span>
                {' '}
                Thu, 17 Feb 2022
                {' '}
              </p>
              <p>
                <span>$</span>
                {' '}
                195.00 |
                {' '}
                <span className="inovocie-link">INV #159</span>
                {' '}
                Thu, 17 Feb 2022
                {' '}
              </p>
              <h6>$2,320 TOTAL DUE</h6>
            </div>
            <div className="bottom-inovoice-text">
              <p>{smstemplate?.textClosing || 'Thank you very much for your work'}</p>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
SmsTemplatePreview.propTypes = {
  show: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  smstemplate: PropTypes.objectOf.isRequired,
  templateName: PropTypes.string.isRequired,
};

export default SmsTemplatePreview;
