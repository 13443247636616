import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import './invoice.css';
import arrow from '../../assets/home/arrowdown.png';
import { currencyComma } from '../../functions';

const InvoicesList = ({
  handleInvoice, allInvoices, handlePageClick, pageCount, selectedPage,
}) => (
  <div className="pool-setup">
    <div className="target-heading">
      <h6>Saved Invoices</h6>
    </div>
    <div className="invoices-table pool-chemistry-box">
      {allInvoices && allInvoices.length > 0 ? (
        <>
          <div className="table-section desktop-comp">
            <div className="invoices-table-left">
              <div className="invoices-head">
                <h6>Date</h6>
                <h6>Invoice No.</h6>
                <h6>Invoice Total</h6>
                <h6>Amount Applied</h6>
                <h6>Balance Due</h6>
                <h6>Status</h6>
              </div>
            </div>
          </div>
          { allInvoices && allInvoices?.map((el) => (
            <div className="table-section desktop-comp" onClick={() => handleInvoice(el._id)}>
              <div className="invoices-table-left">

                <div className="invoice-body">
                  <p>
                    {moment(el?.createdAt).format('D MMM')}
                    {' '}
                    {moment(el?.createdAt).format('YYYY')}
                  </p>
                  <p>{el?.invoiceNo}</p>
                  <p>
                    $
                    {el?.invoiceTotal ? currencyComma(el?.invoiceTotal) : 0}
                  </p>
                  <p>
                    $
                    {el?.amountApplied ? currencyComma(el?.amountApplied) : 0}
                  </p>
                  <p>
                    $
                    {el?.balanceDue ? currencyComma(el?.balanceDue) : 0}
                  </p>
                  <p>
                    <h6 className={(el?.status === 'Approved' || el?.status === 'Sent') ? 'approved-green' : el?.status === 'Pending' ? 'deleted-yellow' : 'deleted-red'}>{el.status === 'deleted' ? 'Deleted' : el.status }</h6>
                    {moment(el?.updatedAt).format('D MMM YYYY')}
                  </p>
                </div>
              </div>
              <div className="invoices-table-right">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          ))}

          { allInvoices && allInvoices?.map((el) => (
            <div className="table-section mobile-comp" onClick={() => handleInvoice(el._id)}>
              <div className="invoices-table-left">
                <div className="invoices-head">
                  <h6>{moment(el.createdAt).format('D MMM')}</h6>
                  <h6>Invoice No.</h6>
                  <h6>Invoice Total</h6>
                  <h6>Amount Applied</h6>
                  <h6>Balance Due</h6>
                  <h6 className={(el?.status === 'Approved' || el?.status === 'Sent') ? 'approved-green' : el?.status === 'Pending' ? 'deleted-yellow' : 'deleted-red'}>{el.status === 'deleted' ? 'Deleted' : el.status }</h6>
                </div>
                <div className="invoice-body">
                  <p>{moment(el?.createdAt).format('YYYY')}</p>
                  <p>{el?.invoiceNo}</p>
                  <p>
                    $
                    {el?.invoiceTotal ? currencyComma(el?.invoiceTotal) : 0}
                  </p>
                  <p>
                    $
                    {el?.amountApplied ? currencyComma(el?.amountApplied) : 0}
                  </p>
                  <p>
                    $
                    {el?.balanceDue ? currencyComma(el?.balanceDue) : 0}
                  </p>
                  <p>
                    {moment(el?.updatedAt).format('D MMM YYYY')}
                  </p>
                </div>
              </div>
              <div className="invoices-table-right">
                <img src={arrow} alt="arrow" />
              </div>
            </div>
          ))}

        </>
      ) : (
        <p className="no-invoice">No Invoices Found</p>
      )}

      {allInvoices && allInvoices.length > 0 ? (
        <div className="user-pagination">
          <ReactPaginate
            previousLabel="← Previous"
            nextLabel="Next →"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination-chk"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeClassName="pagination__link--active"
            breakLabel="..."
            marginPagesDisplayed={-2}
            pageRangeDisplayed={0}
          />
          <div className="no-of-page">
            Page
            {' '}
            {selectedPage}
            {' '}
            of
            {' '}
            {' '}
            {pageCount}
          </div>
        </div>
      ) : (
        null
      )}

    </div>

  </div>

);
InvoicesList.propTypes = {
  handleInvoice: PropTypes.func.isRequired,
  allInvoices: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  pageCount: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
};
export default InvoicesList;
