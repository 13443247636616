import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { getLatLng, geocodeByAddress } from 'react-places-autocomplete';
import share from '../../assets/home/direction.png';
import add from '../../assets/home/save.png';
import ServiceAreaMap from './areaMap';

const DirectionPopup = ({
  directionPopup, handleDirection, siteAddresses, coords,
}) => {
  const [clientCoords, setClientCoords] = useState({
    lat: '',
    lng: '',
  });
  const [matrix, setMatrix] = useState(null);
  const showInMapClicked = () => {
    if ((navigator.platform.indexOf('iPhone') !== -1)
     || (navigator.platform.indexOf('iPad') !== -1)
     || (navigator.platform.indexOf('iPod') !== -1)) {
      window.open(`http://maps.apple.com/?saddr=${coords.lat},${coords.lng}&daddr=${clientCoords.lat},${clientCoords.lng}&travelmode=driving`);
    } else {
      window.open(`https://www.google.com/maps/dir/?api=1&origin=${coords.lat},${coords.lng}&destination=${clientCoords.lat},${clientCoords.lng}&travelmode=driving`);
    }
  };
  return (
    <Modal
      size="md"
      show={directionPopup}
      onHide={handleDirection}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="staff-modal view-appointment-popup direction-page"
    >
      {/* {console.log('siteAddresses', siteAddresses)} */}
      {/* <div className="staff-close">
      <i
        className="fa fa-times"
        aria-hidden="true"
        onClick={handleDirection}
      />
    </div> */}
      <Modal.Body>
        <div className="direction-popup">
          {/* <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.679609062492!2d80.93635431451996!3d26.88191856788769!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd394384f9af%3A0xf5c5ac7015fdd32d!2sWebsultanate%20Software%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1626325566170!5m2!1sen!2sin" /> */}
          <ServiceAreaMap
            coords={coords}
            clientCoords={clientCoords}
            setMatrix={setMatrix}
            siteAddresses={siteAddresses}
            setClientCoords={setClientCoords}
          />
          <div className="direction-details">
            <div className="direction-heading">
              { siteAddresses
            && (
            <h2>
              {siteAddresses}
              {' '}
            </h2>
            )}
              <div className="direction-close">
                <i
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={handleDirection}
                />
              </div>
            </div>
            {matrix && <span>{matrix.totalDistance}</span>}
            <div className="direction-btn">
              <button type="submit" className="btn btn-primary" onClick={() => showInMapClicked()}>
                Directions
                <br />
                {matrix && <span>{matrix.totalTime}</span>}
              </button>
            </div>
            <div className="address">
              <p>Address</p>
              {siteAddresses
            && (
            <h6>
              {siteAddresses.address ? siteAddresses.address : ''}
              {' '}
            </h6>
            )}
            </div>
            <div className="share-direction">
              <button className="save-direction btn btn-primary" type="submit">
                <p>
                  {' '}
                  <img src={add} alt="save" />
                  {' '}
                  <br />
                  Save to...
                </p>
              </button>
              <button className="save-direction btn btn-primary" type="submit">
                <p>
                  <img src={share} alt="save" />
                  <br />
                  Share
                </p>
              </button>
            </div>

          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
DirectionPopup.propTypes = {
  directionPopup: PropTypes.func.isRequired,
  handleDirection: PropTypes.func.isRequired,
  siteAddresses: PropTypes.string.isRequired,
  coords: PropTypes.func.isRequired,

};
export default DirectionPopup;
