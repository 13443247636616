import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Nav, Tabs, Tab,
} from 'react-bootstrap';

const EditFormat = ({
  showPopup, handleImagePopup, setData,
  templateDataCopy, setTemplateDataCopy, Cancel, customName, text,
}) => {
  let sizeCount = 0;
  const Font = ['Times',
    'Arial', 'Helvetica Neue',
    'Helvetica', 'sans-serif', 'BlinkMacSystemFont',
    'Consolas', 'Menlo', 'Monaco', 'Lucida Console',
    'Liberation Mono', 'DejaVu Sans Mono',
    'Bitstream Vera Sans Mono', 'Courier New',
    'monospace', '"Exo 2", sans-serif'];
  const [customObject, setCustomObject] = useState({});
  useEffect(() => {
    let oldObject = { ...templateDataCopy };
    let customcheck = { ...oldObject[customName] };
    setCustomObject(customcheck);
  }, []);
  const setTemplateData = (data, wave) => {
    const { name, value } = data;
    let oldObject = { ...templateDataCopy };
    let customcheck = { ...oldObject[name], [wave]: value };
    oldObject[name] = customcheck;
    setTemplateDataCopy(oldObject);
    setCustomObject(customcheck);
  };
  return (
    <Modal
      size="md"
      show={showPopup}
      onHide={handleImagePopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup change-text-popup"
    >
      {/* <div className="staff-close">
        <i className="fa fa-times" onClick={handleImagePopup} aria-hidden="true" />
      </div> */}
      <Modal.Body>
        <div className="appointment-container preview-invoice preview-popup">

          <div className="change-image-section">
            <h6>
              Feild Name:
              {text}
            </h6>
            {/* <div className="field-location-section">
              <div className="field-location">
                <h6>Field Location</h6>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Margin left</Form.Label>
                  <Form.Control type="number" name="imageMarginLeft" />
                  <span>%</span>
                </Form.Group>
                <br />
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Margin Right</Form.Label>
                  <Form.Control type="number" name="imageMarginLeft" />
                  <span>%</span>
                </Form.Group>
              </div>
              <div className="field-location">
                <h6>Field Size</h6>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Height</Form.Label>
                  <Form.Control type="number" name={customName} value={customObject.height} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'height')} />
                  <span>%</span>
                </Form.Group>
                <br />
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Width</Form.Label>
                  <Form.Control type="number" name={customName} value={customObject.width} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'width')} />
                  <span>%</span>
                </Form.Group>
              </div>
            </div> */}
            <div className="field-location-section">
              <div className="field-location">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Color</Form.Label>
                  <Form.Control type="color" name={customName} value={customObject.color} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'color')} />
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font</Form.Label>
                  <select className="form-control" name={customName} value={customObject.font} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'font')}>
                    {Font.map((f) => (
                      <option value={f}>{f}</option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Style</Form.Label>
                  <select className="form-control" name={customName} value={customObject.fontStyle} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontStyle')}>
                    <option value="normal">Normal</option>
                    <option value="Italic">Italic</option>
                    <option value="bold">Bold</option>
                  </select>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Size</Form.Label>
                  <select className="form-control" name={customName} value={customObject.fontSize} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontSize')}>
                    {Array.from(Array(20).keys()).map(() => {
                      sizeCount += 1;
                      return (
                        <option value={sizeCount}>{sizeCount}</option>
                      );
                    })}
                  </select>
                </Form.Group>
              </div>
            </div>
            <div className="change-section-btn text-section-btn">
              <Button onClick={Cancel}>Cancel</Button>
              <Button onClick={setData}>OK</Button>
            </div>
          </div>

        </div>
      </Modal.Body>
    </Modal>

  );
};
EditFormat.propTypes = {
  showPopup: PropTypes.func.isRequired,
  handleImagePopup: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  templateDataCopy: PropTypes.objectOf.isRequired,
  setTemplateDataCopy: PropTypes.func.isRequired,
  Cancel: PropTypes.func.isRequired,
  customName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default EditFormat;
