import React from 'react';
import { PropTypes } from 'prop-types';
import {
  SquarePaymentsForm,
  CreditCardInput, AchPay, GooglePay, ApplePay,
} from 'react-square-web-payments-sdk';

const SquareCheckout = ({ handleToken }) => {
  console.log('protocol------', window.location.protocol);
  return (
    <>
      <div className="staff-form">
        <SquarePaymentsForm
          applicationId="sandbox-sq0idb-oYeu027nYUL7QSs5ZNQDxA"
          locationId="LXD1PHSA0X03H"
          cardTokenizeResponseReceived={(token) => handleToken(token)}
          createPaymentRequest={() => ({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
              amount: '500',
              label: 'Total',
            },
          })}
        >
          <CreditCardInput text="Pay now" />
          {/* Uncomment this when need of gPay, applePay, ACH */}
          {/* <p />
          <AchPay accountHolderName="Mohd Faizan Ansari" />
          <p />
          {window.location.protocol === 'https:' && (
          <>
            <GooglePay />
            <p />
            <ApplePay />
          </>
          )} */}
        </SquarePaymentsForm>
      </div>
    </>
  );
};
SquareCheckout.propTypes = {
  handleToken: PropTypes.func.isRequired,
};
export default SquareCheckout;
