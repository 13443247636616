/* eslint-disable func-names */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
import React, { useState, useEffect, useCallback } from 'react';
// import { Link } from 'react-router-dom';
import {
  Table, Button, Accordion, Card, Dropdown, Form,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import AdminWrapper from '../admin/adminwrapper/wrapper';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import {
  showErrMsg, validateDataWithKey, getCalendarPath, queryString,
} from '../../functions/index';
// import DurationPicker from '../appointment/durationPicker';
import sort from '../../assets/home/sort.png';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import showmore from '../../assets/home/showmore.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import AddEquipment from './addEquipment';
import AddEquipmentDetails from './addEqpDetails';
import './equipment.css';
import { errorBorderTime, errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';

const EquipmentDetails = () => {
  const { client } = queryString();
  const initialSubcategory = {
    equipmentName: '',
    manufacturer: '',
    modelName: '',
    images: [],
    imageCaption: [],
    notes: '',
  };
  const initialEditData = { index: null, categoryId: '', isEdit: false };
  const [selectedAccordion, setSelectedAccordion] = useState([]);
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  const [isServiceAccordOpen, setIsServiceAccordOpen] = useState(false);
  const [renderSort, setRenderSort] = useState(false);
  const [renderSortProduct, setRenderSortProduct] = useState(false);
  const [openService, setOpenService] = useState();
  const [equipmentDetails, setEquipmentDetails] = useState();
  const [subEquipment, setSubEquipment] = useState(initialSubcategory);
  const [equipment, setEquipment] = useState('');
  const [equipmentList, setEquipmentList] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState('');
  const [addeqipment, setAddEquipment] = useState(false);
  const [type, setType] = useState('industryType');
  const [editData, setEditData] = useState(initialEditData);
  const [preview, setPreview] = useState([]);
  const [files, setFiles] = useState();
  const [imagesLength, setImagesLength] = useState(0);
  // const [removeImages, setRemoveImages] = useState([]);
  const [updatePreview, setUpdatePreview] = useState([]);
  const [subIndex, setSubIndex] = useState();
  const history = useHistory();
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRenderSort = () => {
    console.log('renderingChecking');
    setRenderSort(!renderSort);
  };

  const handleChangeSubEquipments = (e, index, data) => {
    const { value, name } = e.target;

    if (name === 'images') {
      setPreview([...preview, ...e.target.files]);
      if (editData.isEdit === true) {
        setUpdatePreview([...updatePreview, ...e.target.files]);
      }
      setFiles(e.target.files);
    }

    if (name === 'imageCaption') {
      let copySubEquipment = { ...subEquipment };
      copySubEquipment.imageCaption[index] = value;
      // console.log('copuy', copySubEquipment);
      setSubEquipment(copySubEquipment);
    }
    if (name !== 'imageCaption' && name !== 'images') {
      setSubEquipment({ ...subEquipment, [name]: value });
    }
  };

  const handleImageChange = (e, index) => {
    const { value, name } = e.target;
    let copySubEquipment = { ...subEquipment };
    copySubEquipment.imageCaption[index] = value;
    // console.log('copuy', copySubEquipment);
    setSubEquipment(copySubEquipment);
  };

  const handleCaption = (index) => {
    // console.log('hello', index);
    const copySubEquipment = { ...subEquipment };
    // console.log('captionDetails', copySubEquipment);
    copySubEquipment.imageCaption[index] = '';

    // console.log('captionChange', copySubEquipment);
    setSubEquipment(copySubEquipment);
  };

  const removePhotos = (eldata, index) => {
    // console.log('subEquipment', subEquipment.images);
    const data = preview.filter((el, i) => i !== index);
    const updatedData = updatePreview.filter((el, uindex) => uindex !== index);
    let copySubEquipment = { ...subEquipment };
    // console.log('data', data);
    const captionData = copySubEquipment.imageCaption.filter((ele, indx) => indx !== index);
    // console.log(captionData);
    const imageData = copySubEquipment.images.filter((ele, indx) => indx !== index);
    copySubEquipment.imageCaption = captionData;
    copySubEquipment.images = imageData;
    setImagesLength(imageData.length);
    setSubEquipment(copySubEquipment);
    // console.log('copySubEquipment', copySubEquipment);
    setPreview(data);
    setUpdatePreview(updatedData);
  };

  const handleEditEquipment = (id, name, index) => {
    setAddEquipment(!addeqipment);
    setSelectedEquipment(id);
    setEquipment(name);
  };

  const handleUpdateEquipment = async () => {
    try {
      const response = await userInstance().put('/equipment/updateEquipment', {
        selectedCategory: selectedEquipment,
        category: equipment,
      });
      // console.log('sdsdsd', response);
      const { msg } = response.data;
      if (msg) {
        toastMessage('success', 'Equipment Group Name updated');
      }
      setAddEquipment(!addeqipment);
      getServices();
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleEditSubEquipments = async (index, categoryId, name) => {
    try {
      const currentSubCat = equipmentList.filter((el) => el._id === categoryId);
      if (currentSubCat.length) {
        const currentSubData = currentSubCat[0].subCategory[index];
        setSubEquipment({ ...subEquipment, ...currentSubData });
        setPreview(currentSubData.images);
        setImagesLength(currentSubData.images.length);
        setSubIndex(index);
      }
      setEditData({
        ...editData,
        index,
        categoryId,
        isEdit: true,
      });
      // setShowCat(true);
      setEquipmentDetails(!equipmentDetails);
      setEquipment(name);
      setUpdatePreview([]);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const getServices = async () => {
    try {
      const response = await userInstance().get('/equipment/getEquipments');
      const { equipments } = response.data;
      setEquipmentList(equipments);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleAddEquipment = async (e) => {
    e.preventDefault();
    try {
      if (selectedEquipment) {
        handleUpdateEquipment();
      } else if (equipment) {
        const response = await userInstance().post('/equipment/addEquipment', {
          category: equipment,
          type,
        });
        // console.log('sdsdsd', response);
        const { msg } = response.data;
        // HandleOpenClose();
        setAddEquipment(!addeqipment);
        getServices();
        toastMessage('success', msg);
      } else {
        toastMessage('error', 'Please enter category name.');
      }
    } catch (error) {
      //   console.log(error);
    }
  };

  const handleAddSubEquipments = async (e) => {
    try {
      e.preventDefault();
      if (selectedEquipment) {
        // console.log('selectedCategory', selectedCategory);
        const subEquipmentCopy = { ...subEquipment };
        console.log('subEquipment ==============>', subEquipmentCopy);
        const {
          equipmentName,
          manufacturer,
          modelName,
          images,
          imageCaption,
          notes,
        } = subEquipmentCopy;
        const validationObj = {
          equipmentName,
          manufacturer,
          modelName,
        };

        let { isValid, emptyField } = validateDataWithKey(validationObj);

        if (isValid) {
          const reqObj = {
            ...subEquipmentCopy,
          };

          const formdata = new FormData();

          preview.forEach((el, index) => {
            formdata.append('file', el);
            if (imageCaption[index] === '' || imageCaption[index] === undefined) {
              toastMessage('error', 'Please enter Image caption.');
              return;
            }
            console.log({ el, caom: imageCaption[index] });
          });

          if (preview.length > 20) {
            toastMessage('error', 'You can\'t choose images more than twenty.');
          } else {
            // console.log('subEquipmentCopy', subEquipmentCopy);

            const response = await notTimeOutUserInstance().post('/equipment/addSubEquipments', formdata, {
              params: {
                subcategory: subEquipment,
                type,
                selectedCategory: selectedEquipment,
              },
            });
            const { msg } = response.data;
            setEquipmentDetails(!equipmentDetails);
            getServices();
            toastMessage('success', msg);
          }
        } else {
          toastMessage('error', `Please enter ${emptyField} for product.`);
          setErrors(emptyField);
        }
      } else {
        toastMessage('error', 'Please enter product name.');
      }
      setTimeout(() => {
        setErrors('');
      }, errorBorderTime);
    } catch (error) {
      //   console.log(error);
      console.log('errprrrr', error);
    }
  };

  const handleUpdateSubEquipment = async (e) => {
    try {
      e.preventDefault();
      const currentSubCat = equipmentList.filter((el) => el._id === editData.categoryId);

      if (currentSubCat.length) {
        currentSubCat[0].subCategory[editData.index] = subEquipment;
        const subcategoryCopy = {
          ...currentSubCat[0].subCategory[editData.index],
        };
        const {
          equipmentName,
          manufacturer,
          modelName,
          images,
          imageCaption,
          notes,
        } = subcategoryCopy;
        const validationObj = {
          equipmentName,
          manufacturer,
          modelName,
        };
        let { isValid, emptyField } = validateDataWithKey(validationObj);
        if (isValid) {
          const formdata = new FormData();
          updatePreview.forEach((el) => {
            formdata.append('file', el);
          });
          formdata.append('subCategory', JSON.stringify(currentSubCat[0].subCategory));
          formdata.append('_id', editData.categoryId);
          formdata.append('subIndex', subIndex);

          const response = await notTimeOutUserInstance().put('/equipment/updateSubEquipment', formdata);
          // console.log('sdsdsd', response);
          const { msg } = response.data;
          setEquipmentDetails(!equipmentDetails);
          getServices();
          // if (msg) {
          //   if (editData.entryType === 'product') {
          toastMessage('success', 'Product Updated Successfully');
          // } else {
          //   success('Service Updated Successfully', 'Message', 1000);
          // }
        } else {
          toastMessage('error', `Please enter ${emptyField} for product.`);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleDeleteSubEquipments = async (index, categoryId, subcategoryID, eltype) => {
    try {
      let categoryIndex = null;
      const currentSubCat = equipmentList.filter((el, i) => {
        if (el._id === categoryId) {
          categoryIndex = i;
          return true;
        }
        return false;
      });
      if (currentSubCat.length) {
        currentSubCat[0].subCategory.splice(index, 1);
        // console.log('after deleteing', currentSubCat);
        const serviceArr = equipmentList;
        serviceArr[categoryIndex] = currentSubCat[0];
        setEquipmentList([...serviceArr]);
        const formdata = new FormData();
        formdata.append('subCategory', JSON.stringify(currentSubCat[0].subCategory));
        formdata.append('_id', categoryId);
        // setSubEquipment({ ...subEquipment, ...currentSubData });
        const response = await userInstance().put('/equipment/updateSubEquipment', formdata);
        getServices();
        // if (eltype === 'product') {
        toastMessage('success', 'SubEquipment deleted successfully');
        // } else {
        // success('Service deleted successfully', 'Message', 1000);
        // }
      }
    } catch (error) {
      //   console.log(error);
    }
  };

  const submitRemoveEquipment = (id) => {
    confirmAlert({
      title: 'Delete category?',
      message: 'Are you sure to do this?',
      overlayClassName: 'delete-popup',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteEquipment(id),
        },
        {
          label: 'No',
          onClick: () => ' No ',
        },
      ],
    });
  };

  const submitRemove = (index, categoryId, categoryName) => {
    confirmAlert({
      title: 'Delete Service?',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteSubEquipments(index, categoryId, categoryName),
        },
        {
          label: 'No',
          onClick: () => ' No ',
        },
      ],
    });
  };

  const handleDeleteEquipment = async (id) => {
    try {
      const checkSubCat = equipmentList.filter((el) => el._id === id);
      if (checkSubCat.length) {
        if (!checkSubCat[0].subCategory.length) {
          const response = await userInstance().delete(`/equipment/deleteEquipment?id=${id}`);
          // console.log('sdsdsd', response);
          const { msg } = response.data;
          getServices();
          toastMessage('success', msg);
        } else {
          // console.log('cannot');
          toastMessage('error', 'All SubEquipments linked to this category will need to be deleted before it can be deleted.');
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleequipment = () => {
    setAddEquipment(!addeqipment);
  };
  const handleEquipmentDetails = (categoryName, index, datatype) => {
    setType(datatype);
    setPreview([]);
    setUpdatePreview([]);
    // setRemoveImages([]);
    setSelectedEquipment(categoryName);
    setEditData(initialEditData);
    setSubEquipment(initialSubcategory);
    setEquipmentDetails(!equipmentDetails);
    setImagesLength(0);
  };

  const moveArr = (oldIndex, newIndex, entryType, order, sign) => {
    const filteredArr = equipmentList.filter((el) => el.type === entryType);
    const filteredArrType = equipmentList.filter((el) => el.type !== entryType);
    if (sign === 'minus') {
      filteredArr[oldIndex - 1].order = filteredArr[oldIndex].order;
    } else {
      filteredArr[oldIndex + 1].order = filteredArr[oldIndex].order;
    }
    filteredArr[oldIndex].order = order;
    // console.log('filteredArr', filteredArr);
    const currentServices = [...filteredArr];
    if (newIndex >= currentServices.length) {
      let k = newIndex - currentServices.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        currentServices.push(undefined);
      }
    }
    currentServices.splice(newIndex, 0, currentServices.splice(oldIndex, 1)[0]);
    setEquipmentList([...currentServices, ...filteredArrType]);
    // console.log('[...currentServices, ...filteredArrType]', [
    //   ...currentServices,
    //   ...filteredArrType,
    // ]);
  };

  const moveArrSubcategory = (categoryId, oldIndex, newIndex) => {
    const arrCopy = [...equipmentList];
    let index = null;
    const filteredArr = arrCopy.filter((el, i) => {
      if (el._id === categoryId) {
        index = i;
        return true;
      }
      return false;
    });
    if (filteredArr.length) {
      const subcategories = filteredArr[0].subCategory;
      if (newIndex >= subcategories.length) {
        let k = newIndex - subcategories.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
          subcategories.push(undefined);
        }
      }
      subcategories.splice(newIndex, 0, subcategories.splice(oldIndex, 1)[0]);
      arrCopy[index].subCategory = subcategories;
      setEquipmentList([...arrCopy]);
    }
  };

  const handleUpAccordion = (e) => {
    if (selectedAccordion && selectedAccordion.includes(e)) {
      const data = selectedAccordion.filter((el) => el !== e);
      return setSelectedAccordion(data);
    }
    // const accordinData = JSON.parse(localStorage.getItem('accordinData'));
    // localStorage.setItem('accordinData', JSON.stringify({ data: [...selectedAccordion, e] }));
    return setSelectedAccordion([...selectedAccordion, e]);
  };

  const updateSorting = async (e) => {
    e.preventDefault();
    try {
      const response = await userInstance().put('/equipment/updateEquipmentOrder', {
        services: equipmentList,
      });
      const { msg } = response.data;
      toastMessage('success', msg);
    } catch (error) {
      //   console.log(error);
    }
    setRenderSort(false);
    setRenderSortProduct(false);
  };

  // function readURL(input) {
  //   if (input.files && input.files[0]) {
  //     var reader = new FileReader();

  //     reader.onload = function (e) {
  //       const
  //     };

  //     reader.readAsDataURL(input.files[0]);
  //   }
  // }
  const handleBackPage = () => {
    history.push(`${getCalendarPath()}&clientid=${client}`);
  };

  useEffect(() => {
    setLoading(true);
    getServices();
  }, []);

  useEffect(() => {
    if (errors) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errors]);

  return (
    <AdminWrapper>

      {loading ? <Loader /> : (
        <div className="product-service-container equipemnt-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {client
              && (
              <div className="back-option">
                <span />
                <i className="fa fa-times" aria-hidden="true" onClick={handleBackPage} />

              </div>
              )}
                <div className="main-titles">
                  <h3>Equipment Details</h3>
                </div>
              </div>
            </div>
            <div className="table-data">
              <div className="service-mbl">
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <div className="service-container">
                        <div className="service-title title-service">
                          <h4>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey="0"
                            >
                              {/* <i className={isServiceAccordOpen ? 'fas far fa-chevron-down' : 'fas far fa-chevron-up'} /> */}
                              {' '}
                              Equipment List
                            </Accordion.Toggle>
                          </h4>
                          {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                          <div className={`services-header ${renderSort && 'active-sort'}`}>
                            <div className="sorting">
                              <img src={sort} alt="sorting" onClick={handleRenderSort} />
                            </div>
                            <span>
                              <i className="fa fa-plus" onClick={handleequipment} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Card.Header>
                    {/* {console.log('wwww', equipmentList)} */}
                    <Accordion.Collapse eventKey="0">
                      <div className="mob-table">
                        {equipmentList && equipmentList.map((data, index) => (
                          <Accordion className="mob-accordion outer-accordion" key={data._id}>
                            <Card.Header>
                              <div className="heading-accordion">
                                {/* {console.log('datasub', data.subCategory.length, 'index', index)} */}
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey={!data.subCategory.length ? null : '0'}
                                  onClick={() => handleUpAccordion(data._id)}
                                >
                                  <h5>
                                    <i
                                      className={`fas ${
                                        selectedAccordion.includes(data._id)
                                          ? ' far fa-chevron-circle-down'
                                          : 'fa-chevron-circle-up'
                                      }`}
                                    />
                                    {data.category}
                                  </h5>
                                </Accordion.Toggle>
                                <div className={`service-edit-icon ${renderSort && 'add-sort'}`}>
                                  {renderSort && (
                                  <div className={`sorting up-down ${renderSort && 'active-sort'}`}>
                                    {index !== 0 ? (
                                      <img
                                        src={upsort}
                                        alt="sorting"
                                        onClick={() => moveArr(index, index - 1, 'industryType', data.order - 1, 'minus')}
                                      />
                                    ) : null}
                                    {index !== equipmentList.filter((datas) => datas.type === 'industryType').length - 1 ? (
                                      <img
                                        src={downsort}
                                        alt="sorting"
                                        onClick={() => moveArr(index, index + 1, 'industryType', data.order + 1, 'plus')}
                                      />
                                    ) : null}
                                  </div>
                                  )}

                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                      <img src={showmore} alt="show" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <div className="action" onClick={() => handleEquipmentDetails(data.category, index, data.type)}>
                                        <img src={greyplus} alt="edit" />
                                        Add
                                      </div>

                                      <div
                                        className="action"
                                        onClick={() => handleEditEquipment(data._id, data.category, index)}
                                      >
                                        <img src={greyedit} alt="edit" />
                                        Edit
                                      </div>
                                      <div
                                        className="action"
                                        onClick={() => submitRemoveEquipment(data._id)}
                                      >
                                        <img src={greydelete} alt="edit" />
                                        Delete
                                      </div>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>

                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body>
                                <div className="inner-accordion">

                                  <>
                                    <Accordion
                                      defaultActiveKey="0"
                                    >
                                      <Card>
                                        {data.subCategory.map((el, i) => (
                                          <Accordion key={el._id}>
                                            <Card.Header className="sub-category">
                                              <Accordion.Toggle
                                                as={Button}
                                                variant="link"
                                                eventKey="0"
                                              >
                                                <div className="product-service-list">
                                                  <i
                                                    className={`fas ${
                                                      selectedNestedAccordion.includes(0)
                                                        ? 'far fa-chevron-circle-down'
                                                        : 'far fa-chevron-circle-up'
                                                    }`}
                                                  />
                                                  <span>
                                                    {el.equipmentName}
                                                  </span>

                                                </div>
                                              </Accordion.Toggle>
                                              <span className="time-category">
                                                {renderSort ? (
                                                  <div className="sorting up-down">
                                                    {i !== 0 ? (
                                                      <img
                                                        src={upsort}
                                                        alt="sorting"
                                                        onClick={() => moveArrSubcategory(data._id, i, i - 1)}
                                                      />
                                                    ) : null}
                                                    {i !== data.subCategory.length - 1 ? (
                                                      <img
                                                        src={downsort}
                                                        alt="sorting"
                                                        onClick={() => moveArrSubcategory(data._id, i, i + 1)}
                                                      />
                                                    ) : null}
                                                  </div>
                                                ) : null}
                                                {/* {!renderSort ? (
                                                <div className="timehour">{convertToHour(data.duration)}</div>
                                              ) : null} */}
                                              </span>
                                              {/* <span className="time-category">
                                          <div className="timehour">2h</div>
                                        </span> */}

                                            </Card.Header>
                                            <Accordion.Collapse
                                              eventKey="0"
                                            >
                                              <ul className="grid-list">
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>
                                                      Manufacturer
                                                      {/* <span> (as will display on invoices) </span> */}
                                                    </h3>
                                                    <div className="grid-content">{el.manufacturer}</div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Model</h3>
                                                    <div className="grid-content">{el.modelName}</div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Install Date</h3>
                                                    <div className="grid-content">
                                                      23.11.20
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Photos Taken</h3>
                                                    <div className="grid-content">
                                                      <div className="open-date client-notes">
                                                        <div className="basic">
                                                          <Form.Check type="checkbox" checked={el.images && el.images.length !== 0 ? true : ''} />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box grid-notes">
                                                    <h3>
                                                      Notes
                                                      <br />
                                                      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                        <Form.Control as="textarea" rows={3} value={el.notes} disabled={el.notes} />
                                                      </Form.Group>
                                                    </h3>

                                                  </div>
                                                </li>
                                                <li className="grid-list-item">
                                                  <div className="grid-box">
                                                    <h3>Actions</h3>
                                                    <div className="action-btn">
                                                      <Button
                                                        className="edit-btn"
                                                        onClick={
                                                        () => handleEditSubEquipments(i, data._id, data.category)
                                                      }
                                                      >
                                                        <img src={whiteedit} alt="edit" />
                                                      </Button>
                                                      <Button
                                                        className="delete-btn"
                                                        onClick={
                                                        () => submitRemove(i, data._id, data.category)
                                                      }
                                                      >
                                                        <img src={whitedelete} alt="edit" />
                                                      </Button>
                                                    </div>
                                                  </div>
                                                </li>
                                              </ul>
                                            </Accordion.Collapse>
                                          </Accordion>
                                        ))}
                                      </Card>
                                    </Accordion>
                                  </>

                                </div>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Accordion>
                        ))}
                      </div>
                    </Accordion.Collapse>

                  </Card>
                </Accordion>
              </div>

            </div>
            <div className="view-btn">
              <Button type="submit" className="btn btn-save" onClick={updateSorting}>
                Save/Update
              </Button>
            </div>
          </div>
          <AddEquipment
            addeqipment={addeqipment}
            handleequipment={handleequipment}
            setEquipment={setEquipment}
            equipment={equipment}
          // show={show}
            handleAddEquipment={handleAddEquipment}
            errors={errors}
          />
          <AddEquipmentDetails
            subEquipment={subEquipment}
            editData={editData}
            handleUpdateSubEquipment={handleUpdateSubEquipment}
            handleEquipmentDetails={handleEquipmentDetails}
            handleChangeSubEquipments={handleChangeSubEquipments}
            equipmentDetails={equipmentDetails}
            setEquipmentDetails={setEquipmentDetails}
            handleAddSubEquipments={handleAddSubEquipments}
            preview={preview}
            removePhotos={removePhotos}
            handleImageChange={handleImageChange}
            imagesLength={imagesLength}
            handleCaption={handleCaption}
            errors={errors}
          />
        </div>
      )}
    </AdminWrapper>

  );
};

export default EquipmentDetails;
