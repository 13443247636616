import React, { useState, useEffect } from 'react';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { errorMsgTime, successMsgTime } from '../../../functions/intervalTime';
import { userInstance, notTimeOutUserInstance } from '../../../config/axios';
import toastMessage from '../../../functions/toastMessage';

const AddSpecific = () => {
  const initial_state = {
    services_products: 'All Services',
    xero_account: '200',
  };
  const [data, setData] = useState(initial_state);
  const [check, setCheck] = useState(false);
  const [activeChart, setActiveChart] = useState();
  const [dataCat, setdataCat] = useState({ cat: undefined, subCat: 'No Sub-Categories Available' });
  const [category_, setCategory_] = useState([]);
  const [subCategory_, setsubCategory_] = useState([]);
  const [chart, setChart] = useState([{ code: '200', name: 'Sales' }]);
  const [beforeCode, setbeforeCode] = useState(undefined);
  const validateDataWithKey = (vData_) => {
    let isValid = true;
    let emptyField = '';
    Object.keys(vData_).forEach((el) => {
      if (isValid) {
        if (!vData_[el]) {
          isValid = false;
          emptyField = el;
        }
      }
    });
    return { isValid, emptyField };
  };
  const handleCheck = () => {
    setCheck((prev) => !prev);
  };

  const handleSubmit = async () => {
    let payload = {
      xeroCode: activeChart,
      type: data.services_products,
      category: dataCat.cat,

    };
    if (check === false && dataCat.subCat !== 'No Sub-Categories Available') {
      payload.subCategory = dataCat.subCat;
    }

    const response = await userInstance().post('/xero/AddSpecific', payload);
    // console.log('this is the response', response);

    handleSavedCategory();

    // let { isValid, emptyField } = validateDataWithKey(payload);
    // if (isValid) {
    //   payload.subCategory = dataCat.subCat;
    // } else {
    //   NotificationManager.error(`${emptyField} is not selected`, 'Message', errorMsgTime);
    // }

    // subCategory: ,

    // console.log('this is the payload', payload);
  };

  const handleDropDown = async (e) => {
    const { name } = e.target;
    let { value } = e.target;
    if (value === 'All Services' || value === 'All Products') {
      const response = await userInstance().post('/xero/getServicesProducts', { value });
      let { data: { dataArray, code } } = response || {};
      if (code && code === 200) {
        let cat = [];
        dataArray.forEach((ele) => {
          cat.push(ele.category);
        });
        setCategory_(cat);
        let sub = 'No Sub-Categories Available';
        let beforeSet;
        if (dataArray && dataArray.length > 0) {
          let { subCategory } = dataArray[0];
          if (subCategory && subCategory.length > 0) {
            sub = subCategory[0].name;
            beforeSet = subCategory[0]?.xerocode;
            // console.log('this is the beforeSet', beforeSet);
            setbeforeCode(beforeSet);
            setsubCategory_(subCategory);
          } else {
            setsubCategory_([]);
          }
        } else {
          setsubCategory_([]);
        }
        // console.log('this is the sub', sub);
        setdataCat({ cat: cat[0], subCat: sub });
      }
      setData({ ...data, [name]: value });
      setCheck(false);
    } else {
      setCategory_([]);
      setsubCategory_([]);
      setData({ ...data, [name]: value });
    }
  };

  const handleCategory = async (e) => {
    let { value } = e.target;
    const response = await userInstance().post('/xero/getCategory', { value });
    const { data: { code, dataCategory } } = response || {};
    let subCat__ = 'No Sub-Categories Available';
    let beforeSet;
    if (code === 200) {
      const { subCategory } = dataCategory[0] || {};
      if (subCategory && subCategory.length > 0) {
        subCat__ = subCategory[0].name;
        beforeSet = subCategory[0]?.xerocode;
        // console.log('this is the beforeSet', beforeSet);
        setbeforeCode(beforeSet);
        setsubCategory_(subCategory);
      } else {
        setbeforeCode();
        setsubCategory_([]);
      }
    } else {
      setbeforeCode();
      setsubCategory_([]);
    }
    // console.log('this is the sub', subCat__);
    setdataCat({ cat: value, subCat: subCat__ });
    setCheck(false);
  };
  const handleSavedCategory = async () => {
    const categoryToHit = dataCat.cat;

    const response = await userInstance().post('/xero/getCategory', { value: categoryToHit });
    // console.log('this is the response', response);
    const { data: { code, dataCategory } } = response || {};
    let subCat__ = 'No Sub-Categories Available';
    let beforeSet;
    if (code === 200) {
      const { subCategory } = dataCategory[0] || {};
      if (subCategory && subCategory.length > 0) {
        subCat__ = subCategory.find((ele) => ele.name === dataCat.subCat).name;
        // console.log('this is the subCat_', subCat__);
        beforeSet = subCategory.find((ele) => ele.name === dataCat.subCat).xerocode;
        setbeforeCode(beforeSet);
        setsubCategory_(subCategory);
      } else {
        setbeforeCode();
        setsubCategory_([]);
      }
    } else {
      setbeforeCode();
      setsubCategory_([]);
    }
    setdataCat({ cat: categoryToHit, subCat: subCat__ });
    toastMessage('success', 'Successfully Updated Chart of Accounts');
  };

  const handleSubCategory = async (e) => {
    let { value } = e.target;
    // console.log('this is the value', value);
    // console.log('this is the subCategory_', subCategory_);
    let element = subCategory_.find((ele) => ele.name === value);
    let { xerocode } = element || {};
    setbeforeCode(xerocode);
    setdataCat({ ...dataCat, subCat: value });
  };

  const handleChart = async (e) => {
    setActiveChart(e.target.value);
  };
  const getChartofAccount = async () => {
    const response = await userInstance().post('/xero/getChartAccounts');
    const { msg } = response.data;
    if (msg === 'Success') {
      const { data: { accounts } } = response.data || {};
      if (accounts) {
        let newAccount = [];
        accounts.forEach((ele) => {
          if (ele.type === 'REVENUE') {
            let obj = { code: ele.code, name: ele.name };
            newAccount.push(obj);
          }
        });
        setChart(newAccount);
        setActiveChart(newAccount[0].code);
      }
    }
  };
  const getServiceAndProducts = async () => {
    const response = await userInstance().post('/xero/getServicesProducts', { value: 'All Services' });
    let { data: { dataArray, code } } = response || {};
    if (code === 200) {
      let cat = [];
      dataArray.forEach((ele) => {
        cat.push(ele.category);
      });
      setCategory_(cat);
      let sub = 'No Sub-Categories Available';
      let beforeSet;
      if (dataArray && dataArray.length > 0) {
        let { subCategory } = dataArray[0];
        if (subCategory && subCategory.length > 0) {
          // console.log('this is the sub-category', subCategory);
          sub = subCategory[0].name;
          beforeSet = subCategory[0]?.xerocode;
          setbeforeCode(beforeSet);
          setsubCategory_(subCategory);
        } else {
          setbeforeCode();
          setsubCategory_([]);
        }
      } else {
        setbeforeCode();
        setsubCategory_([]);
      }

      setdataCat({ cat: cat[0], subCat: sub });
      setCheck(false);
    }
  };

  useEffect(() => {
    getServiceAndProducts();
    getChartofAccount();
  }, []);
  // console.log('this is the check', check);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Form.Group controlId="formBasicLocation">
            <Form.Label>Selected Services and Products</Form.Label>
            <div className="select-box">
              <Form.Control
                as="select"
                value={data.services_products}
                name="services_products"
                onChange={handleDropDown}
              >
                <option value="All Services">Services</option>
                <option value="All Products">Products</option>
              </Form.Control>
            </div>
          </Form.Group>
          {category_ && category_.length > 0 && (
          <Form.Group controlId="formBasicLocation">
            <Form.Label>
              {data.services_products === 'All Services' ? 'Service' : (data.services_products === 'All Products' ? 'Product' : '')}
              {' '}
              Group Name
            </Form.Label>
            <div className="select-box">
              <Form.Control
                as="select"
                value={dataCat.cat}
                name="cat"
                onChange={handleCategory}
              >

                {category_.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Form.Group>
          )}
          <div className="xero-checkbox">
            <p>
              For all
              {' '}

              {data.services_products === 'All Services' ? 'Service Group Sub-Item Name' : (data.services_products === 'All Products' ? 'Product Group Sub-Item Name' : '')}

            </p>
            <div className="open-date client-notes">
              <div className="basic">
                <Form.Check type="checkbox" onChange={handleCheck} checked={check === true} />
              </div>
            </div>
          </div>

          {!check && (
            <Form.Group controlId="formBasicLocation">
              <Form.Label>
                {data.services_products === 'All Services' ? 'Service Group Sub-Item Name' : (data.services_products === 'All Products' ? 'Product Group Sub-Item Name' : '')}
                {' '}
              </Form.Label>
              <div className="select-box">
                <Form.Control
                  as="select"
                  value={dataCat.subCat}
                  name="subCat"
                  // defaultValue="No Sub-Categories avaliable"
                  onChange={handleSubCategory}
                >
                  {subCategory_ && subCategory_.length === 0 && <option key="No Sub-Categories available">No Sub-Categories available</option> }
                  {subCategory_.map((option) => (
                    <option key={option} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
          )}
          <Form.Group controlId="formBasicLocation">
            <Form.Label>Xero Account</Form.Label>
            <div className="select-box">
              <Form.Control
                as="select"
                value={activeChart}
                name="xero_account"
                onChange={handleChart}
              >
                {' '}
                {beforeCode && !check && (
                <option disabled>
                  Current
                  {' '}
                  {chart.find((ele) => ele.code === beforeCode).code}
                  -
                  {chart.find((ele) => ele.code === beforeCode).name}
                </option>
                )}
                {chart.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.code}
                    -
                    {option.name}
                  </option>
                ))}
              </Form.Control>
            </div>
          </Form.Group>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn right-btns">
                <Button
                  type="button"
                  className="btn btn-save"
                  onClick={handleSubmit}
                >
                  {' '}
                  Save/Update
                  {' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSpecific;
