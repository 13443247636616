import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import showmore from '../../assets/home/showmoreblue.png';
import EditDaysTime from './editDaysTimePopup';
import { getDaysArray } from '../../functions/index';
import { userInstance } from '../../config/axios';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const EmailSMSSending = ({ getSettingData, allSettingData }) => {
  const { timescheduled } = allSettingData || {};
  const initialState = {
    daysList: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    days: 'Everyday',
    startTime: '9:00AM',
    endTime: '5:00PM',
    isSelect: 'start',
    type: 'daytime',
  };
  const [editDays, seteditDays] = useState(false);
  const [timePicker, setTimePicker] = useState(false);
  const [payload, setPayload] = useState(initialState);
  const handleEditdays = () => {
    seteditDays(!editDays);
  };

  const handleTimeRepeat = (e) => {
    const { value } = e.target;
    const myArray = value.split('_');
    const dayName = myArray[0];
    const dayToFrom = myArray[1];
    const allDays = getDaysArray(dayToFrom);
    let copyPayload = { ...payload };
    copyPayload.days = dayName;
    copyPayload.daysList = allDays;
    setPayload(copyPayload);
  };

  const handleTimePicker = (type) => {
    setTimePicker(true);
    setPayload({ ...payload, isSelect: type });
  };

  const handleSaveUpdate = async () => {
    const response = await userInstance().post('/automaticInvoice/addUpdateDayTimeTemplate', payload);
    const {
      code,
    } = response.data;
    if (code === 200) {
      toastMessage('success', 'Email & SMS Sending Days and Times updated successfully');
      getSettingData();
      seteditDays(false);
    }
  };
  useEffect(() => {
    if (timescheduled) {
      const {
        days, startTime, endTime, daysList,
      } = timescheduled || {};
      let copyPayload = { ...payload };
      copyPayload.days = days;
      copyPayload.daysList = daysList;
      copyPayload.startTime = startTime;
      copyPayload.endTime = endTime;
      setPayload(copyPayload);
    }
  }, [timescheduled]);

  return (
    <div className="email-sms-sending search-invoice pool-set-up">
      <div className="target-heading client-profile">
        <div className="">
          <h3>
            Email & SMS Sending Days and Times
          </h3>
        </div>
      </div>
      <div className="sending-days-section">
        <div className="top-heading">
          <h6>Sending Days & Times</h6>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={handleEditdays}>
                      <span>Edit</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="days-description">
          {
            allSettingData?.timescheduled?.daysList?.length && allSettingData?.timescheduled?.daysList.map((el) => {
              const { endTime, startTime } = allSettingData?.timescheduled;
              return (
                <div className="days-description-list">
                  <h6>
                    {el}
                  </h6>
                  <span>
                    {startTime}
                    {' '}
                    to
                    {' '}
                    {endTime}
                  </span>
                </div>
              );
            })
        }

        </div>

      </div>
      <EditDaysTime show={editDays} handleclose={handleEditdays} timePicker={timePicker} setTimePicker={setTimePicker} handleTimeRepeat={handleTimeRepeat} handleTimePicker={handleTimePicker} payload={payload} setPayload={setPayload} handleSaveUpdate={handleSaveUpdate} />
    </div>
  );
};
EmailSMSSending.propTypes = {
  getSettingData: PropTypes.func.isRequired,
  allSettingData: PropTypes.objectOf.isRequired,
};
export default EmailSMSSending;
