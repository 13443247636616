import React, { useEffect, useState } from 'react';
import TimeKeeper from 'react-timekeeper';
import { Modal, Button, ToggleButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

const TimePicker = ({
  handleTimePicker, timepicker, setPayload, payload,
}) => {
  const { startTime, endTime, isSelect } = payload || {};
  const [timeType, setType] = useState('hour');
  const [finalTime, setTime] = useState();
  const handleTime = (selectedTime) => {
    const time = moment(selectedTime.formatted24, ['HH:mm']).format('h:mm A');
    updateCss(
      selectedTime.formattedSimple.split(':')[timeType === 'hour' ? 0 : 1],
    );
    setTime(time);
  };
  const updateCss = (time) => {
    const elements = document.getElementsByClassName('css-1ddlhy');
    // console.log('elements', elements);
    Array.from(elements).forEach((el) => {
      let color = 'black';
      console.log('el', el.innerText);
      if (el.innerText === time.toString()) {
        color = 'white';
      }
      el.style.color = color;
    });
  };
  const updateColor = () => {
    const time = moment(finalTime, ['HH:mm']).format('h:mm A');
    updateCss(parseInt(time.split(':')[0], 10));
  };

  const handlePickChange = (info) => {
    setTimeout(() => {
      setType(info);
      const time = moment(finalTime, ['HH:mm']).format('h:mm A');
      console.log('before time', time);
      updateCss(time.split(':')[timeType === 'hour' ? 0 : 1]);
    }, 250);
  };

  useEffect(() => {
    updateColor();
    if (isSelect === 'start') {
      setTime(startTime);
    }
    if (isSelect === 'end') {
      setTime(endTime);
    }
  }, []);
  const handleTimepickerSet = () => {
    if (isSelect === 'start') {
      setPayload({ ...payload, startTime: finalTime });
    }
    if (isSelect === 'end') {
      setPayload({ ...payload, endTime: finalTime });
    }
    handleTimePicker();
  };
  return (
    <Modal
      size="md"
      show={timepicker}
      onHide={handleTimePicker}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="time-picker-modal"
    >
      <Modal.Body>
        <div className="time-keeper-popup">
          <div className="time-keeper">
            <TimeKeeper
              time={finalTime}
              onChange={(data) => handleTime(data)}
            />
          </div>
          <div className="time-keeper-btn">
            <Button onClick={() => handleTimepickerSet()}>Save/Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
};
TimePicker.propTypes = {
  handleTimePicker: PropTypes.func.isRequired,
  timepicker: PropTypes.bool.isRequired,
  payload: PropTypes.objectOf.isRequired,
  setPayload: PropTypes.func.isRequired,
};
export default TimePicker;
