import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const TemplateFeatures = createContext();

const TemplateFeaturesProvider = (props) => {
  const { children } = props;
  const [dummyView, setDummyView] = useState('');
  const [dummyMainPageView, setDummyMainPageView] = useState('');
  const [isNewChange, setIsNewChange] = useState(false);
  const [saveTemplateConfirmPopup, setSaveTemplateConfirmPopup] = useState(false);
  const [saveConfirmPopupValue, setSaveConfirmPopupValue] = useState('save-before-leave');
  const [isParentClickDropdown, setIsParentClickDropdown] = useState(false);
  const [saveTemplateSelectedDropdown, setSaveTemplateSelectedDropdown] = useState('');
  const [savePopup, setSavePopup] = useState();
  const [defaulttype, setDefaulttype] = useState();
  const [view, setView] = useState('InvoiceTemplate');
  const [quoteView, setQuoteView] = useState('QuoteTemplate');
  const [templateName, setTemplateName] = useState('');
  const [mainView, setMainView] = useState('InvoiceTemplate');

  const handleConfirmPopupSelect = (isMain = false, setSaveTemp) => {
    if (saveConfirmPopupValue === 'save-before-leave') {
      handlesavePopup();
    } else {
      setIsNewChange(false);
      setSaveConfirmPopupValue('save-before-leave');
      if (saveTemplateSelectedDropdown) {
        setSaveTemp(saveTemplateSelectedDropdown);
      } else if (isMain) {
        setMainView(dummyMainPageView);
      } else if (mainView === 'QuoteTemplate') {
        setQuoteView(dummyView);
      } else {
        setView(dummyView);
      }
      setIsParentClickDropdown(false);
    }
    setSaveTemplateConfirmPopup(false);
  };
  const handleSaveConfirmPopupChange = (value) => {
    setSaveConfirmPopupValue(value);
  };

  const handlesavePopup = (type) => {
    setSavePopup(!savePopup);
    setDefaulttype(type);
    if (view === 'addTemplate') {
      setTemplateName('');
    }
  };

  return (
    <TemplateFeatures.Provider value={{
      dummyView,
      setDummyView,
      dummyMainPageView,
      setDummyMainPageView,
      isNewChange,
      setIsNewChange,
      saveTemplateConfirmPopup,
      setSaveTemplateConfirmPopup,
      saveConfirmPopupValue,
      setSaveConfirmPopupValue,
      handleConfirmPopupSelect,
      handleSaveConfirmPopupChange,
      isParentClickDropdown,
      setIsParentClickDropdown,
      saveTemplateSelectedDropdown,
      setSaveTemplateSelectedDropdown,
      savePopup,
      setSavePopup,
      defaulttype,
      setDefaulttype,
      view,
      setView,
      quoteView,
      setQuoteView,
      templateName,
      setTemplateName,
      handlesavePopup,
      mainView,
      setMainView,
    }}
    >
      {children}
    </TemplateFeatures.Provider>
  );
};

TemplateFeaturesProvider.propTypes = {
  children: PropTypes.func.isRequired,
};
export default TemplateFeaturesProvider;
