import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import { addZero, queryString, getCalendarPath } from '../../functions/index';
import showmore from '../../assets/home/showmoreblue.png';
import target from '../../assets/home/icon/target.png';
import { userInstance } from '../../config/axios';
import arrow from '../../assets/home/arrowdown.png';
import ViewAppointment from '../appointment/view-appointment';
import Quotes from '../invoices/quotes';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const PastFutureBooking = () => {
  const history = useHistory();
  const {
    client, routeType, name, type, back, skipValue, page,
  } = queryString();
  const { userData: { userPermissions } } = useContext(AuthDataContext);
  const [appoinments, setAppointments] = useState([]);
  const [bookingTime, setBookingTime] = useState(type || 'Past Bookings');
  const timeFromDateFormat = 'HH:mm';
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [appointmentDetail, setAppointmentDetail] = useState();
  const [appointmentData, setAppointmentData] = useState([]);
  const [addedServices, setAddedServices] = useState([]);
  const [customerDues, setCustomerDues] = useState(0);
  // const limit = 4;
  const [eventDate, setEventDate] = useState('');
  const [limit, setLimit] = useState(4);
  const [selectedPage, setSelectedPage] = useState(1);
  const getAppointments = async (info) => {
    try {
      // console.log('limitlimit=>', limit, skip);
      let infotype = (info === 'quotes' || info === 'Quotes') ? 'Quotes' : (info === 'futurebooking' || info === 'Future Bookings') ? 'Future Bookings' : 'Past Bookings';
      setBookingTime(infotype);
      const response = await userInstance().get(`/appointment/getAllAppointments?limit=${limit}&skip=${skip}&info=${infotype}&client=${client}`);
      const { appointments, count } = response.data;
      setPageCounts(count);
      setAppointments(appointments);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  useEffect(() => {
    getAppointments(bookingTime);
  }, [skip, limit]);

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  const setBookingDuration = (info1) => {
    setSkip(0);
    setLimit(4);
    setPageCounts(1);
    setBookingTime(info1);
    getAppointments(info1);
  };

  const pageCount = Math.ceil(pageCounts / limit);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };

  const handleAppointmentDetails = () => {
    setAppointmentDetail(!appointmentDetail);
  };

  const handleAppointmentDetail = async (info, curDate) => {
    setEventDate(curDate);
    const response = await userInstance().get(
      `/appointment/getAppointmentById?id=${info}`,
    );
    const { appointmentdetail, msg, totalBalanceDue } = response.data;
    // console.log({ appointmentdetail, msg, totalBalanceDue });
    if (msg === 'Success') {
      setAppointmentData(appointmentdetail);
      setCustomerDues(parseFloat(totalBalanceDue).toFixed(2));
      setAddedServices(appointmentdetail.services);
      handleAppointmentDetails();
    }
  };
  const backToAppointment = (types) => {
    if (routeType === 'client' || routeType.includes('edit')) {
      if (!back) {
        history.push(`/client?clientid=${client}&name=${name}&typeRoute=${routeType}&skipValuee=${skipValue}`);
      } else {
        history.push(`/client?clientid=${client}&name=${name}&typeRoute=${routeType}&back=${back}`);
      }
    } else {
      history.push(`${getCalendarPath()}&clientid=${client}`);
    }
    if (types === 'Quotes') {
      setBookingTime('Past Bookings');
    }
  };
  return (
    <>
      {bookingTime !== 'Quotes' ? (
        <AdminWrapper>
          <div className={`appointment-container booking-page pool-set-up ${bookingTime === 'Past Bookings' ? 'past-bookings' : 'future-bookings'}`}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {client && (
                  <div className="back-option">
                    <span />
                    <i className="fa fa-times" onClick={() => backToAppointment(page)} aria-hidden="true" />
                  </div>
                  )}
                  <div className="main-titles-mob">
                    <h3>Quotes, Past & Future Bookings</h3>
                  </div>
                  <div className="target-heading">
                    <h6>{bookingTime}</h6>
                    <div className="content-dropdown">
                      <div className="client-option service-edit-icon">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={showmore} alt="show" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setBookingDuration('Past Bookings')}>
                              <div className="action">
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span>Past Bookings</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setBookingDuration('Future Bookings')}>
                              <div className="action">
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span>Future Bookings</span>
                              </div>
                            </Dropdown.Item>
                            {userPermissions.includes('Quotes') && (
                            <Dropdown.Item onClick={() => setBookingDuration('Quotes')}>
                              <div className="action">
                                <span>
                                  <img src={target} alt="edit" />
                                </span>
                                <span>Quotes</span>
                              </div>
                            </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                  </div>

                  <div className="appointment-view">
                    {appoinments && appoinments.map((el) => (
                      <>
                        {el.dateData && el.dateData.map((item) => (
                          <div className="add-appointment" onClick={() => handleAppointmentDetail(el._id, item.date)}>
                            <div className="search-title">
                              <div className="booking-date-section">
                                <div className="booking-date past-date">
                                  {moment(item.date).format('D MMM')}
                                  {' '}
                                  <br />
                                  <span>{moment(item.date).format('YYYY')}</span>
                                </div>

                                <div className="client-profile">
                                  {item.service.map((ser) => (
                                    <>
                                      <h3>
                                        {`${ser.category.toLowerCase().includes('misc')
                                          ? `${ser.customMisc} $${addZero(ser.renderCost)}`
                                          : ser.subcategory
                                        } ${parseFloat(ser.quantity) > 1
                                          ? `($${`${addZero(ser.cost)}${ser.soldPer === 'Kilogram (kg)' ? ' per kg' : ''}`} x ${`${ser.type === 'service' && ser.pricingType === 'hourly'
                                            ? convertToReadableDuration(ser.quantity) : `${ser.soldPer === 'Kilogram (kg)' ? `${ser.quantity}kg` : ser.quantity}`}${ser.type === 'service' ? ' ' : ''
                                          })`}`
                                          : ''
                                        }`}
                                      </h3>
                                      <p>
                                        <span>{moment(ser.startTime, [timeFromDateFormat]).format('h:mm a')}</span>
                                        {/* {console.log('test', addQuantityDuration(convertToReadableDuration(el.duration), parseFloat(el.quantity)))} */}
                                        <span> | </span>
                                        <span>{` ${convertToReadableDuration(ser.duration)}`}</span>
                                        <span> | </span>
                                        <span>{` with ${ser.staffName}`}</span>
                                        <span> | </span>
                                      </p>
                                      <p>
                                        <span>
                                          {`${ser.type === 'product'
                                            ? `Margin $${addZero(ser.laborMargin)}`
                                            : ser.type === 'service'
                                              ? `Labour $${`${addZero(ser.totalLabour)}`}`
                                              : null
                                          }`}
                                        </span>
                                      </p>
                                    </>
                                  ))}
                                  {/* {bookingTime === 'Past Bookings' ? (
                                    <p className="completed-booking">Completed</p>
                                  ) : (
                                    <p className="completed-booking">Booked Appointment</p>
                                  )} */}
                                  {el.status === 'Completed' ? (
                                    <p className="completed-booking"><span className="appcompleted">Completed</span></p>
                                  ) : el.status === 'Cancelled' ? (
                                    <p className="completed-booking "><span className="appcancelled">Cancelled</span></p>
                                  )
                                    : <p className="completed-booking "><span className="appbooked">Booked</span></p>}
                                </div>
                              </div>
                              <div className="sorting-sub-services appointment-sort">
                                <div
                                  className="content-dropdown"
                                  onClick={() => handleAppointmentDetail(el._id)}
                                >
                                  <Dropdown name="startingDate">
                                    <Dropdown.Toggle variant="link" id="dropdown-basic">
                                      <img src={arrow} alt="arrow" />
                                    </Dropdown.Toggle>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>

                        ))}
                      </>

                    ))}

                    {/* <span className="sep" />
                <div className="add-appointment">
                  <div className="search-title">
                    <div className="booking-date-section">
                      <div className="booking-date past-date">
                        31 Jan
                        {' '}
                        <br />
                        <span>2018</span>
                      </div>

                      <div className="client-profile">
                        <h3>
                          Pool Cleaning Service $120.00
                        </h3>
                        <p>
                          <span>9:30 am</span>
                          {console.log('test', addQuantityDuration(convertToReadableDuration(el.duration), parseFloat(el.quantity)))}
                          <span> | </span>
                          <span> 5hr</span>
                          <span> | </span>
                          <span> with Shivang Chauhan</span>
                          <span> | </span>
                        </p>
                        <p>
                          <span>
                            Labour $120.00
                          </span>
                        </p>
                        <p className="completed-booking">Completed</p>
                      </div>
                    </div>
                    <div className="sorting-sub-services appointment-sort">
                      <div
                        className="content-dropdown"
                      >
                        <Dropdown name="startingDate">
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <img src={arrow} alt="arrow" />
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="sep" />
                <div className="add-appointment">
                  <div className="search-title">
                    <div className="booking-date-section">
                      <div className="booking-date past-date">
                        31 Jan
                        {' '}
                        <br />
                        <span>2018</span>
                      </div>

                      <div className="client-profile">
                        <h3>
                          Pool Cleaning Service $120.00
                        </h3>
                        <p>
                          <span>9:30 am</span>
                          {console.log('test', addQuantityDuration(convertToReadableDuration(el.duration), parseFloat(el.quantity)))}
                          <span> | </span>
                          <span> 5hr</span>
                          <span> | </span>
                          <span> with Shivang Chauhan</span>
                          <span> | </span>
                        </p>
                        <p>
                          <span>
                            Labour $120.00
                          </span>
                        </p>
                        <p className="completed-booking">Completed</p>
                      </div>
                    </div>
                    <div className="sorting-sub-services appointment-sort">
                      <div
                        className="content-dropdown"
                      >
                        <Dropdown name="startingDate">
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <img src={arrow} alt="arrow" />
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="sep" />
                <div className="add-appointment">
                  <div className="search-title">
                    <div className="booking-date-section">
                      <div className="booking-date past-date">
                        31 Jan
                        {' '}
                        <br />
                        <span>2018</span>
                      </div>

                      <div className="client-profile">
                        <h3>
                          Pool Cleaning Service $120.00
                        </h3>
                        <p>
                          <span>9:30 am</span>
                          {console.log('test', addQuantityDuration(convertToReadableDuration(el.duration), parseFloat(el.quantity)))}
                          <span> | </span>
                          <span> 5hr</span>
                          <span> | </span>
                          <span> with Shivang Chauhan</span>
                          <span> | </span>
                        </p>
                        <p>
                          <span>
                            Labour $120.00
                          </span>
                        </p>
                        <p className="completed-booking">Completed</p>
                      </div>
                    </div>
                    <div className="sorting-sub-services appointment-sort">
                      <div
                        className="content-dropdown"
                      >
                        <Dropdown name="startingDate">
                          <Dropdown.Toggle variant="link" id="dropdown-basic">
                            <img src={arrow} alt="arrow" />
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div> */}
                    <div className="user-pagination">
                      <ReactPaginate
                        previousLabel="← Previous"
                        nextLabel="Next →"
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName="pagination-chk"
                        previousLinkClassName="pagination__link"
                        nextLinkClassName="pagination__link"
                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                        breakLabel="..."
                        marginPagesDisplayed={-2}
                        pageRangeDisplayed={0}
                      />
                      <div className="no-of-page">
                        Page
                        {' '}
                        {selectedPage}
                        {' '}
                        of
                        {' '}
                        {' '}
                        {pageCount}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <ViewAppointment
                appointmentDetail={appointmentDetail}
                setAppointmentDetail={setAppointmentDetail}
                handleAppointmentDetails={handleAppointmentDetails}
                appointmentData={appointmentData}
                addedServices={addedServices}
                setAddedServices={setAddedServices}
                customerDues={customerDues}
                eventDate={eventDate}
              />
            </div>
          </div>
        </AdminWrapper>
      ) : ''}
      {bookingTime === 'Quotes' && bookingTime !== 'Past Bookings' && bookingTime !== 'Future Bookings' ? (
        <Quotes
          setBookingTime={setBookingTime}
        />
      ) : ''}
    </>
  );
};
export default PastFutureBooking;
