import React, { useState, useEffect } from 'react';
import {
  Tabs, Tab, Form, Button, Dropdown,
} from 'react-bootstrap';
import target from '../../assets/home/icon/target.png';
import showmore from '../../assets/home/showmoreblue.png';
import AccountingXeroSoftware from './accountingSoftware/accountingXero';
import MYOBSoftware from './accountingSoftware/myobSoftware';
import QuickBooks from './accountingSoftware/quickBooks';

const AccountingSoftware = () => {
  const [view, setView] = useState('Connect to Xero');
  const updateView = (key) => {
    setView(key);
  };

  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case 'Connect to Xero':
        componentToRender = (
          <AccountingXeroSoftware />
        );

        break;
      case 'Connect to MYOB':
        componentToRender = (
          <MYOBSoftware />
        );
        break;

      case 'Connect to QuickBooks':
        componentToRender = (
          <QuickBooks />
        );
        break;
      default:
        break;
    }
    return componentToRender;
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="main-titles-mob xero-page-connect">
          <h3>Connect Your Accounting Software</h3>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Connect to Xero')}>
                      <span>
                        <img src={target} alt="edit" />
                      </span>
                      <span>Connect to Xero</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Connect to MYOB')}>
                      <span>
                        <img src={target} alt="edit" />
                      </span>
                      <span>Connect to MYOB</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Connect to QuickBooks')}>
                      <span>
                        <img src={target} alt="edit" />
                      </span>
                      <span>Connect to QuickBooks</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        {renderView()}

      </div>
    </div>
  );
};

export default AccountingSoftware;
