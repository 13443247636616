import React, {
  useEffect, useRef, useReducer, useState,
} from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import StartEndDate from './startEndDate';
import {
  filterAppointmentObj,
} from '../../functions/index';
import { userInstance } from '../../config/axios';
import crosss from '../../assets/home/black-icon.png';
import sortClientList from '../../functions/listSort';
import search from '../../assets/home/search.png';

const LogActivity = () => {
  const initialfilterObj = {
    reminderType: 'invoiceOnly',
    customerId: 'allCustomer',
    locationId: 'allLocation',
    teammemberId: 'allTeam',
    datetype: 'today',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
  };
  // set the innitial filter obj to the state
  const [filterObj, setFilterObj] = useState(initialfilterObj);
  // state to open and close the date popup.
  const [datePopup, setDatePopup] = useState();
  // keeping track of the date Range only when the date poup is open else everything will work from filter obj
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });

  // get the list of all Customers
  const [customerData, setCustomerData] = useState([]);
  // get the list of all team Members
  const [teamMemberData, setTeamMemberData] = useState([]);
  // get the list of all locations
  const [locationData, setLocationData] = useState([]);

  // the loading data State
  const [loadingData, setLoadingData] = useState(false);
  // filter status state to trigger an API for fetch after ever drop down change
  const [filterStatus, setfilterStatus] = useState('');
  // state to show App Summary Date onto the screen
  const [appsummaryDate, setappsummaryDate] = useState();
  // display the data onto the screen
  const [dataList, setdataList] = useState([]);

  // **Date Modal functions START ** //
  // function for closing the date poup
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  // date Change on the Date Poup
  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };

  const handleDateRange = (start, end) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.rangeStartDate = start;
    copyfilterObj.rangeEndDate = end;
    setFilterObj(copyfilterObj);
    // call the API
    setfilterStatus(start);
    // close the date Popup
    setDatePopup(false);
  };
    // **Date Modal functions END ** //

  // On Change Function in orde to change the DropDown States
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    if (name === 'teammemberId' && value !== 'allTeam') {
      // if a specific team member is selected then set the Location of that Team Member so that it can be put in the query inside the APi
      const getLocationId = teamMemberData.filter((el) => el._id === value);
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = value;
      copyfilterObj.locationId = getLocationId[0].location;
      setFilterObj(copyfilterObj);
      setfilterStatus(value);
    } else if (name === 'locationId' && value === 'allLocation') {
      // all Location is selected then reset the Team Member to All
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = 'allTeam';
      copyfilterObj.locationId = value;
      setFilterObj(copyfilterObj);
      setfilterStatus(value);
    } else {
      // here reminder Type will be set
      // customer Id will be set
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
      setfilterStatus(value);
    }
  };

  //* *Fetching data thorugh API for Location , Team Members , Customers STARTS**//
  // get Location List
  useEffect(() => {
    (async () => {
      const response = await userInstance().get(
        '/appointment/getLocationList',
      );
      const { locationList, code } = response.data;
      locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
      if (code === 200) {
        setLocationData([...locationList]);
      }
    })();
  }, []);

  // get Team Member List
  useEffect(() => {
    (async () => {
      const response = await userInstance().get(
        '/appointment/getTeamMemberList',
      );
      const { teamList, code } = response.data;
      teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
      if (code === 200) {
        setTeamMemberData(teamList);
      }
    })();
  }, []);
  // get All Customers
  useEffect(() => {
    (async () => {
      const response = await userInstance().get(
        '/appointment/filterCustomer',
      );
      const { customers, code } = response.data;
      const customerFilter = customers.filter((el) => el?.firstname);
      customerFilter.sort((a, b) => a?.firstname.localeCompare(b?.firstname));
      if (code === 200) {
        setCustomerData(customers);
      }
    })();
  }, []);
  //* *Fetching data thorugh API for Location , Team Members , Customers ENDS**//

  // Main get Payment API function through which data will extracted from the server API
  const getPaymentFilter = async () => {
    const filterDateObj = filterAppointmentObj(filterObj);
    // console.log('this is the filter Data Obj', filterDateObj);
    // setting the state for the summary Date
    setappsummaryDate(filterDateObj.summaryDate);
    const filterTeamMemberId = filterObj.teammemberId !== 'allTeam'
      ? [filterObj.teammemberId]
      : filterObj.locationId === 'allLocation'
        ? teamMemberData.map((el) => el._id)
        : teamMemberData.filter((el) => el.location === filterObj.locationId).map((el) => el._id);
    const payload = {
      filterObj,
      filterDateObj,
      teamMemberIds: filterTeamMemberId,
    };
    setLoadingData(true);
    // console.log('this is the payload', payload);
    const response = await userInstance().post('/reports/getOriginalInvoices', payload);
    // console.log('this is the response', response);

    setLoadingData(false);
    const { queryData } = response.data;
    if (filterObj.reminderType === 'invoiceOnly') {
      queryData.sort((a, b) => a.customer.firstname.localeCompare(b.customer.firstname));
    }
    setdataList(queryData);
  };

  // MAIN DATA FETCHING API
  useEffect(() => {
    getPaymentFilter();
  }, [filterStatus]);

  return (
    <div className="log-activity-page">
      <div className="appointment-summary">
        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Invoice or Reminder</Form.Label>
          <div className="select-box">
            <Form.Control as="select" name="reminderType" value={filterObj.reminderType} onChange={(e) => handleOnchange(e.target)}>
              <option value="invoiceOnly">Original Invoices Only</option>
              <option value="paymentOnly">Payment Reminders Only</option>
              <option value="paymentOnly">Invoices & Payment Reminders</option>
            </Form.Control>
          </div>
        </Form.Group>
        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Clients</Form.Label>
          <div className="select-box">
            <Form.Control as="select" name="customerId" value={filterObj.customerId} onChange={(e) => handleOnchange(e.target)}>
              <option value="allCustomer">All Customers</option>
              {customerData.map((el) => <option value={el?._id}>{`${el?.firstname} ${el?.lastname}`}</option>)}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Location</Form.Label>
          <div className="select-box">
            <Form.Control as="select" name="locationId" value={filterObj.locationId} onChange={(e) => handleOnchange(e.target)}>
              <option value="allLocation">All Locations</option>
              {locationData.map((el) => <option value={el._id}>{el?.locationName}</option>)}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Team Member</Form.Label>
          <div className="select-box">
            <Form.Control as="select" name="teammemberId" value={filterObj.teammemberId} onChange={(e) => handleOnchange(e.target)}>
              <option value="allTeam">All Team Members</option>
              {teamMemberData.map((el) => (
                <>
                  {filterObj.locationId === 'allLocation'
                    ? <option value={el._id}>{`${el?.firstname} ${el?.lastname}`}</option>
                    : filterObj?.locationId === el?.location
                     && <option value={el._id}>{`${el?.firstname} ${el.lastname}`}</option>}
                </>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicCompanyType">
          <Form.Label>Date Range</Form.Label>
          <div className="select-box">
            <Form.Control
              as="select"
              name="datetype"
              value={filterObj.datetype}
              onChange={(e) => handleOnchange(e.target)}
            >
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="tomorrow">Tomorrow</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="year">This Year</option>
              <option value="lastweek">Last Week</option>
              <option value="lastyear">Last Year</option>
              <option value="nextweek">Next Week</option>
              <option value="nextyear">Next Year</option>
              <option value="weekdate">Week to Date</option>
              <option value="monthdate">Month to Date</option>
              <option value="yeardate">Year to Date</option>
              <option value="daterange">Date Range</option>
            </Form.Control>
          </div>
        </Form.Group>
        {filterObj.datetype === 'daterange' ? (
          <div className="start-date-section">
            <Form.Group className="search-box">
              <div className="custom-datepicker" onClick={handleDatePopup}>
                {`${moment(filterObj?.rangeStartDate).format('ddd DD MMM yyyy')}`}
              </div>
            </Form.Group>
            <span>to</span>
            <Form.Group className="search-box">
              <div className="custom-datepicker">
                {' '}
                {`${moment(filterObj?.rangeEndDate).format('ddd DD MMM yyyy')}`}
              </div>
            </Form.Group>
          </div>
        ) : (
          ''
        )}
        <div className="appointment-summary-section">
          <p>Display Results showing sent detail</p>
          <p>{appsummaryDate}</p>
          <hr />
          <div className="summary-table">
            <div className="summary-table-header">
              {filterObj.reminderType === 'invoiceOnly' && <h6>Date/Time</h6>}
              <h6>Clients</h6>
              {filterObj.reminderType === 'invoiceOnly' && <h6>Invoice</h6>}
              {filterObj.reminderType === 'paymentOnly' && <h6>Reminder</h6>}
              <h6>Amount</h6>
            </div>
            {filterObj.reminderType === 'invoiceOnly' && dataList && dataList.length > 0 && dataList.map((el) => (
              <>
                <div className="summary-table-body">
                  <p>{moment(el.updatedAt).format('D MMM YYYY')}</p>
                  {el && el?.customer && (
                  <p>
                    {el?.customer?.firstname}
                    {' '}
                    {el?.customer?.lastname}
                  </p>
                  )}
                  <p>{el.invoice?.invoiceNo}</p>
                  <p>
                    $
                    {el.amount}
                  </p>
                </div>

              </>
            ))}

            {filterObj.reminderType === 'paymentOnly' && dataList && dataList.length > 0 && dataList.map((el) => (
              <>
                <div className="summary-table-body">
                  {el && el?._id && (
                  <p>
                    {el?._id}
                  </p>
                  )}
                  <p>{el?.reminder}</p>
                  <p>
                    $
                    {el?.totalAmount}
                  </p>
                </div>

              </>
            ))}

          </div>
        </div>
        <StartEndDate datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} handleDateOnchange={handleDateOnchange} dateRange={dateRange} handleDateRange={handleDateRange} />
      </div>
    </div>
  );
};
export default LogActivity;
