import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './xero.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const XeroRedirect = () => {
  let history = useHistory();
  const [count, setCount] = useState(1);

  const getTriggered = async (url) => {
    setCount((prev) => prev + 1);
    try {
      const response = await userInstance().post('/xero/redirectURL', { url });
      // console.log('response', response);
      if (response.data.connect === true) {
        toastMessage('success', 'Successfully Connected to Xero');
        setTimeout(() => {
          history.push('/xero');
        }, 5000);
        // localStorage.setItem('xeroConnect', 'true');
      } else {
        toastMessage('error', 'Not Connected! Please try again.');

        // localStorage.setItem('xeroConnect', 'false');
      }
    } catch (err) {
      console.log('This is the error', err);
      toastMessage('error', 'Not Connected! Please try again.');
      // localStorage.setItem('xeroConnect', 'false');
    }
  };

  useEffect(() => {
    if (count === 1) {
      const url = window.location.href;
      getTriggered(url);
    }
  }, []);
  return (
    <AdminWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="appointment-view xero-box xer-success-box">
              <h2>Successfully Connected to Xero</h2>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};
export default XeroRedirect;
