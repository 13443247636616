import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Modal, Button, Form, Dropdown,
} from 'react-bootstrap';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';

const AddCompanyLocation = ({
  showForm, handleFormModal, selectedCountry, locationData, handleLocationInformation, handleCountry, saveLocation, type, error,
}) => (
  <Modal
    size="lg"
    show={showForm}
    onHide={handleFormModal}
    aria-labelledby="contained-modal-title-vcenter"
    top
    className="staff-modal"
  >
    <div className="staff-close">
      <i className="fa fa-times" onClick={handleFormModal} aria-hidden="true" />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        {type === 'add' ? 'Add Company Location' : 'Edit Company Location'}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="staff-form">
        <Form>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicCompanyType">
                <Form.Label>
                  Location Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="locationName"
                  value={locationData.locationName}
                  onChange={(e) => handleLocationInformation(e)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-5" className="italic-text">
                <Form.Label>
                  Main  Contact&apos;s First Name
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={locationData.firstname}
                  onChange={(e) => handleLocationInformation(e)}
                  placeholder="Enter Main Contact's First Name"
                  className={`${
                    error === 'firstname'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-6" className="italic-text">
                <Form.Label>
                  Main Contact&apos;s Last Name
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={locationData.lastname}
                  placeholder="Main Contact's Last Name"
                  onChange={(e) => handleLocationInformation(e)}
                  className={`${
                    error === 'lastname'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-7" className="number italic-text">
                <Form.Label>
                  Main  Contact&apos;s Mobile No.
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <div className="number-field">
                  {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                  <div className="number-box">
                    <Dropdown id="mobile">
                      <Dropdown.Toggle variant="success" id="dropdown-basic1">
                        <div className="flag-icon">
                          <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                        </div>
                        {selectedCountry === '+61' ? (
                          <span>
                            Australia
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : selectedCountry === '+1' ? (
                          <span>
                            United States
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : ''}
                        <span>
                          <i className="fas fa-caret-down" />
                        </span>
                        {/* <div className="country-code">{selectedCountry}</div> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                          <div className="flag-icon">
                            <img src={flag} alt="flag" />
                          </div>
                          <div className="country-code">
                            <span>Australia</span>
                            +61
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                          <div className="flag-icon">
                            <img src={us} alt="flag" />
                          </div>
                          <div className="country-code">
                            {' '}
                            <span>United States</span>
                            +1
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NumberFormat
                    // className="form-control mobile-num"
                    className={`form-control mobile-num ${
                      error === 'mobile'
                        ? 'error-text'
                        : ''
                    }`}
                    format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                    name="mobile"
                    value={locationData.mobile}
                    placeholder="Enter Main Contact's Mobile No."
                    onChange={(e) => handleLocationInformation(e)}
                    autocomplete="off"
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-8" className="number">
                <Form.Label>
                  Main Contact&apos;s Landline No.
                </Form.Label>
                <div className="number-field">
                  {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                  <div className="number-box">
                    <Dropdown id="mobile">
                      <Dropdown.Toggle variant="success" id="dropdown-basic1">
                        <div className="flag-icon">
                          <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                        </div>
                        {selectedCountry === '+61' ? (
                          <span>
                            Australia
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : selectedCountry === '+1' ? (
                          <span>
                            United States
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : ''}
                        <span>
                          <i className="fas fa-caret-down" />
                        </span>
                        {/* <div className="country-code">{selectedCountry}</div> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                          <div className="flag-icon">
                            <img src={flag} alt="flag" />
                          </div>
                          <div className="country-code">
                            <span>Australia</span>
                            +61
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                          <div className="flag-icon">
                            <img src={us} alt="flag" />
                          </div>
                          <div className="country-code">
                            {' '}
                            <span>United States</span>
                            +1
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NumberFormat
                    // className="form-control mobile-num"
                    className={`form-control mobile-num ${
                      error === 'landline'
                        ? 'error-text'
                        : ''
                    }`}
                    format="### ### ###"
                    name="landline"
                    autocomplete="off"
                    value={locationData.landline}
                    onChange={(e) => handleLocationInformation(e)}
                  />
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-9" className="italic-text">
                <Form.Label>
                  Main Contact&apos;s Email Address
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={locationData.email}
                  onChange={(e) => handleLocationInformation(e)}
                  placeholder="Enter Main Contact's Email Address"
                  className={`${
                    error === 'email'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-9">
                <Form.Label>
                  Main  Contact&apos;s Street Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="streetAddress"
                  value={locationData.streetAddress}
                  onChange={(e) => handleLocationInformation(e)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-9">
                <Form.Label>
                  Location Suburb
                </Form.Label>
                <Form.Control
                  type="text"
                  name="suburb"
                  value={locationData.suburb}
                  onChange={(e) => handleLocationInformation(e)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-9">
                <Form.Label>
                  Location Postcode
                </Form.Label>
                <Form.Control
                  type="number"
                  name="postcode"
                  value={locationData.postcode}
                  onChange={(e) => handleLocationInformation(e)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-9">
                <Form.Label>
                  State
                </Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={locationData.state}
                  onChange={(e) => handleLocationInformation(e)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="form-radio" onChange={(e) => handleLocationInformation(e)} name="gender">
                <Form.Check
                  type="radio"
                  label="Male"
                  name="gender"
                  id="male"
                  checked={locationData.gender === 'male'}
                />
                <Form.Check
                  type="radio"
                  label="Female"
                  name="gender"
                  id="female"
                  checked={locationData.gender === 'female'}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="note"
                  value={locationData.note}
                  onChange={(e) => handleLocationInformation(e)}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn">
                <Button
                  onClick={handleFormModal}
                  className="btn btn-edit"
                >
                  {' '}
                  Cancel
                  {' '}
                </Button>
                <Button
                  className="btn btn-save"
                  onClick={(e) => saveLocation(e)}
                >
                  {' '}
                  Save/Update
                  {' '}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>

    </Modal.Body>
  </Modal>

);
AddCompanyLocation.propTypes = {
  showForm: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string.isRequired,
  locationData: PropTypes.objectOf.isRequired,
  handleLocationInformation: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  saveLocation: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};
export default AddCompanyLocation;
