/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useContext, useCallback,
} from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { NotificationContainer } from 'react-notifications';
import { ToastContainer } from 'react-toastify';
import Home from './components/home/index';
import Login from './components/login/login';

import Contact from './components/contact/contact';
import Calculator from './components/calculator/calculator';
import XeroComponent from './components/xero/xero';
import XeroRedirect from './components/xero/xeroRedirect';
import QuickBookRedirect from './components/xero/quickBookRedirect';
import Subscription from './components/subscription/subscription';
import MyAccount from './components/myaccount/account';
import TestHistory from './components/watertesthistory/professionaluser';
import Setting from './components/settings/setting';
import PoolSetUp from './components/poolsetup/poolsetup';
import ChemicalPricing from './components/chemicalpricing/chemicalpricing';
import ChlorinatorModel from './components/chlorinatormodels/chlorinatormodel';
import Staff from './components/staff/index';
import PermissionAccessLevel from './components/permissionlevels/permissionlevel';
import AppointmentSummary from './components/appointmentsummary/appointmentsummary';
import PermissionSubscriberAccess from './components/permissionlevels/permissionSubscriber';
import ProductServices from './components/prduct&services/product&services';

import About from './components/about/about';
import OnlineDemo from './components/onlinedemo/onlinedemo';
import ProductFeature from './components/productfeatures/productfeature';
import WaterChemistry from './components/waterchemistry/waterchemistry';
import GreenPools from './components/greenpools/greenpool';
import Osmosis from './components/poolosmosis/osmosis';
import FilterMaintenance from './components/filtermaintenance/filtermaintenance';
import Pricing from './components/pricing/pricing';
import BuyNow from './components/buynow/buynow';
import AdminDashboard from './components/admin/admindashboard/dashboard';
import UserRoute from './components/PrivateRoutes/User';
import LoginRoute from './components/PrivateRoutes/Login';
import history from './config/history';
import PoolChemistry from './components/poolchemistry/poolchemistry';
import { AuthDataContext } from './contexts/AuthDataContext';
import HeaderDataProvider from './contexts/HeaderContext';
// import CalendarDataProvider from './contexts/calendarContext';
import UpdatePassword from './components/updatepassword/updatepassword';
import NotAuthorized from './components/defaultpages/401';
import StaffCalendar from './components/calendar/calendar';
import Client from './components/clients/client';
// import InvoiceList from './components/invoices/invoicelist';
import { userInstance } from './config/axios';
import Invoice from './components/invoices/invoice';
import FinalizeVisitcost from './components/appointment/finalizeVisitCost';
import Awaitingapprovals from './components/appointment/awaitingSendapprovals';
import PastFutureBooking from './components/payments/pastFutureBooking';
import WaterTestHistory from './components/payments/waterTestHistory';
import PaymentReceived from './components/payments/paymentsRecieved';
import Quotes from './components/invoices/quotes';
import InvoicesPayment from './components/payments/invoicesPayments';
import ResetPassword from './components/login/resetPassword';
import CompleteAppointmentView from './components/appointment/completeappointmentview';
import AddAppointment from './components/appointment/addappointment';
import EquipmentDetails from './components/equipmentDetails/equipmentDetails';
import Template from './components/editInvoicceTemplate/template';
import Calendarsettings from './components/calendarSetting/calendarSetting';
import IndustryType from './components/industryType/industryType';
import 'react-notifications/lib/notifications.css';
// import InvoiceTemplate from './components/editInvoicceTemplate/invoiceTemplate';
import EditMainTemplate from './components/editInvoicceTemplate';
import CompanyLocation from './components/companyInformation/companyLocation';
import CompanyInfo from './components/companyInformation/companyInfo';
import Cal from './components/calendar/cal';
import Reports from './components/reports/reports';
import Inventory from './components/inventory/inventory';
import SubsPayment from './components/SubscribePayment';
import AutomatedPayment from './components/automatedPayment/automatedPayment';
import CSVUpload from './components/uploadCSV/csvUpload';
import TestStrip from './components/calculator/testStrip';
import SmsTemplate from './components/smsTemplate/smsTemplate';
import Profile from './components/profile/profile';
import NewPermissionLayout from './components/permissionlevels/newPermissionLevel';
import './style.css';
import './responsive.css';
import './mobile.css';
import './App.css';
import MyProfile from './components/myProfile/profile';
import TemplateFeaturesProvider from './contexts/TemplateFeatureContext';

  <script lang="javascript" src="dist/xlsx.full.min.js" />;

function App() {
  const {
    userData, setUserData, clientAllData,
    setClientAllData, setLocationInfo,
  } = useContext(AuthDataContext);
  const settinngUserDetais = () => {
    const data = localStorage.getItem('apptData');
    if (data) {
      const parseData = JSON.parse(data);
      setClientAllData(parseData);
    }
  };
  const validateToken = useCallback(async () => {
    try {
      localStorage.removeItem('firstLogin');
      if (localStorage.getItem('f#@5e')) {
        const response = await userInstance().get('/user/validatetoken');
        const { userdata, calendarSettings, userDetails } = response.data;
        const {
          email, accessLevel, firstLogin, userid, numberUsers, role, industryId, invoicePhone, istwilio, invoiceEmail, isEmail, isNumberVerify, isEmailVerify, chemicalsToDisplay,
        } = userdata;

        const permissionlist = await userInstance().get('/userPermissions/getAllPermissionList');
        const { permissionList } = permissionlist.data;

        const selective = ['Delete Invoice', 'Payments', 'Quotes', 'Invoices & Payments'];
        const userPermissions = [];
        if (permissionList?.length > 0) {
          permissionList.forEach((el) => {
            if (selective.includes(el?.action) && !userPermissions.includes(el?.action)) {
              userPermissions.push(el?.action);
            }
          });
        }
        setUserData({
          ...userData,
          auth: true,
          email,
          accessLevel,
          firstLogin,
          permissions: permissionList || [],
          userPermissions: permissionList || [],
          calendarSettings,
          numberUsers,
          // userPermissions,
          role,
          userDetails,
          industryId: userDetails?.industryId,
          invoicePhone,
          istwilio: istwilio || false,
          invoiceEmail,
          isEmail: isEmail || false,
          isNumberVerify: isNumberVerify || false,
          isEmailVerify: isEmailVerify || false,
          userId: userid,
          chemicalsToDisplay,
        });
        localStorage.setItem('chemicalsToDisplayInvoice', chemicalsToDisplay);
        if (userData?.firstLogin === true && window.location.pathname !== '/updatepassword') {
          window.location.href = '/updatepassword';
        }
        if (userDetails?.payment === 'pending') {
          history.push('/subsPayment');
        }
        const resCalcData = await userInstance().get('/watertest/getCalculatorWaterHistory');

        const resul = resCalcData?.data?.poolData?.poolData;
        // if (resul) {
        //   let stringData = JSON.stringify(resul);
        //   localStorage.setItem('calculatorObject', stringData);
        // }
      } else {
        setUserData({ auth: false });
      }
    } catch (error) {
      console.log('error', error?.response?.data);

      if (localStorage.getItem('f#@5e')) {
        if (error?.response?.data) {
          const { code, msg } = error?.response?.data || {};
          if (code === 401) {
            localStorage.removeItem('s#@4f');
            localStorage.removeItem('f#@5e');
            window.location.href = '/login';
          }
        }
      }
    }
  }, []);
  const getCompanyInformation = async () => {
    try {
      if (localStorage.getItem('f#@5e')) {
        const response = await userInstance().get('/location/getCompanyInformation');
        const { locationData } = response?.data;
        if (locationData) {
          setLocationInfo(locationData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const setFirstActiveStaff = async () => {
    const checkExistStaff = localStorage.getItem('staff_id');
    if (localStorage.getItem('f#@5e') && (checkExistStaff === null || checkExistStaff === '' || checkExistStaff === 'undefined')) {
      const response = await userInstance().get(`/staff/checkExistStaff?id=${checkExistStaff}`);
      const { staffData } = response.data || {};
      const {
        firstname,
        lastname,
        location,
        _id,
      } = staffData || {};
      localStorage.setItem('staff_id', _id);
      localStorage.setItem('staff_name', `${firstname} ${lastname}`);
      localStorage.setItem('locationId', location);
    }
  };

  useEffect(() => {
    validateToken();
    settinngUserDetais();
    getCompanyInformation();
    setFirstActiveStaff();
  }, [validateToken]);
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <LoginRoute exact path="/" component={() => <Login />} />
          <LoginRoute
            exact
            path="/login"
            component={() => <Login />}
          />
          <Route
            exact
            path="/updatepassword"
            component={() => <UpdatePassword />}
          />
          <Route exact path="/401" render={() => <NotAuthorized />} />
          {/* <Route exact path="/contact" render={() => <Contact />} />
          <Route exact path="/account" render={() => <MyAccount />} />
          <Route exact path="/testhistory" render={() => <TestHistory />} />
          <Route exact path="/settings" render={() => <Setting />} />
          <Route exact path="/chemicalpricing" render={() => <ChemicalPricing />} />
          <Route exact path="/chlorinatormodel" render={() => <ChlorinatorModel />} />
          <Route exact path="/appointmentsummary" render={() => <AppointmentSummary />} />
          <Route exact path="/paymentreceived" render={() => <PaymentReceived />} />
          <Route exact path="/about" render={() => <About />} />
          <Route exact path="/onlinedemo" render={() => <OnlineDemo />} />
          <Route exact path="/productfeature" render={() => <ProductFeature />} />
          <Route exact path="/waterchemistry" render={() => <WaterChemistry />} />
          <Route exact path="/green-pools" render={() => <GreenPools />} />
          <Route exact path="/osmosis" render={() => <Osmosis />} />
          <Route exact path="/filter-maintenance" render={() => <FilterMaintenance />} />
          <Route exact path="/pricing" render={() => <Pricing />} />
          <Route exact path="/buy-now" render={() => <BuyNow />} />
          <Route exact path="/poolchemistry" render={() => <PoolChemistry />} /> */}

          <Route exact path="/forget-password" render={() => <ResetPassword />} />
          <Route exact path="/update-password" render={() => <UpdatePassword />} />
          {/* <Route exact path="/testStrip" render={() => <TestStrip />} /> */}
          <Route exact path="/invoice-details" render={() => <SmsTemplate />} />
          <Route exact path="/profile" render={() => <Profile />} />
          <Route
            exact
            path="/subsPayment"
            component={() => <SubsPayment />}
          />
          <Route
            exact
            path="/contact"
            render={() => <Contact />}
          />
          {userData?.accessLevel ? (
            <>
              <Route
                exact
                path="/calendar"
                render={() => (
                  <HeaderDataProvider>

                    <StaffCalendar />

                  </HeaderDataProvider>
                )}
              />
              <Route
                exact
                path="/quotes"
                render={() => (
                  <Quotes />
                )}
              />
              <UserRoute
                exact
                path="/subscriptions"
                component={() => <Subscription />}
              />
              <Route
                exact
                path="/xero"
                component={() => <XeroComponent />}
              />
              <Route
                exact
                path="/xero/redirect"
                component={() => <XeroRedirect />}
              />
              <Route
                exact
                path="/quickbook/redirect"
                component={() => <QuickBookRedirect />}
              />
              <UserRoute
                exact
                path="/calculator"
                component={() => <Calculator />}
              />
              <UserRoute
                exact
                path="/dashboard"
                component={() => <AdminDashboard />}
              />
              <UserRoute
                exact
                path="/staff"
                component={() => <Staff />}
              />
              <UserRoute
                exact
                path="/permissionlevels"
                component={() => <NewPermissionLayout />}
              />

              {/* <Route
                exact
                path="/newPermission"
                component={() => <NewPermissionLayout />}
              /> */}

              <UserRoute
                exact
                path="/subsPayment"
                component={() => <SubsPayment />}
              />

              <UserRoute
                exact
                path="/poolsetup"
                component={() => <PoolSetUp />}
              />
              <UserRoute
                exact
                path="/productservices"
                component={() => <ProductServices />}
              />
              <UserRoute
                exact
                path="/client"
                component={() => <Client />}
              />
              <UserRoute
                exact
                path="/addappointment"
                component={() => <AddAppointment />}
              />

              <UserRoute
                exact
                path="/invoice"
                component={() => (
                  <Invoice />
                )}
              />

              <Route
                exact
                path="/permissionsubscriberlevels"
                component={() => <PermissionSubscriberAccess />}
              />
              <Route
                exact
                path="/reports"
                render={() => <Reports />}
              />
              <UserRoute
                exact
                path="/finlizevisitcost"
                component={() => <FinalizeVisitcost />}
              />
              <Route
                exact
                path="/payemnt-received"
                component={() => <PaymentReceived />}
              />
              <Route
                exact
                path="/approvalappointments"
                component={() => <Awaitingapprovals />}
              />
              <Route
                exact
                path="/booking"
                render={() => <PastFutureBooking />}
              />
              <UserRoute
                exact
                path="/watertesthistory"
                component={() => <WaterTestHistory />}
              />
              <Route
                exact
                path="/invoicespayment"
                render={() => <InvoicesPayment />}
              />
              <Route
                exact
                path="/completeview"
                render={() => <CompleteAppointmentView />}
              />
              <Route
                exact
                path="/equipment-details"
                render={() => <EquipmentDetails />}
              />
              <Route
                exact
                path="/edit-templete"
                render={() => (
                  <TemplateFeaturesProvider>

                    <EditMainTemplate />

                  </TemplateFeaturesProvider>
                )}
              />
              <UserRoute
                exact
                path="/calendarSettings"
                component={() => <Calendarsettings />}
              />
              <UserRoute
                exact
                path="/industry-type"
                component={() => <IndustryType />}
              />
              <Route
                exact
                path="/cal"
                render={() => <Cal />}
              />
              <UserRoute
                exact
                path="/companyInfo"
                component={() => <CompanyInfo />}
              />
              <Route
                exact
                path="/companyLocation"
                render={() => <CompanyLocation />}
              />
              <UserRoute
                exact
                path="/inventory"
                component={() => <Inventory />}
              />

              <UserRoute
                exact
                path="/automated-payment"
                component={() => <AutomatedPayment />}
              />
              <Route
                exact
                path="/data-upload"
                render={() => <CSVUpload />}
              />
              <UserRoute
                exact
                path="/myProfile"
                component={() => <MyProfile />}
              />
            </>
          ) : !localStorage.getItem('s#@4f') ? <Redirect to="/login" /> : null}
          {/* <Route exact path="/updatepassword"
          render={() => <UpdatePassword />} /> */}
        </Switch>
      </Router>
      <ToastContainer autoClose={500} />
      <NotificationContainer timeOut={500} />
    </div>
  );
}

export default App;
