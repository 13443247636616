import React, { useState, useEffect } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './xero.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const QuickBookRedirect = () => {
  const [count, setCount] = useState(1);

  const getTriggered = async (url) => {
    setCount((prev) => prev + 1);
    try {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      let { realmId } = params;
      // console.log('this is the realmId', realmId);
      const response = await userInstance().post('/quickBooks/quickBooksRedirect', { parseRedirectUrl: url, realmId });
      // console.log('this is the response', response);
      // console.log('response', response);
      // if (response.data.connect === true) {
      //   NotificationManager.success('Successfully Connected to QuickBook', 'Message', successMsgTime);
      //   localStorage.setItem('quickBook', 'true');
      // } else {
      //   NotificationManager.error('Not Connected! Please try again', 'Message', errorMsgTime);
      //   localStorage.setItem('quickBook', 'false');
      // }
    } catch (err) {
      console.log('This is the error', err);
      toastMessage('error', 'Not Connected! Please try again.');
      // localStorage.setItem('quickBook', 'false');
    }
  };

  useEffect(() => {
    if (count === 1) {
      const url = window.location.href;
      getTriggered(url);
    }
  }, []);
  return (
    <AdminWrapper>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="appointment-view xero-box xer-success-box">
              <h2>Successfully Connected to QuickBooks</h2>
            </div>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};
export default QuickBookRedirect;
