import { userInstance } from '../../config/axios';

// add and update main group
export const addUdpateReminderGroupApi = async (groupFormName, formType, groupId) => {
  try {
    const payload = {
      groupName: groupFormName,
      formType,
      groupId,
    };
    const response = await userInstance().post(
      '/automaticInvoice/addUpdateConditionGroup',
      payload,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// delete main group
export const deleteReminderGroup = async (groupId) => {
  try {
    const response = await userInstance().delete(
      `/automaticInvoice/deleteReminderGroup?groupId=${groupId}`,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addSubGroupApi = async (subGroupData, paymentReminderGroupTotalDays) => {
  try {
    const payload = { subGroupData, paymentReminderGroupTotalDays };
    const response = await userInstance().post(
      '/automaticInvoice/addSubGroup',
      payload,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const updateSubGroupApi = async (subGroupData, specificClientsIdArray, paymentReminderGroupTotalDays) => {
  try {
    const payload = { subGroupData, specificClientsIdArray, paymentReminderGroupTotalDays };
    const response = await userInstance().put(
      '/automaticInvoice/updateSubGroup',
      payload,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteSubGroupApi = async (payload) => {
  try {
    const response = await userInstance().post(
      '/automaticInvoice/deleteSubGroup',
      payload,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateOrder = async (payload) => {
  try {
    const data = { payload };
    const response = await userInstance().post(
      '/automaticInvoice/updateOrder',
      data,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const invoiceMaximum = async (payload) => {
  try {
    const data = { payload };
    const response = await userInstance().post(
      '/automaticInvoice/invoiceMaximum',
      data,
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
