import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { userInstance } from '../../config/axios';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import Close from '../../assets/admin/close.png';
import PreviewInvoice from './previewInvoice';
import {
  getCalendarPath, removeComma,
} from '../../functions/index';

const CompleteAppointmentView = () => {
  const history = useHistory();
  const [clients, setClients] = useState([]);
  const [services, setServices] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [chemicalsAdded, setChemicalsAdded] = useState(true);
  const [invoice, setInvoice] = useState({});
  const [invoiceClicked, setInvoiceClicked] = useState(false);
  const [appoitmentData, setAppointmentData] = useState([]);
  const [chemicalsArray, setChemicalsArray] = useState([]);
  // const chemicalArray = [{
  //   chemicalName: 'Add 1.5kg of Granular Chlorine',
  //   cost: 20,
  // }, {
  //   chemicalName: 'Add 400 grams if Soda Ash',
  //   cost: 15,
  // },
  // {
  //   chemicalName: 'Add 7.7kg of Buffer',
  //   cost: 170,
  // },
  // {
  //   chemicalName: 'Add 13.9kg of Calcium Raiser',
  //   cost: 225,
  // },
  // {
  //   chemicalName: 'Add 1.6kg of Stabliser',
  //   cost: 45,
  // },
  // {
  //   chemicalName: 'Add 8 Bags of Salt(20kg)',
  //   cost: 240,
  // },
  // ];
  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  useEffect(() => {
    getInvoiceDetail();
  }, []);

  const getInvoiceDetail = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const Id = queryParams.get('data');
      const response = await userInstance().get(`/appointment/getAppointmentById?id=${Id}`);
      const { appointmentdetail } = response.data;
      setClients(appointmentdetail.client);
      setServices(appointmentdetail.services);
      setTotalData(appointmentdetail.totalData);
      setAppointmentData(appointmentdetail.dateData);
      setInvoice(appointmentdetail.Invoice);
      setChemicalsAdded(appointmentdetail.Invoice.chemicalsAdded);
      if (appointmentdetail.chemicalsArray) {
        setChemicalsArray(appointmentdetail.chemicalsArray[0].service);
      }
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };

  const goToCalendar = () => {
    // window.location.href = '/calendar';
    // changes by sanjana
    history.push(`${getCalendarPath()}`);
  };

  const gotoWaterTest = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const Id = queryParams.get('data');
    history.push(`/watertesthistory?data=${Id}`);
  };
  const Rebook = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const Id = queryParams.get('data');
    let oldUrl = getCalendarPath();
    const newUrl = `${oldUrl}&data=Rebook&id=${Id}`;
    history.push(newUrl);
  };
  return (
    <AdminWrapper>
      {!invoiceClicked ? (
        <>
          <div className=" pool-set-up invoices-list completed-appt">
            <div className="invoice-close"><img src={Close} alt={Close} onClick={goToCalendar} /></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-titles-mob">
                    <h3>Appointment Completed</h3>
                  </div>
                  <div className="invoice-detail">
                    <div className="pool-setup">
                      <div className="target-heading">
                        <h6>Payment Received</h6>
                      </div>
                      <div className="invoices-detail-table pool-chemistry-box">
                        <h6 className="invoice-no">
                          Invoice No:
                          {invoice && invoice.invoiceNo}
                        </h6>
                        <h6 className="invoice-no">
                          Date:
                          {moment(invoice.createdAt).format('D MMMM')}
                          ,
                          {moment(invoice.createdAt).format('YYYY')}
                        </h6>
                        <h6>Bill To: </h6>
                        {clients && clients.map((cl) => (
                          <>
                            <h6>{cl.name}</h6>
                            <h6>{cl.address}</h6>
                          </>
                        ))}
                        <h6>Hawthorn Victoria 3122</h6>
                        <div className="invoice-description">
                          <div className="head-des">
                            <div className="left-description">
                              <h6>Description</h6>
                            </div>
                            <div className="right-description">
                              <h6>Goods/Services Cost</h6>
                            </div>
                          </div>
                          <div className="body-desc">
                            {appoitmentData && appoitmentData.map((el) => (
                              <>
                                {el.service && el.service.map((service) => (
                                  <>
                                    <div className="body-description labour-invoice-cost">
                                      <div className="margin-cost-invoice">
                                        <p>
                                          {service.description}
                                          {' '}
                                          |
                                          {' '}
                                          {` ${convertToReadableDuration(service.duration)}`}
                                          {' '}
                                          |
                                        </p>
                                        <p>
                                          {' '}
                                          {service.staffName}
                                        </p>
                                      </div>
                                      <h6>
                                        $
                                        {service.cost}
                                      </h6>
                                    </div>
                                  </>
                                ))}
                              </>
                            ))}

                            {chemicalsAdded && (
                              <>
                                <div className="body-description labour-invoice-cost">
                                  <p>
                                    CHEMICALS ADDED
                                  </p>
                                </div>

                                {chemicalsArray && chemicalsArray.map((chem) => (
                                  <div className="body-description">
                                    <p>
                                      -
                                      {' '}
                                      {chem.subcategory}
                                    </p>
                                    <h6>
                                      $
                                      {parseFloat(removeComma(chem.renderCost)).toFixed(2)}
                                    </h6>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          <div className="invoice-total">
                            <div className="body-description">
                              <h6>
                                Sub Total:
                              </h6>
                              <h6>
                                $
                                {invoice && invoice.invoiceSubTotal}
                              </h6>
                            </div>
                            <div className="body-description">
                              <h6>
                                GST:
                              </h6>
                              <h6>
                                $
                                {invoice && invoice.gst}
                              </h6>
                            </div>
                            <div className="body-description">
                              <h6>
                                Invoice Total:
                              </h6>
                              <h6>
                                $
                                {invoice && invoice.invoiceTotal}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <p className="labour-cost">
                          Labour & Margin: $
                          {totalData.totalLabour}
                        </p>

                        <div className="payment-detail">
                          <h6 className="payment-heading">Payment Detail</h6>
                          <div className="head-des">
                            <div className="left-description">
                              <h6>Invoice Total: </h6>
                            </div>
                            <div className="right-description">
                              <h6>
                                $
                                {invoice && invoice.invoiceTotal}
                              </h6>
                            </div>
                          </div>
                          <div className="body-description">
                            <p>
                              Payment received
                              {' '}
                              <br />
                              by
                              {' '}
                              {invoice.paymentId && invoice.paymentId.paymentMethod}
                              {' '}
                              {moment(invoice.paymentId && invoice.paymentId.createdAt).format('D MMMM')}
                              ,
                              {moment(invoice.paymentId && invoice.paymentId.createdAt).format('YYYY')}
                            </p>
                            <p>
                              $
                              {invoice.paymentId && invoice.paymentId.amount}
                            </p>
                          </div>
                          <div className="body-description">
                            <p>
                              Balance Due:
                            </p>
                            <p>
                              $
                              {invoice && invoice.balanceDue}
                            </p>
                          </div>
                        </div>
                        <div className="footer-btn view-btn">
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              View Other Detail
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => setInvoiceClicked(true)}>View Invoice</Dropdown.Item>
                              <Dropdown.Item onClick={() => gotoWaterTest()}>View Water Test</Dropdown.Item>
                              <Dropdown.Item>Edit Job Notes</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <Button className="appointment-btn btn-save" onClick={() => Rebook()}>
                            Rebook
                          </Button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <PreviewInvoice invoiceData={invoice} mergeInvoices={false} getInvoiceDetail={getInvoiceDetail} chemicalsArray={chemicalsArray} />
      )}

    </AdminWrapper>
  );
};

export default CompleteAppointmentView;
