import React from 'react';
import { Form, Button } from 'react-bootstrap';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const ProductView = (props) => {
  const {
    handleBookingData,
    bookingData,
    editData,
    servicesLength,
    handleChangeCustom,
    timepickerShow,
    handleTimePicker,
    convertToReadableDuration,
    handleDurationPicker,
    timeZone,
    timeDisplay,
  } = props;
  // console.log('product---->>>', editData, servicesLength);
  const withValueLimitSellPrice = (inputObj) => {
    const { value } = inputObj;
    if (value.match(/\./g)) {
      const [, decimal] = value.split('.');
      if (decimal?.length > 2) {
        return 0;
      }
    }
    if (value <= 99999999.99) return inputObj;
    toastMessage('error', 'Enter an amount maximum 8 digit or less.');

    return '';
  };

  return (
    <div className="row">
      <div className="col-md-6 col-6 half">
        <Form.Group controlId="formBasicHiddenEmployees">
          <Form.Label>Appointment Time</Form.Label>
          {/* <div className="select-box">
            <i className="fas fa-sort-down" />
              <Form.Control
              as="select"
              onChange={handleBookingData}
              name="startTime"
              value={bookingData.startTime}
              disabled={!(editData.index === 0 || servicesLength === 0)}
            >
              {getTimes().map((time) => (
                <option value={time} selected={time === '9:30 am'}>
                  {time.toLowerCase()}
                </option>
              ))}
            </Form.Control>
          </div> */}
          <Button
            onClick={handleTimePicker}
            disabled={(editData.type && editData.type === 'mark') ? false : !(editData.index === 0 || servicesLength === 0)}
          >
            {moment(bookingData.startTime, ['HH:mm']).format('h:mm a')}
          </Button>
        </Form.Group>
      </div>
      <div className="col-md-6 col-6 halfright">
        <Form.Group controlId="formBasicHiddenEmployees">
          <Form.Label>Duration</Form.Label>
          <Button onClick={handleDurationPicker}>
            {convertToReadableDuration(bookingData.duration)}
          </Button>
        </Form.Group>
      </div>
      <div className="col-md-6 col-6 half">
        <Form.Group controlId="formBasicHiddenEmployees">
          <Form.Label>
            {`${
              bookingData?.soldPer === 'Item' || !bookingData?.soldPer
                ? 'Quantity'
                : bookingData?.soldPer === 'Add Custom Measure' ? `No. of  ${bookingData?.unit}s` : `No. of ${bookingData?.soldPer?.split('(')[0].replace(/Inch/g, 'Inche').replace(/Foot/g, 'Feet').replace(/Box/g, 'Boxe').trim()}${!(bookingData?.soldPer.includes('Foot') || bookingData?.soldPer.includes('Piece')) ? 's' : ''}`
            }`}
          </Form.Label>
          <NumberFormat
            thousandSeparator
            type="text"
            placeholder="Quantity"
            name="quantity"
            value={bookingData?.quantity}
            onChange={handleBookingData}
            className={!bookingData?.quantity ? 'addBorder' : ''}
            allowNegative={false}
            maxLength={9}
            isAllowed={withValueLimitSellPrice}
          />
        </Form.Group>
      </div>
      <div className="col-md-6 col-6 halfright">
        <Form.Group controlId="formBasicHiddenEmployees">
          <Form.Label>Buy & Sell Costs</Form.Label>
          <div className="select-box">
            {/* <i className="fas fa-sort-down" /> */}
            <Form.Control
              as="select"
              onChange={(e) => handleChangeCustom(e.target.value)}
              name="customCost"
              value={bookingData.customCost}
              disabled={!!bookingData.category.toLowerCase().includes('misc')}
            >
              <option label="Default" name="customCost" value="default">
                Default
              </option>
              <option label="Custom Buy Cost" name="customCost" value="buyCost">
                Custom Buy Cost
              </option>
              <option
                label="Custom Sell Price"
                name="customCost"
                value="sellPrice"
              >
                Custom Sell Price
              </option>
              <option
                label="Custom Buy Cost & Sell Price"
                name="customCost"
                value="buySell"
              >
                Custom Buy Cost & Sell Price
              </option>
            </Form.Control>
          </div>
        </Form.Group>
      </div>
      <div className="col-md-6 col-6 half">
        <Form.Group
          controlId="formBasicHiddenEmployees"
          className={
              (bookingData.customCost !== 'buyCost'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
                ? 'span-text'
                : 'addBorder-input'
            }
        >
          <Form.Label>Buy Cost (ex tax)</Form.Label>
          <div className="p-class">
            <NumberFormat
              thousandSeparator
              type="text"
              placeholder="Buy Cost"
              name="buyCostProduct"
              value={bookingData.buyCostProduct}
              onChange={handleBookingData}
              isAllowed={withValueLimitSellPrice}
              allowNegative={false}
              maxLength={10}
              disabled={
              (bookingData.customCost !== 'buyCost'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
            }
              className={
              (bookingData.customCost !== 'buyCost'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
                ? ''
                : 'addBorder'
            }
              prefix="$"
            />
            <span
              className={
              (bookingData.customCost !== 'buyCost'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
                ? 'input-text-right'
                : 'input-text-right text-red'
            }
            >
              {`${
                bookingData.soldPer
                  ? `per ${bookingData.soldPer === 'Item' ? bookingData.soldPer : bookingData.soldPer === 'Add Custom Measure' ? `${bookingData.symbol}` : bookingData.soldPer.length > 9 ? bookingData.soldPer.split('(')[1].replace(')', '').trim() : bookingData.soldPer.split('(')[0].trim()}`
                  : ''
              }`}
            </span>
          </div>
        </Form.Group>
      </div>
      <div className="col-md-6 col-6 halfright">
        <Form.Group
          controlId="formBasicHiddenEmployees"
          className={
              (bookingData.customCost !== 'sellPrice'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
                ? 'span-text'
                : 'addBorder-input'
            }
        >
          <Form.Label>Sell Price (ex tax)</Form.Label>
          <div className="p-class">
            <NumberFormat
              thousandSeparator
              type="text"
              placeholder="Sell Price"
              name="cost"
              value={bookingData.cost}
              allowNegative={false}
              maxLength={10}
              disabled={
              (bookingData.customCost !== 'sellPrice'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
            }
              onChange={handleBookingData}
              className={
              (bookingData.customCost !== 'sellPrice'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
                ? ''
                : 'addBorder'
            }
              prefix="$"
              isAllowed={withValueLimitSellPrice}
            />
            <span
              className={
              (bookingData.customCost !== 'sellPrice'
                && bookingData.customCost !== 'buySell')
              || bookingData.customCost === 'default'
              || !bookingData.customCost === 'buySell'
                ? 'input-text-right'
                : 'input-text-right text-red'
            }
            >
              {`${
                bookingData.soldPer
                  ? `per ${bookingData.soldPer === 'Item' ? bookingData.soldPer : bookingData.soldPer === 'Add Custom Measure' ? `${bookingData.symbol}` : bookingData.soldPer.length > 9 ? bookingData.soldPer.split('(')[1].replace(')', '').trim() : bookingData.soldPer.split('(')[0].trim()}`
                  : ''
              }`}
            </span>
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

ProductView.propTypes = {
  handleBookingData: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  editData: PropTypes.func.isRequired,
  customCost: PropTypes.func.isRequired,
  servicesLength: PropTypes.func.isRequired,
  handleChangeCustom: PropTypes.func.isRequired,
  timepickerShow: PropTypes.func.isRequired,
  handleTimePicker: PropTypes.func.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
  timeDisplay: PropTypes.string.isRequired,
};

export default ProductView;
