/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Table, Button, Accordion, Card, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { set } from 'lodash';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import AddCategory from './service-form-modal';
import ServiceProductForm from './subcategorymodal';
import { userInstance } from '../../config/axios';
import { showErrMsg, validateDataWithKey, addZero } from '../../functions/index';
import DurationPicker from '../appointment/durationPicker';
import sort from '../../assets/home/sort.png';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import showmore from '../../assets/home/showmore.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import DeletePopUp from '../DeletePopUp';
import AppointmentChecklist from './addChecklist';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import './product&services.css';
import './webfile.css';
import WebProductServices from './webServicesProducts';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';

const ProductServices = () => {
  const { userData: { industryId } } = useContext(AuthDataContext);
  const initialSubcategory = {
    name: '',
    serviceDescription: '',
    tax: '',
    duration: '5',
    cost: '',
    retailCost: '',
    productCode: '',
    soldPer: 'Item',
    unit: '',
    symbol: '',
    trackedInventory: 'Yes',
    barCode: '',
    supplier: '',
    supplyUnit: '',
    currentStockItems: '',
    s_uId: '',
    categoryId: '',
  };
  const initialEditData = { index: null, categoryId: '', isEdit: false };
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState('');
  const [appointmentChecklist, setAppointmentChecklist] = useState([]);
  const [checklistName, setChecklistName] = useState('');
  const [servicesList, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showSubCat, setShowCat] = useState(false);
  const [subcategory, setSubCategory] = useState(initialSubcategory);
  const [editData, setEditData] = useState(initialEditData);
  const [selectedAccordion, setSelectedAccordion] = useState([]);
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isServiceAccordOpen, setIsServiceAccordOpen] = useState(false);
  const [type, setType] = useState('');
  const [renderSort, setRenderSort] = useState(false);
  const [renderSortProduct, setRenderSortProduct] = useState(false);
  const [durationPicker, setDurationPicker] = useState(false);
  const [durationData, setDuration] = useState('');
  const [buysymbol, setBuySymbol] = useState(false);
  const [sellsymbol, setSellSymbol] = useState(false);
  const [openService, setOpenService] = useState();
  const [openProduct, setOpenProduct] = useState();
  const [checklist, setChecklist] = useState();
  const [inputDisabled, setInputDisabled] = useState(true);
  const handleChecklist = () => {
    setChecklistName('');
    setChecklist(!checklist);
  };
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [delData, setDelData] = useState({});
  const [delStatus, setDelStatus] = useState('');
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [hideLocStaff, sethideLocStaff] = useState(true);
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);
  const [errorValue, setErrorValue] = useState('');
  const [loading, setLoading] = useState(false);

  const paraText = 'Are you sure to do this?';
  const handlePopUpChange = (val) => {
    const {
      index, categoryId, categoryName, eltype, id, catType, subcategoryId,
    } = delData;
    if (val === 'Yes') {
      switch (delStatus) {
        case 'Delete Category':
          handleDeleteCategory(id, catType);
          break;
        default:
          handleDeleteSubcategory(index, categoryId, categoryName, eltype, subcategoryId);
          break;
      }
    }
    setDeletePopUp(false);
  };
  const submitRemove = (index, categoryId, categoryName, eltype, subcategoryId) => {
    if (eltype === 'service') {
      setPopUpText('Delete Service?');
    } else {
      setPopUpText('Delete Product?');
    }
    setDelStatus('Delete SubCategory');
    setDelData({
      index, categoryId, categoryName, eltype, subcategoryId,
    });
    setDeletePopUp(true);
  };

  const submitRemoveCategory = (id, catType) => {
    if (catType === 'product') {
      setPopUpText('Delete Product?');
    } else {
      setPopUpText('Delete Service?');
    }
    setDelStatus('Delete Category');
    setDelData({ id, catType });
    setDeletePopUp(true);
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  const handleDurationPicker = () => {
    setDuration(subcategory.duration);
    setDurationPicker(!durationPicker);
  };

  const moveArr = (oldIndex, newIndex, entryType, order, sign) => {
    const filteredArr = servicesList.filter((el) => el.type === entryType);
    const filteredArrType = servicesList.filter((el) => el.type !== entryType);
    if (sign === 'minus') {
      filteredArr[oldIndex - 1].order = filteredArr[oldIndex].order;
    } else {
      filteredArr[oldIndex + 1].order = filteredArr[oldIndex].order;
    }
    filteredArr[oldIndex].order = order;
    // console.log('filteredArr', filteredArr);
    const currentServices = [...filteredArr];
    if (newIndex >= currentServices.length) {
      let k = newIndex - currentServices.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        currentServices.push(undefined);
      }
    }
    currentServices.splice(newIndex, 0, currentServices.splice(oldIndex, 1)[0]);
    setServices([...currentServices, ...filteredArrType]);
    // console.log('[...currentServices, ...filteredArrType]', [
    //   ...currentServices,
    //   ...filteredArrType,
    // ]);
  };

  const moveArrSubcategory = (categoryId, oldIndex, newIndex) => {
    const arrCopy = [...servicesList];
    let index = null;
    const filteredArr = arrCopy.filter((el, i) => {
      if (el._id === categoryId) {
        index = i;
        return true;
      }
      return false;
    });
    if (filteredArr.length) {
      const subcategories = filteredArr[0].subCategory;
      if (newIndex >= subcategories.length) {
        let k = newIndex - subcategories.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
          subcategories.push(undefined);
        }
      }
      subcategories.splice(newIndex, 0, subcategories.splice(oldIndex, 1)[0]);
      arrCopy[index].subCategory = subcategories;
      setServices([...arrCopy]);
    }
  };

  const moveArrCheckList = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    setAppointmentChecklist([...arr]);
  };

  const HandleOpenClose = (entryType) => {
    // console.log('imhitting', entryType);
    setType(entryType);
    setShow(!show);
    setSelectedCategory('');
    setCategory('');
    setAppointmentChecklist([]);
  };

  const handleChange = (e) => {
    let isValid = true;
    if (e.target.name === 'tax' || e.target.name === 'cost') {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(e.target.value.replace(/,/g, ''))) {
        isValid = false;
      }
    }
    // console.log(e.target.value);
    if (isValid) {
      setSubCategory({ ...subcategory, [e.target.name]: e.target.value });
    }
    if (e.target.name === 'cost' && e.target.value) setBuySymbol(true);
    else if (e.target.name === 'cost' && e.target.value === null) setBuySymbol(false);
    if (e.target.name === 'retailCost' && e.target.value) setSellSymbol(true);
    else if (e.target.name === 'retailCost' && e.target.value === null) setSellSymbol(false);
  };

  const withValueLimitSellPrice = (inputObj) => {
    const { value } = inputObj;
    const toCheck = 9999999999.999;
    const splitD = value.toString().split('.');
    const isValid = splitD.length > 1 ? splitD[1].length <= 3 : true;
    if (value <= toCheck && isValid) return inputObj;
    toastMessage('error', 'Enter an amount equal $9,999,999,999.999 or less.');
    return '';
  };
  const [catId, setCatId] = useState('');

  const handleOpenSubCat = (id, entryType, i, catIdMain) => {
    setType(entryType);
    setSelectedCategory(id);
    setShowCat(!showSubCat);
    setSubCategory(initialSubcategory);
    setEditData(initialEditData);
    setCategory(id);
    setCatId(catIdMain);
    if (entryType === 'service') {
      setOpenService(i);
    } else {
      setOpenProduct(i);
    }
  };
  const handleManageCategory = async (e) => {
    e.preventDefault();
    console.log('this is the', type);
    try {
      if (selectedCategory) {
        handleUpdateCategory();
      } else if (category) {
        const dynamicApi = type === 'product' ? '/inventory/addInventory' : '/service/addService';
        if (appointmentChecklist.length > 0) {
          const response = await userInstance().post(dynamicApi, {
            category,
            type,
            appointmentChecklist,
            industryId: industryId?._id,
          });
          const { msg } = response.data;
          HandleOpenClose();
          getServices();
          setAppointmentChecklist([]);
          toastMessage('success', msg);
        } else {
          toastMessage('error', 'Please add appointment checklists.');
        }
      } else {
        setErrorValue(type);
        toastMessage('error', `Please enter ${type} group name.`);
      }
      setTimeout(() => { setErrorValue(''); }, 1000);
    } catch (error) {
      //   console.log(error);
    }
  };

  const updateSorting = async (e) => {
    e.preventDefault();
    try {
      // console.log('servicesList', servicesList);
      const response = await userInstance().put('/service/updateOrder', {
        services: servicesList,
      });
      const { msg } = response.data;
      toastMessage('success', msg);
    } catch (error) {
      console.log(error);
    }
    setRenderSort(false);
    setRenderSortProduct(false);
  };

  const getServices = async (typeData) => {
    try {
      if (typeData === 'type')setLoading(true);
      const response = await userInstance().get(`/service/getServices?industryId=${industryId?._id}`);
      setLoading(false);
      const { services } = response.data;
      setServices(services);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleManageSubCat = async (e) => {
    try {
      e.preventDefault();
      if (selectedCategory) {
        const selectedCategoryId = servicesList.find((el) => (el.category === selectedCategory));
        // console.log('selectedCategory', selectedCategory);
        const subcategoryCopy = { ...subcategory };
        const {
          name, serviceDescription, duration, cost, retailCost, tax,
        } = subcategoryCopy;
        const validationObj = {
          name,
          description: serviceDescription,
          duration,
          tax,
        };
        const priceTypo = type === 'product' ? 'buy cost' : 'sell price';
        if (!category.toLowerCase().includes('misc')) {
          validationObj[priceTypo] = cost;
        }
        // console.log('cost', cost);
        if (type === 'product') {
          if (!category.toLowerCase().includes('misc')) {
            validationObj['retail cost'] = retailCost;
          }
          subcategoryCopy.retailCost = parseFloat(subcategoryCopy.retailCost.replace(/,/g, '')).toFixed(2);
        }
        subcategoryCopy.cost = parseFloat(subcategoryCopy.cost.replace(/,/g, '')).toFixed(2);

        let { isValid, emptyField } = validateDataWithKey(validationObj);
        if (isValid) {
          // console.log('subcategoryCopy', subcategoryCopy);
          if (type === 'product' && parseFloat(subcategoryCopy.cost) > parseFloat(subcategoryCopy.retailCost)) {
            isValid = false;
            setErrorValue('retail cost');
            toastMessage('error', 'Sell price must be greater than buy cost.');
          }
          subcategoryCopy.tax = parseFloat(subcategoryCopy.tax.replace(/,/g, '')).toFixed(2);

          if (isValid) {
            if (type === 'product' && subcategoryCopy.tax.length === 0) {
              isValid = false;
              toastMessage('error', 'Please Enter tax details.');
            }
          }
          // Adding s_uid and category id to sub categories
          let randomStr = (
            Math.floor(Math.random() * 9 + 1) + Date.now() + (Math.random() + 1).toString(36).substring(7)
          ).toString();
          // console.log('selectedCategoryId-----', selectedCategoryId);
          subcategoryCopy.s_uId = randomStr;
          subcategoryCopy.categoryId = selectedCategoryId._id;

          if (isValid) {
            // console.log('subcategoryCopy', subcategoryCopy);
            const reqObj = {
              ...subcategoryCopy,
            };
            if (!category.toLowerCase().includes('misc')) {
              reqObj.name = `${subcategoryCopy.name} $${addZero(
                subcategoryCopy[type === 'product' ? 'retailCost' : 'cost'],
              )}`;
            }
            // For adding services
            if (type === 'service') {
              // deleting unneccessary key and value for services and use serviceReqObj as newObj
              const {
                barCode, categoryId, currentStockItems, supplier, supplyUnit, ...serviceReqObj
              } = reqObj;
              const response = await userInstance().post('/service/addSubCategory', {
                subcategory: serviceReqObj,
                selectedCategory,
                type,
              });
              const { msg } = response.data;
              setShowCat(!showSubCat);
              getServices();
              toastMessage('success', msg);
            } else {
              // for adding product to service/product and also to inventory.
              const totalStock = selectedStaff.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.stockValue), 0);
              delete reqObj.categoryId;
              reqObj.categoryId = selectedCategoryId.inventoryId;
              const data = {
                subcategory: reqObj,
                stockData: selectedStaff,
                selectedCategory,
                type,
                totalStock,
              };
              const response = await userInstance().post('/inventory/addSubInventory', {
                subcategory: reqObj,
                stockData: selectedStaff,
                selectedCategory,
                type,
                totalStock,
              });
              // console.log('sdsdsd', response);
              const { msg } = response.data;
              setShowCat(!showSubCat);
              getServices();
              toastMessage('success', msg);
            }
          }
        } else {
          setErrorValue(emptyField);
          toastMessage('error', `Please enter ${emptyField} for ${type}.`);
        }
      } else {
        setErrorValue('productName');
        toastMessage('error', 'Please enter product name.');
      }
      setTimeout(() => { setErrorValue(''); }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateSubCategory = async (e) => {
    try {
      e.preventDefault();
      const currentSubCat = servicesList.filter((el) => el._id === editData.categoryId);
      // console.log('currentSubCat', type);
      if (currentSubCat.length) {
        currentSubCat[0].subCategory[editData.index] = subcategory;
        const subcategoryCopy = {
          ...currentSubCat[0].subCategory[editData.index],
        };
        const {
          serviceDescription, duration, cost, retailCost, tax, s_uId,
        } = subcategoryCopy;
        const validationObj = {
          name: subcategoryCopy.name,
          description: serviceDescription,
          duration,
          tax,
        };
        if (!category.toLowerCase().includes('misc')) {
          validationObj[type === 'product' ? 'buy cost' : 'sell price'] = cost;
        }
        if (type === 'product') {
          if (!category.toLowerCase().includes('misc')) {
            validationObj['retail cost'] = retailCost;
          }
        }
        let { isValid, emptyField } = validateDataWithKey(validationObj);
        if (isValid) {
          const name = currentSubCat[0].subCategory[editData.index].name;
          const updatedSubCat = {
            ...currentSubCat[0].subCategory[editData.index],
          };

          if (!category.toLowerCase().includes('misc')) {
            const priceValue = currentSubCat[0].subCategory[editData.index][type === 'product' ? 'retailCost' : 'cost'];
            const addValueZero = addZero(priceValue.replace(/,/g, ''));
            updatedSubCat.name = `${name.includes('$') ? name.split('$')[0].trim() : name} $${addValueZero}`;
          }

          updatedSubCat.cost = parseFloat(updatedSubCat.cost.replace(/,/g, '')).toFixed(2);
          if (type === 'product') {
            updatedSubCat.retailCost = parseFloat(updatedSubCat.retailCost.replace(/,/g, '')).toFixed(2);
          }
          currentSubCat[0].subCategory[editData.index] = updatedSubCat;
          // console.log('updatedSubCat', updatedSubCat);

          if (type === 'product' && parseFloat(updatedSubCat.cost) > parseFloat(updatedSubCat.retailCost)) {
            isValid = false;
            setErrorValue('retail cost');
            toastMessage('error', 'Sell price must be greater than buy cost.');
          }

          updatedSubCat.tax = parseFloat(updatedSubCat.tax.replace(/,/g, '')).toFixed(2);
          if (isValid) {
            if (type === 'product' && updatedSubCat.tax.length === 0) {
              isValid = false;
              toastMessage('error', 'Please Enter tax details.');
            }
          }
          if (isValid) {
            if (type === 'product' && subcategoryCopy.stockDataId) {
              const totalStock = selectedStaff.reduce((acc, obj) => parseFloat(acc) + parseFloat(obj.stockValue), 0);
              const response = await userInstance().put('/inventory/updateSubInventory', {
                _id: currentSubCat[0].inventoryId,
                subCategory: currentSubCat[0].subCategory,
                reqType: 'edit',
                subcategoryId: s_uId,
                totalStock,
                stockData: selectedStaff,
              });
              const { msg } = response.data;
              if (msg === 'Product updated') {
                toastMessage('success', 'Product Updated Successfully');
              } else {
                toastMessage('success', 'Product cannot be updated');
              }
            } else {
              const response = await userInstance().put('/service/updateSubCategory', {
                _id: editData.categoryId,
                subCategory: currentSubCat[0].subCategory,
              });
              const { msg } = response.data;
              if (msg) {
                toastMessage('success', 'Product Updated Successfully');
              }
            }
            // console.log('sdsdsd', response);
            setShowCat(!showSubCat);
            getServices();
            // if (msg) {
            //   if (editData.entryType === 'product') {
            //     NotificationManager.success('Product Updated Successfully', 'Message', successMsgTime);
            //   } else {
            //     NotificationManager.success('Service Updated Successfully', 'Message', successMsgTime);
            //   }
            // }
          }
        } else {
          toastMessage('error', `Please enter ${emptyField} for product.`);
          setErrorValue(emptyField);
        }
      }
      setTimeout(() => { setErrorValue(''); }, 1000);
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  const handleEditSubcategory = async (index, categoryId, entryType, name) => {
    try {
      setType(entryType);
      const currentSubCat = servicesList.filter((el) => el._id === categoryId);
      if (currentSubCat.length) {
        // console.log('currentSubCat', currentSubCat);
        const currentSubData = currentSubCat[0].subCategory[index];
        setSubCategory({ ...subcategory, ...currentSubData });
        // for getting the location and stock data
        if (entryType === 'product' && currentSubData.stockDataId) {
          const getInventoryproduct = await userInstance().get(`/inventory/getSepecifInventory/?inventoryStockId=${currentSubData.stockDataId}`);
          const { staffStockData } = getInventoryproduct.data.data;
          const prevLocations = locationData.filter(({ _id: id1 }) => staffStockData.some(({ location: id2 }) => id2 === id1));
          setSelectedLocationValue(prevLocations);
          setSelectedStaff(staffStockData);
        }
      }
      setEditData({
        ...editData,
        index,
        categoryId,
        entryType,
        isEdit: true,
      });
      setShowCat(true);
      setCategory(name);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteSubcategory = async (index, categoryId, categoryName, eltype, subcategoryId) => {
    try {
      // console.log(index, categoryId, categoryName);
      let categoryIndex = null;
      const currentSubCat = servicesList.filter((el, i) => {
        if (el._id === categoryId) {
          categoryIndex = i;
          return true;
        }
        return false;
      });
      if (currentSubCat.length) {
        const stockIdCheck = currentSubCat[0].subCategory[index].stockDataId;

        if (eltype === 'product' && stockIdCheck) {
          const response = await userInstance().put('/inventory/updateSubInventory', {
            _id: currentSubCat[0].inventoryId,
            subCategory: currentSubCat[0].subCategory,
            subcategoryId,
            reqType: 'delete',
          });
        } else {
          currentSubCat[0].subCategory.splice(index, 1);
          const serviceArr = servicesList;
          serviceArr[categoryIndex] = currentSubCat[0];
          setServices([...serviceArr]);
          const response = await userInstance().put('/service/updateSubCategory', {
            _id: categoryId,
            subCategory: currentSubCat[0].subCategory,
          });
        }
        // console.log('sdsdsd', response);
        getServices();
        if (eltype === 'product') {
          toastMessage('success', 'Product deleted Successfully');
        } else {
          toastMessage('success', 'Service deleted Successfully');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCategory = (id, name, entryType, i, data) => {
    // console.log('name', name, entryType, i, data);
    setType(entryType);
    setSelectedCategory(id);
    setAppointmentChecklist(data || []);
    setShow(true);
    setCategory(name);
    if (entryType === 'service') {
      setOpenService(i);
    } else {
      setOpenProduct(i);
    }
  };

  const handleUpdateCategory = async () => {
    try {
      if (category) {
        const findCat = servicesList.find((el) => el._id === selectedCategory);
        const dynamicApi = findCat.type === 'product' && findCat.inventoryId ? '/inventory/updateProductGroup' : '/service/updateCategory';
        const payLoadId = findCat.type === 'product' && findCat.inventoryId ? findCat.inventoryId : selectedCategory;
        if (appointmentChecklist.length > 0) {
          const response = await userInstance().put(dynamicApi, {
            selectedCategory: payLoadId,
            category,
            appointmentChecklist,
          });
          // console.log('sdsdsd', response);
          const { msg } = response.data;
          if (msg) {
            if (type === 'service') {
              toastMessage('success', 'Service Group Name updated');
            } else {
              toastMessage('success', 'Product Group Name updated');
            }
          }
          HandleOpenClose();
          getServices();
        } else {
          toastMessage('error', 'Please add appointment checklist.');
        }
      } else {
        toastMessage('error', 'Please add category name.');
      }
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      const checkSubCat = servicesList.filter((el) => el._id === id);
      if (checkSubCat.length) {
        if (!checkSubCat[0].subCategory.length) {
          const dynamicApi = checkSubCat[0].type === 'product' && checkSubCat[0].inventoryId ? `/inventory/deleteInventory?id=${checkSubCat[0].inventoryId}`
            : `/service/deleteCategory?id=${id}`;
          const response = await userInstance().delete(dynamicApi);
          // console.log('sdsdsd', response);
          const { msg } = response.data;
          getServices();
          toastMessage('success', msg);
        } else {
          // console.log('cannot');
          toastMessage('error', 'All services/products linked to this category will need to be deleted before it can be deleted.');
        }
      }
    } catch (error) {
      console.log(error);
      console.log(error);
    }
  };

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      // console.log(val);
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg = hours && minutes ? `${hours} hr ${minutes} min` : hours && !minutes ? `${hours} hr` : `${minutes} min`;
      arr.push({ value: val, data: msg });
      if (val >= 300) {
        val += 30;
      } else {
        val += 5;
      }
    }
    return arr;
  };

  const convertToHour = (val) => {
    const hours = Math.floor(val / 60);
    const minutes = val % 60;
    const msg = hours && minutes ? `${hours} hr ${minutes} min` : hours && !minutes ? `${hours} hr` : `${minutes} min`;
    return msg;
  };
  useEffect(() => {
    getServices('type');
    // moveArr();
  }, []);

  const handleUpAccordion = (e) => {
    if (selectedAccordion && selectedAccordion.includes(e)) {
      const data = selectedAccordion.filter((el) => el !== e);
      return setSelectedAccordion(data);
    }
    // const accordinData = JSON.parse(localStorage.getItem('accordinData'));
    // localStorage.setItem('accordinData', JSON.stringify({ data: [...selectedAccordion, e] }));
    return setSelectedAccordion([...selectedAccordion, e]);
  };

  const handleNestedAccordion = (e) => {
    if (selectedNestedAccordion && selectedNestedAccordion.includes(e)) {
      const data = selectedNestedAccordion.filter((el) => el !== e);
      return setSelectedNestedAccordion(data);
    }
    return setSelectedNestedAccordion([...selectedNestedAccordion, e]);
  };

  const handleManageChecklist = () => {
    if (checklistName) {
      const old = [...appointmentChecklist];
      old.push(checklistName);
      setAppointmentChecklist(old);
      handleChecklist();
    } else {
      setErrorValue('checklistName');
      toastMessage('error', 'Please Enter Name.');
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };

  const removeList = (index) => {
    const old = [...appointmentChecklist];
    old.splice(index, 1);
    setAppointmentChecklist(old);
  };

  const editList = (e, index) => {
    const old = [...appointmentChecklist];
    old[index] = e.target.value;
    setAppointmentChecklist(old);
  };
  const getLocation = async () => {
    const response = await userInstance().get(
      '/appointment/getLocationList',
    );
    const { locationList, code } = response.data;
    locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
    if (code === 200) {
      setLocationData([...locationList]);
    }
  };
  const [teamMemberData, setTeamMemberData] = useState([]);
  const getTeam = async () => {
    const response = await userInstance().get(
      '/appointment/getTeamMemberList',
    );
    const { teamList, code } = response.data;
    teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
    if (code === 200) {
      setTeamMemberData(teamList);
    }
  };
  const handleStock = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const stt = selectedLocation.slice();
    const fIndex = stt.indexOf(value);
    if (fIndex < 0) {
      stt.push(value);
    }
    setSelectedLocation(stt);
  };
  useEffect(() => {
    getLocation();
    getTeam();
  }, []);

  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  return (
    <>
      <AdminWrapper>
        {loading ? <Loader />
          : (
            <div className="product-service-container">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-titles">
                      <h3>Services & Products</h3>
                    </div>
                  </div>
                </div>
                <div className="service-container">
                  <div className="service-title">
                    <h4>Services</h4>
                    {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                    <span onClick={HandleOpenClose}>
                      <i className="fa fa-plus" />
                    </span>
                    {/* <AddCategory
                  show={show}
                  HandleOpenClose={HandleOpenClose}
                  handleManageCategory={handleManageCategory}
                  category={category}
                  setCategory={setCategory}
                  servicesList={servicesList}
                /> */}
                  </div>
                </div>
                <div className="table-data mob-servicesproducts">
                  <div className="service-mbl">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <div className="service-container">
                            <div className="service-title title-service">
                              <h4>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                  onClick={() => setIsServiceAccordOpen((el) => !el)}
                                >
                                  {/* <i className={isServiceAccordOpen ? 'fas far fa-chevron-down' : 'fas far fa-chevron-up'} /> */}
                                  {' '}
                                  Services
                                </Accordion.Toggle>
                              </h4>
                              {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                              <div className={`services-header ${renderSort && 'active-sort'}`}>
                                <div className="sorting" onClick={() => setRenderSort(!renderSort)}>
                                  <img src={sort} alt="sorting" />
                                </div>
                                <span onClick={() => HandleOpenClose('service')}>
                                  <i className="fa fa-plus" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0" hidden={!servicesList.length}>
                          <div className="mob-table">
                            {servicesList
                              .filter((data) => data.type === 'service')
                              .map((el, i) => (
                                <Accordion className="mob-accordion outer-accordion" key={el._id}>
                                  <Card.Header>
                                    <div className="heading-accordion">
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={!el.subCategory.length ? null : i.toString()}
                                        onClick={() => handleUpAccordion(el._id)}
                                      >
                                        <h5>
                                          <i
                                            className={`fas ${
                                              selectedAccordion.includes(el._id)
                                                ? ' far fa-chevron-circle-down'
                                                : 'fa-chevron-circle-up'
                                            }`}
                                          />
                                          {el.category}
                                        </h5>
                                      </Accordion.Toggle>
                                      <div className={`service-edit-icon ${renderSort && 'add-sort'}`}>
                                        {renderSort ? (
                                          <div className={`sorting up-down ${renderSort && 'active-sort'}`}>
                                            {i !== 0 ? (
                                              <img
                                                src={upsort}
                                                alt="sorting"
                                                onClick={() => moveArr(i, i - 1, 'service', el.order - 1, 'minus')}
                                              />
                                            ) : null}
                                            {i !== servicesList.filter((data) => data.type === 'service').length - 1 ? (
                                              <img
                                                src={downsort}
                                                alt="sorting"
                                                onClick={() => moveArr(i, i + 1, 'service', el.order + 1, 'plus')}
                                              />
                                            ) : null}
                                          </div>
                                        ) : null}
                                        <Dropdown>
                                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <img src={showmore} alt="show" />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {/* {
                                          i === 0 && el.subCategory.length >= 1 ? null : ( */}
                                            <div className="action" onClick={() => handleOpenSubCat(el.category, el.type, i)}>
                                              <img src={greyplus} alt="edit" />
                                              Add
                                            </div>
                                            {/* )
                                        } */}

                                            <div
                                              className="action"
                                              onClick={() => handleEditCategory(el._id, el.category, el.type, i, el.appointmentChecklist)}
                                            >
                                              <img src={greyedit} alt="edit" />
                                              Edit
                                            </div>
                                            {el.category.match(/Misc/g) ? null : (
                                              <div className="action" onClick={() => submitRemoveCategory(el._id, el.type)}>
                                                <img src={greydelete} alt="edit" />
                                                Delete
                                              </div>
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                        {/* <i className="fas fa-pencil-alt" onClick={() => handleEditCategory(el._id, el.category, el.type)} />
                                    <i className="far fa-trash-alt" onClick={() => submitRemoveCategory(el._id)} />
                                    <i className="fa fa-plus" onClick={() => handleOpenSubCat(el.category, el.type)} /> */}
                                      </div>
                                    </div>

                                    {/* <div className="table-icons">
                                  <Button onClick={() => handleEditCategory(el._id, el.category)}><i className="fas fa-pencil-alt" /></Button>
                                  <Button onClick={() => submitRemoveCategory(el._id)}><i className="far fa-trash-alt" /></Button>
                                  <i className="fa fa-plus" onClick={() => handleOpenSubCat(el.category)} />
                                </div> */}
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={i.toString()} className={`${openService === i ? 'show' : ''}`}>
                                    <Card.Body>
                                      <div className="inner-accordion">
                                        {el.subCategory.map((data, index) => (
                                          <>
                                            <Accordion
                                              defaultActiveKey={i.toString()}
                                              onClick={() => handleNestedAccordion(index)}
                                            >
                                              <Card>
                                                <Accordion>
                                                  <Card.Header className="sub-category">
                                                    <Accordion.Toggle
                                                      as={Button}
                                                      variant="link"
                                                      eventKey={index.toString()}
                                                    >
                                                      <div className="product-service-list">
                                                        <i
                                                          className={`fas ${
                                                            selectedNestedAccordion.includes(index)
                                                              ? 'far fa-chevron-circle-down'
                                                              : 'far fa-chevron-circle-up'
                                                          }`}
                                                        />
                                                        <span>
                                                          {`${data.name}`}
                                                          {' '}
                                                          <span className="span-measure inner-measure">
                                                            {data.soldPer === 'Add Custom Measure'
                                                              ? `per ${data.unit} (${data.symbol})`
                                                              : `per ${data.soldPer}`}

                                                          </span>
                                                        </span>

                                                      </div>
                                                    </Accordion.Toggle>
                                                    <span className="time-category">
                                                      {renderSort ? (
                                                        <div className="sorting up-down">
                                                          {index !== 0 ? (
                                                            <img
                                                              src={upsort}
                                                              alt="sorting"
                                                              onClick={() => moveArrSubcategory(el._id, index, index - 1)}
                                                            />
                                                          ) : null}
                                                          {index !== el.subCategory.length - 1 ? (
                                                            <img
                                                              src={downsort}
                                                              alt="sorting"
                                                              onClick={() => moveArrSubcategory(el._id, index, index + 1)}
                                                            />
                                                          ) : null}
                                                        </div>
                                                      ) : null}
                                                      {!renderSort ? (
                                                        <div className="timehour">{convertToHour(data.duration)}</div>
                                                      ) : null}
                                                    </span>
                                                    {/* <div className="arrow-icons">
                                              <i className="fas fa-arrow-alt-circle-up" />
                                              <i className="fas fa-arrow-alt-circle-down" />
                                            </div> */}
                                                  </Card.Header>
                                                  <Accordion.Collapse
                                                    eventKey={index.toString()}
                                                    key={data.name + Math.random()}
                                                  >
                                                    <ul className="grid-list">
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>
                                                            Description
                                                            {/* <span> (as will display on invoices) </span> */}
                                                          </h3>
                                                          <div className="grid-content">{data.serviceDescription}</div>
                                                        </div>
                                                      </li>
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>Time</h3>
                                                          <div className="grid-content">{convertToHour(data.duration)}</div>
                                                        </div>
                                                      </li>
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>Tax</h3>
                                                          <div className="grid-content">
                                                            {`${data.tax ? data.tax : 0}%`}
                                                          </div>
                                                        </div>
                                                      </li>
                                                      {!el.category.toLowerCase().includes('misc') ? (
                                                        <li className="grid-list-item">
                                                          <div className="grid-box">
                                                            <h3>Sell Price (ex tax)</h3>
                                                            <div className="grid-content">
                                                              $
                                                              {`${addZero(data.cost)}`}
                                                            </div>
                                                          </div>
                                                        </li>
                                                      ) : null}
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>Actions</h3>
                                                          <div className="action-btn">
                                                            <Button
                                                              className="edit-btn"
                                                              onClick={() => handleEditSubcategory(index, el._id, 'service', el.category, el.type)}
                                                            >
                                                              <img src={whiteedit} alt="edit" />
                                                            </Button>
                                                            {!el.category.toLowerCase().includes('misc') ? (
                                                              <Button
                                                                className="delete-btn"
                                                                onClick={() => submitRemove(index, el._id, el.category, el.type)}
                                                              >
                                                                <img src={whitedelete} alt="edit" />
                                                              </Button>
                                                            ) : null}
                                                            {/* <Button className="delete-btn">
                                    <span><i className="far fa-trash-alt" /></span>
                                  </Button> */}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </Accordion.Collapse>
                                                </Accordion>
                                              </Card>
                                            </Accordion>
                                          </>
                                        ))}
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Accordion>
                              ))}
                          </div>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                  {/* <div className="view-btn">
                <Button type="submit" className="btn btn-save">Save/Update</Button>
              </div> */}

                  <div className="service-mbl products">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <div className="service-container">
                            <div className="service-title title-service">
                              <h4>
                                <Accordion.Toggle
                                  as={Button}
                                  variant="link"
                                  eventKey="0"
                                  onClick={() => setIsServiceAccordOpen((el) => !el)}
                                >
                                  {/* <i className={isServiceAccordOpen ? 'fas far fa-chevron-circle-down' : 'fas far fa-chevron-circle-up'} /> */}
                                  {' '}
                                  Products
                                </Accordion.Toggle>
                              </h4>
                              {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                              <div className={`services-header ${renderSortProduct && 'active-sort'}`}>
                                <div className="sorting" onClick={() => setRenderSortProduct(!renderSortProduct)}>
                                  <img src={sort} alt="sorting" />
                                </div>
                                <span onClick={() => HandleOpenClose('product')}>
                                  <i className="fa fa-plus" />
                                </span>
                                {/* <AddCategory
                              show={show}
                              HandleOpenClose={HandleOpenClose}
                              handleManageCategory={handleManageCategory}
                              category={category}
                              setCategory={setCategory}
                              servicesList={servicesList}
                            /> */}
                              </div>
                            </div>
                          </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0" hidden={!servicesList.length}>
                          <div className="mob-table">
                            {servicesList
                              .filter((data) => data.type === 'product')
                              .map((el, i) => (
                                <Accordion className="mob-accordion outer-accordion" key={el._id}>
                                  <Card.Header>
                                    <div className="heading-accordion">
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={!el.subCategory.length ? null : i.toString()}
                                        onClick={() => handleUpAccordion(el._id)}
                                      >
                                        <h5>
                                          <i
                                            className={`fas ${
                                              selectedAccordion.includes(el._id)
                                                ? ' far fa-chevron-circle-down'
                                                : 'fa-chevron-circle-up'
                                            }`}
                                          />
                                          {el.category}
                                        </h5>
                                      </Accordion.Toggle>
                                      <div className={`service-edit-icon ${renderSortProduct && 'add-sort'}`}>
                                        {renderSortProduct ? (
                                          <div className={`sorting up-down ${renderSortProduct && 'active-sort'}`}>
                                            {i !== 0 ? (
                                              <img
                                                src={upsort}
                                                alt="sorting"
                                                onClick={() => moveArr(i, i - 1, 'product', el.order - 1, 'minus')}
                                              />
                                            ) : null}
                                            {i !== servicesList.filter((data) => data.type === 'product').length - 1 ? (
                                              <img
                                                src={downsort}
                                                alt="sorting"
                                                onClick={() => moveArr(i, i + 1, 'product', el.order + 1, 'plus')}
                                              />
                                            ) : null}
                                          </div>
                                        ) : null}
                                        <Dropdown>
                                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            <img src={showmore} alt="show" />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {/* {
                                          i === 0 && el.subCategory.length >= 1 ? null : ( */}
                                            <div className="action" onClick={() => handleOpenSubCat(el.category, el.type, i, el._id)}>
                                              <img src={greyplus} alt="edit" />
                                              Add
                                            </div>
                                            {/* )
                                        } */}
                                            <div
                                              className="action"
                                              onClick={() => handleEditCategory(el._id, el.category, el.type, i, el.appointmentChecklist)}
                                            >
                                              <img src={greyedit} alt="edit" />
                                              Edit
                                            </div>
                                            {el.category.match(/Misc/g) ? null : (
                                              <div className="action" onClick={() => submitRemoveCategory(el._id, el.type)}>
                                                <img src={greydelete} alt="edit" />
                                                Delete
                                              </div>
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </div>

                                    {/* <div className="table-icons">
                  <Button onClick={() => handleEditCategory(el._id, el.category)}><i className="fas fa-pencil-alt" /></Button>
                  <Button onClick={() => submitRemoveCategory(el._id)}><i className="far fa-trash-alt" /></Button>
                  <i className="fa fa-plus" onClick={() => handleOpenSubCat(el.category)} />
                </div> */}
                                  </Card.Header>
                                  <Accordion.Collapse eventKey={i.toString()} className={`${openProduct === i ? 'show' : ''}`}>
                                    <Card.Body>
                                      <div className="inner-accordion">
                                        {el.subCategory.map((data, index) => (
                                          <>
                                            <Accordion
                                              defaultActiveKey={i.toString()}
                                              onClick={() => handleNestedAccordion(index)}
                                            >
                                              <Card>
                                                <Accordion>
                                                  <Card.Header className="sub-category">
                                                    <Accordion.Toggle
                                                      as={Button}
                                                      variant="link"
                                                      eventKey={index.toString()}
                                                    >
                                                      <div className="product-service-list">
                                                        <i
                                                          className={`fas ${
                                                            selectedNestedAccordion.includes(index)
                                                              ? 'far fa-chevron-circle-down'
                                                              : 'far fa-chevron-circle-up'
                                                          }`}
                                                        />
                                                        <span>
                                                          {`${data.name}`}
                                                          <span className="span-measure inner-measure">
                                                            {data.soldPer === 'Add Custom Measure'
                                                              ? `per ${data.unit} (${data.symbol})`
                                                              : `per ${data.soldPer}`}
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </Accordion.Toggle>
                                                    <span className="time-category">
                                                      {renderSortProduct ? (
                                                        <div className="sorting up-down">
                                                          {index !== 0 ? (
                                                            <img
                                                              src={upsort}
                                                              alt="sorting"
                                                              onClick={() => moveArrSubcategory(el._id, index, index - 1)}
                                                            />
                                                          ) : null}
                                                          {index !== el.subCategory.length - 1 ? (
                                                            <img
                                                              src={downsort}
                                                              alt="sorting"
                                                              onClick={() => moveArrSubcategory(el._id, index, index + 1)}
                                                            />
                                                          ) : null}
                                                        </div>
                                                      ) : null}

                                                      {!renderSortProduct ? (
                                                        <div className="timehour">{convertToHour(data.duration)}</div>
                                                      ) : null}
                                                    </span>
                                                    {/* <div className="arrow-icons">
                              <i className="fas fa-arrow-alt-circle-up" />
                              <i className="fas fa-arrow-alt-circle-down" />
                            </div> */}
                                                  </Card.Header>
                                                  <Accordion.Collapse
                                                    eventKey={index.toString()}
                                                    key={data.name + Math.random()}
                                                  >
                                                    <ul className="grid-list">
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>
                                                            Product Code
                                                            {/* <span> (as will display on invoices) </span> */}
                                                          </h3>
                                                          <div className="grid-content">
                                                            {data.productCode ? data.productCode : '-'}
                                                          </div>
                                                        </div>
                                                      </li>
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>Barcode</h3>
                                                          <div className="grid-content">
                                                            {data.barCode ? data.barCode : '-'}
                                                          </div>
                                                        </div>
                                                      </li>
                                                      {/* <li className="grid-list-item">
                                                    <div className="grid-box">
                                                      <h3>
                                                        Description
                                                      </h3>
                                                      <div className="grid-content">{data.serviceDescription}</div>
                                                    </div>
                                                  </li> */}
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>
                                                            Supplier
                                                          </h3>
                                                          <div className="grid-content">
                                                            {data.supplier ? data.supplier : '-'}
                                                          </div>
                                                        </div>
                                                      </li>
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>
                                                            Product Sold Per
                                                            {/* <span> (as will display on invoices) </span> */}
                                                          </h3>
                                                          <div className="grid-content">{data.soldPer}</div>
                                                        </div>
                                                      </li>
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>
                                                            Tracked Inventory Product
                                                            {/* <span> (as will display on invoices) </span> */}
                                                          </h3>
                                                          <div className="grid-content">{data.trackedInventory}</div>
                                                        </div>
                                                      </li>
                                                      {/* <li className="grid-list-item">
                                                    <div className="grid-box">
                                                      <h3 className="yellow-text">
                                                        Supply Unit
                                                        {' '}
                                                      </h3>
                                                      <div className="grid-content">{data.supplyUnit ? data.supplyUnit : 'N/A'}</div>
                                                    </div>
                                                  </li> */}
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>
                                                            Duration
                                                          </h3>
                                                          <div className="grid-content">{data.duration ? convertToHour(data?.duration) : '-'}</div>
                                                        </div>
                                                      </li>
                                                      {/* <li className="grid-list-item">
                                                    <div className="grid-box">
                                                      <h3>Time</h3>
                                                      <div className="grid-content">{convertToHour(data.duration)}</div>
                                                    </div>
                                                  </li> */}
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>Tax</h3>
                                                          <div className="grid-content">
                                                            {`${data.tax ? data.tax : 0}%`}
                                                          </div>
                                                        </div>
                                                      </li>
                                                      {!el.category.toLowerCase().includes('misc') ? (
                                                        <>
                                                          <li className="grid-list-item">
                                                            <div className="grid-box">

                                                              <h3>
                                                                {' '}
                                                                <span>
                                                                  Average
                                                                </span>
                                                                {' '}
                                                                Buy Cost (ex tax)

                                                              </h3>
                                                              <div className="grid-content">
                                                                $
                                                                {`${addZero(data?.cost)}`}
                                                                <span className="span-measure">
                                                                  {data.soldPer === 'Add Custom Measure'
                                                                    ? `per ${data.unit} (${data.symbol})`
                                                                    : `per ${data.soldPer}`}
                                                                </span>
                                                              </div>

                                                            </div>
                                                          </li>
                                                          <li className="grid-list-item">
                                                            <div className="grid-box">

                                                              <h3>
                                                                <span>
                                                                  Average
                                                                </span>
                                                                {' '}
                                                                Sell Price (ex tax)

                                                              </h3>
                                                              <div className="grid-content">
                                                                $
                                                                {`${addZero(data?.retailCost)}`}
                                                                <span className="span-measure">
                                                                  {data?.soldPer === 'Add Custom Measure'
                                                                    ? `per ${data?.unit} (${data?.symbol})`
                                                                    : `per ${data?.soldPer}`}
                                                                </span>
                                                              </div>

                                                            </div>
                                                          </li>
                                                        </>
                                                      ) : null}
                                                      <li className="grid-list-item">
                                                        <div className="grid-box">
                                                          <h3>Actions</h3>
                                                          <div className="action-btn">
                                                            <Button
                                                              className="edit-btn"
                                                              onClick={() => handleEditSubcategory(index, el._id, 'product', el.category, el.type)}
                                                            >
                                                              <img src={whiteedit} alt="edit" />
                                                            </Button>
                                                            {!el.category.toLowerCase().includes('misc') ? (
                                                              <Button
                                                                className="delete-btn"
                                                                onClick={() => submitRemove(index, el._id, el.category, el.type, data.s_uId)}
                                                              >
                                                                <img src={whitedelete} alt="edit" />
                                                              </Button>
                                                            ) : null}
                                                            {/* <Button className="delete-btn">
                    <span><i className="far fa-trash-alt" /></span>
                  </Button> */}
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </ul>
                                                  </Accordion.Collapse>
                                                </Accordion>
                                              </Card>
                                            </Accordion>
                                          </>
                                        ))}
                                      </div>
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Accordion>
                              ))}
                          </div>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                </div>
                <div className="web-servicesproducts">
                  <WebProductServices
                    setIsServiceAccordOpen={setIsServiceAccordOpen}
                    renderSort={renderSort}
                    setRenderSort={setRenderSort}
                    HandleOpenClose={HandleOpenClose}
                    servicesList={servicesList}
                    handleUpAccordion={handleUpAccordion}
                    selectedNestedAccordion={selectedNestedAccordion}
                    handleNestedAccordion={handleNestedAccordion}
                    openService={openService}
                    selectedAccordion={selectedAccordion}
                    handleEditCategory={handleEditCategory}
                    handleOpenSubCat={handleOpenSubCat}
                    moveArr={moveArr}
                    submitRemoveCategory={submitRemoveCategory}
                    convertToHour={convertToHour}
                    addZero={addZero}
                    handleEditSubcategory={handleEditSubcategory}
                    submitRemove={submitRemove}
                    moveArrSubcategory={moveArrSubcategory}
                    renderSortProduct={renderSortProduct}
                    setRenderSortProduct={setRenderSortProduct}
                    openProduct={openProduct}
                  />
                </div>
                {renderSort || renderSortProduct ? (
                  <div className="view-btn">
                    <Button type="submit" className="btn btn-save" onClick={updateSorting}>
                      Save/Update
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        {/* </div> */}
        <AddCategory
          show={show}
          HandleOpenClose={HandleOpenClose}
          handleManageCategory={handleManageCategory}
          category={category}
          setCategory={setCategory}
          servicesList={servicesList}
          type={type}
          appointmentChecklist={appointmentChecklist}
          handleManageChecklist={handleManageChecklist}
          checklist={checklist}
          handleChecklist={handleChecklist}
          checklistName={checklistName}
          setChecklistName={setChecklistName}
          removeList={removeList}
          inputDisabled={inputDisabled}
          setInputDisabled={setInputDisabled}
          editList={editList}
          moveArrCheckList={moveArrCheckList}
          error={errorValue}
        />
        <ServiceProductForm
          showSubCat={showSubCat}
          setShowCat={setShowCat}
          subcategory={subcategory}
          handleChange={handleChange}
          handleManageSubCat={handleManageSubCat}
          handleUpdateSubCategory={handleUpdateSubCategory}
          editData={editData}
          renderOptions={renderOptions}
          type={type}
          withValueLimitSellPrice={withValueLimitSellPrice}
          handleDurationPicker={handleDurationPicker}
          convertToReadableDuration={convertToReadableDuration}
          buysymbol={buysymbol}
          sellsymbol={sellsymbol}
          category={category}
          locationData={locationData}
          teamMemberData={teamMemberData}
          selectedLocation={selectedLocation}
          handleStock={handleStock}
          hideLocStaff={hideLocStaff}
          selectedStaff={selectedStaff}
          setSelectedStaff={setSelectedStaff}
          setTeamMemberData={setTeamMemberData}
          setLocationData={setLocationData}
          selectedLocationValue={selectedLocationValue}
          setSelectedLocationValue={setSelectedLocationValue}
          error={errorValue}
        />
        {durationPicker ? (
          <DurationPicker
            durationPicker={durationPicker}
            setDurationPicker={setDurationPicker}
            durationData={durationData}
            handleDurationPicker={handleDurationPicker}
            setDuration={setDuration}
            setBookingData={null}
            convertToReadableDuration={convertToReadableDuration}
            bookingData={null}
            subcategory={subcategory}
            setSubCategory={setSubCategory}
          />
        ) : null}
        {deletePopUp && (
        <DeletePopUp
          PopUpText={popUpText}
          handlePopUpChange={handlePopUpChange}
          setDeletePopUp={setDeletePopUp}
          paraText={paraText}
        />
        )}
      </AdminWrapper>
    </>
  );
};
ProductServices.defaultProps = {
  show: false,
  show1: false,
  HandleOpenClose: PropTypes.func,
  handleOpenSubCat: PropTypes.func,
};

export default ProductServices;
