/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import {
  Button, Modal, Dropdown, Form, Accordion, Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import logo from '../../assets/home/temp-logo.png';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import showmore from '../../assets/home/showmoreblue.png';
import AddSignature from './addSignature';
import ImageDetailsEdit from './imageDetailsEdit';
import { removeCodeFromNumber, showErrMsg, validateDataWithKey } from '../../functions/index';
import { emailRegex } from '../../config/keys';
import uncheckimg from '../../assets/home/uncheck.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import {
  errorMsgTime, successMsgTime, errorBorderTime, nameRegex,
} from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const SignatureTemplate = ({ viewtype }) => {
  const initialState = {
    firstlastname: '',
    complimentary: 'Yours sincerely,',
    mobileNo: '',
    email: '',
    title: '',
  };
  const [selectScaling, setSelectScaling] = useState('Original');
  let initialCSS = {
    text: '',
    url: '',
    type: 'img',
    index: 0,
    style: {
      imageHeight: 100,
      imageWidth: 100,
      imageMarginLeft: 0,
      imageMarginRight: 0,
      imageMarginTop: 0,
      imageMarginBottom: 0,
      imageRotate: 0,
      imageUrl: '',
    },
    tdStyle: {
    },
  };
  const limit = 10;
  const [skip, setSkip] = useState(0);
  const [customObject, setCustomObject] = useState(initialCSS);
  const [payload, setPayload] = useState(initialState);
  const [tempview, setTempView] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [selectedCountry, setCountry] = useState('+61');
  const [addImagepopup, setAddImagePopup] = useState(false);
  const [customFeilds, setCustomFeilds] = useState([]);
  const [uploadImg, setUploadImg] = useState();
  const [signatureList, setSignatureList] = useState([]);
  const [showHide, setShowHide] = useState(null);
  const [editId, setEditId] = useState();
  const [pageCounts, setPageCounts] = useState(1);
  const [errorValue, setErrorValue] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const updateView = (type) => {
    setTempView(type);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'firstlastname') {
      if (value && !(nameRegex.test(value))) {
        return;
      }
    }
    setPayload({ ...payload, [name]: value });
  };
  const handleFormModal = () => {
    setEditId('');
    setPayload(initialState);
    setCustomObject(initialCSS);
    setShowForm(!showForm);
    setUploadImg();
  };

  const getSignatureList = async () => {
    try {
      const response = await userInstance().get(`/staff/getSignatureList?limit=${limit}&skip=${skip}`);
      const { list, count, code } = response.data;
      if (code === 200) {
        setPageCounts(count);
        setSignatureList(list);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleAddSignature = async (e) => {
    e.preventDefault();
    try {
      const {
        firstlastname,
        complimentary,
        email,
        title,
        mobileNo,
      } = payload;
      let { isValid, emptyField } = validateDataWithKey({
        'First & Last Name': firstlastname,
        title,
        'mobile no': mobileNo,
        email,
      });
      if (isValid) {
        let isMobileValid = true;
        let errorMsg = '';
        if (emailRegex.test(String(payload.email).toLowerCase())) {
          if (selectedCountry === '+61') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }

            if (payload.mobileNo === '') {
              errorMsg = 'Please enter a mobile number';
              isMobileValid = false;
            }
            if (payload.mobileNo.charAt(0) !== '4') {
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setErrorValue('mobileno');
            }
            if (payload.mobileNo.trim().length !== 11) {
              setErrorValue('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }

          if (selectedCountry === '+1') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }

            if (payload.mobileNo.charAt(0) !== '4') {
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setErrorValue('mobileno');
            }
            if (payload.mobileNo.trim().length !== 12) {
              setErrorValue('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }

          if (isMobileValid) {
            if (payload?.mobileNo) {
              payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
            }
            if (editId) {
              await handleEditSignature(payload);
            } else {
              if (!uploadImg) {
                setErrorValue('signature');
                toastMessage('error', 'Please upload signature.');

                return;
              }
              await saveAddSignature(payload);
            }
          } else {
            setErrorValue('mobileNo');
            toastMessage('error', errorMsg);
          }
        } else {
          setErrorValue('email');
          toastMessage('error', 'Invalid email.');
        }
      } else {
        setErrorValue(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleEditSignature = async (payloaddata) => {
    let editpayload = payloaddata;
    editpayload.image = customObject;
    const response = await userInstance().post(
      '/staff/editSignature',
      editpayload,
    );
    const { msg, code } = response.data;
    if (code === 200) {
      toastMessage('success', 'Success');

      setShowForm(false);
      if (uploadImg) {
        await UploadImage(editId);
      }
      setCustomObject(initialCSS);
      setPayload(initialState);
      setCustomFeilds([]);
      getSignatureList();
    } else {
      toastMessage('error', msg);
    }
  };
  const saveAddSignature = async (payloaddata) => {
    if (uploadImg) {
      const response = await userInstance().post(
        '/staff/addSignature',
        payloaddata,
      );
      const { msg, code, id } = response.data;
      if (code === 200) {
        toastMessage('success', 'Success');

        setShowForm(false);
        await UploadImage(id);
        setCustomObject(initialCSS);
        setPayload(initialState);
        setCustomFeilds([]);
        getSignatureList();
      } else {
        toastMessage('error', msg);
      }
    } else {
      setErrorValue('signature');
      toastMessage('error', 'Please upload signature.');
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };
  const UploadImage = async (id) => {
    try {
      const formdata = new FormData();
      formdata.append('file', uploadImg);
      formdata.append('id', id);
      formdata.append('customObject', JSON.stringify(customObject));
      await userInstance().post('/staff/uploadSignature', formdata);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = payload.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (payload.mobileNo) {
      obj.mobileNo = `${payload.mobileNo.replace(
        textToReplace,
        '',
      )}`;
    }
    setPayload({ ...payload, ...obj });
  };
  const AddImagePopup = () => {
    setAddImagePopup(!addImagepopup);
  };
  const setTemplateLogo = (data) => {
    if (data.target.files[0]) {
      setUploadImg(data.target.files[0]);
      setCustomObject({
        ...customObject,
        text: window.URL.createObjectURL(data.target.files[0]),
        url: '',
        index: customFeilds.length,
      });
    }
  };
  useEffect(() => {
    getSignatureList();
  }, [skip]);
  const [selectedNestedAccordion, setSelectedNestedAccordion] = useState([]);
  const handleNestedAccordion = (e) => {
    // if (selectedNestedAccordion && selectedNestedAccordion.includes(e)) {
    //   const data = selectedNestedAccordion.filter((el) => el !== e);
    //   return setSelectedNestedAccordion(data);
    // }
    // return setSelectedNestedAccordion([...selectedNestedAccordion, e]);
  };
  const handleEditStaff = (value) => {
    let copySelectedCountry = selectedCountry;
    if (value?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(value.mobileNo);
      value.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }
    const {
      name, complimentary, email, title, mobileNo, _id, image,
    } = value || {};
    setCountry(copySelectedCountry);
    setShowForm(!showForm);
    setPayload({
      ...payload,
      firstlastname: name,
      complimentary,
      mobileNo,
      email,
      title,
      id: _id,
      image: customObject,
    });
    setEditId(_id);
    setCustomObject(image);
  };
  const signatureRemove = async (id) => {
    try {
      await userInstance().put(
        `/staff/deleteSignature?id=${id}`,
      );
      toastMessage('success', 'Signature deleted successfully');

      getSignatureList();
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };
  const handleManageVisible = async ({ hide, _id }) => {
    // try {
    //   const response = await userInstance().put('/staff/editVisible', {
    //     _id,
    //     hide,
    //   });
    //   getStaffList();
    //   NotificationManager.success(response.data.msg, 'Message', 1000);
    // } catch (error) {
    //     console.log(error);
    // }
  };
  const pageCount = Math.ceil(pageCounts / limit);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };
  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);
  const renderStaff = (staff, i) => (
    <Accordion className="mob-accordion">
      <Card>
        <Card.Header>
          <div className="staff-main-heading">
            <Accordion.Toggle as={Button} variant="link" eventKey={i.toString()} onClick={() => handleNestedAccordion(i)}>
              {/* <i className="fas fa-chevron-circle-down" /> */}
              <i className={`fas ${selectedNestedAccordion.includes(i) ? 'far fa-chevron-circle-down' : 'far fa-chevron-circle-up'}`} />
              <span>
                {`${staff.name}`}
              </span>
            </Accordion.Toggle>
          </div>

        </Card.Header>
        <Accordion.Collapse eventKey={i.toString()}>
          <Card.Body>
            <ul className="staff-list">
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Complimentary Close</h3>
                  <h5>
                    {`${staff.complimentary}`}

                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Signature</h3>
                  <h5>
                    Added

                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>First & Last Name</h3>
                  <h5>
                    {`${staff.name}`}
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Title</h3>
                  <h5>
                    {`${staff.title}`}
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Mobile</h3>
                  <h5>
                    <a className="mobile" href={`tel:${staff.mobileNo}`}>
                      {`${staff.mobileNo}`}
                    </a>
                  </h5>
                </div>
              </li>

              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Email</h3>
                  <h5>
                    <a className="email" href={`mailto:${staff.email}`}>
                      {staff.email}
                    </a>
                  </h5>
                </div>
              </li>
              <li className="staff-list-item">
                <div className="staff-box">
                  <h3>Actions</h3>
                  <div className="action-btn">
                    <Button className="edit-btn" onClick={() => handleEditStaff(staff)}>
                      <img src={whiteedit} alt="edit-img" />
                    </Button>
                    <Button className="delete-btn" onClick={() => signatureRemove(staff._id)}>
                      <img src={whitedelete} alt="edit-img" />
                    </Button>
                    {/* {!staff.hide ? (
                      <Button className="eye-btn" onClick={() => handleManageVisible({ _id: staff._id, hide: true })}>
                        <i className="fas fa-eye" />
                      </Button>
                    ) : staff.hide && showHide ? (
                      <Button className="eyehide-btn" onClick={() => handleManageVisible({ _id: staff._id, hide: false })}>
                        <i className="fas fa-eye-slash" />
                      </Button>
                    ) : null} */}
                  </div>
                </div>
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
  return (
    <div className="staff-container">
      <div className="target-heading client-profile">
        <>
          <div className="">
            <h3>
              Staff Signature Template
            </h3>
          </div>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => handleFormModal()}>
                      <span>Add Staff Signature Template</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </>
      </div>

      <div className="staff-container">
        <div className="mob-table">
          {signatureList ? (
            <>
              {signatureList.length === 0 ? (
                <div className="placehoder-text">
                  No Staff Found
                </div>
              ) : (
                <>
                  {signatureList.map((staff, i) => {
                    let jsxData = null;
                    jsxData = renderStaff(staff, i);
                    return jsxData;
                  })}
                </>
              ) }
            </>
          ) : null}
        </div>
      </div>
      {signatureList ? (
        <div className="user-pagination">
          <ReactPaginate
            previousLabel="← Previous"
            nextLabel="Next →"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination-chk"
            previousLinkClassName="pagination__link"
            nextLinkClassName="pagination__link"
            disabledClassName="pagination__link--disabled"
            activeClassName="pagination__link--active"
          />
          <div className="no-of-page">
            Page
            {' '}
            {selectedPage}
            {' '}
            of
            {' '}
            {' '}
            {pageCount}
          </div>
        </div>
      ) : null}

      {showForm && <AddSignature showForm={showForm} setShowForm={setShowForm} payload={payload} handleChange={handleChange} handleFormModal={handleFormModal} handleAddSignature={handleAddSignature} handleCountry={handleCountry} selectedCountry={selectedCountry} AddImagePopup={AddImagePopup} setTemplateLogo={setTemplateLogo} customObject={customObject} editId={editId} error={errorValue} />}
      {addImagepopup && (
        <ImageDetailsEdit
          showPopup={addImagepopup}
          handleImagePopup={AddImagePopup}
          customFeilds={customFeilds}
          setCustomFeilds={setCustomFeilds}
          customObject={customObject}
          setCustomObject={setCustomObject}
          selectScaling={selectScaling}
          setSelectScaling={setSelectScaling}
          setTemplateLogo={setTemplateLogo}
          setUploadImg={setUploadImg}
        />
      )}
    </div>
  );
};
SignatureTemplate.propTypes = {
  viewtype: PropTypes.string.isRequired,
};
export default SignatureTemplate;
