import React, { useState, useEffect, useContext } from 'react';
import {
  Dropdown, Button, Accordion, Card,
} from 'react-bootstrap';
import moment from 'moment';
import { read, utils } from 'xlsx';

import AdminWrapper from '../admin/adminwrapper/wrapper';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import showmore from '../../assets/home/showmoreblue.png';
import './index.css';
import DownloadCSV from './downloadCSV';
import UploadCSV from './uploadCSV';
import RenameCsv from './renameCsv';
import ViewCsv from './viewCsv';
import ViewCsvPopupTable from './viewCsvPopup';
import DeletePopUp from '../DeletePopUp';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import {
  capitalizeFirstLetter,
  checkKeysAndCreateData,
  getTeam,
} from '../../functions';
import {
  uplaodCSVApiData,
  getCsvData,
  updateCsvData,
  deleteCsv,
  checkUploadCsv,
  checkUploadCsvCustomer,
} from './index';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { userInstance } from '../../config/axios';
import download from '../../assets/home/download.png';
import sortClientList from '../../functions/listSort';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';
import UploadIssuePopup from '../Popups/upload-issue-popups';
import UploadErrorTable from './upload-error-table';
import ClientErrortable from './client-error-table';
import {
  staffHeader, headerProduct, headersClient, headerInventory, headerService,
} from './csvStatic';

const CSVUpload = () => {
  const { userData } = useContext(AuthDataContext);
  const {
    industryId, numberUsers, role, userId, accessLevel,
  } = userData || {};
  const [view, setView] = useState('customer');
  const [heading, setHeading] = useState('Clients');
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [uploadCSv, setUploadCSV] = useState(false);
  const [csvFiles, setCsvFiles] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [filesToRemoveArr, setFilesToRemoveArr] = useState([]);
  const [csvDataToRender, setCsvDataToRender] = useState([]);
  const [showRenameCsv, setShowRenameCsv] = useState(false);
  const [showViewCsvPopup, setShowViewCsvPopup] = useState(false);
  const [resultJS, setResultJS] = useState();
  const [showViewCsvPopupTable, setShowViewCsvPopupTable] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delData, setDelData] = useState();
  const [popUpText, setPopUpText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [teamMember, setTeamMemberData] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [allStaff, setAllStaff] = useState([]);
  const [serviceData, setServicesData] = useState([]);
  const [productData, setProductData] = useState([]);
  const paraText = 'Deleting the CSV will remove all client data, but all records saved will be kept';
  const btnText = 'Confirm';
  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [showUploadErrorPopup, setShowUploadErrorPopup] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [chlorinationModelData, setChlorinationModelData] = useState([]);
  const [errorValue, setErrorValue] = useState('');
  const [showUploadError, setShowUploadError] = useState(false);
  const [IsCustomer, setIsCustomer] = useState(false);
  const [mainLoading, setmainLoading] = useState(false);

  // Open close Downlaod csv
  const handleDownload = () => {
    setDownloadCSV(true);
  };
  const closeDownloadModal = () => {
    let copyData = [...csvDataToRender];
    copyData?.forEach((element) => {
      element.isDownload = false;
    });
    setCsvDataToRender(copyData);
    setDownloadCSV(false);
  };
  const closeViewModal = () => {
    setShowViewCsvPopup(false);
  };

  const handleUpload = () => {
    setUploadCSV(!uploadCSv);
    handleRemoveData();
  };

  const getAllClients = async () => {
    const response = await userInstance().get('/customer/getCustomersAll2');
    if (response.status === 200) {
      const { customers } = response.data;
      const newList2 = sortClientList(customers);
      let newArr = [];
      const removeKeys = ['chlorinatorModel', 'siteAddresses', 'sort', 'associatedContacts',
        'showBookings', 'userid', 'contactId', '_id', 'selectedModel', 'selectedModelObj'];
      newList2.forEach((el) => {
        let obj = el;
        Object.keys(obj).forEach((elem) => {
          if (removeKeys.includes(elem)) {
            delete obj[elem];
          }
        });
        let entries = Object.entries(obj);
        let fromIndex;
        let toIndex;
        let capsEntries = entries.map((entry, i) => {
          let keyValues = entry;
          if (keyValues[0] === 'poolHeight') {
            keyValues = ['PoolLength', entry[1]];
          } else if (keyValues[0] === 'customerType') {
            keyValues = ['ContactType', entry[1]];
            toIndex = i + 1;
          } else if (keyValues[0] === 'companyName') {
            keyValues = ['Company Name', entry[1]];
          } else if (keyValues[0] === 'country') {
            keyValues = ['Country', entry[1]];
          } else if (keyValues[0] === 'firstname') {
            keyValues = ['ContactsFirst Name', entry[1]];
          } else if (keyValues[0] === 'lastname') {
            keyValues = ['ContactsLast Name', entry[1]];
          } else if (keyValues[0] === 'mobileNo') {
            keyValues = ['ContactsMobile Number', entry[1]];
          } else if (keyValues[0] === 'landlineNo') {
            keyValues = ['ContactsLandlineNo', entry[1]];
          } else if (keyValues[0] === 'state') {
            keyValues = ['State', entry[1]];
          } else if (keyValues[0] === 'city') {
            keyValues = ['City', entry[1]];
          } else if (keyValues[0] === 'suburb') {
            keyValues = ['City/Town/Suburb', entry[1]];
          } else if (keyValues[0] === 'poolWidth') {
            keyValues = ['Pool Width', entry[1]];
          } else if (keyValues[0] === 'streetAddress') {
            keyValues = ['Street Address', entry[1]];
          } else if (keyValues[0] === 'poolFinish') {
            keyValues = ['PoolFinish', entry[1]];
          } else if (keyValues[0] === 'chlorinationType') {
            keyValues = ['ChlorinationType', entry[1]];
          } else if (keyValues[0] === 'chlorination') {
            keyValues = ['Chlorination', entry[1]];
          } else if (keyValues[0] === 'invoiceSendType') {
            keyValues = ['AutomaticInvoiceSending', entry[1]];
          } else if (keyValues[0] === 'gender') {
            keyValues = ['Gender', entry[1]];
          } else if (keyValues[0] === 'note') {
            keyValues = ['ClientNotes', entry[1]];
          } else if (keyValues[0] === 'postcode') {
            keyValues = ['Postal / Zip Code', entry[1]];
          } else if (keyValues[0] === 'companyName') {
            fromIndex = i;
          }
          return [
            keyValues[0][0].toUpperCase() + keyValues[0].slice(1),
            keyValues[1],
          ];
        });
        const element = capsEntries.splice(fromIndex, 1)[0];
        capsEntries.splice(toIndex, 0, element);
        let capsPopulations = Object.fromEntries(capsEntries);
        newArr.push(capsPopulations);
      });
      setClientList(newArr);
    }
  };
  const getStaffList = async () => {
    try {
      const response = await userInstance().get('/staff/getStaff');
      const { staff } = response.data;
      const newStaffData = staff.map(
        ({
          firstLogin,
          shift,
          hide,
          isOwner,
          isDelete,
          appointments,
          blocktimes,
          istwilio,
          isNumberVerify,
          isEmail,
          isEmailVerify,
          chemicalsToDisplay,
          userid,
          password,
          hexToken,
          createdAt,
          updatedAt,
          __v,
          _id,
          csvName,
          blocktime,
          expiryTime,
          role: roleCopy,
          industryId: industryIdCopy,
          newGeneratedPassword,
          location,
          ...rest
        }) => {
          rest.Location = location?.locationName;
          return rest;
        },
      );

      let newArr = [];
      newStaffData.forEach((el) => {
        let obj = el;
        let entries = Object.entries(obj);
        let capsEntries = entries.map((entry, i) => {
          let keyValues = entry;
          if (keyValues[0] === 'postcode') {
            keyValues = ['ZipCode', entry[1]];
          }
          return [
            keyValues[0][0].toUpperCase() + keyValues[0].slice(1),
            keyValues[1],
          ];
        });
        let capsPopulations = Object.fromEntries(capsEntries);
        newArr.push(capsPopulations);
      });
      setAllStaff(newArr);
    } catch (error) {
      return null;
    }
    return {};
  };

  const onlyNumber = (value) => {
    if (Number(value) >= 0) {
      return value;
    }
    return '';
  };

  const getServices = async () => {
    try {
      const response = await userInstance().get(
        `/service/getServices?industryId=${industryId?._id}`,
      );
      let { services } = response.data;
      const newServiceArr = [];
      const newProductArr = [];
      services.forEach((el) => {
        let obj = {};
        el?.subCategory.forEach((ele) => {
          if (el.type === 'service') {
            obj.Name = ele.name;
            obj.Category = el?.category;
            obj.AppointmentChecklist = el.appointmentChecklist[0] || '';
            obj.Description = ele.serviceDescription;
            obj.Tax = onlyNumber(ele?.tax || 0);
            obj.Duration = onlyNumber(ele?.duration || 0);
            obj['Buy Cost'] = onlyNumber(ele?.cost || 0);
            newServiceArr.push(obj);
          } else {
            obj.Name = ele.name;
            obj['Product Code'] = ele.productCode;
            obj.Barcode = ele?.barCode;
            obj.Category = el?.category;
            obj.AppointmentChecklist = el.appointmentChecklist[0] || '';
            obj.Description = ele.serviceDescription;
            obj.Supplier = ele.supplier;
            obj['sold Unit'] = ele.unit;
            obj.Tracked = ele.trackedInventory;
            obj['Supply Unit'] = ele.supplyUnit;
            obj.Tax = onlyNumber(ele?.tax);
            obj.Duration = onlyNumber(ele?.duration);
            obj['Buy Cost'] = onlyNumber(ele?.cost);
            obj['Retail Cost'] = ele?.retailCost;
            newProductArr.push(obj);
          }
        });
      });
      setServicesData(newServiceArr);
      setProductData(newProductArr);
    } catch (error) {
      console.log(error);
    }
  };
  function getLoacation(array) {
    let locations = '';
    array.forEach((el) => {
      if (el?.location?.locationName) {
        locations = `${locations}, ${el?.location?.locationName}`;
      }
    });
    return locations?.replace(/,/, '');
  }

  function getStaff(array) {
    let temaMember = '';
    array.forEach((el) => {
      temaMember = `${temaMember}, ${el.firstname} ${el.lastname}`;
    });
    return temaMember?.replace(/,/, '');
  }
  function getStocks(array) {
    let stock = '';
    array.forEach((el) => {
      stock = `${stock}, ${el.stockValue}`;
    });
    return stock?.replace(/,/, '');
  }

  const getInventory = async () => {
    const response = await userInstance().get(
      `/inventory/getInventoryProducts?industryId=${industryId?._id}`,
    );
    const { products, code } = response.data;
    const newProductArr = [];
    if (code === 201) {
      products.forEach((ele) => {
        let obj = {};
        obj.Name = ele?.name || '';
        obj['Product Code'] = ele?.productCode;
        obj.Barcode = ele?.barCode;
        obj.Category = ele?.inventoryId?.category;
        obj.AppointmentChecklist = ele?.inventoryId?.appointmentChecklist[0] || '';
        obj.Description = ele?.serviceDescription;
        obj.Supplier = ele?.supplier;
        obj['sold Unit'] = ele.unit;
        obj.Tracked = ele.trackedInventory;
        obj['Supply Unit'] = ele.supplyUnit;
        obj.Tax = onlyNumber(ele?.tax);
        obj.Duration = onlyNumber(ele?.duration);
        obj['Buy Cost'] = onlyNumber(ele?.cost);
        obj['Retail Cost'] = ele?.retailCost;
        obj.Location = getLoacation(ele?.staffStockData);
        obj['Team Member'] = getStaff(ele?.staffStockData);
        obj.stock = getStocks(ele?.staffStockData);
        newProductArr.push(obj);
      });
      setInventoryData(newProductArr);
    }
  };

  const checkHeading = (viewType, headingArr) => {
    // console.log({ viewType, headingArr });
    if (
      (viewType === 'Clients'
                && (headingArr.includes('ContactType')
                    || headingArr.includes('CustomerType')
                    || headingArr.includes('ContactsFirst Name')))
            || (viewType === 'Staffs' && headingArr.includes('Location'))
            || (viewType === 'Inventory'
                && headingArr.includes('Supplier')
                && headingArr.includes('Tracked')
                && headingArr.includes('Sell Price'))
            || (viewType === 'Product'
                && headingArr.includes('Supplier')
                && headingArr.includes('Sell Price')
                && !headingArr.includes('trackedInventory'))
            || (viewType === 'Service'
                && headingArr.includes('Buy Cost')
                && !headingArr.includes('Supplier'))
    ) {
      return true;
    }
    toastMessage(
      'error',
      `Please upload ${viewType.toLowerCase()} csv/excel.`,
    );

    return false;
  };

  const handleEditCsv = (el, i) => {
    let newName = el?.name?.substring(0, el?.name?.lastIndexOf('.'));
    let ext = el?.name?.substring(el?.name?.lastIndexOf('.'));
    setEditData({
      name: newName,
      _id: el?._id,
      index: i,
      ext,
      oldName: el?.name,
    });
    setShowRenameCsv(true);
  };
  const closeRenameModal = () => {
    setShowRenameCsv(false);
  };
  // this function will upload the csv file Step 2 of 2
  const checkCsvHasErrorAndUpload = async () => {
    if (view === 'customer') {
      const res = await checkUploadCsvCustomer(csvData);
      if (res?.data?.code === 200 && res?.data?.errorData.totalMissing > 0) {
        setIsCustomer(true);
        setShowUploadErrorPopup(true);
        setUploadErrors(res?.data?.errorData);
        return;
      }
    } else if (view === 'staff') {
      const res = await checkUploadCsv(view, csvData);
      if (res?.data?.code === 200 && res?.data?.errorData.length > 0) {
        setShowUploadErrorPopup(true);
        setUploadErrors(res?.data?.errorData);
        return;
      }
    }
    setUploadErrors([]);
    uploadCsvApi();
  };

  const uploadCsvApi = async () => {
    const formData = new FormData();
    csvFiles.forEach((el) => {
      formData.append('csvFiles', el);
    });
    const csvDataNew = JSON.stringify(csvData);
    formData.append('csvData', csvDataNew);
    const numberOfUsers = accessLevel === 'owner'
      ? Number.MAX_SAFE_INTEGER
      : numberUsers || 3;
    formData.append('numberUsers', JSON.stringify(numberOfUsers));
    formData.append('industryId', JSON.stringify(industryId._id));
    formData.append('role', JSON.stringify(role ?? 'professional-user'));
    if (csvData.length > 0) {
      setIsLoading(true);
      setmainLoading(true);
      const response = await uplaodCSVApiData(view, formData);
      console.log({ response });
      setIsLoading(false);
      setmainLoading(false);
      if (response.data.code === 200) {
        toastMessage('success', 'CSV uploaded');

        if (view === 'service') {
          getCsvDataFn(view);
        } else {
          setCsvDataToRender([
            ...csvDataToRender,
            ...response.data.data,
          ]);
        }
        setUploadCSV(false);
      } else {
        toastMessage('error', response.msg);
        setUploadCSV(false);
      }
    } else {
      toastMessage('error', 'No data to upload.');
    }
  };
  // this function will just upload the file for select option only Step 1 of 2 Steps
  const handleUplaodChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      var filename = file.name;
      // pre-process data
      var binary = '';
      var bytes = new Uint8Array(e.target.result);
      var length = bytes.byteLength;
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      // call 'xlsx' to read the file
      var wb = read(binary, { type: rABS ? 'binary' : 'array' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_json(ws);
      const checkHeadingValue = checkHeading(
        heading,
        Object.keys(data[0]),
      );
      if (checkHeadingValue) {
        setCsvFiles([file]);
        console.log({ file });
        uploadCSVClient(data, file?.name);
        setShowRemoveButton(true);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadCSVClient = (data, fileName) => {
    const { mainData, readyToUpload, errorAtRows } = checkKeysAndCreateData(
      data,
      fileName,
      view,
      chlorinationModelData,
      teamMember,
      userId,
    );
    setCsvData(mainData);
    // if (readyToUpload) {
    //   if (view === 'customer') {
    //     if (mainData.length > 0) {
    //       const uniqueDataByEmail = [];

    //       const unique = mainData.filter((element, i) => {
    //         const isDuplicate = uniqueDataByEmail.includes(element.email);

    //         if (!isDuplicate) {
    //           uniqueDataByEmail.push(element.email);
    //           return true;
    //         }
    //         duplicateRows = `${duplicateRows}, ${(i + 2).toString()}`;
    //         return false;
    //       });
    //       if (mainData.length === unique.length) {
    //         setCsvData(mainData);
    //       } else {
    //         // this error shows rows number at which duplicacy found
    //         NotificationManager.error(
    //           `Duplicate email found in row- ${duplicateRows?.replace(
    //             /,/,
    //             '',
    //           )}. Please check and upload again`,
    //           'Message',
    //           errorMsgTime,
    //         );
    //       }
    //     }
    //   } else if (
    //     view === 'service'
    //     || view === 'product'
    //     || view === 'inventory'
    //     || view === 'staff'
    //   ) {
    //     setCsvData(mainData);
    //   }
    // } else {
    //   // this error shows rows number at which row error occured
    //   NotificationManager.error(
    //     `There is field missings in row- ${errorAtRows?.replace(
    //       /,/,
    //       '',
    //     )}. Please check and upload again.`,
    //     'Message',
    //     errorMsgTime,
    //   );
    // }
    // uncomment when multiple upload. Start------->
    // let duplicateRows = '';
    // const { mainData, readyToUpload, errorAtRows } = checkKeysAndCreateData(data, fileName);
    // if (readyToUpload) {
    //   if (mainData.length > 0) {
    //     const uniqueDataByEmail = [];

    //     const unique = mainData.filter((element, i) => {
    //       const isDuplicate = uniqueDataByEmail.includes(element.email);

    //       if (!isDuplicate) {
    //         uniqueDataByEmail.push(element.email);
    //         return true;
    //       }
    //       duplicateRows = `${duplicateRows}, ${(i + 2).toString()}`;
    //       return false;
    //     });
    //     if (mainData.length === unique.length) {
    //       return { status: true, mainData };
    //     }
    //     return { status: false, mainData: [] };
    //   }
    // } else {
    //   return { status: false, mainData: [] };
    // }
    // return { status: false, mainData: [] };
    // uncomment when multiple upload. End------->
  };

  const handleRemoveData = () => {
    setCsvFiles([]);
    setCsvData([]);
    setShowRemoveButton(false);
    setUploadErrors([]);
    setShowUploadErrorPopup(false);
  };

  const getCsvDataFn = async (type) => {
    setLoading(true);
    const res = await getCsvData(type);
    setLoading(false);
    if (res.code === 200) {
      setCsvDataToRender(res.csvData);
    }
  };

  const handleSaveEdit = async (editedData) => {
    let {
      name, index, _id, ext, oldName,
    } = editedData;
    if (name.length > 0) {
      setIsLoading(true);
      const updatedName = name + ext;
      const response = await updateCsvData(_id, updatedName, oldName);
      setIsLoading(false);
      if (response.code === 200) {
        toastMessage('success', 'CSV updated');

        if (view === 'service') {
          getCsvDataFn(view);
        } else {
          const copyData = [...csvDataToRender];
          copyData[index].name = updatedName;
          setCsvDataToRender(copyData);
        }
        closeRenameModal();
      } else {
        toastMessage('error', response.msg);
      }
    } else {
      setErrorValue('name');
      // NotificationManager.error('Field is required', 'Message', errorMsgTime);
    }
    setTimeout(() => {
      setErrorValue('');
    }, 1000);
  };
  const closeViewCsvExcel = () => {
    setShowViewCsvPopupTable(false);
    setResultJS([]);
  };
  const submitRemove = (id, index, csvLink) => {
    setPopUpText('Delete CSV?');
    setDeletePopUp(true);
    setDelData({ id, index, csvLink });
  };
  const handlePopUpChange = async (val) => {
    if (val === 'Yes') {
      const res = await deleteCsv(delData.id, delData.csvLink);
      if (res.code === 200) {
        toastMessage('success', 'CSV deleted');
        const renderCsvCopy = [...csvDataToRender];
        renderCsvCopy.splice(delData.index, 1);
        setCsvDataToRender(renderCsvCopy);
      } else {
        toastMessage('error', res.msg);
      }
    }
    setDeletePopUp(false);
  };
  const updateView = (key) => {
    setHeading(key);
    if (key === 'Clients') {
      setView('customer');
    } else if (key === 'Service') {
      setView('service');
    } else if (key === 'Product') {
      setView('product');
    } else if (key === 'Inventory') {
      setView('inventory');
    } else if (key === 'Staffs') {
      setView('staff');
    }
    // setLoderStatus(true);
  };
  const getPoolMineralrsData = async () => {
    const newresponse = await userInstance().get(
      '/poolmineral/getAllPoolMinerals',
    );
    const {
      code,
      mineralList: { chlorinatorModels },
    } = newresponse.data || {};
    if (code === 200) {
      setChlorinationModelData(chlorinatorModels);
    }
  };
  useEffect(() => {
    if (view === 'customer') {
      getPoolMineralrsData();
      getAllClients();
    }
    if (view === 'inventory') {
      getTeam(setTeamMemberData);
      getInventory();
    }
    if (view === 'staff') {
      getStaffList();
    }
    if (view === 'service' || view === 'product') {
      getServices();
    }
    getCsvDataFn(view);
  }, [view]);

  const removeTextLast = (string, removeAt) => {
    let stringValue = string;
    stringValue = stringValue.substr(0, stringValue.lastIndexOf(removeAt));
    return stringValue;
  };
  const addExtension = (string, removeAt) => {
    let stringValue = string;
    stringValue = stringValue.split(removeAt);
    stringValue = stringValue[stringValue.length - 1];
    return `.${stringValue}`;
  };

  const allDownlaodType = () => {
    if (view === 'staff') {
      return allStaff;
    }
    if (view === 'service') {
      return serviceData;
    }
    if (view === 'product') {
      return productData;
    }
    if (view === 'inventory') {
      return inventoryData;
    }
    return clientList;
  };

  const csvHeaderType = () => {
    if (view === 'staff') {
      return staffHeader;
    }
    if (view === 'service') {
      return headerService;
    }
    if (view === 'product') {
      return headerProduct;
    }
    if (view === 'inventory') {
      return headerInventory;
    }
    return headersClient;
  };

  // const handleUplaodChange = (e) => {
  //   if (csvFiles.length > 0) {
  //     // uplaod only 1 file check
  //     NotificationManager.error(
  //       'Please upload 1 file.',
  //       'Message',
  //       errorMsgTime,
  //     );
  //   } else {
  //     const file = [...e.target.files];
  //     if (file?.length < 2) {
  //       // above check is for check multiples file at a time not uploaded
  //       if (file?.length > 0) {
  //         file.forEach(async (el, i) => {
  //           if (el?.name?.includes('.csv')) {
  //             Papa.parse(el, {
  //               complete(results) {
  //                 // const fileHeadings = results.data[0];
  //                 const checkHeadingValue = checkHeading(
  //                   heading,
  //                   results.data[0],
  //                 ); // check csv include customer or staff data or service/product/inventory
  //                 if (checkHeadingValue) {
  //                   setCsvFiles([...csvFiles, ...e.target.files]);
  //                   uploadCSVClient(results.data, el?.name);
  //                 }
  //               },
  //             });
  //           } else if (el?.name?.includes('.xlsx' || '.xls')) {
  //             readXlsxFile(el).then((data) => {
  //               let result = {};
  //               // eslint-disable-next-line no-restricted-syntax
  //               for (const item of data) {
  //                 if (item[1] === 80532) {
  //                   let obj = {
  //                     natural: {
  //                       name: item[4].split('\r\n')[0],
  //                       id: item[4].split('\r\n')[1],
  //                     },
  //                     casual: {
  //                       name: item[5].split('\r\n')[0],
  //                       id: item[5].split('\r\n')[1],
  //                     },
  //                     glam: {
  //                       name: item[6].split('\r\n')[0],
  //                       id: item[6].split('\r\n')[1],
  //                     },
  //                   };
  //                   Object.assign(result, { [item[2]]: obj });
  //                 }
  //               }
  //               if (data) {
  //                 const checkHeadingValue = checkHeading(heading, data[0]);
  //                 if (checkHeadingValue) {
  //                   setCsvFiles([...csvFiles, ...e.target.files]);
  //                   uploadCSVClient(data, el?.name);
  //                 }
  //               }
  //             });
  //           }
  //         });

  //         // Commented code is for multiple file ulpoad.--- Start
  //         // let copyCsvObj = [...csvData];
  //         // const file = [...e.target.files];
  //         // setCsvFiles([...csvFiles, ...e.target.files]);
  //         // if (file?.length <= 10) {
  //         //   if (file?.length > 0) {
  //         //     file.forEach(async (el, i) => {
  //         //       el.checked = false;
  //         //       if (el?.name?.includes('.csv')) {
  //         //         Papa.parse(el, {
  //         //           complete(results) {
  //         //             const { status, mainData } = uploadCSVClient(results.data, el?.name);
  //         //             if (status && mainData.length > 0) {
  //         //               copyCsvObj = [...copyCsvObj, ...[mainData]].flat(Infinity);
  //         //               setCsvData(copyCsvObj);
  //         //             } else {
  //         //               setErrorMessage(`${erroMessage}, ${el?.name}`);
  //         //             }
  //         //           },
  //         //         });
  //         //       } else if (el?.name?.includes('.xlsx' || '.xls')) {
  //         //         readXlsxFile(el).then(
  //         //           (data) => {
  //         //             let result = {};
  //         //             // eslint-disable-next-line no-restricted-syntax
  //         //             for (const item of data) {
  //         //               if (item[1] === 80532) {
  //         //                 let obj = {
  //         //                   natural: {
  //         //                     name: item[4].split('\r\n')[0],
  //         //                     id: item[4].split('\r\n')[1],
  //         //                   },
  //         //                   casual: {
  //         //                     name: item[5].split('\r\n')[0],
  //         //                     id: item[5].split('\r\n')[1],
  //         //                   },
  //         //                   glam: {
  //         //                     name: item[6].split('\r\n')[0],
  //         //                     id: item[6].split('\r\n')[1],
  //         //                   },
  //         //                 };
  //         //                 console.log({ [item[2]]: obj });
  //         //                 Object.assign(result, { [item[2]]: obj });
  //         //               }
  //         //             }
  //         //             if (data) {
  //         //               const { status, mainData } = uploadCSVClient(data, el?.name);
  //         //               if (status && mainData.length > 0) {
  //         //                 copyCsvObj = [...copyCsvObj, ...[mainData]].flat(Infinity);
  //         //                 setCsvData(copyCsvObj);
  //         //               } else {
  //         //                 setErrorMessage(`${erroMessage}, ${el?.name}`);
  //         //               }
  //         //             }
  //         //           },
  //         //         );
  //         //       }
  //         //     });
  //         //   }
  //         // } else {
  //         //   NotificationManager.error('Max limit for upload files is 10', 'Message', errorMsgTime);
  //         // }
  //         // End------
  //       } else {
  //         NotificationManager.error(
  //           'Please upload 1 file.',
  //           'Message',
  //           errorMsgTime,
  //         );
  //       }
  //     } else {
  //       NotificationManager.error(
  //         'Please upload 1 file.',
  //         'Message',
  //         errorMsgTime,
  //       );
  //     }
  //   }
  // };
  const handleCloseIssuePopup = () => {
    setUploadErrors([]);
    setShowUploadErrorPopup(false);
    setIsCustomer(false);
  };
  // this function will ask whether to continuw with upload or open the display error popup
  const confirmSelectUploadError = (selectedOption) => {
    setUploadCSV(false);
    setShowUploadErrorPopup(false);
    if (selectedOption === 'continue') {
      uploadCsvApi();
      setUploadCSV(false);
      setShowUploadErrorPopup(false);
      setShowUploadError();
      setIsCustomer(false);
      setUploadErrors([]);
    } else {
      setShowUploadError(true);
    }
  };

  const confirmSelectUploadErrorFromUpload = (selectedOption) => {
    setUploadCSV(false);
    setShowUploadErrorPopup(false);
    if (selectedOption === 'continue') {
      uploadCsvApi();
      setUploadCSV(false);
      setShowUploadErrorPopup(false);
      setShowUploadError(false);
      setIsCustomer(false);
      setUploadErrors([]);
    } else {
      setShowUploadError(true);
    }
  };

  const closeErrorListTable = () => {
    setShowUploadError(false);
    setShowUploadErrorPopup(true);
  };

  const convertToCSV = (objArray) => {
    const header = Object.keys(objArray[0]).join(',');
    const rows = objArray.map((obj) => {
      const values = Object.values(obj);
      return values.join(',');
    });
    return `${header}\n${rows.join('\n')}`;
  };

  const downloadCSVFile = (csv, filename) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadCSVFile = (downloadType, fileName, selectedCSVData) => {
    let dataToDownload = csvHeaderType();
    if (downloadType === 'all') {
      dataToDownload = allDownlaodType();
    }
    if (downloadType === 'single') {
      dataToDownload = selectedCSVData;
    }
    if (downloadType === 'template') {
      fileName = `${view === 'customer' ? 'Client' : capitalizeFirstLetter(view)} Template`;
    }

    if (dataToDownload.length === 0) {
      toastMessage('error', 'There is no data to download');
    } else {
      const csv = convertToCSV(dataToDownload);
      downloadCSVFile(csv, fileName);
      setDownloadCSV(false);
    }
  };

  return (
    <AdminWrapper>
      {mainLoading ? <Loader />
        : !showUploadError ? (
          <div className=" staff-container pool-set-up search-invoice csv-upload-page">
            {loading ? (
              <Loader />
            ) : (
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <>
                      <div className="main-titles-mob">
                        <h3>CSV Uploads</h3>
                        <div className="client-option service-edit-icon">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="success"
                              id="dropdown-basic"
                            >
                              <img
                                src={showmore}
                                alt="show"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => updateView(
                                    'Clients',
                                  )}
                                >
                                  <span>
                                    {' '}
                                    Clients
                                  </span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => updateView(
                                    'Service',
                                  )}
                                >
                                  <span>
                                    Services
                                  </span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => updateView(
                                    'Product',
                                  )}
                                >
                                  <span>
                                    {' '}
                                    Product
                                    {' '}
                                  </span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => updateView(
                                    'Inventory',
                                  )}
                                >
                                  <span>
                                    {' '}
                                    Inventory
                                    {' '}
                                  </span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div
                                  className="action"
                                  onClick={() => updateView(
                                    'Staffs',
                                  )}
                                >
                                  <span>
                                    {' '}
                                    Staff
                                    {' '}
                                  </span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="target-heading client-profile">
                        <>
                          <div className="">
                            <h3>{heading}</h3>
                          </div>
                          <div className="content-dropdown">
                            <div className="client-option service-edit-icon">
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="success"
                                  id="dropdown-basic"
                                >
                                  <img
                                    src={
                                                                        showmore
                                                                    }
                                    alt="show"
                                  />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <div
                                      className="action"
                                      onClick={
                                                                            handleUpload
                                                                        }
                                    >
                                      <span>
                                        Upload
                                        CSV
                                        File
                                      </span>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <div
                                      className="action"
                                      onClick={() => {
                                        setShowViewCsvPopup(
                                          true,
                                        );
                                      }}
                                    >
                                      <span>
                                        View
                                        Uploaded
                                        CSV
                                        File
                                      </span>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Item>
                                    <div
                                      className="action"
                                      onClick={
                                    handleDownload
                                    }
                                    >
                                      <span>
                                        Download
                                        CSV
                                        File
                                      </span>
                                    </div>
                                  </Dropdown.Item>

                                  <div
                                    className="action csvUploads"
                                    onClick={() => handleDownloadCSVFile('all', `${view
                                   === 'customer'
                                      ? 'Client'
                                      : capitalizeFirstLetter(
                                        view,
                                      )}-list`)}
                                  >
                                    {' '}
                                    Downlaod
                                    All
                                    {' '}
                                    {view
                                   === 'customer'
                                      ? 'Client'
                                      : capitalizeFirstLetter(
                                        view,
                                      )}
                                    {' '}
                                    List
                                  </div>
                                  <div className="action csvUploads" onClick={() => handleDownloadCSVFile('template')}>

                                    Download
                                    {' '}
                                    {view === 'customer'
                                      ? 'Client'
                                      : capitalizeFirstLetter(
                                        view,
                                      )}
                                    {' '}
                                    Template

                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </>
                      </div>
                      <div
                        id="table-scroll"
                        className="staff-table table-scroll"
                      >
                        <div className="mob-table">
                          {csvDataToRender.length > 0 ? (
                            <>
                              {csvDataToRender.map(
                                (el, i) => (
                                  <>
                                    <Accordion className="mob-accordion">
                                      <Card
                                        key={
                                                                                el._id
                                                                            }
                                      >
                                        <Card.Header>
                                          <div className="staff-main-heading">
                                            <Accordion.Toggle
                                              as={
                                                                                            Button
                                                                                        }
                                              variant="link"
                                              eventKey="0"
                                            >
                                              <i className="fas far fa-chevron-circle-down" />
                                              <span>
                                                {removeTextLast(
                                                  el?.name,
                                                  '.',
                                                )}
                                              </span>
                                              <span
                                                style={{
                                                  textTransform:
                                                'none',
                                                }}
                                              >
                                                {addExtension(
                                                  el?.name,
                                                  '.',
                                                )}
                                              </span>
                                            </Accordion.Toggle>
                                          </div>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                          <Card.Body>
                                            <ul className="staff-list">
                                              <li className="staff-list-item">
                                                <div className="staff-box">
                                                  <h3>
                                                    CSV
                                                    File
                                                    Name
                                                  </h3>
                                                  <h5>
                                                    {el?.name}
                                                  </h5>
                                                </div>
                                              </li>
                                              <li className="staff-list-item">
                                                <div className="staff-box">
                                                  <h3>
                                                    Date
                                                    Uploaded
                                                  </h3>
                                                  <h5>
                                                    {moment(
                                                      el?.createdAt,
                                                    ).format(
                                                      'dddd, D MMMM YYYY',
                                                    )}
                                                  </h5>
                                                </div>
                                              </li>
                                              <li className="staff-list-item">
                                                <div className="staff-box">
                                                  <h3>
                                                    Time
                                                    Uploaded
                                                  </h3>
                                                  <h5>
                                                    {moment(
                                                      el?.createdAt,
                                                    ).format(
                                                      'hh:mm a',
                                                    )}
                                                  </h5>
                                                </div>
                                              </li>
                                              <li className="staff-list-item">
                                                <div className="staff-box">
                                                  <h3>
                                                    {' '}
                                                    Uploaded
                                                    By
                                                  </h3>
                                                  <h5>
                                                    {`${el?.uploadedBy?.firstname} ${el?.uploadedBy?.lastname}`}
                                                  </h5>
                                                </div>
                                              </li>
                                              <li className="staff-list-item">
                                                <div className="staff-box">
                                                  <h3>
                                                    Actions
                                                  </h3>
                                                  <div className="action-btn">
                                                    <Button
                                                      className="edit-btn"
                                                      onClick={() => handleEditCsv(
                                                        el,
                                                        i,
                                                      )}
                                                    >
                                                      <img
                                                        src={
                                                                                                                whiteedit
                                                                                                            }
                                                        alt="edit-img"
                                                      />
                                                    </Button>
                                                    <Button
                                                      className="delete-btn"
                                                      onClick={() => submitRemove(
                                                        el._id,
                                                        i,
                                                        el.csvLink,
                                                      )}
                                                    >
                                                      <img
                                                        src={
                                                                                                                whitedelete
                                                                                                            }
                                                        alt="edit-img"
                                                      />
                                                    </Button>
                                                  </div>
                                                </div>
                                              </li>
                                            </ul>
                                          </Card.Body>
                                        </Accordion.Collapse>
                                      </Card>
                                    </Accordion>
                                  </>
                                ),
                              )}
                            </>
                          ) : (
                            <>
                              <h2 className="text-center text-primary">
                                No Data
                              </h2>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            )}
          </div>
        )
          : !IsCustomer
            ? (
              <UploadErrorTable
                uploadErrors={uploadErrors}
                handleClose={closeErrorListTable}
              />
            ) : (
              <ClientErrortable
                uploadErrors={uploadErrors}
                handleClose={closeErrorListTable}
                confirmSelectUploadErrorFromUpload={confirmSelectUploadErrorFromUpload}
              />
            )}
      {/* Upload Error Table will show the errors list which were present during the time of csv upload */}
      {showRenameCsv && (
        <RenameCsv
          csvDataToRender={csvDataToRender}
          setEditData={setEditData}
          closeRenameModal={closeRenameModal}
          editData={editData}
          handleSaveEdit={handleSaveEdit}
          error={errorValue}
          isLoading={isLoading}
        />
      )}
      {uploadCSv && (
        <UploadCSV
          show={uploadCSv}
          handleUpload={handleUpload}
          handleUplaodChange={handleUplaodChange}
          handleRemoveData={handleRemoveData}
          csvFilesName={csvFiles}
          filesToRemoveArr={filesToRemoveArr}
          setFilesToRemoveArr={setFilesToRemoveArr}
          uploadCsvApi={uploadCsvApi}
          isLoading={isLoading}
          showRemoveButton={showRemoveButton}
          checkCsvHasErrorAndUpload={checkCsvHasErrorAndUpload}
        />
      )}
      {showViewCsvPopup && (
        <ViewCsv
          csvDataToRender={csvDataToRender}
          setCsvDataToRender={setCsvDataToRender}
          closeViewModal={closeViewModal}
          resultJS={resultJS}
          setResultJS={setResultJS}
          setShowViewCsvPopupTable={setShowViewCsvPopupTable}
          viewType={view}
        />
      )}
      {showViewCsvPopupTable && (
        <ViewCsvPopupTable
          show={showViewCsvPopupTable}
          resultJS={resultJS}
          closeViewCsvExcel={closeViewCsvExcel}
        />
      )}
      {downloadCSV && (
        <DownloadCSV
          csvDataToRender={csvDataToRender}
          setCsvDataToRender={setCsvDataToRender}
          closeDownloadModal={closeDownloadModal}
          csvHeaderType={csvHeaderType}
          handleDownloadCSVFile={handleDownloadCSVFile}
          view={view}
        />
      )}
      {deletePopUp && (
        <DeletePopUp
                    // additionalContactDetails={additionalContactDetails}
          handlePopUpChange={handlePopUpChange}
          setDeletePopUp={setDeletePopUp}
                    // deletePopUp={deletePopUp}
          PopUpText={popUpText}
          paraText={paraText}
          btnText={btnText}
        />
      )}
      {/* this is the error popup which will show the error which have occured in upload csv */}
      {showUploadErrorPopup && (
        <UploadIssuePopup
          show={showUploadErrorPopup}
          handleCloseIssuePopup={handleCloseIssuePopup}
          confirmSelect={confirmSelectUploadError}
          isLoading={isLoading}
        />
      )}

    </AdminWrapper>
  );
};
export default CSVUpload;
