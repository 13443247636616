import React, { useState } from 'react';
import {
  Modal, Dropdown, Button, Form, ModalTitle,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import showmore from '../../assets/home/showmoreblue.png';
import edit from '../../assets/home/grey-edit.png';
import clientimg from '../../assets/home/client.png';
import greydelete from '../../assets/home/grey-delete.png';
import showimg from '../../assets/home/banner.jpg';
import cross from '../../assets/home/black-icon.png';

const AddEquipmentDetails = ({
  equipmentDetails, handleEquipmentDetails, handleChangeSubEquipments, handleAddSubEquipments, subEquipment, editData, handleUpdateSubEquipment, preview, removePhotos, handleImageChange, imagesLength, handleCaption, errors,
}) => (
  <Modal
    size="md"
    show={equipmentDetails}
    onHide={handleEquipmentDetails}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="staff-modal view-appointment-popup checklist-popup equipment-details"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleEquipmentDetails}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      <ModalTitle>Equipment Details</ModalTitle>
    </Modal.Header>
    <Modal.Body>
      <div className="staff-form">
        {/* equipmentName: '',
    manufacturer: '',
    modelName: '',
    imageUrl: '',
    imageCaption: '',
    notes: */}
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Equiment Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Equipment Name"
            onChange={handleChangeSubEquipments}
            name="equipmentName"
            value={subEquipment.equipmentName}
            className={errors === 'equipmentName' ? 'error-text' : ''}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Manufacturer</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Manufacturer Name"
            onChange={handleChangeSubEquipments}
            name="manufacturer"
            value={subEquipment.manufacturer}
            className={errors === 'manufacturer' ? 'error-text' : ''}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Model</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Model Name and/or Number"
            onChange={handleChangeSubEquipments}
            name="modelName"
            value={subEquipment.modelName}
            className={errors === 'modelName' ? 'error-text' : ''}
          />
        </Form.Group>
        <div className="take-photo">
          <div className="photo-icon">
            <h6>Take Photo</h6>
            <div className="upload-img">
              <Form.Group className="" controlId="exampleForm.ControlInput1">
                <Form.Label htmlFor="upload-image">
                  <i className="fa fa-camera" aria-hidden="true" />
                </Form.Label>
                <input type="file" accept="image/*" multiple id="upload-image" onChange={handleChangeSubEquipments} name="images" />
              </Form.Group>
            </div>

          </div>
          {/* {console.log('preview', preview.length, preview.length, 'image', imagesLength)} */}
          { preview === undefined || preview.length === 0 ? (
            <>
              <div className="show-img">
                <img
                  src={showimg}
                  alt="img"
                />

                <div className="replace-img" onClick={() => removePhotos(0)}>
                  <img src={cross} alt="cancel" />
                </div>
              </div>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Enter Photo Caption"
                  name="imageCaption"
                  value=""
                />
                <img src={cross} alt="cancel" />
              </Form.Group>
            </>
          ) : (
            <>

              {/* {console.log('preview', preview.length, preview.length, 'image', imagesLength)} */}
              {preview && preview.length > 0 && (
                preview.map((el, i) => (
                  <>
                    <div className="show-img" key={el.equipmentName}>
                      {i >= imagesLength || imagesLength === undefined ? (
                        <img
                          src={window.URL.createObjectURL(preview[i])}
                          alt="img"
                        />
                      )
                        : (
                          <img
                            src={subEquipment.images[i]}
                            alt="img"
                          />
                        )}

                      <div className="replace-img" onClick={() => removePhotos(el, i)}>
                        <img src={cross} alt="cancel" />
                      </div>
                    </div>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter Photo Caption"
                        onChange={(e) => handleChangeSubEquipments(e, i)}
                        name="imageCaption"
                        value={subEquipment.imageCaption[i] !== undefined ? subEquipment.imageCaption[i] : ''}
                      />
                      <img src={cross} alt="cancel" onClick={() => handleCaption(i)} />
                    </Form.Group>
                  </>
                ))
              )}

            </>
          )}

          {console.log('preview', preview.length, preview)}

          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={handleChangeSubEquipments}
              name="notes"
              value={subEquipment.notes}
            />
          </Form.Group>
        </div>
        <div className="footer-btn view-btn">
          <Button
            className="appointment-btn btn-save"
            onClick={
           editData.isEdit === true ? handleUpdateSubEquipment
             : handleAddSubEquipments
            }
          >
            Save/Update
          </Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
AddEquipmentDetails.propTypes = {
  equipmentDetails: PropTypes.func.isRequired,
  handleEquipmentDetails: PropTypes.func.isRequired,
  handleAddSubEquipments: PropTypes.func.isRequired,
  handleChangeSubEquipments: PropTypes.isRequired,
  subEquipment: PropTypes.isRequired,
  editData: PropTypes.isRequired,
  handleUpdateSubEquipment: PropTypes.func.isRequired,
  removePhotos: PropTypes.func.isRequired,
  preview: PropTypes.string.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  imagesLength: PropTypes.func.isRequired,
  handleCaption: PropTypes.func.isRequired,
  errors: PropTypes.string.isRequired,
};
export default AddEquipmentDetails;
