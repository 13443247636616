import React, { useState } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import logo from '../../assets/home/logo-pool-2.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const previewQuotesSecond = ({
  showquote, handleQuote, previewURL, setSuccess,
  handleAddQuotes, statusPreview, quoteNo, handleEditQuote, type, quoteType, handleDuplicate,
}) => {
  // console.log('showquote===>', showquote);
  const [pageNumber, setPageNumber] = useState(1);
  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  const url = previewURL;

  return (
    <Modal
      size="md"
      show={showquote}
      onHide={handleQuote}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={handleQuote} aria-hidden="true" />
      </div>
      <Modal.Body>
        <div className={`appointment-container preview-invoice preview-popup preview-quote ${!url ? 'preview-quote-popup' : ''}`}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="add-appointment">
                  <div className="preview-invoice-main">
                    <TransformWrapper>
                      <TransformComponent>
                        <Document
                          file={url}
                          onLoadError={(error) => console.log(`Error while loading document! ${error.message}`)}
                          onLoadSuccess={(pdf) => setSuccess(true)}
                        >
                          <Page pageNumber={pageNumber} />
                        </Document>
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                </div>
                {type !== 'search'
                && (
                <div className="footer-btn view-btn">
                  {quoteType === 'Saved Quotes'
                    ? (
                      <>
                        <Button
                          className="btn-delete"
                          onClick={handleQuote}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="appointment-btn btn-save"
                          onClick={handleDuplicate}
                        >
                          Duplicate
                        </Button>
                      </>
                    )
                    : (
                      <>
                        <Button
                          className="btn-delete"
                          onClick={handleEditQuote}
                        >
                          Edit Quote
                        </Button>
                        <Button
                          className="appointment-btn btn-save"
                          onClick={handleAddQuotes}
                        >
                          Quote to Job
                        </Button>
                      </>
                    )}

                </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
previewQuotesSecond.propTypes = {
  showquote: PropTypes.func.isRequired,
  handleQuote: PropTypes.bool.isRequired,
  addClient: PropTypes.arrayOf.isRequired,
  addedServices: PropTypes.arrayOf.isRequired,
  quoteDate: PropTypes.string.isRequired,
  summary: PropTypes.objectOf.isRequired,
  quotesTotal: PropTypes.string.isRequired,
  gst: PropTypes.string.isRequired,
  handleAddQuotes: PropTypes.func.isRequired,
  statusPreview: PropTypes.bool.isRequired,
  quoteNo: PropTypes.string.isRequired,
  handleEditQuote: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  quoteType: PropTypes.string.isRequired,
  handleDuplicate: PropTypes.func.isRequired,
};
export default previewQuotesSecond;
