/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import moment from 'moment';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import checkimg from '../../assets/home/check1.png';
import { limitWidthHeight } from '../calculator/calculatorUtils';
import StartEndDate from './startEndDate';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

function SubscriptionForm(props) {
  const {
    handleChange,
    handleSubscription,
    handleChangeUsers,
    handleDateState,
    clientDetails,
    handleAddCustomer,
    showForm,
    handleFormModal,
    editClient,
    selectedClient,
    selectedCountry,
    handleCountry,
    setBooking,
    poolTypeArray,
    convertPoolChlorineArray,
    poolFinishArray,
    chlorinationArray,
    // handleChlorinationType,
    // handleChlorination,
    // handlePoolFinish,
    chlorinatorModel,
    error,
    stateArray,
    countries,
    mobileFormat,
  } = props;

  const validateMobileNo = (inputObj) => {
    const { value } = inputObj;
    if (value) {
      // console.log('value', value);
      if (value.trim().charAt(0) === '6' && value.trim().charAt(1) === '1' || (value.trim().charAt(0) === '0') || (value.trim().charAt(0) === '+')) {
        toastMessage('error', `Please do not enter ${value.trim().charAt(0) === '0' || value.trim().charAt(0) === '+' ? value.charAt(0).trim() : '61'}  at start of mobile no.`);
        return '';
      }
      return inputObj;
    }
    return inputObj;
  };
  let options = [];

  for (let i = 3; i < 101; i += 1) {
    options.push(i);
  }
  const [datePopup, setDatePopup] = useState(false);
  const [dateRange, setDateRange] = useState({ start: new Date(), end: new Date() });

  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };

  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };

  const handleDateRange = (start, end) => {
    // let copyfilterObj = { ...filterObj };
    // copyfilterObj.rangeStartDate = start;
    // copyfilterObj.rangeEndDate = end;
    // setFilterObj(copyfilterObj);
    // setfilterStatus(start)
    handleDateState(start, end);
    setDatePopup(false);
  };

  const handleFormFunction = (e) => {
    // e.preventDefault();
    setDateRange({ start: new Date(), end: new Date() });
    if (selectedClient) {
      editClient(e);
    } else {
      handleAddCustomer(e);
    }
  };

  return (
    <Modal
      size="lg"
      show={showForm}
      onHide={!showForm}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal subsciption-popup"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={handleFormModal} aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Subscribers Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form onSubmit={(e) => { e.preventDefault(); }}>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicCompanyType">
                  <Form.Label>
                    {`${clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}`}
                    Contact
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.customerType}
                      name="customerType"
                      onChange={handleChange}
                    >
                      <option value="Architect's Company">Architect</option>
                      <option value="Builder's Company">Builder</option>
                      <option value="Company">Company</option>
                      <option value="Consultant's Company">Consultant</option>
                      <option value="Householder">Householder</option>
                      <option value="Insurance Company">Insurance Company</option>
                      <option value="Landlord">Landlord</option>
                      <option value="Real Estate Agent">Real Estate Agent</option>
                      <option value="Other">Other</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            {clientDetails.customerType !== 'Householder' ? (
              <div className="row">
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label>
                      {clientDetails.customerType === 'Other'
                        ? 'Specific Name for Customer Type'
                        : `${clientDetails.customerType} Name`}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      onChange={handleChange}
                      value={clientDetails.companyName}
                      className={`${
                        error === `${clientDetails?.customerType?.toLowerCase()?.split(' ').join('')}name`
                          ? 'error-text'
                          : ''
                      }`}
                    />
                  </Form.Group>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-9" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    Country
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      name="country"
                      onChange={handleChange}
                      value={clientDetails.country}
                      className={`${
                        error === 'country'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      {countries?.length > 0 && countries.map((el) => <option value={el.name}>{el.name}</option>)}
                    </Form.Control>
                  </div>
                </Form.Group>

              </div>
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-1" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's First Name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="firstname"
                    value={clientDetails.firstname}
                    placeholder="Enter First Name"
                    className={`${
                      error === 'firstname'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-2" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Last Name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="lastname"
                    value={clientDetails.lastname}
                    placeholder="Enter Last Name"
                    className={`${
                      error === 'lastname'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-3" className="number italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Mobile No.
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="number-field">
                    <div className="number-box">
                      <Dropdown id="mobile">
                        <Dropdown.Toggle variant="success" id="dropdown-basic1">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span>
                            {/* <i className="fas fa-caret-down" /> */}
                          </span>
                        </Dropdown.Toggle>

                        {/* <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === 'mobileno'
                          ? 'error-text'
                          : ''
                      }`}
                      format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                      onChange={handleChange}
                      name="mobileNo"
                      value={clientDetails.mobileNo}
                      placeholder="Enter Mobile Number"
                      autocomplete="off"
                      isAllowed={validateMobileNo}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-34" className="number">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Landline No.
                  </Form.Label>
                  <div className="number-field">
                    <div className="number-box">
                      <Dropdown id="landline">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span>
                            {/* <i className="fas fa-caret-down" /> */}
                          </span>

                        </Dropdown.Toggle>

                        {/* <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleCountry('+61')}>
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCountry('+1')}>
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu> */}
                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${
                        error === 'landlineno'
                          ? 'error-text'
                          : ''
                      }`}
                      format="### ### ###"
                      onChange={handleChange}
                      name="landlineNo"
                      value={clientDetails.landlineNo}
                      autocomplete="off"
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-5" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Main ' : ''}
                    Contact's Email Address
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={clientDetails.email}
                    placeholder="Enter Email Address"
                    className={`${
                      error === 'email'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-6" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    Street Address
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="streetAddress"
                    value={clientDetails.streetAddress}
                    placeholder="Enter Street Address"
                    className={`${
                      error === 'streetaddress'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-7" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    City/Town/Suburb
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="suburb"
                    placeholder="Enter City/Town/Suburb"
                    value={clientDetails.suburb}
                    className={`${
                      error === 'suburb'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-8">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    Postal / Zip Code
                  </Form.Label>
                  <Form.Control type="text" onChange={handleChange} name="postcode" value={clientDetails.postcode} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-9" className="italic-text">
                  <Form.Label>
                    {clientDetails.customerType !== 'Householder' ? 'Billing ' : ''}
                    State
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      onChange={handleChange}
                      name="state"
                      value={clientDetails.state}
                      placeholder="Enter State Name"
                      className={`${
                        error === 'state'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      {stateArray?.length > 0 && stateArray.map((el) => (
                        <option value={el.name}>
                          {el.isoCode}
                          {' '}
                          -
                          {' '}
                          {el.name}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Automaic Invoice Sending</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.invoiceSendType}
                      name="invoiceSendType"
                      onChange={handleChange}
                    >
                      {/* <option value="Select">Select</option> */}
                      <option value="Send Invoices by SMS">Send Invoices by SMS</option>
                      <option value="Send Invoices by Email"> Send Invoices by Email</option>
                      <option value="Send Invoices by SMS & Email"> Send Invoices by SMS & Email</option>
                      <option value="Don't Automatically send">Don't Automatically send</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Subscription Type</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.subscriptionType}
                      name="subscriptionType"
                      onChange={handleSubscription}
                    >
                      <option value="Basic Plan">Basic Plan</option>
                      <option value="Standard Plan"> Standard Plan</option>
                      <option value="Premium Plan">Premium Plan</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 detailed-data">

                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    No. of Users
                    {' '}
                    <span>
                      {' '}
                      (includes
                      {' '}
                      {clientDetails.usersDisplay}
                      {' '}
                      user, plus $50.50 per month for each additional user)
                    </span>
                    {' '}
                  </Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.numberUsers}
                      name="numberUsers"
                      onChange={handleChangeUsers}
                    >
                      <option key={2} value={2} disabled={clientDetails.subscriptionType !== 'Basic Plan' ? 'disabled' : null}>{2}</option>
                      {options.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Billing Cycle</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.billingCycle}
                      name="billingCycle"
                      onChange={handleChange}
                    >
                      <option value="Annual">Annual</option>
                      <option value="Monthly">Monthly</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 subsciption-period">
                <Form.Label>Subscription Period</Form.Label>
                <div className="appointment-summary">
                  <div className="start-date-section">
                    <Form.Group className="search-box">
                      <div className="custom-datepicker" onClick={handleDatePopup}>
                        {`${moment(clientDetails.rangeStartDate).format('ddd DD MMM yyyy')}`}
                      </div>
                    </Form.Group>
                    <span>to</span>
                    <Form.Group className="search-box">
                      <div className="custom-datepicker">
                        {' '}
                        {`${moment(clientDetails.rangeEndDate).format('ddd DD MMM yyyy')}`}
                      </div>
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <Form.Group controlId="formBasicLocation">
              <Form.Label>Cost</Form.Label>
              <div className="select-box">

                <Form.Control
                  as="select"
                  value={clientDetails.costDropDown}
                  name="costDropDown"
                  onChange={handleChange}
                >
                  <option value="per annum">
                    $
                    {clientDetails.annnumCost}
                    {' '}
                    per annum
                  </option>
                  <option value="per month">
                    $
                    {clientDetails.monthlyCost}
                    {' '}
                    per month
                  </option>
                </Form.Control>
              </div>
            </Form.Group>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-9">
                  <Form.Label>
                    Payment Status
                  </Form.Label>
                  <Form.Control type="text" disabled name="paymentStatus" value={clientDetails.paymentStatus} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Apply New Payment</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.applyNewPayment}
                      name="applyNewPayment"
                      onChange={handleChange}
                    >
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>Payment Status</Form.Label>
                  <div className="select-box">

                    <Form.Control
                      as="select"
                      value={clientDetails.status}
                      name="status"
                      onChange={handleChange}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Form.Group className="form-radio" onChange={handleChange} name="gender">
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    id="male"
                    checked={clientDetails.gender === 'male'}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    id="female"
                    checked={clientDetails.gender === 'female'}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <div className="client-notes">
                    <Form.Label>Client Notes</Form.Label>
                    <div className="basic">
                      Show on all bookings
                      <Form.Check type="checkbox" onChange={setBooking} checked={clientDetails.showBookings} />
                    </div>
                  </div>
                  <Form.Control as="textarea" rows={3} onChange={handleChange} name="note" value={clientDetails.note} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="button"
                    className="btn btn-edit"
                    onClick={() => {
                      setDateRange({ start: new Date(), end: new Date() });
                      handleFormModal();
                    }}
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={handleFormFunction}
                  >
                    {' '}
                    Save/Update
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
          <StartEndDate datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} handleDateOnchange={handleDateOnchange} dateRange={dateRange} handleDateRange={handleDateRange} />
        </div>
      </Modal.Body>
    </Modal>
  );
}

SubscriptionForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleSubscription: PropTypes.func.isRequired,
  handleChangeUsers: PropTypes.func.isRequired,
  clientDetails: PropTypes.func.isRequired,
  handleAddCustomer: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  selectedClient: PropTypes.func.isRequired,
  editClient: PropTypes.func.isRequired,
  handleDateState: PropTypes.func.isRequired,
  selectedCountry: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  setBooking: PropTypes.func.isRequired,
  poolTypeArray: PropTypes.arrayOf.isRequired,
  convertPoolChlorineArray: PropTypes.arrayOf.isRequired,
  poolFinishArray: PropTypes.arrayOf.isRequired,
  chlorinationArray: PropTypes.arrayOf.isRequired,
  // handleChlorinationType: PropTypes.func.isRequired,
  // handlePoolFinish: PropTypes.func.isRequired,
  // handleChlorination: PropTypes.func.isRequired,
  chlorinatorModel: PropTypes.arrayOf.isRequired,
  error: PropTypes.string.isRequired,
  countries: PropTypes.arrayOf.isRequired,
  stateArray: PropTypes.arrayOf.isRequired,
  mobileFormat: PropTypes.string.isRequired,
};

export default SubscriptionForm;
