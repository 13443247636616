/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Form, Dropdown } from 'react-bootstrap';

import PropTypes from 'prop-types';
import greyplus from '../../assets/home/plus.png';
import showmore from '../../assets/home/showmoreblue.png';
import search from '../../assets/home/search.png';
import crosss from '../../assets/home/black-icon.png';
import searchgray from '../../assets/home/searchgray.png';
import { makingAddress, queryString } from '../../functions/index';
// import user from '../../assets/admin/user.jpg';

const SearchModal = (props) => {
  const { startDateTime, employee_id } = queryString();
  const {
    showSearch, handleShowSearch, searchClient, searchedClients, handleAddClient, clientTxt, setClientTxt, checkAddress, setHideSearch, isHideSearch,
  } = props;
  const history = useHistory();

  const handleAddNewClient = () => {
    history.push(`/client?startDateTime=${startDateTime}&employee_id=${employee_id}&typee=add`);
  };

  return (
    <Modal
      size="md"
      show={showSearch}
      onHide={handleShowSearch}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={handleShowSearch} aria-hidden="true" />
      </div>
      <div className="">
        <Modal.Header>
          Client List
          <div className="client-option service-edit-icon">

            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={showmore} alt="show" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="action" onClick={handleAddNewClient}>
                  <span>
                    <img src={greyplus} alt="edit" />
                  </span>
                  <span>New Client</span>
                </div>
                <div className="action" onClick={() => setHideSearch(true)}>
                  <span><img src={searchgray} alt="edit" /></span>
                  <span>Show Hidden data in Search</span>
                </div>
              </Dropdown.Menu>
            </Dropdown>

          </div>
        </Modal.Header>

        {/* </div> */}
      </div>
      <Modal.Body>
        <div className="search-popup">
          <Form inline>
            <Form.Group className="search-box search-text">
              <Form.Control type="text" placeholder="Search name, billing address or mobile" onChange={(e) => searchClient(e.target.value, 'atualSeacrType')} value={clientTxt} />
              <img src={search} alt="search" />
              {clientTxt ? (
                <div
                  className="remove-text"
                  onClick={() => setClientTxt('')}
                >
                  <img src={crosss} className="cross-img" alt="cross-img" />
                </div>
              ) : null}
            </Form.Group>
            {isHideSearch ? (
              <span className="hidden-data">
                Hidden Data Shown in Search
                <img
                  src={crosss}
                  className="cross-img"
                  alt="cross-img"
                  onClick={() => {
                    setHideSearch(false);
                    searchClient('', 'HiddenSearch');
                  }}
                />
              </span>
            ) : null}
          </Form>
        </div>

        {searchedClients ? (
          <div className="search-user-list">
            {searchedClients.length ? (
              <>
                {searchedClients.map((client) => (
                  <div className="search-client-address">
                    <div className="">
                      <h5 className="client-company-name">{client?.companyName}</h5>
                    </div>
                    <div className="search-items client-name-contact" key={client._id} onClick={() => handleAddClient(client)}>
                      <div className="search-content">
                        <h4>{`${client.firstname} ${client.lastname || ''}`}</h4>
                        <p className="client-address">{makingAddress({ streetAddress: client.streetAddress, suburb: client.suburb })}</p>
                        {/* <p>{`${client.mobileNo}`}</p> */}
                      </div>
                    </div>
                    {client?.siteAddresses?.map((el, index) => (
                      <>
                        {!el?.hide
                        && (
                        <div className="client-name-contact site-addresses" role="presentation" onClick={() => checkAddress(index, client._id)}>
                          <div>
                            <div>
                              <h3 className="client-company-name">
                                Site Address
                                {' '}
                                {index > 0 ? index + 1 : ''}
                              </h3>
                            </div>
                            <h4 className="client-name">{el?.householderName}</h4>
                            <p className="client-address">{el?.address}</p>
                          </div>
                          <div className="open-date client-notes">
                            <div className="basic">
                              <Form.Check
                                type="checkbox"
                                checked={el?.activeSiteAdd ? el?.activeSiteAdd : false}
                              />
                            </div>
                          </div>
                        </div>
                        )}
                      </>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <>
                <span>Client not found</span>
              </>
            )}
          </div>
        ) : null}

      </Modal.Body>
    </Modal>
  );
};

SearchModal.propTypes = {
  showSearch: PropTypes.func.isRequired,
  handleShowSearch: PropTypes.func.isRequired,
  searchClient: PropTypes.func.isRequired,
  searchedClients: PropTypes.func.isRequired,
  handleAddClient: PropTypes.func.isRequired,
  clientTxt: PropTypes.func.isRequired,
  setClientTxt: PropTypes.func.isRequired,
  checkAddress: PropTypes.func.isRequired,
  isHideSearch: PropTypes.bool.isRequired,
  setHideSearch: PropTypes.func.isRequired,
};

export default SearchModal;
