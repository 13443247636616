/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import './calculator.css';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { displayCacliPrices, displayDollar } from './calculatorUtils';

function GreenPoolCalculationPrinting(props) {
  const {
    chlorinationType, selectedModel,
    poolInputs, calVariable, greenSplitName, greenLevelData, greenApiData, greenLabourCost, crossButton, thirdVisitStatus, serviceAnalysis,
  } = props;

  const totaSellCost = (data) => {
    let totalcost = data.filter((el) => el.type === 'product').reduce((pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0) + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0), 0);
    // console.log('totalCost', totalcost);
    return (totalcost).toFixed(2);
  };

  const totalBuyCost = (data) => {
    let totalcost = data.filter((el) => el.type === 'product').reduce((pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0) + parseFloat(next.buyCostProduct ? (next.buyCostProduct === 'N/A' ? 0 : next.buyCostProduct) : 0), 0);
    return totalcost.toFixed(2);
  };

  const consumableMargin = (data) => {
    let totalSell = data.filter((el) => el.type === 'product').reduce((pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0) + parseFloat(next?.renderCost ? (next?.renderCost === 'N/A' ? 0 : next?.renderCost) : 0), 0);
    let totalBuy = data.filter((el) => el.type === 'product').reduce((pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0) + parseFloat(next?.buyCostProduct ? (next?.buyCostProduct === 'N/A' ? 0 : next?.buyCostProduct) : 0), 0);
    return (totalSell - totalBuy).toFixed(2);
  };

  const labourCost = (data) => {
    let totalLab = data.filter((el) => el.type === 'service').reduce((pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0) + parseFloat(next?.renderCost ? (next?.renderCost === 'N/A' ? 0 : next?.renderCost) : 0), 0);
    return totalLab.toFixed(2);
  };

  return (
    <div className="content-container cs-container green-pool-cscontainer">
      <div className="cs-box">
        <div className="">
          {greenLevelData?.length > 0 && greenLevelData.map((el1) => (
            <>
              {el1?.service?.length === 0 ? null : (
                <>
                  <div className="row">
                    <div className="col-sm-12 sm-offset-1">
                      <div className="cs-heading new-cs-heading">
                        <h3>
                          {`CALCULATION ${serviceAnalysis === 'Sand Filter Media Replacement' ? 'SAND FILTER MEDIA' : serviceAnalysis === 'Drain Pool' ? 'DRAIN POOL' : serviceAnalysis === 'Green Pool' ? 'GREEN POOL' : 'BLACK-SPOT ALGAE'} ${el1.numericValue} VISIT`}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 sm-offset-1">
                      <Table responsive className="pool-size">
                        <tbody>
                          <tr>
                            <td>Pool Size : </td>
                            <td>
                              {poolInputs?.poolHeight}
                              <span>X</span>
                              {poolInputs?.poolWidth}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 sm-offset-1">
                      <Table responsive className="total-cost">
                        <tbody>
                          <tr>
                            <td width="90%">{`Labour Cost ${el1?.numericValue} Visit`}</td>
                            <td width="10%">$</td>
                            <td width="10%" className="calc-pool-cost">
                              {labourCost(el1?.service)}
                            </td>
                          </tr>
                          <tr>
                            <td width="90%">
                              {`Consumable Margin ${el1?.numericValue} Visit`}
                              <br />
                              <span>Buy Cost</span>
                            </td>
                            <td width="10%">
                              $
                              <br />
                              <span>$</span>
                            </td>
                            <td width="10%" className="calc-pool-cost">
                              {consumableMargin(el1?.service)}
                              <br />
                              <span>{totalBuyCost(el1?.service)}</span>
                            </td>
                          </tr>
                          <tr>
                            <td width="90%">Total </td>
                            <td width="10%">$</td>
                            <td width="10%" className="calc-pool-cost">{(parseFloat(consumableMargin(el1?.service)) + parseFloat(totalBuyCost(el1?.service)) + parseFloat(labourCost(el1?.service))).toFixed(2)}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="quantity-table">
                        <Table bordered>
                          <thead>
                            <tr>
                              <th>
                                {el1?.value}
                                {' '}
                                Quantity
                              </th>
                              <th>BUY COST</th>
                              <th>EX GST</th>
                            </tr>
                          </thead>
                          <tbody>
                            {el1.service.map((el) => (
                              <>
                                {/* {console.log('el', el.subcategory)} */}
                                {(el?.type === 'product' && el?.subcategory !== '') || (greenSplitName === 'Drain' && el.subcategory === 'Drain Cost')

                                  ? (
                                    <tr>
                                      <td>
                                        <div className="quantity">
                                          {greenSplitName === 'Drain' && el?.subcategory === 'Drain Cost' ? 'Drain 100% of water' : el?.subcategory}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="buy-cost">
                                          <span>{displayDollar(el?.buyCostProduct)}</span>
                                          <span>{displayCacliPrices(el?.buyCostProduct, '')}</span>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="buy-cost">
                                          <span>{displayDollar(el?.renderCost)}</span>
                                          <span>{displayCacliPrices(el?.renderCost, '')}</span>
                                        </div>
                                      </td>
                                      {/* <td className="value">Chlorine</td> */}
                                    </tr>
                                  )
                                  : null}
                              </>
                            ))}
                            <tr>
                              <td>
                                <div className="sub-total">
                                  <span>SUB TOTAL</span>
                                </div>
                              </td>
                              <td>
                                <div className="buy-cost">
                                  <span>$</span>

                                  <span>{totalBuyCost(el1?.service)}</span>

                                </div>
                              </td>
                              <td>
                                <div className="buy-cost">
                                  <span>$</span>

                                  <span>
                                    {totaSellCost(el1?.service)}
                                  </span>

                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>

                </>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
GreenPoolCalculationPrinting.prototype = {
  greenLabourCost: PropTypes.func.isRequired,
  greenApiData: PropTypes.func.isRequired,
  greenLevelData: PropTypes.func.isRequired,
  calVariable: PropTypes.func.isRequired,
  greenSplitName: PropTypes.func.isRequired,
  crossButton: PropTypes.bool.isRequired,
  thirdVisitStatus: PropTypes.bool.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
};
export default GreenPoolCalculationPrinting;
