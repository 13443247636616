import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ViewCsvPopupTable = ({ show, resultJS, closeViewCsvExcel }) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal'
          ) {
            closeViewCsvExcel();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={closeViewCsvExcel}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={() => closeViewCsvExcel()}
          aria-hidden="true"
        />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">View CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          {resultJS}
        </div>
      </Modal.Body>
    </Modal>
  );
};
ViewCsvPopupTable.propTypes = {
  resultJS: PropTypes.arrayOf.isRequired,
  closeViewCsvExcel: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
export default ViewCsvPopupTable;
