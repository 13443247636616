/* eslint-disable no-multi-assign */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Nav, Tabs, Tab,
} from 'react-bootstrap';
import getStrNum from '../../functions/strNumseparator';
import { validateDataRangeText, valueConverterToOther, valueConverterToPx } from '../../functions';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const AddNewTextPopup = ({
  addTextpopup, handleTextPopup, setData,
  templateDataCopy, setTemplateDataCopy, setTemplateDat, text, customName, setText, customNameTwo, handleShow,
  customFeilds, setCustomFeilds, tdIndex, widthValue, setIsNewChange,
}) => {
  console.log('AddNewTextPopup');
  const { userData } = useContext(AuthDataContext);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';
  let sizeCount = 0;
  const Font = [
    "'Exo 2', sans-serif",
    "'Montserrat', sans-serif",
    "'Poppins', sans-serif",
    "'Raleway', sans-serif",
    "'Roboto', sans-serif",
  ];
  let initialCSS = {
    text: '',
    type: 'tr',
    index: 0,
    style: {
      lineHeight: '12px',
      font: "'Exo 2', sans-serif",
      fontStyle: 'Normal',
      color: '#333333',
      fontSize: 9,
      textAlign: 'left',
      textDecoration: 'unset',
      fontWeight: 'unset',
      top: '0px',
      left: '0px',
      display: 'block',
      width: '100%',
      // position: 'absolute',
    },
    tdStyle2: {
      width: '50px',
      textAlign: 'left',
      height: '14px',
      paddingLeft: '0%',
      paddingTop: '0%',
      paddingBottom: '0px',
    },
  };
  const [customObject, setCustomObject] = useState(initialCSS);
  const [errors, setErrors] = useState('');
  const [updatedValues, setUpdatedValues] = useState(initialCSS);
  const [dummy, setDummy] = useState(false);

  const updateMeasurement = (fielddata) => {
    const updateData = JSON.parse(JSON.stringify(fielddata));
    console.log('update text new  orogana val ==>', fielddata);
    if (updateData?.style) {
      if (updateData?.style?.top || updateData?.style?.left || updateData[tdIndex].height || updateData[tdIndex].width) {
        let topVal = valueConverterToOther(updateData.style.top, countryType);
        updateData.style.top = topVal;
        setUpdatedValues(updateData);
        let leftVal = valueConverterToOther(updateData.style.left, countryType);
        updateData.style.left = leftVal;
        setUpdatedValues(updateData);

        let heightVal = valueConverterToOther(updateData[tdIndex].height, countryType);
        updateData[tdIndex].height = heightVal;
        setUpdatedValues(updateData);

        let widthVal = valueConverterToOther(updateData[tdIndex].width, countryType);
        updateData[tdIndex].width = widthVal;
        console.log('updateData add nex text poppp =======>', updateData);
        setUpdatedValues(updateData);
      }
    }
  };

  useEffect(() => {
    // const customcheck = JSON.parse(JSON.stringify(customObject));
    updateMeasurement(initialCSS);
  }, []);

  useEffect(() => {
    let dd = document?.getElementById('newTexttt')?.offsetWidth;

    let ddheight = document?.getElementById('newTexttt')?.offsetHeight;
    if (dd) {
      console.log('dd ===>', dd);
      let str = document.getElementById('newTexttt').innerText;
      console.log('str ============>', str);
      const spacesCount = str.split(' ').length - 1;
      let dd1 = dd + 10 + spacesCount;
      const oldStyleObjectt = JSON.parse(JSON.stringify(customObject));
      console.log('oldStyleObjectt nooo =========>', {
        oldStyleObjectt,
      });
      let newWidth = `${dd1}px`;
      oldStyleObjectt.tdStyle2.width = `${newWidth}`;
      console.log('newWidth =>', newWidth);

      const convertedVal = valueConverterToOther(newWidth, countryType);

      console.log('s==>', convertedVal);

      let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));

      copyupdatedValues.tdStyle2.width = convertedVal;

      setUpdatedValues(copyupdatedValues);

      setCustomObject(oldStyleObjectt);
      setDummy(false);
      console.log('oldStyleObjectt ==>', { copyupdatedValues, oldStyleObjectt });
    }
  }, [dummy]);

  // useEffect(() => {
  //   let dd = document.getElementById('newTexttt').offsetWidth;
  //   let str = document.getElementById('newTexttt').innerText;
  //   const spacesCount = str.split(' ').length - 1;
  //   let dd1 = dd + 10 + spacesCount;
  //   let oldStyleObjectt = JSON.parse(JSON.stringify(customObject));
  //   let newWidth = `${dd1}px`;
  //   oldStyleObjectt.tdStyle2.width = `${newWidth}`;
  //   const convertedVal = valueConverterToOther(newWidth, countryType);
  //   let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
  //   let heightVal = valueConverterToOther(copyupdatedValues.tdStyle2.height, countryType);
  //   console.log('heightVal ==>', { heightVal, convertedVal });
  //   copyupdatedValues.tdStyle2.width = convertedVal;
  //   copyupdatedValues.tdStyle2.height = heightVal;
  //   setUpdatedValues(copyupdatedValues);
  //   console.log('oldStyleObjectt ==>', oldStyleObjectt);
  //   setCustomObject(oldStyleObjectt);
  //   setDummy(false);
  // }, [dummy]);

  const setTemplateData = (data, wave) => {
    setDummy(true);
    const { value } = data;
    let parsingValue;
    console.log('customObject ==>', customObject);
    if (wave === 'text') {
      setCustomObject({ ...customObject, text: value, index: customFeilds.length });
      return;
    }
    if (wave === 'fontSize') {
      parsingValue = parseInt(value, 10);
    } else if (wave === 'lineHeight') {
      parsingValue = `${value}px`;
    } else {
      parsingValue = value;
    }
    let { style } = customObject;
    let oldStyleObject = { ...style };
    oldStyleObject[wave] = parsingValue;

    console.log('oldStyleObject ==>', oldStyleObject);

    setCustomObject({ ...customObject, style: oldStyleObject });
  };

  const setNewText = () => {
    const addtext = customObject?.text || '';
    if (addtext) {
      console.log('customObject ===========>', customObject);
      customFeilds.push(customObject);
      setCustomFeilds(customFeilds);
    }
    handleTextPopup();
    setIsNewChange(true);
  };
  const setTemplateLocationSize2 = (data, type = 'px') => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    const { isValid, error } = validateDataRangeText(value, name, widthValue);
    let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
    setErrors(error);
    if (isValid) {
      const keyVal = `${value}px`;
      let { style } = customObject;
      let oldtdStyleObject = { ...style };
      if ((name === 'left' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject[name] = keyVal;
      } else if ((name === 'top' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject[name] = keyVal;
      } else {
        oldtdStyleObject[name] = 'initial';
      }
      if (oldtdStyleObject.left === 'initial' && oldtdStyleObject.top === 'initial') {
        oldtdStyleObject.position = 'initial';
      }
      copyupdatedValues.style[name] = OriginalVal;
      setUpdatedValues(copyupdatedValues);
      setCustomObject({ ...customObject, style: oldtdStyleObject });
    }
  };

  const setTemplateLocationSize = (data, type) => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    const { isValid, error } = validateDataRangeText(value, name, widthValue);
    let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
    setErrors(error);
    if (isValid) {
      const keyVal = `${value}px`;
      const { tdStyle1, tdStyle2 } = customObject || {};
      let oldtdStyle1Object = { ...tdStyle1 };
      let oldtdStyle2Object = { ...tdStyle2 };
      if (tdIndex === 'tdStyle1') {
        oldtdStyle1Object[name] = keyVal;
        setCustomObject({ ...customObject, tdStyle1: oldtdStyle1Object });
        copyupdatedValues.tdStyle1[name] = OriginalVal;
      } else {
        oldtdStyle2Object[name] = keyVal;
        setCustomObject({ ...customObject, tdStyle2: oldtdStyle2Object });
        copyupdatedValues.tdStyle2[name] = OriginalVal;
      }
      setUpdatedValues(copyupdatedValues);
    }
  };
  const addjustElementWidth = (data, type) => {
    const { name } = data || {};
    const OriginalVal = data?.value;
    const value = valueConverterToPx(OriginalVal, countryType);
    let { tdStyle2 } = customObject;
    let oldStyleObject = { ...tdStyle2 };
    oldStyleObject.width = `${value}px`;
    setCustomObject({ ...customObject, tdStyle2: oldStyleObject });
    let copyupdatedValues = JSON.parse(JSON.stringify(updatedValues));
    copyupdatedValues.tdStyle2.width = OriginalVal;
    setUpdatedValues(copyupdatedValues);
  };
  return (
    <Modal
      size="md"
      show={addTextpopup}
      onHide={handleTextPopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup change-text-popup add-field-popup"
    >
      <Modal.Body>
        <div className="appointment-container  preview-popup">
          <div className="change-image-section">
            <div className="preview-text">
              <h6>Text Wording</h6>
              <span style={{
                fontFamily: `${customObject.style.fontFamily}`,
                fontSize: `${customObject.style.fontSize}px`,
                color: `${customObject.style.color}`,
                fontStyle: `${customObject.style.fontStyle}`,
                textDecoration: `${customObject.style.textDecoration}`,
                fontWeight: `${customObject.style.fontWeight}`,
                lineHeight: `${customObject.style.lineHeight}`,
                border: '1px solid rgb(220, 217, 217)',
                padding: '10px',
                marginBottom: '15px',
                display: 'flex',
                borderRadius: '5px',
              }}
              >
                <span id="newTexttt">{customObject.text}</span>
              </span>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows={3} value={customObject.text} onChange={({ target: { value, name } }) => setTemplateData({ value, name }, 'text')} />
              </Form.Group>
            </div>
            <h6>Type Characteristics</h6>
            <div className="field-location-section">
              <div className="field-location">

                <Form.Group controlId="formGridEmail">
                  <Form.Label>Typeface</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.font} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'font')}>
                      {Font.map((f) => (
                        <option value={f}>{f}</option>
                      ))}
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Point Size</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontSize} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontSize')}>
                      {Array.from(Array(20).keys()).map(() => {
                        sizeCount += 1;
                        return (
                          <option value={sizeCount}>{sizeCount}</option>
                        );
                      })}
                    </select>
                  </div>
                </Form.Group>

              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Style</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontStyle} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontStyle')}>
                      <option value="normal">Normal</option>
                      <option value="Italic">Italic</option>
                    </select>
                  </div>
                </Form.Group>
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Line Spacing</Form.Label>
                  <Form.Control type="number" value={getStrNum(customObject?.style?.lineHeight)?.num} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'lineHeight')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Alignment</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textAlign} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textAlign')}>
                      <option value="left">Flush Left</option>
                      <option value="center">Centered</option>
                      <option value="right">Flush Right</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Colour</Form.Label>
                  <Form.Control type="color" value={customObject?.style?.color} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'color')} />
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Effects</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.textDecoration} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'textDecoration')}>
                      <option value="underline">UnderLine</option>
                      <option value="line-through">Strikeout</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
              <div className="field-location">
                <Form.Group controlId="formGridEmail">
                  <Form.Label>Font Weight</Form.Label>
                  <div className="select-box">
                    <select className="form-control" value={customObject?.style?.fontWeight} onChange={({ target: { name, value } }) => setTemplateData({ name, value }, 'fontWeight')}>
                      <option value="bold">Bold</option>
                      <option value="bolder">Bolder</option>
                      <option value="lighter">Lighter</option>
                      <option value="unset">Unset</option>
                    </select>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="text-popup-spacing">
              <h6>Text Field Location & Size</h6>
              <div className="field-location-section">
                <div className="field-location">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.style?.top)?.num} name="top" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(updatedValues?.style?.top)?.str)} placeholder="0" />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.top && <p className="error-msg">{errors.top}</p>}
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Distance from Left</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.style?.left)?.num} name="left" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(updatedValues?.style?.left)?.str)} placeholder="0" />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                    {errors?.left && <p className="error-msg">{errors.left}</p>}
                  </Form.Group>
                </div>
                <div className="field-location">
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Height</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.[tdIndex]?.height)?.num} name="height" onChange={(e) => setTemplateLocationSize(e.target, getStrNum(updatedValues?.[tdIndex]?.height)?.str)} />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                  </Form.Group>
                  <Form.Group controlId="formGridEmail">
                    <Form.Label>Field Width</Form.Label>
                    <Form.Control type="number" value={getStrNum(updatedValues?.[tdIndex]?.width)?.num} name="width" onChange={(e) => addjustElementWidth(e.target, getStrNum(updatedValues?.[tdIndex]?.width)?.str)} />
                    <span>{countryType === 'au' ? 'mm' : 'inch'}</span>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          <div className="template-btn">
            {/* {customObject.text.length > 0 && (
              <Button type="submit" className="delete-btn">Deleggte Field</Button>
            )} */}
            <Button type="submit" onClick={handleTextPopup} className="delete-btn">Cancel</Button>
            <Button className="save-btn" onClick={setNewText}>Save/ Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
AddNewTextPopup.propTypes = {
  addTextpopup: PropTypes.func.isRequired,
  handleTextPopup: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  templateDataCopy: PropTypes.objectOf.isRequired,
  setTemplateDataCopy: PropTypes.func.isRequired,
  setTemplateDat: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  customName: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  customNameTwo: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
  setCustomFeilds: PropTypes.func.isRequired,
  setIsNewChange: PropTypes.func.isRequired,
  tdIndex: PropTypes.string.isRequired,
  widthValue: PropTypes.string.isRequired,
};
export default AddNewTextPopup;
