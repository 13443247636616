import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './sendInvoicepopup.css';
import moment from 'moment';
import TextEditor from './invoiceTextEditor';

const SendInvoicePopup = ({
  show,
  setShowInvoicePopup,
  sendNewincoice,
  invoiceData,
}) => {
  const [initialData, setInitialData] = useState({
    toEmail: [],
    subject: 'Invoice INV-12345 From Clean Pools Pool Maintenance Experts for MANORS GATE',
    message: '<p>Hi ,</p><p><br/></p> <p>Heres invoice INV-1234 for AUD 2002</p> <p><br/></p><p>The amount outstanding of AUD 2002 is due on 11 march 2021.</p> <p><br/></p><p>View your bill online:.</p> <p><br/></p><p>From your online bill you can print a PDF, export a CSV, or create a free login and view your outstanding bills.</p> <p><br/></p><p>If you have any questions, please let us know.</p> <p><br/></p><p>Thanks,</p><p>Clean Pools Pool Maintenance Experts</p>',
    copy: '',
  });

  const history = useHistory();
  // console.log(invoiceData, 'invoiceData');
  useEffect(() => {
    if (invoiceData) {
      const clientEmail = invoiceData?.customer?.email || '';
      const invNumber = invoiceData?.invoiceNo || '';
      const invUrl = '[Online Invoice Link]';
      const companyName = 'Clean Pools Pool Maintenance Experts for MANORS GATE';
      setInitialData((prevState) => ({
        ...prevState,
        toEmail: [clientEmail],
        subject: `Invoice INV-${invNumber} From ${companyName}`,
        message: `<p>Hi ,</p><p><br/></p> <p>Heres invoice INV-${invNumber} for AUD ${invoiceData?.invoiceTotal}</p> <p><br/></p><p>The amount outstanding of AUD ${invoiceData?.balanceDue} is due on ${moment(invoiceData?.createdAt).format('DD MMM YYYY')}..</p> <p><br/></p><p>View your bill online: ${invUrl}</p> <p><br/></p><p>From your online bill you can print a PDF, export a CSV, or create a free login and view your outstanding bills.</p> <p><br/></p><p>If you have any questions, please let us know.</p> <p><br/></p><p>Thanks,</p><p>Clean Pools Pool Maintenance Experts</p>`,
      }));
    }
  }, [invoiceData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInitialData((prevState) => ({
      ...prevState,
      [name]: value.split(',').map((email) => email.trim()),
    }));
  };
  // console.log('initialData>>>>>>>>>>>>>>>>>>>', initialData);
  const toEmailValue = initialData.toEmail ? initialData.toEmail.join(', ') : '';

  const handleOpen = () => {
    setShowInvoicePopup(!show);
    setInitialData((prevState) => ({
      ...prevState,
      copy: false,
    }));
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleOpen}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="service-modal notification-modal"
      >
        <div className="staff-close sendInvoice-close" onClick={handleOpen}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Send Invoice
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="modal-form">
                <div className="d-flex box-border align-items-center">
                  <span className="highlighted-text">To:</span>
                  <input
                    name="toEmail"
                    className="form-control border-0"
                    type="text"
                    value={toEmailValue}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="d-flex box-border align-items-center">
                  <span className="highlighted-text">Subject:</span>
                  <input
                    name="subject"
                    value={initialData.subject}
                    className="form-control border-0"
                    type="text"
                    onChange={(e) => setInitialData({ ...initialData, subject: e.target.value })}

                  />
                </div>

                <div className="editorSendInvoice box-border">
                  <TextEditor data={initialData.message} setData={(data) => setInitialData((prevState) => ({ ...prevState, message: data }))} />
                </div>
                <div className="d-flex align-items-center  justify-content-between mt-2">
                  <span className="highlighted-text">Send me a copy</span>
                  <Form>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      checked={initialData.copy}
                      onChange={(e) => setInitialData({ ...initialData, copy: e.target.checked === true ? 'test@yopmail.com' : '' })}
                    />
                  </Form>
                </div>
                <div>
                  <span className="sendInvoicePara">You will get a copy at hello@cleanpools.com.au</span>
                </div>
                <div className="footer-btn view-btn sendInvoice-btn">
                  <button type="button" className="btn-delete btn btn-primary" onClick={handleOpen}>Cancel</button>
                  <button type="button" className="appointment-btn btn-save btn btn-primary" onClick={() => sendNewincoice(initialData)}>Send</button>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

SendInvoicePopup.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowInvoicePopup: PropTypes.func.isRequired,
  sendNewincoice: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  invoiceData: PropTypes.object.isRequired,
};

export default SendInvoicePopup;
