import React, { useState, useEffect, useContext } from 'react';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckOutForm from './checkOutForm';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const SubsPayment = () => {
  const { userData, setUserData } = useContext(AuthDataContext);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);

  useEffect(() => {
    // console.log('userData', userData);
    if (userData?.userDetails?.payment === 'pending') {
      setOpenPaymentForm(true);
    }
  }, []);
  const stripePromise = loadStripe('pk_test_51KjgGOSHhBMizAxJbkmFP2RG4SjmsXfhOWsOHrAtCqaBhFXAvDMPkr0X94xVRAN1J8wURdXWKPu6Ub1D2ylrjfy70002aH6XJk');
  return (
    <Elements stripe={stripePromise}>
      <CheckOutForm
        openPaymentForm={openPaymentForm}
        payloadData={userData?.userDetails}
        setOpenPaymentForm={setOpenPaymentForm}
      />
    </Elements>
  );
};
export default SubsPayment;
