import React, { useState, useEffect } from 'react';
import {
  Card,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

const UploadErrorTable = ({ uploadErrors, handleClose }) => {
  const itemsPerPage = 10;
  const [selectedPage, setSelectedPage] = useState(1);
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = uploadErrors.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(uploadErrors.length / itemsPerPage);

  const handlePageClick = (event) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const newOffset = (event.selected * itemsPerPage) % uploadErrors.length;
    setSelectedPage(event.selected + 1);
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className=" staff-container pool-set-up search-invoice csv-upload-erros">
        <div className="container">
          <div className="back-option calc-option" onClick={handleClose}>
            <span />

            <i
              className="fa fa-times"
              aria-hidden="true"
            />

          </div>
          <div className="row">
            <div className="col-md-12">
              <>
                <div className="main-titles-mob">
                  <h3>Upload Errors</h3>
                </div>
                <div id="table-scroll" className="staff-table table-scroll">
                  {currentItems.map((error) => (
                    <>
                      <div className="mob-table">
                        <Card>
                          <Card.Body>
                            <ul className="staff-list">
                              <li className="staff-list-item">
                                <div className="staff-box">
                                  <h3>Row no</h3>
                                  <h5>{error?.row}</h5>
                                </div>
                              </li>
                              <li className="staff-list-item">
                                <div className="staff-box">
                                  <h3>Error</h3>
                                  <h5>{error?.error}</h5>
                                </div>
                              </li>
                              <li className="staff-list-item">
                                <div className="staff-box">
                                  <h3>Description</h3>
                                  <h5>{error?.reason}</h5>
                                </div>
                              </li>
                              {error?.email ? (
                                <li className="staff-list-item">
                                  <div className="staff-box">
                                    <h3>Email</h3>
                                    <h5>{error?.email}</h5>
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          </Card.Body>
                        </Card>
                      </div>
                    </>
                  ))}

                </div>
              </>
            </div>
          </div>
        </div>
        {uploadErrors.length > 10 ? (
          <div className="user-pagination">
            <ReactPaginate
              previousLabel="← Previous"
              nextLabel="Next →"
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName="pagination-chk"
              previousLinkClassName="pagination__link"
              nextLinkClassName="pagination__link"
              disabledClassName="pagination__link--disabled"
              activeClassName="pagination__link--active"
              forcePage={selectedPage - 1}
            />
            <div className="no-of-page">
              Page
              {' '}
              {selectedPage}
              {' '}
              of
              {' '}
              {' '}
              {pageCount}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
UploadErrorTable.propTypes = {
  uploadErrors: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UploadErrorTable;
