/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import DurationPicker from '../appointment/durationPicker';
import { coverBlankField } from '../../functions';

const SecondVisitCost = ({
  handleChangeSecondVisitCost, handleRequest, handleRevertViewUpdate, state, handleNewRevertDefaultUpdate, change,
  withValueLimitSellPrice, handleChangeCustom, convertToReadableDuration,
  handleDurationPicker, checkBlankFields,

}) => {
  const quantityLabel = state.pricingType === 'fixedCost';
  const MAX_VAL = 24;
  return (
    <div className="pool-setup mineral-pool drainage-cost-section">
      <div className="target-heading">
        <h6>
          Second Visit Cost
          Set-up
        </h6>
      </div>
      {/* {console.log('checkBlankFields ==>', checkBlankFields)} */}
      <div className="pool-chemistry-box appointment-service-modal pool-setup-second-visit">
        <div className="row">
          <div className="col-md-6 col-12">
            <Form.Group controlId="formBasicHiddenEmployees">
              <Form.Label>Fixed Price or Hourly Rate</Form.Label>
              <div className="select-box">
                <Form.Control
                  as="select"
                  onChange={handleChangeSecondVisitCost}
                  name="pricingType"
                  value={state.pricingType}
                >
                  <option value="fixedCost">Fixed Cost</option>
                  <option value="hourly">Hourly Rate</option>
                </Form.Control>
              </div>
            </Form.Group>
          </div>
          <div className="col-md-6 col-12">

            <Form.Group controlId="formBasicHiddenEmployees">

              <Form.Label>Duration</Form.Label>
              <Button
                onClick={handleDurationPicker}
              >
                {convertToReadableDuration(state.duration)}
              </Button>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-6 half">
            <Form.Group controlId="formBasicHiddenEmployees">
              <Form.Label>{quantityLabel ? 'Fixed Price Amount' : 'Hourly Rate Amount'}</Form.Label>
              <div className="select-box">
                <Form.Control
                  as="select"
                  onChange={(e) => handleChangeCustom(e.target.value)}
                  name="customCost"
                  value={state.customCost}
                >
                  <option label="Default" name="customCost" value="default">
                    Default
                  </option>
                  <option
                    label={
                  quantityLabel ? 'Custom Sell Price' : 'Custom Hourly Rate'
                }
                    name="customCost"
                    value="yes"
                  >
                    {quantityLabel ? 'Custom Sell Price' : 'Custom Hourly Rate'}
                  </option>
                </Form.Control>
              </div>
            </Form.Group>
          </div>
          <div className="col-6">
            <Form.Group controlId="formBasicHiddenEmployees">
              <Form.Label>
                {quantityLabel
                  ? 'Sell Price (ex tax)'
                  : 'Sell Price (hourly ex tax)'}
              </Form.Label>
              <NumberFormat
                thousandSeparator
                type="text"
                placeholder={
              quantityLabel
                ? 'Sell Price (ex tax)'
                : 'Sell Price (hourly ex tax)'
            }
                name="cost"
                value={state.cost}
                disabled={state.customCost === 'default'}
                onChange={handleChangeSecondVisitCost}
                isAllowed={withValueLimitSellPrice}
                // className={state.customCost === 'default' ? '' : 'addBorder'}
                prefix="$"
                className={coverBlankField(state?.cost, 'cost', checkBlankFields)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="view-btn">
          {/* <Button type="submit" className="btn btn-edit" onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button> */}
          <div className="client-option service-edit-icon">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                View Other Settings
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={(e) => handleRevertViewUpdate(e, state)}>
                  <div className="action">
                    Restore Original Settings
                  </div>
                </Dropdown.Item>
                <Dropdown.Item onClick={(e) => handleNewRevertDefaultUpdate(e, state)}>
                  <div className="action">
                    Revert to Previous Saved Default
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Button type="submit" className="btn btn-save" onClick={handleRequest}>
            Save as Default
          </Button>
        </div>
      </div>
      <div className="bottom-text">
        <span className="require-icon">*</span>
        <p>
          Note that data above is used in
          <b> Calculator </b>
          for Second Visit Duration & Cost (except for Green Pools, which has its own customised workings).
        </p>
      </div>
    </div>
  );
};
SecondVisitCost.propTypes = {
  handleChangeSecondVisitCost: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  withValueLimitSellPrice: PropTypes.func.isRequired,
  handleChangeCustom: PropTypes.func.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};

export default SecondVisitCost;
