import React from 'react';
import {
  Modal, Button, Form,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import showimg from '../../assets/home/banner.jpg';
import cross from '../../assets/home/black-icon.png';

const ViewAppointmentCheckList = ({
  checkList, handleCheckList, handleMarkasComplete, chemicalsAdded, handleImageChange, image, removePhotos, AddCaptions, checklistArray,
  handleChecked,
}) => (
  <Modal
    size="md"
    show={checkList}
    onHide={handleCheckList}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="staff-modal view-appointment-popup checklist-popup"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleCheckList}
        aria-hidden="true"
      />
    </div>
    <Modal.Body>
      <div className="staff-form">
        <h6>Appointment checklist</h6>
        {checklistArray && checklistArray.map((el, i) => (
          <div className="added-chemicals">
            <h6>
              <span>
                {i + 1}
                .
              </span>
              {' '}
              {el.name}
            </h6>
            <div className="open-date client-notes">
              {el.checked ? (
                <div className="basic">
                  <Form.Check type="checkbox" checked={el.checked} onClick={(e) => handleChecked(e, i)} />
                </div>
              ) : (
                <div className="basic cross-check">
                  <Form.Check type="checkbox" checked={el.tempChecked} onClick={(e) => handleChecked(e, i)} />
                </div>
              )}
            </div>
          </div>
        ))}
        <div className="take-photo">

          {image && image.length > 0 ? (
            <>
              {image && image.map((el, i) => (
                <>
                  <div className="show-img">
                    <img src={el.image} alt="img" />

                  </div>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Enter Photo Caption" value={el.caption} />
                  </Form.Group>
                </>
              ))}
            </>
          ) : (
            <>
              <div className="show-img">
                <img src={showimg} alt="img" />
                <div className="replace-img">
                  <img src={cross} alt="cancel" name="removePhoto" />
                </div>
              </div>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control type="text" placeholder="Enter Photo Caption" />
                <img src={cross} alt="cancel" name="removeCaption" />
              </Form.Group>
            </>
          )}
        </div>
      </div>
    </Modal.Body>
  </Modal>
);
ViewAppointmentCheckList.propTypes = {
  checkList: PropTypes.func.isRequired,
  handleCheckList: PropTypes.func.isRequired,
  handleMarkasComplete: PropTypes.func.isRequired,
  chemicalsAdded: PropTypes.bool.isRequired,
  handleImageChange: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  removePhotos: PropTypes.func.isRequired,
  AddCaptions: PropTypes.func.isRequired,
  checklistArray: PropTypes.arrayOf.isRequired,
  handleChecked: PropTypes.func.isRequired,
};
export default ViewAppointmentCheckList;
