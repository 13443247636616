/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';

function StaffForm(props) {
  const {
    handleChange, staffDetails, handleAddStaff, showForm, handleFormModal, editStaff, selectedStaff, selectedCountry,
    handleCountry, compLocation, error, mobileFormat,
  } = props;

  return (
    <Modal
      size="lg"
      show={showForm}
      onHide={handleFormModal}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={handleFormModal} aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Staff
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form onSubmit={(e) => { e.preventDefault(); }}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-1" className="italic-text">
                  <Form.Label>
                    First name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="firstname"
                    value={staffDetails.firstname}
                    placeholder="Enter First Name"
                    className={`${
                      error === 'firstname'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-2" className="italic-text">
                  <Form.Label>
                    Last name
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={handleChange}
                    name="lastname"
                    value={staffDetails.lastname}
                    placeholder="Enter Last Name"
                    className={`${
                      error === 'lastname'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-3" className="number italic-text">
                  <Form.Label>
                    Mobile No.
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="number-field">
                    {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                    <div className="number-box">

                      <Dropdown id="mobile">
                        <Dropdown.Toggle variant="success" id="dropdown-basic1">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span><i className="fas fa-caret-down" /></span>
                          {/* <div className="country-code">{selectedCountry}</div> */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${error === 'mobileNo' ? 'error-text' : ''}`}
                      format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                      onChange={handleChange}
                      name="mobileNo"
                      value={staffDetails.mobileNo}
                      placeholder="Enter Mobile Number"
                      autocomplete="off"
                    />
                  </div>
                </Form.Group>
                {/* <Form.Group controlId="formBasicInput-3" className="number">
                  <Form.Label>Mobile No.</Form.Label>
                  <div className="flag-icon">
                    <img src={flag} alt="flag" />
                  </div>
                  <NumberFormat
                    className="form-control"
                    format="+61 ### ### ###"
                    onChange={handleChange}
                    name="mobileNo"
                    value={staffDetails.mobileNo}
                  />
                </Form.Group> */}
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-34" className="number">
                  <Form.Label>Landline No.</Form.Label>
                  <div className="number-field">
                    {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                    <div className="number-box">

                      <Dropdown id="landline">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span><i className="fas fa-caret-down" /></span>
                          {/* <div className="country-code">{}</div> */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleCountry('+61')}>
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCountry('+1')}>
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <NumberFormat
                      className={`form-control mobile-num ${error === 'landlineNo' ? 'error-text' : ''}`}
                      format="# #### ####"
                      onChange={handleChange}
                      name="landlineNo"
                      value={staffDetails.landlineNo}
                      autocomplete="off"
                    />
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-5" className="italic-text">
                  <Form.Label>
                    Email Address
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={handleChange}
                    name="email"
                    value={staffDetails.email}
                    placeholder="Enter Email Address"
                    className={`${
                      error === 'email'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-6">
                  <Form.Label>Street Address </Form.Label>
                  <Form.Control type="text" onChange={handleChange} name="streetAddress" value={staffDetails.streetAddress} />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-7">
                  <Form.Label>Suburb</Form.Label>
                  <Form.Control type="text" onChange={handleChange} name="suburb" value={staffDetails.suburb} />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-8">
                  <Form.Label>Postcode</Form.Label>
                  <Form.Control type="text" onChange={handleChange} name="postcode" value={staffDetails.postcode} />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicInput-9">
                  <Form.Label>State</Form.Label>
                  <Form.Control type="text" onChange={handleChange} name="state" value={staffDetails.state} />
                </Form.Group>
              </div>
              {/* <div className="row">
              <div className="col-md-6">
                <Form.Group className="for-disable" controlId="formBasicInput-9">
                  <Form.Label>Working Days & Hours</Form.Label>
                  <Form.Control type="text" placeholder="View" disabled />
                  <span className="angle-right">
                    <a
                      href="#/"
                      onClick={() => HandleOpenCloseTable('fromAddStaff')}
                    >
                      <i className="fas fa-angle-right" />
                    </a>
                  </span>
                </Form.Group>

              </div>
            </div> */}
              <div className="col-md-6">
                <Form.Group controlId="formBasicAccessLevel">
                  <Form.Label>
                    Access Level
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      value={staffDetails.accessLevel}
                      onChange={handleChange}
                      name="accessLevel"
                      // placeholder="Select"
                      className={`${
                        error === 'accesslevel'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      <option value="noAccess">No Access</option>
                      <option value="low">Low</option>
                      <option value="medium"> Medium</option>
                      <option value="high"> High</option>
                      {/* <option value="owner"> Owner</option> */}
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="formBasicLocation">
                  <Form.Label>
                    Location
                    {' '}
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      value={staffDetails.location}
                      onChange={handleChange}
                      name="location"
                      className={`${
                        error === 'location'
                          ? 'error-text'
                          : ''
                      }`}
                    >
                      <option value="">Select</option>
                      {
                        compLocation.map((el) => (
                          <option key={el._id} value={el._id}>{el.locationName}</option>
                        ))
                      }

                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group className="form-radio" onChange={handleChange} name="gender">
                  <Form.Check
                    type="radio"
                    label="Male"
                    name="gender"
                    id="male"
                    checked={staffDetails.gender === 'male'}
                  />
                  <Form.Check
                    type="radio"
                    label="Female"
                    name="gender"
                    id="female"
                    checked={staffDetails.gender === 'female'}
                  />
                </Form.Group>
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicHiddenEmployees">
                  <Form.Label>Hide Employee</Form.Label>
                  <div className="select-box">
                    <Form.Control as="select" name="hide" onChange={handleChange}>
                      <option value={false}>No</option>
                      <option value selected={staffDetails.hide === true}> Yes </option>
                    </Form.Control>
                  </div>
                </Form.Group>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Employee Notes</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={handleChange} name="note" value={staffDetails.note} />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn">
                  <Button type="button" className="btn btn-edit" onClick={() => { handleFormModal(); }}> Cancel </Button>
                  <Button type="Submit" className="btn btn-save" onClick={selectedStaff ? editStaff : handleAddStaff}> Save/Update </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>

      </Modal.Body>
    </Modal>
  );
}
StaffForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  staffDetails: PropTypes.func.isRequired,
  handleAddStaff: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  selectedStaff: PropTypes.func.isRequired,
  editStaff: PropTypes.func.isRequired,
  selectedCountry: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  compLocation: PropTypes.arrayOf.isRequired,
  error: PropTypes.string.isRequired,
  mobileFormat: PropTypes.string.isRequired,

};

export default StaffForm;
