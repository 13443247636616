import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import PoolReport from './poolReport';
import { roundUpNext, litresUnits } from './calculatorUtils';

const PoolMainDataSelection = (props) => {
  const {
    poolInputs,
    result,
    chlorinationType,
    getValue,
    serviceAnalysis,
    greenSplitName,
    handleChlorination,
    handleManualValues,
    waterPoolStatus,
    showModel,
    chlorineModel,
    handleChlorinationModel,
    manualValue,
    selectedModel,
    waterLossCalculator,
    waterLossStatus,
    valueType,
    showFullHardness,
  } = props;
  return (
    <>

      {serviceAnalysis === 'Drain Pool'
          && greenSplitName === 'Part'
          && !result && (
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label>Drain Percentage</Form.Label>
              </Col>
              <Col>
                <Form.Group controlId="formBasicInput-6" className="custom-input drain-percentage">
                  {/* <span className="cholinator-text">+</span> */}
                  {/* <Form.Control type="number" name="okNegative" /> */}
                  <Form.Control
                    type="text"
                    className="form-control spec-depth"
                    maxLength="3"
                    disabled
                    value={poolInputs.drainPercentWaterLevelLowered}
                  />
                  <span className="mm">%</span>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form.Group>
      )}
      {serviceAnalysis === 'Water Loss Calculator' && waterLossStatus ? (
        <>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label>Wasted Water Amount</Form.Label>
              </Col>
              <Col>
                <Form.Group controlId="formBasicInput-6" className="custom-input drain-percentage">
                  <span className="loss-text">
                    {`${poolInputs.waterLossPerDay ? roundUpNext(poolInputs.waterLossPerDay, 5) : 0} ${waterLossCalculator === 'Water Loss Per Day' ? `${valueType ? 'litres' : 'gallons'} per day loss` : `${valueType ? 'litres' : 'gallons'} per week loss`}`}
                  </span>
                  {/* <Form.Control type="number" name="okNegative" /> */}
                  <Form.Control
                    type="text"
                    className="form-control spec-depth"
                    maxLength="3"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col column="xs" md={7} xs={5} />
              <Col>
                <Form.Group controlId="formBasicInput-6" className="custom-input drain-percentage">
                  <span className="loss-text">
                    {`${poolInputs.waterLossPerHour ? litresUnits(parseFloat(poolInputs.waterLossPerHour), valueType).chemValue : 0} ${litresUnits(parseFloat(poolInputs.waterLossPerHour), valueType).unit} per hour loss`}
                  </span>
                  {/* <Form.Control type="number" name="okNegative" /> */}
                  <Form.Control
                    type="text"
                    className="form-control spec-depth"
                    maxLength="3"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col column="xs" md={7} xs={5} />
              <Col>
                <Form.Group controlId="formBasicInput-6" className="custom-input drain-percentage">
                  <span className="loss-text">
                    {poolInputs.waterLossPerMinute
                      ? parseFloat(poolInputs.waterLossPerMinute) < 1000
                        ? `${roundUpNext(poolInputs.waterLossPerMinute, 5)} ${valueType ? 'ml' : 'fl oz'} per minute loss`
                        : `${litresUnits(parseFloat(poolInputs.waterLossPerMinute) / 1000, valueType).chemValue} ${litresUnits(parseFloat(poolInputs.waterLossPerMinute) / 1000, valueType).unit} per minute loss`
                      : 0 }
                  </span>
                  <Form.Control
                    type="text"
                    className="form-control spec-depth"
                    maxLength="3"
                    disabled
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          </Form.Group>

        </>
      ) : null}
      {result || serviceAnalysis === 'Water Loss Calculator' ? (
        null
      ) : (
        <div>
          {/* {greenSplitName !== 'Part'
          && ( */}
          <Form.Group controlId="exampleForm.SelectCustom" className={`${serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus ? 'delete-icon' : ''}`}>
            <Form.Row>
              <Col column="xs" md={7} xs={5}>
                <Form.Label>Chlorination</Form.Label>
              </Col>
              <Col>
                <div className="select-box">
                  <Form.Control
                    as="select"
                    custom
                    onChange={handleChlorination}
                    value={chlorinationType}
                    disabled={serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus}
                  >
                    <option value="Salt Pool">Salt Pool</option>
                    {valueType && (
                    <option value="Mineral Pool">
                      Mineral Pool with Chlorinator
                    </option>
                    )}
                    {serviceAnalysis === 'Pool Water Volume Verification via Salinity' ? null : (
                      <>
                        {valueType && (
                          (showFullHardness?.fullhardness || greenSplitName === 'Drain') ? null : (
                            <option value="Calcium Hardness Titration Test">
                              Mineral Pool with Chlorinator + Calcium Hardness Titration Test
                            </option>
                          )
                        )}
                        {valueType && (
                        <option value="Convert Salt Pool to Mineral Pool">
                          Convert Salt Pool to Mineral Pool
                        </option>
                        )}
                        <option value="Manually Chlorinated">
                          Manually Chlorinated
                        </option>
                      </>
                    )}
                  </Form.Control>
                </div>
              </Col>
            </Form.Row>
          </Form.Group>
          {/* )} */}

          {chlorinationType === 'Manually Chlorinated' && serviceAnalysis !== 'Pool Water Volume Verification via Salinity' ? (
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Row>
                <Col column="xs" md={7} xs={5}>
                  <Form.Label>Pool Type</Form.Label>
                </Col>
                <Col>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      custom
                      onChange={handleManualValues}
                      value={manualValue}
                    >
                      <option value="Non-Mineral Pool">
                        Non-Mineral Pool
                      </option>
                      <option value="Convert Pool to Mineral Pool">
                        Convert Pool to Mineral Pool
                      </option>
                      <option value="Aquatherepe Transform Maintenance">
                        Aquatherepe Transform Maintenance
                      </option>
                      {(showFullHardness?.fullhardness || greenSplitName === 'Drain') ? null : (
                        <option value="Aquatherepe Transform Maintenance + Calcium Hardness Titration Test">
                          Aquatherepe Transform Maintenance + Calcium Hardness Titration Test
                        </option>
                      )}
                    </Form.Control>
                  </div>
                </Col>
              </Form.Row>
            </Form.Group>
          ) : (
            ''
          )}
        </div>
      )}
      {serviceAnalysis === 'Pool Water Volume Verification via Salinity' ? null : (
        <>
          {showModel && (chlorinationType !== 'Manually Chlorinated') ? (
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Row>
                <Col column="xs" md={7} xs={5}>
                  <Form.Label>
                    {chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test'
                || chlorinationType
                  === 'Convert Salt Pool to Mineral Pool'
                      ? 'Mineral Type'
                      : ' Chlorinator Model'}
                  </Form.Label>
                </Col>
                <Col>
                  <div className="select-box">
                    <Form.Control
                      as="select"
                      custom
                      onChange={handleChlorinationModel}
                      value={selectedModel}
                    >
                      {chlorineModel?.length > 0 && chlorineModel.map((el) => <option key={el?.name || el?.brandName || el} value={el?.name || el?.brandName || el}>{el?.name || el?.brandName || el}</option>)}
                    </Form.Control>
                  </div>
                </Col>
              </Form.Row>
            </Form.Group>
          ) : null}
        </>
      )}
    </>

  );
};

PoolMainDataSelection.propTypes = {
  poolInputs: PropTypes.objectOf.isRequired,
  result: PropTypes.bool.isRequired,
  chlorinationType: PropTypes.string.isRequired,
  getValue: PropTypes.string.isRequired,
  serviceAnalysis: PropTypes.string.isRequired,
  greenSplitName: PropTypes.string.isRequired,
  handleChlorination: PropTypes.func.isRequired,
  handleManualValues: PropTypes.func.isRequired,
  waterPoolStatus: PropTypes.bool.isRequired,
  showModel: PropTypes.bool.isRequired,
  chlorineModel: PropTypes.arrayOf.isRequired,
  handleChlorinationModel: PropTypes.func.isRequired,
  manualValue: PropTypes.string.isRequired,
  selectedModel: PropTypes.number.isRequired,
  waterLossCalculator: PropTypes.func.isRequired,
  waterLossStatus: PropTypes.bool.isRequired,
  valueType: PropTypes.bool.isRequired,
  showFullHardness: PropTypes.objectOf.isRequired,
};

export default PoolMainDataSelection;
