import React from 'react';
import {
  Button, Accordion, Card, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import showmore from '../../assets/home/showmore.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import DeletePopUp from '../DeletePopUp';
import sort from '../../assets/desktop-view/sort.png';
import add from '../../assets/desktop-view/add.png';

const WebProductServices = ({
  setIsServiceAccordOpen,
  renderSort,
  setRenderSort,
  HandleOpenClose,
  servicesList,
  handleUpAccordion,
  selectedNestedAccordion,
  handleNestedAccordion,
  openService,
  selectedAccordion,
  handleEditCategory,
  handleOpenSubCat,
  moveArr,
  submitRemoveCategory,
  convertToHour,
  addZero,
  handleEditSubcategory,
  submitRemove,
  moveArrSubcategory,
  renderSortProduct,
  setRenderSortProduct,
  openProduct,
}) => (
  <>
    <div className="service-mbl">
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <div className="service-container">
              <div className="service-title title-service">
                <h4>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    onClick={() => setIsServiceAccordOpen((el) => !el)}
                  >
                    {/* <i className={isServiceAccordOpen ? 'fas far fa-chevron-down' : 'fas far fa-chevron-up'} /> */}
                    {' '}
                    Services
                  </Accordion.Toggle>
                </h4>
                {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                <div
                  className={`services-header ${renderSort && 'active-sort'}`}
                >
                  <img
                    src={sort}
                    alt="sorting"
                    onClick={() => setRenderSort(!renderSort)}
                  />
                  <img
                    src={add}
                    alt="sorting"
                    onClick={() => HandleOpenClose('service')}
                  />
                </div>
              </div>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0" hidden={!servicesList?.length}>
            <div className="mob-table">
              {servicesList
                .filter((data) => data.type === 'service')
                .map((el, i) => (
                  <Accordion
                    className="mob-accordion outer-accordion"
                    key={el._id}
                  >
                    <Card.Header>
                      <div className="heading-accordion">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={
                            !el.subCategory.length ? null : i.toString()
                          }
                          onClick={() => handleUpAccordion(el._id)}
                        >
                          <h5>{el.category}</h5>
                        </Accordion.Toggle>
                        <div
                          className={`service-edit-icon ${
                            renderSort && 'add-sort'
                          }`}
                        >
                          {renderSort ? (
                            <div
                              className={`sorting up-down ${
                                renderSort && 'active-sort'
                              }`}
                            >
                              {i !== 0 ? (
                                <img
                                  src={upsort}
                                  alt="sorting"
                                  onClick={() => moveArr(
                                    i,
                                    i - 1,
                                    'service',
                                    el.order - 1,
                                    'minus',
                                  )}
                                />
                              ) : null}
                              {i
                              !== servicesList.filter(
                                (data) => data.type === 'service',
                              ).length
                                - 1 ? (
                                  <img
                                    src={downsort}
                                    alt="sorting"
                                    onClick={() => moveArr(
                                      i,
                                      i + 1,
                                      'service',
                                      el.order + 1,
                                      'plus',
                                    )}
                                  />
                                ) : null}
                            </div>
                          ) : null}
                          <div className="action-web-btn">
                            <i
                              className="las la-edit"
                              onClick={() => handleEditCategory(
                                el._id,
                                el.category,
                                el.type,
                                i,
                                el.appointmentChecklist,
                              )}
                            />
                            {el.category.match(/Misc/g) ? null : (
                              <i
                                className="las la-trash-alt"
                                onClick={() => submitRemoveCategory(el._id, el.type)}
                              />
                            )}
                            {/* {i === 0 && el.subCategory.length >= 1 ? null : (
                              <>
                                {' '} */}
                            <i
                              className="las la-plus-circle"
                              onClick={() => handleOpenSubCat(el.category, el.type, i)}
                            />
                            {/* {' '}
                              </>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse
                      eventKey={i.toString()}
                      className={`${openService === i ? 'show' : ''}`}
                    >
                      <Card.Body>
                        <div className="header-section">
                          <h6>Name</h6>
                          <h6>Description</h6>
                          <h6>
                            Buy Cost
                            {' '}
                            <br />
                            <span>(ex tax)</span>
                          </h6>
                          <h6>
                            Sell Price
                            {' '}
                            <br />
                            <span>(ex tax)</span>
                          </h6>
                          <h6>Action</h6>
                        </div>
                        {el.subCategory.map((data, index) => (
                          <div className="body-section-list header-section">
                            <div className="services-body-list">
                              <p>{data?.name}</p>
                            </div>
                            <div className="services-body-list">
                              <p>{data?.serviceDescription}</p>
                              <p className="item-type">
                                <span>
                                  Non-Inventory item | Duration 5 Min | Tax 10%
                                </span>
                              </p>
                            </div>
                            <div className="services-body-list">
                              <p>
                                {' '}
                                {`${data.tax ? data.tax : 0}%`}
                              </p>
                              <p>
                                <span>per item</span>
                              </p>
                            </div>
                            <div className="services-body-list">
                              <p>
                                $
                                {`${addZero(data.cost)}`}
                              </p>
                              <p>
                                <span>per item</span>
                              </p>
                            </div>
                            <div className="services-body-list">
                              <p>
                                <div className="action-icon">
                                  <i className="las la-edit" onClick={() => handleEditSubcategory(index, el._id, 'service', el.category, el.type)} />
                                  {!el.category.toLowerCase().includes('misc') ? (
                                    <>
                                      {' '}
                                      <i className="las la-trash-alt" onClick={() => submitRemove(index, el._id, el.category, el.type)} />
                                    </>
                                  ) : null}
                                </div>
                              </p>
                            </div>
                          </div>
                        ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                ))}
            </div>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
    <div className="service-mbl products">
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <div className="service-container">
              <div className="service-title title-service">
                <h4>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    onClick={() => setIsServiceAccordOpen((el) => !el)}
                  >
                    {/* <i className={isServiceAccordOpen ? 'fas far fa-chevron-circle-down' : 'fas far fa-chevron-circle-up'} /> */}
                    {' '}
                    Products
                  </Accordion.Toggle>
                </h4>
                {/* <span className="sort-icon"><img src={sort} alt="sort" /></span> */}
                <div
                  className={`services-header ${
                    renderSortProduct && 'active-sort'
                  }`}
                >
                  <img
                    src={sort}
                    alt="sorting"
                    onClick={() => setRenderSortProduct(!renderSortProduct)}
                  />
                  <img
                    src={add}
                    alt="sorting"
                    onClick={() => HandleOpenClose('product')}
                  />
                  {/* <AddCategory
                              show={show}
                              HandleOpenClose={HandleOpenClose}
                              handleManageCategory={handleManageCategory}
                              category={category}
                              setCategory={setCategory}
                              servicesList={servicesList}
                            /> */}
                </div>
              </div>
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0" hidden={!servicesList.length}>
            <div className="mob-table">
              {servicesList
                .filter((data) => data.type === 'product')
                .map((el, i) => (
                  <Accordion
                    className="mob-accordion outer-accordion"
                    key={el._id}
                  >
                    <Card.Header>
                      <div className="heading-accordion">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={
                            !el.subCategory.length ? null : i.toString()
                          }
                          onClick={() => handleUpAccordion(el._id)}
                        >
                          <h5>{el.category}</h5>
                        </Accordion.Toggle>
                        <div
                          className={`service-edit-icon ${
                            renderSortProduct && 'add-sort'
                          }`}
                        >
                          {renderSortProduct ? (
                            <div
                              className={`sorting up-down ${
                                renderSortProduct && 'active-sort'
                              }`}
                            >
                              {i !== 0 ? (
                                <img
                                  src={upsort}
                                  alt="sorting"
                                  onClick={() => moveArr(
                                    i,
                                    i - 1,
                                    'product',
                                    el.order - 1,
                                    'minus',
                                  )}
                                />
                              ) : null}
                              {i
                              !== servicesList.filter(
                                (data) => data.type === 'product',
                              ).length
                                - 1 ? (
                                  <img
                                    src={downsort}
                                    alt="sorting"
                                    onClick={() => moveArr(
                                      i,
                                      i + 1,
                                      'product',
                                      el.order + 1,
                                      'plus',
                                    )}
                                  />
                                ) : null}
                            </div>
                          ) : null}
                          <div className="action-web-btn">
                            <i
                              className="las la-edit"
                              onClick={() => handleEditCategory(
                                el._id,
                                el.category,
                                el.type,
                                i,
                                el.appointmentChecklist,
                              )}
                            />
                            {el.category.match(/Misc/g) ? null : (
                              <i
                                className="las la-trash-alt"
                                onClick={() => submitRemoveCategory(el._id, el.type)}
                              />
                            )}
                            {/* {i === 0 && el.subCategory.length >= 1 ? null : (
                              <>
                                {' '} */}
                            <i
                              className="las la-plus-circle"
                              onClick={() => handleOpenSubCat(
                                el.category,
                                el.type,
                                i,
                                el._id,
                              )}
                            />
                            {/* {' '}
                              </>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </Card.Header>
                    <Accordion.Collapse
                      eventKey={i.toString()}
                      className={`${openProduct === i ? 'show' : ''}`}
                    >
                      <Card.Body>
                        <div className="inner-accordion">
                          <div className="header-section">
                            <h6>Name</h6>
                            <h6>Description</h6>
                            <h6>
                              Buy Cost
                              {' '}
                              <br />
                              <span>(ex tax)</span>
                            </h6>
                            <h6>
                              Sell Price
                              {' '}
                              <br />
                              <span>(ex tax)</span>
                            </h6>
                            <h6>Action</h6>
                          </div>
                          {el.subCategory.map((data, index) => (
                            <div className="body-section-list header-section">
                              <div className="services-body-list">
                                <p>{data?.name}</p>
                              </div>
                              <div className="services-body-list">
                                <p>{data?.serviceDescription}</p>
                                <p className="item-type">
                                  <span>
                                    Non-Inventory item | Duration 5 Min | Tax
                                    10%
                                  </span>
                                </p>
                              </div>
                              <div className="services-body-list">
                                <p>
                                  {' '}
                                  {`${data.tax ? data.tax : 0}%`}
                                </p>
                                <p>
                                  <span>per item</span>
                                </p>
                              </div>
                              <div className="services-body-list">
                                <p>
                                  $
                                  {`${addZero(data.cost)}`}
                                </p>
                                <p>
                                  <span>per item</span>
                                </p>
                              </div>
                              <div className="services-body-list">
                                <p>
                                  <div className="action-icon">
                                    <i className="las la-edit" onClick={() => handleEditSubcategory(index, el._id, 'product', el.category, el.type)} />
                                    {!el.category.toLowerCase().includes('misc') ? (
                                      <>
                                        {' '}
                                        <i className="las la-trash-alt" onClick={() => submitRemove(index, el._id, el.category, el.type, data.s_uId)} />
                                      </>
                                    ) : null}

                                  </div>
                                </p>
                              </div>
                            </div>
                          ))}
                          {/* {el.subCategory.map((data, index) => (
                            <>
                              <Accordion
                                defaultActiveKey={i.toString()}
                                onClick={() => handleNestedAccordion(index)}
                              >
                                <Card>
                                  <Accordion>
                                    <Card.Header className="sub-category">
                                      <Accordion.Toggle
                                        as={Button}
                                        variant="link"
                                        eventKey={index.toString()}
                                      >
                                        <div className="product-service-list">
                                          <span>
                                            {`${data.name}`}
                                            <span className="span-measure inner-measure">
                                              {data.soldPer
                                              === 'Add Custom Measure'
                                                ? `per ${data.unit} (${data.symbol})`
                                                : `per ${data.soldPer}`}
                                            </span>
                                          </span>
                                        </div>
                                      </Accordion.Toggle>
                                      <span className="time-category">
                                        {renderSortProduct ? (
                                          <div className="sorting up-down">
                                            {index !== 0 ? (
                                              <img
                                                src={upsort}
                                                alt="sorting"
                                                onClick={() => moveArrSubcategory(
                                                  el._id,
                                                  index,
                                                  index - 1,
                                                )}
                                              />
                                            ) : null}
                                            {index
                                            !== el.subCategory.length - 1 ? (
                                              <img
                                                src={downsort}
                                                alt="sorting"
                                                onClick={() => moveArrSubcategory(
                                                  el._id,
                                                  index,
                                                  index + 1,
                                                )}
                                              />
                                              ) : null}
                                          </div>
                                        ) : null}

                                        {!renderSortProduct ? (
                                          <div className="timehour">
                                            {convertToHour(data.duration)}
                                          </div>
                                        ) : null}
                                      </span>
                                    </Card.Header>
                                    <Accordion.Collapse
                                      eventKey={index.toString()}
                                      key={data.name + Math.random()}
                                    >
                                      <ul className="grid-list">
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3>
                                              Product Code
                                            </h3>
                                            <div className="grid-content">
                                              {data.productCode
                                                ? data.productCode
                                                : '-'}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3 className="yellow-text">
                                              Barcode
                                            </h3>
                                            <div className="grid-content">
                                              {data.barCode
                                                ? data.barCode
                                                : '-'}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3>
                                              Description
                                            </h3>
                                            <div className="grid-content">
                                              {data.serviceDescription}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3 className="yellow-text">
                                              Supplier
                                            </h3>
                                            <div className="grid-content">
                                              {data.supplier
                                                ? data.supplier
                                                : '-'}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3>
                                              Product Sold Per
                                            </h3>
                                            <div className="grid-content">
                                              {data.soldPer}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3>
                                              Tracked Inventory Product

                                            </h3>
                                            <div className="grid-content">
                                              {data.trackedInventory}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3 className="yellow-text">
                                              Supply Unit
                                              {' '}
                                            </h3>
                                            <div className="grid-content">
                                              {data.supplyUnit
                                                ? data.supplyUnit
                                                : 'N/A'}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3 className="yellow-text">
                                              Duration
                                            </h3>
                                            <div className="grid-content">
                                              {data.duration
                                                ? convertToHour(data?.duration)
                                                : '-'}
                                            </div>
                                          </div>
                                        </li>
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3>Tax</h3>
                                            <div className="grid-content">
                                              {`${data.tax ? data.tax : 0}%`}
                                            </div>
                                          </div>
                                        </li>
                                        {!el.category
                                          .toLowerCase()
                                          .includes('misc') ? (
                                            <>
                                              <li className="grid-list-item">
                                                <div className="grid-box">
                                                  <h3>
                                                    {' '}
                                                    <span className="yellow-text">
                                                      Average
                                                    </span>
                                                    {' '}
                                                    Buy Cost (ex tax)
                                                  </h3>
                                                  <div className="grid-content">
                                                    $
                                                    {`${addZero(data.cost)}`}
                                                    <span className="span-measure">
                                                      {data.soldPer
                                                    === 'Add Custom Measure'
                                                        ? `per ${data.unit} (${data.symbol})`
                                                        : `per ${data.soldPer}`}
                                                    </span>
                                                  </div>
                                                </div>
                                              </li>
                                              <li className="grid-list-item">
                                                <div className="grid-box">
                                                  <h3>
                                                    <span className="yellow-text">
                                                      Average
                                                    </span>
                                                    {' '}
                                                    Sell Price (ex tax)
                                                  </h3>
                                                  <div className="grid-content">
                                                    $
                                                    {`${addZero(
                                                      data.retailCost,
                                                    )}`}
                                                    <span className="span-measure">
                                                      {data.soldPer
                                                    === 'Add Custom Measure'
                                                        ? `per ${data.unit} (${data.symbol})`
                                                        : `per ${data.soldPer}`}
                                                    </span>
                                                  </div>
                                                </div>
                                              </li>
                                            </>
                                          ) : null}
                                        <li className="grid-list-item">
                                          <div className="grid-box">
                                            <h3>Actions</h3>
                                            <div className="action-btn">
                                              <Button
                                                className="edit-btn"
                                                onClick={() => handleEditSubcategory(
                                                  index,
                                                  el._id,
                                                  'product',
                                                  el.category,
                                                  el.type,
                                                )}
                                              >
                                                <img
                                                  src={whiteedit}
                                                  alt="edit"
                                                />
                                              </Button>
                                              {!el.category
                                                .toLowerCase()
                                                .includes('misc') ? (
                                                  <Button
                                                    className="delete-btn"
                                                    onClick={() => submitRemove(
                                                      index,
                                                      el._id,
                                                      el.category,
                                                      el.type,
                                                      data.s_uId,
                                                    )}
                                                  >
                                                    <img
                                                      src={whitedelete}
                                                      alt="edit"
                                                    />
                                                  </Button>
                                                ) : null}
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </Accordion.Collapse>
                                  </Accordion>
                                </Card>
                              </Accordion>
                            </>
                          ))} */}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Accordion>
                ))}
            </div>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  </>
);

WebProductServices.propTypes = {
  moveArrSubcategory: PropTypes.arrayOf.isRequired,
  selectedNestedAccordion: PropTypes.arrayOf.isRequired,
  servicesList: PropTypes.arrayOf.isRequired,
  setIsServiceAccordOpen: PropTypes.bool.isRequired,
  renderSort: PropTypes.bool.isRequired,
  handleNestedAccordion: PropTypes.isRequired,
  openService: PropTypes.isRequired,
  selectedAccordion: PropTypes.isRequired,
  handleEditCategory: PropTypes.isRequired,
  handleOpenSubCat: PropTypes.isRequired,
  moveArr: PropTypes.isRequired,
  submitRemoveCategory: PropTypes.isRequired,
  convertToHour: PropTypes.isRequired,
  addZero: PropTypes.isRequired,
  handleEditSubcategory: PropTypes.isRequired,
  submitRemove: PropTypes.isRequired,
  setRenderSort: PropTypes.isRequired,
  HandleOpenClose: PropTypes.isRequired,
  handleUpAccordion: PropTypes.isRequired,
  renderSortProduct: PropTypes.isRequired,
  openProduct: PropTypes.isRequired,
  setRenderSortProduct: PropTypes.isRequired,
};
export default WebProductServices;
