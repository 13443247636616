/* eslint-disable react/prop-types */
import React from 'react';
import {
  Button, Form, Col, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const BalanceWater = ({
  state, handleChangeBalance, handleRequest, handleRevertViewUpdate, handleNewRevertDefaultUpdate, change,
}) => (

  <div className="pool-setup balance-water drainage-cost-section">
    <div className="target-heading">
      <h6>Chemicals Used to Balance Water</h6>
    </div>
    <div className="pool-chemistry-box balance-water-box">
      {Array.isArray(state) ? (
        <>
          {state.map((el, index) => (
            <>
              {el.decreaseName !== 'N/A'
                ? (
                  <div className="free-chlorine-setup inner-chlorine">
                    <h5>{el.name === 'Calcium Hardness' ? 'Total Hardness' : el.name}</h5>

                    <div className="row">
                      <div className="col-5">
                        <h6>Increase </h6>
                      </div>
                      <div className="col-7">
                        <div className="select-box">
                          <Form.Control as="select" value={el.selected} custom onChange={(e) => handleChangeBalance(e, index)}>
                            {el.name === 'Free Chlorine'
                              ? (
                                <>
                                  <option value="Granular Chlorine">
                                    {' '}
                                    Granular Chlorine
                                  </option>
                                  <option value="Liquid Chlorine">
                                    {' '}
                                    Liquid Chlorine
                                  </option>
                                </>

                              )
                              : (
                                <option value={el.increaseName}>
                                  {' '}
                                  {el.increaseName}
                                </option>
                              )}

                          </Form.Control>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <h6>Decrease </h6>
                      </div>
                      <div className="col-7">
                        <div className="select-box">
                          <Form.Control as="select" value={el.selected} custom onChange={(e) => handleChangeBalance(e, index)}>
                            {el.name === 'pH' || el.name === 'Total Alkalinity (TA)'
                              ? (
                                <>
                                  <option value="Hydrochloric Acid">
                                    {' '}
                                    Hydrochloric Acid
                                  </option>
                                  <option value="Dry Acid">
                                    {' '}
                                    Dry Acid
                                  </option>
                                </>

                              ) : null}

                            {el.name === 'Total Hardness' || el.name === 'Calcium Hardness'
                              ? (
                                <>
                                  <option value="Reduce by Part-Draining">
                                    {' '}
                                    Reduce by Part-Draining
                                  </option>
                                  <option value="Zodiac Calcium Down">
                                    {' '}
                                    Zodiac Calcium Down
                                  </option>
                                </>
                              ) : null}

                            {el.name === 'pH' || el.name === 'Total Alkalinity (TA)' || (el.name === 'Total Hardness' || el.name === 'Calcium Hardness') ? null : (
                              <option value={el.decreaseName}>
                                {' '}
                                {el.decreaseName}
                              </option>
                            )}

                          </Form.Control>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                : (
                  <div className="free-chlorine-setup">
                    <div className="row">
                      <div className="col-5">
                        <h5>{el.name === 'Calcium Hardness' ? 'Total Hardness' : el.name}</h5>
                      </div>
                      <div className="col-7">
                        <div className="select-box">
                          {el.name === 'Stabiliser (CYA)'
                            ? (
                              <>
                                <Form.Control as="select" custom value={el.selected} onChange={(e) => handleChangeBalance(e, index)}>
                                  <option value="Granular Stabiliser">
                                    {' '}
                                    Granular Stabiliser
                                  </option>
                                  <option value="Lo-Chlor Instant Sunscreen">
                                    {' '}
                                    Lo-Chlor Instant Sunscreen
                                  </option>
                                </Form.Control>
                              </>

                            ) : el.name === 'Combined Chlorine' ? (
                              <>
                                <Form.Control as="select" custom value={el.selected} onChange={(e) => handleChangeBalance(e, index)}>
                                  <option value="Zodiac Oxy Fresh">
                                    {' '}
                                    Zodiac Oxy Fresh
                                  </option>
                                  <option value="AstralPool Oxy Boost">
                                    {' '}
                                    AstralPool Oxy Boost
                                  </option>
                                </Form.Control>
                              </>
                            ) : (
                              <Form.Control as="select" custom>

                                <option value={el.selected}>
                                  {' '}
                                  {el.selected}
                                </option>

                              </Form.Control>
                            )}

                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </>
          ))}
        </>
      )
        : null}

      <div className="view-btn">
        {/* <Button type="submit" className="btn btn-edit" onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button> */}
        <div className="client-option service-edit-icon">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
              View Other Settings
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleRevertViewUpdate(e, state)}>
                <div className="action">
                  Restore Original Settings
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleNewRevertDefaultUpdate(e, state)}>
                <div className="action">
                  Revert to Previous Saved Default
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Button type="submit" className="btn btn-save" onClick={handleRequest}>
          Save as Default
        </Button>
      </div>
    </div>

  </div>

);
BalanceWater.propTypes = {
  state: PropTypes.func.isRequired,
  handleChangeBalance: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};
export default BalanceWater;
