/* eslint-disable max-len */
import React from 'react';
import {
  Modal, Form, Button, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';

const AddBilingContact = ({
  showContactModal,
  handleContactModal,
  contactData,
  saveContactDetails,
  handleContactDetails,
  error,
  selectedCountry,
  handleCountry,
  mobileFormat,
  handleChange,
}) => (
  <Modal
    size="lg"
    show={showContactModal}
    onHide={handleContactModal}
    aria-labelledby="contained-modal-title-vcenter"
    top
    className="staff-modal"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleContactModal}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Additional Contact
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="staff-form">
        <Form>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1" className="italic-text">
                <Form.Label>
                  {' '}
                  Contact First Name
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleContactDetails}
                  value={contactData.name}
                  placeholder="Enter Contact First Name"
                  className={`${
                    error === 'firstname'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1" className="italic-text">
                <Form.Label>
                  Contact Last Name
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  onChange={handleContactDetails}
                  value={contactData.lastName}
                  placeholder="Enter Contact Last Name"
                  className={`${
                    error === 'lastname'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-3" className="number italic-text">
                <Form.Label>
                  Mobile No.
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <div className="number-field">
                  {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                  <div className="number-box">

                    <Dropdown id="mobile">
                      <Dropdown.Toggle variant="success" id="dropdown-basic1">
                        <div className="flag-icon">
                          <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                        </div>
                        {selectedCountry === '+61' ? (
                          <span>
                            Australia
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : selectedCountry === '+1' ? (
                          <span>
                            United States
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : ''}
                        <span><i className="fas fa-caret-down" /></span>
                        {/* <div className="country-code">{selectedCountry}</div> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                          <div className="flag-icon">
                            <img src={flag} alt="flag" />
                          </div>
                          <div className="country-code">
                            <span>Australia</span>
                            +61
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                          <div className="flag-icon">
                            <img src={us} alt="flag" />
                          </div>
                          <div className="country-code">
                            {' '}
                            <span>United States</span>
                            +1
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NumberFormat
                    className={`form-control mobile-num ${error === 'mobile' ? 'error-text' : ''}`}
                    format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                    onChange={handleContactDetails}
                    name="mobile"
                    value={contactData.mobile}
                    placeholder="Enter Mobile Number"
                    autocomplete="off"
                  />
                </div>
              </Form.Group>
              {/* <Form.Group controlId="formBasicInput-3" className="number">
                  <Form.Label>Mobile No.</Form.Label>
                  <div className="flag-icon">
                    <img src={flag} alt="flag" />
                  </div>
                  <NumberFormat
                    className="form-control"
                    format="+61 ### ### ###"
                    onChange={handleChange}
                    name="mobileNo"
                    value={staffDetails.mobileNo}
                  />
                </Form.Group> */}
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-34" className="number">
                <Form.Label>Landline No.</Form.Label>
                <div className="number-field">
                  {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                  <div className="number-box">

                    <Dropdown id="landline">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <div className="flag-icon">
                          <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                        </div>
                        {selectedCountry === '+61' ? (
                          <span>
                            Australia
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : selectedCountry === '+1' ? (
                          <span>
                            United States
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : ''}
                        <span><i className="fas fa-caret-down" /></span>
                        {/* <div className="country-code">{}</div> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleCountry('+61')}>
                          <div className="flag-icon">
                            <img src={flag} alt="flag" />
                          </div>
                          <div className="country-code">
                            <span>Australia</span>
                            +61
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCountry('+1')}>
                          <div className="flag-icon">
                            <img src={us} alt="flag" />
                          </div>
                          <div className="country-code">
                            {' '}
                            <span>United States</span>
                            +1
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NumberFormat
                    className={`form-control mobile-num ${error === 'landlineNo' ? 'error-text' : ''}`}
                    format="# #### ####"
                    onChange={handleContactDetails}
                    name="landline"
                    value={contactData.landlineNo}
                    autocomplete="off"
                  />
                </div>
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1" className="italic-text">
                <Form.Label>
                  Email Address
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  onChange={handleContactDetails}
                  value={contactData.email}
                  placeholder="Enter Email Address"
                  className={`${
                    error === 'email'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn">
                <Button
                  className="btn btn-edit"
                  onClick={handleContactModal}
                >
                  Cancel
                </Button>
                <Button
                  className="btn btn-save"
                  onClick={saveContactDetails}
                >
                  Save/Update
                </Button>
              </div>
            </div>
          </div>

        </Form>
      </div>
    </Modal.Body>
  </Modal>
);

AddBilingContact.propTypes = {
  showContactModal: PropTypes.bool.isRequired,
  handleContactModal: PropTypes.func.isRequired,
  contactData: PropTypes.arrayOf.isRequired,
  saveContactDetails: PropTypes.func.isRequired,
  handleContactDetails: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  selectedCountry: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  mobileFormat: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default AddBilingContact;
