/* eslint-disable max-len */
import React from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

const AddEquipment = ({
  addeqipment,
  handleequipment,
  setEquipment,
  handleAddEquipment,
  equipment,
}) => (
  <Modal
    size="lg"
    show={addeqipment}
    onHide={handleequipment}
    aria-labelledby="contained-modal-title-vcenter"
    top
    className="staff-modal"
    centered
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleequipment}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        Add Equipment
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>

      <div className="staff-form">
        <Form>
          <div className="row">
            <div className="col-md-12">
              <Form.Group
                controlId="formInput1"
                className="italic-text"
              >
                <Form.Label column sm="3">
                  Name of Equipment Group
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name of Equipment Group"
                  name="category"
                  onChange={(e) => setEquipment(e.target.value)}
                  value={equipment}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <div className="view-btn right-btns">
                <Button
                  type="submit"
                  className="btn btn-save"
                  onClick={handleAddEquipment}
                >
                  Save/Update
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal.Body>
  </Modal>
);

AddEquipment.propTypes = {
  addeqipment: PropTypes.bool.isRequired,
  handleequipment: PropTypes.func.isRequired,
  handleAddEquipment: PropTypes.func.isRequired,

  setEquipment: PropTypes.func.isRequired,
  equipment: PropTypes.isRequired,
};

export default AddEquipment;
