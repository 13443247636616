import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Form, Dropdown, Button } from 'react-bootstrap';
import { removeCodeFromNumber, showErrMsg, validateDataWithKey } from '../../functions/index';
import { emailRegex } from '../../config/keys';
import { userInstance } from '../../config/axios';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import showmore from '../../assets/home/showmoreblue.png';
import './companyinfo.css';
import CompanyLocation from './companyLocation';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorBorderTime, errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';

const CompanyInfo = () => {
  const {
    setLocationInfo,
  } = useContext(AuthDataContext);
  const [view, setView] = useState('companyInfo');
  const [companyInformation, setCompanyInformation] = useState({
    companyName: '',
    address: '',
    suburb: '',
    postcode: '',
    state: '',
  });
  const [companyContactDetail, setCompanyContactDetails] = useState({
    firstname: '',
    lastname: '',
    mobile: '',
    landline: '',
    email: '',
    gender: 'male',
    note: '',
  });
  const [selectedCountry, setCountry] = useState('+61');
  const [errorValue, setErrorValue] = useState('');
  const [mobileFormat, setMobileFormat] = useState('### ### ###');
  const [loading, setLoading] = useState(false);
  const handleCompanyInformation = (e) => {
    const { name, value } = e.target;
    setCompanyInformation({ ...companyInformation, [name]: value });
  };

  const handleContactInformation = (e) => {
    const { name, value, id } = e.target;
    const detail = name === 'gender' ? id : value;
    setCompanyContactDetails({ ...companyContactDetail, [name]: detail });
  };
  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    if (countryCode === '+61') {
      setMobileFormat('### ### ###');
    }
    if (countryCode === '+1') {
      setMobileFormat('### ### ####');
    }
    const textToReplace = companyContactDetail.mobile.includes('+61') ? '+61' : '+1';
    console.log(textToReplace);
    const obj = {};
    if (companyContactDetail.mobile) {
      obj.mobile = `${companyContactDetail.mobile.replace(textToReplace, '')}`;
    }
    if (companyContactDetail.landline) {
      obj.landline = `${companyContactDetail.landline.replace(textToReplace, '')}`;
    }
    setCompanyContactDetails({ ...companyContactDetail, ...obj });
  };

  const saveCompanyInformation = async (e) => {
    e.preventDefault();
    // console.log('staff', staffData);
    const {
      firstname, lastname, email, mobile,
    } = companyContactDetail;
    const {
      address, suburb, postcode, state, companyName,
    } = companyInformation;

    const errorObjValues = {
      'Company Name': 'companyName',
      'Company Street Address': 'address',
      'Company Suburb': 'suburb',
      'Company Postcode': 'postcode',
      'Company State': 'state',
      'first name': 'firstname',
      'last name': 'lastname',
      email,
      mobile,
    };

    try {
      const { isValid, emptyField } = validateDataWithKey(
        {
          'Company Name': companyName,
          'Company Street Address': address,
          'Company Suburb': suburb,
          'Company Postcode': postcode,
          'Company State': state,
          'first name': firstname,
          'last name': lastname,
          email,
          mobile,
        },
      );
      if (isValid) {
        let isMobileValid = true;
        let isLandlineValid = true;
        let errorMsg = '';
        // let landlineMsg = '';
        if (emailRegex.test(String(companyContactDetail.email).toLowerCase())) {
          const payload = { ...companyContactDetail, email: email.toLowerCase() };
          const payload2 = { companyInformation: { ...companyInformation }, companyContactDetail: { ...companyContactDetail } };
          // if (payload.mobile === '') {
          //   setErrorValue('mobileno');
          //   isMobileValid = false;
          //   errorMsg = 'Please enter a mobile or landline number';
          // }

          if (selectedCountry === '+61') {
            if (payload.mobile.charAt(0) === '0') {
              payload.mobile = payload.mobile.replace('0', '');
            }
            if (payload.landline.charAt(0) === '0') {
              payload.landline = payload.landline.replace('0', '');
            }
            if (payload.mobile.charAt(0) !== '4') {
              // console.log('payload.mobileNo.charAt(4)', payload.mobileNo.charAt(4));
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setErrorValue('mobileno');
            }
            if (payload.mobile.trim().length !== 11) {
              setErrorValue('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }
          if (selectedCountry === '+1') {
            if (payload.mobile.charAt(0) === '0') {
              payload.mobile = payload.mobile.replace('0', '');
            }
            if (payload.landline.charAt(0) === '0') {
              payload.landline = payload.landline.replace('0', '');
            }

            // if (companyContactDetail.landline && companyContactDetail.landline.trim().length !== 11) {
            //   isLandlineValid = false;
            //   errorMsg = 'Please enter area code, followed by phone no.';
            //   setErrorValue('landlineno');
            // }
            if (payload.mobile.trim().length !== 12) {
              setErrorValue('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }

          // if (payload.mobile !== '') {
          //   if (payload.landline !== '') {
          //     if (companyContactDetail.landline.trim().length !== 11) {
          //       isLandlineValid = false;
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('landline');
          //       }
          //     }
          //   } else {
          //     isMobileValid = true;
          //   }
          // }
          // if (payload.landline !== '') {
          //   if (payload.mobile !== '') {
          //     if (payload.mobile.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('mobile');
          //       }
          //     }
          //   } else {
          //     isLandlineValid = true;
          //   }
          // }
          // if (payload.mobile === '') {
          //   if (payload.landline !== '') {
          //     if (companyContactDetail.landline.trim().length !== 11) {
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('landline');
          //       }
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   isMobileValid = false;
          // }
          // if (payload.landline === '') {
          //   if (payload.mobile !== '') {
          //     if (payload.mobile.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setErrorValue('mobile');
          //       }
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   isLandlineValid = false;
          // }
          console.log(isMobileValid);
          if (payload2?.companyContactDetail?.mobile && isMobileValid) {
            payload2.companyContactDetail.mobile = `${selectedCountry} ${payload2?.companyContactDetail?.mobile}`;
          }

          if (payload2?.companyContactDetail?.landline && isLandlineValid) {
            payload2.companyContactDetail.landline = `${selectedCountry} ${payload2?.companyContactDetail?.landline}`;
          }
          if (isMobileValid) {
            console.log('payload2payload2=>', payload2);
            const response = await userInstance().post('/location/addCompanyInformation', payload2);
            setLocationInfo(payload2);
            // console.log('sdsdsd', response);
            const { msg } = response.data;
            toastMessage('success', 'Location updated Successfully');
          } else {
            toastMessage('error', errorMsg);
          }
        } else {
          setErrorValue('email');
          toastMessage('error', 'Invalid email.');
        }
      } else {
        setErrorValue(errorObjValues[emptyField]);
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  useEffect(() => {
    getCompanyInformation();
  }, []);

  const getCompanyInformation = async () => {
    try {
      setLoading(true);
      const response = await userInstance().get('/location/getCompanyInformation');
      setLoading(false);
      const { locationData } = response.data;
      if (locationData.companyInformation) {
        setCompanyInformation(locationData.companyInformation);
      }
      if (locationData?.companyContactDetail) {
        const compData = { ...locationData.companyContactDetail };

        let copyCountryCode = selectedCountry;
        if (compData?.mobile) {
          const { updatedNo, countryCode } = removeCodeFromNumber(compData?.mobile);
          compData.mobile = updatedNo;
          copyCountryCode = countryCode;
        }
        if (compData?.landline) {
          const { updatedNo } = removeCodeFromNumber(compData?.landline);
          compData.landline = updatedNo;
        }
        console.log('=>>>', copyCountryCode);
        setCountry(copyCountryCode);
        setCompanyContactDetails(compData);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      //   console.log(error);
    }
  };

  useEffect(() => {
    // console.log('element');
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        // console.log('element');
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  console.log('companyContactDetail', companyContactDetail);

  const renderView = () => {
    let componentoRender = null;
    switch (view) {
      case 'companyInfo':
        componentoRender = (
          <CompanyInfoSection
            UpdateView={UpdateView}
            companyInformation={companyInformation}
            companyContactDetail={companyContactDetail}
            handleCompanyInformation={handleCompanyInformation}
            handleContactInformation={handleContactInformation}
            handleCountry={handleCountry}
            selectedCountry={selectedCountry}
            saveCompanyInformation={saveCompanyInformation}
            error={errorValue}
            mobileFormat={mobileFormat}
          />
        );
        break;
      case 'companyLocation':
        componentoRender = (
          <CompanyLocation
            setView={setView}
          />
        );
        break;
      default:
        break;
    }
    return componentoRender;
  };
  const UpdateView = (key) => {
    setView(key);
  };
  return (
    <AdminWrapper>
      {loading ? <Loader /> : (
        <div className="company-info">
          <div className="container">
            {renderView()}
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};
export default CompanyInfo;

const CompanyInfoSection = ({
  UpdateView, companyInformation, companyContactDetail, handleCompanyInformation,
  handleContactInformation, handleCountry, selectedCountry, saveCompanyInformation, error, mobileFormat,
}) => (
  <div className="row">
    <div className="col-md-12">
      <div className="main-titles-mob">
        <h3>Company Information</h3>
        <div className="content-dropdown">
          <div className="client-option service-edit-icon">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={showmore} alt="show" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <div className="action" onClick={() => UpdateView('companyInfo')}>
                    <span> Company Information</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="action" onClick={() => UpdateView('companyLocation')}>
                    <span>Company Locations</span>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div className="action">
                    <span>Resend Login Details</span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-12">
      <div className="staff-form">
        <Form>
          <div className="row">
            <div className="col-md-6">
              <Form.Group controlId="formBasicCompanyType">
                <Form.Label>
                  Company Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="companyName"
                  value={companyInformation.companyName}
                  onChange={(e) => handleCompanyInformation(e)}
                  placeholder="Enter Company Name"
                  className={`${
                    error === 'companyName'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-1" className="italic-text">
                <Form.Label>
                  Company Street Address
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={companyInformation.address}
                  onChange={(e) => handleCompanyInformation(e)}
                  placeholder="Enter Company Street Address"
                  className={`${
                    error === 'address'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-1" className="italic-text">
                <Form.Label>
                  Company Suburb
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="suburb"
                  value={companyInformation.suburb}
                  placeholder="Enter Company Suburb"
                  onChange={(e) => handleCompanyInformation(e)}
                  className={`${
                    error === 'suburb'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-2" className="italic-text">
                <Form.Label>
                  Company Postcode
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="number"
                  name="postcode"
                  value={companyInformation.postcode}
                  onChange={(e) => handleCompanyInformation(e)}
                  placeholder="Enter Company Postcode"
                  className={`${
                    error === 'postcode'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-34" className="number italic-text">
                <Form.Label>
                  State
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="state"
                  value={companyInformation.state}
                  placeholder="Enter State Name"
                  onChange={(e) => handleCompanyInformation(e)}
                  className={`${
                    error === 'state'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-5" className="italic-text">
                <Form.Label>
                  Contact&apos;s First Name
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={companyContactDetail.firstname}
                  onChange={(e) => handleContactInformation(e)}
                  placeholder="Enter Contact's First Name"
                  className={`${
                    error === 'firstname'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-6" className="italic-text">
                <Form.Label>
                  Contact&apos;s Last Name
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={companyContactDetail.lastname}
                  onChange={(e) => handleContactInformation(e)}
                  placeholder="Enter Contact's Last Name"
                  className={`${
                    error === 'lastname'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-7" className="number italic-text">
                <Form.Label>
                  Contact&apos;s Mobile No.
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                {/* <Form.Control
                  type="number"
                  name="mobile"
                  value={companyContactDetail.mobile}
                  onChange={(e) => handleContactInformation(e)}
                /> */}
                <div className="number-field">
                  {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                  <div className="number-box">
                    <Dropdown id="mobile">
                      <Dropdown.Toggle variant="success" id="dropdown-basic1">
                        <div className="flag-icon">
                          <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                        </div>
                        {selectedCountry === '+61' ? (
                          <span>
                            Australia
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : selectedCountry === '+1' ? (
                          <span>
                            United States
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : ''}
                        <span>
                          <i className="fas fa-caret-down" />
                        </span>
                        {/* <div className="country-code">{selectedCountry}</div> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                          <div className="flag-icon">
                            <img src={flag} alt="flag" />
                          </div>
                          <div className="country-code">
                            <span>Australia</span>
                            +61
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                          <div className="flag-icon">
                            <img src={us} alt="flag" />
                          </div>
                          <div className="country-code">
                            {' '}
                            <span>United States</span>
                            +1
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NumberFormat
                    // className="form-control mobile-num"
                    className={`form-control mobile-num ${
                      error === 'mobile'
                        ? 'error-text'
                        : ''
                    }`}
                    format={mobileFormat}
                    value={companyContactDetail?.mobile}
                    onChange={(e) => handleContactInformation(e)}
                    placeholder="Enter Contact's Mobile No."
                    name="mobile"
                    autocomplete="off"
                  />
                </div>

              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-8" className="number">
                <Form.Label>
                  Contact&apos;s Landline No.
                </Form.Label>
                {/* <Form.Control
                  type="number"
                  name="landline"
                  value={companyContactDetail.landline}
                  onChange={(e) => handleContactInformation(e)}
                /> */}
                <div className="number-field">
                  {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                  <div className="number-box">
                    <Dropdown id="mobile">
                      <Dropdown.Toggle variant="success" id="dropdown-basic1">
                        <div className="flag-icon">
                          <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                        </div>
                        {selectedCountry === '+61' ? (
                          <span>
                            Australia
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : selectedCountry === '+1' ? (
                          <span>
                            United States
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : ''}
                        <span>
                          <i className="fas fa-caret-down" />
                        </span>
                        {/* <div className="country-code">{selectedCountry}</div> */}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                          <div className="flag-icon">
                            <img src={flag} alt="flag" />
                          </div>
                          <div className="country-code">
                            <span>Australia</span>
                            +61
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                          <div className="flag-icon">
                            <img src={us} alt="flag" />
                          </div>
                          <div className="country-code">
                            {' '}
                            <span>United States</span>
                            +1
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NumberFormat
                    // className="form-control mobile-num"
                    className={`form-control mobile-num ${
                      error === 'landline'
                        ? 'error-text'
                        : ''
                    }`}
                    format={mobileFormat}
                    name="landline"
                    value={companyContactDetail?.landline}
                    onChange={(e) => handleContactInformation(e)}
                    autocomplete="off"
                  />
                </div>

              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="formBasicInput-9" className="italic-text">
                <Form.Label>
                  Contact&apos;s Email Address
                  {/* <span className="required-field">*</span> */}
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={companyContactDetail.email}
                  onChange={(e) => handleContactInformation(e)}
                  className={`${
                    error === 'email'
                      ? 'error-text'
                      : ''
                  }`}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group className="form-radio" onChange={(e) => handleContactInformation(e)} name="gender">
                <Form.Check
                  type="radio"
                  label="Male"
                  name="gender"
                  id="male"
                  checked={companyContactDetail.gender === 'male'}
                />
                <Form.Check
                  type="radio"
                  label="Female"
                  name="gender"
                  id="female"
                  checked={companyContactDetail.gender === 'female'}
                />
              </Form.Group>
            </div>
            <div className="col-md-12">
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  name="note"
                  value={companyContactDetail.note}
                  onChange={(e) => handleContactInformation(e)}
                  rows={3}
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn">
                <Button
                  type="Submit"
                  className="btn btn-edit"
                >
                  {' '}
                  Cancel
                  {' '}
                </Button>
                <Button
                  className="btn btn-save"
                  onClick={(e) => saveCompanyInformation(e)}
                >
                  {' '}
                  Save/Update
                  {' '}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>

    </div>
  </div>

);
CompanyInfoSection.propTypes = {
  UpdateView: PropTypes.func.isRequired,
  companyInformation: PropTypes.objectOf.isRequired,
  companyContactDetail: PropTypes.objectOf.isRequired,
  handleCompanyInformation: PropTypes.func.isRequired,
  handleContactInformation: PropTypes.func.isRequired,
  selectedCountry: PropTypes.objectOf.isRequired,
  handleCountry: PropTypes.func.isRequired,
  saveCompanyInformation: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  mobileFormat: PropTypes.string.isRequired,
};
