/* eslint-disable no-console */
import React, {
  useState, useContext, useEffect, createContext,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import moment from 'moment';
import logo from '../../assets/home/login-logo.png';
import './login.css';
import { withoutAuth, userInstance } from '../../config/axios';
import { showErrMsg, queryString, validateDataWithKey } from '../../functions/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { socket } from '../../socket';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

function Login() {
  let { firstLogin: loginFirst, token: userToken } = queryString();
  const history = useHistory();
  const [userinput, setUserInput] = useState({ email: '', password: '', currentPassword: '' });
  const { userData, setUserData } = useContext(AuthDataContext);
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    showTemporaryPassword: false,
  });
  const handleClickShowPassword = (type) => {
    setValues({ ...values, [type]: !values[type] });
  };

  useEffect(() => {

  }, []);
  const handleChange = (e) => {
    setUserInput({ ...userinput, [e.target.name]: e.target.value });
  };
  // const handleLogin = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (!userinput.email) {
  //       NotificationManager.error('Please enter email', 'Message', 1000);
  //     } else if (!userinput.password) {
  //       NotificationManager.error('Please enter password', 'Message', 1000);
  //     } else {
  //       console.log('sss');
  //       const response = await withoutAuth().post('/user/login', {
  //         email: userinput.email.toLowerCase(),
  //         password: userinput.password,
  //       });
  //       console.log('ggg', response);
  //       const {
  //         token, staffToken, msg, userdata,
  //       } = response.data;
  //       // console.log('responseDta login==>', response.data);
  //       const { email, firstLogin } = userdata;
  //       NotificationManager.success('Logged-in Successfully', 'Message', 1000);
  //       localStorage.setItem('s#@4f', token);
  //       localStorage.setItem('f#@5e', staffToken);
  //       localStorage.setItem('loginStaff', userdata._id);
  //       const firstday = new Date(moment().startOf('isoWeek').format('YYYY-MM-DD'));
  //       const lastday = new Date(moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'));
  //       const filterStart = localStorage.getItem('date')
  //         || firstday;
  //       const filterEnd = localStorage.getItem('enddate')
  //         || lastday;
  //       localStorage.setItem('date', new Date(filterStart).toISOString());
  //       localStorage.setItem('enddate', new Date(filterEnd).toISOString());
  //       console.log('login', email, userdata._id);
  //       setUserData({
  //         ...userData,
  //         auth: true,
  //         email,
  //         firstLogin,
  //       });
  //       socket.emit('join', userdata._id.toString());
  //       window.location.href = userdata.firstLogin === true ? '/updatepassword' : '/calculator';
  //     }
  //   } catch (error) {
  //     showErrMsg(error);
  //   }
  // };

  // console.log({ loginFirst });

  const handleLogin = async () => {
    try {
      if (!userinput.email) {
        toastMessage('error', 'Please enter email.');
      } else if (!userinput.password) {
        toastMessage('error', 'Please enter password.');
      } else {
        let response = {};
        if (loginFirst) {
          const { isValid, emptyField } = validateDataWithKey({ 'current password': userinput.currentPassword, 'new password': userinput.password, email: userinput.email });
          const paylaod = {
            email: userinput.email,
            currentPassword: userinput.currentPassword,
            newPassword: userinput.password,
            token: userToken,
          };

          if (isValid) {
            response = await withoutAuth().post('/user/userLoginWithUpdatePassword', paylaod);
          } else {
            toastMessage('error', `Please fill ${emptyField}.`);
          }
        } else {
          response = await withoutAuth().post('/user/login', {
            email: userinput.email.toLowerCase(),
            password: userinput.password,
          });
        }
        const {
          token, staffToken, msg, userdata, hexToken, userDetails, sendRoute,
        } = response.data;
        const { email, firstLogin, industryId } = userdata;
        toastMessage('success', 'Login Successful.');
        localStorage.setItem('s#@4f', token);
        localStorage.setItem('f#@5e', staffToken);
        localStorage.setItem('loginStaff', userdata._id);
        const firstday = new Date(moment().startOf('isoWeek').format('YYYY-MM-DD'));
        const lastday = new Date(moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'));
        const filterStart = localStorage.getItem('date')
          || firstday;
        const filterEnd = localStorage.getItem('enddate')
          || lastday;
        localStorage.setItem('date', new Date(filterStart).toISOString());
        localStorage.setItem('enddate', new Date(filterEnd).toISOString());
        setUserData({
          ...userData,
          auth: true,
          email,
          firstLogin,
          userDetails,
          industryId: userDetails.industryId,
        });
        socket.emit('join', userdata._id.toString());
        const urlR = hexToken ? `/updatepassword?token=${hexToken}` : '/updatepassword';
        window.location.href = (userdata.firstLogin === true || hexToken) ? urlR : sendRoute;
      }
    } catch (error) {
      showErrMsg(error);
      // console.log(error);
      console.log(error);
    }
  };

  const handleUserLogin = (e) => {
    e.preventDefault();
    handleLogin();
  };
  const handlehome = () => {
    history.push('/');
  };
  return (
    <div className="login-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="login">
              <h3 className="login-title">Login</h3>
              <div className="login-box">
                <div className="logo-login" onClick={handlehome}>
                  <img src={logo} alt="logo" />
                </div>
                <div className="login-form">
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <Form.Group controlId="formBasicEmail" required="">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter your email"
                            value={userinput.email}
                            name="email"
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </div>
                    </div>

                    {loginFirst ? (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicEmail" required="">
                              {/* <Form.Label>Current Password</Form.Label> */}
                              <Form.Label>Temporary Password Received</Form.Label>
                              <Form.Control
                                type={values.showTemporaryPassword ? 'text' : 'password'}
                                placeholder=""
                                name="currentPassword"
                                autoComplete="on"
                                onChange={handleChange}
                              />
                              <i onClick={() => handleClickShowPassword('showTemporaryPassword')} className={values.showTemporaryPassword ? 'fa fa-eye' : 'fas fa-eye-slash '} aria-hidden="true" />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword" required="">
                              <Form.Label>New Password</Form.Label>
                              <Form.Control
                                type={values.showPassword ? 'text' : 'password'}
                                placeholder=""
                                name="password"
                                autoComplete="on"
                                onChange={handleChange}
                              />
                              <i onClick={() => handleClickShowPassword('showPassword')} className={values.showPassword ? 'fa fa-eye' : 'fas fa-eye-slash '} aria-hidden="true" />
                            </Form.Group>
                          </div>
                        </div>
                      </>
                    )
                      : (
                        <div className="row">
                          <div className="col-md-12">
                            <Form.Group controlId="formBasicPassword" required="">
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type={values.showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                value={userinput.password}
                                name="password"
                                onChange={handleChange}
                              />
                              <i onClick={() => handleClickShowPassword('showPassword')} className={values.showPassword ? 'fa fa-eye' : 'fas fa-eye-slash '} aria-hidden="true" />

                            </Form.Group>
                          </div>
                        </div>
                      )}
                    {/* <div className="row">
                      <div className="col-md-12">
                        <Form.Group className="checkbox" controlId="formBasicCheckbox">
                          <Form.Check type="checkbox" label="Keep me logged in" />
                        </Form.Group>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="login-popup-btn">
                          <Button type="submit" onClick={handleUserLogin}>
                            {' '}
                            Login
                            {' '}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="row" />
              </div>
              <div className="col-12">
                <div className="login-text">
                  <Link to="/">Contact us </Link>
                  if you do not have an account
                </div>
              </div>
              <div className="col-md-12">
                <div className="login-links">
                  <p>
                    <Link to="/forget-password"> Forgot Password? </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
