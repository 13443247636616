import React, { useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactExport from 'react-export-excel';
import showmore from '../../assets/home/showmoreblue.png';
import AgeingSummary from './agingSummary';

const DebtorsCreditors = ({ setLoderStatus, locationData }) => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const [view, setView] = useState('Receivables Ageing Summary');
  const [agingDataExcel, setAgingDataExcel] = useState([]);
  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case 'Receivables Ageing Summary':
        componentToRender = (
          <AgeingSummary setLoderStatus={setLoderStatus} setAgingDataExcel={setAgingDataExcel} locationData={locationData} />
        );
        break;
      case 'Customer Payment Detail':
        // componentToRender = (
        //   <AppointmentDetail />
        // );
        break;

      default:
        break;
    }
    return componentToRender;
  };

  const updateView = (key) => {
    setView(key);
  };

  const camelCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1);

  const filterColumns = (data) => {
    if (agingDataExcel.length) {
      const columns = Object.keys(data[0]);
      return columns;
    }
    return [];
  };

  return (
    <div className="reports-appointment pool-set-up search-invoice ageing-page">
      <div className="target-heading client-profile">
        <>
          <div className="">
            <h3>
              {view}
            </h3>
          </div>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Receivables Ageing Summary')}>
                      <span>Receivables Ageing Summary</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Customer Payment Detail')}>
                      <span>Receivables Ageing Detail</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Customer Payment Detail')}>
                      <span>Payables Ageing Summary</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Customer Payment Detail')}>
                      <span>Payables Ageing Detail</span>
                    </div>
                  </Dropdown.Item>

                  <div className="action">
                    <ExcelFile
                      filename="Receivable-Aging-Summary"
                      element={(
                        <span>Download Displayed Data in Excel</span>)}
                    >
                      <ExcelSheet data={agingDataExcel} name="Test">
                        {(agingDataExcel) && filterColumns(agingDataExcel).map((col) => (
                          <ExcelColumn label={camelCase(col)} value={col} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  </div>

                  {/* <Dropdown.Item>
                    <div className="action" >
                      <span>Download Displayed Data in Excel</span>
                    </div>
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </>
      </div>
      {renderView()}
    </div>

  );
};
DebtorsCreditors.propTypes = {
  setLoderStatus: PropTypes.func.isRequired,
  locationData: PropTypes.arrayOf.isRequired,
};
export default DebtorsCreditors;
