/* eslint-disable brace-style */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { Button } from 'react-bootstrap';
// import Layout from '../layout/index';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import StaffForm from './staffForm';
import StaffTable from './staffTable';
import StaffModal from './staffModal';
import { userInstance } from '../../config/axios';
import { removeCodeFromNumber, showErrMsg, validateDataWithKey } from '../../functions/index';
import { emailRegex } from '../../config/keys';
import './staff.css';
// eslint-disable-next-line import/order
import moment from 'moment';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import {
  errorMsgTime, nameRegex, postCodeRegex, successMsgTime,
} from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';

// const obj = {
//   startData: '',
//   endData: '',
//   starttimetype: '',
//   endtimetype: '',
// };

function Staff() {
  const { userData: { numberUsers, role, industryId } } = useContext(AuthDataContext);
  // console.log('industryId', industryId);
  // console.log('userData', numberUsers, role);
  const initialStaffState = {
    firstname: '',
    lastname: '',
    mobileNo: '',
    landlineNo: '',
    email: '',
    streetAddress: '',
    suburb: '',
    postcode: '',
    state: '',
    accessLevel: '',
    gender: 'male',
    note: '',
    location: '',
    shift: [],
    hide: false,
  };
  const initialShift = {
    startTime: '9:00 AM',
    endTime: '5:00 PM',
    repeats: 'Select',
    endRepeat: 'Select',
    weeklyOngoing: false,
  };
  const limit = 10;
  const date = new Date();
  date.setDate(date.getDate() + 5);
  const [showTable, setShowTable] = useState(false);
  const [staffData, setStaffData] = useState(initialStaffState);
  const [showStartDate, setShowStartDate] = useState(false);
  const [showEndDate, setShowEndDate] = useState(false);
  const [selectedDay, setDay] = useState('');
  const [staffList, setStaffList] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date(new Date().toDateString()),
  );
  const [endDate, setEndDate] = useState(new Date(date.toDateString()));
  const [firstDay, setFirstDay] = useState('');
  const [lastDay, setLastDay] = useState('');
  const [weeklist, setWeekList] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [selectedStaff, setStaffId] = useState('');
  const [showHide, setShowHide] = useState(null);
  const [showDropdownModal, setShowDropdown] = useState(false);
  const [selectedCountry, setCountry] = useState('+61');
  const [renderSpecificDate, setRender] = useState(false);
  const [selectedShift, setSelectedShift] = useState(initialShift);
  const [repeatStaff, setRepeatStaff] = useState(false);
  const [deleteStaff, setDelete] = useState(false);
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [searchTxt, setStaffTxt] = useState('');
  const [timepicker, setTimePicker] = useState(false);
  const [type, setType] = useState('');
  const [compLocation, setCompLocation] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [mobileFormat, setMobileFormat] = useState('### ### ###');
  const [loading, setLoading] = useState(false);

  const getLocation = async () => {
    const response = await userInstance().get('/location/getStaffLocation');
    setCompLocation(response.data.location);
  };

  useEffect(() => {
    getLocation();
  }, []);

  const handleTimePicker = (info) => {
    setType(info);
    setTimePicker(!timepicker);
  };
  const updateRepeatStaff = () => {
    setRepeatStaff(!repeatStaff);
  };
  const handleDelete = () => {
    setDelete(!deleteStaff);
  };

  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    if (countryCode === '+61') {
      setMobileFormat('### ### ###');
    }
    if (countryCode === '+1') {
      setMobileFormat('### ### ####');
    }
    const textToReplace = staffData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (staffData.mobileNo) {
      obj.mobileNo = `${staffData.mobileNo.replace(
        textToReplace,
        '',
      )}`;
    }
    if (staffData.landlineNo) {
      obj.landlineNo = `${staffData.landlineNo.replace(
        textToReplace,
        '',
      )}`;
    }
    setStaffData({ ...staffData, ...obj });
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };

  const HandleOpenCloseDropdown = ({
    date, _id, shift, staff,
  }) => {
    // console.log('shift', shift);
    if (date && _id && shift) {
      setDay(date);
      setStaffId(_id);
      setStaffData({ ...staffData, shift });
    }
    setShowDropdown(!showDropdownModal);
    // console.log('staff', staff);
    if (staff) {
      const { repeats, endRepeat, timing } = staff;
      setSelectedShift({
        startTime: timing.startTime,
        endTime: timing.endTime,
        repeats,
        endRepeat: endRepeat || 'Select',
      });
    } else {
      setSelectedShift({ ...initialShift });
    }
  };

  const HandleOpenCloseTable = (staff) => {
    // console.log('staff', staff);
    setShowTable(!showTable);
  };

  const handleChange = (e) => {
    // console.log('e', e.target);
    const { name } = e.target;
    let { value } = e.target;

    if (name === 'firstname' || name === 'lastname') {
      if (value && !(nameRegex.test(value))) {
        return;
      }
    }

    if (name === 'postcode') {
      if (value && !(postCodeRegex.test(value))) {
        return;
      }
    }

    if (name === 'gender') {
      value = e.target.id;
    }
    if (name === 'hide') {
      value = JSON.parse(e.target.value);
    }

    setStaffData({ ...staffData, [name]: value });
  };

  const [error, setError] = useState('');

  const handleAddStaff = async (e) => {
    e.preventDefault();

    const {
      firstname, lastname, email, accessLevel, location, gender,
    } = staffData;
    try {
      const { isValid, emptyField } = validateDataWithKey({
        'first name': firstname?.trim(),
        'last name': lastname?.trim(),
        email,
        'access level': accessLevel,
        location,
        gender,
      });

      if (isValid) {
        setError('');
        let isMobileValid = true;
        let isLandlineValid = true;
        let errorMsg = '';
        // let landlineMsg = '';
        if (emailRegex.test(String(staffData.email).toLowerCase())) {
          const payload = { ...staffData, email: email.toLowerCase() };
          if (selectedCountry === '+61') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }
            if (payload.landlineNo.charAt(0) === '0') {
              payload.landlineNo = payload.landlineNo.replace('0', '');
            }
            if (payload.mobileNo.charAt(0) !== '4') {
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setError('mobileno');
            }
            if (payload.mobileNo.trim().length !== 11) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }

          if (selectedCountry === '+1') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }
            if (payload.landlineNo.charAt(0) === '0') {
              payload.landlineNo = payload.landlineNo.replace('0', '');
            }

            if (payload.mobileNo.trim().length !== 12) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }
          // if (payload.mobileNo !== '') {
          //   if (payload.landlineNo !== '') {
          //     if (staffData.landlineNo.trim().length !== 11) {
          //       isLandlineValid = false;
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setError('landlineNo');
          //       }
          //     }
          //   } else {
          //     isMobileValid = true;
          //   }
          // }
          // if (payload.landlineNo !== '') {
          //   if (payload.mobileNo !== '') {
          //     if (payload.mobileNo.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setError('mobileNo');
          //       }
          //     }
          //   } else {
          //     isLandlineValid = true;
          //   }
          // }
          // if (payload.mobileNo === '') {
          //   if (payload.landlineNo !== '') {
          //     console.log('staffData.landlineNo', staffData.landlineNo);
          //     if (staffData.landlineNo.trim().length !== 11) {
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   isMobileValid = false;
          //   if (errorMsg) {
          //     setError('landlineNo');
          //   }
          // }

          // if (payload.landlineNo === '') {
          //   if (payload.mobileNo !== '') {
          //     if (payload.mobileNo.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   isLandlineValid = false;
          //   if (errorMsg) {
          //     setError('mobileNo');
          //   }
          // }

          if (payload?.mobileNo && isMobileValid) {
            payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
          }
          if (payload?.landlineNo && isLandlineValid) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }

          if (isMobileValid) {
            let staffrole = 'staff';
            if (role === 'subscriber') {
              staffrole = 'subscriberStaff';
            }
            payload.numberUsers = numberUsers;
            payload.role = staffrole;
            payload.industryId = industryId?._id;
            const response = await userInstance().post(
              '/staff/addStaff',
              payload,
            );
            // console.log('sdsdsd', response);

            const { msg } = response.data;
            setStaffData({ ...staffData, ...initialStaffState });
            setShowForm(false);
            getStaffList();
            setError('');
            toastMessage('success', 'Staff Added Successfully');
          } else {
            toastMessage('error', errorMsg);
          }
        } else {
          setError('email');
          toastMessage('error', 'Invalid email.');
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (error) {
      showErrMsg(error);
      //  console.log(error);
    }
  };

  const handleRepeatDropdown = ({ value }) => {
    if (value === 'Ends On Specific Date') {
      setShowEndDate(true);
    }
    // console.log(selectedDay);
  };

  const setRepeatDates = async () => {
    // if (selectedShift.startTime) {
    //   let timeType = selectedShift.startTime.split(' ');
    //   obj.starttimetype = timeType[1];
    //   let value = selectedShift.startTime.split('AM') || selectedShift.startTime.split('PM');
    //   let getValue = value[0].split(':');
    //   obj.startData = getValue[0] * 60 + parseFloat(getValue[1]);
    // }
    // if (selectedShift.endTime) {
    //   let timeType = selectedShift.endTime.split(' ');
    //   obj.endtimetype = timeType[1];
    //   let value = selectedShift.endTime.split('AM') || selectedShift.endTime.split('PM');
    //   let getValue = value[0].split(':');
    //   obj.endData = getValue[0] * 60 + parseFloat(getValue[1]);
    // }
    // console.log(obj);
    // if (obj.endtimetype === obj.starttimetype && obj.startData === obj.endData) {
    //   NotificationManager.error('Please do not Insert Same Time', 'Message', 5000);
    // }
    // else if (obj.endData === 720 && 735 && obj.endtimetype === obj.starttimetype) {
    //   if (obj.startData < obj.endData) {
    //     console.log('true');
    //     NotificationManager.error('Please Insert Correct Time', 'Message', 5000);
    //   }
    // } else if (obj.startData <= 719 && obj.startData > obj.endData && obj.endtimetype === obj.starttimetype) {
    //   NotificationManager.error('Please Insert Correct Time', 'Message', 5000);
    // }
    const peakTime = moment('11:59PM', 'h:mma');
    const nightTime = moment('11:59AM', 'h:mma');
    const start = moment(selectedShift.startTime, 'h:mma');
    const end = moment(selectedShift.endTime, 'h:mma');
    // console.log(start, end, peakTime, nightTime);
    const result = start.isSame(end);

    if (result) {
      toastMessage('error', 'Start time cannot be equal to end time.');
    } else if (start.isAfter(end) && start.isBefore(nightTime)) {
      toastMessage('error', 'Start time cannot be less than end time.');
    } else if (
      start.isBetween(nightTime, peakTime)
      && end.isBetween(nightTime, peakTime)
    ) {
      if (start.isAfter(end)) {
        toastMessage('error', 'Start time cannot be less than end time.');
      }
    } else {
      let isValid = true;
      const elementsIndex = weeklist.filter(
        (el) => +new Date(el.date) === +selectedDay,
      );
      const { endRepeat, repeats } = selectedShift;
      let currentShifts = staffData.shift;
      if (endRepeat.includes('Mon')) {
        let daysToCount = 4;
        if (endRepeat === 'Ongoing (Mon to Sat)') {
          daysToCount = 5;
        } else if (endRepeat === 'Ongoing (Mon to Sun)') {
          daysToCount = 6;
        }
        const selectedShiftCopy = { ...selectedShift };
        selectedShiftCopy.endRepeat = 'Ongoing';

        for (let i = 0; i <= daysToCount; i++) {
          const currentDate = weeklist[i];
          const updatedShifts = getUpdatedShifts(
            elementsIndex,
            selectedShiftCopy,
            currentDate.date,
            currentShifts,
          );
          currentShifts = updatedShifts;
        }
      } else if (repeats === 'Weekly' && endRepeat !== 'Ongoing' && endRepeat) {
        // meaning specific date is selected
        // find entries which are greater than current date and less than equal to end date
        const endRepeatDate = new Date(endRepeat);
        const currentDate = selectedDay;
        const checkShifts = staffData.shift.filter(
          (el) => new Date(el.date) > currentDate
            && new Date(el.date) <= endRepeatDate,
        );
        // console.log('found repeated shifts', checkShifts);
        if (checkShifts.length) {
          isValid = false;
          updateRepeatStaff();
        } else {
          const updatedShifts = getUpdatedShifts(
            elementsIndex,
            selectedShift,
            selectedDay,
            currentShifts,
          );
          currentShifts = updatedShifts;
        }
      } else if (repeats === 'Weekly' && endRepeat === 'Ongoing') {
        const currentDate = selectedDay;
        const getOngoingData = staffData.shift.filter(
          (el) => new Date(el.date) > currentDate
            && el.repeats === 'Weekly'
            && el.endRepeat === 'Ongoing',
        );
        if (getOngoingData.length) {
          isValid = false;
          updateRepeatStaff();
        } else {
          const updatedShifts = getUpdatedShifts(
            elementsIndex,
            selectedShift,
            selectedDay,
            currentShifts,
          );
          currentShifts = updatedShifts;
        }
      } else {
        const updatedShifts = getUpdatedShifts(
          elementsIndex,
          selectedShift,
          selectedDay,
          currentShifts,
        );
        currentShifts = updatedShifts;
      }
      if (isValid) {
        setStaffData({ ...staffData, shift: currentShifts });
        await updateWorking(currentShifts);
      }
    }
  };

  const updateWorking = async (currentShifts) => {
    try {
      // console.log('edit working', selectedStaff, 'shifts', currentShifts);
      const newShift = [];
      currentShifts.forEach((el) => {
        newShift.push({ ...el, date: el.date.toString() });
      });
      const response = await userInstance().put('/staff/editWorking', {
        shift: newShift,
        selectedStaff,
      });
      setShowDropdown(!showDropdownModal);
      getStaffList();
      toastMessage('success', response.data.msg);
    } catch (error) {
      console.log(error);
      //  console.log(error);
    }
    setShowStartDate(false);
    setShowEndDate(false);
  };

  const getUpdatedShifts = (elementsIndex, shift, day, currentShifts) => {
    const {
      repeats, endRepeat, startTime, endTime, weeklyOngoing,
    } = shift;
    let dayIndex = null;
    if (elementsIndex.length) {
      const checkShift = currentShifts.filter((el, index) => {
        if (+new Date(el.date) === +day) {
          dayIndex = index;
          return true;
        }
        return false;
      });
      // console.log(
      //   'checkShift',
      //   checkShift,
      //   'dayIndex',
      //   dayIndex,
      //   'day',
      //   day,
      //   'currentShifts[dayIndex]',
      //   currentShifts,
      // );
      if (!checkShift.length) {
        const newShift = {
          date: day,
          timing: { startTime, endTime },
          repeats,
          endRepeat,
          weeklyOngoing: weeklyOngoing || false,
        };
        if (repeats === "Don't Repeat") {
          newShift.endRepeat = null;
        }
        currentShifts.push(newShift);
      } else {
        currentShifts[dayIndex].date = day;
        currentShifts[dayIndex].timing = { startTime, endTime };
        currentShifts[dayIndex].repeats = repeats;
        currentShifts[dayIndex].endRepeat = repeats === "Don't Repeat" ? null : endRepeat;
        currentShifts[dayIndex].weeklyOngoing = weeklyOngoing;
      }
    }
    return currentShifts;
  };

  const updateCurrentShift = async () => {
    const elementsIndex = weeklist.filter(
      (el) => +new Date(el.date) === +selectedDay,
    );
    let currentShifts = staffData.shift;
    const selectedShiftCopy = { ...selectedShift };
    selectedShiftCopy.endRepeat = "Dont't Repeat";
    const updatedShifts = getUpdatedShifts(
      elementsIndex,
      selectedShiftCopy,
      selectedDay,
      currentShifts,
    );
    currentShifts = updatedShifts;
    setStaffData({ ...staffData, shift: currentShifts });
    await updateWorking(currentShifts);
    updateRepeatStaff();
  };

  const updateAllShift = async () => {
    let currentShifts = staffData.shift;
    // console.log('previous shifts', currentShifts);
    const selectedShiftCopy = { ...selectedShift };
    // const endRepeatDate = new Date(selectedShiftCopy.endRepeat);
    const currentDate = selectedDay;
    let checkShifts = [];
    if (selectedShiftCopy.endRepeat === 'Ongoing') {
      checkShifts = staffData.shift.filter(
        (el) => !(new Date(el.date) > currentDate && el.endRepeat === 'Ongoing'),
      );
    } else {
      checkShifts = staffData.shift.filter(
        (el) => !(
          new Date(el.date) > currentDate
            && new Date(el.date) <= new Date(el.endRepeat)
        ),
      );
    }
    // console.log('checkShifts', checkShifts);
    const elementsIndex = weeklist.filter(
      (el) => +new Date(el.date) === +selectedDay,
    );
    const updatedShifts = getUpdatedShifts(
      elementsIndex,
      selectedShiftCopy,
      selectedDay,
      checkShifts,
    );
    currentShifts = updatedShifts;
    // console.log('updated shifts', currentShifts);
    setStaffData({ ...staffData, shift: currentShifts });
    await updateWorking(currentShifts);
    updateRepeatStaff();
  };

  const deleteCurrentShift = async () => {
    let currentShifts = staffData.shift;
    const selectedShiftCopy = { ...selectedShift };
    const currentDate = new Date(selectedDay.toString());
    const currentDateCopy = new Date(selectedDay.toString());
    const checkEntryType = staffData.shift.filter(
      (el) => +new Date(el.date) === +currentDate,
    );
    if (!checkEntryType.length) {
      // other entry is clicked
      const elementsIndex = weeklist.filter(
        (el) => +new Date(el.date) === +selectedDay,
      );
      const getOngoingEntry = staffData.shift.filter(
        (el) => new Date(el.date) < currentDate && el.endRepeat === 'Ongoing',
      );
      if (getOngoingEntry.length) {
        // for later
        const selectedShiftCopy = { ...getOngoingEntry[0] };
        const filteredEntries = staffData.shift.filter(
          (el) => el.date !== getOngoingEntry[0].date,
        );
        currentDate.setDate(currentDate.getDate() + 7);
        const updatedShifts = getUpdatedShifts(
          elementsIndex,
          {
            ...selectedShiftCopy,
            startTime: selectedShiftCopy.timing.startTime,
            endTime: selectedShiftCopy.timing.endTime,
          },
          currentDate,
          filteredEntries,
        );
        currentDateCopy.setDate(currentDate.getDate() - 14);
        selectedShiftCopy.endRepeat = currentDateCopy.toString();
        selectedShiftCopy.weeklyOngoing = true;
        const updatedShiftsRight = getUpdatedShifts(
          elementsIndex,
          {
            ...selectedShiftCopy,
            startTime: selectedShiftCopy.timing.startTime,
            endTime: selectedShiftCopy.timing.endTime,
          },
          new Date(getOngoingEntry[0].date),
          updatedShifts,
        );
        currentShifts = updatedShiftsRight;
      } else {
        const getSpecificEntry = staffData.shift.filter(
          (el) => new Date(el.date) < currentDate
            && el.endRepeat !== 'Ongoing'
            && el.endRepeat,
        );
        if (getSpecificEntry.length) {
          // updating the value of original date entry.
          const originalShiftData = getSpecificEntry[0].endRepeat;
          const selectedShiftCopy = { ...getSpecificEntry[0] };
          currentDate.setDate(currentDate.getDate() - 1);
          const endRepeat = currentDate.toString();
          selectedShiftCopy.endRepeat = endRepeat;
          const updatedShifts = getUpdatedShifts(
            elementsIndex,
            {
              ...selectedShiftCopy,
              startTime: selectedShiftCopy.timing.startTime,
              endTime: selectedShiftCopy.timing.endTime,
            },
            new Date(getSpecificEntry[0].date),
            currentShifts,
          );
          currentShifts = updatedShifts;
          currentDate.setDate(currentDate.getDate() + 2);
          selectedShiftCopy.date = currentDate;
          selectedShiftCopy.endRepeat = originalShiftData;

          const updatedShiftsNext = getUpdatedShifts(
            elementsIndex,
            {
              ...selectedShiftCopy,
              startTime: selectedShiftCopy.timing.startTime,
              endTime: selectedShiftCopy.timing.endTime,
            },
            currentDate,
            currentShifts,
          );
          currentShifts = updatedShiftsNext;
        }
      }
    } else {
      const checkShifts = staffData.shift.filter(
        (el) => +new Date(el.date) !== +currentDate,
      );
      const elementsIndex = weeklist.filter(
        (el) => +new Date(el.date) === +selectedDay,
      );
      // console.log(checkShifts, elementsIndex, currentShifts);
      const daysToAdd = selectedShiftCopy.endRepeat !== 'Ongoing'
        && selectedShiftCopy.repeats === 'Weekly'
        && selectedShiftCopy.endRepeat
        ? 1
        : 7;
      currentDate.setDate(currentDate.getDate() + daysToAdd);
      // console.log('currentDate', currentDate, 'daysToAdd', daysToAdd);
      const updatedShifts = getUpdatedShifts(
        elementsIndex,
        selectedShiftCopy,
        currentDate,
        checkShifts,
      );
      currentShifts = updatedShifts;
    }
    // console.log('updated shifts', currentShifts);
    setStaffData({ ...staffData, shift: currentShifts });
    await updateWorking(currentShifts);
    handleDelete();
  };

  const deleteUpcomingShift = async () => {
    let currentShifts = staffData.shift;
    const currentDate = new Date(selectedDay.toString());
    const checkEntryType = staffData.shift.filter(
      (el) => +new Date(el.date) === +currentDate,
    );
    // console.log('checkEntryType', checkEntryType);
    // if checkEntryType is empty it means that repeated entry is clicked which is showing by some other ongoing date.
    if (!checkEntryType.length) {
      const elementsIndex = weeklist.filter(
        (el) => +new Date(el.date) === +selectedDay,
      );
      const getOngoingEntry = staffData.shift.filter(
        (el) => new Date(el.date) < currentDate && el.endRepeat === 'Ongoing',
      );
      if (getOngoingEntry.length) {
        const selectedShiftCopy = { ...getOngoingEntry[0] };
        // getting same day of previous week
        currentDate.setDate(currentDate.getDate() - 7);
        const endRepeat = currentDate.toString();
        selectedShiftCopy.endRepeat = endRepeat;
        // console.log('selectedShiftCopy', selectedShiftCopy);
        selectedShiftCopy.weeklyOngoing = true;
        const updatedShifts = getUpdatedShifts(
          elementsIndex,
          {
            ...selectedShiftCopy,
            startTime: selectedShiftCopy.timing.startTime,
            endTime: selectedShiftCopy.timing.endTime,
          },
          new Date(getOngoingEntry[0].date),
          currentShifts,
        );
        currentShifts = updatedShifts;
      } else {
        const getSpecificEntry = staffData.shift.filter(
          (el) => new Date(el.date) < currentDate
            && el.endRepeat !== 'Ongoing'
            && el.endRepeat,
        );
        if (getSpecificEntry.length) {
          // found specific date entry
          const selectedShiftCopy = { ...getSpecificEntry[0] };
          const currentDate = new Date(selectedDay.toString());
          currentDate.setDate(currentDate.getDate() - 1);
          const endRepeat = currentDate.toString();
          selectedShiftCopy.endRepeat = endRepeat.toString();
          const updatedShifts = getUpdatedShifts(
            elementsIndex,
            {
              ...selectedShiftCopy,
              startTime: selectedShiftCopy.timing.startTime,
              endTime: selectedShiftCopy.timing.endTime,
            },
            new Date(getSpecificEntry[0].date),
            currentShifts,
          );
          currentShifts = updatedShifts;
        }
      }
    } else {
      const checkShifts = staffData.shift.filter(
        (el) => +new Date(el.date) !== +currentDate,
      );
      currentShifts = checkShifts;
    }

    setStaffData({ ...staffData, shift: currentShifts });
    await updateWorking(currentShifts);
    handleDelete();
  };

  const handleShiftChange = ({ name, data }) => {
    // console.log('data', data);

    if (name === 'endRepeat') {
      setShowEndDate(false);
      data = data.toString();
    }
    setSelectedShift({ ...selectedShift, [name]: data });
  };

  const getStaffList = async (typeData) => {
    try {
      if (typeData === 'skip')setLoading(true);
      const response = await userInstance().get(
        `/staff/getStaffList?limit=${limit}&skip=${skip}&type=user`,
      );
      setLoading(false);
      // console.log('response', response);
      const list = response.data.staff;
      // console.log('limit', list);
      list.sort((a, b) => a.lastname.localeCompare(b.lastname));
      setStaffList(list);

      setPageCounts(response.data.count);
    } catch (error) {
      setLoading(false);
      // console.log(error);
      console.log(error);
    }
  };
  useEffect(() => {
    const prevMonday = new Date(startDate);
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
    const a = prevMonday;
    setStartDate(prevMonday);
    const startWeek = a;
    const endWeek = new Date(startWeek);
    endWeek.setDate(endWeek.getDate() + 6);
    setEndDate(endWeek);

    const daylist = getDaysArray(startWeek, new Date(endWeek));
    // console.log('daylist', daylist);
    setWeekList(daylist);
    getDateRange(startWeek, endWeek);
    getStaffList('skip');
  }, [skip]);

  const handleAfterRange = async () => {
    endDate.setDate(endDate.getDate() + 1);
    setStartDate(endDate);
    const enddate = new Date(endDate);
    enddate.setDate(enddate.getDate() + 6);
    setEndDate(enddate);
    const dayslist = getDaysArray(new Date(endDate), new Date(enddate));
    setWeekList(dayslist);
    getDateRange(new Date(endDate), new Date(enddate));

    // setWeekList(dayslist);
  };
  const handleBeforeRange = async () => {
    // console.log('startDate', startDate);
    startDate.setDate(startDate.getDate() - 1);
    setEndDate(startDate);
    const enddate = new Date(startDate);
    enddate.setDate(enddate.getDate() - 6);
    setStartDate(enddate);
    const dayslist = getDaysArray(new Date(enddate), new Date(startDate));
    setWeekList(dayslist);
    getDateRange(new Date(enddate), new Date(startDate));
  };

  const getDateRange = (start, end) => {
    // const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    // const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    setFirstDay(start.toString());
    setLastDay(end.toString());
  };

  const handleCalendar = (e) => {
    const end = new Date(e);
    end.setDate(end.getDate() + 6);
    setEndDate(end);
    setStartDate(new Date(e));
    const dayslist = getDaysArray(new Date(e), new Date(end));
    setWeekList(dayslist);
    // console.log('dayslist', dayslist);
    getDateRange(new Date(e), new Date(end));
  };

  const getDaysArray = (start, end) => {
    const arr = [];
    for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
      arr.push({ date: new Date(new Date(dt).toDateString()), working: true });
    }
    return arr;
  };

  const handleFormModal = () => {
    setStaffData({ ...initialStaffState });
    setStaffId('');
    setShowForm(!showForm);
  };

  const handleEditStaff = (staff) => {
    setStaffId(staff._id);
    // if (staff.mobileNo.includes('+61')) {
    //   setCountry('+61');
    // } else if (staff.mobileNo.includes('+1')) {
    //   setCountry('+1');
    // }

    let copySelectedCountry = selectedCountry;
    if (staff?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(staff.mobileNo);
      staff.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }
    if (staff?.landlineNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(staff.landlineNo);
      staff.landlineNo = updatedNo;
      copySelectedCountry = countryCode;
    }
    // const upStaff = {...staff}
    setCountry(copySelectedCountry);

    setShowForm(true);
    setStaffData({ ...staffData, ...staff });
    // console.log(selectedStaff);
  };

  const editStaff = async (e) => {
    e.preventDefault();
    const {
      firstname, lastname, email, accessLevel, location, gender,
    } = staffData;
    try {
      const { isValid, emptyField } = validateDataWithKey({
        'first name': firstname?.trim(),
        'last name': lastname?.trim(),
        email,
        'access level': accessLevel,
        location,
        gender,
      });

      if (isValid) {
        setError('');
        if (emailRegex.test(String(staffData.email).toLowerCase())) {
          let isMobileValid = true;
          let isLandlineValid = true;
          // let mobileMsg = '';
          let errorMsg = '';
          // let landlineMsg = '';
          const payload = { ...staffData, email: email.toLowerCase() };
          // console.log('selectedCountry', selectedCountry);
          // console.log('payload', payload.landlineNo.length);
          if (selectedCountry === '+61') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }
            if (payload.landlineNo.charAt(0) === '0') {
              payload.landlineNo = payload.landlineNo.replace('0', '');
            }
          }
          if (selectedCountry === '+61') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }
            if (payload.landlineNo.charAt(0) === '0') {
              payload.landlineNo = payload.landlineNo.replace('0', '');
            }
            if (payload.mobileNo.charAt(0) !== '4') {
              isMobileValid = false;
              errorMsg = 'Please start mobile number with 4';
              setError('mobileno');
            }
            if (payload.mobileNo.trim().length !== 11) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 9 Digit Mobile Number';
            }
          }

          if (selectedCountry === '+1') {
            if (payload.mobileNo.charAt(0) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
            }
            if (payload.landlineNo.charAt(0) === '0') {
              payload.landlineNo = payload.landlineNo.replace('0', '');
            }

            if (payload.mobileNo.trim().length !== 12) {
              setError('mobileno');
              isMobileValid = false;
              // mobileMsg = 'Invalid Mobile Number';
              errorMsg = 'Enter 10 Digit Mobile Number';
            }
          }
          // if (payload.mobileNo !== '') {
          //   if (payload.landlineNo !== '') {
          //     if (staffData.landlineNo.trim().length !== 11) {
          //       isLandlineValid = false;
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //     }
          //     if (errorMsg) {
          //       setError('landlineNo');
          //     }
          //   } else {
          //     isMobileValid = true;
          //   }
          // }
          // if (payload.landlineNo !== '') {
          //   if (payload.mobileNo !== '') {
          //     if (payload.mobileNo.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //       if (errorMsg) {
          //         setError('mobileNo');
          //       }
          //     }
          //   } else {
          //     isLandlineValid = true;
          //   }
          // }
          // if (payload.mobileNo === '') {
          //   if (payload.landlineNo !== '') {
          //     if (staffData.landlineNo.trim().length !== 10) {
          //       isLandlineValid = false;
          //       errorMsg = 'Wrong Format';
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   if (errorMsg) {
          //     setError('landlineNo');
          //   }
          //   isMobileValid = false;
          // }
          // if (payload.landlineNo === '') {
          //   if (payload.mobileNo !== '') {
          //     if (payload.mobileNo.charAt(0) !== '4') {
          //       isMobileValid = false;
          //       errorMsg = 'Wrong Format';
          //     }
          //   } else {
          //     errorMsg = 'Please enter a mobile or landline number';
          //   }
          //   if (errorMsg) {
          //     setError('mobileNo');
          //   }
          //   isLandlineValid = false;
          // }

          if (payload?.mobileNo && isMobileValid) {
            payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
          }
          if (payload?.landlineNo && isLandlineValid) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }

          if (isMobileValid) {
            const response = await userInstance().put('/staff/editStaff', {
              ...payload,
              selectedStaff,
            });
            // console.log('sdsdsd', response);
            const { msg } = response.data;
            setShowForm(false);
            setError('');
            getStaffList();
            toastMessage('success', msg);
          } else {
            toastMessage('error', errorMsg);
          }
        } else {
          setError('email');
          toastMessage('error', 'Invalid email');
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (error) {
      showErrMsg(error);
    }
  };

  const removeStaff = async (id) => {
    try {
      const response = await userInstance().put(
        `/staff/deleteStaff?id=${id}`,
      );
      getStaffList();
      toastMessage('success', 'Staff deleted successfully');
    } catch (error) {
      console.log(error);
    }
  };

  // const getShiftData = (date) => {
  //   let foundShift;
  //   const { shift } = staffData;
  //   const checkShft = shift.filter((data) => +data.date === +date);
  //   if (checkShft.length) {
  //     foundShift = checkShft[0];
  //   }
  //   return foundShift;
  // };

  const handleEditWorking = async () => {
    try {
      // console.log('edit working', selectedStaff, 'shifts', staffData.shift);
      const newShift = [];
      staffData.shift.forEach((el) => {
        newShift.push({ ...el, date: el.date.toString() });
      });
      const response = await userInstance().put('/staff/editWorking', {
        shift: newShift,
        selectedStaff,
      });
      // setShowTable(!showTable);
      // setEditWorking(false);
      setShowDropdown(!showDropdownModal);
      getStaffList();
      toastMessage('success', response.data.msg);
    } catch (error) {
      console.log(error);
    }
  };

  const updateMonth = (val) => {
    const newMonth = val === 'next' ? startDate.getMonth() + 1 : startDate.getMonth() - 1;
    const newDate = new Date(startDate.setMonth(newMonth));
    const prevMonday = new Date(newDate);
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
    // console.log('prevMonday', prevMonday);
    setStartDate(prevMonday);
    const enddate = new Date(prevMonday);
    enddate.setDate(prevMonday.getDate() + 6);
    // console.log('enddate', enddate);
    setEndDate(enddate);
    const dayslist = getDaysArray(new Date(prevMonday), new Date(enddate));
    setWeekList(dayslist);
    getDateRange(new Date(prevMonday), new Date(enddate));
  };

  const updateYear = (val) => {
    const newYear = val === 'next'
      ? startDate.getFullYear() + 1
      : startDate.getFullYear() - 1;
    const newDate = new Date(startDate.setFullYear(newYear));
    const prevMonday = new Date(newDate);
    prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7));
    setStartDate(prevMonday);
    const enddate = new Date(prevMonday);
    enddate.setDate(prevMonday.getDate() + 6);
    // console.log('enddate', enddate);
    setEndDate(enddate);
    const dayslist = getDaysArray(new Date(prevMonday), new Date(enddate));
    setWeekList(dayslist);
    getDateRange(new Date(prevMonday), new Date(enddate));
  };

  // const getPrevMonday = () => {
  //   const prevMonday = new Date(startDate);
  //   prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() + 6) % 7);
  //   return prevMonday;
  // };
  const searchStaff = async (value) => {
    try {
      setStaffTxt(value);
      const response = await userInstance().get(
        `/staff/searchStaff?value=${value}`,
      );
      const list = response.data.staff;
      list.sort((a, b) => a.firstname.localeCompare(b.firstname));
      // console.log('response', response);
      setStaffList(list);
    } catch (error) {
      console.log(error);
    }
  };

  const handleManageVisible = async ({ hide, _id }) => {
    try {
      const response = await userInstance().put('/staff/editVisible', {
        _id,
        hide,
      });
      getStaffList();
      toastMessage('success', response.data.msg);
    } catch (error) {
      console.log(error);
    }
  };

  const resendPassword = async (staffid) => {
    try {
      const response = await userInstance().put('/staff/resendPassword', {
        staffid,
      });
      toastMessage('success', response.data.msg);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (error) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [error]);

  const pageCount = Math.ceil(pageCounts / limit);

  return (
    <>
      {/* <Layout header footer></Layout> */}
      <AdminWrapper>
        {loading ? <Loader /> : (
          <div className=" staff-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <StaffTable
                    staffList={staffList}
                    HandleOpenCloseTable={HandleOpenCloseTable}
                    handleFormModal={handleFormModal}
                    handleEditStaff={handleEditStaff}
                    removeStaff={removeStaff}
                    setShowHide={setShowHide}
                    showHide={showHide}
                    handleManageVisible={handleManageVisible}
                    searchStaff={searchStaff}
                    searchTxt={searchTxt}
                    resendPassword={resendPassword}
                  />
                  <StaffForm
                    HandleOpenCloseTable={HandleOpenCloseTable}
                    handleChange={handleChange}
                    staffDetails={staffData}
                    handleAddStaff={handleAddStaff}
                    showForm={showForm}
                    handleFormModal={handleFormModal}
                    selectedStaff={selectedStaff}
                    editStaff={editStaff}
                    selectedCountry={selectedCountry}
                    setCountry={setCountry}
                    handleCountry={handleCountry}
                    compLocation={compLocation}
                    error={error}
                    mobileFormat={mobileFormat}
                  />
                  {showTable ? (
                    <StaffModal
                      showTable={showTable}
                      HandleOpenCloseTable={HandleOpenCloseTable}
                      staffData={staffData}
                      showStartDate={showStartDate}
                      setShowStartDate={setShowStartDate}
                      showEndDate={showEndDate}
                      setShowEndDate={setShowEndDate}
                      handleRepeatDropdown={handleRepeatDropdown}
                      setRepeatDates={setRepeatDates}
                      setDay={setDay}
                      handleCalendar={handleCalendar}
                      handleAfterRange={handleAfterRange}
                      handleBeforeRange={handleBeforeRange}
                      firstDay={firstDay}
                      lastDay={lastDay}
                      weeklist={weeklist}
                      handleEditWorking={handleEditWorking}
                      updateMonth={updateMonth}
                      updateYear={updateYear}
                      staffList={
                      staffList
                        ? staffList.filter((el) => el.hide === false)
                        : []
                    }
                      HandleOpenCloseDropdown={HandleOpenCloseDropdown}
                      showDropdownModal={showDropdownModal}
                      setShowDropdown={setShowDropdown}
                      selectedDay={selectedDay}
                      renderSpecificDate={renderSpecificDate}
                      setRender={setRender}
                      selectedShift={selectedShift}
                      handleShiftChange={handleShiftChange}
                      updateRepeatStaff={updateRepeatStaff}
                      repeatStaff={repeatStaff}
                      updateCurrentShift={updateCurrentShift}
                      updateAllShift={updateAllShift}
                      handleDelete={handleDelete}
                      deleteStaff={deleteStaff}
                      deleteCurrentShift={deleteCurrentShift}
                      deleteUpcomingShift={deleteUpcomingShift}
                      handleTimePicker={handleTimePicker}
                      type={type}
                      setTimePicker={setTimePicker}
                      timepicker={timepicker}
                    />
                  ) : null}
                  <div className="view-btn">
                    {/* <Button type="button" className="btn add-btn" onClick={handleFormModal}>Add Staff</Button> */}
                    {/* <Button type="button" className="btn add-btn blue-btn" onClick={HandleOpenCloseTable}>Working Hours</Button> */}
                  </div>
                  {staffList ? (
                    <div className="user-pagination">
                      <ReactPaginate
                        previousLabel="← Previous"
                        nextLabel="Next →"
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName="pagination-chk"
                        previousLinkClassName="pagination__link"
                        nextLinkClassName="pagination__link"
                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                        forcePage={selectedPage - 1}
                        renderOnZeroPageCount={null}
                      />
                      <div className="no-of-page">
                        Page
                        {' '}
                        {selectedPage}
                        {' '}
                        of
                        {' '}
                        {' '}
                        {pageCount}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </AdminWrapper>
    </>
  );
}

export default Staff;
