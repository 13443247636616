import React, { useState, useEffect } from 'react';
import {
  Tabs, Tab, Form, Button, Dropdown,
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import showmore from '../../assets/home/showmoreblue.png';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import AccountingSoftware from './accounting';
import SalesAccount from './sales';
// import Currency from './currency';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import target from '../../assets/home/icon/target.png';
import './xero.css';
import Loader from '../loader/loader';

const XeroComponent = () => {
  const [heading, setHeading] = useState('Accounting Software & Merchant Facility Integrations');
  const [view, setView] = useState('Connect Your Accounting Software');
  const [loading, setLoading] = useState(false);
  const updateView = (key) => {
    if (key === 'Connect Your Accounting Software') {
      setHeading('Accounting Software & Merchant Facility Integrations');
      setView(key);
    } else if (key === 'Chart of Accounts') {
      setHeading('Link Sales to Xero Chart of Accounts');
      setView(key);
    // } else if (key === 'Currency') {
    //   setHeading('Select a Currency for Organisation');
    //   setView(key);
    }
  };

  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case 'Connect Your Accounting Software':
        componentToRender = (
          <AccountingSoftware />
        );

        break;
      case 'Chart of Accounts':
        componentToRender = (
          <SalesAccount />
        );
        break;
        // case 'Currency':
        //   componentToRender = (
        //     <Currency />
        //   );
        // break;
      default:
        break;
    }
    return componentToRender;
  };

  const getDateMeta = async () => {
    try {
      setLoading(true);
      const newresponse = await userInstance().get('/quickBooks/queryContact');
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    // console.log('this is the response', newresponse);
  };
  useEffect(() => {
    getDateMeta();
  }, []);
  return (
    <AdminWrapper>
      {loading ? <Loader /> : (
        <div className="appointment-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-titles-mob link-sales-page">
                  <h3>{heading}</h3>
                  <div className="content-dropdown">
                    <div className="client-option service-edit-icon">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={showmore} alt="show" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('Connect Your Accounting Software')}>
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span>Connect Your Accounting Software </span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <div className="action" onClick={() => updateView('Chart of Accounts')}>
                              <span>
                                <img src={target} alt="edit" />
                              </span>
                              <span>Merchant Facility Integration</span>
                            </div>
                          </Dropdown.Item>
                          {/* <Dropdown.Item>
                          <div className="action" onClick={() => updateView('Currency')}>
                            <span>
                              <img src={target} alt="edit" />
                            </span>
                            <span>Currency</span>
                          </div>
                        </Dropdown.Item> */}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {renderView()}

              </div>
            </div>

          </div>
        </div>
      )}
    </AdminWrapper>
  );
};
export default XeroComponent;
