import React, { useEffect, useState } from 'react';
import { Form, Dropdown, Button } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { coverBlankField } from '../../functions';

const FilterReplacement = ({
  handleRevertViewUpdate,
  state,
  view,
  handleFilterChange,
  handleNewRevertDefaultUpdate,
  handleRequest,
  filterData,
  granSelected,
  isUsImperial,
  checkBlankFields,
}) => {
  const [filteringData, setFilteringData] = useState({
    hoursRequired: '',
    setUpCost: '',
    labourCost: '',
  });
  const [costs, setCost] = useState({
    coarseGlassBuyCost: '',
    fineGlassBuyCost: '',
    coarseGlassSellCost: '',
    fineGlassSellCost: '',
    sandMediaBuyCost: '',
    sandMediaSellCost: '',
  });
  // console.log('checkBlankFields', checkBlankFields);
  const fetchingFilterData = () => {
    let copyFilteringData = { ...filteringData };
    filterData.forEach((el) => {
      switch (el.mediaFilterSize) {
        case 'Labour Cost':
          copyFilteringData.labourCost = el.sandQuantity;
          break;
        case 'Set-up & Pack-up Time':
          copyFilteringData.setUpCost = el.sandQuantity;
          break;

        case 'Hours Required':
          copyFilteringData.hoursRequired = el.sandQuantity;
          break;

        default:
          break;
      }
    });
    setFilteringData(copyFilteringData);
  };

  const fetchingCosts = () => {
    let copyCosts = { ...costs };
    // console.log('granselected', granSelected);
    granSelected.forEach((el) => {
      switch (el.name) {
        case 'coarseGlassMedia':
          copyCosts.coarseGlassBuyCost = el.buycost;
          copyCosts.coarseGlassSellCost = el.sellprice;
          break;
        case 'fineGlassMedia':
          copyCosts.fineGlassBuyCost = el.buycost;
          copyCosts.fineGlassSellCost = el.sellprice;
          break;

        case 'sandMedia':
          copyCosts.sandMediaBuyCost = el.buycost;
          copyCosts.sandMediaSellCost = el.sellprice;
          break;

        default:
          break;
      }
    });
    setCost(copyCosts);
  };

  useEffect(() => {
    fetchingFilterData();
    fetchingCosts();
  }, []);

  // Calculation
  const glassSellPriceCalculation = (
    glassCoarseQuantity,
    glassFineQuantity,
    coarseGlassSellPrice,
    fineGlassSellPrice,
  ) => {
    const result = parseFloat(glassCoarseQuantity) * parseFloat(coarseGlassSellPrice)
      + parseFloat(glassFineQuantity) * parseFloat(fineGlassSellPrice);
    return result.toFixed(2);
  };

  const setUpAndPackUpCost = (setupCost, labourCost) => {
    const result = parseFloat(setupCost) * parseFloat(labourCost);
    return result.toFixed(2);
  };

  const hoursRequiredCost = (hoursRequired, labourCost) => {
    const result = parseFloat(hoursRequired) * parseFloat(labourCost);
    return result.toFixed(2);
  };

  const glassBuyCostCalculation = (
    glassCoarseQuantity,
    glassFineQuantity,
    coarseGlassBuyPrice,
    fineGlassBuyPrice,
  ) => {
    const result = parseFloat(glassCoarseQuantity) * parseFloat(coarseGlassBuyPrice)
      + parseFloat(glassFineQuantity) * parseFloat(fineGlassBuyPrice);
    return result.toFixed(2);
  };
  const sandSellPrice = (bagsSand, sandPrice) => {
    const result = parseFloat(bagsSand) * parseFloat(sandPrice);
    return result.toFixed(2);
  };
  const sandBuyPrice = (bagsSand, sandPrice) => {
    const result = parseFloat(bagsSand) * parseFloat(sandPrice);
    return result.toFixed(2);
  };

  return (
    <>
      <div className="pool-chemistry-box green-pool-calculation acid-wash media-replacement">
        <div className="main-title">
          <h6>Sand Filter Media Replacement</h6>
        </div>
        <div className="pricing-setup">
          <h6>Pricing Set-up for Changing Filter Media</h6>
          <p>
            The blue numbers in the table below will change the costs for Sand
            Filter Media Replacement in Calculator.
            <br />
            Changes to
            {' '}
            {!isUsImperial && 'Glass or'}
            {' '}
            Sand quantity requirement in Table One will
            affect Cost Calculations.
            <br />
            Updated costs for the different filter sizes can be seen in the
            {' '}
            {isUsImperial ? 'second table' : '2nd and 3rd tables'}
            {' '}
            after clicking the Save/Update button at the bottom
            of Table One
          </p>
        </div>

        <div className="green-pool-table filter-table">
          <h5>Filter Media Calculation Table</h5>
          <div className={`main-table ${isUsImperial ? 'us-imperial-table' : ''}`}>
            <div className="pool-table">
              <p>Media Filter Size</p>
              {!isUsImperial && (
              <p>
                Bags Glass
                <span> (15kg)</span>
              </p>
              )}
              {!isUsImperial && (<p>Glass Quantity Details</p>)}
              <p>
                Bags Sand
                <span>
                  {' '}
                  {isUsImperial ? '(50lb)' : '(20kg)'}
                </span>
              </p>
              <p>Sand Quantity Detail</p>
            </div>
            {Array.isArray(state) ? (
              <>
                {state.map((el, i) => (
                  <>
                    {el.mediaFilterSize === 'Hours Required'
                    || el.mediaFilterSize === 'Set-up & Pack-up Time'
                    || el.mediaFilterSize === 'Labour Cost' ? (
                      <>
                        <div className="dosage-part filter-bottom-table">
                          <div className="pool-table-content">
                            <p>
                              {el.mediaFilterSize}
                            </p>
                            <p>
                              <Form>
                                <Form.Group controlId="formBasicInput">
                                  {/* <span>$</span> */}
                                  <Form.Control
                                    type="number"
                                    name="sandQuantity"
                                    value={el.sandQuantity}
                                    onChange={(e) => handleFilterChange(e, i)}
                                    className={coverBlankField(el?.sandQuantity, el?.mediaFilterSize, checkBlankFields)}
                                    // className="error-text"
                                  />
                                </Form.Group>
                              </Form>
                              <span>{el.unit}</span>
                            </p>
                          </div>
                        </div>
                        <hr />
                      </>
                      ) : (
                        <>
                          <div className="dosage-part">
                            <div className="pool-table-content">
                              <p>
                                {el.mediaFilterSize}
                                &quot;
                              </p>
                              {!isUsImperial && <p>{el.bagsGlass}</p>}
                              {!isUsImperial && (
                              <p>
                                <Form>
                                  <Form.Group controlId="formBasicInput">
                                    <Form.Control
                                      type="number"
                                      name="glassCoarseQuantity"
                                      value={
                                      el.glassCoarseQuantity
                                      && el.glassCoarseQuantity.quantity
                                    }
                                      onChange={(e) => handleFilterChange(e, i)}
                                      className={coverBlankField(el?.glassCoarseQuantity?.quantity, el?.mediaFilterSize, checkBlankFields)}
                                    />
                                  </Form.Group>
                                </Form>
                                <span>
                                  {el.glassCoarseQuantity
                                  && el.glassCoarseQuantity.details}
                                </span>
                              </p>
                              )}
                              {isUsImperial ? (
                                <p>
                                  {el.bagsSand}
                                  {' '}
                                  to
                                  {' '}
                                  {parseFloat(el?.bagsSand || 0) - 1 }
                                </p>
                              ) : <p>{el?.bagsSand}</p>}
                              <p>
                                <Form>
                                  <Form.Group controlId="formBasicInput">
                                    <Form.Control
                                      type="number"
                                      name="sandQuantity"
                                      value={el.sandQuantity}
                                      onChange={(e) => handleFilterChange(e, i)}
                                      className={coverBlankField(el?.sandQuantity, el?.mediaFilterSize, checkBlankFields)}
                                    />
                                  </Form.Group>
                                </Form>
                                <span>{isUsImperial ? el.unit === 'kg' ? 'lb' : 'fl oz' : el.unit}</span>
                              </p>
                            </div>

                            <div className="pool-table-content">
                              <p />
                              {!isUsImperial && (<p />)}
                              {!isUsImperial && (
                              <p>
                                <Form>
                                  <Form.Group controlId="formBasicInput">
                                    <Form.Control
                                      type="number"
                                      name="glassFineQuantity"
                                      value={
                                      el.glassFineQuantity
                                      && el.glassFineQuantity.quantity
                                    }
                                      onChange={(e) => handleFilterChange(e, i)}
                                      className={coverBlankField(el?.glassFineQuantity?.quantity, el?.mediaFilterSize, checkBlankFields)}
                                    />
                                  </Form.Group>
                                </Form>
                                <span>
                                  {el.glassFineQuantity
                                  && el.glassFineQuantity.details}
                                </span>
                              </p>
                              )}
                              <p />
                              <p />
                            </div>
                          </div>
                          {i === 6 ? <hr /> : null}
                        </>
                      )}
                  </>
                ))}
              </>
            ) : null}

            <div className="view-btn">
              <div className="client-option service-edit-icon">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="btn btn-edi"
                  >
                    View Other Settings
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div className="action" onClick={handleRevertViewUpdate}>
                        Restore Original Settings
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <div
                        className="action"
                        onClick={handleNewRevertDefaultUpdate}
                      >
                        Revert to Previous Saved Default
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Button
                type="submit"
                className="btn btn-save"
                onClick={handleRequest}
              >
                Save as Default
              </Button>
            </div>
            <div className="note-info">
              <span>*</span>
              {' '}
              {isUsImperial ? 'Costs' : 'Glass & costs'}
              {' '}
              for Media Filter calculations come
              from prices set at &quot;Pool Set-up&gt;Chemical costs &
              Margins&quot;
            </div>
          </div>
        </div>
      </div>
      {!isUsImperial && (
      <div className="pool-chemistry-box green-pool-calculation acid-wash filter-glass-table media-replacement">
        <div className="green-pool-table filter-table">
          <h5>Sand Filter Media Replacement (Glass)</h5>
          <div className="main-table">
            <div className="pool-table">
              <p>Media Filter Size</p>
              <p>Glass Sell Price</p>
              <p>Set-up & Pack-up Cost</p>
              <p>Hours Required Cost</p>
              <p>SELL PRICE TOTAL</p>
            </div>
            {Array.isArray(state) ? (
              <>
                {state.map((el, i) => (
                  <>
                    {el.mediaFilterSize === 'Hours Required'
                    || el.mediaFilterSize === 'Set-up & Pack-up Time'
                    || el.mediaFilterSize === 'Labour Cost' ? null : (
                      <>
                        <div className="dosage-part">
                          <div className="pool-table-content">
                            <p>
                              {el.mediaFilterSize}
                              &quot;
                            </p>
                            <p>
                              {`
                                 $ ${
                                   el.glassFineQuantity
                                   && el.glassCoarseQuantity
                                     ? glassSellPriceCalculation(
                                       el.glassCoarseQuantity.quantity,
                                       el.glassFineQuantity.quantity,
                                       costs.coarseGlassSellCost,
                                       costs.fineGlassSellCost,
                                     )
                                     : ''
                                 }`}
                            </p>
                            <p>
                              {`$ ${setUpAndPackUpCost(
                                filteringData.setUpCost,
                                filteringData.labourCost,
                              )}`}

                            </p>
                            <p>
                              {`$ ${hoursRequiredCost(
                                filteringData.hoursRequired,
                                filteringData.labourCost,
                              )}`}

                            </p>
                            <p>
                              {`$ ${
                                el.glassFineQuantity && el.glassCoarseQuantity
                                  ? (
                                    Math.ceil(
                                      (parseFloat(
                                        glassSellPriceCalculation(
                                          el.glassCoarseQuantity.quantity,
                                          el.glassFineQuantity.quantity,
                                          costs.coarseGlassSellCost,
                                          costs.fineGlassSellCost,
                                        ),
                                      )
                                          + parseFloat(
                                            setUpAndPackUpCost(
                                              filteringData.setUpCost,
                                              filteringData.labourCost,
                                            ),
                                          )
                                          + parseFloat(
                                            hoursRequiredCost(
                                              filteringData.hoursRequired,
                                              filteringData.labourCost,
                                            ),
                                          ))
                                          / 10,
                                    ) * 10
                                  ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : ''
                              }`}
                            </p>
                          </div>
                          <div className="pool-table-content glass-bottom-table">
                            <p>Labour & Margin</p>
                            <p>
                              {`$ ${
                                el.glassFineQuantity && el.glassCoarseQuantity
                                  ? ((
                                    Math.ceil(
                                      (parseFloat(
                                        glassSellPriceCalculation(
                                          el.glassCoarseQuantity.quantity,
                                          el.glassFineQuantity.quantity,
                                          costs.coarseGlassSellCost,
                                          costs.fineGlassSellCost,
                                        ),
                                      )
                                          + parseFloat(
                                            setUpAndPackUpCost(
                                              filteringData.setUpCost,
                                              filteringData.labourCost,
                                            ),
                                          )
                                          + parseFloat(
                                            hoursRequiredCost(
                                              filteringData.hoursRequired,
                                              filteringData.labourCost,
                                            ),
                                          ))
                                          / 10,
                                    ) * 10
                                  ) - parseFloat(
                                    glassBuyCostCalculation(
                                      el.glassCoarseQuantity.quantity,
                                      el.glassFineQuantity.quantity,
                                      costs.coarseGlassBuyCost,
                                      costs.fineGlassBuyCost,
                                    ),
                                  )).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : ''
                              }`}
                            </p>
                          </div>
                        </div>
                        {i < 6 ? <hr /> : null}
                      </>
                      )}
                  </>
                ))}
              </>
            ) : null}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div>
            <hr /> */}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div>
            <hr /> */}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div>
            <hr /> */}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div>
            <hr /> */}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div>
            <hr /> */}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div>
            <hr /> */}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div>
            <hr /> */}
            {/* <div className="dosage-part">
              <div className="pool-table-content">
                <p>24&quot;</p>
                <p>$ 231.00</p>
                <p>$ 135.00</p>
                <p>$ 375.00</p>
                <p>$ 750.00</p>
              </div>
              <div className="pool-table-content glass-bottom-table">
                <p>Labour & Margin</p>
                <p>$ 673.00</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      )}
      <div className="pool-chemistry-box green-pool-calculation acid-wash filter-glass-table media-replacement-page">
        <div className="green-pool-table filter-table">
          <h5>Sand Filter Media Replacement (Sand)</h5>
          <div className="main-table">
            <div className="pool-table">
              <p>Media Filter Size</p>
              <p>Sand Sell Price</p>
              <p>Set-up & Pack-up Cost</p>
              <p>Hours Required Cost</p>
              <p>SELL PRICE TOTAL</p>
            </div>
            {Array.isArray(state) ? (
              <>
                {state.map((el, i) => (
                  <>
                    {el.mediaFilterSize === 'Hours Required'
                    || el.mediaFilterSize === 'Set-up & Pack-up Time'
                    || el.mediaFilterSize === 'Labour Cost' ? null : (
                      <>
                        <div className="dosage-part">
                          <div className="pool-table-content">
                            <p>
                              {el.mediaFilterSize}
                              &quot;
                            </p>
                            <p>{`$ ${sandSellPrice(el.bagsSand, costs.sandMediaSellCost)}`}</p>
                            <p>
                              {' '}
                              {`$ ${setUpAndPackUpCost(
                                filteringData.setUpCost,
                                filteringData.labourCost,
                              )}`}

                            </p>
                            <p>
                              {' '}
                              {`$ ${hoursRequiredCost(
                                filteringData.hoursRequired,
                                filteringData.labourCost,
                              )}`}

                            </p>
                            <p>
                              {`$ ${
                                el.glassFineQuantity && el.glassCoarseQuantity
                                  ? (
                                    Math.ceil(
                                      (parseFloat(
                                        sandSellPrice(el.bagsSand, costs.sandMediaSellCost),
                                      )
                                          + parseFloat(
                                            setUpAndPackUpCost(
                                              filteringData.setUpCost,
                                              filteringData.labourCost,
                                            ),
                                          )
                                          + parseFloat(
                                            hoursRequiredCost(
                                              filteringData.hoursRequired,
                                              filteringData.labourCost,
                                            ),
                                          ))
                                          / 10,
                                    ) * 10
                                  ).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : ''
                              }`}
                            </p>
                          </div>
                          <div className="pool-table-content glass-bottom-table">
                            <p>Labour & Margin</p>
                            <p>
                              {`$ ${
                                el.glassFineQuantity && el.glassCoarseQuantity
                                  ? ((
                                    Math.ceil(
                                      (parseFloat(
                                        sandSellPrice(el.bagsSand, costs.sandMediaSellCost),
                                      )
                                          + parseFloat(
                                            setUpAndPackUpCost(
                                              filteringData.setUpCost,
                                              filteringData.labourCost,
                                            ),
                                          )
                                          + parseFloat(
                                            hoursRequiredCost(
                                              filteringData.hoursRequired,
                                              filteringData.labourCost,
                                            ),
                                          ))
                                          / 10,
                                    ) * 10
                                  ) - parseFloat(
                                    sandBuyPrice(el.bagsSand, costs.sandMediaBuyCost),
                                  )).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  : ''
                              }`}
                            </p>
                          </div>
                        </div>
                        <hr />
                      </>
                      )}
                  </>
                ))}
              </>
            ) : null}

          </div>
        </div>
      </div>
    </>
  );
};
FilterReplacement.propTypes = {
  handleRevertViewUpdate: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  view: PropTypes.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  filterData: PropTypes.isRequired,
  granSelected: PropTypes.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  checkBlankFields: PropTypes.arrayOf.isRequired,
};
export default FilterReplacement;
