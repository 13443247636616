import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TimePicker } from 'smart-webcomponents-react/timepicker';
import moment from 'moment';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

const DurationPicker = ({
  durationPicker,
  durationData,
  handleDurationPicker,
  bookingData,
  setBookingData,
  setDuration,
  convertToReadableDuration,
  subcategory,
  setSubCategory,
  setDurationPicker,
}) => {
  const timepickerRef = useRef(null);
  const [active, setActive] = useState('hour');

  const handleDurationSet = () => {
    // console.log('duration->>>>');
    const { hour, minute } = getDurartions();
    if (!(!parseInt(hour, 10) && parseFloat(minute) < 5)) {
      timepickerRef.current.value = `${hour}:${minute}`;
      if (bookingData && setBookingData) {
        setBookingData({
          ...bookingData,
          duration: durationData,
          durationChange: true,
        });
      } else {
        setSubCategory({ ...subcategory, duration: durationData });
      }
      handleDurationPicker();
    } else {
      toastMessage('error', 'Minimum value for duration is 5 minutes.');
    }
  };

  const handleTime = (e) => {
    // console.log(e);
    const time = moment(new Date(e.detail.value).toISOString()).format('H:m');
    // console.log('time', time);
    const splitData = time.split(':');
    // console.log('splitData', splitData);
    let hour = splitData[0];
    if (hour === '0') {
      hour = '12';
    } else if (hour === '12') {
      hour = '0';
    }
    // console.log('parseFloat(splitData[1])', parseFloat(splitData[1]));
    // console.log('hour', hour);
    const inMinute = parseFloat(hour) * 60 + parseFloat(splitData[1]);
    // console.log('inMinute', inMinute);
    setDuration(inMinute.toString());
  };
  const handleHourChange = (type) => {
    // console.log('hour->>>>');
    timepickerRef.current.selection = type;
    const fEl = document.getElementsByClassName('smart-label smart-unselectable');
    if (type === 'hour') {
      // console.log('fEl', fEl);
      fEl[0].innerHTML = '0';
      fEl[0].classList.add('zero-class');
      fEl[12].innerHTML = '12';
      fEl[0].setAttribute('x', 104);
      fEl[12].setAttribute('x', 102);
      fEl[12].classList.add('other-class');
    } else {
      fEl[0].innerHTML = '0';
      fEl[0].setAttribute('x', 104);
      fEl[0].classList.add('zero-class-min');
    }
    setActive(type);
  };

  const getDurartions = () => {
    // console.log('gteteteet');
    const convertedDuration = convertToReadableDuration(durationData);
    const splitData = convertedDuration.split('hr');
    const hour = convertedDuration.includes('hr') ? splitData[0].replace(/hr/g, '').trim() : '0';
    const minute = convertedDuration.includes('hr')
      ? splitData[1]
        ? splitData[1].replace(/min/g, '').trim()
        : '0'
      : convertedDuration.replace(/min/g, '').trim();
    // console.log('hour from duration', hour);
    return { hour, minute };
  };

  const { hour, minute } = getDurartions();

  useEffect(() => {
    setTimeout(() => {
      try {
        // console.log('timepickerRef.current', timepickerRef.current);
        const fEl = document.getElementsByClassName('smart-label smart-unselectable');
        // console.log('fEl fElfElfElfElfEl', fEl.length);
        if (fEl && fEl.length) {
          fEl[0].innerHTML = '0';
          fEl[0].classList.add('zero-class');
          fEl[12].innerHTML = '12';
          fEl[0].setAttribute('x', 104);
          fEl[12].setAttribute('x', 102);
          timepickerRef.current.value = `${hour === '12' ? '0' : hour === '0' ? '12' : hour}:${minute}`;
        }
      } catch (e) {
        console.log('e', e);
      }
    }, 850);
  }, []);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setDurationPicker(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div
      className="duration-picker time-picker-modal"
    >
      <div>
        <div className="time-keeper-popup duration-popup" ref={wrapperRef}>
          <div className="custom-select-time">
            <h6
              className={`custom-hour ${active === 'hour' ? 'time-active' : ''}`}
              onClick={() => handleHourChange('hour')}
            >
              {hour}
              <span>hr</span>
            </h6>
            <h6
              className={`custom-min ${active === 'minute' ? 'time-active' : ''}`}
              onClick={() => handleHourChange('minute')}
            >
              {minute}
              <span>min</span>
            </h6>
          </div>
          <TimePicker ref={timepickerRef} format="24-hour" onChange={handleTime} minuteInterval={5} />
          <div className="hour_minute_handle">
            <Button
              className={`hour_minute_handle_button ${active === 'hour' ? 'hourbtn-active' : ''}`}
              style={{ backgroundColor: ' #1550b9' }}
              onClick={() => handleHourChange('hour')}
            >
              hr

            </Button>
            <Button
              className={`hour_minute_handle_button ${active === 'minute' ? 'hourbtn-active' : ''}`}
              style={{ backgroundColor: ' #1550b9' }}
              onClick={() => handleHourChange('minute')}
            >
              min

            </Button>
          </div>
          <div className="time-keeper-btn">
            <Button onClick={handleDurationSet}>Save/Update</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
DurationPicker.propTypes = {
  durationPicker: PropTypes.bool.isRequired,
  durationData: PropTypes.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  bookingData: PropTypes.func.isRequired,
  setDuration: PropTypes.func.isRequired,
  setBookingData: PropTypes.func.isRequired,
  convertToReadableDuration: PropTypes.func.isRequired,
  subcategory: PropTypes.func.isRequired,
  setSubCategory: PropTypes.func.isRequired,
  setDurationPicker: PropTypes.func.isRequired,
};
export default DurationPicker;
