import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Modal, Form, Dropdown,
} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import TimePicker from 'smart-webcomponents-react/timepicker';
import cancel from '../../assets/home/cross-icon.png';
import DaysPicker from './daysPicker';
import SearchModal from '../invoices/searchmodal';
import { errorMsgTime } from '../../functions/intervalTime';
import { makingAddress } from '../../functions';
import toastMessage from '../../functions/toastMessage';

const AddPaymentReminder = ({
  show, handleclose, subGroupFormData, handleSubGroupFormChange, allClients, setClients, searchedClients, allCustomerList, setSubGroupFormData, handleFrequency, setRepeatFrequency, repeatFrequency, datePopupData, handleChangeDate, timepickerRef, handleSaveDate, saveUpdateSubGroup, setClientSelectBySearch, clientSelectBySearch, allClinetsExceptId, addClient, setAddClient, specificClientsIdArray, setSpecificClientsIdArray, actionType, triggerDaysCheckAddEdit,

}) => {
  let increment = 1;
  const [daysPicker, setDaysPicker] = useState(false);
  const [clientTxt, setClientTxt] = useState('');
  const [showClientPopup, setShowClientPopup] = useState();
  const [active, setActive] = useState('days');
  const [durationValue, setDuration] = useState({
    days: 1,
    weeks: 0,
  });
  const [isHideSearch, setHideSearch] = useState(false);
  // Functions for the clients form, add etc
  const handleClient = (e) => {
    if (e.target.value === 'Specific Clients') {
      setAddClient(true);
      setSubGroupFormData({
        ...subGroupFormData,
        clientsType: e.target.value,
        clientList: [],
      });
    } else {
      setAddClient(false);
      setClientSelectBySearch([]);
      setSubGroupFormData({ ...subGroupFormData, clientList: allClinetsExceptId, clientsType: e.target.value });
    }
  };
  const searchClient = async (searchTxt) => {
    setClientTxt(searchTxt);
    const str = searchTxt.toString().toLowerCase();
    const filteredClients = allCustomerList.filter((el) => el?.firstname?.toLowerCase().includes(str) || el?.lastname?.toLowerCase().includes(str) || el?.mobileNo?.toLowerCase().includes(str)
      || el?.streetAddress?.toLowerCase().includes(str));
    //  { firstname: dataToSearch },
    // { lastname: dataToSearch },
    // { billingAddress: dataToSearch },
    // { mobileNo: dataToSearch },
    // { 'siteAddresses.address': dataToSearch },
    // { 'siteAddresses.email': dataToSearch },
    // { 'siteAddresses.mobileNo': dataToSearch },
    // { 'siteAddresses.householderName': dataToSearch },
    // { 'associatedContacts.mobileNo': dataToSearch },
    // { 'associatedContacts.email': dataToSearch },
    // { 'associatedContacts.lastName': dataToSearch },
    // { 'associatedContacts.name': dataToSearch },

    setClients(filteredClients);
  };
  const handleAddClient = (info) => {
    let copyClientSelectBySearch = clientSelectBySearch.slice();
    const getClientIndex = copyClientSelectBySearch.findIndex((el) => el?._id === info._id);
    if (getClientIndex === -1) {
      copyClientSelectBySearch.push(info);
      copyClientSelectBySearch.sort((a, b) => a.lastname.localeCompare(b.lastname));
      setClientSelectBySearch(copyClientSelectBySearch);
      let copySubGroupFormData = { ...subGroupFormData };
      copySubGroupFormData.clientList = [...copySubGroupFormData.clientList, ...[info._id]];
      setSubGroupFormData(copySubGroupFormData);
      setClientTxt('');
      setClients(allCustomerList);
      setShowClientPopup(false);
    } else {
      toastMessage('error', 'Customer already selected.');
    }
    if (actionType === 'edit') {
      setSpecificClientsIdArray([...specificClientsIdArray, info._id]);
    }
  };
  const removeClient = (_id) => {
    const removeSelectedClientsArray = clientSelectBySearch.filter((el) => el._id !== _id);
    setClientSelectBySearch(removeSelectedClientsArray);
    const removeSelectedClientId = subGroupFormData.clientList.filter((el) => el !== _id);
    setSubGroupFormData({ ...subGroupFormData, clientList: removeSelectedClientId });
    if (actionType === 'edit') {
      const findIndexClientID = specificClientsIdArray.filter((el) => el !== _id);
      setSpecificClientsIdArray(findIndexClientID);
    }
  };

  const openClientSearchModalFuc = () => {
    setShowClientPopup(!showClientPopup);
  };

  // Clients form functionality end
  const handleDurationPicker = () => {
    setDaysPicker(true);
    setDuration(subGroupFormData.sendTrigger);
  };

  const getDaysData = () => {
    let toShowData = '';
    if (subGroupFormData.sendTrigger.weeks > 0 && subGroupFormData.sendTrigger.days > 0) {
      toShowData = `${subGroupFormData.sendTrigger.days} Days ${subGroupFormData.sendTrigger.weeks} Weeks `;
    } else if (subGroupFormData.sendTrigger.weeks > 0) {
      toShowData = `${subGroupFormData.sendTrigger.weeks} Weeks`;
    } else if (subGroupFormData.sendTrigger.days > 0) {
      toShowData = `${subGroupFormData.sendTrigger.days} Days`;
    }
    return toShowData;
  };

  const handleSaveData = () => {
    setDaysPicker(false);
    if (actionType === 'add') {
      setSubGroupFormData({ ...subGroupFormData, sendTrigger: durationValue });
    } else if (actionType === 'edit') {
      setSubGroupFormData({ ...subGroupFormData, sendTrigger: durationValue });
    }
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'time-picker-modal time-popup') {
            setRepeatFrequency(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleclose}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="dropdown-modal staff-modal edit-working-popup edit-daystime-popup payment-reminder-popup"
      >
        <div className="staff-close">
          <i className="fa fa-times" onClick={handleclose} aria-hidden="true" />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {actionType === 'add' ? 'Add' : 'Edit'}
            {' '}
            Payment Reminder
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={`staff-form ${addClient ? 'add-payment-popup' : ''}`}>
            <div className="shift-container shift-container-top">
              <div className="row">
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label className="text-tarnsform-none">
                      Name of Payment Reminder Group
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="reminderName"
                      value={subGroupFormData.reminderName}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasicCompanyType">
                    <Form.Label>
                      Client Send List
                    </Form.Label>
                    <div className="select-box">
                      {/* <i className="fas fa-sort-down" /> */}
                      <Form.Control
                        as="select"
                        name="clientsType"
                        onChange={handleClient}
                        value={subGroupFormData.clientsType}
                      >
                        <option value="All Clients (except with custom send conditions)">All Clients (except with custom send conditions)</option>
                        <option value="Specific Clients">Specific Client </option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            {addClient && (
              <>
                <div className="shift-container add-client-container">
                  <h6>Client</h6>
                  <i className="fa fa-plus" onClick={() => openClientSearchModalFuc()} />
                </div>
                {clientSelectBySearch && clientSelectBySearch.length > 0 && clientSelectBySearch.sort().map((el) => (
                  <>
                    <div className="shift-container added-client">
                      <div className="client-details-section">
                        <h6>
                          {el.firstname}
                          {' '}
                          {el.lastname}
                        </h6>
                        <p>{makingAddress({ streetAddress: el.streetAddress, suburb: el.suburb })}</p>
                        <p>{el.mobileNo}</p>
                      </div>
                      <img src={cancel} alt="cancel" onClick={() => removeClient(el._id)} />
                    </div>
                  </>
                ))}

              </>
            )}
            <div className="shift-container bottom-shif-container">
              <div className="row">
                <div className="col-md-12">
                  <Form.Group controlId="formBasicCompanyType">
                    <Form.Label>
                      Send Trigger
                    </Form.Label>
                    <div className="select-box">
                      <button onClick={() => handleDurationPicker(true)} type="submit" className="repeat-btn btn btn-primary">
                        {getDaysData() || '1 Day'}
                        {' '}
                        from Most Recent Invoice Date
                      </button>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasicCompanyType">
                    <Form.Label>
                      Repeat Frequency
                    </Form.Label>
                    <div className="select-box">
                      <button onClick={handleFrequency} type="submit" className="repeat-btn btn btn-primary">
                        {`Repeats every ${datePopupData.interval_multiplier} ${datePopupData.interval_type === 'monthly'
                          ? 'Months'
                          : datePopupData.interval_type === 'weekly'
                            ? 'Weeks'
                            : datePopupData.interval_type === 'daily'
                              ? 'Days'
                              : ''
                        }`}
                      </button>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasicCompanyType">
                    <Form.Label>
                      Repeat End Time
                    </Form.Label>
                    <div className="select-box">
                      {/* <i className="fas fa-sort-down" /> */}
                      <Form.Control
                        as="select"
                        name="repeatEndTime"
                        onChange={(e) => handleSubGroupFormChange(e)}
                        value={subGroupFormData.repeatEndTime}
                      >
                        <option value="Ongoing" key={increment}>
                          Ongoing
                        </option>
                        {Array.from(Array(11).keys()).map(() => {
                          increment += 1;
                          return (
                            <option
                              value={`After ${increment} times`}
                              key={increment}
                            >
                              {`After ${increment} times`}
                            </option>
                          );
                        })}
                        <option value="After 1 month">After 1 month</option>
                        <option value="After 2 month">After 2 month</option>
                        <option value="After 3 month">After 3 month</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label>
                      Send Subject
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="sendSubject"
                      value={subGroupFormData.sendSubject}
                      onChange={(e) => handleSubGroupFormChange(e)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label>
                      Text Opening
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="textOpening"
                      value={subGroupFormData.textOpening}
                      placeholder="As Yet We have not received payment for invoices listed below"
                      onChange={(e) => handleSubGroupFormChange(e)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label>
                      Text Closing
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="If you could help with payment in full at your convenience,  It will be really appreciated"
                      name="textClosing"
                      value={subGroupFormData.textClosing}
                      onChange={(e) => handleSubGroupFormChange(e)}
                    />
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <p className="add-payment-text">
                    <span>*</span>
                    {' '}
                    Salutation, Addressee, Complimentary Close and Sender&apos;s Detail will be as set-up for Invoice Sending.
                  </p>
                </div>
              </div>

              <div className="view-btn">
                <Button type="submit" className="btn btn-delete">Preview</Button>
                <Button type="submit" className="btn btn-save" onClick={saveUpdateSubGroup}>Save/Update</Button>
              </div>
              {repeatFrequency && (
                <div className="time-picker-modal time-popup">
                  <div>
                    <div
                      className="time-keeper-popup add-apppt-time"
                      ref={wrapperRef}
                    >
                      <div className="time-keeper">
                        <div className="time-keepr-repeat">
                          <h6>Repeats Every</h6>
                          <h4>
                            {`${datePopupData.interval_multiplier} ${datePopupData.interval_type === 'monthly'
                              ? 'Months'
                              : datePopupData.interval_type === 'weekly'
                                ? 'Weeks'
                                : datePopupData.interval_type === 'daily'
                                  ? 'Days'
                                  : ''
                            }`}
                          </h4>
                        </div>
                        <TimePicker
                          ref={timepickerRef}
                          format="12-hour"
                          onChange={(e) => handleChangeDate(
                            'interval_multiplier',
                            moment(new Date(e.detail.value).toISOString())
                              .format('h:mm')
                              .split(':')[0],
                          )}
                          minuteInterval={5}
                        />
                      </div>
                      <div className="set-btn">
                        <Button
                          onClick={() => handleChangeDate('interval_type', 'daily')}
                          className={`${datePopupData.interval_type === 'daily'
                            ? 'time-active-btn'
                            : ''
                          }`}
                        >
                          Daily
                        </Button>
                        <Button
                          onClick={() => handleChangeDate('interval_type', 'weekly')}
                          className={`${datePopupData.interval_type === 'weekly'
                            ? 'time-active-btn'
                            : ''
                          }`}
                        >
                          Weekly
                        </Button>
                        <Button
                          onClick={() => handleChangeDate('interval_type', 'monthly')}
                          className={`${datePopupData.interval_type === 'monthly'
                            ? 'time-active-btn'
                            : ''
                          }`}
                        >
                          Monthly
                        </Button>
                      </div>
                      <div className="time-keeper-btn">
                        <Button onClick={handleSaveDate}>Save/Update </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {daysPicker && (
        <DaysPicker setDurationPicker={setDaysPicker} setSubGroupFormData={setSubGroupFormData} subGroupFormData={subGroupFormData} durationValue={durationValue} setDuration={setDuration} handleSaveData={handleSaveData} />
      )}
      <SearchModal
        showSearch={showClientPopup}
        handleShowSearch={openClientSearchModalFuc}
        searchClient={searchClient}
        searchedClients={searchedClients}
        clientTxt={clientTxt}
        setClientTxt={setClientTxt}
        handleAddClient={handleAddClient}
        from="reminderSettings"
        setHideSearch={setHideSearch}
        isHideSearch={isHideSearch}
      />
    </>
  );
};
AddPaymentReminder.propTypes = {
  show: PropTypes.bool.isRequired,
  handleclose: PropTypes.func.isRequired,
  handleSubGroupFormChange: PropTypes.func.isRequired,
  setClients: PropTypes.func.isRequired,
  setSubGroupFormData: PropTypes.func.isRequired,
  handleFrequency: PropTypes.func.isRequired,
  setRepeatFrequency: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  timepickerRef: PropTypes.func.isRequired,
  handleSaveDate: PropTypes.func.isRequired,
  saveUpdateSubGroup: PropTypes.func.isRequired,
  subGroupFormData: PropTypes.objectOf.isRequired,
  datePopupData: PropTypes.objectOf.isRequired,
  repeatFrequency: PropTypes.objectOf.isRequired,
  allClients: PropTypes.arrayOf.isRequired,
  searchedClients: PropTypes.arrayOf.isRequired,
  allCustomerList: PropTypes.arrayOf.isRequired,
  clientSelectBySearch: PropTypes.arrayOf.isRequired,
  allClinetsExceptId: PropTypes.arrayOf.isRequired,
  setClientSelectBySearch: PropTypes.func.isRequired,
  setAddClient: PropTypes.func.isRequired,
  addClient: PropTypes.bool.isRequired,
  specificClientsIdArray: PropTypes.arrayOf.isRequired,
  setSpecificClientsIdArray: PropTypes.func.isRequired,
  actionType: PropTypes.string.isRequired,
  triggerDaysCheckAddEdit: PropTypes.objectOf.isRequired,
};
export default AddPaymentReminder;
