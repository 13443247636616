/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Button, Pagination } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import SubscriptionForm from './subscriptionForm';
import SubscriptionTable from './subscriptionTable';
// import StaffModal from './staffModal';
import { userInstance } from '../../config/axios';
import {
  showErrMsg, validateDataWithKey, queryString, getCalendarPath, removeCodeFromNumber,
} from '../../functions/index';
import { client, emailRegex } from '../../config/keys';
import AddSiteAddress from '../clients/siteAddress';
import AddBilingContact from '../clients/billingContact';
import './subscription.css';
import DeletePopUp from '../DeletePopUp/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import {
  errorBorderTime, errorMsgTime, nameRegex, postCodeRegex, successMsgTime,
} from '../../functions/intervalTime';
import UpgradePlanPopUp from './updgradePlanPopUp';
import MyPaymentForm from './SubScriptionPayment';
import CheckoutForm from '../SubscribePayment/checkOutForm';
// import { AuthDataContext } from '../../contexts/AuthDataContext';
import PosVerifyModal from './posVerifyModal';
import { socket } from '../../socket';
import AddSubsSiteAddress from './subsSiteAddress';
import toastMessage from '../../functions/toastMessage';
import { countries, states } from '../../country-state/country-state';
import Loader from '../loader/loader';

function Subscription() {
  const { userData } = useContext(AuthDataContext);
  const history = useHistory();
  const initialClientData = {
    customerType: 'Householder',
    companyName: '',
    firstname: '',
    lastname: '',
    mobileNo: '',
    landlineNo: '',
    email: '',
    streetAddress: '',
    suburb: '',
    postcode: '',
    state: '',
    country: 'Australia',
    invoiceSendType: 'Send Invoices by SMS',
    gender: 'male',
    note: '',
    role: 'subscriber',
    showBookings: true,
    subscriptionType: 'Basic Plan',
    numberUsers: 2,
    usersDisplay: 2,
    billingCycle: 'Annual',
    costDropDown: 'per annum',
    annnumCost: 1800,
    monthlyCost: 150,
    paymentStatus: 'Up-to-Date-Last Payment $530.00 on 22 Mar,2019',
    applyNewPayment: 'No',
    status: 'Active',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    hide: false,
    associatedContacts: [],
    siteAddresses: [],
  };
  let {
    clientid, name, startDateTime, employee_id, typee, manageClient, back,
  } = queryString();
  const initialContactData = {
    name: '', lastName: '', mobileNo: '', email: '', landlineNo: '', hide: false, tempShow: false,
  };
  const initialAddressData = {
    householderName: '', address: '', mobileNo: '', landlineNo: '', email: '', nestedContacts: [], hide: false, tempShow: false, poolWidth: '', poolHeight: '',
  };
  const limit = 10;
  const [showTable, setShowTable] = useState(false);
  const [clientData, setClientData] = useState(initialClientData);
  const [clientList, setClientList] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedClient, setClientId] = useState('');
  const [showHide, setShowHide] = useState(false);
  const [selectedCountry, setCountry] = useState('+61');
  const [searchData, setSearchData] = useState('');
  const [skip, setSkip] = useState(0);
  const [pageCounts, setPageCounts] = useState(1);
  const [showContactModal, setContactModal] = useState(false);
  const [showSiteAddress, setSiteAddress] = useState(false);
  const [contactData, setContactData] = useState(initialContactData);
  const [selectedIndex, setIndex] = useState('');
  const [runEdit, setRun] = useState(null);
  const [isUpdate, setUpdate] = useState(false);
  const [contactIndex, setContactIndex] = useState('');
  const [menuType, setMenuType] = useState('');
  const [addressData, setAddressData] = useState(initialAddressData);
  const [addressIndex, setAddrIndex] = useState('');
  const [hideClicked, setClicked] = useState(null);
  const [msg, setMsg] = useState('');
  const [clientTxt, setClientTxt] = useState('');
  const [isHideSearch, setHideSearch] = useState(false);
  const [hideClickedAssociated, setHideClkAssociated] = useState(null);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delValue, setDelvalue] = useState();
  const [crossStatus, setCrossStatus] = useState(false);
  const [newstate, setnewState] = useState(true);
  const [clientIdentity, setClientIdentity] = useState('');
  const [permissionData, setPermissionData] = useState([]);
  const [planId, setPlanId] = useState('');
  const [poolData, setPoolData] = useState({});
  const [upgradePopUp, setUpgradePopUp] = useState(false);
  const [openPaymentForm, setOpenPaymentForm] = useState(false);
  const [openCheckOutForm, setCheckOutForm] = useState(false);
  const [payload2, setPayload2] = useState({});
  const [openPosVerify, setOpenPosVerify] = useState(false);
  const [deviceCode, setDeviceCode] = useState('');
  const [paymentResult, setPaymentResult] = useState('');
  const [error, setError] = useState('');
  const [subsTypeName, setSubsTypeName] = useState('Basic Plan');
  const [selectedPage, setSelectedPage] = useState(1);
  const [stateArray, setStateArray] = useState([]);
  const [mobileFormat, setMobileFormat] = useState('### ### ###');

  // const stripePromise = loadStripe('pk_test_51KjgGOSHhBMizAxJbkmFP2RG4SjmsXfhOWsOHrAtCqaBhFXAvDMPkr0X94xVRAN1J8wURdXWKPu6Ub1D2ylrjfy70002aH6XJk');

  const poolFinishArray = [
    'Concrete Pool - Outdoor', 'Fibreglass Pool - Outdoor', 'Vinyl Liner Pool - Outdoor', 'Concrete Pool - Indoor', 'Fibreglass Pool - Indoor', 'Vinyl Liner Pool - Indoor',
  ];
  const chlorinationArray = [
    'Salt Pool', 'Mineral Pool with Chlorinator', 'Convert Salt Pool to Mineral Pool',
    'Manually Chlorinated',
  ];
  const poolTypeArray = [
    'Non-Mineral Pool', 'Convert Pool to Mineral Pool', 'Aquatherepe Transform Maintenance',
  ];
  const convertPoolChlorineArray = [
    'Aquatherepe Transform',
    'Aquatherepe Transform - With ORP Probe',
  ];

  const PopUpText = `Any Additional Contacts and
  their details will be deleted
  with this change.`;

  const paraText = 'Do you want to continue?';

  const [saltChlorinator, setSaltChorinator] = useState([]);
  const [mineralChlorinator, setMineralChlorinator] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [chlorinatorModel, setChlorinatorModel] = useState([]);

  const forSettingChlorinationModel = (saltModel, mineralModel, typeCh, changeStatus) => {
    const {
      selectedModel, updatedSelectedModel, selectedModelObj, chlorinatorModel,
    } = clientData;
    if (typeCh === 'Salt Pool') {
      // setChlorinatorModel(saltModel);
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = saltModel;
      }
      setClientData({
        ...clientData,
        selectedModel: !changeStatus ? selectedModel : saltModel[0].name,
        updatedSelectedModel: !changeStatus ? updatedSelectedModel : saltModel[0].name,
        selectedModelObj: !changeStatus ? selectedModelObj : saltModel[0],
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    } else if (typeCh === 'Mineral Pool with Chlorinator') {
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = mineralModel;
      }
      // setChlorinatorModel(mineralModel);
      setClientData({
        ...clientData,
        selectedModel: !changeStatus ? selectedModel : mineralModel[0].brandName,
        updatedSelectedModel: !changeStatus ? updatedSelectedModel : mineralModel[0].brandName,
        selectedModelObj: !changeStatus ? selectedModelObj : mineralModel[0],
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    } else if (typeCh === 'Convert Salt Pool to Mineral Pool') {
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = convertPoolChlorineArray;
      }
      // setChlorinatorModel(convertPoolChlorineArray);
      setClientData({
        ...clientData,
        selectedModel: !changeStatus ? selectedModel : convertPoolChlorineArray[0],
        updatedSelectedModel: !changeStatus ? updatedSelectedModel : convertPoolChlorineArray[0],
        selectedModelObj: null,
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    } else if (typeCh === 'Manually Chlorinated') {
      let chlorineData = chlorinatorModel;
      if ((!changeStatus && chlorinatorModel.length === 0) || changeStatus) {
        chlorineData = poolTypeArray;
      }
      // setChlorinatorModel(poolTypeArray);
      setClientData({
        ...clientData,
        selectedModel: !changeStatus ? selectedModel : poolTypeArray[0],
        updatedSelectedModel: !changeStatus ? updatedSelectedModel : poolTypeArray[0],
        selectedModelObj: null,
        chlorinationType: typeCh,
        chlorinatorModel: chlorineData,
      });
    }
  };

  const handleSiteAddressCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = addressData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (addressData.mobileNo) {
      obj.mobileNo = `${addressData.mobileNo.replace(textToReplace, '')}`;
    }
    setSiteAddress({ ...addressData, ...obj });
    if (countryCode === '+61') {
      setMobileFormat('### ### ###');
    }
    if (countryCode === '+1') {
      setMobileFormat('### ### ####');
    }
  };

  async function gettingLimitedPermission(subType) {
    const copyUserData = { ...userData };
    const splitData = subType.split(' ')[0].toLowerCase();

    const response = await userInstance().get(`/permission/getSubsPermission?type=${splitData}`);
    const { msg, subPermission } = response?.data;
    if (msg === 'success') {
      setPlanId(subPermission._id);
    }

    const copyCreated = copyUserData.permissions.map((el) => ({ ...el }));
    const filterData = copyCreated.filter((el) => el.level.includes(splitData));
    setPermissionData(filterData);
  }

  useEffect(async () => {
    const newresponse = await userInstance().get('/poolmineral/getAllPoolMinerals');
    const mineralList = newresponse?.data?.mineralList;
    if (mineralList) {
      setPoolData(mineralList);
      const saltChorinatorModel = mineralList.chlorinatorModels;
      const mineralChlorinatorModel = mineralList.calciumAdjustments;
      setSaltChorinator(saltChorinatorModel);
      setMineralChlorinator(mineralChlorinatorModel);
      forSettingChlorinationModel(saltChorinatorModel, mineralChlorinatorModel, clientData.chlorinationType);
      gettingLimitedPermission(clientData?.subscriptionType);
    }
  }, []);

  const handlePopUpChange = (val) => {
    if (val === 'Yes') {
      setClientData({ ...clientData, customerType: delValue });
    }
    setDeletePopUp(false);
  };
  const editCustomerType = (value) => {
    setDeletePopUp(true);
    setDelvalue(value);
  };

  const handleContactModal = (index, _id, addrIndex) => {
    setContactData(initialContactData);
    setContactModal(!showContactModal);
    if (index !== undefined) setIndex(index);
    if (_id) setClientId(_id);
    if (addrIndex !== undefined) {
      setMenuType('site');
      setAddrIndex(addrIndex);
    } else {
      setMenuType('associated');
    }
  };
  const handleSiteAddressModal = (index, _id) => {
    setAddressData(initialAddressData);
    setSiteAddress(!showSiteAddress);
    setMenuType('site');
    if (index !== undefined) setIndex(index);
    if (_id) setClientId(_id);
  };

  const handleContactData = (e) => {
    if (e.target.name === 'name' || e.target.name === 'lastName') {
      if (e.target.value && !(nameRegex.test(e.target.value))) {
        return;
      }
    }
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };
  const handleAddressData = (e) => {
    if (e.target.name === 'householderName') {
      if (e.target.value && !(nameRegex.test(e.target.value))) {
        return;
      }
    }
    setAddressData({ ...addressData, [e.target.name]: e.target.value });
  };

  const handleAddBillingContact = (e) => {
    e.preventDefault();
    let message = '';
    const {
      name, lastName, mobileNo, email,
    } = contactData;
    const validationObj = {
      'first name': name?.trim(),
      'last name': lastName?.trim(),
      'mobile no': mobileNo,
    };
    const { isValid, emptyField } = validateDataWithKey(validationObj);

    if (isValid) {
      let isMobileValid = true;
      let isLandlineValid = true;
      let mobileMsg = '';
      let landlineMsg = '';
      if (selectedCountry === '+61') {
        if (contactData.mobileNo.charAt(0) === '0') {
          contactData.mobileNo = contactData.mobileNo.replace('0', '');
        }
        if (contactData.landlineNo.charAt(0) === '0') {
          contactData.landlineNo = contactData.landlineNo.replace('0', '');
        }
        // console.log('landlineNo', clientData.landlineNo.length);
        // if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
        //   isLandlineValid = false;
        //   landlineMsg = 'Please enter area code, followed by phone no.';
        //   setError('landlineno');
        // }
        if (contactData.mobileNo.charAt(0) !== '4') {
          // console.log('contactData.mobileNo.charAt(4)', contactData.mobileNo.charAt(4));
          isMobileValid = false;
          setError('mobileno');
          toastMessage('error', 'Please start mobile number with 4');
        }
        if (contactData.mobileNo.trim().length !== 11) {
          setError('mobileno');
          isMobileValid = false;
          // mobileMsg = 'Invalid Mobile Number';
          toastMessage('error', 'Enter 9 Digit Mobile Number');
        }
        // contactData.mobileNo = `+61 ${mobileNo}`;
      }
      if (selectedCountry === '+1') {
        if (contactData.mobileNo.charAt(0) === '0') {
          contactData.mobileNo = contactData.mobileNo.replace('0', '');
        }
        // if (contactData.landlineNo.charAt(0) === '0') {
        //   contactData.landlineNo = contactData.landlineNo.replace('0', '');
        // }

        // if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
        //   isLandlineValid = false;
        //   landlineMsg = 'Please enter area code, followed by phone no.';
        //   setError('landlineno');
        // }
        if (contactData.mobileNo.trim().length !== 12) {
          setError('mobileno');
          isMobileValid = false;
          toastMessage('error', 'Enter 10 Digit Mobile Number');
        }
        // contactData.mobileNo = `+1 ${mobileNo}`;
      }
      // console.log('isMobileValid', isMobileValid);
      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      // console.log('client', client);
      const propertyName = menuType === 'site' ? 'siteAddresses' : 'associatedContacts';
      const contacts = client[propertyName];
      if (isMobileValid) {
        if (menuType === 'associated') {
          if (isUpdate) {
            let selectedContact = contacts.length ? contacts[contactIndex] : null;
            if (selectedContact) {
              contactData.mobileNo = `${selectedCountry} ${contactData.mobileNo}`;
              selectedContact = contactData;
              contacts[contactIndex] = selectedContact;
            }
            message = 'Contact updated';
          } else {
            contactData.mobileNo = `${selectedCountry} ${contactData.mobileNo}`;
            message = 'Contact added';
            contacts.push(contactData);
          }
        } else if (menuType === 'site') {
          const existingContacts = contacts[addressIndex].nestedContacts;
          if (isUpdate) {
            existingContacts[contactIndex] = contactData;
            message = 'Contact updated';
          } else {
            message = 'Contact added';
            existingContacts.push(contactData);
          }
          let selectedAddress = contacts[addressIndex];
          selectedAddress.nestedContacts = existingContacts;
          contacts[addressIndex] = selectedAddress;
        }
        client[propertyName] = contacts;
        setClientList([...clientListCopy]);
        setRun(true);
        setContactModal(!showContactModal);
        setUpdate(false);
      }
    } else {
      setError(emptyField?.split(' ').join('').toLowerCase());
      toastMessage('error', `Please fill ${emptyField}.`);
    }
    setTimeout(() => {
      setError('');
    }, errorBorderTime);
    setMsg(message);
  };

  const handleEditContact = (data, _id, index, contactI, addrIndex) => {
    let copySelectedCountry = selectedCountry;
    if (data?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(data.mobileNo);
      data.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }

    if (data?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(data.landlineNo);
      data.landlineNo = updatedNo;
    }

    setContactData(data);
    setClientId(_id);
    setIndex(index);
    setUpdate(true);
    setCountry(copySelectedCountry);
    if (contactI !== undefined) setContactIndex(contactI);
    if (addrIndex !== undefined) {
      setMenuType('site');
      setAddrIndex(addrIndex);
    } else {
      setMenuType('associated');
    }
    setContactModal(!showContactModal);
  };

  const [claddressIndex, setClAddressIndex] = useState('');

  const checkAddress = (index, _id, addrId) => {
    setClientId(_id);
    setIndex(index);
    setClAddressIndex(addrId);
    const clientListCopy = clientList;
    const selectedClient = clientListCopy[index];
    if (selectedClient.siteAddresses) {
      selectedClient.siteAddresses.forEach((el, i) => {
        if (i === addrId) {
          const val = el.default !== true;
          el.default = val;
        }
      });
    }
    setClientList([...clientListCopy]);
    setMsg('Site address selected successfully');
    setRun(true);
  };

  const handleDeleteContact = (_id, index, contactI, type, addrIndex) => {
    setClientId(_id);
    setIndex(index);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const propertyName = type === 'site' ? 'siteAddresses' : 'associatedContacts';
    const contacts = client[propertyName] ? client[propertyName] : [];
    if (type === 'contact') {
      contacts.splice(contactI, 1);
    } else if (type === 'site') {
      const existingContacts = contacts[addrIndex].nestedContacts;
      existingContacts.splice(contactI, 1);
    }
    client[propertyName] = contacts;
    setClientList([...clientListCopy]);
    setMsg('Contact deleted');
    setRun(true);
  };

  const handleAddAddress = (e) => {
    e.preventDefault();
    let message = '';
    const {
      householderName, address, mobileNo, poolHeight, poolWidth,
    } = addressData;
    const validationObj = {
      'householder name': householderName?.trim(),
      address,
      'mobile no': mobileNo,
    };
    const { isValid, emptyField } = validateDataWithKey(validationObj);
    if (isValid) {
      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      const addresses = client.siteAddresses ? client.siteAddresses : [];
      let isMobileValid = true;
      let mobileMsg = '';
      let landlineMsg = '';
      // console.log('landlineNo', addressData.mobileNo);
      if (selectedCountry === '+61') {
        if (addressData.mobileNo.charAt(0) === '0') {
          addressData.mobileNo = addressData.mobileNo.replace('0', '');
        }

        if (addressData.mobileNo.charAt(0) !== '4') {
          // console.log('addressData.mobileNo.charAt(4)', addressData.mobileNo.charAt(4));
          isMobileValid = false;
          setError('mobileno');
          toastMessage('error', 'Please start mobile number with 4');
        }
        if (addressData.mobileNo.trim().length !== 11) {
          setError('mobileno');
          isMobileValid = false;
          // mobileMsg = 'Invalid Mobile Number';
          toastMessage('error', 'Enter 9 Digit Mobile Number');
        }
        // addressData.mobileNo = `+61 ${addressData.mobileNo}`;
      }
      if (selectedCountry === '+1') {
        if (addressData.mobileNo.charAt(0) === '0') {
          addressData.mobileNo = addressData.mobileNo.replace('0', '');
        }
        if (addressData.mobileNo.trim().length !== 12) {
          setError('mobileno');
          isMobileValid = false;
          toastMessage('error', 'Enter 10 Digit Mobile Number');
        }
        // addressData.mobileNo = `${selectedCountry} ${addressData.mobileNo}`;
      }
      // if (addressData?.mobileNo) {
      //   addressData.mobileNo = `${selectedCountry} ${addressData.mobileNo}`;
      // }
      if (isMobileValid) {
        if (isUpdate) {
          let selectedContact = addresses.length ? addresses[addressIndex] : null;
          addressData.mobileNo = `${selectedCountry} ${addressData.mobileNo}`;
          if (selectedContact) {
            selectedContact = addressData;
            addresses[addressIndex] = selectedContact;
          }
          message = 'Address updated';
        } else {
          addressData.mobileNo = `${selectedCountry} ${addressData.mobileNo}`;
          message = 'Address added';
          addresses.push(addressData);
        }
        client.siteAddresses = addresses;
        setClientList([...clientListCopy]);
        setRun(true);
        setSiteAddress(!showSiteAddress);
        setUpdate(false);
      }
    } else {
      setError(emptyField?.split(' ').join('').toLowerCase());
      toastMessage('error', `Please fill ${emptyField}.`);
    }
    setTimeout(() => {
      setError('');
    }, errorBorderTime);
    setMsg(message);
  };

  const handleEditAddress = (data, _id, index, addrIndex) => {
    let copySelectedCountry = selectedCountry;
    if (data?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(data.mobileNo);
      data.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }

    if (data?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(data.landlineNo);
      data.landlineNo = updatedNo;
    }

    setCountry(copySelectedCountry);

    setAddressData(data);
    setClientId(_id);
    setIndex(index);
    setUpdate(true);
    setAddrIndex(addrIndex);
    setSiteAddress(!showSiteAddress);
    setMenuType('site');
  };

  const handleDeleteAddress = (_id, index, addrIndex) => {
    setClientId(_id);
    setIndex(index);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const addresses = client.siteAddresses ? client.siteAddresses : [];
    addresses.splice(addrIndex, 1);
    client.siteAddressess = addresses;
    setClientList([...clientListCopy]);
    setMsg('Address deleted');
    setRun(true);
  };

  const handleVisibilityAddr = (index, _id, addrIndex, type) => {
    setIndex(index);
    setClientId(_id);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    const addresses = client[type];
    const currentAddress = addresses[addrIndex];
    currentAddress.hide = !currentAddress.hide;
    currentAddress.tempShow = type === 'associatedContacts' ? hideClickedAssociated : hideClicked;
    client[type] = addresses;
    setClientList([...clientListCopy]);
    setMsg('Visibility updated');
    setRun(true);
  };

  const showAll = (index, type) => {
    const value = !(type === 'siteContacts' || type === 'siteAddresses' ? hideClicked : hideClickedAssociated);
    if (type === 'associatedContacts') {
      setHideClkAssociated(value);
    } else {
      setClicked(value);
    }
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    client[type === 'siteAddresses' ? type : 'associatedContacts'].forEach((el) => {
      el.tempShow = value;
    });
    setClientList([...clientListCopy]);
  };

  const hideAll = (index, _id, type) => {
    setIndex(index);
    setClientId(_id);
    const clientListCopy = clientList;
    const client = clientListCopy[index];
    client[type].forEach((el) => {
      el.hide = true;
      el.tempShow = hideClicked;
    });
    setClientList([...clientListCopy]);
    setMsg('Visibility updated');
    setRun(true);
  };

  const HandleOpenCloseTable = (staff) => {
    setShowTable(!showTable);
    if (staff) {
      setClientId(staff._id);
      const newShift = convertDate(staff.shift);
      setClientData({ ...clientData, ...staff, shift: newShift });
    }
    if (showTable === true) {
      // setEditWorking(false);
    }
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };

  const getClientList = async () => {
    try {
      const response = await userInstance().get(`/subscribers/getAllSubscriber?limit=${limit}&skip=${skip}`);
      // console.log('response ===>>>>', response);
      const list = response.data.returnedArray;
      list.sort((a, b) => a.firstname.localeCompare(b.firstname));
      setPageCounts(response.data.count);
      setClientList(list);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error=>', error);
    }
  };

  const nextpage = async () => {
    setSkip(skip + 1);
  };
  const previousPage = async () => {
    setSkip(skip - 1);
  };

  const searchClient = async (value) => {
    try {
      setClientTxt(value);
      const response = await userInstance().get(`/subscribers/searchSubscriber?value=${value}`);
      let list = response.data.customers;
      if (value) {
        if (isHideSearch) {
          list.forEach((client) => {
            if (client.sideAddresses) {
              client.sideAddresses.forEach((addr) => {
                addr.hide = false;
              });
            }
          });
        } else {
          list = list.filter((el) => (el.siteAddresses ? el.siteAddresses.filter((adr) => adr.hide).length === 0 : el));
        }
      }
      list.sort((a, b) => a.firstname.localeCompare(b.firstname));
      // console.log('response', response);
      setClientList(list);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = clientData.mobileNo.includes('+61') ? '+61' : '+1';
    const obj = {};
    if (clientData.mobileNo) {
      obj.mobileNo = `${clientData.mobileNo.replace(textToReplace, '')}`;
    }
    if (clientData.landlineNo) {
      obj.landlineNo = `${clientData.landlineNo.replace(textToReplace, '')}`;
    }
    setClientData({ ...clientData, ...obj });

    if (countryCode === '+61') {
      setMobileFormat('### ### ###');
    }
    if (countryCode === '+1') {
      setMobileFormat('### ### ####');
    }
  };

  const convertDate = (shift) => {
    const newShift = [];
    shift.forEach((el) => {
      if (el) {
        newShift.push({ ...el, date: new Date(new Date(el.date).toDateString()) });
      }
    });
    return newShift;
  };
  const handleDateState = (start, end) => {
    setClientData({ ...clientData, rangeStartDate: start, rangeEndDate: end });
  };
  const handleSubscription = (e) => {
    let { value } = e.target;
    setSubsTypeName(value);
    setUpgradePopUp(true);
  };
  const handleChangeUsers = (e) => {
    const { name } = e.target;
    let { value } = e.target;
    value = Number(value);

    let annnumCost;
    let monthlyCost;
    if (clientData.subscriptionType === 'Basic Plan') {
      annnumCost = Number((((value - 1) * 50) + 150) * 12);
      monthlyCost = Number(((value - 1) * 50) + 150);
    } else if (clientData.subscriptionType === 'Standard Plan') {
      annnumCost = Number((((value - 3) * 50) + 200) * 12);
      monthlyCost = Number(((value - 3) * 50) + 200);
    } else if (clientData.subscriptionType === 'Premium Plan') {
      annnumCost = Number((((value - 3) * 50) + 250) * 12);
      monthlyCost = Number(((value - 3) * 50) + 250);
    }

    if (name === 'customerType' && selectedClient) {
      const client = clientList.filter((el) => el._id === selectedClient);
      if (client[0].customerType === 'Householder' && value !== 'Householder' || client[0].customerType !== 'Householder' && value === 'Householder') {
        editCustomerType(value);
      } else {
        setClientData({ ...clientData, [name]: value });
      }
    } else {
      setClientData({
        ...clientData, [name]: value, monthlyCost, annnumCost,
      });
    }
  };

  const handleCountryStates = (compareValue) => {
    const fIndex = countries.findIndex((el) => el.name === compareValue);
    if (fIndex >= 0) {
      const copyStates = JSON.parse(JSON.stringify(states));
      const filteredArray = copyStates.filter((el) => el.countryCode === countries[fIndex].isoCode);
      const list = filteredArray.sort((a, b) => a.name.localeCompare(b.name));
      setCountry(`+${countries[fIndex].phonecode}`);
      setStateArray(filteredArray);
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;

    let { value } = e.target;

    if (name === 'firstname' || name === 'lastname') {
      if (value && !(nameRegex.test(value))) {
        return;
      }
    }

    if (name === 'postcode') {
      if (value && !(postCodeRegex.test(value))) {
        return;
      }
    }

    if (name === 'gender') {
      value = e.target.id;
    }
    if (name === 'hide') {
      value = JSON.parse(e.target.value);
    }
    if (name === 'numberUsers') {
      value = Number(value);
    }
    const copy = { ...clientData };
    if (name === 'country') {
      if (value === 'Australia') {
        setMobileFormat('### ### ###');
      }
      if (value === 'United States') {
        setMobileFormat('### ### ####');
      }
      handleCountryStates(value);
      copy.state = '';
    }

    const validateConditionalFields = () => {
      if (name === 'postcode' && value.length >= 10) {
        return false;
      }
      copy[name] = value;
      if (name === 'billingCycle') {
        copy.costDropDown = value === 'Annual' ? 'per annum' : 'per month';
      }
      if (name === 'costDropDown') {
        copy.billingCycle = value === 'per annum' ? 'Annual' : 'Monthly';
      }
      return true;
    };

    if (name === 'customerType' && selectedClient) {
      const client = clientList.filter((el) => el._id === selectedClient);
      if (client[0].customerType === 'Householder' && value !== 'Householder' || client[0].customerType !== 'Householder' && value === 'Householder') {
        editCustomerType(value);
        return;
      }
    }
    if (!validateConditionalFields()) return;
    // let mobNum = true;
    // if (name === 'mobileNo' && value.length < 9) {
    //   mobNum = false;
    // }

    setClientData(copy);
  };
  const handleClosePaymentForm = () => {
    setPaymentResult('');
    setOpenPaymentForm(false);
  };
  const creatingSubscriberAfterpayment = async () => {
    const payload = { ...clientData };
    if (selectedCountry === '+61') {
      if (payload.mobileNo.charAt(0) === '0') {
        payload.mobileNo = payload.mobileNo.replace('0', '');
      }
      if (payload.landlineNo.charAt(0) === '0') {
        payload.landlineNo = payload.landlineNo.replace('0', '');
      }
    }

    payload.permissions = permissionData;
    payload.planId = planId;
    payload.email = payload.email.toLowerCase();
    payload.industryId = userData?.industryId._id;
    if (payload?.landlineNo) {
      payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
    }
    if (payload?.mobileNo) {
      payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
    }

    setPayload2(payload);
    const responseUser = await userInstance().post('/user/registerSubscriber', payload);
    setShowForm(false);
    getClientList();
    toastMessage('success', 'Subscriber Added Successfully');
    setPaymentResult('');
    const { msg, code, userid } = responseUser.data;
    setOpenPaymentForm(false);
    delete poolData._id;
    // if (userid)0 {
    const paylaod = {
      userid,
      planId,
      role: 'subscriber',
    };
    const subsDataResponse = await userInstance().post('/subscribers/addNewSubscriberData', paylaod);
  };
  const handleToken = async (token) => {
    if (token) {
      const amountData = clientData?.billingCycle === 'Monthly' ? clientData?.monthlyCost : clientData?.annnumCost;
      const payload = {
        sourceId: token.token,
        amountMoney: {
          currency: 'USD',
          amount: amountData * 100,
        },
      };
      const response = await userInstance().post('/subscribers/subscriberPayment', payload);
      if (response.data.code === 200) {
        setPaymentResult('Payment Successful');
        creatingSubscriberAfterpayment();
      } else {
        setPaymentResult('Payment Successful');
      }
      // console.log('response', response);
      // alert(JSON.stringify(response.data.resultData));
    }
  };
  const handleAddCustomer = async (e) => {
    e.preventDefault();
    // setOpenPaymentForm(true);
    const {
      customerType,
      companyName,
      firstname,
      lastname,
      mobileNo,
      email,
      streetAddress,
      suburb,
      state,
      country,
      invoiceSendType,
      postcode,
    } = clientData;
    const payload = { ...clientData };
    try {
      const validationObj = {
        'customer type': customerType,
        country,
        'first name': firstname?.trim(),
        'last name': lastname?.trim(),
        'mobile no': mobileNo,
        email,
        'street address': streetAddress,
        suburb,
        'postal/zip code': postcode,
        state,

      };
      if (customerType !== 'Householder') {
        validationObj[`${customerType} name`] = companyName;
      }

      validationObj['invoice sending type'] = invoiceSendType;

      let { isValid, emptyField } = validateDataWithKey(validationObj);

      let emailValid = true;
      if (email) {
        if (emailRegex.test(String(clientData.email).toLowerCase())) {
          emailValid = true;
        } else {
          emailValid = false;
        }
      }

      if (isValid) {
        let isMobileValid = true;
        let isLandlineValid = true;
        let mobileMsg = '';
        let landlineMsg = '';
        let isSubsciptionPeriod = true;
        let subscriptionPeriodMsg = '';

        if (clientData.rangeStartDate > clientData.rangeEndDate) {
          isSubsciptionPeriod = false;
          subscriptionPeriodMsg = 'Subscription period From date should less than Subscription Period To date.';
          toastMessage('error', subscriptionPeriodMsg);
          return;
        }

        if (selectedCountry === '+61') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 11) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 9 Digit Mobile Number';
          }
        }
        if (selectedCountry === '+1') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          // if (payload.mobileNo.charAt(0) !== '4') {
          //   isMobileValid = false;
          //   mobileMsg = 'Please start mobile number with 4';
          //   setError('mobileno');
          // }
          if (payload.mobileNo.trim().length !== 12) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 10 Digit Mobile Number';
          }
        }

        if (isLandlineValid) {
          if (payload?.landlineNo) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }

          if (emailValid) {
            if (isMobileValid) {
              if (payload?.mobileNo) {
                payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
              }
              const emailExist = await userInstance().post('/user/checkUserMail', { email });
              if (emailExist.data.code === 200) {
                setOpenPaymentForm(true);
                setError('');
              } else {
                setError('email');
                toastMessage('error', 'Email already register.');
              }

              // console.log('payload', payload);

              // const splitD = payload?.subscriptionType.split(' ')[0].toLowerCase();
              // permissionData.forEach((el) => {
              //   if (el.level) {
              //     el.level = [splitD, 'owner'];
              //   }
              // });
              // console.log('filtereData', permissionData, splitD, customerType);

              // payload.permissions = permissionData;
              // payload.planId = planId;
              // payload.email = payload.email.toLowerCase();
              // payload.industryId = userData?.industryId._id;

              // setPayload2(payload);
              // const responseUser = await userInstance().post('/user/registerSubscriber', payload);
              // setShowForm(false);
              // getClientList();
              // NotificationManager.success('Subscriber Added Successfully', 'Message', successMsgTime);
              // console.log('responseUSer', responseUser);
              // const { msg, code, userid } = responseUser.data;
              // delete poolData._id;
              // console.log('poolData', poolData);
              // // if (userid)0 {
              // const paylaod = {
              //   userid,
              //   planId,
              //   poolData,
              //   role: 'subscriber',
              // };

              // console.log('payloaf', poolData);
              // const subsDataResponse = await userInstance().post('/subscribers/addNewSubscriberData', paylaod);
              // console.log('response==jlehfh>>', subsDataResponse);
            } else if (!isMobileValid) {
              setError('mobileno');
              toastMessage('error', mobileMsg);
            }
          } else {
            setError('email');
            toastMessage('error', 'Email is Invalid!');
          }
        } else {
          toastMessage('error', landlineMsg);
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (error) {
      const { msg } = error?.response?.data;
      toastMessage('error', msg);
    }
  };
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  const handleFormModal = () => {
    handleCountryStates(clientData?.country);
    setClientData({ ...initialClientData });
    setClientId('');
    setShowForm(!showForm);
  };

  const handleEditClient = (staff) => {
    gettingLimitedPermission(staff?.subscriptionType);
    setClientId(staff._id);
    setShowForm(true);

    let copySelectedCountry = selectedCountry;
    if (staff?.mobileNo) {
      const { updatedNo, countryCode } = removeCodeFromNumber(staff.mobileNo);
      staff.mobileNo = updatedNo;
      copySelectedCountry = countryCode;
    }
    if (staff?.landlineNo) {
      const { updatedNo } = removeCodeFromNumber(staff.landlineNo);
      staff.landlineNo = updatedNo;
    }
    // const upStaff = {...staff}
    setCountry(copySelectedCountry);

    setClientData({ ...clientData, ...staff });
    // console.log(selectedClient);
  };

  const editClient = async (e) => {
    e.preventDefault();
    const {
      customerType,
      companyName,
      firstname,
      lastname,
      mobileNo,
      landlineNo,
      email,
      billingAddressType,
      billingAddress,
      streetAddress,
      state,
      suburb,
      country,
      invoiceSendType,
      postcode,
    } = clientData;

    // console.log('suburb', suburb);
    let name = [firstname, lastname].join(' ');
    const payload = { ...clientData, name };
    try {
      const validationObj = {
        'customer type': customerType,
        country,
        'first name': firstname?.trim(),
        'last name': lastname?.trim(),
        'mobile no': mobileNo,
        email,
        'street address': streetAddress,
        suburb,
        'postal/zip code': postcode,
        state,
        // 'landline no': landlineNo,
        // email,
      };
      // console.log('Step 1');
      // console.log('bilingAddress Type', billingAddressType);

      if (billingAddressType === 'same') {
        validationObj['street address'] = streetAddress;
        payload.billingAddress = streetAddress;
      } else if (billingAddressType === 'different') {
        validationObj['billing address'] = billingAddress;
      }

      if (customerType !== 'Householder') {
        validationObj['company name'] = companyName;
      }
      validationObj['invoice sending type'] = invoiceSendType;
      let { isValid, emptyField } = validateDataWithKey(validationObj);
      let emailValid = true;
      if (email) {
        if (emailRegex.test(String(clientData.email).toLowerCase())) {
          emailValid = true;
        } else {
          emailValid = false;
        }
      }
      if (isValid) {
        // if (emailRegex.test(String(clientData.email).toLowerCase())) {
        let isMobileValid = true;
        let isLandlineValid = true;
        let mobileMsg = '';
        let landlineMsg = '';
        if (selectedCountry === '+61') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }

          if (payload.mobileNo.trim().length !== 11) {
            setError('mobileno');
            isMobileValid = false;
            mobileMsg = 'Invalid Mobile Number';
          }
        }
        if (selectedCountry === '+1') {
          if (payload.mobileNo.charAt(0) === '0') {
            payload.mobileNo = payload.mobileNo.replace('0', '');
          }
          if (payload.landlineNo.charAt(0) === '0') {
            payload.landlineNo = payload.landlineNo.replace('0', '');
          }

          if (clientData.landlineNo && clientData.landlineNo.trim().length !== 11) {
            isLandlineValid = false;
            landlineMsg = 'Please enter area code, followed by phone no.';
            setError('landlineno');
          }
          if (payload.mobileNo.charAt(0) !== '4') {
            isMobileValid = false;
            mobileMsg = 'Please start mobile number with 4';
            setError('mobileno');
          }
          if (payload.mobileNo.trim().length !== 12) {
            setError('mobileno');
            isMobileValid = false;
            // mobileMsg = 'Invalid Mobile Number';
            mobileMsg = 'Enter 10 Digit Mobile Number';
          }
        }
        if (isLandlineValid) {
          if (payload?.landlineNo) {
            payload.landlineNo = `${selectedCountry} ${payload.landlineNo}`;
          }
          if (emailValid) {
            if (isMobileValid) {
              if (payload?.mobileNo) {
                payload.mobileNo = `${selectedCountry} ${payload.mobileNo}`;
              }
              // const splitD = payload?.subscriptionType.split(' ')[0].toLowerCase();
              // permissionData.forEach((el) => {
              //   if (el.level) {
              //     el.level = [splitD, 'owner'];
              //   }
              // });
              // console.log('filtereData', permissionData, splitD, customerType);
              // payload.permissions = permissionData;

              payload.planId = planId;
              payload.email = payload.email.toLowerCase();
              delete payload.location;
              // console.log('payload', payload);
              const response = await userInstance().put('/subscribers/updateSubscriber', { ...payload, selectedClient });
              const { msg, status } = response.data;
              // console.log('this is the reponse', response);
              setShowForm(false);
              getClientList();
              setError('');
              if (response?.status === 200) {
                toastMessage('success', 'Subscriber Updated Successfull');
              }
            } else if (!isMobileValid) {
              setError('mobileno');
              toastMessage('error', mobileMsg);
            }
          } else {
            setError('email');
            toastMessage('error', 'Email is not Valid.');
          }
        } else {
          toastMessage('error', landlineMsg);
        }
      } else {
        setError(emptyField?.split(' ').join('').toLowerCase());
        toastMessage('error', `Please fill ${emptyField}.`);
      }
      setTimeout(() => {
        setError('');
      }, 1000);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const editSubfields = async () => {
    try {
      const clientListCopy = clientList;
      const client = clientListCopy[selectedIndex];
      client.siteAddresses.forEach((el) => {
        delete el.tempShow;
      });
      client.associatedContacts.forEach((el) => {
        delete el.tempShow;
      });
      const { associatedContacts, siteAddresses } = client;
      await userInstance().put('/subscribers/updateSubfields', {
        associatedContacts,
        selectedClient,
        siteAddresses,
      });
      toastMessage('success', msg);
      client.siteAddresses.forEach((el) => {
        el.tempShow = hideClicked;
      });
      client.associatedContacts.forEach((el) => {
        el.tempShow = hideClickedAssociated;
      });
      setClientList([...clientListCopy]);
      setRun(false);
      // getClientList();
    } catch (error) {
      console.log(error);
      setRun(false);
      console.log(error);
    }
  };

  const removeClient = async (id) => {
    try {
      console.log('delete', id);
      const response = await userInstance().delete(`/subscribers/deleteSubscriber?id=${id}`);
      getClientList();
      setnewState(!newstate);
      setClientIdentity(false);
      toastMessage('success', 'Client deleted successfully');
    } catch (error) {
      console.log(error);
    }
  };

  const setBooking = () => {
    setClientData({ ...clientData, showBookings: !clientData.showBookings });
  };
  const backToAppointment = () => {
    if (typee && crossStatus && back) {
      history.push(`/addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&clientid=${clientid}`);
    } else if (typee) {
      let oldUrl = getCalendarPath();
      const newUrl = `${oldUrl}&clientid=${clientid}`;
      if (typee === 'edit') localStorage.setItem('idClient', clientid);
      history.push(newUrl);
    }
  };

  const handleResendLogin = async (clId) => {
    try {
      const response = await userInstance().put('/staff/resendPassword', {
        staffid: clId,
      });
      toastMessage('success', response.data.msg);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (runEdit === null) {
      setLoading(true);
      getClientList();
    }
    if (runEdit === true) {
      editSubfields();
    }
  }, [skip, runEdit]);

  const handleUpgradePopUp = () => {
    // setOpenPaymentForm(true);
    let value = subsTypeName;
    let name = 'subscriptionType';
    let numberUsers = Number(clientData.numberUsers);
    let annnumCost = Number(clientData.annnumCost);
    let monthlyCost = Number(clientData.monthlyCost);

    let usersDisplay;
    if (name === 'subscriptionType') {
      gettingLimitedPermission(value);
      if (value === 'Basic Plan') {
        if (numberUsers === 3) {
          numberUsers = 2;
        }
        usersDisplay = 2;
      } else if (value === 'Standard Plan') {
        if (numberUsers === 2) {
          numberUsers = 3;
        }
        usersDisplay = 3;
      } else if (value === 'Premium Plan') {
        if (numberUsers === 2) {
          numberUsers = 3;
        }
        usersDisplay = 3;
      }
    }
    const valueNumber = numberUsers > usersDisplay ? numberUsers - usersDisplay : 0;
    if (value === 'Basic Plan') {
      annnumCost = Number((((valueNumber) * 50) + 150) * 12);
      monthlyCost = Number(((valueNumber) * 50) + 150);
      // console.log({
      //   annnumCost, monthlyCost, value, valueNumber,
      // });
    } else if (value === 'Standard Plan') {
      annnumCost = Number((((valueNumber) * 50) + 200) * 12);
      monthlyCost = Number(((valueNumber) * 50) + 200);
      // console.log({
      //   annnumCost, monthlyCost, value, valueNumber,
      // });
    } else if (value === 'Premium Plan') {
      annnumCost = Number((((valueNumber) * 50) + 250) * 12);
      monthlyCost = Number(((valueNumber) * 50) + 250);
      // console.log({
      //   annnumCost, monthlyCost, value, valueNumber,
      // });
    }

    if (name === 'customerType' && selectedClient) {
      const client = clientList.filter((el) => el._id === selectedClient);
      if (client[0].customerType === 'Householder' && value !== 'Householder' || client[0].customerType !== 'Householder' && value === 'Householder') {
        editCustomerType(value);
      } else {
        setClientData({ ...clientData, [name]: value });
      }
    } else {
      setClientData({
        ...clientData, [name]: value, numberUsers, usersDisplay, monthlyCost, annnumCost,
      });
    }
    setUpgradePopUp(false);
  };

  useEffect(() => {
    if (error) {
      let element = document.querySelector('.error-text');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [error]);

  const pageCount = Math.ceil(pageCounts / limit);
  return (
    <>
      <AdminWrapper>

        {loading ? <Loader /> : (
          <div className=" client-container subcription-page">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <SubscriptionTable
                    clientList={clientList}
                    HandleOpenCloseTable={HandleOpenCloseTable}
                    handleFormModal={handleFormModal}
                    handleEditClient={handleEditClient}
                    removeClient={removeClient}
                    setShowHide={setShowHide}
                    showHide={showHide}
                    searchClient={searchClient}
                    setSearchData={setSearchData}
                    searchData={searchData}
                    handleContactModal={handleContactModal}
                    handleEditContact={handleEditContact}
                    handleDeleteContact={handleDeleteContact}
                    handleSiteAddressModal={handleSiteAddressModal}
                    handleEditAddress={handleEditAddress}
                    handleDeleteAddress={handleDeleteAddress}
                    handleVisibilityAddr={handleVisibilityAddr}
                    showAll={showAll}
                    hideClicked={hideClicked}
                    hideAll={hideAll}
                    clientTxt={clientTxt}
                    setClientTxt={setClientTxt}
                    setHideSearch={setHideSearch}
                    isHideSearch={isHideSearch}
                    hideClickedAssociated={hideClickedAssociated}
                    checkAddress={checkAddress}
                    selectedClient={selectedClient}
                    claddressIndex={claddressIndex}
                    selectedIndex={selectedIndex}
                    name={name}
                    backToAppointment={backToAppointment}
                    setCrossStatus={setCrossStatus}
                    crossStatus={crossStatus}
                    newstate={newstate}
                    setnewState={setnewState}
                    setClientIdentity={setClientIdentity}
                    handleResendLogin={handleResendLogin}
                  />
                  <SubscriptionForm
                    HandleOpenCloseTable={HandleOpenCloseTable}
                    handleChange={handleChange}
                    handleSubscription={handleSubscription}
                    handleChangeUsers={handleChangeUsers}
                    handleDateState={handleDateState}
                    clientDetails={clientData}
                    handleAddCustomer={handleAddCustomer}
                    showForm={showForm}
                    handleFormModal={handleFormModal}
                    selectedClient={selectedClient}
                    editClient={editClient}
                    handleCountry={handleCountry}
                    selectedCountry={selectedCountry}
                    setBooking={setBooking}
                    poolFinishArray={poolFinishArray}
                    poolTypeArray={poolTypeArray}
                    convertPoolChlorineArray={convertPoolChlorineArray}
                    chlorinationArray={chlorinationArray}
                    error={error}
                    stateArray={stateArray}
                    countries={countries}
                    mobileFormat={mobileFormat}
                  />
                  {/* <div className="view-btn">
                  <Button type="button" className="btn add-btn" onClick={handleFormModal}>
                    Add Client
                  </Button>
                </div> */}

                  {deletePopUp && (
                  <DeletePopUp
                  // additionalContactDetails={additionalContactDetails}
                    handlePopUpChange={handlePopUpChange}
                    setDeletePopUp={setDeletePopUp}
                  // deletePopUp={deletePopUp}
                    PopUpText={PopUpText}
                    paraText={paraText}
                  />
                  )}

                  {clientList && !clientIdentity ? (
                    <div className="user-pagination">
                      <ReactPaginate
                        previousLabel="← Previous"
                        nextLabel="Next →"
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName="pagination-chk"
                        previousLinkClassName="pagination__link"
                        nextLinkClassName="pagination__link"
                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                        forcePage={selectedPage - 1}
                        renderOnZeroPageCount={null}
                      />
                      { clientList && clientList.length > 0 ? (
                        <div className="no-of-page">

                          {' '}
                          Page
                          {' '}
                          {selectedPage}
                          {' '}
                          of
                          {' '}
                          {' '}
                          {pageCount}
                        </div>
                      ) : null}

                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <AddSubsSiteAddress
              handleSiteAddressModal={handleSiteAddressModal}
              showSiteAddress={showSiteAddress}
              addressData={addressData}
              handleAddressData={handleAddressData}
              handleAddAddress={handleAddAddress}
              selectedCountry={selectedCountry}
              handleSiteAddressCountry={handleSiteAddressCountry}
              error={error}
              mobileFormat={mobileFormat}
            />

            <AddBilingContact
              showContactModal={showContactModal}
              handleContactModal={handleContactModal}
              handleContactData={handleContactData}
              handleAddBillingContact={handleAddBillingContact}
              contactData={contactData}
              error={error}
              selectedCountry={selectedCountry}
              handleCountry={handleCountry}
              mobileFormat={mobileFormat}
            />
            {upgradePopUp
              ? (
                <UpgradePlanPopUp
                  setUpgradePopUp={setUpgradePopUp}
                  handleUpgradePopUp={handleUpgradePopUp}
                />
              ) : null}
            {openPaymentForm && (
            <MyPaymentForm
              openPaymentForm={openPaymentForm}
              handleToken={handleToken}
              handleClosePaymentForm={handleClosePaymentForm}
              paymentResult={paymentResult}
            />
            )}
            {/* {openPaymentForm
             && (
             <Elements stripe={stripePromise}>
               <CheckoutForm
                 openPaymentForm={openPaymentForm}
                 payloadData={payload2}
               />
             </Elements>
             )}
          {openPosVerify
             && (
             <PosVerifyModal
               openPosVerify={openPosVerify}
               handleClosePosVerify={handleClosePosVerify}
               deviceCode={deviceCode}
             />
             )} */}
          </div>
        )}
      </AdminWrapper>
    </>
  );
}

export default Subscription;
