import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { dateFormats, getCalendarPath } from '../../functions/index';
import './notification.css';

const Notification = ({
  todayList, oldList, show, setNotify, OpennotificationView, setCheckNotification,
}) => {
  const history = useHistory();
  const handleOpen = () => {
    setNotify(!show);
    setCheckNotification(false);
  };
  const handleViewApp = (id) => {
    setNotify(!show);
    let urls = `${getCalendarPath()}&chemicaltype=opennotification&appId=${id}`;
    history.push(urls);
  };
  const HandleClick = (id) => {
    // console.log('id=>', id);
    setNotify(!show);
    if (history.location.pathname === '/calendar') {
      OpennotificationView(id);
    } else {
      handleViewApp(id);
    }
  };
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleOpen}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="service-modal notification-modal"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={handleOpen}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Alerts
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              {todayList.length > 0 || oldList.length > 0
                ? (
                  <div className="modal-form">
                    <div className="notification-content">
                      {todayList.length > 0 && <h5>Today</h5> }
                      {todayList ? todayList.map((el) => {
                        let { firstname, lastname } = el.sender;
                        let { appointment } = el || {};
                        return (
                          <>
                            {el.type === 'cancelappointment' ? (
                              <div className="content" onClick={() => HandleClick(appointment)}>
                                <div className="notify-img">
                                  <i className="fa fa-times" aria-hidden="true" />
                                </div>
                                <div className="noti-content">
                                  <h6>Appointment cancelled</h6>
                                  <p>
                                    {dateFormats(el.bookingdate)}
                                    {' '}
                                    {el.message}
                                    {' '}
                                    {firstname || ''}
                                    {' '}
                                    {lastname || ''}
                                  </p>
                                </div>
                              </div>
                            ) : el.type === 'rescheduleappointment' ? (
                              <div className="content" onClick={() => HandleClick(appointment)}>
                                <div className="notify-img">
                                  <i className="fa fa-address-card" aria-hidden="true" />
                                </div>
                                <div className="noti-content">
                                  <h6>Appointment rescheduled</h6>
                                  <p>
                                    {dateFormats(el.bookingdate)}
                                    {' '}
                                    {el.message}
                                    {' '}
                                    {firstname || ''}
                                    {' '}
                                    {lastname || ''}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="content" onClick={() => HandleClick(appointment)}>
                                <div className="notify-img">
                                  <i className="fa fa-address-card" aria-hidden="true" />
                                </div>
                                <div className="noti-content">
                                  <h6>New appointment</h6>
                                  <p>
                                    {dateFormats(el.bookingdate)}
                                    {' '}
                                    {el.message}
                                    {' '}
                                    {firstname || ''}
                                    {' '}
                                    {lastname || ''}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>

                        );
                      }) : (
                        <div className="no-data">
                          <p>No New Alerts</p>
                        </div>
                      )}
                    </div>

                    <div className="notification-content">
                      {oldList.length > 0 && <h5>Older</h5> }
                      {oldList && oldList.map((el) => {
                        let { firstname, lastname } = el.sender;
                        let { appointment } = el || {};
                        return (
                          <>
                            {el.type === 'cancelappointment' ? (
                              <div className="content" onClick={() => HandleClick(appointment)}>
                                <div className="notify-img">
                                  <i className="fa fa-times" aria-hidden="true" />
                                </div>
                                <div className="noti-content">
                                  <h6>Appointment cancelled</h6>
                                  <p>
                                    {dateFormats(el.bookingdate)}
                                    {' '}
                                    {el.message}
                                    {' '}
                                    {firstname || ''}
                                    {' '}
                                    {lastname || ''}
                                  </p>
                                </div>
                              </div>
                            ) : el.type === 'rescheduleappointment' ? (
                              <div className="content" onClick={() => HandleClick(appointment)}>
                                <div className="notify-img">
                                  <i className="fa fa-address-card" aria-hidden="true" />
                                </div>
                                <div className="noti-content">
                                  <h6>Appointment rescheduled</h6>
                                  <p>
                                    {dateFormats(el.bookingdate)}
                                    {' '}
                                    {el.message}
                                    {' '}
                                    {firstname || ''}
                                    {' '}
                                    {lastname || ''}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="content" onClick={() => HandleClick(appointment)}>
                                <div className="notify-img">
                                  <i className="fa fa-address-card" aria-hidden="true" />
                                </div>
                                <div className="noti-content">
                                  <h6>New appointment</h6>
                                  <p>
                                    {dateFormats(el.bookingdate)}
                                    {' '}
                                    {el.message}
                                    {' '}
                                    {firstname || ''}
                                    {' '}
                                    {lastname || ''}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>

                        );
                      })}

                      {/* <div className="content">
          <div className="notify-img">
            <i className="fa fa-address-card" aria-hidden="true" />
          </div>
          <div className="noti-content">
            <h6>New appointment</h6>
            <p>Tue 28 Sep 7:30pm Blow Dry for Walk-In booked by kashif</p>
          </div>
        </div>

        <div className="content">
          <div className="notify-img">
            <i className="fa fa-address-card" aria-hidden="true" />
          </div>
          <div className="noti-content">
            <h6>New appointment</h6>
            <p>Tue 28 Sep 7:30pm Blow Dry for Walk-In booked by kashif</p>
          </div>
        </div> */}
                    </div>
                  </div>
                ) : (
                  <div className="modal-form">
                    <div className="notification-content">
                      {/* <i className="fa fa-bell" aria-hidden="true" /> */}
                      <p>No notifications yet!</p>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
Notification.propTypes = {
  todayList: PropTypes.arrayOf.isRequired,
  oldList: PropTypes.arrayOf.isRequired,
  show: PropTypes.bool.isRequired,
  setNotify: PropTypes.func.isRequired,
  OpennotificationView: PropTypes.func.isRequired,
  setCheckNotification: PropTypes.func.isRequired,
};
export default Notification;
