import React, { useState, useEffect, useRef } from 'react';
import {
  Button, Form, Col, Row,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import crosss from '../../assets/home/black-icon.png';
import search from '../../assets/home/search.png';
import { userInstance } from '../../config/axios';
import { errorMsgTime, successMsgTime, errorBorderTime } from '../../functions/intervalTime';
import adjustInv from './index';
import toastMessage from '../../functions/toastMessage';

const InventoryAdjustment = ({ updateView }) => {
  const initialobj = {
    stockChange: 'increaseStock',
    fromLocationId: '',
    fromLocationName: '',
    fromLocationStock: '',
    fromMemberId: '',
    fromMemberStock: '',
    toLocationId: '',
    toLocationName: '',
    toPreviousLocationStock: '',
    toMemberId: '',
    toMemberName: '',
    toPreviousMemberStock: '',
    stockOnHand: '',
    quantityTransferred: '',
    toCurrentLocationStock: '',
    toCurrentMemberStock: '',
  };

  const [showLoaction, setShowLocations] = useState(false);
  const [filterObj, setFilterObj] = useState(initialobj);
  const [locationData, setLocationData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [searchedProduct, setSearchedProduct] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [staffBylocation, setStaffByLocation] = useState([]);
  const [locationSelect, setLocation] = useState('');
  const [singleProduct, setSingleProduct] = useState(null);
  const [selectedLocationValue, setSelectedLocationValue] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [simpleObject, setSimpleObject] = useState({
    quantityAdded: '',
    newQuanity: '',
    adjustmentReason: 'newStockPurchase',
    toLocationId: '',
    toMemberId: '',
  });
  const [errorValue, setErrorValue] = useState('');

  const stockCost = (singleProduct && simpleObject.quantityAdded > 0) ? simpleObject.quantityAdded * parseFloat(singleProduct.cost) : '';
  const handleProductSearch = async (searchText) => {
    try {
      setSearchedProduct(searchText);
      const response = await userInstance().get(
        `/inventory/searchProduct?value=${searchText.replace('$', '').trim()}`,
      );
      const { product } = response.data;
      if (product.length) {
        setSubcategories(product);
      } else {
        setSubcategories([]);
      }
    } catch (error) {
      console.log('error=>', error);
    }
  };
  const selectedproductData = (productId, productname) => {
    const findProduct = subcategories.find((el) => el._id === productId);
    const staff = {};
    findProduct.staffStockData.forEach((el) => {
      const { locationName } = el.location || '';
      if (staff[locationName]) {
        staff[locationName].push(el);
      } else {
        staff[locationName] = [el];
      }
    });
    const initialValue = Object.values(staff)[0] || [];
    const toLocationStockInitial = initialValue?.reduce((acc, obj) => parseFloat(acc) + parseFloat((obj?.stockValue || 0)), 0);
    setSingleProduct(findProduct);
    setSelectedProduct(staff);
    setLocation(Object.keys(staff)[0]);
    setStaffByLocation(initialValue);
    setSearchedProduct(productname);
    if (!initialValue[0]?.location) {
      toastMessage('error', 'Please distribute stock for this product from inventory product list.');
    }
    setFilterObj((prev) => ({
      ...prev,
      fromLocationId: initialValue[0]?.location._id,
      fromLocationName: initialValue[0]?.location.locationName,
      fromLocationStock: toLocationStockInitial,
      toPreviousLocationStock: toLocationStockInitial,
      toLocationId: initialValue[0]?.location._id,
      toLocationName: initialValue[0]?.location.locationName,
      stockOnHand: findProduct?.currentStockItems,
    }));
    setSubcategories(null);
  };
  const handleOnchange = (e) => {
    let { name, value } = e || {};
    // value = value.includes('.') ? value.replace('.', '') : value;
    let copyfilterObj = { ...filterObj };
    if (name === 'stockChange') {
      copyfilterObj.stockChange = value;
      setSimpleObject({
        quantityAdded: '',
        newQuanity: '',
        adjustmentReason: value === 'increaseStock' ? 'newStockPurchase' : value === 'decreaseStock' ? 'internalUse' : '',
      });
      if (value === 'transferFrom' && selectedProduct) {
        setShowLocations(true);
        const toMemberSelected = teamMemberData.find((el) => el.location === filterObj.toLocationId);
        copyfilterObj.fromMemberId = staffBylocation[0]?._id?._id;
        copyfilterObj.toMemberId = toMemberSelected._id;
        copyfilterObj.fromMemberStock = staffBylocation[0].stockValue;
        copyfilterObj.toMemberName = `${toMemberSelected.firstname} ${toMemberSelected.lastname}`;
        copyfilterObj.toPreviousMemberStock = staffBylocation[0].stockValue;
        copyfilterObj.stockOnHand = staffBylocation[0] ? staffBylocation[0].stockValue : '';
        copyfilterObj.toCurrentLocationStock = '';
        copyfilterObj.toCurrentMemberStock = '';
        copyfilterObj.quantityTransferred = '';
      } else {
        setShowLocations(false);
        copyfilterObj.fromMemberId = '';
        copyfilterObj.toMemberId = '';
        copyfilterObj.stockOnHand = singleProduct?.currentStockItems;
      }
    }
    if (name === 'fromLocationId') {
      const filterStaff = selectedProduct[value];
      const toPreviousLocationStockValue = filterStaff.reduce((acc, obj) => parseFloat(acc) + parseFloat((obj?.stockValue || 0)), 0);
      let toPreviousMemberStockValue = 0;

      setStaffByLocation(filterStaff);
      setLocation(value);
      const locData = filterStaff[0].location;
      const staffData = teamMemberData.filter((el) => el.location === locData._id);
      if (filterObj.stockChange === 'transferFrom') {
        if (filterStaff && filterStaff.length > 0) {
          const firstStaffData = filterStaff.find((el) => el._id._id === staffData[0]._id);
          toPreviousMemberStockValue = firstStaffData ? firstStaffData.stockValue : 0;
        }
        copyfilterObj.fromLocationId = locData._id;
        copyfilterObj.fromLocationName = locData.locationName;
        copyfilterObj.toLocationId = locData._id;
        copyfilterObj.toLocationName = locData.locationName;
        copyfilterObj.stockOnHand = filterStaff[0].stockValue;
        copyfilterObj.toPreviousLocationStock = toPreviousLocationStockValue;
        copyfilterObj.toPreviousMemberStock = toPreviousMemberStockValue;
        copyfilterObj.fromLocationStock = toPreviousLocationStockValue;
        copyfilterObj.fromMemberStock = filterStaff[0].stockValue;
        copyfilterObj.fromMemberId = filterStaff[0]._id._id;
        copyfilterObj.toMemberId = staffData[0]._id;
        copyfilterObj.toMemberName = `${staffData[0].firstname} ${staffData[0].lastname}`;
        copyfilterObj.toCurrentLocationStock = '';
        copyfilterObj.toCurrentMemberStock = '';
        copyfilterObj.quantityTransferred = '';
      } else {
        const copyLoc = selectedLocationValue.slice();
        const getLocatIndex = copyLoc.findIndex((el) => el?.locationName === value);
        const getLocationId = locationData.find((el) => el?.locationName === value);
        if (getLocatIndex === -1) {
          copyLoc.push(getLocationId);
        }
        setSelectedLocationValue(copyLoc);
      }
    }
    if (name === 'fromMemberId') {
      if (filterObj.stockChange === 'transferFrom') {
        const getStaff = staffBylocation.find((el) => el._id._id === value);
        copyfilterObj.fromMemberId = value;
        copyfilterObj.stockOnHand = getStaff.stockValue;
        copyfilterObj.fromMemberStock = getStaff.stockValue;
        copyfilterObj.toPreviousMemberStock = getStaff.stockValue;
        copyfilterObj.toCurrentLocationStock = '';
        copyfilterObj.toCurrentMemberStock = '';
        copyfilterObj.quantityTransferred = '';
      } else {
        copyfilterObj.fromMemberId = value;
        if (value !== '') {
          const selectStaffId = value.split('+')[0];
          const selectStaffStock = parseFloat(value.split('+')[1]);
          const getTeamData = singleProduct.staffStockData.find((el) => el._id._id === selectStaffId);
          const findDuplciate = selectedStaff.findIndex((el) => el._id._id === selectStaffId);
          let copyArr2 = [...selectedStaff];
          if (findDuplciate === -1) {
            let testOBj = {
              ...getTeamData,
              stockValue: 0,
              previousStock: selectStaffStock,
            };
            copyArr2.push(testOBj);
            setSelectedStaff(copyArr2);
          }
        }
      }
    }
    if (name === 'toLocationId') {
      const getLocation = locationData.find((el) => el._id === value);
      const staffData = teamMemberData.filter((el) => el.location === value);
      const filterStaff = selectedProduct[getLocation.locationName];
      let toPreviousLocationStockValue = 0;
      let toPreviousMemberStockValue = 0;
      if (filterStaff && filterStaff.length > 0) {
        toPreviousLocationStockValue = filterStaff.reduce((acc, obj) => parseFloat(acc) + parseFloat((obj?.stockValue || 0)), 0);
        const firstStaffData = filterStaff.find((el) => el._id._id === staffData[0]._id);
        toPreviousMemberStockValue = firstStaffData ? firstStaffData.stockValue : 0;
      }
      copyfilterObj.toLocationId = value;
      copyfilterObj.toLocationName = getLocation.locationName;
      copyfilterObj.toPreviousLocationStock = toPreviousLocationStockValue;
      copyfilterObj.toMemberId = staffData[0]._id;
      copyfilterObj.toMemberName = `${staffData[0].firstname} ${staffData[0].lastname}`;
      copyfilterObj.toPreviousMemberStock = toPreviousMemberStockValue;
      copyfilterObj.toCurrentLocationStock = '';
      copyfilterObj.toCurrentMemberStock = '';
      copyfilterObj.quantityTransferred = '';
    }
    if (name === 'toMemberId') {
      const getStaff = teamMemberData.find((el) => el._id === value);
      const { firstname, lastname } = getStaff;
      const toPreviousMemberStockValue = singleProduct.staffStockData.find((el) => el._id._id === value);
      copyfilterObj.toMemberId = value;
      copyfilterObj.toMemberName = `${firstname} ${lastname}`;
      copyfilterObj.toPreviousMemberStock = toPreviousMemberStockValue ? toPreviousMemberStockValue.stockValue : 0;
      copyfilterObj.toCurrentLocationStock = '';
      copyfilterObj.toCurrentMemberStock = '';
      copyfilterObj.quantityTransferred = '';
    }
    if (name === 'quantityTransferred') {
      const valueInNumber = value ? parseFloat(value) : '';
      if (valueInNumber > filterObj.stockOnHand) {
        toastMessage('error', 'Quantity cannot be greater than Quantity on Hand.');
      } else {
        copyfilterObj.quantityTransferred = valueInNumber;
        copyfilterObj.toCurrentMemberStock = filterObj.toPreviousMemberStock + parseFloat(valueInNumber === '' ? 0 : valueInNumber);
        if (filterObj.toLocationId === filterObj.fromLocationId) {
          copyfilterObj.toCurrentLocationStock = filterObj.toPreviousLocationStock;
        } else {
          copyfilterObj.toCurrentLocationStock = filterObj.toPreviousLocationStock + parseFloat(valueInNumber === '' ? 0 : valueInNumber);
        }
      }
    }
    setFilterObj(copyfilterObj);
  };
  const getteammember = async () => {
    const response = await userInstance().get(
      '/appointment/getTeamMemberList',
    );
    const { teamList, code } = response.data;
    if (code === 200) {
      setTeamMemberData(teamList);
      // setfilterStatus(teamList);
    }
  };

  const getLocation = async () => {
    const response = await userInstance().get(
      '/appointment/getLocationList',
    );
    const { locationList, code } = response.data;
    locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
    if (code === 200) {
      setLocationData([...locationList]);
    }
  };

  const handleCancel = () => {
    setShowLocations(false);
    setSearchedProduct('');
    setSelectedProduct([]);
    setLocation('');
    setStaffByLocation(null);
    setSearchedProduct([]);
    setSubcategories(null);
    setFilterObj({});
    // setShowLocations(false);
  };

  const incrementDecrementHandle = (e) => {
    const { value } = e || {};
    const valueInNumber = value === '' ? 0 : parseFloat(value);
    let totalQuantity = 0;
    if (filterObj.stockChange === 'increaseStock') {
      totalQuantity = valueInNumber + filterObj.stockOnHand;
    } else if (filterObj.stockChange === 'decreaseStock') {
      totalQuantity = filterObj.stockOnHand - valueInNumber;
    }
    setSimpleObject({ quantityAdded: valueInNumber, newQuanity: value === '' ? 0 : totalQuantity });
  };
  const limitQuantityAdded = (inputObj) => {
    const { value } = inputObj;
    if (value > filterObj.stockOnHand && filterObj.stockChange === 'decreaseStock') {
      return toastMessage('error', `${filterObj.stockChange === 'increaseStock' ? 'Added' : 'Removed'} Quantity cannot be greater than Quantity on Hand.`);
    }
    return inputObj;
  };
  const limitQuantityStaffDec = (inputObj, index) => {
    const { value } = inputObj;
    if (parseFloat(value || 0) > selectedStaff[index].previousStock && filterObj.stockChange === 'decreaseStock') {
      return toastMessage('error', 'Quantity cannot be greater than previous quantity.');
    }
    return inputObj;
  };
  const handleAdjustDropdown = (e) => {
    const { value } = e.target;
    setSimpleObject({ ...simpleObject, adjustmentReason: value });
  };
  const removeLocation = (locationId) => {
    const removeLocationArray = selectedLocationValue.filter((el) => el._id !== locationId);
    setSelectedLocationValue(removeLocationArray);
    const removeTeamMemberArray = selectedStaff.filter((el) => el.location._id !== locationId);
    setSelectedStaff(removeTeamMemberArray);
  };
  const removeStaff = (staffId, locationId) => {
    const removeTeamMemberArray = selectedStaff.filter((el) => el._id !== staffId);
    setSelectedStaff(removeTeamMemberArray);
  };

  const handleStaffData = (e, index) => {
    const { value } = e.target;
    let copyStaffdata = [...selectedStaff];
    const valueNum = parseFloat(value || '');
    copyStaffdata[index].stockValue = valueNum;
    const previousStockNum = parseFloat(copyStaffdata[index].previousStock);
    if (filterObj.stockChange === 'decreaseStock') {
      copyStaffdata[index].newStock = previousStockNum - valueNum;
    } else {
      copyStaffdata[index].newStock = previousStockNum + valueNum;
    }
    setSelectedStaff(copyStaffdata);
  };
  const trasnsferFromAPI = () => {
    let payload = {
      transferType: filterObj.stockChange, productId: singleProduct._id,
    };
    if (parseFloat(filterObj.quantityTransferred) > 0) {
      let adjustData = singleProduct?.addjustmentRecord;
      const adjustCount = adjustData !== undefined ? adjustData.length : 0;
      payload.transferFromMember = filterObj.fromMemberId;
      payload.transferQuantity = filterObj.quantityTransferred;
      payload.transferToMember = filterObj.toMemberId;
      payload.transferToLocation = filterObj.toLocationId;
      payload.adjustCount = adjustCount + 1;
      payload.quantityAdded = 0;
      payload.adjustmentReason = '';
      payload.stockCost = 0;
      return payload;
    }
    return toastMessage('error', 'Quantity transfer can\'t be empty.');
  };
  const increaseDecreaseStockAPI = async (totalStockValue) => {
    let payload = {
      transferType: filterObj.stockChange, productId: singleProduct?._id,
    };
    if (simpleObject.quantityAdded > 0) {
      if (totalStockValue > simpleObject.quantityAdded || totalStockValue === 0 || totalStockValue < simpleObject.quantityAdded) {
        setErrorValue('distributedStock');
        setTimeout(() => {
          setErrorValue('');
        }, errorBorderTime);
        return toastMessage('error', `${filterObj.stockChange === 'increaseStock' ? 'Distributed' : 'Removed'} stock must be equal to quantity ${filterObj.stockChange === 'increaseStock' ? 'added' : 'removed'}.`);
      }
      let changeStaffArray = [];
      selectedStaff.forEach((el) => {
        let obj = {};
        obj._id = el._id;
        obj.location = el.location;
        obj.firstname = el.firstname;
        obj.lastname = el.lastname;
        obj.stockUsed = el.stockUsed || 0;
        obj.stockValue = el.newStock;
        changeStaffArray.push(obj);
      });
      let resultNonChange = singleProduct.staffStockData.filter((o1) => !changeStaffArray.some((o2) => o1._id._id === o2._id._id));
      const staffDataMain = [...changeStaffArray, ...resultNonChange];

      let adjustData = singleProduct?.addjustmentRecord;
      const adjustCount = adjustData !== undefined ? adjustData.length : 0;
      payload = {
        ...payload, ...simpleObject, staffDataMain, adjustCount: adjustCount + 1, stockCost,
      };
    } else {
      setErrorValue('quantityAdded');
      setTimeout(() => {
        setErrorValue('');
      }, errorBorderTime);
      toastMessage('error', `Quantitiy ${filterObj.stockChange === 'increaseStock' ? 'added' : 'removed'} must be greater than 0.`);
      return null;
    }

    return payload;
  };
  const handleSubmit = async () => {
    let payload = null;
    const totalStockValue = selectedStaff?.reduce((acc, obj) => parseFloat(acc) + parseFloat((obj?.stockValue || 0)), 0);
    if (filterObj.stockChange === 'transferFrom') {
      const trasnsferFromSubmit = await trasnsferFromAPI();
      if (trasnsferFromSubmit) payload = trasnsferFromSubmit || {};
    } else {
      const decreaseStockSubmit = await increaseDecreaseStockAPI(totalStockValue);
      if (decreaseStockSubmit) payload = decreaseStockSubmit || {};
    }
    if (payload) {
      const res = await adjustInv(payload);
      if (res.code === 200) {
        toastMessage('success', 'Inventory Adjust Successfully');
      } else {
        toastMessage('error', 'Unable to update.');
      }
    }
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setSubcategories(null);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    getteammember();
    getLocation();
  }, []);

  useEffect(() => {
    if (errorValue) {
      let element = document.querySelector('.error-text');
      if (element) {
        // console.log('element');
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [errorValue]);

  return (
    <>
      <div className="inventory-adjustment pool-set-up ">
        <div className="target-heading"><h6>Inventory Adjustment</h6></div>
        <div className="appointment-summary">
          <div className="search-box search-text">
            <Form.Group className="search-box">
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Search by product, description or cost"
                value={searchedProduct}
                onChange={(e) => handleProductSearch(e.target.value)}
              />
              <img src={search} alt="search" />
              {searchedProduct ? (
                <div
                  className="remove-text"
                  onClick={handleCancel}
                >
                  <img
                    src={crosss}
                    className="cross-img"
                    alt="cross-img"
                  />
                </div>
              ) : null}
            </Form.Group>
            {subcategories && subcategories.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {subcategories.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el.name}
                    key={el._id}
                    onClick={() => selectedproductData(el._id, el.name)}
                    name="subcategory"
                  >
                    {el.name}
                  </li>
                ))}
              </ul>
            ) : subcategories && !subcategories.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
            <Form.Group controlId="formBasicCompanyType">
              <Form.Label>Stock Movement</Form.Label>
              <div className="select-box">
                <Form.Control as="select" name="stockChange" value={filterObj.stockChange} onChange={(e) => handleOnchange(e.target)}>
                  <option value="increaseStock">Increase Stock Level</option>
                  <option value="decreaseStock">Decrease Stock Level</option>
                  <option value="transferFrom">Transfer From Location</option>
                </Form.Control>
              </div>
            </Form.Group>
          </div>
          {showLoaction && selectedProduct && (
            <>
              <Form.Group controlId="formBasicCompanyType">
                <div className="select-box">
                  <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="fromLocationId" value={locationSelect}>
                    {Object.keys(selectedProduct).map((location) => (
                      <option value={location}>
                        {location}
                        {' '}
                        Team Member
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
              <Form.Group controlId="formBasicCompanyType">
                <div className="select-box">
                  <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="fromMemberId" value={filterObj.fromMemberId}>
                    {staffBylocation && staffBylocation.length > 0 && staffBylocation.map((el) => (
                      <>
                        <option value={el?._id._id}>
                          {`${el?._id.firstname} ${el._id.lastname}`}
                          {' '}
                        </option>
                      </>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
            </>
          )}
          <Form.Group controlId="formBasicCompanyType">
            <Form.Label>Quantity on Hand</Form.Label>
            <Form.Control type="text" value={filterObj.stockOnHand} />
          </Form.Group>
          {filterObj.stockChange !== 'transferFrom' && (
            <>
              <Form.Group controlId="formBasicCompanyType">
                <Form.Label>Adjustment Reason</Form.Label>
                {filterObj.stockChange === 'increaseStock' ? (
                  <div className="select-box">
                    <Form.Control as="select" value={simpleObject.adjustmentReason} onChange={(e) => handleAdjustDropdown(e)}>
                      <option value="newStockPurchase">New Stock Purchase</option>
                      <option value="inventoryStocktake">Inventory Stocktake</option>
                      <option value="returned">Returned</option>
                      <option value="transfered">Transferred</option>
                      <option value="other">Other</option>
                    </Form.Control>
                  </div>
                ) : (
                  <div className="select-box">
                    <Form.Control as="select" value={simpleObject.adjustmentReason} onChange={(e) => handleAdjustDropdown(e)}>
                      <option value="internalUse">Internal Use</option>
                      <option value="faultyStock">Faulty Stock</option>
                      <option value="inventoryStocktake">Inventory Stocktake</option>
                      <option value="lost">Lost</option>
                      <option value="theft">Theft</option>
                      <option value="other">other</option>
                    </Form.Control>
                  </div>
                )}

              </Form.Group>
            </>
          )}

          {showLoaction && selectedProduct ? (
            <>
              <Form.Label className="italic-grey-inventory">Transfer To</Form.Label>
              <Form.Group controlId="formBasicCompanyType">
                <div className="select-box">
                  <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="toLocationId" value={filterObj.toLocationId}>
                    {locationData.map((el) => (
                      <option value={el._id}>
                        {el?.locationName}
                        {' '}
                        Team Member
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>
              <Form.Group controlId="formBasicCompanyType">
                <div className="select-box">
                  <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="toMemberId" value={filterObj.toMemberId}>
                    {teamMemberData.map((el) => (
                      <>
                        {filterObj.toLocationId === '' ? <option value={el?._id}>{`${el?.firstname} ${el?.lastname}`}</option> : (filterObj?.toLocationId === el?.location && el._id !== filterObj.fromMemberId) && <option value={el?._id}>{`${el?.firstname} ${el.lastname}`}</option>}
                      </>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>

              <Form.Group controlId="formBasicInput-2">
                <Form.Label>Quantity Transferred</Form.Label>
                <NumberFormat
                  className="form-control mobile-num"
                  value={filterObj.quantityTransferred}
                  name="quantityTransferred"
                  onChange={(e) => handleOnchange(e.target)}
                  decimalScale={0}
                />
              </Form.Group>
              <Form.Group controlId="formBasicInput-2">
                <Form.Label>
                  Quantity at
                  {' '}
                  {filterObj.toLocationName}
                  {' '}
                  Before Transfer
                </Form.Label>
                <Form.Control type="text" value={filterObj.toPreviousLocationStock} />
              </Form.Group>
              <Form.Group controlId="formBasicInput-2">
                <Form.Label>
                  Quantity at
                  {' '}
                  {filterObj.toLocationName}
                  {' '}
                  After Transfer
                </Form.Label>
                <Form.Control type="text" value={filterObj.toCurrentLocationStock} />
              </Form.Group>
              <Form.Group controlId="formBasicInput-2">
                <Form.Label>
                  Quantity
                  {' '}
                  {filterObj.toMemberName}
                  {' '}
                  Before Transfer
                </Form.Label>
                <Form.Control type="text" value={filterObj.toPreviousMemberStock} />
              </Form.Group>
              <Form.Group controlId="formBasicInput-2">
                <Form.Label>
                  Quantity
                  {' '}
                  {filterObj.toMemberName}
                  {' '}
                  After Transfer
                </Form.Label>
                <Form.Control type="text" value={filterObj.toCurrentMemberStock} />
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group controlId="formBasicInput-2" className="italic-text">
                <Form.Label>
                  Quantity
                  {' '}
                  {filterObj.stockChange === 'increaseStock' ? 'Added' : 'Removed'}
                </Form.Label>
                <NumberFormat
                  className={`form-control mobile-num ${
                    errorValue === 'quantityAdded'
                      ? 'error-text'
                      : ''
                  }`}
                  name="quantityAdded"
                  placeholder="Enter Quantity"
                  value={simpleObject.quantityAdded}
                  onChange={(e) => incrementDecrementHandle(e.target)}
                  decimalScale={0}
                  isAllowed={limitQuantityAdded}
                />
              </Form.Group>
              <Form.Group controlId="formBasicInput-2">
                <Form.Label>New Total Quantity</Form.Label>
                <Form.Control type="text" value={simpleObject.newQuanity} />
              </Form.Group>
              {selectedProduct && (
                <>
                  <Form.Label className="italic-grey-inventory">
                    Select team member to
                    {' '}
                    {filterObj.stockChange === 'increaseStock' ? 'increase' : 'decrease'}
                    {' '}
                    stock
                  </Form.Label>
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          if (e.target.value !== '') { handleOnchange(e.target); }
                        }}
                        name="fromLocationId"
                        className={`${
                          errorValue === 'distributedStock' && selectedStaff.length === 0 && selectedLocationValue.length === 0
                            ? 'error-text'
                            : ''
                        }`}
                      >
                        <option value="">Select location</option>
                        {Object.keys(selectedProduct).map((location) => (
                          <option value={location}>
                            {location}
                            {' '}
                            Team Member
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <div className="inventory-location-tag">
                    {selectedLocationValue.map((el) => (
                      <p>
                        {el?.locationName}
                        {' '}
                        <span onClick={() => removeLocation(el._id)}>X</span>
                      </p>
                    ))}
                  </div>
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        className={`${
                          errorValue === 'distributedStock' && selectedStaff.length === 0
                            ? 'error-text'
                            : ''
                        }`}
                        as="select"
                        onChange={(e) => { handleOnchange(e.target); }}
                        name="fromMemberId"
                        value={filterObj.fromMemberId}
                      >
                        <option value="">Select Team Member</option>
                        {staffBylocation && staffBylocation.length > 0 && staffBylocation.map((el) => (
                          <>
                            <option value={`${el?._id._id}+${el?.stockValue}`}>
                              {`${el?._id.firstname} ${el._id.lastname}`}
                              {' '}
                            </option>
                          </>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <div className="inventory-location-tag">
                    {selectedStaff && selectedStaff.length > 0 && selectedStaff.map((el, index) => (
                      <>
                        <Form.Group controlId="formBasicInput-2">
                          <Form.Label>
                            {' '}
                            {el.firstname}
                            {' '}
                            {el.lastname}
                          </Form.Label>
                          <div className="number-span">
                            <NumberFormat
                              thousandSeparator
                              type="text"
                              placeholder="Enter Stock Level"
                              value={el.stockValue}
                              name="stockValue"
                              onChange={(e) => handleStaffData(e, index)}
                              isAllowed={(inputObj) => limitQuantityStaffDec(inputObj, index)}
                              className={` ${
                                errorValue === 'distributedStock'
                                // && parseFloat(el.stockValue) === 0
                                  ? 'error-text'
                                  : ''
                              }`}
                            />
                            <span onClick={() => removeStaff(el._id, el.location)}>X</span>
                          </div>
                        </Form.Group>

                      </>

                    ))}
                  </div>
                </>
              )}
              <Form.Group controlId="formBasicInput-2">
                <Form.Label>Stock Cost</Form.Label>
                <Form.Control type="text" value={stockCost} />
              </Form.Group>
              <Form.Group controlId="formBasicInput-2">
                <Form.Label>Average Stock Cost</Form.Label>
                <Form.Control type="text" value={stockCost / simpleObject.quantityAdded || ''} />
              </Form.Group>
            </>
          )}
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn">
                <Button
                  type="Submit"
                  className="btn btn-edit"
                  onClick={() => updateView('InventoryAdjustment', 'goBack')}
                >
                  {' '}
                  Cancel
                  {' '}
                </Button>
                <Button
                  type="Submit"
                  className="btn btn-save"
                  onClick={handleSubmit}
                >
                  {' '}
                  Save/Update
                  {' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
InventoryAdjustment.propTypes = {
  updateView: PropTypes.func.isRequired,

};
export default InventoryAdjustment;
