import React, { useState, useEffect, useRef } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import StartEndDate from './startEndDate';
import crosss from '../../assets/home/black-icon.png';
import search from '../../assets/home/search.png';
import { userInstance } from '../../config/axios';
import { filterAppointmentObj } from '../../functions/index';

const CustomerPaymentDetails = ({ locationData }) => {
  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });
  const [datePopup, setDatePopup] = useState();
  const [allLocation, setlocationList] = useState([]);
  const [allteamList, setallteamList] = useState([]);
  const [filterStatus, setfilterStatus] = useState('');
  const [appsummaryDate, setappsummaryDate] = useState();
  const [allCustomer, setAllCustomer] = useState([]);
  const [customerList, setcustomerList] = useState([]);
  const [payment, setPayment] = useState([]);
  const [periodTotal, setPeriodTotal] = useState();
  const [locationNameList, setLocationNameList] = useState();
  const [teamMemberName, setTeamMemberName] = useState();
  // const [locationData, setLocationData] = useState([]);
  const [teamMemberData, setTeamMemberData] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialfilterObj = {
    // location: '',
    searchlocation: '',
    // teammember: 'allteam',
    // searchteammember: '',
    locationId: 'alllocation',
    teammemberId: 'allteam',
    locationStatus: false,
    teamStatus: false,
    datetype: 'today',
    rangeStartDate: new Date(),
    rangeEndDate: new Date(),
    // customer: 'allCustomer',
    // searchcustomer: '',
    // customerStatus: false,
    // customerId: '',
    payment: 'all',
  };
  const [filterObj, setFilterObj] = useState(initialfilterObj);

  useEffect(() => {
    if (filterObj.locationId === 'alllocation') {
      setLocationNameList('All Locations');
    } else {
      const findLocation = locationData.find(
        (el) => el._id === filterObj.locationId,
      );
      if (findLocation) {
        setLocationNameList(findLocation.locationName);
      }
    }
    if (filterObj.teammemberId === 'allteam') {
      setTeamMemberName('All Team Member');
    } else {
      const findTeamMember = teamMemberData.find(
        (el) => el._id === filterObj.teammemberId,
      );
      if (findTeamMember) {
        setTeamMemberName(findTeamMember);
      }
    }
  }, [filterObj.locationId, filterObj.teammemberId]);

  const handleDateRange = (start, end) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.rangeStartDate = start;
    copyfilterObj.rangeEndDate = end;
    setFilterObj(copyfilterObj);
    setfilterStatus(start);
    setDatePopup(false);
  };
  const handleDatePopup = () => {
    setDatePopup(!datePopup);
  };
  const handleDateOnchange = (value, type) => {
    setDateRange({ ...dateRange, [type]: new Date(value) });
  };
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    setfilterStatus(value);
    if (name === 'teammemberId' && value !== 'allteam') {
      const getLocationId = teamMemberData.filter((el) => el._id === value);
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = value;
      copyfilterObj.locationId = getLocationId[0].location;
      setFilterObj(copyfilterObj);
    } else if (name === 'locationId' && value === 'alllocation') {
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = 'allteam';
      copyfilterObj.locationId = value;
      setFilterObj(copyfilterObj);
    } else {
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    }
  };

  // const removeSearchText = (value) => {
  //   let copyfilterObj = { ...filterObj };
  //   copyfilterObj[value] = '';
  //   if (value === 'searchlocation') {
  //     copyfilterObj.locationId = '';
  //   } else {
  //     copyfilterObj.teammemberId = '';
  //   }
  //   setFilterObj(copyfilterObj);
  // };

  const searchLocation = async (value) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj.locationStatus = true;
    copyfilterObj.searchlocation = value;
    // console.log(copyfilterObj);
    setFilterObj(copyfilterObj);

    const response = await userInstance().get(
      `/appointment/filterLocation?searchKeyword=${value}`,
    );
    const { locationList, code } = response.data;
    // console.log(('locationList---', locationList));
    if (code === 200) {
      setlocationList(locationList);
    }
  };

  // useEffect(() => {
  //   (async () => {
  //     const response = await userInstance().get('/appointment/getLocationList');
  //     const { locationList, code } = response.data;
  //     locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
  //     if (code === 200) {
  //       setLocationData([...locationList]);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      const response = await userInstance().get(
        '/appointment/getTeamMemberList',
      );
      const { teamList, code } = response.data;
      teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
      if (code === 200) {
        setTeamMemberData(teamList);
        // setfilterStatus(teamList);
      }
    })();
  }, []);

  // const searchTeammember = async (value) => {
  //   let copyfilterObj = { ...filterObj };
  //   copyfilterObj.searchteammember = value;
  //   copyfilterObj.teamStatus = true;
  //   setFilterObj(copyfilterObj);
  //   const response = await userInstance().get(
  //     `/appointment/filterTeammember?team=${value}&location=${filterObj.locationId}&locationtype=${filterObj.location}`,
  //   );
  //   const { teamList, code } = response.data;
  //   if (code === 200) {
  //     setallteamList(teamList);
  //   }
  // };
  // const searchCustomer = async (value) => {
  //   let copyfilterObj = { ...filterObj };
  //   copyfilterObj.searchcustomer = value;
  //   copyfilterObj.customerStatus = true;
  //   setFilterObj(copyfilterObj);
  //   if (value) {
  //     const response = await userInstance().get(`/appointment/filterCustomer?value=${value}`);
  //     const { customers } = response.data;
  //     setcustomerList(customers);
  //   } else {
  //     setcustomerList([]);
  //   }
  // };
  const getPaymentFilter = async (e) => {
    const filterAppObj = filterAppointmentObj(filterObj);
    setappsummaryDate(filterAppObj.summaryDate);

    const filterTeamMemberId = filterObj.teammemberId !== 'allteam'
      ? [filterObj.teammemberId]
      : filterObj.locationId === 'alllocation'
        ? teamMemberData.map((el) => el._id)
        : teamMemberData
          .filter((el) => el.location === filterObj.locationId)
          .map((el) => el._id);
    const payload = {
      filterObj,
      filterAppObj,
      teamMemberIds: filterTeamMemberId,
    };
    setLoading(true);
    const response = await userInstance().post(
      '/payment/getPaymentSummery',
      payload,
    );
    setLoading(false);
    const { paymentData } = response.data;
    setPayment(paymentData);
    let totalAmount = paymentData.map((el) => el.amount);
    var numberArray = totalAmount.map(Number);
    const sumAmount = numberArray
      .reduce((partial_sum, a) => partial_sum + a, 0)
      .toFixed(2);
    setPeriodTotal(sumAmount);
  };

  const selectValue = (name, value, id) => {
    let copyfilterObj = { ...filterObj };
    copyfilterObj[name] = value;
    setfilterStatus(value);
    if (name === 'searchcustomer') {
      copyfilterObj.customerId = id;
      copyfilterObj.customerStatus = false;
    } else if (name === 'searchlocation') {
      copyfilterObj.locationId = id;
      copyfilterObj.locationStatus = false;
    } else {
      copyfilterObj.teammemberId = id;
      copyfilterObj.teamStatus = false;
    }
    setFilterObj(copyfilterObj);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setlocationList(null);
          let copyfilterObj = { ...filterObj };
          copyfilterObj.teamStatus = false;
          setFilterObj(copyfilterObj);
          setfilterStatus('');
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    getPaymentFilter(filterStatus);
  }, [filterStatus]);

  // console.log({ filterObj, filterStatus });

  const handleRemove = () => {
    setfilterStatus('');
    setFilterObj({ ...filterObj, locationId: 'alllocation', searchlocation: '' });
  };

  return (
    <div className="appointment-summary pageone-payment">

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Payment Method</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            onChange={(e) => handleOnchange(e.target)}
            name="payment"
          >
            <option value="all">All Payment Methods</option>
            <option value="Visa/Mastercard">Visa/ Mastercard</option>
            <option value="EFT">EFT</option>
            <option value="Cash">Cash</option>
            <option value="Cheque">Cheque</option>
          </Form.Control>
        </div>
      </Form.Group>
      {!(locationData?.length > 10)
        ? (
          <Form.Group controlId="formBasicCompanyType">
            <Form.Label>Location</Form.Label>
            <div className="select-box">
              <Form.Control
                as="select"
                onChange={(e) => handleOnchange(e.target)}
                name="locationId"
                value={filterObj.locationId}
              >
                <option value="alllocation">All Locations</option>
                {locationData.map((el) => <option value={el._id}>{el?.locationName}</option>)}
              </Form.Control>
            </div>
          </Form.Group>
        )

        : (
          <div className="search-box search-text search-summary">
            <h6>Location</h6>
            <Form.Group className="search-box">
              <Form.Control
                type="text"
                placeholder="Search by name, mobile, phone or address"
                name="searchlocation"
                onChange={(e) => searchLocation(e.target.value)}
                value={filterObj.searchlocation}
                autoComplete="off"
              />
              <img src={search} alt="search" />
              <div className="remove-text" onClick={handleRemove}>
                <img src={crosss} className="cross-img" alt="cross-img" />
              </div>
            </Form.Group>
            {filterObj.locationStatus && (
            <>
              {/* {console.log('allLocation---', allLocation)} */}
              {allLocation && allLocation.length ? (
                <ul className="auto-complete" ref={wrapperRef}>
                  {allLocation.map((el) => (
                    <li
                      className="auto-complete-list"
                      value={el.locationName}
                      key={el._id}
                      onClick={() => selectValue('searchlocation', el.locationName, el._id)}
                    >
                      {el.locationName}
                    </li>
                  ))}
                </ul>
              ) : allLocation && !allLocation.length ? (
                <ul className="auto-complete" ref={wrapperRef}>
                  <li>No Results Found</li>
                </ul>
              ) : null}
            </>
            )}
          </div>
        )}

      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Team Member</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            onChange={(e) => handleOnchange(e.target)}
            name="teammemberId"
            value={filterObj.teammemberId}
          >
            <option value="allteam">All Team Members</option>
            {teamMemberData.map((el) => (
              <>
                {filterObj.locationId === 'alllocation' ? (
                  <option
                    value={el._id}
                    data-original-title={el.location}
                  >
                    {`${el.firstname} ${el.lastname}`}

                  </option>
                ) : (
                  filterObj.locationId === el.location && (
                    <option
                      value={el._id}
                      data-original-title={el.location}
                    >
                      {`${el.firstname} ${el.lastname}`}

                    </option>
                  )
                )}
              </>
            ))}
          </Form.Control>
        </div>
      </Form.Group>
      {/* {filterObj.teammember === 'specificteam' ? (
        <div className=" search-box search-text">
          <Form.Group className="search-box">
            <Form.Control type="text" placeholder="Search by name, mobile, phone or address" name="searchteammember" onChange={(e) => searchTeammember(e.target.value)} value={filterObj.searchteammember} />
            <img src={search} alt="search" />
            <div className="remove-text" onClick={() => removeSearchText('searchteammember')}>
              <img src={crosss} className="cross-img" alt="cross-img" />
            </div>
          </Form.Group>
          {filterObj.teamStatus && (
          <>
            {allteamList && allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                {allteamList.map((el) => (
                  <li
                    className="auto-complete-list"
                    value={el._id}
                    key={el._id}
                    onClick={() => selectValue('searchteammember', `${el.firstname} ${el.lastname}`, el._id)}
                  >
                    {`${el.firstname} ${el.lastname}`}
                  </li>
                ))}
              </ul>
            ) : allteamList && !allteamList.length ? (
              <ul className="auto-complete" ref={wrapperRef}>
                <li>No Results Found</li>
              </ul>
            ) : null}
          </>
          )}
        </div>
      ) : ''} */}
      <Form.Group controlId="formBasicCompanyType">
        <Form.Label>Date Range</Form.Label>
        <div className="select-box">
          <Form.Control
            as="select"
            value={filterObj.datetype}
            onChange={(e) => handleOnchange(e.target)}
            name="datetype"
          >
            <option value="today">Today</option>
            <option value="yesterday">Yesterday</option>
            <option value="tomorrow">Tomorrow</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
            <option value="year">This Year</option>
            <option value="lastweek">Last Week</option>
            <option value="lastyear">Last Year</option>
            <option value="nextweek">Next Week</option>
            <option value="nextyear">Next Year</option>
            <option value="weekdate">Week to Date</option>
            <option value="monthdate">Month to Date</option>
            <option value="yeardate">Year to Date</option>
            <option value="daterange">Date Range</option>
          </Form.Control>
        </div>
      </Form.Group>
      {filterObj.datetype === 'daterange' ? (
        <div className="start-date-section">
          <Form.Group className="search-box">
            <div className="custom-datepicker" onClick={handleDatePopup}>
              {`${moment(filterObj.rangeStartDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
          <span>to</span>
          <Form.Group className="search-box">
            <div className="custom-datepicker">
              {' '}
              {`${moment(filterObj.rangeEndDate).format('ddd DD MMM yyyy')}`}
            </div>
          </Form.Group>
        </div>
      ) : (
        ''
      )}

      <div className="appointment-summary-section">
        <div className="new-location">
          <p className="location-name">Customer Payment Summary</p>
          <p className="location-light">
            {`${
              filterObj.payment === 'all'
                ? 'All Payment Methods'
                : `${filterObj.payment}`
            } | ${locationNameList}`}
          </p>
          <p className="location-light">
            {teamMemberName?.firstname
              ? `${teamMemberName.firstname} ${teamMemberName.lastname}`
              : 'All Team Members'}
          </p>
          <p className="location-name">{appsummaryDate}</p>
        </div>

        <hr />
        <div className="details-body period-total">
          {periodTotal > 0.0 ? (
            <h6>
              {`Period Total: $${periodTotal.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              )}`}
            </h6>
          ) : (
            <h6>Period Total: $0.00</h6>
          )}
        </div>
        <hr />
        {/* {
         payment.length > 0
           ? payment.map((el) => (
             <div className="details-table">
               <div className="details-body">
                 <h6>Payment Date & Time</h6>
                 <p>{moment(el.createdAt).format('DD MMM yyyy | hh:mm a')}</p>
               </div>
               <div className="details-body">
                 <h6>Client Name</h6>
                 <p>{`${el.customer.firstname} ${el.customer.lastname}`}</p>
               </div>
               <div className="details-body">
                 <h6>Client Address</h6>
                 <p>{el.customer.streetAddress}</p>
               </div>
               <div className="details-body">
                 <h6>Team Member</h6>
                 <p>{el.receivedBy ? `${el.receivedBy.firstname} ${el.receivedBy.lastname}` : ''}</p>
               </div>
               <div className="details-body">
                 <h6>Branch Location</h6>
                 <p>{el.receivedBy.location ? el.receivedBy.location.locationName : ''}</p>
               </div>
               <div className="details-body">
                 <h6>Invoice No.</h6>
                 <p>{el.invoice.invoiceNo}</p>
               </div>
               <div className="details-body">
                 <h6>Transaction Type</h6>
                 <p>Sale</p>
               </div>
               <div className="details-body">
                 <h6>Payment Method</h6>
                 <p>{el.paymentMethod}</p>
               </div>
               <div className="details-body">
                 <h6>Amount Received</h6>
                 <p>{el.amount}</p>
               </div>
               <hr />
             </div>
           )) : (
             <div>
               <p>
                 No Records Found
               </p>
             </div>
           )
        } */}
        <div className="summary-table">
          <div className="summary-table-header">
            <h6>Payment Method</h6>
            <h6>No. of Transaction</h6>
            <h6>Amount</h6>
          </div>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              {payment.length > 0 ? (
                payment?.map((el, index) => (
                  <div className="summary-table-body">
                    <p>{el?._id}</p>
                    <p>{el?.count}</p>
                    <p>
                      $
                      {Number(el?.amount || 0).toFixed(2)}
                    </p>
                  </div>
                ))
              ) : (
                <div>
                  <p>No Records Found</p>
                </div>
              )}
              {payment.length > 0 ? (
                <div className="location-text">
                  <p>
                    {`${
                      filterObj.payment === 'all'
                        ? 'All Payment Method'
                        : `${filterObj.payment}`
                    } | ${locationNameList} |  ${
                      teamMemberName?.firstname
                        ? `${teamMemberName?.firstname} ${teamMemberName?.lastname}`
                        : 'All Team Member'
                    }`}

                  </p>
                </div>
              ) : null}

              <hr />
            </>
          )}

        </div>
      </div>
      <StartEndDate
        datePopup={datePopup}
        setDatePopup={setDatePopup}
        handleDatePopup={handleDatePopup}
        handleDateOnchange={handleDateOnchange}
        dateRange={dateRange}
        handleDateRange={handleDateRange}
      />
    </div>
  );
};

CustomerPaymentDetails.propTypes = {
  locationData: PropTypes.arrayOf.isRequired,
};

export default CustomerPaymentDetails;
