import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

function ClientAppointments({
  limit, setSkip, pageCount, appointmentClient, handleSelect, addedClients, setShowClientAppoinment, setShowWaterSelectionPopup, setAppIdMerge, setEndTimeWaterTest, setBookingData, bookingData, setEndTimeNew, endTimeNew, setDateByApp, newAppointment, setNewAppointment,
}) {
  const [selectedPage, setSelectedPage] = useState(1);
  const handlePageClick = ({ selected }) => {
    setSkip(selected * limit);
    setSelectedPage(selected + 1);
  };
  const [state, setState] = useState('');
  const [selectedExistsStaff, setselectedExistsStaff] = useState({});
  const handleChange = (e, data) => {
    const array1 = data.dateData[0].service;
    const length1 = array1.length;
    const result = array1[length1 - 1];
    const { end } = result;
    const startDate = array1[0].start;
    setselectedExistsStaff(data.staffId);
    setState(data._id);
    setEndTimeNew(moment(end).format('HH:mm'));
    setDateByApp(startDate);
  };

  const handleCancel = () => {
    setShowClientAppoinment(false);
    setShowWaterSelectionPopup(true);
  };
  const handleSelectNew = () => {
    if (state) {
      setAppIdMerge(state);
      // console.log('bookingDatabookingDatabookingData', bookingData);
      const allOld = [...newAppointment];
      const { firstname, lastname, _id } = selectedExistsStaff || '';
      allOld.forEach((item) => {
        // console.log('item=>', item.chemicalTestArray);
        item.service.forEach((el) => {
          el.staffId = _id;
          el.staffName = `${firstname || ''} ${lastname || ''}`;
        });
        item.chemicalTestArray.forEach((el) => {
          el.staffId = _id;
          el.staffName = `${firstname || ''} ${lastname || ''}`;
        });
      });
      setNewAppointment([...allOld]);
      // setEndTimeWaterTest(endTime);
      const obj = {
        ...bookingData,
        staffId: _id,
        staffName: `${firstname || ''} ${lastname || ''}`,
        // startTime: endTimeNew,
      };
      setBookingData(obj);
      setShowClientAppoinment(false);
      setShowWaterSelectionPopup(false);
    } else {
      toastMessage('error', 'Please, select an appointment.');
    }
  };
  // console.log('appointmentClient=>', appointmentClient);
  return (
    <>
      <Modal
        size="lg"
        show={handleSelect}
        onHide={handleCancel}
        aria-labelledby="contained-modal-title-vcenter"
        top
        className="service-modal notification-modal"
      >
        <div className="staff-close">
          <i
            className="fa fa-times"
            onClick={handleCancel}
            aria-hidden="true"
          />
        </div>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Select Appointment of ${addedClients.firstname} ${addedClients.lastname}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="water-appointment">
              <div className="appointment-view">
                {
               appointmentClient && appointmentClient.length > 0 && appointmentClient.map((el) => {
                 const { start } = el ? el.dateData[0].service[0] : {};
                 let { date } = el ? el.dateData[0] : {};
                 return (
                   <>
                     <div className="pending-water-test" onClick={(e) => handleChange('', el)}>
                       <h3>
                         {`${moment(date).format('dddd, D MMMM YYYY')} at ${moment(start).format('h:mm a')} `}
                       </h3>
                       <div className="open-date client-notes">
                         <div className="basic">
                           <Form.Check
                             type="checkbox"
                             name={el._id}
                             value={el._id}
                             checked={
                          state === el._id ? true : ''
                              }
                             onChange={(e) => handleChange(e, el)}
                           />
                         </div>
                       </div>
                     </div>
                   </>
                 );
               })
}
              </div>
            </div>
            <div className="user-pagination">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Load More"
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName="pagination-chk"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
                breakLabel="..."
                marginPagesDisplayed={-2}
                pageRangeDisplayed={0}
              />
              <div className="no-of-page">
                Page
                {' '}
                {selectedPage}
                {' '}
                of
                {' '}
                {' '}
                {pageCount}
              </div>
            </div>
          </div>
          <div className="event-popup-btn footer-btn view-btn">
            <Button className="appointment-btn btn-edit" type="button" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="button" className="appointment-btn btn-save" onClick={handleSelectNew}>
              Select
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
ClientAppointments.propTypes = {

  setShowClientAppoinment: PropTypes.func.isRequired,
  setShowWaterSelectionPopup: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setSkip: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  appointmentClient: PropTypes.arrayOf.isRequired,
  handleSelect: PropTypes.func.isRequired,
  addedClients: PropTypes.arrayOf.isRequired,
  setAppIdMerge: PropTypes.func.isRequired,
  setEndTimeWaterTest: PropTypes.func.isRequired,
  setBookingData: PropTypes.func.isRequired,
  bookingData: PropTypes.objectOf.isRequired,
  setEndTimeNew: PropTypes.func.isRequired,
  endTimeNew: PropTypes.string.isRequired,
  setDateByApp: PropTypes.func.isRequired,
  newAppointment: PropTypes.arrayOf.isRequired,
  setNewAppointment: PropTypes.func.isRequired,

};

export default ClientAppointments;
