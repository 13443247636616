import React, { useState, useEffect } from 'react';
import {
  Tabs, Tab, Form, Button, Dropdown,
} from 'react-bootstrap';
import target from '../../assets/home/icon/target.png';
import showmore from '../../assets/home/showmoreblue.png';
import AddSpecific from './SalesAccount/AddSpecific';
import Default from './SalesAccount/Default';

const SalesAccount = () => {
  const [view, setView] = useState('Default');
  const [heading, setHeading] = useState('Default Sales Account');
  const updateView = (key) => {
    if (key === 'Default') {
      setHeading('Default Sales Account');
      setView(key);
    } else if (key === 'Add Specific') {
      setHeading('Add Specific Sales Account');
      setView(key);
    }
  };

  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case 'Default':
        componentToRender = <Default />;
        break;
      case 'Add Specific':
        componentToRender = <AddSpecific />;
        break;
      default:
        break;
    }
    return componentToRender;
  };

  return (
    <div className="sales-default">
      <div className="row">
        <div className="col-md-12">
          <div className="main-titles-mob">
            <h5>{heading}</h5>
            <div className="content-dropdown">
              <div className="client-option service-edit-icon">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={showmore} alt="show" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div
                        className="action"
                        onClick={() => updateView('Default')}
                      >
                        <span>
                          <img src={target} alt="edit" />
                        </span>
                        <span>Default Sales Account</span>
                      </div>
                    </Dropdown.Item>

                    <Dropdown.Item>
                      <div
                        className="action"
                        onClick={() => updateView('Add Specific')}
                      >
                        <span>
                          <img src={target} alt="edit" />
                        </span>
                        <span>Add Specific </span>
                      </div>
                    </Dropdown.Item>

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {renderView()}
        </div>
      </div>
    </div>
  );
};
export default SalesAccount;
