import React, { useEffect, useState } from 'react';
import TimeKeeper from 'react-timekeeper';
import { Modal, Button, ToggleButtonGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

const StaffTimePicker = ({
  handleTimePicker, timepicker, timeData, handleShiftChange, type, selectedShift,
}) => {
  const [timeType, setType] = useState('hour');
  const [finalTime, setTime] = useState(type === 'startTime' ? selectedShift.startTime : selectedShift.endTime);
  // console.log('type', type, timeType);
  const handleTimepickerSet = () => {
    handleShiftChange({ name: type, data: finalTime });
    handleTimePicker();
  };

  const handleTime = (selectedTime) => {
    // console.log('selectedTime', selectedTime);
    const time = moment(selectedTime.formatted24, ['HH:mm']).format('h:mm A');
    updateCss(
      selectedTime.formattedSimple.split(':')[timeType === 'hour' ? 0 : 1],
    );
    setTime(time);
  };
  const updateCss = (time) => {
    // console.log('time', time);
    const elements = document.getElementsByClassName('css-1ddlhy');
    // console.log('elements', elements);
    Array.from(elements).forEach((el) => {
      let color = 'black';
      // console.log('el', el.innerText);
      if (el.innerText === time.toString()) {
        color = 'white';
      }
      el.style.color = color;
    });
  };
  const updateColor = () => {
    // console.log('setting', bookingData.startTime);
    const time = moment(finalTime, ['HH:mm']).format('h:mm A');
    updateCss(parseInt(time.split(':')[0], 10));
    // console.log('time1', time);
  };

  const handlePickChange = (info) => {
    setTimeout(() => {
      setType(info);
      const time = moment(finalTime, ['HH:mm']).format('h:mm A');
      // console.log('before time', time);
      updateCss(time.split(':')[timeType === 'hour' ? 0 : 1]);
    }, 250);
  };

  useEffect(() => {
    if (type === 'startTime') {
      setTime(selectedShift.startTime);
    } else {
      setTime(selectedShift.endTime);
    }
    updateColor();
  }, [type, selectedShift]);

  return (
    <Modal
      size="md"
      show={timepicker}
      onHide={handleTimePicker}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="time-picker-modal"
    >
      <Modal.Body>
        <div className="time-keeper-popup">
          <div className="time-keeper">
            <TimeKeeper
              time={finalTime}
              onChange={(data) => handleTime(data)}
              forceCoarseMinutes
              onPickChange={handlePickChange}
            />
          </div>
          <div className="time-keeper-btn">
            <Button onClick={handleTimepickerSet}>Save/Update</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
};
StaffTimePicker.propTypes = {
  handleTimePicker: PropTypes.func.isRequired,
  timepicker: PropTypes.bool.isRequired,
  timeData: PropTypes.func.isRequired,
  handleShiftChange: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
  selectedShift: PropTypes.func.isRequired,
};
export default StaffTimePicker;
