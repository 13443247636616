import React, { useState, useEffect } from 'react';
import {
  Tabs, Tab, Form, Button, Dropdown,
} from 'react-bootstrap';
import axios from 'axios';
import { userInstance, notTimeOutUserInstance } from '../../../config/axios';
import showmore from '../../../assets/home/showmoreblue.png';
import QuickBooklogo from '../../../assets/home/QuickBooks.png';
import { errorMsgTime, successMsgTime } from '../../../functions/intervalTime';
import '../xero.css';

let intervalId;
const AccountingXeroSoftware = () => {
  const [connected, setConnected] = useState(false);

  const isConnectFunction = async () => {
    try {
      const response = await userInstance().get('/quickBooks/checkConnection');
      // console.log('this is the response', response);
      const { check } = response.data;
      if (check) {
        setConnected(check);
        clearInterval(intervalId);
      } else {
        setConnected(check);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const isConnectedCheck = async () => {
    try {
      const response = await userInstance().get('/quickBooks/checkConnection');
      // console.log('this is the response', response);
      const { check } = response.data;
      if (check) {
        setConnected(check);
      } else {
        intervalId = setInterval(isConnectFunction, 2000);
        setConnected(check);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  const connectTo = async () => {
    const response = await userInstance().get('/quickBooks/quickBooksConnect');
    // console.log('response', response);
    const { url } = response.data;
    // console.log('this is the url', url);
    window.open(url, '_blank');
  };

  // useEffect(() => {
  //   isConnectedCheck();

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  return (
    <>
      <div>
        {/* <h3>Xero</h3> */}
        <div className="row">
          <div className="col-md-12">
            <div className="appointment-view xero-box status-box-xero">
              {/* <h2>Xero</h2> */}
              <img src={QuickBooklogo} alt="xero" />
              <div className="view-btn">
                <Button onClick={connected === false ? connectTo : undefined} className="btn-save">{connected ? 'Connected' : 'Connect to QuickBooks'}</Button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>

  );
};
export default AccountingXeroSoftware;

// const connect = async () => {
//   const response = await userInstance().get('/xero/xeroConnect');
//   const { xeroArray } = response.data;
//   const redirect_url = xeroArray[0];
//   console.log('redirectUrl', redirect_url);
//   window.open(redirect_url, '_blank');
//   interval = setInterval(connectTick, 2000);
// };
// const connectTick = () => {
//   if (localStorage.getItem('quickBook')) {
//     if (localStorage.getItem('quickBook') === 'true') {
//       NotificationManager.success('QuickBooks Connected', 'Message', successMsgTime);
//       localStorage.removeItem('quickBook');
//       clearInterval(interval);
//       isConnected();
//     } else {
//       console.log('Not Successfully Connected to QuickBooks');
//       localStorage.removeItem('quickBook');
//       clearInterval(interval);
//     }
//   }
// };

// let parseRedirectUrl = 'https://dev.calendar-pro.com/quickbook/redirect?code=AB11653128790znue9fhltfQYc78d1HEBA1qtTFWvfHzFGMEmx&state=testState&realmId=4620816365226154430';
// const anotherAPI = async () => {
//   const response = await userInstance().post('/quickBooks/quickBooksRedirect', { parseRedirectUrl });
//   console.log('this is the response', response);
// };

// const checkValid = async () => {
//   const response = await userInstance().post('/quickBooks/quickBooksValid', { parseRedirectUrl });
//   console.log('this is the response', response);
// };
