/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Draggable, { DraggableCore } from 'react-draggable';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import getStrNum from '../../functions/strNumseparator';
import { TemplateFeatures } from '../../contexts/TemplateFeatureContext';

let startPos = {
  x: 0,
  y: 0,
};
let stopPos = {
  x: 0,
  y: 0,
};
const AddEditTemplate = ({
  templateData,
  templateDataOne,
  handleText,
  handleImagePopupOpen,
  customFeilds,
  handleTextNew,
  handleImageNew,
  handleTextNewMove,
  handleMoveText,
}) => {
  // console.log('add new Template=>', {
  //   templateData, templateDataOne, handleText, handleImagePopupOpen, customFeilds, handleTextNew, handleImageNew,
  // });
  const { userData } = useContext(AuthDataContext);
  const {
    setIsNewChange,
  } = useContext(TemplateFeatures);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';
  const [arrayOne, setArrayOne] = useState(templateData);
  const [activeDrags, setActiveDrag] = useState(0);

  useEffect(() => {
    setArrayOne(templateData);
  }, [templateData]);
  const onStart = (_, data) => {
    setActiveDrag(activeDrags + 1);
    startPos = { ...data };
  };

  useEffect(() => {
    setArrayOne(templateData);
  }, [templateData]);

  const eventLogger = (
    e,
    data,
    style,
    text,
    index,
    i,
    placeholder,
    tdStyle,
  ) => {
    let distx = data.x - startPos.x;
    let disty = data.y - startPos.y;
    let dist = Math.hypot(distx, disty);
    if (dist < 5) {
      return handleText(
        style,
        text,
        index,
        i,
        placeholder,
        tdStyle,
        e,
      );
    }
    setIsNewChange(true);
    return handleMoveText(style, text, index, i, placeholder, tdStyle, data.y, data.x);
  };
  const eventLoggerImg = (
    e,
    data,
    style,
    text,
    index,
    i,
    placeholder,
    tdStyle,
  ) => {
    let distx = data.x - startPos.x;
    let disty = data.y - startPos.y;
    let dist = Math.hypot(distx, disty);
    if (dist < 5) {
      return handleImagePopupOpen(
        index,
      );
    }
    setIsNewChange(true);
    return handleMoveText(style, text, index, i, placeholder, tdStyle, data.y, data.x);
  };
  const eventLogger1 = (
    e,
    data,
    style,
    text,
    index,
  ) => {
    let distx = data.x - startPos.x;
    let disty = data.y - startPos.y;
    let dist = Math.hypot(distx, disty);
    if (dist < 5) {
      return handleTextNew(
        style,
        text,
        index,
        e,
      );
    }
    setIsNewChange(true);
    return handleTextNewMove(style, text, index, data.y, data.x);
  };

  const htmlJson = {
    tr1: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events.map((el) => (
              <>
                {!!el?.text && (
                <>
                  <span
                    className="test"
                    style={{
                      ...el.style,
                    }}
                  >
                    {el.text}
                  </span>
                </>
                )}
              </>
            ))}
          </div>
        </Draggable>
      </div>
    ),
    tr2: (element, iii) => (
      <div>
        <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events.map((el) => (
              <>
                {el?.text && (
                <div style={el.tdStyle2}>
                  <span
                    style={{
                      ...el.style,
                    }}
                  >
                    {el.text}
                  </span>
                </div>
                )}
              </>
            ))}
          </div>
        </Draggable>
      </div>
    ),
    tr3: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            {element.events[0].text ? (
              <span
                style={{
                  ...element.events[0].style,
                }}
              >
                {element.events[0].text}
              </span>

            ) : (
              <>
                <div />
              </>
            )}
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events[1].text && (
            <span
              style={{
                ...element?.events[1]?.style,
              }}
            >
              {element.events[1].text}
            </span>
            )}
          </div>
        </Draggable>
      </div>
    ),
    tr4: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle21',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            {element.events[0].text ? (
              <>
                <div style={element.events[0].tdStyle1}>
                  <span
                    style={{
                      ...element?.events[0]?.style,
                    }}
                  >
                    {element.events[0].text}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div />
              </>
            )}
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events[1].text && (
            <div style={element.events[1].tdStyle2}>
              <span
                style={{
                  ...element.events[1].style,
                }}
              >
                {element?.events[1]?.text}
              </span>
            </div>
            )}
          </div>
        </Draggable>
      </div>
    ),
    tr5: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events[0].text && (
            <>
              <div style={element.events[0].tdStyle1}>
                {element.events.map((el, i) => (
                  <>
                    <span
                      style={{
                        ...el.style,
                      }}
                      role="presentation"

                    >
                      {el.text}
                    </span>
                  </>
                ))}
              </div>
              <div style={element.events[0].tdStyle2} />
            </>
            )}
          </div>
        </Draggable>
      </div>
    ),
    tr6: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            {element.events[0].text && (
            <>
              <div style={element.events[0].tdStyle1}>
                <span
                  style={element.events[0].style}
                >
                  {element.events[0].text}
                </span>
              </div>
            </>
            )}
            <div style={element.events[1].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    tr7: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <div style={element.events[0].tdStyle2}>
              <span
                style={{
                  ...element?.events[0]?.style,
                }}
              >
                {element.events[0].text}
              </span>
            </div>
            <div style={element.events[0].tdStyle1} />
          </div>
        </Draggable>
      </div>
    ),
    tr8: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <div style={element.events[0].tdStyle1} />
            {element.events.map((el, i) => (
              <>
                {/* <td style={element.events[0].tdStyle1} /> */}
                <div style={el.tdStyle2}>
                  <span
                    style={{
                      ...el.style,
                    }}
                  >
                    {el.text}
                  </span>
                </div>
              </>
            ))}
          </div>
        </Draggable>
      </div>
    ),
    tr9: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <div style={element.events[0].tdStyle2}>
              <span
                style={{
                  ...element?.events[0]?.style,
                }}
              >
                {element.events[0].text}
              </span>
            </div>
            <div style={element.events[0].tdStyle1} />
          </div>
        </Draggable>
      </div>
    ),
    tr10: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr11: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <div style={element.events[0].tdStyle1}>
              <span
                style={{
                  ...element.events[0].style,
                }}
              >
                {element.events[0].text}
              </span>
            </div>
            <div style={element.events[0].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    tr12: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >

            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >

            <span
              style={{
                ...element.events[1].style,
              }}
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr13: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={element.events[0].style}
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr14: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[1].style,
              }}
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr15: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={element.events[0].style}
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[1]?.style,
            element?.events[1]?.text,
            element?.events[1]?.index,
            0,
            element?.events[1]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[1]?.style.left)?.num), y: Number(getStrNum(element?.events[1]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[1].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[1]?.style,
              element?.events[1]?.text,
              element?.events[1]?.index,
              0,
              element?.events[1]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={element.events[1].style}
            >
              {element.events[1].text}
            </span>
          </div>
        </Draggable>
      </div>
    ),
    tr16: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element?.events[0]?.style,
              }}
            >
              {element.events[0].text}
            </span>
          </div>
        </Draggable>
        <div style={element.events[0].tdStyle1} />
      </div>
    ),
    tr17: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
            <div style={element.events[0].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    tr18: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
            <div style={element.events[0].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    tr19: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
            <div style={element.events[0].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    tr20: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
            <div style={element.events[0].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    tr21: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle1',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle1, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle1',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
            <div style={element.events[0].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    tr22: (element, iii) => (
      <div>
        <Draggable
          onStart={onStart}
          onStop={(e, data) => eventLogger(
            e,
            data,
            element?.events[0]?.style,
            element?.events[0]?.text,
            element?.events[0]?.index,
            0,
            element?.events[0]?.placeholder,
            'tdStyle2',
          )}
          position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
        >
          <div
            className="box"
            style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
            onClick={(e) => handleText(
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
              e,
            )}
          >
            <span
              style={{
                ...element.events[0].style,
              }}
            >
              {element.events[0].text}
            </span>
            <div style={element.events[0].tdStyle2} />
          </div>
        </Draggable>
      </div>
    ),
    img: (element, iii) => (
      <>
        <>
          <div style={{ ...element.events[0].tdStyle1, position: 'absolute' }} />
          <Draggable
            onStart={onStart}
            onStop={(e, data) => eventLoggerImg(
              e,
              data,
              element?.events[0]?.style,
              element?.events[0]?.text,
              element?.events[0]?.index,
              0,
              element?.events[0]?.placeholder,
              'tdStyle2',
            )}
            position={{ x: Number(getStrNum(element?.events[0]?.style.left)?.num), y: Number(getStrNum(element?.events[0]?.style.top)?.num) }}
          >
            <div
              className="box"
            // style={element.events[0].tdStyle2}
              style={{ ...element.events[0].tdStyle2, position: 'absolute' }}
              onClick={() => handleImagePopupOpen(element.events[0].index)}
            >
              {element.events.map((el, i) => (
                <>
                  {el?.text !== '' && (
                  <img
                    src={el?.text}
                    alt="logo"
                    style={{
                      maxHeight: `${el.style.imageHeight}`,
                      height: 'auto',
                      width: `${el.style.imageWidth}`,
                      objectFit: 'contain',
                      left: `${el.style.left}`,
                      top: `${el.style.top}`,
                      transform: `rotate(${el.style.imageRotate}deg)`,
                      zIndex: el.style.zIndex,
                    // position: el.style.position,
                    }}
                  />
                  // <img
                  //   src={el.preview ? el?.text : el.url}
                  //   alt="logo"
                  //   style={{
                  //     height: `${el.style.imageHeight}`,
                  //     width: `${el.style.imageWidth}`,
                  //     objectFit: 'contain',
                  //     position: 'absolute',
                  //     marginRight: `${el.style.imageMarginRight}`,
                  //     left: `${el.style.imageMarginLeft}`,
                  //     top: `${el.style.imageMarginTop}`,
                  //     marginBottom: `${el.style.imageMarginBottom}`,
                  //     transform: `rotate(${el.style.imageRotate})deg`,
                  //     zIndex: el.style.zIndex,
                  //   }}
                  //   onClick={() => handleImageNew(el?.style, el?.text, el?.index)}
                  // />
                  )}
                </>
              ))}
            </div>
          </Draggable>
        </>
      </>
    ),
  };
  const customJson = {
    tr: (element, iii) => (
      <div>
        <div style={element?.tdStyle2}>
          <span
            style={{
              ...element.style,
            }}
            role="presentation"
            onClick={(e) => handleTextNew(element.style, element.text, element.index, e)}
          >
            {element.text}
          </span>
        </div>
      </div>
    ),
    img: (element, iii) => (
      <div>
        <div>
          <img
            src={element.text}
            alt="logo"
            style={{
              height: `${element.style.imageHeight}`,
              width: `${element.style.imageWidth}`,
              objectFit: 'contain',
              position: 'absolute',
              marginRight: `${element.style.imageMarginRight}`,
              left: `${element.style.imageMarginLeft}`,
              top: `${element.style.imageMarginTop}`,
              marginBottom: `${element.style.imageMarginBottom}`,
              transform: `rotate(${element.style.imageRotate})deg`,
              zIndex: element.style.zIndex,
            }}
            onClick={() => handleImageNew(element.style, element.text, element.index)}
          />
        </div>
      </div>
    ),
  };
  return (
  // <div className="quotes-template-page">
  //   <page size="A4">
  //     {/* <TransformWrapper>
  //       <TransformComponent> */}
  //     <div
  //       id="myTable"
  //       className="edit-template quotes-edit-temp add-edit-quotes-template"
  //       style={{
  //         overflow: 'auto',
  //         border: '1px solid rgb(219, 219, 219)',
  //         wordBreak: 'break-word',
  //         width: '595px',
  //         margin: '0px auto',
  //         borderTop: '1px solid lightgray',
  //         padding: '5% 2% 2%',
  //         display: 'block',
  //         backgroundColor: 'white',
  //         position: 'relative',
  //         height: '842px',
  //       }}
  //     >
  //       {Array.from(new Set(arrayOne.map((s) => s.type)))
  //         .map((lab) => ({
  //           name: lab,
  //           events: arrayOne
  //             .filter((s) => s.type === lab)
  //             .map((edition) => edition),
  //         }))
  //         .map((el, i) => (
  //           <>{htmlJson[el.name](el, i)}</>
  //         ))}
  //       {customFeilds
  //         && customFeilds.map((cust) => <>{customJson[cust.type](cust)}</>)}
  //       {' '}
  //     </div>
  //     {/* </TransformComponent>
  //     </TransformWrapper> */}
  //   </page>

    // </div>
    <div className="quotes-template-page">
      <page size="A4">
        <div
          id="myTable"
          className="edit-template quotes-edit-temp add-edit-quotes-template"
          style={{
            overflow: 'hidden',
            wordBreak: 'break-word',
            width: `${countryType === 'us' ? '7.9in' : '595px'}`,
            margin: '0px auto',
            borderTop: '1px solid lightgray',
            borderRight: '1px solid lightgray',
            borderLeft: 'none',
            display: 'block',
            backgroundColor: 'white',
            position: 'relative',
            height: `${countryType === 'us' ? '11in' : '842px'}`,
            border: '1px solid rgb(219 219 219)',
            padding: '0px',
          }}
        >
          {customFeilds
          && customFeilds.map((cust) => <>{customJson[cust.type](cust)}</>)}
          {Array.from(new Set(arrayOne.map((s) => s.type)))
            .map((lab) => ({
              name: lab,
              events: arrayOne
                .filter((s) => s.type === lab)
                .map((edition) => edition),
            }))
            .map((el, i) => (
              <>{htmlJson[el.name](el, i)}</>
            ))}
        </div>
      </page>
    </div>
  );
};

AddEditTemplate.propTypes = {
  templateData: PropTypes.arrayOf.isRequired,
  templateDataOne: PropTypes.arrayOf.isRequired,
  handleText: PropTypes.func.isRequired,
  handleImagePopupOpen: PropTypes.func.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
  handleTextNew: PropTypes.func.isRequired,
  handleImageNew: PropTypes.func.isRequired,
  handleTextNewMove: PropTypes.func.isRequired,
  handleMoveText: PropTypes.func.isRequired,
};
export default AddEditTemplate;
