/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { userInstance } from '../../config/axios';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';

import flag from '../../assets/admin/aus-flag.svg';
import us from '../../assets/admin/us-flag.svg';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { emailRegex } from '../../config/keys';
import toastMessage from '../../functions/toastMessage';

function EditEmailPopup({
  show, handleClose, verifyMobile, setVerifyMobile, isEmail, invoiceEmail,
}) {
  const { userData, setUserData } = useContext(AuthDataContext);
  const [number, setNumber] = useState(invoiceEmail);
  const [otpvalue, setOtpValue] = useState('');
  const loginStaff = localStorage.getItem('loginStaff');
  const [isMobile, setisMobile] = useState(isEmail);
  const [errorValue, setErrorValue] = useState('');

  const onNumberhandle = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setNumber(value);
  };
  const handleOtpInput = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setOtpValue(value);
  };
  const handleVerifyMobile = async (e, type) => {
    e.preventDefault();
    // console.log('type===> verifyPhone  isActivePhone', type);
    const payload = {
      tonumber: number,
      loginStaffId: loginStaff,
      type,
      isEmail: isMobile,
    };
    if (type === 'verifyEmail') {
      if (number) {
        if (emailRegex.test(String(number).toLowerCase())) {
          console.log(payload);
          const response = await userInstance().post('/automaticInvoice/emailSetting', payload);
          toastMessage('success', 'Verification Code has been sent to your email address.');
          setVerifyMobile(true);
        //   setUserData({
        //     isEmailVerify: false,
        //   });
        } else {
          setErrorValue('email');
          toastMessage('error', 'Invalid email.');
        }
      } else {
        setErrorValue('email');
        toastMessage('error', 'Please enter email address.');
      }
    }
    if (type === 'isActiveEmail') {
      const response = await userInstance().post('/automaticInvoice/emailSetting', payload);
      setUserData({
        ...userData,
        isEmail: isMobile,
      });
      toastMessage('success', 'Email message setting updated successfully.');
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };
  const verifytwilioNumber = async (e) => {
    e.preventDefault();
    if (otpvalue) {
      const payload = {
        otp: otpvalue,
        loginStaffId: loginStaff,
      };
      const response = await userInstance().post('/automaticInvoice/verifyEmailoOtp', payload);
      const { msg, code } = response.data;
      if (code === 200) {
        if (msg === 'Success') {
          toastMessage('success', 'Email verified successfully');
          setUserData({
            ...userData,
            invoiceEmail: number,
            isEmailVerify: true,
          });
          handleClose('email');
        } else {
          setErrorValue('otp');
          toastMessage('error', 'Incorrect OTP.');
        }
      }
    } else {
      setErrorValue('otp');
      toastMessage('error', 'Enter OTP first.');
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => handleClose('email')}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal edit-mobile-popup sending-reminders-page"
    >
      <div className="staff-close">
        <i className="fa fa-times" aria-hidden="true" onClick={() => handleClose('email')} />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {verifyMobile ? 'Verify Email via SMS ' : 'Edit or Change Authorised Email Address'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicCompanyType" className="italic-text">
                  <Form.Label>Authorised Email Address.</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    autocomplete="off"
                    value={number}
                    onChange={(e) => onNumberhandle(e)}
                    placeholder="Enter Authorised Email Address"
                    className={`${
                      errorValue === 'email'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn view-single-btn">
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={(e) => handleVerifyMobile(e, 'verifyEmail')}
                  >
                    {' '}
                    Send Verification Code
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
            {verifyMobile && (
            <div className="verification-code">

              <div className="row">
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label>
                      Enter Verification Code Received
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="companyName"
                      onChange={handleOtpInput}
                      className={`${
                        errorValue === 'otp'
                          ? 'error-text'
                          : ''
                      }`}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="view-btn view-single-btn">
                    <Button
                      type="Submit"
                      className="btn btn-save"
                      onClick={verifytwilioNumber}
                    >
                      {' '}
                      Submit
                      {' '}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            )}
          </Form>
        </div>
        {verifyMobile ? '' : (
          <div className="appointment-summary">
            <h6>SMS Messages & Email Settings for Authorised Mobile</h6>
            <div className="pending-water-test">
              <h3>
                SMS Message Settings for Email Address
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    checked={isMobile}
                    onChange={() => setisMobile(!isMobile)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="Submit"
                    className="btn btn-edit"
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={(e) => handleVerifyMobile(e, 'isActiveEmail')}
                  >
                    {' '}
                    Save/Update
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
EditEmailPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setVerifyMobile: PropTypes.func.isRequired,
  verifyMobile: PropTypes.bool.isRequired,
  isEmail: PropTypes.bool.isRequired,
  invoiceEmail: PropTypes.string.isRequired,
};

export default EditEmailPopup;
