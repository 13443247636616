import React, { useContext, useState, useEffect } from 'react';
import {
  Accordion, Button, Card, Dropdown, Form,
} from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import showmore from '../../assets/home/showmoreblue.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { userInstance } from '../../config/axios';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';

const NewPermissionLayout = () => {
  const { userData, setUserData } = useContext(AuthDataContext);
  const [permissionsState, setpermissionsState] = useState([]);
  const [userPermissionsArray, setuserPermissionsData] = useState([]);
  const [run, setRun] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line react/prop-types
  const { userId } = userData;

  const checkCondition = (element) => {
    if (![
      'Water Test History',
      'Inventory',
      'Users',
      'Permissions & Access Level',
      'Logout',
    ].includes(element.action)
    && ((element.class !== 'main-menu hidden-menu'
    && element.class.includes('main-menu')) || element.action === 'Calculator')) return true;
    return false;
  };

  const createdNestedData = (type, arr) => {
    const newArr = [];
    arr.forEach((el) => {
      if ((type ? !(el.showMenu && el.category !== 'Calculator') : checkCondition(el))) {
        const child = arr.filter((elem) => el.action === elem.category && elem.action !== elem.category);
        newArr.push({ ...el, child });
      }
    });
    return newArr;
  };

  useEffect(() => {
    const copyPermissions = JSON.parse(JSON.stringify(userData?.permissions));
    const filteredData = createdNestedData('show', copyPermissions);
    if (filteredData?.length > 0) {
      const finalFilteredData = createdNestedData('', filteredData);
      // console.log({ filteredData, finalFilteredData });
      setpermissionsState([...finalFilteredData]);
    }
  }, [userData?.role]);

  const checkPermission = (level, id) => {
    const copy = { ...userPermissionsArray };
    let isCheck = false;
    if (copy[level]?.length > 0) {
      isCheck = copy[level]?.includes(id);
    }
    return isCheck;
  };

  const createLevelArray = (arr, action, levelArray) => {
    arr.forEach((elemen2) => {
      if (elemen2.category === action && !levelArray.includes(elemen2._id)) {
        levelArray.push(elemen2._id);
      }
    });

    return levelArray;
  };

  const updatePermission = async ({
    e, el, type, level, pId, mId,
  }) => {
    try {
      setLoading(true);
      const copyPermissions = JSON.parse(JSON.stringify(userData?.permissions));
      let isCheck = e.target.checked;
      let levelArray = [];

      if (type === 'parent') {
        copyPermissions.forEach((elem) => {
          if (elem.category === el.category) {
            if (!levelArray.includes(elem._id)) levelArray.push(elem._id);
            levelArray = createLevelArray(copyPermissions, elem.action, levelArray);
          }
        });
      } else if (type === 'child') {
        levelArray = isCheck ? [pId, el._id] : [el._id];
        if (el?.child?.length > 0) {
          levelArray = createLevelArray(copyPermissions, el.action, levelArray);
        }
      } else if (type === 'subChild') {
        levelArray = isCheck ? [pId, mId, el._id] : [el._id];
      }

      const dataPayload = {
        levelArray,
        level,
        eventType: isCheck ? '$push' : '$pullAll',
      };

      console.log({ dataPayload });
      const response = await userInstance().put('/userPermissions/addNewUserPermissions', dataPayload);
      setLoading(false);
      if (response) {
        toastMessage('success', 'Permission Updated');
        if (userId) {
          getUserPermissions();
        }
      } else {
        toastMessage('error', 'Something Went Wrong');
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      getUserPermissions();
    }
  }, [userId]);

  const getUserPermissions = async () => {
    try {
      const result = await userInstance().get(`/userPermissions/getUserPermissionsById/${userId}`);
      setIsLoading(false);
      const { code, usersPermissions } = result?.data;
      if (code === 200) {
        setuserPermissionsData(usersPermissions);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error ===>', error);
    }
  };

  // console.log({ userPermissionsArray });

  const getName = (value) => {
    if (value === 'services & products') {
      return 'Services & Products';
    } if (value === 'pool set-up') {
      return 'Pool Set-up';
    }
    return value;
  };

  return (
    <AdminWrapper>
      {isLoading ? <Loader /> : (
        <div className="staff-container permission-new-layout">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>Permission & Access Level</h3>

                </div>
                <div id="table-scroll" className="staff-table table-scroll">
                  <div className="mob-table">
                    {permissionsState.map((el) => (
                      <>
                        {el.action === 'Logout' ? null
                          : (
                            <Accordion className="mob-accordion" key="0" defaultActiveKey="0">
                              <Card>
                                <Card.Header>
                                  <div className="permission-new-section">
                                    <h6>Low</h6>
                                    <h6>Medium</h6>
                                    <h6>High</h6>
                                    {/* <h6>Basic</h6>
                                  <h6>Premium</h6>
                                  <h6>Standard</h6> */}

                                  </div>
                                  <div className="staff-main-heading">
                                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                      {/* <i className="fas fa-chevron-circle-down" /> */}
                                      <i className="fas far fa-chevron-circle-down" style={{ visibility: el?.child?.length > 0 ? 'visible' : 'hidden' }} />
                                      <span>
                                        {el.action}
                                      </span>
                                    </Accordion.Toggle>
                                    <div className="check-permission">
                                      <div className="permission-access">
                                        <div className="basic">
                                          <Form.Check
                                            type="checkbox"
                                            checked={checkPermission('low', el._id)}
                                            onChange={(e) => updatePermission({
                                              e, el, type: 'parent', level: 'low',
                                            })}
                                            disabled={loading}
                                          />
                                        </div>
                                        <div className="basic">
                                          <Form.Check
                                            type="checkbox"
                                            checked={checkPermission('medium', el._id)}
                                            onChange={(e) => updatePermission({
                                              e, el, type: 'parent', level: 'medium',
                                            })}
                                            disabled={loading}
                                          />
                                        </div>
                                        <div className="basic">
                                          <Form.Check
                                            type="checkbox"
                                            checked={checkPermission('high', el._id)}
                                            onChange={(e) => updatePermission({
                                              e, el, type: 'parent', level: 'high',
                                            })}
                                            disabled={loading}
                                          />
                                        </div>
                                        {/* <div className="basic">
                                        <Form.Check
                                          type="checkbox"
                                          checked={checkPermission('basic', el._id)}
                                          onChange={(e) => updatePermission({
                                            e, el, type: 'parent', level: 'basic',
                                          })}
                                          disabled={loading}
                                        />
                                      </div>
                                      <div className="basic">
                                        <Form.Check
                                          type="checkbox"
                                          checked={checkPermission('premium', el._id)}
                                          onChange={(e) => updatePermission({
                                            e, el, type: 'parent', level: 'premium',
                                          })}
                                          disabled={loading}
                                        />
                                      </div>
                                      <div className="basic">
                                        <Form.Check
                                          type="checkbox"
                                          checked={checkPermission('standard', el._id)}
                                          onChange={(e) => updatePermission({
                                            e, el, type: 'parent', level: 'standard',
                                          })}
                                          disabled={loading}
                                        />
                                      </div> */}
                                      </div>
                                    </div>
                                  </div>

                                </Card.Header>

                                {el?.child?.length > 0 && el.child.map((elem) => (

                                  (['Logout', 'Subscription Management'].includes(elem?.category))
                                    ? null : (
                                      <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                          <ul className="staff-list permission-child">

                                            <li className="staff-list-item">
                                              <div className="staff-box">
                                                <h3>{getName(elem?.action)}</h3>
                                                <h5>
                                                  <div className="check-permission">
                                                    <div className="permission-access">
                                                      <div className="basic">
                                                        <Form.Check
                                                          type="checkbox"
                                                          checked={checkPermission('low', elem?._id)}
                                                          onChange={(e) => updatePermission({
                                                            e, el: elem, type: 'child', level: 'low', pId: el._id,
                                                          })}
                                                          disabled={loading}
                                                        />
                                                      </div>
                                                      <div className="basic">
                                                        <Form.Check
                                                          type="checkbox"
                                                          checked={checkPermission('medium', elem?._id)}
                                                          onChange={(e) => updatePermission({
                                                            e, el: elem, type: 'child', level: 'medium', pId: el._id,
                                                          })}
                                                          disabled={loading}
                                                        />
                                                      </div>
                                                      <div className="basic">
                                                        <Form.Check
                                                          type="checkbox"
                                                          checked={checkPermission('high', elem?._id)}
                                                          onChange={(e) => updatePermission({
                                                            e, el: elem, type: 'child', level: 'high', pId: el._id,
                                                          })}
                                                          disabled={loading}
                                                        />
                                                      </div>
                                                      {/* <div className="basic">
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={checkPermission('basic', elem?._id)}
                                                        onChange={(e) => updatePermission({
                                                          e, el: elem, type: 'child', level: 'basic', pId: el._id,
                                                        })}
                                                        disabled={loading}
                                                      />
                                                    </div>
                                                    <div className="basic">
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={checkPermission('premium', elem?._id)}
                                                        onChange={(e) => updatePermission({
                                                          e, el: elem, type: 'child', level: 'premium', pId: el._id,
                                                        })}
                                                        disabled={loading}
                                                      />
                                                    </div>
                                                    <div className="basic">
                                                      <Form.Check
                                                        type="checkbox"
                                                        checked={checkPermission('standard', elem?._id)}
                                                        onChange={(e) => updatePermission({
                                                          e, el: elem, type: 'child', level: 'standard', pId: el._id,
                                                        })}
                                                        disabled={loading}
                                                      />
                                                    </div> */}
                                                    </div>
                                                  </div>
                                                </h5>
                                              </div>

                                            </li>
                                            {elem.child?.length > 0 ? (
                                              <ul className="staff-list permission-sub-child">

                                                {elem.child.map((elem2) => (
                                                  <li className="staff-list-item">
                                                    <div className="staff-box">
                                                      <h3>{elem2.action}</h3>
                                                      <h5>
                                                        <div className="check-permission">
                                                          <div className="permission-access">
                                                            <div className="basic">
                                                              <Form.Check
                                                                type="checkbox"
                                                                checked={checkPermission('low', elem2?._id)}
                                                                onChange={(e) => updatePermission({
                                                                  e, el: elem2, type: 'subChild', level: 'low', pId: elem._id, mId: el._id,
                                                                })}
                                                                disabled={loading}
                                                              />
                                                            </div>
                                                            <div className="basic">
                                                              <Form.Check
                                                                type="checkbox"
                                                                checked={checkPermission('medium', elem2?._id)}
                                                                onChange={(e) => updatePermission({
                                                                  e, el: elem2, type: 'subChild', level: 'medium', pId: elem._id, mId: el._id,
                                                                })}
                                                                disabled={loading}
                                                              />
                                                            </div>
                                                            <div className="basic">
                                                              <Form.Check
                                                                type="checkbox"
                                                                checked={checkPermission('high', elem2?._id)}
                                                                onChange={(e) => updatePermission({
                                                                  e, el: elem2, type: 'subChild', level: 'high', pId: elem._id, mId: el._id,
                                                                })}
                                                                disabled={loading}
                                                              />
                                                            </div>
                                                            {/* <div className="basic">
                                                            <Form.Check
                                                              type="checkbox"
                                                              checked={checkPermission('basic', elem2?._id)}
                                                              onChange={(e) => updatePermission({
                                                                e, el: elem2, type: 'subChild', level: 'basic', pId: elem._id, mId: el._id,
                                                              })}
                                                              disabled={loading}
                                                            />
                                                          </div>
                                                          <div className="basic">
                                                            <Form.Check
                                                              type="checkbox"
                                                              checked={checkPermission('premium', elem2?._id)}
                                                              onChange={(e) => updatePermission({
                                                                e, el: elem2, type: 'subChild', level: 'premium', pId: elem._id, mId: el._id,
                                                              })}
                                                              disabled={loading}
                                                            />
                                                          </div>
                                                          <div className="basic">
                                                            <Form.Check
                                                              type="checkbox"
                                                              checked={checkPermission('standard', elem2?._id)}
                                                              onChange={(e) => updatePermission({
                                                                e, el: elem2, type: 'subChild', level: 'standard', pId: elem._id, mId: el._id,
                                                              })}
                                                              disabled={loading}
                                                            />
                                                          </div> */}
                                                          </div>
                                                        </div>
                                                      </h5>
                                                    </div>
                                                  </li>
                                                ))}

                                              </ul>
                                            ) : null}
                                          </ul>

                                        </Card.Body>
                                      </Accordion.Collapse>
                                    )))}
                              </Card>
                            </Accordion>
                          )}
                      </>
                    ))}

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminWrapper>
  );
};
export default NewPermissionLayout;
