import React, { useEffect, useState } from 'react';
import {
  Modal, Dropdown, Button, Form, Toast,
} from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import './index.css';
import { onKeyDownNumberWithDecimcal } from '../../functions/index';

const EditChemicalSellPrice = ({
  editSellPriceStatus, handleChemicalSellPricePopup, chemicalArray, totalBuyCost, totalSellCost, handleRevertSellPriceUpdate, handleSellUpdate, handleRevertUpdate, handleSellPriceChange, consumableMargin, consumableDatabaseMargin, sellPriceStatus, levelData, checkingDrainStatus, pId, finalPriceStatus,
}) => (
  <Modal
    size="md"
    show={editSellPriceStatus}
    onHide={handleChemicalSellPricePopup}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    className="staff-modal view-appointment-popup edit-chemical-popup edit-chemical-sellprice"
  >
    <div className="staff-close">
      <i
        className="fa fa-times"
        onClick={handleChemicalSellPricePopup}
        aria-hidden="true"
      />
    </div>
    <Modal.Header>
      Edit Chemical Sell Price
    </Modal.Header>
    <Modal.Body>
      <div className="chemical-table">
        <div className="chemical-table-head">
          <h6>Quantity</h6>
          <h6>Buy cost</h6>
          <h6>Ex Gst</h6>
        </div>
        {/* {console.log('index', pId, 'chemicalArray', chemicalArray)} */}
        {chemicalArray && chemicalArray[pId]?.service?.length > 0 && chemicalArray[pId]?.service.map((el, index) => {
          let showOf = '';
          if (el?.subcategory?.includes(' of ')) {
            showOf = 'of';
          }
          return (
            <div className="chemical-table-body" key={el.category}>
              {(el?.subcategory !== '' && el?.type !== 'service' && el?.renderCost !== 'N/A' && el.chemicalDisplayName) && (
              <>
                <h6>
                  Add
                  {' '}
                  {el?.quantity}
                  {' '}
                  {el?.chemicalUnits}
                  {' '}
                  {showOf}
                  {' '}
                  {el?.chemicalDisplayName}
                </h6>
                <h6>
                  $
                  <span>{el?.buyCostProduct}</span>
                </h6>
                <h6>
                  $
                  <input
                    className={finalPriceStatus.includes(el.category) ? 'error-text' : ''}
                    type="text"
                    value={el.renderCost}
                    name="sellCost"
                    onChange={(e) => handleSellPriceChange(e, index, el.category, pId)}
                  />
                </h6>
              </>
              )}
            </div>

          );
        })}

        <div className="chemical-table-body subtotal">
          <h6>
            Sub total
          </h6>
          <h6>
            $
            <span>{totalBuyCost(chemicalArray[pId]?.service, 'multi')}</span>
          </h6>
          <h6>
            $
            <span>{totalSellCost(chemicalArray[pId]?.service, 'multi')}</span>
          </h6>
        </div>
      </div>
      <div className="bottom-text">
        <p>
          {`Database Consumable Margin: $${consumableDatabaseMargin(levelData[pId]?.service, checkingDrainStatus, levelData, 'multi', pId)}`}
        </p>
        <p>{`Updated Consumable Margin: $${consumableMargin(chemicalArray[pId]?.service, 'multi')}`}</p>
      </div>
      <div className="view-btn">
        <Button
          type="Submit"
          className="btn btn-edit"
          onClick={handleRevertSellPriceUpdate}
        >
          {' '}
          Revert to Original
          {' '}
        </Button>
        <Button
          type="Submit"
          className="btn btn-save"
          onClick={handleSellUpdate}
        >
          {' '}
          Save/Update
          {' '}
        </Button>
      </div>

    </Modal.Body>
  </Modal>
);
EditChemicalSellPrice.propTypes = {
  editSellPriceStatus: PropTypes.func.isRequired,
  handleChemicalSellPricePopup: PropTypes.func.isRequired,
  chemicalArray: PropTypes.isRequired,
  totalSellCost: PropTypes.func.isRequired,
  totalBuyCost: PropTypes.func.isRequired,
  handleRevertSellPriceUpdate: PropTypes.func.isRequired,
  handleSellUpdate: PropTypes.func.isRequired,
  handleRevertUpdate: PropTypes.func.isRequired,
  handleSellPriceChange: PropTypes.func.isRequired,
  consumableMargin: PropTypes.func.isRequired,
  consumableDatabaseMargin: PropTypes.func.isRequired,
  sellPriceStatus: PropTypes.bool.isRequired,
  levelData: PropTypes.func.isRequired,
  checkingDrainStatus: PropTypes.func.isRequired,
  pId: PropTypes.number.isRequired,
  finalPriceStatus: PropTypes.func.isRequired,
};

export default EditChemicalSellPrice;
