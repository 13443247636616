/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import './signature.css';
import logo from '../../assets/home/temp-logo.png';

function AddSignature(props) {
  const {
    handleChange, payload, showForm, handleFormModal, selectedCountry,
    handleCountry, handleAddSignature, AddImagePopup, setTemplateLogo, customObject, editId, error,
  } = props;
  console.log('error----', error);

  return (
    <Modal
      size="lg"
      show={showForm}
      onHide={handleFormModal}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal"
    >
      <div className="staff-close">
        <i className="fa fa-times" onClick={handleFormModal} aria-hidden="true" />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {editId ? 'Edit Signature' : 'Add Signature' }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">

          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicAccessLevel">
                <Form.Label>Complimentary Close</Form.Label>
                <div className="select-box">
                  <Form.Control as="select" value={payload.complimentary} onChange={handleChange} name="complimentary">
                    <option value="Yours sincerely,">Yours sincerely,</option>
                    <option value="Yours truely,">Yours truely,</option>
                    <option value="Best wishes,">Best wishes,</option>
                    <option value="Warm wishes,">Warm wishes,</option>
                    <option value="Kind wishes,">Kind wishes,</option>
                    <option value="Best regards,">Best regards,</option>
                    <option value="Warm regards,">Warm regards,</option>
                    <option value="Kind regards,">Kind regards,</option>
                    <option value="With thanks,">With thanks,</option>
                  </Form.Control>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1">
                <Form.Label>Signature</Form.Label>
                <p className={`add-images ${
                  error === 'signature'
                    ? 'error-text'
                    : ''
                }`}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      height: '80px',
                      width: '100%',
                      border: '1px solid #dcd9d9 ',
                      padding: '5px 10px',
                      cursor: 'pointer',
                      overflow: 'hidden',
                      fontSize: '12px',
                    }}
                  >
                    {customObject.text
                      ? (
                        <img
                          id="an-img"
                          style={{
                            height: 'auto',
                            maxHeight: `${customObject.style.imageHeight}%`,
                            width: `${customObject.style.imageWidth}%`,
                            objectFit: 'cover',
                            marginRight: `${customObject.style.imageMarginRight}%`,
                            marginLeft: `${customObject.style.imageMarginLeft}px`,
                            marginTop: `${customObject.style.imageMarginTop}px`,
                            marginBottom: `${customObject.style.imageMarginBottom}%`,
                            transform: `rotate(${customObject.style.imageRotate}deg)`,
                          }}
                          src={customObject.text ? customObject.text : logo}
                          alt="logo"
                        />
                      )
                      : 'Add signature image PNG (.png) or JPEG (.jpeg)' }
                  </span>
                  {/* {customObject.text
                    ? (
                      <img
                        id="an-img"
                        style={{
                          height: 'auto',
                          maxHeight: `${customObject.style.imageHeight}%`,
                          width: `${customObject.style.imageWidth}%`,
                          objectFit: 'cover',
                          marginRight: `${customObject.style.imageMarginRight}%`,
                          marginLeft: `${customObject.style.imageMarginLeft}px`,
                          marginTop: `${customObject.style.imageMarginTop}px`,
                          marginBottom: `${customObject.style.imageMarginBottom}%`,
                          transform: `rotate(${customObject.style.imageRotate}deg)`,
                        }}
                        src={customObject.text ? customObject.text : logo}
                        alt="logo"
                      />
                    )
                    : 'Add signature image PNG (.png) or JPEG (.jpeg)' } */}
                </p>
                <div className="signature-btn">
                  <Button className="delete-btn" onClick={() => AddImagePopup()}>Image Detail</Button>
                  <Button type="submit" className="save-template upload-logo">
                    <input
                      type="file"
                      id="upload"
                      onChange={(e) => setTemplateLogo(e)}
                      accept=".jpg,.png,.jpeg"
                    />
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label htmlFor="upload">Select File</Form.Label>
                    </Form.Group>
                  </Button>
                </div>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-1" className="italic-text ">
                <Form.Label>First & Last Name</Form.Label>
                <Form.Control
                  className={`${
                    error === 'first&lastname'
                      ? 'error-text'
                      : ''
                  }`}
                  type="text"
                  onChange={handleChange}
                  name="firstlastname"
                  value={payload.firstlastname}
                  placeholder="Enter First & Last Name"
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-2" className="italic-text ">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  className={`${
                    error === 'title'
                      ? 'error-text'
                      : ''
                  }`}
                  type="text"
                  onChange={handleChange}
                  name="title"
                  value={payload.title}
                  placeholder="Enter Title"
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-3" className="number italic-text">
                <Form.Label>
                  Mobile No.
                </Form.Label>
                <div className="number-field">
                  <div className="number-box">
                    <Dropdown id="mobile">
                      <Dropdown.Toggle variant="success" id="dropdown-basic1">
                        <div className="flag-icon">
                          <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                        </div>
                        {selectedCountry === '+61' ? (
                          <span>
                            Australia
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : selectedCountry === '+1' ? (
                          <span>
                            United States
                            {' '}
                            {selectedCountry}
                          </span>
                        ) : ''}
                        <span><i className="fas fa-caret-down" /></span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                          <div className="flag-icon">
                            <img src={flag} alt="flag" />
                          </div>
                          <div className="country-code">
                            <span>Australia</span>
                            +61
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                          <div className="flag-icon">
                            <img src={us} alt="flag" />
                          </div>
                          <div className="country-code">
                            {' '}
                            <span>United States</span>
                            +1
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <NumberFormat
                    className={`form-control mobile-num ${error === 'mobileNo' ? 'error-text' : ''}`}
                    format={selectedCountry === '+61' ? '### ### ###' : '### ### ####'}
                    onChange={handleChange}
                    name="mobileNo"
                    value={payload.mobileNo}
                    placeholder="Enter Mobile Number"
                    autocomplete="off"
                  />
                </div>
              </Form.Group>

            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="formBasicInput-5" className="italic-text ">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  className={`${
                    error === 'email'
                      ? 'error-text'
                      : ''
                  }`}
                  type="email"
                  onChange={handleChange}
                  name="email"
                  value={payload.email}
                  placeholder="Enter Email Address"
                />
              </Form.Group>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="view-btn">
                <Button type="Submit" className="btn btn-edit" onClick={(e) => { e.preventDefault(); handleFormModal(); }}> Cancel </Button>
                <Button type="Submit" className="btn btn-save" onClick={handleAddSignature}> Save/Update </Button>
              </div>
            </div>
          </div>

        </div>

      </Modal.Body>
    </Modal>
  );
}
AddSignature.propTypes = {
  handleChange: PropTypes.func.isRequired,
  payload: PropTypes.func.isRequired,
  showForm: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  selectedCountry: PropTypes.func.isRequired,
  handleCountry: PropTypes.func.isRequired,
  handleAddSignature: PropTypes.func.isRequired,
  AddImagePopup: PropTypes.func.isRequired,
  setTemplateLogo: PropTypes.func.isRequired,
  customObject: PropTypes.objectOf.isRequired,
  editId: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

export default AddSignature;
