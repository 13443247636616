import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TimePicker } from 'smart-webcomponents-react/timepicker';
import moment from 'moment';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';

const useForceUpdate = () => {
  const [value, setValue] = useState(0);
  return () => setValue((prevValue) => prevValue + 1);
};

const DaysPicker = ({
  setDurationPicker, subGroupFormData, setSubGroupFormData, durationValue, setDuration, handleSaveData,
}) => {
  const timepickerRef = useRef(null);
  const [active, setActive] = useState('days');

  const forceUpdate = useForceUpdate();

  const handleTime = (e) => {
    const { value } = e.detail;
    let time = moment(new Date(value).toISOString()).format('H');
    if (active === 'days') {
      if (time === '0') { time = '24'; }
    } else if (time === '0') { time = '12'; }
    let copySubGroupFormData = durationValue;
    if (active === 'days') {
      copySubGroupFormData.days = parseFloat(time);
    } else {
      copySubGroupFormData.weeks = parseFloat(time);
    }
    setDuration(copySubGroupFormData);
    forceUpdate();
  };

  const handleHourChange = (type) => {
    const fEl = document.getElementsByClassName('smart-label smart-unselectable');
    setActive(type);
    timepickerRef.current.selection = 'hour';
    if (type === 'days') {
      fEl[0].innerHTML = '12';
      fEl[0].classList.add('zero-class');
      fEl[12].innerHTML = '24';
      fEl[0].setAttribute('x', 104);
      fEl[12].setAttribute('x', 102);
      fEl[12].classList.add('other-class');
    } else {
      forceUpdate();
      fEl[0].innerHTML = '12';
      fEl[0].setAttribute('x', 104);
      fEl[0].classList.add('zero-class-min');
    }
  };
  useEffect(() => {
    setTimeout(() => {
      try {
        const fEl = document.getElementsByClassName('smart-label smart-unselectable');
        if (fEl && fEl.length) {
          fEl[0].innerHTML = '12';
          fEl[0].classList.add('zero-class');
          fEl[12].innerHTML = '24';
          fEl[0].setAttribute('x', 104);
          fEl[12].setAttribute('x', 102);
          timepickerRef.current.value = `${subGroupFormData.sendTrigger.days === '0' ? '12' : subGroupFormData.sendTrigger.days === '12' ? '24' : subGroupFormData.sendTrigger.days || '1'}:${subGroupFormData.sendTrigger.weeks}`;
        }
      } catch (e) {
        console.log('error', e);
      }
    }, 800);
  }, []);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setDurationPicker(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div
      className="duration-picker time-picker-modal"
    >
      <div>
        <div className="time-keeper-popup duration-popup automated-payment-days-picker" ref={wrapperRef}>
          <div className="custom-select-time">
            <h6
              className={`custom-hour ${active === 'days' ? 'time-active' : ''}`}
              onClick={() => handleHourChange('days')}
            >
              {durationValue.days || 1}
              <span>Days</span>
            </h6>
            <h6
              className={`custom-min ${active === 'weeks' ? 'time-active' : ''}`}
              onClick={() => handleHourChange('weeks')}
            >
              {durationValue.weeks || 0}
              <span>Weeks</span>
            </h6>
          </div>
          <TimePicker ref={timepickerRef} format={active === 'weeks' ? '12-hour' : '24-hour'} onChange={handleTime} />
          <div className="hour_minute_handle">
            <Button
              className={`hour_minute_handle_button ${active === 'days' ? 'hourbtn-active' : ''}`}
              style={{ backgroundColor: ' #1550b9' }}
              onClick={() => handleHourChange('days')}
            >
              Days
            </Button>
            <Button
              className={`hour_minute_handle_button ${active === 'weeks' ? 'hourbtn-active' : ''}`}
              style={{ backgroundColor: ' #1550b9' }}
              onClick={() => handleHourChange('weeks')}
            >
              Weeks

            </Button>
          </div>
          <div className="time-keeper-btn">
            <Button onClick={handleSaveData}>Save/Update</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
DaysPicker.propTypes = {
  setDurationPicker: PropTypes.func.isRequired,
  setSubGroupFormData: PropTypes.func.isRequired,
  durationValue: PropTypes.objectOf.isRequired,
  setDuration: PropTypes.func.isRequired,
  subGroupFormData: PropTypes.objectOf.isRequired,
  handleSaveData: PropTypes.func.isRequired,
};
export default DaysPicker;
