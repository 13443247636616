import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const UpdateSourceReading = ({
  setSoourceReading, sourceReading, handleWaterSourceChange, waterSourceSelect, handleSetSourceSelected,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          console.log('comingUnderThisSection');
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setSoourceReading(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal update-source-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <div className="pending-water-test" onClick={() => handleWaterSourceChange('waterSource')}>
              <h3>
                Water Source pH is 6.8
                {' '}
                <br />
                <p>(other chemicals - inc salt are zero) </p>
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="waterSource"
                    checked={
                      waterSourceSelect === 'waterSource' ? true : ''
                        }
                    // onChange={(e) => handleWaterSourceChange(e.target.name)}

                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleWaterSourceChange('waterSourceUpdate')}>
              <h3>Enter Water Source Chemistry</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="waterSourceUpdate"
                    checked={
                      waterSourceSelect === 'waterSourceUpdate' ? true : ''
                        }
                    onChange={(e) => handleWaterSourceChange(e.target.name)}

                  />
                </div>
              </div>
            </div>
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={() => { setSoourceReading(!sourceReading); }}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleSetSourceSelected()}>
                Select
              </Button>
            </div>

          </div>

        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>

  );
};
UpdateSourceReading.propTypes = {
  sourceReading: PropTypes.func.isRequired,
  setSoourceReading: PropTypes.func.isRequired,
  handleWaterSourceChange: PropTypes.func.isRequired,
  waterSourceSelect: PropTypes.func.isRequired,
  handleSetSourceSelected: PropTypes.func.isRequired,
};
export default UpdateSourceReading;
