import React, { useContext, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './template.css';
import showmore from '../../assets/home/showmoreblue.png';
import QuoteTemplate from '../quoteTemplates/index';
import InvoiceTemplate from './invoiceTemplate';
import SignatureTemplate from '../signatureTemplate';
import { TemplateFeatures } from '../../contexts/TemplateFeatureContext';
import SaveTemplateConfirmPopup from './save-template-confirm-popup';
import { userInstance, notTimeOutUserInstance } from '../../config/axios';
import DocxInvoiceTemplate from '../docxTemplate/docxInvoice';
import InvoiceV2Template from './invoiceV2Template';

const EditMainTemplate = () => {
  const {
    isNewChange,
    saveTemplateConfirmPopup,
    setDummyMainPageView,
    setSaveTemplateConfirmPopup,
    isParentClickDropdown,
    setIsParentClickDropdown,
    mainView: view,
    setMainView: setView,
  } = useContext(TemplateFeatures);

  const handleTemplateType = (type) => {
    if (isNewChange && view !== type) {
      setIsParentClickDropdown(true);
      setDummyMainPageView(type);
      if (!saveTemplateConfirmPopup) {
        setSaveTemplateConfirmPopup(true);
      }
      return;
    }
    setView(type);
  };

  const handleDownload = async () => {
    try {
      setView('docxinvoice');
      // const response = await userInstance().post('/invoice/downloadInvoicedocx', { responseType: 'blob' });
      // console.log('response ==>', response);
      // const { url } = response.data;
      // const link = document.createElement('a');
      // link.href = url;
      // link.download = 'filename767676.docx';
      // link.click();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AdminWrapper>
        <div className="pool-set-up invoices-list invoice-detail search-invoice template-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="main-titles-mob">
                  <h3>
                    {view === 'QuoteTemplate'
                      ? 'Quote Templates'
                      : view === 'signature'
                        ? 'Signature Templates for Quotes'
                        : 'Invoice Templates'}
                    {' '}
                  </h3>
                  <div className="content-dropdown">
                    <div className="client-option service-edit-icon">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={showmore} alt="show" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => handleTemplateType('NewInvoiceTemplate')}
                          >
                            <div className="action">
                              <span>New Invoice Templates (.html)</span>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleTemplateType('InvoiceTemplate')}
                          >
                            <div className="action">
                              <span>Invoice Templates (.html)</span>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleDownload('InvoiceTemplatedocx')}
                          >
                            <div className="action">
                              <span>Invoice Templates (.docx)</span>
                            </div>
                          </Dropdown.Item>

                          {/* <Dropdown.Item
                            onClick={() => handleTemplateType('OrderTemplate')}
                          >
                            <div className="action">
                              <span>Purchase Order Templates</span>
                            </div>
                          </Dropdown.Item> */}
                          <Dropdown.Item
                            onClick={() => handleTemplateType('QuoteTemplate')}
                          >
                            <div className="action">
                              <span>Quote Templates (.html)</span>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => handleTemplateType('QuoteTemplatedocx')}
                          >
                            <div className="action">
                              <span>Quote Templates (.docx)</span>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleTemplateType('signature')}
                          >
                            <div className="action">
                              <span>
                                Signature Templates for Quotes (.html)
                              </span>
                            </div>
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => handleTemplateType('signature')}
                          >
                            <div className="action">
                              <span>
                                Signature Templates for Quotes (.docx)
                              </span>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {view === 'docxinvoice' ? (
                  <DocxInvoiceTemplate />
                ) : view === 'QuoteTemplate' ? (
                  <QuoteTemplate viewtype={view} />
                ) : view === 'signature' ? (
                  <SignatureTemplate />
                ) : view === 'NewInvoiceTemplate' ? (
                  <InvoiceV2Template />
                ) : (
                  <InvoiceTemplate />
                )}
              </div>
            </div>
          </div>
        </div>
        {saveTemplateConfirmPopup && isParentClickDropdown && (
          <SaveTemplateConfirmPopup isMain setSaveTemp={() => {}} />
        )}
      </AdminWrapper>
    </>
  );
};

export default EditMainTemplate;
