import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const CheckSetUpPermissions = ({
  name, viewName, updateView, imageSource, classType, activeLink,
}) => {
  const {
    userData,
  } = useContext(AuthDataContext);
  const check = userData.permissions.findIndex((el) => el.action === name);
  if (check === -1) return null;

  if (activeLink) {
    return (
      <div
        className={`${classType} ${viewName === activeLink ? 'active-menu' : ''}`}
        onClick={() => updateView(viewName)}
      >
        <img src={imageSource} alt="edit" />
        {name}
      </div>
    );
  }

  return (
    <div
      className={classType}
      onClick={() => updateView(viewName)}
    >
      <span><img src={imageSource} alt="edit" /></span>
      <span>{` ${name}`}</span>
    </div>
  );
};

CheckSetUpPermissions.propTypes = {
  name: PropTypes.string.isRequired,
  viewName: PropTypes.string.isRequired,
  updateView: PropTypes.func.isRequired,
  imageSource: PropTypes.string.isRequired,
  classType: PropTypes.string.isRequired,
  activeLink: PropTypes.bool.isRequired,
};

export default CheckSetUpPermissions;
