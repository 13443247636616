/* eslint-disable no-fallthrough */

import React, { useState, useContext, useEffect } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import showmore from '../../assets/home/showmoreblue.png';
import InventoryProductList from './inventoryProductList';
import SearchInventory from './searchinventory';
import DisplayInventory from './displayInventory';
import NonInventoryList from './NonInventoryList';
import InventoryAdjustment from './inventroyAdjustment';
import SearchResult from './searchResult';
import InventorySearchDetails from './inventorySearchDetails';
import PredictiveInventorySearch from './predictiveInventorySearch';
import PredictiveOrderingDetail from './predictiveOrderingDetail';
import Loader from '../loader/loader';
import './inventory.css';
import './web.css';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { showErrMsg } from '../../functions/index';
import { userInstance } from '../../config/axios';
import target from '../../assets/home/icon/target.png';

const Inventory = () => {
  const { userData: { industryId } } = useContext(AuthDataContext);
  const [view, setView] = useState('InventoryProductList');
  const [searchData, setSearchData] = useState([]);
  const [productList, setProductList] = useState();
  const [searchDataPredective, setSearchDataPredective] = useState([]);
  const [singleProduct, setSingleProduct] = useState('');
  const [loading, setLoading] = useState(false);
  const [servicesList, setServices] = useState([]);
  const [nonInvProductList, setNonInvProductList] = useState([]);
  const [previousView, setPreviousView] = useState('InventoryProductList');

  const getServices = async () => {
    try {
      setLoading(true);
      const response = await userInstance().get(`/inventory/getInventory?industryId=${industryId?._id}`);
      const { products, msg } = response.data;
      if (msg === 'Success') {
        setServices(products);
        setLoading(false);
      }
    } catch (error) {
      showErrMsg(error);
      setLoading(false);
    }
  };
  const getNonInventory = async () => {
    const response = await userInstance().get(`/inventory/getNonInventory?industryId=${industryId?._id}`);
    const { status, data } = response;
    if (status === 200) {
      setNonInvProductList(data.data);
    }
  };

  const updateView = (key, type) => {
    if (type !== 'goBack') {
      setView(key);
    } else {
      setView(previousView);
    }
    if (key !== 'InventoryAdjustment') {
      setPreviousView(key);
    }
  };
  useEffect(() => {
    getServices();
    getNonInventory();
  }, []);
  const renderView = () => {
    let componenettorender = null;
    switch (view) {
      case 'InventoryProductList':
        componenettorender = (
          <InventoryProductList servicesList={servicesList} getServices={getServices} setServices={setServices} />
        );
        break;
      case 'NonInventoryProductList':
        componenettorender = (
          <NonInventoryList updateView={updateView} getNonInventory={getNonInventory} nonInvProductList={nonInvProductList} setNonInvProductList={setNonInvProductList} />
        );
        break;
      case 'InventorySearch':
        componenettorender = (
          <SearchInventory updateView={updateView} setSearchData={setSearchData} />
        );
        break;
      case 'InventorySearchResult':
        componenettorender = (
          <SearchResult updateView={updateView} searchData={searchData} setProductList={setProductList} singleProductId={singleProduct} />
        );
        break;
      case 'Inventory Search Detail':
        componenettorender = (
          <InventorySearchDetails updateView={updateView} productList={productList} setProductList={setProductList} setSingleProduct={setSingleProduct} />
        );
        break;
      case 'PredictiveOrderingSummary':
        componenettorender = (
          <PredictiveInventorySearch updateView={updateView} setSearchDataPredective={setSearchDataPredective} setLoading={setLoading} />
        );
        break;
      case 'InventoryAdjustment':
        componenettorender = (
          <InventoryAdjustment updateView={updateView} />
        );
        break;

      case 'Predictive Ordering Detail':
        componenettorender = (
          <PredictiveOrderingDetail updateView={updateView} searchDataPredective={searchDataPredective} />
        );
        break;
      case 'Inventory Adjustment':
        componenettorender = (
          <InventoryAdjustment />
        );
      default:
    }
    return componenettorender;
  };

  return (
    <AdminWrapper>
      { !loading ? (
        <>
          <div className="reports-page staff-container inventory-page">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-titles-mob">
                    <h3>Inventory</h3>
                    <div className="content-dropdown">
                      <div className="client-option service-edit-icon">
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <img src={showmore} alt="show" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('InventoryProductList')}>
                                <span>Inventory Product List</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('NonInventoryProductList')}>
                                <span>Non-Inventory Product List</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('InventorySearch')}>
                                <span>Inventory Search</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('PredictiveOrderingSummary')}>
                                <span>Predictive Ordering Summary</span>
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <div className="action" onClick={() => updateView('InventoryAdjustment')}>
                                <span>Inventory Adjustment</span>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  <div className="main-titles-desktop">
                    <div className="dropdown-option-section">
                      <div className={`option-list ${view === 'InventoryProductList' ? 'active-menu' : ''}`} onClick={() => updateView('InventoryProductList')}>
                        <img src={target} alt="edit" />
                        Inventory Product List
                      </div>
                      <div className={`option-list ${view === 'NonInventoryProductList' ? 'active-menu' : ''}`} onClick={() => updateView('NonInventoryProductList')}>
                        <img src={target} alt="edit" />
                        Non-Inventory Product List
                      </div>
                      <div className={`option-list ${view === 'InventorySearch' ? 'active-menu' : ''}`} onClick={() => updateView('InventorySearch')}>
                        <img src={target} alt="edit" />
                        Inventory Search
                      </div>
                      <div className={`option-list ${view === 'PredictiveOrderingSummary' ? 'active-menu' : ''}`} onClick={() => updateView('PredictiveOrderingSummary')}>
                        <span>Predictive Ordering Summary</span>
                      </div>
                      <div className={`option-list ${view === 'InventoryAdjustment' ? 'active-menu' : ''}`} onClick={() => updateView('InventoryAdjustment')}>
                        <span>Inventory Adjustment</span>
                      </div>
                    </div>
                  </div>
                  { renderView()}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : <Loader />}
    </AdminWrapper>

  );
};
export default Inventory;
