import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { userInstance } from '../../config/axios';
import logo from '../../assets/home/logo-pool-2.png';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import showmore from '../../assets/home/showmoreblue.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PreviewInvoicePopup2 = ({
  showInvoice, handleInvoicePopup, invoiceData, chemicalsArray, handleClose, previewUrl, VoidInvoice, specificDropDown, handleSpecificInvoice, addappt, setAddAppt, setspecificDropDown,
}) => {
  const {
    invoiceType, invoiceUrl,
  } = invoiceData;
  // console.log({ invoiceUrl, previewUrl });
  const [message, setMsg] = useState('No name to appear in Invoice detail');
  const [typeInvoice, setTypeInvoice] = useState(invoiceType);
  const [numPages, setNumPages] = useState(null);
  const [view, setView] = useState('editTemplate');
  const [pageNumber, setPageNumber] = useState(1);
  const [templateArray, setTemplateArray] = useState([]);
  const [defaultTemp, setdefaultTemp] = useState();
  const getAllTemplate = async (info) => {
    try {
      const viewSection = info || 'InvoiceTemplate';
      const response = await userInstance().get('/template/getAllTemplate?type=Invoice');
      const { code, msg, temp } = response.data;
      if (msg === 'Success') {
        const defaultTemp1 = temp.find((el, i) => el.default);
        if (defaultTemp1) {
          setdefaultTemp(defaultTemp1._id);
        } else {
          setdefaultTemp(temp[0]._id);
        }
      }
      setTemplateArray(temp);
    } catch (error) {
      console.log(error);
    }
  };
  const changeTemplate = (value1) => {
    const changedTemplate = templateArray.find((el) => el._id === value1);
    setdefaultTemp(changedTemplate._id);
    handleSpecificInvoice(changedTemplate._id);
  };

  useEffect(() => {
    if (specificDropDown === true) {
      getAllTemplate();
    }
  }, [specificDropDown]);
  const handleChange = (value) => {
    setAddAppt(value);
    setspecificDropDown(!specificDropDown);
    handleInvoicePopup(value);
  };
  return (
    <>
      <Modal
        size="md"
        show={showInvoice}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="search-modal new-search-popup"
      >
        <div className="staff-close">
          <i className="fa fa-times" onClick={handleClose} aria-hidden="true" />
        </div>
        <Modal.Body>
          <div className="appointment-container preview-invoice preview-popup pool-setup preview-popup-new">
            <div className="container" />
            <div className="row">
              <div className="col-md-12">
                {addappt && (addappt === 'specificTemp' || addappt === 'defaultTemp')
                && (
                <div className="main-titles-mob">
                  <h3>
                    Invoice Templates
                  </h3>
                  <div className="content-dropdown">
                    <div className="client-option service-edit-icon">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={showmore} alt="show" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {addappt === 'specificTemp'
                            ? (
                              <Dropdown.Item onClick={() => handleChange('defaultTemp')}>
                                <div className="action">
                                  <span>Default Invoice Template</span>
                                </div>
                              </Dropdown.Item>
                            )
                            : (
                              <Dropdown.Item onClick={() => handleChange('specificTemp')}>
                                <div className="action">
                                  <span>Select Specific Template</span>
                                </div>
                              </Dropdown.Item>
                            )}

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                )}

                {specificDropDown && (
                <div className="target-heading client-profile">

                  <Form.Group controlId="formBasicCompanyType">
                    <Form.Label>
                      Template Name
                    </Form.Label>
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="customerType"
                        value={defaultTemp}
                        onChange={(e) => changeTemplate(e.target.value)}
                      >
                        {templateArray && templateArray.map((temp) => (
                          <option value={temp._id}>{temp?.default ? `${temp.templateName}-default` : temp.templateName}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>

                </div>
                )}
                {invoiceUrl || previewUrl ? (
                  <div className="add-appointment">
                    <TransformWrapper>
                      <TransformComponent>
                        <Document
                          file={invoiceUrl || previewUrl}
                          onLoadError={(error) => console.log(`Error while loading document! ${error.message}`)}
                        >
                          <Page
                            pageNumber={pageNumber}
                          />
                        </Document>
                      </TransformComponent>
                    </TransformWrapper>
                  </div>

                ) : (
                  <p> PDF Loading... </p>
                )}

                {invoiceData && invoiceType && invoiceUrl
                && (
                <div className="footer-btn view-btn">
                  <Button className="btn-delete" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button className="appointment-btn btn-save" onClick={VoidInvoice}>
                    Void Invoice
                  </Button>
                </div>
                )}

              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>

    </>
  );
};
PreviewInvoicePopup2.propTypes = {
  showInvoice: PropTypes.func.isRequired,
  handleInvoicePopup: PropTypes.bool.isRequired,
  invoiceData: PropTypes.func.isRequired,
  chemicalsArray: PropTypes.arrayOf.isRequired,
  handleClose: PropTypes.func.isRequired,
  previewUrl: PropTypes.string.isRequired,
  VoidInvoice: PropTypes.func.isRequired,
  specificDropDown: PropTypes.bool.isRequired,
  handleSpecificInvoice: PropTypes.func.isRequired,
  addappt: PropTypes.string.isRequired,
  setAddAppt: PropTypes.func.isRequired,
  setspecificDropDown: PropTypes.func.isRequired,
};
export default PreviewInvoicePopup2;
