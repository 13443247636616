import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Nav, Tabs, Tab,
} from 'react-bootstrap';
// import logo from '../../assets/home/temp-logo.png';
const logo = '';

const EditNewImage = ({
  showPopup, handleImagePopup, setTemplateDat, text, customName, setText, customNameTwo, handleShow,
  customFeilds, setCustomFeilds,
}) => {
  const [selectScaling, setSelectScaling] = useState('Original');
  const [customObject, setCustomObject] = useState({});
  useEffect(() => {
    let oldObject = [...customFeilds];
    let customcheck = { ...oldObject[customNameTwo] };

    setCustomObject(customcheck);
  }, []);
  const setTemplateData = (data) => {
    const { name, value } = data.target;
    let oldState = [...customFeilds];
    let { style } = oldState[customNameTwo];
    let oldStyleObject = { ...style };
    oldStyleObject[name] = value;
    oldState[customNameTwo].style = oldStyleObject;
    setCustomObject({ ...customObject, style: oldStyleObject });
    setCustomFeilds(oldState);
  };

  const setSelectScalingValue = (info) => {
    setSelectScaling(info);
    if (info === 'Original') {
      let oldState = [...customFeilds];
      let { style } = oldState[customNameTwo];
      let oldStyleObject = { ...style };
      oldStyleObject.imageHeight = 100;
      oldStyleObject.imageWidth = 100;
      oldState[customNameTwo].style = oldStyleObject;
      customFeilds(oldState);
    }
  };

  const setTemplateLogo = (data) => {
    let oldState = [...customFeilds];
    oldState[customNameTwo].text = window.URL.createObjectURL(data.target.files[0]);
    setCustomFeilds(oldState);
  };

  const removePicture = () => {
    let oldState = [...customFeilds];
    oldState[customNameTwo].text = logo;
    setCustomFeilds(oldState);
  };
  const deleteFeild = () => {
    const oldObject = [...customFeilds];
    oldObject.splice(customNameTwo, 1);
    setCustomFeilds(oldObject);
    handleImagePopup();
  };

  const setNewText = () => {
    handleImagePopup();
  };
  const setOpacity = (info) => {
    let oldState = [...customFeilds];
    oldState[customNameTwo].zIndex = parseInt(info, 10);
    setCustomFeilds(oldState);
  };
  return (
    <Modal
      size="md"
      show={showPopup}
      onHide={handleImagePopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup"
    >
      {/* <div className="staff-close">
        <i className="fa fa-times" onClick={handleImagePopup} aria-hidden="true" />
      </div> */}
      <Modal.Body>
        <div className="appointment-container preview-invoice preview-popup">
          <div className="change-image-section">
            <div className="field-location-section">
              <div className="preview-image">
                <h6>Preview Picture</h6>
                <span
                  style={{
                    display: 'inline-block',
                    height: '80px',
                    width: '100%',
                    border: '1px solid #dcd9d9 ',
                    padding: '10px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <img
                    id="an-img"
                    style={{
                      maxHeight: `${customFeilds[customNameTwo].style.imageHeight}%`,
                      height: 'auto',
                      width: `${customFeilds[customNameTwo].style.imageWidth}%`,
                      objectFit: 'contain',
                      marginRight: `${customFeilds[customNameTwo].style.imageMarginRight}%`,
                      left: `${customFeilds[customNameTwo].style.imageMarginLeft}px`,
                      top: `${customFeilds[customNameTwo].style.imageMarginTop}px`,
                      marginBottom: `${customFeilds[customNameTwo].style.imageMarginBottom}%`,
                      transform: `rotate(${customFeilds[customNameTwo].style.imageRotate}deg)`,
                    }}
                    src={customFeilds[customNameTwo].text ? customFeilds[customNameTwo].text : logo}
                    alt="logo"
                  />
                </span>
              </div>
              <div className="template-btn">
                <Button className="delete-btn" onClick={() => removePicture()}>Remove Picture</Button>
                <Button type="submit" className="save-template upload-logo">
                  <input
                    type="file"
                    id="upload"
                    onChange={(e) => setTemplateLogo(e)}
                  />
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label htmlFor="upload">Upload Picture</Form.Label>
                  </Form.Group>
                </Button>
              </div>
              {/* <div className="field-location">
                <h6>Image Location</h6>
                <div className="image-size">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from Top</Form.Label>
                    <Form.Control type="number" name="imageMarginTop" value={customFeilds[customNameTwo].style.imageMarginTop} onChange={(e) => setTemplateData(e)} />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Distance from left</Form.Label>
                    <Form.Control type="number" name="imageMarginLeft" value={customFeilds[customNameTwo].style.imageMarginLeft} onChange={(e) => setTemplateData(e)} />
                  </Form.Group>
                </div>
              </div> */}

              <div className="field-location">
                <h6>Image Opacity</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="imagetype"
                        value={customFeilds[customNameTwo].style.zIndex}
                        onChange={(e) => setOpacity(e.target.value)}
                      >
                        <option value="9">Set Image behind type</option>
                        <option value="99">Set Image in front of type</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
                <h6>Image Size</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="customerType"
                        value={selectScaling}
                        onChange={(e) => setSelectScalingValue(e.target.value)}
                      >
                        <option value="Original">Fixed Percentage of Original</option>
                        <option value="Custom">Custom Height & Width</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>

                <div className="image-size">
                  {selectScaling === 'Original' ? (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling</Form.Label>
                        <Form.Control type="number" name="imageHeight" value={customFeilds[customNameTwo].style.imageHeight} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={customFeilds[customNameTwo].style.imageRotate} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Height</Form.Label>
                        <Form.Control type="number" name="imageHeight" value={customFeilds[customNameTwo].style.imageHeight} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Width</Form.Label>
                        <Form.Control type="number" name="imageWidth" value={customFeilds[customNameTwo].style.imageWidth} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={customFeilds[customNameTwo].style.imageRotate} onChange={(e) => setTemplateData(e)} />
                      </Form.Group>
                    </>
                  )}

                </div>
              </div>
              <div className="template-btn">
                <Button onClick={deleteFeild} className="delete-btn">Delete Field</Button>
                <Button className="save-btn" onClick={setNewText}>Save/ Update</Button>
              </div>
              {/* <div className="field-location">
                <h6>Actual Size</h6>
                <div className="image-size">
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Height</Form.Label>
                    <Form.Control type="number" name="imageHeight" value={templateDataCopy.imageActualHeight} />
                    <span>%</span>
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Width</Form.Label>
                    <Form.Control type="number" name="imageWidth" value={templateDataCopy.imageActualWidth} />
                    <span>%</span>
                  </Form.Group>
                </div>
              </div>
            */}
            </div>
            {/* <div className="change-section-btn">
              <Button onClick={Cancel}>Cancel</Button>
              <Button type="submit">Paste Picture</Button>
              <Button type="submit" className="upload-logo">
                <input type="file" id="upload" onChange={(e) => setTemplateLogo(e)} />
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label htmlFor="upload"> Load Picture</Form.Label>
                </Form.Group>
              </Button>
              <Button onClick={setData}>OK</Button>
            </div> */}
          </div>

        </div>
      </Modal.Body>
    </Modal>

  );
};
EditNewImage.propTypes = {
  showPopup: PropTypes.func.isRequired,
  handleImagePopup: PropTypes.bool.isRequired,
  setTemplateDat: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  customName: PropTypes.string.isRequired,
  setText: PropTypes.func.isRequired,
  customNameTwo: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
  setCustomFeilds: PropTypes.func.isRequired,
};
export default EditNewImage;
