import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Spinner } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const SaveTemplatePopup = ({
  setSavePopup, handleDataPopup, templateName, saveTemplate, type, isloading,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal save-template-popup') {
            setSavePopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal save-template-popup "
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <h5>
            Create New
            {' '}
            {type || 'Invoice'}
            {' '}
            Template
          </h5>
          <Form.Group controlId="formBasicInput-1">
            <Form.Label>
              Name of New
              {' '}
              {type || 'Invoice'}
              {' '}
              Template
            </Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              placeholder="Enter Name of Template"
              value={templateName}
              onChange={(e) => handleDataPopup(e.target.value)}
            />
          </Form.Group>
          <div className="template-btn view-btn">
            <Button className="cancel-btn" onClick={() => setSavePopup(false)}>Cancel</Button>
            <Button className="save-btn" onClick={() => saveTemplate()}>
              {isloading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : ' Save/ Update'}

            </Button>
          </div>
        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>

  );
};
SaveTemplatePopup.propTypes = {
  setSavePopup: PropTypes.func.isRequired,
  handleDataPopup: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isloading: PropTypes.bool.isRequired,
};
export default SaveTemplatePopup;
