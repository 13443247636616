/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable radix */
/* eslint-disable no-console */
import moment from 'moment';
import { array } from 'prop-types';
import axios from 'axios';
import { userInstance } from '../config/axios';
import { errorMsgTime, successMsgTime } from './intervalTime';
import { emailRegex } from '../config/keys';
import toastMessage from './toastMessage';

// eslint-disable-next-line arrow-body-style
// eslint-disable-next-line import/prefer-default-export

export const checkNumberReg = new RegExp('^[0-9]+$');

export const onKeyDownNumberWithDecimcal = (event) => {
  // console.log('envesd', event);
  if (
    event.key === '+'
    || event.key === '-'
    || event.key === 'e'
    || event.key === '.'
  ) {
    event.preventDefault();
  }
};
export const showErrMsg = (error) => {
  let message = 'Request could not be fulfilled.';
  if (error.response) {
    const { msg } = error.response.data;
    message = msg;
  }
  toastMessage('error', message);
};

export const validateDataWithKey = (data) => {
  let isValid = true;
  let emptyField = '';
  Object.keys(data).forEach((el) => {
    if (isValid) {
      if (!data[el]) {
        isValid = false;
        emptyField = el;
      }
    }
  });
  return { isValid, emptyField };
};

export const validateDataWithKeyWithZeroValue = (data) => {
  let isValid = true;
  let emptyField = '';
  Object.keys(data).forEach((el) => {
    if (isValid) {
      if (data[el] === 0) {
        // console.log('el', data[el]);
        isValid = false;
        emptyField = el;
      }
    }
  });
  return { isValid, emptyField };
};

const convetIntoMinute = (time) => {
  const arr = time.split(':');
  return parseInt(arr[0]) * 60 + parseInt(arr[1]);
};
const timeSplit = (time) => {
  let splitData = time.toString().split(':');
  let hours = splitData[0];
  let minutes = splitData[1];
  return { hours, minutes };
};
const getEndingTime = (startTime, workingTime) => {
  const time1 = convetIntoMinute(startTime);
  const time2 = parseInt(workingTime);
  const totalTime = time1 + time2;
  let hour = parseInt(totalTime / 60, 10);
  if (hour >= 24) {
    hour -= 24;
  }
  const minute = totalTime % 60;
  return `${hour}:${minute}`;
};
const formatTime = (time, index) => {
  const { appId, eventDate } = queryString();
  let splitTime = time.split(':');

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  var date = new Date();

  let m = moment(date.addDays(index));
  m.set({
    hour: splitTime[0],
    minute: splitTime[1],
    second: 0,
    millisecond: 0,
  }).toDate();
  m = appId ? eventDate : m.format();
  return m;
};
export const calculateEndingTimeArr = (setState, index, levelData, type) => {
  // console.log('index', index, 'setState', setState);
  // setState(() => {
  // console.log('prevState', levelData);
  let mainState = JSON.parse(JSON.stringify(levelData));
  let copyState = [...mainState[index].service];
  copyState.map((el, i) => {
    if (i === 0) {
      el.endTime = getEndingTime(el.startTime, el.duration);
      el.start = formatTime(el.startTime, index);
      el.end = formatTime(el.endTime, index);
      return el;
    }
    el.startTime = copyState[i - 1].endTime;
    el.endTime = getEndingTime(el.startTime, el.duration);
    el.start = formatTime(el.startTime, index);
    el.end = formatTime(el.endTime, index);
    return el;
  });
  // console.log('newCopyState', copyState);
  mainState[index].service = copyState;
  // console.log('actualdata', mainState);
  if (!type) {
    setState(() => [...mainState]);
  }
  return [...copyState];

  // });
};

export const calculateEndingTimeArrSandFilter = (setState, levelData) => {
  setState(() => {
    let copyState = [...levelData];
    // console.log('copyState', copyState);
    copyState.map((el, i) => {
      if (i === 0) {
        el.endTime = getEndingTime(el.startTime, el.duration);
        el.start = formatFilterTime(el.startTime);
        el.end = formatFilterTime(el.endTime);
        return el;
      }
      el.startTime = copyState[i - 1].endTime;
      el.endTime = getEndingTime(el.startTime, el.duration);
      el.start = formatFilterTime(el.startTime);
      el.end = formatFilterTime(el.endTime);
      return el;
    });
    return [...copyState];
  });
};

const formatFilterTime = (time, index) => {
  let splitTime = time.split(':');

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  var date = new Date();

  let m = moment(date.addDays(0));
  m.set({
    hour: splitTime[0],
    minute: splitTime[1],
    second: 0,
    millisecond: 0,
  }).toDate();

  return m.format();
};

export const checkAuth = (accessLevel, path, permissions, role) => {
  let isAuthorized = false;
  let comparePath = path;

  const filteredPermissions = permissions.filter(
    (el) => el.route === comparePath,
  );
  if (filteredPermissions.length) {
    isAuthorized = true;
  }
  return isAuthorized;
};

export const queryString = () => {
  let query = window.location.search.substr(1);
  let resultval = {};
  query.split('&').forEach((part) => {
    let item = part.split('=');
    resultval[item[0]] = decodeURIComponent(item[1]);
  });
  return resultval;
};

export const dateRange = (startDate, endDate, steps = 1) => {
  const dateArray = [];
  let currentDates = new Date(startDate);
  // let cc = 1;
  while (currentDates <= new Date(endDate)) {
    dateArray.push(new Date(currentDates));
    // Use UTC date to prevent problems with time zones and DST
    currentDates.setUTCDate(currentDates.getUTCDate() + steps);
    // console.log('index==>', cc, currentDates);
    // cc++;
  }
  return dateArray;
};
const datediff = (first, second) => Math.round((second - first) / (1000 * 60 * 60 * 24));
export const timeIntervalDay = (
  occurence,
  interval,
  type,
  endType,
  startDate,
  specificDate,
) => {
  if (type === null) {
    let obj = {
      interval: parseInt(1),
      intervalSetp: 1,
      eventEndDate: startDate,
    };
    return obj;
  }
  if (type === 'weekly') {
    if (endType === 'On a Specific Date') {
      let diffDays = datediff(new Date(startDate), new Date(specificDate));
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(
        new Date(startDate).getDate() + parseInt(diffDays) + 1,
      );
      let obj = {
        interval: parseInt(diffDays),
        intervalSetp: parseInt(7 * interval),
        eventEndDate,
      };
      return obj;
    }
    if (endType === 'Ongoing') {
      let intervalAllDay = (occurence - 1) * interval;
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(new Date(startDate).getDate() + parseInt(365));
      let obj = {
        interval: parseInt(intervalAllDay),
        intervalSetp: parseInt(7 * interval),
        eventEndDate,
      };
      return obj;
    }
    let intervalAllDay = (occurence - 1) * interval * 7;
    let eventEndDate = new Date(startDate);
    eventEndDate.setDate(
      new Date(startDate).getDate() + parseInt(intervalAllDay) + 1,
    );
    let obj = {
      interval: parseInt(intervalAllDay),
      intervalSetp: parseInt(7 * interval),
      eventEndDate,
    };
    return obj;
  }
  if (type === 'daily') {
    if (endType === 'On a Specific Date') {
      let diffDays = datediff(new Date(startDate), new Date(specificDate));
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(
        new Date(startDate).getDate() + parseInt(diffDays) + 1,
      );
      let obj = {
        interval: parseInt(diffDays),
        intervalSetp: interval,
        eventEndDate,
      };
      return obj;
    }
    if (endType === 'Ongoing') {
      let intervalAllDay = (occurence - 1) * interval;
      let eventEndDate = new Date(startDate);
      // console.log('startDate', startDate);
      eventEndDate.setDate(new Date(startDate).getDate() + parseInt(365));
      let obj = {
        interval: parseInt(intervalAllDay),
        intervalSetp: interval,
        eventEndDate,
      };
      return obj;
    }
    let intervalAllDay = (occurence - 1) * interval;
    let eventEndDate = new Date(startDate);
    eventEndDate.setDate(
      new Date(startDate).getDate() + parseInt(intervalAllDay),
    );
    let obj = {
      interval: parseInt(intervalAllDay),
      intervalSetp: interval,
      eventEndDate,
    };
    return obj;
  }
  if (type === 'monthly') {
    let newDate = new Date(startDate);
    newDate = new Date(
      moment(newDate, 'DD-MM-YYYY')
        .add(parseInt(parseInt(occurence * interval), 10), 'months')
        .format('L'),
    );
    if (endType === 'On a Specific Date') {
      let diffDays = new Date(specificDate).getDate() - new Date(startDate).getDate();
      let obj = {
        interval: parseInt(diffDays),
        intervalSetp: parseInt(30 * interval),
        eventEndDate: newDate,
      };
      return obj;
    }
    if (endType === 'Ongoing') {
      let intervalAllDay = (occurence - 1) * interval * 30;
      let eventEndDate = new Date(startDate);
      eventEndDate.setDate(new Date(startDate).getDate() + parseInt(365));
      let obj = {
        interval: parseInt(intervalAllDay),
        intervalSetp: parseInt(7 * interval),
        eventEndDate,
      };
      return obj;
    }
    let intervalAllDay = (occurence - 1) * interval * 30;
    let obj = {
      interval: parseInt(intervalAllDay),
      intervalSetp: parseInt(30 * interval),
      eventEndDate: newDate,
    };
    return obj;
  }
};

export const eventDateRange = (startDate, endDate, steps = 1, id, type) => {
  const dateArray = [];
  let currentDates = new Date(startDate);
  currentDates.setHours(0, 0, 0, 0);
  while (currentDates <= new Date(endDate)) {
    let eventDate = moment(currentDates, 'YYYY-MM-DD').toLocaleString();
    eventDate = moment(eventDate).format('YYYY-MM-DD');
    let myEvetnt = {
      date: new Date(eventDate).toISOString(),
      status: 'booked',
      appointmentid: id,
    };
    dateArray.push(myEvetnt);
    if (type === 'monthly') {
      currentDates.setMonth(currentDates.getMonth() + steps);
    } else {
      currentDates.setDate(currentDates.getDate() + steps);
    }
  }
  return dateArray;
};
export const getCalendarPath = () => {
  let staff_id = localStorage.getItem('staff_id');
  let date = localStorage.getItem('date');
  let view = localStorage.getItem('view');
  let backPreDate = localStorage.getItem('prebackDate') || '';
  let calendarUrl = `/calendar?activedate=${
    date
      ? moment(new Date(date)).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD')
  }&view=${view || 'timeGridWeek'}&staff_id=${
    staff_id || ''
  }&appointmentId=${backPreDate}`;
  return calendarUrl;
};
export const blockTimeRange = (
  startDate,
  endDate,
  steps = 1,
  duration,
  type,
  startTime,
) => {
  const dateArray = [];
  let currentDates = new Date(startDate);
  // let time = startTime.split(':');
  while (currentDates <= new Date(endDate)) {
    // let start = moment(currentDates).toDate();
    // start.setHours(time[0]);
    // start.setMinutes(time[1]);
    // start.setSeconds(0);
    // let eventDate = moment(currentDates).toLocaleString();
    // eventDate = moment(eventDate).format('YYYY-MM-DDTHH:mm:ssZ');
    let eventDate = moment(currentDates).format('YYYY-MM-DD');
    // const newendDate = new Date(eventDate);
    // const updatedNextEntryStart = moment(
    //   new Date(newendDate.setMinutes(newendDate.getMinutes() + parseFloat(duration))),
    // ).format('YYYY-MM-DDTHH:mm:ssZ');
    // let myEvetnt = {
    //   startblocktime: new Date(eventDate).toISOString(),
    //   endblocktime: updatedNextEntryStart,
    // };
    dateArray.push(new Date(eventDate).toISOString());
    if (type === 'monthly') {
      currentDates.setMonth(currentDates.getMonth() + steps);
    } else {
      currentDates.setDate(currentDates.getDate() + steps);
    }
  }
  return dateArray;
};
export const updateServerDate = (services, currentDates) => {
  // console.log('servicesservices', currentDates, services);
  services.forEach((el) => {
    let start = moment(currentDates).toDate();
    const time = el.startTime.split(':');
    start.setHours(time[0]);
    start.setMinutes(time[1]);
    start.setSeconds(0);
    el.start = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
    el.end = moment(
      new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
    ).format('YYYY-MM-DDTHH:mm:ssZ');
  });
  return services;
};
export const compareDataObject = (
  payloadnew,
  oldObjectData,
  confirmType,
  currentDates,
) => {
  let frequencyObj = '';
  let returntype = true;
  if (
    JSON.stringify(payloadnew.dateData)
    === JSON.stringify(oldObjectData.dateData)
  ) {
    let payloadnewObj = {
      appointmenttype: payloadnew.appointmenttype,
      client: payloadnew.client,
      dateData: payloadnew.dateData,
      totalData: payloadnew.totalData,
      requiredday: payloadnew.requiredday || '',
    };
    let payloadOldObj = {
      appointmenttype: oldObjectData.appointmenttype,
      client: oldObjectData.client,
      dateData: oldObjectData.dateData,
      totalData: oldObjectData.totalData,
      requiredday: oldObjectData.requiredday,
    };
    if (JSON.stringify(payloadOldObj) === JSON.stringify(payloadnewObj)) {
      returntype = true;
    } else {
      returntype = false;
    }
  } else {
    let payloadnewObj = {
      appointmenttype: payloadnew.appointmenttype,
      client: payloadnew.client,
      dateData: payloadnew.dateData,
      totalData: payloadnew.totalData,
      requiredday: payloadnew.requiredday || '',
      // repeats: payloadnew.repeats,
    };
    let payloadOldObj = {
      appointmenttype: oldObjectData.appointmenttype,
      client: oldObjectData.client,
      dateData: oldObjectData.dateData,
      totalData: oldObjectData.totalData,
      requiredday: oldObjectData.requiredday,
      // repeats: oldObjectData.repeats,
    };
    if (JSON.stringify(payloadOldObj) === JSON.stringify(payloadnewObj)) {
      returntype = true;
    } else {
      returntype = false;
    }
  }
  let oldRepeats = {
    endType: oldObjectData.dateData[0].endType,
    frequencyMsg: oldObjectData.dateData[0].frequencyMsg,
    interval_multiplier: oldObjectData.dateData[0].interval_multiplier,
    interval_type: oldObjectData.dateData[0].interval_type,
    // msg: oldObjectData.dateData[0].msg,
    occurence: oldObjectData.dateData[0].occurence,
    occurenceMsg: oldObjectData.dateData[0].occurenceMsg,
  };
  let newRepeats = {
    endType: payloadnew.dateData[0].endType,
    frequencyMsg: payloadnew.dateData[0].frequencyMsg,
    interval_multiplier: payloadnew.dateData[0].interval_multiplier,
    interval_type: payloadnew.dateData[0].interval_type,
    // msg: payloadnew.dateData[0].msg,
    occurence: payloadnew.dateData[0].occurence,
    occurenceMsg: payloadnew.dateData[0].occurenceMsg,
  };
  if (JSON.stringify(oldRepeats) === JSON.stringify(newRepeats)) {
    frequencyObj = {
      endType: null,
      frequencyMsg: '',
      interval_multiplier: null,
      interval_type: null,
      msg: "One-off appointment | Doesn't repeat",
      occurence: null,
      occurenceMsg: '',
    };
  } else {
    frequencyObj = {
      endType: payloadnew.dateData[0].endType,
      frequencyMsg: payloadnew.dateData[0].frequencyMsg,
      interval_multiplier: payloadnew.dateData[0].interval_multiplier,
      interval_type: payloadnew.dateData[0].interval_type,
      msg: payloadnew.dateData[0].msg,
      occurence: payloadnew.dateData[0].occurence,
      occurenceMsg: payloadnew.dateData[0].occurenceMsg,
    };
  }
  // console.log('returntype', returntype, servicArray, frequencyObj);
  return {
    returntype,
    frequencyObj,
  };
};

export const updateServerDateTime = (services, currentDates, updateTime) => {
  let upTime = updateTime;
  const newStafId = localStorage.getItem('staff_id');
  const newStaffName = localStorage.getItem('staff_name');
  services.forEach((el, index) => {
    if (index === 0) {
      upTime = updateTime;
    } else {
      upTime = moment(new Date(services[index - 1].end)).format('HH:mm');
    }
    // console.log(services[index-1].end);
    let time = upTime.split(':');
    let start = moment(currentDates).toDate();
    start.setHours(time[0]);
    start.setMinutes(time[1]);
    start.setSeconds(0);
    el.staffId = newStafId;
    el.staffName = newStaffName;
    el.start = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
    el.end = moment(
      new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
    ).format('YYYY-MM-DDTHH:mm:ssZ');
    // console.log('el.end', el.end);
    el.startTime = upTime;
  });
  return services;
};

export const updateServiceDateTime = (chemicalServices, lastitem) => {
  const { end } = lastitem || {};
  let upTime = '';
  chemicalServices.forEach((el, index) => {
    if (index === 0) {
      upTime = moment(new Date(end)).format('HH:mm');
    } else {
      upTime = moment(new Date(chemicalServices[index - 1].end)).format(
        'HH:mm',
      );
    }
    let time = upTime.split(':');
    let start = moment(end).toDate();
    start.setHours(time[0]);
    start.setMinutes(time[1]);
    start.setSeconds(0);
    el.start = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
    let serviceEndTime = moment(
      new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
    ).format('YYYY-MM-DDTHH:mm:ssZ');
    el.end = serviceEndTime;
    el.endTime = moment(new Date(serviceEndTime)).format('HH:mm');
    el.startTime = upTime;
  });
  return chemicalServices;
};

export const addZero = (value, type) => {
  if (value && value === 'N/A') {
    if (!type) {
      value = '';
    }
    return value;
  }
  return value.split('.')[1]
    ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : parseFloat(value)
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const upcommingDateRange = (
  arr,
  step,
  type,
  date,
  id,
  activeIndex,
  rescheduleType,
  interval_multiplier,
) => {
  const repeatLasIndex = arr.slice(-1);
  const dateArray = [];
  let lastDate = '';
  arr.forEach((el, index) => {
    let starDate = new Date(date);
    starDate.setHours(0, 0, 0, 0);
    if (type === 'daily') {
      let setStep = parseInt(step, 10) * index;
      const update = starDate.setDate(starDate.getDate() + setStep);
      if (rescheduleType === 'current') {
        lastDate = repeatLasIndex ? new Date(repeatLasIndex[0].date) : '';
      } else {
        lastDate = update;
      }
      let myEvetnt = {
        date: moment(update).format('YYYY-MM-DDTHH:mm:ssZ'),
        status: 'booked',
        appointmentid: id,
      };
      dateArray.push(myEvetnt);
    } else if (type === 'weekly') {
      let setStep = parseInt(step, 10) * index * 7;
      const update = starDate.setDate(starDate.getDate() + setStep);

      if (rescheduleType === 'current') {
        lastDate = repeatLasIndex ? new Date(repeatLasIndex[0].date) : '';
      } else {
        lastDate = update;
      }
      let myEvetnt = {
        date: moment(update).format('YYYY-MM-DDTHH:mm:ssZ'),
        status: 'booked',
        appointmentid: id,
      };
      dateArray.push(myEvetnt);
    } else if (type === 'monthly') {
      let setStep = parseInt(step, 10) * index;
      const update = starDate.setMonth(starDate.getMonth() + setStep);
      if (rescheduleType === 'current') {
        lastDate = repeatLasIndex ? new Date(repeatLasIndex[0].date) : '';
      } else {
        lastDate = update;
      }
      let myEvetnt = {
        date: moment(update).format('YYYY-MM-DDTHH:mm:ssZ'),
        status: 'booked',
        appointmentid: id,
      };
      dateArray.push(myEvetnt);
    }
  });
  return { dateArray, lastDate };
};

export const RescheduleUpdateMsg = (
  newDateArray,
  msgIntervalType,
  lastDate,
  oldLastdate,
  oldRepeated,
  activeIndex,
  rescheduleType,
  repeats,
) => {
  let newmsg = '';
  let oldmsg = '';
  const allRepeateArr = [...repeats];
  const remainRepeated = allRepeateArr.splice(activeIndex, 1);
  const oldRepeatedsize = repeats.length - remainRepeated.length;
  let oldoccurenceMsg;
  let oldendType;
  let oldinterval_multitplier;
  let oldinterval_type;
  let oldfrequencyMsg;
  let oldoccurence;
  let StartDates = '';
  if (activeIndex === '0' && oldRepeatedsize > 0) {
    oldmsg = newDateArray[0].endType === 'ongoing'
      ? `Repeats every ${newDateArray[0].interval_multiplier} ${msgIntervalType} | Ongoing`
      : newDateArray[0].occurenceMsg !== 'On a Specific Date'
        ? `Repeats every ${
          newDateArray[0].interval_multiplier
        } ${msgIntervalType} | Ends after ${oldRepeatedsize} times on ${moment(
          lastDate,
        ).format('dddd')}, ${moment(lastDate).format('D MMMM YYYY')}`
        : `Repeats every ${
          newDateArray[0].interval_multiplier
        } ${msgIntervalType} | Ends on ${moment(lastDate).format(
          'dddd',
        )}, ${moment(lastDate).format('D MMMM YYYY')}`;
    oldoccurenceMsg = `After ${oldRepeatedsize} times`;
    oldoccurence = oldRepeatedsize;
    StartDates = repeats[1].date;
  } else if (activeIndex === '0' && oldRepeatedsize === 1) {
    oldmsg = "One-off appointment | Doesn't repeat";
    oldoccurenceMsg = null;
    oldendType = null;
    oldinterval_multitplier = null;
    oldinterval_type = null;
    oldfrequencyMsg = '';
    oldoccurence = null;
  } else {
    const upcomingArr = repeats.slice(activeIndex);
    const oldRepeatedLength = repeats.length - upcomingArr.length;
    if (oldRepeatedLength > 1) {
      oldmsg = newDateArray[0].endType === 'ongoing'
        ? `Repeats every ${newDateArray[0].interval_multiplier} ${msgIntervalType} | Ongoing`
        : newDateArray[0].occurenceMsg !== 'On a Specific Date'
          ? `Repeats every ${
            newDateArray[0].interval_multiplier
          } ${msgIntervalType} | Ends after ${oldRepeatedLength} times on ${moment(
            oldLastdate,
          ).format('dddd')}, ${moment(oldLastdate).format('D MMMM YYYY')}`
          : `Repeats every ${
            newDateArray[0].interval_multiplier
          } ${msgIntervalType} | Ends on ${moment(oldLastdate).format(
            'dddd',
          )}, ${moment(oldLastdate).format('D MMMM YYYY')}`;
      oldoccurenceMsg = `After ${oldRepeatedLength} times`;
      oldoccurence = oldRepeatedLength;
    }
    // oldoccurence = oldRepeatedsize;
  }
  newmsg = newDateArray[0].endType === 'ongoing'
    ? `Repeats every ${newDateArray[0].interval_multiplier} ${msgIntervalType} | Ongoing`
    : newDateArray[0].occurenceMsg !== 'On a Specific Date'
      ? `Repeats every ${
        newDateArray[0].interval_multiplier
      } ${msgIntervalType} | Ends after ${
        newDateArray[0].occurence
      } times on ${moment(lastDate).format('dddd')}, ${moment(
        lastDate,
      ).format('D MMMM YYYY')}`
      : `Repeats every ${
        newDateArray[0].interval_multiplier
      } ${msgIntervalType} | Ends on ${moment(lastDate).format(
        'dddd',
      )}, ${moment(lastDate).format('D MMMM YYYY')}`;
  // console.log('check====>', newmsg, oldmsg, oldoccurenceMsg, oldendType, oldinterval_multitplier, oldinterval_type, oldfrequencyMsg, oldoccurence, StartDates);
  return {
    newmsg,
    oldmsg,
    oldoccurenceMsg,
    oldendType,
    oldinterval_multitplier,
    oldinterval_type,
    oldfrequencyMsg,
    oldoccurence,
    StartDates,
  };
};

export const updateOldServiceDateTime = async (id, dates) => {
  const response = await userInstance().get(
    `/appointment/getAppointmentById?id=${id}`,
  );
  const { appointmentdetail } = response.data;
  const { dateData } = appointmentdetail || {};
  if (dateData.length > 0) {
    const oldNewServicesData = updateServiceDateTime(
      dateData[0].service,
      dates,
    );
    return oldNewServicesData;
  }
};
export const matchDataObject = (newclients, newDataObj, appointmenttypes) => {
  const { client, dateData, appointmenttype } = JSON.parse(localStorage.getItem('currentapp')) || {};
  let matchtype = true;
  let matchFrequency = true;
  if (newDataObj.length === 1) {
    const oldfrequencyMsg = {
      endType: dateData[0].endType,
      frequencyMsg: dateData[0].frequencyMsg,
      interval_multiplier: dateData[0].interval_multiplier,
      interval_type: dateData[0].interval_type,
      occurence: dateData[0].occurence,
      appointmenttype,
    };
    const newfrequencyMsg = {
      endType: newDataObj[0].endType,
      frequencyMsg: newDataObj[0].frequencyMsg,
      interval_multiplier: newDataObj[0].interval_multiplier,
      interval_type: newDataObj[0].interval_type,
      occurence: newDataObj[0].occurence,
      appointmenttype: appointmenttypes,
    };
    if (JSON.stringify(newfrequencyMsg) !== JSON.stringify(oldfrequencyMsg)) {
      matchFrequency = false;
    }
  }
  if (
    JSON.stringify(newDataObj) === JSON.stringify(dateData)
    && JSON.stringify(client) === JSON.stringify(newclients)
  ) {
    matchtype = false;
  }
  return { matchtype, matchFrequency };
};

export const filterAppointmentObj = (dataObj) => {
  const { datetype, rangeStartDate, rangeEndDate } = dataObj || {};
  const dates = moment(new Date()).format('YYYY-MM-DD');
  const curr = new Date();
  if (datetype === 'yesterday') {
    const lt = new Date(dates);
    const gt = new Date(dates).setDate(new Date(dates).getDate() - 1);
    const obj = {
      startDate: new Date(gt).toISOString(),
      endDate: new Date(gt).toISOString(),
      summaryDate: `${moment(gt).format('ddd, D MMM yyyy')} - ${moment(
        gt,
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'today') {
    const lt = new Date(dates);
    const gt = new Date(dates).setDate(new Date(dates).getDate() + 1);
    const obj = {
      startDate: new Date(lt).toISOString(),
      endDate: new Date(lt).toISOString(),
      summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(
        lt,
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'tomorrow') {
    const lt = new Date(dates).setDate(new Date(dates).getDate() + 1);
    const gt = new Date(dates).setDate(new Date(dates).getDate() + 2);
    const obj = {
      startDate: new Date(lt).toISOString(),
      endDate: new Date(lt).toISOString(),
      summaryDate: `${moment(lt).format('ddd, D MMM yyyy')} - ${moment(
        lt,
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'week') {
    // const first = curr.getDate() - curr.getDay();
    // const last = first + 8;
    // const firstday = moment(new Date(curr.setDate(first + 1))).format('YYYY-MM-DD');
    // const lastday = moment(curr.setDate(last)).format('YYYY-MM-DD');
    const filterStart = new Date(
      moment().startOf('isoWeek').format('YYYY-MM-DD'),
    );
    const filterEnd = new Date(moment().endOf('isoWeek').format('YYYY-MM-DD'));
    const obj = {
      startDate: new Date(
        moment().endOf('isoWeek').format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().startOf('isoWeek').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment(filterStart).format('ddd, D MMM yyyy')} - ${moment(
        filterEnd,
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'month') {
    const firstDay = moment(
      new Date(curr.getFullYear(), curr.getMonth(), 1),
    ).format('YYYY-MM-DD');
    const lastDay = moment(
      new Date(curr.getFullYear(), curr.getMonth() + 1, 0),
    ).format('YYYY-MM-DD');
    const obj = {
      startDate: new Date(lastDay).toISOString(),
      endDate: new Date(firstDay).toISOString(),
      summaryDate: `${moment(firstDay).format('ddd, D MMM yyyy')} - ${moment(
        lastDay,
      ).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'year') {
    const obj = {
      startDate: new Date(
        moment().endOf('year').format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().startOf('year').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf('year')
        .format('ddd, D MMM yyyy')} - ${moment()
        .endOf('year')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'lastweek') {
    const obj = {
      startDate: new Date(
        moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, 'weeks')
        .startOf('isoWeek')
        .format('ddd, D MMM yyyy')} - ${moment()
        .subtract(1, 'weeks')
        .endOf('isoWeek')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'lastyear') {
    const obj = {
      startDate: new Date(
        moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().subtract(1, 'year').startOf('year').format('YYYY-DD-MM'),
      ).toISOString(),
      summaryDate: `${moment()
        .subtract(1, 'year')
        .startOf('year')
        .format('ddd, D MMM yyyy')} -
      ${moment().subtract(1, 'year').endOf('year').format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'nextweek') {
    const obj = {
      startDate: new Date(
        moment().add(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().add(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, 'weeks')
        .startOf('isoWeek')
        .format('ddd, D MMM yyyy')} - ${moment()
        .add(1, 'weeks')
        .endOf('isoWeek')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'nextyear') {
    const obj = {
      startDate: new Date(
        moment().add(1, 'year').endOf('year').format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment()
        .add(1, 'year')
        .startOf('year')
        .format('ddd, D MMM yyyy')} - ${moment()
        .add(1, 'year')
        .endOf('year')
        .format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  if (datetype === 'weekdate') {
    const obj = {
      startDate: new Date(
        moment(new Date()).format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().startOf('week').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf('week')
        .format('ddd, D MMM yyyy')} - ${moment(new Date()).format(
        'ddd, D MMM yyyy',
      )}`,
    };
    return obj;
  }
  if (datetype === 'monthdate') {
    const obj = {
      startDate: new Date(
        moment(new Date()).format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().clone().startOf('month').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment()
        .clone()
        .startOf('month')
        .format('ddd, D MMM yyyy')} - ${moment(new Date()).format(
        'ddd, D MMM yyyy',
      )}`,
    };
    return obj;
  }
  if (datetype === 'yeardate') {
    const obj = {
      startDate: new Date(
        moment(new Date()).format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment().startOf('year').format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment()
        .startOf('year')
        .format('ddd, D MMM yyyy')} - ${moment(new Date()).format(
        'ddd, D MMM yyyy',
      )}`,
    };
    return obj;
  }
  if (datetype === 'daterange') {
    const obj = {
      startDate: new Date(
        moment(new Date(rangeEndDate)).format('YYYY-MM-DD'),
      ).toISOString(),
      endDate: new Date(
        moment(new Date(rangeStartDate)).format('YYYY-MM-DD'),
      ).toISOString(),
      summaryDate: `${moment(rangeStartDate).format(
        'ddd, D MMM yyyy',
      )} - ${moment(rangeEndDate).format('ddd, D MMM yyyy')}`,
    };
    return obj;
  }
  return {};
};

export const handleTime = (greenSplitName, data, type) => {
  let gType = '';
  if (type) {
    gType = type;
  }
  let time = data.toString();
  let convertedInMins = '';
  if (time.includes('.')) {
    let splitData = data.split('.');
    convertedInMins = gType === 'drain'
      ? parseFloat(splitData[0]) * 60 + parseFloat(splitData[1] * 6) + 60
      : parseFloat(splitData[0]) * 60 + parseFloat(splitData[1] * 6);
  } else {
    convertedInMins = parseFloat(data) * 60;
  }

  return Math.ceil(convertedInMins / 5) * 5;
};

export const dateFormats = (date) => {
  let formateDate = '';
  if (
    moment(new Date()).format('YYYY-MM-DD')
    === moment(new Date(date)).format('YYYY-MM-DD')
  ) {
    formateDate = `${moment(new Date(date)).format('h:mma')} today`;
  } else if (
    moment().add(1, 'days').format('YYYY-MM-DD')
    === moment(new Date(date)).format('YYYY-MM-DD')
  ) {
    formateDate = `${moment(new Date(date)).format('h:mma')} tomorrow`;
  } else if (
    moment().subtract(1, 'days').format('YYYY-MM-DD')
    === moment(new Date(date)).format('YYYY-MM-DD')
  ) {
    formateDate = `${moment(new Date(date)).format('h:mma')} yesterday`;
  } else {
    formateDate = moment(new Date(date)).format('ddd, D MMM h:mma');
  }

  return formateDate;
};

export const currencyComma = (value) => {
  let res = value;
  if (res) {
    res = value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return res;
};

export const makingAddress = (ans) => {
  let str = '';
  let index = 0;
  // eslint-disable-next-line guard-for-in
  for (const prop in ans) {
    if (ans[prop]) {
      ans[prop] = ans[prop].trim();
      if (ans[prop][ans[prop].length - 1] === ',') {
        ans[prop] = ans[prop].slice(0, -1);
      }
      if (index === 0) {
        str += ans[prop];
      } else {
        str += `, ${ans[prop]}`;
      }
      index++;
    }
  }
  return str;
};

export const convertQuantUnit = (chemical, unit) => {
  // console.log(chemical, unit);
  let chemValue = parseFloat(chemical).toFixed(1);
  let chemUnit = unit;
  let actualValue = chemValue;
  if (parseFloat(chemical) < 1) {
    chemValue = Math.ceil((parseFloat(chemical) * 1000) / 100) * 100;
    actualValue = chemValue / 1000;
    chemUnit = unit === 'fl oz'
      ? 'fl oz'
      : unit === 'lb'
        ? 'oz'
        : unit === 'litres'
          ? 'ml'
          : 'gm';
    if (chemValue === 1000) {
      chemValue = (chemValue / 1000).toFixed(1);
      actualValue = chemValue;
      chemUnit = unit === 'litres' ? 'litre' : 'kg';
    }
  }

  return { chemValue, chemUnit, actualValue };
};

export const calculatingDosage = (dose, size, secDose) => {
  const res = (parseFloat(dose) / 40000) * parseFloat(size);
  let finalRes = convertQuantUnit(res).actualValue;
  if (secDose) {
    finalRes = convertQuantUnit(
      (parseFloat(finalRes) * secDose) / 100,
    ).actualValue;
  }
  return finalRes;
};

export const calculatChemicalProductCost = (item) => {
  let totalCost;
  let totalMargin;
  let totalLabourAmount;
  let totalLabour;
  let totalDuration;
  item.forEach((el, i) => {
    totalCost = item
      .map((el2) => el2.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.renderCost !== 'N/A' ? next.renderCost : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    totalMargin = item
      .map((el2) => el2.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.laborMargin ? next.laborMargin : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    totalLabourAmount = item
      .map((el2) => el2.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.totalLabour ? next.totalLabour : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    totalLabour = totalLabourAmount + totalMargin;
    totalDuration = item
      .map((el2) => el2.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.duration ? next.duration : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    // console.log({ subtotalDuration: totalDuration, subtotalLabour: totalLabour, subtotalCost: totalCost });
  });
  return {
    subtotalDuration: totalDuration,
    subtotalLabour: totalLabour,
    subtotalCost: totalCost,
  };
};

export const removeComma = (value) => {
  let res = value;
  if (res) {
    res = res?.toString().replace(/,/g, '');
  }
  return res;
};
export const getCalendarPath2 = (appointmentID) => {
  let staff_id = localStorage.getItem('staff_id');
  let date = localStorage.getItem('date');
  let view = localStorage.getItem('view');
  let backPreDate = appointmentID || localStorage.getItem('prebackDate');
  let calendarUrl = `/calendar?activedate=${
    date
      ? moment(new Date(date)).format('YYYY-MM-DD')
      : moment(new Date()).format('YYYY-MM-DD')
  }&view=${view || 'timeGridWeek'}&staff_id=${
    staff_id || ''
  }&appointmentId=${appointmentID}`;
  return calendarUrl;
};

export const updateReschduleChemicals = (chemicalServices, lastitem) => {
  const { end } = lastitem || {};
  let upTime = '';
  let copyChemicalServices = chemicalServices;
  // console.log('copyChemicalServices=>', copyChemicalServices);
  if (copyChemicalServices && copyChemicalServices.length > 0) {
    copyChemicalServices[0].testResult[0].service.forEach((el, index) => {
      if (index === 0) {
        upTime = moment(new Date(end)).format('HH:mm');
      } else {
        upTime = moment(
          new Date(copyChemicalServices[0].testResult[0].service[index - 1].end),
        ).format('HH:mm');
      }
      let time = upTime.split(':');
      let start = moment(end).toDate();
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      el.start = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
      let serviceEndTime = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
      ).format('YYYY-MM-DDTHH:mm:ssZ');
      el.end = serviceEndTime;
      el.endTime = moment(new Date(serviceEndTime)).format('HH:mm');
      el.startTime = upTime;
      el.staffId = localStorage.getItem('staff_id');
      el.staffName = localStorage.getItem('staff_name');
    });
    return copyChemicalServices;
  }
  return '';
};
export const getFirstServiceTime = (dates, time) => {
  const firstTime = time.split(':');
  let start = moment(dates).toDate();
  start.setHours(firstTime[0]);
  start.setMinutes(firstTime[1]);
  start.setSeconds(0);
  const upservTime = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
  return upservTime;
};

// For the average GST of services array.
// Note:- Always pass the single appointment aray in averageGstCalculation function.
// **Start**
function roundToTwo(num) {
  // eslint-disable-next-line prefer-template
  const result = +(Math.round(num + 'e+2') + 'e-2');
  return result;
}
export const averageGstCalculation = (appointmentData) => {
  let cost = [];
  let tax = [];
  // console.log('I am here-----------');
  if (appointmentData[0].service.length > 0) {
    // parsFLoat tha tax and cost(cost is render_cost here)
    appointmentData[0].service.forEach((el) => {
      cost.push(parseFloat(el.renderCost === 'N/A' ? 0 : el.renderCost));
      if (el.tax) {
        tax.push(parseFloat(el.tax));
      } else {
        tax.push(10);
      }
    });
    // divide taxes with 100
    const newTax = tax.map((el) => el / 100);
    // Findning new numbers by multiply tax and cost then add them together
    let totalNewCost = 0;
    newTax.forEach((el, index) => {
      totalNewCost += el * cost[index];
    });
    // add old all old cost-
    const totalCost = cost.reduce((partialSum, a) => partialSum + a, 0);
    // Avergae Percentage Formula
    const averagePercentGst = (totalNewCost / totalCost) * 100;
    const perfectGstPer = roundToTwo(averagePercentGst);
    // console.log('done-----');
    return { perfectGstPer, totalCost };
  }
  return 0;
};
// **End**

export const calculatChemicalCost = (item) => {
  let totalCost = 0;
  let totalMargin = 0;
  let totalLabourAmount = 0;
  let totalLabour = 0;
  let totalDuration = 0;
  if (item && item?.length > 0) {
    item.forEach((el) => {
      totalDuration += el?.duration ? parseInt(el?.duration, 10) : 0;
      if (el?.renderCost !== 'N/A') {
        totalCost += el?.renderCost ? parseFloat(el?.renderCost) : 0;
      }
      if (el?.laborMargin !== 'N/A') {
        totalMargin += el?.laborMargin ? parseFloat(el?.laborMargin) : 0;
      }
      if (el?.totalLabour !== 'N/A') {
        totalLabourAmount += el?.totalLabour
          ? parseFloat(el?.totalLabour || 0)
          : 0;
      }
    });
    totalLabour = totalLabourAmount + totalMargin;
  }
  return {
    subtotalDuration: totalDuration,
    subtotalLabour: totalLabour,
    subtotalCost: totalCost,
  };
};

export const calculatServiceProCost = (item) => {
  let totalCost = 0;
  let totalMargin = 0;
  let totalLabourAmount = 0;
  let totalLabour = 0;
  let totalDuration = 0;
  let subSummary = {};
  item.forEach((el) => {
    totalDuration += parseInt(el.duration, 10);
    totalCost += parseFloat(el.renderCost || 0);
    totalLabourAmount += parseFloat(el.totalLabour || 0);
    totalMargin += parseFloat(el.laborMargin || 0);
  });
  totalLabour = totalLabourAmount + totalMargin;
  subSummary.totalCost = addZero(parseFloat(totalCost).toFixed(2));
  subSummary.totalDuration = totalDuration;
  subSummary.totalLabour = addZero(parseFloat(totalLabour).toFixed(2));
  subSummary.multiTotal = totalCost;
  subSummary.totalMargin = addZero(parseFloat(totalMargin).toFixed(2));
  subSummary.totalLabourOnly = addZero(
    parseFloat(totalLabourAmount).toFixed(2),
  );
  return { subSummary };
};

const updategrupeServiceDateTime = (chemicalServices, end) => {
  let upTime = '';
  const newStafId = localStorage.getItem('staff_id');
  const newStaffName = localStorage.getItem('staff_name');
  chemicalServices.forEach((el, index) => {
    if (index === 0) {
      upTime = moment(new Date(end)).format('HH:mm');
    } else {
      upTime = moment(new Date(chemicalServices[index - 1].end)).format(
        'HH:mm',
      );
    }
    let time = upTime.split(':');
    let start = moment(end).toDate();
    start.setHours(time[0]);
    start.setMinutes(time[1]);
    start.setSeconds(0);
    el.start = moment(start).format('YYYY-MM-DDTHH:mm:ssZ');
    let serviceEndTime = moment(
      new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration))),
    ).format('YYYY-MM-DDTHH:mm:ssZ');
    el.end = serviceEndTime;
    el.endTime = moment(new Date(serviceEndTime)).format('HH:mm');
    el.startTime = upTime;
    el.staffId = newStafId;
    el.staffName = newStaffName;
  });
  return chemicalServices;
};

export const updategrupeGrupeApp = (allArray, newDate) => {
  let newDates = newDate;
  const visitIndex = localStorage.getItem('visitIndex');
  let arrayNew1 = [];
  let arrayNew2 = [];
  let CopyNewGropeApp = [];
  let partCount = 0;
  const appTypes = allArray[0].appointment_calc_type;
  const gape = appTypes === 'Black-Spot Algae' || appTypes === 'Drain Pool'
    ? 2
    : appTypes === 'Green Pool'
      ? 4
      : 1;
  // console.log('newDateccc=>', {
  //   newDate, visitIndex, allArray, gape,
  // });
  if (visitIndex !== '1') {
    const copyallArray = [...allArray];
    arrayNew1 = copyallArray.splice(0, parseInt(visitIndex, 10));
    partCount = arrayNew1.length - 1;
  }
  const dateGapeDay = gape * partCount;
  newDates = moment(new Date(newDates))
    .subtract(dateGapeDay, 'days')
    .format('YYYY-MM-DDTHH:mm:ssZ');
  allArray.forEach((el, indexs) => {
    let el22 = el;
    const servicesArray = el.dateData[0].service;
    const upDate = moment(new Date(newDates)).format('YYYY-MM-DD');
    el22.dateData[0].service = updategrupeServiceDateTime(
      servicesArray,
      newDates,
    );
    el22.dateData[0].date = new Date(upDate).toISOString();
    newDates = moment(new Date(newDates))
      .add(gape, 'days')
      .format('YYYY-MM-DDTHH:mm:ssZ');
    CopyNewGropeApp.push(el22);
  });
  return CopyNewGropeApp;
};

export const getDaysArray = (tofrom) => {
  const indexArray = tofrom.split('-');
  const to = indexArray[0];
  const from = indexArray[1];
  const dayList = [];
  const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  for (let i = parseInt(to); i <= parseInt(from); i++) {
    let day = weekday[i];
    dayList.push(day);
  }
  return dayList;
};

export const searchClientFun = async (searchTxt) => {
  try {
    if (searchTxt) {
      const response = await userInstance().get(
        `/customer/searchCustomer?value=${searchTxt}`,
      );

      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllCustomersFun = async () => {
  try {
    const response = await userInstance().get('/customer/getCustomersAll');
    return response.data;
  } catch (error) {
    //   console.log(error);
  }
};

export const numberFromString = (value) => {
  let str = value;
  const matches = str.match(/(\d+)/);
  if (matches) {
    const number = parseFloat(matches[0]);
    return number;
  }
  // return 0 means there is no number in the input value. Use check according to 0 where you call. Don't change it from here
  return 0;
};

export function lowerFirstLetter(string = '') {
  return string?.charAt(0).toLowerCase() + string.slice(1);
}

export const removeCodeFromNumber = (number) => {
  const splitValue = number.includes('+61') ? '+61' : '+1';
  const splitData = number.split(splitValue);
  return {
    countryCode: splitValue,
    updatedNo: splitData[splitData?.length - 1]?.trim(),
  };
};

export const gstChemicalsCalculations = (services) => {
  let totalNewCost = 0;
  services.forEach((el) => {
    if (el?.renderCost !== 'N/A') {
      const taxVal = !Number.isNaN(el?.tax) && el?.tax && el.tax !== 'NaN' ? el?.tax : 0;
      totalNewCost += (parseFloat(el?.renderCost) * parseFloat(taxVal)) / 100;
    }
  });
  return totalNewCost;
};

// csv uplaods objcets creation and validations  Start-->>>>

export const checkValidationCSVClient = (
  objData,
  fileName,
  chlorinationModelData,
) => {
  // destrucutree key value pairs for validation
  let {
    customerType,
    companyName,
    firstname,
    lastname,
    mobileNo,
    landlineNo,
    email,
    streetAddress,
    suburb,
    state,
    country,
    invoiceSendType,
    note,
    chlorination: chlorinatorModel,
    countryCode,
    chlorinationType,
  } = objData;
  // create object for validation of the keys. If required keys not found then return false also check value is empty or not
  const validationObj = {
    'customer type': customerType,
    'first name': firstname,
    'last name': lastname,
    'mobile no': mobileNo,
    'street address': streetAddress,
    suburb,
    state,
    country,
  };
  if (customerType && customerType.toLowerCase() !== 'Householder') {
    validationObj[`${customerType} name`] = companyName;
  }
  validationObj['invoice sending type'] = invoiceSendType;
  let isValid = true;
  // let { isValid, emptyField } = validateDataWithKey(validationObj);
  objData.name = [firstname, lastname].join(' ');
  // Now checking the values of keys to be valid or not like email phone etc.
  if (isValid) {
    if (email) {
      if (emailRegex.test(String(objData.email).toLowerCase())) {
        let isMobileValid = true;
        let isLandlineValid = true;
        // formatting  mobile number for databse schema
        objData.mobileNo = `+${objData.mobileNo}`;
        objData.mobileNo = `${objData.mobileNo.substr(
          0,
          3,
        )} ${objData.mobileNo.substr(3, 3)} ${objData.mobileNo.substr(
          6,
          3,
        )} ${objData.mobileNo.substr(9, 3)}`;
        // formatting  landline number for databse schema
        objData.landlineNo = `+${objData.landlineNo}`;
        objData.landlineNo = `${objData.landlineNo.substr(
          0,
          3,
        )} ${objData.landlineNo.substr(3, 1)} ${objData.landlineNo.substr(
          4,
          4,
        )} ${objData.landlineNo.substr(8, 4)}`;
        if (objData.mobileNo) {
          if (objData.mobileNo.startsWith('+61')) {
            if (objData.mobileNo.charAt(4) === '0') {
              objData.mobileNo = objData.mobileNo.replace('0', '');
              isMobileValid = true;
            }
            if (objData.landlineNo.charAt(4) === '0') {
              objData.landlineNo = objData.landlineNo.replace('0', '');
              isLandlineValid = true;
            }
            if (objData.landlineNo.trim().length !== 15) {
              isLandlineValid = false;
            }
            if (objData.mobileNo.charAt(4) !== '4') {
              isMobileValid = false;
            }
          }
          // if mobile valid then retun the obj
          if (isMobileValid) {
            delete objData.countryCode;
            if (fileName) {
              objData.note = objData.note || '';
              objData.chlorinatorModel = chlorinationModelData || [];
              objData.csvName = fileName;
              objData.chlorinationType = objData.chlorinationType || 'Salt Pool';
              delete objData.chlorination;
            }
            return objData;
          }
        }
      }
    }
  }
  return objData;
};

export const checkServiceValidations = (
  data,
  fileName,
  type,
  teamMemberData,
) => {
  if (data) {
    const subcategoryCopy = data;
    const {
      name,
      serviceDescription,
      duration,
      cost,
      retailCost,
      tax,
      category,
      appointmentChecklist,
      stock,
      teamMember,
      soldUnit,
    } = subcategoryCopy;
    let totalStockArray = [];
    let totalStock = 0;
    let teamArr = [];
    if (type === 'inventory') {
      let stockIndex = -1;
      totalStockArray = stock?.split(',') || 0;

      let teamMemberCsv = teamMember?.split(',').sort((a, b) => a.localeCompare(b)) || [];
      teamMemberCsv = teamMemberCsv.map((el) => el.trim());
      if (totalStockArray && totalStockArray.length > 0) {
        teamMemberCsv.forEach((el) => {
          const indexValue = teamMemberData.findIndex(
            (ele) => el.toLowerCase()
              === `${ele.firstname} ${ele.lastname}`.toLowerCase(),
          );
          if (indexValue !== -1) {
            if (totalStockArray[stockIndex].toString().match(checkNumberReg)) {
              teamMemberData[indexValue].stockValue = parseInt(
                totalStockArray[stockIndex] || 0,
              );
            } else {
              teamMemberData[indexValue].stockValue = parseInt(0);
            }
            stockIndex += 1;
            totalStock += parseInt(totalStockArray[stockIndex] || 0);
            teamArr.push(teamMemberData[indexValue]);
          }
        });
      }
    }
    const validationObj = {
      name,
      description: serviceDescription,
      duration,
      tax,
      category,
      appointmentChecklist,
    };
    const checkDurationNum = !!subcategoryCopy?.duration.match(checkNumberReg);
    if (!checkDurationNum) {
      return {};
    }
    const priceTypo = type === 'product' || type === 'inventory' ? 'buy cost' : 'sell price';
    if (!category?.toLowerCase().includes('misc')) {
      validationObj[priceTypo] = cost;
    }

    if (type === 'product' || type === 'inventory') {
      if (!category?.toLowerCase().includes('misc')) {
        validationObj['retail cost'] = retailCost;
      }
      const checkNum = !!subcategoryCopy?.retailCost.match(checkNumberReg);
      if (checkNum) {
        subcategoryCopy.retailCost = parseFloat(
          subcategoryCopy?.retailCost?.replace(/,/g, ''),
        ).toFixed(2);
      } else {
        return {};
      }
    }
    const checkNum = !!subcategoryCopy?.cost.match(checkNumberReg);
    if (checkNum) {
      subcategoryCopy.cost = parseFloat(
        subcategoryCopy?.cost?.replace(/,/g, ''),
      ).toFixed(2);
    } else {
      return {};
    }

    let { isValid, emptyField } = validateDataWithKey(validationObj);
    if (isValid) {
      if (
        (type === 'product' || type === 'inventory')
        && parseFloat(subcategoryCopy.cost)
          > parseFloat(subcategoryCopy.retailCost)
      ) {
        isValid = false;
      }
      if (subcategoryCopy?.tax.match(checkNumberReg)) {
        subcategoryCopy.tax = parseFloat(
          subcategoryCopy?.tax?.replace(/,/g, ''),
        ).toFixed(2);
      } else {
        return {};
      }
      if (isValid) {
        if (
          (type === 'product' || type === 'inventory')
          && subcategoryCopy?.tax?.length === 0
        ) {
          isValid = false;
          toastMessage('error', 'Please Enter tax details.');
        }
      }
      // Adding s_uid and category id to sub categories
      let randomStr = (
        Math.floor(Math.random() * 9 + 1)
        + Date.now()
        + (Math.random() + 1).toString(36).substring(7)
      ).toString();
      subcategoryCopy.s_uId = randomStr;
      // subcategoryCopy.categoryId = selectedCategoryId._id;

      if (isValid) {
        const reqObj = {
          ...subcategoryCopy,
        };
        if (!category.toLowerCase().includes('misc')) {
          reqObj.name = `${subcategoryCopy.name} $${addZero(
            subcategoryCopy[
              type === 'product' || type === 'inventory' ? 'retailCost' : 'cost'
            ],
          )}`;
        }
        if (type === 'service') {
          const {
            barCode,
            categoryId,
            currentStockItems,
            supplier,
            supplyUnit,
            ...serviceReqObj
          } = reqObj;
          return reqObj;
        }
        delete reqObj.stock;
        const prodData = {
          subcategory: reqObj,
          stockData: teamArr || [],
          type: 'product',
          totalStock: parseFloat(totalStock || 0),
        };
        return prodData;
      }
    } else {
      return {};
      // NotificationManager.error(`Please enter ${emptyField} for ${type}`, 'Message', errorMsgTime);
    }
  } else {
    return {};
    // NotificationManager.error('Please enter product name', 'Message', errorMsgTime);
  }
  return {};
};

const checkStaffvalidations = (data, fileName, type) => {
  const staffData = { ...data };
  const {
    firstname, lastname, email, accessLevel, location,
  } = staffData;
  try {
    const { isValid, emptyField } = validateDataWithKey({
      'first name': firstname,
      'last name': lastname,
      email,
      'access level': accessLevel,
      location,
    });
    if (isValid) {
      let isMobileValid = true;
      let isLandlineValid = true;
      let errorMsg = '';
      if (emailRegex.test(String(staffData.email).toLowerCase())) {
        const payload = { ...staffData, email: email.toLowerCase() };
        payload.mobileNo = `+${payload.mobileNo}`;
        payload.mobileNo = `${payload.mobileNo.substr(
          0,
          3,
        )} ${payload.mobileNo.substr(3, 3)} ${payload.mobileNo.substr(
          6,
          3,
        )} ${payload.mobileNo.substr(9, 3)}`;
        // formatting  landline number for databse schema
        payload.landlineNo = `+${payload.landlineNo}`;
        payload.landlineNo = `${payload.landlineNo.substr(
          0,
          3,
        )} ${payload.landlineNo.substr(3, 1)} ${payload.landlineNo.substr(
          4,
          4,
        )} ${payload.landlineNo.substr(8, 4)}`;
        if (payload.mobileNo) {
          if (payload.mobileNo.startsWith('+61')) {
            if (payload.mobileNo.charAt(4) === '0') {
              payload.mobileNo = payload.mobileNo.replace('0', '');
              isMobileValid = true;
            }
            if (payload.landlineNo.charAt(4) === '0') {
              payload.landlineNo = payload.landlineNo.replace('0', '');
              isLandlineValid = true;
            }
            if (payload.landlineNo.trim().length !== 15) {
              isLandlineValid = false;
            }
            if (payload.mobileNo.charAt(4) !== '4') {
              isMobileValid = false;
            }
          }
          // if mobile valid then retun the obj
          if (isMobileValid) {
            delete payload.countryCode;
            if (fileName) {
              payload.csvName = fileName;
            }
            return payload;
          }
          return {};
        }
        return {};
      }

      console.log('Email not valid');
      return {};
    }
    console.log('not valid');
    return {};
  } catch (error) {
    console.log('error--', error);
    return {};
  }
};

const changeKeyClient = (key, csvType) => {
  if (csvType === 'customer') {
    if (key === 'PoolLength') {
      key = 'poolHeight';
    } else if (key === 'ContactType') {
      key = 'customerType';
    } else if (key === 'InvoiceSendingType') {
      key = 'invoiceSendType';
    } else if (key === 'Postal / Zip Code') {
      key = 'postcode';
    } else if (key === 'ContactsFirst Name') {
      key = 'firstname';
    } else if (key === 'ContactsLast Name') {
      key = 'lastname';
    } else if (key === 'ContactsMobile Number') {
      key = 'mobileNo';
    } else if (key === 'ContactsLandlineNo') {
      key = 'landlineNo';
    } else if (key === 'ContactsEmailAddress') {
      key = 'email';
    } else if (key === 'Country') {
      key = 'country';
    } else if (key === 'State') {
      key = 'state';
    } else if (key === 'City/Town/Suburb') {
      key = 'suburb';
    } else if (key === 'Street Address') {
      key = 'streetAddress';
    } else if (key === 'PoolWidth') {
      key = 'poolWidth';
    } else if (key === 'PoolFinish') {
      key = 'poolFinish';
    } else if (key === 'ChlorinationType') {
      key = 'chlorinationType';
    } else if (key === 'ChlorinationType') {
      key = 'chlorinationType';
    } else if (key === 'Chlorination') {
      key = 'selectedModel';
    } else if (key === 'AutomaticInvoiceSending') {
      key = 'invoiceSendType';
    } else if (key === 'Gender') {
      key = 'gender';
    } else if (key === 'ClientNotes') {
      key = 'note';
    } else if (key === 'Company Name') {
      key = 'companyName';
    }
  }
  if (
    csvType === 'service'
    || csvType === 'product'
    || csvType === 'inventory'
  ) {
    key = key.replaceAll(' ', '');
    if (key === 'barcode') {
      key = 'barCode';
    } else if (key === 'description') {
      key = 'serviceDescription';
    } else if (key === 'tracked') {
      key = 'trackedInventory';
    } else if (key === 'soldUnit' || key === 'supplyUnit') {
      key = 'supplyUnit';
    } else if (key === 'buyCost') {
      key = 'cost';
    } else if (key === 'sellPrice') {
      key = 'retailCost';
    }
  }

  if (csvType === 'staff') {
    key = lowerFirstLetter(key);
  }
  return key;
};

export const revertKeyClient = (key, csvType) => {
  if (csvType === 'customer') {
    if (key === 'poolHeight') {
      key = 'Pool Length';
    } else if (key === 'customerType') {
      key = 'Contact Type';
    } else if (key === 'invoiceSendType') {
      key = 'Invoice Sending Type';
    } else if (key === 'postcode') {
      key = 'Postal / Zip Code';
    } else if (key === 'firstname') {
      key = "Contact's First Name";
    } else if (key === 'lastname') {
      key = "Contact's Last Name";
    } else if (key === 'mobileNo') {
      key = "Contact's Mobile Number";
    } else if (key === 'landlineNo') {
      key = "Contact's Landline No";
    } else if (key === 'email') {
      key = "Contact's Email Address";
    } else if (key === 'country') {
      key = 'Country';
    } else if (key === 'state') {
      key = 'State';
    } else if (key === 'suburb') {
      key = 'City/Town/Suburb';
    } else if (key === 'streetAddress') {
      key = 'Street Address';
    } else if (key === 'poolWidth') {
      key = 'Pool Width';
    } else if (key === 'poolFinish') {
      key = 'Pool Finish';
    } else if (key === 'chlorinationType') {
      key = 'Chlorination Type';
    } else if (key === 'selectedModel') {
      key = 'Chlorination';
    } else if (key === 'invoiceSendType') {
      key = 'Automatic Invoice Sending';
    } else if (key === 'gender') {
      key = 'Gender';
    } else if (key === 'note') {
      key = 'Client Notes';
    } else if (key === 'companyName') {
      key = 'Company Name';
    }
  }
  if (
    csvType === 'service'
    || csvType === 'product'
    || csvType === 'inventory'
  ) {
    key = key.replaceAll(' ', '');
    if (key === 'barcode') {
      key = 'barCode';
    } else if (key === 'description') {
      key = 'serviceDescription';
    } else if (key === 'tracked') {
      key = 'trackedInventory';
    } else if (key === 'soldUnit' || key === 'supplyUnit') {
      key = 'supplyUnit';
    } else if (key === 'buyCost') {
      key = 'cost';
    } else if (key === 'sellPrice') {
      key = 'retailCost';
    }
  }

  if (csvType === 'staff') {
    key = lowerFirstLetter(key);
  }
  return key;
};

export const checkKeysAndCreateData = (
  data,
  fileName,
  csvType,
  chlorinationModelData,
  teamMemberData,
  userId,
) => {
  const mainData = [];
  let readyToUpload = true;
  let errorAtRows = '';
  for (let i = 0; i < data.length; i++) {
    let obj = data[i];
    const altObj = Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [
        `${changeKeyClient(key, csvType)}`,
        value.toString(),
      ]),
    );
    const checkObj = Object.keys(altObj).length === 0;
    altObj.userid = userId;
    altObj.csvName = fileName;
    if (!checkObj) {
      if (csvType === 'customer') {
        altObj.customerType = altObj?.customerType ?? 'Householder';
      }
      mainData.push(altObj);
    } else {
      errorAtRows = `${errorAtRows}, ${(i + 1).toString()}`;
    }
  }
  return { mainData, readyToUpload, errorAtRows };
};

export const getTeam = async (setTeamMemberData) => {
  const response = await userInstance().get('/appointment/getTeamMemberList');
  const { teamList, code } = response.data;
  teamList.sort((a, b) => a.firstname.localeCompare(b.firstname));
  if (code === 200) {
    setTeamMemberData(teamList);
  }
};

export const totalPriceWithGst = (serArrayData) => {
  let totalGstamount = 0;
  let totalGst = 0;
  if (serArrayData?.length > 0) {
    serArrayData?.forEach((el) => {
      let quantityNum = 1;
      let costVal = el?.type === 'product'
        ? el?.cost?.replace(/,/g, '')
        : el?.renderCost?.replace(/,/g, '');
      if (el.type === 'product') {
        // eslint-disable-next-line no-restricted-globals
        quantityNum = !isNaN(el?.quantity) ? parseFloat(el?.quantity) : 1;
      }
      costVal = parseFloat(costVal) * quantityNum;
      // const tax = (!Number.isNaN(el?.tax) && el?.tax && el.tax !== 'NaN') ? el?.tax : 0;
      const tax = 10;
      const newText = parseFloat(tax) / 100;
      const newPrice = parseFloat(costVal) * newText;
      totalGst += newPrice;
      const priceWithGst = parseFloat(costVal) + newPrice;
      totalGstamount += priceWithGst;
      el.priceWithGst = addZero(priceWithGst.toFixed(2));
    });
  }
  return { totalGstamount, totalGst };
};

// 4- Function relataed to change string
export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }
  if (string.toLowerCase() === 'pc') {
    return string.toUpperCase();
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const validateDataRange = (data, name) => {
  let isValid = true;
  let error = {};
  if (name === 'top' && data < 0) {
    error.top = 'Value must be greater than 1';
    isValid = false;
  }
  if (name === 'left' && data < 0) {
    error.left = 'Value must be greater than 1';
    isValid = false;
  }
  if (name === 'imageRotate' && (data < 0 || data > 360)) {
    error.imageRotate = 'Value must be in between 1 to 360 only';
    isValid = false;
  }
  if (name === 'imageHeight' && data < 0) {
    error.imageHeight = 'Value must be greater than 1';
    isValid = false;
  }
  if (name === 'imageWidth' && data < 0) {
    error.imageWidth = 'Value must be greater than 1';
    isValid = false;
  }
  return { isValid, error };
};

export const validateDataRangeText = (data, name, countryType) => {
  let isValid = true;
  let topValue = countryType === 'au'
    ? 800 * 0.264583333
    : countryType === 'us'
      ? 800 * 0.0104166667
      : 800;
  let leftValue = countryType === 'au'
    ? 560 * 0.264583333
    : countryType === 'us'
      ? 560 * 0.0104166667
      : 560;
  let heightValue = countryType === 'au'
    ? 800 * 0.264583333
    : countryType === 'us'
      ? 800 * 0.0104166667
      : 800;
  let widthValue = countryType === 'au'
    ? 560 * 0.264583333
    : countryType === 'us'
      ? 560 * 0.0104166667
      : 560;
  let error = {};
  if (name === 'top' && data > 800) {
    error.top = `Value must be less than ${topValue.toFixed()}`;
    isValid = false;
  }
  if (name === 'left' && data > 560) {
    error.left = `Value must be less than ${leftValue.toFixed()}`;
    isValid = false;
  }
  if (name === 'height' && (data < 0 || data > 800)) {
    error.height = `Value must be in-between 0 to ${heightValue.toFixed()}`;
    isValid = false;
  }
  if (name === 'width' && (data < 0 || data > 100)) {
    error.width = `Value must be less than ${widthValue.toFixed()}`;
    isValid = false;
  }
  return { isValid, error };
};

export const coverBlankField = (value, name, arr) => {
  if ((value === '.' || !value?.trim()) && arr.indexOf(name) >= 0) {
    return 'error-text';
  }
  return '';
};

export const updateSingleServiceDateTime = (
  serArra,
  editServiceIndex,
  addduration,
) => {
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  let compyserArray = [...serArra];
  const { startTime, start, duration } = serArra[editServiceIndex] || {};
  let newduration = '15';
  let start2 = moment(start).toDate();
  const time = startTime.split(':');
  start2.setHours(time[0]);
  start2.setMinutes(time[1]);
  start2.setSeconds(0);
  if (editServiceIndex !== 0) {
    newduration = parseInt(duration) + addduration;
  }
  compyserArray[editServiceIndex].start = moment(start2).format(dateFormat);
  compyserArray[editServiceIndex].end = moment(
    new Date(start2.setMinutes(start2.getMinutes() + parseFloat(newduration))),
  ).format(dateFormat);
  compyserArray[editServiceIndex].duration = newduration;
  compyserArray[editServiceIndex].previousDuration = newduration;
  return compyserArray;
};

export const valueConverterToOther = (value, country) => {
  value = value?.replace('px', '');
  if (country === 'us') {
    value *= 0.0104166667;
  } else if (country === 'au') {
    value *= 0.264583333;
  }
  return value.toFixed(2);
};

export const valueConverterToPx = (val, country) => {
  let value = parseFloat(val);
  if (country === 'us') {
    value /= 0.0104166667;
  } else if (country === 'au') {
    value /= 0.264583333;
  }
  return value.toFixed(2);
};

function splitInput(input) {
  // Regular expression to match value, unit, and the name
  const regex = /(\d+)\s*(kg|litre|litres|lb|fl oz|ml|gram|grams|floz|oz|mm)\b\s*(.*)/i;
  const match = input.match(regex);

  if (match) {
    const name = match[3]; // The name after the unit

    return name.trim();
  }
  return '';
}

export const rmvQunt = (string) => {
  const regex = / \(\d+\s*(kg|litre|litres|lb|fl oz|ml|gram|grams|floz|oz|mm)\)/g;
  if (!string) return '';
  if (string?.includes('bags Sand (20 kg)')) {
    return 'Add Sand';
  }
  if (
    string?.includes('Coarse Glass (15kg)')
    && string?.includes('Fine Glass (15kg)')
  ) {
    return 'Add Coarse Glass & Fine Glass';
  }
  if (string?.match(/^\s*(add|Add)\b/i)) {
    if (string.indexOf('of') === -1) {
      return `Add ${splitInput(string)}`;
    }

    return `Add${string.split('of')[1]}`.replace(regex, '');
  }
  return string;
};
