/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import reload from '../../assets/home/refresh-icon.png';
import { convertQuantUnit } from '../../functions/index';

// Tomorrow Work Point
// Maintain newArrayAppointments with the help of Type
// Should Change GranCost and type for clarity aNd similarity in both Green Pool Calculation and BlackSpotAlgaeTop
// also pass "type " keyWord from parent Component for making the things clear.

const BlackSpotAlgaeTop = ({
  state, poolSize, handleChange, handleRequest, newArrayStateGreen, type, granCost, granBuyCost, isUsImperial,
}) => {
  let unitValue = isUsImperial ? 'lb' : 'kg';
  let secondArrayDosage = '';
  let secondDosage = '';
  let hoursLabour = '';
  let hoursLabourCost = '';
  let vaccumLabourCost = '';
  if (newArrayStateGreen[0].greenName === type) {
    secondArrayDosage = newArrayStateGreen.filter((el) => el.chemicalName === 'Second Chlorine Dosage');
    secondDosage = secondArrayDosage[0].dosageAndTime;
    hoursLabour = newArrayStateGreen.filter((el) => el.chemicalName === 'Labour Cost');
    hoursLabourCost = hoursLabour[0].dosageAndTime;
    const Vaccum = newArrayStateGreen.filter((el) => el.chemicalName === 'Minimum Cost for this Visit');
    if (Vaccum?.length > 0) {
      vaccumLabourCost = Vaccum[0].dosageAndTime;
    }
  }

  let result1 = [];
  let result2 = [];
  let result3 = [];
  let result4 = [];
  let result5 = [];
  let result6 = [];
  let result7 = [];

  function forCalculating(elgreen, elchemical, bool, stopValue) {
    let result = '';
    let actualOutput = '';
    let pooSizeForCalci = poolSize || 0;

    Array.isArray(state) ? newArrayStateGreen.map((el, i) => {
      if (el.greenName === elgreen && el.chemicalName === elchemical && bool === 'A') {
        result = ((parseFloat(el.dosageAndTime) / 40000) * parseFloat(pooSizeForCalci)).toFixed(2);
        if (!stopValue) result6.push(result);
      } else if (el.greenName === elgreen && el.chemicalName === elchemical && bool === 'B') {
        result = (((parseFloat(el.dosageAndTime) / 40000) * parseFloat(pooSizeForCalci) * secondDosage) / 100).toFixed(2);
        if (!stopValue) result7.push(result);
      } else if (el.greenName === elgreen && el.chemicalName === elchemical && bool === 'C') {
        result = (Math.ceil(((parseFloat(el.dosageAndTime) / 40000) * parseFloat(pooSizeForCalci) * parseFloat(granCost)) / 5) * 5).toFixed(2);
        result1.push(result);
      } else if (el.greenName === elgreen && el.chemicalName === elchemical && bool === 'D') {
        result = (Math.ceil(((((parseFloat(el.dosageAndTime) / 40000) * parseFloat(pooSizeForCalci) * secondDosage) / 100).toFixed(2) * granCost) / 5) * 5).toFixed(2);
        result2.push(result);
      } else if (el.greenName === elgreen && el.chemicalName === elchemical && bool === 'E') {
        result = (Math.ceil((parseFloat(el.dosageAndTime) * hoursLabourCost) / 5) * 5).toFixed(2);
        result3.push(result);
      } else if (el.greenName === elgreen && el.chemicalName === elchemical && bool === 'F') {
        result = (Math.ceil((parseFloat(el.dosageAndTime) * hoursLabourCost) / 5) * 5).toFixed(2);
        result4.push(result);
      } else if (el.greenName === elgreen && el.chemicalName === 'Vaccum Cost for Final Visit' && bool === 'G') {
        actualOutput = (parseFloat(el.dosageAndTime) * vaccumLabourCost) / 40000 * parseFloat(pooSizeForCalci);
        let initialresult = actualOutput > 150 ? actualOutput : '150';
        initialresult = Math.ceil(actualOutput / 5) * 5;
        result = parseFloat(initialresult).toFixed(2);
        result5.push(result);
      }
    }) : null;
    return result;
  }

  // let secondDosage = secondArrayDosage[0].dosageAndTime;
  return (
    <>
      <div className="search-pool-size">
        <h6>Pool Size</h6>
        <Form.Group controlId="formBasicEmail">
          {Array.isArray(state)
            ? (
              <>
                {state.map((el, index) => (el.chemicalName === 'Pool Size'
                  ? (
                    <>
                      <Form.Control type="number" name="dosageAndTime" value={el.dosageAndTime} onChange={(e) => handleChange(e, index)} />
                      <span>{isUsImperial ? 'gallons' : el.unit}</span>
                    </>
                  ) : null))}
              </>
            ) : null}

        </Form.Group>
        <Button type="submit" className="btn btn-primary reload-btn" onClick={handleRequest}><img src={reload} alt="search" /></Button>
      </div>

      <div className="pool-chemistry-box green-pool-calculation">
        {/* <div className="main-title">
      <h6>Green Pool Calculations</h6>
    </div> */}

        <div className="green-pool-table pool-cost-table">
          <h5>Black-Spot Cost Calculation </h5>
          <p className="granular">(Black-Spot Remover)</p>
          <div className="pool-color">
            <div className="right-pool-table second-dosage">
              <h6 className="black-one blackCalci1">
                Black-Spot
                <span>1</span>
              </h6>
              <h6 className="black-two blackCalci2">
                Black-Spot
                <span>2</span>
              </h6>
              <h6 className="black-three blackCalci3">
                Black-Spot
                <span>3</span>
              </h6>
              <h6 className="black-four blackCalci4">
                Black-Spot
                <span>4</span>
              </h6>
            </div>
          </div>
          <div className="main-table">

            <div className="dosage-part">
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Black-Spot Remover Dosage</p>
                  <p>Dosage - 1st Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.greenName !== '' && el.chemicalName === 'Initial Black-Spot Remover Dosage'
                          ? (
                            <p>
                              {' '}
                              {convertQuantUnit(forCalculating(el.greenName, el.chemicalName, 'A'), unitValue).chemValue}
                              <span>{convertQuantUnit(forCalculating(el.greenName, el.chemicalName, 'A', 'stop'), unitValue).chemUnit}</span>
                            </p>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Black-Spot Remover Dosage</p>
                  <p>Dosage - 2nd Visit</p>

                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.greenName !== '' && el.chemicalName === 'Initial Black-Spot Remover Dosage'
                          ? (
                            <p>
                              {' '}

                              {convertQuantUnit(forCalculating(el.greenName, el.chemicalName, 'B'), unitValue).chemValue}
                              <span>{convertQuantUnit(forCalculating(el.greenName, el.chemicalName, 'B', 'stop'), unitValue).chemUnit}</span>
                            </p>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Remover Cost</p>
                  <p>- Initial Dosage</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (
                          el.greenName !== '' && el.chemicalName === 'Initial Black-Spot Remover Dosage' ? (
                            <p>
                              {' '}
                              <span>$</span>
                              {forCalculating(el.greenName, el.chemicalName, 'C')}
                            </p>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              {/* {console.log(result6, result7) } */}
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Remover Cost</p>
                  <p>- Second Dosage</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.greenName !== '' && el.chemicalName === 'Initial Black-Spot Remover Dosage' ? (
                          <p>
                            {' '}
                            <span>$</span>
                            {forCalculating(el.greenName, el.chemicalName, 'D')}
                          </p>
                        ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Labour Cost</p>
                  <p>- First Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.greenName === '' && el.chemicalName === 'Hours Required for 1st & 2nd Visit'
                          ? (
                            <>
                              <>
                                {result1.map((elt) => (
                                  <p>
                                    {' '}
                                    <span>$</span>
                                    {forCalculating(el.greenName, el.chemicalName, 'E')}
                                  </p>
                                ))}
                              </>

                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Labour Cost</p>
                  <p>- Second Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (el.greenName === '' && el.chemicalName === 'Hours Required for 1st & 2nd Visit'
                          ? (
                            <>
                              <>
                                {result1.map((elt) => (
                                  <p>
                                    {' '}
                                    <span>$</span>
                                    {forCalculating(el.greenName, el.chemicalName, 'F')}
                                  </p>
                                ))}

                              </>

                            </>
                          ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage">
                  <p>Vaccuming  Cost</p>
                  <p>- Final Visit</p>
                </div>
                <div className="right-pool-table">
                  {Array.isArray(state)
                    ? (
                      <>
                        {state.map((el) => (

                          el.greenName === '' && el.chemicalName === 'Vaccum Cost for Final Visit'
                            ? (
                              <>
                                <>
                                  {result1.map((elt) => (
                                    <p>
                                      {' '}
                                      <span>$</span>
                                      {forCalculating(el.greenName, el.chemicalName, 'G')}
                                    </p>
                                  ))}

                                </>

                              </>
                            ) : null))}
                      </>
                    ) : null}
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage bottom-cost-table">
                  <p>Visit Total</p>

                </div>
                <div className="right-pool-table bottom-cost-table">

                  <>
                    {result1.map((elt, index) => (
                      <p>
                        {' '}
                        <span>$</span>
                        {(parseFloat(result1[index]) + parseFloat(result2[index]) + parseFloat(result3[0]) + parseFloat(result4[0]) + parseFloat(result5[0])).toFixed(2)}
                      </p>
                    ))}

                  </>
                </div>
              </div>
              <hr />
              <div className="pool-table-content">
                <div className="left-pool-table second-dosage bottom-cost-table-new">

                  <p>Labour & Margin</p>
                </div>
                <div className="right-pool-table bottom-cost-table-new">

                  <>
                    {result1.map((elt, index) => (
                      <p>
                        {' '}
                        <span>$</span>
                        {((parseFloat(result3[0]) + parseFloat(result4[0]) + parseFloat(result5[0])) + (parseFloat(result6[index]) * (granCost - granBuyCost)) + (parseFloat(result7[index]) * (granCost - granBuyCost))).toFixed(2)}
                      </p>
                    ))}

                  </>

                </div>
              </div>
              {/* <hr /> */}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};
BlackSpotAlgaeTop.propTypes = {
  state: PropTypes.func.isRequired,
  poolSize: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  newArrayStateGreen: PropTypes.func.isRequired,
  type: PropTypes.func.isRequired,
  granBuyCost: PropTypes.func.isRequired,
  granCost: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
};
export default BlackSpotAlgaeTop;
