/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-cond-assign */
/* eslint-disable no-self-assign */
/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import Helmet from 'react-helmet';
import { Form, Col, Button } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import { userInstance, withoutAuth } from '../../config/axios';
import CalculatorSalt from './calculatorsalt';
import {
  showErrMsg,
  calculateEndingTimeArr,
  getCalendarPath,
  queryString,
  convertQuantUnit,
} from '../../functions/index';
import {
  LoadExistValue, validateData, labourCostCalculation,
  totalSellCost, consumableMargin,
  getDrainWaterLevelPercent, forGettingAllDrains, consumableDatabaseMargin,
  getChemcialAfterReducing, getChemcialAfterReducingSrc, getSourceReducedReadings, calculatedSumForInputs, sumForInputs, finalDrainInp,
  totalBuyCost, drainNewCalculation,
  higherThanTargetLevels,
  litresUnits,
  calciumAdjustmentCal,
  getUpdateCalVariable,
} from './calculatorUtils';
import {
  greenPoolAndDrainPoolCalculation, chemicalDrainGreen, chemicalNewDrainGreen,
} from './greenAndDrainUtils';
import {
  calculatingPoolWaterCapacity, chemicalUnitkilogramAdjustment,
  chemicalUnitAdjustment, chemicalValueAdjustment, getBufferUnit,
  getUnit, getInGrams, getPhosphateMsg, getStabiliserMsg,
  getCalciumMsg, getSaltMsg, getCombinedChlorineMessage, getCopperMsg, getChlorineMsg,
  getPhMsg,
  getClarifierMsg,
  getAlgaecideMsg,
  getFlockMsg,
  getAlkMsg,
  getSaltConvertMsg,
  getCalciumPhReducerMsg,
  maxAchieve,
  getValUnitInLitres,
  bufferUnitVal,
  getPhosValUnit,
  getCopperValUnits,
  algaeClariValUnits,
  flockValUnits,
  stripMessageReturn,
  converChlorine,
} from './waterTestCalciUtils';

import { handleFilterCalculate } from './sandFilterUtils';

import {
  initialAlkMsgsSet, initialInputs, initialSoureInputs, defaultChemicalState, initialCal, initialglbobj, initialValidationData, categoryObj, inpImage, fourthVisit, inpStripImage, inpStripImage4, phArray, calciumHardnessArray, combinedChlorineArray, chlorineArray, stabiliserArray, alkalinityArray,
} from './green_state';
import draining from '../../assets/home/refresh-icon.png';
import './calculator.css';
import CalculatorReadings from './calculatorReadings';
import GreenpoolWorking from './greenPoolWorking';
import WaterVolumeSalinity from './salinity';
import PoolMainDataSelection from './poolMainDataSelection';
import SourceReading from './sourceReading';
import UpdateSourceReading from './updateSourceReading';
import ChemicalsAddedPopUp from './chemicalsAdded';
import EditChemicalQuantity from '../chemicalQuantity/editChemicalQuantity';
import EditChemicalSellPrice from '../chemicalQuantity/editChemicalSellPrice';
import GreenPool from './greenPool';
import CalculatorTop from './calculatorTop';
import DrainPool from './drainPool';
import WaterLossCalculator from './waterLossCalculator';
import CalculatorMiddle from './calculatorMiddle';
import PoolReport from './poolReport';
import ZodiacCalciumPopUp from './zodiacCalciumPopUp';
import BlackSpotPool from './blackSpot';
import AfterEditChemicalPopUp from './afterEditChemicalPopup';
import DeletePopUp from '../DeletePopUp/index';
import ClientDetailsUpdatePopUp from './clientDetailsUpdatePopUp';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import TestStripe from './testStrip';
import { ocrPath, server } from '../../config/keys';
import TestStripConfirm from './testStripConfirm';
import TaxApplicable from '../poolsetup/taxApplicable';
import MultiEditQuantityPopUp from '../chemicalQuantity/multiEditChemicalQuantity';
import MultiEditChemicalSellPrice from '../chemicalQuantity/multiEditChemicalSellPrice';
import CalciumTitrationPopup from './calciumTitrationPopup';
import toastMessage from '../../functions/toastMessage';
import Loader from '../loader/loader';
import InverterHeater from './inverterHeater';

let isOkCalcium = false;

function Calculator() {
  const { userData } = useContext(AuthDataContext);
  const {
    nextStartTime, Id: clId, appId: aptid, eventDate, poolHeight: clientPoolHeight, poolWidth: clientPoolWidth,
    editChemicalList, invoice, startDateTime, employee_id, watId, clident, visitMessage: vmsg, watTesId, back,
    quoteWatTesId, from, quoteId, invFrom, withClientReturn, skipValue, namee,
  } = queryString();

  const { state } = useLocation();

  const Id = clId || state?.clientIdentity || clident;
  const appId = aptid || state?.id;
  const waterId = watId || watTesId;
  const visitMessage = appId ? (vmsg || state?.visitMessage) : vmsg;
  const stateEdit = appId && state ? state?.editChemicals : '';
  const editChem = appId && editChemicalList ? editChemicalList : '';
  const editChemicals = (editChem || stateEdit) || (editChemicalList || '');

  const editFalseStatus = !!((editChemicals || visitMessage));

  const clData = (Id || editChemicals || (startDateTime && visitMessage)) ? localStorage.getItem('apptData') : '';

  const { clientAllData, setClientAllData } = useContext(AuthDataContext);

  const clientAllInfo = ((Id || editChemicals || (startDateTime && visitMessage)) && clData ? JSON.parse(clData) : {});

  const comingServiceName = (appId && visitMessage) ? clientAllInfo?.groupAppointments[0].appointment_calc_type : '';
  const chemicalTestComingArray = (appId && visitMessage) ? clientAllInfo?.groupAppointments : [];

  const clientDetailsData = (Id && clData && clientAllInfo?.clientAllDetails) ? clientAllInfo?.clientAllDetails : {};
  const finalIndex = (Id && clData) ? clientAllInfo?.appointmentData?.clientSiteAddressIndex : '';
  const finalData = (Id && clData && clientDetailsData?.siteAddresses?.length) ? clientDetailsData?.siteAddresses[finalIndex] : clientDetailsData;
  const clientAllDetails = finalData || {};

  const convertPoolChlorine = [
    'Aquatherepe Transform',
    'Aquatherepe Transform - With ORP Probe',
  ];
  const poolTypeArray = [
    'Non-Mineral Pool',
    'Convert Pool to Mineral Pool',
    'Aquatherepe Transform Maintenance',
    'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test',
  ];
  const limitInp = {
    chlorine: 100.00,
    combinedChlorine: 100.00,
    ph: 14.0,
    alkalinity: 1000.00,
    calciumHardness: 10000.00,
    hardCalcium: 10000.00,
    hardMagnesium: 10000.00,
    stabiliser: 1000.00,
    copper: 2.00,
    phosphates: 10000.00,
    salt: 100000.00,
  };

  const historyBack = useHistory();

  const [calVariable, setCalVariable] = useState(initialCal);
  const [poolInputs, setPoolInputs] = useState(initialInputs);
  const [oldCopyPoolInputs, setOldCopyPoolInputs] = useState(initialInputs);
  const [carryingPoolInputsData, setCarryingPoolInputsData] = useState(null);
  const [globj, setGlObj] = useState(initialglbobj);
  const [result, setResult] = useState(false);
  const [customDepths, setCustomDepths] = useState(false);
  const [poolDepth, setPoolDepth] = useState('Average Depth - Rectangular Pool');
  const [poolFinish, setPoolFinish] = useState('Concrete Pool - Outdoor');
  const [chlorinationType, setChlorinationType] = useState('Salt Pool');
  const [showModel, setShowModel] = useState(true);
  const [aerate, setAerate] = useState(false);
  const [alkMsgSet, setAlkMsgs] = useState(initialAlkMsgsSet);
  const [diameterInput, setDiameter] = useState(false);
  const [selectedModel, setSelectedModel] = useState(4000);
  const [validationData, setValData] = useState(initialValidationData);
  const [chlorineNewmodel, setChlorineNewmodel] = useState([]);
  const [chlorineModel, setChlorineModel] = useState(chlorineNewmodel);
  const [mineralModel, setMineralModel] = useState('');
  const [stabState, setStabState] = useState('');
  const [saltValue, setSaltValue] = useState('');
  const [saltNegative, setSaltNegative] = useState('');
  const [saltPositive, setSaltPositive] = useState('');
  const [drainPoint, setDrainPoint] = useState('');
  const [saltMagnaValue, setMagnaSaltValue] = useState('');
  const [saltMagnaNegative, setMagnaSaltNegative] = useState('');
  const [magnaStabiliser, setMagnaStabiliser] = useState('');
  const [saltMagnaPositive, setMagnaSaltPositive] = useState('');
  const [drainMagnaPoint, setMagnaDrainPoint] = useState('');
  const [magnaPoolAdjust, setmagnaPoolAdjust] = useState('');
  const [calciumPoolAdjust, setCalciumPoolAdjust] = useState('');
  const [chemicalBalanceValue, setChemicalBalanceValue] = useState('');
  const [mineralValue, setMineralValue] = useState([]);
  const [manualValue, setManualValue] = useState('Non-Mineral Pool');
  const [serviceAnalysis, setServiceAnalysis] = useState('Water Test');
  const [greenTreatment, setGreenTreatment] = useState('Liquid Chlorine');
  const [status, setStatus] = useState(true);
  const [waterPoolStatus, setWaterPoolStatus] = useState(true);
  const [greenApiData, setGreenApiData] = useState([]);
  const [acidWashApiData, setAcidWashApiData] = useState([]);
  const [greenSplitName, setGreenSplitName] = useState('Liquid');
  const [greenSelect, setGreenSelect] = useState('');
  const [poolFilterData, setPoolFilterData] = useState([]);
  const [filteringData, setFilteringData] = useState({
    hoursRequired: '',
    setUpCost: '',
    labourCost: '',
  });

  const [costsFiltering, setCostsFiltering] = useState({
    coarseGlassBuyCost: '',
    fineGlassBuyCost: '',
    coarseGlassSellCost: '',
    fineGlassSellCost: '',
    sandMediaBuyCost: '',
    sandMediaSellCost: '',
  });

  const initalShowFullHardness = {
    calcium: false,
    magnesium: false,
    fullhardness: false,
  };

  const [initialLevelData, setInitialLevelData] = useState([{
    value: 'First Visit',
    service: [],
    numericValue: '1st',
    sel: '',
    _id: '',
    summary: {},
    days: 'today',
    isChem: false,
  },
  {
    value: 'Second Visit',
    service: [],
    numericValue: '2nd',
    sel: '',
    _id: '',
    summary: {},
    days: '',
    isChem: false,
  },
  {
    value: 'Third Visit',
    service: [],
    numericValue: '3rd',
    sel: '',
    _id: '',
    summary: {},
    days: '',
    isChem: false,
  },
  {
    value: 'Fourth Visit',
    service: [],
    numericValue: '4th',
    sel: '',
    _id: '',
    summary: {},
    days: '',
    isChem: false,
  }]);

  const [levelData, setLevelData] = useState([]);

  const statusObj = {
    cancel: false,
  };

  let valueType = userData?.calendarSettings?.unit === 'metric';

  const [checkList, setCheckList] = useState(false);
  const [takingPh, setTakingPh] = useState('');
  const [confirmChemical, setConfirmChemical] = useState(false);
  const [filterOptions, setFilterOptions] = useState(
    'Replace with Glass (filters to 3 microns)',
  ); /// new Functionalities SandFilter
  const [filterSelect, setFilterSelect] = useState('');
  const [filterStatus, setFilterStatus] = useState(false);
  const [sourceReading, setSoourceReading] = useState(false);
  const [sourceSelected, setSourceSelected] = useState(false);
  const [chemicalAddSelected, setChemicalAddSelected] = useState(false);
  const [filterCost, setFilterCost] = useState({
    media: '',
    coarseAndFine: '',
  });
  const [staffDetails, setStaffDetails] = useState({
    staffName: '',
    staffId: '',
  });

  const [selectedWaterlevel, setSelectedWaterLevel] = useState('Same Water Level in Pool');
  const [selectedWaterLevelAmount, setSelectedWaterLevelAmount] = useState('1');

  const [chemicalBalanceWaterObj, setChemicalBalanceWaterObj] = useState({
    algaecidebalanceName: '',
    totalhardnessbalanceName: '',
    clarifierbalanceName: '',
    combinedchlorinebalanceName: '',
    flockbalanceName: '',
    freechlorinebalanceName: '',
    phbalanceName: '',
    phosphatesbalanceName: '',
    stabilisercyabalanceName: '',
    totalalkalinitytabalanceName: '',
  });

  const MaxLength = 30;
  const [chemicalArray, setChemicalArray] = useState([]);
  const [revertStatus, setRevertStatus] = useState(false);
  const [editQuantityStatus, setEditQuantityStatus] = useState(false);
  const [editSellPriceStatus, setEditSellPriceStatus] = useState(false);
  const [multiEditQuantityStatus, setMultiEditQuantityStatus] = useState(false);
  const [multiEditSellPriceStatus, setMultiEditSellPriceStatus] = useState(false);
  const [sellPriceStatus, setSellPriceStatus] = useState(true);
  const [checkingDrainStatus, setDrainStatus] = useState(true);
  const [chemicalIndex, setChemicalIndex] = useState(null);
  const [greenPoolCalculation, setGreenPoolCalculation] = useState({
    labourCost: '',
    secondChlorineDosage: '',
    hoursRequired: '',
    vaccumCost: '',
  });
  const [chemicalBalanceStatus, setChemicalBalanceStatus] = useState(false);
  const [getValue, setGetValue] = useState('Magna Minerals');
  const [waterSourceSelect, setWaterSourceSelect] = useState('waterSource');
  const [chemicalOptionsSelected, setChemicalOptionsSelected] = useState('chemicalsAdded');
  const [updatedWaterSourceStatus, setUpdatedWaterSourceStatus] = useState(false);
  const [oldPoolInputs, setOldPoolInputs] = useState(initialInputs);
  const [waterTestOptions, setWaterTestOptions] = useState('Water Test Only');
  const [waterLossCalculator, setWaterLossCalculator] = useState('Water Loss Per Day');
  const [sourcePoolInputs, setSourcePoolInputs] = useState(initialSoureInputs);
  const [getresDrainData, setgetresDrainData] = useState(null);

  const [waterLossStatus, setWaterLossStatus] = useState(false);
  const [waterHistory, setWaterHistory] = useState({});
  const [secondVisit, setSecondVisit] = useState(null);
  const [pId, setPId] = useState();
  const [zodiacStatus, setZodiacStatus] = useState(false);
  const [getZodiacStatus, setGetZodiacStatus] = useState(false);
  const [blackTreatment, setBlackTreatment] = useState('Black-Spot Remover');
  const [blackSelect, setBlackSelect] = useState('');
  const [blackSplitName, setBlackSplitName] = useState('Black-Spot');
  const [blackApiData, setBlackApiData] = useState([]);
  const [uniqueClient, setUniqueClient] = useState('');
  const [fetchImage, setFetchImage] = useState(true);
  const [cancelProcessing, setCancelProcessing] = useState(statusObj);
  const [allFields, setAllFields] = useState(true);
  const [blankInputFields, setBlankInputFields] = useState(null);
  const [oldLevelDataState, setOldLevelDataState] = useState([]);
  const [clientPopUpStatus, setClientPopUpStatus] = useState(false);
  const [clientSelectStatus, setClientSelectStatus] = useState(false);
  const [selectedModelObj, setSelectedModelObj] = useState(null);
  const [allPoolParameters, setAllPoolParameters] = useState(null);
  const [stateWaterHistory, setStateWaterHistory] = useState(null);
  const [waterHistoryId, setWaterHistoryId] = useState('');
  const [originalReadings, setOriginalReadings] = useState(null);
  const [showbtn, setShowBtn] = useState(false);
  const [previousCalcium, setPreviousCalcium] = useState(null);
  const [showFullHardness, setShowFullHardness] = useState(initalShowFullHardness);
  const [calciumHardnessIdeal, setCalciumHardnessIdeal] = useState('');
  const [titrationPopup, setTitrationPopup] = useState(false);
  const [titrationPopupOptions, setTitrationPopupOptions] = useState('dontShowCalcium');
  const [showCalcium, setShowCalcium] = useState(true);
  const [isStateFlagValue, setIsStateFlagValue] = useState(false);
  const [allowedPermission, setAllowedPermission] = useState({
    editPrice: false,
    stripUpload: false,
  });

  const waterObj = {

  };

  const defaultChemicalDisplayState = {
    freeChlorine: true,
    totalChlorine: true,
    pH: true,
    totalAlkalinity: true,
    calciumHardness: true,
    stabiliser: true,
    copper: true,
    phosphates: true,
    salt: true,
  };

  const [manageStrip, setManageStrip] = useState(defaultChemicalDisplayState);

  // mineral changes
  const [calciDisplayChemicals, setCalciChemicalsDisplay] = useState({ ...defaultChemicalDisplayState });
  const [backcalciDisplayChemicals, setBackCalciChemicalsDisplay] = useState(defaultChemicalDisplayState);
  const [taxApplicable, setTaxApplicable] = useState({
    chemicalsTax: 1,
    labourTax: 1,
    actualLabourTax: 0,
    actualChemicalTax: 0,
  });

  const [testStrip, setTestStrip] = useState(false);
  const [inverterSize, setInverterSize] = useState('24');
  const [inverterResult, setInverterResult] = useState(false);

  // const defaultCalciumHardnessArray = [{ value: '0', color: '#0F115F' }, { value: '100', color: '#2B1B70' }, { value: '250', color: '#2D0B61' }, { value: '256', color: '#661462' }, { value: '260', color: '#831372' }];

  const [stripArray, setStripArray] = useState({
    calciumHardness: calciumHardnessArray,
    ph: phArray,
    stabiliser: stabiliserArray,
    alkalinity: alkalinityArray,
    chlorine: chlorineArray,
    combinedChlorine: combinedChlorineArray,
  });

  const initialStripObject = {
    calciumHardness: [
      { value: '0', color: '#3c609e' },
      { value: '250', color: '#5a4b91' },
      { value: '500', color: '#6f3c7e' },
      { value: '1000', color: '#8f418b' },
      { value: '3500', color: '#e78de5' },
    ],

    ph: [
      { value: '0.0', color: '#f5d088' },
      { value: '6.0', color: '#bf7e45' },
      { value: '7.2', color: '#b73c20' },
      { value: '8.4', color: '#b6033a' },
      { value: '14.0', color: '#600111' },
    ],

    chlorine: [
      { value: '0.0', color: '#bec6bc' },
      { value: '3.0', color: '#997db8' },
      { value: '5.0', color: '#7d509f' },
      { value: '10.0', color: '#622579' },
      { value: '50.0', color: '#240734' },
    ],

    combinedChlorine: [
      { value: '0.0', color: '#cfcf97' },
      { value: '3.0', color: '#96c19c' },
      { value: '5.0', color: '#77b89c' },
      { value: '10.0', color: '#56b299' },
      { value: '50.0', color: '#05311d' },
    ],

    alkalinity: [
      { value: '0', color: '#ba8f49' },
      { value: '120', color: '#5a766a' },
      { value: '180', color: '#2a6966' },
      { value: '240', color: '#2c6273' },
      { value: '500', color: '#163763' },
    ],

    stabiliser: [
      { value: '0', color: '#b88549' },
      { value: '100', color: '#9d354a' },
      { value: '150', color: '#7a0453' },
      { value: '300', color: '#690b6e' },
      { value: '500', color: '#48074d' },
    ],
  };

  const displayStep = {
    calciumHardness: 20,
    ph: 2,
    chlorine: 10,
    combinedChlorine: 10,
    alkalinity: 10,
    stabiliser: 10,
  };

  const displayName = {
    calciumHardness: 'Calcium Hardness',
    ph: 'pH',
    chlorine: 'Free Chlorine',
    combinedChlorine: 'Total Chlorine',
    alkalinity: 'Total Alkalinity',
    stabiliser: 'Stabiliser (CYA)',
  };

  const initialHexValues = {
    calciumHardness: '',
    ph: '',
    chlorine: '',
    combinedChlorine: '',
    alkalinity: '',
    stabiliser: '',
  };

  const [hexObj, setHexObj] = useState({ ...initialHexValues });
  let [AllArray, setAllArray] = useState(JSON.parse(JSON.stringify(initialStripObject)));

  const staticServerPathForImage = 'https://dev.calendar-pro.com';

  const [testConfirmValue, setTestConfirmValue] = useState('Don’t Test Salt Levels');
  const [testValueStatus, setTestValueStatus] = useState(false);
  const [multiVisit, setMultiVisit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDrainCost, setShowDrainCost] = useState(false);

  const msgGroup = {
    aquaMsg: 'Use Aquachek 7 test-strips to measure Calcium Hardness.',
    impactMsg:
      'Return to Test Strip Page & select “Check Salt Readings”, as mineral salts impact Calcium readings.',
    aquaFullMsg:
      'To measure minerals required enter salt readings.',
    testStripMsg:
      'Return to Test Strip Page & select “Check Salt Readings” to measure Salt required.',
    onlyAquaCheck: 'Use Aquachek 7 test-strips to test ',
  };

  // Hex Color Percentage Difference`

  const percentageDifference = {
    stabiliser: {},
    ph: {},
    alkalinity: {},
    combinedChlorine: {},
    chlorine: {},
    calciumHardness: {},
  };

  const [percent, setPercent] = useState({ ...percentageDifference });
  const [selectedEditOption, setSelectedEditOption] = useState('calculatedCost');
  const [editChemicalPopUp, setEditChemicalPopUp] = useState(false);
  // for keeping copy after edit quantity chemicals
  const [levelDataAfterQuantityEdit, setLevelDataAfterQuantityEdit] = useState([]);

  const poolMsgError = () => {
    let poolCapacity = 0;
    let poolMsg = '';
    if (clientPoolHeight && clientPoolWidth) {
      poolCapacity = clientPoolWidth * clientPoolHeight * 1250;
      poolMsg = '';
      poolCapacity = Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000;
      const exceedLimitValue = valueType ? 2500000 : 660000;
      if (parseInt(poolCapacity, 10) >= exceedLimitValue) {
        poolMsg = 'Exceeded Limit';
      }
    }
    return { poolMsg, poolCapacity };
  };

  const getLatHistory = async (Id) => {
    if (Id) {
      try {
        const response = await userInstance().get(
          `/watertest/getLatWaterHistory?id=${Id}`,
        );

        const { waterList, msg } = response.data;
        const clientChlorType = clientAllDetails?.chlorinationType;
        const watHis = (waterList && waterList?.length && waterList[0]?.history) ? waterList[0]?.history : {};
        const copyComingInputs = watHis?.poolInputs || poolInputs;
        setStateWaterHistory(watHis);
        waterObj.watHis = watHis;
        if (watHis?.globj) {
          decryptPool(watHis);
        } else if (watHis?.chlorinationType && watHis?.poolFinish) {
          setPoolFinish(watHis?.poolFinish);
          setChlorinationType(watHis?.chlorinationType);
          setSelectedModel(watHis?.selectedModel);
          setGetValue(watHis?.getValue);
          setManualValue(watHis?.manualValue);
          setWaterTestOptions(watHis?.waterTestOptions);
          setServiceAnalysis(watHis?.serviceAnalysis);
          setPoolDepth(watHis?.poolDepth);
          setCalVariable(watHis?.calVariable);
          setGetValue(watHis?.getValue);
          setGreenSplitName(watHis?.greenSplitName);
        }
        if (clientChlorType) {
          let cld = clientAllDetails;
          if (cld.chlorinationType === 'Mineral Pool with Chlorinator') {
            cld.chlorinationType = 'Mineral Pool';
          }

          if (cld.chlorinationType === 'Manually Chlorinated') {
            setShowModel(false);
          }

          const { poolCapacity, poolMsg } = poolMsgError(clientPoolWidth, clientPoolHeight);
          setPoolInputs({
            ...copyComingInputs, poolWidth: (clientPoolWidth || cld?.poolWidth), poolHeight: (clientPoolHeight || cld.poolHeight), poolCapacity, diameter: cld.diameter, shallowEnd: cld.shallowEnd, deepEnd: cld.deepEnd,
          });

          if (
            cld.poolDepth === 'Specific Depths - Rectangular Pool'
            || cld.poolDepth === 'Specific Depths - Circular Pool'
          ) {
            setCustomDepths(true);
          } else {
            setCustomDepths(false);
          }
          if (
            cld.poolDepth === 'Average Depth - Circular pool'
            || cld.poolDepth === 'Specific Depths - Circular Pool'
          ) {
            setDiameter(true);
          } else {
            setDiameter(false);
          }

          setPoolFinish(cld.poolFinish);
          setChlorinationType(cld.chlorinationType);
          setSelectedModel(cld.selectedModel);
          setGetValue(cld.selectedModel);
          setPoolDepth(cld.poolDepth);
          setCalVariable({ ...calVariable, poolMsg });
          if (cld.chlorinationType === 'Manually Chlorinated') setManualValue(selectedModel);
          // console.log('condition3');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSourceInputs = (e) => {
    const numReg = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;
    let { value } = e.target;
    const { name } = e.target;
    let val = value.replace('-', '');
    if (value.charAt(0) === '.') {
      val = `0${value}`;
      value = `0${value}`;
    }
    // eslint-disable-next-line no-restricted-globals
    if ((numReg.test(parseFloat(val)) && !isNaN(val)) || !val) {
      setSourcePoolInputs({ ...sourcePoolInputs, [name]: value });
    }
  };

  const getWaterLossData = () => {
    // if (e) e.preventDefault();
    let isValidWater = true;
    const {
      amountOfWaterLost, poolHeight, poolWidth,
    } = poolInputs;
    if (!amountOfWaterLost) {
      isValidWater = false;
      toastMessage('error', 'Enter an amount for Amount of Water Lost.');
    }
    if (isValidWater) {
      let waterLossPerDay = (amountOfWaterLost * poolHeight * poolWidth).toFixed(2);
      let actualWaterLossPerHour = waterLossCalculator === 'Water Loss Per Day' ? (waterLossPerDay / 24) : ((waterLossPerDay / 24) / 7);
      let waterLossPerHour = actualWaterLossPerHour.toFixed(2);
      let waterLossPerMinute = ((actualWaterLossPerHour / 60) * 1000).toFixed(2);
      setPoolInputs({
        ...poolInputs, waterLossPerMinute, waterLossPerHour, waterLossPerDay,
      });
      setWaterLossStatus(!waterLossStatus);
      encryptPool();
    }
  };

  // RGB Strip Work
  const hexToRGBRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const convertHexColor = (val) => hexToRGBRegex.exec(val);

  function RGBDifference(index, greenHex, strHex, boxHex, isMatch, name, copy, type, fromWhere, proceed) {
    let boxColor = parseInt(boxHex[index], 16);
    let greenColor = parseInt(greenHex[index], 16);
    let stripColor = parseInt(strHex[index], 16);
    // let percentDifference = fromWhere === 'readingImage' ? stripColor / greenColor : percent[name][`percent${type}`];

    let percentDifference = stripColor / greenColor;
    if (fromWhere !== 'readingImage') {
      percentDifference = percent[name][`percent${type}`];
    }

    // if (name === 'alkalinity') console.log('****', { percentDifference });
    if (fromWhere === 'readingImage') {
      copy[name][`percent${type}`] = percentDifference;
    }
    let diff = isMatch ? stripColor : percentDifference * boxColor;
    let act = diff > 255 ? 255 : Math.floor(diff);
    return act;
  }

  function hexToRgb(bxHex, stripHex, isMatch, greenChartColor, name, fromWhere, proceed, value) {
    const copyPercent = { ...percent };
    let boxHex = convertHexColor(bxHex);
    let greenHex = convertHexColor(greenChartColor);
    let strHex = convertHexColor(stripHex);

    if (greenHex && strHex && boxHex) {
      let actR = RGBDifference(1, greenHex, strHex, boxHex, isMatch, name, copyPercent, 'R', fromWhere, proceed, value);
      let actG = RGBDifference(2, greenHex, strHex, boxHex, isMatch, name, copyPercent, 'G', fromWhere, proceed, value);
      let actB = RGBDifference(3, greenHex, strHex, boxHex, isMatch, name, copyPercent, 'B', fromWhere, proceed, value);

      let rgbValue = `rgb(${actR},${actG},${actB})`;
      setPercent({ ...copyPercent });
      return rgbValue;
    }

    return '';
  }

  const handleBoxInput = (name, value, type, comArray, inputSource, hexName, fromWhere) => {
    const copy = JSON.parse(JSON.stringify(comArray));
    let copyValue = value;
    const createrName = `${name}Array`;

    if (createrName && type === 'strip') {
      if (value === '') {
        copy[name] = initialStripObject[name];
        return copy;
      }
      if (name === 'calciumHardness' || name === 'alkalinity' || name === 'stabiliser') value = parseInt(Math.round(value));
      if (name === 'calciumHardness') value = parseInt(Math.round(value / 10) * 10);
      if (name === 'ph' || name === 'chlorine' || name === 'combinedChlorine') value = parseFloat(Math.round(value * 10) / 10);

      const checkIndex = AllArray[name].findIndex((el) => parseFloat(el.value) === parseFloat(value));

      const startValue = parseFloat(initialStripObject[name][0].value);
      const endvalue = parseFloat(initialStripObject[name][4].value);

      if (parseFloat(copyValue) > parseFloat(endvalue) || parseFloat(copyValue) < parseFloat(startValue)) {
        let strMessage = `${displayName[name]} input value has to be between ${initialStripObject[name][0].value} and ${initialStripObject[name][4].value}.`;
        toastMessage('error', strMessage);
      }

      if (checkIndex === -1) {
        const fIndex = stripArray[name].findIndex((el) => parseFloat(el.value) === parseFloat(value));

        let arr = [];
        const str = JSON.parse(JSON.stringify(stripArray[name]));
        if (fIndex >= 0) {
          let currentVal = str[fIndex].value;
          let greenChartColor = str[fIndex].color;
          let finalHexValue = hexName || str[fIndex].color;
          str[fIndex].color = finalHexValue;
          let minVal = 0;
          let maxVal = 0;
          if (inputSource === 'initial') {
            AllArray = initialStripObject;
          }

          for (let i = 0; i < 4; i++) {
            if (parseFloat(currentVal) >= parseFloat(AllArray[name][i].value) && parseFloat(currentVal) <= parseFloat(AllArray[name][i + 1].value)) {
              minVal = AllArray[name][i].value;
              maxVal = AllArray[name][i + 1].value;
            }
          }

          const displayName = {
            value: copyValue,
            color: str[fIndex].color,
          };

          if (inputSource === 'input') {
            if (copyArr) {
              return;
            }
            let step = displayStep[name];

            if (fIndex === 1) {
              arr = [str[fIndex - 1], displayName, str[fIndex + step], str[fIndex + 2 * step], str[fIndex + 3 * step]];
            } else if (fIndex === 0) {
              arr = [displayName, str[fIndex + step], str[fIndex + 2 * step], str[fIndex + 3 * step], str[4 * step]];
            } else if (fIndex === str.length - 1 || fIndex === str.length - 2) {
              arr = [str[fIndex - 4 * step], str[fIndex - 3 * step], str[fIndex - 2 * step], str[fIndex - step], displayName];
            } else if (fIndex >= 2) {
              let minIndex = fIndex - 2 * step;
              let minNextIndex = fIndex - step;
              let maxIndex = fIndex + 2 * step;
              let maxBeforeIndex = fIndex + step;

              if (minIndex < 0 || minNextIndex < 0) {
                minIndex = 0;
                minNextIndex = Math.round(fIndex / 2);
              }
              if (maxIndex > str.length - 1 || maxBeforeIndex > str.length - 1) {
                maxIndex = str.length - 1;
                maxBeforeIndex = fIndex + Math.round((maxIndex - fIndex) / 2);
              }
              arr = [str[minIndex], str[minNextIndex], displayName, str[maxBeforeIndex], str[maxIndex]];
            }
            if (arr.length > 0) {
              copy[name] = arr;
            }
            return copy;
          }
          if (name === 'calciumHardness' || name === 'alkalinity' || name === 'stabiliser') {
            minVal = Math.round(minVal);
            maxVal = Math.round(maxVal);
          }
          if (name === 'ph' || name === 'chlorine' || name === 'combinedChlorine') {
            minVal = Math.round(minVal * 10) / 10;
            maxVal = Math.round(maxVal * 10) / 10;
          }

          let minIndex = str.findIndex((el) => parseFloat(el.value) === parseFloat(minVal));
          let maxIndex = str.findIndex((el) => parseFloat(el.value) === parseFloat(maxVal));

          let step = fIndex - minIndex;
          let stepM = maxIndex - fIndex;
          if (step === 1 || step === 0.1) {
            arr = [str[fIndex - step], displayName, str[fIndex + Math.round((maxIndex - fIndex) / 3)], str[fIndex + Math.round(2 * (maxIndex - fIndex) / 3)], str[maxIndex]];
          } else if (step === 0) {
            arr = [displayName, str[fIndex + Math.round((maxIndex - fIndex) / 4)], str[fIndex + Math.round((maxIndex - fIndex) / 2)], str[fIndex + Math.round(3 * (maxIndex - fIndex) / 4)], str[maxIndex]];
          } else if (stepM === 0) {
            arr = [str[minIndex], str[minIndex + Math.round((fIndex - minIndex) / 4)], str[minIndex + Math.round((fIndex - minIndex) / 2)], str[minIndex + Math.round(3 * (fIndex - minIndex) / 4)], displayName];
          } else if (stepM === 1 || maxIndex - fIndex === 0.1) {
            arr = [str[minIndex], str[minIndex + Math.round((fIndex - minIndex) / 3)], str[minIndex + Math.round(2 * (fIndex - minIndex) / 3)], displayName, str[fIndex + stepM]];
          } else if (fIndex >= 2) {
            arr = [str[minIndex], str[Math.round((minIndex + fIndex) / 2)], displayName, str[Math.round((fIndex + maxIndex) / 2)], str[maxIndex]];
          }

          if (arr.length > 0) {
            let newArr = arr;

            if (finalHexValue) {
              newArr = JSON.parse(JSON.stringify(arr)).map((el) => {
                el.color = hexToRgb(el.color, finalHexValue, hexName === el.color, greenChartColor, name, fromWhere, 'yes', el.value);
                return el;
              });
            }
            copy[name] = newArr;
          }
        }
      } else {
        const finIndex = stripArray[name].findIndex(
          (el) => Number(el.value) === Number(value),
        );

        let finalHexValue = hexName || stripArray[name][finIndex].color;
        let greenChartColor = stripArray[name][finIndex].color;
        copy[name][checkIndex].value = copyValue;
        copy[name][checkIndex].color = finalHexValue;

        let newArr = JSON.parse(JSON.stringify(copy[name])).map((el) => {
          el.color = hexToRgb(el.color, finalHexValue, hexName === el.color, greenChartColor, name, fromWhere, 'not', el.value);
          return el;
        });
        copy[name] = newArr;
      }
    }
    return copy;
  };

  const handleInputs = (e, type) => {
    const numReg = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;
    let { value } = e.target;
    if (value) {
      value = value.replace('-', '');
    }
    const { name } = e.target;
    let typo = type;
    const copyArray = { ...AllArray };
    const copyStrip = handleBoxInput(name, value, typo, copyArray, 'input');
    setAllArray(copyStrip);
    if (type === 'strip') {
      setShowBtn(true);
    }

    let val = value.replace('-', '');
    if (value.charAt(0) === '.') {
      val = `0${value}`;
      value = `0${value}`;
    }

    if (type === 'inverter' && value && !/^\d+$/.test(value)) return;

    let {
      poolWidth,
      poolHeight,
      shallowEnd,
      deepEnd,
      poolCapacity,
      poolWaterCapacity,
      diameter,
      originalSaltLvl,
      bagsSaltAdded,
      increaseDecreaseAmount,
      currentSaltLevel,
      waterLevelLowered,
      drainPercentWaterLevelLowered,
      amountOfWaterLost,
    } = poolInputs;
    let poolMsg = '';

    if (name === 'poolWidth') {
      poolWidth = value;
    } else if (name === 'poolHeight') {
      poolHeight = value;
    } else if (name === 'shallowEnd') {
      shallowEnd = value;
    } else if (name === 'deepEnd') {
      deepEnd = value;
    } else if (name === 'diameter') {
      diameter = value;
    } else if (name === 'originalSaltLvl') {
      originalSaltLvl = value;
    } else if (name === 'bagsSaltAdded') {
      bagsSaltAdded = value;
    } else if (name === 'currentSaltLevel') {
      currentSaltLevel = value;
    } else if (name === 'increaseDecreaseAmount') {
      increaseDecreaseAmount = value;
    } else if (name === 'waterLevelLowered') {
      waterLevelLowered = value;
    } else if (name === 'amountOfWaterLost') {
      amountOfWaterLost = value;
    }
    if (!poolWidth) {
      poolWidth = 0;
    }
    if (!poolHeight) {
      poolHeight = 0;
    }
    if (!shallowEnd) {
      shallowEnd = 0;
    }
    if (!deepEnd) {
      deepEnd = 0;
    }
    if (!diameter) {
      diameter = 0;
    }
    if (!waterLevelLowered) {
      waterLevelLowered = 0;
    }
    if (!amountOfWaterLost) {
      amountOfWaterLost = 0;
    }

    poolWidth = parseFloat(poolWidth);
    poolHeight = parseFloat(poolHeight);
    shallowEnd = parseFloat(shallowEnd);
    deepEnd = parseFloat(deepEnd);
    diameter = parseFloat(diameter);
    originalSaltLvl = parseFloat(originalSaltLvl);
    currentSaltLevel = parseFloat(currentSaltLevel);
    bagsSaltAdded = parseFloat(bagsSaltAdded);
    increaseDecreaseAmount = parseFloat(increaseDecreaseAmount);
    waterLevelLowered = parseFloat(waterLevelLowered);

    if (blankInputFields) {
      const stripExist = poolInputs?.BoxCount > 0;
      const sixPadStrip = poolInputs?.BoxCount > 4;
      if (!(e && stripExist && ((sixPadStrip && ['copper', 'phosphates'].includes(name)) || (!sixPadStrip && ['calciumHardness', 'combinedChlorine', 'copper', 'phosphates'].includes(name))))) {
        blankInputFields[name] = value;
      }
    }

    const copyGlObj = { ...globj };

    if (name === 'ph') {
      setTakingPh(value);
    }
    if (
      name === 'poolWidth'
      || name === 'poolHeight'
      || name === 'shallowEnd'
      || name === 'deepEnd'
      || name === 'diameter'
    ) {
      copyGlObj.glbVariable.gldiameter = diameter;
      copyGlObj.glbVariable.glwidth = poolWidth;
      copyGlObj.glbVariable.glheight = poolHeight;
      copyGlObj.glbVariable.gldeepEnd = deepEnd;
      copyGlObj.glbVariable.glshallowEnd = shallowEnd;

      if (poolDepth === 'Average Depth - Rectangular Pool') {
        const value = valueType ? 1250 : (5.5 * 7.48);
        poolCapacity = poolWidth * poolHeight * value;
      } else if (poolDepth === 'Average Depth - Circular pool') {
        const value = valueType ? (1250 * 0.78) : (5.5 * 7.48);
        poolCapacity = diameter * diameter * value;
      } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
        const value = valueType ? 1000 : (5.5 * 7.48);
        const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;

        poolCapacity = poolWidth * poolHeight * shallowCalculation * value;
      } else if (poolDepth === 'Specific Depths - Circular Pool') {
        const value = valueType ? 780 : (5.5 * 7.48);
        const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
        poolCapacity = diameter * diameter * shallowCalculation * value;
      }
    }

    poolCapacity = valueType ? Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000 : Math.floor(parseInt(poolCapacity, 10) / 100) * 100;
    const exceedLimitValue = valueType ? 2500000 : 660000;
    if (parseInt(poolCapacity, 10) >= exceedLimitValue) {
      poolMsg = 'Exceeded Limit';
    }

    if (poolDepth === 'Average Depth - Rectangular Pool') {
      copyGlObj.avgRectangular.poolSize = poolCapacity;
    } else if (poolDepth === 'Average Depth - Circular pool') {
      copyGlObj.avgCircular.poolSize = poolCapacity;
    } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
      copyGlObj.spcRectangular.poolSize = poolCapacity;
    } else if (poolDepth === 'Specific Depths - Circular Pool') {
      copyGlObj.spcCircular.poolSize = poolCapacity;
    }

    setGlObj(copyGlObj);

    if (greenSplitName === 'Part' && waterLevelLowered) {
      let valueHeight = diameterInput ? diameter : poolHeight;
      let valueWidth = diameterInput ? diameter : poolWidth;
      if (valueHeight && valueWidth) {
        drainPercentWaterLevelLowered = getDrainWaterLevelPercent(waterLevelLowered, valueWidth, valueHeight, poolCapacity);
      }
    } else {
      drainPercentWaterLevelLowered = 0;
    }

    // eslint-disable-next-line no-restricted-globals
    if ((numReg.test(parseFloat(val)) && !isNaN(val)) || !val) {
      if (name === 'bagsSaltAdded' && value * 1 === 0) {
        const bagsMsg = `Number entered for bags of ${chlorinationType === 'Salt Pool' ? 'Salt' : 'Mineral'} added, must be higher than 1.`;
        toastMessage('error', bagsMsg);

        value = '';
      } if (name === 'currentSaltLevel' && value * 1 === 0) {
        value = '';
      } if (name === 'increaseDecreaseAmount' && value * 1 === 0) {
        const increaseDecreaseMsg = selectedWaterlevel === 'Lower Water Level in Pool.' ? "Decreased Amount can't be zero." : "Increased Amount can't be zero.";
        toastMessage('error', increaseDecreaseMsg);

        value = '';
      }
      if (name === 'bagsSaltAdded' || name === 'originalSaltLvl' || name === 'currentSaltLevel' || name === 'increaseDecreaseAmount') {
        value = value.replace('-', '');
      }

      const setObj = {
        [e.target.name]: value,
        poolCapacity,
        poolWaterCapacity,
        drainPercentWaterLevelLowered,
        waterLossPerDay: 0,
        waterLossPerMinute: 0,
        waterLossPerHour: 0,
      };

      setCalVariable({ ...calVariable, poolMsg });
      if (poolCapacity && serviceAnalysis === 'Inverter Heater – Heating Time Required') {
        handleInverterInputs(name, value, poolCapacity, inverterSize);
        return;
      }
      setPoolInputs({
        ...poolInputs,
        ...setObj,
      });
      setOldCopyPoolInputs({
        ...oldCopyPoolInputs,
        ...setObj,
      });
    }
  };

  // console.log({poolInputs},poolInputs?.ambientAirTemp)

  // for storing pool values in local Storage
  const encryptPool = async (oldPoolInputs, newCombined) => {
    // if (!Id) {
    if (newCombined) poolInputs.newCombinedChlr = newCombined;

    let obj = {
      poolInputs: oldPoolInputs || poolInputs,
      sourcePoolInputs,
      chlorinationType,
      selectedModel,
      poolDepth,
      waterTestOptions,
      waterLossCalculator,
      greenTreatment,
      poolFinish,
      chlorineModel,
      showModel,
      manualValue,
      greenSelect,
      serviceAnalysis,
      getValue,
      stabState,
      greenSplitName,
      greenData: greenApiData,
      mineralValue,
      calciSalt: calVariable.salt,
      exPh: calVariable.ph,
      exstab: calVariable.stabiliser,
      exCalcium: calVariable.calciumHardness,
      filterStatus,
      globj,
      filterSelect,
      filterOptions,
      selectedWaterlevel,
      selectedWaterLevelAmount,
      // calciumHardnessState,
      oldCopyPoolInputs,
      blackSelect,
      blackTreatment,
      selectedModelObj,
      backStatus,
      calciDisplayChemicals,
      originalReadings,
      AllArray,
      blankInputFields,
      showFullHardness,
      hexObj,
      percent,
      inverterSize,
    };

    setAllPoolParameters(obj);
    if (!Id) {
      const nPayLoad = {
        poolData: obj,
      };
      let stringData = JSON.stringify(obj);
      localStorage.setItem('calculatorObject', stringData);
      const calciAllData = await userInstance().put('/watertest/updateCalculatorHistory', nPayLoad);
    }
  };
  // for getting pool values in local Storage
  const decryptPool = (poolData) => {
    const data = Id ? poolData : localStorage.getItem('calculatorObject');

    if (data) {
      const parseData = Id ? data : JSON.parse(data);
      setPoolInputs(parseData.poolInputs);
      setOldCopyPoolInputs(parseData.oldCopyPoolInputs);
      setSourcePoolInputs(parseData.sourcePoolInputs);
      setGlObj(parseData.globj);
      setTakingPh(parseData.poolInputs.ph);
      setBackStatus(parseData.backStatus);
      if (parseData?.calciDisplayChemicals) {
        setCalciChemicalsDisplay(parseData.calciDisplayChemicals);
      }
      if (parseData?.AllArray) {
        setAllArray(parseData.AllArray);
      }
      if (parseData?.hexObj) {
        setHexObj(parseData?.hexObj);
      }
      if (parseData?.percent) {
        setPercent(parseData?.percent);
      }
      setOriginalReadings(parseData.originalReadings);
      setBlankInputFields(parseData.blankInputFields);
      handlePoolFinish('', parseData.poolFinish, parseData.greenSplitName);
      handlePoolDepth('', parseData.poolDepth, parseData.globj);
      handleGreenChange('', parseData.greenSelect);
      handleBlackChange('', parseData.blackSelect);
      handleManualValues('', parseData.manualValue, parseData.mineralValue);
      handleChlorination(
        '',
        parseData.chlorinationType,
        parseData.chlorineModel,
        parseData.mineralValue,
        parseData.manualValue,
        parseData.getValue,
      );
      handleServiceAnalysis('', parseData.serviceAnalysis, parseData.greenData);
      handleGreenPoolTreatments('', parseData.greenTreatment);
      handleBlackTreatment('', parseData.blackTreatment);
      if (parseData.selectedModel !== 4000) {
        handleChlorinationModel(
          '',
          parseData.selectedModel,
          parseData.getValue,
          parseData.chlorinationType,
        );
      }
      handleWaterTestOptions('', parseData.waterTestOptions);
      handleWaterLossCalculator('', parseData.waterLossCalculator);
      handleFilterCheckChange('', parseData.filterSelect);
      handleFilterMediaOptions('', parseData.filterOptions);
      handleWaterLevelChange('', parseData.selectedWaterlevel);
      handleAmountWaterLevel('', parseData.selectedWaterLevelAmount);
      setShowFullHardness(parseData?.showFullHardness);
    }
  };
  const managingChemicals = (chemical, type) => {
    let conversion;
    if (type && (type === 'Calcium Raiser' || type === 'Buffer')) {
      conversion = Math.floor(Math.trunc(chemical * 1000) / 100) * 100;
    } else {
      conversion = Math.ceil(Math.trunc(chemical * 1000) / 100) * 100;
    }
    return conversion / 1000;
  };

  const updatingServices = () => {

  };
  const chemicalGreen = (
    category,
    buyCost,
    displayCost,
    msg,
    durations,
    chemicalQuantity,
    chemicalDisplayName,
    chemicalUnits,
  ) => {
    const margin = displayCost - (buyCost || 0);
    const serviceStatus = (msg === '2nd Visit Cost' || msg === '3rd Visit Cost' || msg === '4th Visit Cost');

    const copyElData = { ...categoryObj };
    let quant = '0.00';
    if (buyCost === 'N/A') {
      quant = 'N/A';
    } else {
      quant = chemicalQuantity || '0.00';
    }

    const updatedBuyCost = buyCost === 'N/A' ? 'N/A' : LoadExistValue(buyCost).toFixed(2);
    const updatedDisplayCost = buyCost === 'N/A' ? 'N/A' : LoadExistValue(displayCost).toFixed(2);
    const updatedMargin = buyCost === 'N/A' ? 'N/A' : LoadExistValue(margin).toFixed(2);
    const updatedDuration = buyCost === 'N/A' ? '0' : durations;

    copyElData.category = category;
    copyElData.buyCostProduct = updatedBuyCost;
    copyElData.renderCost = updatedDisplayCost;
    copyElData.cost = updatedDisplayCost;
    copyElData.subcategory = msg;
    copyElData.laborMargin = serviceStatus ? '0.00' : updatedMargin;
    copyElData.show = 'visible';
    copyElData.totalLabour = serviceStatus ? updatedMargin : '0.00';
    copyElData.quantity = serviceStatus ? updatedDuration : quant;
    copyElData.duration = durations === undefined ? '0' : updatedDuration;
    copyElData.type = serviceStatus ? 'service' : 'product';
    copyElData.tax = serviceStatus
      ? taxApplicable?.actualLabourTax
      : taxApplicable?.actualChemicalTax;
    copyElData.chemicalDisplayName = chemicalDisplayName;
    copyElData.chemicalUnits = chemicalUnits;

    return copyElData;
  };

  const handleFilterMediaOptions = (e, parseDataFilterOptions) => {
    const value = e ? e.target.value : parseDataFilterOptions;
    setFilterOptions(value);
  };

  const handleFilterCheckChange = (eName, parseDataFilterSelect) => {
    const name = eName || parseDataFilterSelect;
    setFilterSelect(name);
  };

  const getDrainCost = (drain_Percent, capacity) => {
    const { chemicalsTax } = taxApplicable;
    const { setupCost, hourlyRate, litresPerHour } = calVariable;
    const baseCost = Number(setupCost);
    let litresHour = Number(litresPerHour);
    let hourRate = Number(hourlyRate);
    let hoursToDrain = (capacity / litresHour) * drain_Percent;
    hoursToDrain /= 100;
    let Hours = hoursToDrain * 60 + 60;
    let Cost = 0;
    const subTotal = baseCost + hourRate * hoursToDrain;
    const roundUpTotal = parseInt(Math.ceil(subTotal / 5), 10) * 5;
    if (roundUpTotal === 100) {
      Cost = 0;
    } else if (roundUpTotal === 110) {
      Cost = roundUpTotal - 85;
    } else {
      Cost = roundUpTotal * chemicalsTax;
    }
    return { Hours, Cost };
  };

  const isExist = (chem) => {
    let val = chem === 'combinedChlorine' ? 'totalChlorine' : chem;
    if (calciDisplayChemicals[val] && !poolInputs[chem]) return false;
    return true;
  };

  const returnChemicalsInputStatus = () => ({
    phosphates: isExist('phosphates'),
    copper: isExist('copper'),
    calciumHardness: isExist('calciumHardness'),
    combinedChlorine: isExist('combinedChlorine'),
  });

  const handleCalculate = ({
    withDrainage, resDrainData, balancing,
  }) => {
    let copyPoolInputs = withDrainage ? { ...oldPoolInputs } : { ...poolInputs };
    let alkStatus = { ...initialAlkMsgsSet };
    let copyLevelData = JSON.parse(JSON.stringify(initialLevelData));
    const isDryAcid = chemicalBalanceWaterObj.totalalkalinitytabalanceName === 'Dry Acid';

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    const {
      poolWidth, poolHeight, drainPercentWaterLevelLowered,
    } = copyPoolInputs;

    let isValid = true;

    if (serviceAnalysis === 'Green Pool' && !greenSelect) {
      isValid = false;
      toastMessage('error', 'Please select green pool colour.');
    }
    if (serviceAnalysis === 'Sand Filter Media Replacement' && !filterSelect) {
      isValid = false;
      toastMessage('error', 'Please select filter size.');
    }
    if (serviceAnalysis === 'Black-Spot Algae' && !blackSelect) {
      isValid = false;
      // Error msg  change by faizan
      toastMessage('error', 'Select black-spot intensity.');
    }

    // setting oldInputs
    if (!withDrainage) {
      let oldPoolInputs = { ...poolInputs };
      setOldPoolInputs(oldPoolInputs);
    }

    if (isValid === true) {
      if (takingPh === '') {
        poolInputs.ph = calVariable.ph;
      }

      if (
        (serviceAnalysis === 'Green Pool'
          || (serviceAnalysis === 'Drain Pool' && greenSplitName !== 'Part')
          || serviceAnalysis === 'Sand Filter Media Replacement')
        && copyPoolInputs.ph === ''
        && copyPoolInputs.alkalinity === ''
      ) {
        poolInputs.ph = '6.8';
      }

      let changingPhvalue = false;
      let changingChlorineValue = false;
      let aerateStatus = false;
      setAerate(false);
      setAlkMsgs(initialAlkMsgsSet);
      let granularChlorine = 0;
      let sodiumThiosulphate = 0;
      let sodaAsh = 0;
      let hydrochloricAcidPh = 0;
      let buffer = 0;
      let hydrochloricAcidAlk = 0;
      let addStabiliser = 0;
      let calciumRaiser = 0;
      let addSalt = 0;
      let quantityCostForChlorine = 0;
      let quantityCostPh = 0;
      let quantityCostAlk = 0;
      let quantityCostStabiliser = 0;
      let quantityCostCalcium = 0;
      let quantityCostSalt = 0;
      let quantityCostPhDryAcid = 0;
      let quantityCostAlkDryAcid = 0;
      let profitStabiliser = 0;
      let profitCalciumHardness = 0;
      let profitSalt = 0;
      let costPh = 0;
      let costAlkalinity = 0;
      let profitPh = 0;
      let costDryPhAcid = 0;
      let costDryAlkAcid = 0;
      let profitAlkalinity = 0;
      let profitPhDryAcid = 0;
      let profitAlkDryAcid = 0;
      let convertPool = 0;
      let manualAquaValue = 0;
      let quantityCostSaltConvert = 0;
      let costSaltConvert = 0;
      let profitSaltConvert = 0;
      let valueForChlorine = 0;
      let baseCostForChlorine = 0;
      let sellPriceChlorine = 0;
      let costChroline = 0;
      let profitChrolone = 0;
      let phosphateRemover = 0;
      let quantityCostPhosphates = 0;
      let costPhophates = 0;
      let costCombinedChlorine = 0;
      let combinedChlorineDrainingHours = 0;
      let copperDrainingHours = 0;
      let zodiacOxy = 0;
      let quantityCostCombined = 0;
      let negativeSalt = 0;
      let positiveSalt = 0;
      let algaecideQuantity = 0;
      let clarifierMaintainenceQuantity = 0;
      let clarifierGreenPoolQuantity = 0;
      let flockQuantity = 0;
      let quantityCostAlgaecide = 0;
      let sellPriceAlgaecide = 0;
      let costAlgaecide = 0;
      let quantityCostClarifierMain = 0;
      let sellPriceClarifierMain = 0;
      let costClarifierMain = 0;
      let quantityCostClarifierGreen = 0;
      let sellPriceClarifierGreen = 0;
      let costClarifierGreen = 0;
      let quantityCostFlock = 0;
      let sellPriceFlock = 0;
      let costFlock = 0;
      let greenQuantity = 0;
      let greenCost = 0;
      let greenLabourCost = 0;
      let greenVaccumCost = 0;
      let saltDrainingHours = 0;
      let calciumDrainingHours = 0;
      let stabiliserDrainingHours = 0;
      let copperQuantity = 0;
      let quantityCostCopper = 0;
      let costCopper = 0;
      let { ph } = calVariable;
      let costDrainPercentWaterLevelLowered = 0;
      let drainPercentWaterLevelLoweredHours = 0;
      let calciumTaker = 0;
      let calciumReducer = 0;
      let calciumPhReducer = 0;
      let calciumPhReducerCost = 0;
      let quantityPhReducerCost = 0;
      const { poolCapacity } = copyPoolInputs;
      let { poolWaterCapacity } = copyPoolInputs;

      let phosphateStatus = false;

      const {
        alkalinity,
        stabiliser,
        salt,
        baseGranular,
        baseSodiumThi,
        basePhSodaAsh,
        basePhAcid,
        baseAlkBuffer,
        baseCalciumRaiser,
        baseStabiliser,
        basePhosphates,
        baseSalt,
        baseConvert,
        baseMagna,
        baseAqua,
        sellSalt,
        basePotassium,
        baseCopper,
        sellGranular,
        sellPhAcid,
        sellPhSodaAsh,
        sellAlkBuffer,
        sellCalciumRaiser,
        sellStabiliser,
        sellPhosphates,
        sellConvert,
        sellMagna,
        sellAqua,
        sellSodiumThi,
        combinedChlorine,
        phfibre,
        phliner,
        stabiliserCya,
        stabiliserOrp,
        baseLiquid,
        sellLiquid,
        sellCopper,
        sellPotassium,
        sellPotassiumOxyBoost,
        basePotassiumOxyBoost,
        copper,
        copperPositive,
        copperNegative,
        baseDryAcid,
        sellDryAcid,
        drainPointcopper,
        baseZodiacCalcium,
        sellZodiacCalcium,
        calciumHardnessReduce,
        // calcreduNegative,
        // calcreduPositive,
      } = calVariable;
      let { chlorine, calciumHardness, hardCalcium } = calVariable;
      const stabValue = stabiliser === 0 && poolFinish.includes('Indoor') ? 40 : stabiliser;
      const { chemicalsTax, labourTax } = taxApplicable;

      let previousCalciumData = {
        CalCalciumHardness: calciumHardness,
        InpCalciumHarndess: copyPoolInputs.calciumHardness,
      };

      if (chlorinationType === 'Calcium Hardness Titration Test' || showFullHardness?.fullhardness || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
        calciumHardness = hardCalcium;
        copyPoolInputs.calciumHardness = copyPoolInputs.hardCalcium;
      }

      let {
        phContNegative,
        phContPositive,
        phFibreNegative,
        phFibrePositive,
        phVinylNegative,
        phVinylPositive,
        alkNegative,
        alkPositive,
        stabNegative,
        stabPositive,
        stabOrpNegative,
        stabOrpPositive,
        calcNegative,
        calcPositive,
        phConcreteTilted,
        chlorNegative,
        chlorPositive,
        phosPositive,
        phosNegative,
        combNegative,
        combPositive,
        drainPointcalciumHardness,
      } = calVariable;

      const exceedLimitValue = valueType ? 2500000 : 660000;
      if (parseInt(poolCapacity, 10) >= exceedLimitValue) {
        setPoolInputs({ ...copyPoolInputs, poolMsg: 'Exceeded Limit' });
      } else {
        let isDrainage = false;
        let {
          drainWaterSalt, drainWater, drainWaterChorine, drainWaterHardness, drainWaterCopper, inputTaker, newCombinedChrlone, maxTestingDrain, passDiffChlorine, passDiffCopper,
          passDiffHard, passDiffSalt, passDiffStab, diffStabliser, diffCalcium, diffChlorine, diffSalt, diffCopper, drainTaker, drainPercent, actualChlorine,
        } = resDrainData;

        let passingSalt = copyPoolInputs.salt;

        if (manualValue.includes('Aquatherepe Transform Maintenance')) {
          passingSalt = 4500;
        }

        const notImplementFormula = !!((chlorinationType === 'Calcium Hardness Titration Test' || showFullHardness?.fullhardness || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test'));
        let input = (!notImplementFormula && (chlorinationType === 'Mineral Pool'
        || manualValue.includes('Aquatherepe Transform Maintenance')))
          ? calciumAdjustmentCal(copyPoolInputs.calciumHardness, passingSalt)
          : copyPoolInputs.calciumHardness;

        copyPoolInputs.calciumHardness = input;

        maxTestingDrain = Math.max(
          parseInt(drainWater, 10),
          parseInt(drainWaterHardness, 10),
          parseInt(drainWaterSalt, 10),
          parseInt(drainWaterChorine, 10),
          parseInt(drainWaterCopper, 10),
        );

        const fetchChemicalsDrain = (mx, chem) => (mx === chem);

        const whichChemicalDrain = {
          calciumHardness: false,
          combinedChlorine: false,
          stabiliser: false,
          copper: false,
          salt: false,

        };
        // console.log({ maxTestingDrain });
        if (maxTestingDrain) {
          whichChemicalDrain.calciumHardness = fetchChemicalsDrain(maxTestingDrain, drainWaterHardness);
          whichChemicalDrain.combinedChlorine = fetchChemicalsDrain(maxTestingDrain, drainWaterChorine);
          whichChemicalDrain.stabiliser = fetchChemicalsDrain(maxTestingDrain, drainWater);
          whichChemicalDrain.copper = fetchChemicalsDrain(maxTestingDrain, drainWaterCopper);
          whichChemicalDrain.salt = fetchChemicalsDrain(maxTestingDrain, drainWaterSalt);
        }

        let drPercent = drainPercentWaterLevelLowered;

        if (drPercent >= maxTestingDrain) {
          drainWater = 0;
          drainWaterHardness = 0;
          drainWaterSalt = 0;
          drainWaterChorine = 0;
          drainWaterCopper = 0;
        } else {
          drPercent = maxTestingDrain;
        }

        if (!withDrainage && drPercent) {
          const deductDrain = (chem, extra) => {
            // let drainChem = chem === 'chlorine' ? 'combinedChlorine' : chem;
            if (whichChemicalDrain[chem]) {
              return;
            }
            copyPoolInputs[chem] = getChemcialAfterReducing(extra || poolInputs[chem], drPercent);
          };

          deductDrain('ph');
          deductDrain('chlorine');
          deductDrain('alkalinity');
          deductDrain('stabiliser');
          deductDrain('calciumHardness', input);
          deductDrain('salt');
          deductDrain('copper');
          deductDrain('phosphates');
          deductDrain('combinedChlorine');
          newCombinedChrlone = copyPoolInputs?.combinedChlorine - copyPoolInputs?.chlorine;
        }

        if (!withDrainage && drPercent && waterSourceSelect === 'waterSource') {
          let waterSourceph = 6.8 * drPercent / 100;
          let changePh = getChemcialAfterReducing(poolInputs.ph, drPercent);
          copyPoolInputs.ph = sumForInputs(changePh, waterSourceph);
        }
        let copySrcInp = {};
        if (drPercent && waterSourceSelect === 'waterSourceUpdate' && !withDrainage) {
          let calciumDownStatus = false;
          if (chemicalBalanceWaterObj?.totalhardnessbalanceName === 'Zodiac Calcium Down' && !drainWaterHardness && copyPoolInputs.calciumHardness > calcPositive && input > calciumHardness) {
            calciumDownStatus = true;
          }

          copySrcInp = getSourceReducedReadings(sourcePoolInputs, drPercent);
          copyPoolInputs = calculatedSumForInputs(copyPoolInputs, copySrcInp, calciumDownStatus);
          newCombinedChrlone = copyPoolInputs.combinedChlorine - copyPoolInputs.chlorine;
        }

        if (greenSplitName === 'Drain' && waterSourceSelect === 'waterSource') {
          copyPoolInputs.ph = 6.8;
        }

        if (waterSourceSelect === 'waterSourceUpdate' && greenSplitName === 'Drain') {
          copyPoolInputs = calculatedSumForInputs(copyPoolInputs, sourcePoolInputs);
        }

        if (copyPoolInputs.ph !== '' && copyPoolInputs.alkalinity === '') {
          copyPoolInputs.alkalinity = 0;
          changingPhvalue = true;
        }
        if (copyPoolInputs.combinedChlorine !== '' && copyPoolInputs.chlorine === '') {
          copyPoolInputs.chlorine = 0;
          changingChlorineValue = true;
        }

        if (calciDisplayChemicals?.totalChlorine) {
          const valComb = parseFloat((newCombinedChrlone || 0)).toFixed(2);
          if (parseFloat(valComb) > combPositive) {
            const calculation = 30 / 1000000;
            zodiacOxy = parseFloat(calculation * poolCapacity);
          }
        }

        // if (copyPoolInputs) {
        //   console.log('CopyInputs', copyPoolInputs);
        // }

        const maxDrain = Math.max(
          parseInt(drainWater, 10),
          parseInt(drainWaterHardness, 10),
          parseInt(drainWaterSalt, 10),
          parseInt(drainWaterChorine, 10),
          parseInt(drainWaterCopper, 10),
          parseInt(drainPercentWaterLevelLowered, 10),
        );

        if (
          chlorinationType === 'Convert Salt Pool to Mineral Pool'
          || manualValue === 'Convert Pool to Mineral Pool'
          || manualValue.includes('Aquatherepe Transform Maintenance')
        ) {
          convertPool = 10 * (poolCapacity / 25000);
          manualAquaValue = Math.ceil(convertPool);
          convertPool = Math.ceil(convertPool / 10);

          if (copyPoolInputs.salt < salt && calciDisplayChemicals?.salt) {
            const calValue = valueType ? 0.00000099998765 : 0.0001334854155;
            const calculationSalt = (salt - copyPoolInputs.salt) * calValue;
            addSalt = parseFloat((calculationSalt * poolCapacity).toFixed(1));
            const divideValue = 20;
            addSalt = Math.ceil(addSalt / divideValue);
          }
        } else if (
          chlorinationType === 'Salt Pool'
          || selectedModel === 'Magna Minerals'
          || selectedModel === 'Magna Minerals - With ORP Probe'
        ) {
          if (copyPoolInputs?.salt < salt && calciDisplayChemicals?.salt) {
            const calValue = valueType ? 0.00000099998765 : 0.0001334854155;
            const value = (chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test')
              ? 0.00000133
              : calValue;
            const calculation = (salt - copyPoolInputs.salt) * value;
            addSalt = parseFloat((calculation * poolCapacity).toFixed(1));
            const divValue = valueType ? 20 : 16;
            const divideValue = (chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test') ? 10 : divValue;
            addSalt = Math.ceil(addSalt / divideValue);
            addSalt = valueType ? addSalt : Math.ceil(addSalt / 40);
          }
        } else if (
          (selectedModel === 'Aquatherepe Minerals'
          || selectedModel === 'Aquatherepe Minerals - With ORP Probe'
          || ((chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test') && selectedModel === 'Aquatherepe Transform')) && calciDisplayChemicals?.salt
        ) {
          const calculation = (salt - copyPoolInputs.salt) * 0.00000133;
          addSalt = calculation * poolCapacity;
          addSalt = Math.ceil(addSalt / 10);
        }

        if (parseFloat(addSalt) < 0) {
          addSalt = 0;
        }
        input = copyPoolInputs.calciumHardness;

        if (calciDisplayChemicals?.calciumHardness || chlorinationType === 'Calcium Hardness Titration Test' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
          if (input < calciumHardness) {
            const calValue = valueType ? 0.0000013333 : 0.000177977912;
            const calculation = (calciumHardness - input) * calValue;
            calciumRaiser = parseFloat(calculation * poolCapacity);
            calciumTaker = calciumRaiser;
          } else if (chemicalBalanceWaterObj.totalhardnessbalanceName === 'Zodiac Calcium Down' && !drainWaterHardness && copyPoolInputs.calciumHardness > calcPositive && input > calciumHardness) {
            const calculation = (copyPoolInputs.calciumHardness - calciumHardness) * 0.001;
            calciumReducer = parseFloat(calculation * poolCapacity);
          }
        }

        if (copyPoolInputs.stabiliser < stabValue) {
          const calValue = valueType ? 0.000000999987656 : 0.00013348695783;
          const calculation = (stabValue - copyPoolInputs.stabiliser) * calValue;
          addStabiliser = parseFloat(calculation * poolCapacity);
        }

        if (calciDisplayChemicals?.copper && copyPoolInputs.copper > copperPositive && copyPoolInputs.copper > copperNegative && copyPoolInputs.copper < drainPointcopper) {
          const calculation = (200 / 10000000) * poolCapacity;
          copperQuantity = calculation;
        }
        if (copyPoolInputs.copper > 0 && copyPoolInputs < 1) {
          copperQuantity = 0;
        }

        if (
          !(
            copyPoolInputs.chlorine >= chlorNegative
            && copyPoolInputs.chlorine <= chlorPositive
          )
        ) {
          if (copyPoolInputs.chlorine < chlorine) {
            const calValue = valueType ? 0.0000125 : 0.00166859035005;
            const calculation = (chlorine - copyPoolInputs.chlorine) * calValue;
            granularChlorine = parseFloat(calculation * poolCapacity);
          } else if (copyPoolInputs.chlorine > chlorine) {
            const calValue = valueType ? 0.000001 : 0.000245749698;
            const calculation = (copyPoolInputs.chlorine - (chlorine + 1)) * calValue;
            sodiumThiosulphate = parseFloat(calculation * poolCapacity);
            if (sodiumThiosulphate < 0) {
              sodiumThiosulphate = 0;
            }
          }
        }

        if (copyPoolInputs.ph < ph) {
          const calValue = valueType ? 0.000012232142 : 0.001630979;
          const calculation = (ph - parseFloat(copyPoolInputs.ph)) * calValue;
          sodaAsh = parseFloat(calculation * poolCapacity);
        } else if (copyPoolInputs.ph > ph) {
          const calValue = valueType ? 0.000025 : 0.0033371769;
          const calculation = (copyPoolInputs.ph - ph) * calValue;
          hydrochloricAcidPh = parseFloat(calculation * poolCapacity);
        }

        if (copyPoolInputs.alkalinity < alkalinity) {
          const calValue = valueType ? 0.000001758450705 : 0.00023462630087;
          const calculation = (alkalinity - copyPoolInputs.alkalinity) * calValue;
          buffer = parseFloat(calculation * poolCapacity);
        } else if (copyPoolInputs.alkalinity > alkalinity) {
          const calValue = valueType ? 0.0000016246 : 0.00021686311668;
          const calculation = (copyPoolInputs.alkalinity - alkalinity) * calValue;
          hydrochloricAcidAlk = parseFloat(calculation * poolCapacity);
        }
        const inputPhosphate = copyPoolInputs.phosphates > 4000 ? 4000 : copyPoolInputs.phosphates;
        if (calciDisplayChemicals?.phosphates) {
          if (inputPhosphate > phosPositive) {
            if (inputPhosphate < 1000) {
              phosphateRemover = inputPhosphate * poolCapacity * 0.000018;
              phosphateRemover = Math.ceil((phosphateRemover + 1) / 10) * 10;
            } else {
              phosphateRemover = poolCapacity * 0.018
              + poolCapacity * 0.000009 * (inputPhosphate - 1000);
            // phosphateRemover = Math.ceil((phosphateRemover + 1) / 10) * 10;
            }
          }
        }

        if (maxTestingDrain) {
          isDrainage = true;
        }
        if (withDrainage) {
          diffStabliser = false;
          diffCalcium = false;
          diffSalt = false;
          diffChlorine = false;
          diffCopper = false;
          isDrainage = false;
        }
        let phNegat = phContNegative;
        let phPosit = phContPositive;
        let phInput = copyPoolInputs.ph;
        let alkInput = copyPoolInputs.alkalinity;

        if (!isDrainage || isDrainage) {
          if (
            parseFloat(copyPoolInputs.alkalinity) >= calVariable.alkalinity
            && parseFloat(copyPoolInputs.ph) > calVariable.ph
          ) {
            const acidFromPh = hydrochloricAcidPh.toFixed(5);
            const firstCal = (10 / 40000) * poolCapacity;
            const decreaseInAlk = (
              (acidFromPh / firstCal)
              * -153.884033
            ).toFixed(7);
            const originalAlk = parseFloat(copyPoolInputs.alkalinity);
            const newAlk = (parseFloat(decreaseInAlk) + originalAlk).toFixed(7);
            let idealAlk = calVariable.alkalinity;
            if (
              copyPoolInputs.alkalinity >= calVariable.alkalinity
              && copyPoolInputs.alkalinity <= alkPositive
            ) {
              idealAlk = copyPoolInputs.alkalinity;
            }
            const secondCal = (
              (idealAlk - parseFloat(newAlk))
              * 0.000001758450705
            ).toFixed(11);
            const bufferRequired = parseFloat(secondCal) * poolCapacity;
            buffer = bufferRequired;
            if (buffer > 0) {
              hydrochloricAcidAlk = 0;
            }
            if (buffer < 0) {
              buffer = 0;
            }
          }

          let valueTaker = 0;
          let copyPh = poolFinish.includes('Concrete') ? phConcreteTilted : poolFinish.includes('Vinyl') ? phfibre : phliner;
          if (chemicalBalanceWaterObj.totalhardnessbalanceName === 'Zodiac Calcium Down' && input > calVariable.calcPositive) {
            ph = 8.2;
            if (copyPoolInputs.ph === '') {
              copyPoolInputs.ph = copyPh;
            }
            if (copyPoolInputs.ph < ph) {
              const calculation = (ph - parseFloat(copyPoolInputs.ph)) * 0.000012232142;
              sodaAsh = parseFloat(calculation * poolCapacity);
            } else if (copyPoolInputs.ph > ph) {
              const calculation = (copyPoolInputs.ph - ph) * 0.000025;
              hydrochloricAcidPh = parseFloat(calculation * poolCapacity);
              valueTaker = hydrochloricAcidPh;
            }

            if (ph === 8.2) {
              const calculation = (ph - copyPh) * 0.000025;
              calciumPhReducer = parseFloat(calculation * poolCapacity);
            }
            // ph = copyPh;
          }

          // checking if adding acid to balance alk is taking ph under 7

          if (ph === phfibre) {
            phNegat = phFibreNegative;
            phPosit = phFibrePositive;
          } else if (ph === phliner) {
            phNegat = phVinylNegative;
            phPosit = phVinylPositive;
          }

          if (
            (phInput >= phNegat && phInput <= phPosit && alkInput > alkPositive)
            || (phInput < phNegat && alkInput >= alkNegative && alkInput <= alkPositive)
            || alkInput > alkPositive || (phInput > phPosit && alkInput > alkPositive)
          ) {
            if (
              hydrochloricAcidAlk
              && parseInt(parseFloat(copyPoolInputs.ph) - hydrochloricAcidAlk, 10) <= 7
            ) {
              ph = 7;
              if (copyPoolInputs.ph < ph) {
                const calculation = (ph - parseFloat(copyPoolInputs.ph)) * 0.000012232142;
                sodaAsh = parseFloat(calculation * poolCapacity);
              } else if (copyPoolInputs.ph > ph) {
                const calculation = (copyPoolInputs.ph - ph) * 0.000025;
                hydrochloricAcidPh = parseFloat(calculation * poolCapacity);
                valueTaker = hydrochloricAcidPh;
              }
              if (
                parseInt(parseFloat(copyPoolInputs.ph) - hydrochloricAcidAlk, 10)
                  < 7
                && hydrochloricAcidAlk > hydrochloricAcidPh
              ) {
                hydrochloricAcidAlk = hydrochloricAcidPh;
              }
              if (hydrochloricAcidAlk === 0) {
                setAlkMsgs({ ...initialAlkMsgsSet, retest: true });
                alkStatus = { ...initialAlkMsgsSet, retest: true };
              } else if (hydrochloricAcidAlk < 0) {
                setAlkMsgs({ ...initialAlkMsgsSet, retest: true });
                alkStatus = { ...initialAlkMsgsSet, retest: true };
              }
              const valueForAlk = hydrochloricAcidAlk;
              const baseCostAlk = buffer ? 1.42 : 2.33;
              quantityCostAlk = valueForAlk * baseCostAlk;
              const sellPriceAll = quantityCostAlk * (buffer ? 15.2 : 5.4);
              costAlkalinity = parseInt(Math.ceil(sellPriceAll / 5), 10) * 5 * chemicalsTax;
              profitAlkalinity = (costAlkalinity / chemicalsTax).toFixed(2) - quantityCostAlk;
              hydrochloricAcidPh = 0;
              sodaAsh = 0;
              buffer = 0;
              setAerate(true);
              aerateStatus = true;
            }
          }
          // }

          // checking if alk is idea but ph is lower than ideal value.
          if (
            parseFloat(copyPoolInputs.alkalinity) >= alkNegative
            && parseFloat(copyPoolInputs.alkalinity) <= alkPositive
            && parseFloat(copyPoolInputs.ph) < phNegat
            && valueTaker === 0
          ) {
            setAlkMsgs({ ...initialAlkMsgsSet, retest: true });
            setAerate(true);
            aerateStatus = true;
            alkStatus = { ...initialAlkMsgsSet, retest: true };
            buffer = 0;
            hydrochloricAcidPh = 0;
            sodaAsh = 0;
          }

          if (
            parseFloat(copyPoolInputs.alkalinity) === calVariable.alkalinity
            && parseFloat(copyPoolInputs.ph) === calVariable.ph
          ) {
            setAlkMsgs({ ...initialAlkMsgsSet, ok: true });
            alkStatus = { ...initialAlkMsgsSet, ok: true };
          }
          // if alkalinity is less than and Ph is also less than from Negative/Ideal
          if (
            parseFloat(copyPoolInputs.alkalinity) < alkNegative
            && (parseFloat(copyPoolInputs.ph) < phNegat)
          ) {
            // first table calculation
            const phReading = parseFloat(copyPoolInputs.ph);
            const ideal = calVariable.ph;
            const poolSize = poolCapacity;
            const cal1 = (ideal - phReading) * 0.000012232142;
            const sodaAshNew = poolSize * cal1;
            const increaseInAlk = (sodaAshNew / poolCapacity) * 994560;

            // second table calculation
            const alkReading = parseFloat(copyPoolInputs.alkalinity) + increaseInAlk;
            const idealAlk = calVariable.alkalinity;
            const cal2 = (idealAlk - alkReading) * 0.000001758450705;
            let sodiumBicarbonate = poolSize * cal2;
            if (sodiumBicarbonate < 0) {
              sodiumBicarbonate *= -1;
            }
            sodaAsh = sodaAshNew;
            buffer = sodiumBicarbonate;
          }

          // if alkalinity is less than ideal and ph is greater than ideal

          if (
            parseFloat(copyPoolInputs.alkalinity) < calVariable.alkalinity
            && parseFloat(copyPoolInputs.ph) > calVariable.ph
          ) {
            const acidFromPh = hydrochloricAcidPh;
            const firstCal = (10 / 40000) * poolCapacity;
            const decreaseInAlk = (
              (acidFromPh / firstCal)
              * -153.884033
            ).toFixed(7);
            const newReadingForAlk = parseFloat(copyPoolInputs.alkalinity) + parseFloat(decreaseInAlk);
            const calculation = (alkalinity - newReadingForAlk) * 0.000001758450705;
            buffer = parseFloat(calculation * poolCapacity);
          }
        }
        // find which is the higest drainage percentage
        // check all the drains if that is the higest
        // set the value of other drains as zero which are not highest
        if (poolFinish.includes('Indoor')) {
          addStabiliser = 0;
        }

        if (copyPoolInputs.alkalinity === '') {
          if (ph === phConcreteTilted) {
            if (
              copyPoolInputs.ph >= phContNegative
              && copyPoolInputs.ph <= phContPositive
            ) {
              hydrochloricAcidPh = 0;
              sodaAsh = 0;
            }
          } else if (ph === phfibre) {
            if (
              copyPoolInputs.ph >= phFibreNegative
              && copyPoolInputs.ph <= phFibrePositive
            ) {
              hydrochloricAcidPh = 0;
              sodaAsh = 0;
            }
          } else if (ph === phliner) {
            if (
              copyPoolInputs.ph >= phVinylNegative
              && copyPoolInputs.ph <= phVinylPositive
            ) {
              hydrochloricAcidPh = 0;
              sodaAsh = 0;
            }
          }
        }
        if (copyPoolInputs.ph === '') {
          if (
            copyPoolInputs.alkalinity >= alkNegative
            && copyPoolInputs.alkalinity <= alkPositive
          ) {
            hydrochloricAcidAlk = 0;
            buffer = 0;
            setAlkMsgs({ ...initialAlkMsgsSet, ok: true });
            alkStatus = { ...initialAlkMsgsSet, ok: true };
          }
        }

        if (
          ph === phConcreteTilted
          && copyPoolInputs.ph >= phContNegative
          && copyPoolInputs.ph <= phContPositive
          && copyPoolInputs.alkalinity < alkNegative
        ) {
          hydrochloricAcidPh = 0;
          sodaAsh = 0;
        }

        if (
          ph === phfibre
          && copyPoolInputs.ph >= phFibreNegative
          && copyPoolInputs.ph <= phFibrePositive
          && copyPoolInputs.alkalinity < alkNegative
        ) {
          hydrochloricAcidPh = 0;
          sodaAsh = 0;
        }

        if (
          ph === phliner
          && copyPoolInputs.ph >= phVinylNegative
          && copyPoolInputs.ph <= phVinylPositive
          && copyPoolInputs.alkalinity < alkNegative
        ) {
          hydrochloricAcidPh = 0;
          sodaAsh = 0;
        }
        if (
          ph === phConcreteTilted
          && copyPoolInputs.ph >= phContNegative
          && copyPoolInputs.ph <= phContPositive
          && copyPoolInputs.alkalinity >= alkNegative
          && copyPoolInputs.alkalinity <= alkPositive
        ) {
          hydrochloricAcidAlk = 0;
          buffer = 0;
          hydrochloricAcidPh = 0;
          sodaAsh = 0;
          setAlkMsgs({ ...initialAlkMsgsSet, ok: true });
          alkStatus = { ...initialAlkMsgsSet, ok: true };
          setAerate(false);
          aerateStatus = false;
        }
        if (
          ph === phfibre
          && copyPoolInputs.ph >= phFibreNegative
          && copyPoolInputs.ph <= phFibrePositive
          && copyPoolInputs.alkalinity >= alkNegative
          && copyPoolInputs.alkalinity <= alkPositive
        ) {
          hydrochloricAcidAlk = 0;
          buffer = 0;
          hydrochloricAcidPh = 0;
          sodaAsh = 0;
          setAlkMsgs({ ...initialAlkMsgsSet, ok: true });
          setAerate(false);
          alkStatus = { ...initialAlkMsgsSet, ok: true };
          aerateStatus = false;
        }
        if (
          ph === phliner
          && copyPoolInputs.ph >= phVinylNegative
          && copyPoolInputs.ph <= phVinylPositive
          && copyPoolInputs.alkalinity >= alkNegative
          && copyPoolInputs.alkalinity <= alkPositive
        ) {
          hydrochloricAcidAlk = 0;
          buffer = 0;
          hydrochloricAcidPh = 0;
          sodaAsh = 0;
          setAlkMsgs({ ...initialAlkMsgsSet, ok: true });
          setAerate(false);
          aerateStatus = false;
          alkStatus = { ...initialAlkMsgsSet, ok: true };
        }

        if (stabiliser === stabiliserCya) {
          let stabCalci = (stabiliser * stabPositive) / 100;
          let positiveStabiliser = parseFloat(stabiliser) + parseFloat(stabCalci);

          if (
            copyPoolInputs.stabiliser >= 35
            && copyPoolInputs.stabiliser <= positiveStabiliser
          ) {
            addStabiliser = 0;
            drainWater = 0;
          }
        } else if (stabiliser === stabiliserOrp) {
          let stabCalci = (stabiliser * stabOrpPositive) / 100;
          let positiveStabiliser = parseFloat(stabiliser) + parseFloat(stabCalci);
          if (
            copyPoolInputs.stabiliser >= stabOrpNegative
            && copyPoolInputs.stabiliser <= positiveStabiliser
          ) {
            addStabiliser = 0;
            drainWater = 0;
          }
        }
        // let CalciumOk = false;
        let calciumCalci = (calciumHardness * calcPositive) / 100;
        let positiveCalcium = parseFloat(calciumHardness) + parseFloat(calciumCalci);
        if (
          chlorinationType !== 'Mineral Pool'
          && copyPoolInputs.calciumHardness >= calcNegative
          && copyPoolInputs.calciumHardness <= positiveCalcium
        ) {
          calciumRaiser = 0;
          calciumReducer = 0;
          drainWaterHardness = 0;
        } else if (
          chlorinationType === 'Mineral Pool'
          && copyPoolInputs.calciumHardness >= calcNegative
          && copyPoolInputs.calciumHardness <= positiveCalcium
        ) {
          drainWaterHardness = 0;
        }

        if (
          chlorinationType === 'Mineral Pool'
          && input >= calcNegative
          && input <= positiveCalcium
        ) {
          calciumRaiser = 0;
          calciumReducer = 0;
          // CalciumOk = true;
        }

        if (!copyPoolInputs.calciumHardness) {
          // CalciumOk = true;
        }
        // mineral changes

        if (chlorinationType === 'Manually Chlorinated') {
          if (manualValue.includes('Aquatherepe Transform Maintenance')) {
            if (input >= calcNegative && input <= positiveCalcium) {
              calciumRaiser = 0;
              drainWaterHardness = 0;
              calciumReducer = 0;
            } else {
              calciumRaiser = calciumTaker;
              drainWaterHardness = drainTaker;
            }
          }
        }

        let {
          algaecide,
          clarifierMaintainence,
          clarifierGreenPool,
          flock,
          baseAlgaecide,
          baseClarifier,
          baseClarifierGreen,
          baseFlock,
          sellAlgaecide,
          sellClarifier,
          sellClarifierGreen,
          sellFlock,
        } = calVariable;
        if (
          waterTestOptions === 'Water Test + Clarifier & Algaecide'
          || waterTestOptions === 'Water Test + Algaecide'
          || waterTestOptions === 'Water Test + Flock & Algaecide'
        ) {
          if (algaecide === '1000') {
            algaecideQuantity = Math.ceil(
              ((parseFloat(algaecide) / 50000) * poolCapacity) / 100,
            ) * 100;
          }
        }
        if (
          waterTestOptions === 'Water Test + Clarifier & Algaecide'
          || waterTestOptions === 'Water Test + Clarifier'
        ) {
          if (clarifierMaintainence === '100') {
            clarifierMaintainenceQuantity = Math.ceil(((2 / 1000) * poolCapacity) / 100) * 100;
          }
        }
        if (
          (serviceAnalysis === 'Green Pool'
            && greenTreatment === 'Liquid Chlorine + Clarifier')
          || (serviceAnalysis === 'Green Pool'
            && greenTreatment === 'Granular Chlorine + Clarifier'
            || (serviceAnalysis === 'Black-Spot Algae'
            && blackTreatment === 'Black-Spot Remover + Clarifier'))
        ) {
          if (clarifierGreenPool === '1000') {
            clarifierGreenPoolQuantity = Math.ceil(
              ((parseFloat(clarifierGreenPool) / 50000) * poolCapacity) / 100,
            ) * 100;
          }
        }
        if (
          waterTestOptions === 'Water Test + Flock & Algaecide'
          || waterTestOptions === 'Water Test + Flock'
          || (serviceAnalysis === 'Green Pool'
            && (greenTreatment === 'Granular Chlorine + Flock'
              || greenTreatment === 'Liquid Chlorine + Flock')
              || (serviceAnalysis === 'Black-Spot Algae'
              && blackTreatment === 'Black-Spot Remover + Flock'))
        ) {
          if (flock === '2.0') {
            flockQuantity = (parseFloat(flock) / 50000) * poolCapacity;
          }
        }

        const { chemicalVal: algaeVal, unit: algaeUnits } = algaeClariValUnits(algaecideQuantity, valueType);
        algaecideQuantity = algaeVal;

        if (algaecideQuantity) {
          quantityCostAlgaecide = (baseAlgaecide) * algaecideQuantity;
          sellPriceAlgaecide = Math.ceil(((sellAlgaecide) * algaecideQuantity) / 5) * 5;
          costAlgaecide = parseInt(Math.ceil(sellPriceAlgaecide / 5), 10) * 5 * chemicalsTax;
        }

        const { chemicalVal: clarVal, unit: clarUnits } = algaeClariValUnits(clarifierMaintainenceQuantity, valueType);
        clarifierMaintainenceQuantity = clarVal;

        if (clarifierMaintainenceQuantity) {
          quantityCostClarifierMain = (baseClarifier) * clarifierMaintainenceQuantity;
          sellPriceClarifierMain = Math.ceil(
            ((sellClarifier) * clarifierMaintainenceQuantity) / 10,
          ) * 10;
          costClarifierMain = parseInt(Math.ceil(sellPriceClarifierMain / 5), 10) * 5 * chemicalsTax;
        }
        if (clarifierGreenPoolQuantity) {
          quantityCostClarifierGreen = (baseClarifierGreen / 1000) * clarifierGreenPoolQuantity;
          sellPriceClarifierGreen = Math.ceil(
            ((sellClarifierGreen / 1000) * clarifierGreenPoolQuantity) / 10,
          ) * 10;
          costClarifierGreen = parseInt(Math.ceil(sellPriceClarifierGreen / 5), 10) * 5 * chemicalsTax;
        }

        const { chemicalVal: flockVal, unit: flockUnit } = flockValUnits(flockQuantity, valueType);
        flockQuantity = parseFloat(flockVal);

        if (flockQuantity) {
          quantityCostFlock = (baseFlock / 1) * flockQuantity;
          sellPriceFlock = Math.ceil(((sellFlock / 1) * flockQuantity) / 10) * 10;
          costFlock = parseInt(Math.ceil(sellPriceFlock / 5), 10) * 5 * chemicalsTax;
        }

        if (chlorinationType === 'Salt Pool') {
          let saltCalciNega = (saltValue * saltNegative) / 100;
          negativeSalt = parseFloat(saltValue) - parseFloat(saltCalciNega);
          let saltCalciPos = (saltValue * saltPositive) / 100;
          positiveSalt = parseFloat(saltValue) + parseFloat(saltCalciPos);

          if (
            salt === saltValue
            && chlorinationType === 'Salt Pool'
            && parseFloat(copyPoolInputs.salt) >= negativeSalt
            && parseFloat(copyPoolInputs.salt) <= positiveSalt
          ) {
            drainWaterSalt = 0;
            addSalt = 0;
          }
        }
        if ((chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test')) {
          let saltCalciNega = (saltMagnaValue * saltMagnaNegative) / 100;
          negativeSalt = parseFloat(saltMagnaValue) - parseFloat(saltCalciNega);
          let saltCalciPos = (saltMagnaValue * saltMagnaPositive) / 100;
          positiveSalt = parseFloat(saltMagnaValue) + parseFloat(saltCalciPos);

          if (
            salt === saltMagnaValue
            && (chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test')
            && parseFloat(copyPoolInputs.salt) >= negativeSalt
            && parseFloat(copyPoolInputs.salt) <= positiveSalt
          ) {
            drainWaterSalt = 0;
            addSalt = 0;
          }
        }
        if (
          addSalt === 0
          && addStabiliser === 0
          && sodaAsh === 0
          && hydrochloricAcidAlk === 0
          && hydrochloricAcidPh === 0
          && calciumRaiser === 0
          && phosphateRemover === 0
          && buffer === 0
          && granularChlorine === 0
          && sodiumThiosulphate === 0
        ) {
          setChemicalBalanceStatus(true);
        }

        if (balancing) {
          addSalt = 0;
          addStabiliser = 0;
          sodaAsh = 0;
          hydrochloricAcidAlk = 0;
          hydrochloricAcidPh = 0;
          calciumRaiser = 0;
          phosphateRemover = 0;
          buffer = 0;
          granularChlorine = 0;
          sodiumThiosulphate = 0;
          copperQuantity = 0;
          flockQuantity = 0;
          algaecideQuantity = 0;
          clarifierMaintainenceQuantity = 0;
          zodiacOxy = 0;
        }

        // mineral changes
        let calciumMsgDisplayStatus = !calciDisplayChemicals?.salt && chlorinationType !== 'Manually Chlorinated' && poolInputs?.BoxCount > 4 && chlorinationType !== 'Salt Pool';

        if (chlorinationType === 'Calcium Hardness Titration Test' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
          calciumMsgDisplayStatus = false;
        }

        if (calciumMsgDisplayStatus) {
          calciumRaiser = 0;
        }

        const { unit: granUnit, chemicalVal: granVal } = getInGrams(granularChlorine, valueType);
        granularChlorine = converChlorine(chemicalBalanceValue, granVal, valueType);

        const { unit: sodiumUnit, chemicalVal: sodiumval } = getInGrams(sodiumThiosulphate, valueType);
        sodiumThiosulphate = sodiumval;
        if (chemicalBalanceValue === 'Granular Chlorine') {
          valueForChlorine = granularChlorine || sodiumThiosulphate;
          if (valueForChlorine) {
            valueForChlorine = managingChemicals(valueForChlorine);
          }
          baseCostForChlorine = granularChlorine
            ? baseGranular
            : baseSodiumThi;
          quantityCostForChlorine = valueForChlorine * baseCostForChlorine;
          sellPriceChlorine = valueForChlorine * (granularChlorine ? sellGranular : sellSodiumThi);
          costChroline = parseInt(Math.ceil(sellPriceChlorine / 5), 10) * 5 * chemicalsTax;
          profitChrolone = (costChroline / chemicalsTax).toFixed(2) - quantityCostForChlorine;
        } else if (chemicalBalanceValue === 'Liquid Chlorine') {
          valueForChlorine = granularChlorine * 5 || sodiumThiosulphate;
          if (valueForChlorine) {
            valueForChlorine = managingChemicals(valueForChlorine);
          }
          baseCostForChlorine = granularChlorine
            ? baseLiquid
            : baseSodiumThi;
          quantityCostForChlorine = valueForChlorine * baseCostForChlorine;

          sellPriceChlorine = valueForChlorine * (granularChlorine ? sellLiquid : sellSodiumThi);
          costChroline = parseInt(Math.ceil(sellPriceChlorine / 5), 10) * 5 * chemicalsTax;
          profitChrolone = (costChroline / chemicalsTax).toFixed(2) - quantityCostForChlorine;
        }

        const { chemicalVal: hydrAlkVal, unit: hydroAlkUnit } = isDryAcid && !valueType
          ? getInGrams(hydrochloricAcidAlk, valueType) : getValUnitInLitres(hydrochloricAcidAlk, valueType);
        hydrochloricAcidAlk = hydrAlkVal;

        const { chemicalVal: bufferVal, unit: bufferUnit } = bufferUnitVal(buffer, valueType);
        buffer = bufferVal;

        if (
          (hydrochloricAcidPh || hydrochloricAcidPh === 0)
          && hydrochloricAcidAlk
          && !sodaAsh
        ) {
          // sd
        } else if (buffer || hydrochloricAcidAlk) {
          // if (poolFinish !== 'Fibreglass Pool - Outdoor' && poolFinish !== 'Fibreglass Pool - Indoor') {
          //   ph = calVariable.phConcreteTilted;
          // }
          let valueForAlk = parseFloat((buffer || hydrochloricAcidAlk));
          const baseCostAlk = buffer ? baseAlkBuffer : basePhAcid;
          quantityCostAlk = valueForAlk * baseCostAlk;
          const sellPriceAll = valueForAlk * (buffer ? sellAlkBuffer : sellPhAcid);
          costAlkalinity = parseInt(Math.ceil(sellPriceAll / 5), 10) * 5 * chemicalsTax;
          profitAlkalinity = (costAlkalinity / chemicalsTax).toFixed(2) - quantityCostAlk;
        }

        if (isDryAcid && hydrochloricAcidAlk) {
          let valueForAlkDryAcid = hydrochloricAcidAlk;
          quantityCostAlkDryAcid = valueForAlkDryAcid * baseDryAcid;
          const sellPriceAlkDryAcid = valueForAlkDryAcid * sellDryAcid;
          costDryAlkAcid = parseInt(Math.ceil(sellPriceAlkDryAcid / 5), 10) * 5 * chemicalsTax;
          profitAlkDryAcid = (costDryAlkAcid / chemicalsTax).toFixed(2) - quantityCostAlkDryAcid;
          quantityCostAlk = quantityCostAlkDryAcid;
          costAlkalinity = costDryAlkAcid;
          profitAlkalinity = profitAlkDryAcid;
        }
        const { chemicalVal: calPhReduVal, unit: calPhReduUnit } = getValUnitInLitres(calciumPhReducer, valueType);
        calciumPhReducer = calPhReduVal;
        if (calciumPhReducer) {
          const baseCostPh = basePhAcid;
          quantityPhReducerCost = calciumPhReducer * baseCostPh;
          const sellPricePh = calciumPhReducer * sellPhAcid;
          calciumPhReducerCost = parseInt(Math.ceil(sellPricePh / 5), 10) * 5 * chemicalsTax;
          profitPh = (costPh / chemicalsTax).toFixed(2) - quantityCostPh;
        }

        const { chemicalVal: hydroPh, unit: hydroUnit } = isDryAcid && !valueType ? getInGrams(hydrochloricAcidPh, valueType) : getValUnitInLitres(hydrochloricAcidPh, valueType);
        hydrochloricAcidPh = hydroPh;
        const { unit: sodaUnit, chemicalVal: sodaVal } = getInGrams(sodaAsh, valueType);
        sodaAsh = sodaVal;

        if ((hydrochloricAcidPh || sodaAsh)) {
          let valueForPh = parseFloat((sodaAsh || hydrochloricAcidPh));
          if (valueForPh) {
            valueForPh = managingChemicals(valueForPh);
          }
          const baseCostPh = sodaAsh ? basePhSodaAsh : basePhAcid;
          quantityCostPh = valueForPh * baseCostPh;
          const sellPricePh = valueForPh * (sodaAsh ? sellPhSodaAsh : sellPhAcid);
          costPh = parseInt(Math.ceil(sellPricePh / 5), 10) * 5 * chemicalsTax;
          profitPh = (costPh / chemicalsTax).toFixed(2) - quantityCostPh;
        }
        // For Dry Acid
        if (chemicalBalanceWaterObj.phbalanceName === 'Dry Acid' && hydrochloricAcidPh) {
          let valueForPhDryAcid = hydrochloricAcidPh;
          quantityCostPhDryAcid = valueForPhDryAcid * baseDryAcid;
          const sellPricePhDryAcid = valueForPhDryAcid * sellDryAcid;
          costDryPhAcid = parseInt(Math.ceil(sellPricePhDryAcid / 5), 10) * 5 * chemicalsTax;
          profitPhDryAcid = (costDryPhAcid / chemicalsTax).toFixed(2) - quantityCostPhDryAcid;
          quantityCostPh = quantityCostPhDryAcid;
          costPh = costDryPhAcid;
          profitPh = profitPhDryAcid;
        }

        const drainObj = {
          maxDrain, drainWaterChorine, drainWaterHardness, drainWater, drainWaterCopper, drainWaterSalt, withDrainage, poolWidth, poolHeight, poolCapacity, balancing,
        };
        let drainSameMsg = '';
        let container = [];
        if (maxDrain) {
          const maxValueObj = maxAchieve(drainObj);
          drainSameMsg = maxValueObj.drainSameMsg;
          container = maxValueObj.container;
        }

        const { unit: zodiacUnit, chemicalVal: zodiacVal } = getInGrams(zodiacOxy, valueType);
        zodiacOxy = zodiacVal;
        let valueForCombinedChlorine = drainWaterChorine;
        if (valueForCombinedChlorine && !withDrainage) {
          if (
            (
              maxDrain === drainWaterChorine
            && diffChlorine) || balancing
          ) {
            let calculation = (5 / parseFloat(newCombinedChrlone)) * 100;
            let difference = 100 - calculation;
            drainPercent = Math.floor(difference / 5) * 5;
            const { Cost, Hours } = getDrainCost(drainPercent, copyPoolInputs.poolCapacity);
            combinedChlorineDrainingHours = Hours;
            costCombinedChlorine = Cost;
            // drainWaterChorine = costCombinedChlorine;
            quantityCostCombined = 0;
          }
        } else if (
          zodiacOxy && ((((drainWaterChorine && maxDrain !== drainWaterChorine) || !drainWaterChorine)
            && parseFloat(newCombinedChrlone) > 0.199)
          || serviceAnalysis === 'Green Pool' || withDrainage)
        ) {
          const AstralPool = chemicalBalanceWaterObj?.combinedchlorinebalanceName === 'AstralPool Oxy Boost';
          const buyCost = AstralPool ? basePotassiumOxyBoost : basePotassium;
          quantityCostCombined = parseFloat(zodiacOxy) * buyCost;
          const sellPrice = parseFloat(zodiacOxy) * (AstralPool ? sellPotassiumOxyBoost : sellPotassium);
          costCombinedChlorine = parseInt(Math.ceil(sellPrice / 5), 10) * 5 * chemicalsTax;
        }
        const { chemicalVal: copVal, unit: copperUnit } = getCopperValUnits(copperQuantity, valueType);
        copperQuantity = copVal;

        let valueForCopper = drainWaterCopper;
        if (calciDisplayChemicals?.copper) {
          if (valueForCopper) {
            if (
              (!withDrainage
            && maxDrain === drainWaterCopper
            && container && !container.includes('Copper')
            && diffCopper) || (balancing && !container.includes('Copper'))
            ) {
              const { Cost, Hours } = getDrainCost(drainWaterCopper, copyPoolInputs.poolCapacity);
              copperDrainingHours = Hours;
              costCopper = Cost;
              quantityCostCopper = 0;
            }
          } else if (
            (((drainWaterCopper && maxDrain !== drainWaterCopper) || !drainWaterCopper) && copperQuantity)
          || serviceAnalysis === 'Green Pool'
          ) {
            let valueForCopper = copperQuantity;
            quantityCostCopper = baseCopper * valueForCopper;
            const sellPriceCopper = valueForCopper * sellCopper;
            costCopper = parseInt(Math.ceil(sellPriceCopper / 5), 10) * 5 * chemicalsTax;
          }
        }

        let costStabiliser = 0;

        const { unit: stabUnit, chemicalVal: stabiliserValue } = getInGrams(addStabiliser, valueType);
        addStabiliser = stabiliserValue;

        let valueForStabiliser = addStabiliser || drainWater;

        if (valueForStabiliser) {
          valueForStabiliser = managingChemicals(valueForStabiliser);
          const baseCostStabiliser = addStabiliser ? baseStabiliser : null;

          if (
            (!baseCostStabiliser
            && !withDrainage
            && maxDrain === drainWater
            && diffStabliser
            && !container.includes('Stabiliser')) || (balancing && !container.includes('Stabiliser'))
          ) {
            const { Cost, Hours } = getDrainCost(drainWater, copyPoolInputs.poolCapacity);
            stabiliserDrainingHours = Hours;
            costStabiliser = Cost;
            quantityCostStabiliser = 0;
          } else if (((drainWater && maxDrain !== drainWater) || !drainWater) && valueForStabiliser) {
            quantityCostStabiliser = valueForStabiliser * baseCostStabiliser;
            const sellPriceStabiliser = valueForStabiliser * sellStabiliser;
            costStabiliser = parseInt(Math.ceil(sellPriceStabiliser / 5), 10) * 5 * chemicalsTax;
            profitStabiliser = (costStabiliser / chemicalsTax).toFixed(2) - quantityCostStabiliser;
          }
        }

        let costCalcium = 0;

        const { unit: calciumUnit, chemicalVal: calciumValue } = getInGrams(calciumRaiser, valueType);
        calciumRaiser = calciumValue;

        let valueForCalcium = calciumRaiser || drainWaterHardness;
        if (valueForCalcium && (calciDisplayChemicals?.calciumHardness || chlorinationType === 'Calcium Hardness Titration Test' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test')) {
          valueForCalcium = managingChemicals(valueForCalcium);
          const baseCostCalcium = calciumRaiser ? baseCalciumRaiser : null;
          if (
            (!baseCostCalcium
            && !withDrainage
            && maxDrain === drainWaterHardness
            && diffCalcium
            && !container.includes('Calcium Hardness')) || (balancing && !container.includes('Calcium Hardness'))
          ) {
            const { Cost, Hours } = getDrainCost(drainWaterHardness, copyPoolInputs.poolCapacity);
            calciumDrainingHours = Hours;
            costCalcium = Cost;
            quantityCostCalcium = 0;

            if (calciumMsgDisplayStatus) {
              // console.log('condition1');
              costCalcium = 'N/A';
              quantityCostCalcium = 'N/A';
            }
          } else if (valueForCalcium && ((drainWaterHardness && maxDrain !== drainWaterHardness) || !drainWaterHardness)) {
            quantityCostCalcium = valueForCalcium * baseCostCalcium;
            const sellPriceCalcium = valueForCalcium * sellCalciumRaiser;
            costCalcium = parseInt(Math.ceil(sellPriceCalcium / 5), 10) * 5 * chemicalsTax;
            profitCalciumHardness = (costCalcium / chemicalsTax).toFixed(2) - quantityCostCalcium;
          }
        }
        const { chemicalVal: calReducerValue, unit: calReducerUnit } = getValUnitInLitres((calciumReducer / 1000), valueType);
        calciumReducer = calReducerValue;
        if ((calciDisplayChemicals?.calciumHardness || chlorinationType === 'Calcium Hardness Titration Test' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') && calciumReducer && !drainWaterHardness) {
          quantityCostCalcium = (calciumReducer * baseZodiacCalcium);
          const sellPriceCalcium = (calciumReducer * sellZodiacCalcium);
          costCalcium = parseInt(Math.ceil(sellPriceCalcium / 5), 10) * 5 * chemicalsTax;
          profitCalciumHardness = (costCalcium / chemicalsTax).toFixed(2) - quantityCostCalcium;
        }

        let costSalt = 0;
        if ((addSalt || drainWaterSalt || convertPool)) {
          if (
            chlorinationType === 'Convert Salt Pool to Mineral Pool'
            || manualValue === 'Convert Pool to Mineral Pool'
            || manualValue.includes('Aquatherepe Transform Maintenance')
            || waterTestOptions === 'Water Test + Clarifier & Algaecide'
          ) {
            // if (!isDrainage) {
            const costPerBag = baseConvert;
            quantityCostSaltConvert = convertPool * costPerBag;
            const margin = convertPool * sellConvert;
            costSaltConvert = parseInt(Math.ceil(margin / 5), 10) * 5 * chemicalsTax;
            profitSaltConvert = costSaltConvert - quantityCostSaltConvert;
            // }
            if (
              (calciDisplayChemicals?.salt && !withDrainage
              && maxDrain === drainWaterSalt
              && drainWaterSalt
              && diffSalt
              && !container.includes('Salt')) || (balancing && !container.includes('Salt'))
            ) {
              const { Cost, Hours } = getDrainCost(drainWaterSalt, copyPoolInputs.poolCapacity);
              saltDrainingHours = Hours;
              costSalt = Cost;
              quantityCostSalt = 0;
            } else if (((drainWaterSalt && maxDrain !== drainWaterSalt) || !drainWaterSalt)) {
              quantityCostSalt = addSalt * baseSalt;
              const sellPriceSalt = addSalt * sellSalt;
              costSalt = parseInt(Math.ceil(sellPriceSalt / 5), 10) * 5 * chemicalsTax;
              profitSalt = (costSalt / chemicalsTax).toFixed(2) - quantityCostSalt;
            }
          } else if (
            (selectedModel === 'Magna Minerals'
              || selectedModel === 'Magna Minerals - With ORP Probe')
            && calciDisplayChemicals?.salt && ((drainWaterSalt && maxDrain !== drainWaterSalt) || !drainWaterSalt)
          ) {
            const costPerBag = baseMagna;
            const updatedAddSalt = addSalt * 10;
            quantityCostSalt = (updatedAddSalt / 10) * costPerBag;
            const margin = addSalt * sellMagna;
            costSalt = parseInt(Math.ceil(margin / 5), 10) * 5 * chemicalsTax;
            profitSalt = costSalt - quantityCostSalt;
          } else if (chlorinationType === 'Salt Pool') {
            const valueForSalt = addSalt || drainWaterSalt;
            const baseCostSalt = addSalt ? baseSalt : null;
            if (
              (calciDisplayChemicals?.salt && !baseCostSalt
              && !withDrainage
              && maxDrain === drainWaterSalt
              && diffSalt
              && !container.includes('Salt')) || (balancing && !container.includes('Salt'))
            ) {
              const { Cost, Hours } = getDrainCost(drainWaterSalt, copyPoolInputs.poolCapacity);
              saltDrainingHours = Hours;
              costSalt = Cost;
              quantityCostSalt = 0;
            } else if (((drainWaterSalt && maxDrain !== drainWaterSalt) || !drainWaterSalt)) {
              quantityCostSalt = valueForSalt * baseCostSalt;
              const sellPriceSalt = valueForSalt * sellSalt;
              costSalt = parseInt(Math.ceil(sellPriceSalt / 5), 10) * 5 * chemicalsTax;
              profitSalt = (costSalt / chemicalsTax).toFixed(2) - quantityCostSalt;
            }
          } else if (
            (((drainWaterSalt && maxDrain !== drainWaterSalt) || !drainWaterSalt)) && calciDisplayChemicals?.salt && (selectedModel === 'Aquatherepe Minerals'
            || (selectedModel === 'Aquatherepe Transform' && (chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test'))
              || selectedModel === 'Aquatherepe Minerals - With ORP Probe')

          ) {
            const costPerBag = baseAqua;
            quantityCostSalt = addSalt * costPerBag;
            const margin = addSalt * sellAqua;
            costSalt = parseInt(Math.ceil(margin / 5), 10) * 5 * chemicalsTax;
            profitSalt = costSalt - quantityCostSalt;
          }
        }

        if (copperQuantity && phosphateRemover) {
          phosphateStatus = true;
        }
        const { chemicalVal: phosValue, unit: phosUnit } = getPhosValUnit(phosphateRemover, valueType);
        phosphateRemover = phosValue;
        if (calciDisplayChemicals?.phosphates && phosphateRemover && !phosphateStatus) {
          let valueForPhosphate = phosphateRemover;
          quantityCostPhosphates = basePhosphates * valueForPhosphate;
          const sellPricePhosphate = valueForPhosphate * sellPhosphates;
          costPhophates = parseInt(Math.ceil(sellPricePhosphate / 5), 10) * 5 * chemicalsTax;
        }

        if (serviceAnalysis === 'Sand Filter Media Replacement') {
          copyLevelData = handleFilterCalculate({
            filterSelect, setFilterCost, poolFilterData, filterOptions, filteringData, costsFiltering, setLevelData, setFilterStatus, filterCost, levelData: copyLevelData, serviceAnalysis, calciumReducer,
          });
        }

        if (greenSplitName === 'Part') {
          const { Cost, Hours } = getDrainCost(drainPercentWaterLevelLowered, copyPoolInputs.poolCapacity);
          drainPercentWaterLevelLoweredHours = Hours;
          costDrainPercentWaterLevelLowered = Cost;
        }

        // Conditions For Strip

        const SettingNA = (willNa, willNaType) => {
          if (willNa !== false) {
            costSalt = 'N/A';
            quantityCostSalt = 'N/A';
          }
          if (willNaType !== 'calc') {
            costCalcium = 'N/A';
            quantityCostCalcium = 'N/A';
          }
          quantityCostPh = 'N/A';
          costPh = 'N/A';
          costChroline = 'N/A';
          quantityCostForChlorine = 'N/A';
          costCombinedChlorine = 'N/A';
          quantityCostCombined = 'N/A';
          costAlkalinity = 'N/A';
          quantityCostAlk = 'N/A';
          costStabiliser = 'N/A';
          quantityCostStabiliser = 'N/A';

          if (willNaType === 'all') {
            costAlgaecide = 'N/A';
            quantityCostAlgaecide = 'N/A';
            costFlock = 'N/A';
            quantityCostFlock = 'N/A';
            costClarifierMain = 'N/A';
            quantityCostClarifierMain = 'N/A';
            costCopper = 'N/A';
            quantityCostCopper = 'N/A';
            costPhophates = 'N/A';
            quantityCostPhosphates = 'N/A';
            costSaltConvert = 'N/A';
            quantityCostSaltConvert = 'N/A';
          }
        };

        const sixPadStrip = poolInputs?.BoxCount > 4;
        const stripExist = poolInputs?.BoxCount > 0;
        let stabiliserShow = true;
        let calError = '';
        const isInput = returnChemicalsInputStatus();
        let showCalciumStatus = true;
        let isCalciumShow = false;

        const {
          salt: disSalt, phosphates: disPhos, copper: disCopper, calciumHardness: disCalcium, totalChlorine: disTotalChlorine,
        } = calciDisplayChemicals;
        if (stripExist) {
          if (sixPadStrip && poolInputs.chlorine > 20) {
            calError = 'Free Chorine must be below 20.0 to display results';
          } else if (!sixPadStrip && poolInputs.chlorine > 12) {
            calError = 'Free Chorine must be below 12.0 to display results';
          }

          if (calError) {
            SettingNA('', 'all');
          }

          if (!calError) {
            if (copyPoolInputs.ph > 8.0) {
              costStabiliser = 'N/A';
              quantityCostStabiliser = 'N/A';
              stabiliserShow = false;
            }

            if (!disSalt) {
              costSalt = 'N/A';
              quantityCostSalt = 'N/A';
            }
            if (serviceAnalysis === 'Drain Pool' && greenSplitName === 'Part') {
              if (sixPadStrip && (!disSalt) && !showFullHardness?.calcium) {
                console.log('condition2');
                SettingNA('', 'all');
              }
            }

            if (
              !sixPadStrip
              && greenSplitName === 'Part'
            && (chlorinationType.includes('Salt Pool')
           || chlorinationType.includes('Manually'))) {
              if (!(disSalt && isInput.calciumHardness))SettingNA('', 'all');
            }

            if (!isInput.phosphates) {
              costPhophates = 'N/A';
              quantityCostPhosphates = 'N/A';
            }
            if (!isInput.copper) {
              costCopper = 'N/A';
              quantityCostCopper = 'N/A';
            }
            if (!sixPadStrip) {
              if (!isInput.combinedChlorine) {
                costCombinedChlorine = 'N/A';
                quantityCostCombined = 'N/A';
              }
              if (!isInput.calciumHardness && !showFullHardness?.calcium) {
                console.log('condition4');
                showCalciumStatus = false;
                costCalcium = 'N/A';
                quantityCostCalcium = 'N/A';
              }
            }

            if (isDrainage) {
              const checkStatus = (sixPadStrip && disSalt)
               || chlorinationType === 'Manually Chlorinated'
               || chlorinationType === 'Calcium Hardness Titration Test'
               || disSalt && poolInputs.calciumHardness;
              if (!checkStatus) {
                console.log('condition5');
                SettingNA('', 'all');
              }
            }

            calError = stripMessageReturn(chlorinationType, greenSplitName, sixPadStrip, disSalt, isDrainage, isInput.calciumHardness);
          }
        }

        // console.log({ showHardness: showFullHardness?.calcium, CalciumOk, checkCalciumPopUp });

        if (chlorinationType === 'Mineral Pool' && !showFullHardness?.calcium && (!isOkCalcium)) {
          if (!sixPadStrip && isInput.calciumHardness && !disSalt) {
            isCalciumShow = false;
          } else {
            isCalciumShow = true;
          }
        }
        // console.log({ isCalciumShow });
        if (isCalciumShow && titrationPopupOptions === 'dontShowCalcium') {
          showCalciumStatus = false;
          costCalcium = 'N/A';
          quantityCostCalcium = 'N/A';
        }

        if ((isDrainage && maxTestingDrain) && !stripExist && !showCalciumStatus) {
          console.log('condition6');
          SettingNA('', 'all');
          calError = 'Requires Calcium Hardness reading to display drain %';
        }

        if (isDrainage && stripExist && chlorinationType === 'Mineral Pool' && !showCalciumStatus) {
          calError = 'Requires Salt reading to display drain %';
          const costWillNA = disSalt && isDrainage;
          console.log('condition7');
          SettingNA(costWillNA, (costWillNA ? 'all' : 'calc'));
          if (costWillNA) {
            calError = 'Requires Calcium Hardness reading to display drain %';
          }
        }

        if (serviceAnalysis === 'Green Pool' || serviceAnalysis === 'Drain Pool' || serviceAnalysis === 'Black-Spot Algae') {
          if (greenSplitName !== 'Part') {
            const fullgreenobj = {
              greenSplitName: serviceAnalysis === 'Black-Spot Algae' ? blackSplitName : greenSplitName,
              greenPoolCalculation,
              greenTreatment: serviceAnalysis === 'Black-Spot Algae' ? blackTreatment : greenTreatment,
              clarifierGreenPoolQuantity,
              costClarifierGreen,
              quantityCostClarifierGreen,
              flockQuantity,
              costFlock,
              quantityCostFlock,
              serviceAnalysis,
              levelData: copyLevelData,
              setLevelData,
              acidWashApiData,
              greenSelect: serviceAnalysis === 'Black-Spot Algae' ? blackSelect : greenSelect,
              greenApiData: serviceAnalysis === 'Black-Spot Algae' ? blackApiData : greenApiData,
              copyPoolInputs,
              calVariable,
              drainWaterHardness,
              drainWaterSalt,
              drainWaterChorine,
              drainWater,
              isDrainage,
              withDrainage,
              baseGranular,
              drainWaterCopper,
              greenQuantity,
              greenLabourCost,
              greenCost,
              greenVaccumCost,
              valueType,
            };
            copyLevelData = greenPoolAndDrainPoolCalculation(fullgreenobj);
          }
        }
        // const poolValues = { ...copyPoolInputs };

        if (!withDrainage) {
          const mutationInp = (value, percentValue, extra) => {
            if (maxTestingDrain && maxTestingDrain === percentValue) {
              copyPoolInputs[value] = finalDrainInp((extra || poolInputs[value]), drPercent, copySrcInp[value]);
            }
          };
          mutationInp('calciumHardness', drainWaterHardness, input);
          // mutationInp('chlorine', drainWaterChorine);
          mutationInp('copper', drainWaterCopper);
          mutationInp('salt', drainWaterSalt);
          mutationInp('stabiliser', drainWater);
          mutationInp('combinedChlorine', drainWaterChorine);
          copyPoolInputs.newCombinedChlr = maxTestingDrain
            && (maxTestingDrain === drainWaterChorine) ? (copySrcInp.combinedChlorine - poolInputs.chlorine)
            : copyPoolInputs.newCombinedChlr;
        }

        // console.log('==>>', { calError });

        const totalCost = LoadExistValue(costChroline)
          + LoadExistValue(costPh)
          + LoadExistValue(costAlkalinity)
          + LoadExistValue(costStabiliser)
          + LoadExistValue(costCalcium)
          + LoadExistValue(costSalt)
          + LoadExistValue(costSaltConvert)
          + LoadExistValue(costPhophates)
          + LoadExistValue(costCombinedChlorine)
          + LoadExistValue(costAlgaecide)
          + LoadExistValue(costClarifierMain)
          + LoadExistValue(costFlock)
          + LoadExistValue(costCopper)
          + 5.5;

        const { phosphateMsg, renderPhosphate, renderPhosphateUnit } = getPhosphateMsg(phosphateRemover, withDrainage, phosphateStatus, {
          passDiffStab,
          passDiffHard,
          passDiffSalt,
          passDiffChlorine,
          passDiffCopper,
        }, balancing, valueType, phosUnit, calError, sixPadStrip, stripExist, isInput?.phosphates,
        chlorinationType,
        greenSplitName,
        disSalt,
        isDrainage);

        const {
          stabiliserMsg, renderStabiliser, renderStabName, renderStabiliserUnit,
        } = getStabiliserMsg(
          addStabiliser,
          { passDiffStab },
          drainObj,
          copyPoolInputs,
          stabiliser,
          container,
          drainSameMsg,
          valueType,
          stabUnit,
          chlorinationType,
          stripExist,
          sixPadStrip,
          greenSplitName,
          disSalt,
          disCalcium,
          isDrainage,
          'Granular Stabiliser',
          calError,
          stabiliserShow,
        );

        const {
          calciumHardnessMsg, renderCalcium, renderCalciumName, renderCalciumUnit,
        } = getCalciumMsg(
          calciumRaiser,
          { passDiffHard },
          drainObj,
          copyPoolInputs,
          calciumHardness,
          container,
          drainSameMsg,
          calciumReducer,
          chemicalBalanceWaterObj,
          valueType,
          calciumUnit,
          calReducerUnit,
          disSalt,
          chlorinationType,
          calciumMsgDisplayStatus,
          stripExist,
          sixPadStrip,
          greenSplitName,
          isDrainage,
          showFullHardness?.calcium,
          blankInputFields?.hardCalcium,
          showCalciumStatus,
          calError,
          manualValue,
          isInput.calciumHardness,
        );
        const {
          saltMsg, renderSalt, renderSaltName, renderSaltUnit,
        } = getSaltMsg(
          addSalt,
          { passDiffSalt },
          drainObj,
          selectedModel,
          bagsSalt,
          copyPoolInputs,
          salt,
          container,
          drainSameMsg,
          valueType,
          chlorinationType,
          stripExist,
          sixPadStrip,
          greenSplitName,
          disSalt,
          isDrainage,
          calError,
        );
        const { combinedChlorineMsg, renderCombined, renderCombinedUnit } = getCombinedChlorineMessage(
          zodiacOxy,
          passDiffChlorine,
          drainObj,
          container,
          drainSameMsg,
          valueType,
          zodiacUnit,
          chlorinationType,
          stripExist,
          sixPadStrip,
          greenSplitName,
          disSalt,
          disCalcium,
          isDrainage,
          calError,
          isInput.combinedChlorine,
          chemicalBalanceWaterObj?.combinedchlorinebalanceName,
        );
        const { copperMsg, renderCopper, renderCopperUnit } = getCopperMsg(
          copperQuantity,
          passDiffCopper,
          drainObj,
          container,
          drainSameMsg,
          valueType,
          copperUnit,
          calError,
          sixPadStrip,
          stripExist,
          isInput?.copper,
          chlorinationType,
          greenSplitName,
          disSalt,
          isDrainage,
        );

        const {
          chlorineMsg, renderChlorine, renderChlorineName, renderChlorineUnit,
        } = getChlorineMsg(
          granularChlorine,
          sodiumThiosulphate,
          chemicalBalanceValue,
          chlorine,
          poolInputs,
          calVariable,
          balancing,
          valueType,
          granUnit,
          sodiumUnit,
          chlorinationType,
          stripExist,
          sixPadStrip,
          greenSplitName,
          disSalt,
          disCalcium,
          isDrainage,
          calError,
          serviceAnalysis,
          copyPoolInputs?.chlorine,
        );

        const {
          phMsg, renderPh, renderPhName, renderPhUnit,
        } = getPhMsg(aerateStatus,
          sodaAsh,
          hydrochloricAcidPh,
          ph,
          phNegat,
          phPosit,
          copyPoolInputs.ph,
          chemicalBalanceWaterObj,
          balancing, valueType, hydroUnit, sodaUnit, chlorinationType,
          stripExist,
          sixPadStrip,
          greenSplitName,
          disSalt,
          disCalcium,
          isDrainage,
          calError);

        const { calciumPhReducerMsg, renderPhReducer, renderPhReducerUnit } = getCalciumPhReducerMsg(calciumPhReducer, chemicalBalanceWaterObj, valueType, calPhReduUnit, calError);

        const {
          alkMsg, renderAlkalinity, renderAlkName, renderAlkMsgUnit,
        } = getAlkMsg(alkStatus.aerate,
          alkStatus.retest,
          alkStatus.ok,
          hydrochloricAcidAlk,
          buffer,
          chemicalBalanceWaterObj,
          balancing, valueType, hydroAlkUnit, bufferUnit, chlorinationType,
          stripExist,
          sixPadStrip,
          greenSplitName,
          disSalt,
          disCalcium, isDrainage, calError);

        const {
          saltMsgConvert, secondSaltMsgConvert, renderSaltConvert, renderSecondSaltValue, renderSaltConvertName, renderSecondSaltConvertName,
        } = getSaltConvertMsg(convertPool, manualValue, manualAquaValue, balancing, valueType, calError);

        let clarifierMsg = '';
        let algaecideMsg = '';
        let flockMsg = '';
        let renderFlock = '';
        let renderAlgaecide = '';
        let renderClarifier = '';
        let renderClarUnits = '';
        let renderAlgaeUnits = '';
        let renderFlockUnits = '';
        if (
          waterTestOptions === 'Water Test + Clarifier & Algaecide'
        || waterTestOptions === 'Water Test + Clarifier'
        ) {
          const { clarMsg, renClarifier, renClarUnits } = getClarifierMsg(clarifierMaintainenceQuantity, valueType, clarUnits, chlorinationType,
            stripExist,
            sixPadStrip,
            greenSplitName,
            disSalt,
            disCalcium, isDrainage, calError);
          clarifierMsg = clarMsg;
          renderClarifier = renClarifier;
          renderClarUnits = renClarUnits;
        }
        if (
          waterTestOptions === 'Water Test + Clarifier & Algaecide'
        || waterTestOptions === 'Water Test + Algaecide'
        || waterTestOptions === 'Water Test + Flock & Algaecide'
        ) {
          const { algMsg, renAlgaecide, renAlgaeUnits } = getAlgaecideMsg(algaecideQuantity, valueType, algaeUnits, chlorinationType,
            stripExist,
            sixPadStrip,
            greenSplitName,
            disSalt,
            disCalcium, isDrainage, calError);
          renderAlgaecide = renAlgaecide;
          algaecideMsg = algMsg;
          renderAlgaeUnits = renAlgaeUnits;
        }
        if (
          waterTestOptions === 'Water Test + Flock'
        || waterTestOptions === 'Water Test + Flock & Algaecide'
        ) {
          const { flkMsg, renFlock, renFlockUnits } = getFlockMsg(flockQuantity, valueType, flockUnit, chlorinationType,
            stripExist,
            sixPadStrip,
            greenSplitName,
            disSalt,
            disCalcium, isDrainage, calError);
          flockMsg = flkMsg;
          renderFlock = renFlock;
          renderFlockUnits = renFlockUnits;
        }

        if (staffDetails.staffId && staffDetails.staffName) {
          copyLevelData.forEach((el) => el.service.forEach((ele) => {
            ele.staffName = staffDetails.staffName;
            ele.staffId = staffDetails.staffId;
          }));

          // setLevelData(copyLevelData);
        }

        if (copyPoolInputs.ph !== '' && copyPoolInputs.alkalinity === 0 && changingPhvalue) {
          copyPoolInputs.alkalinity = '';
          changingPhvalue = false;
        }
        if (copyPoolInputs.combinedChlorine !== '' && copyPoolInputs.chlorine === 0 && changingChlorineValue) {
          copyPoolInputs.chlorine = '';
          changingChlorineValue = false;
        }

        if (serviceAnalysis.includes('Water Test') || greenSplitName === 'Part') {
          let poolCopy = { ...copyPoolInputs };
          setCarryingPoolInputsData(poolCopy);
        }

        if (takingPh === '') {
          poolInputs.ph = '';
        }

        if (
          (serviceAnalysis === 'Green Pool'
            && greenSplitName !== 'Drain'
            && copyPoolInputs.ph === '6.8')
          || serviceAnalysis === 'Sand Filter Media Replacement'
          || (serviceAnalysis.includes('Water Test') && copyPoolInputs.ph === '0' && isDrainage)
        ) {
          copyPoolInputs.ph = '';
        }

        if (!withDrainage) encryptPool('', newCombinedChrlone);

        let updatedObj = null;
        if (copyLevelData?.length > 0 && greenSplitName !== 'Drain') {
          const fIndex = copyLevelData[0].service?.findIndex((el) => el.subcategory);
          if ((maxDrain && !withDrainage) || balancing) {
            if (maxDrain === drainWater && costStabiliser) {
              updatedObj = chemicalNewDrainGreen(
                drainWater,
                costStabiliser,
                stabiliserDrainingHours,
                stabiliserMsg,
              );
            } else if (maxDrain === drainWaterHardness && costCalcium) {
              updatedObj = chemicalNewDrainGreen(
                drainWaterHardness,
                costCalcium,
                calciumDrainingHours,
                calciumHardnessMsg,
              );
            } else if (maxDrain === drainWaterChorine && costChroline) {
              updatedObj = chemicalNewDrainGreen(
                drainWaterChorine,
                costChroline,
                combinedChlorineDrainingHours,
                combinedChlorineMsg,
              );
            } else if (maxDrain === drainWaterCopper && costCopper) {
              updatedObj = chemicalNewDrainGreen(
                drainWaterCopper,
                costCopper,
                copperDrainingHours,
                copperMsg,
              );
            } else if (maxDrain === drainWaterSalt && costSalt) {
              updatedObj = chemicalNewDrainGreen(
                drainWaterSalt,
                costSalt,
                saltDrainingHours,
                saltMsg,
              );
            } else if (
              maxDrain === drainPercentWaterLevelLowered
            && costDrainPercentWaterLevelLowered
            ) {
              updatedObj = chemicalNewDrainGreen(
                drainPercentWaterLevelLowered,
                costDrainPercentWaterLevelLowered,
                drainPercentWaterLevelLoweredHours,
                '',
              );
            }
          }

          if (withDrainage && !balancing) {
            updatedObj = chemicalNewDrainGreen(0, 0, '0', '');
          }

          if (updatedObj) {
            const drainData = chemicalDrainGreen(
              updatedObj,
              '',
              stripExist,
            );

            if (fIndex >= 0) {
              copyLevelData[0].service?.push(drainData);
            } else if (fIndex === -1) {
              if (updatedObj?.drainPercent !== 0)copyLevelData[0].service = [drainData];
            }
          }
        }

        const { pricingType, cost } = secondVisit || {};
        let customCost = cost?.includes('$') ? cost.replace('$', '').trim() : cost;
        const secondVisitCost = pricingType === 'fixedCost' ? parseFloat(customCost || 0)
          : Math.ceil((parseFloat(customCost || 0) * (parseFloat(secondVisit?.duration || 0) / 60)) / 5) * 5;
        // const waterTestIndex = serviceAnalysis === 'Water Test' && !isDrainage ? 0 : 1;

        const updatedIndex = copyLevelData.findIndex((el) => {
          if (serviceAnalysis === 'Water Test' || serviceAnalysis === 'Drain Pool') {
            if (el?.service?.length === 0) return true;
          } else if (el?.service?.length <= 1) return true;

          return false;
        });
        const GreenBlackStatus = !!((serviceAnalysis === 'Green Pool' || serviceAnalysis === 'Black-Spot Algae'));

        if (GreenBlackStatus) {
          costChroline = 0;
          quantityCostForChlorine = 0;
        }

        const checkToGoChemicalGreen = (msgwa, cat) => {
          let msgSplitQuantity = msgwa?.split(' ')[1];
          if (!msgwa
            || msgwa === 'OK'
          || msgwa.includes('weeks')
          || msgwa === 'Add 0 ml of Hydrochloric Acid'
          || msgwa === 'Re-test after pool is refilled'
          || (!msgwa.includes('to display') && msgwa.includes('%'))
          || msgwa.includes('Aerate')
          || msgSplitQuantity === '0'
          || (msgwa === 'Requires Calcium Hardness reading to display drain %'
            && cat === 'calciumRaiser')) return false;
          return true;
        };

        // const nake = setUpGreenData.map((el, index) => {
        let newChemicalArray = [];

        if (copyLevelData[updatedIndex]?.service?.length > 0) {
          newChemicalArray = copyLevelData[updatedIndex]?.service;
        }

        // console.log({ secondVisitCost });

        if (!GreenBlackStatus && secondVisitCost) {
          let cost = secondVisitCost;
          let duration = secondVisit?.duration;
          let quantityCost = 0.00;
          let msg = '2nd Visit Cost';
          if ((serviceAnalysis === 'Water Test' && !isDrainage) || balancing) {
            cost = 0;
            duration = '0';
            msg = '';
            quantityCost = 0.00;
          }

          newChemicalArray.push(chemicalGreen(
            'Green Pool Labour Cost',
            quantityCost,
            cost,
            msg,
            duration,
            duration,
            '',
            '',
          ));

          // chemicalGreen(index,
          //   quantityCost,
          //   cost,
          //   msg,
          //   duration,
          //   updatedIndex);
        }

        if (checkToGoChemicalGreen(chlorineMsg) && !GreenBlackStatus) {
          let cost = costChroline;
          let duration = '0';
          if (costChroline) {
            cost = costChroline / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'chlorine',
            quantityCostForChlorine,
            cost,
            chlorineMsg,
            duration,
            renderChlorine,
            renderChlorineName,
            renderChlorineUnit,
            // renderChlorineUnique,
          ));
        }
        if (checkToGoChemicalGreen(combinedChlorineMsg)) {
          let cost = drainWaterChorine ? 0 : costCombinedChlorine;
          let duration = costCombinedChlorine
            ? combinedChlorineDrainingHours || '0'
            : '0';
          if (zodiacOxy && costCombinedChlorine && ((drainWaterChorine && maxDrain !== drainWaterChorine) || !drainWaterChorine)) {
            cost = costCombinedChlorine / chemicalsTax;
            duration = '5';
          }

          newChemicalArray.push(chemicalGreen(
            'combined',
            quantityCostCombined,
            cost,
            combinedChlorineMsg,
            duration,
            renderCombined,
            'Zodiac Oxy Fresh',
            renderCombinedUnit,
            // renderCombinedUnique,
          ));
        }

        if (checkToGoChemicalGreen(phMsg)) {
          let cost = costPh;
          let duration = '0';
          if (costPh) {
            cost = costPh / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'hydrochloricAcidPh',
            quantityCostPh,
            cost,
            phMsg,
            duration,
            renderPh,
            renderPhName,
            renderPhUnit,
            // renderPhUnique,
          ));
        }
        if (checkToGoChemicalGreen(alkMsg)) {
          let cost = costAlkalinity;
          let duration = '0';
          if (costAlkalinity) {
            cost = costAlkalinity / chemicalsTax;
            duration = '5';
          }

          newChemicalArray.push(chemicalGreen(
            'buffer',
            quantityCostAlk,
            cost,
            alkMsg,
            duration,
            renderAlkalinity,
            renderAlkName,
            renderAlkMsgUnit,
            // renderAlkUnique,
          ));
        }
        if (checkToGoChemicalGreen(calciumHardnessMsg, 'calciumRaiser')) {
          let cost = drainWaterHardness ? 0 : costCalcium;
          let duration = costCalcium ? calciumDrainingHours || '0' : '0';
          if (valueForCalcium && costCalcium && ((drainWaterHardness && maxDrain !== drainWaterHardness) || !drainWaterHardness)) {
            cost = costCalcium / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'calciumRaiser',
            quantityCostCalcium,
            cost,
            calciumHardnessMsg,
            duration,
            renderCalcium,
            renderCalciumName,
            renderCalciumUnit,
            // renderCalciumUnique,
            'calciumRaiser',
          ));
        }
        if (checkToGoChemicalGreen(stabiliserMsg)) {
          let cost = drainWater ? 0 : costStabiliser;
          let duration = costStabiliser
            ? stabiliserDrainingHours || '0'
            : '0';
          if (valueForStabiliser && costStabiliser && ((drainWater && maxDrain !== drainWater) || !drainWater)) {
            cost = costStabiliser / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'stabiliser',
            quantityCostStabiliser,
            cost,
            stabiliserMsg,
            duration,
            renderStabiliser,
            renderStabName,
            renderStabiliserUnit,
            // renderStabUnique,
          ));
        }

        if (checkToGoChemicalGreen(copperMsg)) {
          let duration = '0';
          let cost = drainWaterCopper ? 0 : costCopper;
          if (costCopper) {
            cost = costCopper / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'copper',
            quantityCostCopper,
            cost,
            copperMsg,
            duration,
            renderCopper,
            'Zodiac Metal Reducer',
            renderCopperUnit,
            // 'Copper',
          ));
        }
        if (checkToGoChemicalGreen(phosphateMsg)) {
          let duration = '0';
          let cost = costPhophates;
          if (costPhophates) {
            cost = costPhophates / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'phosphatesRemover',
            quantityCostPhosphates,
            cost,
            phosphateMsg,
            duration,
            renderPhosphate,
            'Zodiac Phosphate Remover',
            renderPhosphateUnit,
            // renderPhosphateUnique,
          ));
        }

        if (checkToGoChemicalGreen(saltMsg)) {
          let cost = drainWaterSalt ? 0 : costSalt;
          let duration = costSalt ? saltDrainingHours || '0' : '0';
          if (addSalt && costSalt && ((drainWaterSalt && maxDrain !== drainWaterSalt) || !drainWaterSalt)) {
            cost = costSalt / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'salt',
            quantityCostSalt,
            cost,
            saltMsg,
            duration,
            renderSalt,
            renderSaltName,
            renderSaltUnit,
            // renderSaltUnique,
          ));
        }
        if (updatedObj?.drainPercent === 0 && withDrainage) {
          newChemicalArray.push(chemicalDrainGreen(
            updatedObj,
            '',
            stripExist,
          ));
        }

        if (
          !GreenBlackStatus
            && checkToGoChemicalGreen(clarifierMsg)
        ) {
          let cost = costClarifierMain;
          let duration = '0';
          if (costClarifierMain) {
            cost = costClarifierMain / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'clarifier',
            quantityCostClarifierMain,
            cost,
            clarifierMsg,
            duration,
            renderClarifier,
            'Zodiac Clarifier',
            renderClarUnits,
            //  'Clarifier',
          ));
        }
        if (!GreenBlackStatus && checkToGoChemicalGreen(flockMsg)) {
          let cost = costFlock;
          let duration = '0';
          if (costFlock) {
            cost = costFlock / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'flock',
            quantityCostFlock,
            cost,
            flockMsg,
            duration,
            renderFlock,
            'Flock - Aluminium Sulphate',
            renderFlockUnits,
            // 'Flock',
          ));
        }
        if (
          !GreenBlackStatus
            && checkToGoChemicalGreen(algaecideMsg)
        ) {
          let cost = costAlgaecide;
          let duration = '0';
          if (costAlgaecide) {
            cost = costAlgaecide / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'algaecide',
            quantityCostAlgaecide,
            cost,
            algaecideMsg,
            duration,
            renderAlgaecide,
            'Zodiac Pool Long Life Algaecide',
            renderAlgaeUnits,
            // 'Algaecide',
          ));
        }
        if (checkToGoChemicalGreen(saltMsgConvert)) {
          let cost = costSaltConvert;
          let duration = '0';
          if (costSaltConvert) {
            cost = costSaltConvert / chemicalsTax;
            duration = '5';
          }
          newChemicalArray.push(chemicalGreen(
            'saltMsgConvert',
            quantityCostSaltConvert,
            cost,
            saltMsgConvert,
            duration,
            renderSaltConvert,
            renderSaltConvertName,
            // renderSaltConvertUnique,
          ));
        }

        copyLevelData[updatedIndex].service = newChemicalArray;
        copyLevelData[updatedIndex].isChem = true;

        if (calciumReducer && checkToGoChemicalGreen(calciumPhReducerMsg)) {
          const fIndex = copyLevelData.findIndex((el) => el?.service?.length === 0);
          const additinalVisitMsg = copyLevelData[fIndex].numericValue;
          // console.log({ additinalVisitMsg });
          copyLevelData[fIndex].sel = 'selected';
          let updateToBlankService = [];

          if (secondVisitCost) {
            let cost = secondVisitCost;
            let duration = secondVisit?.duration;
            let quantityCost = 0.00;
            let msg = `${additinalVisitMsg} Visit Cost`;

            updateToBlankService.push(chemicalGreen(
              'Green Pool Labour Cost',
              quantityCost,
              cost,
              msg,
              duration,
              duration,
              '',
              '',
            ));
          }

          let cost = calciumPhReducerCost;
          let duration = '0';
          if (calciumPhReducerCost) {
            cost = calciumPhReducerCost / chemicalsTax;
            duration = '5';
          }

          updateToBlankService.push(chemicalGreen(
            'hydrochloricAcidPh',
            quantityPhReducerCost,
            cost,
            calciumPhReducerMsg,
            duration,
            renderPhReducer,
            chemicalBalanceWaterObj?.phbalanceName,
            renderPhReducerUnit,
          ));
          copyLevelData[fIndex].service = updateToBlankService;
        }

        // newLevelData[0].service = calculateEndingTimeArr(setLevelData, 0, newLevelData, 'page');
        // newLevelData[1].service = calculateEndingTimeArr(setLevelData, 1, newLevelData, 'page');
        // newLevelData[2].service = calculateEndingTimeArr(setLevelData, 2, newLevelData, 'page');
        // newLevelData[3].service = calculateEndingTimeArr(setLevelData, 3, newLevelData, 'page');

        let finalData = [];

        copyLevelData.forEach((ele, index) => {
          if (ele?.service?.length > 0) {
            ele.service = calculateEndingTimeArr('', index, copyLevelData, 'page');
            ele.service.forEach((el) => {
              el.staffName = staffDetails.staffName;
              el.staffId = staffDetails.staffId;
              if (el.type === 'product') {
                el.originalQuantity = el.quantity;
              }
            });
            finalData.push(ele);
          }
        });

        // console.log({ finalData, copyLevelData });
        setLevelData(finalData);
        // setLevelData(JSON.parse(JSON.stringify(newLevelData)));
        setChemicalArray(JSON.parse(JSON.stringify(copyLevelData)));
        setPreviousCalcium({ ...previousCalciumData });

        setCalVariable({
          ...calVariable,
          chlorine,
          granularChlorine,
          sodiumThiosulphate,
          sodaAsh,
          hydrochloricAcidPh,
          buffer,
          hydrochloricAcidAlk,
          addStabiliser,
          drainWater,
          calciumRaiser,
          drainWaterHardness,
          addSalt,
          drainWaterSalt,
          costChroline,
          costPh,
          costAlkalinity,
          costStabiliser,
          costCalcium,
          costSalt,
          totalCost,
          quantityCostForChlorine,
          quantityCostPh,
          quantityCostAlk,
          quantityCostStabiliser,
          quantityCostCalcium,
          quantityCostSalt,
          profitChrolone,
          profitPh,
          profitAlkalinity,
          profitStabiliser,
          profitCalciumHardness,
          profitSalt,
          maxDrain,
          ph,
          quantityCostSaltConvert,
          costSaltConvert,
          profitSaltConvert,
          convertPool,
          manualAquaValue,
          withDrainage,
          balancing,
          diffStabliser,
          diffCalcium,
          diffChlorine,
          diffSalt,
          phosphateRemover,
          costPhophates,
          quantityCostPhosphates,
          phosphateMsg,
          stabiliserMsg,
          calciumHardnessMsg,
          saltMsg,
          isDrainage,
          zodiacOxy,
          costCombinedChlorine,
          quantityCostCombined,
          positiveSalt,
          negativeSalt,
          quantityCostAlgaecide,
          sellPriceAlgaecide,
          costAlgaecide,
          quantityCostClarifierMain,
          sellPriceClarifierMain,
          costClarifierMain,
          quantityCostClarifierGreen,
          sellPriceClarifierGreen,
          costClarifierGreen,
          quantityCostFlock,
          sellPriceFlock,
          costFlock,
          flockQuantity,
          clarifierGreenPoolQuantity,
          clarifierMaintainenceQuantity,
          algaecideQuantity,
          greenCost,
          greenLabourCost,
          greenVaccumCost,
          greenQuantity,
          drainWaterChorine,
          drainPercent,
          combinedChlorineDrainingHours,
          copperDrainingHours,
          stabiliserDrainingHours,
          calciumDrainingHours,
          saltDrainingHours,
          combinedChlorineMsg,
          copperQuantity,
          quantityCostCopper,
          costCopper,
          copperMsg,
          drainWaterCopper,
          costDrainPercentWaterLevelLowered,
          drainPercentWaterLevelLoweredHours,
          chlorineMsg,
          phMsg,
          alkMsg,
          saltMsgConvert,
          secondSaltMsgConvert,
          clarifierMsg,
          algaecideMsg,
          flockMsg,
          calciumHardness,
        });

        setPoolInputs({
          ...copyPoolInputs,
          poolCapacity,
          poolWaterCapacity,
          newCombinedChlr: newCombinedChrlone.toFixed(2),
        });

        setMultiVisit(false);
        if (
          (serviceAnalysis === 'Green Pool' || serviceAnalysis === 'Drain Pool' || serviceAnalysis === 'Black-Spot Algae'
          || serviceAnalysis === 'Sand Filter Media Replacement') && greenSplitName !== 'Part') {
          setStatus(false);
          if (!withDrainage) {
            // if (serviceAnalysis === 'Green Pool' || serviceAnalysis === 'Black-Spot Algae') {
            setMultiVisit(true);
            onGreenCaseEditChemicals(copyLevelData);
            // }
          }
        }

        if (calciumReducer) {
          setGetZodiacStatus(true);
        }

        if (serviceAnalysis !== 'Pool Water Volume Verification via Salinity') {
          setResult(true);
        }
        setWaterPoolStatus(true);
      }
    }
  };

  const handlePoolDepth = (e, parseDataPoolDepth) => {
    const value = e ? e.target.value : parseDataPoolDepth;
    const copyGlObj = { ...globj };
    let oneState = false;
    if (
      value === 'Specific Depths - Rectangular Pool'
      || value === 'Specific Depths - Circular Pool'
    ) {
      setCustomDepths(true);
    } else {
      setCustomDepths(false);
    }
    if (
      value === 'Average Depth - Circular pool'
      || value === 'Specific Depths - Circular Pool'
    ) {
      oneState = true;
      setDiameter(true);
    } else {
      setDiameter(false);
    }
    if (e) {
      let drainAmount = 0;
      if (serviceAnalysis === 'Drain Pool' && greenSplitName === 'Part') {
        drainAmount = drainWaterLevelCalci(oneState);
      } else {
        drainAmount = 0;
      }
      if (value === 'Average Depth - Rectangular Pool') {
        if (copyGlObj.glbVariable.glwidth && copyGlObj.glbVariable.glheight) {
          copyGlObj.avgRectangular.poolSize = copyGlObj.glbVariable.glwidth * copyGlObj.glbVariable.glheight * 1250;
          copyGlObj.avgRectangular.poolSize = Math.floor(parseInt(copyGlObj.avgRectangular.poolSize, 10) / 1000) * 1000;
          setPoolInputs({
            ...poolInputs,
            poolCapacity: copyGlObj.avgRectangular.poolSize,
            drainPercentWaterLevelLowered: drainAmount,

          });
        } else {
          setPoolInputs({ ...poolInputs, poolCapacity: 0, drainPercentWaterLevelLowered: drainAmount });
        }
      } else if (value === 'Specific Depths - Circular Pool') {
        if (
          copyGlObj.glbVariable.glshallowEnd
          || (copyGlObj.glbVariable.gldeepEnd && copyGlObj.glbVariable.gldiameter)
        ) {
          const shallowCalculation = (copyGlObj.glbVariable.glshallowEnd - copyGlObj.glbVariable.gldeepEnd) / 2
            + copyGlObj.glbVariable.gldeepEnd;
          copyGlObj.spcCircular.poolSize = copyGlObj.glbVariable.gldiameter
            * copyGlObj.glbVariable.gldiameter
            * shallowCalculation
            * 780;
          copyGlObj.spcCircular.poolSize = Math.floor(parseInt(copyGlObj.spcCircular.poolSize, 10) / 1000) * 1000;
          setPoolInputs({
            ...poolInputs,
            poolCapacity: copyGlObj.spcCircular.poolSize,
            drainPercentWaterLevelLowered: drainAmount,
          });
        } else {
          setPoolInputs({ ...poolInputs, poolCapacity: 0, drainPercentWaterLevelLowered: drainAmount });
        }
      } else if (value === 'Average Depth - Circular pool') {
        if (copyGlObj.glbVariable.gldiameter) {
          copyGlObj.avgCircular.poolSize = copyGlObj.glbVariable.gldiameter
            * copyGlObj.glbVariable.gldiameter
            * 1250
            * 0.78;
          copyGlObj.avgCircular.poolSize = Math.floor(parseInt(copyGlObj.avgCircular.poolSize, 10) / 1000) * 1000;
          setPoolInputs({
            ...poolInputs,
            poolCapacity: copyGlObj.avgCircular.poolSize,
            drainPercentWaterLevelLowered: drainAmount,
          });
        } else {
          setPoolInputs({ ...poolInputs, poolCapacity: 0, drainPercentWaterLevelLowered: drainAmount });
        }
      } else if (value === 'Specific Depths - Rectangular Pool') {
        if (
          (copyGlObj.glbVariable.glheight && copyGlObj.glbVariable.glwidth)
          || copyGlObj.glbVariable.glshallowEnd
          || copyGlObj.glbVariable.gldeepEnd
        ) {
          const shallowCalculation = (copyGlObj.glbVariable.glshallowEnd - copyGlObj.glbVariable.gldeepEnd) / 2
            + copyGlObj.glbVariable.gldeepEnd;
          copyGlObj.spcRectangular.poolSize = copyGlObj.glbVariable.glwidth
            * copyGlObj.glbVariable.glheight
            * shallowCalculation
            * 1000;
          copyGlObj.spcRectangular.poolSize = Math.floor(parseInt(copyGlObj.spcRectangular.poolSize, 10) / 1000) * 1000;
          setPoolInputs({
            ...poolInputs,
            poolCapacity: copyGlObj.spcRectangular.poolSize,
            drainPercentWaterLevelLowered: drainAmount,
          });
        } else {
          setPoolInputs({ ...poolInputs, poolCapacity: 0, drainPercentWaterLevelLowered: drainAmount });
        }
      }
      setGlObj(copyGlObj);
    }
    setPoolDepth(value);
  };

  const drainWaterLevelCalci = (inputedDiameter) => {
    let {
      waterLevelLowered, poolHeight, poolWidth, diameter, poolCapacity,
    } = poolInputs;
    let drainAmount = 0;
    if (waterLevelLowered) {
      let valueHeight = inputedDiameter ? diameter : poolHeight;
      let valueWidth = inputedDiameter ? diameter : poolWidth;
      if (valueHeight && valueWidth) {
        drainAmount = getDrainWaterLevelPercent(waterLevelLowered, valueWidth,
          valueHeight, poolCapacity);
      }
    } else {
      drainAmount = 0;
    }
    return drainAmount;
  };

  const handleServiceAnalysis = (
    e,
    parseDataServiceAnalysis,
    greenParseData,
  ) => {
    const value = e ? e.target.value : parseDataServiceAnalysis;
    setServiceAnalysis(value);
    if (e) {
      if (value === 'Green Pool' || value === 'Drain Pool') {
        let setValue = value === 'Green Pool' ? 'Liquid' : 'Drain';
        if (setValue === 'Drain' && chlorinationType === 'Calcium Hardness Titration Test') {
          setChlorinationType('Mineral Pool');
        }
        if (setValue === 'Drain' && manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
          setManualValue('Aquatherepe Transform Maintenance');
        }
      }
    }

    let copyInputs = { ...poolInputs };
    let drainAmount = 0;
    if (e) {
      if (e.target.value === 'Drain Pool' && greenSplitName === 'Part') {
        drainAmount = drainWaterLevelCalci(diameterInput);
      } else {
        drainAmount = 0;
      }
      copyInputs.drainPercentWaterLevelLowered = drainAmount;
    }

    if (e && greenSplitName === 'Drain' || greenSplitName === 'Part' || (value === 'Drain Pool' && poolFinish.includes('Vinyl'))) {
      copyInputs = { ...oldCopyPoolInputs };
      copyInputs.drainPercentWaterLevelLowered = drainAmount;
    }
    setGreenTreatment('Liquid Chlorine');
    if (value === 'Green Pool' || value === 'Drain Pool') {
      let setValue = value === 'Green Pool' ? 'Liquid' : 'Drain';
      setGreenSplitName(setValue);
      if (setValue === 'Drain' && !poolFinish.includes('Vinyl')) {
        if (e) {
          copyInputs = { ...copyInputs, ...initialSoureInputs };
        }
        setGreenTreatment('Drain Only');
      } else if (value === 'Drain Pool' && poolFinish.includes('Vinyl')) {
        if (e) {
          drainAmount = drainWaterLevelCalci(diameterInput);
          copyInputs.drainPercentWaterLevelLowered = drainAmount;
        }

        setGreenSplitName('Part');
        setGreenTreatment('Part -Drain Pool');
      }
    } else {
      setGreenSplitName('');
    }
    if (e) {
      setPoolInputs({ ...copyInputs });
    }
    if (value === 'Pool Water Volume Verification via Salinity') {
      setWaterPoolStatus(false);
      setBackStatus(false);
    } else {
      setWaterPoolStatus(true);
      if (e) {
        if ((value !== 'Drain Pool') && poolInputs?.BoxCount > 0) {
          setBackStatus(true);
        }
        if (value === 'Drain Pool') {
          setBackStatus(false);
        }
      }
    }
    if (value !== 'Water Loss Calculator') {
      setWaterLossStatus(false);
      setShowModel(true);
    } else {
      setBackStatus(false);
      setShowModel(false);
    }

    if (value === 'Black-Spot Algae') {
      setGreenTreatment('Black-Spot Remover');
    }
    setWaterLossStatus(false);
    setInverterResult(false);
    setWaterSourceSelect('');
  };

  const handleWaterTestOptions = (e, parseDataWaterTestOptions) => {
    const value = e ? e.target.value : parseDataWaterTestOptions;
    setWaterTestOptions(value);
  };
  const handleWaterLossCalculator = (e, parseDataWaterLossCalculator) => {
    const value = e ? e.target.value : parseDataWaterLossCalculator;
    setWaterLossCalculator(value);
  };
  const handleGreenChange = (eName, parseDataGreenSelect) => {
    const name = eName || parseDataGreenSelect;

    setGreenSelect(name);
  };

  const handleGreenPoolTreatments = (e, parseDataGreenTreatment) => {
    const value = e ? e.target.value : parseDataGreenTreatment;
    let splitData = value.trim().split(' ');
    let drainAmount = 0;
    let copyInputs = { ...poolInputs };
    if (e) {
      if (serviceAnalysis === 'Drain Pool' && splitData[0] === 'Part') {
        drainAmount = drainWaterLevelCalci(diameterInput);
      } else {
        drainAmount = 0;
      }
      copyInputs.drainPercentWaterLevelLowered = drainAmount;
    }

    if (greenSplitName === 'Drain' && e) {
      copyInputs = { ...oldCopyPoolInputs };
      copyInputs.drainPercentWaterLevelLowered = drainAmount;
    }
    if (splitData[0] === 'Drain') {
      setGreenSelect('green1');
      if (e) {
        copyInputs = { ...copyInputs, ...initialSoureInputs };
        setTakingPh(initialSoureInputs.ph);
        setBackStatus(false);
      }
    }
    if (e) {
      if (splitData[0] === 'Part' && poolInputs?.BoxCount > 0) {
        setBackStatus(true);
      }
      setPoolInputs({ ...copyInputs });
    }
    setGreenSplitName(splitData[0]);
    setGreenTreatment(value);
  };
  // Black Functionalities
  const handleBlackTreatment = (e, parseDataBlackTreatment) => {
    const value = e ? e.target.value : parseDataBlackTreatment;
    setBlackTreatment(value);
    if (value) {
      let splitData = value.trim().split(' ');
      setBlackSplitName(splitData[0]);
    }
  };
  const handleBlackChange = (eName, parseDataBlackSelect) => {
    const name = eName || parseDataBlackSelect;
    setBlackSelect(name);
  };

  const handlePoolFinish = (e, parsePoolFinish, parseGreenSplitName) => {
    const value = e ? e.target.value : parsePoolFinish;
    const {
      stabiliser, ph, calciumHardness, drainPointcalciumHardness, calcNegative, calcPositive,
    } = checkForPoolFinish(value, showModel);
    setPoolFinish(value);
    setCalVariable({
      ...calVariable,
      ph,
      stabiliser,
      calciumHardness,
      calcNegative,
      calcPositive,
      drainPointcalciumHardness,
    });
    const greenSplit = greenSplitName || parseGreenSplitName;
    if (value) {
      if (serviceAnalysis === 'Drain Pool' && value.includes('Vinyl')) {
        setGreenSplitName('Part');
        setGreenTreatment('Part -Drain Pool');
      }
      if (serviceAnalysis === 'Black-Spot Algae' && (value.includes('Vinyl') || value.includes('Fibreglass'))) {
        setServiceAnalysis('Water Test');
        setWaterTestOptions('Water Test Only');
      }
    }
    if (
      greenSplit === 'Drain'
      && (value === 'Vinyl Liner Pool - Indoor'
        || value === 'Vinyl Liner Pool - Outdoor')
    ) {
      setPoolInputs({ ...poolInputs, ...defaultChemicalState });
      setGreenSplitName('');
      setServiceAnalysis('Water Test');
    }
  };
  const checkForPoolFinish = (value, modalVal) => {
    let ph = calVariable.phConcreteTilted;
    let calciumHardness = calVariable.calciumHardnessConcrete;
    let drainPointcalciumHardness = calVariable.drainPointcalciumHardnessConcrete;
    let calcNegative = calVariable.hardContNegative;
    let calcPositive = calVariable.hardContPositive;

    if (chemicalBalanceWaterObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
      calciumHardness = calVariable?.calciumHardnessReduceConcrete;
      drainPointcalciumHardness = calVariable?.drainPointcalciumHardnessReduceConcrete;
      calcPositive = calVariable?.calcreduContPositive;
      calcNegative = calVariable?.calcreduContNegative;
    }
    let { stabiliser, stabiliserCya, stabiliserOrp } = calVariable;
    if (
      value === 'Fibreglass Pool - Outdoor'
      || value === 'Fibreglass Pool - Indoor'
    ) {
      ph = calVariable.phfibre;
      calciumHardness = calVariable.calciumHardnessFibre;
      drainPointcalciumHardness = calVariable.drainPointcalciumHardnessFibre;
      calcNegative = calVariable.hardFibreNegative;
      calcPositive = calVariable.hardFibrePositive;
      if (chemicalBalanceWaterObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
        calciumHardness = calVariable?.calciumHardnessReduceFibre;
        drainPointcalciumHardness = calVariable?.drainPointcalciumHardnessReduceFibre;
        calcPositive = calVariable?.calcreduFibrePositive;
        calcNegative = calVariable?.calcreduFibreNegative;
      }
    } else if (
      value === 'Vinyl Liner Pool - Outdoor'
      || value === 'Vinyl Liner Pool - Indoor'
    ) {
      ph = calVariable.phliner;
      calciumHardness = calVariable.calciumHardnessVinyl;
      drainPointcalciumHardness = calVariable.drainPointcalciumHardnessVinyl;
      calcNegative = calVariable.hardVinylNegative;
      calcPositive = calVariable.hardVinylPositive;
      if (chemicalBalanceWaterObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
        calciumHardness = calVariable?.calciumHardnessReduceVinyl;
        drainPointcalciumHardness = calVariable?.drainPointcalciumHardnessReduceVinyl;
        calcPositive = calVariable?.calcreduVinylPositive;
        calcNegative = calVariable?.calcreduVinylNegative;
      }
    }

    if (value.includes('Indoor')) {
      stabiliser = 0;
    } else if (selectedModel && !selectedModel.toString().includes('ORP Probe')) {
      stabiliser = stabState !== '' ? stabState : stabiliserCya;
    } else if (selectedModel && selectedModel.toString().includes('ORP Probe') && modalVal) {
      stabiliser = stabState !== '' ? stabState : stabiliserOrp;
    } else {
      stabiliser = stabiliserCya;
    }
    return {
      stabiliser,
      ph,
      calciumHardness,
      drainPointcalciumHardness,
      calcNegative,
      calcPositive,
      // calciumHardnessReduce,
      // drainPointcalciumHardnessReduce,
      // calcreduPositive,
      // calcreduNegative,
    };
  };

  const handleChlorination = (e, parseChlorinationType, parseChlorineModel, parseMineralValue,
    parseDataManualValues, parseGetValue) => {
    setStabState('');
    const value = e ? e.target.value : parseChlorinationType;
    let val = value;

    let mineralVal = e ? mineralValue : parseMineralValue;
    let chlorinationModelArr = chlorineNewmodel;
    let newChlorineModel = selectedModel;

    const objToUpdate = { ...calVariable };

    // mineral changes
    if (val === 'Calcium Hardness Titration Test') {
      val = 'Mineral Pool';
      // mineral changes
      setShowFullHardness({ ...initalShowFullHardness, calcium: true });
      if (e) {
        setPoolInputs((prev) => ({ ...prev, hardCalcium: '', hardMagnesium: '' }));
      }
      objToUpdate.calciumHardness = 860;
    } else if (!showFullHardness?.fullhardness) {
      setShowFullHardness({ ...initalShowFullHardness });
      if (e) {
        setPoolInputs((prev) => ({ ...prev, hardCalcium: '', hardMagnesium: '' }));
      }
      objToUpdate.calciumHardness = calciumHardnessIdeal;
    }

    if (val === 'Mineral Pool') {
      chlorinationModelArr = mineralModel || parseChlorineModel;
      setGetValue('Magna Minerals');
      const dataBalance = mineralVal[0];
      const salt = parseFloat(dataBalance.saltLevel);
      let stabiliser;
      if (dataBalance) {
        stabiliser = dataBalance.stabiliserLevel ? parseFloat(dataBalance.stabiliserLevel) : 40;
        objToUpdate.stabiliser = stabiliser;
        // objToUpdate.calciumHardness = getUpdatedCalcium(magnaPoolAdjust, calciumHardnessState.old);
        objToUpdate.salt = salt;
        setSelectedModelObj(dataBalance);
        setSelectedModel(dataBalance.brandName);
        setmagnaPoolAdjust(parseFloat(dataBalance.magnesium));
        setCalciumPoolAdjust(parseFloat(dataBalance.calcium));
      }

      setManualValue('');
    } else if (val === 'Convert Salt Pool to Mineral Pool') {
      chlorinationModelArr = convertPoolChlorine;
      newChlorineModel = 4000;
      setGetValue('Aquatherepe Transform');
      setSelectedModel('Aquatherepe Transform');
      setManualValue('');
    } else if (val === 'Salt Pool') {
      newChlorineModel = chlorineNewmodel.length ? chlorineNewmodel[0].saltLevel : parseChlorineModel[0].saltLevel;
      setManualValue('');
      if (chlorineNewmodel && chlorineNewmodel.length > 0) {
        setSelectedModelObj(chlorineNewmodel[0]);
        setGetValue(chlorineNewmodel[0]?.name);
        setSelectedModel(chlorineNewmodel[0]?.name);
      }
    } else if (val === 'Manually Chlorinated') {
      chlorinationModelArr = poolTypeArray;
      setSelectedModel('Non-Mineral Pool');
      setManualValue('Non-Mineral Pool');
    }

    setCalVariable(objToUpdate);
    if (e) {
      setChlorineModel(chlorinationModelArr);
    }
    setChlorinationType(value);

    if (val === 'Manually Chlorinated') {
      setShowModel(false);
      const dataManual = e ? 'Non-Mineral Pool' : parseDataManualValues;
      setManualValue(dataManual);
      const { ph } = checkForPoolFinish(poolFinish, false);
      objToUpdate.ph = ph;
      objToUpdate.stabiliser = 40;
    } else {
      setShowModel(true);
    }

    if (val !== 'Mineral Pool' && (parseGetValue !== 'Astral Pool'
        && parseGetValue !== 'Aquatherepe Transform'
        && parseGetValue !== 'Magna Minerals')
        && parseChlorinationType !== 'Manually Chlorinated'
    ) {
      const { salt, stabiliser } = checkChlorinationModel(newChlorineModel);
      objToUpdate.salt = salt;
      objToUpdate.stabiliser = val === 'Manually Chlorinated' ? 40 : stabiliser;
    }

    const { ph: phFinish, stabiliser: stabFinish } = checkForPoolFinish(poolFinish, false);
    objToUpdate.ph = phFinish;
    objToUpdate.stabiliser = stabFinish;

    setCalVariable({ ...objToUpdate });
  };

  const handleManualValues = (e, parseManualValue, parseMineralValue) => {
    const value = e ? e.target.value : parseManualValue;
    let splitData = value.split(' ');
    let mineralVal = e ? mineralValue : parseMineralValue;

    let val = value;
    const copyCal = { ...calVariable };

    if (val === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
      // mineral changes
      setShowFullHardness({ ...initalShowFullHardness, calcium: true });
      if (e) {
        setPoolInputs((prev) => ({ ...prev, hardCalcium: '', hardMagnesium: '' }));
      }
      copyCal.calciumHardness = 860;
    } else if (!showFullHardness?.fullhardness) {
      setShowFullHardness({ ...initalShowFullHardness });
      if (e) {
        setPoolInputs((prev) => ({ ...prev, hardCalcium: '', hardMagnesium: '' }));
      }
      copyCal.calciumHardness = calciumHardnessIdeal;
    }

    const dataBalance = mineralVal.filter((el) => el.brandName.includes(splitData[0]));
    if (dataBalance.length !== 0) {
      setmagnaPoolAdjust(parseFloat(dataBalance[0].magnesium));
    }

    setCalVariable({ ...copyCal });
    setManualValue(value);
    setSelectedModel(value);
    setGetValue(value);
  };

  const handleChlorinationModel = (e, parseSelectedModel, parseGetValue, parseChlorinationType) => {
    const value = e ? e.target.value : parseGetValue;
    const type = e ? chlorinationType : parseChlorinationType;

    const copyCal = { ...calVariable };
    if (type === 'Salt Pool') {
      if (parseGetValue !== 'Astral Pool') {
        let { salt, stabiliser } = checkChlorinationDropModel(value);
        copyCal.salt = salt;
        copyCal.stabiliser = stabiliser;
      }
      // setSelectedModel(fetchData);
    } else if (type === 'Mineral Pool' || type === 'Calcium Hardness Titration Test') {
      if (parseGetValue !== 'Magna Minerals') {
        let { salt, stabiliser } = checkChlorinationMineralModel(value);
        copyCal.salt = salt;
        copyCal.stabiliser = stabiliser;
      }
    } else if (type !== 'Mineral Pool' || type !== 'Salt Pool' || type !== 'Calcium Hardness Titration Test') {
      if (parseGetValue !== 'Aquatherepe Transform') {
        let { salt, stabiliser } = checkChlorinationModel(value, 4000);
        copyCal.salt = salt;
        copyCal.stabiliser = stabiliser;
      }
    }

    setSelectedModel(value);
    setGetValue(value);
    setCalVariable(copyCal);
  };

  const checkChlorinationMineralModel = (valu) => {
    let { stabiliser, salt, stabiliserCya } = calVariable;
    const f = chlorineModel.find((el) => el.brandName === valu);
    setSelectedModelObj(f);

    if (f) {
      let valueData = f.saltLevel ? parseFloat(f.saltLevel) : 4000;
      const titleData = f.brandName;
      const saltVal = f.saltLevel ? parseFloat(f.saltLevel) : 4000;
      const negative = parseFloat(f.okNegative);
      const positive = parseFloat(f.okPositive);
      const stabLevel = parseFloat(f.stabiliserLevel);
      const pointDrain = parseFloat(f.drainPoint);
      const magnesium = parseFloat(f.magnesium);

      setMagnaSaltPositive(positive);
      setMagnaSaltNegative(negative);
      setMagnaSaltValue(saltVal);
      setMagnaDrainPoint(pointDrain);
      setmagnaPoolAdjust(magnesium);
      valueData = valueData.toString();
      if (!poolFinish.includes('Indoor')) {
        if (
          titleData.includes('ORP')
        || (stabLevel !== null && stabLevel !== '' && stabLevel)
        ) {
          stabiliser = stabLevel;
          setStabState(stabLevel);
        } else {
          stabiliser = stabiliserCya;
          setStabState('');
        }
      }
      salt = valueData;
    }
    return {
      salt: parseInt(salt, 10),
      stabiliser,

    };
  };

  const checkChlorinationDropModel = (valu) => {
    const f = chlorineModel.find((el) => el.name === valu);
    setSelectedModelObj(f);

    let { stabiliser, salt, stabiliserCya } = calVariable;

    if (f) {
      const titleData = f.name;
      const saltVal = parseFloat(f.saltLevel);
      const negative = parseFloat(f.okNegative);
      const positive = parseFloat(f.okNegative);
      const stabLevel = parseFloat(f.recmndStabiliserLvl);
      const pointDrain = parseFloat(f.drainPoint);

      setSaltPositive(positive);
      setSaltNegative(negative);
      setSaltValue(saltVal);
      setDrainPoint(pointDrain);

      const valueData = f.saltLevel.toString();
      if (!poolFinish.includes('Indoor')) {
        if (
          titleData.includes('ORP')
        || (stabLevel !== null && stabLevel !== '' && stabLevel)
        ) {
          stabiliser = stabLevel;
          setStabState(stabLevel);
        } else {
          stabiliser = stabiliserCya;
          setStabState('');
        }
      }
      salt = valueData;
    }
    return {
      salt: parseInt(salt, 10),
      stabiliser,
    };
  };

  const checkChlorinationModel = (value, saltQuant) => {
    let { stabiliser, salt } = calVariable;
    value = value.toString();
    if (!poolFinish.includes('Indoor')) {
      if (value.includes('ORP Probe')) {
        stabiliser = stabState !== '' ? stabState : 20;
      } else {
        stabiliser = 40;
      }
    }
    salt = saltQuant || value;
    return { salt: parseInt(salt, 10), stabiliser };
  };

  const handleWaterLevelChange = (e, parseDataSelectedWaterlevel) => {
    if (e) {
      setSelectedWaterLevel(e.target.value);
    } else {
      setSelectedWaterLevel(parseDataSelectedWaterlevel);
    }
  };
  const handleAmountWaterLevel = (e, parseDataSelectedWaterLevelAmount) => {
    if (e) {
      setSelectedWaterLevelAmount(e.target.value);
    } else {
      setSelectedWaterLevelAmount(parseDataSelectedWaterLevelAmount);
    }
  };

  const getStaffNameForCalculator = async () => {
    let staffId = localStorage.getItem('staff_id') ? localStorage.getItem('staff_id') : '';
    let staffName = localStorage.getItem('staff_name') ? localStorage.getItem('staff_name') : ' ';
    if (staffId && staffName) {
      setStaffDetails({ ...staffDetails, staffName, staffId });
    } else {
      const res = await userInstance().get('/staff/getUpdatedStaffData');
      if (res.data.staffData.length) {
        const { staffData } = res.data;
        staffName = `${staffData[0].firstname} ${staffData[0].lastname}`;
        staffId = `${staffData[0]._id}`;
        setStaffDetails({ ...staffDetails, staffName, staffId });
      }
    }
  };

  function getData(mineralList) {
    try {
      if (mineralList) {
        let saveData = mineralList?.chlorinatorModels;
        setChlorineNewmodel(saveData);
        const ch = mineralList?.chlorinatorModels[0];

        let clientChlorType = Id ? (clientAllDetails?.chlorinationType || waterObj?.watHis?.chlorinationType) : '';

        if (Id && !clientChlorType) {
          setSelectedModel(ch.name);
          setSelectedModelObj(ch);
        }

        const data = localStorage.getItem('calculatorObject');
        if (data || clientChlorType) {
          const parseData = JSON.parse(data);
          if (clientChlorType === 'Mineral Pool with Chlorinator' || clientChlorType === 'Calcium Hardness Titration Test') {
            clientChlorType = 'Mineral Pool';
          }
          let upParseData = Id ? clientChlorType || chlorinationType : parseData.chlorinationType;

          upParseData = upParseData || chlorinationType;

          if (upParseData === 'Mineral Pool' || upParseData === 'Calcium Hardness Titration Test') {
            saveData = mineralList?.calciumAdjustments;
          } else if (upParseData === 'Convert Salt Pool to Mineral Pool') {
            saveData = convertPoolChlorine;
          }
        }

        setChlorineModel(saveData);

        let okNegative = parseFloat(ch.okNegative);
        let okPositive = parseFloat(ch.okPositive);
        let saltlevel = parseFloat(ch.saltLevel);
        let drainlevel = parseFloat(ch.drainPoint);

        if (Id && clientChlorType === 'Salt Pool') {
          const hisSelectedModel = clientAllDetails?.selectedModel || waterObj?.watHis?.selectedModel;

          if (hisSelectedModel) {
            const f = saveData.find((ele) => (ele.brandName || ele.name) === hisSelectedModel);

            okNegative = parseFloat(f?.okNegative);
            okPositive = parseFloat(f?.okPositive);
            saltlevel = parseFloat(f?.saltLevel);
            drainlevel = parseFloat(f?.drainPoint);
          }
        }

        const settingMagnaPools = mineralList?.calciumAdjustments;
        const mgPoolBefore = mineralList?.calciumAdjustments[0];
        let poolAdjustMineral = parseFloat(settingMagnaPools[0].magnesium);
        const secondVisitData = mineralList?.secondVisitCost;

        setMineralModel(mineralList?.calciumAdjustments);
        setPoolFilterData(mineralList?.sandFilterMedia);

        setMineralValue(settingMagnaPools);
        let mgPool = mgPoolBefore;

        if (Id && clientChlorType === 'Mineral Pool') {
          const hisSelectedModel = clientAllDetails?.selectedModel || waterObj?.watHis?.selectedModel;
          if (hisSelectedModel) {
            const f = saveData.find((ele) => (ele.brandName || ele.name) === hisSelectedModel);
            mgPool = f;
            poolAdjustMineral = parseFloat(f.magnesium);
          }
        }

        setmagnaPoolAdjust(poolAdjustMineral);

        let magnaCalcium = parseFloat(mgPool.calcium);
        let magnaOkNegative = parseFloat(mgPool.okNegative);
        let magnaOkPositive = parseFloat(mgPool.okPositive);
        let magnaDrainpoint = parseFloat(mgPool.drainPoint);
        let magnaRecmndStabiliserLvl = parseFloat(mgPool.stabiliserLevel);
        let magnaSaltLevel = parseFloat(mgPool.saltLevel);
        let magnaEndTime = parseFloat(mgPool.endTime);
        let magnaStartTime = parseFloat(mgPool.startTime);

        setSecondVisit(secondVisitData);
        setSaltPositive(okPositive);
        setSaltNegative(okNegative);
        setSaltValue(saltlevel);
        setDrainPoint(drainlevel);
        setMagnaSaltPositive(magnaOkPositive);
        setMagnaStabiliser(magnaRecmndStabiliserLvl);
        setMagnaSaltNegative(magnaOkNegative);
        setMagnaSaltValue(magnaSaltLevel);
        setMagnaDrainPoint(magnaDrainpoint);

        // await getLatHistory(Id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // const grabLevelDataFromInitialArrays = () => {
  //   const oldLevelData = [ArrayGreen, ArrayGreen, ArrayGreen, fourthVisit];
  //   const oldCopy = [...oldLevelData];
  //   const copyLevelData = [...levelData];
  //   oldCopy.forEach((el, index) => {
  //     const copyEl = [...el];
  //     copyEl.forEach((elw, i) => {
  //       const copElw = { ...elw };
  //       copyLevelData[index].service[i] = copElw;
  //       copyLevelData[index].service[0].startTime = nextStartTime || '9:30';
  //       copyLevelData[index].sel = '';
  //     });
  //   });

  //   setLevelData(copyLevelData);
  // };

  // useEffect(async () => {
  //   if (!editFalseStatus) {

  //     // await getData();
  //   }
  // }, []);

  useEffect(() => {
    let copyGlObj = { ...globj };
    let poolMsg = '';
    let {
      poolWidth,
      poolHeight,
      shallowEnd,
      deepEnd,
      poolCapacity,
      poolWaterCapacity,
      diameter,
    } = poolInputs;

    if (!poolWidth) {
      poolWidth = 0;
    }
    if (!poolHeight) {
      poolHeight = 0;
    }
    if (!shallowEnd) {
      shallowEnd = 0;
    }
    if (!deepEnd) {
      deepEnd = 0;
    }
    if (!diameter) {
      diameter = 0;
    }

    poolWidth = parseFloat(poolWidth);
    poolHeight = parseFloat(poolHeight);
    shallowEnd = parseFloat(shallowEnd);
    deepEnd = parseFloat(deepEnd);
    diameter = parseFloat(diameter);

    if (poolWidth
    || poolHeight
    || shallowEnd
    || deepEnd
    || diameter) {
      copyGlObj.glbVariable.gldiameter = diameter;
      copyGlObj.glbVariable.glwidth = poolWidth;
      copyGlObj.glbVariable.glheight = poolHeight;
      copyGlObj.glbVariable.gldeepEnd = deepEnd;
      copyGlObj.glbVariable.glshallowEnd = shallowEnd;

      if (poolDepth === 'Average Depth - Rectangular Pool') {
        const value = valueType ? 1250 : (5.5 * 7.48);
        poolCapacity = poolWidth * poolHeight * value;
      } else if (poolDepth === 'Average Depth - Circular pool') {
        const value = valueType ? (1250 * 0.78) : (5.5 * 7.48);
        poolCapacity = diameter * diameter * value;
      } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
        const value = valueType ? 1000 : (5.5 * 7.48);
        const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
        poolCapacity = poolWidth * poolHeight * shallowCalculation * value;
      } else if (poolDepth === 'Specific Depths - Circular Pool') {
        const value = valueType ? 780 : (5.5 * 7.48);
        const shallowCalculation = (shallowEnd - deepEnd) / 2 + deepEnd;
        poolCapacity = diameter * diameter * shallowCalculation * value;
      }
    }
    poolCapacity = valueType ? Math.floor(parseInt(poolCapacity, 10) / 1000) * 1000 : Math.round(parseInt(poolCapacity, 10) / 100) * 100;
    const exceedLimitValue = valueType ? 2500000 : 660000;
    if (parseInt(poolCapacity, 10) >= exceedLimitValue) {
      poolMsg = 'Exceeded Limit';
    }

    if (poolDepth === 'Average Depth - Rectangular Pool') {
      copyGlObj.avgRectangular.poolSize = poolCapacity;
    } else if (poolDepth === 'Average Depth - Circular pool') {
      copyGlObj.avgCircular.poolSize = poolCapacity;
    } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
      copyGlObj.spcRectangular.poolSize = poolCapacity;
    } else if (poolDepth === 'Specific Depths - Circular Pool') {
      copyGlObj.spcCircular.poolSize = poolCapacity;
    }

    setGlObj(copyGlObj);
    poolInputs.poolCapacity = poolCapacity;
    if (!Id) {
      calVariable.poolMsg = poolMsg;
    }
  }, [poolInputs, calVariable]);

  const getIdeals = async (typeres, displayType) => {
    try {
      const newresponse = await userInstance().get(
        '/poolmineral/getAllPoolMinerals',
      );
      const mineralList = newresponse?.data?.mineralList;

      // console.log('mineralIst', mineralList);

      if (mineralList) {
        if (!displayType && !typeres) {
          getData(mineralList);
          if (Id) {
            await getLatHistory(Id);
          }
        }

        // console.log({ mineralList });

        const newIdealData = mineralList.chemicalTargetLevels;
        const setCostData = mineralList.chemicalCosts;
        const mediaFilterData = mineralList.sandFilterMedia;
        const mineralAdjustment = mineralList.calciumAdjustments;
        const chemicalsDisplay = mineralList.chemicalsDisplay;
        const taxApplicableValues = mineralList.taxApplicable;
        const chemBal = mineralList.chemicalBalanceWater;
        const copyCalVariable = { ...initialCal };

        const balanceMineralWater = chemBal[0].selected;
        const liquidWater = chemBal[0].increaseName;

        const Max_Length_Limit = Math.max(setCostData?.length,
          chemBal?.length,
          newIdealData?.length,
          mediaFilterData?.length);

        if (balanceMineralWater !== '') {
          setChemicalBalanceValue(balanceMineralWater);
        } else {
          setChemicalBalanceValue(liquidWater);
        }

        let chemicalBalanceObj = {};

        const drainageData = mineralList.drainageSetup;
        const { setupCost, hourlyRate, litresPerHour } = drainageData;
        let copyCosts = { ...costsFiltering };
        let copyFilteringData = { ...filteringData };
        for (let i = 0; i < Max_Length_Limit; i++) {
          if (setCostData[i]?.calciName) {
            copyCalVariable[`base${setCostData[i].calciName}`] = parseFloat(setCostData[i].buycost || 0);
            copyCalVariable[`sell${setCostData[i].calciName}`] = parseFloat(setCostData[i].sellprice || 0);
          }
          switch (setCostData[i]?.name) {
            case 'coarseGlassMedia':
              copyCosts.coarseGlassBuyCost = (setCostData[i].buycost || '0');
              copyCosts.coarseGlassSellCost = (setCostData[i].sellprice || '0');
              break;
            case 'fineGlassMedia':
              copyCosts.fineGlassBuyCost = (setCostData[i].buycost || '0');
              copyCosts.fineGlassSellCost = (setCostData[i].sellprice || '0');
              break;

            case 'sandMedia':
              copyCosts.sandMediaBuyCost = (setCostData[i].buycost || '0');
              copyCosts.sandMediaSellCost = (setCostData[i].sellprice || '0');
              break;

            default:
              break;
          }

          if (chemBal[i]) {
            const calciumNameBal = chemBal[i]?.name === 'Calcium Hardness' ? 'Total Hardness' : chemBal[i]?.name;
            chemicalBalanceObj[
              `${calciumNameBal?.replace(/[- )(]/g, '').toLowerCase()}balanceName`
            ] = chemBal[i].selected;
          }

          if (newIdealData[i]?.calciName) {
            copyCalVariable[`${newIdealData[i].calciName}Negative`] = parseFloat(newIdealData[i].okNegative || 0);
            copyCalVariable[`${newIdealData[i].calciName}Positive`] = parseFloat(newIdealData[i].okPositive || 0);
            copyCalVariable[`${newIdealData[i].name}`] = parseFloat(newIdealData[i].target);

            if (copyCalVariable[`drainPoint${newIdealData[i].name}`] === '') {
              copyCalVariable[`drainPoint${newIdealData[i].name}`] = parseFloat(newIdealData[i].drainPoint || 0);
            }
          }
          if (mediaFilterData[i]) {
            switch (mediaFilterData[i].mediaFilterSize) {
              case 'Labour Cost':
                copyFilteringData.labourCost = (mediaFilterData[i].sandQuantity || '0');
                break;
              case 'Set-up & Pack-up Time':
                copyFilteringData.setUpCost = (mediaFilterData[i].sandQuantity || '0');
                break;

              case 'Hours Required':
                copyFilteringData.hoursRequired = (mediaFilterData[i].sandQuantity || '0');
                break;

              default:
                break;
            }
          }
        }

        setCostsFiltering(copyCosts);
        setChemicalBalanceWaterObj(chemicalBalanceObj);
        setFilteringData(copyFilteringData);
        setGreenApiData(mineralList.greenpoolcalculation);
        setBlackApiData(mineralList.blackSpotAlgae);
        setAcidWashApiData(mineralList.acidWashCalculation);

        const drainingHours = drainageData.hoursToDrain;
        const drainingCost = drainageData.cost;
        const drainingPoolSize = drainageData.poolsize;
        const drainingHourlyRate = drainageData.hourlyRate;
        const poolSetUpdrainagePercentage = drainageData.drainagePercentage;

        const data = !Id ? localStorage.getItem('calculatorObject') : '';

        let parseData = data ? JSON.parse(data) : {};

        if (Id && waterObj.watHis?.calciSalt) {
          parseData = waterObj.watHis;
        }

        let type = chlorinationType;
        let stateStabiliser = stabState;
        let salty = calVariable.salt;
        let oldPh = calVariable.ph;
        let oldCalcium = calVariable.calciumHardness;
        let calciDisplay = chemicalsDisplay;
        if (parseData) {
          type = parseData.chlorinationType;
          stateStabiliser = parseData.exstab;
          salty = parseData.calciSalt;
          oldPh = parseData.exPh;
          oldCalcium = parseData.exCalcium;

          if (parseData.poolInputs?.BoxCount > 0) {
            if (parseData.calciDisplayChemicals) {
              calciDisplay.salt = parseData.calciDisplayChemicals?.salt;
            }
          }
        }

        let updatedValue = parseData.poolFinish || poolFinish;
        if (Id) {
          if (clientAllDetails?.selectedModelObj) {
            const clientSalt = clientAllDetails?.selectedModelObj;
            type = clientAllDetails?.chlorinationType;
            let finishType = clientAllDetails?.poolFinish;
            if (type === 'Mineral Pool with Chlorinator' || type === 'Calcium Hardness Titration Test') {
              type = 'Mineral Pool';
            }
            updatedValue = finishType;
            salty = clientSalt.saltLevel;
            stateStabiliser = clientSalt.recmndStabiliserLvl || clientSalt.stabiliserLevel || 40;
          }
        }

        // new Changes
        const manageCalciumData = (full) => {
          let cal = 'hard';
          let short = full === 'Concrete' ? 'Cont' : full;
          if (chemicalBalanceObj?.totalhardnessbalanceName === 'Zodiac Calcium Down') {
            full = `Reduce${full}`;
            cal = 'calcredu';
          }
          let calciumHardness = copyCalVariable[`calciumHardness${full}`];
          let drainPointcalciumHardness = copyCalVariable[`drainPointcalciumHardness${full}`];
          let calcNegative = copyCalVariable[`${cal}${short}Negative`];
          let calcPositive = copyCalVariable[`${cal}${short}Positive`];
          return {
            calciumHardness, drainPointcalciumHardness, calcPositive, calcNegative,
          };
        };

        let passValue = 'Concrete';
        if (type !== 'reset') {
          if (updatedValue?.includes('Fibre')) {
            passValue = 'Fibre';
          } else if (updatedValue?.includes('Vinyl')) {
            passValue = 'Vinyl';
          }
        }

        let {
          calciumHardness, drainPointcalciumHardness, calcPositive, calcNegative,
        } = manageCalciumData(passValue);

        let initialSalt = parseFloat(mineralList.chlorinatorModels[0].saltLevel);
        // let initialSalt = saltlevel;
        let salt = initialSalt;
        let ph = copyCalVariable?.phConcreteTilted;
        // let calciumHardness = copyCalVariable?.calciumHardnessConcrete;
        // let drainPointcalciumHardness = copyCalVariable?.drainPointcalciumHardnessConcrete;
        // let calcNegative = copyCalVariable?.hardContNegative;
        // let calcPositive = copyCalVariable?.hardContPositive;
        let calciumHardnessReduce = copyCalVariable?.calciumHardnessReduceConcrete;
        let drainPointcalciumHardnessReduce = copyCalVariable?.drainPointcalciumHardnessReduceConcrete;
        // let calcreduPositive = copyCalVariable?.calcreduContPositive;
        // let calcreduNegative = copyCalVariable?.calcreduContNegative;

        // if (chemicalBalanceObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
        //   calciumHardness = copyCalVariable?.calciumHardnessReduceConcrete;
        //   drainPointcalciumHardness = copyCalVariable?.drainPointcalciumHardnessReduceConcrete;
        //   calcPositive = copyCalVariable?.calcreduContPositive;
        //   calcNegative = copyCalVariable?.calcreduContNegative;
        // }

        // console.log('ssssss', { calciumHardness });

        // if (typeres !== 'reset') {
        //   if (
        //     updatedValue === 'Fibreglass Pool - Outdoor'
        // || updatedValue === 'Fibreglass Pool - Indoor'
        //   ) {
        //     ph = copyCalVariable?.phfibre;
        //     calciumHardness = copyCalVariable?.calciumHardnessFibre;
        //     drainPointcalciumHardness = copyCalVariable?.drainPointcalciumHardnessFibre;
        //     calcNegative = copyCalVariable?.hardFibreNegative;
        //     calcPositive = copyCalVariable?.hardFibrePositive;

        //     if (chemicalBalanceObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
        //       calciumHardness = copyCalVariable?.calciumHardnessReduceFibre;
        //       drainPointcalciumHardness = copyCalVariable?.drainPointcalciumHardnessReduceFibre;
        //       calcPositive = copyCalVariable?.calcreduFibrePositive;
        //       calcNegative = copyCalVariable?.calcreduFibreNegative;
        //     }
        //   } else if (
        //     updatedValue === 'Vinyl Liner Pool - Outdoor'
        // || updatedValue === 'Vinyl Liner Pool - Indoor'
        //   ) {
        //     ph = copyCalVariable?.phliner;
        //     calciumHardness = copyCalVariable?.calciumHardnessVinyl;
        //     drainPointcalciumHardness = copyCalVariable?.drainPointcalciumHardnessVinyl;
        //     calcNegative = copyCalVariable?.hardVinylNegative;
        //     calcPositive = copyCalVariable?.hardVinylPositive;
        //     if (chemicalBalanceObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
        //       calciumHardness = copyCalVariable?.calciumHardnessReduceVinyl;
        //       drainPointcalciumHardness = copyCalVariable?.drainPointcalciumHardnessReduceVinyl;
        //       calcPositive = copyCalVariable?.calcreduVinylPositive;
        //       calcNegative = copyCalVariable?.calcreduVinylNegative;
        //     }
        //   }
        // }

        // if (chemicalBalanceObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
        //   calciumHardness = copyCalVariable.calciumHardnessReduce;
        //   calcNegative = copyCalVariable.calcreduNegative;
        //   calcPositive = copyCalVariable.calcreduPositive;
        //   drainPointcalciumHardness = copyCalVariable.drainPointcalciumHardnessReduce;
        // }

        // mineral changes
        let previousCalciumData = {
          CalCalciumHardness: calciumHardness,
        };

        setPreviousCalcium({ ...previousCalciumData });

        let stablevel = mineralList.chlorinatorModels[0].recmndStabiliserLvl;

        let stabiliser = stablevel === '' || stablevel === 'NaN'
          ? copyCalVariable?.stabiliserCya
          : parseFloat(stablevel);

        if (poolFinish.includes('Indoor')) {
          stabiliser = 0;
        } else if (selectedModel && selectedModel.toString().includes('ORP Probe') && showModel) {
          stabiliser = stateStabiliser !== '' ? stateStabiliser : copyCalVariable?.stabiliserOrp;
        } else if (type === 'Salt Pool' && stateStabiliser !== '') {
          stabiliser = stateStabiliser;
        } else if (type === 'Mineral Pool' && stateStabiliser !== '') {
          stabiliser = stateStabiliser;
        }

        setCalciumHardnessIdeal(calciumHardness);

        if ((type === 'Mineral Pool' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') && showFullHardness?.calcium) {
          calciumHardness = 860;
        }

        if (stateStabiliser) {
          stabiliser = stateStabiliser;
        }
        if (typeres === 'reset' && !Id) {
          stabiliser = copyCalVariable?.stabiliserCya;
          ph = copyCalVariable?.phConcreteTilted;
          // calciumHardness = copyCalVariable.calciumHardnessConcrete;
          // drainPointcalciumHardness = copyCalVariable.drainPointcalciumHardnessConcrete;
          // calcNegative = copyCalVariable.hardContNegative;
          // calcPositive = copyCalVariable.hardContPositive;
        }
        const saltVal = typeres === 'reset' && !Id ? initialSalt : salty || salt;

        const { poolMsg } = poolMsgError(clientPoolWidth, clientPoolHeight);

        if (parseData?.chlorinationType === 'Calcium Hardness Titration Test' || parseData?.showFullHardness?.fullhardness || parseData?.manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
          calciumHardness = 860;
        }

        // console.log('previousCalcium', previousCalcium);
        if (displayType === 'display' && previousCalcium) {
          calciumHardness = previousCalcium?.CalCalciumHardness;
        }
        setShowCalcium(true);

        const copyallowedPermission = { ...allowedPermission };
        userData?.permissions?.forEach((el) => {
          if (el.action === 'Edit Prices') copyallowedPermission.editPrice = true;
          if (el.action === '4-Pad & 6-Pad Test Strip Reader') copyallowedPermission.stripUpload = true;
        });

        setAllowedPermission({ ...copyallowedPermission });

        setCalVariable({
          ...copyCalVariable,
          ph,
          stabiliser,
          calciumHardness,
          drainPointcalciumHardness,
          calcNegative,
          calcPositive,
          calciumHardnessReduce,
          drainPointcalciumHardnessReduce,
          // calcreduPositive,
          // calcreduNegative,
          salt: saltVal,
          litresPerHour,
          drainingHours,
          drainingCost,
          drainingPoolSize,
          drainingHourlyRate,
          setupCost,
          hourlyRate,
          poolSetUpdrainagePercentage,
          initialSalt,
          poolMsg,
        });
        setLoading(false);
        if (!displayType) {
          if (calciDisplay) {
            setCalciChemicalsDisplay(calciDisplay);
            setBackCalciChemicalsDisplay({ ...calciDisplay });
          }
        }
        // const taxApplicablev = {
        //   chemicalsTax: 0,
        //   labourTax: 0,
        // };
        // if (taxApplicableValues) {
        const { chemicalsTax, labourTax } = { chemicalsTax: 10, labourTax: 10 };
        const chemTax = chemicalsTax ? (parseFloat(chemicalsTax) + 100) / 100 : 1;
        const labTax = labourTax ? (parseFloat(labourTax) + 100) / 100 : 1;
        const actualChemicalTax = chemicalsTax || 0;
        const actualLabourTax = labourTax || 0;
        setTaxApplicable({
          ...taxApplicable, chemicalsTax: chemTax, labourTax: labTax, actualChemicalTax, actualLabourTax,
        });
        // }

        if (state?.poolInputs) {
          setPoolInputs(state?.poolInputs);
        }

        // console.log('condition2');
        return true;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  useEffect(() => {
    try {
      if (!editFalseStatus) {
        setLoading(true);
        getStaffNameForCalculator();
        // grabLevelDataFromInitialArrays();
        setResult(false);
        if (!Id) {
          decryptPool();
        }
        getIdeals();
      // getClient();
      }
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  }, [Id]);

  let bagsSalt = 'Salt';
  if (
    selectedModel === 'Aquatherepe Minerals'
  || selectedModel === 'Aquatherepe Minerals - With ORP Probe'
  ) {
    bagsSalt = 'Aquatherepe Minerals';
  } else if (selectedModel === 'Aquatherepe Transform' && (chlorinationType === 'Mineral Pool' || chlorinationType === 'Calcium Hardness Titration Test')) {
    bagsSalt = 'Aquatherepe Tranform';
  } else if (
    selectedModel === 'Magna Minerals'
  || selectedModel === 'Magna Minerals - With ORP Probe'
  ) {
    bagsSalt = 'Magna Minerals';
  }

  const backToFinaliseVisit = () => {
    historyBack.push({
      pathname: '/finlizevisitcost',
      state: {
        id: state.id,
        addedServices: state.addedServices,
        summary: state.summary,
        dateBookingData: state.dateBookingData,
        clientIdentity: state.clientIdentity,
        checklistArray: state.checklistArray,
        image: state.image,
        repeats: state.repeats,
        eventDate: state.eventDate,
        customerDues: state.customerDues,
        chemicalalist: state.chemicalsArray,
        pid: state?.pid,
      },
    });
  };

  const handleBack = async () => {
    // console.log({ editChemicals, startDateTime, editChemicalList });
    if (back) {
      console.log(' quotes 444444444444444444');
      historyBack.push(`/quotes?showservice=true&startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&watTesId=${watTesId}`);
      return;
    }
    if (editChemicals) {
      // console.log('bhai yaha hai');
      if (waterId && startDateTime) {
        historyBack.push(`addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&waterId=${waterId}`);
      } else if (invoice) {
        const clientQuery = withClientReturn ? `&clientid=${clident}` : '';
        historyBack.push(`/${invFrom}?viewtype=detailPage&from=viewApp&cal=cal${clientQuery}`);
      } else {
        let oldUrl = getCalendarPath();
        const newUrl = `${oldUrl}&chemicaltype=open`;
        (state && (state?.editChemicals || state?.comeFrom === 'finalise')) ? backToFinaliseVisit() : historyBack.push(newUrl);
      }
    } else if (visitMessage) {
      if (!startDateTime) {
        let oldUrl = getCalendarPath();
        const newUrl = `${oldUrl}&chemicaltype=open`;
        (state && (state?.editChemicals || state?.comeFrom === 'finalise')) ? backToFinaliseVisit() : historyBack.push(newUrl);
      } else {
        historyBack.push(`addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&waterId=${waterId}`);
      }
    } else {
      if (
        (serviceAnalysis === 'Green Pool' && greenSplitName !== 'Drain' || serviceAnalysis === 'Drain Pool')
      || serviceAnalysis === 'Sand Filter Media Replacement' || serviceAnalysis.includes('Water Test') || greenSplitName === 'Part' || chlorinationType === 'Calcium Hardness Titration Test'
      ) {
        if (greenSplitName !== 'Drain' && greenSplitName !== 'Part') {
          poolInputs.ph = takingPh;
        }
        await getIdeals('', 'display');

        setPoolInputs(oldPoolInputs);

        setTitrationPopupOptions('dontShowCalcium');
        encryptPool(oldPoolInputs);
        setChemicalOptionsSelected('chemicalsAdded');

        if (updatedWaterSourceStatus) {
          setPoolInputs(carryingPoolInputsData);
        }
        if (calVariable.maxDrain) {
          chemicalDrainGreen(levelData, setLevelData, 'g');
        }

        setChemicalBalanceStatus(false);
        setDrainStatus(true);
        setUpdatedWaterSourceStatus(false);
        setGetZodiacStatus(false);
      // }, 200);
      }

      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      setResult(false);
      // grabLevelDataFromInitialArrays();
      if (serviceAnalysis === 'Pool Water Volume Verification via Salinity') {
        setWaterPoolStatus(false);
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      }
      setStatus(true);
      if (serviceAnalysis === 'Water Loss Calculator') { setWaterLossStatus(false); }
      if (serviceAnalysis === 'Inverter Heater – Heating Time Required') {
        setInverterResult(false);
      }
    }
  };

  const onGreenCaseEditChemicals = (levelDataState) => {
    // console.log({ levelDataState });
    const copyLevelData = JSON.parse(JSON.stringify(levelDataState));

    if (copyLevelData?.length > 0) {
      let index = 2;
      if (greenSplitName === 'Drain' || serviceAnalysis === 'Sand Filter Media Replacement') {
        index = 1;
      }
      // const pureOldLevelData = JSON.parse((JSON.stringify(copyLevelData)));
      // console.log('pureOldLevelData', pureOldLevelData);
      // setChemicalArray(pureOldLevelData);
      setOldLevelDataState(copyLevelData);
      setLevelData(JSON.parse((JSON.stringify(copyLevelData))));
      setPId(index);
      setDrainStatus(false);
      setResult(true);
      setChemicalAddSelected(true);
    }
  };

  const managinChemicalsEdit = () => {
    const copyLevelData = clientAllInfo?.appointmentData?.chemicalsArray[0]?.testResult;

    if (copyLevelData?.length > 0) {
      const index = 0;
      const pureOldLevelData = copyLevelData.map((el) => ({ ...el }));
      const copyForChemical = copyLevelData.map((el) => ({ ...el }));
      setChemicalArray(copyForChemical);
      setOldLevelDataState(pureOldLevelData);
      setPId(index);
      setDrainStatus(false);
      setResult(true);
      setChemicalAddSelected(true);
    }
  };

  const sethistoryData = () => {
    const msg = {
      chlorineMsg: calVariable.chlorineMsg,
      combinedChlorineMsg: calVariable.combinedChlorineMsg,
      phMsg: calVariable.phMsg,
      alkMsg: calVariable.alkMsg,
      calciumHardnessMsg: calVariable.calciumHardnessMsg,
      stabiliserMsg: calVariable.stabiliserMsg,
      phosphateMsg: calVariable.phosphateMsg,
      saltMsg: calVariable.saltMsg,
      copperMsg: calVariable.copperMsg,
      algaecideMsg: calVariable.algaecideMsg,
      clarifierMsg: calVariable.clarifierMsg,
      flockMsg: calVariable.flockMsg,
      saltMsgConvert: calVariable.saltMsgConvert,
    };

    // c
    let getSetData = {
      ...allPoolParameters, msg, calVariable, calciDisplayChemicals, taxApplicable,
    };

    setCheckList(!checkList);
    const payload = {
      history: getSetData,
      appointmentid: appId,
      Id,
      clientId: Id,
    };

    setWaterHistory(payload);
  };

  // console.log({ showCalcium });

  const chemicalNextStageProceed = () => {
    if (parseFloat(calVariable?.totalCost) > 5.50 || (poolInputs?.BoxCount > 0 || !showCalcium)) {
      sethistoryData();
      setConfirmChemical(!confirmChemical);

      let pid = 0;
      const copyLevelData = [...levelData];
      copyLevelData.map((element) => element.service = element.service.filter((el) => (el.cost !== '0.00' || el.cost !== '') && el.subcategory !== ''));

      if ((serviceAnalysis.includes('Water Test') && calVariable.isDrainage) || serviceAnalysis === 'Drain Pool'
    || getZodiacStatus) {
        copyLevelData.forEach((el, index) => {
          if (index === 1 && calVariable.balancing) {
            el.service = [];
          }
        });
        if (!calVariable.balancing) {
          setChemicalAddSelected(true);
        }
        pid = 1;
      } else {
        const firstIndex = 0;

        copyLevelData.forEach((el, index) => {
          if (index !== 0) {
            el.service = [];
          }
        });
        pid = 0;
        setDrainStatus(false);
        calculateEndingTimeArr(setLevelData, firstIndex, levelData);
        setChemicalAddSelected(true);
      }
      copyLevelData.forEach((el) => el.service.length && el.service.forEach((el) => {
        el.quantity = (el.subcategory.includes('% of') || el.category === 'drain') ? el.duration : el.subcategory.split(' ')[1];
      }));

      const pureOldLevelData = copyLevelData.map((el) => ({ ...el }));
      const copyForChemical = copyLevelData.map((el) => ({ ...el }));

      setChemicalArray(copyForChemical);
      setOldLevelDataState(pureOldLevelData);
      setLevelData(copyLevelData);
      if (calVariable?.balancing) {
        setStatus(false);
      }

      setPId(pid);
    }
  };

  const SaveWaterTest = async () => {
    if (!editChemicals) {
      chemicalNextStageProceed();
    } else {
      managinChemicalsEdit();
    }
  };

  const handleReset = async () => {
    let copyInitials = { ...initialInputs };
    if (Id) {
      copyInitials.poolWidth = poolInputs.poolWidth;
      copyInitials.poolHeight = poolInputs.poolHeight;
      copyInitials.poolCapacity = poolInputs.poolCapacity;
    }
    localStorage.removeItem('calculatorObject');
    setServiceAnalysis('Water Test');
    setGlObj(initialglbobj);
    if (!Id) {
      setChlorinationType('Salt Pool');
      setSelectedModel(4000);
      setGetValue('Magna Minerals');
      setChlorineModel(chlorineNewmodel);
      setPoolFinish('Concrete Pool - Outdoor');
      setManualValue('Non-Mineral Pool');
      setShowModel(true);
      setPoolDepth('Average Depth - Rectangular Pool');
      setDiameter(false);
      setCustomDepths(false);
    }

    setGreenSplitName('Liquid');
    setGreenTreatment('Liquid Chlorine');
    setBlackTreatment('Black-Spot Remover');
    setBlackSplitName('Black-Spot');
    setFilterOptions('Replace with Glass (filters to 3 microns)');
    setFilterSelect('');
    setGreenSelect('');
    setBlackSelect('');
    setSelectedWaterLevelAmount('1');
    setSelectedWaterLevel('Same Water Level in Pool');
    setPoolInputs(copyInitials);
    setOldCopyPoolInputs(copyInitials);
    setAlkMsgs(initialAlkMsgsSet);
    setTakingPh('');
    setWaterPoolStatus(true);
    setSourcePoolInputs(initialSoureInputs);
    setWaterLossStatus(false);
    setWaterTestOptions('Water Test Only');
    setWaterLossCalculator('Water Loss Per Day');
    setChemicalOptionsSelected('chemicalsAdded');
    setValData(initialValidationData);
    setBlankInputFields(null);
    setAllFields(true);
    setFetchImage(true);
    setTestStrip(false);
    setBackStatus(false);
    setShowFullHardness(initalShowFullHardness);
    setAllArray(JSON.parse(JSON.stringify(initialStripObject)));
    setHexObj({ ...initialHexValues });
    setPercent({ ...percentageDifference });
    setInverterResult(false);
    setInverterSize('24');
    await getIdeals('reset');
  };

  const handleCalculateFurtherProcess = (res, isFlagValue) => {
    let actualCalciumValue = poolInputs?.calciumHardness;
    let actualCalciumIdealValue = calVariable?.calciumHardness;
    if (chlorinationType === 'Calcium Hardness Titration Test' || showFullHardness?.fullhardness || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
      actualCalciumValue = poolInputs?.hardCalcium;
      actualCalciumIdealValue = calVariable?.hardCalcium;
    }

    let calciumCalciRedu = (actualCalciumIdealValue * calVariable.calcPositive) / 100;
    let positiveCalciumRedu = parseFloat(actualCalciumIdealValue) + parseFloat(calciumCalciRedu);

    let copyLevelData = [...levelData];
    if (!isFlagValue && ((res?.maxTestingDrain || (serviceAnalysis === 'Drain Pool' && greenSplitName === 'Part' && poolInputs?.drainPercentWaterLevelLowered)) || greenSplitName === 'Drain')) {
      setDrainStatus(true);
      setSoourceReading(true);
      setWaterSourceSelect('waterSource');
      setLevelData(copyLevelData);
    } else if (res?.input > positiveCalciumRedu && chemicalBalanceWaterObj?.totalhardnessbalanceName === 'Zodiac Calcium Down' && !res?.drainWaterHardness) {
      setZodiacStatus(true);
    } else if (isFlagValue) {
      setTimeout(() => {
        handleCalculate({
          withDrainage: '', resDrainData: res, balancing: false,
        });
      }, 5000);
    } else {
      handleCalculate({
        withDrainage: '', resDrainData: res, balancing: false,
      });
    }
  };

  const addInCurrentMessage = (addMessage, message, fQuant, fName, sQuant, sName, pad) => {
    let m = message;
    if (!pad && (!fQuant || !sQuant)) {
      message += `${m ? '<br> -' : ''}${addMessage}`;
      if (!fQuant) message += ` ${fName}`;
      if (!sQuant) message += !fQuant ? ` & ${sName}` : ` ${sName}`;
      message += '.';
    }
    return message;
  };

  const handleAppointmentWaterTest = () => {
    isOkCalcium = false;
    let isValid = true;
    let validationObj = {};
    let emptyField = [];
    const {
      poolWidth, poolHeight, shallowEnd, deepEnd, diameter, currentSaltLevel, originalSaltLvl, bagsSaltAdded, increaseDecreaseAmount, drainPercentWaterLevelLowered, waterLevelLowered, hardCalcium,
    } = poolInputs;
    let { poolWaterCapacity } = poolInputs;
    const blankCopy = { ...blankInputFields };

    if (serviceAnalysis !== 'Pool Water Volume Verification via Salinity') {
      if (poolDepth === 'Average Depth - Rectangular Pool') {
        validationObj.poolWidth = poolWidth;
        validationObj.poolHeight = poolHeight;
      } else if (poolDepth === 'Average Depth - Circular pool') {
        validationObj.diameter = diameter;
      } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
        validationObj.poolWidth = poolWidth;
        validationObj.poolHeight = poolHeight;
        validationObj.shallowEnd = shallowEnd;
        validationObj.deepEnd = deepEnd;
      } else if (poolDepth === 'Specific Depths - Circular Pool') {
        validationObj.shallowEnd = shallowEnd;
        validationObj.deepEnd = deepEnd;
        validationObj.diameter = diameter;
      }

      if (greenSplitName !== 'Drain' && (chlorinationType === 'Calcium Hardness Titration Test' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test')) {
        if (!hardCalcium) {
          toastMessage('error', 'Perform a Calcium Hardness Titration Test and enter the Calcium Hardness Value.');
          validationObj.hardCalcium = hardCalcium;
          blankCopy.hardCalcium = hardCalcium;
        }
      }

      let isFlagValue = false;

      if (validationObj && greenSplitName !== 'Drain') {
        validationObj = { ...validationObj, ...blankCopy };
      }

      // console.log({ validationObj, blankCopy });

      if (chlorinationType === 'Manually Chlorinated') {
        delete validationObj?.salt;
      }

      if (!showFullHardness?.calcium) {
        delete validationObj?.hardCalcium;
        delete validationObj?.hardMagnesium;
      }

      const returnedData = validateData(validationObj);
      isValid = returnedData.isValid;
      emptyField = returnedData.emptyField;

      setValData({ ...initialValidationData, ...emptyField });

      if (greenSplitName === 'Part') {
        if (!waterLevelLowered) {
          isValid = false;
          toastMessage('error', 'Enter an amount for Water Level Lowered by.');
        } else {
          if (drainPercentWaterLevelLowered > 100 && !poolFinish.includes('Vinyl')) {
            isValid = false;
            toastMessage('error', 'Drain amount entered is higher than 100%.');
          }
          if (drainPercentWaterLevelLowered > 30 && poolFinish.includes('Vinyl')) {
            isValid = false;
            toastMessage('error', 'Vinyl Liner Pool drain amount must be under 30%.');
          }
        }
      }

      if (parseFloat(poolInputs.copper) === 2) {
        isValid = false;
        toastMessage('error', 'Copper reading must be less than 2.00ppb.');
      }

      if (calciDisplayChemicals?.totalChlorine) {
        if (
          parseFloat(poolInputs.chlorine) > parseFloat(poolInputs.combinedChlorine)
        ) {
          isValid = false;
          // setResult(false);
          toastMessage('error', 'Total Chlorine reading can’t be less than Free Chlorine.');
        }
      }

      // mineral changes

      if (hardCalcium && parseFloat(hardCalcium) > parseFloat(poolInputs.calciumHardness) && (showFullHardness?.calcium || showFullHardness?.fullhardness)) {
        isValid = false;
        toastMessage('error', 'Calcium Hardness reading can’t be higher than Total Hardness.');
      }

      const notShowErrorConditions = (serviceAnalysis === 'Drain Pool' && greenSplitName !== 'Part') || serviceAnalysis === 'Water Loss Calculator' || serviceAnalysis === 'Pool Water Volume Verification via Salinity';
      const sixPadStrip = poolInputs.BoxCount > 4;
      const {
        salt: disSalt, phosphates: disPhos, copper: disCopper, calciumHardness: disCalcium, totalChlorine: disTotalChlorine,
      } = calciDisplayChemicals;

      const isInput = returnChemicalsInputStatus();

      if (backStatus && isValid && !notShowErrorConditions) {
        let message = '';

        if (
          chlorinationType === 'Salt Pool'
          || chlorinationType === 'Convert Salt Pool to Mineral Pool'
        ) {
          if (sixPadStrip) {
            if (!disSalt) {
              if (greenSplitName !== 'Part') {
                message = 'Return to Test Strip Page & select “Check Salt Readings” to measure Salt required.';
              } else {
                message = 'Return to Test Strip Page & select “Check Salt Readings”.';
              }
            }
          } else if (!sixPadStrip) {
            if (!disSalt) {
              message = msgGroup?.testStripMsg;
            } else {
              // message = msgGroup?.onlyAquaCheck;
            }
          }
        } else if (chlorinationType === 'Mineral Pool') {
          if (sixPadStrip) {
            if (!disSalt) {
              message = msgGroup?.impactMsg;
            }
          } else if (!sixPadStrip) {
            if (!disSalt) {
              message = msgGroup?.aquaFullMsg;
            } else {
              // message = msgGroup?.aquaMsg;
            }
          }
        } else if (chlorinationType === 'Calcium Hardness Titration Test') {
          // mineral changes
          if (sixPadStrip) {
            if (!disSalt) {
              message = 'Return to Test Strip page & select “Check Salt Readings” to measure Minerals required.';
            }
          }
        } else if (chlorinationType === 'Manually Chlorinated') {
          if (!disSalt && !sixPadStrip && manualValue !== 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
            message = msgGroup?.aquaMsg;
          }
        }

        message = addInCurrentMessage(msgGroup?.onlyAquaCheck, message, isInput.combinedChlorine, 'Total Chlorine', isInput.calciumHardness, 'Total Hardness', sixPadStrip);

        message = addInCurrentMessage('Do a titration test or use a photometer to test', message, isInput.copper, 'Copper', isInput.phosphates, 'Phosphates', false);

        if (message) {
          isFlagValue = true;
          toastMessage('error', '');
          setTimeout(() => {
            let element = document.getElementById('toastIdentity');
            element.innerHTML = message;
          }, 0);
        }
      }

      if (isValid) {
        const res = forGettingAllDrains({
          calVariable,
          poolFinish,
          chlorinationType,
          poolInputs,
          // calciumHardnessState,
          manualValue,
          selectedModel,
          drainMagnaPoint,
          drainPoint,
          magnaPoolAdjust,
          calciDisplayChemicals,
          serviceAnalysis,
          showFullHardness,
        });

        setgetresDrainData(res);

        // console.log('serviceAnalysis', serviceAnalysis);

        let passingSalt = poolInputs.salt;
        let isCalciumShow = false;

        if (manualValue.includes('Aquatherepe Transform Maintenance')) {
          passingSalt = 4500;
        }

        if (greenSplitName === 'Drain') {
          isOkCalcium = true;
        }
        if (greenSplitName !== 'Drain') {
        // mineral changes
          let actualCalciumValue = poolInputs?.calciumHardness;
          let actualCalciumIdealValue = calVariable?.calciumHardness;
          if (chlorinationType === 'Calcium Hardness Titration Test' || showFullHardness?.fullhardness || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
            actualCalciumValue = poolInputs?.hardCalcium;
            actualCalciumIdealValue = calVariable?.hardCalcium;
          }

          const notImplementFormula = !!((chlorinationType === 'Calcium Hardness Titration Test' || showFullHardness?.fullhardness || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test'));
          let input = (!notImplementFormula && (chlorinationType === 'Mineral Pool'
        || manualValue.includes('Aquatherepe Transform Maintenance')))
            ? calciumAdjustmentCal(actualCalciumValue, passingSalt)
            : actualCalciumValue;

          // mineral changes
          let calciumCalci = (actualCalciumIdealValue * calVariable?.calcPositive) / 100;
          let positiveCalcium = parseFloat(actualCalciumIdealValue) + parseFloat(calciumCalci);

          if (input >= calVariable?.calcNegative && input <= positiveCalcium) {
            isOkCalcium = true;
          }
          if (!poolInputs.calciumHardness) {
            isOkCalcium = true;
          }

          let isDrainage = false;

          if (res?.maxTestingDrain) {
            isDrainage = true;
          }

          if (chlorinationType === 'Mineral Pool' && !showFullHardness?.calcium && !isOkCalcium && disSalt) {
            isCalciumShow = true;
          }
        }
        // mineral changes
        if (isCalciumShow) {
          setIsStateFlagValue(isFlagValue);
          setTitrationPopup(true);
        } else {
          handleCalculateFurtherProcess(res, isFlagValue);
        }
      }
    } else {
      validationObj.originalSaltLvl = originalSaltLvl;
      validationObj.bagsSaltAdded = bagsSaltAdded;
      validationObj.currentSaltLevel = currentSaltLevel;
      if (selectedWaterlevel === 'Lower Water Level in Pool' || selectedWaterlevel === 'Higher Water Level in Pool') {
        validationObj.increaseDecreaseAmount = increaseDecreaseAmount;
      }

      const returnedData = validateData(validationObj);
      isValid = returnedData.isValid;
      emptyField = returnedData.emptyField;
      setValData({ ...initialValidationData, ...emptyField });
      if ((currentSaltLevel * 1) <= (originalSaltLvl * 1)) {
        isValid = false;
        toastMessage('error', 'Current Salt level entered should be higher than Original Salt level.');
      } else if (selectedWaterlevel === 'Lower Water Level in Pool' && !increaseDecreaseAmount) {
        isValid = false;
        toastMessage('error', 'Enter Decreased Amount.');
      } else if (selectedWaterlevel === 'Higher Water Level in Pool' && !increaseDecreaseAmount) {
        isValid = false;
        toastMessage('error', 'Enter Increased Amount.');
      }
      if (isValid) {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        poolWaterCapacity = calculatingPoolWaterCapacity(originalSaltLvl, currentSaltLevel, bagsSaltAdded, chlorinationType);
        // correction for selectedWaterLevelAmount
        if (increaseDecreaseAmount >= 1) {
          if (selectedWaterlevel === 'Lower Water Level in Pool') {
            poolWaterCapacity = Math.floor((poolWaterCapacity + (poolWaterCapacity / 1250) * increaseDecreaseAmount) / 1000) * 1000;
          } else if (selectedWaterlevel === 'Higher Water Level in Pool') {
            poolWaterCapacity = Math.floor((poolWaterCapacity - (poolWaterCapacity / 1250) * increaseDecreaseAmount) / 1000) * 1000;
          }
        }
        setWaterPoolStatus(true);

        setPoolInputs({
          ...poolInputs,
          poolWaterCapacity,
        });
      }
    }
  };

  const handleWaterSourceChange = (data) => {
    setWaterSourceSelect(data);
  };
  const handleSetSourceSelected = () => {
    if (waterSourceSelect === 'waterSource') {
      const { maxTestingDrain, drainWaterHardness } = getresDrainData;
      let inputChange = poolInputs.calciumHardness;
      if (maxTestingDrain && maxTestingDrain !== drainWaterHardness) {
        inputChange = parseFloat(inputChange) - ((parseFloat(inputChange) * parseFloat(maxTestingDrain)) / 100);
      }
      if (!drainWaterHardness && inputChange > calVariable.calcPositive && chemicalBalanceWaterObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
        setZodiacStatus(true);
      } else {
        handleCalculate({ withDrainage: '', resDrainData: getresDrainData });
      }
      setSoourceReading(false);
      // setChemicalArray(copyLevelData);
    } else if (waterSourceSelect === 'waterSourceUpdate') {
      setSourceSelected(true);
    }
  };

  const handleChemicalPopUpChange = (data) => {
    setChemicalOptionsSelected(data);
  };

  const handleUpdateWaterSourceReadings = () => {
    // for checking Source Input is Higher than actual Ideal Level
    let isValid = true;
    if (waterSourceSelect === 'waterSourceUpdate') {
      const {
        stabiliser, calciumHardness, salt, calcPositive,
      } = calVariable;
      const returnedChemcial = higherThanTargetLevels(sourcePoolInputs, calVariable, poolInputs.combinedChlorine, chlorinationType, getValue, selectedModel);
      isValid = returnedChemcial.isValid;
      if (!isValid) {
        const { chemicalValue, higherChemical } = returnedChemcial;
        const limUnit = (chemicalValue === 'copper' || chemicalValue === 'phospahte') ? 'ppb' : 'ppm';
        toastMessage('error', `Water source to be less than ${chemicalValue} ${limUnit} ${higherChemical}.`);
      } else {
        const drainObj = getresDrainData;
        if (waterSourceSelect === 'waterSourceUpdate') {
          if (drainObj.drainWater && sourcePoolInputs.stabiliser > 0) {
            drainObj.drainWater = drainNewCalculation(poolInputs.stabiliser,
              drainObj.drainWater,
              sourcePoolInputs.stabiliser,
              stabiliser);
          }
          if (drainObj.drainWaterHardness && sourcePoolInputs.calciumHardness > 0) {
            drainObj.drainWaterHardness = drainNewCalculation(
              poolInputs.calciumHardness,
              drainObj.drainWaterHardness,
              sourcePoolInputs.calciumHardness,
              calciumHardness,
            );
          }
          if (drainObj.drainWaterSalt && sourcePoolInputs.salt > 0) {
            drainObj.drainWaterSalt = drainNewCalculation(poolInputs.salt,
              drainObj.drainWaterSalt,
              sourcePoolInputs.salt,
              salt);
          }
          if (drainObj.drainWaterCopper && sourcePoolInputs.copper > 0) {
            drainObj.drainWaterCopper = drainNewCalculation(poolInputs.copper,
              drainObj.drainWaterCopper,
              sourcePoolInputs.copper,
              0.199);
          }
          if (drainObj.drainWaterChorine && sourcePoolInputs.combinedChlorine > 0) {
            drainObj.drainWaterChorine = drainNewCalculation(poolInputs.combinedChlorine,
              drainObj.drainWaterChorine, sourcePoolInputs.combinedChlorine, poolInputs.chlorine);
          }
        }
        setSoourceReading(false);
        setSourceSelected(false);

        let inputChange = poolInputs.calciumHardness;
        let srcInpu = sourcePoolInputs.calciumHardness;
        if (drainObj.maxTestingDrain && drainObj.maxTestingDrain !== drainObj.drainWaterHardness) {
          inputChange = getChemcialAfterReducing(inputChange, drainObj.maxTestingDrain);
          srcInpu = getChemcialAfterReducingSrc(srcInpu, drainObj.maxTestingDrain);
          inputChange = sumForInputs(inputChange, srcInpu);
        }
        if (!drainObj.drainWaterHardness && inputChange > calcPositive && chemicalBalanceWaterObj.totalhardnessbalanceName === 'Zodiac Calcium Down') {
          setZodiacStatus(true);
        } else {
          handleCalculate({ withDrainage: '', resDrainData: getresDrainData });
        }
      }
    }
  };

  const handleChemicalsSelectPop = () => {
    if (chemicalOptionsSelected === 'editQuantity') {
      console.log('1');
      setSoourceReading(false);
      setChemicalAddSelected(false);
      setMultiEditQuantityStatus(!multiEditQuantityStatus);
    } else if (chemicalOptionsSelected === 'editSellPrice') {
      console.log('2');
      setSoourceReading(false);
      setChemicalAddSelected(false);
      setMultiEditSellPriceStatus(true);
      setLevelDataAfterQuantityEdit(JSON.parse(JSON.stringify(chemicalArray)));
      setStatus(true);
    } else if (chemicalOptionsSelected === 'chemicalsAdded') {
      if (!editChemicals) {
        console.log('4');
        setStatus(false);
        setChemicalAddSelected(false);
      } else if (waterId && startDateTime && back) {
        if (quoteId) {
          historyBack.push(`/quotes?showservice=true&startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&watTesId=${waterId}&quoteId=${quoteId}&from=calculator`);
        } else {
          historyBack.push(`/quotes?showservice=true&startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&watTesId=${waterId}`);
        }

        // historyBack.push(`/addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&waterId=${waterId}`);
        // return;
      } else if (waterId && startDateTime) {
        if (quoteId) {
          historyBack.push(`/addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&waterId=${waterId}&quoteId=${quoteId}&from=calculator`);
        } else {
          historyBack.push(`/addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}&waterTest=done&waterId=${waterId}`);
        }
      } else if (invoice) {
        const clientQuery = withClientReturn ? `&clientid=${clident}` : '';
        const invQueryWithClient = withClientReturn && (namee || skipValue) ? `client=${clident}&from=client&namee=${namee}&typeRoute=client&skipValue=${skipValue}` : '';
        if (invQueryWithClient) {
          historyBack.push(`/${invFrom}?${invQueryWithClient}`);
        } else {
          historyBack.push(`/${invFrom}?viewtype=detailPage&from=viewApp&cal=cal${clientQuery}`);
        }
      } else {
        let oldUrl = getCalendarPath();
        const newUrl = `${oldUrl}&chemicaltype=open`;
        state?.editChemicals ? backToFinaliseVisit() : historyBack.push(newUrl);
      }

      setEditSellPriceStatus(false);
      setEditQuantityStatus(false);
      setMultiEditQuantityStatus(false);
      setMultiEditSellPriceStatus(false);
      setSoourceReading(false);
      setLevelData(chemicalArray);
    }
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const handleChemicalSellPricePopup = () => {
    let copyLevelDataService = [];
    let copyChemicalArray = [...chemicalArray];
    if (pId) {
      copyLevelDataService = editChemicals ? [...oldLevelDataState[pId]?.service] : [...levelData[pId]?.service];
      copyChemicalArray[pId].service.map((el, index) => el.renderCost = copyLevelDataService[index]?.renderCost);
    }
    setChemicalArray(copyChemicalArray);
    setChemicalArray(levelData);
    if (serviceAnalysis === 'Water Test') {
      setResult(true);
    } else {
      setStatus(false);
    }
    setMultiEditSellPriceStatus(false);
    setChemicalAddSelected(false);
  };

  // Edit Chemical Pop Up Cross Button Function
  const handleChemicalQuantityPopup = () => {
    let copyLevelDataService = [];
    let copyChemicalArray = [...chemicalArray];
    if (pId) {
      copyLevelDataService = editChemicals ? [...oldLevelDataState[pId]?.service] : [...levelData[pId]?.service];
      copyChemicalArray[pId]?.service.map((el, index) => el.quantity = copyLevelDataService[index]?.quantity);
    }
    setChemicalArray(levelData);
    if (serviceAnalysis === 'Water Test') {
      setResult(true);
    } else {
      setStatus(false);
    }
    setMultiEditQuantityStatus(false);
    setChemicalAddSelected(false);
  };

  const handleQuantityChange = (event, indx, numericValue) => {
    const numReg = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;
    setChemicalIndex(indx);
    let copyLevelData = [...chemicalArray];
    const parentId = chemicalArray.findIndex((el) => el?.numericValue === numericValue);
    if (parentId === -1) return;
    const finalData = (visitMessage || editChemicals || (multiVisit && !editChemicals)) ? oldLevelDataState : levelData;
    const copyService = copyLevelData[parentId]?.service?.map((a) => ({ ...a }));
    const actualLevelCopy = finalData[parentId]?.service?.map((a) => ({ ...a }));

    if (event.target.value) {
      const sellCost = (event.target.value / (actualLevelCopy[indx]?.quantity) * actualLevelCopy[indx].renderCost).toFixed(2);
      copyService[indx].renderCost = (Math.ceil(sellCost / 5) * 5).toFixed(2);
      copyService[indx].buyCostProduct = (event.target.value / actualLevelCopy[indx]?.quantity * actualLevelCopy[indx]?.buyCostProduct).toFixed(2);
    } else {
      copyService[indx].renderCost = '0.00';
      copyService[indx].buyCostProduct = '0.00';
    }
    // if (parseFloat(event.target.value) > 0) {
    //   setSellPriceStatus(true);
    // } else {
    //   setSellPriceStatus(false);
    // }
    // eslint-disable-next-line no-restricted-globals
    if ((numReg.test(parseFloat(event.target.value)) && !isNaN(event.target.value)) || !event.target.value) {
      const oldQuantity = actualLevelCopy[indx].quantity;
      copyService[indx].quantity = event.target.value;
      copyService[indx].actualQuantity = oldQuantity;
      copyLevelData[parentId].service = copyService;
      setChemicalArray(copyLevelData);
    }
  };

  const [lessCostChemicals, setLessCostChemicals] = useState([]);
  const [finalPriceStatus, setFinalPriceStatus] = useState([]);

  const handleSellPriceChange = (event, indx, numericValue) => {
    // console.log('evnet', event);
    const numReg = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;
    setChemicalIndex(indx);
    // const changeState = lessCostChemicals.slice();
    // const indexxx = changeState.indexOf(name);
    const finalData = (visitMessage || editChemicals) ? oldLevelDataState : levelData;
    let copyLevelData = [...chemicalArray];
    const parentId = chemicalArray.findIndex((el) => el?.numericValue === numericValue);
    if (parentId === -1) return;

    const copyService = copyLevelData[parentId]?.service?.map((a) => ({ ...a }));
    const actualLevelCopy = finalData[parentId]?.service?.map((a) => ({ ...a }));
    const copyServiceIndex = copyService[indx];
    copyServiceIndex.renderCost = event.target.value;
    copyServiceIndex.actualSellPrice = actualLevelCopy[indx].renderCost;

    // if (parseFloat(copyServiceIndex.buyCostProduct) > parseFloat(copyServiceIndex.renderCost || '0')) {
    //   if (indexxx < 0) {
    //     changeState.push(name);
    //     setLessCostChemicals(changeState);
    //   }
    // } else {
    //   if (indexxx >= 0) {
    //     changeState.splice(indexxx, 1);
    //     setLessCostChemicals(changeState);
    //   }
    //   setSellPriceStatus(true);
    // }

    if (parseFloat(copyServiceIndex.renderCost) >= parseFloat(copyServiceIndex.buyCostProduct)) {
      const mrgin = parseFloat(copyServiceIndex.renderCost) - parseFloat(copyServiceIndex.buyCostProduct);
      copyServiceIndex.laborMargin = mrgin.toFixed(2);
    }
    // eslint-disable-next-line no-restricted-globals
    if ((numReg.test(parseFloat(event.target.value)) && !isNaN(event.target.value)) || !event.target.value) {
      copyLevelData[parentId].service = copyService;
      setChemicalArray(copyLevelData);
    }
  };

  const updateQuantityAndCost = (el) => {
    const goAhead = parseFloat(el.quantity) > 0 && el?.type === 'product';
    if (goAhead) {
      let showOf = '';
      if (el?.subcategory?.includes(' of ')) {
        showOf = 'of';
      }

      if (el.renderCost !== 'N/A' && el.chemicalDisplayName) {
        if (el.chemicalUnits === 'lb' && el?.quantity?.toString()?.includes('.')) {
          const splitValue = el?.quantity?.toString()?.split('.');
          const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
          el.subcategory = `Add ${splitValue[0]} lb ${childUnit} ${showOf} ${el?.chemicalDisplayName}`;
        } else {
          el.subcategory = `Add ${el?.quantity} ${el?.chemicalUnits} ${showOf} ${el?.chemicalDisplayName}`;
        }
        if (el?.originalQuantity && (parseFloat(el?.originalQuantity) > parseFloat(el?.quantity))) {
          if (el.chemicalUnits === 'lb' && el?.quantity?.toString()?.includes('.')) {
            const splitValue = el?.quantity?.toString()?.split('.');
            const childUnit = splitValue[1] !== '0' ? `${splitValue[1]} oz` : '';
            const splitValue2 = el.originalQuantity?.toString()?.split('.');
            const childUnit2 = splitValue2[1] !== '0' ? `${splitValue2[1]} oz` : '';
            el.subcategory = `${splitValue2[0]} lb ${childUnit2} ${showOf} ${el?.chemicalDisplayName} - Only ${splitValue[0]} lb ${childUnit} added`;
          } else {
            el.subcategory = `${el?.originalQuantity} ${el?.chemicalUnits} ${showOf} ${el?.chemicalDisplayName} - Only ${el?.quantity} ${el?.chemicalUnits} added`;
          }
        }
      }
    }
    return true;
  };

  const forChangingSubCategory = () => {
    let copyLevelData = [...chemicalArray];
    if ((multiVisit && !editChemicals) || (visitMessage && startDateTime)) {
      copyLevelData.map((elem, index) => {
        let goAhead = true;
        if (index === 0 && serviceAnalysis === 'Sand Filter Media Replacement') {
          goAhead = false;
        }
        if (goAhead) {
          elem.service.map(updateQuantityAndCost);
        }
      });
    } else {
      const copyService = copyLevelData[pId].service.map((a) => ({ ...a }));
      copyService.map(updateQuantityAndCost);
      copyLevelData[pId].service = copyService;
      copyLevelData[pId].service = calculateEndingTimeArr(setLevelData, pId, copyLevelData, 'page');
    }
    setChemicalArray(copyLevelData);
    setLevelData(copyLevelData);
  };
  const handleSellUpdate = () => {
    const copyChemicalArray = [...chemicalArray];
    let proceedFurther = true;
    let changeState = [];
    copyChemicalArray.forEach((el) => {
      if (el?.service?.length > 0) {
        el.service.forEach((el) => {
          if (el.chemicalDisplayName && el.type === 'product' && el.renderCost !== 'N/A' && !(Number(el?.renderCost) > Number(el.buyCostProduct))) {
            proceedFurther = false;
            changeState.push(el.category);
          }
        });
      }
    });
    // console.log({ proceedFurther, copyChemicalArray, changeState });
    if (!proceedFurther) {
      setFinalPriceStatus(changeState);
      toastMessage('error', 'EX GST cost is not less than the BUY COST.');
      return;
    }

    forChangingSubCategory();
    toastMessage('success', 'Chemicals Updated Successfully');
    if (!editChemicals) setMultiEditSellPriceStatus(false);
    setStatus(false);

    // if (lessCostChemicals.length === 0) {
    //   forChangingSubCategory();
    //   toastMessage('success', 'Chemicals Updated Successfully');
    //   if (!editChemicals) {
    //     if ((multiVisit && !editChemicals) || (visitMessage && startDateTime)) {
    //       setMultiEditSellPriceStatus(false);
    //     } else {
    //       setEditSellPriceStatus(false);
    //     }
    //   }
    //   setEditChemicalPopUp(false);
    //   setEditQuantityStatus(false);
    //   setMultiEditQuantityStatus(false);
    //   setSellPriceStatus(true);
    //   setStatus(false);
    //   setFinalPriceStatus(lessCostChemicals);
    //   // setLevelData(chemicalArray);
    // } else {
    //   toastMessage('error', 'EX GST cost is not less than the BUY COST.');

    //   setFinalPriceStatus(lessCostChemicals);
    // }
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  const handleSaveUpdate = () => {
    // console.log('clicked');
    const copyChemicalArray = [...chemicalArray];
    let proceedFurther = true;
    copyChemicalArray.forEach((el) => {
      if (el?.service?.length > 0) {
        el.service.forEach((el) => {
          if (el.chemicalDisplayName && el.type === 'product' && el.renderCost !== 'N/A' && !(Number(el?.quantity) > 0)) {
            proceedFurther = false;
          }
        });
      }
    });
    // console.log({ proceedFurther, copyChemicalArray });
    if (!proceedFurther) {
      toastMessage('error', 'Quantity cannot be less than or equal to zero.');
      return;
    }

    setLevelDataAfterQuantityEdit(JSON.parse(JSON.stringify(chemicalArray)));

    setEditChemicalPopUp(!editChemicalPopUp);
    window.scroll({ top: 0, behavior: 'smooth' });
  };
  const handleRevertSellPriceUpdate = () => {
    if ((multiVisit && !editChemicals) || (visitMessage && startDateTime)) {
      let copyLevelDataService = JSON.parse(JSON.stringify(levelDataAfterQuantityEdit));
      // let copyChemicalArray = [...chemicalArray];
      // copyChemicalArray.map((elem, i) => elem?.service?.map((el, index) => el.renderCost = el.renderCost === 'N/A' ? 'N/A' : copyLevelDataService[i].service[index].renderCost));
      setChemicalArray(copyLevelDataService);
      setRevertStatus(!revertStatus);
      setSellPriceStatus(true);
      toastMessage('success', 'Chemicals Updated Successfully');
      return;
    }

    let copyLevelDataService = editChemicals ? JSON.parse(JSON.stringify(levelDataAfterQuantityEdit)) : JSON.parse(JSON.stringify(levelDataAfterQuantityEdit));
    let copyChemicalArray = [...chemicalArray];

    setChemicalArray(copyLevelDataService);
    setRevertStatus(!revertStatus);
    setSellPriceStatus(true);
    toastMessage('success', 'Chemicals Updated Successfully');
    setFinalPriceStatus([]);
  };

  const handleReverQuantitytUpdate = () => {
    if ((multiVisit && !editChemicals) || (visitMessage && startDateTime)) {
      setRevertStatus(!revertStatus);
      setChemicalArray(JSON.parse(JSON.stringify(levelData)));
      setSellPriceStatus(true);
      toastMessage('success', 'Chemicals Updated Successfully');
      return;
    }
    let copyLevelDataService = editChemicals ? oldLevelDataState[pId].service : [...levelData[pId].service];
    let copyLevel = JSON.parse(JSON.stringify(copyLevelDataService));
    let copyChemicalArray = [...chemicalArray];
    // copyChemicalArray[pId].service.map((el, index) => {
    //   el.quantity = copyLevelDataService[index].quantity;
    //   el.subcategory = copyLevelDataService[index].subcategory;
    // });
    copyChemicalArray[pId].service = copyLevel;
    setChemicalArray(copyChemicalArray);
    setRevertStatus(!revertStatus);
    setSellPriceStatus(true);
    toastMessage('success', 'Chemicals Updated Successfully');
  };

  const handleZodiacCalcium = () => {
    handleCalculate({ withDrainage: '', resDrainData: getresDrainData });
    setZodiacStatus(false);
  };

  const handleImageReaderValue = async (file) => {
    const copInp = { ...poolInputs };

    const config = {
      headers: {
        'X-Api-Key': 'p/gcVAktZnOKwpnVWo0y3A==KexQVrmhwFbmCHi9',
      },
    };
    if (file) {
      const data = new FormData();

      data.append('image', file);

      const response = await axios.post('https://api.api-ninjas.com/v1/imagetotext', data, config);

      if (response) {
        Object.keys(inpImage).forEach((chem) => {
          copInp[`${inpImage[chem]}`] = '';
        });
      }
      response.data.forEach((el, index) => {
        if (el.bounding_box) {
          delete el.bounding_box;
        }

        const splitData = el.text.split(' ');
        let styring = '';
        if (splitData.length === 2) {
          styring = splitData[1].trim().replace(/-|:/g, '');
          styring = styring.toLowerCase().replace('o', '0');
        }

        // eslint-disable-next-line no-restricted-globals
        if (splitData.length === 2 && isNaN(splitData[0]) && !isNaN(styring)) {
          const chem = splitData[0].toLowerCase().trim();
          if (styring[styring.length - 1] === '.') {
            styring = styring.slice(0, -1);
          }
          const chemInpVal = styring;

          if (inpImage[chem]) {
            copInp[`${inpImage[chem]}`] = chemInpVal;
          }
        }
      });

      let imageStatus = true;
      let belowLimit = true;
      let blankFields = {};
      if (!cancelProcessing.cancel) {
        Object.keys(inpImage).forEach((chem) => {
          if (copInp[`${inpImage[chem]}`] === '') {
            imageStatus = false;
            blankFields[inpImage[chem]] = '';
          } else if (parseFloat((copInp[`${inpImage[chem]}`])) > limitInp[`${inpImage[chem]}`]) {
            copInp[`${inpImage[chem]}`] = '';
            imageStatus = false;
            belowLimit = false;
            blankFields[inpImage[chem]] = '';
          }
        });
      }

      setBlankInputFields(blankFields);
      if (!imageStatus) {
        toastMessage('error', 'Enter missing Reading(s), or use a better quality photo.');

        setAllFields(false);
      } else {
        setAllFields(true);
      }

      if (!cancelProcessing.cancel) {
        setTakingPh(copInp.ph);
        setPoolInputs(copInp);
      }

      setFetchImage(true);
      cancelProcessing.cancel = false;
    }
  };
  const handleBackVisitAppointment = () => {
    historyBack.push(`${getCalendarPath()}&chemicaltype=open`);
  };

  const handleAfterEditChemical = (data) => {
    setSelectedEditOption(data);
  };

  const handleSaveAfterEditChemicals = () => {
    if (selectedEditOption === 'calculatedCost') {
      toastMessage('success', 'Chemicals Updated Successfully');
      if (!editChemicals) setMultiEditQuantityStatus(!multiEditQuantityStatus);
      forChangingSubCategory();
      setSellPriceStatus(true);
      setStatus(false);
    } else {
      setMultiEditSellPriceStatus(true);
      setMultiEditQuantityStatus(false);
    }
    setEditChemicalPopUp(!editChemicalPopUp);
  };

  const handlePopUpChange = () => {
    let copyPoolInputs = { ...poolInputs };

    cancelProcessing.cancel = true;

    setFetchImage(true);
  };
  const PopUpText = `Uploading & ${valueType ? 'analysing' : 'analyzing'}  images takes between 3-20 seconds.`;
  const popupItalicText = '(depending on internet speed)';
  const warning = 'Check readings input into Calculator carefully, as no responsibility is taken for accuracy.';
  const paraText = 'Processing...';

  const handleSelect = async () => {
    if (Id) {
      if (chlorinationType === 'Convert Salt Pool to Mineral Pool' || selectedModel === 'Convert Pool to Mineral Pool') {
        setClientPopUpStatus(false);
        if (serviceAnalysis === 'Water Loss Calculator') {
          getWaterLossData();
        } else if (serviceAnalysis === 'Inverter Heater – Heating Time Required') {
          handleTimeToHeat();
        } else {
          handleAppointmentWaterTest();
        }
      } else {
        setClientPopUpStatus(true);
      }
    }
  };

  const handleCalculations = ({ e }) => {
    e.preventDefault();
    let isValid = false;

    if (Id) {
      const dat = clientAllDetails;

      if (dat?.chlorinationType === 'Mineral Pool with Chlorinator') {
        dat.chlorinationType = 'Mineral Pool';
      }

      if (dat?.selectedModel && dat?.chlorinationType) {
        if ((dat.selectedModel !== selectedModel
          || dat.chlorinationType !== chlorinationType
          || dat.poolFinish !== poolFinish
          || dat?.poolWidth !== poolInputs?.poolWidth
          || dat?.poolHeight !== poolInputs?.poolHeight
          || dat?.diameter !== poolInputs?.diameter
          || dat?.shallowEnd !== poolInputs?.shallowEnd
          || dat?.deepEnd !== poolInputs?.deepEnd
          || dat?.poolDepth !== poolDepth
        )) {
          isValid = true;
        }
      }
      if (!dat?.chlorinationType) {
        isValid = true;
      }
    }

    if (!isValid) {
      if (serviceAnalysis === 'Water Loss Calculator') {
        getWaterLossData();
      } else if (serviceAnalysis === 'Inverter Heater – Heating Time Required') {
        handleTimeToHeat();
      } else {
        handleAppointmentWaterTest();
      }
    } else {
      handleSelect();
    }
  };

  const handleDrainingCalculation = ({ e }) => {
    e.preventDefault();
    if (greenSplitName === 'Part') {
      handleCalculate({
        withDrainage: true,
        resDrainData: getresDrainData,
        balancing: true,
      });
    } else {
      handleCalculate({
        withDrainage: true,
        resDrainData: getresDrainData,
      });
    }
  };

  const updateSiteAddress = async () => {
    setClientPopUpStatus(false);
    if (serviceAnalysis === 'Water Loss Calculator') {
      getWaterLossData();
    } else if (serviceAnalysis === 'Inverter Heater – Heating Time Required') {
      handleTimeToHeat();
    } else {
      handleAppointmentWaterTest();
    }
    const copyClientData = { ...clientAllInfo };
    const beforePayload = { ...copyClientData?.clientAllDetails?.siteAddresses[finalIndex] };
    const payload = {
      ...beforePayload, selectedModelObj, selectedModel, chlorinationType, poolFinish, chlorinatorModel: chlorineModel, poolWidth: poolInputs?.poolWidth, poolHeight: poolInputs?.poolHeight,
    };
    const selectedClient = Id;
    copyClientData.clientAllDetails.siteAddresses[finalIndex] = payload;
    copyClientData.appointmentData.client.siteAddresses[finalIndex] = payload;
    clientAllData.clientAllDetails.siteAddresses[finalIndex] = payload;
    clientAllData.appointmentData.client.siteAddresses[finalIndex] = payload;
    const { associatedContacts, siteAddresses } = copyClientData?.appointmentData?.client;

    const response = await userInstance().put('/customer/updateSubfields', {
      associatedContacts,
      selectedClient,
      siteAddresses,
    });
    const { msg } = response.data;

    if (response?.data) {
      toastMessage('success', msg);
      localStorage.setItem('apptData', JSON.stringify(copyClientData));
    }
  };

  const updateClientDetails = async () => {
    setClientPopUpStatus(false);
    if (serviceAnalysis === 'Water Loss Calculator') {
      getWaterLossData();
    } else if (serviceAnalysis === 'Inverter Heater – Heating Time Required') {
      handleTimeToHeat();
    } else {
      handleAppointmentWaterTest();
    }

    const copyClientData = { ...clientAllInfo };
    const beforePayload = { ...copyClientData?.clientAllDetails };
    const selectedClient = Id;
    beforePayload.chlorinatorModel = chlorineModel;

    const {
      poolWidth,
      poolHeight,
      shallowEnd,
      deepEnd,
      diameter,
    } = poolInputs;

    const payload = {
      ...beforePayload,
      selectedModelObj,
      selectedModel,
      chlorinationType,
      poolFinish,
      poolDepth,
      poolWidth,
      poolHeight,
      shallowEnd,
      deepEnd,
      diameter,
    };

    copyClientData.clientAllDetails = payload;
    copyClientData.appointmentData.client = payload;
    clientAllData.clientAllDetails = payload;
    clientAllData.appointmentData.client = payload;

    try {
      const response = await userInstance().put('/xero/updateContact', { ...payload, selectedClient });
    } catch (error) {
      console.log('error', error);
    }

    const res = await userInstance().put('/customer/updateCustomer', { ...payload, selectedClient });
    const { msg } = res?.data;

    if (res?.data) {
      toastMessage('success', msg);

      localStorage.setItem('apptData', JSON.stringify(copyClientData));
    }
  };

  const handleUpdateClientDetails = () => {
    if (clientAllInfo?.clientAllDetails?.siteAddresses.length > 0) {
      updateSiteAddress();
    } else {
      updateClientDetails();
    }
  };

  const handleTitrationPopupChange = (titrationOption) => {
    setTitrationPopupOptions(titrationOption);
  };

  const handleSelectedCalciumOption = () => {
    if (titrationPopupOptions === 'dontShowCalcium') {
      setTitrationPopup(!titrationPopup);
      setShowCalcium(false);
    } else if (titrationPopupOptions === 'showCalcium') {
      setTitrationPopup(!titrationPopup);
      setShowCalcium(true);
    }
    handleCalculateFurtherProcess(getresDrainData, isStateFlagValue);
  };

  const handleCancleTitrationPopup = () => {
    // mineral changes
    setTitrationPopup(!titrationPopup);
    setTitrationPopupOptions('dontShowCalcium');
  };

  useEffect(() => {
    try {
      if (editChemicals) {
        const historyPool = clientAllInfo?.waterTestHistory?.poolInputs;
        const chemicalDisplayInCalculator = clientAllInfo?.waterTestHistory?.calciDisplayChemicals;
        const goingToUpdate = clientAllInfo?.waterTestHistory?.calVariable;
        const serviceArray = clientAllInfo?.appointmentData?.chemicalsArray[0]?.testResult[0]?.service;
        const chemTaxValue = clientAllInfo?.waterTestHistory?.taxApplicable?.chemicalsTax;
        const upTaxValue = chemTaxValue || 1;
        const updatedCalVariableObj = getUpdateCalVariable(goingToUpdate, serviceArray, upTaxValue);
        setCalVariable(updatedCalVariableObj);
        setResult(true);
        setPoolInputs(historyPool);
        if (chemicalDisplayInCalculator) {
          setCalciChemicalsDisplay(chemicalDisplayInCalculator);
        }
        setLoading(false);
      } else if (visitMessage && appId && chemicalTestComingArray?.length > 0) {
        const newArray = [];
        chemicalTestComingArray.forEach((el, index) => el.chemicalsArray.forEach((ele) => {
          let obj = {};
          obj._id = el._id;

          const test = ele?.testResult[0];

          const newMai = { ...test, ...obj };

          newArray.push(newMai);
        }));
        const historyPool = clientAllInfo?.waterTestHistory;

        const fIndex = newArray.findIndex((el) => el._id === appId);

        newArray.forEach((el) => {
          const serviceData = el?.service.map((ele) => {
            if (parseFloat(ele?.quantity) === 0 && ele?.subcategory?.includes('Add')) {
              ele.quantity = ele?.subcategory?.split(' ')[1] || '0.00';
            }
            return ele;
          });
          el.service = serviceData;
        });

        setLevelData(JSON.parse(JSON.stringify(newArray)));
        setStatus(false);
        setChemicalAddSelected(true);
        setResult(true);
        setOldLevelDataState(JSON.parse(JSON.stringify(newArray)));
        setChemicalArray(JSON.parse(JSON.stringify(newArray)));
        setPId(fIndex);
        setServiceAnalysis(historyPool?.serviceAnalysis);
        setPoolInputs(historyPool?.poolInputs);
        if (historyPool?.calciDisplayChemicals) {
          setCalciChemicalsDisplay(historyPool?.calciDisplayChemicals);
          setBackCalciChemicalsDisplay({ ...historyPool?.calciDisplayChemicals });
        }
        setLoading(false);
      } else if (visitMessage && startDateTime) {
        const newArray = clientAllInfo?.appointmentData?.chemicalsArray[0]?.testResult;
        const historyPool = clientAllInfo.waterTestHistory;
        setStatus(false);
        setServiceAnalysis(historyPool?.serviceAnalysis);
        setPoolInputs(historyPool?.poolInputs);
        setResult(true);
        setLevelData(JSON.parse(JSON.stringify(newArray)));
        setOldLevelDataState(JSON.parse(JSON.stringify(newArray)));
        setChemicalArray(JSON.parse(JSON.stringify(newArray)));
        setChemicalAddSelected(true);
        if (historyPool?.calciDisplayChemicals) {
          setCalciChemicalsDisplay(historyPool?.calciDisplayChemicals);
          setBackCalciChemicalsDisplay({ ...historyPool?.calciDisplayChemicals });
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }, []);

  const handleImageChange = async (e, typeImage) => {
    try {
      if (e.target.files.length > 0) {
        const formData = new FormData();
        if (typeImage !== 'typeImage') {
          setFetchImage(false);
        }
        cancelProcessing.cancel = false;
        formData.append('image1', e.target.files[0]);
        const copyInp = { ...poolInputs };
        const copyHexValues = { ...initialHexValues };
        let resCopy = {};
        const copyArr = { ...AllArray };
        const response = await axios.post(`${ocrPath}/ocr`, formData);

        if (response?.data === 'image processing error') {
          toastMessage('error', 'Image Processing Error');
          setFetchImage(true);
          return;
        }

        if (response) {
          const finalRes = response.data;
          if (finalRes?.Type === 'Strip' && !allowedPermission.stripUpload) {
            toastMessage('error', "You don't have permission to upload strip images");
            setFetchImage(true);
            return;
          }

          if (finalRes?.error) {
            let stripUploadErrorMessage = 'Please upload correct Image';
            if (finalRes?.error === 'strip detection error' || finalRes?.error === 'pad detection error') {
              stripUploadErrorMessage = 'Please upload a photo that shows the entire strip & ensure the strip is flat on the ground.';
            }
            setFetchImage(true);
            toastMessage('error', stripUploadErrorMessage);
            return;
          }

          if (typeImage === 'typeImage' && finalRes?.Type !== 'Strip') {
            toastMessage('error', 'Please Upload Strip Images.');
          }

          if (finalRes?.Type === 'Strip' && finalRes?.Images?.length > 0) {
            finalRes.Images.forEach((el, i) => {
              finalRes[`Box${i + 1}`] = el;
            });
          }
          copyInp.poolImageType = finalRes?.Type;

          const inp = finalRes?.Type === 'Strip' ? finalRes?.BoxCount > 4 ? { ...inpStripImage } : { ...inpStripImage4 } : { ...inpImage };
          let fullhardness = {
            calcium: false,
            magnesium: false,
            fullhardness: false,
          };
          if (Object.prototype.hasOwnProperty.call(finalRes, 'Mg HARD') && Object.prototype.hasOwnProperty.call(finalRes, 'Ca HARD')) {
            fullhardness.calcium = true;
            fullhardness.magnesium = true;
            fullhardness.fullhardness = true;
            if (chlorinationType === 'Calcium Hardness Titration Test') {
              setChlorinationType('Mineral Pool');
            }
            delete inp.COPPER;
          } else {
            delete inp['Mg HARD'];
            delete inp['Ca HARD'];
          }

          Object.keys(inp).forEach((chem) => {
            copyInp[`${inp[chem]}`] = '';
            if (finalRes[chem]) {
              copyInp[`${inp[chem]}`] = (finalRes[chem] === 'O' || finalRes[chem] === 'o') ? '0' : finalRes[chem];
              if (copyInp[`${inp[chem]}`].toString().includes('images/')) {
                copyInp[`${inp[chem]}`] = copyInp[`${inp[chem]}`].toString().split('/')[1];
              }
            }
          });
          let imageStatus = true;
          let belowLimit = true;
          let blankFields = {};

          if (!cancelProcessing.cancel) {
            Object.keys(inp).forEach((chem) => {
              // console.log('make===>>>r', finalRes[chem]);
              if (copyInp[`${inp[chem]}`] === '') {
                imageStatus = false;
                blankFields[inp[chem]] = '';
              } else if (parseFloat((copyInp[`${inp[chem]}`])) > limitInp[`${inp[chem]}`]) {
                copyInp[`${inp[chem]}`] = '';
                imageStatus = false;
                belowLimit = false;
                blankFields[inp[chem]] = '';
              }
              // }
              if (finalRes?.Type === 'Strip' && !chem.includes('Box') && !chem.includes('HEX')) {
                copyHexValues[inp[chem]] = copyInp[inp[`${chem}_HEX`]];
                // console.log('inputChem', inp[chem]);
                // const opy = handleBoxInput(inp[chem], copyInp[`${inp[chem]}`], 'strip', copyArr, 'initial', copyInp[`${inp[chem]}Hex`]);
                // copyArr[inp[chem]] = opy[inp[chem]];

                // console.log(copyInp, 'inputChem', chem, inp[`${chem}_HEX`], copyInp[(inp[`${chem}_HEX`])]);
                const opy = handleBoxInput(inp[chem], copyInp[`${inp[chem]}`], 'strip', copyArr, 'initial', copyInp[inp[`${chem}_HEX`]], 'readingImage');
                // console.log('opy', opy);
                copyArr[inp[chem]] = opy[inp[chem]];
              }
            });
          }

          if (serviceAnalysis === 'Mineral Pool') {
            blankFields.hardCalcium = '';
          }

          if (chlorinationType === 'Calcium Hardness Titration Test' || manualValue === 'Aquatherepe Transform Maintenance + Calcium Hardness Titration Test') {
            fullhardness.calcium = true;
            delete blankFields.hardMagnesium;
          }
          // mineral changes
          if (fullhardness?.fullhardness) {
            delete blankFields?.copper;
            setCalVariable({ ...calVariable, calciumHardness: 860 });
          } else {
            setCalVariable({ ...calVariable, calciumHardness: previousCalcium?.CalCalciumHardness });
          }
          // mineral changes
          if (fullhardness?.calcium) {
            setCalVariable({ ...calVariable, calciumHardness: 860 });
          }

          setShowFullHardness({
            ...fullhardness,
          });

          setBlankInputFields(blankFields);
          if (!imageStatus) {
            toastMessage('error', 'Enter missing Reading(s), or use a better quality photo.');

            setAllFields(false);
          } else {
            setAllFields(true);
          }

          // mineral changes

          if (!cancelProcessing.cancel) {
            if (finalRes.Type === 'Strip') {
              setTestStrip(true);
              if (finalRes?.BoxCount <= 4) {
                copyInp.combinedChlorine = '';
                copyInp.calciumHardness = '';
                copyInp.Box1 = '';
                copyInp.Box2 = '';
              }

              copyInp.copper = '';
              copyInp.salt = '';
              copyInp.phosphates = '';
              copyInp.hardCalcium = '';
              copyInp.hardMagnesium = '';

              if (validationData?.salt) {
                delete validationData.salt;
              }

              // const hideChemicals = {
              //   totalChlorine: false,
              //   calciumHardness: false,
              //   copper: false,
              //   phosphates: false,
              //   salt: false,
              // };

              // if (finalRes?.BoxCount > 4) {
              //   delete hideChemicals.totalChlorine;
              //   delete hideChemicals.calciumHardness;
              // }

              // setCalciChemicalsDisplay({ ...backcalciDisplayChemicals, ...hideChemicals });
            } else {
              // mineral changes
              copyInp.BoxCount = 0;
              setCalciChemicalsDisplay({ ...backcalciDisplayChemicals });
            }

            setTakingPh(copyInp.ph);
            setPoolInputs(copyInp);
            setOriginalReadings({ ...copyInp });
            setOldCopyPoolInputs({ ...copyInp });
            setAllArray(copyArr);
            setBackStatus(false);
            setHexObj(copyHexValues);
          }

          if (typeImage !== 'typeImage') {
            setFetchImage(true);
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const [forCancelButtonShow, setCancelButtonShow] = useState(false);
  const [backStatus, setBackStatus] = useState(false);

  const [errorValues, setErrorValue] = useState({});

  const handleOk = () => {
    const errors = {};
    let isValid = true;
    let errorsString = '';
    const inp = poolInputs.BoxCount > 4 ? inpStripImage : inpStripImage4;
    Object.keys(inp).forEach((el) => {
      if (!el.includes('Box') && !el.includes('HEX')) {
        const startValue = parseFloat(initialStripObject[inp[el]][0].value);
        const endValue = parseFloat(initialStripObject[inp[el]][4].value);
        const stripValue = poolInputs[inp[el]];

        if (!stripValue || (parseFloat(stripValue) > parseFloat(endValue) || parseFloat(stripValue) < parseFloat(startValue))) {
          if (errorsString) {
            errorsString = `${errorsString},${inp[el]}`;
          } else {
            errorsString = `${inp[el]}`;
          }
          errors[inp[el]] = true;
          isValid = false;
        }
      }
    });

    if (!isValid) {
      setErrorValue(errors);
      setTimeout(() => {
        setErrorValue({});
      }, 1000);
      return toastMessage('error', 'Please Check Input Readings.');
    }
    setErrorValue({});

    if (chlorinationType !== 'Manually Chlorinated') {
      setTestValueStatus(true);
    } else {
      setTestStrip(false);
      setAllArray(JSON.parse(JSON.stringify(initialStripObject)));
      setBackStatus(true);
    }
  };

  const handleBackStrip = () => {
    setTestStrip(false);
    setAllArray(JSON.parse(JSON.stringify(initialStripObject)));
    setBackStatus(true);
  };

  const boxChange = (name, value) => (e) => {
    const copyStr = { ...poolInputs };
    copyStr[name] = value;

    setPoolInputs(copyStr);
  };

  const handleOriginalReadings = (boxCount) => () => {
    const copyArr = { ...AllArray };
    const copyOriginal = { ...originalReadings };
    const inp = boxCount > 4 ? inpStripImage : inpStripImage4;

    Object.keys(inp).forEach((chem) => {
      if (!chem.includes('Box') && !chem.includes('HEX')) {
        const opy = handleBoxInput(
          inp[chem],
          copyOriginal[`${inp[chem]}`],
          'strip',
          copyArr,
          'initial',
          hexObj[inp[chem]],
        );
        copyArr[inp[chem]] = opy[inp[chem]];
      }
    });
    setAllArray(copyArr);
    setPoolInputs({ ...originalReadings });
    setShowBtn(false);
  };

  const handleCancelStrip = () => {
    setTestStrip(true);
    setCancelButtonShow(false);
  };
  const handleTestStripPage = () => {
    const copyArr = { ...AllArray };
    const copyInputsStrip = { ...poolInputs };
    const copyOriginal = { ...originalReadings };

    let showButton = false;
    const inp = poolInputs.BoxCount > 4 ? inpStripImage : inpStripImage4;
    Object.keys(inp).forEach((chem) => {
      if (!chem.includes('Box') && !chem.includes('HEX')) {
        const isOriginal = Number(copyOriginal[inp[chem]]) === Number(copyInputsStrip[`${inp[chem]}`]);
        const opy = handleBoxInput(
          inp[chem],
          copyInputsStrip[`${inp[chem]}`],
          'strip',
          copyArr,
          'initial',
          isOriginal ? hexObj[inp[chem]] : '',
        );
        copyArr[inp[chem]] = opy[inp[chem]];
        if (copyInputsStrip[inp[chem]] !== originalReadings[inp[chem]]) {
          showButton = true;
        }
      }
    });
    setShowBtn(showButton);
    setAllArray(copyArr);
    setTestStrip(true);
    setBackStatus(false);
  };

  const handlClickOutside = () => {
    const inp = poolInputs.BoxCount > 4 ? inpStripImage : inpStripImage4;
    const copyInputsStrip = { ...poolInputs };
    let showButton = false;
    Object.keys(inp).forEach((chem) => {
      if (!chem.includes('Box') && copyInputsStrip[inp[chem]] !== originalReadings[inp[chem]]) {
        showButton = true;
      }
    });
    setShowBtn(showButton);
  };

  const handleTestStripValueChange = (valTest) => {
    setTestConfirmValue(valTest);
  };

  const handleConfirmTestStrip = () => {
    let saltStatus = true;
    blankInputFields.salt = '';
    if (testConfirmValue === 'Don’t Test Salt Levels') {
      saltStatus = false;
      delete blankInputFields.salt;
      poolInputs.salt = '';
    }

    if (testConfirmValue === 'Check Salt Readings') {
      if (poolInputs.salt) {
        delete blankInputFields.salt;
      }
    }

    setCalciChemicalsDisplay({ ...calciDisplayChemicals, salt: saltStatus });
    setTestValueStatus(false);
    setTestStrip(false);
    setAllArray(JSON.parse(JSON.stringify(initialStripObject)));
    setBackStatus(true);
  };
  const handleRemove = (index, numericValue) => {
    let copyLevelData = [...chemicalArray];
    const parentId = chemicalArray.findIndex((el) => el?.numericValue === numericValue);
    if (parentId === -1) return;
    const copyService = copyLevelData[parentId]?.service?.map((a) => ({ ...a }));
    const message = copyService[index].subcategory.split(/add/i)[1];
    copyService[index].subcategory = `${message} - Not Added`;
    copyService[index].renderCost = 'N/A';
    copyService[index].cost = 'N/A';
    copyService[index].buyCostProduct = 'N/A';
    copyService[index].laborMargin = 'N/A';
    copyService[index].duration = '0';

    copyLevelData[parentId].service = copyService;
    setChemicalArray(copyLevelData);
  };

  const handleInverterInputs = (name, value, poolCapacity, size) => {
    const copyInputs = { ...poolInputs };
    if (name) {
      copyInputs[name] = value;
    }
    copyInputs.poolCapacity = poolCapacity;
    const {
      requiredPoolTemp, currentPoolTemp, ambientAirTemp,
    } = copyInputs;
    if (requiredPoolTemp || currentPoolTemp) {
      copyInputs.increaseInPoolTemp = Number(requiredPoolTemp) - Number(currentPoolTemp);
    }

    if (poolCapacity) {
      const MultiplyValue = size === '12' ? 0.28 : size === '17' ? 0.45 : 0.54;
      const value = (Number(ambientAirTemp) - 19) * MultiplyValue;
      const value2 = value + Number(size);
      const value3 = (value2 * 3412) / (Number(poolCapacity) * 4);
      console.log('vl', value, value2, value3, MultiplyValue);
      if (poolCapacity && value3) {
        copyInputs.tempRise = value3;
      }
    }
    setPoolInputs({ ...copyInputs });
  };

  const handleInverterSize = (e) => {
    const { value } = e.target;
    handleInverterInputs('', '', poolInputs?.poolCapacity, value);
    setInverterSize(value);
  };

  const handleTimeToHeat = () => {
    const {
      requiredPoolTemp, currentPoolTemp, ambientAirTemp, increaseInPoolTemp, tempRise, poolWidth, poolHeight, shallowEnd, deepEnd, diameter,
    } = poolInputs;

    window.scrollTo({ top: 0, behavior: 'smooth' });

    let validationObj = { requiredPoolTemp, currentPoolTemp, ambientAirTemp };

    if (poolDepth === 'Average Depth - Rectangular Pool') {
      validationObj.poolWidth = poolWidth;
      validationObj.poolHeight = poolHeight;
    } else if (poolDepth === 'Average Depth - Circular pool') {
      validationObj.diameter = diameter;
    } else if (poolDepth === 'Specific Depths - Rectangular Pool') {
      validationObj.poolWidth = poolWidth;
      validationObj.poolHeight = poolHeight;
      validationObj.shallowEnd = shallowEnd;
      validationObj.deepEnd = deepEnd;
    } else if (poolDepth === 'Specific Depths - Circular Pool') {
      validationObj.shallowEnd = shallowEnd;
      validationObj.deepEnd = deepEnd;
      validationObj.diameter = diameter;
    }
    const returnedData = validateData(validationObj);

    if (!returnedData.isValid) {
      setValData({ ...initialValidationData, ...returnedData.emptyField });
      return;
    }

    if ((Number(requiredPoolTemp) <= Number(currentPoolTemp))) {
      toastMessage('error', 'Required pool temperature needs to be higher than the current pool temperature.');
      return;
    }

    const result = Number(poolInputs?.increaseInPoolTemp) / Number(poolInputs.tempRise);
    setPoolInputs({ ...poolInputs, timeToHeat: result });
    setInverterResult(true);
    encryptPool();
  };

  return (
    <>
      {' '}
      {testStrip
        ? (
          <>
            <TestStripe
              poolInputs={poolInputs}
              handleInputs={handleInputs}
              handleImageChange={handleImageChange}
              handleOk={handleOk}
              boxChange={boxChange}
              AllArray={AllArray}
              limitInp={limitInp}
              handleOriginalReadings={handleOriginalReadings}
              handleBackStrip={handleBackStrip}
              staticServerPathForImage={staticServerPathForImage}
              errorValues={errorValues}
              showbtn={showbtn}
              handlClickOutside={handlClickOutside}
            />
            {testValueStatus && (
              <TestStripConfirm
                handleTestStripValueChange={handleTestStripValueChange}
                handleConfirmTestStrip={handleConfirmTestStrip}
                testConfirmValue={testConfirmValue}
                testValueStatus={testValueStatus}
                setTestValueStatus={setTestValueStatus}
              />
            )}
          </>
        ) : (
          <>
            <Helmet>
              <html className="calculator-main" lang="en" />
            </Helmet>
            <AdminWrapper>
              {/* {console.log('loader', loading)} */}
              {loading ? <Loader /> : (
                <div
                  className={`content-container bg-lightblue calculator-container ${
                    result ? 'page-two' : (Id && status && !result) ? 'calendar-cross' : 'upload-img-section'
                  }`}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">

                        {(result && status) || (serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus && !result) || (serviceAnalysis === 'Water Loss Calculator' && waterLossStatus) || (serviceAnalysis === 'Inverter Heater – Heating Time Required' && inverterResult) ? (
                          <div className="back-option calc-option" onClick={() => handleBack('')}>
                            <span />

                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                            />

                          </div>
                        ) : null}
                        {(Id && status && !result) && (
                        <div className="back-option calc-option" onClick={handleBackVisitAppointment}>
                          <span />

                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                          />

                        </div>
                        )}

                        {status ? (
                          <div className="title">
                            <h3>
                              {serviceAnalysis === 'Pool Water Volume Verification via Salinity' ? (
                                <>
                                  Pool Water Volume Verification
                                  <br />
                                  (via Salinity)
                                </>
                              ) : 'Pool Maintenance Report'}
                            </h3>
                            {result || serviceAnalysis === 'Pool Water Volume Verification via Salinity' || serviceAnalysis === 'Water Loss Calculator' || greenSplitName === 'Drain' || serviceAnalysis === 'Inverter Heater – Heating Time Required' ? null : (
                              <div className="upload-img-calc">
                                <Form.Group className="" controlId="exampleForm.ControlInput1">
                                  <Form.Label htmlFor="upload-image">
                                    <i className="fa fa-camera" aria-hidden="true" />
                                  </Form.Label>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                            // onChange={handleFileInputChange}
                                    id="upload-image"
                                    name="images"
                                  />
                                </Form.Group>

                              </div>
                            )}

                          </div>
                        ) : null}

                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className={`form-container ${serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus ? 'waterVolume' : ''}`}>
                          <form>
                            <div className={`form-box ${selectedWaterlevel === 'Higher Water Level in Pool' || selectedWaterlevel === 'Lower Water Level in Pool' ? 'form-box-increase' : ''}`}>
                              {!result && (
                              <>
                                <CalculatorTop
                                  serviceAnalysis={serviceAnalysis}
                                  handleServiceAnalysis={handleServiceAnalysis}
                                  waterPoolStatus={waterPoolStatus}
                                  waterLossStatus={waterLossStatus}
                                  poolFinish={poolFinish}
                                  handlePoolFinish={handlePoolFinish}
                                  waterTestOptions={waterTestOptions}
                                  handleWaterTestOptions={handleWaterTestOptions}
                                />

                                {serviceAnalysis === 'Green Pool' && (
                                <GreenPool
                                  handleGreenChange={handleGreenChange}
                                  handleGreenPoolTreatments={handleGreenPoolTreatments}
                                  greenTreatment={greenTreatment}
                                  greenSelect={greenSelect}
                                />
                                ) }

                                {serviceAnalysis === 'Black-Spot Algae' && (
                                <BlackSpotPool
                                  handleGreenChange={handleBlackChange}
                                  handleBlackTreatment={handleBlackTreatment}
                                  greenTreatment={blackTreatment}
                                  greenSelect={blackSelect}
                                />
                                ) }

                                {serviceAnalysis === 'Drain Pool' && (
                                <DrainPool
                                  handleGreenPoolTreatments={handleGreenPoolTreatments}
                                  greenTreatment={greenTreatment}
                                  greenSplitName={greenSplitName}
                                  poolInputs={poolInputs}
                                  result={result}
                                  handleInputs={handleInputs}
                                  poolFinish={poolFinish}
                                  valueType={valueType}
                                />
                                ) }
                                {serviceAnalysis === 'Sand Filter Media Replacement' && (
                                <PoolReport
                                  filterOptions={filterOptions}
                                  handleFilterMediaOptions={handleFilterMediaOptions}
                                  handleFilterCheckChange={handleFilterCheckChange}
                                  filterSelect={filterSelect}
                                  poolFilterData={poolFilterData}
                                  handleReset={handleReset}
                                />
                                )}

                                {serviceAnalysis === 'Water Loss Calculator'
                                  ? (
                                    <WaterLossCalculator
                                      poolInputs={poolInputs}
                                      result={result}
                                      handleInputs={handleInputs}
                                      waterLossStatus={waterLossStatus}
                                      waterPoolStatus={waterPoolStatus}
                                      handleWaterLossCalculator={handleWaterLossCalculator}
                                      waterLossCalculator={waterLossCalculator}
                                      valueType={valueType}
                                    />
                                  )
                                  : null}

                              </>
                              )}

                              {serviceAnalysis !== 'Pool Water Volume Verification via Salinity'
                                ? (
                                  <CalculatorMiddle
                                    poolInputs={poolInputs}
                                    handleInputs={handleInputs}
                                    result={result}
                                    serviceAnalysis={serviceAnalysis}
                                    handlePoolDepth={handlePoolDepth}
                                    poolDepth={poolDepth}
                                    waterPoolStatus={waterPoolStatus}
                                    calVariable={calVariable}
                                    validationData={validationData}
                                    customDepths={customDepths}
                                    status={status}
                                    diameterInput={diameterInput}
                                    waterLossStatus={waterLossStatus}
                                    valueType={valueType}
                                    inverterResult={inverterResult}
                                  />
                                )
                                : null}

                              {(result || serviceAnalysis === 'Inverter Heater – Heating Time Required') ? null : (
                                <PoolMainDataSelection
                                  poolInputs={poolInputs}
                                  result={result}
                                  chlorinationType={chlorinationType}
                                  getValue={getValue}
                                  serviceAnalysis={serviceAnalysis}
                                  greenSplitName={greenSplitName}
                                  handleChlorination={handleChlorination}
                                  handleManualValues={handleManualValues}
                                  waterPoolStatus={waterPoolStatus}
                                  showModel={showModel}
                                  chlorineModel={chlorineModel}
                                  handleChlorinationModel={handleChlorinationModel}
                                  manualValue={manualValue}
                                  selectedModel={selectedModel}
                                  waterLossCalculator={waterLossCalculator}
                                  waterLossStatus={waterLossStatus}
                                  valueType={valueType}
                                  showFullHardness={showFullHardness}
                                />
                              )}

                              {serviceAnalysis === 'Pool Water Volume Verification via Salinity' && !result ? (
                                <WaterVolumeSalinity
                                  poolInputs={poolInputs}
                                  handleInputs={handleInputs}
                                  result={result}
                                  chlorinationType={chlorinationType}
                                  getValue={getValue}
                                  serviceAnalysis={serviceAnalysis}
                                  selectedWaterLevelAmount={selectedWaterLevelAmount}
                                  selectedWaterlevel={selectedWaterlevel}
                                  handleWaterLevelChange={handleWaterLevelChange}
                                  handleAmountWaterLevel={handleWaterLevelChange}
                                  waterPoolStatus={waterPoolStatus}
                                  MaxLength={MaxLength}
                                  validationData={validationData}
                                  valueType={valueType}
                                />
                              ) : null}

                              {serviceAnalysis === 'Inverter Heater – Heating Time Required'
                                ? (
                                  <InverterHeater
                                    poolInputs={poolInputs}
                                    inverterResult={inverterResult}
                                    handleInverterInputs={(e) => handleInputs(e, 'inverter')}
                                    inverterSize={inverterSize}
                                    handleInverterSize={handleInverterSize}
                                    valueType={valueType}
                                    validationData={validationData}
                                  />
                                )
                                : null}

                              {status ? (
                                <div className="hide-section">
                                  {(serviceAnalysis === 'Pool Water Volume Verification via Salinity'
                                   || serviceAnalysis === 'Water Loss Calculator'
                                    || greenSplitName === 'Drain'
                                     || serviceAnalysis === 'Inverter Heater – Heating Time Required') ? null : (
                                       <CalculatorReadings
                                         result={result}
                                         calVariable={calVariable}
                                         poolInputs={poolInputs}
                                         handleInputs={handleInputs}
                                         chlorinationType={chlorinationType}
                                         manualValue={manualValue}
                                         serviceAnalysis={serviceAnalysis}
                                         waterTestOptions={waterTestOptions}
                                         greenSplitName={greenSplitName}
                                         validationData={validationData}
                                         allFields={allFields}
                                         limitInp={limitInp}
                                         valueType={valueType}
                                         calciDisplayChemicals={calciDisplayChemicals}
                                         showFullHardness={showFullHardness}
                                         blankInputFields={blankInputFields}
                                         showCalcium={showCalcium}
                                         titrationPopupOptions={titrationPopupOptions}
                                       />
                                    )}
                                </div>
                              ) : (
                                <GreenpoolWorking
                                  handleBack={handleBack}
                                  greenApiData={greenApiData}
                                  levelData={levelData}
                                  setLevelData={setLevelData}
                                  calVariable={calVariable}
                                  poolInputs={poolInputs}
                                  calculateEndingTimeArr={calculateEndingTimeArr}
                                  greenSplitName={serviceAnalysis === 'Black-Spot Algae' ? blackSplitName : greenSplitName}
                                  labourCostCalculation={labourCostCalculation}
                            // handlingGreenState={handlingGreenState}
                                  handleCalculate={handleCalculate}
                                  chemicalBalanceStatus={chemicalBalanceStatus}
                                  serviceAnalysis={serviceAnalysis}
                                  setResult={setResult}
                                  checkingDrainStatus={checkingDrainStatus}
                                  updatedWaterSourceStatus={updatedWaterSourceStatus}
                                  getresDrainData={getresDrainData}
                                  waterHistory={waterHistory}
                                  balanceZodiac={chemicalBalanceWaterObj.totalhardnessbalanceName}
                                  zodiacStatus={getZodiacStatus}
                                  chemicalaArray={chemicalArray}
                                  sethistoryData={sethistoryData}
                                  diameterInput={diameterInput}
                                  customDepths={customDepths}
                                  valueType={valueType}
                                />
                              )}
                            </div>

                            {status && (
                            <div className="hide-section">
                              <Form.Group>
                                <Form.Row className={`calc-btn-bottom ${greenSplitName === 'Drain' ? 'hide-btn-section' : ''}`}>
                                  <Col>
                                    {waterLossStatus || inverterResult || result || (serviceAnalysis === 'Pool Water Volume Verification via Salinity' && waterPoolStatus) ? (
                                      <div className="show">
                                        <div className={`btn-show ${serviceAnalysis === 'Water Loss Calculator' && waterPoolStatus ? 'waterPoolBack' : ''}`}>
                                          <div className="back-btn">
                                            <Button
                                              type="button"
                                              onClick={() => handleBack('')}
                                            >
                                              <span>
                                                <i className="fas fa-chevron-left" />
                                              </span>
                                              Back
                                            </Button>
                                          </div>
                                          {calVariable.isDrainage !== null
                                    && calVariable.isDrainage === true ? (
                                      <div className="calculate-btn draining-btn">
                                        <Button
                                          type="button"
                                          className="btn btn-primary withoutdrainage"
                                          onClick={(e) => handleDrainingCalculation({ e })}
                                        >
                                          <span>
                                            <img src={draining} alt="drain" />
                                          </span>
                                          {greenSplitName === 'Part' ? 'No Chemicals' : 'No Draining'}
                                        </Button>
                                      </div>
                                            ) : null}
                                          {(serviceAnalysis === 'Pool Water Volume Verification via Salinity' || waterLossStatus || inverterResult) ? null : (
                                            <div className="save-btn drain-btn">
                                              <Button
                                                type="button"
                                                onClick={() => {
                                                  SaveWaterTest();
                                                }}
                                              >
                                                Next

                                                <span> ( 1 of 3)</span>
                                              </Button>
                                            </div>
                                          )}

                                        </div>
                                      </div>

                                    ) : (
                                      <div className={`ml-auto calc-btn-reset ${backStatus ? 'draining-btn-main' : ''} ${serviceAnalysis === 'Water Loss Calculator' ? 'wasted-water-btn' : ''}`}>
                                        <div className="back-btn">
                                          {/* draining-btn-main */}
                                          {forCancelButtonShow ? (
                                            <Button type="button" onClick={handleCancelStrip}>
                                              Cancel
                                            </Button>
                                          )
                                            : (
                                              <Button type="button" onClick={handleReset}>
                                                Reset
                                              </Button>
                                            )}
                                        </div>
                                        {backStatus && (
                                        <div className="calculate-btn draining-btn calc-draining-btn">
                                          <Button
                                            type="button"
                                            className="btn btn-primary withoutdrainage"
                                            onClick={handleTestStripPage}
                                          >
                                            Test Strip Page
                                          </Button>
                                        </div>
                                        )}

                                        {waterLossStatus && serviceAnalysis === 'Water Loss Calculator' ? null : (
                                          <div className="calculate-btn">
                                            <Button
                                              type="submit"
                                              className="btn btn-primary btn-save"
                                              onClick={(e) => handleCalculations({ e })}
                                            >
                                              Calculate
                                            </Button>
                                          </div>
                                        )}

                                      </div>
                                    )}
                                  </Col>
                                </Form.Row>
                              </Form.Group>

                            </div>
                            )}

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {result && status ? (
                <CalculatorSalt
                  calVariable={calVariable}
                  poolInputs={poolInputs}
                  chlorinationType={chlorinationType}
                  bagsSalt={bagsSalt}
                  getInGrams={getInGrams}
                  getUnit={getUnit}
                  serviceAnalysis={serviceAnalysis}
                  manualValue={manualValue}
                  waterTestOptions={waterTestOptions}
                  selectedModel={selectedModel}
                  taxApplicable={taxApplicable}
                  calciDisplayChemicals={calciDisplayChemicals}
                  showFullHardness={showFullHardness}
                  blankInputFields={blankInputFields}
                  showCalcium={showCalcium}
                  titrationPopupOptions={titrationPopupOptions}
                  showDrainCost={showDrainCost}
                  greenSplitName={greenSplitName}
                />
              ) : null}

              {sourceReading ? (
                <UpdateSourceReading
                  sourceReading={sourceReading}
                  setSoourceReading={setSoourceReading}
                  handleWaterSourceChange={handleWaterSourceChange}
                  waterSourceSelect={waterSourceSelect}
                  handleSetSourceSelected={handleSetSourceSelected}
                />
              ) : null}
              {sourceSelected ? (
                <SourceReading
                  sourceSelected={sourceSelected}
                  setSourceSelected={setSourceSelected}
                  handleInputs={handleInputs}
                  poolInputs={poolInputs}
                  handleUpdateWaterSourceReadings={handleUpdateWaterSourceReadings}
                  sourcePoolInputs={sourcePoolInputs}
                  handleSourceInputs={handleSourceInputs}
                />
              ) : null}

              {chemicalAddSelected
                ? (
                  <ChemicalsAddedPopUp
                    chemicalAddSelected={chemicalAddSelected}
                    setChemicalAddSelected={setChemicalAddSelected}
                    handleChemicalsSelectPop={handleChemicalsSelectPop}
                    handleChemicalPopUpChange={handleChemicalPopUpChange}
                    chemicalOptionsSelected={chemicalOptionsSelected}
                    editPricePermission={allowedPermission.editPrice}
                  />
                ) : null}

              {editQuantityStatus ? (
                <EditChemicalQuantity
                  editQuantityStatus={editQuantityStatus}
                  handleChemicalQuantityPopup={handleChemicalQuantityPopup}
                  chemicalArray={chemicalArray}
                  handleQuantityChange={handleQuantityChange}
                  handleSaveUpdate={handleSaveUpdate}
                  handleReverQuantitytUpdate={handleReverQuantitytUpdate}
                  totalBuyCost={totalBuyCost}
                  totalSellCost={totalSellCost}
                  consumableMargin={consumableMargin}
                  levelData={levelData}
                  checkingDrainStatus={checkingDrainStatus}
                  pId={pId}
                  handleRemove={handleRemove}
                />
              ) : null}
              {editSellPriceStatus ? (
                <EditChemicalSellPrice
                  editSellPriceStatus={editSellPriceStatus}
                  handleChemicalSellPricePopup={handleChemicalSellPricePopup}
                  chemicalArray={chemicalArray}
                  totalBuyCost={totalBuyCost}
                  totalSellCost={totalSellCost}
                  handleSellPriceChange={handleSellPriceChange}
                  handleSellUpdate={handleSellUpdate}
                  handleRevertSellPriceUpdate={handleRevertSellPriceUpdate}
                  consumableMargin={consumableMargin}
                  consumableDatabaseMargin={consumableDatabaseMargin}
                  sellPriceStatus={sellPriceStatus}
                  levelData={oldLevelDataState}
                  checkingDrainStatus={checkingDrainStatus}
                  finalPriceStatus={finalPriceStatus}
                  pId={pId}
                />
              ) : null}

              {multiEditQuantityStatus ? (
                <MultiEditQuantityPopUp
                  multiEditQuantityStatus={multiEditQuantityStatus}
                  handleChemicalQuantityPopup={handleChemicalQuantityPopup}
                  chemicalArray={chemicalArray}
                  handleQuantityChange={handleQuantityChange}
                  handleSaveUpdate={handleSaveUpdate}
                  handleReverQuantitytUpdate={handleReverQuantitytUpdate}
                  totalBuyCost={totalBuyCost}
                  totalSellCost={totalSellCost}
                  consumableMargin={consumableMargin}
                  levelData={levelData}
                  checkingDrainStatus={checkingDrainStatus}
                  pId={pId}
                  handleRemove={handleRemove}
                  serviceAnalysis={serviceAnalysis}
                />
              ) : null}

              {multiEditSellPriceStatus ? (
                <MultiEditChemicalSellPrice
                  multiEditSellPriceStatus={multiEditSellPriceStatus}
                  handleChemicalSellPricePopup={handleChemicalSellPricePopup}
                  chemicalArray={chemicalArray}
                  totalBuyCost={totalBuyCost}
                  totalSellCost={totalSellCost}
                  handleSellPriceChange={handleSellPriceChange}
                  handleSellUpdate={handleSellUpdate}
                  handleRevertSellPriceUpdate={handleRevertSellPriceUpdate}
                  consumableMargin={consumableMargin}
                  consumableDatabaseMargin={consumableDatabaseMargin}
                  sellPriceStatus={sellPriceStatus}
                  levelData={oldLevelDataState}
                  checkingDrainStatus={checkingDrainStatus}
                  finalPriceStatus={finalPriceStatus}
                  pId={pId}
                  serviceAnalysis={serviceAnalysis}
                />
              ) : null}

              {editChemicalPopUp ? (
                <AfterEditChemicalPopUp
                  selectedEditOption={selectedEditOption}
                  editChemicalPopUp={editChemicalPopUp}
                  setEditChemicalPopUp={setEditChemicalPopUp}
                  handleAfterEditChemical={handleAfterEditChemical}
                  handleSaveAfterEditChemicals={handleSaveAfterEditChemicals}
                  editPricePermission={allowedPermission.editPrice}
                />
              ) : null}

              {zodiacStatus ? (
                <ZodiacCalciumPopUp
                  setZodiacStatus={setZodiacStatus}
                  handleZodiacCalcium={handleZodiacCalcium}
                  zodiacStatus={zodiacStatus}
                  waterTestOptions={waterTestOptions}
                />
              )
                : null}

              {fetchImage ? null : (
                <DeletePopUp
                  setDeletePopUp={setFetchImage}
                  handlePopUpChange={handlePopUpChange}
                  PopUpText={PopUpText}
                  paraText={paraText}
                  warning={warning}
                  popupItalicText={popupItalicText}
                />
              )}

              {clientPopUpStatus && (
              <ClientDetailsUpdatePopUp
                clientPopUpStatus={clientPopUpStatus}
                setClientPopUpStatus={setClientPopUpStatus}
                handleUpdateClientDetails={handleUpdateClientDetails}
                clientSelectStatus={clientSelectStatus}
                setClientSelectStatus={clientSelectStatus}
              />
              )}
              {titrationPopup && (
                <CalciumTitrationPopup
                  titrationPopup={titrationPopup}
                  setTitrationPopup={setTitrationPopup}
                  titrationPopupOptions={titrationPopupOptions}
                  setTitrationPopupOptions={setTitrationPopupOptions}
                  handleTitrationPopupChange={handleTitrationPopupChange}
                  handleSelectedCalciumOption={handleSelectedCalciumOption}
                  handleCancleTitrationPopup={handleCancleTitrationPopup}
                />
              )}

            </AdminWrapper>
          </>
        )}
    </>
  );
}
export default Calculator;
