/* eslint-disable arrow-body-style */
import axios from 'axios';
import { server } from './keys';

// eslint-disable-next-line arrow-body-style
// eslint-disable-next-line import/prefer-default-export
export const withoutAuth = () => {
  return axios.create({
    baseURL: `${server}`,
  });
};

export const userInstance = () => {
  return axios.create({
    baseURL: server,
    headers: {
      Authorization: localStorage.getItem('s#@4f')
        ? `${'Bearer '}${localStorage.getItem('s#@4f')}`
        : '',
      authtoken: localStorage.getItem('f#@5e')
        ? `${'Bearer '}${localStorage.getItem('f#@5e')}`
        : '',
    },
    timeout: 1000 * 20,
  });
};

// export const staffInstance = () => {
//   return axios.create({
//     baseURL: server,
//     headers: {
//       Authorization: localStorage.getItem('f#@5e')
//         ? `${'Bearer '}${localStorage.getItem('f#@5e')}`
//         : '',
//     },
//     timeout: 1000 * 20,
//   });
// };

export const notTimeOutUserInstance = () => {
  return axios.create({
    baseURL: server,
    headers: {
      Authorization: localStorage.getItem('s#@4f')
        ? `${'Bearer '}${localStorage.getItem('s#@4f')}`
        : '',
      authtoken: localStorage.getItem('f#@5e')
        ? `${'Bearer '}${localStorage.getItem('f#@5e')}`
        : '',
    },
  });
};
