import React, { useState, useEffect } from 'react';
import {
  Dropdown, Form, Button, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import showmore from '../../assets/home/showmoreblue.png';
import clientimg from '../../assets/home/client.png';
import greydelete from '../../assets/home/grey-delete.png';
import edit from '../../assets/home/grey-edit.png';
import { userInstance } from '../../config/axios';
import { queryString } from '../../functions/index';
import SearchModal from '../invoices/searchmodal';
import DatePopupModal from '../invoices/datePopup';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import sortClientList from '../../functions/listSort';
import toastMessage from '../../functions/toastMessage';

const SearchPayments = () => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  const [paymentFrom, setPaymentFrom] = useState(moment(new Date()).format('D MMM YYYY'));
  const [paymentTo, setPaymentTo] = useState(moment(new Date()).format('D MMM YYYY'));
  const [invoiceFrom, setInvoiceFrom] = useState('');
  const [invoiceTo, setInvoiceTo] = useState('');
  const [invoiceStart, setInvoiceStart] = useState(moment(new Date()).format('D MMM YYYY'));
  const [invoiceEnd, setInvoiceEnd] = useState(moment(new Date()).format('D MMM YYYY'));
  const [invoiceTotalFrom, setInvoiceTotalFrom] = useState('');
  const [invoiceTotalTo, setInvoiceTotalTo] = useState('');
  const [list, setList] = useState([]);
  const [client, setclient] = useState();
  const [clientTxt, setClientTxt] = useState('');
  const [searchedClients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [addClient, setAddClient] = useState([]);
  const [datePopup, setDatePopup] = useState();
  const [type, setType] = useState();
  const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('D MMM YYYY'));
  const [clientType, setClientType] = useState('allCustomers');
  const selectClient = queryString();
  const prevClient = selectClient ? selectClient.client : '';
  const [isHideSearch, setHideSearch] = useState(false);
  const [activeAdd, setActiveAdd] = useState(0);
  const [searchedClients2, setClients2] = useState([]);
  const [isloader, setisloader] = useState(false);

  const SearchPayment = async () => {
    try {
      setisloader(true);
      if ((clientType === 'selectedCustomer' && selectedClient) || clientType !== 'selectedCustomer') {
        let arr = [];
        if (clientType === 'selectedCustomer') {
          arr = [selectedClient];
        } else {
          searchedClients.forEach((el) => {
            arr.push(el._id);
          });
        }
        const payload = {
          paymentFrom: moment(new Date(paymentFrom)).format('YYYY-MM-DD'),
          paymentTo: moment(new Date(paymentTo)).add(1, 'days').format('YYYY-MM-DD'),
          invoiceFrom: invoiceFrom.toString().length === 0 ? null : invoiceFrom,
          invoiceTo: invoiceTo.toString().length === 0 ? null : invoiceTo,
          invoiceStart: moment(new Date(invoiceStart)).format('YYYY-MM-DD'),
          invoiceEnd: moment(new Date(invoiceEnd)).add(1, 'days').format('YYYY-MM-DD'),
          invoiceTotalFrom: invoiceTotalFrom.toString().length === 0 ? null : invoiceTotalFrom,
          invoiceTotalTo: invoiceTotalTo.toString().length === 0 ? null : invoiceTotalTo,
          selectedClient: arr,
        };
        if ((paymentFrom !== '' && paymentTo !== '') && (paymentFrom > paymentTo)) {
          toastMessage('error', 'Payment Date (From) Should less than Payment Date (to)');
        }
        if ((invoiceFrom !== '' && invoiceTo !== '') && (Number(invoiceFrom) > Number(invoiceTo))) {
          toastMessage('error', 'Invoice No. (From) Should less than Invoice No. (to)');
        }
        if ((invoiceTotalFrom !== '' && invoiceTotalTo !== '') && (Number(invoiceTotalFrom) > Number(invoiceTotalTo))) {
          toastMessage('error', 'Invoice Total (From) Should less than Invoice Total (to)');
        }
        if ((invoiceStart !== '' && invoiceEnd !== '') && (invoiceStart > invoiceEnd)) {
          toastMessage('error', 'Invoice Date (From) Should less than Invoice Date (to)');
        }
        const response = await userInstance().post('/payment/searchPayment', payload);
        const { paymentData } = response.data;
        setList(paymentData);
        setisloader(false);
      } else {
        toastMessage('error', 'Please select client.');
        setisloader(false);
      }
    } catch (error) {
      console.log(error);
      //     console.log(error);
    }
  };
  const handleClient = () => {
    setclient(!client);
  };

  const searchClient = async (searchTxt) => {
    setClientTxt(searchTxt);
  };
  useEffect(() => {
    let searchTimeOut = setTimeout(async () => {
      if (!clientTxt) {
        setClients(searchedClients2);
        return;
      }
      try {
        const response = await userInstance().get(`/customer/searchCustomer?value=${clientTxt}`);
        let list2 = response.data.customers;
        if (clientTxt) {
          if (isHideSearch) {
            list2.forEach((client2) => {
              if (client2.siteAddressess) {
                client2.siteAddressess.forEach((addr) => {
                  addr.hide = false;
                });
              }
            });
          } else {
            list2 = list2.filter((el) => (el.siteAddresses ? el.siteAddresses.filter((adr) => adr.hide).length === 0 : el));
          }
        }
        const newList = sortClientList(list2);
        setClients(newList);
      } catch (error) {
        console.log(error);
      }
    }, 500);
    return (() => clearInterval(searchTimeOut));
  }, [clientTxt]);

  const getAllCustomers = async () => {
    try {
      const response = await userInstance().get('/customer/getCustomersAll');
      const { customers } = response.data;
      const newList = sortClientList(customers);
      setClients(newList);
      setClients2(newList);
      if (prevClient) {
        const findCustomer = customers.find((el) => el._id === prevClient);
        setSelectedClient(findCustomer._id);
        setAddClient([findCustomer]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCustomers();
  }, []);
  const handleAddClient = (info) => {
    const copy = { ...info };
    if (info.siteAddresses[activeAdd] || info.siteAddresses.length === 0) {
      let { activeSiteAdd } = info.siteAddresses[activeAdd]
        ? info.siteAddresses[activeAdd]
        : {};
      if (activeSiteAdd || info.siteAddresses.length === 0) {
        setSelectedClient(info._id);
        setAddClient([info]);
        handleClient();
        setClientTxt('');
      } else {
        toastMessage('error', 'Please select site address.');
      }
    } else {
      toastMessage('error', 'Please select site address.');
    }
  };

  //   const goToPayments = (info) => {
  //     window.location.href = `/invoicespayment?data=${info}`;
  //   };
  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
  const handleDatePopup = (e) => {
    // console.log(e);
    if (isValidDate(e)) {
      const date = moment(e).format('D MMM YYYY');
      if (type === 'paymentFrom') {
        setPaymentFrom(date);
      } else if (type === 'paymentTo') {
        setPaymentTo(date);
      } else if (type === 'invoiceStart') {
        setInvoiceStart(date);
      } else if (type === 'invoiceEnd') {
        setInvoiceEnd(date);
      }
    } else {
      setType(e);
      if (e === 'paymentFrom') {
        setSelectedDate(paymentFrom);
      } else if (e === 'paymentTo') {
        setSelectedDate(paymentTo);
      } else if (e === 'invoiceStart') {
        setSelectedDate(invoiceStart);
      } else if (e === 'invoiceEnd') {
        setSelectedDate(invoiceEnd);
      }
    }
    setDatePopup(!datePopup);
  };
  const checkAddress = (index, id) => {
    setActiveAdd(index);
    let oldState = [...searchedClients];
    oldState.forEach((el) => {
      if (el._id === id) {
        el.siteAddresses?.forEach((el2, i) => {
          if (index === i) {
            el2.activeSiteAdd = !el2.activeSiteAdd;
          } else {
            el2.activeSiteAdd = false;
          }
        });
      } else {
        el.siteAddresses.forEach((el2, i) => {
          el2.activeSiteAdd = false;
        });
      }
    });
    setClients(oldState);
  };
  return (
    <div className="invoice-detail search-invoice payment-search">
      <div className="pool-setup">
        <div className="target-heading">
          <h6>Search Payments</h6>
        </div>

        <div className="pool-chemistry-box appointment-service-modal">
          {prevClient && (
          <>
            {addClient.length === 0 ? (
              <div className="target-heading client-profile">
                <h3> Client </h3>
                <span className="plus-icon" onClick={handleClient} role="presentation">
                  <i className="fas fa-plus" />
                </span>
              </div>
            ) : (
              <div className="target-heading client-profile">
                {addClient && addClient.map((cl) => (
                  <>
                    <div className="">
                      <h3>{`${cl.firstname} ${cl.lastname}`}</h3>
                      <p>
                        {`${cl.streetAddress}${cl.suburb || cl.state ? '' : ''} ${cl.suburb
                        } ${cl.state}`}
                      </p>
                      <p>{cl.mobileNo}</p>
                      {' '}
                      search payment
                      {' '}
                      <p />
                      <div className="client-name-contact site-addresses ">
                        {activeAdd !== '' && activeAdd >= 0 && cl?.siteAddresses?.length > 0 && (
                        <div className="client-left-details">
                          <h5 className="client-company-name">
                            Site Address
                          </h5>
                          <p>
                            {cl?.siteAddresses[activeAdd]
                              .householderName
                              ? cl?.siteAddresses[activeAdd]
                                .householderName
                              : ''}
                          </p>
                          <p>
                            {cl?.siteAddresses[activeAdd].address
                              ? cl?.siteAddresses[activeAdd]
                                .address
                              : ''}
                            {' '}
                          </p>
                          <p>
                            <a
                              className="mobile"
                              href={`tel:${
                                cl?.siteAddresses[activeAdd]
                                  .mobileNo || ''
                              }`}
                            >
                              {cl?.siteAddresses[
                                activeAdd
                              ].mobileNo.includes('+61')
                                ? cl?.siteAddresses[activeAdd]
                                  .mobileNo
                                : `+61 ${
                                  cl?.siteAddresses[
                                    activeAdd
                                  ].mobileNo.replace(
                                    phoneRegex,
                                    '$1 $2 $3',
                                  ) || ''
                                }`}
                            </a>
                          </p>
                        </div>
                        )}
                      </div>
                    </div>
                  </>
                ))}
                <div className="content-dropdown">
                  <div className="client-option service-edit-icon">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={showmore} alt="show" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <div
                            className="action client"
                            onClick={() => {
                              handleClient();
                            }}
                          >
                            <img src={clientimg} alt="edit" />
                            Change Client
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <div className="action" onClick={() => setAddClient([])}>
                            <img src={greydelete} alt="edit" />
                            Delete
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            )}
          </>
          )}

          <div className="search-invoces-box">
            <h6>Search Criteria</h6>
            {!prevClient && (
              <>
                <div className="search-invoice-bg">
                  <div className="pending-water-test" onClick={() => setClientType('allCustomers')}>
                    <h3>Search All Customers</h3>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check type="checkbox" value="allCustomers" checked={clientType === 'allCustomers'} onChange={(e) => setClientType(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="pending-water-test" onClick={() => setClientType('selectedCustomer')}>
                    <h3>Search Selected Customers</h3>
                    <div className="open-date client-notes">
                      <div className="basic">
                        <Form.Check type="checkbox" value="selectedCustomer" checked={clientType === 'selectedCustomer'} onChange={(e) => setClientType(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  {clientType === 'selectedCustomer' && addClient && (
                  <>
                    {addClient.length === 0 ? (
                      <div className="target-heading client-profile">
                        <h3> Client </h3>
                        <span className="plus-icon" onClick={handleClient} role="presentation">
                          <i className="fas fa-plus" />
                        </span>
                      </div>
                    ) : (
                      <div className="target-heading client-profile">
                        {addClient && addClient.map((cl) => (
                          <>
                            <div className="">
                              <h3>{`${cl.firstname} ${cl.lastname}`}</h3>
                              <p>
                                {`${cl.streetAddress}${cl.suburb || cl.state ? '' : ''} ${cl.suburb
                                } ${cl.state}`}
                              </p>
                              <p>{cl.mobileNo}</p>
                              {' '}
                              search payment
                              {' '}
                              <p />
                              <div className="client-name-contact site-addresses ">
                                {activeAdd !== '' && activeAdd >= 0 && cl?.siteAddresses?.length > 0 && (
                                <div className="client-left-details">
                                  <h5 className="client-company-name">
                                    Site Address
                                  </h5>
                                  <p>
                                    {cl?.siteAddresses[activeAdd]
                                      .householderName
                                      ? cl?.siteAddresses[activeAdd]
                                        .householderName
                                      : ''}
                                  </p>
                                  <p>
                                    {cl?.siteAddresses[activeAdd].address
                                      ? cl?.siteAddresses[activeAdd]
                                        .address
                                      : ''}
                                    {' '}
                                  </p>
                                  <p>
                                    <a
                                      className="mobile"
                                      href={`tel:${
                                        cl?.siteAddresses[activeAdd]
                                          .mobileNo || ''
                                      }`}
                                    >
                                      {cl?.siteAddresses[
                                        activeAdd
                                      ].mobileNo.includes('+61')
                                        ? cl?.siteAddresses[activeAdd]
                                          .mobileNo
                                        : `+61 ${
                                          cl?.siteAddresses[
                                            activeAdd
                                          ].mobileNo.replace(
                                            phoneRegex,
                                            '$1 $2 $3',
                                          ) || ''
                                        }`}
                                    </a>
                                  </p>
                                </div>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                        <div className="content-dropdown">
                          <div className="client-option service-edit-icon">
                            <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <img src={showmore} alt="show" />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item>
                                  <div
                                    className="action client"
                                    onClick={() => {
                                      handleClient();
                                    }}
                                  >
                                    <img src={clientimg} alt="edit" />
                                    Change Client
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                  <div className="action" onClick={() => setAddClient([])}>
                                    <img src={greydelete} alt="edit" />
                                    Delete
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                  )}
                </div>
              </>
            )}
            <div className="row">
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Payment Date (From)</Form.Label>
                  {/* <Form.Control type="date" placeholder="" value={paymentFrom} onChange={(e) => setPaymentFrom(e.target.value)} /> */}
                  <div className="custom-datepicker" onClick={() => handleDatePopup('paymentFrom')}>{paymentFrom}</div>
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Payment Date (to)</Form.Label>
                  {/* <Form.Control type="date" placeholder="" value={paymentTo} onChange={(e) => setPaymentTo(e.target.value)} /> */}
                  <div className="custom-datepicker" onClick={() => handleDatePopup('paymentTo')}>{paymentTo}</div>
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Invoice No. (From)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    onKeyDown={(event) => {
                      if (event.key === '-' || event.key === '+' || event.key === 'e') {
                        event.preventDefault();
                      }
                    }}
                    value={invoiceFrom}
                    onChange={(e) => setInvoiceFrom(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Invoice No. (to)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    onKeyDown={(event) => {
                      if (event.key === '-' || event.key === '+' || event.key === 'e') {
                        event.preventDefault();
                      }
                    }}
                    value={invoiceTo}
                    onChange={(e) => setInvoiceTo(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Invoice Date (From)</Form.Label>
                  {/* <Form.Control type="date" placeholder="" value={invoiceStart} onChange={(e) => setInvoiceStart(e.target.value)} /> */}
                  <div className="custom-datepicker" onClick={() => handleDatePopup('invoiceStart')}>{invoiceStart}</div>
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Invoice Date (to)</Form.Label>
                  {/* <Form.Control type="date" placeholder="" value={invoiceEnd} onChange={(e) => setInvoiceEnd(e.target.value)} /> */}
                  <div className="custom-datepicker" onClick={() => handleDatePopup('invoiceEnd')}>{invoiceEnd}</div>
                </Form.Group>
              </div>
              <div className="col-md-12 col-12">
                <Form.Group className="search-box">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="" />
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Invoice Total (From)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    onKeyDown={(event) => {
                      if (event.key === '-' || event.key === '+' || event.key === 'e') {
                        event.preventDefault();
                      }
                    }}
                    value={invoiceTotalFrom}
                    onChange={(e) => setInvoiceTotalFrom(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6 col-6">
                <Form.Group className="search-box">
                  <Form.Label>Invoice Total (to)</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder=""
                    onKeyDown={(event) => {
                      if (event.key === '-' || event.key === '+' || event.key === 'e') {
                        event.preventDefault();
                      }
                    }}
                    value={invoiceTotalTo}
                    onChange={(e) => setInvoiceTotalTo(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <p className="group-time">* Less fields filled-in will broaden the search.</p>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn search-invoice-btn">
                  <Button
                    className="btn-save"
                    onClick={() => SearchPayment()}
                  >
                    {isloader
                      ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : 'Search'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {list && list?.length > 0 ? (
            <>
              {list && list.map((el) => (
                <div className="table-section">
                  <div className="invoices-table-left">
                    <div className="invoices-head">
                      <h6>{moment(el.createdAt).format('DD MMM')}</h6>
                      <h6>Invoice No.</h6>
                      <h6>Invoice Total</h6>
                      <h6>Amount Received</h6>
                      <h6>Payment Date</h6>
                    </div>
                    <div className="invoice-body">
                      <p>{moment(el.createdAt).format('YYYY')}</p>
                      <p>{el.invoice.invoiceNo}</p>
                      <p>
                        $
                        {el.invoice.invoiceTotal}
                      </p>
                      <p>
                        $
                        {el.amount}
                      </p>
                      <p>
                        {moment(el.createdAt).format('D MMMM')}
                        {moment(el.createdAt).format('YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <p className="table-section">No Payments Found</p>
          )}

        </div>
      </div>
      <SearchModal
        showSearch={client}
        handleShowSearch={handleClient}
        searchClient={searchClient}
        searchedClients={searchedClients}
        clientTxt={clientTxt}
        handleAddClient={handleAddClient}
        setHideSearch={setHideSearch}
        isHideSearch={isHideSearch}
        checkAddress={checkAddress}
        activeAdd={activeAdd}
      />
      <DatePopupModal datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} quoteDate={selectedDate} />
    </div>
  );
};
export default SearchPayments;
