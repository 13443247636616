// import React from 'react';
// import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import { Modal, Button } from 'react-bootstrap';
// import { userInstance } from '../../config/axios';
// import logo from '../../assets/home/logo-pool-2.png';

// const PreviewQuote = ({
//   showquote, handleQuote, addClient, addedServices,
//   quoteDate, summary, quotesTotal, gst, handleAddQuotes, quoteNumber,
// }) => {
//   const convertToReadableDuration = (data) => {
//     const hours = Math.floor(data / 60);
//     const minutes = data % 60;
//     let msg = '';
//     msg = hours && minutes ? `${hours}hr ${minutes} min` : hours && !minutes ? `${hours}hr` : `${minutes} min`;
//     return msg;
//   };

//   return (
//     <Modal
//       size="md"
//       show={showquote}
//       onHide={handleQuote}
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//       className="search-modal new-search-popup"
//     >
//       <div className="staff-close">
//         <i className="fa fa-times" onClick={handleQuote} aria-hidden="true" />
//       </div>
//       <Modal.Body>
//         <div className="appointment-container preview-invoice preview-popup preview-quote">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-md-12">
//                 <div className="add-appointment">
//                   <TransformWrapper>
//                     <TransformComponent>
//                       <div className="preview-invoice-main">
//                         <div className="top-logo">
//                           <img src={logo} alt="logo" />
//                           <p>
//                             5/607 St Kilda Road
//                             <br />
//                             {' '}
//                             Melboume VIIC 3004
//                           </p>
//                           <p>Call: 0409 774 687</p>
//                           <p>www.cleanpools.com.au</p>

//                         </div>
//                         <div className="tax-invoice">
//                           <p>
//                             Typecraft Pvt. Ltd. trading as CleanPools Pool Maintenance Experts
//                           </p>
//                         </div>
//                         <div className="invoices-listing-box invoices-bottom">

//                           {addClient && addClient.map((cl) => (
//                             <>
//                               <p>{`${cl.firstname} ${cl.lastname || ''}`}</p>
//                               <p className="cl-address">
//                                 {' '}
//                                 {`${cl.streetAddress}${cl.suburb ? cl.suburb : '' || cl.state ? '' : ''} ${cl.suburb || ''
//                                 } ${cl.state || ''}`}
//                               </p>
//                             </>
//                           ))}
//                           <div className="quote-date">
//                             <p>
//                               {`${moment(quoteDate).format(
//                                 'D MMMM YYYY',
//                               )}`}
//                             </p>
//                           </div>
//                           <div className="quote-info">
//                             <p>
//                               {addClient && addClient.map((cl) => (
//                                 <>
//                                   Dear
//                                   {' '}
//                                   {`${cl.firstname}`}
//                                   ,
//                                 </>
//                               ))}
//                               <br />
//                               Thank you for the time spent discussing your pool requirement. Please find our quote detailed below with our recommendations and costs.
//                             </p>
//                             <h6>
//                               Quote No.
//                               {quoteNumber ? (
//                                 <span>{quoteNumber}</span>

//                               ) : (
//                                 <span>&lt;Pending&gt;</span>
//                               )}
//                             </h6>
//                           </div>
//                           <div className="quote-description">
//                             {addedServices && addedServices.map((service, index) => (
//                               <h6>
//                                 <span>
//                                   {' '}
//                                   Item
//                                   {' '}
//                                   {index + 1}
//                                   :
//                                 </span>
//                                 <span>
//                                   {service.description}
//                                   {' '}
//                                   |
//                                   {' '}
//                                   {` ${convertToReadableDuration(service.duration)}`}
//                                   {' '}
//                                   | $
//                                   {' '}
//                                   {service.cost}
//                                 </span>
//                               </h6>
//                             ))}
//                             <h6>
//                               <span> Sub Total:</span>
//                               <span>
//                                 $
//                                 {summary.totalCost}
//                               </span>
//                             </h6>
//                             <h6>
//                               <span>GST Amount:</span>
//                               <span>
//                                 $
//                                 {gst}
//                               </span>
//                             </h6>
//                             <h6>
//                               <span>Total Cost:</span>
//                               <span>
//                                 $
//                                 {quotesTotal}
//                               </span>
//                             </h6>
//                           </div>
//                           <div className="desc-text">
//                             <p>I hope that the above pricing meets with your approval & look farward to being of service to you in the future. If we have misinterpreted your requirements of if you require any additional detail or ammendments, Please do not hesitate to contact me on 0409 774 687 </p>
//                           </div>
//                           <div className="sign-info">
//                             <p>Your sincerely, </p>
//                             <p>
//                               Terry Newton
//                               <br />
//                               {' '}
//                               <span>Proprietor</span>
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     </TransformComponent>
//                   </TransformWrapper>
//                 </div>
//                 <div className="footer-btn view-btn">
//                   <Button
//                     className="btn-delete"
//                     onClick={handleQuote}
//                   >
//                     Cancel
//                   </Button>
//                   <Button
//                     className="appointment-btn btn-save"
//                     onClick={handleAddQuotes}
//                   >
//                     Send
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Modal.Body>
//     </Modal>
//   );
// };
// PreviewQuote.propTypes = {
//   showquote: PropTypes.func.isRequired,
//   handleQuote: PropTypes.bool.isRequired,
//   addClient: PropTypes.arrayOf.isRequired,
//   addedServices: PropTypes.arrayOf.isRequired,
//   quoteDate: PropTypes.string.isRequired,
//   summary: PropTypes.objectOf.isRequired,
//   quotesTotal: PropTypes.string.isRequired,
//   gst: PropTypes.string.isRequired,
//   handleAddQuotes: PropTypes.func.isRequired,
//   quoteNumber: PropTypes.string.isRequired,
// };
// export default PreviewQuote;

/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Button } from 'react-bootstrap';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PreviewQuote = ({
  previewUrl, loading, setSuccess,
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const url = previewUrl;

  return (
    <>
      {' '}
      {loading ? (
        <p>Loading PDF...</p>
      ) : (
        <>
          <TransformWrapper>
            <TransformComponent>
              <Document
                file={url}
                onLoadError={(error) => console.log(`Error while loading document! ${error.message}`)}
                onLoadSuccess={(pdf) => setSuccess(true)}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </TransformComponent>
          </TransformWrapper>
        </>
      )}
    </>
  );
};

PreviewQuote.propTypes = {
  // templateData: PropTypes.arrayOf.isRequired,
  // templateDataOne: PropTypes.arrayOf.isRequired,
  // customFeilds: PropTypes.arrayOf.isRequired,
  previewUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  // handleAddQuotes: PropTypes.func.isRequired,
  // handleQuote: PropTypes.bool.isRequired,
  setSuccess: PropTypes.bool.isRequired,
};

export default PreviewQuote;
