import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import { TemplateFeatures } from '../../contexts/TemplateFeatureContext';

const SaveTemplateConfirmPopup = ({
  isMain, setSaveTemp,
}) => {
  const {
    saveConfirmPopupValue,
    saveTemplateConfirmPopup,
    setSaveTemplateConfirmPopup,
    handleSaveConfirmPopupChange,
    handleConfirmPopupSelect,
    setIsParentClickDropdown,
  } = useContext(TemplateFeatures);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setSaveTemplateConfirmPopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="duration-picker time-picker-modal update-source-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="appointment-view">
            <div className="pending-water-test" onClick={() => handleSaveConfirmPopupChange('save-before-leave')}>
              <h3>Save changes made to template</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="save-before-leave"
                    checked={
                      saveConfirmPopupValue === 'save-before-leave' ? true : ''
                        }
                    onChange={(e) => handleSaveConfirmPopupChange(e.target.name)}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleSaveConfirmPopupChange('do-not-save')}>
              <h3>Leave page without saving</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    name="do-not-save"
                    checked={
                      saveConfirmPopupValue === 'do-not-save' ? true : ''
                        }
                    onChange={(e) => handleSaveConfirmPopupChange(e.target.name)}
                  />
                </div>
              </div>
            </div>

            <div className="event-popup-btn footer-btn view-btn">
              <Button
                className="appointment-btn btn-edit"
                type="button"
                onClick={() => {
                  setSaveTemplateConfirmPopup(!saveTemplateConfirmPopup);
                  handleSaveConfirmPopupChange('save-before-leave');
                  setIsParentClickDropdown(false);
                }}
              >
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleConfirmPopupSelect(isMain, setSaveTemp)}>
                Select
              </Button>
            </div>

          </div>

        </div>
      </div>
    </div>

  );
};

SaveTemplateConfirmPopup.propTypes = {
  isMain: PropTypes.bool.isRequired,
  setSaveTemp: PropTypes.func.isRequired,
};
export default SaveTemplateConfirmPopup;
