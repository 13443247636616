/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Modal, Form, Col, Row, Button,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import SoldData from './productSold';

const ServiceProductForm = ({
  showSubCat,
  setShowCat,
  handleChange,
  subcategory,
  handleManageSubCat,
  handleUpdateSubCategory,
  editData,
  type,
  withValueLimitSellPrice,
  handleDurationPicker,
  convertToReadableDuration,
  buysymbol,
  sellsymbol,
  category,
  handleEditSubcategory,
  teamMemberData,
  locationData,
  hideLocStaff,
  setSelectedStaff,
  selectedStaff,
  selectedLocationValue,
  setSelectedLocationValue,
  error,
}) => {
  const initialObject = {
    locationId: 'alllocation',
    teammemberId: 'allteam',
  };

  const [filterObj, setFilterObj] = useState(initialObject);
  const handleOnchange = (e) => {
    const { name, value } = e || {};
    if (name === 'teammemberId' && value !== 'allteam') {
      const getTeamData = teamMemberData.filter((el) => el._id === value);
      const findDuplciate = selectedStaff.findIndex((el) => el._id === value);
      let copyArr2 = [...selectedStaff];
      if (findDuplciate === -1) {
        let testOBj = {
          ...getTeamData[0],
          stockValue: 0,
        };
        copyArr2.push(testOBj);
        setSelectedStaff(copyArr2);
      }

      // For filter the staff and location
      let copyfilterObj = filterObj;
      const getLocationId = teamMemberData.filter((el) => el._id === value);
      copyfilterObj.teammemberId = value;
      copyfilterObj.locationId = getLocationId[0].location;
      setFilterObj(copyfilterObj);
    } else if (name === 'locationId' && value !== 'alllocation') {
      const copyLoc = selectedLocationValue.slice();
      const getLocatIndex = copyLoc.findIndex((el) => el._id === value);
      const getLocationId = locationData.find((el) => el._id === value);
      if (getLocatIndex === -1) {
        copyLoc.push(getLocationId);
      }
      setSelectedLocationValue(copyLoc);
      let copyfilterObj = filterObj;
      copyfilterObj.teammemberId = 'allteam';
      copyfilterObj.locationId = value;
      setFilterObj(copyfilterObj);
    } else {
      let copyfilterObj = { ...filterObj, [name]: value };
      setFilterObj(copyfilterObj);
    }
  };
  const removeLocation = (locationId) => {
    const removeLocationArray = selectedLocationValue.filter((el) => el._id !== locationId);
    setSelectedLocationValue(removeLocationArray);
    const removeTeamMemberArray = selectedStaff.filter((el) => el.location !== locationId);
    setSelectedStaff(removeTeamMemberArray);
  };
  const removeStaff = (staffId, locationId) => {
    const removeTeamMemberArray = selectedStaff.filter((el) => el._id !== staffId);
    setSelectedStaff(removeTeamMemberArray);
  };
  const handleStaffData = (e, index) => {
    const { value } = e.target;
    let copyStaffdata = [...selectedStaff];
    copyStaffdata[index].stockValue = parseFloat(value);
    setSelectedStaff(copyStaffdata);
  };
  const closeModal = () => {
    setSelectedStaff([]);
    setShowCat(!showSubCat);
  };
  return (
    <Modal
      size="xl"
      show={showSubCat}
      onHide={closeModal}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="subcategory-modal"
    >
      <div className="staff-close">
        <i
          className="fa fa-times"
          onClick={closeModal}
          aria-hidden="true"
        />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {` ${type === 'service' ? 'Service' : 'Product'} Details`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-12">
            <div className="modal-form">
              <Form>
                <Form.Group
                  controlId="formInput1"
                  className="subcategory-label italic-text"
                >
                  {!editData.isEdit ? (
                    <>
                      <Form.Label>
                        {` ${type === 'service' ? 'Service' : 'Product'} Name`}
                        {/* <span className="required-field">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`Enter ${type === 'service' ? 'Service' : 'Product'} Name`}
                        onChange={handleChange}
                        value={subcategory.name}
                        name="name"
                        className={`${
                          error === 'name'
                            ? 'error-text'
                            : ''
                        }`}
                      />
                    </>
                  ) : (
                    <>
                      {/* <div className="full-cate"> */}
                      <Form.Label>
                        {` ${type === 'service' ? 'Service' : 'Product'} Name`}
                        {/* <span className="required-field">*</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={`Enter ${type === 'service' ? 'Service' : 'Product'} Name`}
                        onChange={handleChange}
                        value={
                          subcategory.name.includes('$')
                            ? subcategory.name.split('$')[0].trim()
                            : subcategory.name
                        }
                        name="name"
                        className={`${
                          error === 'name'
                            ? 'error-text'
                            : ''
                        }`}
                      />
                      {/* </div> */}
                      {/* <Form.Group controlId="formInput1" className="cate-label">
                        <Form.Label>
                          Sell Price
                        </Form.Label>
                        <Form.Control type="text" disabled value={subcategory[type === 'product' ? 'retailCost' : 'cost']} name="name" />
                      </Form.Group> */}
                    </>
                  )}
                </Form.Group>
                {type === 'product' ? (
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>
                      {`${type === 'service' ? 'Service' : 'Product'} Code`}
                    </Form.Label>

                    <Form.Control type="text" placeholder={`Enter ${type === 'service' ? 'Service' : 'Product'} Code`} name="productCode" value={subcategory.productCode} onChange={handleChange} />

                  </Form.Group>
                ) : null}
                {type === 'product' ? (
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>
                      Barcode
                    </Form.Label>

                    <Form.Control type="text" placeholder="Barcode Code`" name="barCode" value={subcategory.barCode} onChange={handleChange} />

                  </Form.Group>
                ) : null}

                <Form.Group controlId="exampleForm.ControlTextarea1" className="italic-text multilines-text">
                  <Form.Label>
                    {`${
                      type === 'service' ? 'Service' : 'Product'
                    } Description`}
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>

                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={`Enter ${type === 'service' ? 'Service' : 'Product'} Description \n(this is used as the line item description in invoices)`}
                    onChange={handleChange}
                    value={subcategory.serviceDescription}
                    name="serviceDescription"
                    className={`${
                      error === 'description'
                        ? 'error-text'
                        : ''
                    }`}
                  />
                  {/* <span className="multilines">(this is used as the line item description in invoices)</span> */}

                </Form.Group>
                {type === 'product' ? (
                  <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>
                      Supplier
                    </Form.Label>

                    <Form.Control type="text" placeholder="Enter Supplier" name="supplier" value={subcategory.supplier} onChange={handleChange} />

                  </Form.Group>
                ) : null}
                {type === 'product' ? (
                  <>
                    <Form.Group>
                      <Form.Label className="form-label">
                        {`${
                          type === 'service' ? 'Service' : 'Product'
                        } Sold As (per item, weight, volume, etc)`}
                      </Form.Label>

                      <div className="select-box">
                        <select
                          id="formBasic"
                          className="form-control"
                          name="soldPer"
                          onChange={handleChange}
                          value={subcategory.soldPer}
                        >
                          {SoldData.map((el) => (
                            <option value={el}>{el}</option>
                          ))}
                        </select>
                      </div>

                    </Form.Group>

                    {subcategory.soldPer === 'Add Custom Measure' && (
                    <>
                      <Form.Group
                        controlId="exampleForm.ControlTextarea1"
                        className="italic-text"
                      >
                        <Form.Label>
                          Custom Measure Sold As
                        </Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter Weight or Measure Name"
                          name="unit"
                          value={subcategory.unit}
                          onChange={handleChange}
                        />

                      </Form.Group>
                      <Form.Group
                        controlId="exampleForm.ControlTextarea1"
                        className="italic-text"
                      >
                        <Form.Label>
                          Custom Measure Symbol
                        </Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter Custom Measure Symbol (abbreviation)"
                          name="symbol"
                          value={subcategory.symbol}
                          onChange={handleChange}
                        />

                      </Form.Group>
                    </>
                    )}
                    <Form.Group>
                      <Form.Label className="form-label">
                        {`Tracked Inventory ${
                          type === 'service' ? 'Service' : 'Product'
                        }`}
                      </Form.Label>

                      <div className="select-box">
                        <select
                          id="formBasic"
                          className="form-control"
                          onChange={handleChange}
                          value={subcategory.trackedInventory}
                          name="trackedInventory"
                        >
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>

                    </Form.Group>
                  </>
                ) : null}
                {type === 'product' && (
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>
                    Supply Unit
                  </Form.Label>

                  <Form.Control type="text" placeholder="Enter Supply Unit" name="supplyUnit" value={subcategory.supplyUnit} onChange={handleChange} />

                </Form.Group>
                )}
                <Form.Group controlId="formInput1" className="italic-text">
                  <Form.Label>
                    Tax Applicable
                    {/* <span className="required-field">*</span> */}
                  </Form.Label>

                  <div className="percent">
                    <NumberFormat
                      thousandSeparator
                      type="text"
                      placeholder="Enter Tax Rate (if no tax, enter as 0)"
                      onChange={handleChange}
                      value={subcategory.tax}
                      name="tax"
                      isAllowed={withValueLimitSellPrice}
                      className={`${
                        error === 'tax'
                          ? 'error-text'
                          : ''
                      }`}
                    />

                    {/* <Form.Control
                        type="text"
                        placeholder="Enter Tax Rate (if no tax, enter as 0)"
                        onChange={handleChange}
                        value={subcategory.tax}
                        name="tax"
                        className={`${
                          error === 'tax'
                            ? 'error-text'
                            : ''
                        }`}
                      /> */}
                  </div>

                </Form.Group>
                <Form.Group>
                  <Form.Label className="form-label">
                    Duration
                  </Form.Label>

                  <div className="select-box-duration">
                    {/* <select
                      id="formBasic"
                      className="form-control"
                      onChange={handleChange}
                      value={subcategory.duration}
                      name="duration"
                    >
                      {renderOptions().map((el) => (
                        <option value={el.value}>{el.data}</option>
                      ))}
                    </select> */}
                    <Button onClick={handleDurationPicker}>
                      {convertToReadableDuration(subcategory.duration)}
                    </Button>
                  </div>

                </Form.Group>
                {!category.toLowerCase().includes('misc') ? (
                  <Form.Group controlId="formInput1" className={`italic-text ${buysymbol ? 'price-tag' : ''}`}>
                    <Form.Label>
                      {type === 'product'
                        ? 'Buy Cost (ex tax)'
                        : 'Sell Price (ex tax)'}
                      {/* <span className="required-field">*</span> */}
                    </Form.Label>

                    <div className="relative-section">
                      <NumberFormat
                        thousandSeparator
                        type="text"
                        placeholder={type === 'product'
                          ? 'Enter Buy Cost'
                          : 'Enter Sell Price'}
                        onChange={handleChange}
                        value={subcategory.cost}
                        name="cost"
                        isAllowed={withValueLimitSellPrice}
                        className={`${
                          (error === 'sell price' || error === 'buy cost')
                            ? 'error-text'
                            : ''
                        }`}
                      />
                      {buysymbol ? <span className="price-symbol">$</span> : ''}
                      <span className="input-text-right">{subcategory.soldPer === 'Add Custom Measure' ? `per ${subcategory.unit} (${subcategory.symbol})` : `per ${subcategory.soldPer}`}</span>
                    </div>

                  </Form.Group>
                ) : null}
                {type === 'product' && !category.toLowerCase().includes('misc') ? (
                  <Form.Group controlId="formInput1" className={`italic-text ${sellsymbol ? 'price-tag' : ''}`}>
                    <Form.Label>
                      Sell Price (ex tax)
                      {/* <span className="required-field">*</span> */}
                    </Form.Label>
                    <div className="relative-section">
                      <NumberFormat
                        thousandSeparator
                        type="text"
                        placeholder="Enter Sell Price"
                        onChange={handleChange}
                        value={subcategory.retailCost}
                        name="retailCost"
                        isAllowed={withValueLimitSellPrice}
                        className={`${
                          error === 'sell price' || error === 'retail cost'
                            ? 'error-text'
                            : ''
                        }`}
                      />
                      {sellsymbol ? <span className="price-symbol">$</span> : ''}
                      <span className="input-text-right">{subcategory.soldPer === 'Add Custom Measure' ? `per ${subcategory.unit} (${subcategory.symbol})` : `per ${subcategory.soldPer}`}</span>
                    </div>

                  </Form.Group>
                ) : null}

                {/* {hideLocStaff && type === 'product' && (
                <>
                  <Form.Group  controlId="exampleForm.ControlTextarea1" className="italic-text">
                    <Form.Label>
                      Distribute Stocks to Team Member
                    </Form.Label>
                  </Form.Group>
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="locationId" value={filterObj.locationId}>
                        <option value="alllocation">All Locations</option>
                        {locationData.map((el) => <option value={el._id}>{el?.locationName}</option>)}
                      </Form.Control>
                    </div>
                  </Form.Group>
                  <div className="inventory-location-tag">
                    {selectedLocationValue.map((el) => (
                      <p>
                        {el?.locationName}
                        {' '}
                        <span onClick={() => removeLocation(el._id)}>X</span>
                      </p>
                    ))}
                  </div>
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control as="select" onChange={(e) => handleOnchange(e.target)} name="teammemberId" value={filterObj.teammemberId}>
                        <option value="allteam">All Team Members</option>
                        {teamMemberData.map((el) => (
                          <>
                            {filterObj.locationId === 'alllocation' ? <option value={el._id}>{`${el?.firstname} ${el?.lastname}`}</option> : filterObj?.locationId === el?.location && <option value={el._id}>{`${el?.firstname} ${el.lastname}`}</option>}
                          </>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>

                  <div className="inventory-location-tag">
                    {selectedStaff && selectedStaff.length > 0 && selectedStaff.map((el, index) => (
                      <>
                        <Form.Group controlId="formBasicInput-2">
                          <Form.Label>
                            {' '}
                            {el.firstname}
                            {' '}
                            {el.lastname}
                          </Form.Label>
                          <div className="number-span">
                            <NumberFormat
                              thousandSeparator
                              type="text"
                              placeholder="Enter Stock Level"
                              value={el.stockValue}
                              name="stockValue"
                              onChange={(e) => handleStaffData(e, index)}
                            />
                            <span onClick={() => removeStaff(el._id, el.location)}>X</span>
                          </div>
                        </Form.Group>
                      </>
                    ))}
                  </div>
                </>
                ) } */}
                <div className="view-btn">
                  {/* <Button type="submit" className="back-btn">
          <span><i className="fas fa-angle-left" /></span>
          {' '}
          Back
        </Button> */}
                  {/* <Button type="submit" className="btn btn-delete">Delete</Button> */}
                  <Button
                    type="submit"
                    className="btn btn-save"
                    onClick={
                      editData.isEdit === true
                        ? handleUpdateSubCategory
                        : handleManageSubCat // for Adding
                    }
                  >
                    Save/Update
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
};

ServiceProductForm.propTypes = {
  showSubCat: PropTypes.bool.isRequired,
  setShowCat: PropTypes.bool.isRequired,
  subcategory: PropTypes.bool.isRequired,
  handleChange: PropTypes.bool.isRequired,
  handleManageSubCat: PropTypes.bool.isRequired,
  handleUpdateSubCategory: PropTypes.bool.isRequired,
  editData: PropTypes.bool.isRequired,
  convertToReadableDuration: PropTypes.bool.isRequired,
  type: PropTypes.bool.isRequired,
  withValueLimitSellPrice: PropTypes.func.isRequired,
  handleDurationPicker: PropTypes.func.isRequired,
  buysymbol: PropTypes.bool.isRequired,
  sellsymbol: PropTypes.bool.isRequired,
  category: PropTypes.bool.isRequired,
  handleEditSubcategory: PropTypes.bool.isRequired,
  hideLocStaff: PropTypes.bool.isRequired,
  locationData: PropTypes.arrayOf.isRequired,
  teamMemberData: PropTypes.arrayOf.isRequired,
  selectedStaff: PropTypes.arrayOf.isRequired,
  setSelectedStaff: PropTypes.func.isRequired,
  selectedLocationValue: PropTypes.func.isRequired,
  setSelectedLocationValue: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
};

export default ServiceProductForm;
