/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import {
  Form, Card,
} from 'react-bootstrap';
import checkimg from '../../assets/home/check1.png';
import { userInstance } from '../../config/axios';
import { showErrMsg } from '../../functions/index';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './permissionlevel.css';
import { AuthDataContext } from '../../contexts/AuthDataContext';

function PermissionSubscriberAccess() {
  const [permissions, setPermissions] = useState([]);

  const { userData } = useContext(AuthDataContext);

  const getPermissions = async () => {
    try {
      // console.log('userData', userData);
      const response = await userInstance().get(`/permission/getPermissions?accessLevel=${userData?.accessLevel}`);
      const res = await userInstance().get('/permission/getSubsPermission');
      const { permissionsList } = response.data;
      setPermissions(permissionsList);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const updatePermission = async ({ e, _id, level }) => {
    try {
      const payload = {
        permissionId: _id,
        type: level,
      };
      // console.log('payload', payload);
      const response = await userInstance().put('/permission/updateSubsPermission', payload);
      //   const response = await userInstance().put('/permission/updatePermission', { _id, level: newLevels, accessLevel: userData.accessLevel });
      //   console.log('response', response);
      //   const { msg } = response.data;
      //   NotificationManager.success(msg, 'Message', 1000);
    //   setPermissions(newArray);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const checkPermission = (level, data) => {
    // console.log('data', data, 'level', level);
    let checked = true;
    const filteredData = data.filter((el) => el === level);
    if (!filteredData.length) {
      checked = false;
    }
    return checked;
  };

  // const updatePermissions = async () => {
  //   try {
  //     const response = await userInstance().put('/permission/update', { permissions });
  //     console.log('response', response);
  //     const { msg } = response.data;
  //     NotificationManager.error(msg, 'Message', 1000);
  //   } catch (error) {
  //       console.log(error);
  //   }
  // };
  // console.log('permissions==>', permissions);
  useEffect(() => {
    getPermissions();
  }, []);
  return (
    <>
      <AdminWrapper>
        <div className="permission-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <div className="user-title">
                <h4>PROFESSIONAL USER</h4>
              </div> */}
                <div className="main-titles">
                  <h3>Subscriber Permission & Access Level</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="permission-box permission-box-mob" hidden={!permissions.length}>
                  <Card>
                    <Card.Body>
                      {permissions.map((el) => (
                        <>
                          {
                              el.category !== 'Subscription Management'

                        && (
                        <div className="permission-level">
                          <h6>{el.category}</h6>
                          <div className="permission-access">
                            <div className="basic">
                              <h6>Basic</h6>
                              <Form.Check
                                type="checkbox"
                                id={`basic ${el._id}`}
                                // checked={checkPermission('low', el.level)}
                                onChange={(e) => updatePermission({ e, _id: el._id, level: 'basic' })}
                              />
                            </div>
                            <div className="basic">
                              <h6>Standard</h6>
                              <Form.Check
                                type="checkbox"
                                id={`standard ${el._id}`}
                                // checked={checkPermission('medium', el.level)}
                                onChange={(e) => updatePermission({ e, _id: el._id, level: 'standard' })}
                              />
                            </div>
                            <div className="basic">
                              <h6>Premium</h6>
                              <Form.Check
                                type="checkbox"
                                id={`premium ${el._id}`}
                                // checked={checkPermission('high', el.level)}
                                onChange={(e) => updatePermission({ e, _id: el._id, level: 'premium' })}
                              />
                            </div>
                            {/* <div className="basic">
                              <h6>Owner</h6>
                              <span className="check-img">
                                <img src={checkimg} alt="check-img" />
                              </span>
                              {' '}
                            </div> */}
                          </div>
                        </div>
                        )
}
                        </>
                      ))}
                    </Card.Body>
                  </Card>

                  {/* <div className="view-btn">
                  <Button type="submit" className="btn btn-save">Save/Update</Button>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminWrapper>
    </>
  );
}
export default PermissionSubscriberAccess;
