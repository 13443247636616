import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Spinner } from 'react-bootstrap';

const RenameCsv = ({
  closeRenameModal, editData, setEditData, handleSaveEdit, error, isLoading,
}) => {
  const handleEditChange = (e) => {
    const { value } = e.target;
    setEditData({ ...editData, name: value });
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal'
          ) {
            closeRenameModal();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal download-csv-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <h6>Edit CSV</h6>
          <div className="appointment-view">
            <Form.Group controlId="formBasicInput-1">
              <Form.Label>
                Name of the CSV
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter csv name"
                value={editData.name}
                onChange={(e) => handleEditChange(e)}
                className={`${
                  error === 'name'
                    ? 'error-text'
                    : ''
                }`}
              />
            </Form.Group>
            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={closeRenameModal}>
                Cancel
              </Button>
              <Button type="button" className="appointment-btn btn-save" onClick={() => handleSaveEdit(editData)}>
                {isLoading ? (
                  <Spinner animation="border" />
                ) : (
                  'Save/ Update'
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
RenameCsv.propTypes = {
  closeRenameModal: PropTypes.func.isRequired,
  setEditData: PropTypes.func.isRequired,
  handleSaveEdit: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  editData: PropTypes.objectOf.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default RenameCsv;
