/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table, Button, Accordion, Card, Form, Dropdown,
} from 'react-bootstrap';
import Papa from 'papaparse';
import readXlsxFile from 'read-excel-file';
import { read, utils } from 'xlsx';
import ReactExport from 'react-export-excel';
import PropTypes, { object } from 'prop-types';
import { confirmAlert } from 'react-confirm-alert'; // Import
import {
  queryString, checkValidationCSVClient, checkKeysAndCreateData, makingAddress, currencyComma,
} from '../../functions/index';
import { checkUploadCsv, uplaodCSVApiData } from '../uploadCSV/index';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import uncheckimg from '../../assets/home/uncheck.png';
import search from '../../assets/home/search.png';
import showmore from '../../assets/home/showmoreblue.png';
import whiteedit from '../../assets/home/white-edit.png';
import whitedelete from '../../assets/home/white-delete.png';
import greyedit from '../../assets/home/grey-edit.png';
import greydelete from '../../assets/home/grey-delete.png';
import greyplus from '../../assets/home/plus.png';
import '../prduct&services/product&services.css';
import crosss from '../../assets/home/black-icon.png';
import time from '../../assets/home/clock.png';
import searchgray from '../../assets/home/searchgray.png';
import upload from '../../assets/home/upload.png';
import download from '../../assets/home/download.png';
import arrow from '../../assets/home/arrowdown.png';
import DeletePopUp from '../DeletePopUp/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { emailRegex } from '../../config/keys';
import { userInstance } from '../../config/axios';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';
import UploadIssuePopup from '../Popups/upload-issue-popups';
import UploadErrorTable from '../uploadCSV/upload-error-table';

function ClienTable(props) {
  const {
    clientAllData, userData: {
      industryId, numberUsers, role, userId,
    },
  } = useContext(AuthDataContext);
  // console.log('ClienTable ==>');
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;
  const {
    setCrossStatus,
    clientList,
    setClientList,
    handleEditClient,
    removeClient,
    showHide,
    searchClient,
    handleFormModal,
    handleContactModal,
    handleEditContact,
    handleDeleteContact,
    handleSiteAddressModal,
    handleEditAddress,
    handleDeleteAddress,
    handleVisibilityAddr,
    showAll,
    hideClicked,
    hideAll,
    setClientTxt,
    clientTxt,
    setHideSearch,
    isHideSearch,
    hideClickedAssociated,
    clientDetail,
    setClientDetail,
    checkAddress,
    selectedIndex,
    claddressIndex,
    selectedClient,
    backToAppointment,
    setClientIdentity,
    skipValue,
    setskipValue,
    setShowUploadError,
    setUploadErrors,
    showUploadErrorPopup,
    setShowUploadErrorPopup,
    getClientList,
  } = props;

  let {
    clientid, name, startDateTime, employee_id, typeRoute, typee, back, quoteId, skipValuee,
  } = queryString();
  const history = useHistory();
  const [accordinData, setAccordinData] = useState({
    client: [],
    associatedContacts: [],
    siteAddresses: [],
    contacts: [],
    addresses: [],
    addrContacts: [],
  });
  const [clientNewData, setClientNewData] = useState([]);
  const [newstate, setnewState] = useState(true);
  const [check1, setCheck1] = useState(false);
  const [indexCheck, setindexCheck] = useState(0);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [delData, setDelData] = useState();
  const [popUpText, setPopUpText] = useState();
  const [dueAmount, setDueAmount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvFiles, setCsvFiles] = useState([]);
  const [chlorinationModelData, setChlorinationModelData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const paraText = 'Deleting the client will remove the client, but all records saved will be kept.';

  async function toggleClient(clientIdent) {
    console.log('hit');
    // console.log('chalakyaa', { clientIdent });
    // console.log(' clientid, name, startDateTime, employee_id, typeRoute, typee, back, quoteId,', clientid, name, startDateTime, employee_id, typeRoute, typee, back, quoteId);

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    const changeState = clientNewData.slice();
    const index = changeState.indexOf(clientIdent);
    if (index >= 0) {
      if (name && typeRoute !== 'client') {
        // console.log('condition one===>> ');
        const upType = typee || typeRoute;
        const baseUrl = `addappointment?startDateTime=${startDateTime}&employee_id=${employee_id}`;
        if (upType === 'edit' && back) {
          if (back === 'newinvoice') {
            history.push('/invoice?clienttype=edit');
          } else if (back === 'quotes') {
            history.push('/quotes?clienttype=edit');
          } else if (back === 'approvalappointments') {
            history.push(`/${back}`);
          } else if (quoteId) {
            history.push(`${baseUrl}&cust=add&quoteId=${quoteId}`);
          } else if (back === 'awaiting') {
            history.push('/approvalappointments?viewtype=detailPage&backfrom=editclient');
          } else {
            history.push(`${baseUrl}&cust=add`);
          }
        } else if (name) {
          // console.log('condition two ');
          backToAppointment();
        } else if (upType === 'add') {
          // console.log('condition three ');
          history.push(`${baseUrl}&typo=add`);
        }
      } else {
        // console.log('condition four ');
        changeState.splice(index, 1);
        setClientNewData(changeState);
        setClientIdentity('');
        setnewState(!newstate);
      }
    } else {
      changeState.push(clientIdent);
      // console.log('changeState**', { changeState, clientIdent });
      if (skipValuee) {
        console.log('hi-------');
        setskipValue(skipValuee);
        history.replace({ pathname: 'client' });
      }

      // console.log('changeState', changeState);
      setClientNewData(changeState);
      setClientIdentity(clientIdent);
      setnewState(!newstate);

      const result = await userInstance().post(
        '/customer/getCustomerDueAmount',
        { id: clientIdent },
      );
      setDueAmount(result.data.totalBalanceDue);

      // console.log('result', result);
    }
  }

  useEffect(() => {
    console.log('jhello---------------');
    if (!typeRoute && name && clientAllData.clientAllDetails) {
      toggleClient(clientid);
    }
    if (typeRoute && name) {
      toggleClient(clientid);
    }
    if (typee === 'add') {
      // handleFormModal();
    }
    if (back === 'awaiting') {
      toggleClient(clientid);
      // console.log(clientid);
    }
    const getPoolMineralrsData = async () => {
      const newresponse = await userInstance().get(
        '/poolmineral/getAllPoolMinerals',
      );
      const {
        code,
        mineralList: { chlorinatorModels },
      } = newresponse.data || {};
      if (code === 200) {
        setChlorinationModelData(chlorinatorModels);
      }
    };
  }, []);

  function nth(n) {
    return n + ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || `${n}th`;
  }
  const [show, setShow] = useState(true);

  const handleSiteAccordion = (id, type) => {
    // console.log('grytdwteiudu', id, type);
    const selectedAccordin = accordinData[type];
    // console.log('grytdwteiudu', selectedAccordin);
    if (selectedAccordin && selectedAccordin.includes(id)) {
      const data = selectedAccordin.filter((el) => el !== id);

      setAccordinData({ ...accordinData, [type]: [...data] });
    } else {
      setAccordinData({ ...accordinData, [type]: [...selectedAccordin, id] });
    }
    if (type === 'client') { setShow(!show); }
  };

  const camelCase = (str) => str.substring(0, 1).toUpperCase() + str.substring(1);

  const handleQuotes = (id, firstName) => {
    const routeType = typee || 'client';
    if (!back) {
      history.push(`/booking?client=${id}&routeType=${routeType}&name=${firstName}&skipValue=${skipValue}`);
    } else {
      history.push(`/booking?client=${id}&routeType=${routeType}&name=${firstName}&back=${back}`);
    }
  };

  const filterColumns = (data) => {
    if (clientList && clientList.length) {
      // Get column names
      const columns = Object.keys(data[0]);
      // Remove by key (firstname)
      const filterColsByKey = columns.filter(
        (c) => c !== 'appointments' && c !== 'associatedContacts' && c !== 'siteAddresses' && c !== 'chlorinatorModel' && c !== 'selectedModelObj' && c !== '_id' && c !== 'userid' && c !== '__v' && c !== 'showBookings' && c !== 'createdAt' && c !== 'updatedAt' && c !== 'contactId',
      );

      // OR use the below line instead of the above if you want to filter by index
      // columns.shift()

      return filterColsByKey; // OR return columns
    }
    return [];
  };

  const handlePopUpChange = (val) => {
    if (val === 'Yes') {
      switch (popUpText) {
        case 'Delete Client':
          removeClient(delData, toggleClient);
          break;

        case 'Delete Contact?': {
          const {
            _id, i, contactIndex, type, addrIndex,
          } = delData;
          handleDeleteContact(_id, i, contactIndex, type, addrIndex);
          break;
        }

        case 'Delete Site Address?': {
          const { _id, i, addrIndex } = delData;
          handleDeleteAddress(_id, i, addrIndex);
          break;
        }

        default:
          break;
      }
    }

    setDeletePopUp(false);
  };

  const submitRemove = (id) => {
    setPopUpText('Delete Client');
    setDeletePopUp(true);
    setDelData(id);
  };

  const submitRemoveContact = (_id, i, contactIndex, type, addrIndex) => {
    setPopUpText('Delete Contact?');
    setDeletePopUp(true);
    setDelData({
      _id, i, contactIndex, type, addrIndex,
    });
  };

  const submitRemoveAddress = (_id, i, addrIndex) => {
    setPopUpText('Delete Site Address?');
    setDeletePopUp(true);
    setDelData({ _id, i, addrIndex });
  };

  const handleWaterTest = (clientId, clientName) => {
    const routeType = typee || 'client';
    if (!back) {
      history.push(`/watertesthistory?data=${clientId}&name=${clientName}&routeType=${routeType}&skipValue=${skipValue}`);
    } else {
      history.push(`/watertesthistory?data=${clientId}&name=${clientName}&routeType=${routeType}&back=${back}`);
    }
  };

  const invoiceHistory = (clientId, namee) => {
    let Id = clientId || '';
    const routeType = typee || 'client';
    history.push(`/invoice?client=${Id}&from=client&namee=${namee}&typeRoute=${routeType}&skipValue=${skipValue}`);
  };

  function onKeyDown(keyEvent) {
    // console.log('keyEvent', keyEvent.keyCode);
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }
  const handleCheck = (idx) => {
    setindexCheck(idx);
    setCheck1(!check1);
  };
  const checkHeading = (viewType, headingArr) => {
    if (
      ((headingArr.includes('ContactType') || headingArr.includes('CustomerType') || headingArr.includes('ContactsFirst Name')))
    ) {
      return true;
    }
    toastMessage('error', `Please upload ${viewType.toLowerCase()} csv/excel.`);

    return false;
  };

  const checkCsvHasErrorAndUpload = async (csvDataArray, csvUploadedFile) => {
    const res = await checkUploadCsv('customer', csvDataArray);
    if (res?.data?.code === 200 && res?.data?.errorData.length > 0) {
      setShowUploadErrorPopup(true);
      setUploadErrors(res?.data?.errorData);
      return;
    }
    setUploadErrors([]);
    uploadCsvApi(csvDataArray, csvUploadedFile);
  };

  const uploadCsvApi = async (csvDataArray, csvUploadedFile) => {
    const formData = new FormData();
    const csvFileArray = csvUploadedFile || csvFiles;
    csvFileArray.forEach((el) => {
      formData.append('csvFiles', el);
    });
    const csvDataNew = JSON.stringify(csvDataArray || csvData);
    formData.append('csvData', csvDataNew);
    formData.append('numberUsers', JSON.stringify(numberUsers));
    formData.append('industryId', JSON.stringify(industryId._id));
    formData.append('role', role);
    setIsLoading(true);
    if (csvDataArray?.length > 0 || csvData.length > 0) {
      const response = await uplaodCSVApiData('customer', formData);
      // console.log({ response });
      if (response.data.code === 200) {
        getClientList();
        setCsvData([]);
        setCsvFiles([]);
        toastMessage('success', 'CSV uploaded');
      } else {
        toastMessage('error', response.msg);
      }
    } else {
      toastMessage('error', 'No data to upload.');
    }
    setIsLoading(false);
  };

  const handleUplaodChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      var filename = file.name;
      // pre-process data
      var binary = '';
      var bytes = new Uint8Array(e.target.result);
      var length = bytes.byteLength;
      for (var i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      // call 'xlsx' to read the file
      var wb = read(binary, { type: rABS ? 'binary' : 'array' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = utils.sheet_to_json(ws);
      // console.log('data---', data);
      const checkHeadingValue = checkHeading('Clients', Object.keys(data[0]));
      if (checkHeadingValue) {
        setCsvFiles([file]);
        uploadCSVClient(data, file?.name, [file]);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadCSVClient = (data, fileName, csvUploadedFile) => {
    const { mainData } = checkKeysAndCreateData(
      data,
      fileName,
      'customer',
      chlorinationModelData,
      [],
      userId,
    );
    setCsvData(mainData);
    checkCsvHasErrorAndUpload(mainData, csvUploadedFile);
  };

  let contactCount = 1;
  // console.log('clientList', { clientList, newstate, clientNewData });

  const confirmSelectUploadError = (selectedOption) => {
    setShowUploadErrorPopup(false);
    if (selectedOption === 'continue') {
      uploadCsvApi();
      setShowUploadErrorPopup(false);
      setUploadErrors([]);
    } else {
      setShowUploadError(true);
    }
  };

  const handleCloseIssuePopup = () => {
    setUploadErrors([]);
    setShowUploadErrorPopup(false);
  };

  const filteredData = clientList?.filter((el) => clientNewData.includes(el._id));
  console.log({ filteredData });

  return (
    <>

      <div className="main-titles">
        <h3>Client</h3>
      </div>

      {newstate && (
      <>
        <div className="main-titles-mob">
          <h3>Client List</h3>
          <div className="content-dropdown" />

          <div className="client-option service-edit-icon">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={showmore} alt="show" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="action" onClick={() => handleFormModal()}>
                  <span>
                    <img src={greyplus} alt="edit" />
                  </span>
                  <span> New Client</span>
                </div>
                <div className="action" onClick={() => setHideSearch(true)}>
                  <span><img src={searchgray} alt="edit" /></span>
                  <span>Show Hidden data in Search</span>
                </div>
                <div className="action">
                  <span><img src={download} alt="edit" /></span>
                  <ExcelFile filename="client-list" element={<Button className="btn">Download in XL</Button>}>
                    <ExcelSheet data={clientList} name="Test">
                      {filterColumns(clientList).map((col) => (
                        <ExcelColumn label={camelCase(col)} value={col} />
                      ))}
                    </ExcelSheet>
                  </ExcelFile>
                </div>
                <div className="action upload">
                  <span><img src={upload} alt="edit" /></span>
                  <span>
                    <input id="upload" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={(e) => handleUplaodChange(e)} />
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label htmlFor="upload">
                        Upload Client List
                      </Form.Label>
                    </Form.Group>
                  </span>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <div className="client-search-box">
          <Form inline>
            <Form.Group controlId="formBasicSearch" className=" search-box search-text">
              <Form.Control
                placeholder="Search by name, mobile, phone or address"
                onChange={(e) => searchClient(e.target.value)}
                onKeyDown={onKeyDown}
                value={clientTxt}
              />
              <img src={search} alt="search" />
              {clientTxt ? (
                <div className="remove-text" onClick={() => searchClient('')}>
                  <img src={crosss} className="cross-img" alt="cross-img" />
                </div>
              ) : null}
            </Form.Group>
            {isHideSearch ? (
              <span className="hidden-data">
                Hidden Data Shown in Search
                <img
                  src={crosss}
                  className="cross-img"
                  alt="cross-img"
                  onClick={() => {
                    setHideSearch(false);
                    searchClient('');
                  }}
                />
              </span>
            ) : null}
          </Form>

        </div>
      </>
      )}

      {clientList ? (
        <>
          {clientList.length === 0 ? (
            <div className="placehoder-text">No Clients Found</div>
          ) : (
            <>
              {clientList.map((client, i) => {
                let jsxData = (
                  <>
                    {newstate && (

                    <div className="client-main-contact" key={client?._id}>
                      <div
                        className="client-name-contact"
                        onClick={() => handleCheck(client?._id)}
                      >
                        <div
                          className=""
                        >
                          <h3 className="client-company-name">{client?.companyName}</h3>
                          <h4 className="client-name">
                            {' '}
                            {`${client?.firstname} ${client?.lastname || ''}`}
                          </h4>

                          <p className="client-address">{makingAddress({ streetAddress: client?.streetAddress, suburb: client?.suburb, state: client?.state })}</p>
                        </div>
                        <div className="open-date client-notes">
                          {client?.siteAddresses?.length > 0
                            && (
                            <div className="basic">
                              <Form.Check type="checkbox" checked={indexCheck === client._id && check1 === true} onClick={() => handleCheck(client._id)} />
                            </div>
                            )}
                          <div className="arrow-img">
                            <img
                              src={arrow}
                              alt="arrow"
                              role="presentation"
                              onClick={() => toggleClient(client?._id)}
                            />
                          </div>

                        </div>
                      </div>
                      {client?.siteAddresses?.length > 0 && client.siteAddress?.length > 0 && client.siteAddress.filter((elem) => !elem.hide).map((el, elindex) => (
                        <div
                          className="client-name-contact site-addresses"
                          role="presentation"
                          onClick={() => checkAddress(i, client?._id, elindex)}
                        >
                          <div className="">
                            {elindex === 0 ? (
                              <h3 className="client-company-name">Site Address</h3>
                            )
                              : (
                                <h3 className="client-company-name">{`Site Address ${elindex + 1}`}</h3>
                              )}
                            {/* <h3 className="client-company-name">{`Site Address ${elindex + 1}`}</h3> */}
                            <h4 className="client-name">{el.householderName}</h4>
                            <p className="client-address">{el.address}</p>
                          </div>
                          <div className="open-date client-notes">
                            <div className="basic">
                              <Form.Check
                                type="checkbox"
                                checked={el.default ? true : ''}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    )}
                    {/* {console.log({ clientNewData, id: client?._id, newstate })} */}
                    {newstate === false && clientNewData?.includes(client?._id) && (

                    <div className="cover-client-page">
                      {console.log('dsahdhsg')}
                      <div className="client-back-btn">
                        {/* <button type="button" className="btn" onClick={() => toggleClient(client?.firstname)}>
                            <img src={crosss} alt="cancel" />
                          </button> */}
                        <div className="staff-close">

                          <i className="fa fa-times" onClick={() => toggleClient(client?._id)} aria-hidden="true" />

                        </div>
                      </div>
                      <Accordion className="mob-accordion new-client-section" key={client?._id}>
                        <Card>
                          <Card.Header>
                            <div className="client-heading-option first-heading">
                              <Accordion.Toggle
                                as={Button}
                                variant="link"
                                eventKey={client?._id}
                              >
                                <h5>
                                  <i
                                    className={`fas ${
                                      accordinData.client?.includes(client?._id)
                                        ? 'far fa-chevron-circle-down'
                                        : 'far fa-chevron-circle-up'
                                    }`}
                                    style={{ visibility: 'hidden' }}
                                  />
                                  {`${client?.companyName ? client?.companyName : client?.firstname}`}
                                </h5>
                                {dueAmount && dueAmount !== 0 ? <p className="due-amt">{`$${currencyComma(dueAmount?.toFixed(2))} Due`}</p> : <span className="outstanding-amt">$0.00 Outstanding</span>}

                              </Accordion.Toggle>
                              <div className="client-option service-edit-icon">
                                <Dropdown>
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <img src={showmore} alt="show" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <div className="action" onClick={() => handleContactModal(i, client?._id)}>
                                      <span>
                                        <img src={greyplus} alt="edit" />
                                      </span>
                                      {client?.customerType !== 'Householder' ? (
                                        <span> Associated Billing Contact</span>
                                      ) : (
                                        <span> Additional Contact</span>
                                      )}
                                    </div>
                                    {client?.customerType !== 'Householder' ? (
                                      <div className="action" onClick={() => handleSiteAddressModal(i, client._id)}>
                                        <span>
                                          <span>
                                            <img src={greyplus} alt="edit" />
                                          </span>
                                        </span>
                                        <span>Site Address</span>
                                      </div>
                                    ) : (
                                      <div className="action" onClick={() => handleEditClient(client)}>
                                        <span>
                                          <img src={greyedit} alt="edit" />
                                        </span>
                                        <span> Edit </span>
                                      </div>
                                    )}
                                    {client?.customerType !== 'Householder' ? (
                                      <>
                                        <div className="action" onClick={() => showAll(i, 'associatedContacts')}>
                                          <span>
                                            <i className="fa fa-eye" aria-hidden="true" />
                                          </span>
                                          <span>
                                            {`${
                                              hideClickedAssociated === true && hideClickedAssociated
                                                ? 'Hide'
                                                : hideClickedAssociated === false
                                                  ? 'Show Hidden'
                                                  : 'Show Hidden'
                                            } Associated Billing Contacts`}
                                          </span>
                                        </div>
                                        <div className="action">
                                          <span>
                                            <img src={searchgray} alt="edit" />
                                          </span>
                                          <span>Search Hidden Associated Billing Contacts</span>
                                        </div>
                                      </>
                                    ) : null}

                                    <div
                                      className="action"
                                      onClick={() => showAll(
                                        i,
                                        client?.customerType !== 'Householder' ? 'siteAddresses' : 'siteContacts',
                                      )}
                                    >
                                      <span>
                                        <i className="fa fa-eye" aria-hidden="true" />
                                      </span>

                                      <span>
                                        {`${
                                          hideClicked === true && hideClicked
                                            ? 'Hide'
                                            : hideClicked === false
                                              ? 'Show Hidden'
                                              : 'Show Hidden'
                                        } ${client?.customerType !== 'Householder' ? 'Site Addresses' : 'Contacts'}`}
                                      </span>
                                    </div>
                                    {client?.customerType !== 'Householder' ? (
                                      <>
                                        <div className="action">
                                          <span>
                                            <img src={searchgray} alt="edit" />
                                          </span>
                                          <span>Search Hidden Site Addresses</span>
                                        </div>
                                        <div className="action" onClick={() => handleEditClient(client)}>
                                          <span>
                                            <img src={greyedit} alt="edit" />
                                          </span>
                                          <span> Edit </span>
                                        </div>
                                      </>
                                    ) : null}
                                    <div className="action" onClick={() => handleQuotes(client?._id, client?.firstname)}>
                                      <span>
                                        <i className="fa fa-eye" aria-hidden="true" />
                                      </span>
                                      <span> Quotes, Past & Future Bookings </span>
                                    </div>

                                    <div className="action" onClick={() => handleWaterTest(client?._id, client?.firstname)}>
                                      <span>
                                        <i className="fa fa-eye" aria-hidden="true" />
                                      </span>
                                      <span> Water Tests </span>
                                    </div>
                                    <div className="action" onClick={() => invoiceHistory(client?._id, client?.firstname)}>
                                      <span>
                                        <i className="fa fa-eye" aria-hidden="true" />
                                      </span>
                                      <span> Invoices & Payments </span>
                                    </div>
                                    <div className="action" onClick={() => submitRemove(client?._id)}>
                                      <span>
                                        <img src={greydelete} alt="edit" />
                                      </span>
                                      <span> Delete </span>
                                    </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </Card.Header>
                          {/* { console.log('show----', show)} */}
                          {show ? (
                            <Accordion eventKey={client?._id}>
                              <Card.Body>
                                <ul className="staff-list top-client-details">
                                  {(client?.firstname || client?.lastname) ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Main Contact</h3>
                                        <h5>{`${client?.firstname} ${client?.lastname || ''}`}</h5>
                                      </div>
                                    </li>
                                  ) : null}
                                  {client?.mobileNo ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Mobile</h3>
                                        <h5>
                                          <a className="mobile" href={`tel:${client.mobileNo}`}>
                                            {`${client.mobileNo}`}
                                          </a>
                                        </h5>
                                      </div>
                                    </li>
                                  ) : null}
                                  {client?.email ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Email</h3>
                                        <h5>
                                          <a className="email" href={`mailto:${client?.email}`}>
                                            {client.email}
                                          </a>
                                        </h5>
                                      </div>
                                    </li>
                                  ) : null}

                                  {client?.streetAddress ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Billing Street Address</h3>
                                        <h5>{`${client.streetAddress}`}</h5>
                                      </div>
                                    </li>
                                  ) : null}
                                  {client?.suburb ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Billing Suburb</h3>
                                        <h5>{client.suburb !== undefined ? client.suburb : ''}</h5>
                                      </div>
                                    </li>
                                  ) : null}
                                  {client?.customerType ? (
                                    <li className="staff-list-item">
                                      <div className="staff-box">
                                        <h3>Billing Contact Type</h3>
                                        <h5>{`${client.customerType}`}</h5>
                                      </div>
                                    </li>
                                  ) : null}

                                  {client?.postcode && (
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>Postal / Zip Code</h3>
                                      <h5>{`${client.postcode}`}</h5>
                                    </div>
                                  </li>
                                  )}
                                  {client?.state && (
                                  <li className="staff-list-item">
                                    <div className="staff-box">
                                      <h3>State</h3>
                                      <h5>{`${client.state}`}</h5>
                                    </div>
                                  </li>
                                  )}

                                </ul>
                                {client?.associatedContacts?.length > 0
                                && client.associatedContacts.filter(
                                  (addr) => addr.hide === false || addr.tempShow === true,
                                ).length > 0 ? (
                                    client.associatedContacts.map((el, contactIndex) => {
                                      let contactsId = '';
                                      if (el.hide === false || el.tempShow === true) {
                                        contactCount += 1;
                                        contactsId = el.name + el.mobileNo + el.email + contactIndex;
                                      }

                                      return (el.hide === false || el.tempShow === true)
                                        ? (
                                          <Accordion className="mob-accordion inner-item" key={contactIndex.toString()}>
                                            <Card>

                                              <Card.Header>
                                                <div className="client-heading-option main-inner-heading first-heading">
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey={contactIndex.toString()}
                                                    onClick={() => handleSiteAccordion(contactIndex.toString(), 'associatedContacts')}
                                                  >
                                                    <h5>
                                                      <i
                                                        className={`fas ${
                                                          accordinData.associatedContacts.includes(contactIndex.toString())
                                                            ? 'far fa-chevron-circle-down'
                                                            : 'far fa-chevron-circle-up'
                                                        }`}
                                                      />
                                                      {client.customerType === 'Householder' ? `${
                                                        nth(contactCount)
                                                      } Contact` : `Associated Billing Contact ${contactCount}`}
                                                    </h5>

                                                  </Accordion.Toggle>
                                                  {/* <div
                                                      className={`client-option service-edit-icon ${accordinData.associatedContacts.includes(contactIndex.toString()) ? 'show-associated' : 'hide-img'}`}
                                                      onClick={() => handleContactModal(i, client._id)}
                                                    >
                                                      <i className="fa fa-plus" aria-hidden="true" />
                                                      <div className="client-option service-edit-icon">
                                                        <Dropdown>
                                                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            <img src={showmore} alt="show" />
                                                          </Dropdown.Toggle>
                                                        </Dropdown>
                                                      </div>
                                                    </div> */}
                                                </div>
                                              </Card.Header>

                                              <div key={contactsId}>
                                                {/* <div className={`associated-close ${accordinData.associatedContacts.includes(contactIndex.toString()) && 'show-associated'}`}>
                                              <h6>{`${el.name} ${el.lastName || ''}`}</h6>
                                              <h6>
                                                {el.mobileNo}
                                              </h6>
                                            </div> */}

                                                <Accordion.Collapse eventKey={contactIndex.toString()}>
                                                  <Card.Body>

                                                    <div className="">

                                                      <ul className="staff-list ass-contact">
                                                        <li className="staff-list-item">
                                                          <div className="staff-box">
                                                            <h3>

                                                              Contact Name
                                                            </h3>
                                                            <h5>{`${el.name} ${el.lastName || ''}`}</h5>
                                                          </div>
                                                        </li>
                                                        <li className="staff-list-item">
                                                          <div className="staff-box">
                                                            <h3>Phone No</h3>
                                                            <h5>

                                                              {el.mobileNo}
                                                            </h5>
                                                          </div>
                                                        </li>
                                                        <li className="staff-list-item">
                                                          <div className="staff-box">
                                                            <h3>Relationship to Main Contact</h3>
                                                            <h5>Secretary</h5>
                                                          </div>
                                                        </li>

                                                        <li className="staff-list-item">
                                                          <div className="staff-box">
                                                            <h3>Actions</h3>
                                                            <div className="action-btn">
                                                              <Button className="edit-btn">
                                                                <img
                                                                  src={whiteedit}
                                                                  alt="edit"
                                                                  onClick={() => handleEditContact(el, client._id, i, contactIndex)}
                                                                />
                                                              </Button>
                                                              <Button className="eye-btn">
                                                                <i
                                                                  className={`${
                                                                    el.hide ? 'fas fa-eye-slash' : 'fa fa-eye'
                                                                  }`}
                                                                  onClick={() => handleVisibilityAddr(
                                                                    i,
                                                                    client._id,
                                                                    contactIndex,
                                                                    'associatedContacts',
                                                                  )}
                                                                />
                                                              </Button>
                                                              <Button className="delete-btn">
                                                                <img
                                                                  src={whitedelete}
                                                                  alt="edit"
                                                                  onClick={() => submitRemoveContact(
                                                                    client._id,
                                                                    i,
                                                                    contactIndex,
                                                                    'contact',
                                                                  )}
                                                                />
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      </ul>

                                                    </div>

                                                  </Card.Body>
                                                </Accordion.Collapse>
                                              </div>

                                            </Card>
                                          </Accordion>
                                        )
                                        : null;
                                    })) : null}
                                {client?.siteAddresses?.length > 0
                                && client.siteAddresses.filter((addr) => addr.hide === false || addr.tempShow === true)
                                  .length > 0 ? (
                                    <>
                                      {client.siteAddresses.map((el, addrIndex) => {
                                        let addressesId = '';
                                        if ((!el.hide || el.tempShow === true)) {
                                          addressesId = el.householderName + el.address + el.mobileNo;
                                        }
                                        let jsx = (!el.hide || el.tempShow === true) ? (
                                          <Accordion className="mob-accordion inner-item" key={addressesId}>
                                            <Card>
                                              <Card.Header>
                                                <div className="client-heading-option first-heading site-with-address">
                                                  <Accordion.Toggle
                                                    as={Button}
                                                    variant="link"
                                                    eventKey={addressesId}
                                                    onClick={() => handleSiteAccordion(addressesId, 'addresses')}
                                                  >
                                                    <h5>
                                                      <i
                                                        className={`fas ${
                                                          accordinData.addresses.includes(addressesId)
                                                            ? 'far fa-chevron-circle-down'
                                                            : 'far fa-chevron-circle-up'
                                                        }`}
                                                      />

                                                      {addrIndex === 0 ? (
                                                        <div>
                                                          Site Address
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          {`Site Address ${(addrIndex + 1)}`}
                                                        </div>
                                                      )}

                                                    </h5>
                                                    <h6>{`${el.address}`}</h6>
                                                  </Accordion.Toggle>

                                                  {/* <div
                                                    className="client-option service-edit-icon"
                                                    onClick={() => handleContactModal(i, client._id, addrIndex)}
                                                  >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                  </div> */}
                                                  {/* <div
                                                    className={`client-option service-edit-icon ${accordinData.addresses.includes(addressesId) ? 'show-associated' : 'hide-img'}`}
                                                    onClick={() => handleContactModal(i, client._id, addrIndex)}
                                                  >
                                                    <i className="fa fa-plus" aria-hidden="true" />
                                                    <div className="client-option service-edit-icon">
                                                      <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                          <img src={showmore} alt="show" />
                                                        </Dropdown.Toggle>
                                                      </Dropdown>
                                                    </div>
                                                  </div> */}
                                                </div>
                                              </Card.Header>
                                              <Accordion.Collapse eventKey={addressesId}>
                                                <Card.Body>
                                                  <div className="occupier-contact" key={addressesId}>
                                                    <Card>
                                                      <Card.Body>
                                                        <ul className="staff-list occupier-list">
                                                          <li className="staff-list-item">
                                                            <div className="staff-box">
                                                              <h3>Occupier</h3>
                                                              <h5>{el.householderName}</h5>
                                                            </div>
                                                          </li>
                                                          <li className="staff-list-item">
                                                            <div className="staff-box">
                                                              <h3>Mobile No.</h3>
                                                              <h5>{el.mobileNo}</h5>
                                                            </div>
                                                          </li>
                                                          <li className="staff-list-item">
                                                            <div className="staff-box">
                                                              <h3>Actions</h3>
                                                              <div className="action-btn">
                                                                <Button className="edit-btn">
                                                                  <img
                                                                    src={whiteedit}
                                                                    alt="edit"
                                                                    onClick={() => handleEditAddress(el, client._id, i, addrIndex)}
                                                                  />
                                                                </Button>
                                                                <Button className="eye-btn">
                                                                  <i
                                                                    className={`${
                                                                      el.hide
                                                                        ? 'fas fa-eye-slash'
                                                                        : 'fa fa-eye'
                                                                    }`}
                                                                    aria-hidden="true"
                                                                    onClick={() => handleVisibilityAddr(
                                                                      i,
                                                                      client._id,
                                                                      addrIndex,
                                                                      'siteAddresses',
                                                                    )}
                                                                  />
                                                                </Button>
                                                                <Button className="delete-btn">
                                                                  <img
                                                                    src={whitedelete}
                                                                    alt="edit"
                                                                    onClick={() => submitRemoveAddress(client._id, i, addrIndex)}
                                                                  />
                                                                </Button>
                                                              </div>
                                                            </div>
                                                          </li>
                                                        </ul>

                                                      </Card.Body>
                                                    </Card>
                                                  </div>
                                                </Card.Body>
                                              </Accordion.Collapse>
                                            </Card>
                                          </Accordion>
                                        ) : null;
                                        return jsx;
                                      })}
                                    </>
                                  ) : null}
                              </Card.Body>
                            </Accordion>
                          ) : null}
                        </Card>
                      </Accordion>
                    </div>
                    )}
                  </>
                );
                if (showHide === false && client.hide === true) {
                  jsxData = null;
                }
                return jsxData;
              })}
              {deletePopUp && (
              <DeletePopUp
                  // additionalContactDetails={additionalContactDetails}
                PopUpText={popUpText}
                handlePopUpChange={handlePopUpChange}
                setDeletePopUp={setDeletePopUp}
                paraText={paraText}
              />
              )}
              {showUploadErrorPopup && <UploadIssuePopup show={showUploadErrorPopup} handleCloseIssuePopup={handleCloseIssuePopup} confirmSelect={confirmSelectUploadError} isLoading={isLoading} />}
            </>
          )}
        </>
      ) : null}
    </>
  );
}

ClienTable.propTypes = {
  clientList: PropTypes.func.isRequired,
  setClientList: PropTypes.func.isRequired,
  handleEditClient: PropTypes.func.isRequired,
  removeClient: PropTypes.func.isRequired,
  showHide: PropTypes.func.isRequired,
  searchClient: PropTypes.func.isRequired,
  handleFormModal: PropTypes.func.isRequired,
  handleContactModal: PropTypes.func.isRequired,
  handleEditContact: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired,
  handleSiteAddressModal: PropTypes.func.isRequired,
  handleEditAddress: PropTypes.func.isRequired,
  handleDeleteAddress: PropTypes.func.isRequired,
  handleVisibilityAddr: PropTypes.func.isRequired,
  showAll: PropTypes.func.isRequired,
  hideClicked: PropTypes.func.isRequired,
  hideAll: PropTypes.func.isRequired,
  setClientTxt: PropTypes.func.isRequired,
  clientTxt: PropTypes.func.isRequired,
  setHideSearch: PropTypes.func.isRequired,
  isHideSearch: PropTypes.func.isRequired,
  hideClickedAssociated: PropTypes.func.isRequired,
  setClientDetail: PropTypes.func.isRequired,
  clientDetail: PropTypes.func.isRequired,
  selectedClient: PropTypes.func.isRequired,
  selectedIndex: PropTypes.func.isRequired,
  claddressIndex: PropTypes.func.isRequired,
  backToAppointment: PropTypes.func.isRequired,
  setCrossStatus: PropTypes.bool.isRequired,
  skipValue: PropTypes.number.isRequired,
  setskipValue: PropTypes.func.isRequired,
  showUploadErrorPopup: PropTypes.bool.isRequired,
  setShowUploadErrorPopup: PropTypes.func.isRequired,
  getClientList: PropTypes.func.isRequired,
};

export default ClienTable;
