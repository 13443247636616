import React, { useState, useEffect, useRef } from 'react';
import {
  Modal, Button, Row, Col, Form,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { TimePicker } from 'smart-webcomponents-react/timepicker';
import moment from 'moment';
import 'smart-webcomponents-react/source/styles/smart.default.css';
import NumberFormat from 'react-number-format';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import { onKeyDownNumberWithDecimcal } from '../../functions/index'; // changes by abhishek

const SourceReading = ({
  sourceSelected,
  setSourceSelected,
  poolInputs,
  handleInputs,
  handleUpdateWaterSourceReadings,
  sourcePoolInputs,
  handleSourceInputs,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal') {
            setSourceSelected(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div
      className="duration-picker time-picker-modal source-popup"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup" ref={wrapperRef}>
          <div className="staff-close">
            <i
              className="fa fa-times"
              onClick={() => { setSourceSelected(false); }}
              aria-hidden="true"
            />
          </div>
          <div className="custom-select-time">
            <div className="">
              <div className="col-md-12">
                <h4>Water Source Chemistry</h4>
              </div>
              <div className="bg-color-source">
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="lg" md={8} xs={8} />
                      <Col>
                        <p>Reading</p>
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Free Chlorine
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="chlorine"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.chlorine}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Total Chlorine
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="combinedChlorine"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.combinedChlorine}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          pH
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="ph"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.ph}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Total Alkalinity (TA)
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="alkalinity"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.alkalinity}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Total Hardness
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="calciumHardness"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.calciumHardness}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Stabiliser (CYA)
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="stabiliser"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.stabiliser}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Copper
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="copper"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.copper}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Phosphates
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="phosphates"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.phosphates}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group>
                    <Form.Row>
                      <Col column="xs" md={8} xs={8}>
                        <Form.Label className="target full-width">
                          Salt
                        </Form.Label>
                      </Col>
                      <Col>
                        <NumberFormat
                          type="text"
                          name="salt"
                          className="form-control"
                          // onKeyDown={onKeyDownNumberWithDecimcal} // change by abhishek
                          value={sourcePoolInputs.salt}
                          onChange={handleSourceInputs}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                </div>
              </div>
              <div className="footer-btn view-btn">
                <Button className="appointment-btn btn-save" onClick={handleUpdateWaterSourceReadings}>
                  Save/Update
                </Button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
SourceReading.propTypes = {
  sourceSelected: PropTypes.bool.isRequired,
  setSourceSelected: PropTypes.bool.isRequired,
  poolInputs: PropTypes.bool.isRequired,
  handleInputs: PropTypes.func.isRequired,
  handleUpdateWaterSourceReadings: PropTypes.func.isRequired,
  handleSourceInputs: PropTypes.func.isRequired,
  sourcePoolInputs: PropTypes.string.isRequired,
};
export default SourceReading;
