/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import {
  Form, Card,
} from 'react-bootstrap';
import checkimg from '../../assets/home/check1.png';
import { userInstance } from '../../config/axios';
import { showErrMsg } from '../../functions/index';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import './permissionlevel.css';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import toastMessage from '../../functions/toastMessage';

function PermissionAccessLevel() {
  const [permissions, setPermissions] = useState([]);

  const { userData } = useContext(AuthDataContext);
  // console.log('userData', userData);
  const getPermissions = async () => {
    try {
      // console.log('userData', userData);
      if (userData?.role === 'subscriber' || userData?.role === 'subscriberStaff') {
        const newResponse = await userInstance().get('/permission/getAllSubsPermission');
        // console.log('newrespone====>>', newResponse);
        const { levels, planDetails } = newResponse?.data?.allSubsPermissions;
        // console.log('level========s', levels, planDetails);
        setPermissions(planDetails);
      } else {
        const response = await userInstance().get(`/permission/getPermissions?accessLevel=${userData?.accessLevel}`);
        const { permissionsList } = response?.data;
        setPermissions(permissionsList);
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const updatePermission = async ({ e, _id, level }) => {
    try {
      // console.log(e.target.checked, _id, level);
      let newLevels = [];
      const elementsIndex = permissions.findIndex((element) => element._id === _id);
      const newArray = [...permissions];
      console.log('element', newArray[elementsIndex]);
      if (e.target.checked === false) {
        newLevels = newArray[elementsIndex].level.filter((el) => el !== level);
      } else if (e.target.checked === true) {
        newLevels = [...newArray[elementsIndex].level, level];
      }
      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        level: newLevels,
      };

      if (userData.accessLevel === 'premium' || userData.accessLevel === 'standard' || userData.accessLevel === 'basic') {
        newLevels = newArray;
      }
      if (userData?.role === 'subscriber') {
        const response = await userInstance().put('/permission/updateSubsAllPermission', { _id, level: newLevels });
        // console.log('response', response);
      } else {
        const response = await userInstance().put('/permission/updatePermission', { _id, level: newLevels, accessLevel: userData.accessLevel });
        // console.log('response', response);
        const { msg } = response.data;
        toastMessage('success', msg);
      }
      // console.log('newArray', newArray);
      setPermissions(newArray);
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };

  const checkPermission = (level, data) => {
    // console.log('data', data, 'level', level);
    let checked = true;
    const filteredData = data.filter((el) => el === level);
    if (!filteredData.length) {
      checked = false;
    }
    return checked;
  };

  // const updatePermissions = async () => {
  //   try {
  //     const response = await userInstance().put('/permission/update', { permissions });
  //     console.log('response', response);
  //     const { msg } = response.data;
  //     NotificationManager.error(msg, 'Message', 1000);
  //   } catch (error) {
  //       console.log(error);
  //   }
  // };
  // console.log('permissions==>', permissions);
  useEffect(() => {
    getPermissions();
  }, []);
  return (
    <>
      <AdminWrapper>
        <div className="permission-container">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/* <div className="user-title">
                <h4>PROFESSIONAL USER</h4>
              </div> */}
                <div className="main-titles">
                  <h3>Permission & Access Level</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="permission-box permission-box-mob" hidden={!permissions.length}>
                  <Card>
                    <Card.Body>
                      {permissions.map((el) => (
                        <div className="permission-level">
                          <h6>{el.action}</h6>
                          <div className="permission-access">
                            <div className="basic">
                              <h6>Low</h6>
                              <Form.Check
                                type="checkbox"
                                id={`low ${el._id}`}
                                checked={checkPermission('low', el.level)}
                                onChange={(e) => updatePermission({ e, _id: el._id, level: 'low' })}
                              />
                            </div>
                            <div className="basic">
                              <h6>Medium</h6>
                              <Form.Check
                                type="checkbox"
                                id={`medium ${el._id}`}
                                checked={checkPermission('medium', el.level)}
                                onChange={(e) => updatePermission({ e, _id: el._id, level: 'medium' })}
                              />
                            </div>
                            <div className="basic">
                              <h6>High</h6>
                              <Form.Check
                                type="checkbox"
                                id={`high ${el._id}`}
                                checked={checkPermission('high', el.level)}
                                onChange={(e) => updatePermission({ e, _id: el._id, level: 'high' })}
                              />
                            </div>
                            {/* <div className="basic">
                              <h6>Owner</h6>
                              <span className="check-img">
                                <img src={checkimg} alt="check-img" />
                              </span>
                              {' '}
                            </div> */}
                          </div>
                        </div>
                      ))}
                    </Card.Body>
                  </Card>

                  {/* <div className="view-btn">
                  <Button type="submit" className="btn btn-save">Save/Update</Button>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminWrapper>
    </>
  );
}
export default PermissionAccessLevel;
