import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Spinner,
} from 'react-bootstrap';

const UploadDocx = ({
  showPopup, handleImagePopup, uploadDocx, setSelectedImgData, isloading,
}) => {
  const [filename, setFileName] = useState('');
  const setTemplateLogo = async (data) => {
    try {
      const { name } = data.target.files[0] || {};
      setFileName(name);
      setSelectedImgData(data.target.files[0]);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Modal
        size="md"
        show={showPopup}
        onHide={handleImagePopup}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="search-modal new-search-popup1 image-upload-popup new-image-popup"
      >
        <Modal.Body>
          <div className="appointment-container preview-invoice preview-popup">
            <div className="change-image-section">
              <div className="field-location-section">
                <div className="preview-image">
                  <h6>Preview docx</h6>
                  <span
                    style={{
                      display: 'inline-block',
                      height: '80px',
                      width: '100%',
                      border: '1px solid #dcd9d9 ',
                      padding: '10px',
                      cursor: 'pointer',
                      overflow: 'hidden',
                      position: 'relative',
                    }}
                  >
                    {filename ? (
                      <h6>
                        <img src="https://tse4.mm.bing.net/th?id=OIP.jnDNojHk5-kel05zeNllyQHaIK&pid=Api&P=0&h=180" alt="logo" height="50%" />
                        {filename}
                      </h6>
                    ) : <h6>Upload docx</h6>}
                  </span>
                </div>

                <div className="event-popup-btn footer-btn view-btn template-btn temp-btn  ">
                  <Button className="appointment-btn btn-edit" type="button" onClick={() => handleImagePopup()}>
                    Cancel
                  </Button>
                  {filename ? (
                    <Button className="save-btn" onClick={() => uploadDocx()}>

                      {isloading
                        ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : '    Save/ Update'}
                    </Button>
                  )
                    : (
                      <Button type="submit" className="save-template upload-logo">
                        <input
                          type="file"
                          id="upload"
                          onChange={(e) => setTemplateLogo(e)}
                          accept=".docx"
                        />
                        <Form.Group>
                          <Form.Label htmlFor="upload">
                            {' '}
                            {isloading
                              ? (
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                              ) : 'Select File'}

                          </Form.Label>
                        </Form.Group>
                      </Button>
                    )}

                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
UploadDocx.propTypes = {
  showPopup: PropTypes.func.isRequired,
  handleImagePopup: PropTypes.func.isRequired,
  uploadDocx: PropTypes.func.isRequired,
  setSelectedImgData: PropTypes.func.isRequired,
  isloading: PropTypes.func.isRequired,
};
export default UploadDocx;
