import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal,
} from 'react-bootstrap';
import Preview from 'preview-office-docs';

const PreviewDocxTemp = ({
  previewPopup, handlePreviewPopup, url, onError,
}) => (
  <>
    <Modal
      size="md"
      show={previewPopup}
      onHide={handlePreviewPopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 preview-popup preview-pdf"
    >
      <Modal.Body>
        <div className="appointment-container preview-invoice preview-popup">
          <div className="back-option" onClick={handlePreviewPopup}>
            <span />
            <i className="fa fa-times" aria-hidden="true" />
          </div>
          <div className="edit-template-page" key={url}>
            <Preview
              url={url}
              height="500px"
              width="100%"
            />
          </div>

          <div className="close-btn">
            <Button className="btn btn-primary" onClick={() => handlePreviewPopup()}>Close</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  </>
);
PreviewDocxTemp.propTypes = {
  previewPopup: PropTypes.func.isRequired,
  handlePreviewPopup: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
};
export default PreviewDocxTemp;
