import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import showmore from '../../assets/home/showmoreblue.png';
import SalesSummary from './salesSummary';
import TransactionSummary from './transactionsummary';

const Transaction = ({ locationData }) => {
  const [view, setView] = useState('Transaction Summary');
  const renderView = () => {
    let componentToRender = null;
    switch (view) {
      case 'Transaction Summary':
        componentToRender = (
          <TransactionSummary locationData={locationData} />
        );
        break;

      default:
        break;
    }
    return componentToRender;
  };

  const updateView = (key) => {
    setView(key);
  };
  return (
    <div className="reports-appointment pool-set-up search-invoice">
      <div className="target-heading client-profile">
        <>
          <div className="">
            <h3>
              {view}
            </h3>
          </div>
          <div className="content-dropdown">
            <div className="client-option service-edit-icon">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={showmore} alt="show" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div className="action" onClick={() => updateView('Transaction Summary')}>
                      <span>Transaction Summary</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action">
                      <span>Transaction Detail</span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <div className="action">
                      <span>Download Displayed Data in Excel</span>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </>
      </div>
      {renderView()}
    </div>

  );
};

Transaction.propTypes = {
  locationData: PropTypes.arrayOf.isRequired,
};
export default Transaction;
