import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Spinner,
} from 'react-bootstrap';
// import logo from '../../assets/home/temp-logo.png';

const UploadIssuePopup = ({
  handleCloseIssuePopup,
  confirmSelect,
  isLoading,
}) => {
  const [selectOption, setselectOption] = useState('view-error');
  const handleChange = (e) => {
    setselectOption(e.target.value);
  };
  const handleChangeForDiv = (value) => {
    setselectOption(value);
  };
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.srcElement.className === 'duration-picker time-picker-modal download-csv-popup'
          ) {
            handleCloseIssuePopup();
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="duration-picker time-picker-modal download-csv-popup">
      <div>
        <div
          className="time-keeper-popup duration-popup event-popup"
          ref={wrapperRef}
        >
          <h6>Upload Issues</h6>
          <p className="duplicate-text">Duplicated email address, phone or mobile numbers, etc.</p>
          <div className="appointment-view">
            <div className="pending-water-test" onClick={() => handleChangeForDiv('view-error')}>
              <h3>View Error Data</h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    value="view-error"
                    onChange={handleChange}
                    checked={selectOption === 'view-error'}
                  />
                </div>
              </div>
            </div>
            <div className="pending-water-test" onClick={() => handleChangeForDiv('continue')}>
              <h3>Continue Upload </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    value="continue"
                    onChange={handleChange}
                    checked={selectOption === 'continue'}
                  />
                </div>
              </div>
            </div>

            <div className="event-popup-btn footer-btn view-btn">
              <Button className="appointment-btn btn-edit" type="button" onClick={handleCloseIssuePopup}>
                Cancel
              </Button>
              <Button
                type="button"
                className="appointment-btn btn-save action csvUploads"
                onClick={() => confirmSelect(selectOption)}
              >
                {isLoading ? <Spinner animation="border" /> : 'Select'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
UploadIssuePopup.propTypes = {
  handleCloseIssuePopup: PropTypes.func.isRequired,
  confirmSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
export default UploadIssuePopup;
