import React, { useEffect, useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import AdminWrapper from '../admin/adminwrapper/wrapper';
import arrow from '../../assets/home/arrowdown.png';
import './report.css';
import ReportsAppointments from './appoinments';
import ReportsListing from './reportsListing';
import CustomerPayments from './customerPayments';
import showmore from '../../assets/home/showmoreblue.png';
import DebtorsCreditors from './debtorsCreditors';
import Inventory from './inventory';
import Sales from './sales';
import Purchases from './purchases';
import Transaction from './transaction';
import Loader from '../loader/loader';
import LogActivity from './logActivity';
import { userInstance } from '../../config/axios';

const Reports = () => {
  const [view, setView] = useState('reportListing');
  const [name, setName] = useState('Reports');
  const [loderStatus, setLoderStatus] = useState(false);
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await userInstance().get('/appointment/getLocationList');
      const { locationList, code } = response.data;
      locationList.sort((a, b) => a.locationName.localeCompare(b.locationName));
      if (code === 200) {
        setLocationData([...locationList]);
      }
    })();
  }, []);

  // console.log({ locationData });

  const renderView = () => {
    let componenettorender = null;
    switch (view) {
      case 'reportListing':
        componenettorender = (
          <ReportsListing updateView={updateView} setLoderStatus={setLoderStatus} />
        );
        break;
      case 'Appointments':
        componenettorender = (
          <ReportsAppointments setLoderStatus={setLoderStatus} />
        );
        break;
      case 'Customer Payments':
        componenettorender = (
          <CustomerPayments setLoderStatus={setLoderStatus} locationData={locationData} />
        );
        break;
      case 'Debtors & Creditors':
        componenettorender = (
          <DebtorsCreditors setLoderStatus={setLoderStatus} locationData={locationData} />
        );
        break;
      case 'Inventory':
        componenettorender = (
          <Inventory />
        );
        break;
      case 'Sales':
        componenettorender = (
          <Sales />
        );
        break;
      case 'Purchases':
        componenettorender = (
          <Purchases />
        );
        break;
      case 'Transactions':
        componenettorender = (
          <Transaction locationData={locationData} />
        );
        break;
      case 'Invoices & Payment Reminders Sent':
        componenettorender = (
          <LogActivity />
        );
        break;
      default:
        break;
    }
    return componenettorender;
  };

  const updateView = (key) => {
    setView(key);
    setName(key);
    // setLoderStatus(true);
  };
  return (
    <AdminWrapper>
      {loderStatus
        ? <Loader />
        : (
          <div className="reports-page staff-container">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="main-titles-mob">
                    <h3>{name}</h3>
                    {view === 'reportListing' ? '' : (
                      <div className="content-dropdown">
                        <div className="client-option service-edit-icon">
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <img src={showmore} alt="show" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Appointments')}>
                                  <span>Appointments</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Customer Payments')}>
                                  <span>Customer Payments</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Debtors & Creditors')}>
                                  <span>Debtors & Creditors</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Inventory')}>
                                  <span>Inventory</span>
                                </div>
                              </Dropdown.Item>
                              {/* <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Subscription')}>
                                  <span>Subscription Management</span>
                                </div>
                              </Dropdown.Item> */}
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Sales')}>
                                  <span>Sales</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Purchases')}>
                                  <span>Purchases</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Transactions')}>
                                  <span>Transactions  Audit</span>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <div className="action" onClick={() => updateView('Invoices & Payment Reminders Sent')}>
                                  <span>Invoices & Payment Reminders Sent</span>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </div>
                  {renderView()}
                </div>
              </div>
            </div>
          </div>
        )}
    </AdminWrapper>
  );
};
export default Reports;
