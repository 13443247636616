const getStrNum = (value) => {
  // if (value === 'initial') {
  //   return { num: 0, str: '' };
  // }
  //   const num = val?.toString()?.match(/\d+/g);
  //   const str = val?.toString()?.match(/[a-zA-Z]+/g);
  //   const numVal = num && num?.length > 0 ? num[0] : '';
  //   const strval = str && str?.length > 0 ? str[0] : '';
  //   const newStr = strval || '%';
  //   return { num: numVal, str: newStr };
  if (!value) {
    return { num: '', str: '' };
  }
  const splitValue = value?.toString()?.includes('%') ? '%' : value?.toString()?.includes('px') ? 'px' : '';
  let nSplit = [value];
  if (splitValue) {
    nSplit = value.split(splitValue);
  }

  return { num: nSplit[0] || '', str: splitValue };
};

export default getStrNum;
