/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/destructuring-assignment */
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { AuthDataContext } from '../../contexts/AuthDataContext';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
const PreviewTemplate = ({
  templateData, templateDataOne, customFeilds, previewUrl, loading,
}) => {
  // console.log('previewwww', templateData, templateDataOne, customFeilds);
  const {
    userData,
  } = useContext(AuthDataContext);
  let countryType = userData?.userDetails?.country === 'United States' ? 'us' : 'au';
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const url = previewUrl;
  const onDocumentLoad = ({ numPagess }) => {
    setPageNumber({ numPagess });
  };
  return (
    <>
      {' '}
      {loading ? (
        <p>Loading PDF...</p>
      ) : (
        <TransformWrapper>
          <TransformComponent>
            <Document
              file={url}
              onLoadError={(error) => console.log(`Error while loading document! ${error.message}`)}
            >
              <Page size={`${countryType === 'us' ? 'Letter' : 'A4'}`} pageNumber={pageNumber} />
            </Document>
          </TransformComponent>
        </TransformWrapper>
      )}
    </>
  );
};

PreviewTemplate.propTypes = {
  templateData: PropTypes.arrayOf.isRequired,
  templateDataOne: PropTypes.arrayOf.isRequired,
  customFeilds: PropTypes.arrayOf.isRequired,
  previewUrl: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default PreviewTemplate;
