import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'react-bootstrap';
import DateModal from '../appointment/dateModal';

const SaveTemplatePopup = ({
  setSavePopup, handleDataPopup, templateName, saveTemplate, view,
}) => {
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (event.srcElement.className === 'duration-picker time-picker-modal save-template-popup') {
            setSavePopup(false);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  return (
    <div
      // className="duration-picker time-picker-modal save-template-popup "
      className="duration-picker time-picker-modal"
    >
      <div>
        <div className="time-keeper-popup duration-popup event-popup invoice-template-popup" ref={wrapperRef}>
          <h5>{view === 'editTemplate' ? 'Edit Invoice Template' : 'Create New Invoice Template'}</h5>
          <Form.Group controlId="formBasicInput-1">
            <Form.Label>
              Name of New Invoice Template
            </Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              placeholder="Enter Name of Template"
              value={templateName}
              onChange={(e) => handleDataPopup(e.target.value)}
            />
          </Form.Group>
          <div className="template-btn view-btn">
            <Button className="cancel-btn" onClick={() => setSavePopup(false)}>Cancel</Button>
            <Button className="save-btn" onClick={() => saveTemplate()}>Save/ Update</Button>
          </div>
        </div>
      </div>
      {/* <DateModal blockedtime={blockedtime} setBlockedtime={setBlockedtime} /> */}
    </div>
  );
};
SaveTemplatePopup.propTypes = {
  setSavePopup: PropTypes.func.isRequired,
  handleDataPopup: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
};
export default SaveTemplatePopup;
