/* eslint-disable no-undef */
import React from 'react';
import { Form, Button, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

const TaxApplicable = ({
  handleChangeTaxValues, handleRequest, handleRevertViewUpdate, state, handleNewRevertDefaultUpdate, change, isUsImperial, error, taxStatus, taxStatusLabour,
}) => (
  <div className="pool-setup mineral-pool drainage-cost-section tax-applicable-page">
    <div className="target-heading">
      <h6>Tax Applicable</h6>
    </div>
    <div className="pool-chemistry-box">

      <div className="row">
        <div className="col-12">
          <Form>
            <Form.Group controlId="formBasicInput" className="italic-text">
              <Form.Label>Chemicals</Form.Label>
              <Form.Control
                type="text"
                name="chemicalsTax"
                onChange={handleChangeTaxValues}
                value={state?.chemicalsTax}
                placeholder="Enter Tax (if no tax, enter as 0)"
                className={`${
                  error === 'chemicalsTax'
                    ? 'error-text'
                    : ''
                }`}
              />
              <span className="price-icon">%</span>
            </Form.Group>

          </Form>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Form>
            <Form.Group controlId="formBasicInput" className="italic-text">
              <Form.Label>
                {' '}
                Labour
              </Form.Label>
              <Form.Control
                type="text"
                name="labourTax"
                onChange={handleChangeTaxValues}
                value={state?.labourTax}
                placeholder="Enter Tax (if no tax, enter as 0)"
                className={`${
                  error === 'labourTax'
                    ? 'error-text'
                    : ''
                }`}
              />
              <span className="price-icon">%</span>
            </Form.Group>
          </Form>
        </div>
      </div>

      <div className="view-btn justify-content-end">
        {/* <Button type="submit" className="btn btn-edit" onClick={(e) => handleRevertViewUpdate(e, state)}>Revert To Default</Button> */}
        {/* <div className="client-option service-edit-icon">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
              View Other Settings
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={(e) => handleRevertViewUpdate(e, state)}>
                <div className="action">
                  Restore Original Settings
                </div>
              </Dropdown.Item>
              <Dropdown.Item onClick={(e) => handleNewRevertDefaultUpdate(e, state)}>
                <div className="action">
                  Revert to Previous Saved Default
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
        <Button type="submit" className="btn btn-save" onClick={handleRequest}>
          Save/Update
        </Button>
      </div>
    </div>
    {/* <div className="bottom-text">
      <span className="require-icon">*</span>
      <p>

        {' '}
        Note that these three costs are used in the
        <b> Calculator</b>
        {' '}
        for Drainage Cost.
      </p>
    </div> */}
  </div>

);
TaxApplicable.propTypes = {
  handleChangeTaxValues: PropTypes.func.isRequired,
  handleRequest: PropTypes.func.isRequired,
  state: PropTypes.isRequired,
  handleRevertViewUpdate: PropTypes.func.isRequired,
  handleNewRevertDefaultUpdate: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isUsImperial: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  taxStatus: PropTypes.bool.isRequired,
  taxStatusLabour: PropTypes.bool.isRequired,
};

export default TaxApplicable;
