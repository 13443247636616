/* eslint-disable consistent-return */
import React, {
  useState, useRef, useEffect, useContext,
} from 'react';
import {
  Modal, Button, Form, Dropdown, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory, useSearchParams } from 'react-router-dom';
import arrow from '../../assets/home/arrowdown.png';
import sort from '../../assets/home/sort.png';
import {
  showErrMsg, addZero, averageGstCalculation, removeComma, totalPriceWithGst, queryString, calculatChemicalProductCost, updateServiceDateTime, currencyComma, gstChemicalsCalculations,
} from '../../functions/index';
import { notTimeOutUserInstance, userInstance, withoutAuth } from '../../config/axios';
import PreviewQuote from './previewQuote';
import ServiceModal from '../appointment/servicemodal';
import TimePicker from '../appointment/timepicker';
import DateModal from './datePopup';
import upsort from '../../assets/home/up-sort.png';
import downsort from '../../assets/home/down-sort.png';
import DurationPicker from '../appointment/durationPicker';
import HourPicker from '../appointment/hourPicker';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { errorBorderTime, errorMsgTime, successMsgTime } from '../../functions/intervalTime';
import SMSOption from './smsOption';
import ChangeTextPopup from './quotechangeTextPopup';
import QuotesTextOption from './quotesTextOption';
import toastMessage from '../../functions/toastMessage';
import DeleteWaterTestPopup from '../appointment/deleteWaterTest';
import { displayCacliPrices, displayDollar } from '../calculator/calculatorUtils';

const QuoteDetail = ({
  addClient, setQuoteType, saveQuoteType, showQuote, setAddClient, setSelectedClient, activeAdd, setActiveAdd, setsaveQuoteType, handlePreviewEdit, quoteDataFromAppointment, from, savetype,
}) => {
  // console.log('saveQuoteType ==>', {
  //   saveQuoteType, showQuote,
  // });
  const history = useHistory();
  const {
    locationsInfo, userData: { industryId }, clientAllData, setClientAllData,
  } = useContext(AuthDataContext);
  const { companyContactDetail, companyInformation } = locationsInfo || {};
  const {
    address, companyName, postcode,
  } = companyInformation || {};
  let cstate = companyInformation ? companyInformation.state : '';
  const {
    mobile, email,
  } = companyContactDetail || {};
  let companyaddress = `${address || ''} ${companyInformation?.suburb || ''} ${cstate || ''} ${postcode || ''}`;
  let companyLocations = companyaddress !== '  ' ? companyaddress : '';

  const [preview, setPreview] = useState();
  const [datePopup, setDatePopup] = useState();
  const [gst, setGst] = useState('');
  const [quotesTotal, setQuotesTotal] = useState('');
  const [quoteSign, setQuoteSign] = useState('');
  const [templateArray, setTemplateArray] = useState([]);
  const [templateType, setTemplateType] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [customFeilds, setCustomFeilds] = useState();
  const [smsOption, setSMSOption] = useState(false);
  const [waterTestPopup, setWaterTestPopup] = useState(false);
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ';
  const timeFromDateFormat = 'HH:mm';
  let setDefaultTime = '9:30';

  const initialBookingData = {
    frequency: "One-off appointment | Doesn't repeat",
    repeatEnd: 'Ongoing',
    category: '',
    subcategory: '',
    startTime: setDefaultTime,
    duration: '5',
    staffId: '',
    staffName: '',
    clientId: '',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    timeChange: false,
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    previousCost: '',
    prevBuyCostProduct: '',
    renderCost: '',
    soldPer: '',
    unit: '',
    symbol: '',
    description: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    supplier: '',
  };
  const otherBookingInitial = {
    category: '',
    subcategory: '',
    duration: '5',
    cost: '',
    start: '',
    end: '',
    customCost: 'default',
    type: '',
    buyCostProduct: '',
    laborMargin: '',
    quantity: '1',
    customMisc: '',
    previousDuration: '',
    previousQuantity: '',
    pricingType: 'fixedCost',
    soldPer: '',
    unit: '',
    symbol: '',
    tax: '',
    s_uId: '',
    categoryId: '',
    supplier: '',
  };
  let {
    startDateTime,
    employee_id,
    appId,
    blocktime,
    blocktype,
    block_id,
    quoteId,
    clientid,
    waterTest,
    waterId,
    typo,
    cust,
    quoteWatTesId,
    watTesId,
  } = queryString();
  const initialDatePopup = { interval_type: 'weekly', interval_multiplier: '4' };
  const initialDateData = {
    date: moment().format(dateFormat),
    createdOn: new Date(),
    interval_multiplier: null,
    occurence: null,
    interval_type: null,
    endsAt: null,
    endType: null,
    msg: "One-off appointment | Doesn't repeat",
    frequencyMsg: '',
    occurenceMsg: '',
    specificDate: moment().format(dateFormat),
    requiredday: null,
    appointmenttype: 'oneteam_oneday',
    service: [],
  };
  const multiNotesState = [
    { notes: null },
  ];
  const [multiNotes, setMultiNotes] = useState(multiNotesState);
  const [showService, setShowService] = useState(false);
  const [bookingData, setBookingData] = useState(initialBookingData);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState(null);
  const [staffList, setStaffList] = useState([]);
  const [addedServices, setServices] = useState([]);
  const [editData, setEditData] = useState({ edit: false, index: '' });
  const [notes, setNotes] = useState('');
  const [hourPicker, setHourPicker] = useState(false);
  const [hourData, setHourData] = useState('');
  const [summary, setSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
    totalLabour: 0,
  });

  const [dateData, setDateData] = useState(initialDateData);
  const [renderSort, setRenderSort] = useState(false);
  const [misc, setMisc] = useState(false);
  const [extras, setExtras] = useState({ value: '', msg: '' });
  const [timepickerShow, setTimePickerShow] = useState(false);
  const [subcategoryTxt, setSearchTxt] = useState('');
  const [durationPicker, setDurationPicker] = useState(false);
  const [timeData, setTime] = useState('9:30');
  const [durationData, setDuration] = useState('');
  const [repeatFrequency, setRepeatFrequency] = useState('');
  const [datePopupData, setDatePopupData] = useState(initialDatePopup);
  const timepickerRef = useRef(null);

  const [newLength, setnewlength] = useState();
  const [staffIndex, setStaffIndex] = useState();
  const [quoteDate, setQuoteDate] = useState(new Date());
  const [appointmentType, setAppointmentType] = useState('oneteam_oneday');
  const [multiServices, setMultiServices] = useState([]);
  const [multiAppointment, setmultiAppointment] = useState(false);
  const [newAppointment, setNewAppointment] = useState([initialDateData]);
  const [appointmentIndex, setappointmentIndex] = useState('');
  const [quoteNumber, setQuoteNumber] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previewPopup, setPreviewPopup] = useState();
  const [success, setSuccess] = useState(false);
  const [signatureList, setSignatureList] = useState([]);
  const [quoteurl, setquoteUrl] = useState('/quotes/addQuotes');
  const [erroValue, setErrorValue] = useState('');
  const loginStaff = localStorage.getItem('loginStaff');
  const queryParams = new URLSearchParams(window.location.search);
  const [quoteOpeningText, setQuoteOpeningText] = useState('Thank you for the time spent discussing your pool requirements. Please find our quote detailed below with our recommendations and costs.');
  const [quoteClosingText, setQuoteClosingText] = useState('I hope that the above pricing meets with your approval and look forward to being of service to you in the future. If we have misinterpreted your requirements or if you require any additional detail or ammendments, Please do not hesitate to contact me on 0409 774 687');
  const [textPopup, setTextPopup] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('');
  const [quoteText, setQuoteText] = useState('');
  const [text, setText] = useState({});
  const [isOpenText, setIsOpenText] = useState(false);
  const [iscloseText, setIsCloseText] = useState(false);
  const [quoteOption, setQuoteOption] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [templateData, setTemplateData] = useState([]);
  const [copyService, setCopyService] = useState([]);
  const [requiredDays, setRequiredDays] = useState(2);
  const testChemicals = {};
  const [signStaff, setSignStaff] = useState({
    name: '',
    type: '',
    signature: '',
    complimentary: '',
  });
  let waterTestInitial = {
    newTotalCost: 0,
    newTotalDuration: 0,
    newTotalLabourMargin: 0,
  };
  const [waterServiceSummary, setWaterServiceSummary] = useState(waterTestInitial);
  const [chemicalData, setChemicalData] = useState([]);
  const [chemicalgst, setChemicalGst] = useState(0);
  const [mutiSummary, setMutiSummary] = useState({
    totalCost: 0,
    totalDuration: '',
    totalLabourMargin: 0,
    totalLabourAmount: 0,
    totalLabour: 0,
  });

  useEffect(() => {
    if (saveQuoteType === 'addnew') {
      handleReset();
    }
  }, [saveQuoteType]);

  const calculateWatesrCost = (data) => {
    let allChemicalServList = [];
    if (waterTest === 'done') {
      data?.testResult?.forEach((el, i) => {
        let serviceProTypearr = el.service.filter(
          (el2) => el2.type === 'service'
            || (el2.type === 'product' && el2.status !== 'quantity'),
        );
        allChemicalServList.push(serviceProTypearr);
      });
      const { subtotalCost, subtotalDuration, subtotalLabour } = calculatChemicalProductCost(allChemicalServList);
      let newTotalCost = parseFloat(data?.totalCost) - parseInt(subtotalCost, 10);
      let newTotalDuration = data?.totalDuration - parseInt(subtotalDuration, 10);
      let newTotalLabourMargin = parseFloat(data?.labourMargin) - parseInt(subtotalLabour, 10);

      setWaterServiceSummary({
        ...waterServiceSummary,
        newTotalCost: addZero(parseFloat(newTotalCost).toFixed(2)),
        newTotalDuration,
        newTotalLabourMargin: addZero(
          parseFloat(newTotalLabourMargin).toFixed(2),
        ),
      });
    }
  };
  const TotalPartVist = (el) => {
    let totalcost = el.reduce(
      (pre, next) => parseFloat(pre || 0)
        + parseFloat(next.renderCost ? (next.renderCost === 'N/A' ? 0 : next.renderCost) : 0),
      0,
    );
    testChemicals.totalCost = totalcost.toFixed(2);
    return totalcost.toFixed(2);
  };
  const TotalPartLabour = (el) => {
    let totalLabourOnly = el.reduce(
      (pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0)
        + parseFloat(next?.totalLabour ? (next?.totalLabour === 'N/A' ? 0 : next?.totalLabour) : 0),
      0,
    );
    let totalMargin = el.reduce(
      (pre, next) => parseFloat((pre === 'N/A' ? 0 : pre) || 0)
        + parseFloat(next?.laborMargin ? (next?.laborMargin === 'N/A' ? 0 : next?.laborMargin) : 0),
      0,
    );
    let totalLabour = totalLabourOnly + totalMargin;
    return totalLabour;
  };
  const calculateMutiCost = (item, index) => {
    if (waterTest === 'done') {
      const lastAppServ = item[index]?.service?.length;
      const lastServiceArr = item[index].service[lastAppServ - 1];
      if (lastServiceArr) {
        const updatedChemicalList = updateServiceDateTime(
          item[index].chemicalTestArray,
          lastServiceArr,
        );
        item[index].chemicalTestArray = updatedChemicalList;
      }
    }
    const totalCost = item
      .map((el2) => el2?.service.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.renderCost ? next.renderCost : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    const totalLabourAmount = item
      .map((el2) => el2?.service.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.totalLabour ? next.totalLabour : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);

    const totalMargin = item
      .map((el2) => el2?.service.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.laborMargin ? next.laborMargin : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    const totalLabour = totalLabourAmount + totalMargin;
    const totalDuration = item
      .map((el2) => el2.service.reduce(
        (pre, next) => parseFloat(pre || 0)
            + parseFloat(next.duration ? next.duration : 0),
        0,
      ))
      .reduce((pre, next) => pre + next);
    if (index !== null) {
      const subSummary = item[index].service;
      let obj = {
        totalCost: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
        totalDuration: TotalPartduration(subSummary),
        totalLabour: addZero(
          parseFloat(TotalPartLabour(subSummary)).toFixed(2),
        ),
        multiTotal: addZero(parseFloat(TotalPartVist(subSummary)).toFixed(2)),
        totalMargin: parseFloat(totalMargin).toFixed(2),
        totalLabourOnly: parseFloat(totalLabourAmount).toFixed(2),
      };
      item[index].summary = obj;
      setNewAppointment(item);
    }
    setMutiSummary({
      ...mutiSummary,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      totalLabourMargin: addZero(parseFloat(totalMargin).toFixed(2)),
      multiTotal: totalCost,
      totalLabourAmount: addZero(parseFloat(totalLabourAmount).toFixed(2)),
    });
  };
  const TotalPartduration = (el) => {
    let totalDuration = el.reduce(
      (pre, next) => parseFloat(pre || 0) + parseFloat(next?.duration ? next?.duration : 0),
      0,
    );
    return totalDuration;
  };
  useEffect(() => {
    if (waterTest === 'done') {
      const resulttChemical = JSON.parse(localStorage.getItem('chemicalalist'));
      const chemicalalist = resulttChemical || {};
      calculateWatesrCost(chemicalalist[0], '');
      if (chemicalalist[0]?.testResult.length >= 1) {
        const calcType = chemicalalist[0]?.testResult[0].serviceAnalysis;
        // setCalculater_type(calcType);
        let testCount = chemicalalist[0]?.testResult.length;
        let apptypes = 'oneteam_oneday';
        let apptypemsg = "One-off appointment | Doesn't repeat";
        if (testCount > 1) {
          apptypes = 'oneteam_multiday';
          apptypemsg = `One Team Member Multi Visit | ${chemicalalist[0]?.testResult.length} days | Doesn't repeat`;
          setAppointmentType('oneteam_multiday');
        }
        let waterarr = [];
        let flag = new Date();

        chemicalalist[0].testResult.forEach((el, i) => {
          let copyinitialDateData = { ...initialDateData };
          const chemicalaTypeList = el.service.filter(
            (el2) => el2.type === 'product' && el2.status === 'quantity',
          );
          const serviceProTypeList = el.service.filter(
            (el2) => el2.type === 'service'
              || (el2.type === 'product' && el2.status !== 'quantity'),
          );
          const chemicalGst = gstChemicalsCalculations(chemicalaTypeList);
          setChemicalGst(chemicalGst);
          if (i === 0) {
            setServices(serviceProTypeList);
          }

          setChemicalData(chemicalaTypeList);
          calculateSummary(serviceProTypeList);
          copyinitialDateData.chemicalTestArray = chemicalaTypeList || [];
          copyinitialDateData.service = serviceProTypeList || [];
          copyinitialDateData.appointmenttype = apptypes;
          copyinitialDateData.msg = apptypemsg;
          copyinitialDateData.AllchemicalTestArray = el.service;
          if (i === 0) {
            copyinitialDateData.date = moment(flag).format(dateFormat);
          } else if (calcType === 'Black-Spot Algae' || calcType === 'Drain Pool') {
            copyinitialDateData.date = moment(flag)
              .add(2, 'days')
              .format(dateFormat);
          } else if (calcType === 'Green Pool') {
            copyinitialDateData.date = moment(flag)
              .add(4, 'days')
              .format(dateFormat);
          } else {
            copyinitialDateData.date = moment(flag)
              .add(1, 'days')
              .format(dateFormat);
          }

          flag = copyinitialDateData.date;
          waterarr.push(copyinitialDateData);
          setRequiredDays(parseInt(testCount, 10));
          calculateMutiCost(waterarr, i);
        });
        setNewAppointment(waterarr);
      }
    }
  }, []);

  const handleSaveDate = () => {
    updateDateData();
    handleFrequency();
  };

  const isValidDate = (d) => d instanceof Date && !Number.isNaN(d);
  const handleDatePopup = (e) => {
    if (isValidDate(e)) {
      setDateData({ ...dateData, date: e });
      setQuoteDate(e);
    }
    setDatePopup(!datePopup);
  };

  const setStaff = (staffName, staffId) => {
    const servicesListCopy = addedServices;
    servicesListCopy.forEach((el) => {
      el.staffId = staffId;
      el.staffName = staffName;
    });
    setServices([...servicesListCopy]);
  };

  const handleChangeNotesMulti = (name, value, index) => {
    let val = [...newAppointment];
    val[index][name] = value;
    setMultiNotes(val);
  };

  const updateDateData = (isEndType) => {
    let { interval_type, interval_multiplier } = datePopupData;
    const originalIntervalType = interval_type;
    let frequencyMsg = '';
    if (parseInt(interval_multiplier, 10) > 1) {
      switch (interval_type) {
        case 'monthly':
          interval_type = 'months';
          break;
        case 'weekly':
          interval_type = 'weeks';
          break;
        case 'daily':
          interval_type = 'days';
          break;

        default:
          break;
      }
    }
    frequencyMsg = parseInt(interval_multiplier, 10) > 1 ? `Repeats every ${interval_multiplier} ${interval_type}` : interval_type;
    const updateObj = {
      ...dateData, interval_multiplier, interval_type: originalIntervalType, frequencyMsg,
    };
    if (isEndType) {
      updateObj.endType = 'ongoing';
    }
    setDateData(updateObj);
  };

  const handleChangeDate = (type, value) => {
    setDatePopupData({ ...datePopupData, [type]: value });
  };

  const handleFrequency = () => {
    setRepeatFrequency(!repeatFrequency);
    if (!repeatFrequency) {
      setTimeout(() => {
        timepickerRef.current.value = `${datePopupData.interval_multiplier}:00`;
      }, 350);
    }
  };
  const handleTimePicker = () => {
    setTime(bookingData.startTime);
    setTimePickerShow(!timepickerShow);
  };

  const handleDurationPicker = () => {
    setDuration(bookingData.duration);
    setDurationPicker(!durationPicker);
  };
  const handleHourPicker = () => {
    setHourData(bookingData.quantity);
    setHourPicker(!hourPicker);
  };

  const moveArr = (oldIndex, newIndex, sign) => {
    const servicesCopy = [...addedServices];
    const { duration, start, startTime } = addedServices[newIndex];

    let newNextStartTime;
    let currentNewStartTime;

    if (sign === 'plus') {
      const currentEntryStart = new Date(servicesCopy[oldIndex].start);
      const updatedNextEntryStart = moment(
        new Date(currentEntryStart.setMinutes(currentEntryStart.getMinutes() + parseFloat(duration))),
      ).format(dateFormat);

      newNextStartTime = moment(updatedNextEntryStart).format(timeFromDateFormat);

      currentNewStartTime = servicesCopy[oldIndex].startTime;
    } else if (sign === 'minus') {
      // console.log('minus');
      newNextStartTime = startTime;

      const updatedCurrentEntryStart = moment(
        new Date(new Date(start).setMinutes(new Date(start).getMinutes() + parseFloat(servicesCopy[oldIndex].duration))),
      ).format(dateFormat);

      currentNewStartTime = moment(updatedCurrentEntryStart).format(timeFromDateFormat);
    }

    const updatedOldIndex = {
      ...addedServices[oldIndex],
      startTime: newNextStartTime,
    };
    const updatedNewIndex = {
      ...addedServices[newIndex],
      startTime: currentNewStartTime,
    };

    servicesCopy[oldIndex] = { ...updatedOldIndex };
    servicesCopy[newIndex] = { ...updatedNewIndex };

    if (newIndex >= servicesCopy.length) {
      let k = newIndex - servicesCopy.length + 1;
      // eslint-disable-next-line no-plusplus
      while (k--) {
        servicesCopy.push(undefined);
      }
    }
    servicesCopy.splice(newIndex, 0, servicesCopy.splice(oldIndex, 1)[0]);
    // console.log('servicesCopy', servicesCopy);
    calculateDurations(servicesCopy);
    // setServices([...servicesCopy]);
  };
  const handleChangeCustom = (value) => {
    const obj = { ...bookingData, customCost: value };
    if (value === 'default') {
      obj.cost = bookingData.previousCost;
      if (bookingData.type === 'product') {
        obj.buyCostProduct = bookingData.prevBuyCostProduct;
      }
    } else if (value === 'yes' || value === 'sellPrice') {
      obj.cost = '';
      obj.buyCostProduct = obj.prevBuyCostProduct;
    } else if (value === 'buyCost') {
      obj.buyCostProduct = '';
      obj.cost = obj.previousCost;
    } else if (value === 'buySell') {
      obj.cost = '';
      obj.buyCostProduct = '';
    }
    setBookingData(obj);
  };
  const handleShowService = (index) => {
    // console.log('indexindex', index);
    setappointmentIndex(index);
    setStaffIndex(0);
    setnewlength(addedServices.length);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setShowService(!showService);
    setEditData({ edit: false, index: '' });
    const lastService = addedServices[addedServices.length - 1];
    if (lastService) {
      // console.log('lastService', lastService);
      const startTime = moment(lastService.end).format(timeFromDateFormat);
      setBookingData({ ...bookingData, startTime, ...otherBookingInitial });
    } else {
      setBookingData({ ...bookingData, ...otherBookingInitial });
    }
    setSearchTxt('');
    setSubcategories(null);
    setMisc(false);
  };

  const handleBookingData = (e) => {
    const { name, value } = e.target;
    const obj = { ...bookingData, [name]: value };
    if (name === 'customMisc') {
      obj.description = value;
    }
    if (name === 'staffName') {
      const staffId = staffList[e.target.selectedIndex]._id;
      obj[name] = value;
      obj.staffId = staffId;
      if (appointmentType === 'oneteam_oneday' || appointmentType === 'oneteam_multiday') {
        setStaff(value, staffId);
      }
    }
    if (name === 'cost' && !bookingData.customCost) {
      const newval = addZero(value);
      obj[name] = newval;
    } else if (name === 'startTime') {
      obj.timeChange = true;
    } else if (name === 'duration') {
      obj.durationChange = true;
    } else if (name === 'quantity') {
      obj.quantityChange = true;
    } else if (name === 'pricingType') {
      obj.duration = bookingData.previousDuration;
      obj.quantity = '5';
      if (bookingData.customCost !== 'default') {
        obj.cost = '';
      }
    } else if (name === 'soldPer' && value !== 'Add Custom Measure') {
      obj.unit = '';
      obj.symbol = '';
    }
    setBookingData(obj);
  };

  const addSubcategoryToService = (value) => {
    let kquantity = '1';
    const selectedCategory = categories.filter((el) => el.subCategory.filter((sub) => sub.name === value).length);
    const subcategoryData = selectedCategory[0].subCategory.filter((el) => el.name === value);

    const { type, category } = selectedCategory[0];
    const {
      duration, cost, soldPer, unit, symbol, serviceDescription, tax, s_uId,
      categoryId, supplier,
    } = subcategoryData[0];

    let customCostData = 'default';
    let newCost = addZero(subcategoryData[0][type === 'product' ? 'retailCost' : 'cost']);
    let newBuyCost = type === 'product' ? cost : '';

    if (selectedCategory[0].category.toLowerCase().includes('misc')) {
      setMisc(true);
      customCostData = type === 'product' ? 'buySell' : 'yes';
      newCost = '';
      newBuyCost = '';
    }
    let soldPerValue = soldPer || '';
    if (type === 'product') {
      if (soldPer === 'Add Custom Measure') {
        soldPerValue = `${unit}(${symbol})`;
      }
    }
    if (value.toLowerCase().includes('misc')) {
      if (type === 'product') {
        kquantity = '';
        bookingData.quantity = kquantity;
      }
      // setBookingData({ ...bookingData, quantity: '' });
    }
    setBookingData({
      ...bookingData,
      category,
      subcategory: value,
      cost: newCost,
      duration,
      type,
      quantity: kquantity,
      buyCostProduct: newBuyCost,
      customCost: customCostData,
      timeChange: false,
      customMisc: '',
      previousDuration: '',
      previousQuantity: '',
      pricingType: 'fixedCost',
      previousCost: newCost,
      prevBuyCostProduct: newBuyCost,
      soldPer: soldPerValue,
      description: serviceDescription || value,
      tax,
      s_uId,
      categoryId,
      supplier,
    });
    setSearchTxt(value);
    setSubcategories(null);
  };

  const getCategories = async () => {
    try {
      const response = await userInstance().get('/service/getServices');
      const { services } = response.data;
      setCategories(services);
      const staffData = await getStaffList();
      const objToUpdate = {
        ...bookingData,
      };
      if (staffData !== null && staffData !== undefined) {
        objToUpdate.staffName = staffData.staffName;
        objToUpdate.staffId = staffData.staffId;
      }
      setBookingData(objToUpdate);
    } catch (error) {
      //   console.log(error);
    }
  };
  const getAllTemplate = async (info) => {
    try {
      // console.log('getAllTemplate=>');
      const response = await userInstance().get('/template/getAllTemplate?type=quote');
      const { temp } = response.data;
      if ((saveQuoteType === 'editquote' || saveQuoteType === 'duplicate') && info !== 'reset') {
        const activeTemp = temp.filter((el) => el._id === showQuote.templateType);
        if (activeTemp.length > 0) {
          setSelectedTemplate(activeTemp[0].currentTemplateData);
          setCustomFeilds(activeTemp[0].customFeilds);
        } else {
          const defaultTemp = temp.filter((el, i) => el.default);
          setTemplateType(defaultTemp[0]._id);
          setSelectedTemplate(defaultTemp[0].currentTemplateData);
          setCustomFeilds(defaultTemp[0].customFeilds);
        }
      } else {
        const defaultTemp = temp.filter((el, i) => el.default);
        if (defaultTemp.length > 0) {
          setTemplateType(defaultTemp[0]._id);
          setSelectedTemplate(defaultTemp[0].currentTemplateData);
          setCustomFeilds(defaultTemp[0].customFeilds);
        } else {
          setTemplateType(temp[0]._id);
          setSelectedTemplate(temp[0].currentTemplateData);
          setCustomFeilds(temp[0].customFeilds);
        }
      }
      setTemplateArray(temp);
    } catch (error) {
      console.log(error);
    }
  };

  const getTimes = () => g();

  const g = () => {
    const x = {
      slotInterval: 5,
      openTime: '12:25 AM',
      closeTime: '23:30 PM',
    };

    // Format the time
    const startTime = moment(x.openTime, 'h:mm A');

    // Format the end time and the next day to it
    const endTime = moment(x.closeTime, 'h:mm A');

    // Times
    const allTimes = [];

    // Loop over the times - only pushes time with 30 minutes interval
    while (startTime <= endTime) {
      // Push times
      allTimes.push(startTime.format('h:mm A'));
      // Add interval of 30 minutes
      startTime.add(x.slotInterval, 'minutes');
    }
    return allTimes;
  };

  const renderOptions = () => {
    let val = 5;
    const arr = [];
    while (val <= 960) {
      const hours = Math.floor(val / 60);
      const minutes = val % 60;
      const msg = hours && minutes ? `${hours}hr ${minutes} min` : hours && !minutes ? `${hours}hr` : `${minutes} min`;
      arr.push({ value: val, data: msg });
      val += 5;
    }
    return arr;
  };

  const convertToReadableDuration = (data) => {
    const upData = Math.ceil(data / 5) * 5;
    const hours = Math.floor(upData / 60);
    const minutes = upData % 60;
    let msg = '';
    msg = hours && minutes
      ? `${hours}hr ${minutes} min`
      : hours && !minutes
        ? `${hours}hr`
        : `${minutes} min`;
    return msg;
  };

  const getStaffList = async () => {
    try {
      const response = await userInstance().get('/staff/getStaff');
      const { staff } = response.data;
      setStaffList(staff);
      if (saveQuoteType === 'editquote' || saveQuoteType === 'duplicate') {
        const selectedStaff = staff.filter((_el, i) => _el._id === showQuote.staff);
        console.log('edit quote here...');
        return {
          staffName: `${selectedStaff[0].firstname} ${selectedStaff[0].lastname}`,
          staffId: selectedStaff[0]._id,
        };
      }
      if (staff.length) {
        const selectedStaff = staff.filter((_el, i) => _el._id === loginStaff);
        return {
          staffName: `${selectedStaff[0].firstname} ${selectedStaff[0].lastname}`,
          staffId: selectedStaff[0]._id,
        };
      }
    } catch (error) {
      //   console.log(error);
      return null;
    }
  };

  const getSignatureListList = async (types) => {
    try {
      const response = await userInstance().get('/staff/getAllSignatureList');
      const { list, code } = response.data;
      if (code === 200) {
        setSignatureList(list);
        if (list.length > 0) {
          if ((saveQuoteType === 'editquote' || saveQuoteType === 'duplicate') && types !== 'reset') {
            const activeStaff = list.filter((el) => el._id === showQuote?.associateName);
            const obj = {
              name: `${activeStaff[0].name}` || '',
              type: `${activeStaff[0].title}` || '',
              signature: activeStaff[0].image || '',
              complimentary: `${activeStaff[0].complimentary}` || '',
              mobileNo: `${activeStaff[0].mobileNo}` || '',
              email: `${activeStaff[0].email}` || '',
            };
            setSignStaff(obj);
            setQuoteSign(activeStaff[0]._id);
          } else {
            const obj = {
              name: `${list[0].name}` || '',
              type: `${list[0].title}` || '',
              signature: list[0].image || '',
              complimentary: `${list[0].complimentary}` || '',
              mobileNo: `${list[0].mobileNo}` || '',
              email: `${list[0].email}` || '',
            };
            setSignStaff(obj);
            setQuoteSign(list[0]._id);
          }
        }
      }
    } catch (error) {
      //   console.log(error);
      return null;
    }
  };
  const pad = (str, max) => {
    str = str.toString();
    return str.length < max ? pad(`0${str}`, max) : str;
  };

  const convertHours = (mins) => {
    const hour = Math.floor(mins / 60);
    var mins = mins % 60;
    const converted = `${pad(hour, 2)}:${pad(mins, 2)}`;
    return converted;
  };

  const calculate_time_slot = (start_time, end_time, interval = '30') => {
    var i;
    let formatted_time;
    const time_slots = new Array();
    for (var i = start_time; i <= end_time; i += interval) {
      formatted_time = convertHours(i);
      time_slots.push(formatted_time);
    }
    return time_slots;
  };

  const getHourWithQuantity = (duration, multiplier) => {
    let hourPart = duration.includes('hr') ? parseFloat(duration.split('hr')[0].trim()) : 0;
    let minutePart = duration.includes('hr') && duration.includes('min')
      ? parseFloat(duration.split('hr')[1].split('min')[0])
      : duration.includes('min')
        ? parseFloat(duration.split('min')[0])
        : 0;

    let hourWithQuantity = hourPart * multiplier;
    // console.log('hourWithQuantity', hourWithQuantity);
    return { hourWithQuantity, hourPart, minutePart };
  };

  const calculateServiceLabour = (bookingDataCopy) => {
    const convertedDuration = bookingDataCopy.pricingType === 'hourly'
      ? bookingDataCopy.quantity
      : bookingDataCopy.duration;

    let hour = convertToReadableDuration(addQuantityDuration(convertedDuration, parseFloat('1')));
    let { hourWithQuantity, minutePart } = getHourWithQuantity(hour, bookingDataCopy.cost);

    minutePart = (minutePart / 60) * parseFloat(bookingDataCopy.cost);
    let finalDuration = parseFloat(hourWithQuantity + minutePart);

    // eslint-disable-next-line no-mixed-operators
    finalDuration = !Number.isInteger(finalDuration / 60) ? Math.ceil(finalDuration / 5) * 5 : finalDuration;

    return parseFloat(finalDuration).toFixed(2);
  };

  const addQuantityDuration = (duration, quantity) => {
    const updatedDuration = convertToReadableDuration(duration.toString());
    // console.log('updatedDuration', updatedDuration);
    let { hourWithQuantity, hourPart, minutePart } = getHourWithQuantity(updatedDuration, quantity);

    let multipleMinute = parseFloat(`0.${hourWithQuantity.toString().split('.')[1]}`);

    const updatedMinute = hourPart ? multipleMinute * (60 / hourPart) : 0;
    minutePart *= quantity;

    let finalDuration = parseFloat(hourWithQuantity.toString().split('.')[0]) * 60 + updatedMinute + minutePart;
    finalDuration = !Number.isInteger(finalDuration / 60) ? Math.ceil(finalDuration / 5) * 5 : finalDuration;

    return finalDuration.toString();
  };

  const calculateMarginLabour = (bookingDataCopy) => {
    // console.log('booking data copy', bookingDataCopy);
    // console.log('lenght', bookingData
    // .length);
    const {
      cost, buyCostProduct, quantity, type, renderCost,
    } = bookingDataCopy;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    let totalLabour = 0;
    // console.log('type', type);

    if (type === 'product') {
      totalBuy += parseFloat(removeComma(buyCostProduct));
      totalSellPrice += parseFloat(removeComma(renderCost));
      console.log('totalSellPrice', totalSellPrice, 'total buy', totalBuy);
      const totalLabourProduct = (totalSellPrice - (totalBuy * parseFloat(quantity))).toFixed(2);
      laborMargin = totalLabourProduct.toString();
    }
    if (type === 'service') {
      totalLabour = bookingDataCopy.pricingType === 'hourly' ? calculateServiceLabour(bookingDataCopy) : cost;
    }
    return { totalLabour, laborMargin };
  };

  const renderCostCalculation = (data) => {
    let quot = data / 5;
    console.log('quot', quot);
    let str = quot.toString();
    let splitData = str.split('.');
    console.log('splitData', splitData);
    let value;
    if (splitData.length > 1 && splitData[1] !== '' && splitData[1] !== '0') {
      value = parseFloat(splitData[0]) + 1;
    } else {
      value = quot;
    }
    let result = value * 5;
    return result;
  };

  const validateService = () => {
    let isValid = true;
    let msg = '';
    let errValue = '';
    const {
      category, subcategory, staffName, type, customMisc, quantity,
    } = bookingData;
    let { cost, buyCostProduct } = bookingData;

    cost = cost.replace(/,/g, '').replace('$', '');
    buyCostProduct = buyCostProduct.replace(/,/g, '').replace('$', '');
    // console.log('cost', cost, 'buy cost product', buyCostProduct);

    if (!category) {
      isValid = false;
      msg = 'Please select service or product';
      errValue = 'category';
    } else if (!subcategory) {
      isValid = false;
      msg = 'Please select subcategory';
      errValue = 'subcategory';
    } else if (!staffName) {
      isValid = false;
      msg = 'Please select staff';
      errValue = 'staffName';
    } else if (type === 'product' && parseFloat(cost) < parseFloat(buyCostProduct)) {
      isValid = false;
      msg = 'Sell price must be greater than buy cost';
      errValue = 'sell price';
    } else if (!cost) {
      isValid = false;
      msg = 'Enter Sell Price (ex tax)';
      errValue = 'sell price';
    } else if (!buyCostProduct && type === 'product') {
      isValid = false;
      msg = 'Enter Buy Price (ex tax)';
      errValue = 'buyCosrProduct';
    } else if (bookingData.category.toLowerCase().includes('misc') && !customMisc) {
      isValid = false;
      msg = 'Enter New Name';
      errValue = 'customMisc';
    } else if (!quantity) {
      isValid = false;
      msg = 'Enter quantity';
      errValue = 'quantity';
    }
    return { isValid, msg, errValue };
  };
  function groupBy(collection, property) {
    let i = 0; let val; let index;
    let values = [];
    let result = [];
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  }
  let addChemicalsWithService = [];
  const handleAddService = async () => {
    const {
      subcategory, type, duration, previousDuration, previousQuantity, pricingType, customMisc, description,
    } = bookingData;
    // console.log('bookingData in handleAddService', bookingData);
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;
    let oldStateCopy = [...newAppointment];
    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(buyCostProduct.replace(/,/g, '').replace('$', '')).toFixed(2);
    // console.log('parseFloat(cost)', parseFloat(cost), 'parseFloat(quantity)', parseFloat(quantity));
    renderCost = type === 'service' && pricingType === 'hourly'
      ? ((parseFloat(cost) * parseFloat(quantity)) / 60)
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    // const output = renderCostCalculation(15.00);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = ((renderCost / quantity).toFixed(2)).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }
    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      let startTime = setDefaultTime; // changes by sanjana '9:30';
      let start = moment(dateData.date).toDate();
      let time = bookingData.startTime.split(':');
      const bookingDataCopy = { ...bookingData };

      // console.log('bookingData', bookingData);

      const lastService = addedServices[addedServices.length - 1];
      // console.log('lastService', lastService);
      if (lastService) {
        start = moment(lastService.end).toDate();
        time = moment(lastService.end).format(timeFromDateFormat).split(':');
      }
      // console.log('time', time);
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);
      // console.log('start', start);

      bookingDataCopy.cost = cost;
      bookingDataCopy.quantity = quantity;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.description = customMisc || description;

      if (bookingDataCopy.buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }
      const { totalLabour, laborMargin } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;
      let durationInMin = duration;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
      }
      // console.log('gdydedeideoijd', durationInMin);
      // console.log('converted duration', convertToReadableDuration(durationInMin));
      if (!previousDuration) {
        // console.log('setting duration');
        bookingDataCopy.previousDuration = durationInMin;
      }
      // console.log('durationInMin', durationInMin);
      bookingDataCopy.duration = durationInMin;
      // if (parseFloat(durationInMin) > 960) {
      //   setExtras({ ...extras, value: durationInMin, data: convertToReadableDuration(durationInMin) });
      // }
      bookingDataCopy.start = moment(start).format(dateFormat);
      bookingDataCopy.end = moment(
        new Date(start.setMinutes(start.getMinutes() + parseFloat(bookingDataCopy.duration))),
      ).format(dateFormat);

      startTime = moment(bookingDataCopy.start).format(timeFromDateFormat);
      // console.log('startTime', startTime);

      bookingDataCopy.startTime = startTime;
      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00' ? addZero(renderCost) : addZero(renderCost),
      );

      bookingDataCopy.timeChange = false;
      if (!previousDuration) {
        // console.log('setting duration');
        bookingDataCopy.previousDuration = durationInMin;
      }
      if (!previousQuantity) {
        // console.log('setting quantity');
        bookingDataCopy.previousQuantity = quantity;
      }

      if (!editData.edit) {
        finalServices = [...addedServices, bookingDataCopy];
      }
      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? removeComma(next.renderCost) : 0), 0);
        const totalduration = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0), 0);
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      // let FillterServices = finalServices.sort((a, b) => ((a.staffName > b.staffName) ? 1 : -1));
      let oldServices = [...newAppointment[appointmentIndex].service];
      oldServices.push(bookingDataCopy);
      // let objnew = groupBy(oldServices, 'staffName');
      // objnew.forEach((el, i) => {
      //   const total = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? next.renderCost : 0), 0);
      //   const totalduration = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0), 0);
      //   objnew[i].totalAmount = total;
      //   objnew[i].totalduration = totalduration;
      // });
      // console.log('objoneee', objnew);
      oldStateCopy[appointmentIndex].service = oldServices;
      handleShowService();
      setServices([...finalServices]);
      // console.log('these are the final sercvices and copy', finalServices, copyService);
      addChemicalsWithService = [...finalServices, ...copyService];

      calculateSummary(addChemicalsWithService);
      // console.log('finalServices', finalServices);
      setMultiServices(obj);
      setNewAppointment(oldStateCopy);
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };

  const calculateGst = (info1, finalService) => {
    const { totalGst } = totalPriceWithGst(finalService);
    setGst(totalGst.toFixed(2));
  };
  const calculateQuotesTotal = (info2, finalService) => {
    const { totalGstamount, totalGst } = totalPriceWithGst(finalService);
    // console.log('totalAmmount===>', totalGstamount);
    setQuotesTotal(addZero(parseFloat(totalGstamount).toFixed(2)));
  };
  const calculateSummary = (finalServices) => {
    // console.log('finalServices=====>', finalServices);
    let totalDuration = 0;
    let totalCost = 0;
    let totalCostWithoutProduct = 0;
    let totalBuy = 0;
    let totalSellPrice = 0;
    let laborMargin = 0;
    finalServices.forEach((el) => {
      totalDuration += parseFloat(el.duration);
      totalCost += parseFloat(removeComma(el.renderCost));
      // totalCostWithoutProduct += parseFloat(el.cost);

      // totalBuy += parseFloat(el.buyCostProduct);
      // totalSellPrice += parseFloat(el.cost);
      laborMargin += parseFloat(el.laborMargin) + parseFloat(el.totalLabour);
    });
    const totalLabour = laborMargin;
    // console.log('laborMargin', laborMargin);
    calculateGst(addZero(parseFloat(totalCost).toFixed(2)), finalServices);
    calculateQuotesTotal(addZero(parseFloat(totalCost).toFixed(2)), finalServices);
    setSummary({
      ...summary,
      totalCost: addZero(parseFloat(totalCost).toFixed(2)),
      totalDuration,
      totalLabour: addZero(parseFloat(totalLabour).toFixed(2)),
      multiTotal: totalCost,
    });
  };
  const editService = () => {
    const {
      start,
      startTime,
      subcategory,
      type,
      previousDuration,
      previousQuantity,
      duration,
      pricingType,
    } = bookingData;
    let {
      cost, buyCostProduct, renderCost, quantity,
    } = bookingData;
    // console.log('booking data for edit', bookingData);
    // console.log('buyCostProduct', buyCostProduct.replace(/,/g, '').replace('$', ''));

    quantity = quantity.replace(/,/g, '');
    cost = parseFloat(cost.replace(/,/g, '').replace('$', '')).toFixed(2);
    buyCostProduct = parseFloat(buyCostProduct.replace(/,/g, '').replace('$', '')).toFixed(2);

    renderCost = type === 'service' && pricingType === 'hourly'
      ? ((parseFloat(cost) * parseFloat(quantity)) / 60)
      : (parseFloat(cost) * parseFloat(quantity)).toFixed(2);
    if (type === 'product') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
      cost = ((renderCost / quantity).toFixed(2)).toString();
    }
    if (type === 'service' || pricingType === 'hourly') {
      renderCost = (Math.ceil(renderCost / 5) * 5).toString();
    }

    const { isValid, msg, errValue } = validateService();
    if (isValid) {
      let finalServices = [];
      const bookingDataCopy = { ...bookingData };

      const startDate = moment(start).toDate();
      const time = startTime.split(':');

      bookingDataCopy.subcategory = subcategory.replace(
        subcategory.split('$')[1],
        renderCost.split('.')[1] === '00' ? addZero(renderCost) : addZero(renderCost),
      );
      bookingDataCopy.cost = cost;
      bookingDataCopy.renderCost = renderCost;
      bookingDataCopy.quantity = quantity;

      if (buyCostProduct) {
        bookingDataCopy.buyCostProduct = buyCostProduct;
      }

      startDate.setHours(time[0]);
      startDate.setMinutes(time[1]);
      startDate.setSeconds(0);

      bookingDataCopy.start = moment(startDate).format(dateFormat);

      const { totalLabour, laborMargin } = calculateMarginLabour(bookingDataCopy);
      bookingDataCopy.laborMargin = laborMargin;
      bookingDataCopy.totalLabour = totalLabour;

      let durationInMin = 0;
      if (type === 'service') {
        durationInMin = parseFloat(quantity) > 1 || pricingType === 'hourly'
          ? quantity
          : duration;
        bookingDataCopy.duration = durationInMin;
      } else if (previousQuantity !== quantity || previousDuration !== duration) {
        // console.log('re calculating the duration');
        // durationInMin = parseFloat(quantity) > 1 ? addQuantityDuration(duration, parseFloat(quantity)) : duration;
        // bookingDataCopy.duration = durationInMin;
        bookingDataCopy.previousDuration = durationInMin;
        bookingDataCopy.previousQuantity = bookingDataCopy.quantity;
      }

      // if (parseFloat(durationInMin) > 960) {
      //   setExtras({ ...extras, value: durationInMin, data: convertToReadableDuration(durationInMin) });
      // }

      bookingDataCopy.end = moment(
        new Date(startDate.setMinutes(startDate.getMinutes() + parseFloat(bookingDataCopy.duration))),
      ).format(dateFormat);

      const currentServices = [...addedServices];
      currentServices[editData.index] = bookingDataCopy;
      finalServices = currentServices;
      let obj = groupBy(finalServices, 'staffName');
      obj.forEach((el, i) => {
        const total = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.renderCost ? removeComma(next.renderCost) : 0), 0);
        const totalduration = el.reduce((pre, next) => parseFloat(pre || 0) + parseFloat(next.duration ? next.duration : 0), 0);
        obj[i].totalAmount = total;
        obj[i].totalduration = totalduration;
      });
      // console.log('calling recalculate');
      calculateNewDurations(bookingDataCopy);
      bookingDataCopy.timeChange = false;

      setShowService(!showService);
      setServices([...finalServices]);
      calculateSummary([...finalServices]);
      setMultiServices(obj);
      // console.log('updated booking data', bookingDataCopy);
    } else {
      setErrorValue(errValue);
      toastMessage('error', msg);
    }
    setTimeout(() => {
      setErrorValue('');
    }, errorBorderTime);
  };
  const calculateDurations = (services) => {
    services.forEach((el) => {
      let start = moment(el.date).toDate();
      const time = el.startTime.split(':');
      start.setHours(time[0]);
      start.setMinutes(time[1]);
      start.setSeconds(0);

      el.start = moment(start).format(dateFormat);
      el.end = moment(new Date(start.setMinutes(start.getMinutes() + parseFloat(el.duration)))).format(dateFormat);
    });
    // console.log('services', services);
    setServices([...services]);
  };
  const handleDeleteService = () => {
    const currentServices = [...addedServices];
    currentServices.splice(editData.index, 1);
    if (editData.index === addedServices.length - 1) {
      console.log('last service/product got deleted');
      setBookingData({
        ...bookingData,
        startTime: addedServices[editData.index].startTime,
      });
      setServices([...currentServices]);
    } else {
      recalculateServices(addedServices[editData.index]);
    }
    calculateSummary([...currentServices]);
    setShowService(!showService);
  };
  const handleEditService = ({ service, index }) => {
    setStaffIndex(index);
    if (index === 0) {
      setnewlength(0);
    } else {
      setnewlength(addedServices.length);
    }
    console.log('editing');
    // console.log('service', service);
    const updateObj = { ...bookingData, ...service };
    setBookingData(updateObj);
    setShowService(!showService);
    setEditData({ edit: true, index });
    if (parseFloat(service.duration) > 960) {
      setExtras({
        ...extras,
        value: service.duration,
        data: convertToReadableDuration(service.duration),
      });
    }
    const subcategory = service.subcategory.replace(
      service.subcategory.split('$')[1],
      service.renderCost.split('.')[1] === '00' ? addZero(service.renderCost) : addZero(service.cost),
    );
    setSearchTxt(subcategory);
  };

  const handleSearchCategory = async (searchTxt) => {
    try {
      setSearchTxt(searchTxt);
      if (searchTxt) {
        const response = await userInstance().get(`/service/searchCategory?value=${searchTxt.replace('$', '').trim()}&industryId=${industryId._id}`);
        const { services } = response.data;
        setCategories(services);
        if (services.length) {
          const subCat = [];
          services.forEach((service) => {
            const reg = new RegExp(`${searchTxt.replace('$', '').trim()}`, 'i');
            const matchedSubcategories = service.subCategory.filter(
              (el) => reg.test(el.cost) || reg.test(el.name) || reg.test(el.retailCost) || reg.test(el.serviceDescription),
            );
            subCat.push(...matchedSubcategories);
          });
          if (subCat.length) {
            setSubcategories([...subCat]);
          } else {
            setSubcategories([]);
          }
        } else {
          setSubcategories([]);
        }
      }
    } catch (error) {
      console.log(error);
      //   console.log(error);
    }
  };
  const getFinalPdfUrl = () => {
    let {
      firstname, lastname, streetAddress, suburb, state, siteAddresses, mobileNo, note,
    } = addClient[0] ? addClient[0] : {};

    let {
      date, createdOn, endsAt, requiredday, appointmenttype,
    } = dateData || {};

    let { multiTotal } = summary || {};

    const multiTotalCost = requiredday ? multiTotal * parseInt(requiredday, 10) : multiTotal;
    // chemicalData
    const newGst = parseFloat(removeComma(gst)) + chemicalgst;
    const totalGst = currencyComma(newGst.toFixed(2));
    const newSubtotal = (parseFloat(multiTotalCost || 0) + parseFloat(removeComma(waterServiceSummary?.newTotalCost))).toFixed(2);
    const subtotalcost = currencyComma(newSubtotal);
    const totals = parseFloat(newSubtotal) + parseFloat(newGst);
    const newquotesTotal = currencyComma(totals.toFixed(2));

    const selectedSiteAddres = siteAddresses && siteAddresses?.filter((el) => el.activeSiteAdd);
    const tempSiteAddress = selectedSiteAddres ? selectedSiteAddres[0]?.address : '';
    let copyTemplateData = [...selectedTemplate];
    // copyTemplateData[1].text = copyTemplateData[1].text || companyLocations;
    copyTemplateData[1].text = copyTemplateData[1].text || '';
    copyTemplateData[2].text = mobile || copyTemplateData[2].text;
    copyTemplateData[4].text = email || copyTemplateData[3].text;
    copyTemplateData[6].text = companyName || '';
    copyTemplateData[5].text = copyTemplateData[5].text ? `${firstname} ${lastname || ''}` : '';
    copyTemplateData[5].address = `${streetAddress}`;
    copyTemplateData[5].subaddress = `${suburb || state ? '' : ''} ${suburb || ''} ${state || ''} ${postcode}`;
    copyTemplateData[7].text = moment(quoteDate).format('Do MMM YYYY');
    copyTemplateData[8].text = `Dear ${firstname || ''}` || '';
    copyTemplateData[9].text = copyTemplateData[9].text ? quoteOpeningText : '';
    copyTemplateData[21].text = quoteClosingText;
    copyTemplateData[11].text = ` ${quoteNumber || 'N/A'}`;
    copyTemplateData[12].siteAddresses = tempSiteAddress || '';
    copyTemplateData[16].text = `$${subtotalcost}` || '$00.00';
    copyTemplateData[18].text = `$${totalGst}` || '$00.00';
    copyTemplateData[20].text = `$${newquotesTotal}` || '$00.00';
    copyTemplateData[22].text = `${signStaff.complimentary}` || '';
    copyTemplateData[23].text = '';
    copyTemplateData[23].url = `${signStaff.signature.url}` || '';
    copyTemplateData[23].preview = `${signStaff.signature.url}` || '';
    copyTemplateData[24].text = `${signStaff.name || ''}`;
    copyTemplateData[25].text = `${signStaff.type || ''}`;
    copyTemplateData[26].text = `${signStaff.mobileNo || ''}`;
    copyTemplateData[27].text = `${signStaff.email || ''}`;
    const payload = {
      templateData: copyTemplateData,
      customFeilds,
      templateDataOne: {
        services: addedServices,
        chemicalData,
      },
      type: 'quote',
    };
    return payload;
  };

  // console.log('this is the water test summary', waterServiceSummary);
  // console.log('this is the addedServices', addedServices);

  const handleAddQuotes = async (sendby) => {
    try {
      let isValid = true;
      let val = '';
      if (!addClient.length) {
        isValid = false;
        val = 'clients';
      } else if (!addedServices.length) {
        isValid = false;
        val = 'services';
      }
      if (isValid) {
        setIsloader(true);
        let data_array = [];
        let {
          date, createdOn, endsAt, requiredday, appointmenttype,
        } = dateData || {};
        // eslint-disable-next-line no-undef
        const startDate = moment(date).format('YYYY-MM-DD');
        // console.log('this is the summary', summary);
        let { multiTotal, totalDuration, totalLabour } = summary || {};

        let totalSummary = {
          totalCost: addZero((requiredday ? parseFloat(multiTotal) * parseInt(requiredday, 10) : parseFloat(multiTotal)).toFixed(2)),
          totalDuration: requiredday ? totalDuration * parseInt(requiredday.client, 10) : totalDuration,
          totalLabour,
          multiTotal: requiredday ? multiTotal * parseInt(requiredday, 10) : multiTotal,
        };
        // console.log('this is the total summary', totalSummary);
        for (let step = 0; step < newAppointment.length; step++) {
          let dateDataCopy = {
            ...dateData,
            date: new Date(startDate).toISOString(),
            createdOn: moment(createdOn).format(dateFormat),
            endsAt: new Date(startDate).toISOString(),
            teamnotes: multiNotes[step]?.notes,
            occurenceMsg: dateData?.endType === 'ongoing' ? 'Ongoing' : dateData?.occurenceMsg,
            service: addedServices || [],
            summary: totalSummary,
          };
          data_array.push(dateDataCopy);
        }
        let {
          firstname, lastname, streetAddress, suburb, state, _id, siteAddresses, mobileNo, note,
        } = addClient[0] ? addClient[0] : {};
        const waterTestId = watTesId;
        let testResultObj = {
          value: 'First Step',
          service: chemicalData,
          numericValue: '1st',
          sel: '',
          _id: '',
          summary: {
            totalCost: waterServiceSummary.newTotalCost,
            totalDuration: waterServiceSummary.newTotalDuration,
            totalLabourAndMargin: waterServiceSummary.newTotalLabourMargin,
            totalLabour: '',
            totalMargin: '',
            multiTotal: '',
          },
          days: 'today',
          isChem: true,
          serviceAnalysis: 'Water Test',
        };
        let testResultArr = [testResultObj];
        let chemicalsObject = {
          totalCost: waterServiceSummary.newTotalCost,
          totalDuration: waterServiceSummary.newTotalDuration,
          labourMargin: waterServiceSummary.newTotalLabourMargin,
          testResult: testResultArr,
        };
        let chemicalsArray = chemicalData && chemicalData?.length > 0 ? [chemicalsObject] : [];

        const newquotesTotal = parseFloat(totalSummary?.multiTotal || 0) + parseFloat(removeComma(waterServiceSummary?.newTotalCost));
        const newGst = (parseFloat(removeComma(gst)) + chemicalgst).toFixed(2);
        const payload = {
          services: addedServices,
          client: _id,
          previewUrl,
          notes,
          chemicalsArray,
          dateData: data_array,
          totalData: totalSummary,
          appointmenttype,
          requiredday: requiredday || '',
          gst: newGst,
          quoteDate: new Date(startDate).toISOString(),
          quotesTotal: newquotesTotal.toFixed(2),
          quoteSign,
          templateType,
          industryId: industryId?._id,
          sendby,
          activeAdd,
          isOpenText,
          iscloseText,
          quoteOpeningText,
          quoteClosingText,
          waterTestId,
          chemicalData,
          type: savetype,
        };

        if (saveQuoteType === 'editquote') {
          const finalyPayload = { ...payload, quoteId: showQuote?._id };

          const response = await userInstance().post('/quotes/editQuotes', finalyPayload);
          toastMessage('success', response.data.msg);
        } else {
          const templateDataPdf = getFinalPdfUrl();
          const finalyPayload = { ...payload, templateDataPdf };

          const response = await userInstance().post('/quotes/addQuotes', finalyPayload);
          const { savedQuotes } = response.data;
          if (savetype === 'save') {
            const {
              _id: Id, staff, quoteDate: dates, waterTestId: watertestId, chemicalsArray: chemicalsArrayData,
            } = savedQuotes || {};

            const copyClientData = savedQuotes.client;
            const copyAddClient = { ...clientAllData };
            copyAddClient.clientAllDetails = copyClientData;
            setClientAllData(copyAddClient);
            localStorage.setItem('apptData', JSON.stringify(copyAddClient));
            let url = `/addappointment?startDateTime=${dates}&employee_id=${staff}&quoteId=${Id}`;
            if (watertestId) {
              localStorage.setItem('chemicalalist', JSON.stringify(chemicalsArray));
              url = `/addappointment?startDateTime=${dates}&employee_id=${staff}&quoteId=${Id}&waterTest=done&waterId=${watertestId}`;
            } else {
              localStorage.removeItem('chemicalalist');
            }
            history.push(url);

            return;
          }
          toastMessage('success', response.data.msg);
        }
        setIsloader(false);
        setSelectedClient('');
        setQuoteType('Pending Quotes');
        setServices([]);
        setBookingData(initialBookingData);
        setNotes('');
        setAddClient([]);
        setSummary({ totalCost: 0, totalDuration: 0 });
        setDateData(initialDateData);
        setMultiNotes(multiNotesState);
        setmultiAppointment(false);
        setMultiServices([]);
        setquoteUrl('');
        history.push('/quotes');
      } else {
        toastMessage('error', `Please add ${val}.`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const calculateNewDurations = (data) => {
    // console.log('data in calculateNewDurations', data);
    // const selectedEntryIndex = addedServices.findIndex((x, i) => i === editData.index);
    const remainingServices = addedServices.filter((_el, i) => i > editData.index);
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.end);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(new Date(end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)))).format(
        dateFormat,
      );
    }
    setServices([addedServices[0], ...remainingServices]);
  };

  const recalculateServices = (data) => {
    const remainingServices = addedServices.filter((_el, i) => i > editData.index);
    const previousServices = addedServices.filter((_el, i) => i < editData.index);
    // console.log('remainingServices', remainingServices);
    for (let i = 0; i < remainingServices.length; i++) {
      const currentEl = remainingServices[i];
      let end = '';
      if (i === 0) {
        end = new Date(data.start);
      } else {
        end = new Date(remainingServices[i - 1].end);
      }
      const startTime = moment(end).format(timeFromDateFormat);
      currentEl.startTime = startTime;
      currentEl.start = moment(end).format(dateFormat);
      currentEl.end = moment(new Date(end.setMinutes(end.getMinutes() + parseFloat(currentEl.duration)))).format(
        dateFormat,
      );
    }
    const arr = previousServices;
    const newArray = arr.concat(remainingServices);
    setServices([...newArray]);
  };
  const setEditQuoteData = () => {
    let copyAddClient = [...addClient];
    const objToUpdate = {
      ...bookingData,
    };
    if (showQuote?.staff) {
      objToUpdate.staffName = showQuote?.services[0].staffName;
      objToUpdate.staffId = showQuote?.staff;
    }
    setBookingData(objToUpdate);
    setAddClient([showQuote.client]);
    setTemplateType(showQuote.templateType);
    setQuoteDate(new Date(showQuote.quoteDate));
    setQuoteSign(showQuote.associateName);
    setDateData(showQuote.dateData[0]);
    setSummary(showQuote.totalData);
    setServices(showQuote.services);
    setGst(addZero(parseFloat(removeComma(showQuote.gst)).toFixed(2)));
    const totals = removeComma(showQuote?.quotesTotal);
    setQuotesTotal(addZero(parseFloat(totals).toFixed(2)));
    setQuoteNumber(showQuote.quoteNo);
    setActiveAdd(showQuote?.clientSiteAddressIndex || 0);
  };
  useEffect(() => {
    getCategories();
  }, [dateData]);
  useEffect(() => {
    const showservice = queryParams.get('showservice');
    if (showservice) {
      if (quoteDataFromAppointment?.length > 0) {
        const newArray = quoteDataFromAppointment[0].service.map((el) => {
          if (el.subcategory) {
            el.description = el.subcategory;
          }
          return el;
        });

        setChemicalData(quoteDataFromAppointment[0].service);
        // setServices([...newArray]);
        calculateSummary([...newArray]);
        setCopyService([...newArray]);
        // setTemplateData([...newArray]);
      }
    }
    if (saveQuoteType === 'editquote' || saveQuoteType === 'duplicate') {
      // console.log('editquote ====>');
      setEditQuoteData();
    }
    getAllTemplate();
    return () => {
      setServices([]);
    };
  }, [saveQuoteType]);
  const handlePreview = async () => {
    try {
      let isValid = true;
      let val = '';
      if (!addClient.length) {
        isValid = false;
        val = 'client.';
      } else if (!addedServices.length) {
        isValid = false;
        val = 'service.';
      } else if (quoteSign === '') {
        isValid = false;
        val = 'Quote Sign-off.';
      }
      if (isValid) {
        let {
          firstname, lastname, streetAddress, suburb, state, siteAddresses, mobileNo, note,
        } = addClient[0] ? addClient[0] : {};

        const selectedSiteAddres = siteAddresses && siteAddresses?.filter((el) => el.activeSiteAdd);
        const tempSiteAddress = selectedSiteAddres ? selectedSiteAddres[0]?.address : '';

        const tt = `${firstname} ${lastname || ''}` || '';
        const tt2 = `${streetAddress}`;
        const tt3 = `${suburb || state ? '' : ''} ${suburb || ''} ${state || ''} ${postcode}`;

        let {
          date, createdOn, endsAt, requiredday, appointmenttype,
        } = dateData || {};

        let { multiTotal } = summary || {};

        // console.log('summary =>', summary);

        const multiTotalCost = requiredday ? multiTotal * parseInt(requiredday, 10) : multiTotal;
        const newGst = parseFloat(removeComma(gst)) + chemicalgst;
        const totalGst = currencyComma(newGst.toFixed(2));
        const newSubtotal = (parseFloat(multiTotalCost || 0) + parseFloat(removeComma(waterServiceSummary?.newTotalCost))).toFixed(2);
        const subtotalcost = currencyComma(newSubtotal);
        const totals = parseFloat(newSubtotal) + parseFloat(newGst);
        const newquotesTotal = currencyComma(totals.toFixed(2));

        // console.log('summary222 =>', {
        //   multiTotalCost, totalGst, subtotalcost, totals, newquotesTotal,
        // });
        let copyTemplateData = [...selectedTemplate];
        // copyTemplateData[1].text = copyTemplateData[1].text || companyLocations;
        copyTemplateData[1].text = copyTemplateData[1].text || '';
        copyTemplateData[2].text = mobile || copyTemplateData[2].text;
        copyTemplateData[4].text = email || copyTemplateData[3].text;
        copyTemplateData[6].text = companyName || '';
        copyTemplateData[5].text = copyTemplateData[5].text ? `${firstname} ${lastname || ''}` : '';
        copyTemplateData[5].address = `${streetAddress}`;
        copyTemplateData[5].subaddress = `${suburb || state ? '' : ''} ${suburb || ''} ${state || ''} ${postcode}`;
        copyTemplateData[7].text = moment(quoteDate).format('Do MMM YYYY');
        copyTemplateData[8].text = `Dear ${firstname || ''}` || '';
        copyTemplateData[9].text = copyTemplateData[9].text ? quoteOpeningText : '';
        copyTemplateData[21].text = quoteClosingText;
        copyTemplateData[11].text = ` ${quoteNumber || 'N/A'}`;
        copyTemplateData[12].siteAddresses = tempSiteAddress || '';
        copyTemplateData[16].text = `$${subtotalcost}` || '$00.00';
        copyTemplateData[18].text = `$${totalGst}` || '$00.00';
        copyTemplateData[20].text = `$${newquotesTotal}` || '$00.00';
        copyTemplateData[22].text = `${signStaff.complimentary}` || '';
        copyTemplateData[23].text = '';
        copyTemplateData[23].url = `${signStaff.signature.url}` || '';
        copyTemplateData[23].preview = `${signStaff.signature.url}` || '';
        copyTemplateData[24].text = `${signStaff.name || ''}`;
        copyTemplateData[25].text = `${signStaff.type || ''}`;
        copyTemplateData[26].text = `${signStaff.mobileNo || ''}`;
        copyTemplateData[27].text = `${signStaff.email || ''}`;
        setTemplateData(addedServices);
        setPreviewPopup(!previewPopup);
        setSuccess(false);
        setLoading(true);
        const payload = {
          templateData: copyTemplateData,
          customFeilds,
          templateDataOne: {
            services: addedServices,
            chemicalData,
          },
          type: 'quote',
        };
        setPreview(!preview);
        // console.log('addedServices =====>', addedServices);
        const response = await notTimeOutUserInstance().post('/template/previewTemplatePdf', payload);
        const { code, msg, tempId } = response.data;
        setPreviewUrl(tempId);
        setLoading(false);
      } else {
        toastMessage('error', `Please add ${val}.`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignature = (e) => {
    setQuoteSign(e.target.value);
    const activeStaff = signatureList.filter((el) => el._id === e.target.value);
    const obj = {
      name: `${activeStaff[0].name}`,
      type: `${activeStaff[0].title}`,
      signature: activeStaff[0].image,
      complimentary: `${activeStaff[0].complimentary}`,
      mobileNo: `${activeStaff[0].mobileNo}` || '',
      email: `${activeStaff[0].email}` || '',
    };
    setSignStaff(obj);
  };
  const handleTemplate = (index) => {
    const { _id } = templateArray[index] || {};
    setTemplateType(_id);

    setSelectedTemplate(templateArray[index].currentTemplateData);
    setCustomFeilds(templateArray[index].customFeilds);
  };
  useEffect(() => {
    getSignatureListList();
  }, []);

  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate[9]?.text) {
        setQuoteOpeningText(selectedTemplate[9]?.text);
      }

      if (selectedTemplate[21]?.text) {
        setQuoteClosingText(selectedTemplate[21]?.text);
      }
    }
  }, [selectedTemplate]);

  const handleSMS = (type) => {
    if (type === 'save') {
      handleAddQuotes('saveaspending');
    } else {
      setSMSOption(!smsOption);
    }
  };
  const handleReset = () => {
    setPreviewPopup('');
    setsaveQuoteType('');
    // console.log('saveQuoteType====>', saveQuoteType);
    if (saveQuoteType === 'editquote') {
      handlePreviewEdit(showQuote);
      return;
    }
    setsaveQuoteType('');
    setQuoteNumber('');
    getAllTemplate('reset');
    setTemplateType('');
    setQuoteSign('');
    setMultiNotes(multiNotesState);
    setShowService(false);
    setBookingData(initialBookingData);
    setDateData(initialDateData);
    setQuoteDate(new Date());
    setNewAppointment([initialDateData]);
    setServices([]);
    setAddClient([]);
    getSignatureListList('reset');
    getCategories();
    setSummary({
      totalCost: 0,
      totalDuration: '',
      totalLabourMargin: 0,
    });
    setWaterServiceSummary(waterTestInitial);
    setChemicalData([]);
    setMutiSummary({
      totalCost: 0,
      totalDuration: '',
      totalLabourMargin: 0,
      totalLabourAmount: 0,
      totalLabour: 0,
    });
    localStorage.removeItem('chemicalalist');
    history.push('/quotes');
  };
  const handleOpenCloseText = (val, type) => {

  };
  const handleTextPopup = () => {
    setTextPopup(false);
  };
  const handleOpentTextPopup = (val) => {
    if (val === 'Closing') {
      setText(selectedTemplate[21]);
    } else {
      setText(selectedTemplate[9]);
    }
    const plcText = `Quote ${val} Text`;
    setPlaceholderText(plcText);
    setTextPopup(true);
    setQuoteText(val);
  };
  const saveUpdateText = (txt, type) => {
    setQuoteOption(true);
    if (type === 'Closing') {
      setQuoteClosingText(txt);
    } else {
      setQuoteOpeningText(txt);
    }
  };
  const handleQuotesText = (temptype, qtype) => {
    const isCheck = temptype === 'quoteTemp';
    if (qtype === 'Opening') {
      setIsOpenText(isCheck);
    }
    if (qtype === 'Closing') {
      setIsCloseText(isCheck);
    }
    setTextPopup(false);
    setQuoteOption(false);
  };

  const removeWaterConfirm = async (index) => {
    setWaterTestPopup(true);
    setWaterServiceSummary(waterTestInitial);
  };

  const removeWaterTest = async () => {
    setWaterTestPopup(false);
    setChemicalData([]);
    calculateSummary([...addedServices]);
    setChemicalGst(0);
    localStorage.removeItem('chemicalalist');
    history.push('/quotes');
  };
  const handleWaterUpdate = async (index) => {
    // if (saveQuoteType === 'editquote') {
    //   return;
    // }

    const activequoteId = showQuote?._id;
    const waterTestId = queryParams.get('quoteWatTesId');
    try {
      if (waterTest) {
        const response = await userInstance().get(
          `/watertest/getWaterHistoryByWaterId?id=${watTesId}`,
        );

        const { waterList, msg } = response.data;

        const copyClientAllData = { ...clientAllData };

        let obj = {
          chemicalsArray: waterList?.chemicals,
        };

        copyClientAllData.appointmentData = obj;
        copyClientAllData.waterTestHistory = waterList?.history;
        localStorage.setItem('apptData', JSON.stringify(copyClientAllData));
        const { chemicalsArray, _id } = showQuote || {};
        if (from === 'calculator' && _id) {
          localStorage.setItem('chemicalalist', JSON.stringify(chemicalsArray));
        }

        // console.log('copyClientAllData second time', copyClientAllData);
        if (waterList?.history?.serviceAnalysis === 'Water Test') {
          if (activequoteId) {
            history.push(`/calculator?startDateTime=${startDateTime}&employee_id=${employee_id}&editChemicalList=true&watTesId=${waterList?._id}&back=quote&quoteId=${activequoteId}`);
          } else {
            history.push(`/calculator?startDateTime=${startDateTime}&employee_id=${employee_id}&editChemicalList=true&watTesId=${waterList?._id}&back=quote`);
          }
        } else if (waterList?.chemicals?.length > 0) {
          if (activequoteId) {
            history.push(`/calculator?startDateTime=${startDateTime}&employee_id=${employee_id}&visitMessage=true&watId=${waterList?._id}&chemIndex=${index}&quoteId=${activequoteId}`);
          } else {
            history.push(`/calculator?startDateTime=${startDateTime}&employee_id=${employee_id}&visitMessage=true&watId=${waterList?._id}&chemIndex=${index}`);
          }
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const convertToReadableDurationHelper = (duration1, duration2) => {
    let calculateDuration = 0;
    if (duration1) {
      calculateDuration += parseFloat(duration1);
    }
    if (duration2) {
      calculateDuration += parseFloat(duration2);
    }
    return convertToReadableDuration(calculateDuration);
  };

  const calculateTotalLabour = (productMargin, serviceLabour) => {
    let totalLabour = 0;
    if (productMargin) {
      totalLabour += parseFloat(productMargin);
    }
    if (serviceLabour) {
      totalLabour += parseFloat(serviceLabour);
    }

    if (totalLabour) {
      return `$${totalLabour}`;
    }
    return `$${0}`;
  };

  const getTotalCost = (chemicalsCost, serviceCost) => {
    let totalCost = currencyComma(parseFloat(removeComma(
      chemicalsCost,
    )) + parseFloat(removeComma(serviceCost)));
    return `${totalCost}`;
  };

  // console.log('these are the added services', addedServices);
  // console.log('these are the chemicals data', chemicalData);
  // console.log('this is the summary', summary);
  // console.log('newAppointment =>', newAppointment);

  return (
    <>
      <Form.Group controlId="formBasicHiddenEmployees">
        <Form.Label>Template to Use</Form.Label>
        <div className="select-box">
          {/* <i className="fas fa-sort-down" /> */}
          <Form.Control
            as="select"
            name="templatetype"
            // value={templateType}
            onChange={(e) => handleTemplate(e.target.value)}
          >
            {templateArray && templateArray.map((temp, index) => (
              <option value={index} selected={temp._id === templateType}>{temp.templateName}</option>
            ))}
          </Form.Control>
        </div>
      </Form.Group>
      <span className="sep" />
      <div className="appointment-date" role="presentation" onClick={() => handleDatePopup('')}>
        <div className="">
          <h3>
            Date:
            {' '}
            {`${moment(quoteDate).format(
              'D MMMM YYYY',
            )}`}

          </h3>
        </div>
        <div className="sorting-sub-services appointment-sort">
          <div
            className="content-dropdown"
          >
            <img src={arrow} alt="arrow" />
          </div>
        </div>
      </div>
      <div className="appointment-date pending-quote" role="presentation">
        <div className="">
          {quoteNumber ? (
            <h3>
              Quote #
              {' '}
              {quoteNumber}
            </h3>
          ) : (
            <h3>
              Quote # &lt;Pending&gt;
            </h3>
          )}
        </div>
      </div>
      <div className="quotes-client-details">
        {addClient.length > 0 ? (
          <>
            { addClient && addClient.map((cl) => (
              <>
                <p>
                  Deara
                  {' '}
                  {`${cl.firstname}`}
                </p>
              </>
            ))}
          </>
        ) : (
          <p>Dear  &lt;fname&gt;,</p>

        )}
        <div className="client-quotes-notes" onClick={() => handleOpentTextPopup('Opening')}>
          <span>
            {quoteOpeningText || ''}
          </span>
        </div>
      </div>
      <span className="sep" />
      <div className="quotes-services">
        <div className="search-title service-head">
          <h3> Services or Products</h3>
          <div className="sorting-services">
            {addedServices.length ? (
              <div className="sorting">
                <img src={sort} alt="sorting" onClick={() => setRenderSort(!renderSort)} />
              </div>
            ) : null}
            <span
              className="plus-icon"
              onClick={() => handleShowService(0)}
              role="presentation"
            >
              <i className="fas fa-plus" />
            </span>
          </div>
        </div>

        {addedServices && addedServices.map((ser, i) => (
          <div className="search-title">
            <div
              className="client-profile"
              onClick={() => handleEditService({ service: ser, index: i })}
            >
              <h3>
                {`${ser.category.toLowerCase().includes('misc')
                  ? `${ser.customMisc} $${addZero(ser.renderCost)}`
                  : ser.subcategory
                } ${parseFloat(ser.quantity) > 1
                  ? `($${`${addZero(ser.cost)}${ser.soldPer === 'Kilogram (kg)' ? ' per kg' : ''}`} x ${`${ser.type === 'service' && ser.pricingType === 'hourly'
                    ? convertToReadableDuration(ser.quantity) : `${ser.soldPer === 'Kilogram (kg)' ? `${ser.quantity}kg` : ser.quantity}`}${ser.type === 'service' ? ' ' : ''
                  })`}`
                  : ''
                }`}
              </h3>
              <p>
                <span>{moment(ser.startTime, [timeFromDateFormat]).format('h:mm a')}</span>
                <span> | </span>
                <span>{` ${convertToReadableDuration(ser.duration)}`}</span>
                <span> | </span>
                <span>{` with ${ser.staffName}`}</span>
                <span> | </span>
              </p>
              <p>
                <span>
                  {`${ser.type === 'product'
                    ? `Margin $${addZero(ser.laborMargin)}`
                    : ser.type === 'service'
                      ? `Labour $${`${addZero(ser.totalLabour)}`}`
                      : null
                  }`}
                </span>
              </p>
            </div>
            <div className="sorting-sub-services appointment-sort">
              <div className="service-edit-icon">
                {renderSort ? (
                  <div className={`sorting up-down ${renderSort && 'active-sort'}`}>
                    {i !== 0 ? (
                      <img src={upsort} alt="sorting" onClick={() => moveArr(i, i - 1, 'minus')} />
                    ) : null}
                    {i !== addedServices.length - 1 ? (
                      <img src={downsort} alt="sorting" onClick={() => moveArr(i, i + 1, 'plus')} />
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div
                className="content-dropdown"
                onClick={() => handleEditService({ service: ser, index: i })}
              >
                <Dropdown name="startingDate">
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    <img src={arrow} alt="arrow" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>Option 1</Dropdown.Item>
                    <Dropdown.Item>Option 2</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        ))}
      </div>
      <span className="sep" />
      {newAppointment && newAppointment.map((el4, index4) => (
        <>
          {el4?.chemicalTestArray
    && el4?.chemicalTestArray.length > 0 && (
    <>
      <span className="sep" />
      <div className="finalize-cost-page view-appointment-details">
        <div className="added-chemicals-info">
          <div className="chemical-cross">
            <h6 onClick={() => handleWaterUpdate(index4)}>
              Chemicals added
            </h6>
            <i
              className="fa fa-times"
              aria-hidden="true"
              onClick={() => removeWaterConfirm(index4)}
            />
          </div>
          {el4?.chemicalTestArray
        && el4?.chemicalTestArray?.length > 0
        && el4?.chemicalTestArray.map(
          (chem, index) => (
            <>
              {(parseFloat(chem?.cost) > 0 || (chem?.cost === 'N/A'))
              && chem?.type === 'product' ? (
                <div className="chemical-description">
                  <div className="chemical-name">
                    -
                    {' '}
                    {chem.subcategory || ''}
                  </div>
                  <div className="chemical-rate">
                    {/* $ */}
                    <span>
                      {' '}
                      {displayDollar(chem?.renderCost)}
                      <span>
                        {displayCacliPrices(chem?.renderCost, '')}
                      </span>
                    </span>
                  </div>
                </div>
                ) : null}
            </>
          ),
        )}
        </div>
      </div>
    </>
          )}
        </>
      ))}

      <div className="client-quotes-notes" onClick={() => handleOpentTextPopup('Closing')}>
        <span>
          {quoteClosingText || ''}
        </span>
      </div>
      <div className="appointment-total">
        <h6>
          {' '}
          {`Quote Total: $${getTotalCost(waterServiceSummary.newTotalCost, summary.totalCost)}`}
        </h6>

        <p>
          Duration:
          {' '}
          {convertToReadableDurationHelper(waterServiceSummary.newTotalDuration, summary.totalDuration)}
        </p>
        <p>
          Labour & Margin:
          {calculateTotalLabour(waterServiceSummary?.newTotalLabourMargin, summary?.totalLabour)}
        </p>
      </div>

      <Form.Group controlId="formBasicHiddenEmployees">
        <Form.Label>Quote Sign-off - Team Member Name & Mobile</Form.Label>
        <div className="select-box">
          {/* <i className="fas fa-sort-down" /> */}
          {signatureList.length > 0 && (
          <Form.Control
            as="select"
            name="quotesign"
            onChange={(e) => handleSignature(e)}
            value={quoteSign}
          >
            {signatureList && signatureList.map((el, index) => {
              const {
                name, mobileNo, _id, title,
              } = el || {};
              return (
                <option value={_id} selected={_id === quoteSign ? true : ''}>{`${name} |  ${title} | ${mobileNo} `}</option>
              );
            })}

          </Form.Control>
          )}
          {signatureList.length === 0 && (
          <Form.Control
            as="select"
            name="quotesign"
          >
            <option>No Quote Sign-off Available</option>
          </Form.Control>
          )}

        </div>
      </Form.Group>
      <div className="footer-btn view-btn">
        <Button
          className="btn-delete"
          onClick={() => handleReset()}
        >
          Cancel
        </Button>
        <Button
          className="appointment-btn btn-save"
          onClick={handlePreview}
        >
          Preview Quote
        </Button>
      </div>
      <DateModal datePopup={datePopup} setDatePopup={setDatePopup} handleDatePopup={handleDatePopup} quoteDate={quoteDate} />

      <ServiceModal
        showService={showService}
        setShowService={setShowService}
        handleShowService={handleShowService}
        newLength={newLength}
        categories={categories}
        subcategories={subcategories}
        handleBookingData={handleBookingData}
        getTimes={getTimes}
        bookingData={bookingData}
        renderOptions={renderOptions}
        staffList={staffList}
        handleAddService={handleAddService}
        editData={editData}
        handleDeleteService={handleDeleteService}
        handleSearchCategory={handleSearchCategory}
        setSubcategories={setSubcategories}
        addSubcategoryToService={addSubcategoryToService}
        editService={editService}
        setBookingData={setBookingData}
        staffIndex={staffIndex}
        misc={misc}
        addedServices={addedServices}
        servicesLength={addedServices.length}
        extras={extras}
        handleChangeCustom={handleChangeCustom}
        timepickerShow={timepickerShow}
        handleTimePicker={handleTimePicker}
        subcategoryTxt={subcategoryTxt}
        setSearchTxt={setSearchTxt}
        durationPicker={durationPicker}
        setDurationPicker={setDurationPicker}
        convertToReadableDuration={convertToReadableDuration}
        handleDurationPicker={handleDurationPicker}
        setnewlength={setnewlength}
        setHourPicker={setHourPicker}
        hourPicker={hourPicker}
        handleHourPicker={handleHourPicker}
        appointmentType={appointmentType}
        setDuration={setDuration}
        durationData={durationData}
        error={erroValue}
      />
      {timepickerShow ? (
        <TimePicker
          timepickerShow={timepickerShow}
          handleTimePicker={handleTimePicker}
          bookingData={bookingData}
          setBookingData={setBookingData}
          timeData={timeData}
          setTime={setTime}
        />
      ) : null}
      {durationPicker && (
        <DurationPicker
          durationPicker={durationPicker}
          setDurationPicker={setDurationPicker}
          durationData={durationData}
          handleDurationPicker={handleDurationPicker}
          setDuration={setDuration}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      )}
      {hourPicker ? (
        <HourPicker
          setHourPicker={setHourPicker}
          handleHourPicker={handleHourPicker}
          hourPicker={hourPicker}
          hourData={hourData}
          handleDurationPicker={handleDurationPicker}
          setHourData={setHourData}
          setBookingData={setBookingData}
          convertToReadableDuration={convertToReadableDuration}
          bookingData={bookingData}
          subcategory={null}
          setSubCategory={null}
        />
      ) : null}
      {textPopup && (
        <ChangeTextPopup
          textPopup={textPopup}
          handleTextPopup={handleTextPopup}
          text={text}
          placeholderText={placeholderText}
          closingText={quoteClosingText}
          openingText={quoteOpeningText}
          type={quoteText}
          saveUpdateText={saveUpdateText}
        />
      )}

      {/* <PreviewQuote
        showquote={preview}
        handleQuote={handlePreview}
        addClient={addClient}
        addedServices={addedServices}
        quoteDate={quoteDate}
        summary={summary}
        quotesTotal={quotesTotal}
        gst={gst}
        handleAddQuotes={handleAddQuotes}
        selectedTemplate={selectedTemplate}
        quoteNumber={quoteNumber}
      /> */}
      <Modal
        size="md"
        show={previewPopup}
        onHide={handlePreview}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="search-modal new-search-popup1 preview-popup preview-pdf"
      >
        <Modal.Body>
          <div className="appointment-container preview-invoice preview-popup">
            <PreviewQuote
              templateData={templateData}
              customFeilds={customFeilds}
              previewUrl={previewUrl}
              loading={loading}
              setSuccess={setSuccess}
            />
            {success && (
            <div className="footer-btn view-btn">
              <div className="client-option service-edit-icon edit-quote-tempbtn">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className="btn btn-edi">
                    View Other Settings
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handlePreview}>
                      <div className="action">
                        Edit
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleReset()}>
                      <div className="action">
                        Cancel
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* <Button
                className="btn-delete"
                onClick={handlePreview}
              >
                Cancel
              </Button> */}

              <Button
                className="appointment-btn btn-save"
                onClick={() => handleSMS(savetype)}
              >
                {isloader && savetype === 'save'
                  ? (
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : `${savetype === 'save' ? 'Quote to Job' : 'Next'}`}

              </Button>
            </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
      {smsOption && (
        <SMSOption setSMSOption={setSMSOption} handleSubmit={handleAddQuotes} isloader={isloader} />
      )}
      {quoteOption && (
      <QuotesTextOption setQuoteOption={setQuoteOption} handleSubmit={handleQuotesText} types={quoteText} />
      )}
      {waterTestPopup ? (
        <DeleteWaterTestPopup
          setWaterTestPopup={setWaterTestPopup}
          handleWaterTestPopuncheck={removeWaterTest}
        />
      ) : null}
    </>

  );
};
QuoteDetail.propTypes = {
  addClient: PropTypes.func.isRequired,
  setQuoteType: PropTypes.func.isRequired,
  saveQuoteType: PropTypes.string.isRequired,
  showQuote: PropTypes.objectOf.isRequired,
  setAddClient: PropTypes.func.isRequired,
  setSelectedClient: PropTypes.func.isRequired,
  activeAdd: PropTypes.string.isRequired,
  setActiveAdd: PropTypes.func.isRequired,
  setsaveQuoteType: PropTypes.func.isRequired,
  handlePreviewEdit: PropTypes.func.isRequired,
  quoteDataFromAppointment: PropTypes.arrayOf.isRequired,
  from: PropTypes.string.isRequired,
  savetype: PropTypes.string.isRequired,
};
export default QuoteDetail;
