import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Button, Modal, Spinner,
} from 'react-bootstrap';
import getStrNum from '../../functions/strNumseparator';
import { userInstance } from '../../config/axios';
import { validateDataRange } from '../../functions';

const logo = '';
const AddNewImage = ({
  showPopup, handleImagePopup,
  setTemplateDataCopy, templateDataCopy, setTemplateData, templateData, setIsNewChange,
}) => {
  const index = 0;
  const initialCSS = {
    text: '',
    url: '',
    type: 'img',
    textFeild: false,
    index: 0,
    placeholder: 'Company Logo',
    style: {
      lineHeight: '9px',
      imageHeight: '50px',
      imageWidth: '100px',
      imageRotate: 0,
      imageUrl: '',
      zIndex: 9,
      top: '0px',
      left: '5px',
    },
    tdStyle1: {
      width: 'fit-content',
    },
    tdStyle2: {
      textAlign: 'left',
      height: 'fit-content',
      paddingLeft: '0px',
      marginTop: '0px',
      marginBottom: '0px',
    },
  };
  const [selectScaling, setSelectScaling] = useState('Original');
  const [isloader, setisloader] = useState(false);
  const [errors, setErrors] = useState();
  const [customObject, setCustomObject] = useState(templateData[index]);
  const handlesetTemplateData = (data, type) => {
    let oldState = [...templateDataCopy];
    const { name, value } = data.target;
    const { isValid, error } = validateDataRange(value, name);
    setErrors(error);
    if (isValid) {
      let { style } = oldState[index];
      let oldStyleObject = { ...style };
      const types = name === 'imageRotate' ? '' : type;
      oldStyleObject[name] = `${value + types}`;
      setCustomObject({ ...customObject, style: oldStyleObject });
      oldState[index].style = oldStyleObject;
      setTemplateDataCopy(oldState);
    }
  };

  const setSelectScalingValue = (info) => {
    setSelectScaling(info);
    if (info === 'Original') {
      let oldState = [...templateDataCopy];
      let { style } = oldState[index];
      let oldStyleObject = { ...style };
      oldStyleObject.imageHeight = '100%';
      oldStyleObject.imageWidth = '100%';
      setCustomObject({ ...customObject, style: oldStyleObject });
      oldState[index].style = oldStyleObject;
      setTemplateDataCopy(oldState);
    }
  };

  const setTemplateLogo = async (data) => {
    try {
      setisloader(true);

      const formdata = new FormData();
      formdata.append('file', data.target.files[0]);
      const result = await userInstance().post('/template/uploadSingleImage', formdata);
      const { imageUrl } = result.data;
      setCustomObject({
        ...customObject,
        text: imageUrl,
        url: imageUrl,
      });
      setisloader(false);
      data.target.value = '';
    } catch (e) {
      setisloader(false);
    }
  };

  const removePicture = () => {
    let oldState = [...templateDataCopy];
    oldState[index].text = '';
    oldState[index].url = '';
    setTemplateDataCopy(oldState);
    setCustomObject({
      ...customObject,
      text: '',
      url: '',
    });
  };
  const deleteFeild = () => {
    let oldState = [...templateDataCopy];
    oldState[index] = initialCSS;
    setTemplateDataCopy(oldState);
    setTemplateData(oldState);
    handleImagePopup();
  };

  const setNewText = () => {
    let oldState = [...templateDataCopy];
    oldState[index] = customObject;
    setTemplateDataCopy(oldState);
    setTemplateData(oldState);
    handleImagePopup();
    setIsNewChange(true);
  };

  const setOpacity = (info) => {
    let oldState = [...templateDataCopy];
    let { style } = oldState[index];
    let oldStyleObject = { ...style };
    oldStyleObject.zIndex = parseInt(info, 10);
    setCustomObject({ ...customObject, style: oldStyleObject });
    oldState[index].style = oldStyleObject;
    setTemplateDataCopy(oldState);
  };

  const setTemplateLocationSize2 = (data, type = 'px') => {
    const { name, value } = data || {};
    const { isValid, error } = validateDataRange(value, name);
    setErrors(error);
    if (isValid) {
      const keyVal = `${value}px`;
      let oldState = [...templateDataCopy];
      let { style } = oldState[index];
      let oldtdStyleObject = { ...style };
      if ((name === 'left' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject.position = 'absolute';
        oldtdStyleObject[name] = keyVal;
      } else if ((name === 'top' && (value !== '' && value !== '0' && value !== 'px'))) {
        oldtdStyleObject.position = 'absolute';
        oldtdStyleObject[name] = keyVal;
      } else {
        oldtdStyleObject[name] = 'initial';
      }
      if (oldtdStyleObject.left === 'initial' && oldtdStyleObject.top === 'initial') {
        oldtdStyleObject.position = 'initial';
      }
      oldState[index].style = oldtdStyleObject;
      setCustomObject({ ...customObject, style: oldtdStyleObject });
    }
  };

  return (
    <Modal
      size="md"
      show={showPopup}
      onHide={handleImagePopup}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="search-modal new-search-popup1 image-upload-popup new-image-popup"
    >
      <Modal.Body>
        <div className="appointment-container preview-invoice preview-popup">
          <div className="change-image-section">
            <div className="field-location-section">
              <div className="preview-image">
                <h6>Preview Picture</h6>
                <span
                  style={{
                    display: 'inline-block',
                    height: '80px',
                    width: '100%',
                    border: '1px solid #dcd9d9 ',
                    padding: '10px',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  {customObject?.url ? (
                    <img
                      id="an-img"
                      style={{
                        height: 'auto',
                        maxHeight: `${customObject?.style.imageHeight}`,
                        width: `${customObject?.style.imageWidth}`,
                        objectFit: 'contain',
                        marginRight: `${customObject?.style.imageMarginRight}`,
                        top: `${customObject?.style.top}`,
                        left: `${customObject?.style.left}`,
                        position: `${customObject?.style.position}`,
                        transform: `translate(-${customObject?.style.left !== 'initial' ? customObject?.style.left : '0%'}, -${customObject?.style.top !== 'initial' ? customObject?.style.top : '0%'}) rotate(${customObject?.style.imageRotate}deg)`,

                      }}
                      src={customObject?.url ? customObject?.url : logo}
                      alt="logo"
                    />
                  ) : (
                    <h6>Upload Logo</h6>
                  )}
                </span>
              </div>
              <div className={`template-btn temp-btn ${!customObject?.url ? 'right-btns' : ''}`}>
                {customObject?.url && (
                  <Button className="delete-btn" onClick={removePicture}>Remove</Button>
                )}
                <Button type="submit" className="save-template upload-logo">
                  <input
                    type="file"
                    id="upload"
                    onChange={(e) => setTemplateLogo(e)}
                    accept="image/png, image/jpeg, image/jpeg"
                  />
                  <Form.Group>
                    <Form.Label htmlFor="upload">
                      {' '}
                      {isloader
                        ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : 'Select File'}

                    </Form.Label>
                  </Form.Group>
                </Button>
              </div>
              <div className="text-popup-spacing">
                <h6>Image Location & Size</h6>
                <div className="field-location-section">
                  <div className="field-location">
                    <Form.Group controlId="formBasicEmail">

                      <Form.Label>Distance from Top</Form.Label>
                      <Form.Control type="number" value={getStrNum(customObject?.style?.top)?.num} name="top" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(customObject?.style?.top)?.str)} placeholder="Enter Value" />
                      {errors?.top && <p className="error-msg">{errors.top}</p>}
                    </Form.Group>

                  </div>
                  <div className="field-location">
                    <Form.Group controlId="formGridEmail">
                      <Form.Label>Distance from Left</Form.Label>
                      <Form.Control type="number" value={getStrNum(customObject?.style?.left)?.num} name="left" onChange={(e) => setTemplateLocationSize2(e.target, getStrNum(customObject?.style?.left)?.str)} placeholder="Enter Value" />
                      {errors?.left && <p className="error-msg">{errors.left}</p>}
                    </Form.Group>

                  </div>
                </div>
              </div>

              <div className="field-location">
                <h6>Image Opacity</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="imagetype"
                        value={customObject?.style?.zIndex}
                        onChange={(e) => setOpacity(e.target.value)}
                      >
                        <option value="9">Set Image behind type</option>
                        <option value="99">Set Image in front of type</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>
                <h6>Image Size</h6>
                <div className="image-selection">
                  <Form.Group controlId="formBasicCompanyType">
                    <div className="select-box">
                      <Form.Control
                        as="select"
                        name="customerType"
                        value={selectScaling}
                        onChange={(e) => setSelectScalingValue(e.target.value)}
                      >
                        <option value="Original">Fixed Percentage of Original</option>
                        <option value="Custom">Custom Height & Width</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </div>

                <div className="image-size">
                  {selectScaling === 'Original' ? (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Percentage</Form.Label>
                        <Form.Control type="number" name="imageWidth" value={getStrNum(customObject?.style?.imageWidth)?.num} onChange={(e) => handlesetTemplateData(e, getStrNum(customObject?.style?.imageHeight)?.str)} />
                        {errors?.imageWidth && <p className="error-msg">{errors.imageWidth}</p>}
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={customObject?.style?.imageRotate} onChange={(e) => handlesetTemplateData(e)} />
                        {errors?.imageRotate && <p className="error-msg">{errors.imageRotate}</p>}
                      </Form.Group>
                    </>
                  ) : (
                    <>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Height</Form.Label>
                        <Form.Control type="number" name="imageHeight" value={getStrNum(customObject?.style?.imageHeight)?.num} onChange={(e) => handlesetTemplateData(e, getStrNum(customObject?.style?.imageHeight)?.str)} />
                        {errors?.imageHeight && <p className="error-msg">{errors.imageHeight}</p>}
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Scaling Width</Form.Label>
                        <Form.Control type="number" name="imageWidth" value={getStrNum(customObject?.style?.imageWidth)?.num} onChange={(e) => handlesetTemplateData(e, getStrNum(customObject?.style?.imageHeight)?.str)} />
                        {errors?.imageWidth && <p className="error-msg">{errors.imageWidth}</p>}
                      </Form.Group>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Rotate Image</Form.Label>
                        <Form.Control type="number" name="imageRotate" value={customObject?.style?.imageRotate} onChange={(e) => handlesetTemplateData(e)} />
                        {errors?.imageRotate && <p className="error-msg">{errors.imageRotate}</p>}
                      </Form.Group>
                    </>
                  )}

                </div>
              </div>
              <div className={`template-btn ${!customObject?.url ? 'right-btns' : ''}`}>
                {customObject?.url && (
                  <Button onClick={deleteFeild} className="delete-btn">Delete Field</Button>
                )}
                <Button className="save-btn" onClick={setNewText}>Save/ Update</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>

  );
};
AddNewImage.propTypes = {
  showPopup: PropTypes.func.isRequired,
  handleImagePopup: PropTypes.bool.isRequired,
  setTemplateDataCopy: PropTypes.func.isRequired,
  templateDataCopy: PropTypes.arrayOf.isRequired,
  setTemplateData: PropTypes.func.isRequired,
  setIsNewChange: PropTypes.func.isRequired,
  templateData: PropTypes.arrayOf.isRequired,
};
export default AddNewImage;
