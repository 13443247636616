/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { checkAuth } from '../../functions/index';
import { AuthDataContext } from '../../contexts/AuthDataContext';

const UserRoute = ({ component: Component, ...rest }) => {
  const { userData } = useContext(AuthDataContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        // console.log('user user', userData);
        let path = <Redirect to="/login" />;
        if (localStorage.getItem('s#@4f')) {
          const isAuthorized = checkAuth(userData?.accessLevel, props.location.pathname, userData?.permissions, userData?.role);
          if (isAuthorized) {
            if (userData?.firstLogin === true) {
              console.log('here redirecting to update password');
              path = <Redirect to="/updatepassword" />;
            } else if (userData?.industryId?.name
              !== 'Pool Servicing'
            && (rest.path === '/calculator'
              || rest.path
                === '/poolsetup')) {
              path = <Redirect to="/" />;
            } else {
              path = <Component {...props} {...rest} />;
            }
          } else {
            path = <Redirect to="/401" />;
          }
        }
        return path;
      }}
    />
  );
};

UserRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.func.isRequired,
};

export default UserRoute;
