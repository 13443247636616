/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  Form, Button, Modal, Dropdown,
} from 'react-bootstrap';
import { userInstance } from '../../config/axios';
import { errorMsgTime, successMsgTime } from '../../functions/intervalTime';

import flag from '../../assets/admin/aus-flag.png';
import us from '../../assets/admin/us-flag.png';
import { AuthDataContext } from '../../contexts/AuthDataContext';
import { removeCodeFromNumber } from '../../functions';
import toastMessage from '../../functions/toastMessage';

function EditMobilePopup({
  show, handleClose, verifyMobile, setVerifyMobile, istwilio, invoicePhone,
}) {
  const { userData, setUserData } = useContext(AuthDataContext);
  const [number, setNumber] = useState();
  const [otpvalue, setOtpValue] = useState('');
  const loginStaff = localStorage.getItem('loginStaff');
  const [isMobile, setisMobile] = useState(istwilio);
  const [selectedCountry, setCountry] = useState('+61');
  const [errorValue, setErrorValue] = useState('');

  useEffect(() => {
    if (invoicePhone) {
      const { updatedNo, countryCode } = removeCodeFromNumber(invoicePhone);
      setNumber(updatedNo);
      setCountry(countryCode);
    }
  }, [invoicePhone]);
  const onNumberhandle = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setNumber(value);
  };
  const handleOtpInput = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setOtpValue(value);
  };
  const handleVerifyMobile = async (e, type) => {
    e.preventDefault();
    // console.log('type===> verifyPhone  isActivePhone', type);
    const payload = {
      tonumber: number,
      loginStaffId: loginStaff,
      type,
      istwilio: isMobile,
    };
    let isMobileValid = true;
    if (payload?.tonumber) {
      payload.tonumber = `${selectedCountry} ${payload.tonumber}`;
    }

    if (type === 'verifyPhone') {
      if (selectedCountry === '+61') {
        console.log(number);
        if (number.charAt(0) === '0') {
          payload.tonumber = payload.tonumber.replace('0', '');
        }
        if (number.charAt(0) !== '4') {
          isMobileValid = false;
          toastMessage('error', 'Please start mofdsbile number with 4');
          setErrorValue('mobileno');
        }
        if (number.trim().length !== 11) {
          setErrorValue('mobileno');
          isMobileValid = false;
          // mobileMsg = 'Invalid Mobile Number';
          toastMessage('error', 'Enter 9 Digit Mobile Number');
        }
      }
      if (selectedCountry === '+1') {
        if (number.charAt(0) === '0') {
          payload.tonumber = payload.tonumber.replace('0', '');
        }

        if (number.trim().length !== 12) {
          setErrorValue('mobileno');
          isMobileValid = false;
          // mobileMsg = 'Invalid Mobile Number';
          toastMessage('error', 'Enter 10 Digit Mobile Number');
        }
      }
      if (isMobileValid) {
        if (number) {
          const response = await userInstance().post('/automaticInvoice/twillioSetting', payload);
          toastMessage('success', 'Verification Code has been sent to your mobile number.');
          setVerifyMobile(true);
          // setUserData({
          //   isNumberVerify: false,
          // });
        } else {
          setErrorValue('mobileNo');
          toastMessage('error', 'Please enter mobile number.');
        }
      }
    }
    if (type === 'isActivePhone') {
      const response = await userInstance().post('/automaticInvoice/twillioSetting', payload);
      setUserData({
        ...userData,
        istwilio: isMobile,
      });
      toastMessage('success', 'SMS message setting updated successfully.');
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };
  const verifytwilioNumber = async (e) => {
    e.preventDefault();
    if (otpvalue) {
      const payload = {
        otp: otpvalue,
        loginStaffId: loginStaff,
      };
      const response = await userInstance().post('/automaticInvoice/verifyTwilioOtp', payload);
      const { msg, code } = response.data;
      if (code === 200) {
        if (msg === 'Success') {
          toastMessage('success', 'Number verified successfully');
          setUserData({
            ...userData,
            invoicePhone: number,
            isNumberVerify: true,
          });
          handleClose('phone');
        } else {
          setErrorValue('otp');
          toastMessage('error', 'Incorrect OTP.');
        }
      }
    } else {
      setErrorValue('otp');
      toastMessage('error', 'Enter OTP first.');
    }
    setTimeout(() => { setErrorValue(''); }, 1000);
  };
  const handleCountry = (countryCode) => {
    setCountry(countryCode);
    const textToReplace = number.includes('+61') ? '+61' : '+1';
    let num = '';
    if (number) {
      num = `${number.replace(textToReplace, '')}`;
    }
    setNumber(num);
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => handleClose('phone')}
      aria-labelledby="contained-modal-title-vcenter"
      top
      className="staff-modal edit-mobile-popup sending-reminders-page"
    >
      <div className="staff-close">
        <i className="fa fa-times" aria-hidden="true" onClick={() => handleClose('phone')} />
      </div>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {verifyMobile ? 'Verify Mobile Number via SMS ' : 'Edit or Change Authorised Mobile Number'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="staff-form">
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="formBasicInput-3" className="number italic-text">
                  <Form.Label>
                    Authorised Mobile No.
                  </Form.Label>
                  <div className="number-field">
                    {/* <Form.Label className="special-label">Phone Input</Form.Label> */}
                    <div className="number-box">
                      <Dropdown id="mobile">
                        <Dropdown.Toggle variant="success" id="dropdown-basic1">
                          <div className="flag-icon">
                            <img src={selectedCountry === '+61' ? flag : us} alt="flag" />
                          </div>
                          {selectedCountry === '+61' ? (
                            <span>
                              Australia
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : selectedCountry === '+1' ? (
                            <span>
                              United States
                              {' '}
                              {selectedCountry}
                            </span>
                          ) : ''}
                          <span>
                            <i className="fas fa-caret-down" />
                          </span>
                          {/* <div className="country-code">{selectedCountry}</div> */}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleCountry('+61')} id="12">
                            <div className="flag-icon">
                              <img src={flag} alt="flag" />
                            </div>
                            <div className="country-code">
                              <span>Australia</span>
                              +61
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleCountry('+1')} id="122">
                            <div className="flag-icon">
                              <img src={us} alt="flag" />
                            </div>
                            <div className="country-code">
                              {' '}
                              <span>United States</span>
                              +1
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <NumberFormat
                      // className="form-control mobile-num"
                      className={`${
                        errorValue === 'mobileNo'
                          ? 'error-text'
                          : ''
                      }`}
                      format={`${selectedCountry === '+61' ? '### ### ###' : '### ### ####'}`}
                      placeholder="Enter Authorised Mobile No."
                      name="mobileNo"
                      autocomplete="off"
                      value={number}
                      onChange={(e) => onNumberhandle(e)}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn view-single-btn">
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={(e) => handleVerifyMobile(e, 'verifyPhone')}
                  >
                    {' '}
                    Send Verification Code
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
            {verifyMobile && (
            <div className="verification-code">

              <div className="row">
                <div className="col-md-12">
                  <Form.Group controlId="formBasicInput-1">
                    <Form.Label>
                      Enter Verification Code Received
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="companyName"
                      onChange={handleOtpInput}
                      className={`${
                        errorValue === 'otp'
                          ? 'error-text'
                          : ''
                      }`}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="view-btn view-single-btn">
                    <Button
                      type="Submit"
                      className="btn btn-save"
                      onClick={verifytwilioNumber}
                    >
                      {' '}
                      Submit
                      {' '}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            )}
          </Form>
        </div>
        {verifyMobile ? '' : (
          <div className="appointment-summary">
            <h6>SMS Messages & Email Settings for Authorised Mobile</h6>
            <div className="pending-water-test">
              <h3>
                SMS Message Settings for Authorised Mobile
              </h3>
              <div className="open-date client-notes">
                <div className="basic">
                  <Form.Check
                    type="checkbox"
                    checked={isMobile}
                    onChange={() => setisMobile(!isMobile)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="view-btn">
                  <Button
                    type="Submit"
                    className="btn btn-edit"
                  >
                    {' '}
                    Cancel
                    {' '}
                  </Button>
                  <Button
                    type="Submit"
                    className="btn btn-save"
                    onClick={(e) => handleVerifyMobile(e, 'isActivePhone')}
                  >
                    {' '}
                    Save/Update
                    {' '}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
EditMobilePopup.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setVerifyMobile: PropTypes.func.isRequired,
  verifyMobile: PropTypes.bool.isRequired,
  istwilio: PropTypes.bool.isRequired,
  invoicePhone: PropTypes.string.isRequired,
};

export default EditMobilePopup;
